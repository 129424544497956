<md-dialog aria-label="Approve" style="min-width:600px; overflow:hidden;"  ng-cloak>

    <md-toolbar>
        <div class="md-toolbar-tools">
            <h2>{{approvedialog.action}}</h2>
            <span flex></span>

            <md-button ng-click="approvedialog.dialogCancel(false)" class="md-icon-button">
                <ng-md-icon  icon="close" size="24"  aria-label="Close dialog"></ng-md-icon>
            </md-button>
        </div>
    </md-toolbar>
    <md-dialog-content class="dialog-content" style=""  md-autofocus>
        <div style="padding: 20px;"  ng-if="!approvedialog.isPublishing">

            <div layout="column" ng-if="!approvedialog.showApproveAll">
                <div flex>
                    <md-checkbox ng-model="approvedialog.checkboxes.this" ng-init="approvedialog.checkboxes.this = true;" aria-label="Checkbox 1">
                    <label>{{approvedialog.action}} this item</label>
                    </md-checkbox>
                </div>
                <div flex ng-if="approvedialog.hasChildren">
                    <md-checkbox ng-model="approvedialog.checkboxes.all" aria-label="Checkbox 2">
                        <label>Approve all children of this item</label>
                    </md-checkbox>
                </div>
                <div ng-if="approvedialog.beforeList.length > 0" layout="row">
                    <div flex="100" class="downloads-container" ng-if="approvedialog.beforeList.length > 0">
                        <h5>Artwork draft</h5>
                        <div ng-repeat="upload in approvedialog.beforeList" layout="row" layout-wrap layout-padding="20" class="download-row small" ng-class="{'file-name-error': !approvedialog.filenameInCodeCheck(upload.fileName,approvedialog.book.bookCode)}">
                            <md-tooltip ng-if="!approvedialog.filenameInCodeCheck(upload.fileName,approvedialog.book.bookCode);" class="statbar"  md-direction="top">This filename does not contain the current job code</md-tooltip>           
                            <a href="{{upload.ngfName|fileurl}}" target="_blank">
                                <div class="thumbnail-block" wraps-tooltip="artwork-upload-thumb">
                                    <img class="thumbnail-preview" ng-src="{{upload.ngfName|thumburl}}" err-SRC="/images/wraps-logo.png" alt="{{upload.fileName}}"/>
                                    <div class="overlay text-center"><h3><ng-md-icon icon="file_download" size="24"></ng-md-icon>{{upload.fileSize | filesize}}</h3></div>
                                </div>
                            </a>
                            <div flex>
                                <h3 >{{upload.fileName}}</h3>
                                <h3 >{{upload.fileSize | filesize}}</h3>
                            </div>
                            <div flex="100" class="divider"><div></div></div>
                        </div>
                    </div>
                    <div flex="50" class="downloads-container" ng-if="approvedialog.afterList.length > 0">
                        <h5>Final Files</h5>
                        <div ng-repeat="upload in approvedialog.afterList" layout="row" layout-wrap layout-padding="20" class="download-row small" ng-class="{'file-name-error': !approvedialog.filenameInCodeCheck(upload.fileName,approvedialog.book.bookCode)}">
                            <md-tooltip ng-if="!approvedialog.filenameInCodeCheck(upload.fileName,approvedialog.book.bookCode);" class="statbar"  md-direction="top">This filename does not contain the current job code</md-tooltip>           
                            <a href="{{upload.ngfName|fileurl}}" target="_blank">
                                <div class="thumbnail-block" wraps-tooltip="artwork-upload-thumb">
                                    <img class="thumbnail-preview" ng-src="{{upload.ngfName|thumburl}}" err-SRC="/images/wraps-logo.png" alt="{{upload.fileName}}"/>
                                    <div class="overlay"><h3><ng-md-icon icon="file_download" size="24"></ng-md-icon>{{upload.fileSize | filesize}}</h3></div>
                                </div>
                            </a>
                            <div flex>
                                <h3>{{upload.fileName}}</h3>
                                <h3 >{{upload.fileSize | filesize}}</h3>
                            </div>
                            <div flex="100" class="divider"><div></div></div>
                        </div>
                    </div>
                </div>
            </div>
            <div layout="column" ng-if="approvedialog.showApproveAll">

                <div flex>
                    <md-checkbox ng-model="approvedialog.checkboxes.allOthers" aria-label="Checkbox 3">
                        <label>Approve ALL content items in this book?</label>
                    </md-checkbox>
                </div>
                <p>By clicking <strong>Approve All</strong>, you agree that you have reviewed all content within this document, it is correct and you are happy to take responsibility for this.
                    You are approving all of the copy within this document, not just the changes. If in doubt, do not use ‘approve all’; instead check through each content block approving the book in sections.</p>
            </div>

        </div>

        <div style="padding: 20px 20px 0;"  ng-if="approvedialog.isPublishing">

            <div layout="column" >

                <div>
                    <div flex>
                        <md-checkbox ng-model="approvedialog.checkboxes.this" aria-label="Checkbox 4">
                            <label>Confirm Publishing</label>
                        </md-checkbox>
                    </div>
                    <ul>
                        <li ng-repeat="item in approvedialog.publishTypes">{{item.name}}</li>
                    </ul>
                </div>

                <br />

                <div ng-if="approvedialog.book.enclosures && approvedialog.isFulfilment">
                    <b>Enclosures:</b>
                    <ul>
                        <li ng-repeat="item in approvedialog.book.enclosures">{{item}}</li>
                    </ul>
                </div>

                <div ng-if="approvedialog.selectedProductIds && approvedialog.isFulfilment">

                    <b>Product Ids:</b>

                    <ul>
                        <li ng-repeat="item in approvedialog.selectedProductIds">{{item.productId}} - {{item.Insurers.insurerName}} - {{item.masterProductGroup}}</li>
                    </ul>

                </div>

            </div>

        </div>

        <div layout="row" layout-wrap  flex="100"  style="padding: 0 20px;" ng-animate="approvedialog.needFile" ng-if="approvedialog.needFile">
            <h4>Approval files</h4>
            <div flex="100" layout="row" layout-wrap>
                <div flex="100">
                    <md-input-container class="md-block">
                        <label>Approval message</label>
                        <textarea name="message" ng-model="approvedialog.message"   rows="5" required></textarea>
                    </md-input-container>
                    <div class="errors" ng-messages="rejectForm.message.$error" ng-if="rejectForm.$dirty">
                        <div ng-message="required">Required</div>
                    </div>
                </div>

                <ul class="file-ui">
                    <li ng-repeat="f in approvedialog.filesList" style="font:smaller" class="file-li animate-if">{{f.name}}
                        <div class="file-prog" ng-mouseenter="f.hovered = true" ng-mouseleave="f.hovered = false">
                            <!--<md-progress-circular class="animate-if" ng-if="f.determinateValue < 100" md-mode="determinate" value="{{f.determinateValue}}" md-diameter="30"></md-progress-circular>-->
                            <md-progress-circular class="animate-if" ng-if="!f.loaded" md-mode="indeterminate" md-diameter="30"></md-progress-circular>
                            <!--<span class="animate-if" ng-if="f.determinateValue < 100" >{{f.determinateValue}}%</span>-->
                            <ng-md-icon class="animate-if file-complete" ng-if="f.loaded"  icon="check_circle" size="30"  aria-label="Upload complete"></ng-md-icon>
                            <ng-md-icon class="file-remove" ng-if="f.hovered"  icon="cancel" size="30"  aria-label="Remove file" ng-click="approvedialog.removeFile(approvedialog.filesList, $index)"></ng-md-icon>
                        </div>
                    </li>
                </ul>
            </div>
            <div flex="5"></div>
            <div flex="30">
                <div ngf-drop ngf-select ng-model="approvedialog.files" class="drop-box"
                     ngf-drag-over-class="'dragover'" ngf-multiple="true" ngf-allow-dir="true"
                     accept="{{approvedialog.safeFileList}}"
                     ngf-pattern="approvedialog.safeFileList">
                    <ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop files here or<br /><strong style="text-decoration: underline;">Click to upload</strong>
                </div>
                <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>



            </div>



        </div>






    </md-dialog-content>
    <md-dialog-actions layout="row">
        <span flex></span>

        <md-button class="md-primary" ng-click="approvedialog.dialogCancel(false)">Cancel</md-button>
        <md-button ng-disabled="(approvedialog.hasChecks(approvedialog.checkboxes) || approvedialog.isApproving) || (approvedialog.needFile && approvedialog.uploadMandatory && (!approvedialog.files[0].fileName || approvedialog.filesList.length <= 0))" class="md-raised md-accent" ng-click="approvedialog.approveItems(approvedialog.checkboxes,approvedialog.needFile)">{{approvedialog.action}}</md-button>

    </md-dialog-actions>
</md-dialog>