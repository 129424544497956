(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.bottomsheet.controller:BottomsheetCtrl
   *
   * @description
   *
   */
  angular
    .module('library.bottomsheet')
    .controller('BottomsheetCtrl', BottomsheetCtrl);

  function BottomsheetCtrl($localStorage, $mdBottomSheet, $mdDialog, $q, $scope, $timeout, $interval, $filter, apiUrl, book, Books, Containers, Events, History, item, Structures, Upload, Users, Versions, Wraps, WrapsBooks) {
    var vm = this;
    vm.ctrlName = 'BottomsheetCtrl';

    $scope.$storage = $localStorage;
    if(!Users.isAuthenticated()){$location.path('/');}


    //vm.safeFileList = Wraps.safeFileList;

    
    vm.bottomSheetData = item;
    vm.tempData = angular.copy(item);

    vm.book = book;

    vm.hideBottomSheet = function () {
      $mdBottomSheet.cancel();
    }


    vm.nl2br = Wraps.nl2br;
    vm.json2Obj = Wraps.json2Obj;

    vm.getTableWidths = Wraps.getTableWidths;


    vm.filenameInCodeCheck = WrapsBooks.filenameInCodeCheck;


    if(item.versions.Contents.contentTypeId === 12) {
      Wraps.attachTableHeader(vm.bottomSheetData).then(function (results) {
        vm.bottomSheetData.tableHeader = results;
      });

    }

    vm.chapterTypes = Wraps.chapterTypes;

    vm.compareObjects = function(obj1,obj2){
		return  angular.equals(obj1,obj2);
	  }


    vm.openChangeDialog = function (filter) {
      var deferred = $q.defer();
      if($scope.$storage.user.roleId === 6){
        return $q.resolve( {"message": "Ammendment made"} );
      }
      else {
        return $mdDialog.show({
          controller: 'ChangedialogCtrl',
          controllerAs: 'changedialog',
          templateUrl: 'library/changedialog/changedialog.tpl.html',
          parent: angular.element(document.body),
          locals: {filter: filter},
          bindToController: true,
          clickOutsideToClose: true
        })
          .then(function (data) {
            return data;
          }, function () {
            return false; //cancel
          });
      }
    };

    Wraps.getQREFs(vm.isLoading).then(function(data) {
      vm.QREFs = data;
      //console.log("vm.QREFs",vm.QREFs);
    });




    vm.saveVersion = function (content) {
      var test = '';
      //if(1===1) {
      vm.openChangeDialog().then(function(eventData){
        if(eventData) {


          //console.log(eventData);
          vm.isLoading = true;
          var version = angular.copy(content.versions);
          var content_copy = angular.copy(content);
          delete content_copy.versions;


          if (version.Contents.contentTypeId === 1) {
            //console.log(version);

            if(content.chapterTypeId===2){
              var chapterObj = {};

              chapterObj.chapterTypeId  = content.chapterTypeId;
              chapterObj.chapterLinkId = content.chapterLinkId;
              chapterObj.chapterCopy = "<h2>Front Cover</h2>\n";
              if(content.filesList){
                chapterObj.filesList = content.filesList;
              }
              if(content.PDFList){
                chapterObj.PDFList = content.PDFList;
              }
              
              var chapterJson = Wraps.toJson(chapterObj); //front cover
              version.versiondata = chapterJson;
            }else if(content.chapterTypeId===3){
              var chapterJson = Wraps.toJson({chapterTypeId: content.chapterTypeId, chapterLinkId: content.chapterLinkId, chapterCopy: "<h2>QREF</h2>\n" }); //qref
              version.versiondata = chapterJson;
            }else if(content.chapterTypeId===4){
              var chapterJson = Wraps.toJson({chapterTypeId: content.chapterTypeId,  chapterCopy: version.versiondata }); //contents
              version.versiondata = chapterJson;
            }else if(content.chapterTypeId===5){
              var chapterJson = Wraps.toJson({chapterTypeId: content.chapterTypeId,  chapterCopy: "<h2>Summary of benefits and Exclusions</h2>\n" }); //summary
              version.versiondata = chapterJson;
            }else if(content.chapterTypeId===6){
              var chapterJson = Wraps.toJson({chapterTypeId: content.chapterTypeId,  chapterCopy: "<h2>Back Cover</h2>\n" }); //back cover
              version.versiondata = chapterJson;
            }else if(content.chapterTypeId===7){
              var chapterJson = Wraps.toJson({chapterTypeId: content.chapterTypeId,  chapterCopy: version.versiondata }); //BGHP Benefits
              version.versiondata = chapterJson;
            }else if(content.chapterTypeId===8){
              var chapterJson = Wraps.toJson({chapterTypeId: content.chapterTypeId,  chapterCopy: version.versiondata }); //BGHP Terms
              version.versiondata = chapterJson;
            }else if(content.chapterTypeId===9){
              var chapterJson = Wraps.toJson({chapterTypeId: content.chapterTypeId,  chapterCopy: version.versiondata }); //BGHP Exclusions
              version.versiondata = chapterJson;
            }else{
              var chapterJson = Wraps.toJson({chapterTypeId: content.chapterTypeId,  chapterCopy: version.versiondata }); //default
              version.versiondata = chapterJson;
            }

          } else if (version.Contents.contentTypeId === 5) {
            var benefit = {};
            if (_.size(content_copy.benefit.options) === 0) {
              delete content_copy.benefit.options;
            }
            benefit.benefit = content_copy.benefit;
            version.versiondata = Wraps.toJson(benefit);
            //console.log('benefit');
          } else if (version.Contents.contentTypeId === 6) {
            var glossary = {};
            glossary.glossary = content_copy.glossary;
            version.versiondata = Wraps.toJson(glossary);
            //console.log('glossary');
          } else if (version.Contents.contentTypeId === 7) {
            var exclusion = {};
            exclusion.exclusion = content_copy.exclusion;
            version.versiondata = Wraps.toJson(exclusion);
            //console.log('exclusion');
          } else if (version.Contents.contentTypeId === 8) {
            var medical = {};
            medical.medical = content_copy.medical;
            version.versiondata = Wraps.toJson(medical);
            //console.log('medical');
          } else if (version.Contents.contentTypeId === 9) {
            var brief = {};
            brief.brief = content_copy.brief;
            version.versiondata = Wraps.toJson(brief);
            //console.log('brief');
          } else if (version.Contents.contentTypeId === 10) {
            var artwork = {};
            artwork.artwork = content_copy.artwork;
            version.versiondata = Wraps.toJson(artwork);
            //console.log('artwork');
          } else if (version.Contents.contentTypeId === 11) {
            var tableHeader = {};
            tableHeader.tableHeader = content_copy.tableHeader;
            version.versiondata = Wraps.toJson(tableHeader);
            //console.log('tableHeader');
          } else if (version.Contents.contentTypeId === 12) {
            var tableRow = {};
            tableRow.tableRow = content_copy.tableRow;
            version.versiondata = Wraps.toJson(tableRow);
            //console.log('tableRow');
          }else if (version.Contents.contentTypeId === 13) {
            var artwork = {};
            artwork.artwork = content_copy.artwork;
            version.versiondata = Wraps.toJson(artwork);
            //console.log('final');
          } else if (version.Contents.contentTypeId === 17) {
            var benefit = {};
            if (_.size(content_copy.benefit.options) === 0) {
              delete content_copy.benefit.options;
            }
            benefit.benefit = content_copy.benefit;
            version.versiondata = Wraps.toJson(benefit);
            //console.log('benefit');
          } else if (version.Contents.contentTypeId === 20) {
            var exclusion = {};

            exclusion.exclusion = content_copy.exclusion;
            version.versiondata = Wraps.toJson(exclusion);
            //console.log('exclusion');
          }



          Versions.checkVersion({
            "versiondata": version.versiondata,
            "contentId": version.contentId
          }).$promise.then(function (newVersionId) {

            // if we find a version with the same text we reuse it rather than create duplicates
            if (!angular.isUndefined(newVersionId.versionId)) {

              content_copy.versionId = newVersionId.versionId;
              content_copy.lastUpdated = moment.utc().format(Wraps.databaseDateFormat);
              Structures.upsert(content_copy, function () {
                Books.upsert({"bookId": content_copy.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                History.create({
                  "structureId": content_copy.structureId,
                  "versionIdOld": content.versionId,
                  "versionIdNew": content_copy.versionId,
                  "historyId": 0
                }, function (historyResponse) {
                  Events.create({
                    "eventId": 0,
                    "bookId": content_copy.bookId,
                    "structureId": content_copy.structureId,
                    "contentId": version.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Edited",
                    "eventData": Wraps.toJson(eventData)
                  });
                  content.versionId = content_copy.versionId;
                  $mdBottomSheet.hide();
                  vm.isLoading = false;
                });
              });

            } else {
              version.versionId = 0;
              version.created = moment.utc().format(Wraps.databaseDateFormat);
              version.lastUpdated = moment.utc().format(Wraps.databaseDateFormat);
              version.status = 1;


              Versions.create(version, function (data) {
                content_copy.versionId = data.versionId;
                //console.log(content.versionId);
                content_copy.lastUpdated = moment.utc().format(Wraps.databaseDateFormat);
                Structures.upsert(content_copy, function () {

                  Books.upsert({"bookId": content_copy.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                  History.create({
                    "structureId": content_copy.structureId,
                    "versionIdOld": content.versionId,
                    "versionIdNew": content_copy.versionId,
                    "historyId": 0
                  }, function (historyResponse) {
                    Events.create({
                      "eventId": 0,
                      "bookId": content_copy.bookId,
                      "structureId": content_copy.structureId,
                      "contentId": version.contentId,
                      "historyId": historyResponse.historyId,
                      "userId": $scope.$storage.user.id,
                      "eventType": "Edited",
                      "eventData": Wraps.toJson(eventData)
                    });
                    content.versionId = content_copy.versionId;
                    $mdBottomSheet.hide().then(function () {
                      vm.isLoading = false;
                    });

                  });
                });
              });
            }
          });
        }
      });
    };
    vm.disabledProducts = {};
    vm.updateOption = function(benfit,product){

      if(benfit.option) {
        if (!benfit.option[product]) {
          delete benfit.options[product];
          benfit[product] = vm.tempData.benefit[product];
          vm.disabledProducts[product] = false;
        }
      }
    }

    vm.updateOptions = function(benfit,product){
      if(benfit.options[product]==="Linked"){
        benfit[product] = "";
        vm.disabledProducts[product] = true;
      }else if(benfit.options[product]==="Not covered"){

        benfit[product] = "Not covered";
        vm.disabledProducts[product] = true;
      }else if(benfit.options[product]==="Paid in full"){

        benfit[product] = "Paid in full";
        vm.disabledProducts[product] = true;
      }else if(benfit.options[product]==="Optional") {
        benfit[product] = vm.tempData.benefit[product];
        vm.disabledProducts[product] = false;
      }else if(benfit.options[product]==="Show in summary") {
        benfit[product] = vm.tempData.benefit[product];
        vm.disabledProducts[product] = false;
      }
    }
    vm.updateLinked = function(benfit,product){
      if(!benfit.options[product]){
        delete benfit.options[product];
        benfit[product] = vm.tempData.benefit[product];
      }else{
        benfit[product] = "";
      }
    }

    vm.showHistory = function(id) {
      $mdDialog.show({
        controller: 'HistorydialogCtrl',
        controllerAs: 'historydialog',
        templateUrl: 'library/historydialog/historydialog.tpl.html',
        parent: angular.element(document.html),
        locals:  {structureId: id, clickSource: false },
        clickOutsideToClose:true
      })
        .then(function(data) {

          if(data){
            vm.bottomSheetData.versions.versiondata = data;
            vm.json2Obj(vm.bottomSheetData);
            
            
            
            //console.log(vm.bottomSheetData.versions);
            //console.log(data);


          }

        }, function() {
          vm.status = 'You cancelled the dialog.';
        });
    };


    // CKEditor options.
    vm.options = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      removeButtons: 'Subscript,Superscript',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools',bottom: 'ckfoot'},
      toolbar: [
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
        { name: 'paragraph', items: [  'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent'] },
        { name: 'wrapsplugins', items: [ 'Highlight','UnBold' ] },
        { name: 'format', items: [  'RemoveFormat' ] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };

    vm.options2 = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      enterMode: CKEDITOR.ENTER_BR,
      autoParagraph: false,
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools',bottom: 'ckfoot'},
      toolbar: [
        { name: 'wrapsplugins', items: [  'Highlight' ] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };

    vm.options3 = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      removeButtons: 'Subscript,Superscript',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools2',bottom: 'ckfoot2'},
      toolbar: [
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
        { name: 'paragraph', items: [  'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent'] },
        { name: 'wrapsplugins', items: [  'Highlight','UnBold' ] },
        { name: 'format', items: [  'RemoveFormat' ] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };

    vm.options4 = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      removeButtons: 'Subscript,Superscript',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools3',bottom: 'ckfoot3'},
      toolbar: [
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
        { name: 'paragraph', items: [  'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',] },
        { name: 'wrapsplugins', items: [  'Highlight','UnBold' ] },
        { name: 'format', items: [  'RemoveFormat' ] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };


    vm.removeFile = function(array, index){
      array.splice(index, 1);
    }


    var timestamp = new Date().getTime();
    $scope.$watch('bottomsheet.briefFiles', function () {

      vm.upload(vm.briefFiles,'brief').then(function(statuses){
        vm.isLoading = false;
      });
    });

    $scope.$watch('bottomsheet.artworkFiles', function () {

      vm.upload(vm.artworkFiles,'artwork').then(function(statuses){
        vm.isLoading = false;
      });
    });

    $scope.$watch('bottomsheet.chapterFiles', function () {
      vm.upload(vm.chapterFiles,'logo').then(function(statuses){
        vm.isLoading = false;
      });
    });

    $scope.$watch('bottomsheet.frontCoverPDF', function () {
      vm.upload(vm.frontCoverPDF,'frontCoverPDF').then(function(statuses){
        vm.isLoading = false;
        });
    });

    vm.log = '';
    //vm.filesList = [];
    vm.upload = function (files,itemType) {

      var promises = {};
     // var S3_BUCKET_URL = apiUrl+'/containers/wraps-files%2f'+itemType+'%2f'+timestamp+'/upload';
      var S3_BUCKET_URL = apiUrl+'/containers/wraps-files/upload';
      var deferred = $q.defer();

       if (files && !files.length){ //if multiple = false
         var tempFiles = [files];
         files = tempFiles;
      }

      if (files && files.length) {
        _.forEach(files, function (file) {
          if (!file.$error) {
            vm.isLoading = true;
            var thisFileDefer = $q.defer();
            promises[file.name] = thisFileDefer.promise;
            //console.log(file);

            file.container = 'wraps-files';
            file.fileLocation = itemType+'/'+timestamp;
            file.fileName = file.name;
            file.fileSize = file.size;
            file.isLoading = true;
            if(itemType==='brief'){vm.bottomSheetData.brief.filesList.push(file);}
            else if(itemType==='artwork'){vm.bottomSheetData.artwork.filesList.push(file);}
            else if(itemType==='final'){vm.bottomSheetData.artwork.filesList.push(file);}
              
            else if(itemType==='logo') {
              if(!vm.bottomSheetData.filesList){
                vm.bottomSheetData.filesList=[];
              }
              if(tempFiles){
                vm.bottomSheetData.filesList = [];
              }
              vm.bottomSheetData.filesList.push(file);
            }else if(itemType==='frontCoverPDF') {
                if(!vm.bottomSheetData.PDFList){
                    vm.bottomSheetData.PDFList=[];
                }
                if(tempFiles){
                    vm.bottomSheetData.PDFList = [];
                }
                vm.bottomSheetData.PDFList.push(file);
            }


            //TODO add timeout to animate files
            var newName = file.fileLocation + '/' + file.name;
            Upload.rename(file, newName );
            Upload.upload({
              url: S3_BUCKET_URL,
              file: file}).then(function (resp) {
                var count = 0;
                var interval = $interval( function(){
                    Wraps.isImage($filter('thumburl')(file.ngfName)).then(function(rtn){
                        if (rtn === true){
                            $interval.cancel(interval);
                            //console.log(resp.config.file);
                            file.isLoading = false;
                            thisFileDefer.resolve(resp);
                        }
                    });
                    if(count++ >= 20){ //waiting 10 seconds before finishing
                        $interval.cancel(interval);
                        file.isLoading = false;
                        thisFileDefer.resolve('no thumbnail');
                    }
                }, 1000);
            }, null, function (evt) {
              file.determinateValue = parseInt(100.0 *   evt.loaded / evt.total);
              //console.log("file.determinateValue",file.determinateValue);

            });
          }
        });
      }

      return $q.all(promises);
    };


    
    // $scope.$watch('addartwork.files', function () {

    //   vm.upload(vm.files).then(function(statuses){
    //     vm.isLoading = false;
    //   });
    // });

    //vm.filesList = [];

    // vm.upload = function (files) {

    //   var promises = {};

    //   if (files && files.length) {
    //     _.forEach(files, function (file) {
    //       if (!file.$error) {
    //         vm.isLoading = true;
    //         var thisFileDefer = $q.defer();
    //         promises[file.name] = thisFileDefer.promise;

    //         file.container = 'wraps-files';
    //         file.fileLocation = itemType+'/'+timestamp;
    //         file.fileName = file.name;
    //         file.fileSize = file.size;
    //         vm.filesList.push(file);
    //         file.isLoading = true;


    //         //TODO add timeout to animate files

    //         var newName = file.fileLocation + '/' + file.name;
    //         Upload.rename(file, newName );
    //         Upload.upload({
    //           url: S3_BUCKET_URL,
    //           file: file}).then(function (resp) {
    //             var count = 0;
    //             var interval = $interval( function(){
    //                 Wraps.isImage($filter('thumburl')(file.ngfName)).then(function(rtn){
    //                     if (rtn === true){
    //                         $interval.cancel(interval);
    //                         //console.log(resp.config.file);
    //                         file.isLoading = false;
    //                         thisFileDefer.resolve(resp);
    //                     }
    //                 });
    //                 if(count++ >= 10){ //waiting 10 seconds before finishing
    //                     $interval.cancel(interval);
    //                     file.isLoading = false;
    //                     thisFileDefer.resolve('no thumbnail');
    //                 }
    //             }, 1000);
    //         }, null, function (evt) {
    //           file.determinateValue = parseInt(100.0 *   evt.loaded / evt.total);

    //         });
    //       }
    //     });
    //   }
    //   return $q.all(promises);
    // };



    // Called when the editor is completely ready.
    vm.onReady = function () {
      // ...
    };
  }
}());



