<md-content layout-padding>
    <div ng-show="tabWorkflow.book.workflowDoc"  class="workflow">
        <h4 wraps-tooltip="docman-wf-input_docapprovers">Document Workflow</h4>
        <input-wf-step ownerid="tabWorkflow.book.bookOwner" array="tabWorkflow.book.workflowDoc" disabled="tabWorkflow.disabled" dragular="tabWorkflow.docOptions" dragular-model="tabWorkflow.book.workflowDoc" isvalid="tabWorkflow.validDoc"></input-wf-step>
        <input-wf-speedial content="'doc'" disabled="tabWorkflow.disabled"  ng-show="(true && !tabWorkflow.disabled)"></input-wf-speedial>
    </div>

    <div  ng-show="tabWorkflow.book.workflowBrief"  class="workflow">
        <h4 wraps-tooltip="docman-wf-input_briefapprovers">Document or Brief Workflow</h4>
        <input-wf-step ownerid="tabWorkflow.book.bookOwner" array="tabWorkflow.book.workflowBrief" disabled="tabWorkflow.disabled" dragular="tabWorkflow.briefOptions" dragular-model="tabWorkflow.book.workflowBrief" isvalid="tabWorkflow.validBrief"></input-wf-step>
        <input-wf-speedial content="'brief'" disabled="tabWorkflow.disabled" ng-show="(true && !tabWorkflow.disabled)"></input-wf-speedial>
    </div>

    <div  ng-show="tabWorkflow.book.workflowArtwork"  class="workflow">
        <h4 wraps-tooltip="docman-wf-input_artworkapprovers">
            Artwork Draft Workflow
            <md-switch style="display: inline-block; margin: -3px 10px;" ng-model="tabWorkflow.enableWorkflowArtwork"  aria-label="active" ng-change="tabWorkflow.checkArtwork()"  ng-disabled="tabWorkflow.disabled"></md-switch>
        </h4>
        <input-wf-step ownerid="tabWorkflow.book.bookOwner" array="tabWorkflow.book.workflowArtwork" disabled="tabWorkflow.disabled" ng-show="tabWorkflow.enableWorkflowArtwork" dragular="tabWorkflow.artworkOptions" dragular-model="tabWorkflow.book.workflowArtwork" isvalid="tabWorkflow.validArtwork"></input-wf-step>
        <input-wf-speedial content="'artwork'" disabled="tabWorkflow.disabled" ng-show="(tabWorkflow.enableWorkflowArtwork && !tabWorkflow.disabled)"></input-wf-speedial>
    </div>

    <div ng-show="tabWorkflow.book.workflowFinal"  class="workflow">
        <h4 wraps-tooltip="docman-wf-input_finalapprovers">Publishing Workflow</h4>
        <input-wf-step ownerid="tabWorkflow.book.bookOwner" array="tabWorkflow.book.workflowFinal" disabled="tabWorkflow.disabled" dragular="tabWorkflow.finalOptions" dragular-model="tabWorkflow.book.workflowFinal" isvalid="tabWorkflow.validFinal"></input-wf-step>
    </div>

</md-content>

