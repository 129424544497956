(function () {
  'use strict';
	/*--if you're looking for the wraps-tooltip wrapsTooltip directive, look in the tooltip-controller--*/
  /**
   * @ngdoc directive
   * @name library.directive:libraryContentRow
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="library">
       <file name="index.html">
        <library-content-row></library-content-row>
       </file>
     </example>
   *
   */
  /*angular
    .module('tooltip')
    .directive('helpMe', helpMe);

  function helpMe($compile) {
    return {
      scope: {
        helpMethod: '&'
      },
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.on('mouseenter', function () {
          //console.log('asd');
          scope.helpMethod({helpbutton: ''});
        });
        /!*element.on('mouseleave', function () {
        element.css('background-color', 'white');
       });*!/
      }
    };
  }*/

}());
