(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name profile.controller:ProfileCtrl
   *
   * @description
   *
   */
  angular
    .module('profile')
    .controller('ProfileCtrl', ProfileCtrl);

  function ProfileCtrl($interval, $localStorage, $location,  $q, $scope, $timeout, apiUrl, Locations, Roles, Teams, Upload, Users, Wraps) {
    var vm = this;
    vm.ctrlName = 'ProfileCtrl';
    $scope.$storage = $localStorage;

    delete $scope.$storage.userForm; // should delete legacy data

    //console.log($localStorage);
    if(!Users.isAuthenticated()){$location.path('/');}

    vm.apiUrl = apiUrl;

    //vm.initials = $scope.$storage.userForm.firstName.charAt(0).toUpperCase() + $scope.$storage.userForm.lastName.charAt(0).toUpperCase();

    Users.find({filter: {where: {id: $localStorage.user.id}}}).$promise.then(function (user) {
      vm.userForm = user[0];
		if(user[0].profilePic){
			vm.loadedavatar = {filename:user[0].profilePic.split('/').pop()}; //profile pic filename profilePic
		}
    });
    
    vm.roles = Roles.find({});
    vm.locations = Locations.__get__notDeleted();
    vm.teams = Teams.__get__notDeleted();

    vm.deleteProfilePic = function(user) {
		vm.userForm.profilePic = '';
		$scope.usersForm.$setDirty();
    }

    vm.saveRow = function (user) {
      vm.isLoading = true;
      user.lastUpdated = Date.now();
      Users.prototype$updateAttributes({id: user.id}, user, function(){
        $timeout(function () {
          $localStorage.user.firstName = user.firstName;
          $localStorage.user.lastName = user.lastName;
          $localStorage.user.jobTitle = user.jobTitle;
          $localStorage.user.email = user.email;
          $localStorage.user.contactEmail = user.contactEmail;
          $localStorage.user.tel = user.tel;
          $localStorage.user.location = user.location;
		  $localStorage.user.profilePic = user.profilePic;
          vm.initials = $localStorage.user.firstName.charAt(0) + $localStorage.user.lastName.charAt(0);
          vm.isLoading = false;
		  vm.saved = "success";
		  $scope.usersForm.$setPristine();
        }, 1000);
      }, function(err){console.error(err); vm.saved = "error"; $scope.usersForm.$setPristine();});
    };
	var possibleclasses = "abcdefghijklmnopq";
	//vm.avatarColor = possibleclasses.charAt(Math.floor(Math.random() * possibleclasses.length));

    var timestamp = new Date().getTime();
    $scope.$watch('profile.profilePic', function () {
      if(vm.profilePic) {
        vm.upload(vm.profilePic).then(function (statuses) {
          vm.isLoading = false;
        }).catch(function (error) {
          console.log("Error:", error);
          vm.isLoading = false;
        });
      }
    });
    
    vm.upload = function (file) {
      var S3_BUCKET_URL = apiUrl + '/containers/wraps-files/upload';
      var deferred = $q.defer();

      if (file && !file.$error) {
        vm.isLoading = true;

        file.container = 'wraps-files';
        file.fileLocation = 'profilePics/' + timestamp;
        file.fileName = file.name;
        file.fileSize = file.size;
        vm.profilePic.filesList = [];
        vm.profilePic.filesList.push(file);
        //console.log(vm.JSONFile);

        //TODO add timeout to animate files
        var newName = file.fileLocation + '/' + file.name;
        Upload.rename(file, newName );
        Upload.upload({
          url: S3_BUCKET_URL,
          file: file

        }).then(function (resp) {
          resp.config.file.fileName = resp.config.file.fileName.replace(/\.[^\.]+$/, '.jpg');
          var count = 0;
		  vm.thumbgen = true;
          var interval = $interval( function(){
              Wraps.isImage(apiUrl + '/containers/getthumbnail/' + resp.config.file.fileLocation + '/' + resp.config.file.fileName).then(function(rtn){
            	if (rtn === true){
                  $interval.cancel(interval);
                  //console.log(resp.config.file);
                  vm.userForm.profilePic = resp.config.file.fileLocation + '/' + resp.config.file.fileName;
                  vm.thumbgen = false;
                  deferred.resolve(resp);
            	}
            });
            if(count++ >= 10){ //waiting 10 seconds before finishing
              $interval.cancel(interval);
			  vm.thumbgen = false;
              deferred.reject("thumbnail timeout");
            }
          }, 1000);

          
        }, null, function (evt) {
          file.determinateValue = parseInt(100.0 *   evt.loaded / evt.total);
        });
      }else{
		  deferred.reject("upload image error");
	  }
      return deferred.promise;
    };



    vm.removeFile = function(array, index){
    	//console.log(index);
    	array.splice(index, 1);
		vm.userForm.profilePic = '';
    };
    
    
  };

}());
