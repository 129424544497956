<md-dialog aria-label="Add Brief"  class="addbrief">

    <md-toolbar>
        <div class="md-toolbar-tools">
            <h2 wraps-tooltip="briefbox-addbrief_form">Add Brief</h2>
            <span flex></span>

            <md-button ng-click="addbrief.dialogCancel(false)" class="md-icon-button" wraps-tooltip="briefbox-addbrief_formclose">
                <ng-md-icon  icon="close" style="fill: white;"  aria-label="Close dialog"></ng-md-icon>
            </md-button>
        </div>
    </md-toolbar>
    <md-dialog-content class="dialog-content big" style="padding-left: 20px;">

                <md-content class="md-padding">
                    <br />
                    <form ng-submit="$event.preventDefault()" name="addBriefForm" layout="column">

                        <div layout="row" layout-wrap>
                            <div flex="70" layout="row" layout-wrap>
                                <div flex="100">
                                    <md-input-container class="cke-container">
                                        <label>Brief description here...</label>
                                        <textarea  ng-model="addbrief.brief.description"  contenteditable="true" ckeditor="addbrief.options" required></textarea>
                                    </md-input-container>

                                    <div id="cktools" flex></div>
                                    <div id="ckfoot"></div>
                                </div>
                            </div>
                            <div flex="5"></div>
                            <div flex="25">
                                <div ngf-drop ngf-select ng-model="addbrief.files" class="drop-box"
                                     ngf-drag-over-class="'dragover'" ngf-multiple="true" ngf-allow-dir="true"
                                     accept="{{addbrief.safeFileList}}"
                                     ngf-pattern="addbrief.safeFileList"
                                     wraps-tooltip="briefbox-addbrief_upload">
                                    <ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop files here or<br /><strong style="text-decoration: underline;">Click to upload</strong>
                                </div>
                                <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>
                            </div>
                            <div flex="50" class="downloads-container" ng-if="addbrief.filesList.length > 0">
                                <h5>Uploads</h5>
                                <div ng-repeat="upload in addbrief.filesList" layout="row" layout-wrap layout-padding="20" class="download-row small">
                                    <a href="{{upload.ngfName|fileurl}}" target="_blank">
                                        <div class="thumbnail-block" wraps-tooltip="artwork-upload-thumb">
                                            <md-progress-circular ng-if="upload.isLoading"></md-progress-circular>
                                            <img ng-if="!upload.isLoading" class="thumbnail-preview" ng-src="{{upload.ngfName|thumburl}}" err-SRC="/images/wraps-logo.png" alt="{{upload.fileName}}"/>
                                            <div class="overlay text-center"><ng-md-icon icon="file_download" size="24"></ng-md-icon></div>
                                        </div>
                                    </a>
                                    <div flex>
                                        <h3 md-truncate>{{upload.fileName}}</h3>
                                        <h3 md-truncate>{{upload.fileSize | filesize}} <a class="md-accent" style="cursor:pointer" ng-click="addbrief.removeFile(addbrief.filesList, $index)">Remove</a></h3>
                                    </div>
                                    <div flex="100" class="divider"><div></div></div>
                                </div>
                            </div>
                        </div>

                    </form>
                </md-content>

    </md-dialog-content>
    <md-dialog-actions layout="row">

        <span flex></span>
        <md-button class="md-primary" ng-click="addbrief.dialogCancel(false)" wraps-tooltip="briefbox-addbrief_cancel">Cancel</md-button>
        <md-button  ng-disabled="(addbrief.isLoading || !addBriefForm.$valid)" class="md-raised md-accent" ng-click="addbrief.addNewBrief(addbrief)" wraps-tooltip="briefbox-addbrief_savebrief">Save Brief</md-button>

    </md-dialog-actions>
</md-dialog>

