<md-dialog aria-label="Content History" style="min-width:600px; overflow:hidden;"  ng-cloak>

    <md-toolbar>
        <div class="md-toolbar-tools">
            <h2>Content History</h2>
            <span flex></span>

            <md-button ng-click="historydialog.dialogCancel(false)" class="md-icon-button">
                <ng-md-icon  icon="close" style="fill: white;" aria-label="Close dialog"></ng-md-icon>
            </md-button>
        </div>
    </md-toolbar>
    <md-dialog-content class="dialog-content" style="">
        <div style="padding: 20px 20px;">
            <md-radio-group ng-model="historydialog.versions"  ng-repeat="history in historydialog.histories | orderBy:'-historyId' ">
                <md-radio-button ng-value="{{history.versions}}" class="md-primary" ng-if="!$first" aria-label="history select">
                    <get-contents item="history"></get-contents>


                    <small style="color: #bbbbbb;">{{history.lastUpdated | date:"dd/MM/yyyy HH:mm:ss"}}</small></md-radio-button>

           <!--{{history.versions}}-->
            </md-radio-group>
        </div>
    </md-dialog-content>
    <md-dialog-actions layout="row" ng-if="!historydialog.clickSource">
        <span flex></span>

        <md-button class="md-grey"  ng-click="historydialog.dialogCancel(false)">Cancel<ng-md-icon icon="close" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>
        <md-button ng-disabled="!historydialog.versions" class="md-green"  ng-click="historydialog.swapHistory(historydialog.versions)">Swap<ng-md-icon icon="swap_vert" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>

    </md-dialog-actions>
</md-dialog>