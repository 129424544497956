angular
    .module('library')
    .component('tabDates', {
        templateUrl: 'library/library-components/right-menu/tab-dates/tab-dates.tpl.html',
        controller: tabDatesCtrl,
        controllerAs:'tabDates',
        bindings:{
            book:'<',
            visible:'<',
            disabled:'<'
        }

    });

tabDatesCtrl.$inject = ['$scope'];
function tabDatesCtrl($scope){
    var vm = this;
    vm.today = moment().hours(0).minutes(0).seconds(0).toDate();
    var defaultLiveDate;

    vm.$onInit = function(){
        vm.timezones = moment.tz.names();
    }

    $scope.$watch('tabDates.visible',function(){

        vm.today = moment().hours(0).minutes(0).seconds(0).toDate();
        defaultLiveDate = moment().startOf('day').startOf('month').add(3, 'months').hours(0).toDate();

        if(vm.book && !vm.book.bookId && !vm.book.duplicateId){
        //new document set defaults
            vm.expiryDate = undefined;
            vm.book.liveDate = defaultLiveDate;
            vm.book.dates = {};
            vm.book.dates.previewPeriod = '0,months';
            vm.book.dates.reviewPeriod = '2,months';
            vm.book.dates.lifecyclePeriod = '12,months';
            vm.book.dates.timezone = moment.tz.guess();
            vm.changeLiveDate(vm.book);

        }else if(vm.book && !vm.book.dates){
        //if dates object is not present;
            vm.book.dates = {};
            vm.book.dates.timezone= 'Europe/London';

            vm.expiryDate = vm.book.expiryDate;
            if (vm.book.liveDate){
            //create new date object for full"

                vm.book.liveDate = moment(vm.book.liveDate).toDate();

                console.log("a",moment(vm.book.liveDate).diff(vm.book.activeDate,'months',true));
                console.log("b",moment(vm.book.expiryDate).subtract(moment(vm.book.liveDate).diff(vm.book.activeDate,'months',true), 'months').diff(vm.book.reviewDate,'months',true));


                vm.book.dates.previewPeriod = Math.round(moment(vm.book.liveDate).diff(vm.book.activeDate,'months',true)) + ',months';
                vm.book.dates.reviewPeriod = Math.round(moment(vm.book.expiryDate).subtract(Math.round(moment(vm.book.liveDate).diff(vm.book.activeDate,'months',true)), 'months').diff(vm.book.reviewDate,'months',true));
                if(vm.book.dates.reviewPeriod > 0){
                    vm.book.dates.reviewPeriod = vm.book.dates.reviewPeriod + ',months';
                }else{
                    vm.book.dates.reviewPeriod = '2,weeks';
                }
                vm.book.dates.lifecyclePeriod = moment(vm.book.expiryDate).startOf('day').add(1,'day').diff(moment(vm.book.liveDate).startOf('day'),'months',true);
                console.log("lifecyclePeriod", vm.book.dates.lifecyclePeriod);
                if(vm.book.dates.lifecyclePeriod == 3 || vm.book.dates.lifecyclePeriod == 6 || vm.book.dates.lifecyclePeriod == 9 || vm.book.dates.lifecyclePeriod == 12){
                    vm.book.dates.lifecyclePeriod = vm.book.dates.lifecyclePeriod + ',months';
                    //console.log("yep");
                }else{
                    vm.book.dates.lifecyclePeriod = "0,months";
                    //console.log("nope");
                }

                vm.changeLiveDate(vm.book);

            }else{
            //create new date object for live on approval
                console.log("c",moment(vm.book.expiryDate).diff(vm.book.reviewDate,'months',true));

                vm.book.liveOnApproval = true;
                vm.book.dates.previewPeriod = '0,months';
                vm.book.dates.reviewPeriod = Math.round(moment(vm.book.expiryDate).diff(vm.book.reviewDate,'months',true));
                if(vm.book.dates.reviewPeriod > 0){
                    vm.book.dates.reviewPeriod = vm.book.dates.reviewPeriod + ',months';
                }else{
                    vm.book.dates.reviewPeriod = '2,weeks';
                }
                vm.book.dates.lifecyclePeriod = "0,months";
                vm.goLiveOnApproval();

            }


        }else if(vm.book){
            vm.expiryDate = vm.book.expiryDate;
            if(typeof vm.book.dates == "string"){
                vm.book.dates = JSON.parse(vm.book.dates);
            }
            console.log("dates", vm.book.dates);
            if (vm.book.liveDate) {
                vm.book.liveOnApproval = false;
                vm.changeLiveDate(vm.book);
            }else{
                vm.book.liveOnApproval = true;
                vm.goLiveOnApproval();
            }
        }else{

        }
        //console.log(this,$scope, vm.book);

    });




    vm.checkRightMenuDates = function (book) {
        //book.activeDate
        //book.dates.previewPeriod
        //book.liveDate
        //book.liveOnApproval
        //book.reviewDate
        //book.beforeExpiry
        //book.expiryDate


        vm.reviewDateDescription = 'Expiry Date';

    }

    vm.changeActiveDate = function (book) {
        var selectVal = book.dates.previewPeriod.split(",");
        selectVal[0]=parseInt(selectVal[0]);
        book.activeDate = moment(book.liveDate).subtract(selectVal[0], selectVal[1]).toDate();
        if(selectVal[0] > 0){
            vm.reviewDateDescription = 'Next Active Date';
        }else{
            vm.reviewDateDescription = 'Expiry Date';
        }
        vm.changeActiveAnniversary(book);
    };
    vm.changeLiveDate = function(book){
        var dt = new Date();
        var tzMinutes = dt.getTimezoneOffset();

        console.log("Timezone Offset",tzMinutes);
        if(!vm.book.liveOnApproval){
            book.liveDate = moment(book.liveDate).subtract(tzMinutes,'minute').startOf('day').toDate();
        }
        vm.changeActiveDate(book);
        vm.changeExpiryDate(book);
    }


    vm.changeReviewDate = function (book) {
        var selectVal = book.dates.reviewPeriod.split(",");
        selectVal[0]=parseInt(selectVal[0]);
        if(book.dates.liveDateAnniversary){
            book.reviewDate = moment(book.dates.activeDateAnniversary).subtract(selectVal[0],selectVal[1]).toDate();
        }else{
            book.reviewDate = undefined;
            var timetoexpiry = book.dates.lifecyclePeriod.split(',');
            timetoexpiry = parseInt(timetoexpiry[0]);
            if (selectVal[1] == 'weeks'){
                selectVal[0] = selectVal[0] / 4;
            }
            book.dates.timeToReview = (timetoexpiry - selectVal[0]) + ',months';
        }
        vm.checkValidDates();
    };
    vm.checkValidDates = function(){
        if (
            // (vm.book.activeDate && moment(vm.book.activeDate).isBefore(vm.today, 'day'))    ||       //active date is before today
            (!vm.book.liveOnApproval && (!vm.book.liveDate || !vm.book.activeDate)) ||      //it's not live on approval but live date and active date are blank
            // (vm.book.liveDate && moment(vm.book.liveDate).isBefore(vm.today,'day')) ||            //live date is before today
            // (!vm.book.liveOnApproval && vm.book.expiryDate && !vm.book.reviewDate) ||               //review date is blank
            (moment(vm.book.reviewDate).isBefore(vm.today,'hour')) ||                              //review date is before today
            (vm.book.liveDate && moment(vm.book.reviewDate).isBefore(vm.book.liveDate,'hour')) ||  //review date is before Live date
            //(!vm.book.expiryDate) ||                                                        //expiry date is blank
            (moment(vm.book.expiryDate).isBefore(vm.today)) ||                                //expiry date is before today
            (vm.book.dates.reviewPeriod != '3,months' && vm.book.dates.reviewPeriod != '2,months' && vm.book.dates.reviewPeriod != '1,months' && vm.book.dates.reviewPeriod != '2,weeks') //review Period is non standard

        ){
            vm.book.dates.isValid = false;
        }else{
            vm.book.dates.isValid = true;
        }
    };

    vm.changeExpiryDate = function(book){

        var dt = new Date();
        var tzMinutes = dt.getTimezoneOffset();

        console.log("Timezone Offset",tzMinutes);

        var selectVal = book.dates.lifecyclePeriod.split(",");
        selectVal[0]=parseInt(selectVal[0]);

        if (selectVal[0] > 0) {
            if (book.liveDate){
                book.expiryDate = moment(book.liveDate).subtract(tzMinutes,'minute').startOf('day').subtract(1, 'minute').add(selectVal[0], selectVal[1]).toDate();
                book.dates.liveDateAnniversary = moment(book.expiryDate).add(1,'minute').toDate();
            }else{
                book.expiryDate = undefined;
                book.dates.liveDateAnniversary = undefined;
            }
            vm.expiryDate = undefined;
        } else {

            console.log("vm.expiryDate",vm.expiryDate);
            console.log("book.expiryDate",book.expiryDate);

            if(vm.book.liveOnApproval && !vm.expiryDate) {

                vm.expiryDate = moment().startOf('day').endOf('month').add(12, 'months').hours(23).minutes(59).seconds(0).toDate();

            }else if(!vm.expiryDate){

                vm.expiryDate  = moment(book.liveDate).startOf('day').subtract(1, 'minute').add(12, 'months').toDate();
            }


            book.expiryDate = moment(vm.expiryDate).hours(23).minutes(59).seconds(0).toDate();

            book.dates.liveDateAnniversary = moment(book.expiryDate).add(1,'minute').toDate();
        }

        vm.changeActiveAnniversary(book);
    }
    vm.changeActiveAnniversary = function(book){
        var selectVal = book.dates.previewPeriod.split(",");
        selectVal[0]=parseInt(selectVal[0]);
        if(book.dates.liveDateAnniversary) {
            book.dates.activeDateAnniversary = moment(book.dates.liveDateAnniversary).subtract(selectVal[0], selectVal[1]).toDate();
        }else{
            book.dates.activeDateAnniversary = undefined;
        }
        vm.changeReviewDate(book);
    }

    vm.goLiveOnApproval = function(){
        if(vm.book.liveOnApproval) {

            vm.book.dates.previewPeriod = '0,months';
            vm.book.liveDate = undefined;
            vm.book.activeDate = undefined;
            if (vm.book.dates.lifecyclePeriod != '0,months'){
                vm.book.expiryDate = undefined;
                vm.book.dates.liveDateAnniversary = undefined;
                vm.book.reviewDate = undefined;
            }
            vm.reviewDateDescription = 'Expiry Date';
            vm.changeExpiryDate(vm.book);

        }else{
            vm.book.dates.previewPeriod = '2,months';
            vm.book.liveDate = defaultLiveDate;
            vm.changeLiveDate(vm.book);
        }
    }

}