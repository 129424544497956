


(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.addartwork.controller:AddartworkCtrl
   *
   * @description
   *
   */
  angular
      .module('library.addartwork')
      .controller('AddartworkCtrl', AddartworkCtrl);

  function AddartworkCtrl($filter, $interval, $localStorage, $mdDialog, $q, $scope, $timeout, apiUrl, book, Books, Containers, Contents, Events, History, Structures, Teams, type, Upload, Users, Versions, Wraps, WrapsBooks) {
    var vm = this;
    vm.ctrlName = 'AddartworkCtrl';
    $scope.$storage = $localStorage;
    if(!Users.isAuthenticated()){$location.path('/');}



    //Teams for add content owners
    vm.teams = Teams.__get__notDeleted();


    vm.safeFileList = Wraps.safeFileList;

    
    vm.bookCode = book.bookCode;

    vm.dialogHide = function(data) {
      $mdDialog.hide(data);
    };
    vm.dialogCancel = function(data) {
      $mdDialog.cancel(data);
    };

    vm.removeFile = function(array, index){
      array.splice(index, 1);
    }

    
    vm.filenameInCodeCheck = WrapsBooks.filenameInCodeCheck;

    if(type=='artwork'){
      var thisContentTypeId = 10;
      var thisContentName = "Artwork";
      var thisItemType = "artwork";
      
    }else if(type=='final'){
      var thisContentTypeId = 13;
      var thisContentName = "Final";
      var thisItemType = "final";
    }

    vm.addNewArtwork = function (artworkData){
      vm.isLoading = true;
      var artwork ={};
      artwork.artwork = artworkData.artwork;
      artwork.artwork.filesList = artworkData.filesList;
      
      //console.log("data",artworkData.fileList);
      var owner = 20; //design -  was artworkData.artworkTeam.teamId
      Contents.create({contentId: 0, contentTypeId: thisContentTypeId, contentName: thisContentName, contentOwner: owner}, function (data1) {

        Versions.create({versionId: 0, contentId: data1.contentId, versiondata: Wraps.toJson(artwork)}, function (data2) {

          Structures.create({structureId: 0, versionId: data2.versionId, contentId: data1.contentId, bookId: book.bookId, "status": 0, "approvalStatus": book.approvalStatus , parent: null, order: data2.versionId}, function (data3) {

            Books.upsert({ "bookId": book.bookId,  "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
            History.create({
              "structureId": data3.structureId,
              "versionIdOld": 0,
              "versionIdNew": data3.versionId,
              "historyId": 0
            },function(historyResponse){
              Events.create({
                "eventId": 0,
                "bookId": book.bookId,
                "structureId": data3.structureId,
                "contentId": data1.contentId,
                "historyId": historyResponse.historyId,
                "userId": $scope.$storage.user.id,
                "eventType": "Added",
                "eventData": thisContentName + " added"
              });
            });

            vm.dialogHide(true);
            //vm.isLoading = false;

          });
        });
      });

    }

    var timestamp = new Date().getTime();
    //var S3_BUCKET_URL = apiUrl+'/containers/wraps-files%2fartwork%2f'+timestamp+'/upload';
    var S3_BUCKET_URL = apiUrl+'/Containers/wraps-files/upload';
    var itemType = thisItemType;
    $scope.$watch('addartwork.files', function () {

      vm.upload(vm.files).then(function(statuses){
        vm.isLoading = false;
      });
    });
    vm.log = '';
    vm.filesList = [];
    vm.filesNames = [];
    vm.upload = function (files) {

      var promises = {};

      if (files && files.length) {
        _.forEach(files, function (file) {
          if (!file.$error) {
            vm.isLoading = true;
            var thisFileDefer = $q.defer();
            promises[file.name] = thisFileDefer.promise;

            file.container = 'wraps-files';
            file.fileLocation = itemType+'/'+timestamp;
            file.fileName = file.name;
            file.fileSize = file.size;
            vm.filesList.push(file);
            file.isLoading = true;


            //TODO add timeout to animate files

            var newName = file.fileLocation + '/' + file.name;
            Upload.rename(file, newName );
            Upload.upload({
              url: S3_BUCKET_URL,
              file: file}).then(function (resp) {
                var count = 0;
                var interval = $interval( function(){
                    Wraps.isImage($filter('thumburl')(file.ngfName)).then(function(rtn){
                        if (rtn === true){
                            $interval.cancel(interval);
                            //console.log(resp.config.file);
                            file.isLoading = false;
                            thisFileDefer.resolve(resp);
                        }
                    });
                    if(count++ >= 10){ //waiting 10 seconds before finishing
                        $interval.cancel(interval);
                        file.isLoading = false;
                        thisFileDefer.resolve('no thumbnail');
                    }
                }, 1000);
            }, null, function (evt) {
              file.determinateValue = parseInt(100.0 *   evt.loaded / evt.total);

            });
          }
        });
      }
      return $q.all(promises);
    };



    vm.options = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
        fillEmptyBlocks:true,
      forcePasteAsPlainText: false,
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      removeButtons: 'Subscript,Superscript',
      extraPlugins: 'sharedspace',
      sharedSpaces: { top: 'cktools',bottom: 'ckfoot'},
      toolbar: [
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
        { name: 'paragraph', items: [  'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',] },
        { name: 'format', items: [  'RemoveFormat' ] }
      ]
    };





  }
}());

