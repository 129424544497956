(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableInsurer', readableInsurer);

  function readableInsurer(Wraps) {
    return function (id) {
      var insurer;
      if ((insurer = _.find(Wraps.insurers, {insurerId: parseInt(id)})) && insurer.insurerName) {
        return insurer.insurerName;
      }
      return null;
    };
  }
}());
