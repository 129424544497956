(function () {
  'use strict';

  angular
    .module('library.addbrief')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('addbrief', {
        url: '/addbrief',
        templateUrl: 'library/addbrief/addbrief.tpl.html',
        controller: 'AddbriefCtrl',
        controllerAs: 'addbrief'
      });
  }
}());
