<form ng-submit="$event.preventDefault()" name="addComments.commentForm" layout="column">

    <div layout="row" layout-padding class="comment-container self-comment">
        <div aria-label="{{$storage.user.firstName}} {{$storage.user.lastName}}" class="avatar-container" wraps-tooltip="comment-user-avatar">
            <img ng-if="$storage.user.profilePic != '' && $storage.user.profilePic != null" ng-src="{{addComments.apiUrl}}/containers/getthumbnail/{{$storage.user.profilePic}}" class="user-avatar" alt="User Avatar"/>
            <div ng-if="$storage.user.profilePic == '' || $storage.user.profilePic == null" class="user-avatar initials {{$storage.user.lastName|lowercase|limitTo:1}}">{{$storage.user.firstName|uppercase|limitTo:1}}{{$storage.user.lastName|uppercase|limitTo:1}}</div>

        </div>
        <div flex layout="row" layout-wrap class="comment-text" wraps-tooltip="comments-new-message">
            <span class="md-body-2" flex="100">{{event.eventName}}</span><br/>
            <span class="md-caption" flex="50">{{event.Users.firstName}} {{event.Users.lastName}}</span><span class="md-caption timestamp" flex="50">{{event.created | date:'dd MMM yyyy HH:mm'}}</span>
            <md-input-container flex="100" class="comment-bubble">
                <label>Comment message</label>
                <textarea name="message" ng-model="addComments.comment.message" required ng-focus="addComments.showForm = true" ng-blur="addComments.showForm = false"></textarea>
            </md-input-container>
            <div class="errors" ng-messages="addComments.commentForm.message.$error" ng-if="addComments.commentForm.message.$touched">
                <div ng-message="required">Required</div>
            </div>


        </div>
        <div style="width:48px; text-align:center">
            <md-button class="md-icon-button" ng-if="addComments.commentForm.$dirty" ng-click="addComments.commentForm.$setPristine(); addComments.commentForm.$setUntouched(); addComments.comment.message = null" aria-label="cancel">
                <ng-md-icon style="fill:#ccc;" icon="close" size="24"></ng-md-icon>
            </md-button>
        </div>
    </div>
    <div layout="row" layout-wrap layout-padding class="add-comments animate-if" ng-if="addComments.commentForm.$dirty">
        <div flex="100" class="legend">
            <md-checkbox aria-label="Notify all users in workflow" wraps-tooltip="comments-all-contacts" ng-checked="addComments.isChecked()"  md-indeterminate="addComments.isIndeterminate()" ng-click="addComments.toggleAll()">
                <span class="md-subhead">Notify all users in workflow</span>
            </md-checkbox>
        </div>
        <div class="demo-select-all-checkboxes" flex="50" flex-gt-sm="33" ng-repeat="contact in addComments.allContacts " >
            <md-checkbox ng-checked="addComments.exists(contact, addComments.selected )" ng-click="addComments.toggle(contact, addComments.selected)">
                <strong><small>{{contact.name}}</small></strong><br/>
                <small>{{contact.email}}</small>
            </md-checkbox>
        </div>
    </div>
    <div layout="row" layout-wrap layout-padding class="animate-if" ng-if="addComments.commentForm.$dirty">
        <div class="demo-select-all-checkboxes" flex="100" flex-gt-sm="33" ng-repeat="contact in addComments.defaultContacts " >
            <md-checkbox style="margin:0px;" wraps-tooltip="comments-support-contact" ng-checked="addComments.exists(contact, addComments.selected )" ng-click="addComments.toggle(contact, addComments.selected)">
                <strong><small>{{contact.name}}</small></strong><br/>
                <small>{{contact.email}}</small>
            </md-checkbox>
            <md-tooltip>
                The Wraps team can help you with
                <ul>
                    <li>Meta-data creation & document coding</li>
                    <li>What should go into your brief section</li>
                    <li>Who should be on your workflows for approval</li>
                    <li>All technical and functional support needed</li>
                    <li>Reporting any unusual behaviour & faults.</li>
                </ul>
            </md-tooltip>
        </div>
    </div>
    <div layout="row" layout-wrap layout-padding class="animate-if" ng-if="addComments.commentForm.$dirty">
        <div flex="100">
            <md-contact-chips
                    ng-model="addComments.additionalRecipients"
                    md-contacts="addComments.querySearch($query)"
                    md-contact-name="name"
                    md-contact-email="email"
                    md-require-match="true"
                    md-separator-keys="addComments.keys"
                    md-highlight-flags="i"
                    placeholder="Other Wraps user"
                    secondary-placeholder="Add another recipient"
                    input-aria-label="Recipient not in workflow">
            </md-contact-chips>
        </div>
    </div>
    <div layout="row" layout-wrap layout-padding layout-align="end start" class="animate-if" ng-if="addComments.commentForm.$dirty">
        <div>
            <md-button class="md-raised md-primary" ng-hide="addComments.commentForm.$pristine" ng-disabled="addComments.commentForm.$invalid || addComments.commentForm.$pristine" ng-click="addComments.showForm=false; addComments.saveComments(addComments.book,child,addComments.comment, addComments.mergeSelected()); " aria-label="send" style="position:relative; top:-8px; margin:0px">
                Send
            </md-button>
        </div>
    </div>
</form>
