<md-dialog aria-label="Push Changes"  class="pushdialog">

    <md-toolbar>
        <div class="md-toolbar-tools">
            <h2>Push Changes</h2>
            <span flex></span>

            <md-button ng-click="pushdialog.dialogCancel(false)" class="md-icon-button">
                <ng-md-icon  icon="close" style="fill: white;" size="24"  aria-label="Close dialog"></ng-md-icon>
            </md-button>
        </div>
    </md-toolbar>
    <md-dialog-content class="dialog-content" style="">

        <md-content class="md-padding">


            <div ng-show="::pushdialog.contentlist">
                <h3>Select books to push changes to:</h3>

                <md-input-container  style="margin:20px;">
                    <label><ng-md-icon icon="search" style="fill: #ccc; position: relative; padding-left: 2px; vertical-align:middle" size="20" wraps-tooltip="search-button"></ng-md-icon>  Filter book name</label>
                    <input ng-model="pushdialog.filter.Books.bookCode" aria-label="Filter book name">
                </md-input-container>

                <div ng-repeat="content in pushdialog.contentlist | filter: pushdialog.filter | orderBy: 'Books.bookId'" ng-if="content.structureId != pushdialog.activeContent.structureId" ng-init="content.selected = (content.versionId === pushdialog.activeContent.versionId ? content.structureId : false )">
                    <md-checkbox style="margin: 10px;" ng-model="content.selected" ng-true-value="{{::content.structureId}}"  ng-false-value="{{false}}"  ng-disabled="content.versionId === pushdialog.activeContent.versionId || (content.Books.currentWorkflowStatus.role != 1 && content.Books.currentWorkflowStatus.role != 2) "  ng-change="pushdialog.setSelected(content)" >
                        <span>{{::content.Books.bookCode}} - <small>{{content.Books.workflowStatus}} - {{content.Books.documentStatus}}</small></span>

                        <get-contents item="content"></get-contents>



                    </md-checkbox>
                    <md-divider></md-divider>
                </div>
            </div>

            <md-progress-circular   md-diameter="40" md-mode="indeterminate" ng-show="pushdialog.isLoading"></md-progress-circular>

        </md-content>





    </md-dialog-content>
    <md-dialog-actions layout="row">
        <span flex></span>

        <md-button class="md-primary" ng-click="pushdialog.dialogCancel(false)">Cancel</md-button>

        <md-button  class="md-primary md-raised" ng-disabled="(pushdialog.isLoading || pushdialog.selected.length===0)" ng-click="pushdialog.pushContent(pushdialog.selected)">Push</md-button>

    </md-dialog-actions>
</md-dialog>