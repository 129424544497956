<md-progress-linear ng-if="tabBook.isloading" md-mode="indeterminate"></md-progress-linear>
<md-content ng-if="!tabBook.isloading && !tabBook.book.docTypeId" class="details-row">
    <div class="greyBarWrapper" layout="row" >
        <h3 flex>Unable to display book</h3>
        <md-button aria-label="Close" ng-click="tabBook.library.closeTab(tabBook.opentab.bookId)" class="md-icon-button" wraps-tooltip="jobbox-close-icon">
            <ng-md-icon icon="close" style="fill:grey"></ng-md-icon>
        </md-button>
    </div>
    <div style="padding:10px 20px 40px; text-align:left;" class="library-data">
        <h3 ng-if="!tabBook.book.permissionDenied" >No job with that Id could be found</h3>
        <h3 ng-if="tabBook.book.permissionDenied" >Sorry, either you do not have permission to view this job or it does not exist</h3>
    </div>
</md-content>
<md-content id="anchorBook{{tabBook.book.bookId}}" class="details-row" ng-if="tabBook.book.docTypeId">
    <div layout="column">
                    <!--inline style to be replaced with css class-->
                    <div class="greyBarWrapper" layout="row" layout-align="start center" ng-class="{ 'complianceSelect': tabBook.book.complianceSelect}" >
                        <h3 flex md-truncate>{{ tabBook.book.bookCode }}</h3>
                        <!--refresh book button-->
                        <md-button style="margin:4px 8px;" ng-if="tabBook.library.hasPermissions('refreshDocumentTemp')" ng-click="tabBook.refresh()" class="margin:4px 8px;" ng-class="{'md-icon-button':!tabBook.opentab.updateRequired, 'md-fab':tabBook.opentab.updateRequired, 'md-mini':tabBook.opentab.updateRequired, 'md-accent':tabBook.opentab.updateRequired}" aria-label="Refresh book" wraps-tooltip="jobbox-refresh-icon">
                            <ng-md-icon  icon="refresh" size="24" md-colors="{fill:tabBook.opentab.updateRequired?'grey-50':'grey-600'}"></ng-md-icon>
                        </md-button>
                        <!--toggle search button-->
                        <md-button  aria-label="toggle search" ng-if="tabBook.book.DocTypes.docTypeTemplate === 1" ng-click="tabBook.openSearch = !tabBook.openSearch"  class="md-icon-button" ng-class="{'md-focused':tabBook.openSearch, 'highlight':!(tabBook.openSearch) && tabBook.hasLibraryTextSearch()}" wraps-tooltip="jobbox-icon-search">
                            <ng-md-icon  icon="search" size="24" md-colors="{fill:!tabBook.openSearch && tabBook.hasLibraryTextSearch()?'grey-50':'grey-600'}"></ng-md-icon>
                        </md-button>
                        <!--view comments button-->
                        <md-button  aria-label="All Comments" ng-click="tabBook.library.openLeftComments(tabBook.book); $event.stopPropagation()"  class="md-icon-button " wraps-tooltip="jobbox-comments-icon">
                            <ng-md-icon  icon="comment" size="24" md-colors="{fill:'grey-600'}"></ng-md-icon>
                        </md-button>
                        <!--open book meta-data panel-->
                        <md-button ng-disabled="tabBook.library.isLoading || tabBook.book.isLoading" aria-label="Book info" ng-click="tabBook.library.openRightMenu(tabBook.book)" class="md-icon-button" wraps-tooltip="jobbox-menu_docman" style="opacity: 0.6;">
                            <ng-md-icon icon="info" size="24"></ng-md-icon>
                        </md-button>
                        <!--book menu-->
                        <div ng-if="tabBook.library.hasPermissions('duplicateDocuments')||
                        (tabBook.library.insertAccessChildren(tabBook.book) && tabBook.book.DocTypes.docTypeTemplate === 1) ||
                        (tabBook.library.isDLO(tabBook.book) && tabBook.book.approvalList[tabBook.book.approvalStatus].role === 1 && tabBook.book.approvalStatus < 2) ||
                        tabBook.library.submitForApprovalVisibility(tabBook.book) ||
                        tabBook.library.viewResetToBrief(tabBook.book) ||
                        tabBook.library.viewResetToMCO(tabBook.book) ||
                        (tabBook.library.showApproveAll(tabBook.book) && !tabBook.library.viewMakeBookLive(tabBook.book)) ||
                        tabBook.library.showWithdraw(tabBook.book) ||
                        tabBook.library.viewEarlyExpire(tabBook.book) ||
                        tabBook.library.isMemUploadable(tabBook.book) ||
                        tabBook.library.isSwapTeamUser(tabBook.book)
">
                            <div class="menu-container" layout-align="center right" layout="column">

                                <md-menu md-position-mode="target-right target"  md-offset="-10 10">
                                    <!-- book menu trigger-->
                                    <md-button aria-label="Open tools menu" class="md-icon-button " ng-click="$mdMenu.open();$event.stopPropagation()" wraps-tooltip="jobbox-menu-icon">
                                        <ng-md-icon icon="more_vert" size="24" md-colors="{fill:'grey-600'}"></ng-md-icon>
                                    </md-button>

                                    <!--book menu content-->
                                    <md-menu-content>
                                        <md-menu-item >
                                            <!-- duplicate book-->
                                            <md-button aria-label="Duplicate book" ng-click="tabBook.library.duplicateBookDialog(tabBook.book)" ng-if="tabBook.library.hasPermissions('duplicateDocuments')" wraps-tooltip="jobbox-menu_duplicate-record">
                                                <div layout="row">
                                                    <p flex>Duplicate Document</p>
                                                    <ng-md-icon icon="call_split" style="fill: grey; padding-left:8px; position:relative; top:0px;" size="20"></ng-md-icon>
                                                </div>
                                            </md-button>
                                        </md-menu-item>
                                        <md-menu-item ng-if="tabBook.library.insertAccessChildren(tabBook.book) && tabBook.book.DocTypes.docTypeTemplate === 1" wraps-tooltip="jobbox-menu_addchapter">
                                            <!--add chapter-->
                                            <md-button  ng-click="tabBook.library.openChapterDialog(tabBook.book)" aria-label="Add Chapter">
                                                <div layout="row">
                                                    <p flex>Add Chapter</p>
                                                    <ng-md-icon icon="playlist_add" class="threeDotBookMenuPositioning" size="20"></ng-md-icon>
                                                </div>
                                            </md-button>
                                        </md-menu-item>
                                        <md-menu-item ng-if="(tabBook.book.documentStatus.substring(0, 4) != 'EXPD' && tabBook.library.isDLO(tabBook.book) && tabBook.book.approvalList[tabBook.book.approvalStatus].role === 1 && tabBook.book.approvalStatus < 2)" >
                                            <!--submit to content owner-->
                                            <md-button  ng-click="tabBook.library.changeStatusNextDialog(tabBook.book)" aria-label="Edit Status" wraps-tooltip="jobbox-menu_submitmco">
                                                <div layout="row">
                                                    <p flex>Submit to MCO </p>

                                                    <ng-md-icon icon="forward" class="threeDotBookMenuPositioning" size="20"></ng-md-icon>
                                                </div>
                                            </md-button>
                                        </md-menu-item>
                                        <md-menu-item ng-if="tabBook.library.submitForApprovalVisibility(tabBook.book);">
                                            <!--submit for approval-->
                                            <md-button  ng-click="tabBook.library.changeStatusNextDialog(tabBook.book)" aria-label="Edit Status" wraps-tooltip="jobbox-menu_submitapp">
                                                <div layout="row">
                                                    <p flex>Submit for Approval</p>

                                                    <ng-md-icon icon="forward"class="threeDotBookMenuPositioning" size="20"></ng-md-icon>
                                                </div>
                                            </md-button>
                                        </md-menu-item>

                                        <md-menu-item  ng-if="tabBook.library.viewResetToBrief(tabBook.book)">
                                            <!--reset to brief-->
                                            <md-button  ng-click="tabBook.library.resetToBriefDialog(tabBook.book)" aria-label="Reset to Brief" wraps-tooltip="jobbox-menu_resettobrief">
                                                <div layout="row">
                                                    <p flex>Reset to Brief</p>

                                                    <ng-md-icon icon="skip_previous" class="threeDotBookMenuPositioning" size="20"></ng-md-icon>
                                                </div>
                                            </md-button>
                                        </md-menu-item>

                                        <md-menu-item  ng-if="tabBook.library.viewResetToMCO(tabBook.book)">
                                            <!--reset to content owner-->
                                            <md-button  ng-click="tabBook.library.resetToMCODialog(tabBook.book)" aria-label="Reset to MCO" wraps-tooltip="jobbox-menu_resettomco">
                                                <div layout="row">
                                                    <p flex>Reset to MCO</p>

                                                    <ng-md-icon icon="skip_previous" class="threeDotBookMenuPositioning" size="20"></ng-md-icon>
                                                </div>
                                            </md-button>
                                        </md-menu-item>

                                        <!--approve all content-->
                                        <md-menu-item  ng-if="tabBook.library.showApproveAll(tabBook.book) && !tabBook.library.viewMakeBookLive(tabBook.book)">
                                            <md-button  ng-click="tabBook.library.approveContentDialog('all',tabBook.book);" aria-label="Make Book Live" wraps-tooltip="jobbox-menu_approveall">
                                                <div layout="row">
                                                    <p flex>Approve All Content</p>

                                                    <ng-md-icon icon="check_circle" class="threeDotBookMenuPositioning" size="20"></ng-md-icon>
                                                </div>
                                            </md-button>
                                        </md-menu-item>
                                        <!--withdraw book-->
                                        <md-menu-item  ng-if="tabBook.library.showWithdraw(tabBook.book)">
                                            <md-button  ng-click="tabBook.library.withdraw(tabBook.book);" aria-label="Widthdraw" wraps-tooltip="jobbox-menu_withdraw">
                                                <div layout="row">
                                                    <p flex>Withdraw</p>

                                                    <ng-md-icon icon="archive" class="threeDotBookMenuPositioning" size="20"></ng-md-icon>
                                                </div>
                                            </md-button>
                                        </md-menu-item>


                                        <!--Request deletion of this book - MCO only - request sends to Document lifecycle owner-->
                                        <!--hidden until 4.0 or removal-->
                                        <!--<md-menu-item ng-if="tabBook.library.showDLORequestDelete(tabBook.book)">-->
                                        <!--<md-button  ng-click="tabBook.library.requestDelete('book', tabBook.book.bookId, '', '');" aria-label="Request Delete">-->
                                        <!--<div layout="row">-->
                                        <!--<p flex>Request Delete</p>-->
                                        <!--<ng-md-icon icon="delete_forever" class="threeDotBookMenuPositioning" size="20"></ng-md-icon>-->
                                        <!--</div>-->
                                        <!--</md-button>-->
                                        <!--</md-menu-item>-->

                                        <!--Delete the book - DLO only & when MCO has requested-->
                                        <!--hidden until 4.0 or removal-->
                                        <!--<md-menu-item ng-if="tabBook.library.showDLODeleteBook(tabBook.book)">-->
                                        <!--<md-button  ng-click="tabBook.library.DLODelete(tabBook.book);" aria-label="Delete">-->
                                        <!--<div layout="row">-->
                                        <!--<p flex>DLO Delete</p>-->
                                        <!--<ng-md-icon icon="delete_forever" class="threeDotBookMenuPositioning" size="20"></ng-md-icon>-->
                                        <!--</div>-->
                                        <!--</md-button>-->
                                        <!--</md-menu-item>-->

                                        <!-- Early Expire Document-->
                                        <md-menu-item ng-if="tabBook.library.viewEarlyExpire(tabBook.book)" >
                                            <md-button ng-click="tabBook.library.earlyExpireDialog(tabBook.book)" wraps-tooltip="jobbox-menu_earlyexpire">
                                                <div layout="row">
                                                    <p flex>Early Expire Document</p>
                                                    <ng-md-icon icon="pan_tool" class="threeDotBookMenuPositioning" size="16"></ng-md-icon>
                                                </div>
                                            </md-button>
                                        </md-menu-item>

                                        <!-- upload to finals/publishing - this is a temp fix only seen by admin-->
                                        <md-menu-item ng-if="tabBook.library.isMemUploadable(tabBook.book)" >
                                          <md-button ng-click="tabBook.library.uploadPDF(tabBook.book)" wraps-tooltip="jobbox-menu_uploadtopublishing">
                                              <div layout="row">
                                                  <p flex>Upload to Publishing</p>
                                                  <ng-md-icon icon="file_upload" class="threeDotBookMenuPositioning" size="16"></ng-md-icon>
                                              </div>
                                          </md-button>
                                        </md-menu-item>

                                        <!-- check workflow - this is a temp fix only seen by admin-->
                                        <md-menu-item ng-if="tabBook.library.isRepairWorkflow(tabBook.book)" >
                                            <md-button ng-click="tabBook.library.repairWorkflow(tabBook.book)" wraps-tooltip="jobbox-menu_repairworkflow">
                                                <div layout="row">
                                                    <p flex>Check and Repair Workflow</p>
                                                    <ng-md-icon icon="build" class="threeDotBookMenuPositioning" size="16"></ng-md-icon>
                                                </div>
                                            </md-button>
                                        </md-menu-item>


                                        <!-- Swap Team User, currently testing for design and final files -->
                                        <md-menu-item ng-if="tabBook.library.isSwapTeamUser(tabBook.book)" >
                                            <md-button ng-click="tabBook.library.swapTeamUserDialog(tabBook.book)">
                                                <div layout="row">
                                                    <p flex>Swap User in Team</p>
                                                    <ng-md-icon icon="people" class="threeDotBookMenuPositioning" size="16"></ng-md-icon>
                                                </div>
                                            </md-button>
                                        </md-menu-item>

                                    </md-menu-content>
                                </md-menu>

                            </div>
                        </div>
                        <md-button aria-label="Close" ng-click="tabBook.library.closeTab(tabBook.book.bookId)" class="md-icon-button" wraps-tooltip="jobbox-close-icon">
                            <ng-md-icon icon="close" md-colors="{fill:'grey-600'}"></ng-md-icon>
                        </md-button>

                    </div>
                    <div class="booksearchbar" layout="row" layout-align="start center" ng-show="tabBook.openSearch" ng-animate>
                        <form name="searchForm">
                            <md-input-container style="min-width:50%;width:480px;max-width:90%;" wraps-tooltip="doc-search-full-text" >
                                <label>Full text search</label>
                                <input ng-model="tabBook.searchBooksText" ng-model-options="{debounce:1000}"  md-select-on-focus minlength="4" name="textSearch" id="textSearch" aria-label="Search book content" md-placeholder="Full text search" ng-change="tabBook.getSearchByBook()">
                                <div ng-messages="searchForm.textSearch.$error">
                                    <div ng-message="minlength">Keyword(s) must be 4 characters or longer</div>
                                </div>
                            </md-input-container>
                        </form>
                    </div>


                    <md-progress-linear ng-if="tabBook.book.isLoading" md-mode="indeterminate" style="position: absolute;"></md-progress-linear>
                    <div  ng-if="!tabBook.book.isNew" style="padding:10px 20px 40px; text-align:left;" class="library-data" id="book-info-{{tabBook.book.bookId}}" ng-init="tabBook.library.showApproveToast(tabBook.book,'book-info-{{tabBook.book.bookId}}')"  >

                        <!--{{ tabBook.book.approvalList[tabBook.book.approvalStatus]}}-->

                        <div layout="row" layout-wrap>





                            <div layout="row" layout-wrap flex="100" class="book-info" style="    margin-bottom: 10px;">
                                <div flex="100">
                                    <h3><span style="font-weight: 700 !important;">{{ tabBook.book.bookName }}</span><br />
                                        Code: <span style="font-weight: 500 !important;" ng-class="{'code-change-error': (tabBook.book.bookCodeOld != 'disableAutoCode'  && tabBook.book.bookCodeOld.length > 0 && tabBook.book.bookCodeOld != tabBook.book.bookCode)}">

                                                    <md-tooltip ng-if="(tabBook.book.bookCodeOld != 'disableAutoCode'  && tabBook.book.bookCodeOld.length > 0 && tabBook.book.bookCodeOld != tabBook.book.bookCode)" class="statbar"  md-direction="top">
                                                        This filename has changed since creation ({{ tabBook.book.bookCodeOld }})
                                                    </md-tooltip>

                                                    {{ tabBook.book.bookCode }}

                                                </span><br />

                                        <!-- <span ng-if="(tabBook.book.bookCodeOld != 'disableAutoCode'  && tabBook.book.bookCodeOld.length > 0 && tabBook.book.bookCodeOld != tabBook.book.bookCode)">Initial Code: <span style="font-weight: 500 !important;">{{ tabBook.book.bookCodeOld }}</span><br /></span> -->

                                        Owner: <span style="font-weight: 500 !important;">{{tabBook.book.Owner.fullName}}</span><br />
                                        ID: <span style="font-weight: 500 !important;">{{tabBook.book.bookIdLong}}</span></h3>
                                </div>
                                <div flex="25">
                                    <h4>Division: <b>{{ tabBook.book.Divisions.division }}</b></h4>
                                    <h4 ng-if="tabBook.book.insurerId != 38"><!-- non specific insurer id -->Insured by: <b>{{ tabBook.book.Insurers.insurerName}}</b></h4>
                                    <h4 ng-if="tabBook.book.insurerId == 38"><!-- non specific insurer id -->Insured by: <b>{{ tabBook.book.InsurerDetailsText }}</b></h4>
                                    <h4>Product: <b>{{tabBook.book.Products.productName}}</b></h4>
                                    <h4>Doc Type: <b>{{ tabBook.book.DocTypes.docTypeName }}</b></h4>
                                    <h4 ng-if="tabBook.book.socialChannelIds && tabBook.book.socialChannelIds.length > 0">Social Channels:
                                        <b ng-repeat="channelId in tabBook.book.socialChannelIds">{{ channelId |readableSocialChannel}}<span ng-if="!$last">, </span></b>
                                    </h4>
                                    <br />
                                    <md-fab-speed-dial md-direction="right" class="md-scale" md-open="false" style=" padding-top: 10px;">
                                        <md-fab-trigger>
                                            <md-button ng-if="tabBook.book.DocTypes.docTypeTemplate === 1"  target="_blank"  class="md-fab " style=" background: #4CAF50;"   aria-label="alert" wraps-tooltip="jobbox-download">
                                                <ng-md-icon  icon="file_download" style="fill: white;    position: relative;" size="32"></ng-md-icon>
                                            </md-button>
                                        </md-fab-trigger>

                                        <md-fab-actions ng-if="tabBook.book.DocTypes.docTypeId === 1">
                                            <md-button href="{{tabBook.library.pdfUrl}}{{tabBook.book.pdfVersionUrl}}/?book={{ tabBook.book.bookId}}{{tabBook.library.linkExtras}}" target="_blank" type="button" class=" speeddial-button green-button" wraps-tooltip="jobbox-download-pdf">PDF</md-button>
                                            <md-button href="{{tabBook.library.pdfUrl}}{{tabBook.book.pdfVersionUrl}}/?book={{ tabBook.book.bookId}}{{tabBook.library.linkExtras}}&hl=true" target="_blank" type="button" class=" speeddial-button orange-button" wraps-tooltip="jobbox-download-changes">CHANGES</md-button>
                                            <md-button href="{{tabBook.library.pdfUrl}}/wcr/?book={{ tabBook.book.bookId}}{{tabBook.library.linkExtras}}&hl=true" target="_blank" type="button" class=" speeddial-button blue-button" wraps-tooltip="jobbox-download-wcr">WCR</md-button>
                                            <md-button ng-if="tabBook.library.isDLO(tabBook.book)" href="{{tabBook.library.pdfUrl}}{{tabBook.book.pdfVersionUrl}}/?book={{ tabBook.book.bookId}}{{tabBook.library.linkExtras}}&big=true" target="_blank"  type="button" class=" speeddial-button purple-button" wraps-tooltip="jobbox-download-large">LARGE FORMAT</md-button>
                                            <md-button ng-if="tabBook.library.isDLO(tabBook.book) && tabBook.book.status===1" href="{{tabBook.library.pdfUrl}}/translations/?book={{ tabBook.book.bookId}}{{tabBook.library.linkExtras}}" target="_blank"  type="button" class=" speeddial-button pink-button" wraps-tooltip="jobbox-download-translation-json">TRANSLATION JSON</md-button>


                                        </md-fab-actions>

                                        <md-fab-actions ng-if="tabBook.book.DocTypes.docTypeId === 93">
                                            <md-button href="{{tabBook.library.pdfUrl}}{{tabBook.book.pdfVersionUrl}}/?book={{ tabBook.book.bookId}}{{tabBook.library.linkExtras}}" target="_blank" type="button" class=" speeddial-button green-button" wraps-tooltip="jobbox-download-pdf">PDF</md-button>
                                            <md-button href="{{tabBook.library.pdfUrl}}{{tabBook.book.pdfVersionUrl}}/?book={{ tabBook.book.bookId}}{{tabBook.library.linkExtras}}&print=true" target="_blank" type="button" class=" speeddial-button purple-button" wraps-tooltip="jobbox-download-hr-pdf">HR PDF</md-button>
                                            <md-button href="{{tabBook.library.pdfUrl}}/wcr/?book={{ tabBook.book.bookId}}{{tabBook.library.linkExtras}}&hl=true" target="_blank" type="button" class=" speeddial-button blue-button" wraps-tooltip="jobbox-download-wcr">WCR</md-button>


                                        </md-fab-actions>
                                    </md-fab-speed-dial>


                                </div>
                                <div flex="25">
                                    <h4>Audience: <b>{{tabBook.book.Audiences.audienceName}}</b></h4>
                                    <h4>Region: <b>{{tabBook.book.Regions.regionName}}</b></h4>
                                    <h4 ng-if="tabBook.book.SubInsurers.subInsurerName">Sub Insurer: <b>{{tabBook.book.SubInsurers.subInsurerName}}</b></h4>
                                    <h4 ng-if="tabBook.book.Clients.clientName">Client: <b>{{tabBook.book.Clients.clientName}}</b></h4>
                                    <h4 ng-if="tabBook.book.Clients.Projects.projectName">Project: <b>{{tabBook.book.Projects.projectName}}</b></h4>
                                </div>

                                <div flex="25">

                                    <h4 ng-if="tabBook.book.Distributors.distributorName">Distributor: <b>{{tabBook.book.Distributors.distributorName}}</b></h4>
                                    <h4>Customer Type: <b>{{tabBook.book.ProductTypes.productTypeName}}</b></h4>
                                    <h4>Workflow Status: <b>{{ tabBook.book.workflowStatus }}</b></h4>
                                    <h4>Document Status: <b>{{ tabBook.book.documentStatus }}</b></h4>
                                    <h4 ng-if="tabBook.book.sourceId" ><span class="meta-label">Duplication ID: </span><a class="md-accent" style="font-weight: bold;" ng-href="/library/{{ tabBook.book.sourceId }}">{{ tabBook.book.sourceIdLong }}</a></h4>
                                </div>

                                <div flex="25">

                                    <h4>Created Date: <b>{{tabBook.book.createdDisplayDate }}</b></h4>
                                    <h4>Active Date: <b>{{tabBook.book.activeDateDisplayDate }}</b></h4>
                                    <h4>Live Date: <b>{{tabBook.book.liveDateDisplayDate }}</b></h4>
                                    <h4>Review Date: <b>{{tabBook.book.reviewDateDisplayDate }}</b></h4>
                                    <h4>Expiry Date: <b>{{tabBook.book.expiryDateDisplayDate }}</b></h4>
                                    <h4 ng-if="tabBook.book.earlyExpiryDate">Early Expiry Date: <b>{{tabBook.book.earlyExpiryDateDisplayDate }}</b></h4>
                                    <h4 ng-if="tabBook.book.withdrawDate">Withdrawn Date: <b>{{tabBook.book.withdrawDate | date:'dd/MM/yyyy'}}</b></h4>
                                </div>





                            </div>

                        </div>

                        <div ng-if="tabBook.book.Children">






                            <md-divider ng-if="tabBook.book.DocTypes.docTypeTemplate === 1"></md-divider>
                            <h1  ng-if="tabBook.book.DocTypes.docTypeTemplate === 1" ng-click="tabBook.opentab.selected.doc = !tabBook.opentab.selected.doc; tabBook.library.goToAnchor('anchorBook' + tabBook.book.bookId)"  ng-class="{'active-header': tabBook.opentab.selected.doc}"  style=" cursor: pointer; padding:0 0 10px 10px;  " layout="row" layout-align="space-between center">Document Content

                                <ng-md-icon ng-if="tabBook.book.action && !tabBook.opentab.selected.doc" icon="error" md-colors="{fill: 'light-blue'}" size="33"></ng-md-icon>
                                <span  ng-if="tabBook.book.searchCount>0" class="searchCount">{{tabBook.book.searchCount}}</span>

                                <div layout="row" layout-wrap flex="70" ng-if="tabBook.book.docStatusBar">
                                    <div flex></div>
                                    <div flex="nogrow" ng-if="!tabBook.library.isReorderingChildren(tabBook.book)">
                                        <div class="statbar" style="">
                                            <!--<h2 style="margin:5px;">Document:</h2>-->
                                            <span class="outer "  ng-repeat="stat in tabBook.book.docStatusBar">
                                                          <span class="inner" ng-class="stat.colour">{{stat.count}}<md-tooltip class="statbar"  md-direction="top">{{stat.tooltip}}</md-tooltip></span>
                                                          <span class="label">{{stat.name}}</span>

                                                        </span>
                                        </div>
                                    </div>
                                    <div ng-if="tabBook.library.isReorderingChildren(tabBook.book)" style="padding:5px 3px 0px 0px">
                                        <md-button class="md-grey"  ng-click="tabBook.library.reorderClose(tabBook.book);tabBook.book.updateBook(true);$event.stopPropagation();">Cancel</md-button>
                                        <md-button class="md-green" ng-click="tabBook.library.reorderSave(tabBook.book,null,tabBook.book);$event.stopPropagation();">Re-Order</md-button>
                                    </div>
                                </div>


                            </h1>

                            <doc ng-if="tabBook.book.DocTypes.docTypeTemplate === 1 && tabBook.opentab.selected.doc" book="tabBook.book"></doc>








                            <!--BRIEF-->



                            <md-divider ng-if="tabBook.book.briefStatusBar"></md-divider>
                            <h1  ng-if="tabBook.book.briefStatusBar" ng-click="tabBook.opentab.selected.brief = !tabBook.opentab.selected.brief"  ng-class="{'active-header': tabBook.opentab.selected.brief}"  style="padding:0 0 10px 10px; cursor: pointer;" layout="row" layout-align="space-between center" wraps-tooltip="itembrief">Document or Brief

                                <div layout="row" layout-wrap flex="70" ng-if="tabBook.book.briefStatusBar">


                                    <div flex></div>
                                    <div flex="nogrow">
                                        <div class="statbar" style="">
                                            <!--<h2 style="margin:5px; z-index: 1;">Brief:</h2>-->
                                            <span class="outer "  ng-repeat="stat in tabBook.book.briefStatusBar">
                                                      <span class="inner" ng-class="stat.colour">{{stat.count}}<md-tooltip class="statbar"  md-direction="top">{{stat.tooltip}}</md-tooltip></span>
                                                      <span class="label">{{stat.name}}</span>

                                                    </span>
                                        </div>
                                    </div>
                                </div>

                            </h1>

                            <md-list ng-if="(tabBook.book.showBrief && tabBook.opentab.selected.brief)">
                                <md-button ng-if="tabBook.book.showBriefButton && tabBook.book.documentStatus.substring(0, 4) !== 'EXPD'" type="button" class="md-fab md-mini add-brief" ng-click="tabBook.library.addbriefDialog(tabBook.book);$event.stopPropagation()" aria-label="Add Brief" wraps-tooltip="briefbox-addbrief">
                                    <ng-md-icon icon="add" size="26"  aria-label="add"></ng-md-icon>
                                </md-button>

                                <md-list-item layout="row" flex="100" layout-wrap ng-repeat="brief in tabBook.book.Children | filter: {versions: {Contents: {contentTypeId: 9}}} | orderBy:'order'">
                                    <div ng-class="{ 'selectedChapter' : tabBook.opentab.selected.structure[0] == brief.structureId}"  flex="100" layout="row"  layout-wrap ng-click="tabBook.toggleStructure(brief.structureId);">

                                        <div flex="35" class="brief-text" ng-bind-html="brief.brief.description | urlsToLinks"  ></div>
                                        <!-- <ul flex="40" class="files-ul">
                                            <li ng-repeat="file in brief.brief.filesList" >

                                                <ng-md-icon class="file-download"   icon="attach_file" size="30"  aria-label="Upload complete"></ng-md-icon><a   ng-href="{{tabBook.library.getFile(file.fileLocation,file.fileName)}}" target="_blank" >{{file.fileName}}</a>

                                            </li>
                                        </ul> -->
                                        <div flex="50" class="file-ui downloads-container">


                                            <div ng-repeat="upload in brief.brief.filesList" layout="row" layout-wrap layout-padding="20" class="download-row small" >

                                                <a href="{{upload.ngfName|fileurl}}" target="_blank">
                                                    <div class="thumbnail-block" wraps-tooltip="artwork-upload-thumb">

                                                        <img ng-if="!upload.isLoading" class="thumbnail-preview" ng-src="{{upload.ngfName|thumburl}}" style="" err-SRC="/images/wraps-logo.png" alt="{{upload.fileName}}"/>
                                                        <div class="overlay text-center"><ng-md-icon icon="file_download" size="24"></ng-md-icon></div>
                                                    </div>
                                                </a>
                                                <div flex>
                                                    <h2 >{{upload.fileName}}</h2>
                                                    <h2 >{{upload.fileSize | filesize}} </h2>
                                                </div>
                                                <div flex="100" class="divider"><div></div></div>
                                            </div>
                                        </div>
                                        <div flex="15"><content-menu child="brief" parentbook="tabBook.book" parent="tabBook.book"></content-menu></div>
                                    </div>

                                </md-list-item>
                            </md-list>




                            <!--ARTWORK-->


                            <md-divider ng-if="tabBook.book.artworkStatusBar"></md-divider>
                            <h1  ng-if="tabBook.book.artworkStatusBar" ng-click="tabBook.opentab.selected.artwork = !tabBook.opentab.selected.artwork"  ng-class="{'active-header': tabBook.opentab.selected.artwork}"  style="  padding:0 0 10px 10px; cursor: pointer;" layout="row" layout-align="space-between center">Artwork Draft
                                <div layout="row" layout-wrap flex="70" ng-if="tabBook.book.artworkStatusBar">



                                    <div flex></div>
                                    <div flex="nogrow">
                                        <div class="statbar" style="">
                                            <!--<h2 style="margin:5px; z-index: 1;">Artwork:</h2>-->
                                            <span class="outer "  ng-repeat="stat in tabBook.book.artworkStatusBar">
                                                      <span class="inner" ng-class="stat.colour">{{stat.count}}<md-tooltip class="statbar"  md-direction="top">{{stat.tooltip}}</md-tooltip></span>
                                                      <span class="label">{{stat.name}}</span>

                                                    </span>
                                        </div>
                                    </div>
                                </div>
                            </h1>
                            <md-list ng-if="(tabBook.book.showArtwork && tabBook.opentab.selected.artwork)">
                                <md-button ng-if="tabBook.book.showArtworkButton && tabBook.book.documentStatus.substring(0, 4) != 'EXPD'" type="button" class="md-fab md-mini add-brief" ng-click="tabBook.library.addartworkDialog(tabBook.book);$event.stopPropagation()" aria-label="Add Artwork">
                                    <ng-md-icon icon="add" size="26"  aria-label="add"></ng-md-icon>
                                </md-button>
                                <md-list-item layout="row" flex="100" layout-wrap ng-repeat="artwork in tabBook.book.Children | filter: {versions: {Contents: {contentTypeId: 10}}} | orderBy:'order'">
                                    <div ng-class="{ 'selectedChapter' : tabBook.opentab.selected.structure[0] == artwork.structureId}"  flex="100" layout="row"  layout-wrap ng-click="tabBook.toggleStructure(artwork.structureId);">

                                        <div flex="35" class="brief-text" ng-bind-html="artwork.artwork.description | urlsToLinks"  ></div>


                                        <div flex="50" class="file-ui downloads-container">


                                            <div ng-repeat="upload in artwork.artwork.filesList" layout="row" layout-wrap layout-padding="20" class="download-row small"  ng-class="{'file-name-error': !tabBook.filenameInCodeCheck(upload.fileName,tabBook.book.bookCode)}">

                                                <md-tooltip ng-if="!tabBook.filenameInCodeCheck(upload.fileName,tabBook.book.bookCode);" class="statbar"  md-direction="top">This filename does not contain the current job code</md-tooltip>
                                                <a href="{{upload.ngfName|fileurl}}" target="_blank">
                                                    <div class="thumbnail-block" wraps-tooltip="artwork-upload-thumb">

                                                        <img ng-if="!upload.isLoading" class="thumbnail-preview" ng-src="{{upload.ngfName|thumburl}}" err-SRC="/images/wraps-logo.png" alt="{{upload.fileName}}"/>
                                                        <div class="overlay text-center"><ng-md-icon icon="file_download" size="24"></ng-md-icon></div>
                                                    </div>
                                                </a>
                                                <div flex>
                                                    <h2 >{{upload.fileName}}</h2>
                                                    <h2 >{{upload.fileSize | filesize}} </h2>
                                                </div>
                                                <div flex="100" class="divider"><div></div></div>
                                            </div>
                                        </div>
                                        <div flex="15"><content-menu child="artwork" parentbook="tabBook.book" parent="tabBook.book"></content-menu></div>
                                    </div>
                                </md-list-item>
                            </md-list>






                            <!--FINAL-->


                            <md-divider ng-if="tabBook.book.finalStatusBar"></md-divider>
                            <h1  ng-if="tabBook.book.finalStatusBar" ng-click="tabBook.opentab.selected.final = !tabBook.opentab.selected.final"  ng-class="{'active-header': tabBook.opentab.selected.final}"  style="  padding:0 0 10px 10px; cursor: pointer;" layout="row" layout-align="space-between center">Publishing
                                <div layout="row" layout-wrap flex="70" ng-if="tabBook.book.finalStatusBar">



                                    <div flex></div>
                                    <div flex="nogrow">
                                        <div class="statbar" style="">
                                                    <span class="outer "  ng-repeat="stat in tabBook.book.finalStatusBar">
                                                      <span class="inner" ng-class="stat.colour">{{stat.count}}<md-tooltip class="statbar"  md-direction="top">{{stat.tooltip}}</md-tooltip></span>
                                                      <span ng-if="stat.name != 'DESIGN'" class="label">{{stat.name}}</span>
                                                      <span ng-if="stat.name == 'DESIGN'" class="label" wraps-tooltip="docman-wf-input_finalfiles">FINAL FILES</span>

                                                    </span>
                                        </div>
                                    </div>
                                </div>
                            </h1>

                            <md-list ng-if="(tabBook.book.showFinal && tabBook.opentab.selected.final)">
                                <md-button ng-if="tabBook.book.showFinalButton && tabBook.book.documentStatus.substring(0, 4) != 'EXPD'" type="button" class="md-fab md-mini add-brief" ng-click="tabBook.library.addFinalDialog(tabBook.book);$event.stopPropagation()" aria-label="Add Publishing">
                                    <ng-md-icon icon="add" size="26"  aria-label="add"></ng-md-icon>
                                </md-button>
                                <md-list-item layout="row" flex="100" layout-wrap ng-repeat="final in tabBook.book.Children | filter: {versions: {Contents: {contentTypeId: 13}}} | orderBy:'order'">
                                    <div ng-class="{ 'selectedChapter' : tabBook.opentab.selected.structure[0] == final.structureId}"  flex="100" layout="row"  layout-wrap ng-click="tabBook.toggleStructure(final.structureId);">

                                        <div flex="35" class="brief-text" ng-bind-html="final.artwork.description | urlsToLinks"  ></div>

                                        <!-- <ul flex="40" class="files-ul">
                                            <li ng-repeat="file in final.artwork.filesList" >


                                                <a   ng-class="{'file-name-error': !tabBook.filenameInCodeCheck(file.fileName,tabBook.book.bookCode)}" ng-href="{{tabBook.library.getFile(file.fileLocation,file.fileName)}}" target="_blank" >
                                                    <md-tooltip ng-if="!tabBook.filenameInCodeCheck(file.fileName,tabBook.book.bookCode);" class="statbar"  md-direction="top">This filename does not contain the current job code</md-tooltip>
                                                    <ng-md-icon class="file-download"   icon="insert_photo" size="30"  aria-label="Upload complete"></ng-md-icon>
                                                    {{file.fileName}}
                                                </a>

                                            </li>
                                        </ul> -->
                                        <div flex="50" class="file-ui downloads-container">


                                            <div ng-repeat="upload in final.artwork.filesList" layout="row" layout-wrap layout-padding="20" class="download-row small"  ng-class="{'file-name-error': !tabBook.filenameInCodeCheck(upload.fileName,tabBook.book.bookCode)}">

                                                <md-tooltip ng-if="!tabBook.filenameInCodeCheck(upload.fileName,tabBook.book.bookCode);" class="statbar"  md-direction="top">This filename does not contain the current job code</md-tooltip>
                                                <a href="{{upload.ngfName|fileurl}}" target="_blank">
                                                    <div class="thumbnail-block" wraps-tooltip="artwork-upload-thumb">

                                                        <img ng-if="!upload.isLoading" class="thumbnail-preview" ng-src="{{upload.ngfName|thumburl}}" err-SRC="/images/wraps-logo.png" alt="{{upload.fileName}}"/>
                                                        <div class="overlay text-center"><ng-md-icon icon="file_download" size="24"></ng-md-icon></div>
                                                    </div>
                                                </a>
                                                <div flex>
                                                    <h2 >{{upload.fileName}}</h2>
                                                    <h2 >{{upload.fileSize | filesize}} </h2>
                                                </div>
                                                <div flex="100" class="divider"><div></div></div>
                                            </div>
                                        </div>
                                        <div flex="15"><content-menu child="final" parentbook="tabBook.book" parent="tabBook.book"></content-menu></div>
                                    </div>
                                </md-list-item>
                            </md-list>


                            <!--WCR-->

                            <md-divider ng-if="tabBook.book.DocTypes.docTypeTemplate === 1"></md-divider>
                            <h1 ng-if="tabBook.book.DocTypes.docTypeTemplate === 1"  ng-click="tabBook.opentab.selected.wcr = !tabBook.opentab.selected.wcr" ng-class="{'active-header': tabBook.opentab.selected.wcr}" style=" cursor: pointer; padding:20px 0 20px 10px;">Wording Change Report </h1>
                            <wcr ng-if="tabBook.book.DocTypes.docTypeTemplate === 1; tabBook.opentab.selected.wcr;" book="tabBook.book"></wcr>





                            <md-divider></md-divider>

                        </div>



                    </div>

    </div>
</md-content>
