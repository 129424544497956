
<md-bottom-sheet class="md-list md-has-header">
    <md-content  layout="row" layout-wrap  layout-align="center start">
        <div class=" default-box">
            <md-subheader></md-subheader>


            <div layout="row">
                <div  flex></div>
                <div>

                    <md-button class="md-grey"  ng-click="bottomsheet.hideBottomSheet()">Cancel<ng-md-icon icon="close" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>
      
                    
                    <md-button ng-disabled="bottomsheet.isLoading || bottomsheet.compareObjects(bottomsheet.tempData,bottomsheet.bottomSheetData)" class="md-green"  ng-click="bottomsheet.saveVersion(bottomsheet.bottomSheetData)">Save<ng-md-icon icon="check" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>

                    <md-progress-circular style="position: absolute; top: 0; left: 50%; margin-left: -50px;"  md-diameter="40" md-mode="indeterminate" ng-show="bottomsheet.isLoading"></md-progress-circular>

                    <md-button aria-label="Open history" class="md-icon-button" id="history-button" ng-click="bottomsheet.showHistory(bottomsheet.bottomSheetData.structureId)">
                        <ng-md-icon icon="history" style="fill: grey;" size="24"></ng-md-icon>
                    </md-button>


                    <!--This is for the show and swap versions on same content ID-->
                    <!--<md-button aria-label="Open versions" class="md-icon-button" ng-click="bottomsheet.showVersions(bottomsheet.bottomSheetData.structureId)">-->
                        <!--<ng-md-icon icon="layers" style="fill: grey;" size="24"></ng-md-icon>-->
                    <!--</md-button>-->


                </div>
            </div>

<!--<pre>{{bottomsheet.tempData.benefit | json}}</pre>-->
<!--<pre>{{bottomsheet.bottomSheetData.benefit | json}}</pre>-->


            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId <= 4" layout="row" layout-wrap >

                <div  ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 1"  flex="100"  >
                    <div layout="row">
                        <md-input-container  flex="80">
                            <label>Chapter Type</label>
                            <md-select ng-model="bottomsheet.bottomSheetData.chapterTypeId" placeholder="Chapter Type"  required>
                                <md-option ng-value="types.chapterTypeId" ng-repeat="types in bottomsheet.chapterTypes | orderBy: 'chapterTypeName'" >{{types.chapterTypeName}}</md-option>
                            </md-select>
                        </md-input-container>

                    </div>
                    <div layout="row">
                        <md-input-container ng-if="bottomsheet.bottomSheetData.chapterTypeId === 3" flex="80">
                            <label>QREF</label>
                            <md-select ng-model="bottomsheet.bottomSheetData.chapterLinkId" placeholder="Choose QREF"  required>
                                <md-option ng-value="QREF.bookId" ng-repeat="QREF in bottomsheet.QREFs | filter: {isLive: true}  | orderBy: 'bookCode'" >{{QREF.bookCode}}</md-option>
                            </md-select>
                        </md-input-container>

                    </div>


                    <div layout="row" layout-wrap ng-if="bottomsheet.bottomSheetData.chapterTypeId === 2">
                        <div flex="70" layout="row" layout-wrap>

                            <ul class="file-ui">
                                <li ng-repeat="f in bottomsheet.bottomSheetData.filesList" style="font:smaller" class="file-li animate-if">{{f.fileName}}
                                    <div class="file-prog" ng-mouseenter="f.hovered = true" ng-mouseleave="f.hovered = false">
                                        <md-progress-circular class="animate-if" ng-if="f.determinateValue < 100" md-mode="determinate" value="{{f.determinateValue}}" md-diameter="30"></md-progress-circular>
                                        <span class="animate-ifx" ng-if="f.determinateValue < 100" >{{f.determinateValue}}%</span>
                                        <ng-md-icon class="animate-if file-complete" ng-if="!f.determinateValue || f.determinateValue === 100"  icon="check_circle" size="30"  aria-label="Upload complete"></ng-md-icon>
                                        <ng-md-icon class="file-remove" ng-if="f.hovered"  icon="cancel" size="30"  aria-label="Remove file" ng-click="bottomsheet.removeFile(bottomsheet.bottomSheetData.filesList, $index)"></ng-md-icon>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div flex="5"></div>
                        <div flex="25">
                            <div ngf-drop ngf-select ng-model="bottomsheet.chapterFiles" class="drop-box"
                                 ngf-drag-over-class="'dragover'" ngf-multiple="false" ngf-allow-dir="true"
                                 accept="image/*"
                                 ngf-pattern="'image/*'"><ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop logo image here or<br /><strong style="text-decoration: underline;">Click to upload</strong></div>
                            <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>
                        </div>
                    </div>

                    <div layout="row" style="padding-top:10px;" layout-wrap ng-if="bottomsheet.bottomSheetData.chapterTypeId === 2 && bottomsheet.bottomSheetData.versions.Contents.contentTypeId == 1">
                        <div flex="70" layout="row" layout-wrap>

                            <ul class="file-ui">
                                <li ng-repeat="f in bottomsheet.bottomSheetData.PDFList" style="font:smaller" class="file-li animate-if">{{f.fileName}}
                                    <div class="file-prog" ng-mouseenter="f.hovered = true" ng-mouseleave="f.hovered = false">
                                        <md-progress-circular class="animate-if" ng-if="f.determinateValue < 100" md-mode="determinate" value="{{f.determinateValue}}" md-diameter="30"></md-progress-circular>
                                        <span class="animate-ifx" ng-if="f.determinateValue < 100" >{{f.determinateValue}}%</span>
                                        <ng-md-icon class="animate-if file-complete" ng-if="!f.determinateValue || f.determinateValue === 100"  icon="check_circle" size="30"  aria-label="Upload complete"></ng-md-icon>
                                        <ng-md-icon class="file-remove" ng-if="f.hovered" icon="cancel" size="30" aria-label="Remove file" ng-click="bottomsheet.removeFile(bottomsheet.bottomSheetData.PDFList, $index)"></ng-md-icon>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div flex="5"></div>
                        <div flex="25">
                            <div ngf-drop ngf-select ng-model="bottomsheet.frontCoverPDF" class="drop-box"
                                 ngf-drag-over-class="'dragover'" ngf-multiple="true" ngf-allow-dir="true"
                                 accept="application/pdf"
                                 ngf-pattern="'application/pdf'"><ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop front cover PDF here or<br /><strong style="text-decoration: underline;">Click to upload</strong></div>
                            <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>
                        </div>
                    </div>
                </div>

                <div   flex="100"  ng-if="bottomsheet.bottomSheetData.chapterTypeId != 2 && bottomsheet.bottomSheetData.chapterTypeId != 3 && bottomsheet.bottomSheetData.chapterTypeId != 5 && bottomsheet.bottomSheetData.chapterTypeId != 6 " >
                    <md-input-container  flex="100" ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId == 4" class="cke-container">
                        <label>Text</label>
                        <textarea  ng-model="bottomsheet.bottomSheetData.versions.versiondata" contenteditable="true" ckeditor="bottomsheet.options" md-autofocus></textarea>
                    </md-input-container>

                    <md-input-container flex="100"  ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId != 4" class="cke-container">
                        <label>Text</label>
                        <textarea  ng-model="bottomsheet.bottomSheetData.versions.versiondata"   contenteditable="true" ckeditor="bottomsheet.options2"  md-autofocus></textarea>
                    </md-input-container>
                </div>
				<div   flex="100" ng-if="bottomsheet.bottomSheetData.chapterTypeId != 2 && bottomsheet.bottomSheetData.chapterTypeId != 3 && bottomsheet.bottomSheetData.chapterTypeId != 5 && bottomsheet.bottomSheetData.chapterTypeId != 6 ">
	                <div id="ckfoot" flex="100"></div>
	                <div id="cktools"  flex="100"></div>
				</div>
            </div>



            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 5"  class="Benefit"  layout-wrap  layout="row"  flex="100"  style="padding-bottom: 20px;">

                <div layout="row"  layout-wrap  flex="100">
                <table width="100%">
                    <thead><tr>
                        <th>Benefit</th>
                        <th ng-bind-html="bottomsheet.book.levels.level1.name"></th>
                        <th ng-bind-html="bottomsheet.book.levels.level2.name"></th>
                        <th ng-bind-html="bottomsheet.book.levels.level3.name"></th>
                        <th ng-bind-html="bottomsheet.book.levels.level4.name"></th>
                        <th>Explanation of Benefits</th></tr></thead>
                    <tbody>
                    <tr>

                        <td width="">
                            <md-input-container><textarea  ng-model="bottomsheet.bottomSheetData.benefit.name" aria-label="Benefit name"  md-autofocus></textarea></md-input-container>
                        </td>
                        <td width="12%">
                            <md-input-container><textarea ng-disabled="bottomsheet.disabledProducts.level1"  ng-model="bottomsheet.bottomSheetData.benefit.level1" aria-label="Benefit level1" ></textarea></md-input-container>


                            <md-checkbox ng-change="bottomsheet.updateOption(bottomsheet.bottomSheetData.benefit,'level1')" class="linked" ng-model="bottomsheet.bottomSheetData.benefit.option.level1" aria-label="level1">Options</md-checkbox>

                            <md-radio-group  ng-change="bottomsheet.updateOptions(bottomsheet.bottomSheetData.benefit,'level1')" ng-if="bottomsheet.bottomSheetData.benefit.option.level1"  ng-model="bottomsheet.bottomSheetData.benefit.options.level1">
                                <md-radio-button value="Linked">Linked</md-radio-button>
                                <md-radio-button value="Not covered">Not covered</md-radio-button>
                                <md-radio-button value="Paid in full">Paid in full</md-radio-button>
                                <md-radio-button value="Optional">Optional</md-radio-button>
                                <md-radio-button value="Show in summary">Show in summary</md-radio-button>
                            </md-radio-group>

                        </td>
                        <td width="12%">
                            <md-input-container><textarea  ng-disabled="bottomsheet.disabledProducts.level2"  ng-model="bottomsheet.bottomSheetData.benefit.level2" aria-label="Benefit level2" ></textarea></md-input-container>

                            <md-checkbox ng-change="bottomsheet.updateOption(bottomsheet.bottomSheetData.benefit,'level2')" class="linked" ng-model="bottomsheet.bottomSheetData.benefit.option.level2" aria-label="level2">Options</md-checkbox>

                            <md-radio-group  ng-change="bottomsheet.updateOptions(bottomsheet.bottomSheetData.benefit,'level2')"  ng-if="bottomsheet.bottomSheetData.benefit.option.level2"  ng-model="bottomsheet.bottomSheetData.benefit.options.level2">
                                <md-radio-button value="Linked">Linked</md-radio-button>
                                <md-radio-button value="Not covered">Not covered</md-radio-button>
                                <md-radio-button value="Paid in full">Paid in full</md-radio-button>
                                <md-radio-button value="Optional">Optional</md-radio-button>
                                <md-radio-button value="Show in summary">Show in summary</md-radio-button>
                            </md-radio-group>

                        </td>
                        <td width="12%">
                            <md-input-container><textarea ng-disabled="bottomsheet.disabledProducts.level3" ng-model="bottomsheet.bottomSheetData.benefit.level3" aria-label="Benefit level3" ></textarea></md-input-container>

                            <md-checkbox ng-change="bottomsheet.updateOption(bottomsheet.bottomSheetData.benefit,'level3')" class="linked" ng-model="bottomsheet.bottomSheetData.benefit.option.level3" aria-label="level3">Options</md-checkbox>

                            <md-radio-group  ng-change="bottomsheet.updateOptions(bottomsheet.bottomSheetData.benefit,'level3')"  ng-if="bottomsheet.bottomSheetData.benefit.option.level3"  ng-model="bottomsheet.bottomSheetData.benefit.options.level3">
                                <md-radio-button value="Linked">Linked</md-radio-button>
                                <md-radio-button value="Not covered">Not covered</md-radio-button>
                                <md-radio-button value="Paid in full">Paid in full</md-radio-button>
                                <md-radio-button value="Optional">Optional</md-radio-button>
                                <md-radio-button value="Show in summary">Show in summary</md-radio-button>
                            </md-radio-group>

                        </td>
                        <td width="12%">
                            <md-input-container><textarea ng-disabled="bottomsheet.disabledProducts.level4" ng-model="bottomsheet.bottomSheetData.benefit.level4" aria-label="Benefit gold superior" ></textarea></md-input-container>

                            <md-checkbox  ng-change="bottomsheet.updateOption(bottomsheet.bottomSheetData.benefit,'level4')" class="linked" ng-model="bottomsheet.bottomSheetData.benefit.option.level4" aria-label="level4">Options</md-checkbox>

                            <md-radio-group  ng-change="bottomsheet.updateOptions(bottomsheet.bottomSheetData.benefit,'level4')"  ng-if="bottomsheet.bottomSheetData.benefit.option.level4"  ng-model="bottomsheet.bottomSheetData.benefit.options.level4">
                                <md-radio-button value="Linked">Linked</md-radio-button>
                                <md-radio-button value="Not covered">Not covered</md-radio-button>
                                <md-radio-button value="Paid in full">Paid in full</md-radio-button>
                                <md-radio-button value="Optional">Optional</md-radio-button>
                                <md-radio-button value="Show in summary">Show in summary</md-radio-button>
                            </md-radio-group>

                        </td>
                        <td width="38%" style="position: relative;">
                            <md-input-container class="cke-container">
                                <textarea   ng-model="bottomsheet.bottomSheetData.benefit.explanation"  contenteditable="true" ckeditor="bottomsheet.options3"  placeholder="" aria-label="Benefit explanation"></textarea>
                            </md-input-container>
                            <div id="ckfoot2"></div>
                            <div  style="position: absolute; bottom: -55px;" id="cktools2"></div>
                            <md-checkbox class="linked right"  ng-change="bottomsheet.updateLinked(bottomsheet.bottomSheetData.benefit,'explanation')" ng-model="bottomsheet.bottomSheetData.benefit.options.explanation"  ng-true-value="'Linked'" ng-false-value="false" aria-label="explanation">Linked</md-checkbox>
                        </td>
                    </tr>

                    </tbody>
                </table>
</div>
                <div layout="row"  layout-wrap ng-if="bottomsheet.book.levels.payment.active"  flex="100">
                    <md-input-container flex>
                        <label>Payment for treatment</label>
                        <md-select ng-model="bottomsheet.bottomSheetData.benefit.payment" placeholder="Payment for treatment"  required  ng-disabled="bottomsheet.bottomSheetData.benefit.options.payment" >
                            <md-option value="Direct Billing" >Direct Billing</md-option>
                            <md-option value="Non applicable" >Non applicable</md-option>
                            <md-option value="Reimbursement" >Reimbursement</md-option>
                        </md-select>
                    </md-input-container>
                </div>
                <div layout="row"  layout-wrap ng-if="bottomsheet.book.levels.payment.active"  flex="100">
                    <md-checkbox class="linked"  ng-change="bottomsheet.updateLinked(bottomsheet.bottomSheetData.benefit,'payment')" ng-model="bottomsheet.bottomSheetData.benefit.options.payment"  ng-true-value="'Linked'" ng-false-value="false" aria-label="payment">Linked</md-checkbox>

                </div>


            </div>
            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 6"  class="Benefit Glossary"  layout="row"  flex="100"  style="padding-bottom: 20px;">
                <table width="100%">
                    <thead><tr><th>Defined term</th><th>Description</th><th>Search words</th></tr></thead>
                    <tbody>
                    <tr>

                        <td width="25%">
                            <md-input-container><textarea  ng-model="bottomsheet.bottomSheetData.glossary.term" aria-label="Glossary term"  md-autofocus></textarea></md-input-container>
                        </td>

                        <td width="50%" style="position: relative;">
                            <md-input-container class="cke-container">
                                <textarea    ng-model="bottomsheet.bottomSheetData.glossary.description"  contenteditable="true" ckeditor="bottomsheet.options4"  placeholder="" aria-label="Glossary description"></textarea>
                            </md-input-container>
                            <div id="ckfoot3"></div>
                            <div  style="position: absolute; bottom: -55px;" id="cktools3"></div>
                        </td>

                        <td width="25%">
                            <md-input-container><textarea  ng-model="bottomsheet.bottomSheetData.glossary.words" aria-label="Glossary words" ></textarea></md-input-container>
                        </td>
                    </tr>

                    </tbody>
                </table>

            </div>
            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 7"  class="Benefit"  layout="row" layout-wrap  flex="100"  style="padding-bottom: 20px;">
                <div layout="row"  layout-wrap   flex="100">
                    <table width="100%">
                        <thead><tr><th>Exclusion</th><th>Notes</th><th>Rules</th></tr></thead>
                        <tbody>
                        <tr>

                            <td width="30%">
                                <md-input-container><textarea  ng-model="bottomsheet.bottomSheetData.exclusion.name" aria-label="Exclusion"  md-autofocus></textarea></md-input-container>
                            </td>

                            <td width="20%">
                                <md-input-container><textarea  ng-model="bottomsheet.bottomSheetData.exclusion.notes" aria-label="Exclusion Notes" ></textarea></md-input-container>
                            </td>

                            <td width="50%" style="position: relative;">
                                <md-input-container class="cke-container">
                                    <textarea    ng-model="bottomsheet.bottomSheetData.exclusion.rules"  contenteditable="true" ckeditor="bottomsheet.options4"  placeholder="" aria-label="Exclusion Rules"></textarea>
                                </md-input-container>
                                <div id="ckfoot3"></div>
                                <div  style="position: absolute; bottom: -55px;" id="cktools3"></div>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>
                <div style="position: relative; top:-30px;"  flex="50">
                    <md-checkbox ng-model="bottomsheet.bottomSheetData.exclusion.level1" >{{bottomsheet.book.levels.level1.name}}</md-checkbox>
                    <md-checkbox ng-model="bottomsheet.bottomSheetData.exclusion.level2" >{{bottomsheet.book.levels.level2.name}}</md-checkbox>
                    <md-checkbox ng-model="bottomsheet.bottomSheetData.exclusion.level3" >{{bottomsheet.book.levels.level3.name}}</md-checkbox>
                    <md-checkbox ng-model="bottomsheet.bottomSheetData.exclusion.level4" >{{bottomsheet.book.levels.level4.name}}</md-checkbox>

                </div>

            </div>

            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 8"  class="Benefit Glossary"  layout="row"  flex="100"  style="padding-bottom: 20px;">
                <table width="100%">
                    <thead><tr><th>Term</th><th>Description</th></tr></thead>
                    <tbody>
                    <tr>

                        <td width="40%">
                            <md-input-container><textarea  ng-model="bottomsheet.bottomSheetData.medical.term" aria-label="Medical term"  md-autofocus></textarea></md-input-container>
                        </td>

                        <td width="60%" style="position: relative;">
                            <md-input-container class="cke-container">
                                <textarea    ng-model="bottomsheet.bottomSheetData.medical.description"  contenteditable="true" ckeditor="bottomsheet.options4"  placeholder="" aria-label="Medical description"></textarea>
                            </md-input-container>
                            <div id="ckfoot3"></div>
                            <div  style="position: absolute; bottom: -55px;" id="cktools3"></div>
                        </td>
                    </tr>

                    </tbody>
                </table>

            </div>

            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 9" layout="row" layout-wrap >



                <div layout="row" layout-wrap>
                    <div flex="70" layout="row" layout-wrap>
                        <div flex="100">
                            <md-input-container class="cke-container">
                                <label>Brief description</label>
                                <textarea  ng-model="bottomsheet.bottomSheetData.brief.description"  contenteditable="true" ckeditor="bottomsheet.options"></textarea>
                            </md-input-container>



                            <div id="cktools" flex></div>
                            <div id="ckfoot"></div>
                        </div>
                        <ul class="file-ui">
                            <li ng-repeat="f in bottomsheet.bottomSheetData.brief.filesList" style="font:smaller" class="file-li animate-if">{{f.fileName}}
                                <div class="file-prog" ng-mouseenter="f.hovered = true" ng-mouseleave="f.hovered = false">
                                    <md-progress-circular class="animate-if" ng-if="f.determinateValue < 100" md-mode="determinate" value="{{f.determinateValue}}" md-diameter="30"></md-progress-circular>
                                    <span class="animate-ifx" ng-if="f.determinateValue < 100" >{{f.determinateValue}}%</span>
                                    <ng-md-icon class="animate-if file-complete" ng-if="!f.determinateValue || f.determinateValue === 100"  icon="check_circle" size="30"  aria-label="Upload complete"></ng-md-icon>
                                    <ng-md-icon class="file-remove" ng-if="f.hovered"  icon="cancel" size="30"  aria-label="Remove file" ng-click="bottomsheet.removeFile(bottomsheet.bottomSheetData.brief.filesList, $index)"></ng-md-icon>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div flex="5"></div>
                    <div flex="25">
                        <div ngf-drop ngf-select ng-model="bottomsheet.briefFiles" class="drop-box"
                             ngf-drag-over-class="'dragover'" ngf-multiple="true" ngf-allow-dir="true"
                             accept="{{bottomsheet.safeFileList}}"
                             ngf-pattern="bottomsheet.safeFileList">
                                <ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop files here or<br /><strong style="text-decoration: underline;">Click to upload</strong>d
                        </div>
                        <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>



                    </div>
                </div>




            </div>

            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 10 || bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 13" layout="row" layout-wrap >


                <div layout="row" layout-wrap>
                    <div flex="70" layout="row" layout-wrap>
                        <div flex="100">
                            <md-input-container class="cke-container">
                                <label>Artwork description</label>
                                <textarea  ng-model="bottomsheet.bottomSheetData.artwork.description"  contenteditable="true" ckeditor="bottomsheet.options"></textarea>
                            </md-input-container>



                            <div id="cktools" flex></div>
                            <div id="ckfoot"></div>
                        </div>
                        <div class="file-ui downloads-container">
                            <!-- <li ng-repeat="f in bottomsheet.bottomSheetData.artwork.filesList" style="font:smaller" class="file-li animate-if">{{f.fileName}}
                                <div class="file-prog" ng-mouseenter="f.hovered = true" ng-mouseleave="f.hovered = false">
                                    <md-progress-circular class="animate-if" ng-if="f.determinateValue < 100" md-mode="determinate" value="{{f.determinateValue}}" md-diameter="30"></md-progress-circular>
                                    <span class="animate-ifx" ng-if="f.determinateValue < 100" >{{f.determinateValue}}%</span>
                                    <ng-md-icon class="animate-if file-complete" ng-if="!f.determinateValue || f.determinateValue === 100"  icon="check_circle" size="30"  aria-label="Upload complete"></ng-md-icon>
                                    <ng-md-icon class="file-remove" ng-if="f.hovered"  icon="cancel" size="30"  aria-label="Remove file"  ng-click="bottomsheet.removeFile(bottomsheet.bottomSheetData.artwork.filesList, $index)"></ng-md-icon>
                                </div>
                            </li> -->

                            <div ng-repeat="upload in bottomsheet.bottomSheetData.artwork.filesList" layout="row" layout-wrap layout-padding="20" class="download-row small"  ng-class="{'file-name-error': !bottomsheet.filenameInCodeCheck(upload.fileName,bottomsheet.book.bookCode)}">
                                <md-tooltip ng-if="!bottomsheet.filenameInCodeCheck(upload.fileName,bottomsheet.book.bookCode);" class="statbar"  md-direction="top">This filename does not contain the current job code</md-tooltip>                      
                                <a href="{{upload.ngfName|fileurl}}" target="_blank">
                                    <div class="thumbnail-block" wraps-tooltip="artwork-upload-thumb">

                                        <md-progress-circular  ng-if="upload.determinateValue < 100" md-mode="determinate" value="{{upload.determinateValue}}"></md-progress-circular>
                                        <md-progress-circular ng-if="upload.determinateValue === 100 && upload.isLoading"></md-progress-circular>
                                    
                                        <img ng-if="!upload.isLoading" class="thumbnail-preview" ng-src="{{upload.ngfName|thumburl}}" err-SRC="/images/wraps-logo.png" alt="{{upload.fileName}}"/>
                                        <div class="overlay text-center"><ng-md-icon icon="file_download" size="24"></ng-md-icon></div>
                                    </div>
                                </a>
                                <div flex>
                                    <h3 md-truncate>{{upload.fileName}}</h3>
                                    <h3 md-truncate>{{upload.fileSize | filesize}} <a class="md-accent" style="cursor:pointer; font-weight: bold;" ng-click="bottomsheet.removeFile(bottomsheet.bottomSheetData.artwork.filesList, $index)">Remove</a></h3>
                                </div>
                                <div flex="100" class="divider"><div></div></div>
                            </div>
                        </div>
                    </div>
                    <div flex="5"></div>
                    <div flex="25">
                        <div ngf-drop ngf-select ng-model="bottomsheet.artworkFiles" class="drop-box"
                             ngf-drag-over-class="'dragover'" ngf-multiple="true" ngf-allow-dir="true"
                             accept="{{bottomsheet.safeFileList}}"
                             ngf-pattern="bottomsheet.safeFileList">
                            <ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop files here or<br /><strong style="text-decoration: underline;">Click to upload</strong>
                        </div>
                        <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>



                    </div>

                    <div flex="50" class="downloads-container" ng-if="addartwork.filesList.length > 0">
                            <h5>Uploads</h5>
                            
                    </div>

                </div>




            </div>




            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 11"  class="Benefit Table"  layout="row"  flex="100"  style="padding-bottom: 20px;">

                <table width="100%" class="workflow-table">
                    <tr>
                        <th colspan="100" width="60%" align="left">NAME</th>
                    </tr>
                    <tr >
                        <td ng-repeat="item in bottomsheet.bottomSheetData.tableHeader" >
                            <div layout="row" flex="100">
                                <md-input-container flex="100" >
                                    <label>Column Name</label>
                                    <input ng-model="item.name">
                                </md-input-container>
                            </div>
                            <div layout="row" flex="100">
                                <md-input-container flex="70" >
                                    <label>Width %</label>
                                    <input ng-model="item.width">
                                </md-input-container>
                            </div>
                        </td>
                    </tr>
                </table>

            </div>


            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 12"  class="Benefit Table"  layout="row"  flex="100"  style="padding-bottom: 20px;">


                <div flex="100" layout="row" layout-wrap>
                    <div flex="100">
                        <table width="100%" >
                            <thead>
                            <tr>
                                <th ng-repeat="item in bottomsheet.bottomSheetData.tableHeader | orderBy:'order'"  width="{{bottomsheet.getTableWidths(item.width, bottomsheet.bottomSheetData.tableHeader)}}%">
                                    <div ng-bind-html="item.name"></div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td ng-repeat="item in bottomsheet.bottomSheetData.tableHeader | orderBy:'order'" >
                                    <md-input-container class="cke-container">
                                        <textarea ng-model="bottomsheet.bottomSheetData.tableRow[item.index].data"   contenteditable="true" ckeditor="bottomsheet.options"></textarea>
                                    </md-input-container>
                                </td>
                            </tr>
                            </tbody>

                        </table>
                    </div>
                    <div flex="100">
                        <div id="ckfoot"></div>
                        <div  style="" id="cktools"></div>
                    </div>
                </div>
            </div>



            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 14 || bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 15 || bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 18 || bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 21"  class="Benefit Table"  layout="row"  flex="100"  style="padding-bottom: 20px;">
                <div flex="100" layout="row" layout-wrap>
                    <div flex="100">
                        <md-input-container flex class="cke-container">
                            <label>Heading text</label>
                            <textarea  ng-model="bottomsheet.bottomSheetData.versions.versiondata" contenteditable="true" ckeditor="bottomsheet.options2" md-autofocus></textarea>
                        </md-input-container>
                    </div>
                    <div flex="100">
                        <div id="ckfoot"></div>
                        <div  id="cktools"></div>
                    </div>
                </div>
            </div>

            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 16 || bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 19"  class="Benefit Table"  layout="row"  flex="100"  style="padding-bottom: 20px;">
                <div flex="100" layout="row" layout-wrap>
                    <div flex="100">
                        <md-input-container flex class="cke-container">
                            <label>Clause</label>
                            <textarea  ng-model="bottomsheet.bottomSheetData.versions.versiondata" contenteditable="true" ckeditor="bottomsheet.options" md-autofocus></textarea>
                        </md-input-container>
                    </div>
                    <div flex="100">
                        <div id="ckfoot"></div>
                        <div  id="cktools"></div>
                    </div>
                </div>
            </div>



            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 17"  class="Benefit Table"  layout="row"  flex="100"  style="padding-bottom: 20px;">
                <div flex="100" layout="row" layout-wrap>
                    <div flex="100">


                        <table width="100%">
                            <thead>
                            <tr>
                                <th>Benefits</th>
                                <th>Explanation</th>
                                <th>Limit</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td width="30%"><md-input-container><textarea  ng-model="bottomsheet.bottomSheetData.benefit.name" aria-label="Benefit name" required></textarea></md-input-container></td>

                                <td width="50%" style="position: relative;">
                                    <md-input-container class="cke-container">
                                        <label>Benefit explanation</label>
                                        <textarea    ng-model="bottomsheet.bottomSheetData.benefit.explanation"  contenteditable="true" ckeditor="bottomsheet.options"  placeholder="" aria-label="Benefit explanation"></textarea>
                                    </md-input-container>
                                    <div id="ckfoot"></div>
                                    <div  style="position: absolute; bottom: -55px;" id="cktools"></div>
                                </td>

                                <td width="20%">
                                    <md-input-container><textarea ng-disabled="bottomsheet.bottomSheetData.benefit.options.limit"  ng-model="bottomsheet.bottomSheetData.benefit.limit" aria-label="Benefit limit" ></textarea></md-input-container>

                                    <md-checkbox ng-change="bottomsheet.updateOption(bottomsheet.bottomSheetData.benefit,'limit')" class="linked" ng-model="bottomsheet.bottomSheetData.benefit.option.limit" aria-label="limit">Options</md-checkbox>

                                    <md-radio-group  ng-change="bottomsheet.updateOptions(bottomsheet.bottomSheetData.benefit,'limit')" ng-if="bottomsheet.bottomSheetData.benefit.option.limit"  ng-model="bottomsheet.bottomSheetData.benefit.options.limit">
                                        <md-radio-button value="Linked">Linked</md-radio-button>
                                        <md-radio-button value="Not covered">Not covered</md-radio-button>
                                        <md-radio-button value="Paid in full">Paid in full</md-radio-button>
                                    </md-radio-group>

                                </td>
                            </tr>

                            </tbody>
                        </table>


                    </div>
                </div>
            </div>


            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 20"  class="Benefit"  layout="row" layout-wrap  flex="100"  style="padding-bottom: 20px;">
                <div layout="row"  layout-wrap   flex="100">
                    <table width="100%">
                        <thead><tr><th>Exclusion</th><th>Rules</th></tr></thead>
                        <tbody>
                        <tr>

                            <td width="30%">
                                <md-input-container><textarea  ng-model="bottomsheet.bottomSheetData.exclusion.name" aria-label="Exclusion"  md-autofocus></textarea></md-input-container>
                            </td>


                            <td width="70%" style="position: relative;">
                                <md-input-container class="cke-container">
                                    <textarea    ng-model="bottomsheet.bottomSheetData.exclusion.rules"  contenteditable="true" ckeditor="bottomsheet.options4"  placeholder="" aria-label="Exclusion Rules"></textarea>
                                </md-input-container>
                                <div id="ckfoot3"></div>
                                <div  style="position: absolute; bottom: -55px;" id="cktools3"></div>
                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>

            </div>







        </div>


    </md-content>
</md-bottom-sheet>