(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name maintenance.directive:rowEdit
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="maintenance">
       <file name="index.html">
        <row-edit></row-edit>
       </file>
     </example>
   *
   */
  angular
    .module('maintenance')
    .directive('rowEdit', rowEdit);

  function rowEdit() {
    return {
      restrict: 'E',
      scope: false,
      templateUrl: 'maintenance/row-edit-directive.tpl.html',
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());
