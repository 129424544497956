(function () {
  'use strict';

  angular
    .module('tooltip')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('tooltip', {
        url: '/tooltip',
        templateUrl: 'tooltip/tooltip.tpl.html',
        controller: 'TooltipCtrl',
        controllerAs: 'tooltip'
      });
  }
}());
