(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableProduct', readableProduct);

  function readableProduct(Wraps) {
    return function (id) {
      var product;
      if ((product = _.find(Wraps.products, {productId: parseInt(id)})) && product.productName) {
        return product.productName;
      }
      return null;
    };
  }
}());
