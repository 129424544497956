<md-list-item layout="row" flex="100" style="margin-bottom: 30px;" layout-wrap ng-repeat="change in book.wcrChanges | orderBy:'order'">

    <div layout="row" flex="100" layout-wrap style="padding:6px; background:#bbb;">
        <div flex="100" style="text-align:left; color: #fff; font-size: 14px;" ng-bind-html="change.location"></div>
    </div>
    <div layout="row" flex="100" layout-wrap style="padding:4px 0; color:#aaa;">
        <div flex="45" style="text-align:center;">Old Wording</div>
        <div flex="45" style="text-align:center;">New Wording</div>
        <div flex="10" style="text-align:center;">Actions</div>
    </div>

    <div layout="row" flex="100" layout-wrap style="border-top:1px #eee solid; " class="changes-col">

        <div ng-class="[change.contentTypeName]" ng-if="change.contentTypeId <= 4" flex="45" style="color: #999; padding-right:10px;"  ng-bind-html="change.versionData1"  ></div>

        <div ng-class="[change.contentTypeName]" ng-if="change.contentTypeId <= 4" flex="45"  style=" padding-right:10px;"  ng-bind-html="change.versionData2"  ></div>


        <!-- Benefits-->
        <div ng-if="change.contentTypeId === 5" flex="90" layout-wrap layout="row" style="" class="benefit-changes" >
            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">
                <h5>Name:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.benefit.name)"></p>
            </div>
            <div flex="50"  style=" padding-right:10px;"   layout-wrap layout="row">
                <h5>Name:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.benefit.name)"></p>
            </div>

            <div flex="50"  style=" padding-right:10px;color: #999;" ng-show="book.levels.level1.active === true" layout-wrap layout="row">
                <h5>{{book.levels.level1.name}}:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.benefit.level1)"></p>
            </div>
            <div flex="50"  style=" padding-right:10px;" ng-show="book.levels.level1.active === true" layout-wrap layout="row">
                <h5>{{book.levels.level1.name}}:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.benefit.level1)"></p>
            </div>

            <div flex="50"  style=" padding-right:10px;color: #999;" ng-show="book.levels.level2.active === true" layout-wrap layout="row">
                <h5>{{book.levels.level2.name}}:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.benefit.level2)"></p>
            </div>
            <div flex="50"  style=" padding-right:10px;" ng-show="book.levels.level2.active === true" layout-wrap layout="row">
                <h5>{{book.levels.level2.name}}:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.benefit.level2)"></p>
            </div>

            <div flex="50"  style=" padding-right:10px;color: #999;" ng-show="book.levels.level3.active === true" layout-wrap layout="row">
                <h5>{{book.levels.level3.name}}:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.benefit.level3)"></p>
            </div>
            <div flex="50"  style=" padding-right:10px;" ng-show="book.levels.level3.active === true" layout-wrap layout="row">
                <h5>{{book.levels.level3.name}}:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.benefit.level3)"></p>
            </div>

            <div flex="50"  style=" padding-right:10px;color: #999;" ng-show="book.levels.level4.active === true" layout-wrap layout="row" >
                <h5>{{book.levels.level4.name}}:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.benefit.level4)"></p>
            </div>
            <div flex="50"  style=" padding-right:10px;" ng-show="book.levels.level4.active === true" layout-wrap layout="row">
                <h5>{{book.levels.level4.name}}:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.benefit.level4)"></p>
            </div>

            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">
                <h5>Explanation:</h5><div flex ng-bind-html="change.json1.benefit.explanation"></div>
            </div>
            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">
                <h5>Explanation:</h5><div flex ng-bind-html="change.json2.benefit.explanation"></div>
            </div>
        </div>


        <!--Glossary-->
        <div ng-if="change.contentTypeId === 6" flex="90" layout-wrap layout="row" style="" class="benefit-changes" >

            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">
                <h5>Term:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.glossary.term)"></p>
            </div>
            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">
                <h5>Term:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.glossary.term)"></p>
            </div>

            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">
                <h5>Description:</h5><div flex ng-bind-html="change.json1.glossary.description"></div>
            </div>
            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">
                <h5>Description:</h5><div flex ng-bind-html="change.json2.glossary.description"></div>
            </div>

            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">
                <h5>Words:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.glossary.words)"></p>
            </div>
            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">
                <h5>Words:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.glossary.words)"></p>
            </div>

        </div>


        <!--Exclusion-->
        <div ng-if="change.contentTypeId === 7" flex="90" layout-wrap layout="row" style="" class="benefit-changes" >

            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">
                <h5>Name:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.exclusion.name)"></p>
            </div>
            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">
                <h5>Name:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.exclusion.name)"></p>
            </div>

            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">
                <h5>Rules:</h5><div flex ng-bind-html="change.json1.exclusion.rules"></div>
            </div>
            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">
                <h5>Rules:</h5><div flex ng-bind-html="change.json2.exclusion.rules"></div>
            </div>

        </div>


        <!--Medical-->
        <div ng-if="change.contentTypeId === 8" flex="90" layout-wrap layout="row" style="" class="benefit-changes" >

            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">
                <h5>Term:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.medical.term)"></p>
            </div>
            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">
                <h5>Term:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.medical.term)"></p>
            </div>

            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">
                <h5>Description:</h5><div flex ng-bind-html="change.json1.medical.description"></div>
            </div>
            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">
                <h5>Description:</h5><div flex ng-bind-html="change.json2.medical.description"></div>
            </div>

        </div>

        <!--Table Header-->
        <div ng-if="change.contentTypeId === 11" flex="90" layout-wrap layout="row" style="" class="benefit-changes" >


            <div flex="100"   layout-wrap layout="row"  ng-repeat="col in change.json2.tableHeader">
                <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row" >
                    <h5>Column {{$index + 1}}:</h5><p flex ng-bind-html="change.json1.tableHeader[$index].name"></p>
                </div>
                <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row" >
                    <h5>Column {{$index + 1}}:</h5><p flex ng-bind-html="col.name"></p>
                </div>
            </div>


        </div>

        <!--Table Row-->
        <div ng-if="change.contentTypeId === 12" flex="90" layout-wrap layout="row" style="" class="benefit-changes" >


            <!--<pre>-->
                <!--{{change | json }}-->
            <!--</pre>-->

            <div flex="100"   layout-wrap layout="row"  ng-repeat="col in change.json2.tableRow">
                <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row" >
                    <h5>Column {{$index + 1}}:</h5><p flex ng-bind-html="change.json1.tableRow[$index].data"></p>
                </div>
                <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row" >
                    <h5>Column {{$index + 1}}:</h5><p flex ng-bind-html="col.data"></p>
                </div>
            </div>


        </div>


        <!--BGHP -->
        <div ng-if="change.contentTypeId === 14 || change.contentTypeId === 15 || change.contentTypeId === 16 || change.contentTypeId === 18 || change.contentTypeId === 19 || change.contentTypeId === 21" flex="90" layout-wrap layout="row" style="" class="benefit-changes" >

            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">
                <div flex ng-bind-html="change.versionData1" ></div>
            </div>
            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">
                <div flex ng-bind-html="change.versionData2" ></div>
            </div>

        </div>


        <div ng-if="change.contentTypeId === 17" flex="90" layout-wrap layout="row" style="" class="benefit-changes" >

            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">
                <h5>Name:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.benefit.name)"></p>
            </div>
            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">
                <h5>Name:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.benefit.name)"></p>
            </div>

            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">
                <h5>Explanation:</h5><div flex ng-bind-html="change.json1.benefit.explanation"></div>
            </div>
            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">
                <h5>Explanation:</h5><div flex ng-bind-html="change.json2.benefit.explanation"></div>
            </div>

            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">
                <h5>Limit:</h5><p flex ng-bind-html="change.json1.benefit.limit"></p>
            </div>
            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">
                <h5>Limit:</h5><p flex ng-bind-html="change.json2.benefit.limit"></p>
            </div>

        </div>





        <div ng-if="change.contentTypeId === 20" flex="90" layout-wrap layout="row" style="" class="benefit-changes" >

            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">
                <h5>Name:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.exclusion.name)"></p>
            </div>
            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">
                <h5>Name:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.exclusion.name)"></p>
            </div>

            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">
                <h5>Rules:</h5><div flex ng-bind-html="change.json1.exclusion.rules"></div>
            </div>
            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">
                <h5>Rules:</h5><div flex ng-bind-html="change.json2.exclusion.rules"></div>
            </div>

        </div>





        <div flex="10">
            <md-button   flex="50" wraps-tooltip="action-icon"  class="md-icon-button" style="margin:0 -6px;" ng-click="tabBook.library.openDocDiffDialog(change)"  aria-label="alert">
                <ng-md-icon icon="info" style="fill: #ccc; position: relative;" size="24"></ng-md-icon>
            </md-button>

<span ng-if="change.versionIdNew != 0">
            <md-button flex="50"  ng-click="tabBook.library.openWorkflowDialog(tabBook.library.childByStructureId(book,change.structureId), book, 'Reject'); $event.stopPropagation()"
                       ng-if="tabBook.library.viewReject(tabBook.library.childByStructureId(book,change.structureId), book); "
                       class="md-icon-button" style="margin:0 -6px;"  aria-label="alert">
                <ng-md-icon  icon="cancel" style="fill: #F44336; position: relative;  " size="24"></ng-md-icon>
            </md-button>
            <md-button flex="50"  ng-click="tabBook.library.approveContentDialog(tabBook.library.childByStructureId(book,change.structureId),book); $event.stopPropagation()"
                       ng-if="tabBook.library.viewApprove(tabBook.library.childByStructureId(book,change.structureId), book);  "
                       class="md-icon-button" style="margin:0 -6px;"  aria-label="alert">
                <ng-md-icon  icon="check_circle" style="fill: #4CAF50; position: relative;  " size="24"></ng-md-icon>
            </md-button>
</span>


        </div>


    </div>
    <md-divider></md-divider>

</md-list-item>
<!--<div layout="row" flex="100" ng-if="tabBook.library.showApproveAll(book)">-->
    <!--<div flex></div><md-button type="button" class="md-green" ng-click="tabBook.library.approveContentDialog('all',book);">approve all <ng-md-icon icon="check_circle" style="fill: white; padding-left:0px; position:relative; top:0px;" size="20"></ng-md-icon></md-button>-->
<!--</div>-->