<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">

    <div class="md-whiteframe-z1 default-box" style="" >
        <md-content layout-padding >
            <h2  flex>New Library</h2>


            <md-progress-circular style="position: absolute; top: 10px; left: 50%; margin-left: -50px;" class="animate-if"  md-diameter="80" md-mode="indeterminate" ng-if="newLibrary.isLoading"></md-progress-circular>

            <p ng-repeat="book in newLibrary.books"> {{book.bookId}} - <a ng-click="book.getBook()">{{book.bookCode}} - {{book.lastUpdated}}</a>  - - - {{book.Children[0].structureId}} </p>

        </md-content>
    </div>
</md-content>


