<md-toolbar ng-show="tooltip.showToolTip" class="tooltipplaceholder md-primary md-hue-2"></md-toolbar>
<md-toolbar layout="row" class="md-whiteframe-z0 md-primary md-hue-2 tooltipbar" ng-show="tooltip.showToolTip">
    <md-container layout="row">
        <md-button aria-label="Tool tips" class="md-icon-button" flex="none" ng-click="tooltip.hide()" wraps-tooltip="tooltip-close">
            <ng-md-icon icon="help" ></ng-md-icon>
        </md-button>
		<div flex="auto">
			<h4 wraps-tooltip="tooltip-title">{{tooltip.helpTitle}}<span style="opacity:0.4" ng-if="tooltip.hasPermissions('adminTooltips') && tooltip.helpCode != ''" wraps-tooltip="tooltip-help-code"> {{tooltip.helpCode}}</span></h4>
        	<p wraps-tooltip="tooltip-description">{{tooltip.helpText}}</p>
		</div>
		<md-button aria-label="Close" class="md-icon-button" flex="none" ng-click="tooltip.hide()" wraps-tooltip="tooltip-close">
            <ng-md-icon icon="close" ></ng-md-icon>
        </md-button>
    </md-container>
</md-toolbar>