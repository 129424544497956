(function () {
  'use strict';

  angular
    .module('home')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home', {
        url: '/home',

        views: {
          tooltip: {
              templateUrl: 'tooltip/tooltip.tpl.html',
              controller: 'TooltipCtrl',
              controllerAs: 'tooltip'
          },
          navigation: {
            templateUrl: 'navigation/navigation.tpl.html',
            controller: 'NavigationCtrl',
            controllerAs: 'navigation'
          },
          main: {
            templateUrl: 'home/home.tpl.html',
            controller: 'HomeCtrl',
            controllerAs: 'home'
          },
          footer: {
            templateUrl: 'footer/footer.tpl.html',
            controller: 'FooterCtrl',
            controllerAs: 'footer'
          }
        }
      });
  }
}());
