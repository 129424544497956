<div style="font-size:10px">

    <div  ng-if="vm.item.versions.Contents.contentTypeId <= 4" class="history_output" ng-bind-html="vm.item.versions.versiondata" ></div>


    <div ng-if="vm.item.versions.Contents.contentTypeId === 5"   layout="row" class="Benefit"  flex="100">


        <table width="100%">
            <thead>
                <tr>
                    <th>Benefits</th>
                    <th ng-bind-html="vm.book.levels.level1.name"></th>
                    <th ng-bind-html="vm.book.levels.level2.name"></th>
                    <th ng-bind-html="vm.book.levels.level3.name"></th>
                    <th ng-bind-html="vm.book.levels.level4.name"></th>
                    <th>Explanation of Benefits</th>
                </tr>
            </thead>
            <tbody>
            <tr>

                <td><div ng-bind-html="vm.nl2br(item.benefit.name)"></div></td>
                <td width="12%"><div ng-bind-html="vm.nl2br(vm.item.benefit.level1)"></div></td>
                <td width="12%"><div ng-bind-html="vm.nl2br(vm.item.benefit.level2)"></div></td>
                <td width="12%"><div ng-bind-html="vm.nl2br(vm.item.benefit.level3)"></div></td>
                <td width="12%"><div ng-bind-html="vm.nl2br(vm.item.benefit.level4)"></div></td>
                <td width="38%"><div ng-bind-html="vm.item.benefit.explanation"></div></td>
            </tr>

            </tbody>
        </table>

    </div>


    <div ng-if="vm.item.versions.Contents.contentTypeId === 6"   layout="row" class="Benefit Glossary"  flex="100">
        <table width="100%">
            <thead><tr><th>Defined term</th><th>Description</th><th>Search words</th></tr></thead>
            <tbody>
            <tr >
                <td width="25%"><div ng-bind-html="vm.item.glossary.term"></div></td>
                <td width="50%"><div ng-bind-html="vm.item.glossary.description"></div></td>
                <td width="25%"><div ng-bind-html="vm.item.glossary.words"></div></td>
            </tr>

            </tbody>
        </table>
    </div>

    <div ng-if="vm.item.versions.Contents.contentTypeId === 7"   layout="row" class="Benefit"  flex="100">
        <table width="100%">
            <thead><tr><th>Exclusion</th><th>Notes</th><th>Rules</th></tr></thead>
            <tbody>
            <tr >
                <td width="30%"><div ng-bind-html="vm.item.exclusion.name"></div></td>
                <td width="20%"><div ng-bind-html="vm.item.exclusion.notes"></div></td>
                <td width="50%"><div ng-bind-html="vm.item.exclusion.rules"></div></td>
            </tr>
            </tbody>
        </table>
    </div>

    <div ng-if="vm.item.versions.Contents.contentTypeId === 8"   layout="row" class="Benefit Glossary"  flex="100">
        <table width="100%">
            <thead><tr><th>Term</th><th>Description</th></tr></thead>
            <tbody>
            <tr >
                <td width="40%"><div ng-bind-html="vm.item.medical.term"></div></td>
                <td width="60%"><div ng-bind-html="vm.item.medical.description"></div></td>
            </tr>
            </tbody>
        </table>
    </div>




    <div ng-if="vm.item.versions.Contents.contentTypeId === 9"   layout="row" class="Benefit"  flex="100">
        <div>
            <div ng-bind-html="vm.item.brief.description | urlsToLinks"></div>
            <ul class="file-ui">
                <li ng-repeat="file in item.brief.filesList" style="font:smaller" class="file-li animate-if">
                    <a   ng-href="{{historydialog.getFile(file.fileLocation,file.fileName)}}" target="_blank" >
                        <ng-md-icon class="file-download"   icon="insert_photo" size="20"  aria-label="Upload complete"></ng-md-icon>{{file.fileName}}
                    </a>
                </li>
            </ul>
        </div>
    </div>

    <div ng-if="vm.item.versions.Contents.contentTypeId === 10"   layout="row" class="Benefit"  flex="100">
        <div>
            <div ng-bind-html="vm.item.artwork.description | urlsToLinks"></div>
            <ul class="file-ui">
                <li ng-repeat="file in item.artwork.filesList" style="font:smaller" class="file-li animate-if">
                    <a   ng-href="{{historydialog.getFile(file.fileLocation,file.fileName)}}" target="_blank" >
                        <ng-md-icon class="file-download"   icon="insert_photo" size="20"  aria-label="Upload complete"></ng-md-icon>{{file.fileName}}
                    </a>
                </li>
            </ul>
        </div>
    </div>




    <div ng-if="vm.item.versions.Contents.contentTypeId === 11"   layout="row" class="Benefit"  flex="100">
        <table width="100%">
            <thead>
                <tr>
                    <th ng-repeat="column in item.tableHeader track by $index">Column {{$index + 1}}</th>
                </tr>
            </thead>
            <tbody>
            <tr >
                <td width=""  ng-repeat="column in item.tableHeader track by $index"><div ng-bind-html="column.name"></div></td>
            </tr>
            </tbody>
        </table>
    </div>
    



    <div ng-if="vm.item.versions.Contents.contentTypeId === 12"   layout="row" class="Benefit"  flex="100">
            <table width="100%">
                <thead>
                    <tr>
                        <th ng-repeat="column in item.tableRow track by $index">Column {{$index + 1}}</th>
                    </tr>
                </thead>
                <tbody>
                <tr >
                    <td width=""  ng-repeat="column in item.tableRow track by $index"><div ng-bind-html="column.data"></div></td>
                </tr>
                </tbody>
            </table>
        </div>
        
            
    <div  ng-if="vm.item.versions.Contents.contentTypeId == 14" class="history_output" ng-bind-html="vm.item.versions.versiondata" ></div>

    <div  ng-if="vm.item.versions.Contents.contentTypeId == 15" class="history_output" ng-bind-html="vm.item.versions.versiondata" ></div>

    <div  ng-if="vm.item.versions.Contents.contentTypeId == 16" class="history_output" ng-bind-html="vm.item.versions.versiondata" ></div>

    <div ng-if="vm.item.versions.Contents.contentTypeId === 17"   layout="row" class="Benefit"  flex="100">
        <table width="100%">
            <thead>
            <tr>
                <th>Benefits</th>
                <th>Explanation of Benefits</th>
                <th>Limits</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td width="30%"><div ng-bind-html="vm.nl2br(item.benefit.name)"></div></td>
                <td width="50%"><div ng-bind-html="vm.item.benefit.explanation"></div></td>
                <td width="20%"><div ng-bind-html="vm.nl2br(vm.item.benefit.limit)"></div></td>
            </tr>
            </tbody>
        </table>
    </div>


    <div  ng-if="vm.item.versions.Contents.contentTypeId == 18" class="history_output" ng-bind-html="vm.item.versions.versiondata" ></div>

    <div  ng-if="vm.item.versions.Contents.contentTypeId == 19" class="history_output" ng-bind-html="vm.item.versions.versiondata" ></div>

    <div ng-if="vm.item.versions.Contents.contentTypeId === 20"   layout="row" class="Benefit"  flex="100">
        <table width="100%">
            <thead><tr><th>Exclusion</th><th>Rules</th></tr></thead>
            <tbody>
            <tr >
                <td width="30%"><div ng-bind-html="vm.item.exclusion.name"></div></td>
                <td width="70%"><div ng-bind-html="vm.item.exclusion.rules"></div></td>
            </tr>
            </tbody>
        </table>
    </div>

    <div  ng-if="vm.item.versions.Contents.contentTypeId == 21" class="history_output" ng-bind-html="vm.item.versions.versiondata" ></div>


</div>