(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name maintenance.filter:titlecase
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('drive')
    .filter('fileurl', fileurl);

  function fileurl(apiUrl) {
    return function(ngfName) {
        return encodeURI(apiUrl + '/containers/getfile/' + ngfName);
    };
  }
}());
