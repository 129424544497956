<md-content layout="row" layout-wrap layout-align="center start" class="default-content">

    <div class="md-whiteframe-z1 default-box" style="" ng-if="maintenance.hasAccess == true">


        <md-progress-circular style="position: absolute; top: 0; left: 50%; margin-left: -50px;" md-diameter="100"
                              md-mode="indeterminate" ng-show="maintenance.isLoading"></md-progress-circular>

        <md-data-table-toolbar layout-wrap>
            <h2 flex wraps-tooltip="maintenance-title">Meta List Maintenance</h2>
            <md-button aria-label="Download CSV" class="md-icon-button" ng-csv="maintenance.downloadCsv()"  lazy-load="true" filename="{{maintenance.tableName | lowercase}}-download.csv" add-bom="true" charset="utf-8" csv-label="true">
                <ng-md-icon icon="file_download" style="fill: grey; " size="24" wraps-tooltip="table-data-download"></ng-md-icon>
            </md-button>
            <md-button aria-label="Open search" class="md-icon-button" ng-hide="maintenance.searchMaintenance"
                       ng-click="maintenance.searchMaintenance=true" wraps-tooltip="maintenance-search-button"
                       aria-label="Open search">
                <ng-md-icon icon="search" style="fill: grey;" size="24"></ng-md-icon>
            </md-button>
            <md-button class="md-raised md-accent" ng-click="maintenance.openEdit()"
                       wraps-tooltip="maintenance-add-btn"
                       ng-if="(maintenance.tableBehaviour == 1 && maintenance.tableAdmin == true) || maintenance.tableBehaviour == 2 || (maintenance.tableBehaviour == 3 && maintenance.tableAdmin == true)"
            >Add Row
            </md-button>
            <div layout="row" layout-align="space-between start" flex="100" class="md-caption" style="margin-bottom:8px">
                <span>Selected list: <strong>{{maintenance.tableName|titlecase}}</strong></span>
                <span>Division behaviour: <strong ng-if="maintenance.tableBehaviour == 1">Partially shared</strong><strong ng-if="maintenance.tableBehaviour == 2">Separate</strong><strong ng-if="maintenance.tableBehaviour == 3">Fully shared</strong></span>
                <span>Access level: <strong ng-if="maintenance.tableAdmin">Full admin</strong><strong ng-if="!maintenance.tableAdmin">Division admin</strong></span>
                <span>Your division: <strong>{{maintenance.divisionName|titlecase}}</strong></span>
            </div>
        </md-data-table-toolbar>

        <!--<md-data-table-toolbar>-->
        <div>
            <md-tabs md-selected="maintenance.selectedTabIndex" md-border-bottom md-autoselect md-swipe-content
                     md-dynamic-height>
                <md-tab ng-repeat="(tableName, tableProperties) in maintenance.tables"
                        ng-disabled="tableProperties.disabled">
                    <md-tab-label>
                        <span wraps-tooltip="maintenance-tab">{{tableProperties.tableLabel | titlecase}}</span>
                    </md-tab-label>
                    <md-tab-body>
                        <md-data-table-container ng-if="maintenance.tableName">
                            <table md-data-table> <!--md-row-select="selected"-->
                                <thead ng-show="maintenance.searchMaintenance">
                                <tr>
                                    <td ng-if="maintenance.tableBehaviour != 3 || (maintenance.tableBehaviour == 3 && maintenance.tableAdmin == true)"></td>
                                    <td ng-repeat="col in maintenance.cols" wraps-tooltip="maintenance-search-input">
                                        <md-input-container
                                                ng-if="maintenance.tables[maintenance.tableName].dropDown[col.name].childColName != col.name">
                                            <input ng-model="maintenance['filter' + maintenance.tableName][col.name]"
                                                   style="width: 90%;" aria-label="{{'Filter ' + col.name}}">
                                        </md-input-container>
                                        <md-input-container
                                                ng-if="maintenance.tables[maintenance.tableName].dropDown[col.name].childColName == col.name">
                                            <input ng-model="maintenance['filter' + maintenance.tableName][maintenance.tables[maintenance.tableName].dropDown[col.name].tableName][maintenance.tables[maintenance.tableName].dropDown[col.name].childColName]"
                                                   style="width: 90%;" aria-label="{{'Filter ' + col.name}}">
                                        </md-input-container>
                                    </td>

                                    <td ng-if="maintenance.tableAdmin == true && maintenance.tableBehaviour != 3" ng-show="true"></td><!--hiding divisions-->

                                    <td style="padding:0 10px 0 3px">
                                        <div class="md-actions" style="float:right">
                                            <md-button aria-label="Close Search" class="md-icon-button"
                                                       ng-click="maintenance.searchMaintenance=false; maintenance['filter' + maintenance.tableName]={}"
                                                       wraps-tooltip="maintenance-search-close">
                                                <ng-md-icon icon="close" style="fill: grey; " size="24"></ng-md-icon>
                                            </md-button>
                                        </div>
                                    </td>
                                </tr>
                                </thead>
                                <thead>
                                <tr>
                                    <!--<th order-by="id" numeric>ID</th>-->
                                    <th style="width:80px; text-align:center;">
                                        <md-button ng-if="maintenance.tableAdmin == true || maintenance.tableBehaviour == 2 || (maintenance.tableBehaviour == 3 && maintenance.tableAdmin == true)" type="button"
                                                   class="md-icon-button md-accent"
                                                   ng-disabled="maintenance.checkObj(maintenance.deleteArray)"
                                                   ng-click="maintenance.deleteRows(maintenance.deleteArray)"
                                                   aria-label="Delete Selected Rows"
                                                   wraps-tooltip="maintenance-delete-btn">
                                            <ng-md-icon icon="delete"></ng-md-icon>
                                        </md-button>
                                    </th>
                                    <th ng-repeat="col in maintenance.cols" ng-click="sortBy(col.name)"
                                        wraps-tooltip="maintenance-table-heading">{{col.name | titlecase}}
                                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                                                    ng-class="{active: maintenance.orderBy === col.name || maintenance.orderBy[0] === col.name || maintenance.orderBy === maintenance.tables[maintenance.tableName].dropDown[col.name].tableName + '.' + col.name , reverse: maintenance.reverse}"></ng-md-icon>
                                    </th>
                                    <th ng-if="maintenance.tableAdmin == true && maintenance.tableBehaviour != 3" ng-show="true">Divisions</th><!--hiding divisions-->
                                    <th style="width: 80px;"></th>
                                </tr>
                                </thead>


                                <tbody>
                                <tr ng-repeat="row in maintenance.filteredRows = (maintenance.rows | filter:maintenance['filter' + maintenance.tableName]) | orderBy:maintenance.orderBy:maintenance.reverse | limitTo: maintenance.maintQuery.limit: (maintenance.maintQuery.page- 1) * maintenance.maintQuery.limit track by row[maintenance.pk]"
                                    class="data-rows" ng-if="maintenance.tableName == tableName"
                                    wraps-tooltip="maintenance-table-row">
                                    <td style="text-align: center;">
                                        <md-checkbox ng-if="maintenance.tableAdmin == true || maintenance.tableBehaviour == 2 || (maintenance.tableBehaviour == 3 && maintenance.tableAdmin == true)"
                                                     ng-model="maintenance.deleteArray[row[maintenance.pk]]"
                                                     ng-click="$event.stopPropagation()"
                                                     wraps-tooltip="maintenance-select-row">
                                            <span ng-hide="true">Select Row</span>
                                        </md-checkbox>
                                        <md-switch
                                                ng-if="maintenance.tableAdmin == false && maintenance.tableBehaviour == 1"
                                                name="{{maintenance.tableName}}_{{row[maintenance.pk]}}"
                                                ng-model="row.selected"
                                                ng-change="maintenance.toggleDivisionMeta(row)"
                                                aria-label="{{maintenance.tableName}} toggle switch"
                                        >
                                        </md-switch>
                                    </td>
                                    <td ng-repeat="col in maintenance.cols">
                                        <span ng-if="maintenance.tables[maintenance.tableName].dropDown[col.name].childColName == col.name">{{row[maintenance.tables[maintenance.tableName].dropDown[col.name].tableName][col.name]}}</span>
                                        <span ng-if="maintenance.tables[maintenance.tableName].dropDown[col.name].childColName != col.name">{{row[col.name]}}</span>
                                    </td>
                                    <td ng-if="maintenance.tableAdmin == true && maintenance.tableBehaviour != 3" ng-show="true"><span>{{maintenance.joinDivisions(row.divisions)}}</span></td><!--hiding divisions-->
                                    <td>
                                        <div class="md-actions" style="float:right" ng-if="(maintenance.tableBehaviour == 1 && maintenance.tableAdmin == true) || maintenance.tableBehaviour == 2 || (maintenance.tableBehaviour == 3 && maintenance.tableAdmin == true)">
                                            <md-button aria-label="Edit Row" class="md-icon-button md-default"
                                                       ng-click="$storage.selectedRow  = row[maintenance.pk]; maintenance.openEdit(row)"
                                                       wraps-tooltip="maintenance-edit-row">
                                                <ng-md-icon icon="mode_edit"></ng-md-icon>
                                            </md-button>
                                        </div>
                                    </td>
                                </tr>


                                </tbody>


                            </table>

                        </md-data-table-container>
                        <md-data-table-pagination md-limit="maintenance.maintQuery.limit"
                                                  md-page="maintenance.maintQuery.page"
                                                  md-total="{{maintenance.filteredRows.length}}"></md-data-table-pagination>

                    </md-tab-body>
                </md-tab>
            </md-tabs>
        </div>

        <!--<md-data-table-pagination md-limit="users.usersQuery.limit" md-page="users.usersQuery.page" md-total="{{users.filteredUsers.length}}"></md-data-table-pagination>-->


    </div>
    <div class="md-whiteframe-z1 default-box" style="" ng-if="maintenance.hasAccess === false">
        <md-data-table-toolbar><h2 flex>Access denied<br/><br/><br/></h2>

        </md-data-table-toolbar>
    </div>
    <div class="md-whiteframe-z1 default-box" style="" ng-if="maintenance.hasAccess === null">
        <md-data-table-toolbar><h2 flex><br/></h2></md-data-table-toolbar>
        <md-progress-circular style="position: absolute; top: 10px; left: 50%; margin-left: -50px;" md-diameter="80"
                              md-mode="indeterminate"></md-progress-circular>
    </div>

    <md-sidenav class="md-sidenav-right md-whiteframe-z3 library-sidenav" md-component-id="right"
                style="position: fixed;">
        <row-edit></row-edit>
    </md-sidenav>

</md-content>
