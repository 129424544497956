(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name library.directive:libraryContentRow
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="library">
       <file name="index.html">
        <library-content-row></library-content-row>
       </file>
     </example>
   *
   */
  angular
    .module('users')
    .directive('usersRightMenu', rightMenu);

  function rightMenu($compile) {
    return {
      restrict: "E",
      templateUrl: 'users/users-directives/users-rightMenu.tpl.html',
      scope: false,
      link: function(scope, element, attributes){
        //scope.book = scope.$eval(attributes.book);
      }
    };
  }

}());
