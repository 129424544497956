angular
    .module('library')
    .component('inputWfSpeedial', {
        templateUrl: 'library/library-components/right-menu/input-wf-speedial/input-wf-speedial.tpl.html',
        controller: inputWfSpeedialCtrl,
        controllerAs:'inputWfSpeedial',
        bindings:{
            content:'=',
            disabled:'='
        }

    });

inputWfSpeedialCtrl.$inject = ['$scope'];
function inputWfSpeedialCtrl($scope){
    var vm = this;
    vm.addWorkflow = $scope.$parent.tabWorkflow.addWorkflow;
    vm.enableWorkflowArtwork = $scope.$parent.tabWorkflow.enableWorkflowArtwork;
}