(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.notifications.controller:notificationsCtrl
   *
   * @description
   *
   */
  angular
    .module('library.notifications')
    .controller('NotificationsCtrl', NotificationsCtrl);

  function NotificationsCtrl(
      $localStorage,
      $location,
      $mdDialog,
      $q,
      $scope,
      $state,
      $window,
      activeContent,
      Books,
      Events,
      History,
      Notifications,
      Structures,
      Users,
      Wraps,
      WrapsBooks
  ) {
    var vm = this;
    vm.ctrlName = 'NotificationsCtrl';
	vm.isLoading = true;
	vm.list = {};
	vm.now = new Date();

    $scope.$storage = $localStorage;
    if(!Users.isAuthenticated()){$location.path('/');}




    vm.initNotifications = function() {
        var promises = {};
        var tabArray = [
           'blackAssetsByOwner',
            'documentsWithActionsByOwnerPersonal',
            'documentsWithActionsByOwnerTeam',
            'documentsInReview'
        ];

        _.forEach(tabArray, function (tab, ind){
            var deferred = $q.defer();
            var actionStatus = [];
            var docStatus = '';
            var owner = '';
            var sortOrder = 'bookId';
            if( tab === 'documentsInReview' ){
                docStatus = '(r)';
                owner = Wraps.user.firstName + ' ' + Wraps.user.lastName;
                sortOrder = 'reviewDate';
            } else {
                actionStatus = [(ind + 1)];
            }

            var searchArray = {
                docTypeName: '',
                documentStatus: docStatus,
                sortDirection: 'asc',
                sortOrder: sortOrder,
                ownerFullName: owner,
                workflowStatus: '',
                bookCode: '',
                division: '',
                actionStatus: actionStatus,
                productName: '',
                bookSearchId: '',
                insurerDetails: '',
            };

            var filterObject = {'startRow':0, 'noResults':0, 'searchArray':searchArray};

            Books.getLibrarySearch(filterObject).$promise.then(function (results) {
                if( tab === 'documentsInReview' ){
                  //results.data.countDown = 10;
                  console.log("results.data",results.data);
                }
                vm.list[tab] = results.data;
                deferred.resolve();
            });
            promises[tab] = deferred.promise;
        });

        var countsDeferred = $q.defer();
        Notifications.getCounts().then(function () {
            vm.count = Notifications.count;
            countsDeferred.resolve();
        });

        promises.counts = countsDeferred.promise;

        return $q.all(promises);
    };

    vm.initNotifications().then(function(){
        vm.isLoading = false;
    });


    vm.dialogHide = function(data) {
      $mdDialog.hide(data);
    };
    vm.dialogCancel = function(data) {
      $mdDialog.cancel(data);
    };


    vm.openDocument = function (bookId) {
		  $state.go('library',{bookId : bookId});
    };


    if(!vm.blackAssetsQuery) {
      vm.blackAssetsQuery = {
        order: 'bookId'
      };
    }

    vm.downloadCSV = function () {
        var actionStatus = [];
        var docStatus = '';
        var owner = '';
        var sortOrder = 'bookId';
        if( vm.activeContent === 3 ){
            docStatus = '(r)';
            owner = Wraps.user.firstName + ' ' + Wraps.user.lastName;
            sortOrder = 'reviewDate';
        } else {
            actionStatus = [(vm.activeContent + 1)];
        }


      var searchArray = {
          docTypeName: '',
          documentStatus: docStatus,
          sortDirection: 'asc',
          sortOrder: sortOrder,
          ownerFullName: owner,
          workflowStatus: '',
          bookCode: '',
          division: '',
          actionStatus: actionStatus,
          productName: '',
          bookSearchId: '',
          insurerDetails: '',
          insurerName: '',
          psiCode: ''
      };

        var deferred = $q.defer();
        vm.isLoading = true;
        WrapsBooks.downloadCSV(searchArray).then(function(csv){
            vm.isLoading = false;
            deferred.resolve(csv);
        });
        return deferred.promise;
    };









  }
}());
