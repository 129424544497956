<md-dialog aria-label="Black Assets"  class="notifications">

    <md-toolbar>
        <div class="md-toolbar-tools">
            <h2 wraps-tooltip="dialog-notifications-title">Notifications</h2>
            <span flex></span>

            <md-button aria-label="Refresh notifications" class="md-icon-button" ng-click="notifications.initNotifications()" wraps-tooltip="notifications-refresh-icon">
                <ng-md-icon icon="refresh" style="fill: white;" size="24"></ng-md-icon>
            </md-button>
            <md-button aria-label="Download CSV" class="md-icon-button" ng-csv="notifications.downloadCSV()"  lazy-load="true" filename="actions-report.csv" add-bom="true" charset="utf-8" csv-label="true">
                <ng-md-icon icon="file_download" size="24" wraps-tooltip="notifications-download-csv"></ng-md-icon>
            </md-button>
            <md-button ng-click="notifications.dialogCancel(false);" class="md-icon-button" wraps-tooltip="dialog-notifications-close">
                <ng-md-icon  icon="close" style="fill: white;" size="24"  aria-label="Close dialog"></ng-md-icon>
            </md-button>
        </div>
    </md-toolbar>
    <md-dialog-content class="dialog-content big" style="">

        <md-content class="md-padding">

            <md-tabs md-dynamic-height md-border-bottom md-selected="notifications.activeContent">
                <md-tab>
					<md-tab-label><span wraps-tooltip="dialog-notifications-tab-black-assets">Black Assets ({{notifications.count.blackAssetsByOwner.blackAssets}})</span></md-tab-label>
                    <md-tab-body>
						<md-progress-linear ng-if="notifications.isLoading" md-mode="indeterminate"></md-progress-linear>
                        <md-data-table-container ng-if="notifications.list.blackAssetsByOwner">
                            <table md-data-table  > <!--md-row-select="selected"-->
                                <thead  md-order="notifications.list.blackAssetsByOwner.order" ng-if="notifications.list.blackAssetsByOwner.length > 0">
                                <tr wraps-tooltip="dialog-notifications-heading-black-assets">
                                    <th></th>
                                    <th order-by="bookId">ID</th>
                                    <th order-by="bookName">Book Name</th>
                                    <th order-by="bookCode">Code</th>
                                    <th order-by="lastUpdated">Last Updated</th>
                                    <th order-by="docTypeName">Doc Type</th>
                                    <th order-by="expiryDate">Expiry Date</th>
                                    <th order-by="earlyExpiryDate">Early Expiry Date</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr ng-repeat="blackAsset in notifications.list.blackAssetsByOwner | orderBy: notifications.list.blackAssetsByOwner.order" class="data-rows" ng-click="notifications.dialogCancel(false); notifications.openDocument(blackAsset.bookId)"  wraps-tooltip="dialog-notifications-row-black-assets">
                                    <td></td>
                                    <td>{{blackAsset.bookId}}</td>
                                    <td>{{blackAsset.bookName}}</td>
                                    <td>{{blackAsset.bookCode}}</td>
                                    <td>{{blackAsset.lastUpdated | date:'dd/MM/yyyy'}}</td>
                                    <td>{{blackAsset.DocTypes.docTypeName}}</td>
                                    <td>{{blackAsset.expiryDate | date:'dd/MM/yyyy'}}</td>
                                    <td>{{blackAsset.earlyExpiryDate | date:'dd/MM/yyyy'}}</td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </md-data-table-container>
						<p ng-if="(!notifications.list.blackAssetsByOwner || notifications.list.blackAssetsByOwner.length == 0) && !notifications.isLoading" wraps-tooltip="dialog-notifications-no-black-assets">You have no black assets</p>
                    </md-tab-body>
                </md-tab>


                <md-tab>
					<md-tab-label><span wraps-tooltip="dialog-notifications-tab-actions-user">Personal Actions ({{notifications.count.documentsWithActionsByOwnerPersonal.actions}})</span></md-tab-label>
                    <md-tab-body>
						<md-progress-linear ng-if="notifications.isLoading" md-mode="indeterminate"></md-progress-linear>

                        <md-data-table-container ng-if="notifications.list.documentsWithActionsByOwnerPersonal">


                            <table md-data-table  > <!--md-row-select="selected"-->
                                <thead  md-order="notifications.list.documentsWithActionsByOwnerPersonal.order" ng-if="notifications.list.documentsWithActionsByOwnerPersonal.length > 0">
                                <tr wraps-tooltip="dialog-notifications-heading-actions-user">
                                    <th></th>

                                    <th order-by="bookId">ID</th>
                                    <th order-by="bookName">Book Name</th>
                                    <th order-by="bookCode">Book Code</th>
                                    <th order-by="lastUpdated">Last Updated</th>
                                    <th order-by="docTypeName">Doc Type</th>
                                    <th order-by="currentWorkflowRole">Workflow Role</th>
                                    <th order-by="currentWorkflowContent">Workflow Content</th>
                                    <!--<th order-by="currentWorkflowUser">Workflow User</th>-->
                                    <th order-by="currentWorkflowTeam">Workflow Team</th>

                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr ng-repeat="action in notifications.list.documentsWithActionsByOwnerPersonal | orderBy: notifications.list.documentsWithActionsByOwnerPersonal.order" class="data-rows" ng-click="notifications.dialogCancel(false); notifications.openDocument(action.bookId)" wraps-tooltip="dialog-notifications-row-actions-user">
                                    <td></td>
                                    <td>{{action.bookId}}</td>
                                    <td>{{action.bookName}}</td>
                                    <td>{{action.bookCode}}</td>
                                    <td>{{action.lastUpdated | date: 'dd/MM/yyyy'}}</td>
                                    <td>{{action.DocTypes.docTypeName}}</td>
                                    <td>{{action.workflowStatus}}</td>
                                    <td>{{action.currentWorkflow.content | uppercase}}</td>
                                    <!--<td>{{action.currentWorkflowUser}}</td>-->
                                    <td>{{action.currentWorkflowTeamName}}</td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </md-data-table-container>
						<p ng-if="(!notifications.list.documentsWithActionsByOwnerPersonal || notifications.list.documentsWithActionsByOwnerPersonal.length == 0) && !notifications.isLoading" wraps-tooltip="dialog-notifications-no-actions-user">You have no personal actions</p>
                    </md-tab-body>
                </md-tab>


                <md-tab>
					<md-tab-label><span wraps-tooltip="dialog-notifications-tab-actions-team">Team Actions ({{notifications.count.documentsWithActionsByOwnerTeam.actions}})</span></md-tab-label>
                    <md-tab-body>
						<md-progress-linear ng-if="notifications.isLoading" md-mode="indeterminate"></md-progress-linear>

                        <md-data-table-container ng-if="notifications.list.documentsWithActionsByOwnerTeam">
                            <table md-data-table  > <!--md-row-select="selected"-->
                                <thead  md-order="notifications.list.documentsWithActionsByOwnerTeam.order" ng-if="notifications.list.documentsWithActionsByOwnerTeam.length > 0">
                                <tr wraps-tooltip="dialog-notifications-heading-actions-team">
                                    <th></th>

                                    <th order-by="bookId">ID</th>
                                    <th order-by="bookName">Book Name</th>
                                    <th order-by="bookCode">Book Code</th>
                                    <th order-by="lastUpdated">Last Updated</th>
                                    <th order-by="docTypeName">Doc Type</th>
                                    <th order-by="currentWorkflowRole">Workflow Role</th>
                                    <th order-by="currentWorkflowContent">Workflow Content</th>
                                    <th order-by="currentWorkflowTeam">Workflow Team</th>

                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr ng-repeat="action in notifications.list.documentsWithActionsByOwnerTeam | orderBy: notifications.list.documentsWithActionsByOwnerTeam.order" class="data-rows" ng-click="notifications.dialogCancel(false); notifications.openDocument(action.bookId)" wraps-tooltip="dialog-notifications-row-actions-team">
                                    <td></td>
                                    <td>{{action.bookId}}</td>
                                    <td>{{action.bookName}}</td>
                                    <td>{{action.bookCode}}</td>
                                    <td>{{action.lastUpdated | date:'dd/MM/yyyy'}}</td>
                                    <td>{{action.DocTypes.docTypeName}}</td>
                                    <td>{{action.workflowStatus}}</td>
                                    <td>{{action.currentWorkflow.content | uppercase}}</td>
                                    <td>{{action.currentWorkflowTeamName}}</td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>
                        </md-data-table-container>
						<p ng-if="(!notifications.list.documentsWithActionsByOwnerTeam || notifications.list.documentsWithActionsByOwnerTeam.length == 0) && !notifications.isLoading" wraps-tooltip="dialog-notifications-no-actions-team">You have no team actions</p>
                    </md-tab-body>
                </md-tab>


                <md-tab>
                <md-tab-label><span wraps-tooltip="dialog-notifications-tab-actions-team">In Review ({{notifications.count.documentsInReview.inReview}})</span></md-tab-label>
                <md-tab-body>
                    <md-progress-linear ng-if="notifications.isLoading" md-mode="indeterminate"></md-progress-linear>

                    <md-data-table-container ng-if="notifications.list.documentsInReview">
                        <table md-data-table  > <!--md-row-select="selected"-->
                            <thead  md-order="notifications.list.documentsInReview.order" ng-if="notifications.list.documentsInReview.length > 0">
                            <tr wraps-tooltip="dialog-notifications-heading-actions-team">
                                <th></th>

                                <th order-by="bookId">ID</th>
                                <th order-by="bookName">Book Name</th>
                                <th order-by="bookCode">Book Code</th>
                                <th order-by="docTypeName">Doc Type</th>
                                <th order-by="reviewDate">Review Date</th>
                                <th order-by="expiryDate">Expiry Date</th>
                                <th order-by="expiryDate">Days until Expiry</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr ng-repeat="action in notifications.list.documentsInReview | orderBy: notifications.list.documentsInReview.order" class="data-rows" ng-click="notifications.dialogCancel(false); notifications.openDocument(action.bookId)" wraps-tooltip="dialog-notifications-row-actions-team">
                                <td></td>
                                <td>{{action.bookId}}</td>
                                <td>{{action.bookName}}</td>
                                <td>{{action.bookCode}}</td>
                                <td>{{action.DocTypes.docTypeName}}</td>
                                <td>{{action.reviewDate | date: 'dd/MM/yyyy'}}</td>
                                <td>{{action.expiryDate | date: 'dd/MM/yyyy'}}</td>
                                <td>{{action.reviewCountdown}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </md-data-table-container>
                    <p ng-if="(!notifications.list.documentsInReview || notifications.list.documentsInReview.length == 0) && !notifications.isLoading" wraps-tooltip="dialog-notifications-no-actions-team">You have no documents in review</p>
                </md-tab-body>
            </md-tab>
            </md-tabs>


        </md-tabs>

            <md-progress-circular   md-diameter="40" md-mode="indeterminate" ng-show="pushdialog.isLoading"></md-progress-circular>

        </md-content>

    </md-dialog-content>
    <md-dialog-actions layout="row">
        <span flex></span>
        <md-button class="md-primary md-raised md-button"  ng-click="notifications.dialogCancel(false)" wraps-tooltip="dialog-notifications-close">Close</md-button>
    </md-dialog-actions>
</md-dialog>
