(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableEmphasis', readableEmphasis);

  function readableEmphasis(Wraps) {
    return function (id) {
      var emphasis;
      if ((emphasis = _.find(Wraps.emphasis, {id: parseInt(id)})) && emphasis.name) {
        return emphasis.name;
      }
      return null;
    };
  }
}());
