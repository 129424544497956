
<svg width="100%" height="100%" viewBox="0 0 36 36" class="donut">
	<circle ng-if="total" class="doughnut-total" cx="18" cy="18" r="15.91549430918954" stroke="{{total.colour}}" fill="transparent" stroke-width="4" stroke-alignment="inner"></circle>
	<circle ng-if="!total" class="doughnut-total" cx="18" cy="18" r="15.91549430918954" stroke="{{primary.colour}}" fill="transparent" stroke-width="4" stroke-alignment="inner" stroke-dasharray="{{primary.value}} {{100-primary.value}}" stroke-dashoffset="25"></circle>
	<circle ng-if="total && primary" class="doughnut-segment" cx="18" cy="18" r="15.91549430918954" fill="transparent" stroke="{{primary.colour}}" stroke-width="4" stroke-alignment="inner" stroke-dasharray="{{primary.value/total.value*100}} {{(1-primary.value/total.value)*100}}" stroke-dashoffset="25"></circle>
	<circle ng-if="total" ng-repeat="segment in series" class="doughnut-segment" cx="18" cy="18" r="15.91549430918954" fill="transparent" stroke="{{segment.colour}}" stroke-width="4" stroke-alignment="inner" stroke-dasharray="{{segment.value/total.value*100}} {{(1-segment.value/total.value)*100}}"  ng-init="segment.runningTotal = series[$index-1].runningTotal + segment.value" stroke-dashoffset="{{ 125 -((series[$index-1].runningTotal+0)/total.value*100)}}"></circle>
	<circle ng-if="!total" ng-repeat="segment in series" class="doughnut-segment" cx="18" cy="18" r="15.91549430918954" fill="transparent" stroke="{{segment.colour}}" stroke-width="4" stroke-alignment="inner" stroke-dasharray="{{segment.value}} {{100-segment.value}}"  ng-init="segment.runningTotal = series[$index-1].runningTotal + segment.value" stroke-dashoffset="{{ 125 -((series[$index-1].runningTotal+0))}}"></circle>
	<g transform="translate(18,18)" class="chart-text">
		<text x="0" y="0" class="chart-primary" ng-if="primary">
		  {{primary.value}}<tspan ng-if="!total">%</tspan>
		</text>
		<text x="0" y="0" class="chart-primary" ng-if="!primary && total">
		  {{total.value | number:0}}
		</text>
		<text x="0" y="4" class="chart-total" ng-if="primary && total">
		  /{{total.value}}
		</text>
	  </g>
</svg>
