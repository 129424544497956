(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.pushdialog.controller:PushdialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.pushdialog')
    .controller('PushdialogCtrl', PushdialogCtrl);

  function PushdialogCtrl($localStorage, $mdDialog, $q, $scope, activeContent, Books, Events, History, Structures, Users, Wraps) {
    var vm = this;
    vm.ctrlName = 'PushdialogCtrl';

    $scope.$storage = $localStorage;
    if(!Users.isAuthenticated()){$location.path('/');}

    vm.activeContent = activeContent;

    Structures.find({
      filter: {"where":{"contentId":vm.activeContent.contentId}, "include": [{"versions":{"Contents":"ContentTypes"}},"Books"]}
    }).$promise.then(function(data){
      _.remove(data, function (item) { if(!item.Books){return item;} });
      _.remove(data, function (item) { if(item.Books){return item.Books.status > 0;} });

      _.forEach(data, function (item, key) {

        if(item.versions.Contents.contentTypeId == 1 && item.versions.versiondata.charAt(0) === "{"){
          var json = angular.fromJson(item.versions.versiondata);
          item.versions.versiondata = json.chapterCopy;
        }

        if (item.versions.Contents.ContentTypes.isJson === true) {
          Wraps.json2Obj(item);
        }
        try{  item.Books.approvalList = angular.fromJson(item.Books.approvalList); } catch (err) { }
        item.Books.userInBook = userInBookApprovalList(item.Books, $scope.$storage.user);
        if(item.Books.approvalList && item.Books.approvalList[item.Books.approvalStatus]){
          item.Books.currentWorkflowStatus = item.Books.approvalList[item.Books.approvalStatus];
        }
      });
      _.remove(data, function (item) { if(item.Books.userInBook[0] != item.Books.approvalStatus){return item;} });

      vm.contentlist = data;
    });


// gets array of indexes current user is in book
    var userInBookApprovalList = function (book, user) {
      var indexes = [];
      _.forEach(book.approvalList, function (item, index) {
        item.role = parseInt(item.role);
        if((item.role === 3 || item.role === 8) && book.bookOwner === $scope.$storage.user.id ){

        }else{
          if(item.user === user.id && item.team === user.teamId ){//&& item.role === user.roleId){
            indexes.push(index);
          }else if((angular.isUndefined(item.user) || item.user === '') && item.team === user.teamId ){//&& item.role === user.roleId){
            indexes.push(index);
          }else if((angular.isUndefined(item.user) || item.user === '') && (angular.isUndefined(item.team) || item.team === '') ){//&& item.role === user.roleId){
            indexes.push(index);
          }
        }
      });
      return indexes;
    }



    vm.selected = [];
    vm.setSelected = function (chapter){
      if(chapter.selected>0){
        vm.selected.push(chapter);
      }else{
        _.pullAt(vm.selected, _.findKey(vm.selected, 'structureId', chapter.structureId));
      }
    }




    vm.pushContent = function (selected){
      vm.isLoading = true;


          //TODO this might want to copy previous RFC?

          angular.forEach(selected, function (item) {
            Structures.upsert({"versionId": activeContent.versionId, "structureId": item.structureId}, function () {
                  Books.upsert({"bookId": item.Books.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                  History.create({
                    "structureId": item.structureId,
                    "versionIdOld": item.versionId,
                    "versionIdNew": activeContent.versionId,
                    "historyId": 0
                  }, function (historyResponse) {

                    //console.log(vm.activeContent);
                    Events.findOne({filter:{"order":"created DESC", "limit":1, "where":{"bookId":vm.activeContent.bookId, "structureId":vm.activeContent.structureId, "eventType":"Edited"}}}).$promise.then(function (results) {
                      Events.create({
                        "eventId": 0,
                        "bookId": item.Books.bookId,
                        "structureId": item.structureId,
                        "contentId": vm.activeContent.contentId,
                        "historyId": historyResponse.historyId,
                        "userId": $scope.$storage.user.id,
                        "eventType": "Changed",
                        "eventData": results.eventData
                      });
                    });

                  });
                  vm.dialogHide(true); //true = refresh parent
                  vm.isLoading = false;
                }, function () {
                  //console.log('error saving');
                }
            );
          });

    }

    vm.nl2br = function(string){
      try {
        return string.replace(/\n/g, '<br>');
      }catch(err){
        //return false;
      }
    }

    vm.json2Obj = function(obj){

      try{ obj = angular.extend(obj,angular.fromJson(obj.versions.versiondata));}
      catch (err){    console.log(err.name + ': "' + err.message +  '" occurred when assigning string.'); return false;}
      console.log(obj.benefit);
      return obj;

    }


    vm.dialogHide = function(data) {
      $mdDialog.hide(data);
    };
    vm.dialogCancel = function(data) {
      $mdDialog.cancel(data);
    };









  }
}());
