(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name drive.service:Drive
   *
   * @description
   *
   */
  angular
    .module('drive')
    .service('Drive', Drive);

  function Drive($q, Books, DocTypeCats, DocTypes, Insurers, Products, apiUrl) {
    var self = this;

    self.get = function () {
      return 'Drive';
    };
	self.data = {};
	self.data.insurers = {};
	self.data.products = {};
	self.data.categories = {};
	self.data.docTypes = {};
	self.data.docs = [];
	self.data.docDetail = {};

	self.getRows = function (newParams, currentParams) {
		var deferred = $q.defer();
		self.getInsurers().then(function () {
			if (newParams.dtype !== null && newParams.dtype !== undefined) {
				console.log(newParams.dtype, 'dty');
				self.getBooks(newParams.dtype, currentParams).then(function () {
					self.getBook(newParams.doc).then(function () {
						deferred.resolve();
					});
				});
			} else if (newParams.doc !== null && newParams.doc !== undefined) {
                console.log(newParams.doc, 'doc');
				self.getBook(newParams.doc).then(function () {
					deferred.resolve();
				});
			} else {
                console.log(newParams.insurer, newParams.product, newParams.category);
				self.getProducts(newParams.insurer).then(function () {
					self.getCategories(newParams.product, currentParams).then(function () {
						self.getDocTypes(newParams.category, currentParams).then(function () {
							deferred.resolve();
						});
					});
				});
			}
		});
		return deferred.promise;
	}; // end of getRows()

	  self.getBook = function (id) {
		  var bookDeferred = $q.defer();
		  var message = '';
		  var download = {};
		  if (id !== null && id !== undefined) {
			  Books.getDownloadsForDrive({"bookId":id}).$promise.then(function (bookRtn) {
				  self.data.docDetail = {};
                  self.data.docDetail.downloads = [];
                  
                  var downloadDetails = bookRtn[0];
				  // _.forEach(bookRtn, function (downloadDetails) {
				  	message = '';
                    downloadDetails.versionData = angular.fromJson(downloadDetails.versionData);

                    message = '';
                    if (typeof downloadDetails.versionData.artwork !== 'undefined' && downloadDetails.versionData.artwork.filesList.length === 0) {
                    	message = downloadDetails.versionData.artwork.description;
					}

				  	if (typeof downloadDetails.versionData.artwork !== 'undefined' && downloadDetails.versionData.artwork.filesList.length > 0) {

				  		_.forEach(downloadDetails.versionData.artwork.filesList, function (fileData) {
							download = {};
                            download.fileLocation = encodeURI(apiUrl + '/containers/getfile/' + fileData.ngfName);
                            download.thumbLocation = encodeURI(apiUrl + '/containers/getthumbnail/' + fileData.ngfName.replace(/\.[^\.]+$/, '.jpg'));
                            download.fileName = fileData.fileName;
                            download.fileSize = formatBytes(fileData.fileSize);
                            self.data.docDetail.downloads.push(download);
						});

					}

				  if (downloadDetails.versionData.artwork.filesList.length === 0 ) {
					  self.data.docDetail.noDownloads = true;
					  self.data.docDetail.noDownloadsMessage = message;
				  }
                  // });

				  // self.data.docDetail.downloads = [{name:'my first download that is awesome'},{name:'my second download that is just as awesome as the first'}, {name:'my third download that is not awesome'}, {name:'my fourth download'},{name:'my fifth download'},{name:'my sixth download'}, {name:'my seventh download'}]; //dummy data
				  bookDeferred.resolve();
			  });
		  }else{
			  bookDeferred.resolve();
		  }
		  return bookDeferred.promise;
	  };

      function formatBytes(bytes, decimals) {
          if (bytes === 0) return '0 Bytes';
          var k = 1024,
              dm = decimals <= 0 ? 0 : decimals || 2,
              sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
              i = Math.floor(Math.log(bytes) / Math.log(k));
          return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      }

	  self.getBooks = function(dtype, currentParams){
		  var booksDeferred = $q.defer();
		  if (dtype !== null && dtype !== undefined){
			  Books.getBooksForDrive(currentParams).$promise.then(function(bookRtn){
				  self.data.docs = bookRtn;
				  booksDeferred.resolve();
			  });
		  }else{
			  booksDeferred.resolve();
		  }
		  return booksDeferred.promise;
	  };

	  self.getCategories = function (product, currentParams) {
		  var catsDeferred = $q.defer();
		  if (product !== null && product !== undefined){
			  self.data.categories={};
			  DocTypeCats.getDocTypeCatsAndDocCount(currentParams).$promise.then(function(catRtn){
				  // console.log("category call");
				  _.forEach(catRtn,function(cat){
					  self.data.categories[cat.docTypeCatCode]=cat;
				  });
				  catsDeferred.resolve();
			  });
		  }else{
			  catsDeferred.resolve();
		  }
		  return catsDeferred.promise;
	  };
	  self.getDocTypes = function (cat, currentParams) {
		  var typesDeferred = $q.defer();
		  
		  if (cat !== null && cat !== undefined){
			  self.data.docTypes = {};
			  DocTypes.getDocTypesAndDocCount(currentParams).$promise.then(function(typeRtn){
				  // console.log("doc types call");
				  _.forEach(typeRtn,function(type){
					  self.data.docTypes[type.docTypeCode]=type;
				  });
				  typesDeferred.resolve();
			  });
		  } else {
			  typesDeferred.resolve();
		  }
		  return typesDeferred.promise;
	  };

	  self.getInsurers = function () {
		  var insDeferred = $q.defer();
		  if (_.isEmpty(self.data.insurers)) {
			  Insurers.getInsurersAndDocCount().$promise.then(function(insurerRtn){
				  //console.log("insurers call");
				  _.forEach(insurerRtn,function(insurer){
					  self.data.insurers[insurer.insurerCode]=insurer;
				  });
				  insDeferred.resolve();
			  });
		  } else {
			  // console.log("insurer object already exists: ", _.isEmpty(self.data.insurers));
			  insDeferred.resolve();
		  }
		  return insDeferred.promise;
	  };
	  self.getProducts = function (insurer) {
		  var productDeferred = $q.defer();
		  if (insurer !== null && insurer !== undefined) {
			  Products.getProductsAndDocCount({"insurerCode":insurer}).$promise.then(function(prodRtn){
				  // console.log("products call");
				  self.data.products = {};
				  _.forEach(prodRtn,function(prod){
					  self.data.products[prod.productCode]=prod;
				  });
				  productDeferred.resolve();
			  });
		  } else {
			  productDeferred.resolve();
		  }
		  return productDeferred.promise;
	  };
  }
}());
