(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableRegion', readableRegion);

  function readableRegion(Wraps) {
    return function (id) {
      var region;
      if ((region = _.find(Wraps.regions, {regionId: parseInt(id)})) && region.regionName) {
        return region.regionName;
      }
      return null;
    };
  }
}());
