<div  layout="row" flex="100">

<!-- Section, Subsection, Copy--->

    <div ng-class="{ 'selectedCopy' : tabBook.opentab.selected.structure[0] == child.structureId }" ng-click="tabBook.toggleSection(child.structureId);" ng-if="child.versions.Contents.contentTypeId === 2 || child.versions.Contents.contentTypeId === 3 || child.versions.Contents.contentTypeId === 4" layout="row" flex="100">
         <div flex  ng-class="[child.versions.Contents.ContentTypes.contentTypeName, child.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(child)]" ng-bind-html="tabBook.makeBold(child.versions.versiondata,book.boldWords,book.emphasis)"></div>

            <span ng-if="!tabBook.library.isReorderingChildren(chapter)" style="float:right">
                <content-menu index="$index" child="child" parentbook="book" parent="parent"></content-menu>
            </span>

            <div ng-if="tabBook.library.isReorderingChildren(parent)">
                <md-button style="float:right;" aria-label="Order">
                    <ng-md-icon icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
                </md-button>
            </div>
        <span style="margin: 5px 10px;"  ng-if="child.searchTotal>0 && tabBook.opentab.selected.structure[0] != child.structureId" class="searchCount">{{child.searchTotal}}</span>



    </div>


<!-- Glossary -->

    <table width="100%" ng-if="child.versions.Contents.contentTypeId == 6"  class="BGHPTable BGHPGlossary">
        <thead ng-if="child.prev != 6"><tr><th>DEFINED TERM</th><th>DESCRIPTION</th><th>SEARCH WORDS</th></tr></thead>
        <tbody>
        <tr data-index="{{$index}}" data-id="{{child.structureId}}" data-order="{{$index}}" ng-click="tabBook.toggleSection(child.structureId);" ng-class="[{ 'selectedCopy' : tabBook.opentab.selected.structure[0] == child.structureId },tabBook.library.checkVersion(child)]"  >
            <td width="20%" ng-class="child.versions.Contents.Departments.departmentName"  class="BGHPTermNumber">
                <div ng-bind-html="tabBook.makeBold(child.glossary.term,book.boldWords,book.emphasis)"></div>
            </td>
            <td width="50%"  >
                <div ng-bind-html="tabBook.makeBold(child.glossary.description,book.boldWords,book.emphasis)"></div>
            </td>
            <td  width="30%">

                <span ng-if="!tabBook.library.isReorderingChildren(parent)" style="float:right">
                    <content-menu index="$index" child="child" parentbook="book" parent="parent"></content-menu>
                </span>

                <div ng-if="tabBook.library.isReorderingChildren(parent)">
                    <md-button style="float:right;" aria-label="Order" class="drag-handle">
                        <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
                    </md-button>
                </div>

                <div ng-model="child.glossary.words">{{child.glossary.words}}</div>
                <span style="margin: 5px 10px;"  ng-if="child.searchTotal>0 && tabBook.opentab.selected.structure[0] != child.structureId" class="searchCount">{{child.searchTotal}}</span>

            </td>
        </tr>

        </tbody>
    </table>


<!-- Benefit Section, benefit subsection, benefit intro-->



    <table id="anchorSection{{level1.Children[0].contentId}}" width="100%" ng-if="child.versions.Contents.contentTypeId === 14 || child.versions.Contents.contentTypeId === 15 || child.versions.Contents.contentTypeId === 16" class="BGHPTable" index="$index" ng-click="toggleStructure(level1.Children[0].parent.structureId);  tabBook.library.goToAnchor('anchorSection' + level1.Children[0].contentId)">
        <thead ng-if="child.versions.Contents.contentTypeId === 14">
        <tr>
            <th width="70%">BENEFIT AND EXPLANATION</th>
            <th width="30%">LIMITS</th>
        </tr>
        </thead>
        <tbody ng-class="{ 'selectedCopy' : tabBook.opentab.selected.structure[0] == child.structureId }" ng-click="tabBook.toggleSection(child.structureId);" >
        <tr data-index="{{indexNo}}" data-id="{{child.structureId}}" data-order="{{indexNo}}" >
            <td colspan="2" ng-class="{'linked' : child.benefit.options.limit == 'Linked'}">
                <div ng-class="[child.versions.Contents.ContentTypes.contentTypeName, child.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(child)]">
                    <span ng-if="!tabBook.library.isReorderingChildren(parent)" style="float:right">
                    <content-menu index="indexNo" child="child" parentbook="book" parent="parent"></content-menu>
                    </span>
                    <div ng-if="tabBook.library.isReorderingChildren(parent)">
                        <md-button style="float:right;" aria-label="Order" class="drag-handle">
                            <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
                        </md-button>
                    </div>

                    <div flex  ng-class="{ 'caps' : child.versions.Contents.contentTypeId  != 16 }"  ng-bind-html="tabBook.makeBold(child.versions.versiondata,book.boldWords,book.emphasis)"></div>
                </div>
                <span style="margin: 5px 10px;"  ng-if="child.searchTotal>0 && tabBook.opentab.selected.structure[0] != child.structureId" class="searchCount">{{child.searchTotal}}</span>
            </td>
        </tr>
        </tbody>
    </table>

<!--Benefit-->

    <table width="100%"  ng-if="child.versions.Contents.contentTypeId === 17" class="BGHPTable" ng-class="[{ 'first' : child.prev != 17}, { 'last' : child.next != 17}]">
        <tbody ng-class="{ 'selectedCopy' : tabBook.opentab.selected.structure[0] == child.structureId }" ng-click="tabBook.toggleSection(child.structureId);">
        <tr data-index="{{indexNo}}" data-id="{{child.structureId}}" data-order="{{indexNo}}" >
            <td width="70%" ng-class="[child.versions.Contents.ContentTypes.contentTypeName, child.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(child)]" >
                <div class="BGHPBenefitName caps" ng-bind-html="tabBook.makeBold(tabBook.library.nl2br(child.benefit.name),book.boldWords,book.emphasis)"></div>
                <div class="BGHPBenefitExplanation"  ng-bind-html="tabBook.makeBold(child.benefit.explanation,book.boldWords,book.emphasis)"></div>
            </td>
            <td  width="30%" ng-class="{'linked' : child.benefit.options.limit == 'Linked'}" class="BenefitLimit">
                <div ng-class="[child.versions.Contents.ContentTypes.contentTypeName, child.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(child)]" >
                    <span ng-if="!tabBook.library.isReorderingChildren(parent)" style="float:right">
                    <content-menu index="indexNo" child="child" parentbook="book" parent="parent"></content-menu>
                    </span>
                    <div ng-if="tabBook.library.isReorderingChildren(parent)">
                        <md-button style="float:right;" aria-label="Order" class="drag-handle">
                            <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
                        </md-button>
                    </div>

                    <div    ng-bind-html="tabBook.makeBold(tabBook.library.nl2br(child.benefit.limit),book.boldWords,book.emphasis)"></div>
                </div>
                <span style="margin: 5px 10px;"  ng-if="child.searchTotal>0 && tabBook.opentab.selected.structure[0] != child.structureId" class="searchCount">{{child.searchTotal}}</span>
            </td>
        </tr>
        </tbody>
    </table>

<!--Term Section-->

    <table width="100%"  ng-if="child.versions.Contents.contentTypeId === 18" class="BGHPTable">
        <thead ng-if="!(child.prev > 17)">
        <tr>
            <th width="10%">No.</th>
            <th width="90%">CLAUSE</th>
        </tr>
        </thead>
        <tbody ng-class="{ 'selectedCopy' : tabBook.opentab.selected.structure[0] == child.structureId }" ng-click="tabBook.toggleSection(child.structureId); tabBook.library.localStorage.structureId = level1.Children[0].parent" >
        <tr data-index="{{indexNo}}" data-id="{{child.structureId}}" data-order="{{indexNo}}" >
            <td width="10%" class="BGHPTermSection">{{indexNo+1}}.</td>
            <td width="90%" class="BGHPTermSection">

                <div ng-class="[child.versions.Contents.ContentTypes.contentTypeName, child.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(child)]" >
                    <span ng-if="!tabBook.library.isReorderingChildren(parent)" style="float:right">
                    <content-menu index="indexNo" child="child" parentbook="book" parent="parent"></content-menu>
                    </span>
                    <div ng-if="tabBook.library.isReorderingChildren(parent)">
                        <md-button style="float:right;" aria-label="Order" class="drag-handle">
                            <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
                        </md-button>
                    </div>
                    <div flex   ng-bind-html="tabBook.makeBold(child.versions.versiondata,book.boldWords,book.emphasis)"></div>
                </div>
                <span style="margin: 5px 10px;"  ng-if="child.searchTotal>0 && tabBook.opentab.selected.structure[0] != child.structureId" class="searchCount">{{child.searchTotal}}</span>
            </td>
        </tr>
        </tbody>
    </table>

<!-- Term -->

    <table width="100%"  ng-if="child.versions.Contents.contentTypeId === 19" class="BGHPTable BGHPTerm">

        <tbody ng-class="{ 'selectedCopy' : tabBook.opentab.selected.structure[0] == child.structureId }" ng-click="tabBook.toggleSection(child.structureId);" >
        <tr data-index="{{indexNo}}" data-id="{{child.structureId}}" data-order="{{indexNo}}" >
            <td width="10%" class="BGHPTermNumber">{{parent.indexNo+1}}.{{child.indexNo+1}}</td>
            <td width="90%" class="">

                <div ng-class="[child.versions.Contents.ContentTypes.contentTypeName, child.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(child)]" >
                    <span ng-if="!tabBook.library.isReorderingChildren(parent)" style="float:right">
                    <content-menu index="indexNo" child="child" parentbook="book" parent="parent"></content-menu>
                    </span>
                    <div ng-if="tabBook.library.isReorderingChildren(parent)">
                        <md-button style="float:right;" aria-label="Order" class="drag-handle">
                            <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
                        </md-button>
                    </div>
                    <div flex   ng-bind-html="tabBook.makeBold(child.versions.versiondata,book.boldWords,book.emphasis)"></div>
                </div>
                <span style="margin: 5px 10px;"  ng-if="child.searchTotal>0 && tabBook.opentab.selected.structure[0] != child.structureId" class="searchCount">{{child.searchTotal}}</span>
            </td>
        </tr>
        </tbody>
    </table>

<!--Exclusion-->

    <table width="100%"  ng-if="child.versions.Contents.contentTypeId === 20" class="BGHPTable BGHPExclusion"  data-index="{{indexNo}}" data-id="{{child.structureId}}" data-order="{{indexNo}}" >
        <tbody ng-class="{ 'selectedCopy' : tabBook.opentab.selected.structure[0] == child.structureId }" ng-click="tabBook.toggleSection(child.structureId);" >
        <tr>
            <td width="30%" class="BGHPTermNumber">
                <div flex  ng-class="[child.versions.Contents.ContentTypes.contentTypeName, child.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(child)]" ng-bind-html="tabBook.makeBold(child.exclusion.name,book.boldWords,book.emphasis)"></div>
            </td>
            <td width="70%"  class="">

                <div ng-class="[child.versions.Contents.ContentTypes.contentTypeName, child.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(child)]" >
                    <span ng-if="!tabBook.library.isReorderingChildren(parent)" style="float:right">
                    <content-menu index="indexNo" child="child" parentbook="book" parent="parent"></content-menu>
                    </span>
                    <div ng-if="tabBook.library.isReorderingChildren(parent)">
                        <md-button style="float:right;" aria-label="Order" class="drag-handle">
                            <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
                        </md-button>
                    </div>
                    <div flex   ng-bind-html="tabBook.makeBold(child.exclusion.rules,book.boldWords,book.emphasis)"></div>
                </div>
                <span style="margin: 5px 10px;"  ng-if="child.searchTotal>0 && tabBook.opentab.selected.structure[0] != child.structureId" class="searchCount">{{child.searchTotal}}</span>
            </td>
        </tr>
        </tbody>
    </table>

    <!--Exclusion Section-->


    <table width="100%"  ng-if="child.versions.Contents.contentTypeId === 21" class="BGHPTable">

        <tbody ng-class="{ 'selectedCopy' : tabBook.opentab.selected.structure[0] == child.structureId }" ng-click="tabBook.toggleSection(child.structureId);" >
        <tr data-index="{{indexNo}}" data-id="{{child.structureId}}" data-order="{{indexNo}}" >
            <td class="BGHPExclusionSection">
                <div ng-class="[child.versions.Contents.ContentTypes.contentTypeName, child.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(child)]" >
                    <span ng-if="!tabBook.library.isReorderingChildren(parent)" style="float:right">
                    <content-menu index="indexNo" child="child" parentbook="book" parent="parent"></content-menu>
                    </span>
                    <div ng-if="tabBook.library.isReorderingChildren(parent)">
                        <md-button style="float:right;" aria-label="Order" class="drag-handle">
                            <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
                        </md-button>
                    </div>
                    <div flex class="caps" ng-bind-html="tabBook.makeBold(child.versions.versiondata,book.boldWords,book.emphasis)"></div>
                </div>
                <span style="margin: 5px 10px;"  ng-if="child.searchTotal>0 && tabBook.opentab.selected.structure[0] != child.structureId" class="searchCount">{{child.searchTotal}}</span>
            </td>
        </tr>
        </tbody>
    </table>



</div>
