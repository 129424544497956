(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name login.controller:LoginCtrl
   *
   * @description
   *
   */
  angular
    .module('login')
    .controller('LoginCtrl', LoginCtrl);

  function LoginCtrl($location, $localStorage, $rootScope, $scope, $timeout, Users, Wraps) {
    var vm = this;
    vm.ctrlName = 'LoginCtrl';
    $scope.$storage = $localStorage;

    vm.isLoginPage = true;

    delete $scope.$storage.userForm; // should delete legacy data
    
    if(Users.isAuthenticated()) {
      Users.logout();
      $scope.$storage.user = {};
      $scope.$storage.loggedInUser = {};

    }

    if($rootScope.pw) {
      vm.credentials = {};
      vm.credentials.password = $rootScope.pw;
      $rootScope.pw = false;
    }

    vm.submitLogin = function (resend) {
      vm.isLoading = true;
      vm.error = false;
      vm.resendMsg = false;

      $timeout(function() {
        if(!resend) {
          Users.login({rememberMe: false}, vm.credentials, function () {
            Wraps.fetchedUser =  Wraps.getCurrentUser(true);

            Wraps.fetchedUser.then(function(){
                var next = $location.nextAfterLogin || '/home';

                $location.nextAfterLogin = null;
                $location.path(next);

                vm.resendMsg = "Login successful";
                vm.isLoading = false;
            }, function(){
                $scope.$storage.user = {};
                vm.error = "Failed to load user";
                vm.isLoading = false;
                $timeout(function() {vm.error = '';},3000);
            });

          }, function () {
            $scope.$storage.user = {};

              vm.error = "Login details incorrect";
              $timeout(function() {vm.error = '';},3000);
              vm.isLoading = false;

          });
        }else{
          Users.resetPassword({ email: vm.credentials.email }, function(){

            //vm.credentials.password = "";


                vm.resendMsg = "Password reset sent to email";
                $timeout(function() {vm.resendMsg = '';vm.resend = false;},3000);
                vm.isLoading = false;

            //vm.isLoading = false;
          },function () {

            vm.resendError = "Email incorrect";
            $timeout(function() {vm.resendError = '';},3000);
          });
        }
      },1000);
    };
    
    
  }
}());
