(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.docdiffdialog.controller:DocdiffdialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.docdiffdialog')
    .controller('DocdiffdialogCtrl', DocdiffdialogCtrl);

  function DocdiffdialogCtrl($mdDialog, $q, $sce, $scope, apiUrl, change, child, Contents, Events, History, htmldiff, Roles, Structures, Users, Versions, Wraps) {
    var vm = this;
    vm.ctrlName = 'DocdiffdialogCtrl';
    if(!Users.isAuthenticated()){$location.path('/');}

    vm.change = change;
    vm.child = child;
    vm.apiUrl = apiUrl;
    vm.hasPermissions = Wraps.hasPermissions;

    vm.dialogHide = function(data) {
      $mdDialog.hide(data);
    };
    vm.dialogCancel = function(data) {
      $mdDialog.cancel(data);
    };


    vm.rfcReasons = Wraps.rfcReasons;
    vm.rfcCategories = Wraps.rfcCategories;

    vm.book = Wraps.book;

    //vm.histories = History.find({ filter: { where: { structureId: change.structureId }, "include": [{"versions": "Contents"}] }});
    //vm.events = Events.find({ filter: { where: { structureId: change.structureId }, "include": ["Users","Contents", {"History":["versions","versionsNew"]}] }});


    var loadEvents = function () {
      Events.find({"filter":{"where":{"structureId":change.structureId},"include":"Users",order: 'eventId DESC', }}).$promise.then(function(events){
        //console.log(events);
        _.forEach(events, function (event) {
          if(event.eventData && event.eventData.charAt(0)==='{') {
            event.rfcReasons = [];
            event.categories = [];
            event.Comment = angular.fromJson(event.eventData);
            //link id's with name from datalists
            _.forEach(event.Comment.reason, function(val,key){
              event.rfcReasons.push(_.result(_.find(vm.rfcReasons, {'id': _.parseInt(val)}), 'name'));
            });
            _.forEach(event.Comment.category, function(val,key){
              event.categories.push(_.result(_.find(vm.rfcCategories, {'id': _.parseInt(val)}), 'name'));
            });
            //console.log(event.Comment.rfcReasons);

          }
        });
        vm.events = events;

      });
    }

    $scope.$on('newCommentCreated', function(event){
        // console.log('comment event received');
        loadEvents();
    });

    if(vm.change) {
      loadEvents();

      $scope.left = vm.change.versionData1;
      $scope.right = vm.change.versionData2;
      $scope.content = vm.change.versionData;

      if(vm.change.versionData1 === null && vm.change.versionData2 === null){
        vm.history = false;
        console.log();
      }else{
        vm.history = true;
      }



      var checkUndefined = function(data){
        if(angular.isUndefined(data) || data === null) {
          data = "";
        }
        return data;
      }
      
      var htmldiffUndefined = function(left, right){
        return htmldiff(checkUndefined(left),checkUndefined(right));
      }


      if(vm.change.contentTypeId >= 5){

        $scope.left = angular.fromJson($scope.left);
        $scope.right = angular.fromJson($scope.right);
        $scope.content = angular.fromJson($scope.content);

        vm.left = $scope.left;
        if(vm.left){vm.left.versions = { "Contents":{"contentTypeId" : vm.change.contentTypeId}};}
        vm.right = $scope.right;
        if(vm.right){vm.right.versions = { "Contents":{"contentTypeId" : vm.change.contentTypeId}};}
        vm.content = $scope.content;
        if(vm.content){vm.content.versions = { "Contents":{"contentTypeId" : vm.change.contentTypeId}};}

        if(vm.change.contentTypeId === 5){
          if(!$scope.left){ $scope.left={benefit:{}}; }
          if(!$scope.right){ $scope.right={benefit:{}}; }

          vm.diff = "<strong>Name:</strong><br />"+htmldiffUndefined($scope.left.benefit.name, $scope.right.benefit.name);
          vm.diff += "<br /><br /><strong>"+vm.book.levels.level1.name+":</strong><br />"+htmldiffUndefined($scope.left.benefit.level1, $scope.right.benefit.level1);
          vm.diff += "<br /><br /><strong>"+vm.book.levels.level2.name+":</strong><br />"+htmldiffUndefined($scope.left.benefit.level2, $scope.right.benefit.level2);
          vm.diff += "<br /><br /><strong>"+vm.book.levels.level3.name+":</strong><br />"+htmldiffUndefined($scope.left.benefit.level3, $scope.right.benefit.level3);
          vm.diff += "<br /><br /><strong>"+vm.book.levels.level4.name+":</strong><br />"+htmldiffUndefined($scope.left.benefit.level4, $scope.right.benefit.level4);
          vm.diff += "<br /><br /><strong>Explanation:</strong><br />"+htmldiffUndefined($scope.left.benefit.explanation, $scope.right.benefit.explanation);

        }else if(vm.change.contentTypeId === 6){
           if(!$scope.left){ $scope.left={glossary:{}}; }
            if(!$scope.right){ $scope.right={glossary:{}}; }
           vm.diff = "<strong>Term:</strong><br />"+htmldiffUndefined($scope.left.glossary.term, $scope.right.glossary.term);
           vm.diff += "<br /><br /><strong>Description:</strong><br />"+htmldiffUndefined($scope.left.glossary.description, $scope.right.glossary.description);
           vm.diff += "<br /><br /><strong>Words:</strong><br />"+htmldiffUndefined($scope.left.glossary.words, $scope.right.glossary.words);

        }else if(vm.change.contentTypeId === 7){
          if(!$scope.left){ $scope.left={exclusion:{}}; }
          if(!$scope.right){ $scope.right={exclusion:{}}; }

          vm.diff = "<strong>Name:</strong><br />"+htmldiffUndefined($scope.left.exclusion.name, $scope.right.exclusion.name);
          vm.diff += "<br /><br /><strong>Rules:</strong><br />"+htmldiffUndefined($scope.left.exclusion.rules, $scope.right.exclusion.rules);

        }else if(vm.change.contentTypeId === 8) {
          if(!$scope.left){ $scope.left={medical:{}}; }
          if(!$scope.right){ $scope.right={medical:{}}; }

          vm.diff = "<strong>Term:</strong><br />" + htmldiffUndefined($scope.left.medical.term, $scope.right.medical.term);
          vm.diff += "<br /><br /><strong>Description:</strong><br />" + htmldiffUndefined($scope.left.medical.description, $scope.right.medical.description);

          
        }else if(vm.change.contentTypeId === 11) {
          if(!$scope.left){ $scope.left={tableHeader:{}}; }
          if(!$scope.right){ $scope.right={tableHeader:{}}; }
          vm.diff = "";
          _.forEach($scope.left.tableHeader, function (item,key) {

              vm.diff += "<br /><br /><strong>Column " + (key + 1) + ":</strong><br />" + htmldiffUndefined($scope.left.tableHeader[key].name, $scope.right.tableHeader[key].name);
          });
          
        }else if(vm.change.contentTypeId === 12) {
          if(!$scope.left){ $scope.left={tableRow:{}}; }
          if(!$scope.right){ $scope.right={tableRow:{}}; }
          vm.diff = "";
          _.forEach($scope.left.tableRow, function (item,key) {

              vm.diff += "<br /><br /><strong>Column " + (key + 1) + ":</strong><br />" + htmldiffUndefined($scope.left.tableRow[key].data, $scope.right.tableRow[key].data);
          });
          
        }else if(vm.change.contentTypeId === 17) {
          if(!$scope.left){ $scope.left={benefit:{}}; }
          if(!$scope.right){ $scope.right={benefit:{}}; }

          vm.diff = "<strong>Name:</strong><br />" + htmldiffUndefined($scope.left.benefit.name, $scope.right.benefit.name);
          vm.diff += "<br /><br /><strong>Explanation:</strong><br />" + htmldiffUndefined($scope.left.benefit.explanation, $scope.right.benefit.explanation);
          vm.diff += "<br /><br /><strong>Limit:</strong><br />" + htmldiffUndefined($scope.left.benefit.limit, $scope.right.benefit.limit);

        }else if(vm.change.contentTypeId === 20) {
          if(!$scope.left){ $scope.left={exclusion:{}}; }
          if(!$scope.right){ $scope.right={exclusion:{}}; }

          vm.diff = "<strong>Name:</strong><br />" + htmldiffUndefined($scope.left.exclusion.name, $scope.right.exclusion.name);
          vm.diff += "<br /><br /><strong>Rules:</strong><br />" + htmldiffUndefined($scope.left.exclusion.rules, $scope.right.exclusion.rules);
        }


      }else{
        if(!$scope.left) {
          $scope.left = "";
        }

        //vm.diff = diff($scope.left, $scope.right);

        vm.left = {};
        vm.left.versions = { "Contents":{"contentTypeId" : vm.change.contentTypeId},"versiondata":$scope.left};

        vm.right = {};
        vm.right.versions = { "Contents":{"contentTypeId" : vm.change.contentTypeId},"versiondata":$scope.right};

        vm.content = {};
        vm.content.versions = { "Contents":{"contentTypeId" : vm.change.contentTypeId},"versiondata":$scope.content};

        vm.diff = $sce.trustAsHtml(htmldiffUndefined($scope.left, $scope.right));

      }


    }



  }
}());
