angular
    .module('library')
    .component('inputLevel', {
        templateUrl: 'library/library-components/right-menu/input-level/input-level.tpl.html',
        controller: inputLevelCtrl,
        controllerAs:'inputLevel',
        bindings:{
            book:'<',
            disabled:'<',
            level:'@'
        }

    });

inputLevelCtrl.$inject = [];
function inputLevelCtrl(){
    //var vm = this;
}