<md-content layout-padding>

    <div layout="row"  layout-wrap    class="publish">
        <div flex="100">
            <h4>Document Publishing</h4>

            <div layout="row"  layout-wrap    class="publish">
                <input-switchlist flex="33" book="tabPublish.book" disabled="tabPublish.disabled" array="tabPublish.productionList" switch="tabPublish.switch.production" label="Production Manager" model="production"></input-switchlist>
                <input-switchlist flex="33" book="tabPublish.book" disabled="tabPublish.disabled" array="tabPublish.fulfilmentList" switch="tabPublish.switch.fulfilment" label="Service Centre" model="fulfilment"></input-switchlist>
                <input-switchlist flex="33" book="tabPublish.book" disabled="tabPublish.disabled" array="tabPublish.digitalList" switch="tabPublish.switch.digital" label="Digital & Social" model="digital"></input-switchlist>
                <input-switchlist flex="33" book="tabPublish.book" disabled="tabPublish.disabled" array="tabPublish.supportList" switch="tabPublish.switch.support" label="Support Tools" model="support"></input-switchlist>
                <input-switchlist flex="33" book="tabPublish.book" disabled="tabPublish.disabled" array="tabPublish.inSituList" switch="tabPublish.switch.inSitu" label="Evidence" model="inSitu"></input-switchlist>

            </div>


        </div>
    </div>
</md-content>
