<md-content layout-padding class="products-tab">
    <div layout="row">
        <h4  flex wraps-tooltip="productids-title">Products</h4>
        <md-button aria-label="Open search" class="md-icon-button" ng-hide="tabProducts.searchProductIds" ng-click="tabProducts.searchProductIds=true" wraps-tooltip="productids-search-button"  aria-label="Open search">
            <ng-md-icon icon="search" style="fill: grey;" size="24"></ng-md-icon>
        </md-button>
    </div>


    <div layout="column">
        <div ng-show="tabProducts.selectedRows && tabProducts.selectedRows.length > 0" flex="100">
            <md-chips md-removable="true" readonly="true" ng-model="tabProducts.selectedRows">
                <md-chip-template>
                    <strong>{{$chip.productId}}</strong> - {{$chip.Insurers.insurerName}} - {{$chip.masterProductGroup}}
                </md-chip-template>

            </md-chips>
        </div>

        <md-data-table-container>
            <table md-data-table style="width:100%"> <!--md-row-select="selected"-->
                <thead>
                <tr ng-show="tabProducts.searchProductIds">

                    <td wraps-tooltip="productids-search-input">
                        <md-input-container>
                            <input ng-model="tabProducts['filterProductIds'].Insurers.insurerName" style="width: 90%;"
                                   aria-label="Filter Insurer Name"></md-input-container>
                    </td>

                    <td wraps-tooltip="productids-search-input">
                        <md-input-container>
                            <input ng-model="tabProducts['filterProductIds'].masterProductGroup" style="width: 90%;"
                                   aria-label="Filter Master Product Group"></md-input-container>
                    </td>

                    <td wraps-tooltip="productids-search-input">
                        <md-input-container>
                            <input ng-model="tabProducts['filterProductIds'].productCategory" style="width: 90%;"
                                   aria-label="Filter Product Category / Group"></md-input-container>
                    </td>

                    <td wraps-tooltip="productids-search-input">
                        <md-input-container>
                            <input ng-model="tabProducts['filterProductIds'].productName" style="width: 90%;"
                                   aria-label="Filter Product Name"></md-input-container>
                    </td>

                    <td wraps-tooltip="productids-search-input">
                        <md-input-container>
                            <input ng-model="tabProducts['filterProductIds'].subProduct" style="width: 90%;"
                                   aria-label="Filter Sub Product"></md-input-container>
                    </td>

                    <td wraps-tooltip="productids-search-input">
                        <md-input-container>
                            <input ng-model="tabProducts['filterProductIds'].indiOrSME" style="width: 90%;"
                                   aria-label="Filter Indi / SME"></md-input-container>
                    </td>

                    <td wraps-tooltip="productids-search-input">
                        <md-input-container>
                            <input ng-model="tabProducts['filterProductIds'].productId" style="width: 90%;"
                                   aria-label="Filter Product Ids"></md-input-container>
                    </td>

                    <td style="padding:0 10px 0 3px">
                        <div class="md-actions" style="float:right">
                            <md-button aria-label="Close Search" class="md-icon-button"
                                       ng-click="tabProducts.searchProductIds=false;"
                                       wraps-tooltip="productids-search-close">
                                <ng-md-icon icon="close" style="fill: grey; " size="24"></ng-md-icon>
                            </md-button>
                        </div>
                    </td>
                </tr>
                </thead>
                <thead>
                <tr>
                    <th ng-click="sortBy('insurerName')" wraps-tooltip="tabProducts-table-heading">Insurer Name<ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: tabProducts.orderBy === 'insurerName', reverse: tabProducts.reverse}"></th>
                    <th ng-click="sortBy('masterProductGroup')" wraps-tooltip="tabProducts-table-heading">Master Product Group<ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: tabProducts.orderBy === 'masterProductGroup', reverse: tabProducts.reverse}"></th>
                    <th ng-click="sortBy('productCategory')" wraps-tooltip="tabProducts-table-heading">Product Category / Group<ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: tabProducts.orderBy === 'productCategory', reverse: tabProducts.reverse}"></th>
                    <th ng-click="sortBy('productName')" wraps-tooltip="tabProducts-table-heading">Product Name<ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: tabProducts.orderBy === 'productName', reverse: tabProducts.reverse}"></th>
                    <th ng-click="sortBy('subProduct')" wraps-tooltip="tabProducts-table-heading">Sub Product<ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: tabProducts.orderBy === 'subProduct', reverse: tabProducts.reverse}"></th>
                    <th ng-click="sortBy('indiOrSME')" wraps-tooltip="tabProducts-table-heading">Indi / SME<ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: tabProducts.orderBy === 'indiOrSME', reverse: tabProducts.reverse}"></th>
                    <th ng-click="sortBy('productId')" wraps-tooltip="tabProducts-table-heading">Product Id <ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: tabProducts.orderBy === 'productId', reverse: tabProducts.reverse}"></ng-md-icon></th>
                    <th></th>
                </tr>
                </thead>

                <tbody>

                <tr ng-repeat="productIds in tabProducts.filteredRows = (tabProducts.productIds | filter:tabProducts['filterProductIds']) | orderBy:tabProducts.orderBy:tabProducts.reverse | limitTo: tabProducts.productsQuery.limit: (tabProducts.productsQuery.page- 1) * tabProducts.productsQuery.limit">
                    <td>
                        <span>{{productIds.Insurers.insurerName}}</span>
                    </td>
                    <td>
                        <span>{{productIds.masterProductGroup}}</span>
                    </td>
                    <td>
                        <span>{{productIds.productCategory}}</span>
                    </td>
                    <td>
                        <span>{{productIds.productName}}</span>
                    </td>
                    <td>
                        <span>{{productIds.subProduct}}</span>
                    </td>
                    <td>
                        <span>{{productIds.indiOrSME}}</span>
                    </td>
                    <td>
                        <span>{{productIds.productId}}</span>
                    </td>
                    <td>
                        <md-checkbox ng-model="tabProducts.book.productIdsSelectedArray[productIds.productId]" ng-disabled="tabProducts.disabled" aria-label="checkbox"
                                     wraps-tooltip="productids-select-row"></md-checkbox>
                    </td>
                </tr>
                </tbody>
            </table>
        </md-data-table-container>
        <md-data-table-pagination md-limit="tabProducts.productsQuery.limit" md-page="tabProducts.productsQuery.page" md-total="{{tabProducts.filteredRows.length}}" style="width:100%"></md-data-table-pagination>
    </div>
</md-content>