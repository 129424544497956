(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name reports.controller:ReportsCtrl
   *
   * @description
   *
   */
  angular
    .module('reports')
    .controller('ReportsCtrl', ReportsCtrl);

  function ReportsCtrl($filter, $localStorage, $location, $mdDialog, $scope, $timeout, Reports, Users, Wraps) {
    var vm = this;
    vm.ctrlName = 'ReportsCtrl';

    if(!Users.isAuthenticated()){$location.path('/');}

    $scope.$storage = $localStorage;
	vm.hasAccess = null;
	vm.hasPermissions = Wraps.hasPermissions;
	  
	Wraps.fetchedUser.then(function(){
		 vm.hasAccess = vm.hasPermissions('adminReports');
	 });
	
    var myDate = new Date();

    var previousMonth = $filter('date')(myDate.setMonth(myDate.getMonth()-1), 'MMMM yyyy');
    var previous6Month = $filter('date')(myDate.setMonth(myDate.getMonth()-5), 'MMMM yyyy');
    var previous12Month = $filter('date')(myDate.setMonth(myDate.getMonth()-6), 'MMMM yyyy');


    vm.monthlyDate = previousMonth;
    vm.biAnnualDate = previous6Month + " -  " + previousMonth;
    vm.annualDate  = previous12Month + " -  " + previousMonth;
	
	vm.green = '#ddebcc';
	vm.yellow = '#fdf5c9';
	vm.amber = '#fce3bc';
	vm.red = '#f2c9c5';
	  
	var layout1 = {
		hLineWidth: function (i, node) {
			return (i === 0 || i === node.table.body.length) ? 0 : 1;
		},
		vLineWidth: function (i, node) {
			return 0;
		},
		hLineColor: function (i, node) {
			return (i === 1) ? 'gray' : 'lightgray';
		},
		paddingLeft: function(i, node) { return 10; },
		paddingRight: function(i, node) { return 10; },
		paddingTop: function(i, node) { return 3; },
		paddingBottom: function(i, node) { return 3; }
	};
	  
	var layout2 = {
		hLineWidth: function (i, node) {
			return (i === 0 || i === node.table.body.length) ? 0 : 1;
		},
		vLineWidth: function (i, node) {
			return 0;
		},
		hLineColor: function (i, node) {
			return (i === 2) ? 'gray' : 'lightgray';
		},
		paddingLeft: function(i, node) { return 10; },
		paddingRight: function(i, node) { return 10; },
		paddingTop: function(i, node) { return 3; },
		paddingBottom: function(i, node) { return 3; }
	}
	
	//Table DD constructor functions
	function conditionalTable(title, helper, data){
		var tblObj = {};
		tblObj.style = 'tableExample';
		tblObj.table = {
			headerRows:1,
			widths: ['*', 30, 30,30],
			body : [
				[{text:title + ' \n(' + helper + ')',style:'tableHeader'}, {text:'%',style:'tableHeader', alignment:'right'}, {text:'#',style:'tableHeader',alignment:'right'}, {text:'Total',style:'tableHeader',alignment:'right'}]
			]
		};
		_.forEach(data, function(rowData){
			var newRow = [{text:[{text:rowData.name + ' ', bold:true}, rowData.helper]}, {text:rowData.percentage, alignment:'right', fillColor:vm[rowData.color]},  {text: rowData.number, alignment:'right'},  {text:rowData.total, alignment:'right'}];
			tblObj.table.body.push(newRow);
		})
		tblObj.layout = layout1;
		return tblObj;
	};
	  
	function docInfoTable(title, helper, data){
		var tblObj = {};
		tblObj.style = 'tableExample';
		tblObj.table = {
			headerRows: 2,
			widths: [30,'*', 100, 100,],
			body : [
				[{text:title + ' \n(' + helper + ')',style:'tableHeader',colSpan: 4},{},{},{}]
			]
		};
		_.forEach(data, function(rowData){
			var newRow = [{text:rowData.id}, {text:rowData.bookCode},  {text:rowData.docType},  {text:rowData.owner}];
			tblObj.table.body.push(newRow);
		})
		tblObj.layout = layout2;
		return tblObj;
	};
	function trafficLightsTable(title, helper, data){
		var tblObj = {};
		tblObj.style = 'tableExample';
		tblObj.table = {
			headerRows:1,
			widths: ['*',30,30,30,30],
			body : [
				[{text:title + ' \n(' + helper + ')',style:'tableHeader'}, {text:'R',style:'tableHeader', alignment:'right'}, {text:'A',style:'tableHeader',alignment:'right'}, {text:'G',style:'tableHeader',alignment:'right'}, {text:'%',style:'tableHeader',alignment:'right'}]
			]
		};
		_.forEach(data, function(rowData){
			var newRow = [{text:rowData.name, bold:true}, {text:rowData.red,alignment:'right',fillColor:vm.red},  {text:rowData.amber,alignment:'right',fillColor:vm.amber},  {text:rowData.green,alignment:'right',fillColor:vm.green},{text:rowData.percentage,alignment:'right'}];
			tblObj.table.body.push(newRow);
		})
		tblObj.layout = layout1;
		return tblObj;
	};
	
	function twoColTable(title, helper, data){
		var tblObj = {};
		tblObj.style = 'tableExample';
		tblObj.table = {
			headerRows:1,
			widths: ['*', 30],
			body : [
				[{text:title + ' \n(' + helper + ')', style:'tableHeader', colSpan:'2'}, {}]
			]
		};
		_.forEach(data, function(rowData){
			var newRow = [{text:rowData.name, bold:true}, {text:rowData.val,alignment:'right'}];
			tblObj.table.body.push(newRow);
		});
		tblObj.layout = layout1;
		return tblObj;
	};
	  
	
	// pdf report document definition object
	var fullReportDD = {
		content: [
			{text: 'Marketing Compliance MI Report ', style: 'header'},
			{text: '', style: 'subheader'}
		],
		styles: {
			header: {
				fontSize: 18,
				bold: true,
				margin: [0, 0, 0, 10],
			},
			subheader: {
				fontSize: 16,
				bold: true,
				margin: [0, 10, 0, 5]
			},
			tableExample: {
				margin: [0, 5, 0, 15],
				fontSize:10,
				width:'100%'
			},
			tableHeader: {
				bold: true,
				color: 'gray',
				fillColor:'#f6f6f6'
			}
		},
		defaultStyle: {
			color:'#1e1e1e'
			// alignment: 'justify'
		}

	};  
	  
	// download full report PDF
	vm.openPdf = function(reportingData){
		//create Document definition object using JSON Data.
		_.forEach(reportingData.tables, function(tableData){
			switch(tableData.type){
				case 'conditional':
					var tempTable = new conditionalTable(tableData.title, tableData.helper, tableData.data);
					break;
				case 'trafficLights':
					var tempTable = new trafficLightsTable(tableData.title, tableData.helper, tableData.data);
					break;
				case 'docInfo':
					var tempTable = new docInfoTable(tableData.title, tableData.helper, tableData.data);
					break;
				case 'twoCol':
					var tempTable = new twoColTable(tableData.title, tableData.helper, tableData.data);
					break;
				default:
					
			}
			fullReportDD.content.push(tempTable);  
		});
		
		
		fullReportDD.content[1].text = reportingData.date;
		//generate and download PDF
 		pdfMake.createPdf(fullReportDD).download('Marketing Compliance MI Report - ' + vm.monthlyDate);
	}
	//old reporting functions - replaced July 2018
/*
    var sendReport = function(period){
      Reports.createReport({
        "reportType": period,
        "to": $scope.$storage.loggedInUser.email
      }).$promise.then(function (response) {
        console.log("createReport", response);
      });
    }


    vm.sendReportDialog = function (period) {
      var confirm = $mdDialog.confirm()
        .title('Email report')
        .htmlContent('Send Excel report to:'+$scope.$storage.loggedInUser.email+'<br />')
        .ariaLabel('Email report')
        .ok('Send')
        .cancel('Cancel');

      $mdDialog.show(confirm)
        .then(function () {
          sendReport(period);
        }, function () {

        });
    }
	*/
	//dummy JSON Data
	
    vm.reportingData = {
		date:'May 2018',
		tables:[
			{
				title:'Dummy Data: Fin Proms',
				helper:'Shown as % of Live Fin Proms',
				type:'conditional',
				data:[
					{
						name:'Not real data',
						helper:'It\'s just made up to test my code',
						percentage:'25%',
						color:'red',
						number:'7',
						total:'354'
					},
					{
						name:'BGEU Black Assets',
						helper:'% of Fin Proms that have expired but not yet withdrawn, as at date of report',
						percentage:'0%',
						color:'green',
						number:'0',
						total:'42'
					},
					{
						name:'Example Black Assets',
						helper:'% of Fin Proms that have expired but not yet withdrawn, as at date of report',
						percentage:'12%',
						color:'amber',
						number:'1',
						total:'8'
					},
					{
						name:'Some other Black Assets',
						helper:'% of Fin Proms that have expired but not yet withdrawn, as at date of report',
						percentage:'5%',
						color:'yellow',
						number:'3',
						total:'61'
					}
				]
			},
			{
				title:'Force Expiry',
				helper:'Live / Published documents expired in reporting month due to "Compliance" or "External Regulatory Body"',
				type:'docInfo',
				data:[
					{
						id:'6396',
						bookCode:'BIN-LIFE-IPID-EN-1802-v1.2-XXXX-0003400',
						docType:'IPID',
						owner:'Henry Kasparian'
					},
					{
						id:'1643',
						bookCode:'BIN-GENE-BROA-EN-0412-v1.03-XXXX-0003981',
						docType:'Broadcasting (TV, Radio, Video)',
						owner:'Lucy Donaldson'
					}
				]
			},
			{
				title:'Rejections: Global',
				helper:'Rejected by MCOMP/Compliance in reporting month, ranked by severity - incl. documents not live at time of report',
				type:'trafficLights',
				data:[
					{
						name:'Total Global Rejections',
						red:'8',
						amber:'54',
						green:'',
						percentage:'35%'
					},
					{
						name:'Department: BG Travel',
						red:'',
						amber:'',
						green:'',
						percentage:'-'
					},
					{
						name:'Department: Business Services',
						red:'6',
						amber:'1',
						green:'3',
						percentage:'8%'
					},
					{
						name:'Department: Customer Services',
						red:'8',
						amber:'54',
						green:'',
						percentage:'35%'
					}
				]
			},
			{
				title:'Incomplete Records',
				helper:'Documents that have been in the Publishing workflow for +1 month, excluding Corportate Product Owned Documents',
				type:'twoCol',
				data:[
					{
						name:'Total number of Documents (See "Incomplete Records" for full details',
						val:'188'
					},
					{
						name:'Number of which were on April\'s MI report',
						val:'98'
					}
				]
			},
			{
				title:'A second Traffic light table',
				helper:'just to prove traffic light tables work',
				type:'trafficLights',
				data:[
					{
						name:'different data',
						red:'7',
						amber:'',
						green:'53',
						percentage:'74%'
					},
					{
						name:'Department: Business Services',
						red:'6',
						amber:'1',
						green:'3',
						percentage:'8%'
					},
					{
						name:'No problems here',
						'red':'',
						amber:'',
						green:'',
						percentage:'-'
					},
					{
						name:'Department: Customer Services',
						red:'8',
						amber:'54',
						green:'',
						percentage:'35%'
					}
				]
			},

		]
	}
    

  }
}());
