(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name reset.controller:ResetCtrl
   *
   * @description
   *
   */
  angular
    .module('reset')
    .controller('ResetCtrl', ResetCtrl);

  function ResetCtrl($location, $rootScope, $scope, $stateParams, $timeout, LoopBackAuth, Users) {
    var vm = this;
    vm.ctrlName = 'ResetCtrl';



    //console.log('$stateParams',$stateParams.accesstoken);



    vm.submitReset = function(){

      //console.log('123');
      vm.isLoading = true;
      vm.error = false;
      vm.resendMsg = false;

      var accesstoken = $stateParams.accesstoken

      //console.log(vm.credentials);
      $timeout(function() {
        if (accesstoken){
          if(vm.credentials) {
            LoopBackAuth.accessTokenId = accesstoken;

            //verify passwords match
            if (!vm.credentials.password || !vm.credentials.password2 ||
              vm.credentials.password !== vm.credentials.password2) {
              vm.error = 'Passwords do not match';
              $timeout(function () { vm.error = ''; }, 3000);
            } else {

              Users.setNewPassword({"password": vm.credentials.password}).$promise.then(function (err, response) {
                vm.error = 'Password reset';
                $rootScope.pw = vm.credentials.password;
                $timeout(function () {vm.error = ''; $location.path('/');}, 2000);

              }, function () {
                  vm.error = 'Tolken too old please resend';
                  $timeout(function() { vm.error = '';},3000);

              });
            }

          }else{
            vm.error = 'Please enter password';
            $timeout(function() { vm.error = '';},3000);
          }
        }else{
          vm.error = 'URL error';
          $timeout(function() { vm.error = '';},3000);
        }

        vm.isLoading = false;
      },1000);

    }


  }
}());
