(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accessDenied.controller:AccessDeniedCtrl
   *
   * @description
   *
   */
  angular
    .module('accessDenied')
    .controller('AccessDeniedCtrl', AccessDeniedCtrl);

  function AccessDeniedCtrl($state,clientConfig) {
    var vm = this;
    vm.clientConfig = clientConfig;
    vm.ctrlName = 'AccessDeniedCtrl';
    vm.src = $state.params.src;
  }
}());
