angular
    .module('library')
    .component('tabBook', {
        templateUrl: 'library/library-components/tab-book/tab-book.tpl.html',
        controller: tabBookCtrl,
        controllerAs:'tabBook',
        bindings:{
            library:'<',
            book:'<',
            opentab:'<',
            isloading:'<'
        }

    });

tabBookCtrl.$inject = ['$filter', '$localStorage', '$scope', '$state', 'Books', 'WrapsBooks'];
function tabBookCtrl($filter, $localStorage, $scope, $state, Books, WrapsBooks){
    var vm = this;

    var sortArrayByLength = function (arr, ascYN) {
        arr.sort(function (a, b) {           // sort array by length of text
            if (ascYN) return a.length - b.length;              // ASC -> a - b
            else return b.length - a.length;                    // DESC -> b - a
        });
    }

    vm.filenameInCodeCheck = WrapsBooks.filenameInCodeCheck;


    // vm.filenameInCodeCheck = function (filename, bookCode) {
    //     console.log(filename,bookCode,filename.search(bookCode));
    //     if(filename && filename.search(bookCode) >= 0){
    //         return true;
    //     }else{
    //         return false;
    //     }
    // }


    vm.toggleStructure = function (id) {
        if(!angular.isDefined(vm.opentab.selected.structure)){
            vm.opentab.selected.structure = [];
        }
        if (_.indexOf(vm.opentab.selected.structure, id) >= 0) {
            _.pull(vm.opentab.selected.structure, id);
        } else {
            vm.opentab.selected.structure.length = 0; //clears existing selection
            vm.opentab.selected.structure.push(id);
        }
    }
    vm.toggleChapter = function (id) {
        //console.log("toggleChapter", id, _.indexOf(vm.opentab.selected.level1, id));
        if(!angular.isDefined(vm.opentab.selected.level1)){
            vm.opentab.selected.level1 = [];
        }
        vm.opentab.selected.level2 = []; //collapse chapter children on close
        if (_.indexOf(vm.opentab.selected.level1, id) >= 0) {
            _.pull(vm.opentab.selected.level1, id);
        } else {
            vm.opentab.selected.level1.length = 0; //clears existing selection
            vm.opentab.selected.level1.push(id);
        }
    }
    vm.inSelected = function (id) {
        if(!angular.isDefined(vm.opentab.selected.level1)){
            vm.opentab.selected.level1 = [];
        }
        if (_.indexOf(vm.opentab.selected.level1, id) >= 0) {
            return true;
        } else {
            return false;
        }
    }
    vm.inSection = function (id) {
        if(!angular.isDefined(vm.opentab.selected.level2)){
            vm.opentab.selected.level2 = [];
        }
        if (_.indexOf(vm.opentab.selected.level2, id) >= 0) {
            return true;
        } else {
            return false;
        }
    }

    vm.hasLibraryTextSearch = function(){
        return !!$localStorage.pagination.filterLibrary.textSearch;
    }


    //collapse/expand sections
    vm.collapseSections = function (sects) {
        if(!angular.isDefined(vm.opentab.selected.level2)){
            vm.opentab.selected.level2 = [];
        }
        _.forEach(sects, function (section) {
           if(vm.opentab.selected.level2.indexOf(section.structureId) >= 0) {
               vm.opentab.selected.level2.splice(vm.opentab.selected.level2.indexOf(section.structureId), 1);
           }
        });
    };
    vm.expandSections = function (sects) {
        if(!angular.isDefined(vm.opentab.selected.level2)){
            vm.opentab.selected.level2 = [];
        }
        _.forEach(sects, function (section) {
            if (vm.opentab.selected.level2.indexOf(section.structureId) == -1) {
                vm.opentab.selected.level2.push(section.structureId);
            }
        });
    };
    vm.toggleSection = function (sect) {

        if(!angular.isDefined(vm.opentab.selected.level2)){
            vm.opentab.selected.level2 = [];
        }
        if(!angular.isDefined(vm.opentab.selected.structure)){
            vm.opentab.selected.structure = [];
        }
        //console.log('toggleSection');
        if (vm.opentab.selected.structure == sect) { // if selected copy
            if (vm.opentab.selected.level2.indexOf(sect) >= 0) { //if open
                vm.opentab.selected.level2.splice(vm.opentab.selected.level2.indexOf(sect), 1); //close it
                vm.opentab.selected.structure.length = 0; //deselect it
            } else { // if not open
                vm.opentab.selected.level2.push(sect);//open it
            }
        } else { //if not selected copy

            vm.opentab.selected.structure.length = 0; //remove previously selected
            vm.opentab.selected.structure = [];
            vm.opentab.selected.structure.push(sect);// set as selected.
            if (vm.opentab.selected.level2.indexOf(sect) == -1) { // if not open
                vm.opentab.selected.level2.push(sect);//open it
            }
        }
    }


    vm.makeBold = function (input, wordsToBold, emphasis) {
        if(!emphasis){emphasis = 1;}
        try {
            sortArrayByLength(wordsToBold);
            input = vm.showSearch(input);
            if (emphasis === 1) {
                input = input.replace("’", "'").replace(/&nbsp;/g, " ").replace(new RegExp('(\\b)(' + wordsToBold.join('|') + ')(\\b)', 'ig'), '$1<b>$2</b>$3');
            } else if (emphasis === 2) {
                input = input.replace("’", "'").replace(/&nbsp;/g, " ").replace(new RegExp('(\\b)(' + wordsToBold.join('|') + ')(\\b)', 'ig'), '$1<i>$2</i>$3');
            } else if (emphasis === 3) {
                input = input.replace("’", "'").replace(/&nbsp;/g, " ");
            }

            return input;
        } catch (err) {
            return input;
        }
    }

    vm.showSearch = function (input) {
        try {
            if (vm.searchBooksText) {
                input = input.replace(/&nbsp;/g, " ")
                    .replace(new RegExp('()(' + _.escapeRegExp(vm.searchBooksText) + '(?!([^<]+)?>))()', 'ig'), '$1<mark class="search">$2</mark>$3');
            }
            return input;
        } catch (err) {
            return input;
        }

    }

    vm.getSearchByBook = function () {
        //console.log("getSearchByBook");
        Books.getSearchByBook({
            bookId: vm.opentab.bookId,
            search: vm.searchBooksText
        }).$promise.then(function (searchResults) {
            var searchTotal = 0;
            _.forEach(vm.book.Children, function (copy1) {
                copy1.searchCount = _.result(_.find(searchResults, {'structureId': copy1.structureId}), 'count');
                searchTotal = copy1.searchCount;
                _.forEach(copy1.Children, function (copy2) {
                    copy2.searchCount = _.result(_.find(searchResults, {'structureId': copy2.structureId}), 'count');
                    searchTotal = searchTotal + copy2.searchCount;
                    copy2.searchTotal = copy2.searchCount;
                    _.forEach(copy2.Children, function (copy3) {
                        copy3.searchCount = _.result(_.find(searchResults, {'structureId': copy3.structureId}), 'count');
                        copy2.searchTotal = copy2.searchTotal + copy3.searchCount;
                        searchTotal = searchTotal + copy3.searchCount;
                        _.forEach(copy3.Children, function (copy4) {
                            copy4.searchCount = _.result(_.find(searchResults, {'structureId': copy4.structureId}), 'count');
                            copy2.searchTotal = copy2.searchTotal + copy4.searchCount;
                            searchTotal = searchTotal + copy4.searchCount;
                        });
                    });
                });
                copy1.searchTotal = searchTotal;
            });
        });

    }

    vm.refresh = function(){
        vm.book.updateBook().then(function(){vm.getSearchByBook()})
    }

    $scope.$watch('tabBook.openSearch', function(newState,oldState){
        if(newState == false && oldState == true){
            vm.searchBooksText = '';
            vm.getSearchByBook();
        }else if(newState == true){
            vm.searchBooksText = angular.copy($localStorage.pagination.filterLibrary.textSearch);
            vm.getSearchByBook();
        }
    });


    $scope.$watch('tabBook.isloading', function(current, old) {
        // on book load, open current workflow section by default.
        if(!vm.isloading && vm.book && vm.book.currentWorkflowStatus){
            if ((!vm.book.status || vm.book.status !== 1) && // don't force-open workflow-sections for completed books
                (!vm.book.documentStatus || vm.book.documentStatus.slice(0,4) !== 'EXPD') && // don't force-open workflow-sections for expired books
                vm.book.currentWorkflowStatus.content !== 'doc') // don't force-open the document content section
            {
                if (!vm.opentab.selected) {
                    vm.opentab.selected = {};
                }
                vm.opentab.selected[vm.book.currentWorkflowStatus.content] = true;
            }
        }
    });





   // vm.$onInit = function(){
        //vm.isLoading = true;
        //vm.book = $filter('filter')(WrapsBooks.openBooks, {bookId: vm.opentab.bookId})[0];
        //console.log(vm.book);
        //$scope.$watch('WrapsBooks.openBooks', function(current, old) {
            //console.log(current, WrapsBooks.openBooks);

            //console.log(book);
            // vm.isLoading=true;
            // WrapsBooks.getBooks({bookId: parseInt(vm.opentab.bookId)}).then(function(results){
            //     if(results.length > 0){
            //         vm.book = results[0];
            //         vm.book.getBook();
            //     }
            //     vm.isLoading=false;
            //
            // });
        //});
    //};


}
