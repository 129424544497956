(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableSocialChannel', readableSocialChannel);

  function readableSocialChannel(Wraps) {
    return function (id) {
      var socialChannel;
      id = parseInt(id);
      if (angular.isNumber(id)) {
        if ((socialChannel = _.find(Wraps.socialChannels, {socialChannelId: parseInt(id)})) && socialChannel.socialChannelName) {
          return socialChannel.socialChannelName;
        }
      }
      return null;
    };
  }
}());
