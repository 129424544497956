(function () {
  'use strict';

  angular
    .module('library')
    .config(config);

  function config($stateProvider) {
    $stateProvider
        .state('library', {
            url: '/library/:bookId',
            params: {
                bookId: {
                    dynamic: true,
                    value: null,
                    squash: true
                }
            },
            views: {
                tooltip: {
                  templateUrl: 'tooltip/tooltip.tpl.html',
                  controller: 'TooltipCtrl',
                  controllerAs: 'tooltip'
                },
                navigation: {
                  templateUrl: 'navigation/navigation.tpl.html',
                  controller: 'NavigationCtrl',
                  controllerAs: 'navigation'
                },
                main: {
                  templateUrl: 'library/library.tpl.html',
                  controller: 'LibraryCtrl',
                  controllerAs: 'library'
                },
                footer: {
                  templateUrl: 'footer/footer.tpl.html',
                  controller: 'FooterCtrl',
                  controllerAs: 'footer'
                }
            }
        }).state('libraryLink', {
      url: '/library/link/:docId',
      views: {
        main: {

          controller: function ($scope, $localStorage, $sessionStorage, $location, $stateParams, atob) {
            var selected;
            // get the id
            $scope.id = $stateParams.docId;


            $scope.$storage = $localStorage;
            $scope.$session = $sessionStorage;

            // console.log('$location', $location);
            // console.log('$location.$$url', $location.$$url);
            if ($stateParams.docId) {
              selected = angular.fromJson(atob(decodeURIComponent($stateParams.docId)));
              // console.log("selected", selected);

              $scope.$storage.selectedBook = selected.selectedBook;
              $scope.$storage.selectedCopy = selected.structureId;
              $scope.$storage.filterLibrary = {bookIdLong: selected.selectedBook};
              $scope.$session.selectedList = selected.selectedList;

              $location.url('/library');
            }
          }
        }
      }
    });
  }
}());
