(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.changedialog.controller:ChangedialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.changedialog')
    .controller('ChangedialogCtrl', ChangedialogCtrl);

  function ChangedialogCtrl($localStorage, $mdDialog, $scope, filter, Wraps) {
    var vm = this;
    vm.ctrlName = 'ChangedialogCtrl';

    $scope.$storage = $localStorage;

    vm.rfcReasons = Wraps.rfcReasons;

    if (($scope.$storage.user.roleId != 5 && $scope.$storage.user.roleId != 1) ) {
      vm.rfcReasons = _.filter(vm.rfcReasons, function (reason) { return reason.id !== 48; });
    }

    if(angular.isUndefined(filter)){vm.filter = 'rfc';}else{
      var splitFilt = filter.split('-')
      if(splitFilt[1] && splitFilt[1] != '1'){
          vm.rfcReasons = _.filter(vm.rfcReasons, function (reason) { return reason.id !== 48; });
      }
      if(splitFilt[0] == "resetToBrief" || splitFilt[0] == "resetToMCO") {
          vm.noCheckbox = true;
          vm.filter = 'rfc';
      }else{
          vm.filter = splitFilt[0];
      }

    }

      //console.log(vm.filter);


    vm.getRFC = function(){
      return angular.copy($scope.$storage.lastRFC);
    }

    vm.dialogHide = function(data) {
      vm.isLoading = true;
      $scope.$storage.lastRFC = angular.copy(data);
      $mdDialog.hide(data);
    };

    vm.dialogCancel = function(data) {
      $mdDialog.cancel(data);
    };
  }
}());