<div>
<md-toolbar class="md-theme-light">
    <div class="md-toolbar-tools">
        <h1 flex class="md-title" wraps-tooltip="settings-title">Settings</h1>
        <md-button ng-click="library.closeLeftMenu()" class="md-icon-button" aria-label="Close left pane" wraps-tooltip="settings-close">
            <ng-md-icon icon="close" size="24"></ng-md-icon>
        </md-button>
    </div>
</md-toolbar>
<md-content  layout-padding style="padding-top:0px;">

    <div >
        <md-tabs md-dynamic-height md-border-bottom md-selected="library.leftMenuTabIndex">
            <md-tab label="Column Visibility">
					<p>Please note that adding too many columns will cause readability issues.</p>
				<div layout="row" layout-wrap>
					<div flex="33"><md-switch name="Book Code" ng-model="$storage.showFilters.code">Book Code</md-switch></div>
                    <div flex="33"><md-switch name="PSI Code" ng-model="$storage.showFilters.psiCode">PSI Code</md-switch></div>
                    <div flex="33"><md-switch name="Book Name" ng-model="$storage.showFilters.name">Book Name</md-switch></div>
                    <div flex="33"><md-switch name="Insurer" ng-model="$storage.showFilters.insurer">Insurer</md-switch></div>
                    <div flex="33"><md-switch name="InsurerDetails" ng-model="$storage.showFilters.insurerDetails">Insurer Details</md-switch></div>
                    <div flex="33"><md-switch name="Product" ng-model="$storage.showFilters.product">Product</md-switch></div>
                    <div flex="33"><md-switch name="Audience" ng-model="$storage.showFilters.audience">Audience</md-switch></div>
                    <div flex="33"><md-switch name="Region" ng-model="$storage.showFilters.region" >Region</md-switch></div>
                    <div flex="33"><md-switch name="Product Type" ng-model="$storage.showFilters.productType">Customer Type</md-switch></div>
                    <div flex="33"><md-switch name="Doc Type" ng-model="$storage.showFilters.docTypeCatName">Master Doc Type</md-switch></div>
                    <div flex="33"><md-switch name="Doc Type" ng-model="$storage.showFilters.doc">Doc Type</md-switch></div>
                    <div flex="33"><md-switch name="Fin Prom" ng-model="$storage.showFilters.finProm">Fin Prom</md-switch></div>
                    <div flex="33"><md-switch name="Client" ng-model="$storage.showFilters.clients">Client</md-switch></div>
                    <div flex="33"><md-switch name="Owner" ng-model="$storage.showFilters.Owner.fullName">Owner</md-switch></div>
                    <div flex="33"><md-switch name="Owner Team" ng-model="$storage.showFilters.Owner.teamName">Owner Team</md-switch></div>
                    <div flex="33"><md-switch name="Division" ng-model="$storage.showFilters.division">Division</md-switch></div>
                    <div flex="33"><md-switch name="Workflow Status" ng-model="$storage.showFilters.workflowStatus">Workflow Status</md-switch></div>
                    <div flex="33"><md-switch name="Document Status" ng-model="$storage.showFilters.documentStatus">Document Status</md-switch></div>
                    <div flex="33"><md-switch name="Date" ng-model="$storage.showFilters.date">Date Created</md-switch></div>
                    <div flex="33"><md-switch name="Date" ng-model="$storage.showFilters.lastUpdated">Date Updated</md-switch></div>
                    <div flex="33"><md-switch name="Date" ng-model="$storage.showFilters.activeDate">Active Date</md-switch></div>
                    <div flex="33"><md-switch name="Date" ng-model="$storage.showFilters.liveDate">Live Date</md-switch></div>
                    <div flex="33"><md-switch name="Date" ng-model="$storage.showFilters.reviewDate">Review Date</md-switch></div>
                    <div flex="33"><md-switch name="Date" ng-model="$storage.showFilters.expiryDate">Expiry Date</md-switch></div>
                    <div flex="33"><md-switch name="Date" ng-model="$storage.showFilters.inWorkflow">In Workflow</md-switch></div>
                    <div flex="33"><md-switch name="Project Name" ng-model="$storage.showFilters.projectName">Project Name</md-switch></div>
				</div>
                <md-button class="md-primary" ng-click="library.resetColVisibility()">Use defaults</md-button>
                </md-tab>
                <md-tab label="Saved Searches">
					<div class="grey-area">
						<h4>Save current search:</h4>
						<p>{{library.stringifySearch($storage.pagination.filterLibrary)}}</p><p ng-if="library.stringifySearch($storage.pagination.filterLibrary) == ''">Apply a filter before trying to save search.</p>
						<div layout="row">
						<md-input-container flex="50">
							<label>Name of search</label>
							<input ng-model="library.searchName" name="saveSearch">
						</md-input-container>
						<md-input-container flex="50">
							<md-button ng-click="library.saveSearch()" ng-disabled="library.searchName == '' || library.stringifySearch($storage.pagination.filterLibrary) == ''" class="md-button md-raised md-primary" aria-label="Save current search">Save search</md-button>
						</md-input-container>
						</div>
					</div>
					<h4>Load a saved search:</h4>
					<md-list ng-if="library.savedSearches.length != 0">
						<md-list-item ng-repeat="search in library.savedSearches" ng-click="library.loadSearchBlob(search.searchId)">
							<p>{{search.searchName}}</p>
							<md-button class="md-icon-button" ng-click="library.deleteSearchSave(search.searchId, search.searchName, $event)" aria-label="Delete"><ng-md-icon icon="delete" style="fill:grey" size="24"></ng-md-icon></md-button>
						</md-list-item>
					</md-list>
					<div ng-if="library.savedSearches.length == 0">
						<p><strong>You have no saved searches.</strong><br/>To save a search, search the library as normal, then click the save icon and use the form above to name the search.</p>
					</div>
                </md-tab>
            </md-tabs>
</div>






</md-content>
</div>
