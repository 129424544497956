(function () {
  'use strict';

  angular
    .module('maintenance')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('maintenance', {
        url: '/maintenance',
        views: {
		  tooltip: {
              templateUrl: 'tooltip/tooltip.tpl.html',
              controller: 'TooltipCtrl',
              controllerAs: 'tooltip'
          },
          navigation: {
            templateUrl: 'navigation/navigation.tpl.html',
            controller: 'NavigationCtrl',
            controllerAs: 'navigation'
          },
          main: {
            templateUrl: 'maintenance/maintenance.tpl.html',
            controller: 'MaintenanceCtrl',
            controllerAs: 'maintenance'
          },
          footer: {
            templateUrl: 'footer/footer.tpl.html',
            controller: 'FooterCtrl',
            controllerAs: 'footer'
          }
        }
      });
  }
}());
