(function () {
  'use strict';

  /* @ngdoc object
   * @name permissionmanagement
   * @description
   *
   */
  angular
    .module('permissionmanagement', [
      'ui.router'
    ]);
}());
