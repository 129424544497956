angular
    .module('library')
    .component('dateIcon', {
        templateUrl: 'library/library-components/date-icon/date-icon.tpl.html',
        controller: dateIconCtrl,
        controllerAs:'dateIcon',
        bindings:{
            date:'<',
            color:'<',
            label:'@',
            notsetlabel:'<'
        }

    });

dateIconCtrl.$inject = ['$scope'];
function dateIconCtrl($scope){
    var vm = this;
    $scope.$watch('dateIcon.notsetlabel', function(){
        if(vm.notsetlabel){
            vm.notSetLabel = vm.notsetlabel.split(',');
            if(vm.notSetLabel.length <3){
                vm.notSetLabel.unshift(' ');
            }
        }

    });
}