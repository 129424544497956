<md-dialog aria-label="Add Chapter"  class="docdiffdialog">

    <md-toolbar>
        <div class="md-toolbar-tools">
            <h2>Content Changes</h2>
            <span flex></span>

            <md-button ng-click="docdiffdialog.dialogCancel(false)" class="md-icon-button" >
                <ng-md-icon  icon="close" style="fill: white;" size="24"  aria-label="Close dialog"></ng-md-icon>
            </md-button>
        </div>
    </md-toolbar>
    <md-dialog-content class="dialog-content" style="">


        <md-content flex>
            <div flex layout="column">
                <div layout="row" flex="100" layout-wrap style="padding:6px; background:#bbb;">
                    <div flex="100" style="text-align:left; color: #fff; font-size: 14px;" ng-bind-html="docdiffdialog.change.location"></div>
                </div>

                <md-tabs md-dynamic-height md-border-bottom md-selected="docdiffdialog.tabIndex">

                    <md-tab ng-if="docdiffdialog.history;" label="Content Changes">

                        <div flex layout="column" layout-gt-md="row" layout-padding>

                            <div ng-class="[docdiffdialog.change.contentTypeName]" class="diffDialog" style="border-left: 0;" ng-bind-html="docdiffdialog.diff"></div>

                        </div>
                    </md-tab>
                    <md-tab ng-if="docdiffdialog.history;" label="Old">

                        <div flex layout="column" layout-gt-md="row" layout-padding>

                            <!--<div ng-class="[docdiffdialog.change.contentTypeName]" class="diffDialog" style="border-left: 0;" ng-bind-html="left"></div>-->
                            <get-contents item="docdiffdialog.left"></get-contents>
                        </div>
                    </md-tab>
                    <md-tab ng-if="docdiffdialog.history;" label="New">

                        <div flex layout="column" layout-gt-md="row" layout-padding>

                            <!--<div ng-class="[docdiffdialog.change.contentTypeName]" class="diffDialog" style="border-left: 0;" ng-bind-html="right"></div>-->
                            <get-contents item="docdiffdialog.right"></get-contents>


                        </div>
                    </md-tab>

                    <md-tab ng-if="!docdiffdialog.history;" label="Content">

                        <div flex layout="column" layout-gt-md="row" layout-padding>

                            <!--<div ng-class="[docdiffdialog.change.contentTypeName]" class="diffDialog" style="border-left: 0;" ng-bind-html="right"></div>-->
                            <get-contents item="docdiffdialog.content"></get-contents>


                        </div>
                    </md-tab>
                    <md-tab label="Comments">
                        <md-content layout="column" flex class="comments-pane">

                            <add-comments child="docdiffdialog.child" ng-if="docdiffdialog.child && docdiffdialog.hasPermissions('addComments')"></add-comments>
                            <div class="divider"></div>
                            <md-progress-linear ng-if="docdiffdialog.isLoading" md-mode="indeterminate"></md-progress-linear>
                            <div class="comments-tab" flex>

                                <div ng-repeat="event in docdiffdialog.events" layout="row" layout-padding class="comment-container" ng-class="{'self-comment':$storage.user.id==event.Users.id}">
                                    <div aria-label="{{event.Users.firstName}} {{event.Users.lastName}}" class="avatar-container" wraps-tooltip="comment-user-avatar">
                                        <img ng-if="event.Users && event.Users.profilePic != '' && event.Users.profilePic != null" ng-src="{{docdiffdialog.apiUrl}}/containers/getthumbnail/{{event.Users.profilePic}}" class="user-avatar" alt="User Avatar"/>
                                        <div ng-if="event.Users && (event.Users.profilePic == '' || event.Users.profilePic == null)" class="user-avatar initials {{event.Users.lastName|lowercase|limitTo:1}}">{{event.Users.firstName|uppercase|limitTo:1}}{{event.Users.lastName|uppercase|limitTo:1}}</div>
                                        <img ng-if="!event.Users" ng-src="/images/wraps-logo.png" md-colors="{background: 'primary'}" class="user-avatar sys-notif" alt="WRAPS"/>

                                    </div>
                                    <div flex layout="row" layout-wrap class="comment-text">
                                        <span class="md-body-2" flex="100">{{event.eventType}}</span><br/>
                                        <span class="md-caption" flex="50">{{event.Users.firstName}} {{event.Users.lastName}}</span><span class="md-caption timestamp" flex="50">{{event.created | date:'dd MMM yyyy HH:mm'}}</span>
                                        <div flex="100" class="comment-bubble">

                                            <span >{{event.Comment.message}}</span>
                                            <!--<span style="white-space: pre-wrap;" ng-if="event.eventType==='Comment'">{{event.Comment.message}}</span>-->
                                            <span style="white-space: pre-wrap;" ng-if="!event.Comment">{{event.eventData}}</span>
                                            <div style="" ng-if="event.categories.length > 0">
                                                - Category: <span data-ng-repeat="category in event.categories">{{category}} </span>
                                            </div>
                                            <div style="" ng-if="event.rfcReasons.length > 0">
                                                - Reason: <span data-ng-repeat="reason in event.rfcReasons">{{reason}} </span>
                                            </div>
                                            <div style="" ng-if="event.Comment.filesList.length > 0">
                                                <ul class="files-ul" style="white-space: normal; margin-top:-10px; font-size: 12px;">
                                                    <li ng-repeat="file in event.Comment.filesList" ng-switch="file.fileName.split('.').pop()">
                                                        <ng-md-icon ng-switch-when="pdf|doc|docx" ng-switch-when-separator="|" class="file-download" icon="insert_drive_file" size="24" aria-label="Upload complete"></ng-md-icon>
                                                        <ng-md-icon ng-switch-when="jpg|png|jpeg|gif|svg" ng-switch-when-separator="|" class="file-download" icon="image" size="24" aria-label="Upload complete"></ng-md-icon>
                                                        <ng-md-icon ng-switch-when="csv|xls|xlsx" ng-switch-when-separator="|" class="file-download" icon="border_all" size="24" aria-label="Upload complete"></ng-md-icon>
                                                        <ng-md-icon ng-switch-when="htm|html|js|php" ng-switch-when-separator="|" class="file-download" icon="code" size="24" aria-label="Upload complete"></ng-md-icon>
                                                        <ng-md-icon ng-switch-default class="file-download" icon="attach_file" size="24" aria-label="Upload complete"></ng-md-icon>
                                                        {{file.fileName}}
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>

                                    </div>
                                    <div style="width:48px; text-align:center">
                                        <md-button class="md-icon-button" ng-if="event.Messages" ng-click="library.openEmailDetailsDialog(event.eventId)"  aria-label="alert">
                                            <ng-md-icon  icon="email" style="fill: #ccc;" size="24"></ng-md-icon>
                                        </md-button>

                                        <md-button ng-if="event.structureId && leftComments.book.DocTypes.docTypeTemplate === 1" wraps-tooltip="action-icon"  class="md-icon-button" ng-click="library.openDocDiffDialog(library.getChangesByStructureId(event.structureId))"  aria-label="alert">
                                            <ng-md-icon  icon="info" style="fill: #ccc;" size="24"></ng-md-icon>
                                        </md-button>
                                    </div>
                                </div>


                            </div>
                        </md-content>
                    </md-tab>
                </md-tabs>

            </div>
        </md-content>


    </md-dialog-content>
    <md-dialog-actions layout="row">
        <span flex></span>

        <md-button class="md-grey"  ng-click="docdiffdialog.dialogCancel(false)">Close <ng-md-icon icon="close" size="20"></ng-md-icon></md-button>

    </md-dialog-actions>
</md-dialog>
