// CommonJS package manager support
if (typeof module !== 'undefined' && typeof exports !== 'undefined' &&
  module.exports === exports) {
  // Export the *name* of this Angular module
  // Sample usage:
  //
  //   import lbServices from './lb-services';
  //   angular.module('app', [lbServices]);
  //
  module.exports = "lbServices";
}

(function(window, angular, undefined) {
  'use strict';

  var urlBase = "/api";
  var authHeader = 'authorization';

  function getHost(url) {
    var m = url.match(/^(?:https?:)?\/\/([^\/]+)/);
    return m ? m[1] : null;
  }

  var urlBaseHost = getHost(urlBase) || location.host;

/**
 * @ngdoc overview
 * @name lbServices
 * @module
 * @description
 *
 * The `lbServices` module provides services for interacting with
 * the models exposed by the LoopBack server via the REST API.
 *
 */
  var module = angular.module("lbServices", ['ngResource']);

/**
 * @ngdoc object
 * @name lbServices.AppVersions
 * @header lbServices.AppVersions
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `AppVersions` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "AppVersions",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/AppVersions/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#create
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/AppVersions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#createMany
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/AppVersions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#upsert
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/AppVersions",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#replaceOrCreate
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/AppVersions/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#upsertWithWhere
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/AppVersions/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#exists
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/AppVersions/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#findById
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/AppVersions/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#replaceById
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/AppVersions/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#find
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/AppVersions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#findOne
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/AppVersions/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#updateAll
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/AppVersions/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#deleteById
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/AppVersions/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#count
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/AppVersions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#prototype$updateAttributes
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AppVersions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/AppVersions/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#createChangeStream
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/AppVersions/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.AppVersions#patchOrCreate
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#updateOrCreate
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#patchOrCreateWithWhere
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#update
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#destroyById
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#removeById
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#patchAttributes
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AppVersions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.AppVersions#modelName
        * @propertyOf lbServices.AppVersions
        * @description
        * The name of the model represented by this $resource,
        * i.e. `AppVersions`.
        */
        R.modelName = "AppVersions";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Audiences
 * @header lbServices.Audiences
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Audiences` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Audiences",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Audiences/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Audiences#__get__notDeleted
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Queries notDeleted of Audiences.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Audiences/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#__create__notDeleted
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Audiences/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#__delete__notDeleted
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Audiences/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#__count__notDeleted
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Counts notDeleted of Audiences.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Audiences/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#create
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Audiences",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#createMany
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Audiences",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#upsert
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Audiences",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#replaceOrCreate
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Audiences/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#upsertWithWhere
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Audiences/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#exists
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Audiences/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#findById
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Audiences/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#replaceById
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Audiences/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#find
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Audiences",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#findOne
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Audiences/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#updateAll
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Audiences/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#deleteById
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Audiences/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#count
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Audiences/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#prototype$updateAttributes
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Audiences id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Audiences/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#createChangeStream
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Audiences/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Books.Audiences() instead.
            "::get::Books::Audiences": {
              url: urlBase + "/Books/:id/Audiences",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Audiences#patchOrCreate
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Audiences#updateOrCreate
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Audiences#patchOrCreateWithWhere
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Audiences#update
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Audiences#destroyById
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Audiences#removeById
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Audiences#patchAttributes
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Audiences id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Audiences#modelName
        * @propertyOf lbServices.Audiences
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Audiences`.
        */
        R.modelName = "Audiences";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Books
 * @header lbServices.Books
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Books` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Books",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Books/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Books.Audiences() instead.
            "prototype$__get__Audiences": {
              url: urlBase + "/Books/:id/Audiences",
              method: "GET",
            },

            // INTERNAL. Use Books.Clients() instead.
            "prototype$__get__Clients": {
              url: urlBase + "/Books/:id/Clients",
              method: "GET",
            },

            // INTERNAL. Use Books.Distributors() instead.
            "prototype$__get__Distributors": {
              url: urlBase + "/Books/:id/Distributors",
              method: "GET",
            },

            // INTERNAL. Use Books.DocTypes() instead.
            "prototype$__get__DocTypes": {
              url: urlBase + "/Books/:id/DocTypes",
              method: "GET",
            },

            // INTERNAL. Use Books.Enclosures.findById() instead.
            "prototype$__findById__Enclosures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Enclosures/:fk",
              method: "GET",
            },

            // INTERNAL. Use Books.Enclosures.destroyById() instead.
            "prototype$__destroyById__Enclosures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Enclosures/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Enclosures.updateById() instead.
            "prototype$__updateById__Enclosures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Enclosures/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Books.Insurers() instead.
            "prototype$__get__Insurers": {
              url: urlBase + "/Books/:id/Insurers",
              method: "GET",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.findById() instead.
            "prototype$__findById__ProductIdsBookLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/ProductIdsBookLink/:fk",
              method: "GET",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.destroyById() instead.
            "prototype$__destroyById__ProductIdsBookLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/ProductIdsBookLink/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.updateById() instead.
            "prototype$__updateById__ProductIdsBookLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/ProductIdsBookLink/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Books.Products() instead.
            "prototype$__get__Products": {
              url: urlBase + "/Books/:id/Products",
              method: "GET",
            },

            // INTERNAL. Use Books.ProductTypes() instead.
            "prototype$__get__ProductTypes": {
              url: urlBase + "/Books/:id/ProductTypes",
              method: "GET",
            },

            // INTERNAL. Use Books.Projects() instead.
            "prototype$__get__Projects": {
              url: urlBase + "/Books/:id/Projects",
              method: "GET",
            },

            // INTERNAL. Use Books.Regions() instead.
            "prototype$__get__Regions": {
              url: urlBase + "/Books/:id/Regions",
              method: "GET",
            },

            // INTERNAL. Use Books.Children.findById() instead.
            "prototype$__findById__Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Children/:fk",
              method: "GET",
            },

            // INTERNAL. Use Books.Children.destroyById() instead.
            "prototype$__destroyById__Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Children/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Children.updateById() instead.
            "prototype$__updateById__Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Children/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Books.Structures.findById() instead.
            "prototype$__findById__Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Structures/:fk",
              method: "GET",
            },

            // INTERNAL. Use Books.Structures.destroyById() instead.
            "prototype$__destroyById__Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Structures/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Structures.updateById() instead.
            "prototype$__updateById__Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Structures/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Books.SubInsurers() instead.
            "prototype$__get__SubInsurers": {
              url: urlBase + "/Books/:id/SubInsurers",
              method: "GET",
            },

            // INTERNAL. Use Books.Users() instead.
            "prototype$__get__Users": {
              url: urlBase + "/Books/:id/Users",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__get__getStructure
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Queries getStructure of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "__get__getStructure": {
              isArray: true,
              url: urlBase + "/Books/getStructure",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__create__getStructure
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Creates a new instance in getStructure of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "__create__getStructure": {
              url: urlBase + "/Books/getStructure",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__delete__getStructure
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Deletes all getStructure of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__getStructure": {
              url: urlBase + "/Books/getStructure",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__count__getStructure
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Counts getStructure of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__getStructure": {
              url: urlBase + "/Books/getStructure/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__get__getStructureVersions
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Queries getStructureVersions of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "__get__getStructureVersions": {
              isArray: true,
              url: urlBase + "/Books/getStructureVersions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__create__getStructureVersions
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Creates a new instance in getStructureVersions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "__create__getStructureVersions": {
              url: urlBase + "/Books/getStructureVersions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__delete__getStructureVersions
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Deletes all getStructureVersions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__getStructureVersions": {
              url: urlBase + "/Books/getStructureVersions",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__count__getStructureVersions
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Counts getStructureVersions of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__getStructureVersions": {
              url: urlBase + "/Books/getStructureVersions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__get__getStructureVersionsContents
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Queries getStructureVersionsContents of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "__get__getStructureVersionsContents": {
              isArray: true,
              url: urlBase + "/Books/getStructureVersionsContents",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__create__getStructureVersionsContents
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Creates a new instance in getStructureVersionsContents of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "__create__getStructureVersionsContents": {
              url: urlBase + "/Books/getStructureVersionsContents",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__delete__getStructureVersionsContents
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Deletes all getStructureVersionsContents of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__getStructureVersionsContents": {
              url: urlBase + "/Books/getStructureVersionsContents",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__count__getStructureVersionsContents
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Counts getStructureVersionsContents of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__getStructureVersionsContents": {
              url: urlBase + "/Books/getStructureVersionsContents/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__get__getBooks
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Queries getBooks of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "__get__getBooks": {
              isArray: true,
              url: urlBase + "/Books/getBooks",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__create__getBooks
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Creates a new instance in getBooks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "__create__getBooks": {
              url: urlBase + "/Books/getBooks",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__delete__getBooks
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Deletes all getBooks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__getBooks": {
              url: urlBase + "/Books/getBooks",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__count__getBooks
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Counts getBooks of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__getBooks": {
              url: urlBase + "/Books/getBooks/count",
              method: "GET",
            },

            // INTERNAL. Use Books.Enclosures() instead.
            "prototype$__get__Enclosures": {
              isArray: true,
              url: urlBase + "/Books/:id/Enclosures",
              method: "GET",
            },

            // INTERNAL. Use Books.Enclosures.create() instead.
            "prototype$__create__Enclosures": {
              url: urlBase + "/Books/:id/Enclosures",
              method: "POST",
            },

            // INTERNAL. Use Books.Enclosures.destroyAll() instead.
            "prototype$__delete__Enclosures": {
              url: urlBase + "/Books/:id/Enclosures",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Enclosures.count() instead.
            "prototype$__count__Enclosures": {
              url: urlBase + "/Books/:id/Enclosures/count",
              method: "GET",
            },

            // INTERNAL. Use Books.ProductIdsBookLink() instead.
            "prototype$__get__ProductIdsBookLink": {
              isArray: true,
              url: urlBase + "/Books/:id/ProductIdsBookLink",
              method: "GET",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.create() instead.
            "prototype$__create__ProductIdsBookLink": {
              url: urlBase + "/Books/:id/ProductIdsBookLink",
              method: "POST",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.destroyAll() instead.
            "prototype$__delete__ProductIdsBookLink": {
              url: urlBase + "/Books/:id/ProductIdsBookLink",
              method: "DELETE",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.count() instead.
            "prototype$__count__ProductIdsBookLink": {
              url: urlBase + "/Books/:id/ProductIdsBookLink/count",
              method: "GET",
            },

            // INTERNAL. Use Books.Children() instead.
            "prototype$__get__Children": {
              isArray: true,
              url: urlBase + "/Books/:id/Children",
              method: "GET",
            },

            // INTERNAL. Use Books.Children.create() instead.
            "prototype$__create__Children": {
              url: urlBase + "/Books/:id/Children",
              method: "POST",
            },

            // INTERNAL. Use Books.Children.destroyAll() instead.
            "prototype$__delete__Children": {
              url: urlBase + "/Books/:id/Children",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Children.count() instead.
            "prototype$__count__Children": {
              url: urlBase + "/Books/:id/Children/count",
              method: "GET",
            },

            // INTERNAL. Use Books.Structures() instead.
            "prototype$__get__Structures": {
              isArray: true,
              url: urlBase + "/Books/:id/Structures",
              method: "GET",
            },

            // INTERNAL. Use Books.Structures.create() instead.
            "prototype$__create__Structures": {
              url: urlBase + "/Books/:id/Structures",
              method: "POST",
            },

            // INTERNAL. Use Books.Structures.destroyAll() instead.
            "prototype$__delete__Structures": {
              url: urlBase + "/Books/:id/Structures",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Structures.count() instead.
            "prototype$__count__Structures": {
              url: urlBase + "/Books/:id/Structures/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#create
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Books",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#createMany
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Books",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#upsert
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Books",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#replaceOrCreate
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Books/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#upsertWithWhere
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Books/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#exists
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Books/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#findById
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Books/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#replaceById
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Books/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#find
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Books",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#findOne
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Books/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#updateAll
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Books/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#deleteById
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Books/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#count
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Books/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#prototype$updateAttributes
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Books/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#createChangeStream
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Books/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getLibrarySearch
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Get Library Search
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getLibrarySearch": {
              url: urlBase + "/Books/getLibrarySearch",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#checkForErrors
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Check all documents for errors
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "checkForErrors": {
              url: urlBase + "/Books/checkForErrors",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#sendExpired
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Send email for expired books
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "sendExpired": {
              isArray: true,
              url: urlBase + "/Books/sendExpired",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getSearchOverview
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Get list of books in search with count
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getSearchOverview": {
              isArray: true,
              url: urlBase + "/Books/getSearchOverview",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getSearchByBook
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Get list of books in search with count
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getSearchByBook": {
              isArray: true,
              url: urlBase + "/Books/getSearchByBook",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#trash
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Set books to deleted
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "trash": {
              url: urlBase + "/Books/trash",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#requestDeletion
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Request Document gets deleted
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "requestDeletion": {
              url: urlBase + "/Books/requestDeletion",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#setBookStatuses
             * @methodOf lbServices.Books
             *
             * @description
             *
             * check and set book status
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "setBookStatuses": {
              isArray: true,
              url: urlBase + "/Books/setBookStatuses",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getNotifications
             * @methodOf lbServices.Books
             *
             * @description
             *
             * get black assets by owner
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getNotifications": {
              url: urlBase + "/Books/getNotifications",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getActiveDocumentsByOwner
             * @methodOf lbServices.Books
             *
             * @description
             *
             * get black assets by owner
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getActiveDocumentsByOwner": {
              url: urlBase + "/Books/getActiveDocumentsByOwner",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getBlackAssets
             * @methodOf lbServices.Books
             *
             * @description
             *
             * get black assets by owner
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getBlackAssets": {
              url: urlBase + "/Books/getBlackAssets",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getDocumentsUserInvolvedIn
             * @methodOf lbServices.Books
             *
             * @description
             *
             * get black assets by owner
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getDocumentsUserInvolvedIn": {
              url: urlBase + "/Books/getDocumentsUserInvolvedIn",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getInCycleDocuments
             * @methodOf lbServices.Books
             *
             * @description
             *
             * get black assets by owner
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getInCycleDocuments": {
              url: urlBase + "/Books/getInCycleDocuments",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getFulfilment
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Get list of books for fulfilment
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getFulfilment": {
              url: urlBase + "/Books/getFulfilment",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getYourDocumentsStats
             * @methodOf lbServices.Books
             *
             * @description
             *
             * get documents by owner
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getYourDocumentsStats": {
              url: urlBase + "/Books/getYourDocumentsStats",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getBooksForDrive
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Get a book for drive with params
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getBooksForDrive": {
              isArray: true,
              url: urlBase + "/Books/getBooksForDrive",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getDownloadsForDrive
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Get a download paths for drive with book id
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getDownloadsForDrive": {
              isArray: true,
              url: urlBase + "/Books/getDownloadsForDrive",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getUpdatedBookIds
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Get ids of books that have been updated
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getUpdatedBookIds": {
              url: urlBase + "/Books/getUpdatedBookIds",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#generateDocumentCode
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Auto generates a document code
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "generateDocumentCode": {
              isArray: true,
              url: urlBase + "/Books/generateDocumentCode",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#fixProductionDoubleEncoding
             * @methodOf lbServices.Books
             *
             * @description
             *
             * normalise double-encoded data in production, fullfilment, inSitu, digital and support fields
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "fixProductionDoubleEncoding": {
              isArray: true,
              url: urlBase + "/Books/fixProductionDoubleEncoding",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#fixMultiEscaping
             * @methodOf lbServices.Books
             *
             * @description
             *
             * normalise over-escaped data in levels field
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "fixMultiEscaping": {
              isArray: true,
              url: urlBase + "/Books/fixMultiEscaping",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getBookCodesBySearch
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Get codes by book search
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getBookCodesBySearch": {
              url: urlBase + "/Books/getBookCodesBySearch",
              method: "POST",
            },

            // INTERNAL. Use DocTypes.Books.findById() instead.
            "::findById::DocTypes::Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypes/:id/Books/:fk",
              method: "GET",
            },

            // INTERNAL. Use DocTypes.Books.destroyById() instead.
            "::destroyById::DocTypes::Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypes/:id/Books/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use DocTypes.Books.updateById() instead.
            "::updateById::DocTypes::Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypes/:id/Books/:fk",
              method: "PUT",
            },

            // INTERNAL. Use DocTypes.Books() instead.
            "::get::DocTypes::Books": {
              isArray: true,
              url: urlBase + "/DocTypes/:id/Books",
              method: "GET",
            },

            // INTERNAL. Use DocTypes.Books.create() instead.
            "::create::DocTypes::Books": {
              url: urlBase + "/DocTypes/:id/Books",
              method: "POST",
            },

            // INTERNAL. Use DocTypes.Books.createMany() instead.
            "::createMany::DocTypes::Books": {
              isArray: true,
              url: urlBase + "/DocTypes/:id/Books",
              method: "POST",
            },

            // INTERNAL. Use DocTypes.Books.destroyAll() instead.
            "::delete::DocTypes::Books": {
              url: urlBase + "/DocTypes/:id/Books",
              method: "DELETE",
            },

            // INTERNAL. Use DocTypes.Books.count() instead.
            "::count::DocTypes::Books": {
              url: urlBase + "/DocTypes/:id/Books/count",
              method: "GET",
            },

            // INTERNAL. Use Structures.Books() instead.
            "::get::Structures::Books": {
              url: urlBase + "/Structures/:id/Books",
              method: "GET",
            },

            // INTERNAL. Use Users.Books.findById() instead.
            "::findById::Users::Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Books/:fk",
              method: "GET",
            },

            // INTERNAL. Use Users.Books.destroyById() instead.
            "::destroyById::Users::Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Books/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Users.Books.updateById() instead.
            "::updateById::Users::Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Books/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Users.Books() instead.
            "::get::Users::Books": {
              isArray: true,
              url: urlBase + "/Users/:id/Books",
              method: "GET",
            },

            // INTERNAL. Use Users.Books.create() instead.
            "::create::Users::Books": {
              url: urlBase + "/Users/:id/Books",
              method: "POST",
            },

            // INTERNAL. Use Users.Books.createMany() instead.
            "::createMany::Users::Books": {
              isArray: true,
              url: urlBase + "/Users/:id/Books",
              method: "POST",
            },

            // INTERNAL. Use Users.Books.destroyAll() instead.
            "::delete::Users::Books": {
              url: urlBase + "/Users/:id/Books",
              method: "DELETE",
            },

            // INTERNAL. Use Users.Books.count() instead.
            "::count::Users::Books": {
              url: urlBase + "/Users/:id/Books/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Books#patchOrCreate
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Books#updateOrCreate
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Books#patchOrCreateWithWhere
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Books#update
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Books#destroyById
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Books#removeById
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Books#patchAttributes
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Books#modelName
        * @propertyOf lbServices.Books
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Books`.
        */
        R.modelName = "Books";


            /**
             * @ngdoc method
             * @name lbServices.Books#Audiences
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation Audiences.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
        R.Audiences = function() {
          var TargetResource = $injector.get("Audiences");
          var action = TargetResource["::get::Books::Audiences"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books#Clients
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation Clients.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
        R.Clients = function() {
          var TargetResource = $injector.get("Clients");
          var action = TargetResource["::get::Books::Clients"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books#Distributors
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation Distributors.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
        R.Distributors = function() {
          var TargetResource = $injector.get("Distributors");
          var action = TargetResource["::get::Books::Distributors"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books#DocTypes
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation DocTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R.DocTypes = function() {
          var TargetResource = $injector.get("DocTypes");
          var action = TargetResource["::get::Books::DocTypes"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Books.Enclosures
     * @header lbServices.Books.Enclosures
     * @object
     * @description
     *
     * The object `Books.Enclosures` groups methods
     * manipulating `Enclosures` instances related to `Books`.
     *
     * Call {@link lbServices.Books#Enclosures Books.Enclosures()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Books#Enclosures
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Queries Enclosures of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R.Enclosures = function() {
          var TargetResource = $injector.get("Enclosures");
          var action = TargetResource["::get::Books::Enclosures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Enclosures#count
             * @methodOf lbServices.Books.Enclosures
             *
             * @description
             *
             * Counts Enclosures of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Enclosures.count = function() {
          var TargetResource = $injector.get("Enclosures");
          var action = TargetResource["::count::Books::Enclosures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Enclosures#create
             * @methodOf lbServices.Books.Enclosures
             *
             * @description
             *
             * Creates a new instance in Enclosures of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R.Enclosures.create = function() {
          var TargetResource = $injector.get("Enclosures");
          var action = TargetResource["::create::Books::Enclosures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Enclosures#createMany
             * @methodOf lbServices.Books.Enclosures
             *
             * @description
             *
             * Creates a new instance in Enclosures of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R.Enclosures.createMany = function() {
          var TargetResource = $injector.get("Enclosures");
          var action = TargetResource["::createMany::Books::Enclosures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Enclosures#destroyAll
             * @methodOf lbServices.Books.Enclosures
             *
             * @description
             *
             * Deletes all Enclosures of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Enclosures.destroyAll = function() {
          var TargetResource = $injector.get("Enclosures");
          var action = TargetResource["::delete::Books::Enclosures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Enclosures#destroyById
             * @methodOf lbServices.Books.Enclosures
             *
             * @description
             *
             * Delete a related item by id for Enclosures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for Enclosures
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Enclosures.destroyById = function() {
          var TargetResource = $injector.get("Enclosures");
          var action = TargetResource["::destroyById::Books::Enclosures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Enclosures#findById
             * @methodOf lbServices.Books.Enclosures
             *
             * @description
             *
             * Find a related item by id for Enclosures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for Enclosures
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R.Enclosures.findById = function() {
          var TargetResource = $injector.get("Enclosures");
          var action = TargetResource["::findById::Books::Enclosures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Enclosures#updateById
             * @methodOf lbServices.Books.Enclosures
             *
             * @description
             *
             * Update a related item by id for Enclosures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `fk` – `{*}` - Foreign key for Enclosures
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R.Enclosures.updateById = function() {
          var TargetResource = $injector.get("Enclosures");
          var action = TargetResource["::updateById::Books::Enclosures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books#Insurers
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation Insurers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
        R.Insurers = function() {
          var TargetResource = $injector.get("Insurers");
          var action = TargetResource["::get::Books::Insurers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Books.ProductIdsBookLink
     * @header lbServices.Books.ProductIdsBookLink
     * @object
     * @description
     *
     * The object `Books.ProductIdsBookLink` groups methods
     * manipulating `ProductIdsBookLink` instances related to `Books`.
     *
     * Call {@link lbServices.Books#ProductIdsBookLink Books.ProductIdsBookLink()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Books#ProductIdsBookLink
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Queries ProductIdsBookLink of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R.ProductIdsBookLink = function() {
          var TargetResource = $injector.get("ProductIdsBookLink");
          var action = TargetResource["::get::Books::ProductIdsBookLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.ProductIdsBookLink#count
             * @methodOf lbServices.Books.ProductIdsBookLink
             *
             * @description
             *
             * Counts ProductIdsBookLink of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.ProductIdsBookLink.count = function() {
          var TargetResource = $injector.get("ProductIdsBookLink");
          var action = TargetResource["::count::Books::ProductIdsBookLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.ProductIdsBookLink#create
             * @methodOf lbServices.Books.ProductIdsBookLink
             *
             * @description
             *
             * Creates a new instance in ProductIdsBookLink of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R.ProductIdsBookLink.create = function() {
          var TargetResource = $injector.get("ProductIdsBookLink");
          var action = TargetResource["::create::Books::ProductIdsBookLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.ProductIdsBookLink#createMany
             * @methodOf lbServices.Books.ProductIdsBookLink
             *
             * @description
             *
             * Creates a new instance in ProductIdsBookLink of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R.ProductIdsBookLink.createMany = function() {
          var TargetResource = $injector.get("ProductIdsBookLink");
          var action = TargetResource["::createMany::Books::ProductIdsBookLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.ProductIdsBookLink#destroyAll
             * @methodOf lbServices.Books.ProductIdsBookLink
             *
             * @description
             *
             * Deletes all ProductIdsBookLink of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.ProductIdsBookLink.destroyAll = function() {
          var TargetResource = $injector.get("ProductIdsBookLink");
          var action = TargetResource["::delete::Books::ProductIdsBookLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.ProductIdsBookLink#destroyById
             * @methodOf lbServices.Books.ProductIdsBookLink
             *
             * @description
             *
             * Delete a related item by id for ProductIdsBookLink.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for ProductIdsBookLink
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.ProductIdsBookLink.destroyById = function() {
          var TargetResource = $injector.get("ProductIdsBookLink");
          var action = TargetResource["::destroyById::Books::ProductIdsBookLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.ProductIdsBookLink#findById
             * @methodOf lbServices.Books.ProductIdsBookLink
             *
             * @description
             *
             * Find a related item by id for ProductIdsBookLink.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for ProductIdsBookLink
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R.ProductIdsBookLink.findById = function() {
          var TargetResource = $injector.get("ProductIdsBookLink");
          var action = TargetResource["::findById::Books::ProductIdsBookLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.ProductIdsBookLink#updateById
             * @methodOf lbServices.Books.ProductIdsBookLink
             *
             * @description
             *
             * Update a related item by id for ProductIdsBookLink.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `fk` – `{*}` - Foreign key for ProductIdsBookLink
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R.ProductIdsBookLink.updateById = function() {
          var TargetResource = $injector.get("ProductIdsBookLink");
          var action = TargetResource["::updateById::Books::ProductIdsBookLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books#Products
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation Products.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
        R.Products = function() {
          var TargetResource = $injector.get("Products");
          var action = TargetResource["::get::Books::Products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books#ProductTypes
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation ProductTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
        R.ProductTypes = function() {
          var TargetResource = $injector.get("ProductTypes");
          var action = TargetResource["::get::Books::ProductTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books#Projects
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation Projects.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
        R.Projects = function() {
          var TargetResource = $injector.get("Projects");
          var action = TargetResource["::get::Books::Projects"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books#Regions
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation Regions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
        R.Regions = function() {
          var TargetResource = $injector.get("Regions");
          var action = TargetResource["::get::Books::Regions"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Books.Children
     * @header lbServices.Books.Children
     * @object
     * @description
     *
     * The object `Books.Children` groups methods
     * manipulating `Structures` instances related to `Books`.
     *
     * Call {@link lbServices.Books#Children Books.Children()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Books#Children
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Queries Children of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Children = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::get::Books::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Children#count
             * @methodOf lbServices.Books.Children
             *
             * @description
             *
             * Counts Children of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Children.count = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::count::Books::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Children#create
             * @methodOf lbServices.Books.Children
             *
             * @description
             *
             * Creates a new instance in Children of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Children.create = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::create::Books::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Children#createMany
             * @methodOf lbServices.Books.Children
             *
             * @description
             *
             * Creates a new instance in Children of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Children.createMany = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::createMany::Books::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Children#destroyAll
             * @methodOf lbServices.Books.Children
             *
             * @description
             *
             * Deletes all Children of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Children.destroyAll = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::delete::Books::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Children#destroyById
             * @methodOf lbServices.Books.Children
             *
             * @description
             *
             * Delete a related item by id for Children.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for Children
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Children.destroyById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::destroyById::Books::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Children#findById
             * @methodOf lbServices.Books.Children
             *
             * @description
             *
             * Find a related item by id for Children.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for Children
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Children.findById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::findById::Books::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Children#updateById
             * @methodOf lbServices.Books.Children
             *
             * @description
             *
             * Update a related item by id for Children.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `fk` – `{*}` - Foreign key for Children
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Children.updateById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::updateById::Books::Children"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Books.Structures
     * @header lbServices.Books.Structures
     * @object
     * @description
     *
     * The object `Books.Structures` groups methods
     * manipulating `Structures` instances related to `Books`.
     *
     * Call {@link lbServices.Books#Structures Books.Structures()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Books#Structures
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Queries Structures of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::get::Books::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Structures#count
             * @methodOf lbServices.Books.Structures
             *
             * @description
             *
             * Counts Structures of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Structures.count = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::count::Books::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Structures#create
             * @methodOf lbServices.Books.Structures
             *
             * @description
             *
             * Creates a new instance in Structures of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures.create = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::create::Books::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Structures#createMany
             * @methodOf lbServices.Books.Structures
             *
             * @description
             *
             * Creates a new instance in Structures of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures.createMany = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::createMany::Books::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Structures#destroyAll
             * @methodOf lbServices.Books.Structures
             *
             * @description
             *
             * Deletes all Structures of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Structures.destroyAll = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::delete::Books::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Structures#destroyById
             * @methodOf lbServices.Books.Structures
             *
             * @description
             *
             * Delete a related item by id for Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for Structures
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Structures.destroyById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::destroyById::Books::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Structures#findById
             * @methodOf lbServices.Books.Structures
             *
             * @description
             *
             * Find a related item by id for Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for Structures
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures.findById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::findById::Books::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Structures#updateById
             * @methodOf lbServices.Books.Structures
             *
             * @description
             *
             * Update a related item by id for Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `fk` – `{*}` - Foreign key for Structures
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures.updateById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::updateById::Books::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books#SubInsurers
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation SubInsurers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
        R.SubInsurers = function() {
          var TargetResource = $injector.get("SubInsurers");
          var action = TargetResource["::get::Books::SubInsurers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books#Users
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::get::Books::Users"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.BooksVersions
 * @header lbServices.BooksVersions
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `BooksVersions` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "BooksVersions",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/BooksVersions/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#create
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/BooksVersions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#createMany
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/BooksVersions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#upsert
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/BooksVersions",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#replaceOrCreate
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/BooksVersions/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#upsertWithWhere
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/BooksVersions/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#exists
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/BooksVersions/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#findById
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/BooksVersions/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#replaceById
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/BooksVersions/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#find
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/BooksVersions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#findOne
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/BooksVersions/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#updateAll
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/BooksVersions/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#deleteById
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/BooksVersions/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#count
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/BooksVersions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#prototype$updateAttributes
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BooksVersions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/BooksVersions/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#createChangeStream
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/BooksVersions/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#patchOrCreate
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#updateOrCreate
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#patchOrCreateWithWhere
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#update
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#destroyById
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#removeById
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#patchAttributes
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BooksVersions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.BooksVersions#modelName
        * @propertyOf lbServices.BooksVersions
        * @description
        * The name of the model represented by this $resource,
        * i.e. `BooksVersions`.
        */
        R.modelName = "BooksVersions";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Clients
 * @header lbServices.Clients
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Clients` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Clients",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Clients/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Clients.Teams() instead.
            "prototype$__get__Teams": {
              url: urlBase + "/Clients/:id/Teams",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#__get__notDeleted
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Queries notDeleted of Clients.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Clients/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#__create__notDeleted
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Clients/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#__delete__notDeleted
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Clients/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#__count__notDeleted
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Counts notDeleted of Clients.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Clients/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#create
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Clients",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#createMany
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Clients",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#upsert
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Clients",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#replaceOrCreate
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Clients/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#upsertWithWhere
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Clients/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#exists
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Clients/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#findById
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Clients/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#replaceById
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Clients/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#find
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Clients",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#findOne
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Clients/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#updateAll
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Clients/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#deleteById
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Clients/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#count
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Clients/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#prototype$updateAttributes
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Clients id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Clients/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#createChangeStream
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Clients/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Books.Clients() instead.
            "::get::Books::Clients": {
              url: urlBase + "/Books/:id/Clients",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Clients#patchOrCreate
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Clients#updateOrCreate
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Clients#patchOrCreateWithWhere
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Clients#update
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Clients#destroyById
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Clients#removeById
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Clients#patchAttributes
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Clients id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Clients#modelName
        * @propertyOf lbServices.Clients
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Clients`.
        */
        R.modelName = "Clients";


            /**
             * @ngdoc method
             * @name lbServices.Clients#Teams
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Fetches belongsTo relation Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Clients id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R.Teams = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::get::Clients::Teams"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Color
 * @header lbServices.Color
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Color` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Color",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Colors/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Color#create
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Colors",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#createMany
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Colors",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#upsert
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Colors",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#replaceOrCreate
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Colors/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#upsertWithWhere
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Colors/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#exists
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Colors/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#findById
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Colors/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#replaceById
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Colors/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#find
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Colors",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#findOne
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Colors/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#updateAll
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Colors/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#deleteById
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Colors/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#count
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Colors/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#prototype$updateAttributes
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Color id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Colors/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#createChangeStream
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Colors/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#upvote
             * @methodOf lbServices.Color
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{number=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "upvote": {
              url: urlBase + "/Colors/upvote",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Color#patchOrCreate
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Color#updateOrCreate
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Color#patchOrCreateWithWhere
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Color#update
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Color#destroyById
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Color#removeById
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Color#patchAttributes
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Color id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Color#modelName
        * @propertyOf lbServices.Color
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Color`.
        */
        R.modelName = "Color";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Containers
 * @header lbServices.Containers
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Containers` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Containers",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Containers/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Containers#getContainers
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Containers` object.)
             * </em>
             */
            "getContainers": {
              isArray: true,
              url: urlBase + "/Containers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#createContainer
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Containers` object.)
             * </em>
             */
            "createContainer": {
              url: urlBase + "/Containers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#destroyContainer
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "destroyContainer": {
              url: urlBase + "/Containers/:container",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#getContainer
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Containers` object.)
             * </em>
             */
            "getContainer": {
              url: urlBase + "/Containers/:container",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#getFiles
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Containers` object.)
             * </em>
             */
            "getFiles": {
              isArray: true,
              url: urlBase + "/Containers/:container/files",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#getFile
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` -
             *
             *  - `file` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Containers` object.)
             * </em>
             */
            "getFile": {
              url: urlBase + "/Containers/:container/files/:file",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#removeFile
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` -
             *
             *  - `file` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "removeFile": {
              url: urlBase + "/Containers/:container/files/:file",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#upload
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `result` – `{object=}` -
             */
            "upload": {
              url: urlBase + "/Containers/:container/upload",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#download
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` -
             *
             *  - `file` – `{string=}` -
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "download": {
              url: urlBase + "/Containers/:container/download/:file",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#test1
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * test1
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Containers` object.)
             * </em>
             */
            "test1": {
              isArray: true,
              url: urlBase + "/Containers/test1",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#prepareFinalPDF
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * Upload Doc to S3
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Containers` object.)
             * </em>
             */
            "prepareFinalPDF": {
              isArray: true,
              url: urlBase + "/Containers/prepareFinalPDF",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#uploadFinalPDF
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * Upload Doc to S3
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Containers` object.)
             * </em>
             */
            "uploadFinalPDF": {
              isArray: true,
              url: urlBase + "/Containers/uploadFinalPDF",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#downloadTest
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * Download Doc from S3
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `args` – `{object}` - args
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Containers` object.)
             * </em>
             */
            "downloadTest": {
              isArray: true,
              url: urlBase + "/Containers/downloadTest",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#getfile
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `file1` – `{string}` -
             *
             *  - `file2` – `{string}` -
             *
             *  - `file3` – `{string}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "getfile": {
              url: urlBase + "/Containers/getfile/:file1/:file2/:file3",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#getFulfilmentFile
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `args` – `{object=}` - args
             *
             *  - `file1` – `{string}` -
             *
             *  - `file2` – `{string}` -
             *
             *  - `file3` – `{string}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "getFulfilmentFile": {
              url: urlBase + "/Containers/getFulfilmentFile/:file1/:file2/:file3",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#getthumbnail
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `file1` – `{string}` -
             *
             *  - `file2` – `{string}` -
             *
             *  - `file3` – `{string}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "getthumbnail": {
              url: urlBase + "/Containers/getthumbnail/:file1/:file2/:file3",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#dlrq
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Containers` object.)
             * </em>
             */
            "dlrq": {
              isArray: true,
              url: urlBase + "/Containers/dlrq/",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#getAuthFile
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `file` – `{string}` -
             *
             *  - `token` – `{string}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "getAuthFile": {
              url: urlBase + "/Containers/getAuthFile/:file",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.Containers#modelName
        * @propertyOf lbServices.Containers
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Containers`.
        */
        R.modelName = "Containers";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Contents
 * @header lbServices.Contents
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Contents` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Contents",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Contents/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Contents.ContentTypes() instead.
            "prototype$__get__ContentTypes": {
              url: urlBase + "/Contents/:id/ContentTypes",
              method: "GET",
            },

            // INTERNAL. Use Contents.Teams() instead.
            "prototype$__get__Teams": {
              url: urlBase + "/Contents/:id/Teams",
              method: "GET",
            },

            // INTERNAL. Use Contents.Versions.findById() instead.
            "prototype$__findById__Versions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Contents/:id/Versions/:fk",
              method: "GET",
            },

            // INTERNAL. Use Contents.Versions.destroyById() instead.
            "prototype$__destroyById__Versions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Contents/:id/Versions/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Contents.Versions.updateById() instead.
            "prototype$__updateById__Versions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Contents/:id/Versions/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#__get__ContentType
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Queries ContentType of Contents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "__get__ContentType": {
              isArray: true,
              url: urlBase + "/Contents/ContentType",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#__create__ContentType
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Creates a new instance in ContentType of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "__create__ContentType": {
              url: urlBase + "/Contents/ContentType",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#__delete__ContentType
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Deletes all ContentType of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__ContentType": {
              url: urlBase + "/Contents/ContentType",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#__count__ContentType
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Counts ContentType of Contents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__ContentType": {
              url: urlBase + "/Contents/ContentType/count",
              method: "GET",
            },

            // INTERNAL. Use Contents.Versions() instead.
            "prototype$__get__Versions": {
              isArray: true,
              url: urlBase + "/Contents/:id/Versions",
              method: "GET",
            },

            // INTERNAL. Use Contents.Versions.create() instead.
            "prototype$__create__Versions": {
              url: urlBase + "/Contents/:id/Versions",
              method: "POST",
            },

            // INTERNAL. Use Contents.Versions.destroyAll() instead.
            "prototype$__delete__Versions": {
              url: urlBase + "/Contents/:id/Versions",
              method: "DELETE",
            },

            // INTERNAL. Use Contents.Versions.count() instead.
            "prototype$__count__Versions": {
              url: urlBase + "/Contents/:id/Versions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#create
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Contents",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#createMany
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Contents",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#upsert
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Contents",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#replaceOrCreate
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Contents/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#upsertWithWhere
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Contents/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#exists
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Contents/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#findById
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Contents/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#replaceById
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Contents/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#find
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Contents",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#findOne
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Contents/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#updateAll
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Contents/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#deleteById
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Contents/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#count
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Contents/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#prototype$updateAttributes
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Contents/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#createChangeStream
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Contents/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Events.Contents() instead.
            "::get::Events::Contents": {
              url: urlBase + "/Events/:id/Contents",
              method: "GET",
            },

            // INTERNAL. Use Structures.Contents() instead.
            "::get::Structures::Contents": {
              url: urlBase + "/Structures/:id/Contents",
              method: "GET",
            },

            // INTERNAL. Use Teams.Contents.findById() instead.
            "::findById::Teams::Contents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Contents/:fk",
              method: "GET",
            },

            // INTERNAL. Use Teams.Contents.destroyById() instead.
            "::destroyById::Teams::Contents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Contents/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Teams.Contents.updateById() instead.
            "::updateById::Teams::Contents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Contents/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Teams.Contents() instead.
            "::get::Teams::Contents": {
              isArray: true,
              url: urlBase + "/Teams/:id/Contents",
              method: "GET",
            },

            // INTERNAL. Use Teams.Contents.create() instead.
            "::create::Teams::Contents": {
              url: urlBase + "/Teams/:id/Contents",
              method: "POST",
            },

            // INTERNAL. Use Teams.Contents.createMany() instead.
            "::createMany::Teams::Contents": {
              isArray: true,
              url: urlBase + "/Teams/:id/Contents",
              method: "POST",
            },

            // INTERNAL. Use Teams.Contents.destroyAll() instead.
            "::delete::Teams::Contents": {
              url: urlBase + "/Teams/:id/Contents",
              method: "DELETE",
            },

            // INTERNAL. Use Teams.Contents.count() instead.
            "::count::Teams::Contents": {
              url: urlBase + "/Teams/:id/Contents/count",
              method: "GET",
            },

            // INTERNAL. Use Versions.Contents() instead.
            "::get::Versions::Contents": {
              url: urlBase + "/Versions/:id/Contents",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Contents#patchOrCreate
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Contents#updateOrCreate
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Contents#patchOrCreateWithWhere
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Contents#update
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Contents#destroyById
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Contents#removeById
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Contents#patchAttributes
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Contents#modelName
        * @propertyOf lbServices.Contents
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Contents`.
        */
        R.modelName = "Contents";


            /**
             * @ngdoc method
             * @name lbServices.Contents#ContentTypes
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Fetches belongsTo relation ContentTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
        R.ContentTypes = function() {
          var TargetResource = $injector.get("ContentTypes");
          var action = TargetResource["::get::Contents::ContentTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Contents#Teams
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Fetches belongsTo relation Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R.Teams = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::get::Contents::Teams"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Contents.Versions
     * @header lbServices.Contents.Versions
     * @object
     * @description
     *
     * The object `Contents.Versions` groups methods
     * manipulating `Versions` instances related to `Contents`.
     *
     * Call {@link lbServices.Contents#Versions Contents.Versions()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Contents#Versions
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Queries Versions of Contents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R.Versions = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::get::Contents::Versions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Contents.Versions#count
             * @methodOf lbServices.Contents.Versions
             *
             * @description
             *
             * Counts Versions of Contents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Versions.count = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::count::Contents::Versions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Contents.Versions#create
             * @methodOf lbServices.Contents.Versions
             *
             * @description
             *
             * Creates a new instance in Versions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R.Versions.create = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::create::Contents::Versions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Contents.Versions#createMany
             * @methodOf lbServices.Contents.Versions
             *
             * @description
             *
             * Creates a new instance in Versions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R.Versions.createMany = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::createMany::Contents::Versions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Contents.Versions#destroyAll
             * @methodOf lbServices.Contents.Versions
             *
             * @description
             *
             * Deletes all Versions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Versions.destroyAll = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::delete::Contents::Versions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Contents.Versions#destroyById
             * @methodOf lbServices.Contents.Versions
             *
             * @description
             *
             * Delete a related item by id for Versions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             *  - `fk` – `{*}` - Foreign key for Versions
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Versions.destroyById = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::destroyById::Contents::Versions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Contents.Versions#findById
             * @methodOf lbServices.Contents.Versions
             *
             * @description
             *
             * Find a related item by id for Versions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             *  - `fk` – `{*}` - Foreign key for Versions
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R.Versions.findById = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::findById::Contents::Versions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Contents.Versions#updateById
             * @methodOf lbServices.Contents.Versions
             *
             * @description
             *
             * Update a related item by id for Versions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             *  - `fk` – `{*}` - Foreign key for Versions
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R.Versions.updateById = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::updateById::Contents::Versions"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ContentTypes
 * @header lbServices.ContentTypes
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ContentTypes` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ContentTypes",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ContentTypes/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#create
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ContentTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#createMany
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ContentTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#upsert
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/ContentTypes",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#replaceOrCreate
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ContentTypes/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#upsertWithWhere
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ContentTypes/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#exists
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ContentTypes/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#findById
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ContentTypes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#replaceById
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ContentTypes/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#find
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ContentTypes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#findOne
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ContentTypes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#updateAll
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ContentTypes/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#deleteById
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ContentTypes/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#count
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ContentTypes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#prototype$updateAttributes
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ContentTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/ContentTypes/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#createChangeStream
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ContentTypes/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Contents.ContentTypes() instead.
            "::get::Contents::ContentTypes": {
              url: urlBase + "/Contents/:id/ContentTypes",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#patchOrCreate
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#updateOrCreate
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#patchOrCreateWithWhere
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#update
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#destroyById
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#removeById
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#patchAttributes
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ContentTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ContentTypes#modelName
        * @propertyOf lbServices.ContentTypes
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ContentTypes`.
        */
        R.modelName = "ContentTypes";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Departments
 * @header lbServices.Departments
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Departments` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Departments",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Departments/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Departments.Teams.findById() instead.
            "prototype$__findById__Teams": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Teams/:fk",
              method: "GET",
            },

            // INTERNAL. Use Departments.Teams.destroyById() instead.
            "prototype$__destroyById__Teams": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Teams/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Departments.Teams.updateById() instead.
            "prototype$__updateById__Teams": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Teams/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Departments.Users.findById() instead.
            "prototype$__findById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Departments.Users.destroyById() instead.
            "prototype$__destroyById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Departments.Users.updateById() instead.
            "prototype$__updateById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Users/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#__get__notDeleted
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Queries notDeleted of Departments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Departments/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#__create__notDeleted
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Departments/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#__delete__notDeleted
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Departments/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#__count__notDeleted
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Counts notDeleted of Departments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Departments/notDeleted/count",
              method: "GET",
            },

            // INTERNAL. Use Departments.Teams() instead.
            "prototype$__get__Teams": {
              isArray: true,
              url: urlBase + "/Departments/:id/Teams",
              method: "GET",
            },

            // INTERNAL. Use Departments.Teams.create() instead.
            "prototype$__create__Teams": {
              url: urlBase + "/Departments/:id/Teams",
              method: "POST",
            },

            // INTERNAL. Use Departments.Teams.destroyAll() instead.
            "prototype$__delete__Teams": {
              url: urlBase + "/Departments/:id/Teams",
              method: "DELETE",
            },

            // INTERNAL. Use Departments.Teams.count() instead.
            "prototype$__count__Teams": {
              url: urlBase + "/Departments/:id/Teams/count",
              method: "GET",
            },

            // INTERNAL. Use Departments.Users() instead.
            "prototype$__get__Users": {
              isArray: true,
              url: urlBase + "/Departments/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Departments.Users.create() instead.
            "prototype$__create__Users": {
              url: urlBase + "/Departments/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Departments.Users.destroyAll() instead.
            "prototype$__delete__Users": {
              url: urlBase + "/Departments/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use Departments.Users.count() instead.
            "prototype$__count__Users": {
              url: urlBase + "/Departments/:id/Users/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#create
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Departments",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#createMany
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Departments",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#upsert
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Departments",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#replaceOrCreate
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Departments/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#upsertWithWhere
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Departments/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#exists
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Departments/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#findById
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Departments/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#replaceById
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Departments/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#find
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Departments",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#findOne
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Departments/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#updateAll
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Departments/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#deleteById
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Departments/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#count
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Departments/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#prototype$updateAttributes
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Departments/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#createChangeStream
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Departments/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Teams.Departments() instead.
            "::get::Teams::Departments": {
              url: urlBase + "/Teams/:id/Departments",
              method: "GET",
            },

            // INTERNAL. Use Users.Departments() instead.
            "::get::Users::Departments": {
              url: urlBase + "/Users/:id/Departments",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Departments#patchOrCreate
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Departments#updateOrCreate
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Departments#patchOrCreateWithWhere
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Departments#update
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Departments#destroyById
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Departments#removeById
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Departments#patchAttributes
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Departments#modelName
        * @propertyOf lbServices.Departments
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Departments`.
        */
        R.modelName = "Departments";

    /**
     * @ngdoc object
     * @name lbServices.Departments.Teams
     * @header lbServices.Departments.Teams
     * @object
     * @description
     *
     * The object `Departments.Teams` groups methods
     * manipulating `Teams` instances related to `Departments`.
     *
     * Call {@link lbServices.Departments#Teams Departments.Teams()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Departments#Teams
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Queries Teams of Departments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R.Teams = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::get::Departments::Teams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Teams#count
             * @methodOf lbServices.Departments.Teams
             *
             * @description
             *
             * Counts Teams of Departments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Teams.count = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::count::Departments::Teams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Teams#create
             * @methodOf lbServices.Departments.Teams
             *
             * @description
             *
             * Creates a new instance in Teams of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R.Teams.create = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::create::Departments::Teams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Teams#createMany
             * @methodOf lbServices.Departments.Teams
             *
             * @description
             *
             * Creates a new instance in Teams of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R.Teams.createMany = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::createMany::Departments::Teams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Teams#destroyAll
             * @methodOf lbServices.Departments.Teams
             *
             * @description
             *
             * Deletes all Teams of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Teams.destroyAll = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::delete::Departments::Teams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Teams#destroyById
             * @methodOf lbServices.Departments.Teams
             *
             * @description
             *
             * Delete a related item by id for Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `fk` – `{*}` - Foreign key for Teams
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Teams.destroyById = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::destroyById::Departments::Teams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Teams#findById
             * @methodOf lbServices.Departments.Teams
             *
             * @description
             *
             * Find a related item by id for Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `fk` – `{*}` - Foreign key for Teams
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R.Teams.findById = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::findById::Departments::Teams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Teams#updateById
             * @methodOf lbServices.Departments.Teams
             *
             * @description
             *
             * Update a related item by id for Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `fk` – `{*}` - Foreign key for Teams
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R.Teams.updateById = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::updateById::Departments::Teams"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Departments.Users
     * @header lbServices.Departments.Users
     * @object
     * @description
     *
     * The object `Departments.Users` groups methods
     * manipulating `Users` instances related to `Departments`.
     *
     * Call {@link lbServices.Departments#Users Departments.Users()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Departments#Users
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Queries Users of Departments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::get::Departments::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Users#count
             * @methodOf lbServices.Departments.Users
             *
             * @description
             *
             * Counts Users of Departments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Users.count = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::count::Departments::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Users#create
             * @methodOf lbServices.Departments.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.create = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::create::Departments::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Users#createMany
             * @methodOf lbServices.Departments.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.createMany = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::createMany::Departments::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Users#destroyAll
             * @methodOf lbServices.Departments.Users
             *
             * @description
             *
             * Deletes all Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyAll = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::delete::Departments::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Users#destroyById
             * @methodOf lbServices.Departments.Users
             *
             * @description
             *
             * Delete a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::destroyById::Departments::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Users#findById
             * @methodOf lbServices.Departments.Users
             *
             * @description
             *
             * Find a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.findById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::findById::Departments::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Users#updateById
             * @methodOf lbServices.Departments.Users
             *
             * @description
             *
             * Update a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.updateById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::updateById::Departments::Users"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Distributors
 * @header lbServices.Distributors
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Distributors` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Distributors",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Distributors/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Distributors#__get__notDeleted
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Queries notDeleted of Distributors.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Distributors/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#__create__notDeleted
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Distributors/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#__delete__notDeleted
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Distributors/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#__count__notDeleted
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Counts notDeleted of Distributors.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Distributors/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#create
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Distributors",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#createMany
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Distributors",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#upsert
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Distributors",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#replaceOrCreate
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Distributors/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#upsertWithWhere
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Distributors/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#exists
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Distributors/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#findById
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Distributors/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#replaceById
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Distributors/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#find
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Distributors",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#findOne
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Distributors/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#updateAll
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Distributors/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#deleteById
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Distributors/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#count
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Distributors/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#prototype$updateAttributes
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Distributors id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Distributors/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#createChangeStream
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Distributors/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Books.Distributors() instead.
            "::get::Books::Distributors": {
              url: urlBase + "/Books/:id/Distributors",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Distributors#patchOrCreate
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Distributors#updateOrCreate
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Distributors#patchOrCreateWithWhere
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Distributors#update
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Distributors#destroyById
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Distributors#removeById
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Distributors#patchAttributes
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Distributors id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Distributors#modelName
        * @propertyOf lbServices.Distributors
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Distributors`.
        */
        R.modelName = "Distributors";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.DivisionMetaLink
 * @header lbServices.DivisionMetaLink
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `DivisionMetaLink` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "DivisionMetaLink",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/DivisionMetaLinks/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use DivisionMetaLink.Divisions() instead.
            "prototype$__get__Divisions": {
              url: urlBase + "/DivisionMetaLinks/:id/Divisions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#create
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/DivisionMetaLinks",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#createMany
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/DivisionMetaLinks",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#upsert
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/DivisionMetaLinks",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#replaceOrCreate
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/DivisionMetaLinks/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#upsertWithWhere
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/DivisionMetaLinks/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#exists
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/DivisionMetaLinks/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#findById
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/DivisionMetaLinks/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#replaceById
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/DivisionMetaLinks/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#find
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/DivisionMetaLinks",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#findOne
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/DivisionMetaLinks/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#updateAll
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/DivisionMetaLinks/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#deleteById
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/DivisionMetaLinks/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#count
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/DivisionMetaLinks/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#prototype$updateAttributes
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DivisionMetaLink id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/DivisionMetaLinks/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#createChangeStream
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/DivisionMetaLinks/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#getMetaTableData
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Get Division Meta Data. Division can ether be an int or comma separated string i.e 1,5,4,3. Then pass in the table name
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "getMetaTableData": {
              url: urlBase + "/DivisionMetaLinks/getMetaTableData",
              method: "POST",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.findById() instead.
            "::findById::Divisions::DivisionMetaLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/DivisionMetaLink/:fk",
              method: "GET",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.destroyById() instead.
            "::destroyById::Divisions::DivisionMetaLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/DivisionMetaLink/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.updateById() instead.
            "::updateById::Divisions::DivisionMetaLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/DivisionMetaLink/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink() instead.
            "::get::Divisions::DivisionMetaLink": {
              isArray: true,
              url: urlBase + "/Divisions/:id/DivisionMetaLink",
              method: "GET",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.create() instead.
            "::create::Divisions::DivisionMetaLink": {
              url: urlBase + "/Divisions/:id/DivisionMetaLink",
              method: "POST",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.createMany() instead.
            "::createMany::Divisions::DivisionMetaLink": {
              isArray: true,
              url: urlBase + "/Divisions/:id/DivisionMetaLink",
              method: "POST",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.destroyAll() instead.
            "::delete::Divisions::DivisionMetaLink": {
              url: urlBase + "/Divisions/:id/DivisionMetaLink",
              method: "DELETE",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.count() instead.
            "::count::Divisions::DivisionMetaLink": {
              url: urlBase + "/Divisions/:id/DivisionMetaLink/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#patchOrCreate
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#updateOrCreate
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#patchOrCreateWithWhere
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#update
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#destroyById
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#removeById
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#patchAttributes
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DivisionMetaLink id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.DivisionMetaLink#modelName
        * @propertyOf lbServices.DivisionMetaLink
        * @description
        * The name of the model represented by this $resource,
        * i.e. `DivisionMetaLink`.
        */
        R.modelName = "DivisionMetaLink";


            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#Divisions
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Fetches belongsTo relation Divisions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DivisionMetaLink id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
        R.Divisions = function() {
          var TargetResource = $injector.get("Divisions");
          var action = TargetResource["::get::DivisionMetaLink::Divisions"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Divisions
 * @header lbServices.Divisions
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Divisions` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Divisions",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Divisions/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Divisions.DivisionMetaLink.findById() instead.
            "prototype$__findById__DivisionMetaLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/DivisionMetaLink/:fk",
              method: "GET",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.destroyById() instead.
            "prototype$__destroyById__DivisionMetaLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/DivisionMetaLink/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.updateById() instead.
            "prototype$__updateById__DivisionMetaLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/DivisionMetaLink/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Divisions.Users.findById() instead.
            "prototype$__findById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Divisions.Users.destroyById() instead.
            "prototype$__destroyById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Divisions.Users.updateById() instead.
            "prototype$__updateById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/Users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink() instead.
            "prototype$__get__DivisionMetaLink": {
              isArray: true,
              url: urlBase + "/Divisions/:id/DivisionMetaLink",
              method: "GET",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.create() instead.
            "prototype$__create__DivisionMetaLink": {
              url: urlBase + "/Divisions/:id/DivisionMetaLink",
              method: "POST",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.destroyAll() instead.
            "prototype$__delete__DivisionMetaLink": {
              url: urlBase + "/Divisions/:id/DivisionMetaLink",
              method: "DELETE",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.count() instead.
            "prototype$__count__DivisionMetaLink": {
              url: urlBase + "/Divisions/:id/DivisionMetaLink/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#__get__notDeleted
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Queries notDeleted of Divisions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Divisions/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#__create__notDeleted
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Divisions/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#__delete__notDeleted
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Divisions/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#__count__notDeleted
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Counts notDeleted of Divisions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Divisions/notDeleted/count",
              method: "GET",
            },

            // INTERNAL. Use Divisions.Users() instead.
            "prototype$__get__Users": {
              isArray: true,
              url: urlBase + "/Divisions/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Divisions.Users.create() instead.
            "prototype$__create__Users": {
              url: urlBase + "/Divisions/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Divisions.Users.destroyAll() instead.
            "prototype$__delete__Users": {
              url: urlBase + "/Divisions/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use Divisions.Users.count() instead.
            "prototype$__count__Users": {
              url: urlBase + "/Divisions/:id/Users/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#create
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Divisions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#createMany
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Divisions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#upsert
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Divisions",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#replaceOrCreate
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Divisions/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#upsertWithWhere
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Divisions/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#exists
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Divisions/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#findById
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Divisions/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#replaceById
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Divisions/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#find
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Divisions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#findOne
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Divisions/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#updateAll
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Divisions/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#deleteById
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Divisions/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#count
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Divisions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#prototype$updateAttributes
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Divisions/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#createChangeStream
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Divisions/change-stream",
              method: "POST",
            },

            // INTERNAL. Use DivisionMetaLink.Divisions() instead.
            "::get::DivisionMetaLink::Divisions": {
              url: urlBase + "/DivisionMetaLinks/:id/Divisions",
              method: "GET",
            },

            // INTERNAL. Use Users.Divisions() instead.
            "::get::Users::Divisions": {
              url: urlBase + "/Users/:id/Divisions",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Divisions#patchOrCreate
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Divisions#updateOrCreate
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Divisions#patchOrCreateWithWhere
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Divisions#update
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Divisions#destroyById
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Divisions#removeById
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Divisions#patchAttributes
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Divisions#modelName
        * @propertyOf lbServices.Divisions
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Divisions`.
        */
        R.modelName = "Divisions";

    /**
     * @ngdoc object
     * @name lbServices.Divisions.DivisionMetaLink
     * @header lbServices.Divisions.DivisionMetaLink
     * @object
     * @description
     *
     * The object `Divisions.DivisionMetaLink` groups methods
     * manipulating `DivisionMetaLink` instances related to `Divisions`.
     *
     * Call {@link lbServices.Divisions#DivisionMetaLink Divisions.DivisionMetaLink()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Divisions#DivisionMetaLink
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Queries DivisionMetaLink of Divisions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R.DivisionMetaLink = function() {
          var TargetResource = $injector.get("DivisionMetaLink");
          var action = TargetResource["::get::Divisions::DivisionMetaLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.DivisionMetaLink#count
             * @methodOf lbServices.Divisions.DivisionMetaLink
             *
             * @description
             *
             * Counts DivisionMetaLink of Divisions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.DivisionMetaLink.count = function() {
          var TargetResource = $injector.get("DivisionMetaLink");
          var action = TargetResource["::count::Divisions::DivisionMetaLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.DivisionMetaLink#create
             * @methodOf lbServices.Divisions.DivisionMetaLink
             *
             * @description
             *
             * Creates a new instance in DivisionMetaLink of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R.DivisionMetaLink.create = function() {
          var TargetResource = $injector.get("DivisionMetaLink");
          var action = TargetResource["::create::Divisions::DivisionMetaLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.DivisionMetaLink#createMany
             * @methodOf lbServices.Divisions.DivisionMetaLink
             *
             * @description
             *
             * Creates a new instance in DivisionMetaLink of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R.DivisionMetaLink.createMany = function() {
          var TargetResource = $injector.get("DivisionMetaLink");
          var action = TargetResource["::createMany::Divisions::DivisionMetaLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.DivisionMetaLink#destroyAll
             * @methodOf lbServices.Divisions.DivisionMetaLink
             *
             * @description
             *
             * Deletes all DivisionMetaLink of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.DivisionMetaLink.destroyAll = function() {
          var TargetResource = $injector.get("DivisionMetaLink");
          var action = TargetResource["::delete::Divisions::DivisionMetaLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.DivisionMetaLink#destroyById
             * @methodOf lbServices.Divisions.DivisionMetaLink
             *
             * @description
             *
             * Delete a related item by id for DivisionMetaLink.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `fk` – `{*}` - Foreign key for DivisionMetaLink
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.DivisionMetaLink.destroyById = function() {
          var TargetResource = $injector.get("DivisionMetaLink");
          var action = TargetResource["::destroyById::Divisions::DivisionMetaLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.DivisionMetaLink#findById
             * @methodOf lbServices.Divisions.DivisionMetaLink
             *
             * @description
             *
             * Find a related item by id for DivisionMetaLink.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `fk` – `{*}` - Foreign key for DivisionMetaLink
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R.DivisionMetaLink.findById = function() {
          var TargetResource = $injector.get("DivisionMetaLink");
          var action = TargetResource["::findById::Divisions::DivisionMetaLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.DivisionMetaLink#updateById
             * @methodOf lbServices.Divisions.DivisionMetaLink
             *
             * @description
             *
             * Update a related item by id for DivisionMetaLink.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `fk` – `{*}` - Foreign key for DivisionMetaLink
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R.DivisionMetaLink.updateById = function() {
          var TargetResource = $injector.get("DivisionMetaLink");
          var action = TargetResource["::updateById::Divisions::DivisionMetaLink"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Divisions.Users
     * @header lbServices.Divisions.Users
     * @object
     * @description
     *
     * The object `Divisions.Users` groups methods
     * manipulating `Users` instances related to `Divisions`.
     *
     * Call {@link lbServices.Divisions#Users Divisions.Users()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Divisions#Users
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Queries Users of Divisions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::get::Divisions::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.Users#count
             * @methodOf lbServices.Divisions.Users
             *
             * @description
             *
             * Counts Users of Divisions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Users.count = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::count::Divisions::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.Users#create
             * @methodOf lbServices.Divisions.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.create = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::create::Divisions::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.Users#createMany
             * @methodOf lbServices.Divisions.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.createMany = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::createMany::Divisions::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.Users#destroyAll
             * @methodOf lbServices.Divisions.Users
             *
             * @description
             *
             * Deletes all Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyAll = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::delete::Divisions::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.Users#destroyById
             * @methodOf lbServices.Divisions.Users
             *
             * @description
             *
             * Delete a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::destroyById::Divisions::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.Users#findById
             * @methodOf lbServices.Divisions.Users
             *
             * @description
             *
             * Find a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.findById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::findById::Divisions::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.Users#updateById
             * @methodOf lbServices.Divisions.Users
             *
             * @description
             *
             * Update a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.updateById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::updateById::Divisions::Users"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.DocContents
 * @header lbServices.DocContents
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `DocContents` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "DocContents",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/DocContents/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.DocContents#__get__notDeleted
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Queries notDeleted of DocContents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/DocContents/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#__create__notDeleted
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/DocContents/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#__delete__notDeleted
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/DocContents/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#__count__notDeleted
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Counts notDeleted of DocContents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/DocContents/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#create
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/DocContents",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#createMany
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/DocContents",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#upsert
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/DocContents",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#replaceOrCreate
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/DocContents/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#upsertWithWhere
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/DocContents/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#exists
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/DocContents/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#findById
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/DocContents/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#replaceById
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/DocContents/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#find
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/DocContents",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#findOne
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/DocContents/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#updateAll
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/DocContents/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#deleteById
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/DocContents/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#count
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/DocContents/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#prototype$updateAttributes
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocContents id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/DocContents/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#createChangeStream
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/DocContents/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.DocContents#patchOrCreate
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.DocContents#updateOrCreate
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.DocContents#patchOrCreateWithWhere
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.DocContents#update
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.DocContents#destroyById
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.DocContents#removeById
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.DocContents#patchAttributes
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocContents id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.DocContents#modelName
        * @propertyOf lbServices.DocContents
        * @description
        * The name of the model represented by this $resource,
        * i.e. `DocContents`.
        */
        R.modelName = "DocContents";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.DocTypeCats
 * @header lbServices.DocTypeCats
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `DocTypeCats` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "DocTypeCats",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/DocTypeCats/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use DocTypeCats.DocTypes.findById() instead.
            "prototype$__findById__DocTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypeCats/:id/DocTypes/:fk",
              method: "GET",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.destroyById() instead.
            "prototype$__destroyById__DocTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypeCats/:id/DocTypes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.updateById() instead.
            "prototype$__updateById__DocTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypeCats/:id/DocTypes/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#__get__notDeleted
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Queries notDeleted of DocTypeCats.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/DocTypeCats/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#__create__notDeleted
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/DocTypeCats/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#__delete__notDeleted
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/DocTypeCats/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#__count__notDeleted
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Counts notDeleted of DocTypeCats.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/DocTypeCats/notDeleted/count",
              method: "GET",
            },

            // INTERNAL. Use DocTypeCats.DocTypes() instead.
            "prototype$__get__DocTypes": {
              isArray: true,
              url: urlBase + "/DocTypeCats/:id/DocTypes",
              method: "GET",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.create() instead.
            "prototype$__create__DocTypes": {
              url: urlBase + "/DocTypeCats/:id/DocTypes",
              method: "POST",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.destroyAll() instead.
            "prototype$__delete__DocTypes": {
              url: urlBase + "/DocTypeCats/:id/DocTypes",
              method: "DELETE",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.count() instead.
            "prototype$__count__DocTypes": {
              url: urlBase + "/DocTypeCats/:id/DocTypes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#create
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/DocTypeCats",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#createMany
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/DocTypeCats",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#upsert
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/DocTypeCats",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#replaceOrCreate
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/DocTypeCats/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#upsertWithWhere
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/DocTypeCats/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#exists
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/DocTypeCats/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#findById
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/DocTypeCats/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#replaceById
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/DocTypeCats/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#find
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/DocTypeCats",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#findOne
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/DocTypeCats/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#updateAll
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/DocTypeCats/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#deleteById
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/DocTypeCats/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#count
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/DocTypeCats/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#prototype$updateAttributes
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypeCats id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/DocTypeCats/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#createChangeStream
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/DocTypeCats/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#getDocTypeCatsAndDocCount
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Get a list Doc Type Cats and a count of documents associated with them
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "getDocTypeCatsAndDocCount": {
              isArray: true,
              url: urlBase + "/DocTypeCats/getDocTypeCatsAndDocCount",
              method: "POST",
            },

            // INTERNAL. Use DocTypes.DocTypeCats() instead.
            "::get::DocTypes::DocTypeCats": {
              url: urlBase + "/DocTypes/:id/DocTypeCats",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#patchOrCreate
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#updateOrCreate
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#patchOrCreateWithWhere
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#update
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#destroyById
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#removeById
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#patchAttributes
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypeCats id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.DocTypeCats#modelName
        * @propertyOf lbServices.DocTypeCats
        * @description
        * The name of the model represented by this $resource,
        * i.e. `DocTypeCats`.
        */
        R.modelName = "DocTypeCats";

    /**
     * @ngdoc object
     * @name lbServices.DocTypeCats.DocTypes
     * @header lbServices.DocTypeCats.DocTypes
     * @object
     * @description
     *
     * The object `DocTypeCats.DocTypes` groups methods
     * manipulating `DocTypes` instances related to `DocTypeCats`.
     *
     * Call {@link lbServices.DocTypeCats#DocTypes DocTypeCats.DocTypes()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#DocTypes
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Queries DocTypes of DocTypeCats.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypeCats id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R.DocTypes = function() {
          var TargetResource = $injector.get("DocTypes");
          var action = TargetResource["::get::DocTypeCats::DocTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats.DocTypes#count
             * @methodOf lbServices.DocTypeCats.DocTypes
             *
             * @description
             *
             * Counts DocTypes of DocTypeCats.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypeCats id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.DocTypes.count = function() {
          var TargetResource = $injector.get("DocTypes");
          var action = TargetResource["::count::DocTypeCats::DocTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats.DocTypes#create
             * @methodOf lbServices.DocTypeCats.DocTypes
             *
             * @description
             *
             * Creates a new instance in DocTypes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypeCats id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R.DocTypes.create = function() {
          var TargetResource = $injector.get("DocTypes");
          var action = TargetResource["::create::DocTypeCats::DocTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats.DocTypes#createMany
             * @methodOf lbServices.DocTypeCats.DocTypes
             *
             * @description
             *
             * Creates a new instance in DocTypes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypeCats id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R.DocTypes.createMany = function() {
          var TargetResource = $injector.get("DocTypes");
          var action = TargetResource["::createMany::DocTypeCats::DocTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats.DocTypes#destroyAll
             * @methodOf lbServices.DocTypeCats.DocTypes
             *
             * @description
             *
             * Deletes all DocTypes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypeCats id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.DocTypes.destroyAll = function() {
          var TargetResource = $injector.get("DocTypes");
          var action = TargetResource["::delete::DocTypeCats::DocTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats.DocTypes#destroyById
             * @methodOf lbServices.DocTypeCats.DocTypes
             *
             * @description
             *
             * Delete a related item by id for DocTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypeCats id
             *
             *  - `fk` – `{*}` - Foreign key for DocTypes
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.DocTypes.destroyById = function() {
          var TargetResource = $injector.get("DocTypes");
          var action = TargetResource["::destroyById::DocTypeCats::DocTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats.DocTypes#findById
             * @methodOf lbServices.DocTypeCats.DocTypes
             *
             * @description
             *
             * Find a related item by id for DocTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypeCats id
             *
             *  - `fk` – `{*}` - Foreign key for DocTypes
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R.DocTypes.findById = function() {
          var TargetResource = $injector.get("DocTypes");
          var action = TargetResource["::findById::DocTypeCats::DocTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats.DocTypes#updateById
             * @methodOf lbServices.DocTypeCats.DocTypes
             *
             * @description
             *
             * Update a related item by id for DocTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypeCats id
             *
             *  - `fk` – `{*}` - Foreign key for DocTypes
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R.DocTypes.updateById = function() {
          var TargetResource = $injector.get("DocTypes");
          var action = TargetResource["::updateById::DocTypeCats::DocTypes"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.DocTypes
 * @header lbServices.DocTypes
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `DocTypes` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "DocTypes",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/DocTypes/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use DocTypes.Books.findById() instead.
            "prototype$__findById__Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypes/:id/Books/:fk",
              method: "GET",
            },

            // INTERNAL. Use DocTypes.Books.destroyById() instead.
            "prototype$__destroyById__Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypes/:id/Books/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use DocTypes.Books.updateById() instead.
            "prototype$__updateById__Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypes/:id/Books/:fk",
              method: "PUT",
            },

            // INTERNAL. Use DocTypes.DocTypeCats() instead.
            "prototype$__get__DocTypeCats": {
              url: urlBase + "/DocTypes/:id/DocTypeCats",
              method: "GET",
            },

            // INTERNAL. Use DocTypes.Books() instead.
            "prototype$__get__Books": {
              isArray: true,
              url: urlBase + "/DocTypes/:id/Books",
              method: "GET",
            },

            // INTERNAL. Use DocTypes.Books.create() instead.
            "prototype$__create__Books": {
              url: urlBase + "/DocTypes/:id/Books",
              method: "POST",
            },

            // INTERNAL. Use DocTypes.Books.destroyAll() instead.
            "prototype$__delete__Books": {
              url: urlBase + "/DocTypes/:id/Books",
              method: "DELETE",
            },

            // INTERNAL. Use DocTypes.Books.count() instead.
            "prototype$__count__Books": {
              url: urlBase + "/DocTypes/:id/Books/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#__get__notDeleted
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Queries notDeleted of DocTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/DocTypes/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#__create__notDeleted
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/DocTypes/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#__delete__notDeleted
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/DocTypes/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#__count__notDeleted
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Counts notDeleted of DocTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/DocTypes/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#create
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/DocTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#createMany
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/DocTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#upsert
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/DocTypes",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#replaceOrCreate
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/DocTypes/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#upsertWithWhere
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/DocTypes/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#exists
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/DocTypes/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#findById
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/DocTypes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#replaceById
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/DocTypes/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#find
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/DocTypes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#findOne
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/DocTypes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#updateAll
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/DocTypes/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#deleteById
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/DocTypes/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#count
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/DocTypes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#prototype$updateAttributes
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/DocTypes/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#createChangeStream
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/DocTypes/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#getDocTypesAndDocCount
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Get a list Doc Cats and a count of documents associated with them
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "getDocTypesAndDocCount": {
              isArray: true,
              url: urlBase + "/DocTypes/getDocTypesAndDocCount",
              method: "POST",
            },

            // INTERNAL. Use Books.DocTypes() instead.
            "::get::Books::DocTypes": {
              url: urlBase + "/Books/:id/DocTypes",
              method: "GET",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.findById() instead.
            "::findById::DocTypeCats::DocTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypeCats/:id/DocTypes/:fk",
              method: "GET",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.destroyById() instead.
            "::destroyById::DocTypeCats::DocTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypeCats/:id/DocTypes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.updateById() instead.
            "::updateById::DocTypeCats::DocTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypeCats/:id/DocTypes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use DocTypeCats.DocTypes() instead.
            "::get::DocTypeCats::DocTypes": {
              isArray: true,
              url: urlBase + "/DocTypeCats/:id/DocTypes",
              method: "GET",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.create() instead.
            "::create::DocTypeCats::DocTypes": {
              url: urlBase + "/DocTypeCats/:id/DocTypes",
              method: "POST",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.createMany() instead.
            "::createMany::DocTypeCats::DocTypes": {
              isArray: true,
              url: urlBase + "/DocTypeCats/:id/DocTypes",
              method: "POST",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.destroyAll() instead.
            "::delete::DocTypeCats::DocTypes": {
              url: urlBase + "/DocTypeCats/:id/DocTypes",
              method: "DELETE",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.count() instead.
            "::count::DocTypeCats::DocTypes": {
              url: urlBase + "/DocTypeCats/:id/DocTypes/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.DocTypes#patchOrCreate
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#updateOrCreate
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#patchOrCreateWithWhere
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#update
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#destroyById
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#removeById
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#patchAttributes
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.DocTypes#modelName
        * @propertyOf lbServices.DocTypes
        * @description
        * The name of the model represented by this $resource,
        * i.e. `DocTypes`.
        */
        R.modelName = "DocTypes";

    /**
     * @ngdoc object
     * @name lbServices.DocTypes.Books
     * @header lbServices.DocTypes.Books
     * @object
     * @description
     *
     * The object `DocTypes.Books` groups methods
     * manipulating `Books` instances related to `DocTypes`.
     *
     * Call {@link lbServices.DocTypes#Books DocTypes.Books()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.DocTypes#Books
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Queries Books of DocTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::get::DocTypes::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypes.Books#count
             * @methodOf lbServices.DocTypes.Books
             *
             * @description
             *
             * Counts Books of DocTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Books.count = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::count::DocTypes::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypes.Books#create
             * @methodOf lbServices.DocTypes.Books
             *
             * @description
             *
             * Creates a new instance in Books of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books.create = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::create::DocTypes::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypes.Books#createMany
             * @methodOf lbServices.DocTypes.Books
             *
             * @description
             *
             * Creates a new instance in Books of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books.createMany = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::createMany::DocTypes::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypes.Books#destroyAll
             * @methodOf lbServices.DocTypes.Books
             *
             * @description
             *
             * Deletes all Books of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Books.destroyAll = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::delete::DocTypes::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypes.Books#destroyById
             * @methodOf lbServices.DocTypes.Books
             *
             * @description
             *
             * Delete a related item by id for Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             *  - `fk` – `{*}` - Foreign key for Books
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Books.destroyById = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::destroyById::DocTypes::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypes.Books#findById
             * @methodOf lbServices.DocTypes.Books
             *
             * @description
             *
             * Find a related item by id for Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             *  - `fk` – `{*}` - Foreign key for Books
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books.findById = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::findById::DocTypes::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypes.Books#updateById
             * @methodOf lbServices.DocTypes.Books
             *
             * @description
             *
             * Update a related item by id for Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             *  - `fk` – `{*}` - Foreign key for Books
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books.updateById = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::updateById::DocTypes::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#DocTypeCats
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Fetches belongsTo relation DocTypeCats.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
        R.DocTypeCats = function() {
          var TargetResource = $injector.get("DocTypeCats");
          var action = TargetResource["::get::DocTypes::DocTypeCats"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Enclosures
 * @header lbServices.Enclosures
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Enclosures` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Enclosures",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Enclosures/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#__get__notDeleted
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Queries notDeleted of Enclosures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Enclosures/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#__create__notDeleted
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Enclosures/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#__delete__notDeleted
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Enclosures/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#__count__notDeleted
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Counts notDeleted of Enclosures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Enclosures/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#create
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Enclosures",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#createMany
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Enclosures",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#upsert
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Enclosures",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#replaceOrCreate
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Enclosures/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#upsertWithWhere
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Enclosures/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#exists
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Enclosures/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#findById
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Enclosures/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#replaceById
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Enclosures/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#find
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Enclosures",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#findOne
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Enclosures/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#updateAll
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Enclosures/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#deleteById
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Enclosures/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#count
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Enclosures/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#prototype$updateAttributes
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Enclosures id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Enclosures/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#createChangeStream
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Enclosures/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#getEnclosures
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Get Enclosures based on Division ID
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "getEnclosures": {
              isArray: true,
              url: urlBase + "/Enclosures/getEnclosures",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#duplicateByBookId
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Set Product IDs status to 0 based on book id
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "duplicateByBookId": {
              isArray: true,
              url: urlBase + "/Enclosures/duplicateByBookId",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#setStatusByBookId
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Set Product IDs status to 0 based on book id
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "setStatusByBookId": {
              isArray: true,
              url: urlBase + "/Enclosures/setStatusByBookId",
              method: "POST",
            },

            // INTERNAL. Use Books.Enclosures.findById() instead.
            "::findById::Books::Enclosures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Enclosures/:fk",
              method: "GET",
            },

            // INTERNAL. Use Books.Enclosures.destroyById() instead.
            "::destroyById::Books::Enclosures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Enclosures/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Enclosures.updateById() instead.
            "::updateById::Books::Enclosures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Enclosures/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Books.Enclosures() instead.
            "::get::Books::Enclosures": {
              isArray: true,
              url: urlBase + "/Books/:id/Enclosures",
              method: "GET",
            },

            // INTERNAL. Use Books.Enclosures.create() instead.
            "::create::Books::Enclosures": {
              url: urlBase + "/Books/:id/Enclosures",
              method: "POST",
            },

            // INTERNAL. Use Books.Enclosures.createMany() instead.
            "::createMany::Books::Enclosures": {
              isArray: true,
              url: urlBase + "/Books/:id/Enclosures",
              method: "POST",
            },

            // INTERNAL. Use Books.Enclosures.destroyAll() instead.
            "::delete::Books::Enclosures": {
              url: urlBase + "/Books/:id/Enclosures",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Enclosures.count() instead.
            "::count::Books::Enclosures": {
              url: urlBase + "/Books/:id/Enclosures/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Enclosures#patchOrCreate
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#updateOrCreate
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#patchOrCreateWithWhere
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#update
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#destroyById
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#removeById
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#patchAttributes
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Enclosures id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Enclosures#modelName
        * @propertyOf lbServices.Enclosures
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Enclosures`.
        */
        R.modelName = "Enclosures";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.EnclosureTypes
 * @header lbServices.EnclosureTypes
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `EnclosureTypes` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "EnclosureTypes",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/EnclosureTypes/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#__get__notDeleted
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Queries notDeleted of EnclosureTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/EnclosureTypes/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#__create__notDeleted
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/EnclosureTypes/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#__delete__notDeleted
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/EnclosureTypes/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#__count__notDeleted
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Counts notDeleted of EnclosureTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/EnclosureTypes/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#create
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/EnclosureTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#createMany
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/EnclosureTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#upsert
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/EnclosureTypes",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#replaceOrCreate
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/EnclosureTypes/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#upsertWithWhere
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/EnclosureTypes/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#exists
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/EnclosureTypes/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#findById
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/EnclosureTypes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#replaceById
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/EnclosureTypes/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#find
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/EnclosureTypes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#findOne
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/EnclosureTypes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#updateAll
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/EnclosureTypes/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#deleteById
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/EnclosureTypes/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#count
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/EnclosureTypes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#prototype$updateAttributes
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - EnclosureTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/EnclosureTypes/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#createChangeStream
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/EnclosureTypes/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#patchOrCreate
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#updateOrCreate
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#patchOrCreateWithWhere
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#update
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#destroyById
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#removeById
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#patchAttributes
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - EnclosureTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.EnclosureTypes#modelName
        * @propertyOf lbServices.EnclosureTypes
        * @description
        * The name of the model represented by this $resource,
        * i.e. `EnclosureTypes`.
        */
        R.modelName = "EnclosureTypes";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ErrorLogs
 * @header lbServices.ErrorLogs
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ErrorLogs` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ErrorLogs",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ErrorLogs/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#create
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ErrorLogs",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#createMany
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ErrorLogs",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#upsert
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/ErrorLogs",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#replaceOrCreate
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ErrorLogs/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#upsertWithWhere
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ErrorLogs/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#exists
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ErrorLogs/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#findById
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ErrorLogs/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#replaceById
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ErrorLogs/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#find
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ErrorLogs",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#findOne
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ErrorLogs/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#updateAll
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ErrorLogs/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#deleteById
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ErrorLogs/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#count
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ErrorLogs/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#prototype$updateAttributes
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ErrorLogs id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/ErrorLogs/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#createChangeStream
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ErrorLogs/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#insertError
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Log error into the error log table
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "insertError": {
              isArray: true,
              url: urlBase + "/ErrorLogs/insertError",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#patchOrCreate
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#updateOrCreate
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#patchOrCreateWithWhere
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#update
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#destroyById
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#removeById
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#patchAttributes
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ErrorLogs id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ErrorLogs#modelName
        * @propertyOf lbServices.ErrorLogs
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ErrorLogs`.
        */
        R.modelName = "ErrorLogs";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Events
 * @header lbServices.Events
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Events` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Events",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Events/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Events.Contents() instead.
            "prototype$__get__Contents": {
              url: urlBase + "/Events/:id/Contents",
              method: "GET",
            },

            // INTERNAL. Use Events.History() instead.
            "prototype$__get__History": {
              url: urlBase + "/Events/:id/History",
              method: "GET",
            },

            // INTERNAL. Use Events.Messages() instead.
            "prototype$__get__Messages": {
              url: urlBase + "/Events/:id/Messages",
              method: "GET",
            },

            // INTERNAL. Use Events.Users() instead.
            "prototype$__get__Users": {
              url: urlBase + "/Events/:id/Users",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#create
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Events",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#createMany
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Events",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#upsert
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Events",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#replaceOrCreate
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Events/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#upsertWithWhere
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Events/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#exists
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Events/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#findById
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Events/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#replaceById
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Events/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#find
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Events",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#findOne
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Events/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#updateAll
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Events/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#deleteById
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Events/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#count
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Events/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#prototype$updateAttributes
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Events id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Events/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#createChangeStream
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Events/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Users.Events.findById() instead.
            "::findById::Users::Events": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Events/:fk",
              method: "GET",
            },

            // INTERNAL. Use Users.Events.destroyById() instead.
            "::destroyById::Users::Events": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Events/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Users.Events.updateById() instead.
            "::updateById::Users::Events": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Events/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Users.Events() instead.
            "::get::Users::Events": {
              isArray: true,
              url: urlBase + "/Users/:id/Events",
              method: "GET",
            },

            // INTERNAL. Use Users.Events.create() instead.
            "::create::Users::Events": {
              url: urlBase + "/Users/:id/Events",
              method: "POST",
            },

            // INTERNAL. Use Users.Events.createMany() instead.
            "::createMany::Users::Events": {
              isArray: true,
              url: urlBase + "/Users/:id/Events",
              method: "POST",
            },

            // INTERNAL. Use Users.Events.destroyAll() instead.
            "::delete::Users::Events": {
              url: urlBase + "/Users/:id/Events",
              method: "DELETE",
            },

            // INTERNAL. Use Users.Events.count() instead.
            "::count::Users::Events": {
              url: urlBase + "/Users/:id/Events/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Events#patchOrCreate
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Events#updateOrCreate
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Events#patchOrCreateWithWhere
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Events#update
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Events#destroyById
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Events#removeById
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Events#patchAttributes
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Events id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Events#modelName
        * @propertyOf lbServices.Events
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Events`.
        */
        R.modelName = "Events";


            /**
             * @ngdoc method
             * @name lbServices.Events#Contents
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Fetches belongsTo relation Contents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Events id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R.Contents = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::get::Events::Contents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Events#History
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Fetches belongsTo relation History.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Events id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::get::Events::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Events#Messages
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Fetches belongsTo relation Messages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Events id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
        R.Messages = function() {
          var TargetResource = $injector.get("Messages");
          var action = TargetResource["::get::Events::Messages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Events#Users
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Fetches belongsTo relation Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Events id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::get::Events::Users"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.History
 * @header lbServices.History
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `History` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "History",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Histories/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use History.Structures() instead.
            "prototype$__get__Structures": {
              url: urlBase + "/Histories/:id/Structures",
              method: "GET",
            },

            // INTERNAL. Use History.versions() instead.
            "prototype$__get__versions": {
              url: urlBase + "/Histories/:id/versions",
              method: "GET",
            },

            // INTERNAL. Use History.versionsNew() instead.
            "prototype$__get__versionsNew": {
              url: urlBase + "/Histories/:id/versionsNew",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#create
             * @methodOf lbServices.History
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Histories",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#createMany
             * @methodOf lbServices.History
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Histories",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#upsert
             * @methodOf lbServices.History
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Histories",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#replaceOrCreate
             * @methodOf lbServices.History
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Histories/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#upsertWithWhere
             * @methodOf lbServices.History
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Histories/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#exists
             * @methodOf lbServices.History
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Histories/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#findById
             * @methodOf lbServices.History
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Histories/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#replaceById
             * @methodOf lbServices.History
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Histories/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#find
             * @methodOf lbServices.History
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Histories",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#findOne
             * @methodOf lbServices.History
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Histories/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#updateAll
             * @methodOf lbServices.History
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Histories/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#deleteById
             * @methodOf lbServices.History
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Histories/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#count
             * @methodOf lbServices.History
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Histories/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#prototype$updateAttributes
             * @methodOf lbServices.History
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - History id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Histories/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#createChangeStream
             * @methodOf lbServices.History
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Histories/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Events.History() instead.
            "::get::Events::History": {
              url: urlBase + "/Events/:id/History",
              method: "GET",
            },

            // INTERNAL. Use Structures.History.findById() instead.
            "::findById::Structures::History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/History/:fk",
              method: "GET",
            },

            // INTERNAL. Use Structures.History.destroyById() instead.
            "::destroyById::Structures::History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/History/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Structures.History.updateById() instead.
            "::updateById::Structures::History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/History/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Structures.History() instead.
            "::get::Structures::History": {
              isArray: true,
              url: urlBase + "/Structures/:id/History",
              method: "GET",
            },

            // INTERNAL. Use Structures.History.create() instead.
            "::create::Structures::History": {
              url: urlBase + "/Structures/:id/History",
              method: "POST",
            },

            // INTERNAL. Use Structures.History.createMany() instead.
            "::createMany::Structures::History": {
              isArray: true,
              url: urlBase + "/Structures/:id/History",
              method: "POST",
            },

            // INTERNAL. Use Structures.History.destroyAll() instead.
            "::delete::Structures::History": {
              url: urlBase + "/Structures/:id/History",
              method: "DELETE",
            },

            // INTERNAL. Use Structures.History.count() instead.
            "::count::Structures::History": {
              url: urlBase + "/Structures/:id/History/count",
              method: "GET",
            },

            // INTERNAL. Use Versions.History.findById() instead.
            "::findById::Versions::History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/History/:fk",
              method: "GET",
            },

            // INTERNAL. Use Versions.History.destroyById() instead.
            "::destroyById::Versions::History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/History/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Versions.History.updateById() instead.
            "::updateById::Versions::History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/History/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Versions.History() instead.
            "::get::Versions::History": {
              isArray: true,
              url: urlBase + "/Versions/:id/History",
              method: "GET",
            },

            // INTERNAL. Use Versions.History.create() instead.
            "::create::Versions::History": {
              url: urlBase + "/Versions/:id/History",
              method: "POST",
            },

            // INTERNAL. Use Versions.History.createMany() instead.
            "::createMany::Versions::History": {
              isArray: true,
              url: urlBase + "/Versions/:id/History",
              method: "POST",
            },

            // INTERNAL. Use Versions.History.destroyAll() instead.
            "::delete::Versions::History": {
              url: urlBase + "/Versions/:id/History",
              method: "DELETE",
            },

            // INTERNAL. Use Versions.History.count() instead.
            "::count::Versions::History": {
              url: urlBase + "/Versions/:id/History/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.History#patchOrCreate
             * @methodOf lbServices.History
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.History#updateOrCreate
             * @methodOf lbServices.History
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.History#patchOrCreateWithWhere
             * @methodOf lbServices.History
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.History#update
             * @methodOf lbServices.History
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.History#destroyById
             * @methodOf lbServices.History
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.History#removeById
             * @methodOf lbServices.History
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.History#patchAttributes
             * @methodOf lbServices.History
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - History id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.History#modelName
        * @propertyOf lbServices.History
        * @description
        * The name of the model represented by this $resource,
        * i.e. `History`.
        */
        R.modelName = "History";


            /**
             * @ngdoc method
             * @name lbServices.History#Structures
             * @methodOf lbServices.History
             *
             * @description
             *
             * Fetches belongsTo relation Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - History id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::get::History::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.History#versions
             * @methodOf lbServices.History
             *
             * @description
             *
             * Fetches belongsTo relation versions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - History id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R.versions = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::get::History::versions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.History#versionsNew
             * @methodOf lbServices.History
             *
             * @description
             *
             * Fetches belongsTo relation versionsNew.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - History id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R.versionsNew = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::get::History::versionsNew"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Insurers
 * @header lbServices.Insurers
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Insurers` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Insurers",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Insurers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Insurers.ProductIds.findById() instead.
            "prototype$__findById__ProductIds": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Insurers/:id/ProductIds/:fk",
              method: "GET",
            },

            // INTERNAL. Use Insurers.ProductIds.destroyById() instead.
            "prototype$__destroyById__ProductIds": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Insurers/:id/ProductIds/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Insurers.ProductIds.updateById() instead.
            "prototype$__updateById__ProductIds": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Insurers/:id/ProductIds/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#__get__notDeleted
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Queries notDeleted of Insurers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Insurers/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#__create__notDeleted
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Insurers/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#__delete__notDeleted
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Insurers/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#__count__notDeleted
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Counts notDeleted of Insurers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Insurers/notDeleted/count",
              method: "GET",
            },

            // INTERNAL. Use Insurers.ProductIds() instead.
            "prototype$__get__ProductIds": {
              isArray: true,
              url: urlBase + "/Insurers/:id/ProductIds",
              method: "GET",
            },

            // INTERNAL. Use Insurers.ProductIds.create() instead.
            "prototype$__create__ProductIds": {
              url: urlBase + "/Insurers/:id/ProductIds",
              method: "POST",
            },

            // INTERNAL. Use Insurers.ProductIds.destroyAll() instead.
            "prototype$__delete__ProductIds": {
              url: urlBase + "/Insurers/:id/ProductIds",
              method: "DELETE",
            },

            // INTERNAL. Use Insurers.ProductIds.count() instead.
            "prototype$__count__ProductIds": {
              url: urlBase + "/Insurers/:id/ProductIds/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#create
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Insurers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#createMany
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Insurers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#upsert
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Insurers",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#replaceOrCreate
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Insurers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#upsertWithWhere
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Insurers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#exists
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Insurers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#findById
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Insurers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#replaceById
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Insurers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#find
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Insurers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#findOne
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Insurers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#updateAll
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Insurers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#deleteById
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Insurers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#count
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Insurers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#prototype$updateAttributes
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Insurers id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Insurers/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#createChangeStream
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Insurers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#getInsurersAndDocCount
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Get a list insurers and a count of documents associated with them
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "getInsurersAndDocCount": {
              isArray: true,
              url: urlBase + "/Insurers/getInsurersAndDocCount",
              method: "POST",
            },

            // INTERNAL. Use Books.Insurers() instead.
            "::get::Books::Insurers": {
              url: urlBase + "/Books/:id/Insurers",
              method: "GET",
            },

            // INTERNAL. Use ProductIds.Insurers() instead.
            "::get::ProductIds::Insurers": {
              url: urlBase + "/ProductIds/:id/Insurers",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Insurers#patchOrCreate
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Insurers#updateOrCreate
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Insurers#patchOrCreateWithWhere
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Insurers#update
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Insurers#destroyById
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Insurers#removeById
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Insurers#patchAttributes
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Insurers id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Insurers#modelName
        * @propertyOf lbServices.Insurers
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Insurers`.
        */
        R.modelName = "Insurers";

    /**
     * @ngdoc object
     * @name lbServices.Insurers.ProductIds
     * @header lbServices.Insurers.ProductIds
     * @object
     * @description
     *
     * The object `Insurers.ProductIds` groups methods
     * manipulating `ProductIds` instances related to `Insurers`.
     *
     * Call {@link lbServices.Insurers#ProductIds Insurers.ProductIds()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Insurers#ProductIds
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Queries ProductIds of Insurers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Insurers id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R.ProductIds = function() {
          var TargetResource = $injector.get("ProductIds");
          var action = TargetResource["::get::Insurers::ProductIds"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Insurers.ProductIds#count
             * @methodOf lbServices.Insurers.ProductIds
             *
             * @description
             *
             * Counts ProductIds of Insurers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Insurers id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.ProductIds.count = function() {
          var TargetResource = $injector.get("ProductIds");
          var action = TargetResource["::count::Insurers::ProductIds"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Insurers.ProductIds#create
             * @methodOf lbServices.Insurers.ProductIds
             *
             * @description
             *
             * Creates a new instance in ProductIds of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Insurers id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R.ProductIds.create = function() {
          var TargetResource = $injector.get("ProductIds");
          var action = TargetResource["::create::Insurers::ProductIds"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Insurers.ProductIds#createMany
             * @methodOf lbServices.Insurers.ProductIds
             *
             * @description
             *
             * Creates a new instance in ProductIds of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Insurers id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R.ProductIds.createMany = function() {
          var TargetResource = $injector.get("ProductIds");
          var action = TargetResource["::createMany::Insurers::ProductIds"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Insurers.ProductIds#destroyAll
             * @methodOf lbServices.Insurers.ProductIds
             *
             * @description
             *
             * Deletes all ProductIds of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Insurers id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.ProductIds.destroyAll = function() {
          var TargetResource = $injector.get("ProductIds");
          var action = TargetResource["::delete::Insurers::ProductIds"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Insurers.ProductIds#destroyById
             * @methodOf lbServices.Insurers.ProductIds
             *
             * @description
             *
             * Delete a related item by id for ProductIds.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Insurers id
             *
             *  - `fk` – `{*}` - Foreign key for ProductIds
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.ProductIds.destroyById = function() {
          var TargetResource = $injector.get("ProductIds");
          var action = TargetResource["::destroyById::Insurers::ProductIds"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Insurers.ProductIds#findById
             * @methodOf lbServices.Insurers.ProductIds
             *
             * @description
             *
             * Find a related item by id for ProductIds.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Insurers id
             *
             *  - `fk` – `{*}` - Foreign key for ProductIds
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R.ProductIds.findById = function() {
          var TargetResource = $injector.get("ProductIds");
          var action = TargetResource["::findById::Insurers::ProductIds"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Insurers.ProductIds#updateById
             * @methodOf lbServices.Insurers.ProductIds
             *
             * @description
             *
             * Update a related item by id for ProductIds.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Insurers id
             *
             *  - `fk` – `{*}` - Foreign key for ProductIds
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R.ProductIds.updateById = function() {
          var TargetResource = $injector.get("ProductIds");
          var action = TargetResource["::updateById::Insurers::ProductIds"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.InsurerDetails
 * @header lbServices.InsurerDetails
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `InsurerDetails` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "InsurerDetails",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/InsurerDetails/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use InsurerDetails.Users.findById() instead.
            "prototype$__findById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InsurerDetails/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use InsurerDetails.Users.destroyById() instead.
            "prototype$__destroyById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InsurerDetails/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use InsurerDetails.Users.updateById() instead.
            "prototype$__updateById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InsurerDetails/:id/Users/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#__get__notDeleted
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Queries notDeleted of InsurerDetails.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/InsurerDetails/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#__create__notDeleted
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/InsurerDetails/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#__delete__notDeleted
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/InsurerDetails/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#__count__notDeleted
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Counts notDeleted of InsurerDetails.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/InsurerDetails/notDeleted/count",
              method: "GET",
            },

            // INTERNAL. Use InsurerDetails.Users() instead.
            "prototype$__get__Users": {
              isArray: true,
              url: urlBase + "/InsurerDetails/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use InsurerDetails.Users.create() instead.
            "prototype$__create__Users": {
              url: urlBase + "/InsurerDetails/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use InsurerDetails.Users.destroyAll() instead.
            "prototype$__delete__Users": {
              url: urlBase + "/InsurerDetails/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use InsurerDetails.Users.count() instead.
            "prototype$__count__Users": {
              url: urlBase + "/InsurerDetails/:id/Users/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#create
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/InsurerDetails",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#createMany
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/InsurerDetails",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#upsert
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/InsurerDetails",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#replaceOrCreate
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/InsurerDetails/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#upsertWithWhere
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/InsurerDetails/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#exists
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/InsurerDetails/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#findById
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/InsurerDetails/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#replaceById
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/InsurerDetails/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#find
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/InsurerDetails",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#findOne
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/InsurerDetails/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#updateAll
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/InsurerDetails/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#deleteById
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/InsurerDetails/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#count
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/InsurerDetails/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#prototype$updateAttributes
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - InsurerDetails id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/InsurerDetails/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#createChangeStream
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/InsurerDetails/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#setStatusByBookId
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Set Product IDs status to 0 based on book id
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "setStatusByBookId": {
              isArray: true,
              url: urlBase + "/InsurerDetails/setStatusByBookId",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#duplicateByBookId
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Set Product IDs status to 0 based on book id
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "duplicateByBookId": {
              isArray: true,
              url: urlBase + "/InsurerDetails/duplicateByBookId",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#patchOrCreate
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#updateOrCreate
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#patchOrCreateWithWhere
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#update
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#destroyById
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#removeById
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#patchAttributes
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - InsurerDetails id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.InsurerDetails#modelName
        * @propertyOf lbServices.InsurerDetails
        * @description
        * The name of the model represented by this $resource,
        * i.e. `InsurerDetails`.
        */
        R.modelName = "InsurerDetails";

    /**
     * @ngdoc object
     * @name lbServices.InsurerDetails.Users
     * @header lbServices.InsurerDetails.Users
     * @object
     * @description
     *
     * The object `InsurerDetails.Users` groups methods
     * manipulating `Users` instances related to `InsurerDetails`.
     *
     * Call {@link lbServices.InsurerDetails#Users InsurerDetails.Users()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#Users
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Queries Users of InsurerDetails.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - InsurerDetails id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::get::InsurerDetails::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails.Users#count
             * @methodOf lbServices.InsurerDetails.Users
             *
             * @description
             *
             * Counts Users of InsurerDetails.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - InsurerDetails id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Users.count = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::count::InsurerDetails::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails.Users#create
             * @methodOf lbServices.InsurerDetails.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - InsurerDetails id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.create = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::create::InsurerDetails::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails.Users#createMany
             * @methodOf lbServices.InsurerDetails.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - InsurerDetails id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.createMany = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::createMany::InsurerDetails::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails.Users#destroyAll
             * @methodOf lbServices.InsurerDetails.Users
             *
             * @description
             *
             * Deletes all Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - InsurerDetails id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyAll = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::delete::InsurerDetails::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails.Users#destroyById
             * @methodOf lbServices.InsurerDetails.Users
             *
             * @description
             *
             * Delete a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - InsurerDetails id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::destroyById::InsurerDetails::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails.Users#findById
             * @methodOf lbServices.InsurerDetails.Users
             *
             * @description
             *
             * Find a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - InsurerDetails id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.findById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::findById::InsurerDetails::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails.Users#updateById
             * @methodOf lbServices.InsurerDetails.Users
             *
             * @description
             *
             * Update a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - InsurerDetails id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.updateById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::updateById::InsurerDetails::Users"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Languages
 * @header lbServices.Languages
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Languages` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Languages",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Languages/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Languages#__get__notDeleted
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Queries notDeleted of Languages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Languages/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#__create__notDeleted
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Languages/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#__delete__notDeleted
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Languages/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#__count__notDeleted
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Counts notDeleted of Languages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Languages/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#create
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Languages",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#createMany
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Languages",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#upsert
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Languages",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#replaceOrCreate
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Languages/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#upsertWithWhere
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Languages/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#exists
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Languages/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#findById
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Languages/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#replaceById
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Languages/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#find
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Languages",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#findOne
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Languages/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#updateAll
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Languages/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#deleteById
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Languages/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#count
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Languages/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#prototype$updateAttributes
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Languages id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Languages/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#createChangeStream
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Languages/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Languages#patchOrCreate
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Languages#updateOrCreate
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Languages#patchOrCreateWithWhere
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Languages#update
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Languages#destroyById
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Languages#removeById
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Languages#patchAttributes
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Languages id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Languages#modelName
        * @propertyOf lbServices.Languages
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Languages`.
        */
        R.modelName = "Languages";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Locations
 * @header lbServices.Locations
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Locations` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Locations",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Locations/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Locations.Users.findById() instead.
            "prototype$__findById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Locations/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Locations.Users.destroyById() instead.
            "prototype$__destroyById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Locations/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Locations.Users.updateById() instead.
            "prototype$__updateById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Locations/:id/Users/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#__get__notDeleted
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Queries notDeleted of Locations.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Locations/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#__create__notDeleted
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Locations/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#__delete__notDeleted
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Locations/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#__count__notDeleted
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Counts notDeleted of Locations.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Locations/notDeleted/count",
              method: "GET",
            },

            // INTERNAL. Use Locations.Users() instead.
            "prototype$__get__Users": {
              isArray: true,
              url: urlBase + "/Locations/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Locations.Users.create() instead.
            "prototype$__create__Users": {
              url: urlBase + "/Locations/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Locations.Users.destroyAll() instead.
            "prototype$__delete__Users": {
              url: urlBase + "/Locations/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use Locations.Users.count() instead.
            "prototype$__count__Users": {
              url: urlBase + "/Locations/:id/Users/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#create
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Locations",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#createMany
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Locations",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#upsert
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Locations",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#replaceOrCreate
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Locations/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#upsertWithWhere
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Locations/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#exists
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Locations/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#findById
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Locations/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#replaceById
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Locations/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#find
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Locations",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#findOne
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Locations/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#updateAll
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Locations/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#deleteById
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Locations/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#count
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Locations/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#prototype$updateAttributes
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Locations id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Locations/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#createChangeStream
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Locations/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Users.Locations() instead.
            "::get::Users::Locations": {
              url: urlBase + "/Users/:id/Locations",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Locations#patchOrCreate
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Locations#updateOrCreate
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Locations#patchOrCreateWithWhere
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Locations#update
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Locations#destroyById
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Locations#removeById
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Locations#patchAttributes
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Locations id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Locations#modelName
        * @propertyOf lbServices.Locations
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Locations`.
        */
        R.modelName = "Locations";

    /**
     * @ngdoc object
     * @name lbServices.Locations.Users
     * @header lbServices.Locations.Users
     * @object
     * @description
     *
     * The object `Locations.Users` groups methods
     * manipulating `Users` instances related to `Locations`.
     *
     * Call {@link lbServices.Locations#Users Locations.Users()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Locations#Users
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Queries Users of Locations.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Locations id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::get::Locations::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Locations.Users#count
             * @methodOf lbServices.Locations.Users
             *
             * @description
             *
             * Counts Users of Locations.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Locations id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Users.count = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::count::Locations::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Locations.Users#create
             * @methodOf lbServices.Locations.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Locations id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.create = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::create::Locations::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Locations.Users#createMany
             * @methodOf lbServices.Locations.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Locations id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.createMany = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::createMany::Locations::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Locations.Users#destroyAll
             * @methodOf lbServices.Locations.Users
             *
             * @description
             *
             * Deletes all Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Locations id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyAll = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::delete::Locations::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Locations.Users#destroyById
             * @methodOf lbServices.Locations.Users
             *
             * @description
             *
             * Delete a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Locations id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::destroyById::Locations::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Locations.Users#findById
             * @methodOf lbServices.Locations.Users
             *
             * @description
             *
             * Find a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Locations id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.findById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::findById::Locations::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Locations.Users#updateById
             * @methodOf lbServices.Locations.Users
             *
             * @description
             *
             * Update a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Locations id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.updateById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::updateById::Locations::Users"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Messages
 * @header lbServices.Messages
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Messages` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Messages",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Messages/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Messages#create
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Messages",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#createMany
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Messages",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#upsert
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Messages",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#replaceOrCreate
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Messages/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#upsertWithWhere
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Messages/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#exists
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Messages/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#findById
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Messages/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#replaceById
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Messages/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#find
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Messages",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#findOne
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Messages/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#updateAll
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Messages/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#deleteById
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Messages/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#count
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Messages/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#prototype$updateAttributes
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Messages id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Messages/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#createChangeStream
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Messages/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#sendmail
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * sendmail
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "sendmail": {
              url: urlBase + "/Messages/sendmail",
              method: "POST",
            },

            // INTERNAL. Use Events.Messages() instead.
            "::get::Events::Messages": {
              url: urlBase + "/Events/:id/Messages",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Messages#patchOrCreate
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Messages#updateOrCreate
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Messages#patchOrCreateWithWhere
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Messages#update
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Messages#destroyById
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Messages#removeById
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Messages#patchAttributes
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Messages id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Messages#modelName
        * @propertyOf lbServices.Messages
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Messages`.
        */
        R.modelName = "Messages";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.MetaTables
 * @header lbServices.MetaTables
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `MetaTables` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "MetaTables",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/MetaTables/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#create
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/MetaTables",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#createMany
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/MetaTables",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#upsert
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/MetaTables",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#replaceOrCreate
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/MetaTables/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#upsertWithWhere
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/MetaTables/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#exists
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/MetaTables/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#findById
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/MetaTables/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#replaceById
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/MetaTables/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#find
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/MetaTables",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#findOne
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/MetaTables/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#updateAll
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/MetaTables/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#deleteById
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/MetaTables/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#count
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/MetaTables/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#prototype$updateAttributes
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - MetaTables id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/MetaTables/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#createChangeStream
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/MetaTables/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#getLastUpdatedCounts
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Get a count of how many meta-list items have been updated since the users last fetch
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "getLastUpdatedCounts": {
              url: urlBase + "/MetaTables/getLastUpdatedCounts",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.MetaTables#patchOrCreate
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#updateOrCreate
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#patchOrCreateWithWhere
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#update
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#destroyById
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#removeById
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#patchAttributes
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - MetaTables id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.MetaTables#modelName
        * @propertyOf lbServices.MetaTables
        * @description
        * The name of the model represented by this $resource,
        * i.e. `MetaTables`.
        */
        R.modelName = "MetaTables";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Notifications
 * @header lbServices.Notifications
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Notifications` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Notifications",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Notifications/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Notifications#create
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Notifications",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#createMany
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Notifications",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#upsert
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Notifications",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#replaceOrCreate
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Notifications/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#upsertWithWhere
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Notifications/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#exists
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Notifications/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#findById
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Notifications/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#replaceById
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Notifications/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#find
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Notifications",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#findOne
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Notifications/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#updateAll
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Notifications/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#deleteById
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Notifications/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#count
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Notifications/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#prototype$updateAttributes
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notifications id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Notifications/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#createChangeStream
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Notifications/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#sendApprovalNotifications_day3
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Send Approval Notifications Day 3
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "sendApprovalNotifications_day3": {
              url: urlBase + "/Notifications/sendApprovalNotifications_day3",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#sendApprovalNotifications_day5
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Send Approval Notifications Day 5
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "sendApprovalNotifications_day5": {
              url: urlBase + "/Notifications/sendApprovalNotifications_day5",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#sendApprovalNotifications_day7
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Send Approval Notifications Day 7
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "sendApprovalNotifications_day7": {
              url: urlBase + "/Notifications/sendApprovalNotifications_day7",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#sendExpiryNotifications
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Send Review Notifications
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "sendExpiryNotifications": {
              url: urlBase + "/Notifications/sendExpiryNotifications",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#sendTestNotification
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * sendTestNotification
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "sendTestNotification": {
              url: urlBase + "/Notifications/sendTestNotification",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#sendExpiryReminderNotifications
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Send Review Notifications
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "sendExpiryReminderNotifications": {
              url: urlBase + "/Notifications/sendExpiryReminderNotifications",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#sendReviewNotifications
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Send Review Notifications
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "sendReviewNotifications": {
              url: urlBase + "/Notifications/sendReviewNotifications",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#sendReviewEventNotification
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Send Review Event Notification
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "sendReviewEventNotification": {
              url: urlBase + "/Notifications/sendReviewEventNotification",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#sendEventNotification
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Send Event Notification
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "sendEventNotification": {
              url: urlBase + "/Notifications/sendEventNotification",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Notifications#patchOrCreate
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Notifications#updateOrCreate
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Notifications#patchOrCreateWithWhere
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Notifications#update
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Notifications#destroyById
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Notifications#removeById
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Notifications#patchAttributes
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notifications id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Notifications#modelName
        * @propertyOf lbServices.Notifications
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Notifications`.
        */
        R.modelName = "Notifications";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.NotificationTypes
 * @header lbServices.NotificationTypes
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `NotificationTypes` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "NotificationTypes",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/NotificationTypes/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#create
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/NotificationTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#createMany
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/NotificationTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#upsert
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/NotificationTypes",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#replaceOrCreate
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/NotificationTypes/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#upsertWithWhere
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/NotificationTypes/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#exists
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/NotificationTypes/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#findById
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/NotificationTypes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#replaceById
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/NotificationTypes/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#find
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/NotificationTypes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#findOne
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/NotificationTypes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#updateAll
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/NotificationTypes/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#deleteById
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/NotificationTypes/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#count
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/NotificationTypes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#prototype$updateAttributes
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - NotificationTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/NotificationTypes/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#createChangeStream
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/NotificationTypes/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#patchOrCreate
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#updateOrCreate
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#patchOrCreateWithWhere
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#update
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#destroyById
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#removeById
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#patchAttributes
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - NotificationTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.NotificationTypes#modelName
        * @propertyOf lbServices.NotificationTypes
        * @description
        * The name of the model represented by this $resource,
        * i.e. `NotificationTypes`.
        */
        R.modelName = "NotificationTypes";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.OrderHistory
 * @header lbServices.OrderHistory
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `OrderHistory` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "OrderHistory",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/OrderHistories/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use OrderHistory.Structures() instead.
            "prototype$__get__Structures": {
              url: urlBase + "/OrderHistories/:id/Structures",
              method: "GET",
            },

            // INTERNAL. Use OrderHistory.versions() instead.
            "prototype$__get__versions": {
              url: urlBase + "/OrderHistories/:id/versions",
              method: "GET",
            },

            // INTERNAL. Use OrderHistory.versionsNew() instead.
            "prototype$__get__versionsNew": {
              url: urlBase + "/OrderHistories/:id/versionsNew",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#create
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/OrderHistories",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#createMany
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/OrderHistories",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#upsert
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/OrderHistories",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#replaceOrCreate
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/OrderHistories/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#upsertWithWhere
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/OrderHistories/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#exists
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/OrderHistories/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#findById
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/OrderHistories/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#replaceById
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/OrderHistories/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#find
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/OrderHistories",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#findOne
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/OrderHistories/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#updateAll
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/OrderHistories/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#deleteById
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/OrderHistories/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#count
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/OrderHistories/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#prototype$updateAttributes
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - OrderHistory id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/OrderHistories/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#createChangeStream
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/OrderHistories/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#patchOrCreate
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#updateOrCreate
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#patchOrCreateWithWhere
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#update
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#destroyById
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#removeById
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#patchAttributes
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - OrderHistory id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.OrderHistory#modelName
        * @propertyOf lbServices.OrderHistory
        * @description
        * The name of the model represented by this $resource,
        * i.e. `OrderHistory`.
        */
        R.modelName = "OrderHistory";


            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#Structures
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Fetches belongsTo relation Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - OrderHistory id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::get::OrderHistory::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#versions
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Fetches belongsTo relation versions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - OrderHistory id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R.versions = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::get::OrderHistory::versions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#versionsNew
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Fetches belongsTo relation versionsNew.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - OrderHistory id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R.versionsNew = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::get::OrderHistory::versionsNew"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.PdfVersions
 * @header lbServices.PdfVersions
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `PdfVersions` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "PdfVersions",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/PdfVersions/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#create
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/PdfVersions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#createMany
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/PdfVersions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#upsert
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/PdfVersions",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#replaceOrCreate
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/PdfVersions/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#upsertWithWhere
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/PdfVersions/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#exists
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/PdfVersions/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#findById
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/PdfVersions/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#replaceById
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/PdfVersions/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#find
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/PdfVersions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#findOne
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/PdfVersions/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#updateAll
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/PdfVersions/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#deleteById
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/PdfVersions/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#count
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/PdfVersions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#prototype$updateAttributes
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PdfVersions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/PdfVersions/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#createChangeStream
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/PdfVersions/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#patchOrCreate
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#updateOrCreate
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#patchOrCreateWithWhere
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#update
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#destroyById
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#removeById
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#patchAttributes
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PdfVersions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.PdfVersions#modelName
        * @propertyOf lbServices.PdfVersions
        * @description
        * The name of the model represented by this $resource,
        * i.e. `PdfVersions`.
        */
        R.modelName = "PdfVersions";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.PermissionCategories
 * @header lbServices.PermissionCategories
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `PermissionCategories` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "PermissionCategories",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/PermissionCategories/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use PermissionCategories.Permissions.findById() instead.
            "prototype$__findById__Permissions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionCategories/:id/Permissions/:fk",
              method: "GET",
            },

            // INTERNAL. Use PermissionCategories.Permissions.destroyById() instead.
            "prototype$__destroyById__Permissions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionCategories/:id/Permissions/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use PermissionCategories.Permissions.updateById() instead.
            "prototype$__updateById__Permissions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionCategories/:id/Permissions/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#__get__notDeleted
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Queries notDeleted of PermissionCategories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/PermissionCategories/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#__create__notDeleted
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/PermissionCategories/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#__delete__notDeleted
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/PermissionCategories/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#__count__notDeleted
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Counts notDeleted of PermissionCategories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/PermissionCategories/notDeleted/count",
              method: "GET",
            },

            // INTERNAL. Use PermissionCategories.Permissions() instead.
            "prototype$__get__Permissions": {
              isArray: true,
              url: urlBase + "/PermissionCategories/:id/Permissions",
              method: "GET",
            },

            // INTERNAL. Use PermissionCategories.Permissions.create() instead.
            "prototype$__create__Permissions": {
              url: urlBase + "/PermissionCategories/:id/Permissions",
              method: "POST",
            },

            // INTERNAL. Use PermissionCategories.Permissions.destroyAll() instead.
            "prototype$__delete__Permissions": {
              url: urlBase + "/PermissionCategories/:id/Permissions",
              method: "DELETE",
            },

            // INTERNAL. Use PermissionCategories.Permissions.count() instead.
            "prototype$__count__Permissions": {
              url: urlBase + "/PermissionCategories/:id/Permissions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#create
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/PermissionCategories",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#createMany
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/PermissionCategories",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#upsert
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/PermissionCategories",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#replaceOrCreate
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/PermissionCategories/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#upsertWithWhere
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/PermissionCategories/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#exists
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/PermissionCategories/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#findById
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/PermissionCategories/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#replaceById
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/PermissionCategories/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#find
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/PermissionCategories",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#findOne
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/PermissionCategories/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#updateAll
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/PermissionCategories/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#deleteById
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/PermissionCategories/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#count
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/PermissionCategories/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#prototype$updateAttributes
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionCategories id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/PermissionCategories/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#createChangeStream
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/PermissionCategories/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Permissions.PermissionCategories() instead.
            "::get::Permissions::PermissionCategories": {
              url: urlBase + "/Permissions/:id/PermissionCategories",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#patchOrCreate
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#updateOrCreate
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#patchOrCreateWithWhere
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#update
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#destroyById
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#removeById
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#patchAttributes
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionCategories id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.PermissionCategories#modelName
        * @propertyOf lbServices.PermissionCategories
        * @description
        * The name of the model represented by this $resource,
        * i.e. `PermissionCategories`.
        */
        R.modelName = "PermissionCategories";

    /**
     * @ngdoc object
     * @name lbServices.PermissionCategories.Permissions
     * @header lbServices.PermissionCategories.Permissions
     * @object
     * @description
     *
     * The object `PermissionCategories.Permissions` groups methods
     * manipulating `Permissions` instances related to `PermissionCategories`.
     *
     * Call {@link lbServices.PermissionCategories#Permissions PermissionCategories.Permissions()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#Permissions
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Queries Permissions of PermissionCategories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionCategories id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R.Permissions = function() {
          var TargetResource = $injector.get("Permissions");
          var action = TargetResource["::get::PermissionCategories::Permissions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories.Permissions#count
             * @methodOf lbServices.PermissionCategories.Permissions
             *
             * @description
             *
             * Counts Permissions of PermissionCategories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionCategories id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Permissions.count = function() {
          var TargetResource = $injector.get("Permissions");
          var action = TargetResource["::count::PermissionCategories::Permissions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories.Permissions#create
             * @methodOf lbServices.PermissionCategories.Permissions
             *
             * @description
             *
             * Creates a new instance in Permissions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionCategories id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R.Permissions.create = function() {
          var TargetResource = $injector.get("Permissions");
          var action = TargetResource["::create::PermissionCategories::Permissions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories.Permissions#createMany
             * @methodOf lbServices.PermissionCategories.Permissions
             *
             * @description
             *
             * Creates a new instance in Permissions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionCategories id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R.Permissions.createMany = function() {
          var TargetResource = $injector.get("Permissions");
          var action = TargetResource["::createMany::PermissionCategories::Permissions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories.Permissions#destroyAll
             * @methodOf lbServices.PermissionCategories.Permissions
             *
             * @description
             *
             * Deletes all Permissions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionCategories id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Permissions.destroyAll = function() {
          var TargetResource = $injector.get("Permissions");
          var action = TargetResource["::delete::PermissionCategories::Permissions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories.Permissions#destroyById
             * @methodOf lbServices.PermissionCategories.Permissions
             *
             * @description
             *
             * Delete a related item by id for Permissions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionCategories id
             *
             *  - `fk` – `{*}` - Foreign key for Permissions
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Permissions.destroyById = function() {
          var TargetResource = $injector.get("Permissions");
          var action = TargetResource["::destroyById::PermissionCategories::Permissions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories.Permissions#findById
             * @methodOf lbServices.PermissionCategories.Permissions
             *
             * @description
             *
             * Find a related item by id for Permissions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionCategories id
             *
             *  - `fk` – `{*}` - Foreign key for Permissions
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R.Permissions.findById = function() {
          var TargetResource = $injector.get("Permissions");
          var action = TargetResource["::findById::PermissionCategories::Permissions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories.Permissions#updateById
             * @methodOf lbServices.PermissionCategories.Permissions
             *
             * @description
             *
             * Update a related item by id for Permissions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionCategories id
             *
             *  - `fk` – `{*}` - Foreign key for Permissions
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R.Permissions.updateById = function() {
          var TargetResource = $injector.get("Permissions");
          var action = TargetResource["::updateById::PermissionCategories::Permissions"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.PermissionGroupJoin
 * @header lbServices.PermissionGroupJoin
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `PermissionGroupJoin` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "PermissionGroupJoin",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/PermissionGroupJoins/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use PermissionGroupJoin.Permissions() instead.
            "prototype$__get__Permissions": {
              url: urlBase + "/PermissionGroupJoins/:id/Permissions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#create
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/PermissionGroupJoins",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#createMany
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/PermissionGroupJoins",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#upsert
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/PermissionGroupJoins",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#replaceOrCreate
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/PermissionGroupJoins/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#upsertWithWhere
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/PermissionGroupJoins/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#exists
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/PermissionGroupJoins/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#findById
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/PermissionGroupJoins/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#replaceById
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/PermissionGroupJoins/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#find
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/PermissionGroupJoins",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#findOne
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/PermissionGroupJoins/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#updateAll
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/PermissionGroupJoins/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#deleteById
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/PermissionGroupJoins/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#count
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/PermissionGroupJoins/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#prototype$updateAttributes
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroupJoin id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/PermissionGroupJoins/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#createChangeStream
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/PermissionGroupJoins/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#markPermissionGroupJoinAsDeleted
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Get list of books in search with count
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "markPermissionGroupJoinAsDeleted": {
              url: urlBase + "/PermissionGroupJoins/markPermissionGroupJoinAsDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#assignPermissions
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * remove existing joins for current permission group and set new ones
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "assignPermissions": {
              url: urlBase + "/PermissionGroupJoins/assignPermissions",
              method: "POST",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.findById() instead.
            "::findById::PermissionGroups::PermissionGroupJoin": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin/:fk",
              method: "GET",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.destroyById() instead.
            "::destroyById::PermissionGroups::PermissionGroupJoin": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.updateById() instead.
            "::updateById::PermissionGroups::PermissionGroupJoin": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin/:fk",
              method: "PUT",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin() instead.
            "::get::PermissionGroups::PermissionGroupJoin": {
              isArray: true,
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin",
              method: "GET",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.create() instead.
            "::create::PermissionGroups::PermissionGroupJoin": {
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin",
              method: "POST",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.createMany() instead.
            "::createMany::PermissionGroups::PermissionGroupJoin": {
              isArray: true,
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin",
              method: "POST",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.destroyAll() instead.
            "::delete::PermissionGroups::PermissionGroupJoin": {
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin",
              method: "DELETE",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.count() instead.
            "::count::PermissionGroups::PermissionGroupJoin": {
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin/count",
              method: "GET",
            },

            // INTERNAL. Use Permissions.PermissionGroupJoin() instead.
            "::get::Permissions::PermissionGroupJoin": {
              url: urlBase + "/Permissions/:id/PermissionGroupJoin",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#patchOrCreate
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#updateOrCreate
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#patchOrCreateWithWhere
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#update
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#destroyById
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#removeById
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#patchAttributes
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroupJoin id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.PermissionGroupJoin#modelName
        * @propertyOf lbServices.PermissionGroupJoin
        * @description
        * The name of the model represented by this $resource,
        * i.e. `PermissionGroupJoin`.
        */
        R.modelName = "PermissionGroupJoin";


            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#Permissions
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Fetches belongsTo relation Permissions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroupJoin id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R.Permissions = function() {
          var TargetResource = $injector.get("Permissions");
          var action = TargetResource["::get::PermissionGroupJoin::Permissions"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.PermissionGroups
 * @header lbServices.PermissionGroups
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `PermissionGroups` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "PermissionGroups",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/PermissionGroups/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.findById() instead.
            "prototype$__findById__PermissionGroupJoin": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin/:fk",
              method: "GET",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.destroyById() instead.
            "prototype$__destroyById__PermissionGroupJoin": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.updateById() instead.
            "prototype$__updateById__PermissionGroupJoin": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin/:fk",
              method: "PUT",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin() instead.
            "prototype$__get__PermissionGroupJoin": {
              isArray: true,
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin",
              method: "GET",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.create() instead.
            "prototype$__create__PermissionGroupJoin": {
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin",
              method: "POST",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.destroyAll() instead.
            "prototype$__delete__PermissionGroupJoin": {
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin",
              method: "DELETE",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.count() instead.
            "prototype$__count__PermissionGroupJoin": {
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#__get__notDeleted
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Queries notDeleted of PermissionGroups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/PermissionGroups/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#__create__notDeleted
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/PermissionGroups/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#__delete__notDeleted
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/PermissionGroups/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#__count__notDeleted
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Counts notDeleted of PermissionGroups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/PermissionGroups/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#create
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/PermissionGroups",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#createMany
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/PermissionGroups",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#upsert
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/PermissionGroups",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#replaceOrCreate
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/PermissionGroups/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#upsertWithWhere
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/PermissionGroups/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#exists
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/PermissionGroups/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#findById
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/PermissionGroups/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#replaceById
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/PermissionGroups/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#find
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/PermissionGroups",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#findOne
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/PermissionGroups/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#updateAll
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/PermissionGroups/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#deleteById
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/PermissionGroups/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#count
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/PermissionGroups/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#prototype$updateAttributes
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroups id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/PermissionGroups/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#createChangeStream
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/PermissionGroups/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Users.PermissionGroups() instead.
            "::get::Users::PermissionGroups": {
              url: urlBase + "/Users/:id/PermissionGroups",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#patchOrCreate
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#updateOrCreate
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#patchOrCreateWithWhere
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#update
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#destroyById
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#removeById
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#patchAttributes
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroups id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.PermissionGroups#modelName
        * @propertyOf lbServices.PermissionGroups
        * @description
        * The name of the model represented by this $resource,
        * i.e. `PermissionGroups`.
        */
        R.modelName = "PermissionGroups";

    /**
     * @ngdoc object
     * @name lbServices.PermissionGroups.PermissionGroupJoin
     * @header lbServices.PermissionGroups.PermissionGroupJoin
     * @object
     * @description
     *
     * The object `PermissionGroups.PermissionGroupJoin` groups methods
     * manipulating `PermissionGroupJoin` instances related to `PermissionGroups`.
     *
     * Call {@link lbServices.PermissionGroups#PermissionGroupJoin PermissionGroups.PermissionGroupJoin()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#PermissionGroupJoin
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Queries PermissionGroupJoin of PermissionGroups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroups id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R.PermissionGroupJoin = function() {
          var TargetResource = $injector.get("PermissionGroupJoin");
          var action = TargetResource["::get::PermissionGroups::PermissionGroupJoin"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups.PermissionGroupJoin#count
             * @methodOf lbServices.PermissionGroups.PermissionGroupJoin
             *
             * @description
             *
             * Counts PermissionGroupJoin of PermissionGroups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroups id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.PermissionGroupJoin.count = function() {
          var TargetResource = $injector.get("PermissionGroupJoin");
          var action = TargetResource["::count::PermissionGroups::PermissionGroupJoin"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups.PermissionGroupJoin#create
             * @methodOf lbServices.PermissionGroups.PermissionGroupJoin
             *
             * @description
             *
             * Creates a new instance in PermissionGroupJoin of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroups id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R.PermissionGroupJoin.create = function() {
          var TargetResource = $injector.get("PermissionGroupJoin");
          var action = TargetResource["::create::PermissionGroups::PermissionGroupJoin"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups.PermissionGroupJoin#createMany
             * @methodOf lbServices.PermissionGroups.PermissionGroupJoin
             *
             * @description
             *
             * Creates a new instance in PermissionGroupJoin of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroups id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R.PermissionGroupJoin.createMany = function() {
          var TargetResource = $injector.get("PermissionGroupJoin");
          var action = TargetResource["::createMany::PermissionGroups::PermissionGroupJoin"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups.PermissionGroupJoin#destroyAll
             * @methodOf lbServices.PermissionGroups.PermissionGroupJoin
             *
             * @description
             *
             * Deletes all PermissionGroupJoin of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroups id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.PermissionGroupJoin.destroyAll = function() {
          var TargetResource = $injector.get("PermissionGroupJoin");
          var action = TargetResource["::delete::PermissionGroups::PermissionGroupJoin"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups.PermissionGroupJoin#destroyById
             * @methodOf lbServices.PermissionGroups.PermissionGroupJoin
             *
             * @description
             *
             * Delete a related item by id for PermissionGroupJoin.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroups id
             *
             *  - `fk` – `{*}` - Foreign key for PermissionGroupJoin
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.PermissionGroupJoin.destroyById = function() {
          var TargetResource = $injector.get("PermissionGroupJoin");
          var action = TargetResource["::destroyById::PermissionGroups::PermissionGroupJoin"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups.PermissionGroupJoin#findById
             * @methodOf lbServices.PermissionGroups.PermissionGroupJoin
             *
             * @description
             *
             * Find a related item by id for PermissionGroupJoin.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroups id
             *
             *  - `fk` – `{*}` - Foreign key for PermissionGroupJoin
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R.PermissionGroupJoin.findById = function() {
          var TargetResource = $injector.get("PermissionGroupJoin");
          var action = TargetResource["::findById::PermissionGroups::PermissionGroupJoin"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups.PermissionGroupJoin#updateById
             * @methodOf lbServices.PermissionGroups.PermissionGroupJoin
             *
             * @description
             *
             * Update a related item by id for PermissionGroupJoin.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroups id
             *
             *  - `fk` – `{*}` - Foreign key for PermissionGroupJoin
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R.PermissionGroupJoin.updateById = function() {
          var TargetResource = $injector.get("PermissionGroupJoin");
          var action = TargetResource["::updateById::PermissionGroups::PermissionGroupJoin"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Permissions
 * @header lbServices.Permissions
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Permissions` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Permissions",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Permissions/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Permissions.PermissionGroupJoin() instead.
            "prototype$__get__PermissionGroupJoin": {
              url: urlBase + "/Permissions/:id/PermissionGroupJoin",
              method: "GET",
            },

            // INTERNAL. Use Permissions.PermissionCategories() instead.
            "prototype$__get__PermissionCategories": {
              url: urlBase + "/Permissions/:id/PermissionCategories",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#__get__notDeleted
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Queries notDeleted of Permissions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Permissions/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#__create__notDeleted
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Permissions/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#__delete__notDeleted
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Permissions/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#__count__notDeleted
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Counts notDeleted of Permissions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Permissions/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#create
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Permissions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#createMany
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Permissions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#upsert
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Permissions",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#replaceOrCreate
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Permissions/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#upsertWithWhere
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Permissions/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#exists
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Permissions/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#findById
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Permissions/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#replaceById
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Permissions/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#find
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Permissions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#findOne
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Permissions/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#updateAll
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Permissions/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#deleteById
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Permissions/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#count
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Permissions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#prototype$updateAttributes
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Permissions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Permissions/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#createChangeStream
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Permissions/change-stream",
              method: "POST",
            },

            // INTERNAL. Use PermissionCategories.Permissions.findById() instead.
            "::findById::PermissionCategories::Permissions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionCategories/:id/Permissions/:fk",
              method: "GET",
            },

            // INTERNAL. Use PermissionCategories.Permissions.destroyById() instead.
            "::destroyById::PermissionCategories::Permissions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionCategories/:id/Permissions/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use PermissionCategories.Permissions.updateById() instead.
            "::updateById::PermissionCategories::Permissions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionCategories/:id/Permissions/:fk",
              method: "PUT",
            },

            // INTERNAL. Use PermissionCategories.Permissions() instead.
            "::get::PermissionCategories::Permissions": {
              isArray: true,
              url: urlBase + "/PermissionCategories/:id/Permissions",
              method: "GET",
            },

            // INTERNAL. Use PermissionCategories.Permissions.create() instead.
            "::create::PermissionCategories::Permissions": {
              url: urlBase + "/PermissionCategories/:id/Permissions",
              method: "POST",
            },

            // INTERNAL. Use PermissionCategories.Permissions.createMany() instead.
            "::createMany::PermissionCategories::Permissions": {
              isArray: true,
              url: urlBase + "/PermissionCategories/:id/Permissions",
              method: "POST",
            },

            // INTERNAL. Use PermissionCategories.Permissions.destroyAll() instead.
            "::delete::PermissionCategories::Permissions": {
              url: urlBase + "/PermissionCategories/:id/Permissions",
              method: "DELETE",
            },

            // INTERNAL. Use PermissionCategories.Permissions.count() instead.
            "::count::PermissionCategories::Permissions": {
              url: urlBase + "/PermissionCategories/:id/Permissions/count",
              method: "GET",
            },

            // INTERNAL. Use PermissionGroupJoin.Permissions() instead.
            "::get::PermissionGroupJoin::Permissions": {
              url: urlBase + "/PermissionGroupJoins/:id/Permissions",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Permissions#patchOrCreate
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Permissions#updateOrCreate
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Permissions#patchOrCreateWithWhere
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Permissions#update
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Permissions#destroyById
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Permissions#removeById
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Permissions#patchAttributes
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Permissions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Permissions#modelName
        * @propertyOf lbServices.Permissions
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Permissions`.
        */
        R.modelName = "Permissions";


            /**
             * @ngdoc method
             * @name lbServices.Permissions#PermissionGroupJoin
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Fetches belongsTo relation PermissionGroupJoin.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Permissions id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R.PermissionGroupJoin = function() {
          var TargetResource = $injector.get("PermissionGroupJoin");
          var action = TargetResource["::get::Permissions::PermissionGroupJoin"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Permissions#PermissionCategories
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Fetches belongsTo relation PermissionCategories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Permissions id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
        R.PermissionCategories = function() {
          var TargetResource = $injector.get("PermissionCategories");
          var action = TargetResource["::get::Permissions::PermissionCategories"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ProductIds
 * @header lbServices.ProductIds
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ProductIds` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ProductIds",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ProductIds/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use ProductIds.Insurers() instead.
            "prototype$__get__Insurers": {
              url: urlBase + "/ProductIds/:id/Insurers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#__get__notDeleted
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Queries notDeleted of ProductIds.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/ProductIds/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#__create__notDeleted
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/ProductIds/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#__delete__notDeleted
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/ProductIds/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#__count__notDeleted
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Counts notDeleted of ProductIds.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/ProductIds/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#create
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ProductIds",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#createMany
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ProductIds",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#upsert
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/ProductIds",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#replaceOrCreate
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ProductIds/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#upsertWithWhere
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ProductIds/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#exists
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ProductIds/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#findById
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ProductIds/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#replaceById
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ProductIds/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#find
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ProductIds",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#findOne
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ProductIds/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#updateAll
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ProductIds/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#deleteById
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ProductIds/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#count
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ProductIds/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#prototype$updateAttributes
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProductIds id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/ProductIds/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#createChangeStream
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ProductIds/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Insurers.ProductIds.findById() instead.
            "::findById::Insurers::ProductIds": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Insurers/:id/ProductIds/:fk",
              method: "GET",
            },

            // INTERNAL. Use Insurers.ProductIds.destroyById() instead.
            "::destroyById::Insurers::ProductIds": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Insurers/:id/ProductIds/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Insurers.ProductIds.updateById() instead.
            "::updateById::Insurers::ProductIds": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Insurers/:id/ProductIds/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Insurers.ProductIds() instead.
            "::get::Insurers::ProductIds": {
              isArray: true,
              url: urlBase + "/Insurers/:id/ProductIds",
              method: "GET",
            },

            // INTERNAL. Use Insurers.ProductIds.create() instead.
            "::create::Insurers::ProductIds": {
              url: urlBase + "/Insurers/:id/ProductIds",
              method: "POST",
            },

            // INTERNAL. Use Insurers.ProductIds.createMany() instead.
            "::createMany::Insurers::ProductIds": {
              isArray: true,
              url: urlBase + "/Insurers/:id/ProductIds",
              method: "POST",
            },

            // INTERNAL. Use Insurers.ProductIds.destroyAll() instead.
            "::delete::Insurers::ProductIds": {
              url: urlBase + "/Insurers/:id/ProductIds",
              method: "DELETE",
            },

            // INTERNAL. Use Insurers.ProductIds.count() instead.
            "::count::Insurers::ProductIds": {
              url: urlBase + "/Insurers/:id/ProductIds/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ProductIds#patchOrCreate
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#updateOrCreate
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#patchOrCreateWithWhere
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#update
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#destroyById
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#removeById
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#patchAttributes
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProductIds id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ProductIds#modelName
        * @propertyOf lbServices.ProductIds
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ProductIds`.
        */
        R.modelName = "ProductIds";


            /**
             * @ngdoc method
             * @name lbServices.ProductIds#Insurers
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Fetches belongsTo relation Insurers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProductIds id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
        R.Insurers = function() {
          var TargetResource = $injector.get("Insurers");
          var action = TargetResource["::get::ProductIds::Insurers"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ProductIdsBookLink
 * @header lbServices.ProductIdsBookLink
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ProductIdsBookLink` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ProductIdsBookLink",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ProductIdsBookLinks/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#__get__notDeleted
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Queries notDeleted of ProductIdsBookLink.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/ProductIdsBookLinks/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#__create__notDeleted
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/ProductIdsBookLinks/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#__delete__notDeleted
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/ProductIdsBookLinks/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#__count__notDeleted
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Counts notDeleted of ProductIdsBookLink.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/ProductIdsBookLinks/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#create
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ProductIdsBookLinks",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#createMany
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ProductIdsBookLinks",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#upsert
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/ProductIdsBookLinks",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#replaceOrCreate
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ProductIdsBookLinks/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#upsertWithWhere
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ProductIdsBookLinks/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#exists
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ProductIdsBookLinks/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#findById
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ProductIdsBookLinks/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#replaceById
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ProductIdsBookLinks/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#find
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ProductIdsBookLinks",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#findOne
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ProductIdsBookLinks/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#updateAll
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ProductIdsBookLinks/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#deleteById
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ProductIdsBookLinks/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#count
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ProductIdsBookLinks/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#prototype$updateAttributes
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProductIdsBookLink id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/ProductIdsBookLinks/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#createChangeStream
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ProductIdsBookLinks/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#setStatusByBookId
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Set Product IDs status to 0 based on book id
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "setStatusByBookId": {
              isArray: true,
              url: urlBase + "/ProductIdsBookLinks/setStatusByBookId",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#duplicateByBookId
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Set Product IDs status to 0 based on book id
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "duplicateByBookId": {
              isArray: true,
              url: urlBase + "/ProductIdsBookLinks/duplicateByBookId",
              method: "POST",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.findById() instead.
            "::findById::Books::ProductIdsBookLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/ProductIdsBookLink/:fk",
              method: "GET",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.destroyById() instead.
            "::destroyById::Books::ProductIdsBookLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/ProductIdsBookLink/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.updateById() instead.
            "::updateById::Books::ProductIdsBookLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/ProductIdsBookLink/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Books.ProductIdsBookLink() instead.
            "::get::Books::ProductIdsBookLink": {
              isArray: true,
              url: urlBase + "/Books/:id/ProductIdsBookLink",
              method: "GET",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.create() instead.
            "::create::Books::ProductIdsBookLink": {
              url: urlBase + "/Books/:id/ProductIdsBookLink",
              method: "POST",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.createMany() instead.
            "::createMany::Books::ProductIdsBookLink": {
              isArray: true,
              url: urlBase + "/Books/:id/ProductIdsBookLink",
              method: "POST",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.destroyAll() instead.
            "::delete::Books::ProductIdsBookLink": {
              url: urlBase + "/Books/:id/ProductIdsBookLink",
              method: "DELETE",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.count() instead.
            "::count::Books::ProductIdsBookLink": {
              url: urlBase + "/Books/:id/ProductIdsBookLink/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#patchOrCreate
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#updateOrCreate
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#patchOrCreateWithWhere
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#update
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#destroyById
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#removeById
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#patchAttributes
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProductIdsBookLink id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ProductIdsBookLink#modelName
        * @propertyOf lbServices.ProductIdsBookLink
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ProductIdsBookLink`.
        */
        R.modelName = "ProductIdsBookLink";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Products
 * @header lbServices.Products
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Products` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Products",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Products/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Products#__get__notDeleted
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Queries notDeleted of Products.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Products/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#__create__notDeleted
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Products/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#__delete__notDeleted
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Products/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#__count__notDeleted
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Counts notDeleted of Products.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Products/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#create
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Products",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#createMany
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Products",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#upsert
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Products",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#replaceOrCreate
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Products/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#upsertWithWhere
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Products/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#exists
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Products/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#findById
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Products/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#replaceById
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Products/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#find
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Products",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#findOne
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Products/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#updateAll
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Products/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#deleteById
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Products/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#count
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Products/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#prototype$updateAttributes
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Products id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Products/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#createChangeStream
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Products/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#getProductsAndDocCount
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Get a list Products and a count of documents associated with them
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "getProductsAndDocCount": {
              isArray: true,
              url: urlBase + "/Products/getProductsAndDocCount",
              method: "POST",
            },

            // INTERNAL. Use Books.Products() instead.
            "::get::Books::Products": {
              url: urlBase + "/Books/:id/Products",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Products#patchOrCreate
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Products#updateOrCreate
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Products#patchOrCreateWithWhere
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Products#update
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Products#destroyById
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Products#removeById
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Products#patchAttributes
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Products id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Products#modelName
        * @propertyOf lbServices.Products
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Products`.
        */
        R.modelName = "Products";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ProductTypes
 * @header lbServices.ProductTypes
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ProductTypes` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ProductTypes",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ProductTypes/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#__get__notDeleted
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Queries notDeleted of ProductTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/ProductTypes/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#__create__notDeleted
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/ProductTypes/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#__delete__notDeleted
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/ProductTypes/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#__count__notDeleted
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Counts notDeleted of ProductTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/ProductTypes/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#create
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ProductTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#createMany
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ProductTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#upsert
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/ProductTypes",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#replaceOrCreate
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ProductTypes/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#upsertWithWhere
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ProductTypes/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#exists
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ProductTypes/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#findById
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ProductTypes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#replaceById
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ProductTypes/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#find
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ProductTypes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#findOne
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ProductTypes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#updateAll
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ProductTypes/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#deleteById
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ProductTypes/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#count
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ProductTypes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#prototype$updateAttributes
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProductTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/ProductTypes/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#createChangeStream
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ProductTypes/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Books.ProductTypes() instead.
            "::get::Books::ProductTypes": {
              url: urlBase + "/Books/:id/ProductTypes",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#patchOrCreate
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#updateOrCreate
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#patchOrCreateWithWhere
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#update
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#destroyById
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#removeById
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#patchAttributes
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProductTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ProductTypes#modelName
        * @propertyOf lbServices.ProductTypes
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ProductTypes`.
        */
        R.modelName = "ProductTypes";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Projects
 * @header lbServices.Projects
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Projects` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Projects",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Projects/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Projects#__get__notDeleted
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Queries notDeleted of Projects.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Projects/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#__create__notDeleted
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Projects/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#__delete__notDeleted
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Projects/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#__count__notDeleted
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Counts notDeleted of Projects.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Projects/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#create
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Projects",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#createMany
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Projects",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#upsert
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Projects",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#replaceOrCreate
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Projects/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#upsertWithWhere
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Projects/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#exists
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Projects/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#findById
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Projects/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#replaceById
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Projects/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#find
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Projects",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#findOne
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Projects/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#updateAll
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Projects/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#deleteById
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Projects/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#count
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Projects/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#prototype$updateAttributes
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Projects id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Projects/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#createChangeStream
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Projects/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Books.Projects() instead.
            "::get::Books::Projects": {
              url: urlBase + "/Books/:id/Projects",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Projects#patchOrCreate
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Projects#updateOrCreate
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Projects#patchOrCreateWithWhere
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Projects#update
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Projects#destroyById
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Projects#removeById
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Projects#patchAttributes
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Projects id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Projects#modelName
        * @propertyOf lbServices.Projects
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Projects`.
        */
        R.modelName = "Projects";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.PublishTypes
 * @header lbServices.PublishTypes
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `PublishTypes` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "PublishTypes",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/PublishTypes/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#__get__notDeleted
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Queries notDeleted of PublishTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/PublishTypes/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#__create__notDeleted
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/PublishTypes/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#__delete__notDeleted
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/PublishTypes/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#__count__notDeleted
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Counts notDeleted of PublishTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/PublishTypes/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#create
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/PublishTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#createMany
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/PublishTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#upsert
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/PublishTypes",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#replaceOrCreate
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/PublishTypes/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#upsertWithWhere
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/PublishTypes/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#exists
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/PublishTypes/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#findById
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/PublishTypes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#replaceById
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/PublishTypes/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#find
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/PublishTypes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#findOne
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/PublishTypes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#updateAll
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/PublishTypes/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#deleteById
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/PublishTypes/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#count
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/PublishTypes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#prototype$updateAttributes
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PublishTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/PublishTypes/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#createChangeStream
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/PublishTypes/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#patchOrCreate
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#updateOrCreate
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#patchOrCreateWithWhere
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#update
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#destroyById
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#removeById
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#patchAttributes
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PublishTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.PublishTypes#modelName
        * @propertyOf lbServices.PublishTypes
        * @description
        * The name of the model represented by this $resource,
        * i.e. `PublishTypes`.
        */
        R.modelName = "PublishTypes";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Regions
 * @header lbServices.Regions
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Regions` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Regions",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Regions/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Regions#__get__notDeleted
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Queries notDeleted of Regions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Regions/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#__create__notDeleted
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Regions/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#__delete__notDeleted
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Regions/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#__count__notDeleted
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Counts notDeleted of Regions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Regions/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#create
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Regions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#createMany
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Regions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#upsert
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Regions",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#replaceOrCreate
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Regions/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#upsertWithWhere
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Regions/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#exists
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Regions/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#findById
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Regions/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#replaceById
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Regions/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#find
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Regions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#findOne
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Regions/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#updateAll
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Regions/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#deleteById
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Regions/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#count
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Regions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#prototype$updateAttributes
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Regions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Regions/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#createChangeStream
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Regions/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Books.Regions() instead.
            "::get::Books::Regions": {
              url: urlBase + "/Books/:id/Regions",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Regions#patchOrCreate
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Regions#updateOrCreate
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Regions#patchOrCreateWithWhere
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Regions#update
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Regions#destroyById
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Regions#removeById
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Regions#patchAttributes
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Regions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Regions#modelName
        * @propertyOf lbServices.Regions
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Regions`.
        */
        R.modelName = "Regions";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Reports
 * @header lbServices.Reports
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Reports` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Reports",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Reports/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Reports#create
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Reports",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#createMany
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Reports",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#upsert
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Reports",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#replaceOrCreate
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Reports/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#upsertWithWhere
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Reports/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#exists
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Reports/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#findById
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Reports/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#replaceById
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Reports/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#find
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Reports",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#findOne
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Reports/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#updateAll
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Reports/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#deleteById
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Reports/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#count
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Reports/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#prototype$updateAttributes
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Reports id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Reports/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#createChangeStream
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Reports/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#createReport
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Process 
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "createReport": {
              isArray: true,
              url: urlBase + "/Reports/createReport",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#sendReport
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Process 
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "sendReport": {
              isArray: true,
              url: urlBase + "/Reports/sendReport",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Reports#patchOrCreate
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Reports#updateOrCreate
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Reports#patchOrCreateWithWhere
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Reports#update
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Reports#destroyById
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Reports#removeById
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Reports#patchAttributes
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Reports id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Reports#modelName
        * @propertyOf lbServices.Reports
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Reports`.
        */
        R.modelName = "Reports";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.RfcCategories
 * @header lbServices.RfcCategories
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `RfcCategories` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "RfcCategories",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/RfcCategories/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#create
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/RfcCategories",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#createMany
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/RfcCategories",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#upsert
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/RfcCategories",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#replaceOrCreate
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/RfcCategories/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#upsertWithWhere
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/RfcCategories/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#exists
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/RfcCategories/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#findById
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/RfcCategories/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#replaceById
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/RfcCategories/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#find
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/RfcCategories",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#findOne
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/RfcCategories/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#updateAll
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/RfcCategories/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#deleteById
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/RfcCategories/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#count
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/RfcCategories/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#prototype$updateAttributes
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - RfcCategories id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/RfcCategories/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#createChangeStream
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/RfcCategories/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#patchOrCreate
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#updateOrCreate
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#patchOrCreateWithWhere
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#update
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#destroyById
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#removeById
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#patchAttributes
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - RfcCategories id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.RfcCategories#modelName
        * @propertyOf lbServices.RfcCategories
        * @description
        * The name of the model represented by this $resource,
        * i.e. `RfcCategories`.
        */
        R.modelName = "RfcCategories";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.RfcJoin
 * @header lbServices.RfcJoin
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `RfcJoin` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "RfcJoin",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/RfcJoins/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#create
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/RfcJoins",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#createMany
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/RfcJoins",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#upsert
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/RfcJoins",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#replaceOrCreate
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/RfcJoins/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#upsertWithWhere
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/RfcJoins/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#exists
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/RfcJoins/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#findById
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/RfcJoins/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#replaceById
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/RfcJoins/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#find
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/RfcJoins",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#findOne
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/RfcJoins/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#updateAll
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/RfcJoins/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#deleteById
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/RfcJoins/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#count
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/RfcJoins/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#prototype$updateAttributes
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - RfcJoin id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/RfcJoins/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#createChangeStream
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/RfcJoins/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#patchOrCreate
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#updateOrCreate
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#patchOrCreateWithWhere
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#update
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#destroyById
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#removeById
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#patchAttributes
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - RfcJoin id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.RfcJoin#modelName
        * @propertyOf lbServices.RfcJoin
        * @description
        * The name of the model represented by this $resource,
        * i.e. `RfcJoin`.
        */
        R.modelName = "RfcJoin";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.RfcReasons
 * @header lbServices.RfcReasons
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `RfcReasons` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "RfcReasons",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/RfcReasons/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#create
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/RfcReasons",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#createMany
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/RfcReasons",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#upsert
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/RfcReasons",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#replaceOrCreate
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/RfcReasons/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#upsertWithWhere
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/RfcReasons/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#exists
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/RfcReasons/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#findById
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/RfcReasons/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#replaceById
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/RfcReasons/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#find
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/RfcReasons",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#findOne
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/RfcReasons/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#updateAll
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/RfcReasons/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#deleteById
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/RfcReasons/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#count
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/RfcReasons/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#prototype$updateAttributes
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - RfcReasons id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/RfcReasons/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#createChangeStream
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/RfcReasons/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#patchOrCreate
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#updateOrCreate
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#patchOrCreateWithWhere
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#update
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#destroyById
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#removeById
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#patchAttributes
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - RfcReasons id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.RfcReasons#modelName
        * @propertyOf lbServices.RfcReasons
        * @description
        * The name of the model represented by this $resource,
        * i.e. `RfcReasons`.
        */
        R.modelName = "RfcReasons";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Roles
 * @header lbServices.Roles
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Roles` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Roles",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Roles/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Roles#prototype$__findById__principals
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Find a related item by id for principals.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `fk` – `{*}` - Foreign key for principals
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "prototype$__findById__principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/principals/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#prototype$__destroyById__principals
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Delete a related item by id for principals.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `fk` – `{*}` - Foreign key for principals
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/principals/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#prototype$__updateById__principals
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Update a related item by id for principals.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `fk` – `{*}` - Foreign key for principals
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "prototype$__updateById__principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/principals/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Roles.Users.findById() instead.
            "prototype$__findById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Roles.Users.destroyById() instead.
            "prototype$__destroyById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Roles.Users.updateById() instead.
            "prototype$__updateById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/Users/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#prototype$__get__principals
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Queries principals of Roles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "prototype$__get__principals": {
              isArray: true,
              url: urlBase + "/Roles/:id/principals",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#prototype$__create__principals
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Creates a new instance in principals of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "prototype$__create__principals": {
              url: urlBase + "/Roles/:id/principals",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#prototype$__delete__principals
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Deletes all principals of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__principals": {
              url: urlBase + "/Roles/:id/principals",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#prototype$__count__principals
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Counts principals of Roles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__principals": {
              url: urlBase + "/Roles/:id/principals/count",
              method: "GET",
            },

            // INTERNAL. Use Roles.Users() instead.
            "prototype$__get__Users": {
              isArray: true,
              url: urlBase + "/Roles/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Roles.Users.create() instead.
            "prototype$__create__Users": {
              url: urlBase + "/Roles/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Roles.Users.destroyAll() instead.
            "prototype$__delete__Users": {
              url: urlBase + "/Roles/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use Roles.Users.count() instead.
            "prototype$__count__Users": {
              url: urlBase + "/Roles/:id/Users/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#create
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Roles",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#createMany
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Roles",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#upsert
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Roles",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#replaceOrCreate
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Roles/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#upsertWithWhere
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Roles/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#exists
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Roles/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#findById
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Roles/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#replaceById
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Roles/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#find
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Roles",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#findOne
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Roles/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#updateAll
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Roles/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#deleteById
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Roles/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#count
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Roles/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#prototype$updateAttributes
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Roles/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#createChangeStream
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Roles/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Users.Roles() instead.
            "::get::Users::Roles": {
              url: urlBase + "/Users/:id/Roles",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Roles#patchOrCreate
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Roles#updateOrCreate
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Roles#patchOrCreateWithWhere
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Roles#update
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Roles#destroyById
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Roles#removeById
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Roles#patchAttributes
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Roles#modelName
        * @propertyOf lbServices.Roles
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Roles`.
        */
        R.modelName = "Roles";

    /**
     * @ngdoc object
     * @name lbServices.Roles.Users
     * @header lbServices.Roles.Users
     * @object
     * @description
     *
     * The object `Roles.Users` groups methods
     * manipulating `Users` instances related to `Roles`.
     *
     * Call {@link lbServices.Roles#Users Roles.Users()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Roles#Users
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Queries Users of Roles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::get::Roles::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Roles.Users#count
             * @methodOf lbServices.Roles.Users
             *
             * @description
             *
             * Counts Users of Roles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Users.count = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::count::Roles::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Roles.Users#create
             * @methodOf lbServices.Roles.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.create = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::create::Roles::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Roles.Users#createMany
             * @methodOf lbServices.Roles.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.createMany = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::createMany::Roles::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Roles.Users#destroyAll
             * @methodOf lbServices.Roles.Users
             *
             * @description
             *
             * Deletes all Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyAll = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::delete::Roles::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Roles.Users#destroyById
             * @methodOf lbServices.Roles.Users
             *
             * @description
             *
             * Delete a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::destroyById::Roles::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Roles.Users#findById
             * @methodOf lbServices.Roles.Users
             *
             * @description
             *
             * Find a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.findById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::findById::Roles::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Roles.Users#updateById
             * @methodOf lbServices.Roles.Users
             *
             * @description
             *
             * Update a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.updateById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::updateById::Roles::Users"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SaveSearch
 * @header lbServices.SaveSearch
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SaveSearch` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SaveSearch",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SaveSearches/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#create
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/SaveSearches",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#createMany
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/SaveSearches",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#upsert
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/SaveSearches",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#replaceOrCreate
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/SaveSearches/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#upsertWithWhere
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/SaveSearches/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#exists
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SaveSearches/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#findById
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SaveSearches/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#replaceById
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/SaveSearches/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#find
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SaveSearches",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#findOne
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/SaveSearches/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#updateAll
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/SaveSearches/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#deleteById
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SaveSearches/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#count
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SaveSearches/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#prototype$updateAttributes
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SaveSearch id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/SaveSearches/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#createChangeStream
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SaveSearches/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#patchOrCreate
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#updateOrCreate
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#patchOrCreateWithWhere
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#update
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#destroyById
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#removeById
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#patchAttributes
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SaveSearch id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.SaveSearch#modelName
        * @propertyOf lbServices.SaveSearch
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SaveSearch`.
        */
        R.modelName = "SaveSearch";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SocialChannels
 * @header lbServices.SocialChannels
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SocialChannels` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SocialChannels",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SocialChannels/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#__get__notDeleted
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Queries notDeleted of SocialChannels.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/SocialChannels/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#__create__notDeleted
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/SocialChannels/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#__delete__notDeleted
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/SocialChannels/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#__count__notDeleted
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Counts notDeleted of SocialChannels.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/SocialChannels/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#create
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/SocialChannels",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#createMany
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/SocialChannels",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#upsert
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/SocialChannels",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#replaceOrCreate
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/SocialChannels/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#upsertWithWhere
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/SocialChannels/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#exists
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SocialChannels/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#findById
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SocialChannels/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#replaceById
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/SocialChannels/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#find
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SocialChannels",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#findOne
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/SocialChannels/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#updateAll
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/SocialChannels/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#deleteById
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SocialChannels/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#count
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SocialChannels/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#prototype$updateAttributes
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SocialChannels id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/SocialChannels/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#createChangeStream
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SocialChannels/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#patchOrCreate
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#updateOrCreate
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#patchOrCreateWithWhere
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#update
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#destroyById
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#removeById
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#patchAttributes
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SocialChannels id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.SocialChannels#modelName
        * @propertyOf lbServices.SocialChannels
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SocialChannels`.
        */
        R.modelName = "SocialChannels";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Structures
 * @header lbServices.Structures
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Structures` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Structures",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Structures/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Structures.Books() instead.
            "prototype$__get__Books": {
              url: urlBase + "/Structures/:id/Books",
              method: "GET",
            },

            // INTERNAL. Use Structures.Contents() instead.
            "prototype$__get__Contents": {
              url: urlBase + "/Structures/:id/Contents",
              method: "GET",
            },

            // INTERNAL. Use Structures.Children.findById() instead.
            "prototype$__findById__Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/Children/:fk",
              method: "GET",
            },

            // INTERNAL. Use Structures.Children.destroyById() instead.
            "prototype$__destroyById__Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/Children/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Structures.Children.updateById() instead.
            "prototype$__updateById__Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/Children/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Structures.History.findById() instead.
            "prototype$__findById__History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/History/:fk",
              method: "GET",
            },

            // INTERNAL. Use Structures.History.destroyById() instead.
            "prototype$__destroyById__History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/History/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Structures.History.updateById() instead.
            "prototype$__updateById__History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/History/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Structures.versions() instead.
            "prototype$__get__versions": {
              url: urlBase + "/Structures/:id/versions",
              method: "GET",
            },

            // INTERNAL. Use Structures.Children() instead.
            "prototype$__get__Children": {
              isArray: true,
              url: urlBase + "/Structures/:id/Children",
              method: "GET",
            },

            // INTERNAL. Use Structures.Children.create() instead.
            "prototype$__create__Children": {
              url: urlBase + "/Structures/:id/Children",
              method: "POST",
            },

            // INTERNAL. Use Structures.Children.destroyAll() instead.
            "prototype$__delete__Children": {
              url: urlBase + "/Structures/:id/Children",
              method: "DELETE",
            },

            // INTERNAL. Use Structures.Children.count() instead.
            "prototype$__count__Children": {
              url: urlBase + "/Structures/:id/Children/count",
              method: "GET",
            },

            // INTERNAL. Use Structures.History() instead.
            "prototype$__get__History": {
              isArray: true,
              url: urlBase + "/Structures/:id/History",
              method: "GET",
            },

            // INTERNAL. Use Structures.History.create() instead.
            "prototype$__create__History": {
              url: urlBase + "/Structures/:id/History",
              method: "POST",
            },

            // INTERNAL. Use Structures.History.destroyAll() instead.
            "prototype$__delete__History": {
              url: urlBase + "/Structures/:id/History",
              method: "DELETE",
            },

            // INTERNAL. Use Structures.History.count() instead.
            "prototype$__count__History": {
              url: urlBase + "/Structures/:id/History/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#create
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Structures",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#createMany
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Structures",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#upsert
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Structures",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#replaceOrCreate
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Structures/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#upsertWithWhere
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Structures/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#exists
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Structures/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#findById
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Structures/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#replaceById
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Structures/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#find
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Structures",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#findOne
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Structures/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#updateAll
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Structures/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#deleteById
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Structures/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#count
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Structures/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#prototype$updateAttributes
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Structures/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#createChangeStream
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Structures/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#duplicateDeep
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `structureId` – `{number=}` -
             */
            "duplicateDeep": {
              url: urlBase + "/Structures/duplicatedeep",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#trash
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Set structure to deleted
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "trash": {
              url: urlBase + "/Structures/trash",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#getChildContent
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Get list of children using bookId and contetId
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "getChildContent": {
              isArray: true,
              url: urlBase + "/Structures/getChildContent",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#getStatuses
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Get list of all book statuses
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "getStatuses": {
              isArray: true,
              url: urlBase + "/Structures/getStatuses",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#updateStructuresAndEvents
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Updates structures and events from csv list of structure ids
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "updateStructuresAndEvents": {
              url: urlBase + "/Structures/updateStructuresAndEvents",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#updateOrder
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Updates structures order
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "updateOrder": {
              url: urlBase + "/Structures/updateOrder",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#getWCR
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Get list of all changes to a book
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "getWCR": {
              isArray: true,
              url: urlBase + "/Structures/getWCR",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#requestDeletion
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Request Document gets deleted
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "requestDeletion": {
              isArray: true,
              url: urlBase + "/Structures/requestDeletion",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#getStatusesAndContent
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Get list of all book statuses with content
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "getStatusesAndContent": {
              isArray: true,
              url: urlBase + "/Structures/getStatusesAndContent",
              method: "POST",
            },

            // INTERNAL. Use Books.Children.findById() instead.
            "::findById::Books::Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Children/:fk",
              method: "GET",
            },

            // INTERNAL. Use Books.Children.destroyById() instead.
            "::destroyById::Books::Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Children/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Children.updateById() instead.
            "::updateById::Books::Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Children/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Books.Structures.findById() instead.
            "::findById::Books::Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Structures/:fk",
              method: "GET",
            },

            // INTERNAL. Use Books.Structures.destroyById() instead.
            "::destroyById::Books::Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Structures/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Structures.updateById() instead.
            "::updateById::Books::Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Structures/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Books.Children() instead.
            "::get::Books::Children": {
              isArray: true,
              url: urlBase + "/Books/:id/Children",
              method: "GET",
            },

            // INTERNAL. Use Books.Children.create() instead.
            "::create::Books::Children": {
              url: urlBase + "/Books/:id/Children",
              method: "POST",
            },

            // INTERNAL. Use Books.Children.createMany() instead.
            "::createMany::Books::Children": {
              isArray: true,
              url: urlBase + "/Books/:id/Children",
              method: "POST",
            },

            // INTERNAL. Use Books.Children.destroyAll() instead.
            "::delete::Books::Children": {
              url: urlBase + "/Books/:id/Children",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Children.count() instead.
            "::count::Books::Children": {
              url: urlBase + "/Books/:id/Children/count",
              method: "GET",
            },

            // INTERNAL. Use Books.Structures() instead.
            "::get::Books::Structures": {
              isArray: true,
              url: urlBase + "/Books/:id/Structures",
              method: "GET",
            },

            // INTERNAL. Use Books.Structures.create() instead.
            "::create::Books::Structures": {
              url: urlBase + "/Books/:id/Structures",
              method: "POST",
            },

            // INTERNAL. Use Books.Structures.createMany() instead.
            "::createMany::Books::Structures": {
              isArray: true,
              url: urlBase + "/Books/:id/Structures",
              method: "POST",
            },

            // INTERNAL. Use Books.Structures.destroyAll() instead.
            "::delete::Books::Structures": {
              url: urlBase + "/Books/:id/Structures",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Structures.count() instead.
            "::count::Books::Structures": {
              url: urlBase + "/Books/:id/Structures/count",
              method: "GET",
            },

            // INTERNAL. Use History.Structures() instead.
            "::get::History::Structures": {
              url: urlBase + "/Histories/:id/Structures",
              method: "GET",
            },

            // INTERNAL. Use OrderHistory.Structures() instead.
            "::get::OrderHistory::Structures": {
              url: urlBase + "/OrderHistories/:id/Structures",
              method: "GET",
            },

            // INTERNAL. Use Structures.Children.findById() instead.
            "::findById::Structures::Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/Children/:fk",
              method: "GET",
            },

            // INTERNAL. Use Structures.Children.destroyById() instead.
            "::destroyById::Structures::Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/Children/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Structures.Children.updateById() instead.
            "::updateById::Structures::Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/Children/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Structures.Children() instead.
            "::get::Structures::Children": {
              isArray: true,
              url: urlBase + "/Structures/:id/Children",
              method: "GET",
            },

            // INTERNAL. Use Structures.Children.create() instead.
            "::create::Structures::Children": {
              url: urlBase + "/Structures/:id/Children",
              method: "POST",
            },

            // INTERNAL. Use Structures.Children.createMany() instead.
            "::createMany::Structures::Children": {
              isArray: true,
              url: urlBase + "/Structures/:id/Children",
              method: "POST",
            },

            // INTERNAL. Use Structures.Children.destroyAll() instead.
            "::delete::Structures::Children": {
              url: urlBase + "/Structures/:id/Children",
              method: "DELETE",
            },

            // INTERNAL. Use Structures.Children.count() instead.
            "::count::Structures::Children": {
              url: urlBase + "/Structures/:id/Children/count",
              method: "GET",
            },

            // INTERNAL. Use Versions.Structures.findById() instead.
            "::findById::Versions::Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/Structures/:fk",
              method: "GET",
            },

            // INTERNAL. Use Versions.Structures.destroyById() instead.
            "::destroyById::Versions::Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/Structures/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Versions.Structures.updateById() instead.
            "::updateById::Versions::Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/Structures/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Versions.Structures() instead.
            "::get::Versions::Structures": {
              isArray: true,
              url: urlBase + "/Versions/:id/Structures",
              method: "GET",
            },

            // INTERNAL. Use Versions.Structures.create() instead.
            "::create::Versions::Structures": {
              url: urlBase + "/Versions/:id/Structures",
              method: "POST",
            },

            // INTERNAL. Use Versions.Structures.createMany() instead.
            "::createMany::Versions::Structures": {
              isArray: true,
              url: urlBase + "/Versions/:id/Structures",
              method: "POST",
            },

            // INTERNAL. Use Versions.Structures.destroyAll() instead.
            "::delete::Versions::Structures": {
              url: urlBase + "/Versions/:id/Structures",
              method: "DELETE",
            },

            // INTERNAL. Use Versions.Structures.count() instead.
            "::count::Versions::Structures": {
              url: urlBase + "/Versions/:id/Structures/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Structures#patchOrCreate
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Structures#updateOrCreate
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Structures#patchOrCreateWithWhere
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Structures#update
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Structures#destroyById
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Structures#removeById
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Structures#patchAttributes
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Structures#modelName
        * @propertyOf lbServices.Structures
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Structures`.
        */
        R.modelName = "Structures";


            /**
             * @ngdoc method
             * @name lbServices.Structures#Books
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Fetches belongsTo relation Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::get::Structures::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures#Contents
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Fetches belongsTo relation Contents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R.Contents = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::get::Structures::Contents"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Structures.Children
     * @header lbServices.Structures.Children
     * @object
     * @description
     *
     * The object `Structures.Children` groups methods
     * manipulating `Structures` instances related to `Structures`.
     *
     * Call {@link lbServices.Structures#Children Structures.Children()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Structures#Children
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Queries Children of Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Children = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::get::Structures::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.Children#count
             * @methodOf lbServices.Structures.Children
             *
             * @description
             *
             * Counts Children of Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Children.count = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::count::Structures::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.Children#create
             * @methodOf lbServices.Structures.Children
             *
             * @description
             *
             * Creates a new instance in Children of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Children.create = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::create::Structures::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.Children#createMany
             * @methodOf lbServices.Structures.Children
             *
             * @description
             *
             * Creates a new instance in Children of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Children.createMany = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::createMany::Structures::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.Children#destroyAll
             * @methodOf lbServices.Structures.Children
             *
             * @description
             *
             * Deletes all Children of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Children.destroyAll = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::delete::Structures::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.Children#destroyById
             * @methodOf lbServices.Structures.Children
             *
             * @description
             *
             * Delete a related item by id for Children.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `fk` – `{*}` - Foreign key for Children
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Children.destroyById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::destroyById::Structures::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.Children#findById
             * @methodOf lbServices.Structures.Children
             *
             * @description
             *
             * Find a related item by id for Children.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `fk` – `{*}` - Foreign key for Children
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Children.findById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::findById::Structures::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.Children#updateById
             * @methodOf lbServices.Structures.Children
             *
             * @description
             *
             * Update a related item by id for Children.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `fk` – `{*}` - Foreign key for Children
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Children.updateById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::updateById::Structures::Children"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Structures.History
     * @header lbServices.Structures.History
     * @object
     * @description
     *
     * The object `Structures.History` groups methods
     * manipulating `History` instances related to `Structures`.
     *
     * Call {@link lbServices.Structures#History Structures.History()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Structures#History
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Queries History of Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::get::Structures::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.History#count
             * @methodOf lbServices.Structures.History
             *
             * @description
             *
             * Counts History of Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.History.count = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::count::Structures::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.History#create
             * @methodOf lbServices.Structures.History
             *
             * @description
             *
             * Creates a new instance in History of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History.create = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::create::Structures::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.History#createMany
             * @methodOf lbServices.Structures.History
             *
             * @description
             *
             * Creates a new instance in History of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History.createMany = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::createMany::Structures::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.History#destroyAll
             * @methodOf lbServices.Structures.History
             *
             * @description
             *
             * Deletes all History of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.History.destroyAll = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::delete::Structures::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.History#destroyById
             * @methodOf lbServices.Structures.History
             *
             * @description
             *
             * Delete a related item by id for History.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `fk` – `{*}` - Foreign key for History
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.History.destroyById = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::destroyById::Structures::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.History#findById
             * @methodOf lbServices.Structures.History
             *
             * @description
             *
             * Find a related item by id for History.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `fk` – `{*}` - Foreign key for History
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History.findById = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::findById::Structures::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.History#updateById
             * @methodOf lbServices.Structures.History
             *
             * @description
             *
             * Update a related item by id for History.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `fk` – `{*}` - Foreign key for History
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History.updateById = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::updateById::Structures::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures#versions
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Fetches belongsTo relation versions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R.versions = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::get::Structures::versions"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SubInsurers
 * @header lbServices.SubInsurers
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SubInsurers` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SubInsurers",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SubInsurers/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#__get__notDeleted
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Queries notDeleted of SubInsurers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/SubInsurers/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#__create__notDeleted
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/SubInsurers/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#__delete__notDeleted
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/SubInsurers/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#__count__notDeleted
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Counts notDeleted of SubInsurers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/SubInsurers/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#create
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/SubInsurers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#createMany
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/SubInsurers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#upsert
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/SubInsurers",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#replaceOrCreate
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/SubInsurers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#upsertWithWhere
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/SubInsurers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#exists
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SubInsurers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#findById
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SubInsurers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#replaceById
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/SubInsurers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#find
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SubInsurers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#findOne
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/SubInsurers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#updateAll
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/SubInsurers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#deleteById
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SubInsurers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#count
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SubInsurers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#prototype$updateAttributes
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SubInsurers id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/SubInsurers/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#createChangeStream
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SubInsurers/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Books.SubInsurers() instead.
            "::get::Books::SubInsurers": {
              url: urlBase + "/Books/:id/SubInsurers",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#patchOrCreate
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#updateOrCreate
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#patchOrCreateWithWhere
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#update
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#destroyById
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#removeById
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#patchAttributes
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SubInsurers id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.SubInsurers#modelName
        * @propertyOf lbServices.SubInsurers
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SubInsurers`.
        */
        R.modelName = "SubInsurers";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Subscriptions
 * @header lbServices.Subscriptions
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Subscriptions` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Subscriptions",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Subscriptions/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#create
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Subscriptions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#createMany
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Subscriptions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#upsert
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Subscriptions",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#replaceOrCreate
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Subscriptions/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#upsertWithWhere
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Subscriptions/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#exists
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Subscriptions/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#findById
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Subscriptions/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#replaceById
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Subscriptions/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#find
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Subscriptions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#findOne
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Subscriptions/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#updateAll
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Subscriptions/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#deleteById
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Subscriptions/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#count
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Subscriptions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#prototype$updateAttributes
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Subscriptions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Subscriptions/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#createChangeStream
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Subscriptions/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#patchOrCreate
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#updateOrCreate
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#patchOrCreateWithWhere
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#update
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#destroyById
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#removeById
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#patchAttributes
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Subscriptions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Subscriptions#modelName
        * @propertyOf lbServices.Subscriptions
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Subscriptions`.
        */
        R.modelName = "Subscriptions";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Teams
 * @header lbServices.Teams
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Teams` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Teams",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Teams/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Teams.Contents.findById() instead.
            "prototype$__findById__Contents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Contents/:fk",
              method: "GET",
            },

            // INTERNAL. Use Teams.Contents.destroyById() instead.
            "prototype$__destroyById__Contents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Contents/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Teams.Contents.updateById() instead.
            "prototype$__updateById__Contents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Contents/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Teams.Departments() instead.
            "prototype$__get__Departments": {
              url: urlBase + "/Teams/:id/Departments",
              method: "GET",
            },

            // INTERNAL. Use Teams.Users.findById() instead.
            "prototype$__findById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Teams.Users.destroyById() instead.
            "prototype$__destroyById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Teams.Users.updateById() instead.
            "prototype$__updateById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Teams.Contents() instead.
            "prototype$__get__Contents": {
              isArray: true,
              url: urlBase + "/Teams/:id/Contents",
              method: "GET",
            },

            // INTERNAL. Use Teams.Contents.create() instead.
            "prototype$__create__Contents": {
              url: urlBase + "/Teams/:id/Contents",
              method: "POST",
            },

            // INTERNAL. Use Teams.Contents.destroyAll() instead.
            "prototype$__delete__Contents": {
              url: urlBase + "/Teams/:id/Contents",
              method: "DELETE",
            },

            // INTERNAL. Use Teams.Contents.count() instead.
            "prototype$__count__Contents": {
              url: urlBase + "/Teams/:id/Contents/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#__get__notDeleted
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Queries notDeleted of Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Teams/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#__create__notDeleted
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Teams/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#__delete__notDeleted
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Teams/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#__count__notDeleted
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Counts notDeleted of Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Teams/notDeleted/count",
              method: "GET",
            },

            // INTERNAL. Use Teams.Users() instead.
            "prototype$__get__Users": {
              isArray: true,
              url: urlBase + "/Teams/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Teams.Users.create() instead.
            "prototype$__create__Users": {
              url: urlBase + "/Teams/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Teams.Users.destroyAll() instead.
            "prototype$__delete__Users": {
              url: urlBase + "/Teams/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use Teams.Users.count() instead.
            "prototype$__count__Users": {
              url: urlBase + "/Teams/:id/Users/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#create
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Teams",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#createMany
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Teams",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#upsert
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Teams",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#replaceOrCreate
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Teams/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#upsertWithWhere
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Teams/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#exists
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Teams/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#findById
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Teams/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#replaceById
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Teams/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#find
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Teams",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#findOne
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Teams/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#updateAll
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Teams/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#deleteById
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Teams/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#count
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Teams/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#prototype$updateAttributes
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Teams/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#createChangeStream
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Teams/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Clients.Teams() instead.
            "::get::Clients::Teams": {
              url: urlBase + "/Clients/:id/Teams",
              method: "GET",
            },

            // INTERNAL. Use Contents.Teams() instead.
            "::get::Contents::Teams": {
              url: urlBase + "/Contents/:id/Teams",
              method: "GET",
            },

            // INTERNAL. Use Departments.Teams.findById() instead.
            "::findById::Departments::Teams": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Teams/:fk",
              method: "GET",
            },

            // INTERNAL. Use Departments.Teams.destroyById() instead.
            "::destroyById::Departments::Teams": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Teams/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Departments.Teams.updateById() instead.
            "::updateById::Departments::Teams": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Teams/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Departments.Teams() instead.
            "::get::Departments::Teams": {
              isArray: true,
              url: urlBase + "/Departments/:id/Teams",
              method: "GET",
            },

            // INTERNAL. Use Departments.Teams.create() instead.
            "::create::Departments::Teams": {
              url: urlBase + "/Departments/:id/Teams",
              method: "POST",
            },

            // INTERNAL. Use Departments.Teams.createMany() instead.
            "::createMany::Departments::Teams": {
              isArray: true,
              url: urlBase + "/Departments/:id/Teams",
              method: "POST",
            },

            // INTERNAL. Use Departments.Teams.destroyAll() instead.
            "::delete::Departments::Teams": {
              url: urlBase + "/Departments/:id/Teams",
              method: "DELETE",
            },

            // INTERNAL. Use Departments.Teams.count() instead.
            "::count::Departments::Teams": {
              url: urlBase + "/Departments/:id/Teams/count",
              method: "GET",
            },

            // INTERNAL. Use Users.Teams() instead.
            "::get::Users::Teams": {
              url: urlBase + "/Users/:id/Teams",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Teams#patchOrCreate
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Teams#updateOrCreate
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Teams#patchOrCreateWithWhere
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Teams#update
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Teams#destroyById
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Teams#removeById
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Teams#patchAttributes
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Teams#modelName
        * @propertyOf lbServices.Teams
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Teams`.
        */
        R.modelName = "Teams";

    /**
     * @ngdoc object
     * @name lbServices.Teams.Contents
     * @header lbServices.Teams.Contents
     * @object
     * @description
     *
     * The object `Teams.Contents` groups methods
     * manipulating `Contents` instances related to `Teams`.
     *
     * Call {@link lbServices.Teams#Contents Teams.Contents()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Teams#Contents
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Queries Contents of Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R.Contents = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::get::Teams::Contents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Contents#count
             * @methodOf lbServices.Teams.Contents
             *
             * @description
             *
             * Counts Contents of Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Contents.count = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::count::Teams::Contents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Contents#create
             * @methodOf lbServices.Teams.Contents
             *
             * @description
             *
             * Creates a new instance in Contents of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R.Contents.create = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::create::Teams::Contents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Contents#createMany
             * @methodOf lbServices.Teams.Contents
             *
             * @description
             *
             * Creates a new instance in Contents of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R.Contents.createMany = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::createMany::Teams::Contents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Contents#destroyAll
             * @methodOf lbServices.Teams.Contents
             *
             * @description
             *
             * Deletes all Contents of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Contents.destroyAll = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::delete::Teams::Contents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Contents#destroyById
             * @methodOf lbServices.Teams.Contents
             *
             * @description
             *
             * Delete a related item by id for Contents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `fk` – `{*}` - Foreign key for Contents
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Contents.destroyById = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::destroyById::Teams::Contents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Contents#findById
             * @methodOf lbServices.Teams.Contents
             *
             * @description
             *
             * Find a related item by id for Contents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `fk` – `{*}` - Foreign key for Contents
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R.Contents.findById = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::findById::Teams::Contents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Contents#updateById
             * @methodOf lbServices.Teams.Contents
             *
             * @description
             *
             * Update a related item by id for Contents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `fk` – `{*}` - Foreign key for Contents
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R.Contents.updateById = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::updateById::Teams::Contents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams#Departments
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Fetches belongsTo relation Departments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
        R.Departments = function() {
          var TargetResource = $injector.get("Departments");
          var action = TargetResource["::get::Teams::Departments"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Teams.Users
     * @header lbServices.Teams.Users
     * @object
     * @description
     *
     * The object `Teams.Users` groups methods
     * manipulating `Users` instances related to `Teams`.
     *
     * Call {@link lbServices.Teams#Users Teams.Users()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Teams#Users
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Queries Users of Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::get::Teams::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Users#count
             * @methodOf lbServices.Teams.Users
             *
             * @description
             *
             * Counts Users of Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Users.count = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::count::Teams::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Users#create
             * @methodOf lbServices.Teams.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.create = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::create::Teams::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Users#createMany
             * @methodOf lbServices.Teams.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.createMany = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::createMany::Teams::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Users#destroyAll
             * @methodOf lbServices.Teams.Users
             *
             * @description
             *
             * Deletes all Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyAll = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::delete::Teams::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Users#destroyById
             * @methodOf lbServices.Teams.Users
             *
             * @description
             *
             * Delete a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::destroyById::Teams::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Users#findById
             * @methodOf lbServices.Teams.Users
             *
             * @description
             *
             * Find a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.findById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::findById::Teams::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Users#updateById
             * @methodOf lbServices.Teams.Users
             *
             * @description
             *
             * Update a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.updateById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::updateById::Teams::Users"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Tooltips
 * @header lbServices.Tooltips
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Tooltips` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Tooltips",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Tooltips/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#__get__notDeleted
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Queries notDeleted of Tooltips.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Tooltips/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#__create__notDeleted
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Tooltips/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#__delete__notDeleted
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Tooltips/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#__count__notDeleted
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Counts notDeleted of Tooltips.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Tooltips/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#create
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Tooltips",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#createMany
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Tooltips",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#upsert
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Tooltips",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#replaceOrCreate
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Tooltips/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#upsertWithWhere
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Tooltips/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#exists
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Tooltips/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#findById
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Tooltips/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#replaceById
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Tooltips/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#find
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Tooltips",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#findOne
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Tooltips/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#updateAll
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Tooltips/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#deleteById
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Tooltips/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#count
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Tooltips/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#prototype$updateAttributes
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Tooltips id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Tooltips/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#createChangeStream
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Tooltips/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Tooltips#patchOrCreate
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#updateOrCreate
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#patchOrCreateWithWhere
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#update
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#destroyById
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#removeById
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#patchAttributes
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Tooltips id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Tooltips#modelName
        * @propertyOf lbServices.Tooltips
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Tooltips`.
        */
        R.modelName = "Tooltips";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Users
 * @header lbServices.Users
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Users` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Users",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Users/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Users#prototype$__findById__accessTokens
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Find a related item by id for accessTokens.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `fk` – `{*}` - Foreign key for accessTokens
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "prototype$__findById__accessTokens": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/accessTokens/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#prototype$__destroyById__accessTokens
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Delete a related item by id for accessTokens.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `fk` – `{*}` - Foreign key for accessTokens
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__accessTokens": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/accessTokens/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#prototype$__updateById__accessTokens
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Update a related item by id for accessTokens.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `fk` – `{*}` - Foreign key for accessTokens
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "prototype$__updateById__accessTokens": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/accessTokens/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Users.Departments() instead.
            "prototype$__get__Departments": {
              url: urlBase + "/Users/:id/Departments",
              method: "GET",
            },

            // INTERNAL. Use Users.Teams() instead.
            "prototype$__get__Teams": {
              url: urlBase + "/Users/:id/Teams",
              method: "GET",
            },

            // INTERNAL. Use Users.Locations() instead.
            "prototype$__get__Locations": {
              url: urlBase + "/Users/:id/Locations",
              method: "GET",
            },

            // INTERNAL. Use Users.Events.findById() instead.
            "prototype$__findById__Events": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Events/:fk",
              method: "GET",
            },

            // INTERNAL. Use Users.Events.destroyById() instead.
            "prototype$__destroyById__Events": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Events/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Users.Events.updateById() instead.
            "prototype$__updateById__Events": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Events/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Users.Books.findById() instead.
            "prototype$__findById__Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Books/:fk",
              method: "GET",
            },

            // INTERNAL. Use Users.Books.destroyById() instead.
            "prototype$__destroyById__Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Books/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Users.Books.updateById() instead.
            "prototype$__updateById__Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Books/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Users.Roles() instead.
            "prototype$__get__Roles": {
              url: urlBase + "/Users/:id/Roles",
              method: "GET",
            },

            // INTERNAL. Use Users.PermissionGroups() instead.
            "prototype$__get__PermissionGroups": {
              url: urlBase + "/Users/:id/PermissionGroups",
              method: "GET",
            },

            // INTERNAL. Use Users.Divisions() instead.
            "prototype$__get__Divisions": {
              url: urlBase + "/Users/:id/Divisions",
              method: "GET",
            },

            // INTERNAL. Use Users.UsersStatus() instead.
            "prototype$__get__UsersStatus": {
              url: urlBase + "/Users/:id/UsersStatus",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#prototype$__get__accessTokens
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Queries accessTokens of Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "prototype$__get__accessTokens": {
              isArray: true,
              url: urlBase + "/Users/:id/accessTokens",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#prototype$__create__accessTokens
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Creates a new instance in accessTokens of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "prototype$__create__accessTokens": {
              url: urlBase + "/Users/:id/accessTokens",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#prototype$__delete__accessTokens
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Deletes all accessTokens of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__accessTokens": {
              url: urlBase + "/Users/:id/accessTokens",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#prototype$__count__accessTokens
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Counts accessTokens of Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__accessTokens": {
              url: urlBase + "/Users/:id/accessTokens/count",
              method: "GET",
            },

            // INTERNAL. Use Users.Events() instead.
            "prototype$__get__Events": {
              isArray: true,
              url: urlBase + "/Users/:id/Events",
              method: "GET",
            },

            // INTERNAL. Use Users.Events.create() instead.
            "prototype$__create__Events": {
              url: urlBase + "/Users/:id/Events",
              method: "POST",
            },

            // INTERNAL. Use Users.Events.destroyAll() instead.
            "prototype$__delete__Events": {
              url: urlBase + "/Users/:id/Events",
              method: "DELETE",
            },

            // INTERNAL. Use Users.Events.count() instead.
            "prototype$__count__Events": {
              url: urlBase + "/Users/:id/Events/count",
              method: "GET",
            },

            // INTERNAL. Use Users.Books() instead.
            "prototype$__get__Books": {
              isArray: true,
              url: urlBase + "/Users/:id/Books",
              method: "GET",
            },

            // INTERNAL. Use Users.Books.create() instead.
            "prototype$__create__Books": {
              url: urlBase + "/Users/:id/Books",
              method: "POST",
            },

            // INTERNAL. Use Users.Books.destroyAll() instead.
            "prototype$__delete__Books": {
              url: urlBase + "/Users/:id/Books",
              method: "DELETE",
            },

            // INTERNAL. Use Users.Books.count() instead.
            "prototype$__count__Books": {
              url: urlBase + "/Users/:id/Books/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#create
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Users",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#createMany
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Users",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#upsert
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Users",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#replaceOrCreate
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Users/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#upsertWithWhere
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Users/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#exists
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Users/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#findById
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Users/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#replaceById
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Users/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#find
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Users",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#findOne
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Users/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#updateAll
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Users/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#deleteById
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Users/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#count
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Users/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#prototype$updateAttributes
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Users/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#createChangeStream
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Users/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#login
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Login a user with username/email and password.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `include` – `{string=}` - Related objects to include in the response. See the description of return value for more details.
             *   Default value: `user`.
             *
             *  - `rememberMe` - `boolean` - Whether the authentication credentials
             *     should be remembered in localStorage across app/browser restarts.
             *     Default: `true`.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The response body contains properties of the AccessToken created on login.
             * Depending on the value of `include` parameter, the body may contain additional properties:
             *   - `user` - `U+007BUserU+007D` - Data of the currently logged in user. (`include=user`)
             *
             */
            "login": {
              params: {
                include: 'user',
              },
              interceptor: {
                response: function(response) {
                  var accessToken = response.data;
                  LoopBackAuth.setUser(
                    accessToken.id, accessToken.userId, accessToken.user);
                  LoopBackAuth.rememberMe =
                    response.config.params.rememberMe !== false;
                  LoopBackAuth.save();
                  return response.resource;
                },
              },
              url: urlBase + "/Users/login",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#logout
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Logout a user with access token.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `access_token` – `{string=}` - Do not supply this argument, it is automatically extracted from request headers.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "logout": {
              interceptor: {
                response: function(response) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return response.resource;
                },
                responseError: function(responseError) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return responseError.resource;
                },
              },
              url: urlBase + "/Users/logout",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#confirm
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Confirm a user registration with email verification token.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `uid` – `{string}` -
             *
             *  - `token` – `{string}` -
             *
             *  - `redirect` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "confirm": {
              url: urlBase + "/Users/confirm",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#resetPassword
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Reset password for a user with email.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "resetPassword": {
              url: urlBase + "/Users/reset",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#setNewPassword
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Set new password for authenticated user
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "setNewPassword": {
              isArray: true,
              url: urlBase + "/Users/setNewPassword",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#getUserStats
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Get list of all changes to a book
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "getUserStats": {
              isArray: true,
              url: urlBase + "/Users/getUserStats",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#approvalListUsers
             * @methodOf lbServices.Users
             *
             * @description
             *
             * getApprovalListOfUsersFromBook
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "approvalListUsers": {
              isArray: true,
              url: urlBase + "/Users/approvalListUsers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#switchUser
             * @methodOf lbServices.Users
             *
             * @description
             *
             * switch user
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "switchUser": {
              isArray: true,
              url: urlBase + "/Users/switchUser",
              method: "POST",
            },

            // INTERNAL. Use Books.Users() instead.
            "::get::Books::Users": {
              url: urlBase + "/Books/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Departments.Users.findById() instead.
            "::findById::Departments::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Departments.Users.destroyById() instead.
            "::destroyById::Departments::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Departments.Users.updateById() instead.
            "::updateById::Departments::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Departments.Users() instead.
            "::get::Departments::Users": {
              isArray: true,
              url: urlBase + "/Departments/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Departments.Users.create() instead.
            "::create::Departments::Users": {
              url: urlBase + "/Departments/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Departments.Users.createMany() instead.
            "::createMany::Departments::Users": {
              isArray: true,
              url: urlBase + "/Departments/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Departments.Users.destroyAll() instead.
            "::delete::Departments::Users": {
              url: urlBase + "/Departments/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use Departments.Users.count() instead.
            "::count::Departments::Users": {
              url: urlBase + "/Departments/:id/Users/count",
              method: "GET",
            },

            // INTERNAL. Use Divisions.Users.findById() instead.
            "::findById::Divisions::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Divisions.Users.destroyById() instead.
            "::destroyById::Divisions::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Divisions.Users.updateById() instead.
            "::updateById::Divisions::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/Users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Divisions.Users() instead.
            "::get::Divisions::Users": {
              isArray: true,
              url: urlBase + "/Divisions/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Divisions.Users.create() instead.
            "::create::Divisions::Users": {
              url: urlBase + "/Divisions/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Divisions.Users.createMany() instead.
            "::createMany::Divisions::Users": {
              isArray: true,
              url: urlBase + "/Divisions/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Divisions.Users.destroyAll() instead.
            "::delete::Divisions::Users": {
              url: urlBase + "/Divisions/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use Divisions.Users.count() instead.
            "::count::Divisions::Users": {
              url: urlBase + "/Divisions/:id/Users/count",
              method: "GET",
            },

            // INTERNAL. Use Events.Users() instead.
            "::get::Events::Users": {
              url: urlBase + "/Events/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use InsurerDetails.Users.findById() instead.
            "::findById::InsurerDetails::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InsurerDetails/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use InsurerDetails.Users.destroyById() instead.
            "::destroyById::InsurerDetails::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InsurerDetails/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use InsurerDetails.Users.updateById() instead.
            "::updateById::InsurerDetails::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InsurerDetails/:id/Users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use InsurerDetails.Users() instead.
            "::get::InsurerDetails::Users": {
              isArray: true,
              url: urlBase + "/InsurerDetails/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use InsurerDetails.Users.create() instead.
            "::create::InsurerDetails::Users": {
              url: urlBase + "/InsurerDetails/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use InsurerDetails.Users.createMany() instead.
            "::createMany::InsurerDetails::Users": {
              isArray: true,
              url: urlBase + "/InsurerDetails/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use InsurerDetails.Users.destroyAll() instead.
            "::delete::InsurerDetails::Users": {
              url: urlBase + "/InsurerDetails/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use InsurerDetails.Users.count() instead.
            "::count::InsurerDetails::Users": {
              url: urlBase + "/InsurerDetails/:id/Users/count",
              method: "GET",
            },

            // INTERNAL. Use Locations.Users.findById() instead.
            "::findById::Locations::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Locations/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Locations.Users.destroyById() instead.
            "::destroyById::Locations::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Locations/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Locations.Users.updateById() instead.
            "::updateById::Locations::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Locations/:id/Users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Locations.Users() instead.
            "::get::Locations::Users": {
              isArray: true,
              url: urlBase + "/Locations/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Locations.Users.create() instead.
            "::create::Locations::Users": {
              url: urlBase + "/Locations/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Locations.Users.createMany() instead.
            "::createMany::Locations::Users": {
              isArray: true,
              url: urlBase + "/Locations/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Locations.Users.destroyAll() instead.
            "::delete::Locations::Users": {
              url: urlBase + "/Locations/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use Locations.Users.count() instead.
            "::count::Locations::Users": {
              url: urlBase + "/Locations/:id/Users/count",
              method: "GET",
            },

            // INTERNAL. Use Roles.Users.findById() instead.
            "::findById::Roles::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Roles.Users.destroyById() instead.
            "::destroyById::Roles::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Roles.Users.updateById() instead.
            "::updateById::Roles::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/Users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Roles.Users() instead.
            "::get::Roles::Users": {
              isArray: true,
              url: urlBase + "/Roles/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Roles.Users.create() instead.
            "::create::Roles::Users": {
              url: urlBase + "/Roles/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Roles.Users.createMany() instead.
            "::createMany::Roles::Users": {
              isArray: true,
              url: urlBase + "/Roles/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Roles.Users.destroyAll() instead.
            "::delete::Roles::Users": {
              url: urlBase + "/Roles/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use Roles.Users.count() instead.
            "::count::Roles::Users": {
              url: urlBase + "/Roles/:id/Users/count",
              method: "GET",
            },

            // INTERNAL. Use Teams.Users.findById() instead.
            "::findById::Teams::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Teams.Users.destroyById() instead.
            "::destroyById::Teams::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Teams.Users.updateById() instead.
            "::updateById::Teams::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Teams.Users() instead.
            "::get::Teams::Users": {
              isArray: true,
              url: urlBase + "/Teams/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Teams.Users.create() instead.
            "::create::Teams::Users": {
              url: urlBase + "/Teams/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Teams.Users.createMany() instead.
            "::createMany::Teams::Users": {
              isArray: true,
              url: urlBase + "/Teams/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Teams.Users.destroyAll() instead.
            "::delete::Teams::Users": {
              url: urlBase + "/Teams/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use Teams.Users.count() instead.
            "::count::Teams::Users": {
              url: urlBase + "/Teams/:id/Users/count",
              method: "GET",
            },

            // INTERNAL. Use UsersStatus.Users.findById() instead.
            "::findById::UsersStatus::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/UsersStatuses/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use UsersStatus.Users.destroyById() instead.
            "::destroyById::UsersStatus::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/UsersStatuses/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use UsersStatus.Users.updateById() instead.
            "::updateById::UsersStatus::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/UsersStatuses/:id/Users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use UsersStatus.Users() instead.
            "::get::UsersStatus::Users": {
              isArray: true,
              url: urlBase + "/UsersStatuses/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use UsersStatus.Users.create() instead.
            "::create::UsersStatus::Users": {
              url: urlBase + "/UsersStatuses/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use UsersStatus.Users.createMany() instead.
            "::createMany::UsersStatus::Users": {
              isArray: true,
              url: urlBase + "/UsersStatuses/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use UsersStatus.Users.destroyAll() instead.
            "::delete::UsersStatus::Users": {
              url: urlBase + "/UsersStatuses/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use UsersStatus.Users.count() instead.
            "::count::UsersStatus::Users": {
              url: urlBase + "/UsersStatuses/:id/Users/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#getCurrent
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Get data of the currently logged user. Fail with HTTP result 401
             * when there is no user logged in.
             *
             * @param {function(Object,Object)=} successCb
             *    Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *    `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             */
            'getCurrent': {
              url: urlBase + "/Users" + '/:id',
              method: 'GET',
              params: {
                id: function() {
                  var id = LoopBackAuth.currentUserId;
                  if (id == null) id = '__anonymous__';
                  return id;
                },
              },
              interceptor: {
                response: function(response) {
                  LoopBackAuth.currentUserData = response.data;
                  return response.resource;
                },
                responseError: function(responseError) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return $q.reject(responseError);
                },
              },
              __isGetCurrentUser__: true,
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Users#patchOrCreate
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Users#updateOrCreate
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Users#patchOrCreateWithWhere
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Users#update
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Users#destroyById
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Users#removeById
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Users#patchAttributes
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];

        /**
         * @ngdoc method
         * @name lbServices.Users#getCachedCurrent
         * @methodOf lbServices.Users
         *
         * @description
         *
         * Get data of the currently logged user that was returned by the last
         * call to {@link lbServices.Users#login} or
         * {@link lbServices.Users#getCurrent}. Return null when there
         * is no user logged in or the data of the current user were not fetched
         * yet.
         *
         * @returns {Object} A Users instance.
         */
        R.getCachedCurrent = function() {
          var data = LoopBackAuth.currentUserData;
          return data ? new R(data) : null;
        };

        /**
         * @ngdoc method
         * @name lbServices.Users#isAuthenticated
         * @methodOf lbServices.Users
         *
         * @returns {boolean} True if the current user is authenticated (logged in).
         */
        R.isAuthenticated = function() {
          return this.getCurrentId() != null;
        };

        /**
         * @ngdoc method
         * @name lbServices.Users#getCurrentId
         * @methodOf lbServices.Users
         *
         * @returns {Object} Id of the currently logged-in user or null.
         */
        R.getCurrentId = function() {
          return LoopBackAuth.currentUserId;
        };

        /**
        * @ngdoc property
        * @name lbServices.Users#modelName
        * @propertyOf lbServices.Users
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Users`.
        */
        R.modelName = "Users";


            /**
             * @ngdoc method
             * @name lbServices.Users#Departments
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Fetches belongsTo relation Departments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
        R.Departments = function() {
          var TargetResource = $injector.get("Departments");
          var action = TargetResource["::get::Users::Departments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users#Teams
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Fetches belongsTo relation Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R.Teams = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::get::Users::Teams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users#Locations
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Fetches belongsTo relation Locations.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
        R.Locations = function() {
          var TargetResource = $injector.get("Locations");
          var action = TargetResource["::get::Users::Locations"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Users.Events
     * @header lbServices.Users.Events
     * @object
     * @description
     *
     * The object `Users.Events` groups methods
     * manipulating `Events` instances related to `Users`.
     *
     * Call {@link lbServices.Users#Events Users.Events()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Users#Events
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Queries Events of Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R.Events = function() {
          var TargetResource = $injector.get("Events");
          var action = TargetResource["::get::Users::Events"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Events#count
             * @methodOf lbServices.Users.Events
             *
             * @description
             *
             * Counts Events of Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Events.count = function() {
          var TargetResource = $injector.get("Events");
          var action = TargetResource["::count::Users::Events"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Events#create
             * @methodOf lbServices.Users.Events
             *
             * @description
             *
             * Creates a new instance in Events of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R.Events.create = function() {
          var TargetResource = $injector.get("Events");
          var action = TargetResource["::create::Users::Events"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Events#createMany
             * @methodOf lbServices.Users.Events
             *
             * @description
             *
             * Creates a new instance in Events of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R.Events.createMany = function() {
          var TargetResource = $injector.get("Events");
          var action = TargetResource["::createMany::Users::Events"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Events#destroyAll
             * @methodOf lbServices.Users.Events
             *
             * @description
             *
             * Deletes all Events of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Events.destroyAll = function() {
          var TargetResource = $injector.get("Events");
          var action = TargetResource["::delete::Users::Events"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Events#destroyById
             * @methodOf lbServices.Users.Events
             *
             * @description
             *
             * Delete a related item by id for Events.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `fk` – `{*}` - Foreign key for Events
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Events.destroyById = function() {
          var TargetResource = $injector.get("Events");
          var action = TargetResource["::destroyById::Users::Events"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Events#findById
             * @methodOf lbServices.Users.Events
             *
             * @description
             *
             * Find a related item by id for Events.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `fk` – `{*}` - Foreign key for Events
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R.Events.findById = function() {
          var TargetResource = $injector.get("Events");
          var action = TargetResource["::findById::Users::Events"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Events#updateById
             * @methodOf lbServices.Users.Events
             *
             * @description
             *
             * Update a related item by id for Events.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `fk` – `{*}` - Foreign key for Events
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R.Events.updateById = function() {
          var TargetResource = $injector.get("Events");
          var action = TargetResource["::updateById::Users::Events"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Users.Books
     * @header lbServices.Users.Books
     * @object
     * @description
     *
     * The object `Users.Books` groups methods
     * manipulating `Books` instances related to `Users`.
     *
     * Call {@link lbServices.Users#Books Users.Books()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Users#Books
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Queries Books of Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::get::Users::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Books#count
             * @methodOf lbServices.Users.Books
             *
             * @description
             *
             * Counts Books of Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Books.count = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::count::Users::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Books#create
             * @methodOf lbServices.Users.Books
             *
             * @description
             *
             * Creates a new instance in Books of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books.create = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::create::Users::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Books#createMany
             * @methodOf lbServices.Users.Books
             *
             * @description
             *
             * Creates a new instance in Books of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books.createMany = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::createMany::Users::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Books#destroyAll
             * @methodOf lbServices.Users.Books
             *
             * @description
             *
             * Deletes all Books of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Books.destroyAll = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::delete::Users::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Books#destroyById
             * @methodOf lbServices.Users.Books
             *
             * @description
             *
             * Delete a related item by id for Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `fk` – `{*}` - Foreign key for Books
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Books.destroyById = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::destroyById::Users::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Books#findById
             * @methodOf lbServices.Users.Books
             *
             * @description
             *
             * Find a related item by id for Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `fk` – `{*}` - Foreign key for Books
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books.findById = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::findById::Users::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Books#updateById
             * @methodOf lbServices.Users.Books
             *
             * @description
             *
             * Update a related item by id for Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `fk` – `{*}` - Foreign key for Books
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books.updateById = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::updateById::Users::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users#Roles
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Fetches belongsTo relation Roles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
        R.Roles = function() {
          var TargetResource = $injector.get("Roles");
          var action = TargetResource["::get::Users::Roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users#PermissionGroups
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Fetches belongsTo relation PermissionGroups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
        R.PermissionGroups = function() {
          var TargetResource = $injector.get("PermissionGroups");
          var action = TargetResource["::get::Users::PermissionGroups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users#Divisions
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Fetches belongsTo relation Divisions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
        R.Divisions = function() {
          var TargetResource = $injector.get("Divisions");
          var action = TargetResource["::get::Users::Divisions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users#UsersStatus
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Fetches belongsTo relation UsersStatus.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
        R.UsersStatus = function() {
          var TargetResource = $injector.get("UsersStatus");
          var action = TargetResource["::get::Users::UsersStatus"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.UsersStatus
 * @header lbServices.UsersStatus
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `UsersStatus` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "UsersStatus",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/UsersStatuses/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use UsersStatus.Users.findById() instead.
            "prototype$__findById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/UsersStatuses/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use UsersStatus.Users.destroyById() instead.
            "prototype$__destroyById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/UsersStatuses/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use UsersStatus.Users.updateById() instead.
            "prototype$__updateById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/UsersStatuses/:id/Users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use UsersStatus.Users() instead.
            "prototype$__get__Users": {
              isArray: true,
              url: urlBase + "/UsersStatuses/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use UsersStatus.Users.create() instead.
            "prototype$__create__Users": {
              url: urlBase + "/UsersStatuses/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use UsersStatus.Users.destroyAll() instead.
            "prototype$__delete__Users": {
              url: urlBase + "/UsersStatuses/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use UsersStatus.Users.count() instead.
            "prototype$__count__Users": {
              url: urlBase + "/UsersStatuses/:id/Users/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#create
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/UsersStatuses",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#createMany
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/UsersStatuses",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#upsert
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/UsersStatuses",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#replaceOrCreate
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/UsersStatuses/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#upsertWithWhere
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/UsersStatuses/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#exists
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/UsersStatuses/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#findById
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/UsersStatuses/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#replaceById
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/UsersStatuses/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#find
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/UsersStatuses",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#findOne
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/UsersStatuses/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#updateAll
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/UsersStatuses/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#deleteById
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/UsersStatuses/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#count
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/UsersStatuses/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#prototype$updateAttributes
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UsersStatus id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/UsersStatuses/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#createChangeStream
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/UsersStatuses/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Users.UsersStatus() instead.
            "::get::Users::UsersStatus": {
              url: urlBase + "/Users/:id/UsersStatus",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#patchOrCreate
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#updateOrCreate
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#patchOrCreateWithWhere
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#update
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#destroyById
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#removeById
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#patchAttributes
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UsersStatus id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.UsersStatus#modelName
        * @propertyOf lbServices.UsersStatus
        * @description
        * The name of the model represented by this $resource,
        * i.e. `UsersStatus`.
        */
        R.modelName = "UsersStatus";

    /**
     * @ngdoc object
     * @name lbServices.UsersStatus.Users
     * @header lbServices.UsersStatus.Users
     * @object
     * @description
     *
     * The object `UsersStatus.Users` groups methods
     * manipulating `Users` instances related to `UsersStatus`.
     *
     * Call {@link lbServices.UsersStatus#Users UsersStatus.Users()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#Users
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Queries Users of UsersStatus.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UsersStatus id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::get::UsersStatus::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus.Users#count
             * @methodOf lbServices.UsersStatus.Users
             *
             * @description
             *
             * Counts Users of UsersStatus.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UsersStatus id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Users.count = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::count::UsersStatus::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus.Users#create
             * @methodOf lbServices.UsersStatus.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UsersStatus id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.create = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::create::UsersStatus::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus.Users#createMany
             * @methodOf lbServices.UsersStatus.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UsersStatus id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.createMany = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::createMany::UsersStatus::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus.Users#destroyAll
             * @methodOf lbServices.UsersStatus.Users
             *
             * @description
             *
             * Deletes all Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UsersStatus id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyAll = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::delete::UsersStatus::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus.Users#destroyById
             * @methodOf lbServices.UsersStatus.Users
             *
             * @description
             *
             * Delete a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UsersStatus id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::destroyById::UsersStatus::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus.Users#findById
             * @methodOf lbServices.UsersStatus.Users
             *
             * @description
             *
             * Find a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UsersStatus id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.findById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::findById::UsersStatus::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus.Users#updateById
             * @methodOf lbServices.UsersStatus.Users
             *
             * @description
             *
             * Update a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UsersStatus id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.updateById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::updateById::UsersStatus::Users"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Versions
 * @header lbServices.Versions
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Versions` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Versions",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Versions/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Versions.Contents() instead.
            "prototype$__get__Contents": {
              url: urlBase + "/Versions/:id/Contents",
              method: "GET",
            },

            // INTERNAL. Use Versions.Structures.findById() instead.
            "prototype$__findById__Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/Structures/:fk",
              method: "GET",
            },

            // INTERNAL. Use Versions.Structures.destroyById() instead.
            "prototype$__destroyById__Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/Structures/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Versions.Structures.updateById() instead.
            "prototype$__updateById__Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/Structures/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Versions.History.findById() instead.
            "prototype$__findById__History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/History/:fk",
              method: "GET",
            },

            // INTERNAL. Use Versions.History.destroyById() instead.
            "prototype$__destroyById__History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/History/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Versions.History.updateById() instead.
            "prototype$__updateById__History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/History/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Versions.Structures() instead.
            "prototype$__get__Structures": {
              isArray: true,
              url: urlBase + "/Versions/:id/Structures",
              method: "GET",
            },

            // INTERNAL. Use Versions.Structures.create() instead.
            "prototype$__create__Structures": {
              url: urlBase + "/Versions/:id/Structures",
              method: "POST",
            },

            // INTERNAL. Use Versions.Structures.destroyAll() instead.
            "prototype$__delete__Structures": {
              url: urlBase + "/Versions/:id/Structures",
              method: "DELETE",
            },

            // INTERNAL. Use Versions.Structures.count() instead.
            "prototype$__count__Structures": {
              url: urlBase + "/Versions/:id/Structures/count",
              method: "GET",
            },

            // INTERNAL. Use Versions.History() instead.
            "prototype$__get__History": {
              isArray: true,
              url: urlBase + "/Versions/:id/History",
              method: "GET",
            },

            // INTERNAL. Use Versions.History.create() instead.
            "prototype$__create__History": {
              url: urlBase + "/Versions/:id/History",
              method: "POST",
            },

            // INTERNAL. Use Versions.History.destroyAll() instead.
            "prototype$__delete__History": {
              url: urlBase + "/Versions/:id/History",
              method: "DELETE",
            },

            // INTERNAL. Use Versions.History.count() instead.
            "prototype$__count__History": {
              url: urlBase + "/Versions/:id/History/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#create
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Versions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#createMany
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Versions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#upsert
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Versions",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#replaceOrCreate
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Versions/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#upsertWithWhere
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Versions/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#exists
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Versions/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#findById
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Versions/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#replaceById
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Versions/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#find
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Versions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#findOne
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Versions/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#updateAll
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Versions/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#deleteById
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Versions/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#count
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Versions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#prototype$updateAttributes
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Versions/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#createChangeStream
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Versions/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#checkVersion
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Get id of version if matched
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "checkVersion": {
              url: urlBase + "/Versions/checkVersion",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#approveVersionsByBook
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Set version status to approved (2) by bookId if live
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "approveVersionsByBook": {
              url: urlBase + "/Versions/approveVersionsByBook",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#getFilePaths
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Get id of version if matched
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "getFilePaths": {
              url: urlBase + "/Versions/getFilePaths",
              method: "POST",
            },

            // INTERNAL. Use Contents.Versions.findById() instead.
            "::findById::Contents::Versions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Contents/:id/Versions/:fk",
              method: "GET",
            },

            // INTERNAL. Use Contents.Versions.destroyById() instead.
            "::destroyById::Contents::Versions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Contents/:id/Versions/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Contents.Versions.updateById() instead.
            "::updateById::Contents::Versions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Contents/:id/Versions/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Contents.Versions() instead.
            "::get::Contents::Versions": {
              isArray: true,
              url: urlBase + "/Contents/:id/Versions",
              method: "GET",
            },

            // INTERNAL. Use Contents.Versions.create() instead.
            "::create::Contents::Versions": {
              url: urlBase + "/Contents/:id/Versions",
              method: "POST",
            },

            // INTERNAL. Use Contents.Versions.createMany() instead.
            "::createMany::Contents::Versions": {
              isArray: true,
              url: urlBase + "/Contents/:id/Versions",
              method: "POST",
            },

            // INTERNAL. Use Contents.Versions.destroyAll() instead.
            "::delete::Contents::Versions": {
              url: urlBase + "/Contents/:id/Versions",
              method: "DELETE",
            },

            // INTERNAL. Use Contents.Versions.count() instead.
            "::count::Contents::Versions": {
              url: urlBase + "/Contents/:id/Versions/count",
              method: "GET",
            },

            // INTERNAL. Use History.versions() instead.
            "::get::History::versions": {
              url: urlBase + "/Histories/:id/versions",
              method: "GET",
            },

            // INTERNAL. Use History.versionsNew() instead.
            "::get::History::versionsNew": {
              url: urlBase + "/Histories/:id/versionsNew",
              method: "GET",
            },

            // INTERNAL. Use OrderHistory.versions() instead.
            "::get::OrderHistory::versions": {
              url: urlBase + "/OrderHistories/:id/versions",
              method: "GET",
            },

            // INTERNAL. Use OrderHistory.versionsNew() instead.
            "::get::OrderHistory::versionsNew": {
              url: urlBase + "/OrderHistories/:id/versionsNew",
              method: "GET",
            },

            // INTERNAL. Use Structures.versions() instead.
            "::get::Structures::versions": {
              url: urlBase + "/Structures/:id/versions",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Versions#patchOrCreate
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Versions#updateOrCreate
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Versions#patchOrCreateWithWhere
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Versions#update
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Versions#destroyById
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Versions#removeById
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Versions#patchAttributes
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Versions#modelName
        * @propertyOf lbServices.Versions
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Versions`.
        */
        R.modelName = "Versions";


            /**
             * @ngdoc method
             * @name lbServices.Versions#Contents
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Fetches belongsTo relation Contents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R.Contents = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::get::Versions::Contents"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Versions.Structures
     * @header lbServices.Versions.Structures
     * @object
     * @description
     *
     * The object `Versions.Structures` groups methods
     * manipulating `Structures` instances related to `Versions`.
     *
     * Call {@link lbServices.Versions#Structures Versions.Structures()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Versions#Structures
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Queries Structures of Versions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::get::Versions::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.Structures#count
             * @methodOf lbServices.Versions.Structures
             *
             * @description
             *
             * Counts Structures of Versions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Structures.count = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::count::Versions::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.Structures#create
             * @methodOf lbServices.Versions.Structures
             *
             * @description
             *
             * Creates a new instance in Structures of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures.create = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::create::Versions::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.Structures#createMany
             * @methodOf lbServices.Versions.Structures
             *
             * @description
             *
             * Creates a new instance in Structures of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures.createMany = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::createMany::Versions::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.Structures#destroyAll
             * @methodOf lbServices.Versions.Structures
             *
             * @description
             *
             * Deletes all Structures of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Structures.destroyAll = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::delete::Versions::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.Structures#destroyById
             * @methodOf lbServices.Versions.Structures
             *
             * @description
             *
             * Delete a related item by id for Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `fk` – `{*}` - Foreign key for Structures
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Structures.destroyById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::destroyById::Versions::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.Structures#findById
             * @methodOf lbServices.Versions.Structures
             *
             * @description
             *
             * Find a related item by id for Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `fk` – `{*}` - Foreign key for Structures
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures.findById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::findById::Versions::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.Structures#updateById
             * @methodOf lbServices.Versions.Structures
             *
             * @description
             *
             * Update a related item by id for Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `fk` – `{*}` - Foreign key for Structures
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures.updateById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::updateById::Versions::Structures"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Versions.History
     * @header lbServices.Versions.History
     * @object
     * @description
     *
     * The object `Versions.History` groups methods
     * manipulating `History` instances related to `Versions`.
     *
     * Call {@link lbServices.Versions#History Versions.History()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Versions#History
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Queries History of Versions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::get::Versions::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.History#count
             * @methodOf lbServices.Versions.History
             *
             * @description
             *
             * Counts History of Versions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.History.count = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::count::Versions::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.History#create
             * @methodOf lbServices.Versions.History
             *
             * @description
             *
             * Creates a new instance in History of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History.create = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::create::Versions::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.History#createMany
             * @methodOf lbServices.Versions.History
             *
             * @description
             *
             * Creates a new instance in History of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History.createMany = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::createMany::Versions::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.History#destroyAll
             * @methodOf lbServices.Versions.History
             *
             * @description
             *
             * Deletes all History of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.History.destroyAll = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::delete::Versions::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.History#destroyById
             * @methodOf lbServices.Versions.History
             *
             * @description
             *
             * Delete a related item by id for History.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `fk` – `{*}` - Foreign key for History
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.History.destroyById = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::destroyById::Versions::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.History#findById
             * @methodOf lbServices.Versions.History
             *
             * @description
             *
             * Find a related item by id for History.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `fk` – `{*}` - Foreign key for History
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History.findById = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::findById::Versions::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.History#updateById
             * @methodOf lbServices.Versions.History
             *
             * @description
             *
             * Update a related item by id for History.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `fk` – `{*}` - Foreign key for History
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History.updateById = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::updateById::Versions::History"];
          return action.apply(R, arguments);
        };


        return R;
      }]);


  module
  .factory('LoopBackAuth', function() {
    var props = ['accessTokenId', 'currentUserId', 'rememberMe'];
    var propsPrefix = '$LoopBack$';

    function LoopBackAuth() {
      var self = this;
      props.forEach(function(name) {
        self[name] = load(name);
      });
      this.currentUserData = null;
    }

    LoopBackAuth.prototype.save = function() {
      var self = this;
      var storage = this.rememberMe ? localStorage : sessionStorage;
      props.forEach(function(name) {
        save(storage, name, self[name]);
      });
    };

    LoopBackAuth.prototype.setUser = function(accessTokenId, userId, userData) {
      this.accessTokenId = accessTokenId;
      this.currentUserId = userId;
      this.currentUserData = userData;
    };

    LoopBackAuth.prototype.clearUser = function() {
      this.accessTokenId = null;
      this.currentUserId = null;
      this.currentUserData = null;
    };

    LoopBackAuth.prototype.clearStorage = function() {
      props.forEach(function(name) {
        save(sessionStorage, name, null);
        save(localStorage, name, null);
      });
    };

    return new LoopBackAuth();

    // Note: LocalStorage converts the value to string
    // We are using empty string as a marker for null/undefined values.
    function save(storage, name, value) {
      try {
        var key = propsPrefix + name;
        if (value == null) value = '';
        storage[key] = value;
      } catch (err) {
        console.log('Cannot access local/session storage:', err);
      }
    }

    function load(name) {
      var key = propsPrefix + name;
      return localStorage[key] || sessionStorage[key] || null;
    }
  })
  .config(['$httpProvider', function($httpProvider) {
    $httpProvider.interceptors.push('LoopBackAuthRequestInterceptor');
  }])
  .factory('LoopBackAuthRequestInterceptor', ['$q', 'LoopBackAuth',
    function($q, LoopBackAuth) {
      return {
        'request': function(config) {
          // filter out external requests
          var host = getHost(config.url);
          if (host && host !== urlBaseHost) {
            return config;
          }

          if (LoopBackAuth.accessTokenId) {
            config.headers[authHeader] = LoopBackAuth.accessTokenId;
          } else if (config.__isGetCurrentUser__) {
            // Return a stub 401 error for User.getCurrent() when
            // there is no user logged in
            var res = {
              body: { error: { status: 401 }},
              status: 401,
              config: config,
              headers: function() { return undefined; },
            };
            return $q.reject(res);
          }
          return config || $q.when(config);
        },
      };
    }])

  /**
   * @ngdoc object
   * @name lbServices.LoopBackResourceProvider
   * @header lbServices.LoopBackResourceProvider
   * @description
   * Use `LoopBackResourceProvider` to change the global configuration
   * settings used by all models. Note that the provider is available
   * to Configuration Blocks only, see
   * {@link https://docs.angularjs.org/guide/module#module-loading-dependencies Module Loading & Dependencies}
   * for more details.
   *
   * ## Example
   *
   * ```js
   * angular.module('app')
   *  .config(function(LoopBackResourceProvider) {
   *     LoopBackResourceProvider.setAuthHeader('X-Access-Token');
   *  });
   * ```
   */
  .provider('LoopBackResource', function LoopBackResourceProvider() {
    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#setAuthHeader
     * @methodOf lbServices.LoopBackResourceProvider
     * @param {string} header The header name to use, e.g. `X-Access-Token`
     * @description
     * Configure the REST transport to use a different header for sending
     * the authentication token. It is sent in the `Authorization` header
     * by default.
     */
    this.setAuthHeader = function(header) {
      authHeader = header;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#getAuthHeader
     * @methodOf lbServices.LoopBackResourceProvider
     * @description
     * Get the header name that is used for sending the authentication token.
     */
    this.getAuthHeader = function() {
      return authHeader;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#setUrlBase
     * @methodOf lbServices.LoopBackResourceProvider
     * @param {string} url The URL to use, e.g. `/api` or `//example.com/api`.
     * @description
     * Change the URL of the REST API server. By default, the URL provided
     * to the code generator (`lb-ng` or `grunt-loopback-sdk-angular`) is used.
     */
    this.setUrlBase = function(url) {
      urlBase = url;
      urlBaseHost = getHost(urlBase) || location.host;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#getUrlBase
     * @methodOf lbServices.LoopBackResourceProvider
     * @description
     * Get the URL of the REST API server. The URL provided
     * to the code generator (`lb-ng` or `grunt-loopback-sdk-angular`) is used.
     */
    this.getUrlBase = function() {
      return urlBase;
    };

    this.$get = ['$resource', function($resource) {
      var LoopBackResource = function(url, params, actions) {
        var resource = $resource(url, params, actions);

        // Angular always calls POST on $save()
        // This hack is based on
        // http://kirkbushell.me/angular-js-using-ng-resource-in-a-more-restful-manner/
        resource.prototype.$save = function(success, error) {
          // Fortunately, LoopBack provides a convenient `upsert` method
          // that exactly fits our needs.
          var result = resource.upsert.call(this, {}, this, success, error);
          return result.$promise || result;
        };
        return resource;
      };

      LoopBackResource.getUrlBase = function() {
        return urlBase;
      };

      LoopBackResource.getAuthHeader = function() {
        return authHeader;
      };

      return LoopBackResource;
    }];
  });
})(window, window.angular);
