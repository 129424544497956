(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name navigation.controller:NavigationCtrl
   *
   * @description
   *
   */
  angular
    .module('navigation')
    .controller('NavigationCtrl', NavigationCtrl);

  function NavigationCtrl($injector, $rootScope, $localStorage, $location, clientConfig, $mdDialog, $mdSidenav, $window, $scope, $state, system, apiUrl, Books, Notifications, Teams, Users, Wraps) {
    var vm = this;
    vm.ctrlName = 'NavigationCtrl';
    vm.thisTeam = '';

    vm.system = system;
    vm.clientConfig = clientConfig;
    $scope.$storage = $localStorage;
    vm.apiUrl = apiUrl;

    vm.isActive = function (viewLocation) {
      var locationPath = $location.path().substring(0, 7) == "/reset/" ? $location.path().substring(0, 6) : $location.path();
      return viewLocation === locationPath;
    };

    if (Users.isAuthenticated()) {

      vm.hasPermissions = Wraps.hasPermissions;
      vm.hasAnyOfPermissionCat = Wraps.hasAnyOfPermissionCat;

      Users.find({ "filter": { "include": ["Roles", "Teams", "UsersStatus", "Divisions", { "PermissionGroups": [{ "PermissionGroupJoin": ["Permissions"] }] }] } }).$promise.then(function (results) {
        //Users.find({"filter": {"include": ["Teams", "Roles", "Divisions"]}}).$promise.then(function (results) {
        vm.userslist = results;
      });

      Teams.__get__notDeleted(function (results) {
        vm.teamslist = results;
      });


      if ($scope.$storage.loggedInUser && _.isNumber($scope.$storage.loggedInUser.loggedInAs) && $scope.$storage.loggedInUser.loggedInAs !== 0) {
        Users.find({ "filter": {
          "where": { "id": $scope.$storage.loggedInUser.loggedInAs },
          "include": ["Roles", "Teams", "UsersStatus", "Divisions", { "PermissionGroups": [{ "PermissionGroupJoin": ["Permissions"] }] }]
        } }, function (spoofedUser) {
            if (spoofedUser[0].PermissionGroups && spoofedUser[0].PermissionGroups.PermissionGroupJoin.length && spoofedUser[0].PermissionGroups.PermissionGroupJoin.length > 0) {
                var permissions = _.map(spoofedUser[0].PermissionGroups.PermissionGroupJoin, 'Permissions');
                delete spoofedUser[0].PermissionGroups;
                if (permissions && permissions.length > 0) {
                    spoofedUser[0].permissions = permissions;
                }
            }
          $scope.$storage.user = spoofedUser[0];
        });
      }

      Notifications.getCounts();
      vm.notificationCount = Notifications.count; //init the obj binding

      $rootScope.$on('NOTIFICATION_COUNT_UPDATE', function () {
        vm.notificationCount = Notifications.count;
      });

      vm.switchToUserDiag = function () {
          vm.showSwitchUserDiag([], []);
      };

      vm.showSwitchUserDiag = function () {
        $mdDialog.show({
          controller: 'NavigationCtrl',
          controllerAs: 'navigation',
          templateUrl: 'navigation/user-switch/user-switch.tpl.html',
          parent: angular.element(document.body),
          /*locals: { restrictedDocs: restrictedDocs, openBooksArray: openBooksArray },*/
          bindToController: true,
          clickOutsideToClose: true
        })
          .then(function () {
          }, function () {
          });
      }

      vm.switchUser = function (user) {
        vm.thisUser = user;
        $scope.$storage.user = user;
        $scope.$storage.loggedInUser.loggedInAs = user.id;
        var userInWorkflow;
        Users.update({ "where": { "id": $localStorage.loggedInUser.id } }, { "loggedInAs": user.id }, function () {
          if (user.PermissionGroups && user.PermissionGroups.PermissionGroupJoin.length && user.PermissionGroups.PermissionGroupJoin.length > 0) {
            var permissions = _.map(user.PermissionGroups.PermissionGroupJoin, 'Permissions');
            delete user.PermissionGroups;

            Wraps.user = vm.thisUser;
            if (permissions && permissions.length > 0) {
              Wraps.user.permissions = permissions;
            }
            $scope.$storage.user = Wraps.user;
          }

          /*_.forEach($scope.$storage.openTabs, function (openTab) {
            _.forEach(restrictedDocs, function (restrictedDoc) {
              if (restrictedDoc.bookId == openTab.bookId) {
                $rootScope.$emit('closeUnauthorisedTab', { "bookId": openTab.bookId });
              }
            });
          });*/

          if ($location.path().includes('/library')) {
            var WrapsBooks = $injector.get('WrapsBooks');
            WrapsBooks.getLibraryList();
            /*if(_.isNumber($state.params.bookId) && $state.params.bookId > 0) {
              WrapsBooks.updateBookById($state.params.bookId);
            }*/
            WrapsBooks.updateAllTabs();
          } else if ($location.path().includes('/home')) {
            $rootScope.$emit('updateHomeStats');
          }else if (!$location.path().includes('/home') && !$location.path().includes('/library')) {
            $window.location.reload();
          }
          Notifications.getCounts();
          $mdDialog.hide();
        });
      };

      vm.dialogCancel = function () {
        $mdDialog.cancel();
      };



      vm.userProfile = function () {
        vm.tabIndex = 0;
        $mdSidenav('right').toggle();
      };
      $scope.helpMethodLibFun = function (helpCode) {
        Wraps.displayHelpText(helpCode);
      }

      vm.navbarStatsDialog = function (tab) {
        $mdDialog.show({
          controller: 'NotificationsCtrl',
          controllerAs: 'notifications',
          templateUrl: 'library/notifications/notifications.tpl.html',
          parent: angular.element(document.body),
          locals: { activeContent: tab },
          bindToController: true,
          clickOutsideToClose: true
        })
          .then(function () {
            /*if (data) {
             var book = vm.bookById(activeContent.bookId);
             book.update();
             }*/
          }, function () {
            //vm.status = 'You cancelled the dialog.';
          });
      };
      vm.users = function () {
        $mdDialog.show(
          $mdDialog.alert()
            .title('User Area')
            .content('You do not have permissions to use this area')
            .ok('Close')
        );
      };

      vm.editAsMe = function () {
        Users.update({ "where": { "id": $localStorage.loggedInUser.id } }, { "loggedInAs": 0 }, function () {
          $scope.$storage.user = $scope.$storage.loggedInUser;
          Wraps.user = $scope.$storage.loggedInUser;
          vm.thisUser = /*angular.copy(*/$scope.$storage.loggedInUser/*)*/;
          Wraps.getCurrentUser(false).then(function () {
            vm.updateForUser();
            Notifications.getCounts();
            if (!$location.path().includes('/home') && !$location.path().includes('/library')) {
              $window.location.reload();
            }
          })
        });
      };

      vm.updateForUser = function () {
        if ($location.path().includes('/library')) {
          var WrapsBooks = $injector.get('WrapsBooks');
          WrapsBooks.getLibraryList();
          /*if(_.isNumber($state.params.bookId) && $state.params.bookId > 0) {
            WrapsBooks.updateBookById($state.params.bookId);
          }*/
          WrapsBooks.updateAllTabs();
        } else if ($location.path().includes('/home')) {
          $rootScope.$emit('updateHomeStats');
        }
        Notifications.getCounts();
      }

      vm.spoofingUser = function () {
        var mismatchedIds = false;
        if ($scope.$storage.user && $scope.$storage.loggedInUser &&
            $scope.$storage.user.id && $scope.$storage.loggedInUser.id) {
          mismatchedIds = $scope.$storage.user.id !== $scope.$storage.loggedInUser.id;
        }
        return mismatchedIds;

      }

      vm.checkUser = function () {
        vm.viewRestrictedDocs = false;
        _.forEach(vm.thisUser.PermissionGroups.PermissionGroupJoin, function (permission) {
          if (permission.permissionId == 54) {
            vm.viewRestrictedDocs = true;
          }
        })
      }

    }
  }
}());
