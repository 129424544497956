<md-dialog aria-label="Workflow"  class="workflowdialog"   ng-cloak>

    <md-toolbar ng-if-start="workflowdialog.type==='Reject'">
        <div class="md-toolbar-tools">
            <h2>Comments - {{workflowdialog.type}}</h2>
            <span flex></span>

            <md-button ng-click="workflowdialog.dialogCancel(false)" class="md-icon-button">
                <ng-md-icon  icon="close" style="fill: white;"  aria-label="Close dialog"></ng-md-icon>
            </md-button>
        </div>
    </md-toolbar>
    <md-dialog-content  class="dialog-content big" style="">

        <md-content class="md-padding">
            <form ng-submit="$event.preventDefault()" name="rejectForm" layout="column" style="padding: 20px;">


                <div layout="row"  layout-wrap>

                    <md-input-container flex="60">
                        <label>Rejection Category</label>
                        <md-select ng-model="workflowdialog.reject.category" placeholder="Rejection Category"  required  name="category" ng-change="workflowdialog.reject.reason = undefined">
                            <md-option   ng-value="item.category" ng-repeat="item in workflowdialog.rejectionReasons | unique:'category' | orderBy: 'name'" >{{item.Category.name}}</md-option>
                        </md-select>
                    </md-input-container>

                    <md-input-container flex="90" ng-if="workflowdialog.reject.category">
                        <label>Rejection Reason</label>
                        <md-select ng-model="workflowdialog.reject.reason" placeholder="Rejection Reason"  required  name="reason" multiple="true">
                            <md-option   ng-value="item.reason" ng-repeat="item in workflowdialog.rejectionReasons | filter: {category: workflowdialog.reject.category} | orderBy: 'name'" >{{item.Reason.name}}</md-option>
                        </md-select>
                    </md-input-container>


                </div>



                <!--<md-input-container class="md-block">-->
                    <!--<label>Rejection Category</label>-->
                    <!--<md-select name="reason1" ng-model="workflowdialog.reject.category" multiple required>-->



                        <!--<md-option ng-repeat="category in workflowdialog.rfcReasons | filter: { type: 'rfrc'}" value="{{category.id}}">{{category.name}}</md-option>-->
                    <!--</md-select>-->

                <!--</md-input-container>-->

                <!--<md-input-container class="md-block">-->
                    <!--<label>Rejection Reason</label>-->
                    <!--<md-select name="reason2" ng-model="workflowdialog.reject.reason" multiple required>-->
                        <!--<md-option ng-repeat="reason in workflowdialog.rfcReasons | filter: { type: 'rfr'}" value="{{reason.id}}">{{reason.name}}</md-option>-->


                    <!--</md-select>-->

<!--&lt;!&ndash;{{workflowdialog.reject.reason}}&ndash;&gt;-->




                <!--</md-input-container>-->





                <div layout="row" layout-wrap>
                    <div flex="70" layout="row" layout-wrap>
                        <div flex="100">
                            <md-input-container class="md-block">
                                <label>Rejection message</label>
                                <textarea name="message" ng-model="workflowdialog.reject.message"   rows="5" required></textarea>
                            </md-input-container>
                            <div class="errors" ng-messages="rejectForm.message.$error" ng-if="rejectForm.$dirty">
                                <div ng-message="required">Required</div>
                            </div>
                        </div>



                        <ul class="file-ui">
                            <li ng-repeat="f in workflowdialog.reject.filesList" style="font:smaller" class="file-li animate-if">{{f.name}}
                                <div class="file-prog" ng-mouseenter="f.hovered = true" ng-mouseleave="f.hovered = false">
                                    <!--<md-progress-circular class="animate-if" ng-if="f.determinateValue < 100" md-mode="determinate" value="{{f.determinateValue}}" md-diameter="30"></md-progress-circular>-->
                                    <md-progress-circular class="animate-if" ng-if="!f.loaded" md-mode="indeterminate" md-diameter="30"></md-progress-circular>
                                    <!--<span class="animate-if" ng-if="f.determinateValue < 100" >{{f.determinateValue}}%</span>-->
                                    <ng-md-icon class="animate-if file-complete" ng-if="f.loaded"  icon="check_circle" size="30"  aria-label="Upload complete"></ng-md-icon>
                                    <ng-md-icon class="file-remove" ng-if="f.hovered"  icon="cancel" size="30"  aria-label="Remove file" ng-click="workflowdialog.removeFile(workflowdialog.reject.filesList, $index)"></ng-md-icon>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div flex="5"></div>
                    <div flex="25">
                        <div ngf-drop ngf-select ng-model="workflowdialog.files" class="drop-box"
                             ngf-drag-over-class="'dragover'" ngf-multiple="true" ngf-allow-dir="true"
                             accept="{{workflowdialog.safeFileList}}"
                             ngf-pattern="workflowdialog.safeFileList">
                            <ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop files here or<br /><strong style="text-decoration: underline;">Click to upload</strong>
                        </div>
                        <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>



                    </div>



                </div>


            </form>

        </md-content>

    </md-dialog-content>

    <md-dialog-actions ng-if-end>
        <span flex></span>
        <md-button class="md-grey"  ng-click="workflowdialog.dialogCancel(false)">Cancel<ng-md-icon icon="close" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>
        <md-button  ng-disabled="(workflowdialog.isLoading || rejectForm.$invalid)" class="md-green"  ng-click="workflowdialog.saveReject(workflowdialog.reject);">Submit Rejection<ng-md-icon icon="done" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>

    </md-dialog-actions>

</md-dialog>
