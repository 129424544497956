(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.approvedialog.controller:ApprovedialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.approvedialog')
    .controller('ApprovedialogCtrl', ApprovedialogCtrl);

  function ApprovedialogCtrl(
	$filter,
	$localStorage, 
	$location, 
	$mdDialog, 
	$q, 
	$scope, 
	$timeout,  
	apiUrl, 
	book, 
	Books,
	child, 
	Containers, 
	Contents, 
	Events, 
	History, 
	Messages,
    ProductIds,
	needFile, 
	pdfUrl,  
	Structures, 
	system,  
	Upload, 
	Users, 
	Versions, 
	Wraps,
  Notifications,
  WrapsBooks
	) {
    var vm = this;
    vm.ctrlName = 'ApprovedialogCtrl';

    vm.action = "Approve";

    $scope.$storage = $localStorage;


    vm.safeFileList = Wraps.safeFileList;
    
    vm.book.bookCode = book.bookCode;
    
    vm.filenameInCodeCheck = WrapsBooks.filenameInCodeCheck;


    if(!Users.isAuthenticated()){$location.path('/');}

    vm.dialogHide = function(data) {
      $mdDialog.hide(data);
    };
    vm.dialogCancel = function() {
      $mdDialog.cancel();
    };


    vm.nl2br = function(string){
      try {
        return string.replace(/\n/g, '<br>');
      }catch(err){
        //return false;
      }
    }

    vm.json2Obj = function(obj){

      try{ obj = angular.extend(obj,angular.fromJson(obj.versions.versiondata));}
      catch (err){    console.log(err.name + ': "' + err.message +  '" occurred when assigning string.'); return false;}
      console.log(obj.benefit);
      return obj;

    }


    vm.isFulfilment = false;
    vm.uploadMandatory = false;


    if(book.currentWorkflow.role == 9 || book.currentWorkflow.role == 10 || book.currentWorkflow.role == 13 ) {

      vm.uploadMandatory = true;
    }
    if (book.currentWorkflow.role === 6 && (book.currentWorkflow.content === 'artwork' || book.currentWorkflow.content === 'final') ||
        book.currentWorkflow.content === 'brief' && (book.currentWorkflow.role === 2 || book.currentWorkflow.role === 1)) {
        vm.action = 'Submit';
    }


    //init publishing roles
    if(book.currentWorkflow.role == 11 || book.currentWorkflow.role == 12 || book.currentWorkflow.role == 13 || book.currentWorkflow.role == 14 || book.currentWorkflow.role == 15){
      vm.isPublishing = true;
      //vm.publishTypes = [{name:"test 1"},{name:"test 2"},{name:"test 3"}];
      vm.publishTypes = [];
      if(book.currentWorkflow.role == 11){
        _.forEach(book.production, function(value,key) {
          vm.publishTypes.push(_.find(Wraps.publishTypes, { 'id': _.toInteger(key) }));
        });
        //console.log(vm.publishTypes);
      }else if(book.currentWorkflow.role == 12){
        vm.isFulfilment = true;
        _.forEach(book.fulfilment, function(value,key) {
          vm.publishTypes.push(_.find(Wraps.publishTypes, { 'id': _.toInteger(key) }));
        });
        //console.log(vm.publishTypes);
      }else if(book.currentWorkflow.role == 13){
        _.forEach(book.inSitu, function(value,key) {
          vm.publishTypes.push(_.find(Wraps.publishTypes, { 'id': _.toInteger(key) }));
        });
        //console.log(vm.publishTypes);
      }else if(book.currentWorkflow.role == 14){
        _.forEach(book.digital, function(value,key) {
          vm.publishTypes.push(_.find(Wraps.publishTypes, { 'id': _.toInteger(key) }));
        });
        //console.log(vm.publishTypes);
      }else if(book.currentWorkflow.role == 15){
        _.forEach(book.support, function(value,key) {
          vm.publishTypes.push(_.find(Wraps.publishTypes, { 'id': _.toInteger(key) }));
        });
        //console.log(vm.publishTypes);
      }

      vm.hasChildren  =  false;
    }
    else if((child.Children && child.Children.length > 0) && (book.approvalStatus === child.approvalStatus && book.status === child.status)){vm.hasChildren  =  true;}
    else{vm.hasChildren  =  false;}
    

    if(child ==="all"){vm.showApproveAll  =  true;}
    else{vm.showApproveAll  =  false;}

    var updateList = {structureIdList:[]};
    
    $scope.$watch('approvedialog.checkboxes', function (newCheckboxes, oldVal) {
      //console.log(child);
      if(child ==="all"){var thisApprovalStatus = book.approvalStatus; }else{var thisApprovalStatus = child.approvalStatus;}
      if (book.approvalList[thisApprovalStatus] && (book.approvalList[thisApprovalStatus].role == 9 || book.approvalList[thisApprovalStatus].role == 10 || book.approvalList[thisApprovalStatus].role == 11 || book.approvalList[thisApprovalStatus].role == 12 || book.approvalList[thisApprovalStatus].role == 13) || book.currentWorkflow.role == 14 || book.currentWorkflow.role == 15 ){
        if (newCheckboxes) {
          //console.log('checkboxes', newCheckboxes);
          var tempBook = angular.copy(book);
          var tempChild = angular.copy(child);
          updateList = {structureIdList: []};


          if (newCheckboxes.this) {
            setChildStatuses(tempChild, tempBook, tempBook.approvalStatus, tempBook.status);
            //console.log('tempChild', tempChild);
          }
          if (newCheckboxes.all) {
            getAll(tempChild, tempBook, tempBook.approvalStatus, tempBook.status);
          }
          if (newCheckboxes.allOthers) {
            getAll(tempBook, tempBook, tempBook.approvalStatus, tempBook.status);
          }
          //console.log('updateList', updateList);

          vm.failedCount = 0;

          checkAll(tempBook, tempBook, updateList);
          //console.log('vm.failedCount', vm.failedCount);

          if (vm.failedCount === 0) {
            needFile = true;
            vm.needFile = true;
          } else {
            needFile = false;
            vm.needFile = false;
          }
        }
      }
    }, true);


    vm.approveItems = function(checkboxes,needFile){
      vm.isApproving = true;
      updateList = {structureIdList:[]};
      if(checkboxes) {

        if (checkboxes.all) {
          getAll(child, book, book.approvalStatus, book.status);
        }
        if (checkboxes.this) {
          setChildStatuses(child, book, book.approvalStatus, book.status);
        }
        if (checkboxes.allOthers) {
          getAll(book, book, book.approvalStatus, book.status);
        }

        if (updateList.structureIdList.length > 0) {
          book.isLoading = true;
          updateStructuresAndEvents(book.bookId, updateList).then(function (response) {

            //console.log("updateStructuresAndEvents response",response);
            //console.log("updateStructuresAndEvents book",book);
            //console.log(vm.filesList);
            if (vm.filesList.length >= 1 || vm.message) {

              Events.create({
                "eventId": 0,
                "bookId": book.bookId,
                "userId": $scope.$storage.user.id,
                "eventType": "Approve Files",
                "eventData": Wraps.toJson({"message": vm.message, "filesList": vm.filesList})
              });
              //console.log("file done!");

            }
              WrapsBooks.setBookStatuses(book).then(function (emailAction) {
                book.isLoading = false;
              $mdDialog.hide();
            });
          });
        } else {
          $mdDialog.hide();
        }
      }
      $mdDialog.hide();





    }

   // {"artwork":{
    // "description":"<p>Here is the last file1234</p>\n","filesList":
    // [{"container":"wraps-files",
    // "fileLocation":"artwork/1493119101051",
    // "fileName":"Stone_floor_01.jpg",
    // "fileSize":4838115,
    // "ngfName":"artwork/1493119101051/Stone_floor_01.jpg",
    // "determinateValue":100,"hovered":false}]
    // }}










    var getAll = function (parent, thisBook,approvalStatus,status) {
      _.forEach(parent.Children, function (item) {
        if(approvalStatus === item.approvalStatus && status === item.status) {
          setChildStatuses(item, thisBook, approvalStatus, status);
        }
        getAll(item, thisBook, approvalStatus, status);
      });
    }

    var checkAll = function (parent, thisBook, thisUpdatelist) {
      _.forEach(parent.Children, function (item) {
        if(thisBook.approvalList[thisBook.approvalStatus] && _.indexOf(thisUpdatelist.structureIdList ,item.structureId)<0 && !(item.approvalStatus >= thisUpdatelist.approvalStatus && item.status >= thisUpdatelist.status) && thisBook.approvalList[thisBook.approvalStatus].content === thisBook.approvalList[item.approvalStatus].content ){
          vm.failedCount++;
        }
        checkAll(item, thisBook, thisUpdatelist);
      });
    }



    var updateStructuresAndEvents = function(bookId, thisUpdateList) {
      var deferred = $q.defer();
      Structures.updateStructuresAndEvents(
          {
            "bookId": bookId,
            "status": thisUpdateList.status,
            "approvalStatus": thisUpdateList.approvalStatus,
            "eventType": "Approved",
            "eventData": "Content Approved - Status:" + thisUpdateList.status + "\nApprover:" + thisUpdateList.approvalStatus,
            "userId": $scope.$storage.user.id,
            "structureIdArray": thisUpdateList.structureIdList
          }
      ).$promise.then(function (response) {
        deferred.resolve(response);

      });
      return deferred.promise;
    }




    var setChildStatuses = function(thisChild, thisBook,approvalStatus,status){
      if(thisBook.approvalList[thisChild.approvalStatus]) {
          var type = thisBook.approvalList[thisChild.approvalStatus].content;
      }

      // console.log("type",type);
      // console.log("type2",thisBook.approvalList[thisChild.approvalStatus].content);
      // console.log("thisChild.approvalStatus",thisChild.approvalStatus);
      // console.log("thisChild.approvalStatus === _.findLastIndex(thisBook.approvalList,{'content':type})",thisChild.approvalStatus === _.findLastIndex(thisBook.approvalList,{'content':type}));

      if(type && thisChild.approvalStatus === _.findLastIndex(thisBook.approvalList,{'content':type})){  //child approval is end of TYPE workflow
        thisChild.status = 1; //set child to ready
      }else{
        thisChild.approvalStatus++; //move child up
      }
      updateList.status = thisChild.status;
      updateList.approvalStatus = thisChild.approvalStatus;
      updateList.structureIdList.push(thisChild.structureId);
      return true;


    }

    var productIdsArray = [];
    _.forEach(book.productIdsSelectedArray, function (toggled, id) {
      if(toggled === true) {
        productIdsArray.push(id);
      }
    })

    ProductIds.find({'filter':{'where':{'productId':{'inq':productIdsArray}}, "include": ["Insurers"]}}, function (results) {
      vm.selectedProductIds = results;
    });

    
    if(book.currentWorkflow.content == "artwork" || book.currentWorkflow.content == "final"){
      console.log("book.currentWorkflow.content",book.currentWorkflow.content);
      vm.beforeList = $filter('filter')(book.Children,{versions: {Contents: {contentTypeId: 10}}})[0] ? $filter('filter')(book.Children,{versions: {Contents: {contentTypeId: 10}}})[0].artwork.filesList : [];
      vm.afterList = $filter('filter')(book.Children,{versions: {Contents: {contentTypeId: 13}}})[0] ? $filter('filter')(book.Children,{versions: {Contents: {contentTypeId: 13}}})[0].artwork.filesList : [];
    }

    






    vm.removeFile = function(array, index){
      array.splice(index, 1);
    }

    var timestamp = new Date().getTime();
    var S3_BUCKET_URL = apiUrl+'/Containers/wraps-files/upload';
    var itemType = "approveFiles";

    $scope.$watch('approvedialog.files', function (files) {
      //console.log('upload watch',test);
      vm.upload(files).then(function(statuses){
        vm.isLoading = false;
      });
    });

    vm.log = '';

    vm.upload = function (files) {
      if(!vm.filesList){vm.filesList = [];}
      var deferred = $q.defer();

      if (files && files.length) {
        _.forEach(files, function (file) {
          if (!file.$error) {
            vm.isLoading = true;
            //console.log(file);

            file.container = 'wraps-files';
            file.fileLocation = itemType+'/'+timestamp;
            file.fileName = file.name;
            file.fileSize = file.size;
            vm.filesList.push(file);


            //TODO add timeout to animate files

            var newName = file.fileLocation + '/' + file.name;
            Upload.rename(file, newName );
            Upload.upload({
              url: S3_BUCKET_URL,
              file: file
            }).then(function (resp) {
                file.loaded = true;

                deferred.resolve();
              },
              null,
              function (evt) {
                file.determinateValue = parseInt(100.0 *   evt.loaded / evt.total);
                //TODO get this working
                //console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '% file :'+ evt);
              });


          }
        });
      }
      return deferred.promise;
    };


    vm.hasChecks = function(checks){
      return !(_.indexOf(_.values(checks), true)>=0);
    }





  }
}());
