(function () {
  'use strict';

  /* @ngdoc object
   * @name library.workflowdialog
   * @description
   *
   */
  angular
    .module('library.workflowdialog', [
      'ui.router'
    ]);
}());
