<div layout="row"  layout-wrap>
    <div flex="10">
        <md-switch ng-model="inputLevel.book.levels[inputLevel.level].active" aria-label="{{inputLevel.level}} active"   ng-disabled="inputLevel.disabled"></md-switch>
    </div>
    <div flex="40">
        <md-input-container md-no-float>
            <label>{{inputLevel.level|titlecase}} Label</label>
            <input ng-model="inputLevel.book.levels[inputLevel.level].name" placeholder="{{inputLevel.level}} label" aria-label="{{inputLevel.level}} label"  ng-disabled="inputLevel.disabled">
        </md-input-container>
    </div>
</div>