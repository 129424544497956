<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">

    <div class="md-whiteframe-z1 default-box" style="" ng-if="permissionmanagement.hasAccess" >
        

            <md-progress-circular style="position: absolute; top: 0; left: 50%; margin-left: -50px;"  md-diameter="100" md-mode="indeterminate" ng-show="permissionmanagement.isLoading"></md-progress-circular>

            <md-data-table-toolbar>
            	<h2  flex wraps-tooltip="permissions-title">Permission Management</h2>
            	<md-button class="md-raised md-accent" ng-if="permissionmanagement.assignPermissionsToGroups === false" ng-click="permissionmanagement.openEdit()" wraps-tooltip="permissions-add-row-btn">Add Row</md-button>
            </md-data-table-toolbar>
            <!--<md-data-table-toolbar>-->
            <div>
                <md-tabs md-selected="permissionmanagement.selectedTabIndex" md-border-bottom md-autoselect md-swipe-content md-dynamic-height>
					<md-tab>
						<md-tab-label>
							<span wraps-tooltip="permissions-tab-assignment">Assign permissions to group</span>
						</md-tab-label>
						<md-tab-body>
							<form name="assignmentForm" class="assign-permissions-tab" role="form" id="assignmentForm" ng-model="permissionmanagement.assignmentForm">
									<p><strong>Note:</strong> These changes will take effect from next login or reloading of the WRAPS application.</p>
									<md-input-container wraps-tooltip="permissions-assignment-group">
										<md-select   ng-model="permissionmanagement.thisPermissionGroup" ng-change="permissionmanagement.getCurrentPermissions()" placeholder="Select group" style="width:300px;">
											<md-option ng-value="permissionGroup.groupId" ng-repeat="permissionGroup in permissionmanagement.permissionGroups">{{permissionGroup.groupName}}</md-option>
										</md-select>
									</md-input-container>
									<div ng-repeat="categories in permissionmanagement.PermissionCategories| orderBy:'permissionCategoryName'" class="permission-group-container">
										<md-checkbox class="permission-group-label" ng-model="permissionmanagement.isAllSelected['cat_' + categories.permissionCategoryId]" ng-click="permissionmanagement.toggleAll(categories.permissionCategoryId)" aria-label="{{categories.permissionCategoryName}}" wraps-tooltip="permissions-assignment-category">{{categories.permissionCategoryName}}</md-checkbox>
										<div class="permission-group" layout="row" layout-wrap>
											<div flex="33" ng-repeat="permission in permissionmanagement.permissions| filter:{'permissionStatus': 1}| orderBy:'permissionName'" ng-if="permission.permissionCategoryId == categories.permissionCategoryId">
												<md-checkbox ng-model="permission.selected" area-label="{{permission.permissionName}}" ng-change="permissionmanagement.optionToggled(permission.permissionCategoryId)"  ng-disabled="permission.permissionCode == 'adminPermissions'" wraps-tooltip="permissions-assignment-permission">{{permission.permissionName}}</md-checkbox>
											</div>
										</div>
									</div>

									<div class="permission-group-container" ng-if="permissionmanagement.hasMisc">
										<md-checkbox class="permission-group-label" ng-model="permissionmanagement.isAllSelectedMisc" ng-click="permissionmanagement.toggleAllMisc()" aria-label="Miscellaneous" wraps-tooltip="permissions-assignment-category">Miscellaneous</md-checkbox>
										<div class="permission-group" layout="row" layout-wrap>
											<div flex="33" ng-repeat="permission in permissionmanagement.permissions| filter:{'permissionCategoryId': null, 'permissionStatus': 1} | orderBy:'permissionName'">
												<md-checkbox ng-model="permission.selected" area-label="{{permission.permissionName}}" ng-change="permissionmanagement.optionToggled(permission.permissionCategoryId)" wraps-tooltip="permissions-assignment-permission">{{permission.permissionName}}</md-checkbox>
											</div>
										</div>
									</div>
									<div layout="row" layout-align="end start">
										<md-progress-circular  style=" display:inline;  margin-left: 10px; top:-4px;" class="animate-if"  md-diameter="30" md-mode="indeterminate" ng-if="permissionmanagement.isSaving"></md-progress-circular>
										<p ng-if="permissionmanagement.saved == 'success' && assignmentForm.$pristine">Saved changes successfully</p>
										<p ng-if="permissionmanagement.saved == 'error' && assignmentForm.$pristine">Unable to save changes</p>
										<md-button class="md-raised md-accent" ng-if="permissionmanagement.assignPermissionsToGroups === true" ng-disabled="assignmentForm.$pristine || !permissionmanagement.thisPermissionGroup || permissionmanagement.isSaving" ng-click="permissionmanagement.savePermissionsToGroup(assignmentForm)" wraps-tooltip="permissions-assignment-save">Save Permissions</md-button>
									</div>
							</form>
						</md-tab-body>
					</md-tab>
				  <md-tab ng-repeat="(tableName, tableProperties) in permissionmanagement.tables"
						  ng-disabled="tableProperties.disabled">
					  <md-tab-label><span wraps-tooltip="permissions-tab">{{tableProperties.friendlyName}}</span></md-tab-label>
					  <md-tab-body>
						  <md-data-table-container ng-if="permissionmanagement.tableName">
							  <md-progress-linear md-mode="query" ng-show="permissionmanagement.rows.length == 0"></md-progress-linear>
							  <table md-data-table  > <!--md-row-select="selected"-->
								  <thead>
								  <tr>
									  <!--<th order-by="id" numeric>ID</th>-->
									  <th style="width:80px; text-align:center;">
										  <md-button ng-if="$storage.selectedRow == 0" type="button" class="md-icon-button md-accent" ng-disabled="permissionmanagement.checkObj(permissionmanagement.deleteArray)" ng-click="permissionmanagement.deleteRows(permissionmanagement.deleteArray)" aria-label="Delete Selected Rows">
											  <ng-md-icon icon="delete" wraps-tooltip="permissions-delete-button"></ng-md-icon>
										  </md-button>
									  </th>
									  <th ng-repeat="col in permissionmanagement.cols" ng-click="sortBy(col.name)" wraps-tooltip="permissions-table-heading">{{col.name | titlecase}} <ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: permissionmanagement.orderBy === col.name || permissionmanagement.orderBy[0] === col.name, reverse: permissionmanagement.reverse}"></ng-md-icon></th>
									  <th style="width: 80px;"></th>
								  </tr>
								  </thead>


								  <tbody>
								  <tr ng-repeat="row in permissionmanagement.rows|orderBy:permissionmanagement.orderBy:permissionmanagement.reverse track by row[permissionmanagement.pk]" class="data-rows" wraps-tooltip="permissions-table-row-data" ng-if="permissionmanagement.tableName == tableName">
									  <td style="text-align: center;">
										  <md-checkbox ng-if="$storage.selectedRow == 0" ng-model="permissionmanagement.deleteArray[row[permissionmanagement.pk]]" ng-click="$event.stopPropagation()" wraps-tooltip="permissions-table-select-row">
											  <span ng-hide="true">Select Row</span>
										  </md-checkbox>
									  </td>
									  <td ng-repeat="col in permissionmanagement.cols">
										  <span ng-if="permissionmanagement.tables[permissionmanagement.tableName].dropDown.childColName == col.name">{{row[permissionmanagement.tables[permissionmanagement.tableName].dropDown.tableName][permissionmanagement.tables[permissionmanagement.tableName].dropDown.childColName]}}</span>
										  <span ng-if="permissionmanagement.tables[permissionmanagement.tableName].dropDown.childColName != col.name">{{row[col.name]}}</span></td>
									  <td>
										  <div class="md-actions" style="float:right">
											  <md-button aria-label="Edit Row" class="md-icon-button md-default" ng-click="$storage.selectedRow  = row[permissionmanagement.pk]; permissionmanagement.openEdit(row)" wraps-tooltip="permissions-table-edit-row"><ng-md-icon icon="mode_edit" ></ng-md-icon></md-button>
										  </div>
									  </td>
								  </tr>


								  </tbody>


							  </table>
						  </md-data-table-container>
					  </md-tab-body>
				  </md-tab>
				
				</md-tabs>
            </div>
            <!--</md-data-table-toolbar>-->

            <!--<md-data-table-pagination md-limit="users.usersQuery.limit" md-page="users.usersQuery.page" md-total="{{users.filteredUsers.length}}"></md-data-table-pagination>-->





	</div>
	<div class="md-whiteframe-z1 default-box" style="" ng-if="permissionmanagement.hasAccess === false">
		<md-data-table-toolbar><h2  flex>Access denied<br /><br /><br /></h2>

		</md-data-table-toolbar>
	</div>
	<div class="md-whiteframe-z1 default-box" style="" ng-if="permissionmanagement.hasAccess === null" >
		 <md-data-table-toolbar><h2  flex><br/></h2></md-data-table-toolbar>
            <md-progress-circular style="position: absolute; top: 10px; left: 50%; margin-left: -50px;"  md-diameter="80" md-mode="indeterminate"></md-progress-circular>
	</div>
	
	<md-sidenav class="md-sidenav-right md-whiteframe-z3 library-sidenav" md-component-id="right" style="position: fixed;">
		<permissionmanagement-row-edit></permissionmanagement-row-edit>
	</md-sidenav>

</md-content>
