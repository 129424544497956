<md-content layout-padding class="enclosures-tab">
    <h4>Enclosures</h4>
    <p>Enter the enclosures to be linked to this letter in the appropriate fields; leave the field
        blank if there is no correlating enclosure.<br /> Enclosures should be written either as per the
        Wraps ID, or in the case of generic documents, as per their Wraps name.<br/>
        Note that these enclosures should be correct at the time of publishing.</p>


    <div layout="row" layout-wrap>
        <table flex="100">
            <tbody>
            <tr ng-repeat="enclosureType in tabEnclosures.enclosureTypes">
                <td>
                    <span style="font-size:0.9em">{{$index + 1}} &nbsp; {{enclosureType.enclosureTypeName}}</span>
                </td>
                <td style="width:75%">
                    <md-input-container style="width:100%">
                        <md-autocomplete
                                md-selected-item="selectedItem"
                                md-search-text="tabEnclosures.book.enclosures['enclosure_'+enclosureType.enclosureTypeId]"
                                md-delay="200"
                                md-min-length="3"
                                md-items="item in tabEnclosures.getBooksForEnclosureType(tabEnclosures.book.enclosures['enclosure_'+enclosureType.enclosureTypeId], enclosureType.enclosureDocTypeId)"
                                md-item-text="item.bookCode"
                                ng-disabled="tabEnclosures.disabled">
                            <span md-highlight-text="searchText">{{item.bookCode}}</span>
                        </md-autocomplete>
                    </md-input-container>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</md-content>
