(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name drive.controller:DriveCtrl
   *
   * @description
   *
   */
  angular
    .module('drive')
    .controller('DriveCtrl', DriveCtrl);

  function DriveCtrl($scope, $state, $timeout, Books, DocTypes, Drive, Wraps) {
    var vm = this;
    vm.ctrlName = 'DriveCtrl';
    vm.hasPermissions = Wraps.hasPermissions;
    vm.hasAccess = null;
    vm.data = Drive.data;
    vm.getBook = function (id) {
      vm.isLoading = true;
      Drive.getBook(id).then(function () {
        vm.isLoading = false;
      });
    };

    // Note this module uses state params to display the folder structure. $stateParams = {category:null,dtype:null}  <== Default
    vm.params = $state.params;

    vm.uiOnParamsChanged = function (newParams) {
      vm.isLoading = true;
      Drive.getRows(newParams, vm.params).then(function () {
        vm.isLoading = false;
      });
    };

    vm.isLoading = true;
    Drive.getRows(vm.params, vm.params).then(function () {
      vm.isLoading = false;
    });

    Wraps.fetchedUser.then(function () {
      vm.hasAccess = vm.hasPermissions('viewDrive');
    });

    $scope.sortBy = function (propertyName) {
      vm.reverse = (vm.orderBy === propertyName) ? !vm.reverse : false;
      vm.orderBy = propertyName;
    };

    vm.toggleInsurer = function (ins) {
      if (ins === vm.params.insurer && vm.params.dtype === null) {
        $state.go('.', {insurer: null, product: null, category: null, dtype: null, doc: null});
        // close active insurer
      } else {
        vm.openInsurer(ins);
      }
    };
    vm.toggleProduct = function (prod) {
      if (prod === vm.params.product && vm.params.dtype === null) {
        $state.go('.', {product: null, category: null, dtype: null, doc: null});
        // close active product
      } else {
        vm.openProduct(prod);
      }
    };
    vm.toggleCategory = function (cat) {
      if (cat === vm.params.category && vm.params.dtype === null) {
        $state.go('.', {category: null, dtype: null, doc: null});
        // close active category
      } else {
        vm.openCategory(cat);
      }
    };

    vm.openInsurer = function (ins) {
      $state.go('.', {insurer: ins, product: null, category: null, dtype: null, doc: null});
      // open insurer
    };
    vm.openProduct = function (prod) {
      $state.go('.', {product: prod, category: null, dtype: null, doc: null});
      // open product
    };
    vm.openCategory = function (cat) {
      $state.go('.', {category: cat, dtype: null, doc: null});
      // open category
    };

    vm.openDocType = function (documentType) {
      $state.go('.', {dtype: documentType, doc: null});
    };
    vm.driveHome = function () {
      $state.go('.', {category: null, dtype: null, doc: null, insurer: null, product: null});
    };
    vm.openDoc = function (docId) {
      $state.go('.', {doc: docId});
    };

    vm.openDocInLibrary = function (bookId) {
      $state.go('library', {bookId: bookId});
    };

    vm.getBook = function (bookId) {
      Drive.getBook(bookId);
    };
  }
}());
