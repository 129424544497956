(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name users.controller:UsersCtrl
   *
   * @description
   *
   */
  angular
    .module('users')
    .controller('UsersCtrl', UsersCtrl);

  function UsersCtrl($localStorage, $location, $mdDialog, $mdSidenav, $q, $scope, Departments, Divisions, Locations, PermissionGroups, Roles, Subscriptions, Teams, Users, UsersStatus, Wraps) {
    var vm = this;
    vm.ctrlName = 'UsersCtrl';

    $scope.$storage = $localStorage;
    if(!Users.isAuthenticated()){$location.path('/');}

	vm.existingEmail = '^((?!test@example\.com).)*$';
	vm.hasAccess = null;
	vm.hasPermissions = Wraps.hasPermissions;

	Wraps.fetchedUser.then(function(){
    	vm.hasAccess = vm.hasPermissions('adminUsers');
	});

    vm.roles = Roles.find({});
    PermissionGroups.__get__notDeleted(function (perms) {

      if($scope.$storage.user.permissionGroup !== 2 ){  //WRAPS Admin - remove team
        _.remove(perms, function(p) {
          return p.groupId == 2;
        });
      }
      vm.permissionGroups = perms;
    });

    Divisions.__get__notDeleted(function (divisions) {
      vm.divisions = divisions;
    });

    vm.departments = Departments.__get__notDeleted();
    vm.teams = Teams.find({"filter":{"include":"Departments", "where":{"teamStatus":1}}});
    vm.locations = Locations.__get__notDeleted();
    vm.usersStatus = UsersStatus.find({});

    vm.compareJson = function(objA, objB){
      return (Wraps.toJson(objA) == Wraps.toJson(objB));
    }

    vm.updateUsers = function (callback) {
      vm.isLoading = true;
      Users.find({filter: {include: ['Departments', 'Teams', 'Locations', 'Roles', 'Divisions']}}, function (userResponse) {
        if($scope.$storage.user.permissionGroup !== 2 ){  //WRAPS Admin - remove users
          _.remove(userResponse, function(u) {
            return u.permissionGroup == 2;
          });
        }
        vm.users = userResponse;
        vm.isLoading = false;
        // commented out waiting for latam
        //Subscriptions.find({filter: {where: {"divisionId": $localStorage.loggedInUser.divisionId}}}).$promise.then(function (subscriptions) {
        Subscriptions.find().$promise.then(function (subscriptions) {
          Users.find({filter: {where: {and: [{"divisionId": $localStorage.loggedInUser.divisionId}, {status: null}, {permissionGroup:{ nlike: "2"}}]}}}).$promise.then(function (userCount) {
            vm.noOfSubs = _.sumBy(subscriptions, 'subscriptions');
            vm.noOfUsers = parseInt(userCount.length);
          });
        });

        if(typeof callback === 'function'){callback();}
      });
    }

    vm.updateUsers(function(){ vm.filterUsers = {};});


    vm.saveRow = function (user) {
      vm.isLoading = true;
      user.lastUpdated = moment.utc().format(Wraps.databaseDateFormat);
      Users.prototype$updateAttributes({id: user.id}, user, function(){
        vm.updateUsers(function(){$scope.$storage.selectedUser = 0;});
      },
      function(){
        //error
        }
      );
    };

    vm.openRightMenu = function (mode, user) {
      if(vm.noOfUsers > vm.noOfSubs && mode == 'new') {
        var confirm = $mdDialog.confirm()
          .title('Licence number exceeded')
          .htmlContent("You currently have <strong>" + (vm.noOfSubs - vm.noOfUsers) + "</strong> users licences left, do you wish to continue anyway?")
          .ok('Add user')
          .cancel('Cancel');

        $mdDialog.show(confirm)
          .then(function () {
            vm.userDetails = angular.copy(user);
            vm.mode = mode;
			vm.existingEmail = '^((?!test@example\.com).)*$';
            $mdSidenav('right').toggle();

          }, function () {

          });
      }else {
        vm.userDetails = angular.copy(user);
        vm.mode = mode;
		vm.existingEmail = '^((?!test@example\.com).)*$';
        $mdSidenav('right').toggle();
      }
    };

    vm.closeRightMenu = function () {
      vm.tabIndex = 0;
	  $scope.usersForm.$setUntouched();
	  $scope.usersForm.$setPristine();
	  vm.userDetails = {};
		
      $mdSidenav('right').close()
        .then(function () {
          //console.log(vm);
        });
    };


    vm.saveUser = function (userDetails, usersForm) {
      //userDetails.divisionId = $localStorage.loggedInUser.divisionId;
      if(userDetails.password == ""){delete userDetails.password;}
      delete userDetails.lastUpdated;
      if(userDetails.status === "null"){userDetails.status = null;}
      if(userDetails.limitDocs){userDetails.limitDocs = 1;}else{userDetails.limitDocs = null;}
      if((userDetails.status === 2 || userDetails.status === '2') && userDetails.dateDisabled === null){userDetails.dateDisabled = moment().format("YYYY-MM-DD HH:mm:ss")}else if(userDetails.status !== 2 && userDetails.status !== '2'){ userDetails.dateDisabled = null}
      Users.prototype$updateAttributes({id: userDetails.id}, userDetails, function(response){
          vm.tabIndex = 0;
          $mdSidenav('right').close()
            .then(function () {
              vm.updateUsers(function(){$scope.$storage.selectedUser = 0;});
              if (userDetails.id === $scope.$storage.user.id) {
                  $scope.$storage.user = Object.assign($localStorage.user, userDetails);
              }
          });
      }, function (response) {
        if(response.data &&  response.data.error && response.data.error.name && response.data.error.name == "ValidationError") {
			vm.existingEmail = '^((?!' + vm.userDetails.email.replace(".", "\.") + ').)*$';
          //usersForm.email.$error.validation = true;
        }
      });
    }


    //table filter info
    if(!vm.usersQuery) {
      vm.usersQuery = {
        order: 'id',
        limit: 10,
        page: 1
      };
    }

    $scope.$watch('users.filterUsers', function() {
      vm.usersQuery = {
        limit: 10,
        page: 1
      };
    },true);

    vm.downloadCSV = function () {
      var newArr = [];
      if(vm.hasPermissions('downloadUsers')) {
          _.forEach(vm.users, function (user) {
              var newUser = {};
              newUser.firstName = user.firstName;
              newUser.lastName = user.lastName;
              newUser.email = user.email;
              newUser.created = moment(user.created).format("DD/MM/YYYY HH:mm");
              newUser.lastUpdated = moment(user.lastUpdated).format("DD/MM/YYYY HH:mm");
              newUser.division = user.Divisions.division;
              newUser.location = user.Locations.locationName;
              newUser.team = user.Teams.shortName;
              newUser.role = user.Roles.name;
              newUser.restricted = user.limitDocs ? 'restricted' : '';
              newUser.status = user.status == 2 ? 'disabled' : '';
              newUser.dateDisabled = user.dateDisabled;
              newUser.notes = user.bupaNotes;
              newArr.push(newUser);
          });
      }
      return newArr;
    };



  }
}());
