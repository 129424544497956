<form name="documentInfoForm" layout="column">
    <md-toolbar class="md-theme-light">
        <div class="md-toolbar-tools">
            <h1 flex class="md-title" wraps-tooltip="doc-man-title">Document Management</h1>
            <md-button ng-click="library.closeRightMenu()" class="md-icon-button" aria-label="Close right pane">
                <ng-md-icon icon="close" size="24"></ng-md-icon>
            </md-button>
        </div>
    </md-toolbar>
        <!--<pre>{{documentInfoForm.insurerId | json}}</pre>-->
    <div layout="row" layout-align="end start" layout-padding>
        <div>

            <md-button  type="button"  ng-click="library.closeRightMenu()">Cancel</md-button>
            <md-button
                    ng-if="(!library.disableBookEdit || (library.isOwner(library.selected.book)   && library.selected.book.status === 1))"
                    type="button"
                    class="md-raised md-primary"
                    ng-disabled="
                        library.isLoading ||
                        (
                        library.selected.book.checkCode ||
                        library.selected.book.isWorkflowInvalid ||
                        !(library.selected.book.finProm) ||
                        library.checkBlank(library.selected.book.bookName) ||
                        library.checkBlank(library.selected.book.bookCode) ||
                        library.checkBlank(library.selected.book.DocTypes) ||
                        library.checkBlank(library.selected.book.insurerId) ||
                        library.checkBlank(library.selected.book.productId) ||
                        library.checkBlank(library.selected.book.productTypeId) ||
                        library.checkBlank(library.selected.book.regionId) ||
                        library.checkBlank(library.selected.book.audienceId) ||
                        library.checkBlank(library.selected.book.language) ||
                        library.checkBlank(library.selected.book.bookOwner) ||
                        (library.checkBlank(library.selected.book.socialChannelIds) && library.selected.book.docTypeId == 182) ||
                        (library.checkBlank(library.selected.book.projectId) && library.selected.book.divisionId == 1) ||
                        (library.checkBlank(library.selected.book.clientId) && library.selected.book.productTypeId == 4) ||
                        ((library.selected.book.bookName + '').length > 50 && library.selected.book.fulfilment[7] && library.selected.book.DocTypes.docTypeId == 1) ||
                        ((!library.selected.book.dates.isValid) && !library.selected.book.bookCode) ||
                        (library.selected.book.docTypeId == 93 && library.checkBlank(library.selected.book.extras.BGHPColour)) ||
                        (library.productIdsAllowedSubDocTypes.indexOf(library.selected.book.DocTypes.docTypeId) !== -1 && library.selected.book.fulfilment[7] == true && (!library.selected.book.productIdsSelectedArray || !library.checkProductIdsSelectedArray(library.selected.book.productIdsSelectedArray))) ||
                        (library.selected.book.insurerId == 38 && (!library.selected.book.insurerDetailsSelectedArray || library.checkBlankArray(library.selected.book.insurerDetailsSelectedArray)))
                        )"
                    ng-click="library.saveBookInfoDialog(library.selected.book)"
            >Save</md-button><!-- non specific insurer id -->
            <!--/*TODO change  182 on line 34 for the Social Asset doc Type ID*/-->
        </div>
    </div>
    <md-tabs md-border-bottom md-selected="library.tabIndex" flex>
        <md-tab>
            <md-tab-label><span>Main</span> <ng-md-icon ng-if="(
                        library.selected.book.checkCode ||
                        !(library.selected.book.finProm) ||
                        library.checkBlank(library.selected.book.bookName) ||
                        library.checkBlank(library.selected.book.bookCode) ||
                        library.checkBlank(library.selected.book.DocTypes) ||
                        library.checkBlank(library.selected.book.insurerId) ||
                        library.checkBlank(library.selected.book.productId) ||
                        library.checkBlank(library.selected.book.productTypeId) ||
                        library.checkBlank(library.selected.book.regionId) ||
                        library.checkBlank(library.selected.book.audienceId) ||
                        library.checkBlank(library.selected.book.language) ||
                        library.checkBlank(library.selected.book.bookOwner) ||
                        ((!library.selected.book.socialChannelIds || library.selected.book.socialChannelIds.length == 0) && library.selected.book.docTypeId == 182) ||
                        (library.checkBlank(library.selected.book.projectId) && library.selected.book.divisionId == 1) ||
                        (library.checkBlank(library.selected.book.clientId) && library.selected.book.productTypeId == 4) ||
                        (library.selected.book.docTypeId == 93 && library.checkBlank(library.selected.book.extras.BGHPColour)) ||
                        ((library.selected.book.bookName + '').length > 50 && library.selected.book.fulfilment[7] && library.selected.book.DocTypes.docTypeId == 1) ||
                        (library.selected.book.insurerId == 38 && (!library.selected.book.insurerDetailsSelectedArray || library.checkBlankArray(library.selected.book.insurerDetailsSelectedArray))))
                        " icon="warning" size="20" md-colors="{fill:'accent'}"></ng-md-icon><!-- non specific insurer id -->
                <!--/*TODO change  182 on line 61 for the Social Asset doc Type ID*/-->
            </md-tab-label>
            <md-tab-body>
                <tab-main book="library.selected.book" visible="library.sideNavOpens" disabled="library.disableBookEdit" switch="library.switch" newlanguage="library.newLanguage"></tab-main>
            </md-tab-body>
        </md-tab>


        <md-tab label="Publish" class="publish" flex>
            <md-tab-label>Publish</md-tab-label>
            <md-tab-body>
                <tab-publish book="library.selected.book" visible="library.sideNavOpens" disabled="library.disableBookEdit" switch="library.switch"></tab-publish>
            </md-tab-body>
        </md-tab>



        <md-tab label="Enclosures" ng-if="library.enclosuresAllowedSubDocTypes.indexOf(library.selected.book.DocTypes.docTypeId) !== -1 && library.selected.book.fulfilment[7] == true" flex>
            <md-tab-label>Enclosures</md-tab-label>
            <md-tab-body>
                <tab-enclosures book="library.selected.book" disabled="library.disableBookEdit"></tab-enclosures>
            </md-tab-body>
        </md-tab>


        <md-tab label="Products" ng-if="library.productIdsAllowedSubDocTypes.indexOf(library.selected.book.DocTypes.docTypeId) !== -1 && library.selected.book.fulfilment[7] == true" class="products" flex>
            <md-tab-label><span>Products</span> <ng-md-icon ng-if="!library.isLoading && ((library.productIdsAllowedSubDocTypes.indexOf(library.selected.book.DocTypes.docTypeId) !== -1 && library.selected.book.fulfilment[7] == true && (!library.selected.book.productIdsSelectedArray || !library.checkProductIdsSelectedArray(library.selected.book.productIdsSelectedArray))))" icon="warning" size="20" md-colors="{fill:'accent'}"></ng-md-icon></md-tab-label>
            <md-tab-body>
                <tab-products book="library.selected.book" disabled="library.disableBookEdit"></tab-products>
            </md-tab-body>
        </md-tab>




        <md-tab label="Workflow" class="workflow" ng-disabled="!library.selected.book.DocTypes" flex>
            <md-tab-label><span>Workflow</span>
                <ng-md-icon ng-if="(
                    library.selected.book.isWorkflowInvalid
                )" icon="warning" size="20" md-colors="{fill:'accent'}"></ng-md-icon>
            </md-tab-label>
            <md-tab-body>
                <tab-workflow book="library.selected.book" visible="library.sideNavOpens" disabled="library.disableBookEdit"></tab-workflow>
            </md-tab-body>


            <!--<pre>{{library.selected.book | json }}</pre>-->
        </md-tab>
        <md-tab label="Levels" ng-disabled="!library.selected.book.workflowDoc || !library.selected.book.DocTypes"  ng-if="library.selected.book.DocTypes.docTypeId === 1" flex>
            <md-tab-label>Levels</md-tab-label>
            <md-tab-body>
                <tab-levels book="library.selected.book" disabled="library.disableBookEdit"></tab-levels>
            </md-tab-body>


        </md-tab>
        <md-tab label="Content" flex>
            <md-tab-label>Content</md-tab-label>
            <md-tab-body>
                <tab-content book="library.selected.book" disabled="library.disableBookEdit"></tab-content>
            </md-tab-body>

        </md-tab>
        <md-tab label="Dates" flex>
            <md-tab-label><span>Dates</span> <ng-md-icon ng-if="!library.isLoading && !library.selected.book.dates.isValid" icon="warning" size="20" md-colors="{fill:'accent'}"></ng-md-icon></md-tab-label>
            <md-tab-body>
                <tab-dates book="library.selected.book" visible="library.sideNavOpens"  disabled="library.disableBookEdit"></tab-dates>
            </md-tab-body>
        </md-tab>


        <md-tab label="Notes" flex>
            <md-tab-label>Notes</md-tab-label>
            <md-tab-body>
                <tab-notes book="library.selected.book" disabled="library.disableBookEdit"></tab-notes>
            </md-tab-body>
        </md-tab>
    </md-tabs>

</form>
