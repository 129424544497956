<md-content layout-padding>
    <div layout="row" layout-wrap>
        <div flex="50" >

            <h4 wraps-tooltip="docman-content-input_doccontenttags">Document Content</h4>

            <md-switch ng-repeat="item in tabContent.docContents | filter: { docContentStatus: 1, divisionId:tabContent.book.divisionId} | orderBy: 'name'" name="{{item.name}}"    ng-model="tabContent.book.bookContent[item.id]"    ng-disabled="tabContent.disabled">{{item.name}}</md-switch>
            <!--{{tabContent.selected.book.bookContent}}-->


        </div>
        <div flex="50" >

            <h4 wraps-tooltip="docman-content-input_langversiontags">Language Versions</h4>
            <md-switch ng-repeat="item in tabContent.languages | filter: { languageStatus: 1, divisionId:tabContent.book.divisionId}| orderBy: 'name'" name="{{item.name}}"    ng-model="tabContent.book.languageVersions[item.id]"    ng-disabled="tabContent.disabled">{{item.name}}</md-switch>
            <!--{{tabContent.selected.book.languageVersions}}-->
        </div>
    </div>
</md-content>