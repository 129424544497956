<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content"  ng-keydown="library.keyUpDown($event)">



    <div class="md-whiteframe-z1 default-box" style=""  ng-if="library.hasAccess === true">
		<!-- "ADD" FAB button-->
        <md-button wraps-tooltip="adddoc-button" ng-disabled="library.isLoading" ng-click="library.openRightMenu()" class="md-fab wraps-add-external"  aria-label="Open right bar" ng-if="library.hasPermissions('addDocuments')">
            <ng-md-icon  icon="add" style="fill: white" options="{'rotation': 'counterclock'}" size="30" clickIconMorph></ng-md-icon>
        </md-button>


		<!--circular progress notifier-->
        <md-progress-circular style="position: absolute; top: 10px; left: 50%; margin-left: -50px;" class="animate-if"  md-diameter="80" md-mode="indeterminate" ng-if="library.isLoading"></md-progress-circular>
        <md-data-table-toolbar>
            <h2 class="" flex>Library</h2>
            <div ng-if="library.openTabs.length > 0" style="white-space:nowrap; margin-top:42px;" class="md-caption">
                <h4 style="display:inline-block">{{library.openTabs.length}} tab<span ng-if="library.openTabs.length > 1">s</span> open</h4>
                <md-button aria-label="Clear search" class="md-gray md-caption" style="font-size:12px;" ng-click="library.openTabs.length = 0; library.openBooks.length = 0; library.selectedTabIndex = 0" wraps-tooltip="library-search-clear">
                    <ng-md-icon icon="backspace" md-colors="{fill:'grey'}" size="18"></ng-md-icon>close all tabs
                </md-button>
            </div>
        </md-button>

        </md-data-table-toolbar>
        <md-tabs md-selected="library.selectedTabIndex" md-border-bottom md-autoselect md-swipe-content md-dynamic-height>
            <md-tab ng-click="library.openLibraryList()">
                <md-tab-label><ng-md-icon icon="list"></ng-md-icon></md-tab-label>
                <md-tab-body>
                    <tab-list library="library"></tab-list>
                </md-tab-body>
            </md-tab>
            <md-tab ng-repeat="openTab in library.openTabs" ng-click="library.openDoc(openTab.bookId);">
                <md-tab-label><ng-md-icon ng-if="library.openBooks[$index].actionStatus" icon="{{library.icons[library.openBooks[$index].actionStatus -1]}}" md-colors="{fill:library.colors[library.openBooks[$index].actionStatus -1]}" size="20" class="animate-if" wraps-tooltip="action-icon"></ng-md-icon>
                    <ng-md-icon ng-if="!library.openBooks[$index].actionStatus" icon="description" class="tab-doc-icon"  size="20"  md-colors="{fill:openTab.updateRequired?'accent':'grey-600'}"></ng-md-icon> {{openTab.bookId|seven}}</md-tab-label>
                <md-tab-body>
                    <tab-book ng-if="openTab" book="library.openBooks[$index]" opentab="openTab" library="library" isloading="openTab.isLoading"></tab-book>
                </md-tab-body>
            </md-tab>
            <md-tab md-on-select="library.docOpenDialog()">
                <md-tab-label><ng-md-icon icon="add"></ng-md-icon></md-tab-label>
            </md-tab>
        </md-tabs>


            {{library.file}}
			


    </div>

	<div class="md-whiteframe-z1 default-box" style="" ng-if="library.hasAccess === null" >
		 <md-data-table-toolbar><h2  flex><br/></h2></md-data-table-toolbar>
            <md-progress-circular style="position: absolute; top: 10px; left: 50%; margin-left: -50px;"  md-diameter="80" md-mode="indeterminate"></md-progress-circular>
	</div>

</md-content>

<md-sidenav class="md-sidenav-left md-whiteframe-z3 library-sidenav" md-component-id="left" style="position: fixed;">
    <left-menu ng-if="library.leftContent==='menu'"></left-menu>
    <left-comments ng-if="library.leftContent==='comments'"></left-comments>
</md-sidenav>

<md-sidenav class="md-sidenav-right md-whiteframe-z3 library-sidenav" md-component-id="right" style="position: fixed;">
    <right-menu></right-menu>
</md-sidenav>


<md-button ng-click="library.toTheTop()" class="md-fab md-fab-bottom-right docs-scroll-fab md-button md-ink-ripple"  ng-class="library.showScrollFabs" style="position: fixed;" aria-label="Scroll to top" wraps-tooltip="library-scroll-to-top">
    <ng-md-icon  icon="keyboard_arrow_up" style="fill: white"></ng-md-icon>
</md-button>

