(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.addbrief.controller:AddbriefCtrl
   *
   * @description
   *
   */
  angular
    .module('library.addbrief')
    .controller('AddbriefCtrl', AddbriefCtrl);

  function AddbriefCtrl($filter, $interval, $localStorage, $mdDialog, $q, $scope, $timeout, apiUrl, book, Books, Containers, Contents, Events, History, Structures, Teams, Upload, Users, Versions, Wraps) {
  var vm = this;
  vm.ctrlName = 'AddbriefCtrl';
  $scope.$storage = $localStorage;
  if(!Users.isAuthenticated()){$location.path('/');}



  //Teams for add content owners
  vm.teams = Teams.__get__notDeleted();
  
    
  vm.safeFileList = Wraps.safeFileList;

  vm.dialogHide = function(data) {
    $mdDialog.hide(data);
  };
  vm.dialogCancel = function(data) {
    $mdDialog.cancel(data);
  };


    vm.removeFile = function(array, index){
      array.splice(index, 1);
    }

  vm.currentApprovalPosition = 1;

  vm.addNewBrief = function (briefData){
    vm.isLoading = true;
    var brief ={};
    brief.brief = briefData.brief;
    brief.brief.filesList = briefData.filesList;
    //console.log("data",briefData.fileList);
    var owner = 20; //design -  was briefData.briefTeam.teamId
    Contents.create({contentId: 0, contentTypeId: 9, contentName: "Brief", contentOwner: owner}, function (data1) {

      Versions.create({versionId: 0, contentId: data1.contentId, versiondata: Wraps.toJson(brief)}, function (data2) {

        Structures.create({structureId: 0, versionId: data2.versionId, contentId: data1.contentId, bookId: book.bookId, "status": 0, "approvalStatus" : book.userInBook[0], parent: null, order: data2.versionId}, function (data3) {

          Books.upsert({ "bookId": book.bookId,  "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
          History.create({
            "structureId": data3.structureId,
            "versionIdOld": 0,
            "versionIdNew": data3.versionId,
            "historyId": 0
          },function(historyResponse){
            Events.create({
              "eventId": 0,
              "bookId": book.bookId,
              "structureId": data3.structureId,
              "contentId": data1.contentId,
              "historyId": historyResponse.historyId,
              "userId": $scope.$storage.user.id,
              "eventType": "Added",
              "eventData": "Brief added"
            });
          });

          vm.dialogHide(true);
          //vm.isLoading = false;

        });
      });
    });

  }



    var timestamp = new Date().getTime();
    var S3_BUCKET_URL = apiUrl+'/Containers/wraps-files/upload';
    // var S3_BUCKET_URL = apiUrl+'/containers/wraps-files%2fbrief%2f'+timestamp+'/upload';
    var itemType = "brief";
    $scope.$watch('addbrief.files', function () {

      vm.upload(vm.files).then(function(statuses){
        vm.isLoading = false;
      });
    });
    vm.log = '';
    vm.filesList = [];
    vm.filesNames = [];
    vm.upload = function (files) {

      var promises = {};

      if (files && files.length) {
        _.forEach(files, function (file) {
          if (!file.$error) {
            vm.isLoading = true;
            var thisFileDefer = $q.defer();
            promises[file.name] = thisFileDefer.promise;

            file.container = 'wraps-files';
            file.fileLocation = itemType+'/'+timestamp;
            file.fileName = file.name;
            file.fileSize = file.size;
            vm.filesList.push(file);
            file.isLoading = true;


            //TODO add timeout to animate files

            var newName = file.fileLocation + '/' + file.name;
            Upload.rename(file, newName );
              Upload.upload({
                url: S3_BUCKET_URL,
                file: file,
                params:{
                  orderId: 1,
                  customerId: 1,
                  otherImageInfo:[]
                }}).then(function (resp) {
                  var count = 0;
                  var interval = $interval( function(){
                      Wraps.isImage($filter('thumburl')(file.ngfName)).then(function(rtn){
                          if (rtn === true){
                              $interval.cancel(interval);
                              //console.log(resp.config.file);
                              file.isLoading = false;
                              thisFileDefer.resolve(resp);
                          }
                      });
                      if(count++ >= 10){ //waiting 10 seconds before finishing
                          $interval.cancel(interval);
                          file.isLoading = false;
                          thisFileDefer.resolve('no thumbnail');
                      }
                  }, 1000);
                }, null, function (evt) {
                  file.determinateValue = parseInt(100.0 *   evt.loaded / evt.total);
              });
          }
        });
      }
      return $q.all(promises);
    };



    vm.options = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
        fillEmptyBlocks:true,
      forcePasteAsPlainText: false,
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      removeButtons: 'Subscript,Superscript',
      extraPlugins: 'sharedspace',
      sharedSpaces: { top: 'cktools',bottom: 'ckfoot'},
      toolbar: [
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
        { name: 'paragraph', items: [  'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',] },
        { name: 'format', items: [  'RemoveFormat' ] }
      ]
    };





}
}());
