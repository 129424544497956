(function () {
  'use strict';
  angular
    .module('wrapsv1')
    .config(config);
  function config($urlRouterProvider) {
    $urlRouterProvider.rule(function ($injector, $location) {
      var path = $location.path();
      var newPath;
      var hasTrailingSlash = path[path.length - 1] === '/';
      if (hasTrailingSlash) {
        // if last character is a slash, return the same url without the slash
        newPath = path.substr(0, path.length - 1);
        return newPath;
      }
    });
    $urlRouterProvider.otherwise('/login');
  }
}());
