(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name library.service:Wraps
   *
   * @description
   *
   */
  angular
    .module('library')
    .service('Wraps', Wraps);

  function Wraps(
	$filter,
	$localStorage,
	$location,
	$q,
	$rootScope,
	$sessionStorage,
	$templateCache,
	$timeout,
	$window,
  clientConfig,
	AppVersions,
	Books,
	BooksVersions,
	Clients,
	Containers,
	Contents,
    DivisionMetaLink,
	Divisions,
	DocContents,
    EnclosureTypes,
	Events,
	History,
	Languages,
	Messages,
	NotificationTypes,
    Notifications,
	MetaTables,
	PdfVersions,
	Projects,
	PublishTypes,
	RfcCategories,
	RfcJoin,
	RfcReasons,
	Roles,
	SocialChannels,
	Structures,
	system,
	Teams,
	Users,
	Versions) {
    var self = this;

    self.get = function () {
      return 'Wraps';
    };

    self.$storage = $localStorage;
    self.$session = $sessionStorage;
    self.user = self.$storage.user;



    self.clientConfig = clientConfig;

    self.httpErrors =  false;
    self.toastMessages = false;



    var outerJoin = function(name, array1, link1, array2, link2){
      _.each(array1, function(item){
        item[name] = _.find(array2, [link2, item[link1]]);
      });
      return array1;
    }





    self.emphasis = [

      {"id":1,"name":"Bold"},
      {"id":2,"name":"Italic"},
      {"id":3,"name":"None"}
    ];

    self.BGHPColour = [
      {"id":0,"name":"Gold"},
      {"id":1,"name":"Silver"},
      {"id":2,"name":"Navy"}
    ];



    self.states = [
      {  "id": 1, "name": "DLO" },
      { "id": 2, "name": "MCO" },
      {  "id": 3, "name": "Design"  },
      {  "id": 4, "name": "Approve" }
    ];

    self.levels = {
      "level1": {"name": "Essential", "active": true},
      "level2": {"name": "Classic", "active": true},
      "level3": {"name": "Gold", "active": true},
      "level4": {"name": "Gold Superior", "active": true},
      "payment": {"name": "Payment for treatment", "active": false}
    };

    self.chapterTypes = [
      { "chapterTypeId": 1, "chapterTypeName": "Text" },
      { "chapterTypeId": 2, "chapterTypeName": "Front Cover" },
      { "chapterTypeId": 3, "chapterTypeName": "QREF" },
      { "chapterTypeId": 4, "chapterTypeName": "Table of Contents"  },
      { "chapterTypeId": 5, "chapterTypeName": "Summary of Benefits and Exclusions"  },
      { "chapterTypeId": 7, "chapterTypeName": "BGHP Benefits"  },
      { "chapterTypeId": 9, "chapterTypeName": "BGHP Exclusions"  },
      { "chapterTypeId": 8, "chapterTypeName": "BGHP Terms and Conditions"  },
      { "chapterTypeId": 6, "chapterTypeName": "Back Cover" }
    ];






    self.safeFileList =
          ".idml," +
          ".msg," +
          ".oft," +
          "image/*," +
          "application/pdf," +
          "application/json," +
          "text/*," +
          "audio/*," +
          "video/*," +
          "message/*," +
          "application/vnd.ms-outlook," +
          "application/zip," +
          "application/x-rar-compressed," +
          "application/octet-stream," +
          "application/postscript," +
          "application/x-photoshop," +
          "application/x-shockwave-flash," +
          "application/powerpoint," +
          "application/vnd.ms-powerpoint," +
          "application/vnd.openxmlformats-officedocument.presentationml.presentation," +
          "application/excel," +
          "application/vnd.ms-excel," +
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet," +
          "application/msword," +
          "application/vnd.ms-word," +
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document," +
          "application/vnd.oasis.opendocument.text";


    self.databaseDateFormat = "YYYY-MM-DD HH:mm:ss";



  // .msg / .eml / .pst
  // .indd / .idml / .psd / .ai / .eps / .ps
  //  .zip
  // we might need movie files too.

    self.updateBookData = false;


    self.searchByBook = {id:''};
    self.toolTip = {showToolTip:false};

    self.appVersion = AppVersions.findOne({filter: {"order": "appVersionId DESC"}});

    self.reload = function(){
      $window.location.reload(true);
    }

    self.initData = function(){
      console.log("Init data fired.");
      var promises = {};
      if(!self.lastUpdated){self.lastUpdated = {};}

      //reasons for change call
      var rfcReasonsDefer = $q.defer();
      var rfcCategoriesDefer = $q.defer();
      var rfcJoinDefer = $q.defer();
      // console.log("Deferrers set, fetching promises...");
      RfcReasons.find().$promise.then(function(results){
        // console.log('RfcReasons fetched: ',results, "Trying categories...")
        self.rfcReasons = results; rfcReasonsDefer.resolve(results);
        RfcCategories.find().$promise.then(function(results){
          // console.log("Categories fetched: ",results,'Trying joins...')
          self.rfcCategories = results; rfcCategoriesDefer.resolve(results);
          RfcJoin.find().$promise.then(function(results){
            // console.log('Joins fetched: ',results,'Resolve results.')
            self.rfcJoin = results; rfcJoinDefer.resolve(results);
            // console.log('Populate rejection reasons.');
            self.rejectionReasons = outerJoin('Reason',outerJoin('Category',self.rfcJoin, 'category', self.rfcCategories, 'id'), 'reason', self.rfcReasons, 'id');

          });
        });
      });

      // console.log('All promises completed, populating global promise variables.');
      promises.rfcReasons = rfcReasonsDefer.promise;
      promises.rfcCategories = rfcCategoriesDefer.promise;
      promises.rfcJoin = rfcJoinDefer.promise;

      //publish types call
      // console.log('------------------------------')
      // console.log('Set up publish types deferrer');
      var publishTypesDefer = $q.defer();
      // console.log('Get undeleted things')
      PublishTypes.__get__notDeleted(function (results) {
        self.publishTypes = results;
        self.lastUpdated.PublishTypes = moment().utc().format('YYYY-MM-DD HH:mm:ss');
        // console.log("Done, resolving publiser types.");
        publishTypesDefer.resolve(results);
      });

      // console.log('------------------------------')
      // console.log('Set up publish types not filtered deferrer');
      var publishTypesNotFilteredDefer = $q.defer();
      PublishTypes.find({}, function (results) {
        self.publishTypesNotFiltered = results;
        self.lastUpdated.PublishTypesNotFiltered = moment().utc().format('YYYY-MM-DD HH:mm:ss');
        // console.log("Done, resolving publiser types.");
        publishTypesNotFilteredDefer.resolve(results);
      });
      // console.log("Set publish types promise and not filtered promise variables");
      promises.publishTypes = publishTypesDefer.promise;
      promises.publishTypesNotFiltered = publishTypesNotFilteredDefer.promise;

      //users call
      // console.log('------------------------------')
      // console.log('Set up user query promise.');
      var usersDefer = $q.defer();
      Users.find({"filter": {"include": ["Teams", "Roles", "Divisions"]}}).$promise.then(function (results) {
        self.users = results;
        self.lastUpdated.Users = moment().utc().format('YYYY-MM-DD HH:mm:ss');
        // console.log("Done, resolving...");
        usersDefer.resolve(results);
      });
      // console.log('Set user promise variable')
      promises.users = usersDefer.promise;

      // //divisions call
      // console.log('------------------------------')
      // console.log('Set up divisions promise.');
      var divisionDefer = $q.defer();
      Divisions.__get__notDeleted(function (results) {
        self.divisions = results;
        // console.log("Done, resolving divisions...");
        divisionDefer.resolve();
      });
      // console.log("Set divisions variable");
      promises.divisions = divisionDefer.promise;


      // console.log('------------------------------')
      // console.log('Set up Team promise.');
      var teamDefer = $q.defer();
      Teams.__get__notDeleted(function (results) {
        self.teams = results;
        // console.log("Resolving Team");
        teamDefer.resolve();
      });
      // console.log("Set Team  variables");
      promises.teams = teamDefer.promise;


      // console.log('------------------------------')
      // console.log('Set up roles promise.');
      //roles call
      var rolesDefer = $q.defer();
      Roles.find().$promise.then(function (results) {
        self.roles = results;
        self.lastUpdated.Roles = moment().utc().format('YYYY-MM-DD HH:mm:ss');
        // console.log('resolving roles');
        rolesDefer.resolve(results);
      });
      // console.log("set roles variable");
      promises.roles = rolesDefer.promise;

      /*console.log('------------------------------')
      console.log('Set up notification promise.');*/
      var NotificationTypesDefer = $q.defer();
      NotificationTypes.find().$promise.then(function (results) {
        self.notificationTypes = results;
        self.lastUpdated.NotificationTypes = moment().utc().format('YYYY-MM-DD HH:mm:ss');
        //console.log('Resolve notification');
        NotificationTypesDefer.resolve(results);
      });
      //console.log('set notification variable');
      promises.notificationTypes = NotificationTypesDefer.promise;


      //meta-list calls that can be updated through maintenance area.
      /*console.log('------------------------------')
      console.log('Set up meta tables query promise.');*/
      var metaDefer = $q.defer();
      self.updateData().then(function(){
        //console.log('Defer meta tables');
        metaDefer.resolve();
      });
      // console.log('set meta tables variable');
      promises.metaTables = metaDefer.promise;

     /* console.log('------------------------------')
      console.log('RETURN PROMISES TO CALLER!!!');
      console.log('------------------------------')*/
      return $q.all(promises);

    }

    // self.initData().then(function(data){
    //   self.rejectionReasons = outerJoin('Reason',outerJoin('Category',data.rfcJoin, 'category', data.rfcCategories, 'id'), 'reason', data.rfcReasons, 'id');
    // });


    self.updateData = function(){
      var promises = {};
      if(!self.lastUpdated){self.lastUpdated = {};}
      //var includeDivisions = self.hasPermissions('editDivisionOnDocs') ? '1,2' : self.$storage.user.divisionId; //@todo make dynamic
      var includeDivisions = '1,2';
      var lastUpdatedCountsPromise = $q.defer();
      MetaTables.getLastUpdatedCounts(self.lastUpdated, function (countLU) {
        //console.log("lstupdated co", countLU);
        countLU = countLU.response[0];

        if(countLU.Audiences > 0){
          var AudiencesDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'Audiences'}, function (results) {
            self.audiences = results['response'][0];
            self.lastUpdated.Audiences = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            AudiencesDefer.resolve(results);
          });
          promises.audiences = AudiencesDefer.promise;
        }

        if(countLU.Clients > 0){
          var clientsDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'Clients'},function (results) {
            self.clients = results['response'][0];
            self.lastUpdated.Clients = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            clientsDefer.resolve(results);
          });
          promises.clients = clientsDefer.promise;
        }

        if(countLU.Distributors > 0){
          var DistributorsDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'Distributors'}, function (results) {
            self.distributors = results['response'][0];
            self.lastUpdated.Distributors = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            DistributorsDefer.resolve(results);
          });
          promises.distributors = DistributorsDefer.promise;
        }

        if(countLU.DocContents > 0){
          var docContentsDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'DocContents'}, function (results) {
            self.docContents = results['response'][0];
            self.lastUpdated.DocContents = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            docContentsDefer.resolve(results);
          });
          promises.docContents = docContentsDefer.promise;
        }

        if(countLU.DocTypes > 0){
          var DocTypesDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'DocTypes'}, function (results) {
            self.docTypes = results['response'][0];
            self.lastUpdated.DocTypes = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            DocTypesDefer.resolve(results);
          });
          promises.docTypes = DocTypesDefer.promise;
        }

        if(countLU.DocTypeCats > 0){
          var DocTypeCatsDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'DocTypeCats'}, function (results) {
            self.docTypeCats = results['response'][0];
            self.lastUpdated.DocTypeCats = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            DocTypeCatsDefer.resolve(results);
          });
          promises.docTypeCats = DocTypeCatsDefer.promise;
        }

        if(countLU.Insurers > 0){
          var InsurersDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'Insurers'}, function (results) {
            self.insurers = results['response'][0];
            self.lastUpdated.Insurers = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            InsurersDefer.resolve(results);
          });
          promises.insurers = InsurersDefer.promise;
        }

        if(countLU.Languages > 0){
          var languagesDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'Languages'},function (results) {
            self.languages = results['response'][0];
            self.lastUpdated.Languages = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            languagesDefer.resolve(results);
          });
          promises.languages = languagesDefer.promise;
        }

        if(countLU.ProductTypes > 0){
          var ProductTypesDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'ProductTypes'}, function (results) {
            self.productTypes = results['response'][0];
            self.lastUpdated.ProductTypes = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            ProductTypesDefer.resolve(results);
          });
          promises.productTypes = ProductTypesDefer.promise;
        }

        if(countLU.Products > 0) {
          var ProductsDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'Products'}, function (results) {
            self.products = results['response'][0];
            self.lastUpdated.Products = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            ProductsDefer.resolve(results);
          });
          promises.products = ProductsDefer.promise;
        }

        if(countLU.Projects > 0) {
          var projectsDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'Projects'}, function (results) {
            self.projects = results['response'][0];
            self.lastUpdated.Projects = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            projectsDefer.resolve(results);
          });
          promises.projects = projectsDefer.promise;
        }

        if(countLU.Regions > 0){
          var RegionsDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'Regions'}, function (results) {
            self.regions = results['response'][0];
            self.lastUpdated.Regions = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            RegionsDefer.resolve(results);
          });
          promises.regions = RegionsDefer.promise;
        }

        if(countLU.SocialChannels > 0){
            var SocialChannelsDefer = $q.defer();
            DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'SocialChannels'}, function (results) {
                self.socialChannels = results['response'][0];
                self.lastUpdated.SocialChannels = moment().utc().format('YYYY-MM-DD HH:mm:ss');
                SocialChannelsDefer.resolve(results);
            });
            promises.socialChannels = SocialChannelsDefer.promise;
        }

        if(countLU.SubInsurers > 0){
          var SubInsurersDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'SubInsurers'}, function (results) {
            self.subInsurers = results['response'][0];
            self.lastUpdated.SubInsurers = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            SubInsurersDefer.resolve(results);
          });
          promises.subInsurers = SubInsurersDefer.promise;
        }

        //todo division-meta-link call this one. - don't use includeDivsions variable on this, because we need to return all teams in all divisions for the workflows.
        if(countLU.Teams > 0){
          var teamsDefer = $q.defer();
          Teams.find({"filter": {"include": "Departments", "order": "docOrd ASC", "where":{"teamStatus":1}}}).$promise.then(function(results){
            self.teams = results;
            //console.log('teams', self.teams);
            self.lastUpdated.Teams = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            teamsDefer.resolve(results);
          });
          promises.teams = teamsDefer.promise;
        }

        $q.all(promises).then(function(){
          lastUpdatedCountsPromise.resolve();
        });
      });

      return lastUpdatedCountsPromise.promise;
    }


    self.json2Obj = function(obj){
      try{ obj = angular.extend(obj,angular.fromJson(obj.versions.versiondata)); }
      catch (err){
        console.log(err.name + ': "' + err.message +  '" occurred when assigning string.');
        return false;
      }
      return obj;
    }


    self.json2ObjWCR = function(obj){
      try{
        obj.json1 = angular.fromJson(obj.versionData1);
        obj.json2 = angular.fromJson(obj.versionData2);
        obj.json = true;
        //console.log(obj);
      }
      catch (err){    console.log(err.name + ': "' + err.message +  '" occurred when assigning string.'); return false;}

      return obj;

    }

    self.nl2br = function(string){
      try {
        return string.replace(/\n/g, '<br>');
      }catch(err){
        //return false;
      }
    }

    self.isUndefinedOrZero = function(val) {
      return angular.isUndefined(val)? val : 0;
    }


    self.getEmailsFromApproval = function(approval) {
      //console.log(approval);
      if(approval.user!="" && !angular.isUndefined(approval.user)){
        return Users.findOne( {"filter": {"where":{"and":[{"status":null},{"id": approval.user}] }}} ).$promise.then(function(user){
          var userEmails = [];
          userEmails.push({'email':user.email,'name':user.firstName});
          return userEmails;
        });
      }else if(approval.team!="" && !angular.isUndefined(approval.team)){
        return Users.find({"filter": {"where":{"and":[{"status":null}, {"teamId": approval.team}]},"include": "Teams"}}).$promise.then(function(userList){
          var userEmails = [];
          _.forEach(userList, function(user, key) {
            userEmails.push({'email':user.contactEmail,'name':user.Teams.teamName});
          });
          return _.uniqBy(userEmails, 'email');
        });
      }else if(approval.role!="" && !angular.isUndefined(approval.role)){
        return Users.find({"filter": {"where":{"and":[{"status":null}, {"roleId": approval.role}]},"include": "Roles"}}).$promise.then(function(userList){
          var userEmails = [];
          _.forEach(userList, function(user, key) {
            userEmails.push({'email':user.contactEmail,'name':user.Roles.name});
          });
          return _.uniqBy(userEmails, 'email');
        });
      }

    }


    self.getEmailsFromDocument = function(thisApprovalList){
      var deferred = $q.defer();
      var promises = [];
      var userEmails = [];
      _.forEach(thisApprovalList, function(approval, key) {
        var innerdeferred = $q.defer();
        self.getEmailsFromApproval(approval).then(function(email){
          userEmails = _.concat(userEmails,email);
          innerdeferred.resolve(email);
        });
        promises.push(innerdeferred.promise);
      });
      $q.all(promises).then(function() {
          deferred.resolve(_.uniqBy(userEmails, 'email'));
      });
      return deferred.promise;
    }


    self.sendmail = function (to, subject, message, header, info) {
      return Messages.sendmail({
        to: to, // list of receivers
        subject: subject, // Subject line
        message: message, // html message
        header: header,
        info:info,
        content: "",
        contact: "Have questions? Contact your account administrator at " + self.clientConfig.email,
        disclaimer: "The information contained in this email and any attachments is confidential and may be subject to copyright or other intellectual property protection."+
        "If you are not the intended recipient, you are not authorised to use or disclose this information, and we request that you notify us by reply mail or telephone and "+
        "delete the original message from your mail system."
      }, function (response) {
        //console.log(response);
        if(response){
          self.toastMessages = {message:"Email Sent to: "+response.to};


        }

      });
    }


    self.hasPermissions = function(thisPermission){
      if(self.user && _.findIndex(self.user.permissions, {permissionCode: thisPermission}) >= 0){
        return true;
      }else{
        return false;
      }
    }


    self.hasAnyOfPermissionCat = function(thisCat){
      if(self.user && _.findIndex(self.user.permissions, {permissionCategoryId: thisCat}) >= 0){
        return true;
      }else{
        return false;
      }
    }



    /*self.getCurrentUser = function () {
      if(Users.isAuthenticated()) {

        Users.getCurrent({"filter": {"include": ["Roles", "Teams", "UsersStatus", {"PermissionGroups": [{"PermissionGroupJoin": ["Permissions"]}]}]}}).$promise.then(function (result) {
          if (result.PermissionGroups && result.PermissionGroups.PermissionGroupJoin.length && result.PermissionGroups.PermissionGroupJoin.length > 0) {
            var permissions = _.map(result.PermissionGroups.PermissionGroupJoin, 'Permissions');
            delete result.PermissionGroups;
          }
          self.LoggedInUser = result;
          if (permissions && permissions.length > 0) {
            self.LoggedInUser.permissions = permissions;
          }
          self.$storage.user = result;
          self.$storage.loggedInUser = result;
        });

      }

    }*/
	  self.getCurrentUser = function (atLogin) {
      if(Users.isAuthenticated()) {
        var deferred = $q.defer();

        if(atLogin) {
          Users.getCurrent().$promise.then(function (currentUser) {
            Users.update({"where": {"id": currentUser.id}}, {"loggedInAs":0}, function () {
              Users.getCurrent({"filter": {"include": ["Roles", "Teams", "UsersStatus", "Divisions", {"PermissionGroups": [{"PermissionGroupJoin": ["Permissions"]}]}]}}).$promise.then(function (result) {
                if (result.PermissionGroups && result.PermissionGroups.PermissionGroupJoin.length && result.PermissionGroups.PermissionGroupJoin.length > 0) {
                  result.permissions = _.map(result.PermissionGroups.PermissionGroupJoin, 'Permissions');
                  delete result.PermissionGroups;
                }

                self.LoggedInUser = result;
                deferred.resolve(true);
                // if (permissions && permissions.length > 0) {
                //   self.LoggedInUser.permissions = permissions;
                //   self.user.permissions = permissions;
                // }

                self.user = result;
                self.$storage.user = result;
                self.$storage.loggedInUser = result;
              });
            });
          });
        }else {
          Users.getCurrent().$promise.then(function (currentUser) {
            Users.getCurrent({"filter": {"include": ["Roles", "Teams", "UsersStatus", "Divisions", {"PermissionGroups": [{"PermissionGroupJoin": ["Permissions"]}]}]}}).$promise.then(function (result) {
              if (result.PermissionGroups && result.PermissionGroups.PermissionGroupJoin.length && result.PermissionGroups.PermissionGroupJoin.length > 0) {
                var permissions = _.map(result.PermissionGroups.PermissionGroupJoin, 'Permissions');
                delete result.PermissionGroups;
              }

              self.LoggedInUser = result;
              deferred.resolve(true);
              if (permissions && permissions.length > 0) {
                self.LoggedInUser.permissions = permissions;
              }
              self.$storage.loggedInUser = result;
            });
          });
        }

        return deferred.promise;
      }

    }


    self.padZeros = function(number, length) {
      var str = '' + number;
      while (str.length < length) {
        str = '0' + str;
      }
      return str;
    }

    self.sendCommentEmail = function (thisBook, thisComment, checkedEmailUsers, eventId) {
      //console.log("sendCommentEmail");

      if(angular.isObject(thisComment)) {
        thisComment = thisComment.message;
      }

      var deferred = $q.defer();
      var emailList = _.map(checkedEmailUsers, 'email').join();

     if(thisBook.liveDate == null){
     var liveDate = 'Live on Approval';}
     else{
     var liveDate = $filter('date')(thisBook.liveDate, "dd/MM/yyyy");
     }

     //var emailList = _.map(users, 'email').join();
      var toEmail = self.emailSystemSwap(emailList);
      var url = $location.$$protocol + '://' + $location.$$host +($location.$$port?':'+$location.$$port:'') + "/library/" + thisBook.bookId;

      thisBook.displayStatus = self.getDisplayStatus(thisBook);

        self.sendmail(
          toEmail,  //to
          thisBook.bookIdLong + " - " + "You have a notification about - " + thisBook.bookName , //subject
          "Comment :<br>" + thisComment + "" +
          "<br><br>" +
          "Click this link to view the document: <a href='" + url + "'>" + thisBook.bookName + " ("+ thisBook.bookId + ")" + "</a> " +
          "<br><br><br>" +
          "This email has been sent to the following people: " + emailList.replace(/,/g,", ").replace(/,\s*$/, ""), //message
          self.$storage.user.firstName + " " + self.$storage.user.lastName + " has commented on a document you are involved with.", //header
          "Document Name: " + thisBook.bookName +
          "<br>Document Code: " + thisBook.bookCode +
          "<br>Document ID: " + thisBook.bookIdLong +
          "<br>Document Owner: " + thisBook.Owner.fullName +
          "<br>Status: " + thisBook.displayStatus +
          "<br>Live Date: " + liveDate +
          "<br>Submitted By: " + self.$storage.user.firstName + " " + self.$storage.user.lastName + "" //info
        ).$promise.then(function (messageDetails) {
          Events.upsert({"eventId":eventId, "messageId":messageDetails.messageId}).$promise.then(function (results) {
            deferred.resolve(results);
          });

        });
      return deferred.promise;
    }



    //as per google doc
    self.sendApprovedEmail = function(thisBook,eventId) {
      //console.log("sendApprovedEmail");

      var deferred = $q.defer();
      if(thisBook.liveDate == null){
        var liveDate = 'Live on Approval';}
      else{
        var liveDate = $filter('date')(thisBook.liveDate, "dd/MM/yyyy");
      }

      var toEmail = self.emailSystemSwap(thisBook.Owner.email);
        var url = $location.$$protocol + '://' + $location.$$host +($location.$$port?':'+$location.$$port:'') + "/library/" + thisBook.bookId;

        thisBook.displayStatus = self.getDisplayStatus(thisBook);

        self.sendmail(
          toEmail,  //to
          thisBook.bookIdLong + " - " + "A document you have sent for review has been approved - " + thisBook.bookCode, //subject
          "Hi " + thisBook.Owner.firstName + "<br><br>" +
          "A document you have sent for review has been approved.  It may be part of an approval chain and therefore still on its way through the workflow.<br><br>" +
          "Click this link to view the document: <a href='" + url + "'>" + thisBook.bookName + " ("+ thisBook.bookId + ")" + "</a> " +
          "<br><br>" +
          "Note: if you believe you are not the right person or team responsible for reviewing or approving this document, please contact " +
          self.clientConfig.email + "as soon as possible. All delays are logged and reportable.<br><br>" +
          "This email has been sent to the following people: " + thisBook.Owner.email, //message
          "Congratulations! A document you have sent for review has been approved.", //header
          "Document Name: " + thisBook.bookName + "<br>Document Code: " + thisBook.bookCode + "<br>Document ID: " + thisBook.bookIdLong + "<br>Document Owner: " + thisBook.Owner.fullName + "<br>Status: " + thisBook.displayStatus + "<br>Live Date: " + liveDate + "<br>Submitted By: " + self.$storage.user.firstName + " " + self.$storage.user.lastName + "" //info
        ).$promise.then(function (messageDetails) {
          Events.upsert({"eventId":eventId, "messageId":messageDetails.messageId}).$promise.then(function (results) {
            deferred.resolve(results);
          });

        });
      return deferred.promise;
    }

    //as per google doc
    self.sendReviewEmail = function(thisBook, eventId) {
      //console.log("sendReviewEmail",eventId);
      var deferred = $q.defer();
      self.getEmailsFromApproval(thisBook.approvalList[thisBook.approvalStatus]).then(function (users) {

        if(thisBook.liveDate == null){
          var liveDate = 'Live on Approval';}
        else{
          var liveDate = $filter('date')(thisBook.liveDate, "dd/MM/yyyy");
        }

        var emailList = _.map(users, 'email').join();
        var toEmail = self.emailSystemSwap(emailList);

        var url = $location.$$protocol + '://' + $location.$$host +($location.$$port?':'+$location.$$port:'') + "/library/" + thisBook.bookId;


        thisBook.displayStatus = self.getDisplayStatus(thisBook);

        if(users.length===1){var name = users[0].name;}else{var name = "";}





        if (thisBook.approvalList[thisBook.approvalStatus] && thisBook.approvalList[thisBook.approvalStatus].role == 12) {

          var enclosureText = '';
          enclosureText = "Enclosures:<br/ >";
          EnclosureTypes.__get__notDeleted().$promise.then(function (enclosureTypes) {
            //console.log("ENCLOSURE TYPES FOR EMAIL" , enclosureTypes, thisBook, thisBook.Enclosures);
            _.forEach(enclosureTypes, function (item) {
              var encType = "";
              //var colName = "enclosure" + item.enclosureTypeName.replace(/ /g, "");
              if (encType = _.filter(thisBook.Enclosures, {'enclosureType': item.enclosureTypeId + ""})[0]) {
                //console.log("encType", encType, item.enclosureTypeName);
                //console.log(encType.enclosureBookCode);
                enclosureText += item.enclosureTypeName + ": " + encType.enclosureBookCode + "<br />";
              }
            });
            return enclosureText;
            //console.log(enclosureText);
          }).then(function (enclosureText) {
            var productIdText = '';
            if (!_.isEmpty(thisBook.productIdsSelectedArray)) {
              productIdText = '<br />Product Ids:<br />'
              _.forEach(thisBook.productIdsSelectedArray, function (state, productId) {
                if (state === true) {
                  productIdText += productId + '<br />';
                }
              });
            }


            self.sendmail(
              toEmail,  //to
              thisBook.bookIdLong + " - " + "You have a new document to review - " + thisBook.bookCode, //subject
              "Hi " + name + "<br><br>" +
              "There are documents waiting for your review or approval.<br><br>" +
              "Click this link to view the document: <a href='" + url + "'>" + thisBook.bookName + " (" + thisBook.bookId + ")" + "</a> " +
              "<br><br>" +

              enclosureText +
              productIdText +

              "<br><br>" +
              "Note: if you believe you are not the right person or team responsible for reviewing or approving this document, please contact " + self.clientConfig.email + " as soon as possible. All delays are logged and reportable.<br><br>" +
              "This email has been sent to the following people: " + emailList, //message
              "There are documents waiting for your review or approval.", //header
              "Document Name: " + thisBook.bookName + "<br>Document Code: " + thisBook.bookCode + "<br>Document ID: " + thisBook.bookIdLong + "<br>Document Owner: " + thisBook.Owner.fullName + "<br>Status: " + thisBook.displayStatus + "<br>Live Date: " + liveDate + "<br>Submitted By: " + self.$storage.user.firstName + " " + self.$storage.user.lastName + "" //info
            ).$promise.then(function (messageDetails) {

              //console.log("messageDetails",messageDetails);
              Events.upsert({
                "eventId": eventId,
                "messageId": messageDetails.messageId
              }).$promise.then(function (results) {
                deferred.resolve(results);
              });

            });
          });


        } else {

          self.sendmail(
            toEmail,  //to
            thisBook.bookIdLong + " - " + "You have a new document to review - " + thisBook.bookCode, //subject
            "Hi " + name + "<br><br>" +
            "There are documents waiting for your review or approval.<br><br>" +
            "Click this link to view the document: <a href='" + url + "'>" + thisBook.bookName + " (" + thisBook.bookId + ")" + "</a> " +
            "<br><br>" +
            "Note: if you believe you are not the right person or team responsible for reviewing or approving this document, please contact "+ self.clientConfig.email +" as soon as possible. All delays are logged and reportable.<br><br>" +
            "This email has been sent to the following people: " + emailList, //message
            "There are documents waiting for your review or approval.", //header
            "Document Name: " + thisBook.bookName + "<br>Document Code: " + thisBook.bookCode + "<br>Document ID: " + thisBook.bookIdLong + "<br>Document Owner: " + thisBook.Owner.fullName + "<br>Status: " + thisBook.displayStatus + "<br>Live Date: " + liveDate + "<br>Submitted By: " + self.$storage.user.firstName + " " + self.$storage.user.lastName + "" //info
          ).$promise.then(function (messageDetails) {

            //console.log("messageDetails",messageDetails);
            Events.upsert({
              "eventId": eventId,
              "messageId": messageDetails.messageId
            }).$promise.then(function (results) {
              deferred.resolve(results);
            });


          });

        }










      });
      return deferred.promise;
    }

    //as per google doc
    self.sendRejectEmail = function(thisBook, reject, previousEditor,eventId) {
      //console.log("sendRejectEmail");
      var deferred = $q.defer();
      self.getEmailsFromApproval(thisBook.approvalList[previousEditor]).then(function (users) {

        if(thisBook.liveDate == null){
          var liveDate = 'Live on Approval';}
        else{
          var liveDate = $filter('date')(thisBook.liveDate, "dd/MM/yyyy");
        }

        var emailList = _.map(users, 'email').join();
        var toEmail = self.emailSystemSwap(emailList);

        var url = $location.$$protocol + '://' + $location.$$host +($location.$$port?':'+$location.$$port:'') + "/library/" + thisBook.bookId;


        thisBook.displayStatus = self.getDisplayStatus(thisBook);

        if(users.length===1){var name = users[0].name;}else{var name = "";}

        //console.log(reject);
        var reasons ="";//"<b>Reasons</b><br>";
        var categories ="";//"<b>Categories</b><br>";

        if(!angular.isArray(reject.reason)){ reject.reason = [reject.reason];}
        _.forEach(reject.reason, function(val,key){
          reasons = reasons + "<li>"+_.result(_.find(self.rfcReasons, {'id': _.parseInt(val)}), 'name')+"</li>";
        });

        if(!angular.isArray(reject.category)){ reject.category = [reject.category];}
        _.forEach(reject.category, function(val,key){
          categories = categories + "<li>"+_.result(_.find(self.rfcCategories, {'id': _.parseInt(val)}), 'name')+"</li>";
        });

        self.sendmail(
          toEmail,  //to
            thisBook.bookIdLong + " - " + "A document you have sent for review has been rejected - "+ thisBook.bookCode, //subject
          "Hi "+name+"<br><br>" +

          "A document you have sent for review has been rejected. The reason for rejection is:<br><br>" +
          "<blockquote>" +
          "<strong>Rejection Category:</strong> <ul>"+categories+"</ul>" +
          "<strong>Reason for Rejection:</strong> <ul>"+reasons + "</ul>" +
          "<strong>Rejection Message:</strong> <br>"+ reject.message+"</blockquote><br><br>" +
          "Click this link to view the document: <a href='" + url + "'>" + thisBook.bookName + " ("+ thisBook.bookId + ")" + "</a> " +
          "<br><br>" +


          "<i>Note: the review and approve window is open for a limited time only, so your timely attention to this rejection is key to the success of the " +
          "process. Delays beyond the expiration date could put us in breach of our TCF obligations.</i><br><br>" +

          "<i>If you believe you are not the right person or team responsible for reviewing or approving this document, " +
          "please contact " + self.clientConfig.email + " as soon as possible. All rejections are logged and reportable.</i><br><br>" +

          "This email has been sent to the following people: "+emailList , //message

          "A document you have sent for review has been rejected", //header
          "Document Name: " + thisBook.bookName + "<br>Document Code: " + thisBook.bookCode + "<br>Document ID: " + thisBook.bookIdLong + "<br>Document Owner: "+thisBook.Owner.fullName+"<br>Status: "+thisBook.displayStatus+"<br>Live Date: "+liveDate+"<br>Rejected By: "+self.$storage.user.firstName+" "+self.$storage.user.lastName+"" //info
        ).$promise.then(function (messageDetails) {
          //console.log("messageDetails",messageDetails);
          Events.upsert({"eventId":eventId, "messageId":messageDetails.messageId}).$promise.then(function (results) {
            deferred.resolve(results);
          });

        });
      });
      return deferred.promise;
    }

    self.sendEarlyExpiryEmail = function(book, dateOfExpiry){
      var funcPromise = $q.defer();

      // in finals and not at artwork upload step
      if(book.approvalList[book.approvalStatus] && book.approvalList[book.approvalStatus].content == 'final' && book.approvalList[book.approvalStatus].role != 6){
        var publishStep = _.filter(book.approvalList, function(obj , index){

          return obj.content == 'final' && obj.role != 6 && index <= book.approvalStatus;
        });

        //approval list logic--//
        var users = [];
        var promises = [];

        var production = '';
        var fulfilment = '';
        var digital = '';
        var support = '';

        _.forEach(publishStep, function (step) {
          var loopDefered = $q.defer();
          //get where things are published for this step and assign it to a variable to output within the blockquote of the email.
          switch(step.role) {
              case 11:
                  _.forEach(book.production, function (value, key) {
                          production = production + "<li>"+_.result(_.find(self.publishTypes, {'id': _.toInteger(key)}), 'name')+"</li>";
                      });
                  break;
              case 12:
                  _.forEach(book.fulfilment, function (value, key) {
                      fulfilment = fulfilment + "<li>"+_.result(_.find(self.publishTypes, {'id': _.toInteger(key)}), 'name')+"</li>";
                  });
                  break;
              case 14:
                  _.forEach(book.digital, function (value, key) {
                      digital = digital + "<li>"+_.result(_.find(self.publishTypes, {'id': _.toInteger(key)}), 'name')+"</li>";
                  });
                  break;
              case 15:
                  _.forEach(book.support, function (value, key) {
                      support = support + "<li>"+_.result(_.find(self.publishTypes, {'id': _.toInteger(key)}), 'name')+"</li>";
                  });
                  break;
              default:
                break;
          }

          // get users that are assigned to this step.
          self.getEmailsFromApproval(step).then(function(res){
            users = _.concat(users,res);
            loopDefered.resolve();
          });
          promises.push(loopDefered.promise);
        });

        $q.all(promises).then(function(res){
          console.log("users",users);
          if(users && users.length > 0){
                var emailList = _.map(users, 'email').join();
                var toEmail = self.emailSystemSwap(emailList);
                console.log(production, fulfilment, digital, support);

                if (users.length === 1) {
                    var name = users[0].name;
                } else {
                    var name = "";
                }


                var earlyExpiryDate = $filter('date')(dateOfExpiry, "dd/MM/yyyy");

                if (book.liveDate == null) {
                    var liveDate = 'Live on Approval';
                }
                else {
                    var liveDate = $filter('date')(book.liveDate, "dd/MM/yyyy");
                }

                if (book.expiryDate == null) {
                    var expiryDate = 'Set from Live Date';
                }
                else {
                    var expiryDate = $filter('date')(book.expiryDate, "dd/MM/yyyy");
                }

                var url = $location.$$protocol + '://' + $location.$$host + ($location.$$port ? ':' + $location.$$port : '') + "/library/" + book.bookId;



                self.sendmail(
                    toEmail,  //to
                    book.bookIdLong + " - " + "This document has been expired early - " + book.bookCode, //subject
                    "Hi " + name + "<br><br>" +
                    "The owner of this document has set it to expire early, so it will be no longer needed from " + earlyExpiryDate + ". The document is published in the following places:<br><br>" +
                    "<blockquote>" +

                    ( production ? "<strong>Production Manager:</strong> <ul>" + production + "</ul>" : "" ) +
                    ( fulfilment ? "<strong>Fulfilment:</strong> <ul>" + fulfilment + "</ul>" : "" ) +
                    ( digital ? "<strong>Digital and Social:</strong> <ul>" + digital + "</ul>" : "" ) +
                    ( support ? "<strong>Support Tools:</strong> <ul>" + support + "</ul>" : "" ) +


                    "</blockquote><br><br>" +
                    "As this document was expired from a LIVE status, it is likely that is has been published on various drives and sites. You are recorded as a publisher of this document; please remove it from any places you have published it, and contact and print houses to ensure it is no longer available for ordering if relevant. <br><br>" +
                    "Click this link to view the item: <a href='" + url + "'>" + book.bookName + " (" + book.bookId + ")" + "</a> <br><br>" +
                    "<strong>Important: documents may still need to be accessible by various departments, business units and customers; ensure that any removed documents are moved to archive folders. If in doubt, contact the owner, " + book.Owner.fullName + ", for further information.</strong><br><br>" +
                    "<i> Note: if you believe you are not the right person or team responsible for taking this action, please contact " + self.clientConfig.email + " as soon as possible.</i><br><br>" +
                    "This email has been sent to the following people: " + emailList,
                    "This document has been expired early",
                    "Document Name: " + book.bookName +
                    "<br>Document Code: " + book.bookCode +
                    "<br>Document ID: " + book.bookIdLong +
                    "<br>Document Owner: " + book.Owner.fullName +
                    "<br>Live Date: " + liveDate +
                    "<br>Expiry Date: " + expiryDate +
                    "<br>Early Expiry Date: " + earlyExpiryDate
                ).$promise.then(function (msg) {
                    funcPromise.resolve(msg);
                });
                //return msg;

          }else{
            funcPromise.resolve();
          }
        });
      }else{
        funcPromise.resolve();
      }
      return funcPromise.promise;
    }



    self.getDisplayStatus = function (thisBook){

      var displayStatus ="";
      if (thisBook.approvalList.length > 0 && thisBook.approvalList) {
        thisBook.thisAppStatus = thisBook.approvalList[thisBook.approvalStatus];
        var now = new Date();
        if (thisBook.status === 1) {




          if (!thisBook.activeDate) {
            displayStatus = 'NO DATE';
          }
          else if (thisBook.withdrawDate && now >= new Date(thisBook.withdrawDate) ) {
            displayStatus = 'EXPD(W)';
          }
          else if (thisBook.earlyExpiryDate && now >= new Date(thisBook.earlyExpiryDate) ) {
            displayStatus = 'EXPD(F)';
          }
          else if (now >= new Date(thisBook.expiryDate)) {
            displayStatus = 'EXPD';
            //console.log(now, "-", new Date(book.expiryDate));
          }
          else if (now >= new Date(thisBook.reviewDate)) {
            displayStatus = 'LIVE(R)';
          }
          else if (now >= new Date(thisBook.liveDate)) {
            displayStatus = 'LIVE';
          }
          else if (now >= new Date(thisBook.activeDate)) {
            displayStatus = 'ACTV';
          }
          else {
            displayStatus = 'READY';
          }

        } else {
          var pend = '';
          if (now >= new Date(thisBook.activeDate) && thisBook.status === 0) {
            pend = "(P)";
          }

          if (thisBook.withdrawDate && now >= new Date(thisBook.withdrawDate) ) {
            displayStatus = 'EXPD(W)';
          }
          else if (thisBook.earlyExpiryDate && now >= new Date(thisBook.earlyExpiryDate) ) {
            displayStatus = 'EXPD(F)';
          }
          else if (now >= new Date(thisBook.expiryDate)) {
            displayStatus = 'EXPD' + pend;
          }else{
           // displayStatus = displayStatus + self.getUserStatus(thisBook.approvalList[thisBook.approvalStatus], pend);
           // if(thisBook.approvalList[thisBook.approvalStatus] && thisBook.approvalList[thisBook.approvalStatus].role == 6 && thisBook.approvalList[thisBook.approvalStatus].content == "final"){displayStatus = "FINAL FILES";}
            displayStatus = 'IN WORK';
          }


        }
      }
      var docStatus = "";
      if (thisBook.status === 0) {
        docStatus =  self.getUserStatus(thisBook.approvalList[thisBook.approvalStatus], pend);
        if(thisBook.approvalList[thisBook.approvalStatus] && thisBook.approvalList[thisBook.approvalStatus].role == 6 && thisBook.approvalList[thisBook.approvalStatus].content == "final"){docStatus = "FINAL FILES";}
      }else{
        docStatus = "COMPLETE";
      }

      return docStatus +" - "+displayStatus;
    }




    self.getUserStatus = function(thisCurrentApproval, pend){
      var displayStatus;
      if (thisCurrentApproval && thisCurrentApproval.role) {
        if (thisCurrentApproval.role != 3) {
          displayStatus = $filter('uppercase')(self.getRoleById(thisCurrentApproval.role).description) + pend;
        } else if (parseInt(thisCurrentApproval.team) > 0) {
          displayStatus = $filter('uppercase')(self.getTeamById(thisCurrentApproval.team).shortName) + pend;
        }
      }
      return displayStatus;
    }





    self.getRoleById = function(roleId){
      return _.find(self.roles, {'id': parseInt(roleId)});
    }
    self.getTeamById = function(teamId){
      return _.find(self.teams, {'teamId': parseInt(teamId)});
    }
    self.getUserById = function(userId){
      return _.find(self.users, {'id': parseInt(userId)});
    }

    self.getTableWidths = function(width, tableHeaders){
      var total = _.sumBy(tableHeaders, function(o){return _.toInteger(o.width);});
      return (width / total) * 100;

    }


    self.attachTableHeader = function(row){
      var deferred = $q.defer();
      Structures.findOne({ filter: { "where":{"structureId": row.parent }, "include":[ {"versions":[{"Contents":"ContentTypes"}]} ] } }).$promise.then(function (results) {
        if (results.versions.Contents.ContentTypes.isJson === true) {
          self.json2Obj(results);
          //console.log(results);
          var rtn = results.tableHeader;
        }else{
          var rtn = false;
        }
        deferred.resolve(rtn);
      });
      return deferred.promise;
    }





    self.getListChildren = function(book,field,type){
      //console.log(book);
      var list = [];
      _.forEach(book.Children, function (val1) {
        if(!type || val1.versions.Contents.ContentTypes.contentTypeGroup === type){ list.push(val1[field]); }
          _.forEach(val1.Children, function (val2) {
            if(!type || val2.versions.Contents.ContentTypes.contentTypeGroup === type){ list.push(val2[field]); }
            _.forEach(val2.Children, function (val3) {
              if(!type || val3.versions.Contents.ContentTypes.contentTypeGroup === type){ list.push(val3[field]); }
              _.forEach(val3.Children, function (val4) {
                if(!type || val4.versions.Contents.ContentTypes.contentTypeGroup === type){ list.push(val4[field]); }
              });
            });
          });

      });

      //console.log(list);
      //console.log(_.uniq(list));

      return _.uniq(list);

    }

    self.getListChildrenNew = function(book,fields,type){
      //console.log(book);
      var list = [];
      _.forEach(book.Children, function (val1) {
        if(!type || val1.versions.Contents.ContentTypes.contentTypeGroup === type){ list.push(_.pick(val1, fields));  }
        _.forEach(val1.Children, function (val2) {
          if(!type || val2.versions.Contents.ContentTypes.contentTypeGroup === type){ list.push(_.pick(val2, fields)); }
          _.forEach(val2.Children, function (val3) {
            if(!type || val3.versions.Contents.ContentTypes.contentTypeGroup === type){ list.push(_.pick(val3, fields));  }
            _.forEach(val3.Children, function (val4) {
              if(!type || val4.versions.Contents.ContentTypes.contentTypeGroup === type){ list.push(_.pick(val4, fields));  }
            });
          });
        });

      });

      return  _.uniqWith(list, _.isEqual);

    }



    self.emailSystemSwap = function(thisEmail){
      if(system === "local") {
        return "jason.hampton@gmail.com";
      }else if(system === "demo" || system === "staging" || system === "switch"){
        return "jason@wraps.io,clark@wraps.io";
      }else{
        return  thisEmail;
      }
    }


    self.saveComments = function(thisBook, thisChild, thisComment, checkedEmailUsers){
      var deferred = $q.defer();
      if(thisChild){
        Events.create({
          "eventId": 0,
          "bookId": thisBook.bookId,
          "structureId": thisChild.structureId,
          "contentId": thisChild.versions.contentId,
          "historyId": thisChild.History[thisChild.History.length - 1].historyId,
          "userId": self.$storage.user.id,
          "eventType": "Comment",
          "eventData": thisComment.message
        }).$promise.then(function(event){
          //console.log("Save Child Comments");
          if(checkedEmailUsers.length > 0){
                self.sendCommentEmail(thisBook, thisComment, checkedEmailUsers, event.eventId).then(function (messageDetails) {

                    deferred.resolve();
                });
          }else{
            deferred.resolve();
          }
        });
      }else{
        Events.create({
          "eventId": 0,
          "bookId": thisBook.bookId,
          "structureId": null,
          "contentId": null,
          "historyId": null,
          "userId": self.$storage.user.id,
          "eventType": "Comment",
            "eventData": thisComment.message
        }).$promise.then(function(event){
          //console.log("Save Doc Comments");

          self.commentsUpdate = event.eventId;
          if(checkedEmailUsers.length > 0){
            self.sendCommentEmail(thisBook, thisComment, checkedEmailUsers, event.eventId).then(function (messageDetails) {

              deferred.resolve();
            });
          }else{
            deferred.resolve();
          }




        });
      }

      return deferred.promise;
    }

    self.loadComments = function (thisBook) {
      //console.log('loading comments for book:'+thisBook.bookId);

      var deferred = $q.defer();

      var filter = {"filter":{
                      "where":{"bookId":thisBook.bookId,"eventType":{"inq": ["Comment","Approved", "Reset to MCO","Reset to Brief", "Delete", "Withdraw", "Early Expired","Edited","Edited Workflow", "Added", "Rejected", "Approve Files","System Notification","Delete Document Request","Delete Document", "Document Created"]}},
                      "include": ["Messages","Users", {History:'versionsNew'}],
                      "order": "eventId DESC"}};


      Events.find(filter).$promise.then(function(events){
        if(thisBook.DocTypes.docTypeTemplate === 1){
          var deleted = _.remove(events, function (item) { //removes document approvals
            return item.structureId != null && item.eventType === "Approved";
          });
        }else{
          var deleted = _.remove(events, function (item) { //removes item approvals for book if it hasn't got a message (pre 2018-08-09 when messages went live)
            return item.structureId === null && item.eventType === "Approved" && item.messageId === null;
          });

          var deleted2 = _.remove(events, function (item) { //removes item approvals for content if after 2018-08-09 - bit a horrid hack!
            return item.structureId  != null && item.eventType === "Approved" && item.messageId  === null && item.created > "2018-08-09";
          });
        }


        _.forEach(events, function (event,index) {
          //TODO this might be replacing user inputs on the word book
          if(event.eventData){event.eventData = event.eventData.replace(/Book/g, "Document");}


          var eventContent;
          if(event.eventData && event.eventData.charAt(0)==='{') {
            event.isJson=true;
            event.rfcReasons = [];
            event.categories = [];
            event.significant = false;
            event.Comment = angular.fromJson(event.eventData);

            if(event.eventType === "System Notification"){
              //event.eventName = "Document " + event.eventType;
              event.eventName = event.eventType;
              //console.log("System Notification debug",self.notificationTypes);

              event.Comment.message = _.find(self.notificationTypes, {'notificationTypeId': parseInt(event.Comment.notificationTypeId)}).notificationTypeDescription;
            }else if(event.eventType === "Delete Document Request"){
              //event.eventName = "Document " + event.eventType;
              event.eventName = event.eventType;
              //console.log("Delete Document Request",self.notificationTypes);

            }else if(event.eventType === "Comment"){
                event.eventName = "" + event.eventType;
            }else if(event.eventType === "Edited Workflow"){

              event.eventName = event.eventType;
              event.Comment.message = "Team workflow reallocation";

            }else {
              //link id's with name from datalists
                //console.log(event.Comment);

              if (!angular.isArray(event.Comment.reason) && event.Comment.reason) {
                event.Comment.reason = [event.Comment.reason];
              }
              _.forEach(event.Comment.reason, function (val, key) {
                event.rfcReasons.push(_.result(_.find(self.rfcReasons, {'id': _.parseInt(val)}), 'name'));
              });

              if (!angular.isArray(event.Comment.category) && event.Comment.category) {
                event.Comment.category = [event.Comment.category];
              }
              _.forEach(event.Comment.category, function (val, key) {
                event.categories.push(_.result(_.find(self.rfcCategories, {'id': _.parseInt(val)}), 'name'));
              });
              if (event.Comment.significant) {
                event.significant = event.Comment.significant;
              }



              if(event.filesList){
                event.Comment.filesList = event.filesList;
              }

              if(event.eventType == "Edited" || event.eventType == "Added"){
                  //console.log("event",event);
                event.eventName = event.eventType;
                var versiondata = null;

                if(event.History && event.History.versionIdNew != event.History.versionIdOld) {
                    if(self.isValidJsonStr(event.History.versionsNew.versiondata)){
                      var parsedVersions = JSON.parse(event.History.versionsNew.versiondata);
                      var contentType = Object.keys(parsedVersions)[0];
                      versiondata = parsedVersions[contentType];
                      if(versiondata){
                        event.Comment.message = versiondata.description;
                        event.Comment.filesList = versiondata.filesList;
                      }
                    }else{
                      // had it here for possible mem guide stuff later
                      //event.Comment.message = event.History.versionsNew.versiondata;
                    }
                }


              }else if (event.Comment.approvalStatus && event.eventType != "Rejected") {

                if(angular.fromJson(event.eventData).message){
                  event.Comment.message += '- Message: ' + angular.fromJson(event.eventData).message;
                }

                if (event.Comment.approvalStatus == 0) {
                  eventContent = thisBook.approvalList[event.Comment.approvalStatus] ? thisBook.approvalList[event.Comment.approvalStatus].content.ucFirst() : '';
                } else {
                  eventContent = thisBook.approvalList[event.Comment.approvalStatus - 1] ? thisBook.approvalList[event.Comment.approvalStatus - 1].content.ucFirst() : '';
                }

                event.eventName = eventContent + " " + event.eventType;
              }else {
                event.eventName = event.eventType;
                if (event.eventType == "Rejected" && event.Comment.approvalStatus){

                  if(event.filesList){
                    event.Comment.filesList = event.filesList;
                  }

                  event.Comment.approvalStatus < 0 ? event.Comment.approvalStatus = 0 : '';
                  eventContent = thisBook.approvalList[event.Comment.approvalStatus] ? thisBook.approvalList[event.Comment.approvalStatus].content.ucFirst() : '';
                  status = self.getUserStatus(thisBook.approvalList[event.Comment.approvalStatus],'');
                  event.Comment.message = eventContent + " " + "workflow reset to " + status + "\n\n" + event.Comment.message;
                  event.eventName = eventContent + " " + event.eventType;

                }

              }
              //console.log("event", event);
            }
          }else{
            //console.log(event);
            event.Comment = {};
            var status;

            if (event.eventType === "Approved") {

              var approvalStatus = self.charToFollowString(event.eventData,"approvalStatus:") | self.charToFollowString(event.eventData,"Approver:");

              if (approvalStatus != undefined){
                if(approvalStatus==0){
                  eventContent = thisBook.approvalList[approvalStatus] ? thisBook.approvalList[approvalStatus].content.ucFirst() : '';
                }else if(approvalStatus % 10 == 0) {
                  //handle the random approvalStatus:20 event data
                    //console.log(approvalStatus, thisBook.approvalList[approvalStatus/10]);
                    eventContent = thisBook.approvalList[approvalStatus/10-1] ? thisBook.approvalList[approvalStatus/10-1].content.ucFirst() : '';
                    //eventContent = self.getUserStatus(thisBook.approvalList[approvalStatus/10],"");
                }else{
                  eventContent = thisBook.approvalList[approvalStatus-1] ? thisBook.approvalList[approvalStatus-1].content.ucFirst() : '';
                }
              }

              event.eventName = eventContent + " " + event.eventType;
              if(self.charToFollowString(event.eventData," Status:")==1){
                status = " to READY";
              }else if(approvalStatus % 10 == 0 && approvalStatus != 0){
                //handles the linear approval status 20 format - where we don't understand the data;
                  status = "";
              }else{
                status = " to " + self.getUserStatus(thisBook.approvalList[approvalStatus],"");
              }

              event.Comment.message = eventContent + " " + "workflow moved" + status+"";



            }else if((event.eventType === "Reset to Brief" || event.eventType === "Reset to MCO") && self.charToFollowString(event.eventData,"approvalStatus:")){

              if(self.charToFollowString(event.eventData,"approvalStatus:")==0){
                eventContent = thisBook.approvalList[self.charToFollowString(event.eventData,"approvalStatus:")] ? thisBook.approvalList[self.charToFollowString(event.eventData,"approvalStatus:")].content.ucFirst() : '';
              }else{
                eventContent = thisBook.approvalList[self.charToFollowString(event.eventData,"approvalStatus:")-1] ? thisBook.approvalList[self.charToFollowString(event.eventData,"approvalStatus:")-1].content.ucFirst() : '';
              }

              event.eventName = event.eventType;
              status = self.getUserStatus(thisBook.approvalList[self.charToFollowString(event.eventData,"approvalStatus:")],"");


              event.Comment.message = eventContent + " " + "workflow reset from " + status+"";

            }else if(event.eventType === "Added"){
              var versiondata = null;
              if(event.History) {
                var parsedVersions = JSON.parse(event.History.versionsNew.versiondata);
                var contentType = Object.keys(parsedVersions)[0];;
                versiondata = parsedVersions[contentType];
              }
              event.eventName = event.eventData.split(' ')[0] + " " + event.eventType;
              event.Comment.message = versiondata ? versiondata.description : event.eventData;
              event.Comment.filesList = versiondata ? versiondata.filesList : [];

            }else if(event.structureId === null && event.eventType === "Comment"){
              //event.eventName = "Document " + event.eventType;
              event.eventName = "" + event.eventType;
              event.Comment.message = event.eventData;
            }else{

              //console.log('event',event);
              //console.log('charToFollowString',self.charToFollowString(event.eventData,"Approver:"));

              if(self.charToFollowString(event.eventData,"Approver:")){

                if(self.charToFollowString(event.eventData," Status:")==1){
                  status = "READY";
                }else{
                  status = self.getUserStatus(thisBook.approvalList[self.charToFollowString(event.eventData,"Approver:")],"");
                }



                eventContent = thisBook.approvalList[self.charToFollowString(event.eventData,"Approver:")] ? thisBook.approvalList[self.charToFollowString(event.eventData,"Approver:")].content.ucFirst() : '';

                event.Comment.message = eventContent + " " + "workflow moved to " + status+"";
                event.eventName = eventContent + " " + event.eventType;
              }else {

                event.eventName = event.eventType;
                event.Comment.message = event.eventData;
              }
            }
          }

          if(event.eventName){event.eventName = event.eventName.replace(/Doc /g, "Document ");}
          if(event.Comment.message){event.Comment.message = event.Comment.message.replace(/Doc /g, "Document ");}
        });




        BooksVersions.find({
            "filter": {
                "where": {"bookId": thisBook.bookId},
                order: 'created ASC'
            }
        }).$promise.then(function (versions) {

          var promises = [];
          if(thisBook.sourceId ){

            var promise1 = $q.defer();
            Books.findOne({"filter": {"where": {"bookId": thisBook.sourceId}}}).$promise.then(function(sourceBook) {
              events.push({
                  eventName: "Document Duplicated",
                  created: Date.parse(moment(thisBook.created).subtract(1, 'minutes')), //this is to set the duplication msg to come before the version data
                  Users: thisBook.Owner,
                  Comment: {message: "Duplicated from: " + thisBook.sourceIdLong + "\n" + sourceBook.bookName + "\n" + sourceBook.bookCode}
              });
              promise1.resolve();
              console.log(thisBook.Owner);
            });
            promises.push(promise1.promise);
          }else{
            if(versions && versions[0] && versions[0].fromBlob == '{}'){
                events.push( {eventName: "Document Created",created: thisBook.created, Users: self.getUserById(versions[0].userId), Comment: {message: "This document was created manually"}});
            }else{
                events.push( {eventName: "Document Created",created: thisBook.created, Users: {'firstName':'User', 'lastName':'Unknown'}, Comment: {message: "This document was created manually"}});
            }
          }

          $q.all(promises).then(function () {
            _.forEach(versions, function (version, ind) {
              if(self.isValidJsonStr(version.diff)){

                var diffs = JSON.parse(version.diff);

                _.forEach(diffs, function (diff, diff_ind) {
                  if(diff.path[0]) { diff.path = diff.path[0]; }
                  if(self.isValidJsonStr(diff.lhs)){
                    diff.lhs = JSON.parse(diff.lhs)
                  }
                  if(self.isValidJsonStr(diff.rhs)){
                    diff.rhs = JSON.parse(diff.rhs)
                  }

                  //clears out non changing entries
                  if  (
                        !diff.path ||
                        (
                          (!diff.lhs || diff.lhs === 0 || diff.lhs === null || angular.equals({}, diff.lhs)) &&
                          (!diff.rhs || diff.rhs === 0 || diff.rhs === null || angular.equals({}, diff.rhs))
                        )
                      ) {
                    diff.delete = true;
                    return;
                  }

                  //set default type to string
                  diff.type = 'string';

                  //only manipulates the fields needed
                  switch (diff.path) {
                    case 'bookId':
                        diff.label = 'ID';
                        break;
                    case 'bookName':
                        diff.label = 'Name';
                        break;
                    case 'bookCode':
                        diff.label = 'Code';
                        break;
                    case 'psiCode':
                        diff.label = 'PSI Code';
                        break;
                    case 'divisionId':
                        diff.label = 'Division';
                        diff.lhs = diff.lhs?$filter('readableDivision')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableDivision')(diff.rhs):null;
                        break;
                    case 'bookOwner':
                        diff.label = 'Owner';
                        diff.lhs = diff.lhs?$filter('readableUser')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableUser')(diff.rhs):null;
                        break;
                    case 'docTypeId':
                        diff.label = 'Document Type';
                        // gets master doctype too
                        diff.lhs = diff.lhs?$filter('readableMasterDocType')(diff.lhs)+" / "+$filter('readableDocType')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableMasterDocType')(diff.rhs)+" / "+$filter('readableDocType')(diff.rhs):null;
                        break;
                    case 'approvalList':
                        diff.label = 'Workflow';
                        diff.type = 'workflow';
                        //removes value:false
                        diff.lhs = _.filter(diff.lhs, function(o) { return o.value; });
                        //swaps out id for details
                        _.forEach(diff.lhs, function(v,i){
                          diff.lhs[i].role = $filter('readableRole')(parseInt(diff.lhs[i].role));
                          diff.lhs[i].team = $filter('readableTeam')(parseInt(diff.lhs[i].team));
                          diff.lhs[i].user = $filter('readableUser')(parseInt(diff.lhs[i].user));
                        });
                        //groups by workflow type - breif/artwork etc
                        diff.lhs = _.groupBy(diff.lhs, 'content');

                        diff.rhs = _.filter(diff.rhs, function(o) { return o.value; });
                        _.forEach(diff.rhs, function(v,i){
                          diff.rhs[i].role = $filter('readableRole')(parseInt(diff.rhs[i].role));
                          diff.rhs[i].team = $filter('readableTeam')(parseInt(diff.rhs[i].team));
                          diff.rhs[i].user = $filter('readableUser')(parseInt(diff.rhs[i].user));
                        });
                        diff.rhs = _.groupBy(diff.rhs, 'content');

                        console.log(diff.rhs);
                        break;
                    case 'emphasis':
                        if(thisBook.DocTypes.docTypeTemplate != 1){ diff.delete = true;}
                        else{
                          diff.label = 'Document Emphasis';
                          diff.lhs = diff.lhs?$filter('readableEmphasis')(diff.lhs):null;
                          diff.rhs = diff.rhs?$filter('readableEmphasis')(diff.rhs):null;
                        }
                        break;
                    case 'extras':
                        diff.label = 'BGHP Colour';
                        //currently the only field being used is bghp colour
                        diff.lhs = diff.lhs && diff.lhs.BGHPColour?$filter('readableBGHPColour')(parseInt(diff.lhs.BGHPColour)):null;
                        diff.rhs = diff.rhs && diff.rhs.BGHPColour?$filter('readableBGHPColour')(parseInt(diff.rhs.BGHPColour)):null;
                        break;
                    case 'insurerId':
                        diff.label = 'Insurer';
                        diff.lhs = diff.lhs?$filter('readableInsurer')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableInsurer')(diff.rhs):null;
                        break;
                    case 'productId':
                        diff.label = 'Product';
                        diff.lhs = diff.lhs?$filter('readableProduct')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableProduct')(diff.rhs):null;
                        break;
                    case 'regionId':
                        diff.label = 'Region';
                        diff.lhs = diff.lhs?$filter('readableRegion')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableRegion')(diff.rhs):null;
                        break;
                    case 'productTypeId':
                        diff.label = 'Product Type';
                        diff.lhs = diff.lhs?$filter('readableProductType')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableProductType')(diff.rhs):null;
                        break;
                    case 'clientId':
                        diff.label = 'Client';
                        diff.lhs = diff.lhs?$filter('readableClient')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableClient')(diff.rhs):null;
                        break;
                    case 'audienceId':
                        diff.label = 'Audience';
                        diff.lhs = diff.lhs?$filter('readableAudience')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableAudience')(diff.rhs):null;
                        break;
                    case 'language':
                        diff.label = 'Language';
                        diff.lhs = diff.lhs?$filter('readableLanguage')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableLanguage')(diff.rhs):null;
                        break;
                    case 'subInsurerId':
                        diff.label = 'Sub Insurer';
                        diff.lhs = diff.lhs?$filter('readableSubInsurer')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableSubInsurer')(diff.rhs):null;
                        break;
                    case 'distributorID':
                        diff.label = 'Distributor';
                        diff.lhs = diff.lhs?$filter('readableDistributor')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableDistributor')(diff.rhs):null;
                        break;
                    case 'projectId':
                        diff.label = 'Project';
                        diff.lhs = diff.lhs?$filter('readableProject')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableProject')(diff.rhs):null;
                        break;
                    case 'socialChannelIds':
                        diff.label = "Social Channels";
                        if (diff.lhs && diff.lhs.length > 0) {
                            var count = 0;
                            var tempLhs = "";
                            _.forEach(diff.lhs, function(id){
                                if (count === 0) {
                                    count++;
                                } else {
                                    tempLhs += ", ";
                                }
                                tempLhs += $filter('readableSocialChannel')(id);
                            });
                            diff.lhs = tempLhs;
                        } else {
                            diff.lhs = null;
                        }
                        if (diff.rhs && diff.rhs.length > 0) {
                            var count = 0;
                            var tempRhs = "";
                            _.forEach(diff.rhs, function(id){
                                if (count === 0) {
                                    count++;
                                } else {
                                    tempRhs += ", ";
                                }
                                tempRhs += $filter('readableSocialChannel')(id);
                            });
                            diff.rhs = tempRhs;
                        } else {
                            diff.rhs = null;
                        }

                        break;
                    case 'finProm':
                        diff.label = 'Financial Promotion';
                        //might need some checks
                        diff.lhs = diff.lhs;
                        diff.rhs = diff.rhs;
                        break;
                    case 'limitDoc':
                        diff.label = 'Private Document';
                        //might need some checks
                        diff.lhs = diff.lhs;
                        diff.rhs = diff.rhs;
                        break;
                    case 'production':
                        diff.label = 'Production Manager';
                        diff.type = 'array'
                        _.forEach(diff.lhs, function(v,i){ diff.lhs[i] = $filter('readablePublishTypes')(parseInt(i)); });
                        _.forEach(diff.rhs, function(v,i){ diff.rhs[i] = $filter('readablePublishTypes')(parseInt(i)); });
                        break;
                    case 'fulfilment':
                        diff.label = 'Fulfilment';
                        diff.type = 'array'
                        _.forEach(diff.lhs, function(v,i){ diff.lhs[i] = $filter('readablePublishTypes')(parseInt(i)); });
                        _.forEach(diff.rhs, function(v,i){ diff.rhs[i] = $filter('readablePublishTypes')(parseInt(i)); });
                        break;
                    case 'digital':
                        diff.label = 'Digital & Social';
                        diff.type = 'array'
                        _.forEach(diff.lhs, function(v,i){ diff.lhs[i] = $filter('readablePublishTypes')(parseInt(i)); });
                        _.forEach(diff.rhs, function(v,i){ diff.rhs[i] = $filter('readablePublishTypes')(parseInt(i)); });
                        break;
                    case 'support':
                        diff.label = 'Support Tools';
                        diff.type = 'array'
                        _.forEach(diff.lhs, function(v,i){ diff.lhs[i] = $filter('readablePublishTypes')(parseInt(i)); });
                        _.forEach(diff.rhs, function(v,i){ diff.rhs[i] = $filter('readablePublishTypes')(parseInt(i)); });
                        break;
                    case 'inSitu':
                        diff.label = 'Evidence';
                        diff.type = 'array'
                        _.forEach(diff.lhs, function(v,i){ diff.lhs[i] = $filter('readablePublishTypes')(parseInt(i)); });
                        _.forEach(diff.rhs, function(v,i){ diff.rhs[i] = $filter('readablePublishTypes')(parseInt(i)); });
                        break;
                    case 'levels':
                        if(thisBook.DocTypes.docTypeTemplate != 1){ diff.delete = true;}
                        else {
                          diff.label = 'Levels';
                          diff.type = 'array';
                          diff.lhs = _.filter(diff.lhs, function(o) { return o.active; });
                          diff.rhs = _.filter(diff.rhs, function(o) { return o.active; });
                          _.forEach(diff.lhs, function(v,i){ diff.lhs[i] = diff.lhs[i].name; });
                          _.forEach(diff.rhs, function(v,i){ diff.rhs[i] = diff.rhs[i].name; });
                        }
                        break;
                    case 'bookContent':
                        diff.label = 'Content';
                        diff.type = 'array';
                        //revoves false objects
                        diff.lhs = _.pickBy(diff.lhs, _.identity);
                        diff.rhs = _.pickBy(diff.rhs, _.identity);
                        _.forEach(diff.lhs, function(v,i){ diff.lhs[i] = $filter('readableContent')(parseInt(i)); });
                        _.forEach(diff.rhs, function(v,i){ diff.rhs[i] = $filter('readableContent')(parseInt(i)); });
                        break;
                    case 'languageVersions':
                        diff.label = 'Language Versions';
                        diff.type = 'array';
                        //revoves false objects
                        diff.lhs = _.pickBy(diff.lhs, _.identity);
                        diff.rhs = _.pickBy(diff.rhs, _.identity);
                        _.forEach(diff.lhs, function(v,i){ diff.lhs[i] = $filter('readableLanguage')(parseInt(i)); });
                        _.forEach(diff.rhs, function(v,i){ diff.rhs[i] = $filter('readableLanguage')(parseInt(i)); });
                        break;
                    case 'created':
                        diff.label = 'Created Date';
                        diff.lhs = diff.lhs?$filter('date')(diff.lhs, 'dd MMM yyyy'):null;
                        diff.rhs = diff.rhs?$filter('date')(diff.rhs, 'dd MMM yyyy'):null;
                        break;
                    case 'activeDate':
                        diff.label = 'Active Date';
                        diff.lhs = diff.lhs?$filter('date')(diff.lhs, 'dd MMM yyyy'):null;
                        diff.rhs = diff.rhs?$filter('date')(diff.rhs, 'dd MMM yyyy'):null;
                        break;
                    case 'liveDate':
                        diff.label = 'Live Date';
                        diff.lhs = diff.lhs?$filter('date')(diff.lhs, 'dd MMM yyyy'):null;
                        diff.rhs = diff.rhs?$filter('date')(diff.rhs, 'dd MMM yyyy'):null;
                        break;
                    case 'reviewDate':
                        diff.label = 'Review Date';
                        diff.lhs = diff.lhs?$filter('date')(diff.lhs, 'dd MMM yyyy'):null;
                        diff.rhs = diff.rhs?$filter('date')(diff.rhs, 'dd MMM yyyy'):null;
                        break;
                    case 'expiryDate':
                        diff.label = 'Expiry Date';
                        diff.lhs = diff.lhs?$filter('date')(diff.lhs, 'dd MMM yyyy'):null;
                        diff.rhs = diff.rhs?$filter('date')(diff.rhs, 'dd MMM yyyy'):null;
                        break;
                    case 'withdrawDate':
                        diff.label = 'Withdraw Date';
                        diff.lhs = diff.lhs?$filter('date')(diff.lhs, 'dd MMM yyyy'):null;
                        diff.rhs = diff.rhs?$filter('date')(diff.rhs, 'dd MMM yyyy'):null;
                        break;
                    case 'notes':
                        diff.label = 'Notes';
                        break;
                    default:
                      //sets unused fields to deleted
                      diff.delete = true;
                  }
                });

                //deletes unrequired fields
                var changes = _.filter(diffs, function(o) { return !o.delete; });

                //sets display order
                var order = [
                              'bookName',
                              'bookCode',
                              'psiCode',
                              'divisionId',
                              'bookOwner',
                              'docTypeId',
                              'approvalList',
                              'emphasis',
                              'extras',
                              'insurerId',
                              'productId',
                              'regionId',
                              'productTypeId',
                              'clientId',
                              'audienceId',
                              'language',
                              'subInsurerId',
                              'distributorID',
                              'projectId',
                              'finProm',
                              'limitDoc',
                              'production',
                              'fulfilment',
                              'digital',
                              'support',
                              'inSitu',
                              'levels',
                              'bookContent',
                              'languageVersions',
                              'created',
                              'activeDate',
                              'liveDate',
                              'reviewDate',
                              'expiryDate',
                              'withdrawDate',
                              'notes',
                            ];

                //sorts as per order
                changes.sort(function(a, b){
                  return order.indexOf(a.path) - order.indexOf(b.path)
                });

                //console.log("changes",version.bookVersionId,changes);

                //         change.path == 'approvalList' ||



                // adds event with message for each qualifing version
                if (changes && changes.length > 0) {
                  var fieldsString = changes.length > 2 ?
                      changes[0].label + ', ' + changes[1].label + ' and ' + (changes.length - 2) + ' other fields edited.' :
                      changes.length > 1 ?
                          changes[0].label + ' and ' + changes[1].label + ' edited.':
                          changes[0].label + ' edited.';

                  var event = {
                      eventName: 'Document Info Edited',
                      created: version.created,
                      Users: self.getUserById(version.userId),
                      Comment: {
                          message: fieldsString,
                          diff: changes
                      }
                  }
                  events.push(event);
                }

              }

            });

            angular.extend(self.selectedEvents, events);
            deferred.resolve(events);
          });
        });


      });


      return deferred.promise;
    };







    self.getQREFs = function (isLoading){

      var deferred = $q.defer();
      isLoading = true;
      Books.find({
        filter: {
          where: {
            docTypeId: 33,
            expiryDate: {gt: moment(new Date())},
            and: [{
              or: [{
                earlyExpiryDate: {gt: moment(new Date())}
              }, {
                earlyExpiryDate: null
              }]
            }]
          }
        }
      }).$promise.then(function (data) {

        _.forEach(data, function(value, key) {
          var inFinals = _.findIndex(value.approvalList, ['content', 'final']);
          if(inFinals >= 0 || value.status == 1){
            value.isLive = true;
          }
        });

        isLoading = false;
        deferred.resolve(data);
      });
      return deferred.promise;
    }


      self.toggleTooltip = function(helpText) {
        /*self.helpText = 'Hover over elements in the page to find out what they do';
        self.showToolTip = (self.showToolTip ? false : true);*/
        $rootScope.$emit("toggleTooltip");
      };

      self.displayHelpText = function (helpText) {
        $rootScope.$emit("tooltip", helpText);
      };




    self.toJson = function(data) {
      if(angular.isObject(data)){
        return angular.toJson(data);
      }else{
        return data;
      }
    }

    self.isValidJsonStr = function(str) {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
    }


    self.charToFollowString = function(str, search) {
      var result;
      var regexp = new RegExp(search+'\\s*(\\d+)', 'i');
      if((result = regexp.exec(str)) && str){
        return result[1];
      }else{
        return false;
      }


      //created regexp version above to replace
      // if(str && str.indexOf(search)>=0) {
      //   if(str.charAt(str.indexOf(search) + search.length)==" "){
      //     return str.charAt(str.indexOf(search) + search.length+1);
      //   }else{
      //
      //     return str.charAt(str.indexOf(search) + search.length);
      //   }
      //
      // }else{
      //   return false;
      // }
    }

    self.isImage = function(src) {

      var deferred = $q.defer();

      var image = new Image();
      image.onerror = function() {
        deferred.resolve(false);
      };
      image.onload = function() {
        deferred.resolve(true);
      };
      image.src = src;

      return deferred.promise;
    }


    String.prototype.ucFirst = function()
    {
      return this.charAt(0).toUpperCase() + this.substr(1);
    }


  }
}());
