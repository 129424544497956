(function () {
  'use strict';

  angular
    .module('drive')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('drive', {
        url: '/drive/:insurer/:product/:category/:dtype/:doc',
		params: {
			insurer: {
				dynamic: true,
				value: null,
				squash: true
			},
			product: {
				dynamic: true,
				value: null,
				squash: true
			},
			category: {
				dynamic: true,
				value: null,
				squash: true
			},
			dtype: {
				dynamic: true,
				value: null,
				squash: true
			},
			doc: {
				dynamic: true,
				value: null,
				squash: true
			}
		},
        views: {
          tooltip: {
              templateUrl: 'tooltip/tooltip.tpl.html',
              controller: 'TooltipCtrl',
              controllerAs: 'tooltip'
          },
          navigation: {
            templateUrl: 'navigation/navigation.tpl.html',
            controller: 'NavigationCtrl',
            controllerAs: 'navigation'
          },
          main: {
            templateUrl: 'drive/drive.tpl.html',
            controller: 'DriveCtrl',
            controllerAs: 'drive'
          },
          footer: {
            templateUrl: 'footer/footer.tpl.html',
            controller: 'FooterCtrl',
            controllerAs: 'footer'
          }
        }
      });
  }
}());
