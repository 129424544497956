angular
    .module('library')
    .component('tabProducts', {
        templateUrl: 'library/library-components/right-menu/tab-products/tab-products.tpl.html',
        controller: tabProductsCtrl,
        controllerAs: 'tabProducts',
        bindings: {
            book: '<',
            visible:'<',
            disabled: '<'
        }

    });
tabProductsCtrl.$inject = ['$localStorage', '$scope', 'ProductIds'];
function tabProductsCtrl($localStorage, $scope, ProductIds) {
    var vm = this;

    $scope.sortBy = function (propertyName) {
        vm.reverse = (vm.orderBy === propertyName) ? !vm.reverse : false;
        vm.orderBy = propertyName;
    };



    $scope.$watch('tabProducts.visible',function() {

        ProductIds.find({"filter": {"where": {"divisionId": vm.book.divisionId }, "include": ["Insurers"]}}, function (results) {
            vm.productIds = results;

            var filterKeys, filterObj;

            $scope.$watchCollection('tabProducts.book.productIdsSelectedArray', function (selectedProductIds) {
                vm.selectProductIdsArray = [];
                _.forEach(selectedProductIds, function (val, selectedProductId) {

                    if(val === true) {
                        vm.selectProductIdsArray.push(selectedProductId);
                    }
                });

                vm.selectProductIdsArray = vm.selectProductIdsArray.map(function (x) {
                    return parseInt(x, 10);
                });

                filterObj = {
                    "productId": vm.selectProductIdsArray
                };

                vm.selectedRows = vm.filterProds(vm.productIds, filterObj);
            });

            vm.filterProds = function(productIds, filter) {
                filterKeys = Object.keys(filter);
                // return productIds.filter((item) => {
                return productIds.filter(function(item) {
                    //  return filterKeys.every(key => !!~filter[key].indexOf(item[key]));
                    return filterKeys.every(function(key) {return !!~filter[key].indexOf(item[key])});
                });
            };

        });
    });


    $scope.$watchCollection('tabProducts.selectedRows', function (selectedRows) {

        //console.log("productIdsSelectedArray",vm.book.productIdsSelectedArray);
        //console.log("selectedRows",selectedRows);

        if(selectedRows) {
            _.forEach(vm.book.productIdsSelectedArray, function (item, key) {
                vm.book.productIdsSelectedArray[key] = false;

            });

            _.forEach(selectedRows, function (row) {
                vm.book.productIdsSelectedArray[row.productId] = true;

            });
        }
    });

    if (!vm.productsQuery) {
        vm.productsQuery = {
            order: 'id',
            limit: 15,
            page: 1
        };
    }

}