<md-content layout-padding>
    <div layout="row" layout-wrap>
        <div style="margin-top:10px" flex="100" layout="row" layout-align="center center"">
        <!--<span class="timeline-step" wraps-tooltip="docman-date-event_active" ng-if="tabDates.reviewDateDescription != 'Expiry Date'">
            <span class="inner" md-colors="tabDates.focus == 'active' || tabDates.focus == 'approval' ? {background:'accent'} : {color:'primary'}"></span>
            <span class="label">Active</span>
        </span>-->
        <date-icon date="tabDates.book.activeDate" color="tabDates.focus == 'active' ? 'accent' : 'background-500'" label="Active" ng-if="tabDates.reviewDateDescription != 'Expiry Date'"></date-icon>
        <div flex class="timeline" md-colors="tabDates.focus == 'active' ? {background:'accent'} : {color:'primary'}" ng-if="tabDates.reviewDateDescription != 'Expiry Date'"></div>
        <date-icon date="tabDates.book.liveDate" color="tabDates.focus == 'live' || tabDates.focus == 'approval' ? 'accent' : 'background-500'" label="Live" notsetlabel="'on,App,'"></date-icon>
        <div flex  class="timeline" md-colors="tabDates.focus == 'expiry' ? {background:'accent'} : {color:'primary'}"></div>
        <div flex  class="timeline" md-colors="tabDates.focus == 'expiry' ? {background:'accent'} : {color:'primary'}"></div>
        <date-icon date="tabDates.book.reviewDate" color="tabDates.focus == 'review' ? 'accent' : 'background-500'" label="Review" notsetlabel="tabDates.book.dates.reviewPeriod"></date-icon>
        <div flex  class="timeline" md-colors="tabDates.focus == 'expiry' || tabDates.focus == 'review' ? {background:'accent'} : {color:'primary'}"></div>
        <span class="timeline-placeholder timeline" md-colors="tabDates.focus == 'expiry' ? {background:'accent'} : {color:'primary'}" ng-if="tabDates.reviewDateDescription != 'Expiry Date'">
                    </span>

        <div flex ng-if="tabDates.reviewDateDescription != 'Expiry Date'" class="timeline" md-colors="tabDates.focus == 'expiry' ? {background:'accent'} : {color:'primary'}"></div>
        <date-icon date="tabDates.book.expiryDate" color="tabDates.focus == 'expiry' ? 'accent' : 'background-500'" label="Expiry" notsetlabel="tabDates.book.dates.lifecyclePeriod"></date-icon>

    </div>
    <div style="margin-top:25px" flex="100" layout="row" layout-align="center center" ng-if="tabDates.reviewDateDescription != 'Expiry Date'">
         <span class="timeline-placeholder"></span>
        <div flex></div>
        <span class="timeline-placeholder"></span>
        <div flex></div>
        <div flex></div>
        <span class="timeline-placeholder"></span>
        <div flex></div>
        <date-icon date="tabDates.book.dates.activeDateAnniversary" color="tabDates.focus == 'active' ? 'accent-300' : 'background-400'" label="{{tabDates.reviewDateDescription}}"></date-icon>
        <div flex class="timeline" md-colors="tabDates.focus == 'active' ? {background:'accent-300'} : {background:'background-400'}"></div>
        <date-icon date="tabDates.book.dates.liveDateAnniversary" color="tabDates.focus == 'expiry' ? 'accent-300' : 'background-400'" label="Next Live"></date-icon>
    </div>
    <h4 flex="100">&nbsp;</h4>


    <md-input-container flex="0">
    <!--Hidden until LatAm release-->
        <!--<labeL>Timezone for Document Dates</labeL>-->
        <!--<md-select  flex="50" ng-model="tabDates.book.dates.timezone" ng-change="tabDates.changeLiveDate(tabDates.book)">-->
            <!--<md-option ng-repeat="zone in tabDates.timezones" ng-value="zone">{{zone}}</md-option>-->
        <!--</md-select>-->
    </md-input-container>

    <!--tabDates.disabled: {{tabDates.disabled}}-->

    <div flex="100">
    <md-input-container ng-mouseover="tabDates.focus = 'approval'" ng-mouseleave="tabDates.focus = ''">
        <md-switch ng-model="tabDates.book.liveOnApproval" aria-label="Go Live on Approval" ng-change="tabDates.goLiveOnApproval()"  ng-disabled="tabDates.disabled">
            Go live on approval
            <md-button aria-label="Go Live on Approval Help" class="md-icon-button" style="position: relative; right:10px;" >
                <ng-md-icon icon="help" style="fill: darkgray;" size="20"></ng-md-icon>
                <!--<md-tooltip md-direction="right" >Go Live on Approval</md-tooltip>-->
                <md-tooltip md-direction="right" >Set the Live Date of this document to occur when the document has been approved</md-tooltip>
            </md-button>
        </md-switch>
    </md-input-container>
    </div>

    <div flex="50">
    <md-input-container ng-if="!tabDates.book.liveOnApproval" ng-mouseover="tabDates.focus = 'active'" ng-mouseleave="tabDates.focus = ''" >

        <h4 wraps-tooltip="docman-dates-input_active" >
            Active Date
            <md-button aria-label="Active Date Help" class="md-icon-button" style="position: relative; right:10px;" >
                <ng-md-icon icon="help" style="fill: darkgray;" size="20"></ng-md-icon>
                --><md-tooltip md-direction="right" >Set the date when the document first becomes available to the end-user. <br/>This may be different to the Live Date e.g if the document needs to be assessed by customers prior to a product renewal.</md-tooltip>
            </md-button></h4>

        <span class="dateDisplaySmall" >{{tabDates.book.activeDate | date:'dd MMM yyyy HH:mm Z'}}</span>

        <md-radio-group ng-model="tabDates.book.dates.previewPeriod" ng-disabled="tabDates.disabled" ng-change="tabDates.changeActiveDate(tabDates.book)">
            <md-radio-button value="0,months">Same as Live</md-radio-button>
            <md-radio-button value="2,months">2 months before Live</md-radio-button>
            <md-radio-button value="1,months">1 month before Live</md-radio-button>
        </md-radio-group>
        <p ng-if="tabDates.book.activeDate  && tabDates.book.activeDate < tabDates.today" class="error animate-error-if">Active Date in the past</p>

    </md-input-container>
    </div>
    <div flex="50">
    <md-input-container  ng-if="!tabDates.book.liveOnApproval" ng-mouseover="tabDates.focus = 'live'" ng-mouseleave="tabDates.focus = ''">
        <h4 wraps-tooltip="docman-dates-input_live" >
            Live Date
            <md-button aria-label="Live Date Help" class="md-icon-button" style="position: relative; right:10px;" >
                <ng-md-icon icon="help" style="fill: darkgray;" size="20"></ng-md-icon>
                <!--<md-tooltip md-direction="right">This is the date that the content of the document is Live from.</md-tooltip>-->
                <md-tooltip md-direction="right">Set the date that the content of the document is Live from.</md-tooltip>
            </md-button>
        </h4>
        <span class="dateDisplaySmall" >{{tabDates.book.liveDate | date:'dd MMM yyyy HH:mm Z'}}</span> <!--{{tabDates.book.liveDate | date:'dd MMM yyyy HH:mm Z'}}-->

        <!--<label class="animate-if" ng-if="tabDates.book.liveDate">Live</label>-->
        <md-datepicker  ng-model-options="{timezone:tabDates.book.dates.timezone}" ng-required="!tabDates.book.liveOnApproval"  ng-model="tabDates.book.liveDate" md-placeholder="Live Date"  ng-disabled="tabDates.disabled" flex  ng-change="tabDates.changeLiveDate(tabDates.book)" name="livedate"></md-datepicker>
        <div ng-messages="documentInfoForm.livedate.$error" role="alert" class="error animate-error-if">
            <div ng-message="required">Live Date not set</div>
            <div ng-message-default>Live Date is invalid</div>
        </div>

        <p ng-if="tabDates.book.liveDate  && tabDates.book.liveDate < tabDates.today" class="error animate-error-if">Live Date in the past</p>
    </md-input-container>
    </div>

    <div flex="50">
    <md-input-container ng-mouseover="tabDates.focus = 'review'" ng-mouseleave="tabDates.focus = ''">

        <h4 wraps-tooltip="docman-dates-input_review">
            Review Date
            <md-button aria-label="Review Date Help" class="md-icon-button" style="position: relative; right:10px;" >
                <ng-md-icon icon="help" style="fill: darkgray;" size="20"></ng-md-icon>
                <md-tooltip md-direction="right">Set the length of time for document reviews to take place. This will set a date based on when the next active date would be for renewing products, or based on the expiry date if no active period is set.</md-tooltip>
                <!--<md-tooltip md-direction="right">This date is when the review window opens before the end date. It gives the Content Owners and the Document Lifecycle Owner time to review, make amends and approve the new version of the document (if necessary). The Review Date is typically one year from the Active Date, minus the Review Date selected.</md-tooltip>
            --></md-button></h4>
        <span class="dateDisplaySmall" >{{tabDates.book.reviewDate | date:'dd MMM yyyy HH:mm Z'}}</span>



        <md-radio-group ng-model="tabDates.book.dates.reviewPeriod"   ng-disabled="tabDates.disabled" ng-change="tabDates.changeReviewDate(tabDates.book)"  >
            <md-radio-button value="3,months">3 months before {{tabDates.reviewDateDescription}}</md-radio-button>
            <md-radio-button value="2,months" >2 months before {{tabDates.reviewDateDescription}}</md-radio-button>
            <md-radio-button value="1,months" >1 month before {{tabDates.reviewDateDescription}}</md-radio-button>
            <md-radio-button value="2,weeks" >2 weeks before {{tabDates.reviewDateDescription}}</md-radio-button>
        </md-radio-group>
        <p ng-if="tabDates.book.reviewDate  && tabDates.book.reviewDate < tabDates.today" class="error animate-error-if">Review Date in the past</p>
        <p ng-if="tabDates.book.reviewDate && tabDates.book.liveDate  && tabDates.book.reviewDate < tabDates.book.liveDate" class="error animate-error-if">Review Date before Live Date</p>
        <p ng-if="tabDates.book.dates.reviewPeriod != '3,months' && tabDates.book.dates.reviewPeriod != '2,months' && tabDates.book.dates.reviewPeriod != '1,months' && tabDates.book.dates.reviewPeriod != '2,weeks'" class="error animate-error-if">Review Period is non-standard</p>
        <!--<p>{{tabDates.book.reviewDate}}</p>-->
    </md-input-container>
    </div>

    <div flex="50">
    <md-input-container ng-mouseover="tabDates.focus = 'expiry'" ng-mouseleave="tabDates.focus = ''" >
        <h4 wraps-tooltip="docman-dates-input_expiry">
            Expiry Date
            <md-button aria-label="Expiry Date Help" class="md-icon-button" style="position: relative; right:10px;" >
                <ng-md-icon icon="help" style="fill: darkgray;" size="20"></ng-md-icon>
                <md-tooltip md-direction="right">Set the date when the document can no longer be used.<br/>Renewing documents will require a new version to be created and approved.</md-tooltip>
                <!--<md-tooltip md-direction="right">This is the date from which the document can no longer be used.
                    If the document is to be renewed, a new version will need to be created and approved as per the normal process;
                    however, this version of the document will no longer be in use past the Expiry Date.
                    <br><br>
                    <i>Note: If documents are found to still be in use after the recorded expiration date,
                        a risk event may need to be raised and reported. Non-compliant customer-facing
                        documentation can lead to serious consequences for our business and as such can
                        be very high profile. It is imperative that every effort is made to ensure that
                        all available customer-facing documentation is compliant, and if it is expired, that it is no longer in use.</i></md-tooltip>-->
            </md-button></h4>

        <span class="dateDisplaySmall" >{{tabDates.book.expiryDate | date:'dd MMM yyyy HH:mm Z'}}</span>

        <!--<label class="animate-if" ng-if="tabDates.book.expiryDate">Expiry</label>-->
        <md-radio-group ng-model="tabDates.book.dates.lifecyclePeriod"   ng-disabled="tabDates.disabled" ng-change="tabDates.changeExpiryDate(tabDates.book)" >
            <md-radio-button value="12,months">12 months from live date</md-radio-button>
            <md-radio-button value="9,months">9 months from live date</md-radio-button>
            <md-radio-button value="6,months">6 months from live date</md-radio-button>
            <md-radio-button value="3,months">3 months from live date</md-radio-button>
            <md-radio-button value="0,months">Specific date:
            </md-radio-button>
        </md-radio-group>
        <md-datepicker ng-if="tabDates.book.dates.lifecyclePeriod == '0,months'" md-min-date="tabDates.book.liveDate" ng-model="tabDates.expiryDate" md-placeholder="Expiry Date"  ng-disabled="tabDates.disabled" ng-change="tabDates.changeExpiryDate(tabDates.book)" flex></md-datepicker>
        <p ng-if="tabDates.book.expiryDate && tabDates.book.liveDate  && tabDates.book.expiryDate < tabDates.book.liveDate" class="error animate-error-if">Expiry Date  before Live Date</p>
        <p ng-if="tabDates.book.expiryDate  && tabDates.book.expiryDate < tabDates.today" class="error animate-error-if">Expiry Date in the past</p>
        <p ng-if="!tabDates.book.expiryDate  && tabDates.book.dates.lifecyclePeriod == '0,months'" class="error animate-error-if">Expiry Date not set</p>



    </md-input-container>
    </div>



    </div>
</md-content>
