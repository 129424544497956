(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name permissionmanagement.controller:PermissionmanagementCtrl
     *
     * @description
     *
     */
    angular
        .module('permissionmanagement')
        .controller('PermissionmanagementCtrl', PermissionmanagementCtrl);

    function PermissionmanagementCtrl($filter, $localStorage, $location, $mdDialog, $mdSidenav, $q, $scope, $timeout, PermissionCategories, PermissionGroupJoin, PermissionGroups, Permissions, Users, Wraps) {
        var vm = this;
        vm.ctrlName = 'PermissionmanagementCtrl';


        $scope.$storage = $localStorage;

        if (!Users.isAuthenticated()) {
            $location.path('/');
        }
		vm.hasAccess = null;
		vm.hasPermissions = Wraps.hasPermissions;
		vm.hasAnyOfPermissionCat = Wraps.hasAnyOfPermissionCat;

        vm.tables = {
            'Permissions': {
                'friendlyName': 'Permissions',
                'pk': 'permissionId',
                'deletedCol': 'permissionStatus',
                'orderby': ['permissionName'],
				'disabled':true,
                'fields': [
                    {name: 'permissionId', unique: true, required: true},
                    {name: 'permissionName', unique: true, required: true},
                    {name: 'permissionDescription', unique: false, required: true},
                    {name: 'permissionCode', unique: false, required: true},
                    {name: 'permissionCategoryName', unique: false, required: true},
                ],
                'dropDown': {
                    'parentColName':'permissionCategoryId',
                    'tableName':'PermissionCategories',
                    'childPk':'permissionCategoryId',
                    'childColName':'permissionCategoryName'
                }
            },
            'PermissionGroups': {
                'friendlyName': 'Permission Groups',
                'pk': 'groupId',
                'deletedCol': 'groupStatus',
                'orderby': ['groupName'],
				'disabled':true,
                'fields': [
                    {name: 'groupId', unique: true, required: true},
                    {name: 'groupName', unique: true, required: true},
                    {name: 'groupDescription', unique: false, required: true},
                ]
            },
            'PermissionCategories': {
                'friendlyName': 'Permission Categories',
                'pk': 'permissionCategoryId',
                'deletedCol': 'permissionCategoryStatus',
                'orderby': ['permissionCategoryName'],
				'disabled':true,
                'fields': [
                    {name: 'permissionCategoryId', unique: true, required: true},
                    {name: 'permissionCategoryName', unique: true, required: true},
                    {name: 'permissionCategoryDescription', unique: false, required: true},
                ],
				'hasMany':{'table':'Permissions','fk':'permissionCategoryId', 'fdel':'permissionStatus'}
            }
        };
		//console.log(Wraps.fetchedUser);
		Wraps.fetchedUser.then(function(){
			vm.tables.Permissions.disabled = !vm.hasPermissions('adminPermissions');
			vm.tables.PermissionGroups.disabled = !vm.hasPermissions('adminPermissions');
			vm.tables.PermissionCategories.disabled = !vm.hasPermissions('adminPermissions');
			vm.hasAccess = vm.hasAnyOfPermissionCat(5);
		});

        var tmp = {};
        Object.keys(vm.tables).sort().forEach(function (key) {
            tmp[key] = vm.tables[key];
        });
        vm.tables = tmp;
        vm.assignPermissionsToGroups = false;

        vm.changeTable = function (tableName) {
			//console.log(tableName);
            vm.assignPermissionsToGroups = false;
            vm.cols = vm.tables[tableName].fields;
            vm.pk = vm.tables[tableName].pk;
            vm.tableName = tableName;
            vm.deletedCol = vm.tables[tableName].deletedCol;
            vm.orderBy = vm.tables[tableName].orderby;
            vm.reverse = false;
			$scope.$storage.selectedRow = ''; 
			$scope.$storage[tableName] = {};
            vm.deleteArray = {};
            vm.grabRows(tableName);
			
        };

        $scope.sortBy = function (propertyName) {
            vm.reverse = (vm.orderBy === propertyName) ? !vm.reverse : false;
            vm.orderBy = propertyName;
        };

        vm.clearCats = function () {
            _.forEach(vm.PermissionCategories, function (cat) {
                vm.isAllSelected = ['cat_' + cat.permissionCategoryId];
                vm.isAllSelected['cat_' + cat.permissionCategoryId] = false;
            });
            vm.isAllSelectedMisc = false;
        }

        vm.grabRows = function (tableName) {
            vm.rows = [];
			vm.isLoading = true;
            vm.deleteArray = {};

            var args = {};
            if(typeof vm.tables[tableName].dropDown !== 'undefined') {
                args = {"filter":{"include":vm.tables[tableName].dropDown.tableName, "where":{}}};
                args.filter.where[vm.deletedCol]=1;
				PermissionCategories.__get__notDeleted(function (PermissionCategories) {
					vm.PermissionCategories = PermissionCategories;
					vm.clearCats();
				});
            }else{
				args = {"filter":{"where":{}}};
                args.filter.where[vm.deletedCol]=1;
			}

            eval(tableName).find(args).$promise.then(function (results) {
               /* _.forEach(results, function (row) {
                    if (row[vm.deletedCol] == 1) {
                            vm.rows.push(row);
                    }
                });*/
				vm.rows = results;
				vm.isLoading = false;
            });



        };


        vm.toggleAll = function (checkboxCategory) {
            var toggleStatus = [checkboxCategory];
            toggleStatus[checkboxCategory] = !vm.isAllSelected['cat_' + checkboxCategory];

            angular.forEach(vm.permissions, function (itm) {
                if (itm.permissionCategoryId == checkboxCategory && itm.permissionCode != 'adminPermissions') {
                    itm.selected = toggleStatus[checkboxCategory];
                }
            });
        };

        vm.optionToggled = function (checkboxCategory) {
            vm.isAllSelected['cat_' + checkboxCategory] = true;

            var keepGoing = true;

            angular.forEach(vm.permissions, function (itm) {
                if (keepGoing === true && (itm.permissionCategoryId == checkboxCategory) && itm.selected !== true) {
                    vm.isAllSelected['cat_' + checkboxCategory] = false;
                    keepGoing = false;
                    //break
                }
            });
        };

        vm.toggleAllMisc = function () {
            var toggleStatusMisc;
            toggleStatusMisc = !vm.isAllSelectedMisc;

            angular.forEach(vm.permissions, function (itm) {
                if (itm.permissionCategoryId == null) {
                    itm.selected = toggleStatusMisc;
                }
            });
        };

        vm.optionToggledMisc = function () {
            vm.isAllSelectedMisc = true;

            var keepGoing = true;

            angular.forEach(vm.permissions, function (itm) {
                //console.log(itm.permissionCategoryId);
                if (keepGoing === true && (itm.permissionCategoryId == null) && itm.selected !== true) {
                    vm.isAllSelectedMisc = false;
                    keepGoing = false;
                    //break
                }
            });
        }

        vm.compareJson = function (objA, objB) {
            return (angular.toJson(objA) == angular.toJson(objB));
        };

        vm.saveRow = function (row, action) {
            vm.errors = {};
            vm.isLoading = true;
            var pkObj = {};
            var colName;
            pkObj[vm.pk] = row[vm.pk];

            _.forEach(vm.cols, function (col) { //loop through columns/fields

                colName = col.name;
                if(colName == 'permissionCategoryName') {
                    colName = 'permissionCategoryId';
                }

                if (col.required &&
                    !row[colName] && colName != vm.pk) {
                    vm.errors[colName] = 'This field is required';
                } else if (col.unique && colName != vm.pk) { //check they are meant to be unique and that they're not the primary key.
                    var found = _.find(vm.rows, function (item) { //find matching data in existing rows
                        return (item[colName] + '').toLowerCase().trim() == (row[colName] + '').toLowerCase().trim();
                    });
                    if (found && found[vm.pk] != row[vm.pk]) {  //check for found row unless it's the current row.
                        vm.errors[colName] = 'An entry with this value already exists';
                    }
                }

            });

            if(typeof vm.tables[vm.tableName].dropDown != 'undefined') {
                delete row[vm.tables[vm.tableName].dropDown.tableName];
            }

            if (_.isEmpty(vm.errors)) {
                if(action == 'edit') {
                    eval(vm.tableName).upsert(row).$promise.then(function () {
                        $scope.$storage[vm.tableName] = {};
                        vm.closeEdit();
                        vm.grabRows(vm.tableName);
                        $scope.$storage.selectedRow = 0;
                        vm.isLoading = false;
                    },
                    function () {
                        //error
                    });
                }else if(action == 'new') {
                    row[vm.pk] = 0;
                    eval(vm.tableName).create(row).$promise.then(function () {
                        $scope.$storage[vm.tableName] = {};
                        $scope.$storage.selectedRow = 0;
                        vm.closeEdit();
                        vm.grabRows(vm.tableName);
                    });
                }
            }

        };
		
		vm.deleteRows = function (selectedRows) {
            var confirm = $mdDialog.confirm()
                .title('Delete rows')
                .htmlContent('Are you sure you want to delete these rows?<br />')
                .ariaLabel('Confirm Delete')
                .ok('Delete')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function () {
				var finishedDeletes = {};
				var relationshipErrors = [];
                _.forEach(selectedRows, function (tbd, key) { //tbd : to be deleted (bool)
					var rowDefer = $q.defer();
					finishedDeletes[key] = rowDefer.promise;
					if(tbd == true){ 
						//if we actually want to delete this row.
						
						var relation;
						if(relation = vm.tables[vm.tableName].hasMany){
							var fk = relation.fk;
							var del = relation.fdel;
							var args = {"filter":{"where":{}}};
							args.filter.where[relation.fk] = parseInt(key);
							args.filter.where[relation.fdel] = 1;
							//if table has a hasMany relationship with another table.
							eval(relation.table).find(args).$promise.then(function(res){ // make call to child table to check for children
								if(res.length > 0){
									//has children - push row info to error array for prevent alert.
									relationshipErrors.push({rowId:key, count:res.length, childTable: relation.table});
									rowDefer.resolve();
									
								   
								}else{
									//no children associated - okay to delete
									vm.doDelete(key).then(function(){
										rowDefer.resolve();
									});
								}
							});
						}else{
							//no relationship - okay to delete.
							vm.doDelete(key).then(function(){
								rowDefer.resolve();
							});
						}
					}else{
						rowDefer.resolve();
					}
                });
				
				$q.all(finishedDeletes).then(function(){
					//when all promises are resolved;
					if (relationshipErrors.length > 0){
						//console.log(relationshipErrors);
						//check for relational errors + build error modal.
						var errorString = '';
						_.forEach(relationshipErrors, function(row){
							errorString += '<li>Row ' + row.rowId + ' has ' + row.count + ' ' + row.childTable + ' assigned to it.</li>'
						});
						var preventAlert = $mdDialog.alert().title('Unable to delete all rows').htmlContent( 'Unable to delete the following rows due to dependencies in other tables. <ul>' + errorString + '</ul>Please delete or reassign these dependants before attempting to delete this row.').ok('Accept');
						$mdDialog.show(preventAlert);
					}		
					vm.grabRows(vm.tableName);	//refresh
				});
            });
        };
		
		vm.doDelete = function(key){
			var doDelDefer = $q.defer();
			_.forEach(vm.rows, function (row) {
				if (row[vm.pk] == key) {
					row[vm.deletedCol] = 0;
					eval(vm.tableName).upsert(row, function () {
						doDelDefer.resolve();
					});
				}
			});
			return doDelDefer.promise;
		}

        vm.checkObj = function (obj) {
            var conclusion = true;
            _.forEach(obj, function (element) {

                if (element != '') {
                    conclusion = false;
                }
            });
            return conclusion;
        };


   

        vm.openEdit = function (row) {
            vm.errors = {};
            if (!row) {
                $scope.$storage.selectedRow = 0;
                $scope.$storage[vm.tableName] = {};
            } else {
                $scope.$storage[vm.tableName] = angular.copy(row);
            }
            $mdSidenav('right').toggle();
        }

        vm.closeEdit = function () {
            $mdSidenav('right').toggle();
        }

        vm.getCurrentPermissions = function () {
            PermissionGroupJoin.find({"filter": {"where": {"groupId": vm.thisPermissionGroup}}}, function (assignedGroupPermissions) {
                Permissions.__get__notDeleted(function (permissions) {
                    vm.permissions = permissions;
                    vm.clearCats();
                    _.forEach(assignedGroupPermissions, function (assignedGroupPermission) {
                        _.forEach(vm.permissions, function (permission) {
                            if (permission.permissionId == assignedGroupPermission.permissionId) {
                                permission.selected = true;
                                vm.optionToggled(permission.permissionCategoryId);
                                vm.optionToggledMisc();
                            }
                        });
                    });
                });
            });
        }

        vm.savePermissionsToGroup = function (form) {
			vm.isSaving = true;
            /*PermissionGroupJoin.markPermissionGroupJoinAsDeleted({"groupId": vm.thisPermissionGroup}).$promise.then(function (result) {
                _.forEach(vm.permissions, function (permission) {
                    if (permission.selected && permission.selected === true) {
                        PermissionGroupJoin.create({
                            "groupId": vm.thisPermissionGroup,
                            "permissionId": permission.permissionId
                        }).$promise.then(function (result) {
                            //console.log(result);
							vm.isSaving=false;
		  					vm.saved = "success";
		  					form.$setPristine();
                        }, function(err){
							vm.isSaving=false;
							console.error(err); 
							vm.saved = "error"; 
							form.$setPristine();
						});
                    }else{
						vm.isSaving=false;
						console.error("permnotselected");
						form.$setPristine();
					}
                });
            });*/
			PermissionGroupJoin.assignPermissions({"groupId":vm.thisPermissionGroup, "permissions":vm.permissions}).$promise.then(function(rtn){
				vm.isSaving=false;
				vm.saved = "success";
				form.$setPristine();
			},function(err){
				vm.isSaving=false;
				console.error(err); 
				vm.saved = "error"; 
				form.$setPristine();
			});
        };
		
		vm.initView = function(){
			PermissionCategories.__get__notDeleted(function (PermissionCategories) {
                vm.PermissionCategories = PermissionCategories;
                vm.clearCats();
            });
            Permissions.__get__notDeleted(function (permissions) {
                vm.permissions = permissions;
				if ($filter('filter')(vm.permissions, {'permissionCategoryId': null}).length > 0){
					vm.hasMisc = true;
				}else{
					vm.hasMisc = false;
				}
            });
            PermissionGroups.__get__notDeleted(function (permissionGroups) {
				//console.log($scope.$storage.loggedInUser);
				vm.permissionGroups = [];
				_.forEach(permissionGroups, function(group){
					if(group.groupId != 2 || $scope.$storage.loggedInUser.permissionGroup == 2){
					   vm.permissionGroups.push(group);
					   }
				});
            });
			vm.assignPermissionsToGroups = true;
			
			/*vm.cols = [];
            vm.pk = '';
            vm.tableName = '';*/
            
		};
		vm.cols = [];
		vm.pk = '';
		vm.tableName = '';
		vm.initView();


        /*for (var tableName in vm.tables) {
            vm.changeTable(tableName);
            break;
        }*/
		

        document.touchstart = {
            setup: function (_, ns, handle) {
                if (ns.includes("noPreventDefault")) {
                    this.addEventListener("touchstart", handle, {passive: false});
                } else {
                    this.addEventListener("touchstart", handle, {passive: true});
                }
            }
        };
		
		$scope.$watch('permissionmanagement.selectedTabIndex', function(current, old) {
            if(parseInt(current) == 0 || current == undefined){
				vm.initView();
			}else{
				var i = 1;
				_.forEach(vm.tables, function(tableinfo, tableNa){
					if(i == current){
					   vm.changeTable(tableNa);
					}
					i++;
				});
			}
        });


    }
}());
