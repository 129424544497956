<md-dialog aria-label="Add Content"  class="contentdialog">

    <md-toolbar>
        <div class="md-toolbar-tools">
            <h2>Insert Content</h2>
            <span flex></span>

            <md-button ng-click="contentdialog.dialogCancel(false)" class="md-icon-button">
                <ng-md-icon  icon="close" style="fill: white;"  aria-label="Close dialog"></ng-md-icon>
            </md-button>
        </div>
    </md-toolbar>
    <md-dialog-content class="dialog-content big" style="">
        <md-tabs md-dynamic-height md-border-bottom md-selected="contentdialog.tabIndex">
            <md-tab label="Existing Content" md-on-select="contentdialog.currentTab = 'existing'">
                <md-content class="md-padding"><br />
                    <form ng-submit="$event.preventDefault()">
                        <md-autocomplete
                            md-selected-item="contentdialog.selectedItem"
                            md-search-text="contentdialog.searchText"
                            md-selected-item-change="contentdialog.getContent(contentdialog.selectedItem)"
                            md-items="item in contentdialog.querySearch(contentdialog.searchText)"
                            md-item-text="item.bookCode"
                            md-min-length="1"
                            placeholder="Please select a book"
                            md-menu-class="autocomplete-custom-template"
                            ng-model-options="{ debounce: 200 }"
                            md-autoselect="true">
                        <md-item-template><span>{{item.bookCode}}</span></md-item-template>
                            <md-not-found>
                                No matches found.
                            </md-not-found>
                            </md-autocomplete>
                    </form>
                    <div ng-show="contentdialog.contentlist">
                        <h3 ng-if="contentdialog.contentlist.length == 0">No matching content found, try another document.</h3>
                        <h3 ng-if="contentdialog.contentlist.length > 0">Select content to copy:</h3>
                        <div ng-repeat="content in contentdialog.contentlist | orderBy: 'order'">
                            <md-checkbox ng-model="content.selected"  ng-disabled="content.disabled" >


                                <get-contents item="content"></get-contents>


                            </md-checkbox>
                        </div>
                    </div>

                    <!--<pre>{{contentdialog.testbook | json}}</pre>-->
                </md-content>
            </md-tab>

            <md-tab label="New Content" ng-if="contentdialog.thisContentId <= 3 " md-on-select="contentdialog.currentTab = 'content'">
                <md-content class="md-padding">
                    <br />
                    <form ng-submit="$event.preventDefault()" name="contentdialog.contentForm" layout="column">



                        <!--<md-input-container flex>-->
                            <!--<label>Content description</label>-->
                            <!--<input ng-model="contentdialog.contentName"    ng-minlength="3" required>-->

                        <!--</md-input-container>-->


                        <md-input-container flex>
                            <label>Content Owner</label>
                            <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"   required>
                                <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: 'teamName'" >{{team.teamName}}</md-option>
                            </md-select>
                        </md-input-container>



                        <md-input-container flex>
                            <label>Content Type</label>
                            <md-select ng-model="contentdialog.contentType" placeholder="Content Type"    ng-model-options="{trackBy: '$value.contentTypeId'}" ng-change="contentdialog.changeEditor(contentdialog.contentType.contentTypeId)"  required>
                                <md-option ng-if="(contentType.contentTypeId>contentdialog.thisContentId && contentType.contentTypeId<=4)" ng-value="contentType" ng-repeat="contentType in contentdialog.contentTypes | orderBy: 'contentTypeName'" >{{contentType.contentTypeName}}</md-option>
                            </md-select>
                        </md-input-container>


                        <md-input-container flex ng-if="contentdialog.contentType.contentTypeId == 4 && contentdialog.tabIndex == 1" class="cke-container">
                            <label>Content</label>
                            <textarea  ng-model="contentdialog.contentData"  contenteditable="true" ckeditor="contentdialog.options"></textarea>
                        </md-input-container>

                        <md-input-container flex  ng-if="contentdialog.contentType.contentTypeId == 3 && contentdialog.tabIndex == 1" class="cke-container">
                            <label>Content</label>
                            <textarea  ng-model="contentdialog.contentData"   contenteditable="true" ckeditor="contentdialog.options2"></textarea>
                        </md-input-container>

                        <md-input-container flex  ng-if="contentdialog.contentType.contentTypeId == 2 && contentdialog.tabIndex == 1" class="cke-container">
                            <label>Content</label>
                            <textarea  ng-model="contentdialog.contentData"   contenteditable="true" ckeditor="contentdialog.options2"></textarea>
                        </md-input-container>

                        <div id="cktools" flex></div>
                        <div id="ckfoot"></div>



                    </form>
                </md-content>
            </md-tab>

            <md-tab label="New Benefit" ng-if="contentdialog.thisContentId == 2 && contentdialog.thisChapterTypeId != 7 && contentdialog.thisChapterTypeId != 8 && contentdialog.thisChapterTypeId != 9" md-on-select="contentdialog.currentTab = 'benefit'">
            <md-content class="md-padding">
                <br />
                <form ng-submit="$event.preventDefault()" name="contentdialog.benefitForm">

                    <div layout="row"  layout-wrap>
                        <md-input-container flex>
                            <label>Benefit Owner</label>
                            <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"  required >
                                <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: 'teamName'" >{{team.teamName}}</md-option>
                            </md-select>
                        </md-input-container>
                    </div>
                    <div  class="Benefit"  layout="row"  flex="100"  style="padding-bottom: 20px;">

                        <table width="100%">
                            <thead><tr><th>Benefits</th>

                                <th ng-bind-html="contentdialog.book.levels.level1.name"></th>
                                <th ng-bind-html="contentdialog.book.levels.level2.name"></th>
                                <th ng-bind-html="contentdialog.book.levels.level3.name"></th>
                                <th ng-bind-html="contentdialog.book.levels.level4.name"></th>

                                <th>Explanation of Benefits</th></tr></thead>
                            <tbody>
                            <tr>

                                <td width=""><md-input-container><textarea  ng-model="contentdialog.benefit.name" aria-label="Benefit name" required></textarea></md-input-container></td>
                                <td width="12%">
                                    <md-input-container><textarea ng-disabled="contentdialog.disabledProducts.level1"  ng-model="contentdialog.benefit.level1" aria-label="Benefit level1" ></textarea></md-input-container>

                                    <md-checkbox ng-change="contentdialog.updateOption(contentdialog.benefit,'level1')" class="linked" ng-model="contentdialog.benefit.option.level1" aria-label="level1">Options</md-checkbox>

                                    <md-radio-group  ng-change="contentdialog.updateOptions(contentdialog.benefit,'level1')" ng-if="contentdialog.benefit.option.level1"  ng-model="contentdialog.benefit.options.level1">
                                        <md-radio-button value="Linked">Linked</md-radio-button>
                                        <md-radio-button value="Not covered">Not covered</md-radio-button>
                                        <md-radio-button value="Paid in full">Paid in full</md-radio-button>
                                        <md-radio-button value="Optional">Optional</md-radio-button>
                                        <md-radio-button value="Show in summary">Show in summary</md-radio-button>
                                    </md-radio-group>

                                </td>
                                <td width="12%">
                                    <md-input-container><textarea  ng-disabled="contentdialog.disabledProducts.level2"  ng-model="contentdialog.benefit.level2" aria-label="Benefit level2" ></textarea></md-input-container>

                                    <md-checkbox ng-change="contentdialog.updateOption(contentdialog.benefit,'level2')" class="linked" ng-model="contentdialog.benefit.option.level2" aria-label="level2">Options</md-checkbox>

                                    <md-radio-group  ng-change="contentdialog.updateOptions(contentdialog.benefit,'level2')"  ng-if="contentdialog.benefit.option.level2"  ng-model="contentdialog.benefit.options.level2">
                                        <md-radio-button value="Linked">Linked</md-radio-button>
                                        <md-radio-button value="Not covered">Not covered</md-radio-button>
                                        <md-radio-button value="Paid in full">Paid in full</md-radio-button>
                                        <md-radio-button value="Optional">Optional</md-radio-button>
                                        <md-radio-button value="Show in summary">Show in summary</md-radio-button>
                                    </md-radio-group>

                                </td>
                                <td width="12%">
                                    <md-input-container><textarea ng-disabled="contentdialog.disabledProducts.level3" ng-model="contentdialog.benefit.level3" aria-label="Benefit level3" ></textarea></md-input-container>

                                    <md-checkbox ng-change="contentdialog.updateOption(contentdialog.benefit,'level3')" class="linked" ng-model="contentdialog.benefit.option.level3" aria-label="level3">Options</md-checkbox>

                                    <md-radio-group  ng-change="contentdialog.updateOptions(contentdialog.benefit,'level3')"  ng-if="contentdialog.benefit.option.level3"  ng-model="contentdialog.benefit.options.level3">
                                        <md-radio-button value="Linked">Linked</md-radio-button>
                                        <md-radio-button value="Not covered">Not covered</md-radio-button>
                                        <md-radio-button value="Paid in full">Paid in full</md-radio-button>
                                        <md-radio-button value="Optional">Optional</md-radio-button>
                                        <md-radio-button value="Show in summary">Show in summary</md-radio-button>
                                    </md-radio-group>

                                </td>
                                <td width="12%">
                                    <md-input-container><textarea ng-disabled="contentdialog.disabledProducts.level4" ng-model="contentdialog.benefit.level4" aria-label="Benefit level4" ></textarea></md-input-container>

                                    <md-checkbox  ng-change="contentdialog.updateOption(contentdialog.benefit,'level4')" class="linked" ng-model="contentdialog.benefit.option.level4" aria-label="level4">Options</md-checkbox>

                                    <md-radio-group  ng-change="contentdialog.updateOptions(contentdialog.benefit,'level4')"  ng-if="contentdialog.benefit.option.level4"  ng-model="contentdialog.benefit.options.level4">
                                        <md-radio-button value="Linked">Linked</md-radio-button>
                                        <md-radio-button value="Not covered">Not covered</md-radio-button>
                                        <md-radio-button value="Paid in full">Paid in full</md-radio-button>
                                        <md-radio-button value="Optional">Optional</md-radio-button>
                                        <md-radio-button value="Show in summary">Show in summary</md-radio-button>
                                    </md-radio-group>

                                </td>
                                <td width="38%" style="position: relative;">
                                    <md-input-container class="cke-container">
                                        <textarea    ng-model="contentdialog.benefit.explanation"  contenteditable="true" ckeditor="contentdialog.options3"  placeholder="" aria-label="Benefit explanation"></textarea>
                                    </md-input-container>
                                    <div id="ckfoot2"></div>
                                    <div  style="position: absolute; bottom: -55px;" id="cktools2"></div>
                                    <md-checkbox class="linked right"  ng-change="contentdialog.updateLinked(contentdialog.benefit,'explanation')" ng-model="contentdialog.benefit.options.explanation"  ng-true-value="'Linked'" ng-false-value="false" aria-label="explanation">Linked</md-checkbox>
                                </td>
                            </tr>

                            </tbody>
                        </table>

                    </div>


                    <div layout="row"  layout-wrap ng-if="contentdialog.book.levels.payment">
                        <md-input-container flex>
                            <label ng-if="!contentdialog.benefit.options.payment">Payment for treatment</label>
                            <md-select ng-model="contentdialog.benefit.payment" placeholder="Payment for treatment"  ng-disabled="contentdialog.benefit.options.payment" >
                                <md-option value="Direct Billing" >Direct Billing</md-option>
                                <md-option value="Non applicable" >Non applicable</md-option>
                                <md-option value="Reimbursement" >Reimbursement</md-option>
                            </md-select>
                        </md-input-container>

                    </div>
                    <div layout="row"  layout-wrap ng-if="contentdialog.book.levels.Payment"  flex="100">
                        <md-checkbox class="linked"  ng-change="contentdialog.updateLinked(contentdialog.benefit,'payment')" ng-model="contentdialog.benefit.options.payment"  ng-true-value="'Linked'" ng-false-value="false" aria-label="payment">Linked</md-checkbox>

                    </div>




                </form>

            </md-content>
        </md-tab>





            <md-tab label="New Glossary Term" ng-if="contentdialog.thisContentId == 1 && contentdialog.thisChapterTypeId != 7 && contentdialog.thisChapterTypeId != 8 && contentdialog.thisChapterTypeId != 9" md-on-select="contentdialog.currentTab = 'glossary'">
                <md-content class="md-padding">
                    <br />
                    <form ng-submit="$event.preventDefault()" name="contentdialog.glossaryForm">

                        <div layout="row"  layout-wrap>


                            <md-input-container flex>
                                <label>Glossary Owner</label>
                                <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner" required>
                                    <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: 'teamName'" >{{team.teamName}}</md-option>
                                </md-select>
                            </md-input-container>

                        </div>


                        <div  class="Benefit Glossary"  layout="row"  flex="100"  style="padding-bottom: 20px;">
                            <table width="100%">
                                <thead><tr><th>Defined term</th><th>Description</th><th>Search words</th></tr></thead>
                                <tbody>
                                <tr>

                                    <td width="25%">
                                        <md-input-container><textarea  ng-model="contentdialog.glossary.term" aria-label="Glossary term" required></textarea></md-input-container>
                                    </td>

                                    <td width="50%" style="position: relative;">
                                        <md-input-container class="cke-container">
                                            <textarea     ng-model="contentdialog.glossary.description"  contenteditable="true" ckeditor="contentdialog.options4"  placeholder="" aria-label="Glossary description"></textarea>
                                        </md-input-container>
                                        <div id="ckfoot3"></div>
                                        <div  style="position: absolute; bottom: -55px;" id="cktools3"></div>
                                    </td>

                                    <td width="25%">
                                        <md-input-container><textarea  ng-model="contentdialog.glossary.words" aria-label="Glossary words" ></textarea></md-input-container>
                                    </td>
                                </tr>

                                </tbody>
                            </table>

                        </div>






                    </form>
                </md-content>
            </md-tab>


            <md-tab label="New Exclusion" ng-if="contentdialog.thisContentId == 1 && contentdialog.thisChapterTypeId != 7 && contentdialog.thisChapterTypeId != 8 && contentdialog.thisChapterTypeId != 9" md-on-select="contentdialog.currentTab = 'exclusion'">
                <md-content class="md-padding">
                    <br />
                    <form ng-submit="$event.preventDefault()" name="contentdialog.exclusionForm">

                        <div layout="row"  layout-wrap>
                            <md-input-container flex>
                                <label>Exclusion Owner</label>
                                <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"    required>
                                    <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: 'teamName'" >{{team.teamName}}</md-option>
                                </md-select>
                            </md-input-container>

                        </div>
                        <div  class="Benefit"  layout="row"  flex="100"  style="padding-bottom: 20px;">

                            <table width="100%">
                                <thead><tr><th>Exclusion</th><th>Notes</th><th>Rules</th></tr></thead>
                                <tbody>
                                <tr>

                                    <td width="30"><md-input-container><textarea  ng-model="contentdialog.exclusion.name" aria-label="Exclusion name" required></textarea></md-input-container></td>
                                    <td width="20"><md-input-container><textarea  ng-model="contentdialog.exclusion.notes" aria-label="Exclusion notes" ></textarea></md-input-container></td>

                                    <td width="50%" style="position: relative;">
                                        <md-input-container class="cke-container">
                                            <textarea     ng-model="contentdialog.exclusion.rules"  contenteditable="true" ckeditor="contentdialog.options5"  placeholder="" aria-label="Exclusion rules"></textarea>
                                        </md-input-container>
                                        <div id="ckfoot5"></div>
                                        <div  style="position: absolute; bottom: -55px;" id="cktools5"></div>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                            </div>
                        <div style="position: relative; top:-45px;" flex="50">
                            <md-checkbox ng-model="contentdialog.exclusion.level1" >{{bottomsheet.book.levels.level1.name}}</md-checkbox>
                            <md-checkbox ng-model="contentdialog.exclusion.level2" >{{bottomsheet.book.levels.level1.name}}</md-checkbox>
                            <md-checkbox ng-model="contentdialog.exclusion.level3" >{{bottomsheet.book.levels.level1.name}}</md-checkbox>
                            <md-checkbox ng-model="contentdialog.exclusion.level4" >{{bottomsheet.book.levels.level1.name}}</md-checkbox>

                        </div>


                    </form>
                </md-content>
            </md-tab>




            <md-tab label="New Medical Term" ng-if="contentdialog.thisContentId == 1 && contentdialog.thisChapterTypeId != 7 && contentdialog.thisChapterTypeId != 8 && contentdialog.thisChapterTypeId != 9"  md-on-select="contentdialog.currentTab = 'medical'">
                <md-content class="md-padding">
                    <br />
                    <form ng-submit="$event.preventDefault()" name="contentdialog.medicalForm">

                        <div layout="row"  layout-wrap>
                            <md-input-container flex>
                                <label>Exclusion Owner</label>
                                <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"  required>
                                    <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: 'teamName'" >{{team.teamName}}</md-option>
                                </md-select>
                            </md-input-container>

                        </div>
                        <div  class="Benefit Glossary"  layout="row"  flex="100"  style="padding-bottom: 20px;">

                            <table width="100%">
                                <thead><tr><th>Term</th><th>Description</th></tr></thead>
                                <tbody>
                                <tr>

                                    <td width="40"><md-input-container><textarea  ng-model="contentdialog.medical.term" aria-label="Medical term" required></textarea></md-input-container></td>

                                    <td width="60%" style="position: relative;">
                                        <md-input-container class="cke-container">
                                            <textarea     ng-model="contentdialog.medical.description"  contenteditable="true" ckeditor="contentdialog.options6"  placeholder="" aria-label="Medical description"></textarea>
                                        </md-input-container>
                                        <div id="ckfoot6"></div></textarea>
                                        <div  style="position: absolute; bottom: -55px;" id="cktools6"></div>
                                    </td>
                                </tr>

                                </tbody>
                            </table>

                        </div>


                    </form>
                </md-content>
            </md-tab>



            <md-tab label="New Table" ng-if="contentdialog.thisContentId == 2 && contentdialog.thisChapterTypeId != 7 && contentdialog.thisChapterTypeId != 8 && contentdialog.thisChapterTypeId != 9"  md-on-select="contentdialog.currentTab = 'table'">
                <md-content class="md-padding">
                    <br />
                    <form ng-submit="$event.preventDefault()" name="contentdialog.tableHeaderForm">

                        <div layout="row"  layout-wrap>
                            <md-input-container flex="50">
                                <label>Table Owner</label>
                                <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"  required>
                                    <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: 'teamName'" >{{team.teamName}}</md-option>
                                </md-select>
                            </md-input-container>

                        </div>
                        <div  class="Benefit"  layout="row"  flex="100"  style="padding-bottom: 20px;">

                            <div flex="100">

                                <table width="100%" class="workflow-table">
                                    <tr>
                                        <th colspan="100" width="60%" align="left">NAME</th>
                                    </tr>
                                    <tr >
                                        <td ng-repeat="item in contentdialog.tableHeader">
                                            <div layout="row" flex="100">
                                                <md-input-container flex="100" >
                                                    <label>Column Name</label>
                                                    <input ng-model="item.name">
                                                </md-input-container>
                                            </div>
                                            <div layout="row" flex="100">
                                                <md-input-container flex="70" >
                                                    <label>Width %</label>
                                                    <input ng-model="item.width">
                                                </md-input-container>
                                                <md-input-container flex="30">
                                                    <md-button  class="md-icon-button md-red" ng-click="contentdialog.removeTableHeader($index)">
                                                        <ng-md-icon icon="clear"  size="22"  aria-label="remove"></ng-md-icon>
                                                    </md-button>
                                                </md-input-container>
                                            </div>
                                        </td>
                                    </tr>
                                </table>


                                <!--<pre>{{contentdialog.tableHeader | json}}</pre>-->

                                <h5><md-button  ng-if="!library.disableBookEdit" type="button" class="md-icon-button md-green add-approver" ng-click="contentdialog.addTableHeader()">
                                    <ng-md-icon icon="add" size="22"  aria-label="add"></ng-md-icon>
                                </md-button>Add Column</h5>
                            </div>
                        </div>


                    </form>

                </md-content>
            </md-tab>



            <md-tab label="New Table Row" ng-if="contentdialog.thisContentId == 11 && contentdialog.thisChapterTypeId != 7 && contentdialog.thisChapterTypeId != 8 && contentdialog.thisChapterTypeId != 9" md-on-select="contentdialog.currentTab = 'row'">
                <md-content class="md-padding">
                    <br />
                    <form ng-submit="$event.preventDefault()" name="contentdialog.tableRowForm">

                        <div layout="row"  layout-wrap>
                            <md-input-container flex="50">
                                <label>Row Owner</label>
                                <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"  required>
                                    <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: 'teamName'" >{{team.teamName}}</md-option>
                                </md-select>
                            </md-input-container>

                        </div>
                        <div  class="Benefit Table"  layout="row"  flex="100"  style="padding-bottom: 20px;">

                            <div flex="100">



                                <table width="100%" >
                                    <thead>
                                    <tr>
                                        <th ng-repeat="item in contentdialog.child.tableHeader | orderBy:'order'"  width="{{contentdialog.getTableWidths(item.width, contentdialog.child.tableHeader)}}%">
                                            <div ng-bind-html="item.name"></div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td ng-repeat="item in contentdialog.child.tableHeader | orderBy:'order'" >
                                            <md-input-container class="cke-container">
                                                <textarea ng-model="contentdialog.tableRow[item.index].data"   contenteditable="true" ckeditor="contentdialog.options6"></textarea>
                                            </md-input-container>
                                        </td>
                                    </tr>
                                    </tbody>

                                </table>

                                <div id="ckfoot6"></div>
                                <div  style="" id="cktools6"></div>
                                <!--<pre>{{contentdialog.tableRow | json}}</pre>-->




                            </div>
                        </div>


                    </form>

                </md-content>
            </md-tab>






            <md-tab label="New Benefit Section" ng-if="contentdialog.thisContentId == 1 && contentdialog.thisChapterTypeId == 7 && contentdialog.thisChapterTypeId != 8 && contentdialog.thisChapterTypeId != 9" md-on-select="contentdialog.currentTab = 'BGHPBenefitSection'">
                <md-content class="md-padding">
                    <br />
                    <form ng-submit="$event.preventDefault()" name="contentdialog.BGHPBenefitSectionForm" layout="column">

                        <md-input-container flex>
                            <label>Content Owner</label>
                            <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"   required>
                                <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: 'teamName'" >{{team.teamName}}</md-option>
                            </md-select>
                        </md-input-container>

                        <md-input-container ng-init="contentdialog.changeEditor(2)" flex class="cke-container">
                            <label>Content</label>
                            <textarea  ng-model="contentdialog.contentData"   contenteditable="true" ckeditor="contentdialog.options7"></textarea>
                        </md-input-container>

                        <div id="cktools7" flex></div>
                        <div id="ckfoot7"></div>

                    </form>
                </md-content>
            </md-tab>



            <md-tab label="New Benefit Section Intro" ng-if="contentdialog.thisContentId == 14" md-on-select="contentdialog.currentTab = 'BGHPBenefitIntro'; contentdialog.changeEditor(4)">
                <md-content class="md-padding">
                    <br />
                    <form ng-submit="$event.preventDefault()" name="contentdialog.BGHPBenefitIntroForm" layout="column">

                        <md-input-container flex>
                            <label>Content Owner</label>
                            <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"   required>
                                <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: 'teamName'" >{{team.teamName}}</md-option>
                            </md-select>
                        </md-input-container>

                        <md-input-container  flex class="cke-container">
                            <label>Content</label>
                            <textarea  ng-model="contentdialog.contentData"   contenteditable="true" ckeditor="contentdialog.options6"></textarea>
                        </md-input-container>

                        <div id="cktools6" flex></div>
                        <div id="ckfoot6"></div>

                    </form>
                </md-content>
            </md-tab>



            <md-tab label="New Benefit Sub Section" ng-if="contentdialog.thisContentId == 14" md-on-select="contentdialog.currentTab = 'BGHPBenefitSubSection'; contentdialog.changeEditor(3)">
                <md-content class="md-padding">
                    <br />
                    <form ng-submit="$event.preventDefault()" name="contentdialog.BGHPBenefitSubSectionForm" layout="column">

                        <md-input-container flex>
                            <label>Content Owner</label>
                            <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"   required>
                                <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: 'teamName'" >{{team.teamName}}</md-option>
                            </md-select>
                        </md-input-container>

                        <md-input-container  flex class="cke-container">
                            <label>Content</label>
                            <textarea  ng-model="contentdialog.contentData"   contenteditable="true" ckeditor="contentdialog.options7"></textarea>
                        </md-input-container>

                        <div id="cktools7" flex></div>
                        <div id="ckfoot7"></div>

                    </form>
                </md-content>
            </md-tab>



            <md-tab label="New Benefit" ng-if="contentdialog.thisContentId == 14 || contentdialog.thisContentId == 15" md-on-select="contentdialog.currentTab = 'BGHPBenefit'">
                <md-content class="md-padding">
                    <br />
                    <form ng-submit="$event.preventDefault()" name="contentdialog.BGHPBenefitForm" layout="column">

                        <md-input-container flex>
                            <label>Content Owner</label>
                            <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"   required>
                                <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: 'teamName'" >{{team.teamName}}</md-option>
                            </md-select>
                        </md-input-container>

                        <div  class="Benefit"  layout="row"  flex="100"  style="padding-bottom: 20px;">

                            <table width="100%">
                                <thead>
                                <tr>
                                    <th>Benefits</th>
                                    <th>Explanation</th>
                                    <th>Limit</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td width="30%"><md-input-container><textarea  ng-model="contentdialog.benefit.name" aria-label="Benefit name" required></textarea></md-input-container></td>

                                    <td width="50%" style="position: relative;">
                                        <md-input-container class="cke-container">
                                            <textarea    ng-model="contentdialog.benefit.explanation"  contenteditable="true" ckeditor="contentdialog.options3"  placeholder="" aria-label="Benefit explanation"></textarea>
                                        </md-input-container>
                                        <div id="ckfoot2"></div>
                                        <div  style="position: absolute; bottom: -55px;" id="cktools2"></div>
                                     </td>

                                    <td width="20%">
                                        <md-input-container><textarea ng-disabled="contentdialog.benefit.options.limit"  ng-model="contentdialog.benefit.limit" aria-label="Benefit limit" ></textarea></md-input-container>

                                        <md-checkbox ng-change="contentdialog.updateOption(contentdialog.benefit,'limit')" class="linked" ng-model="contentdialog.benefit.option.limit" aria-label="limit">Options</md-checkbox>

                                        <md-radio-group  ng-change="contentdialog.updateOptions(contentdialog.benefit,'limit')" ng-if="contentdialog.benefit.option.limit"  ng-model="contentdialog.benefit.options.limit">
                                            <md-radio-button value="Linked">Linked</md-radio-button>
                                            <md-radio-button value="Not covered">Not covered</md-radio-button>
                                            <md-radio-button value="Paid in full">Paid in full</md-radio-button>
                                        </md-radio-group>

                                    </td>
                                </tr>

                                </tbody>
                            </table>

                        </div>


                    </form>
                </md-content>
            </md-tab>



            <md-tab label="New Term Section" ng-if="contentdialog.thisContentId == 1 && contentdialog.thisChapterTypeId == 8" md-on-select="contentdialog.currentTab = 'BGHPTermSection'">
                <md-content class="md-padding">
                    <br />
                    <form ng-submit="$event.preventDefault()" name="contentdialog.BGHPTermSectionForm" layout="column">

                        <md-input-container flex>
                            <label>Content Owner</label>
                            <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"   required>
                                <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: 'teamName'" >{{team.teamName}}</md-option>
                            </md-select>
                        </md-input-container>

                        <md-input-container ng-init="contentdialog.changeEditor(2)" flex class="cke-container">
                            <label>Content</label>
                            <textarea  ng-model="contentdialog.contentData"   contenteditable="true" ckeditor="contentdialog.options7"></textarea>
                        </md-input-container>

                        <div id="cktools7" flex></div>
                        <div id="ckfoot7"></div>

                    </form>
                </md-content>
            </md-tab>



            <md-tab label="New Term " ng-if="contentdialog.thisContentId == 18" md-on-select="contentdialog.currentTab = 'BGHPTerm'">
                <md-content class="md-padding">
                    <br />
                    <form ng-submit="$event.preventDefault()" name="contentdialog.BGHPTermForm" layout="column">

                        <md-input-container flex>
                            <label>Content Owner</label>
                            <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"   required>
                                <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: 'teamName'" >{{team.teamName}}</md-option>
                            </md-select>
                        </md-input-container>

                        <md-input-container  flex class="cke-container">
                            <label>Content</label>
                            <textarea  ng-model="contentdialog.contentData"   contenteditable="true" ckeditor="contentdialog.options6"></textarea>
                        </md-input-container>

                        <div id="cktools6" flex></div>
                        <div id="ckfoot6"></div>

                    </form>
                </md-content>
            </md-tab>



            <md-tab label="New Exclusion " ng-if="contentdialog.thisContentId == 21 " md-on-select="contentdialog.currentTab = 'BGHPExclusion'">
                <md-content class="md-padding">
                    <br />
                    <form ng-submit="$event.preventDefault()" name="contentdialog.BGHPExclusionForm" layout="column">

                        <md-input-container flex>
                            <label>Content Owner</label>
                            <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"   required>
                                <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: 'teamName'" >{{team.teamName}}</md-option>
                            </md-select>
                        </md-input-container>
                        <div  class="Benefit"  layout="row"  flex="100"  style="padding-bottom: 20px;">

                            <table width="100%">
                                <thead><tr><th>Exclusion</th><th>Rules</th></tr></thead>
                                <tbody>
                                <tr>

                                    <td width="30"><md-input-container><textarea  ng-model="contentdialog.exclusion.name" aria-label="Exclusion name" required></textarea></md-input-container></td>

                                    <td width="50%" style="position: relative;">
                                        <md-input-container class="cke-container">
                                            <textarea ng-model="contentdialog.exclusion.rules"  contenteditable="true" ckeditor="contentdialog.options5"  placeholder="" aria-label="Exclusion rules"></textarea>
                                        </md-input-container>
                                        <div id="ckfoot5"></div>
                                        <div  style="position: absolute; bottom: -55px;" id="cktools5"></div>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>

                    </form>
                </md-content>
            </md-tab>


            <md-tab label="New Exclusion Section " ng-if="contentdialog.thisContentId == 1  && contentdialog.thisChapterTypeId == 9" md-on-select="contentdialog.currentTab = 'BGHPExclusionSection'">
                <md-content class="md-padding">
                    <br />
                    <form ng-submit="$event.preventDefault()" name="contentdialog.BGHPExclusionSectionForm" layout="column">

                        <md-input-container flex>
                            <label>Content Owner</label>
                            <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"   required>
                                <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: 'teamName'" >{{team.teamName}}</md-option>
                            </md-select>
                        </md-input-container>

                        <md-input-container  flex class="cke-container">
                            <label>Content</label>
                            <textarea  ng-model="contentdialog.contentData"   contenteditable="true" ckeditor="contentdialog.options7"></textarea>
                        </md-input-container>

                        <div id="cktools7" flex></div>
                        <div id="ckfoot7"></div>

                    </form>
                </md-content>
            </md-tab>






            <md-tab label=""></md-tab>



</md-tabs>







        <md-progress-linear ng-if="contentdialog.isLoading" md-mode="indeterminate" style="position: absolute;"></md-progress-linear>

        <!--<pre>{{contentdialog.thisChapterTypeId | json}}</pre>-->

    </md-dialog-content>
    <md-dialog-actions layout="row">
        <span flex></span>
        <md-button class="md-grey"  ng-click="contentdialog.dialogCancel(false)">Cancel<ng-md-icon icon="close" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>

        <md-button ng-if="contentdialog.currentTab === 'existing'" ng-disabled="(contentdialog.isLoading || !contentdialog.contentlist || contentdialog.contentlist.length == 0)" class="md-green"  ng-click="contentdialog.addContent(contentdialog.contentlist)">Add Existing Content<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>
        <md-button ng-if="contentdialog.currentTab === 'content'" ng-disabled="(contentdialog.isLoading  || !contentdialog.contentForm.$valid)" class="md-green"  ng-click="contentdialog.addNewContent(contentdialog)">Add New Content<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>
        <md-button ng-if="contentdialog.currentTab === 'benefit'" ng-disabled="(contentdialog.isLoading  || !contentdialog.benefitForm.$valid)" class="md-green"  ng-click="contentdialog.addNewBenefit(contentdialog)">Add New Benefit<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>
        <md-button ng-if="contentdialog.currentTab === 'glossary'" ng-disabled="(contentdialog.isLoading  || !contentdialog.glossaryForm.$valid)" class="md-green"  ng-click="contentdialog.addNewGlossary(contentdialog)">Add New Glossary<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>
        <md-button ng-if="contentdialog.currentTab === 'exclusion'" ng-disabled="(contentdialog.isLoading  || !contentdialog.exclusionForm.$valid)" class="md-green"  ng-click="contentdialog.addNewExclusion(contentdialog)">Add New Exclusion<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>
        <md-button ng-if="contentdialog.currentTab === 'medical'" ng-disabled="(contentdialog.isLoading  || !contentdialog.medicalForm.$valid)" class="md-green"  ng-click="contentdialog.addNewMedical(contentdialog)">Add New Medical Term<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>

        <md-button  ng-if="contentdialog.currentTab === 'table'" ng-disabled="(contentdialog.isLoading  || !contentdialog.tableHeaderForm.$valid)" class="md-green"  ng-click="contentdialog.saveTableHeader(contentdialog)">Add New Table<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>
        <md-button  ng-if="contentdialog.currentTab === 'row'"  ng-disabled="(contentdialog.isLoading  || !contentdialog.tableRowForm.$valid)" class="md-green"  ng-click="contentdialog.saveTableRow(contentdialog)">Add New Row<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>

        <md-button  ng-if="contentdialog.currentTab === 'BGHPBenefitSection'"  ng-disabled="(contentdialog.isLoading  || !contentdialog.BGHPBenefitSectionForm.$valid)" class="md-green"  ng-click="contentdialog.saveBGHP(contentdialog, 'section')">Add New Section<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>
        <md-button  ng-if="contentdialog.currentTab === 'BGHPBenefitIntro'"  ng-disabled="(contentdialog.isLoading  || !contentdialog.BGHPBenefitIntroForm.$valid)" class="md-green"  ng-click="contentdialog.saveBGHP(contentdialog, 'intro')">Add New Section Intro<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>
        <md-button  ng-if="contentdialog.currentTab === 'BGHPBenefitSubSection'"  ng-disabled="(contentdialog.isLoading  || !contentdialog.BGHPBenefitSubSectionForm.$valid)" class="md-green"  ng-click="contentdialog.saveBGHP(contentdialog, 'subSection')">Add New Sub Section<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>
        <md-button  ng-if="contentdialog.currentTab === 'BGHPBenefit'"  ng-disabled="(contentdialog.isLoading  || !contentdialog.BGHPBenefitForm.$valid)" class="md-green"  ng-click="contentdialog.saveBGHP(contentdialog, 'benefit')">Add New Benefit<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>

        <md-button  ng-if="contentdialog.currentTab === 'BGHPTermSection'"  ng-disabled="(contentdialog.isLoading  || !contentdialog.BGHPTermSectionForm.$valid)" class="md-green"  ng-click="contentdialog.saveBGHP(contentdialog, 'termSection')">Add New Term Section<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>

        <md-button  ng-if="contentdialog.currentTab === 'BGHPTerm'"  ng-disabled="(contentdialog.isLoading  || !contentdialog.BGHPTermForm.$valid)" class="md-green"  ng-click="contentdialog.saveBGHP(contentdialog, 'term')">Add New Term<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>

        <md-button  ng-if="contentdialog.currentTab === 'BGHPExclusion'"  ng-disabled="(contentdialog.isLoading  || !contentdialog.BGHPExclusionForm.$valid)" class="md-green"  ng-click="contentdialog.saveBGHP(contentdialog, 'exclusion')">Add New Exclusion<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>

        <md-button  ng-if="contentdialog.currentTab === 'BGHPExclusionSection'"  ng-disabled="(contentdialog.isLoading  || !contentdialog.BGHPExclusionSectionForm.$valid)" class="md-green"  ng-click="contentdialog.saveBGHP(contentdialog, 'exclusionSection')">Add New Exclusion<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>




    </md-dialog-actions>
</md-dialog>