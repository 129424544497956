<div layout="row" style="font-size: 14px; color:#666; " ng-class="{'fixed':item.role != 3 && item.role != 9 && item.role != 8 && item.role != 10, 'off':item.value === false}" ng-repeat="item in inputWfStep.array" class="wf-step">
    <div flex="15" class="statbar-mini">
                        <span class="outer">
                            <span class="inner" ng-class="{grey:!isCurrent, green: isCurrent}"></span>
                            <span class="label ">{{inputWfStep.getRoleById(item.role).name}} {{inputWfStep.enableWorkflowArtwork}}</span>
                        </span>
    </div>

    <div ng-if="inputWfStep.disabled || (item.content == 'final' && $index == 1 && item.team == 20)"  style="padding: 6px 8px;" flex="50">{{inputWfStep.getTeamById(item.team).teamName}}</div>
    <div ng-if="!inputWfStep.disabled && !(item.content == 'final' && $index == 1 && item.team == 20)" style="padding: 0px 6px;" flex="50">

        <md-select ng-model="item.team" placeholder="Select team" style="font-size: 14px; margin: -2px 0 0 0;" ng-disabled="item.value === false">
            <md-option ng-value="team.teamId" ng-repeat="team in inputWfStep.teams  | orderBy: ['teamName'] " >{{team.teamName}}  <span ng-if="team.disabled">- (disabled)</span></md-option>
        </md-select>
        <p ng-if="!item.team || item.team == ''" class="error animate-error-if">You must select a team</p>

    </div>

    <div ng-if="inputWfStep.disabled"  style="padding: 6px 8px;" flex="50">{{inputWfStep.getUserById(item.user).firstName}} {{inputWfStep.getUserById(item.user).lastName}}</div>
    <div ng-if="!inputWfStep.disabled" style="padding: 0px 6px;" flex="50">

        <md-select ng-model="item.user" placeholder="All" style="font-size: 14px; margin: -2px 0 0 0;" ng-disabled="item.value === false || item.team === ''">
            <md-option ng-value="undefined" ng-if="!item.user">All</md-option>
            <md-option ng-value="''" ng-if="item.user">All</md-option>
            <md-option
                ng-value="user.id"
                ng-repeat="user in inputWfStep.users | filter: { teamId: item.team } : true | orderBy: ['firstName', 'lastName'] "
                ng-disabled="(item.role == 8 || item.role == 3)  && (inputWfStep.ownerid == user.id || inputWfStep.arrayMCOUser == user.id)"
                ng-if="!user.disabled ||  user.id === item.user "
                >{{user.firstName}} {{user.lastName}} <span ng-if="user.disabled">- (disabled)</span></md-option>
        </md-select>

    </div>

    <div ng-if="!inputWfStep.disabled" flex="20" style="padding: 0px 6px;" layout="row">
        <md-switch ng-if="item.role === 1" ng-model="item.value"  aria-label="active" style="margin: -2px 0 0 0;" ng-disabled="(inputWfStep.disabled)" ></md-switch>

        <div  ng-if="item.role === 3 || item.role === 8 || item.role === 9 || item.role === 10" wraps-tooltip="docman-wf-input_delete">
            <md-button ng-disabled="inputWfStep.disabled" ng-if="!inputWfStep.disabled" class="md-icon-button" ng-click="inputWfStep.removeWorkflow(item.content,$index)">
                <ng-md-icon icon="clear"  aria-label="remove"></ng-md-icon>
            </md-button>
        </div>
        <div  ng-if="item.role === 3 || item.role === 8 || item.role === 9 || item.role === 10" wraps-tooltip="docman-wf-input_drag">
            <md-button ng-disabled="inputWfStep.disabled" ng-if="!inputWfStep.disabled" class="md-icon-button drag-handle">
                <ng-md-icon class="drag-handle" icon="drag_handle" aria-label="move"></ng-md-icon>
            </md-button>
        </div>
    </div>
</div>
