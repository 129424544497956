(function () {
  'use strict';

  angular
    .module('library.docdiffdialog')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('docdiffdialog', {
        url: '/docdiffdialog',
        templateUrl: 'library/docdiffdialog/docdiffdialog.tpl.html',
        controller: 'DocdiffdialogCtrl',
        controllerAs: 'docdiffdialog'
      });
  }
}());
