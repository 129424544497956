(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name healthcheck.controller:HealthcheckCtrl
   *
   * @description
   *
   */
  angular
    .module('healthcheck')
    .controller('HealthcheckCtrl', HealthcheckCtrl);

  function HealthcheckCtrl($filter, $q, $scope, $state, Books, Wraps) {
    var vm = this;
    vm.ctrlName = 'HealthcheckCtrl';
    vm.isLoading = true;
    vm.orderBy = 'bookId';
    vm.hasPermissions = Wraps.hasPermissions;
    vm.params = $state.params;
    vm.listFilter = {};
    Wraps.initData().then(function () {
      vm.getData();
    });

    vm.data = {};

    $scope.sortBy = function (propertyName) {
      vm.reverse = (vm.orderBy === propertyName) ? !vm.reverse : false;
      vm.orderBy = propertyName;
    };

    vm.getData = function () {
      vm.isLoading = true;
      Books.checkForErrors().$promise.then(function (res) {
        vm.data = res;
        _.forEach(res.docErrors, function (err) {
          err.error = '';
          err.bookIdLong = $filter('seven')(err.bookId);
          if (err.workflow_error) {
            err.error = err.workflow_error + '. ';
          }
          if (err.validation_errors && angular.isString(err.validation_errors)) {
            err.validation_errors = angular.fromJson(err.validation_errors.replace(/'/g, '"'));
            err.error = err.error + 'Invalid data in ' + err.validation_errors.map(function (field) {
              return $filter('titlecase')(field);
            }).join(', ') + '.';
          }
          vm.getDoc(err);
        });
        vm.data = res;
        vm.isLoading = false;
      });
    };

    vm.openDoc = function (docId) {
      $state.go('.', {doc: docId});
    };
    vm.closeDoc = function () {
      $state.go('.', {doc: null});
    };
    vm.getDoc = function (doc) {
      var deferred = $q.defer();
      if (doc) {
        doc.docId = parseInt(doc.docId, 10);
        vm.docLoading = true;
        if (doc.workflow_approvalList) {
          // if it's a string: replace single quotes with double-quotes and then parse it
          if (angular.isString(doc.workflow_approvalList)) {
            doc.workflow_approvalList = angular.fromJson(doc.workflow_approvalList.replace(/'/g, '"'));
          }

          // if it's a string: replace single quotes with double-quotes and then parse it
          if (angular.isString(doc.workflow_contentStatuses)) {
            doc.workflow_contentStatuses = angular.fromJson(doc.workflow_contentStatuses.replace(/'/g, '"'));
          }
          _.forEach(doc.workflow_approvalList, function (workflowStage) {
            if (!workflowStage.user || workflowStage.user === '') {
              workflowStage.User = 'All';
            } else {
              workflowStage.User = Wraps.getUserById(workflowStage.user);
              if (workflowStage.User) {
                workflowStage.User = workflowStage.User.firstName + ' ' + workflowStage.User.lastName;
              } else {
                workflowStage.User = 'Unknown user';
              }
            }
            if (!workflowStage.team || workflowStage.team === '') {
              workflowStage.Team = 'All';
            } else {
              workflowStage.Team = Wraps.getTeamById(workflowStage.team).teamName;
            }
            workflowStage.Role = Wraps.getRoleById(workflowStage.role).description;
          });
          doc.lastStage = doc.workflow_approvalList[doc.workflow_approvalListEnd];
        }
        deferred.resolve();
      } else {
        deferred.resolve();
      }
      deferred.promise.then(function () {
        vm.docLoading = false;
      });
    };

    Wraps.fetchedUser.then(function () {
      vm.hasAccess = vm.hasPermissions('viewHealthcheck');
    });

    vm.openDocInLibrary = function (bookId) {
      $state.go('library', {bookId: bookId});
    };

    // table filter info
    if (!vm.bookQuery) {
      vm.bookQuery = {
          limit: 10,
          page: 1
      };
    }

    $scope.$watch('healthcheck.data.errorList.length', function () {
      vm.bookQuery.page = 1;
    }, true);

    $scope.$watch('healthcheck.listFilter', function () {
      vm.bookQuery.page = 1;
    }, true);
  }
}());
