<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">


    <div class="md-whiteframe-z1 default-box" style="" ng-if="reports.hasAccess">
        <md-content layout-padding >
            <h2  class="">WRAPS Reports</h2>
			
			<h4>Marketing Compliance MI Report</h4>
			<md-select ng-model="reports.month" style="width:150px;">
				<md-option value="052018">May 2018</md-option>
				<md-option value="062018">June 2018</md-option>
				<md-option value="082018">November 2018</md-option>
			</md-select>
			<div layout="row">
				<md-button ng-click="reports.openPdf(reports.reportingData)" class="md-raised md-accent button-padding md-button md-ink-ripple">Download Full Monthly Report</md-button>
			</div>
			
			<div ng-repeat="table in reports.reportingData.tables" class="table-wrapper" ng-switch on="table.type">
				<table class="report" ng-switch-when="conditional">
					
					<thead>
						<tr>
							<th><ng-md-icon icon="help" size="18"></ng-md-icon> {{table.title}}
								<md-tooltip md-direction="left">{{table.helper}}</md-tooltip></th>
							<th class="data">%</th>
							<th class="data">#</th>
							<th class="data">Total</th>
						</tr>
					</thead>
					<tbody>
						<tr ng-repeat="row in table.data">
							<td><ng-md-icon icon="help" size="18"></ng-md-icon> <strong>{{row.name}} </strong><md-tooltip md-direction="left">{{row.helper}}</md-tooltip></td>
							<td class="{{row.color}} data">{{row.percentage}}</td>
							<td class="data">{{row.number}}</td>
							<td class="data">{{row.total}}</td>
						</tr>
					</tbody>
				</table>
				<table class="report" ng-switch-when="docInfo">
					<thead>
						<tr>
							<th colspan="4"><ng-md-icon icon="help" size="18"></ng-md-icon> {{table.title}}
							<md-tooltip md-direction="left">{{table.helper}}</md-tooltip></th>
						</tr>
						<tr>
							<th>ID</th>
							<th>Book Code</th>
							<th class="quart">Doc Type</th>
							<th class="quart">Owner</th>
						</tr>
					</thead>
					<tbody>
						<tr ng-repeat="row in table.data">
							<td class="data">{{row.id}}</td>
							<td>{{row.bookCode}}</td>
							<td>{{row.docType}}</td>
							<td>{{row.owner}}</td>
						</tr>
					</tbody>
				</table>
				<table class="report" ng-switch-when="trafficLights">
					<thead>
						<tr>
							<th><ng-md-icon icon="help" size="18"></ng-md-icon> {{table.title}}
								<md-tooltip md-direction="left">{{table.helper}}</md-tooltip></th>
							<th class="data">R</th>
							<th class="data">A</th>
							<th class="data">G</th>
							<th class="data"><ng-md-icon icon="help" size="18"></ng-md-icon> %<md-tooltip md-direction="left">% of Total MCOMP/Comp Submissions</md-tooltip></th>
						</tr>
					</thead>
					<tbody>
						<tr ng-repeat="row in table.data">
							<td>{{row.name}}</td>
							<td class="data red">{{row.red}}</td>
							<td class="data amber">{{row.amber}}</td>
							<td class="data green">{{row.green}}</td>
							<td class="data">{{row.percentage}}</td>
						</tr>
					</tbody>
				</table>
				<table class="report" ng-switch-when="twoCol">
					<thead>
						<tr>
							<th colspan="2"><ng-md-icon icon="help" size="18"></ng-md-icon> {{table.title}}
							<md-tooltip md-direction="left">{{table.helper}}</md-tooltip></th>
						</tr>
					</thead>
					<tbody>
						<tr ng-repeat="row in table.data">
							<td>{{row.name}}</td>
							<td class="data">{{row.val}}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div layout="row" layout-align-gt-md="end center">
				<md-button ng-click="" class="md-raised md-primary button-padding md-button md-ink-ripple">Download Force Expiry Report</md-button>
			</div>
        </md-content>



    </div>
	<div class="md-whiteframe-z1 default-box" style="" ng-if="reports.hasAccess === false">
		<md-data-table-toolbar><h2  flex>Access denied<br /><br /><br /></h2>

		</md-data-table-toolbar>
	</div>
	 <div class="md-whiteframe-z1 default-box" style="" ng-if="reports.hasAccess === null" >
		 <md-data-table-toolbar><h2  flex><br/></h2></md-data-table-toolbar>
            <md-progress-circular style="position: absolute; top: 10px; left: 50%; margin-left: -50px;"  md-diameter="80" md-mode="indeterminate"></md-progress-circular>
	</div>

</md-content>