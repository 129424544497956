(function () {
  'use strict';

  angular
      .module('accessDenied')
      .config(config);

  function config($stateProvider) {
    $stateProvider
        .state('accessDenied', {
            url: '/access-denied?src',
            params: {
                src: {
                    value: null,
                    squash: true
                }
            },
            views: {
                tooltip: {
                    templateUrl: 'tooltip/tooltip.tpl.html',
                    controller: 'TooltipCtrl',
                    controllerAs: 'tooltip'
                },
                navigation: {
                    templateUrl: 'navigation/navigation.tpl.html',
                    controller: 'NavigationCtrl',
                    controllerAs: 'navigation'
                },
                main: {
                    templateUrl: 'access-denied/access-denied.tpl.html',
                    controller: 'AccessDeniedCtrl',
                    controllerAs: 'accessDenied'
                },
                footer: {
                    templateUrl: 'footer/footer.tpl.html',
                    controller: 'FooterCtrl',
                    controllerAs: 'footer'
                }
            }
        });
  }
}());
