(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableTeam
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableTeam', readableTeam);

  function readableTeam(Wraps) {
    return function (id) {
      var team;
      if ((team = _.find(Wraps.teams, {teamId: parseInt(id)})) && team.teamName) {
        return team.teamName;
      }
      return null;
    };
  }
}());
