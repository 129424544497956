angular
    .module('library')
    .component('tabPublish', {
        templateUrl: 'library/library-components/right-menu/tab-publish/tab-publish.tpl.html',
        controller: tabPublishCtrl,
        controllerAs:'tabPublish',
        bindings:{
            book:'=',
            disabled:'=',
            visible:'<',
            switch:'='
        }

    });

tabPublishCtrl.$inject = ['$filter','$scope','Wraps'];
function tabPublishCtrl($filter, $scope, Wraps){
    var vm = this;
    //console.log("publish");



    $scope.$watch('tabPublish.book.divisionId',function(newValue, oldValue) {



        if(vm.book && oldValue && newValue != oldValue ) {
            setupPubs();
        }




    });

    $scope.$watch('tabPublish.visible',function() {
        if(vm.book) {
            setupPubs();
        }
        
    });


    var setupPubs = function(){
        Wraps.initVars.then(function () {

            vm.publishTypes = Wraps.publishTypes;
            vm.digitalList = $filter('filter')(Wraps.publishTypes, {
                category: 'Digital & Social',
                publishTypeStatus: 1,
                divisionId: vm.book.divisionId
            });
            vm.inSituList = $filter('filter')(Wraps.publishTypes, {
                category: 'Evidence',
                publishTypeStatus: 1,
                divisionId: vm.book.divisionId
            });
            vm.fulfilmentList = $filter('filter')(Wraps.publishTypes, {
                category: 'Fulfilment',
                publishTypeStatus: 1,
                divisionId: vm.book.divisionId
            });
            vm.productionList = $filter('filter')(Wraps.publishTypes, {
                category: 'Production Manager',
                publishTypeStatus: 1,
                divisionId: vm.book.divisionId
            });
            vm.supportList = $filter('filter')(Wraps.publishTypes, {
                category: 'Support Tools',
                publishTypeStatus: 1,
                divisionId: vm.book.divisionId
            });

        });
    }



    $scope.$watch('tabPublish.switch', function (newValue, oldValue) {

        //console.log('tabPublish.switch', angular.copy(vm.book));
        //console.log('tabPublish.switch', newValue);

        if(newValue && vm.book.workflowFinal) {
            
            if (newValue.production && newValue.production.flag == true) {
                ////console.log(_.find(vm.selected.book.workflowFinal, {role: 11}));
                _.find(vm.book.workflowFinal, {role: 11}).value = false;
            } else {
                _.find(vm.book.workflowFinal, {role: 11}).value = true;
            }
            
            if (newValue.fulfilment && newValue.fulfilment.flag == true) {
                //console.log(_.find(vm.selected.book.workflowFinal, {role: 12}));
                _.find(vm.book.workflowFinal, {role: 12}).value = false;
            } else {
                _.find(vm.book.workflowFinal, {role: 12}).value = true;
            }
            if (newValue.digital && newValue.digital.flag == true) {
                //console.log(_.find(vm.selected.book.workflowFinal, {role: 14}));
                _.find(vm.book.workflowFinal, {role: 14}).value = false;
            } else {
                _.find(vm.book.workflowFinal, {role: 14}).value = true;
            }
            if (newValue.support && newValue.support.flag == true) {
                //console.log(_.find(vm.selected.book.workflowFinal, {role: 15}));
                _.find(vm.book.workflowFinal, {role: 15}).value = false;
            } else {
                _.find(vm.book.workflowFinal, {role: 15}).value = true;
            }
            if (newValue.inSitu && newValue.inSitu.flag == true) {
                //console.log(_.find(vm.selected.book.workflowFinal, {role: 13}));
                _.find(vm.book.workflowFinal, {role: 13}).value = false;
            } else {
                _.find(vm.book.workflowFinal, {role: 13}).value = true;
            }
        }
    }, true);
    //console.log("/publish");

}