<div   flex></div>

<!--{{parent.Children.indexOf(child)}}-->
<div ng-if="(tabBook.opentab.selected.structure[0] == child.structureId || tabBook.inSelected(child.structureId)) &&  !tabBook.library.isReorderingChildren(child)" class="md-menu">

 <div class="menu-container" layout-align="center right" layout="column" ng-if="tabBook.library.menuAccess(child,parentbook)">
  <md-menu md-position-mode="target-right target"  md-offset="-10 10">
   <md-button aria-label="Open tools menu" class="md-icon-button" ng-click="$mdOpenMenu();$event.stopPropagation()" wraps-tooltip="jobbox-menu">
    <ng-md-icon icon="more_vert" style="fill: grey" size="24"></ng-md-icon>
   </md-button>
   <md-menu-content>
    <md-menu-item ng-if="tabBook.book.documentStatus.substring(0, 4) !== 'EXPD' && tabBook.library.editAccess(child,parentbook)">
     <md-button ng-click="tabBook.library.openEditCopy(child, parentbook)" wraps-tooltip="briefbox-menu_edit">
      <div layout="row">
       <p flex>Edit</p>
       <ng-md-icon icon="edit" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
      </div>
     </md-button>
    </md-menu-item>

    <md-menu-item ng-if="tabBook.book.documentStatus.substring(0, 4) !== 'EXPD' && tabBook.library.insertAccess(child,parentbook)">
     <md-button  ng-if="child.versions.Contents.ContentTypes.contentTypeId<4 || child.versions.Contents.ContentTypes.contentTypeId===11 || child.versions.Contents.ContentTypes.contentTypeId===14 || child.versions.Contents.ContentTypes.contentTypeId===15 || child.versions.Contents.ContentTypes.contentTypeId===18 || child.versions.Contents.ContentTypes.contentTypeId===21" ng-click="tabBook.library.insertContentDialog(child,parentbook)" aria-label="Insert content">
      <div layout="row">
       <p flex>Insert Content</p>
       <ng-md-icon icon="playlist_add" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
      </div>
     </md-button>
    </md-menu-item>

       <md-menu-item  ng-if="parentbook.DocTypes.docTypeTemplate === 1">
        <md-button ng-click="tabBook.library.openDocDiffDialog(parentbook.getChangesByStructureId(child.structureId), child)" aria-label="alert" wraps-tooltip="action-icon" >
           <div layout="row">
               <p flex>Info</p>
               <ng-md-icon  icon="info" style="fill: grey;  padding-left:10px; position:relative;" size="20"></ng-md-icon>
           </div>
       </md-button>
   </md-menu-item>


<!-- 
       <md-menu-item >
            <md-button ng-click="tabBook.library.showHistory(child.structureId, 'menu')" aria-label="alert" wraps-tooltip="briefbox-menu_history">
               <div layout="row">
                   <p flex>History</p>
                   <ng-md-icon  icon="history" style="fill: grey;  padding-left:10px; position:relative;" size="20"></ng-md-icon>
               </div>
           </md-button>
       </md-menu-item>
 -->


       <md-menu-item   ng-if="tabBook.library.hasPermissions('pushChanges')">
           <md-button  ng-click="tabBook.library.pushContentDialog(child)" aria-label="Push Changes">
               <div layout="row">
                   <p flex>Push Changes</p>
                   <ng-md-icon icon="share" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
               </div>
           </md-button>
       </md-menu-item>
   <md-menu-item ng-if="tabBook.book.documentStatus.substring(0, 4) !== 'EXPD' && tabBook.library.deleteAccess(child, parentbook)">
       <md-button  ng-click="tabBook.library.deleteContentDialog(child, parent.Children)" aria-label="Delete content">
           <div layout="row">
               <p flex>Delete Content</p>
               <ng-md-icon icon="delete" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
           </div>
       </md-button>
   </md-menu-item>


   <md-menu-item ng-if="tabBook.book.documentStatus.substring(0, 4) !== 'EXPD' && tabBook.library.moveAccess(child, parentbook) && (child.versions.Contents.contentTypeId === 1 || (child.versions.Contents.contentTypeId >= 5 &&  child.versions.Contents.contentTypeId <=8) || (child.versions.Contents.contentTypeId == 17) || (child.versions.Contents.contentTypeId == 19)|| (child.versions.Contents.contentTypeId == 20))" >
       <md-button ng-click="tabBook.library.reorderOpen(child, parent, child.versions.Contents.contentTypeId)">
           <div layout="row">
               <p flex>Re-Order All</p>
               <ng-md-icon icon="format_line_spacing" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
           </div>
       </md-button>
   </md-menu-item>

       <md-menu-item ng-if="tabBook.book.documentStatus.substring(0, 4) !== 'EXPD' && !$first && tabBook.library.moveAccess(child, parentbook)">
         <md-button ng-click="tabBook.library.moveUp(parent.Children.indexOf(child),parent)">
          <div layout="row">
           <p flex>Move Up</p>
           <ng-md-icon icon="arrow_drop_up" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
          </div>
         </md-button>
       </md-menu-item>

       <md-menu-item ng-if="tabBook.book.documentStatus.substring(0, 4) !== 'EXPD' && !$last && tabBook.library.moveAccess(child, parentbook)">
           <md-button ng-click="tabBook.library.moveDown(parent.Children.indexOf(child),parent)">
               <div layout="row">
                   <p flex>Move Down</p>
                   <ng-md-icon icon="arrow_drop_down" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
               </div>
           </md-button>
       </md-menu-item>

       <md-menu-item ng-if="tabBook.book.documentStatus.substring(0, 4) !== 'EXPD' && tabBook.library.moveAccess(child, parentbook) && ((child.versions.Contents.contentTypeId >= 5 &&  child.versions.Contents.contentTypeId <=8) || (child.versions.Contents.contentTypeId == 17) || (child.versions.Contents.contentTypeId == 19)|| (child.versions.Contents.contentTypeId == 20))" >
           <md-button ng-click="tabBook.library.ordAbcBookDialog(child,parent.Children)">
               <div layout="row">
                   <p flex>ABC sort Siblings</p>
                   <ng-md-icon icon="sort" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
               </div>
           </md-button>
       </md-menu-item>




   </md-menu-content>
  </md-menu>

 </div>
</div>
</div>

<div ng-if="tabBook.library.isReorderingChildren(child)" style="z-index: 100; padding-right: 10px;">
    <md-button class="md-grey"  ng-click="tabBook.library.reorderClose(child);tabBook.library.updateBooks();$event.stopPropagation();">Cancel</md-button>
    <md-button class="md-green" ng-click="tabBook.library.reorderSave(parentbook, child.structureId, child);$event.stopPropagation();">Re-Order</md-button>
</div>

<md-button
           ng-if="(tabBook.library.viewActionChildren(child, parentbook) && !tabBook.inSelected(child.structureId) && !tabBook.inSection(child.structureId))"
           class="md-icon-button" aria-label="alert"
           wraps-tooltip="briefbox-reject"><!--style="fill: #F44336;"-->
    <ng-md-icon  icon="error" md-colors="{fill:'light-blue'}" size="30"></ng-md-icon>
</md-button>




<md-button ng-click="tabBook.library.openWorkflowDialog(child, parentbook, 'Reject'); $event.stopPropagation()"
           ng-if="tabBook.library.viewReject(child, parentbook); "
           class="md-icon-button" aria-label="alert"
           wraps-tooltip="briefbox-reject"><!--style="fill: #F44336;"-->
    <ng-md-icon  icon="cancel" md-colors="{fill:'red'}" size="30"></ng-md-icon>
</md-button>
<md-button ng-click="tabBook.library.approveContentDialog(child, parentbook); $event.stopPropagation()"
           ng-if="tabBook.library.viewApprove(child, parentbook);  "
           class="md-icon-button" aria-label="alert"
           wraps-tooltip="briefbox-submit/approve"><!-- style="fill: #4CAF50;"-->
    <ng-md-icon  icon="check_circle" md-colors="{fill:'green'}" size="30"></ng-md-icon>
</md-button>

