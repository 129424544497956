(function () {
  'use strict';

  /* @ngdoc object
   * @name wrapsv1
   * @description
   *
   */
  angular
    .module('wrapsv1', [
      'ngAria',
      'ngMaterial',
      'ngMessages',
      'ngSanitize',
      'ngMdIcons',
      'ngStorage',
      'ngFileUpload',
      'lbServices',
      'md.data.table',
      'diff-match-patch',
      'chart.js',
      'ckeditor',
      'htmldiff',
      'dragularModule',
      'ui.router',
      'ngCsv',
      'home',
      'login',
      'users',
      'navigation',
      'library',
      'workflow',
      'reset',
      'reports',
      'maintenance',
      'permissionmanagement',
      'versions',
      'duScroll',
      'footer',
      'tooltip',
      'profile',
      'drive',
      'newLibrary',
      'accessDenied',
      'healthcheck'
    ])
    .config(function ($provide, $locationProvider, $mdThemingProvider, $mdIconProvider, LoopBackResourceProvider, $httpProvider, $mdDateLocaleProvider) {
      //$locationProvider.html5Mode(true).hashPrefix('#!');

      $locationProvider.html5Mode(true);

      //default config
      $provide.value(
          'clientConfig',{
            email: "wraps@bupa.com",
             name: "Bupa",
             helpsiteLink: "http://help.bupa.wraps.io",
             pdfUrl: "https://pdf.bupa.wraps.io",
             logo: "wraps"
            });

      // Use a custom auth header instead of the default 'Authorization'
      LoopBackResourceProvider.setAuthHeader('X-Access-Token');
      if (
        document.location.hostname.substring(0, 7) === '0.0.0.0' ||
        document.location.hostname.substring(0, 9) === 'localhost'
      ){
        LoopBackResourceProvider.setUrlBase('http://localhost:3000/api');
        $provide.value('system','local');
        $provide.value('api', 'LOCAL-');
        $provide.value('apiUrl', 'http://localhost:3000/api');
        $provide.value('pdfUrl', 'http://pdf');
        var color = 'blue';
        var colorOptions = { 'default': '800' };
        var accentColor = 'red';
        var accentOptions = {   'default': 'A700' };

      } else if(
        document.location.hostname.substring(0, 8) === '192.168.' ||
        document.location.hostname.substring(0, 3) === '10.'
      ){
        LoopBackResourceProvider.setUrlBase('http://'+document.location.hostname+':3000/api');
        $provide.value('system','local');
        $provide.value('api', 'LOCAL-');
        $provide.value('apiUrl', 'http://localhost:3000/api');
        $provide.value('pdfUrl', 'http://pdf');
        var color = 'green';
        var colorOptions = {  };
        var accentColor = 'pink';
        var accentOptions = {  };
      } else if (
        document.location.hostname.substring(0, 6) === 'wraps.' ||
        document.location.hostname.substring(0, 17) === 'www.bupa.wraps.io'||
        document.location.hostname.substring(0, 13) === 'bupa.wraps.io'
      ){
        if (document.location.protocol === 'http' ) {
          document.location.href = document.location.href.replace('http', 'https');
        }
        LoopBackResourceProvider.setUrlBase('https://api.bupa.wraps.io/api');
        $provide.value('system','live');
        $provide.value('api', '');
        $provide.value('apiUrl', 'https://api.bupa.wraps.io/api');
        $provide.value('pdfUrl', 'https://pdf.bupa.wraps.io');
        var color = 'blue-grey';
        var colorOptions = {  };
        var accentColor = 'pink';
        var accentOptions = {  };
      } else if (
        document.location.hostname.substring(0, 11) === 'demo.wraps.' ||
        document.location.hostname.substring(0, 22) === 'www.demo.bupa.wraps.io'||
        document.location.hostname.substring(0, 18) === 'demo.bupa.wraps.io'
      ){
        if (document.location.protocol === 'http' ) {
          document.location.href = document.location.href.replace('http', 'https');
        }
        // Change the URL to access the LoopBack REST API server
        LoopBackResourceProvider.setUrlBase('https://api.demo.bupa.wraps.io/api');
        $provide.value('system','demo');
        $provide.value('api', 'DEMO-');
        $provide.value('apiUrl', 'https://api.demo.bupa.wraps.io/api');
        $provide.value('pdfUrl', 'https://pdf.bupa.wraps.io');
        var color = 'teal';
        var colorOptions = {  };
        var accentColor = 'pink';
        var accentOptions = {  };
      }else if (
        document.location.hostname.substring(0, 13) === 'switch.wraps.' ||
        document.location.hostname.substring(0, 19) === 'www.switch.wraps.io'||
        document.location.hostname.substring(0, 15) === 'switch.wraps.io'
      ){
        if (document.location.protocol === 'http' ) {
          document.location.href = document.location.href.replace('http', 'https');
        }
        // Change the URL to access the LoopBack REST API server
        LoopBackResourceProvider.setUrlBase('https://api.switch.wraps.io/api');
        $provide.value('system','switch');
        $provide.value('api', 'SWITCH-');
        $provide.value('apiUrl', 'https://api.switch.wraps.io/api');
        $provide.value('pdfUrl', 'https://pdf.switch.wraps.io');

        $provide.value('clientConfig',{
          email: "switch@wraps.io",
          name: "",
          helpsiteLink: "http://help.switch.wraps.io",
          pdfUrl: "https://pdf.switch.wraps.io",
          logo: "wraps"
         });

        var color = 'indigo';
        var colorOptions = { 'default': '900' };
        var accentColor = 'red';
        var accentOptions = {   'default': 'A700' };
      } else {
        // Change the URL to access the LoopBack REST API server
        LoopBackResourceProvider.setUrlBase('https://api.staging.bupa.wraps.io/api');
        $provide.value('system','staging');
        $provide.value('api', 'STAGING-');
        $provide.value('apiUrl', 'https://api.staging.bupa.wraps.io/api');
        $provide.value('pdfUrl', 'https://pdf.bupa.wraps.io');
        var color = 'cyan';
        var colorOptions = {  };
        var accentColor = 'pink';
        var accentOptions = {  };
      }



      $mdDateLocaleProvider.parseDate = function(dateString) {
        var m = moment(dateString, 'DD-MM-YYYY', true);
        return m.isValid() ? m.toDate() : new Date(NaN);
      };
      $mdDateLocaleProvider.formatDate = function(date) {
        return moment(date).format('DD-MM-YYYY');
      };


      $mdThemingProvider.theme('default')
        .primaryPalette(color, colorOptions)
        .accentPalette( accentColor, accentOptions);

      $mdIconProvider.iconSet('social', 'img/icons/sets/social-icons.svg', 24).defaultIconSet('img/icons/sets/core-icons.svg', 24);

      // Inside app config block
      $httpProvider.interceptors.push(function($q, $location, LoopBackAuth) {
        //console.log('interceptors');
        return {
          response: function(response) {
             //all http



             return response;
           },
          responseError: function(rejection) {
             if (rejection.status === 401) {
               //Now clearing the loopback values from client browser for safe logout...
               LoopBackAuth.clearUser();
               LoopBackAuth.clearStorage();
               if($location.path() !== '/login' && $location.path() !== '/'){
                 $location.nextAfterLogin = $location.path();
                 $location.path('/login');
               }
             }
             return $q.reject(rejection);
           }
        };
      });

    })

    .run(function($location, $window,system, Users, $rootScope, $templateCache, Wraps){

      // window.onbeforeunload = function () {
      //   console.log('Reloading');
      // };

      //console.log("$location.path()",$location.path().substring(0, 7));
      if(!Users.isAuthenticated()) {
        //console.log('auth');
        if ($location.path() !== '/login' && $location.path().substring(0, 7) !== '/reset/' && $location.path() !== '/') {
          $location.nextAfterLogin = $location.path();
          $location.path('/login');
        }
      }

    }).controller('AppCtrl', AppCtrl).factory('$exceptionHandler', ['$injector', function($injector) {
      var errorCount = 0;
      return function myExceptionHandler(exception, cause) {

          if(_.isString(exception) && exception.indexOf('jsonpCallbackParam') !== -1) {

          }else if(errorCount < 100) {
              var ErrorLogs = $injector.get('ErrorLogs');
              var $location = $injector.get('$location');
              var url = $location.absUrl()
              var stack = '';

              if(typeof exception.stack !== 'undefined') {
                  stack = exception.stack.replace(/(\r\n|\n|\r)/gm,"\n ○ ");
              }

              ErrorLogs.insertError({"message":exception.message, "stackTrace": stack, "cause": cause, "url": url});
              errorCount++;
              console.error(exception);
          }else{
            console.log("error count exceeded!")
          }
      };
  }]);

  function AppCtrl($scope, api, Wraps) {

    var vm = this;
    vm.ctrlName = 'AppCtrl';

    $scope.api = api;

    Wraps.appVersion.$promise.then(function(version) {
      Wraps.thisAppVersion = version.appVersionId;
      $scope.appVersion = Wraps.thisAppVersion;
      $scope.docTitle = api + 'WRAPS - v' + version.appVersionId;
    });

    Wraps.fetchedUser =  Wraps.getCurrentUser();


  }

}());
