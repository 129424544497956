<md-toolbar class="md-theme-light">
    <div class="md-toolbar-tools">
        <h1 flex class="md-title" wraps-tooltip="users-form-title">User Management</h1>
        <md-button ng-click="users.closeRightMenu()" class="md-icon-button" aria-label="Close right pane" wraps-tooltip="users-form-close">
            <ng-md-icon icon="close" size="24"></ng-md-icon>
        </md-button>
    </div>
</md-toolbar>
<md-content  layout-padding>

    <form style="padding:0px 20px 80px;" autocomplete="off" name="usersForm" ng-model-options="{ allowInvalid: true}">
        <!--<pre>{{documentInfoForm.insurerId | json}}</pre>-->
        <div layout="row" layout-align="end none">
        <md-button  type="button" class="md-grey cancel-button"  ng-click="users.closeRightMenu()" wraps-tooltip="users-form-close">Cancel<ng-md-icon icon="close" style="fill: white; position: relative; padding-left: 4px; " size="20"  aria-label="Cancel"></ng-md-icon></md-button>
        <md-button
                type="button"
                class="md-green save-button"
                ng-click="users.saveUser(users.userDetails, usersForm)"
                ng-disabled="usersForm.$invalid" wraps-tooltip="users-form-save"
        >Save<ng-md-icon icon="check" style="fill: white; position: relative; padding-left: 4px; " size="20"  aria-label="Save changes"></ng-md-icon></md-button>
        <br />
		<input type="email" name="emailhacktopreventchromeautofill" style="display:none;"/>
		<input type="password" name="passwordhacktopreventchromeautofill" style="display:none;"/>
        </div>



                <p wraps-tooltip="users-licenses">Currently using {{users.noOfUsers}} of {{users.noOfSubs}} licenses</p>

                <div layout="row"  layout-wrap>

                    <md-input-container flex="33" wraps-tooltip="users-form-firstname">
                        <label>First Name</label>
                        <input ng-model="users.userDetails.firstName" required name="firstName">
                        <div ng-show="usersForm.firstName.$touched" ng-messages="usersForm.firstName.$error">
                            <span ng-message="required" class="error">You must enter a first name.</span>
                        </div>
                    </md-input-container>

                    <md-input-container flex="33" wraps-tooltip="users-form-lastname">
                        <label>Last Name</label>
                        <input ng-model="users.userDetails.lastName" required name="lastName">
                        <div ng-show="usersForm.lastName.$touched" ng-messages="usersForm.lastName.$error">
                            <span ng-message="required" class="error">You must enter a last name.</span>
                        </div>
                    </md-input-container>

                    <md-input-container flex="33" wraps-tooltip="users-form-job">
                        <label>Job Title</label>
                        <input ng-model="users.userDetails.jobTitle" required name="jobTitle">
                        <div ng-show="usersForm.jobTitle.$touched" ng-messages="usersForm.jobTitle.$error">
                            <span ng-message="required" class="error">You must enter a job title.</span>
                        </div>
                    </md-input-container>

                </div>




                <div layout="row"  layout-wrap>

                    <md-input-container flex="33" wraps-tooltip="users-form-email">
                        <label>Email address</label>
                        <input ng-model="users.userDetails.email" type="email" required name="email" ng-pattern="users.existingEmail">
                        <div ng-show="usersForm.email.$touched" ng-messages="usersForm.email.$error">
                            <span ng-message="pattern" class="error">This email address has already been registered.</span>
                            <span ng-message="required" class="error">You must enter an email address.</span>
                            <span ng-message="email" class="error">You must enter a valid email address.</span>
                        </div>
                    </md-input-container>

                    <md-input-container flex="33" wraps-tooltip="users-form-contact-email">
                        <label>Contact Email Address</label>
                        <input ng-model="users.userDetails.contactEmail" required name="contactEmail">
                        <div ng-show="usersForm.contactEmail.$touched" ng-messages="usersForm.contactEmail.$error">
                            <span ng-message="required" class="error">You must enter an email address.</span>
                            <span ng-message="email" class="error">You must enter a valid email address.</span>
                        </div>
                    </md-input-container>

                    <md-input-container flex="33" wraps-tooltip="users-form-tel">
                        <label>Phone Number</label>
                        <input ng-model="users.userDetails.tel" required name="phoneNumber">
                        <div ng-show="usersForm.phoneNumber.$touched" ng-messages="usersForm.phoneNumber.$error">
                            <span ng-message="required" class="error">You must enter a phone number.</span>
                        </div>
                    </md-input-container>

                </div>

                <div layout="row"  layout-wrap>

                    <md-input-container flex="25" wraps-tooltip="users-form-team">
                        <label>Team</label>
                        <md-select ng-model="users.userDetails.teamId" required placeholder="Team" name="team">
                            <md-option value="{{team.teamId}}" name="team" required ng-repeat="team in users.teams | orderBy: ['Departments.departmentName','teamName']" >{{team.Departments.departmentName}} / {{team.teamName}}</md-option>
                        </md-select>
                        <div ng-show="usersForm.team.$touched" ng-messages="usersForm.team.$error">
                            <span ng-message="required" class="error">You must enter a team.</span>
                        </div>
                    </md-input-container>
                    <md-input-container flex="25" wraps-tooltip="users-form-location">
                        <label>Location</label>
                        <md-select ng-model="users.userDetails.locationId" required placeholder="Location" name="location">
                            <md-option value="{{location.locationId}}"  ng-repeat="location in users.locations | orderBy: 'locationName'" >{{location.locationName}}</md-option>
                        </md-select>
                        <div ng-show="usersForm.location.$touched" ng-messages="usersForm.location.$error">
                            <span ng-message="required" class="error">You must enter a location.</span>
                        </div>
                    </md-input-container>

                    <md-input-container flex="25" wraps-tooltip="users-form-role">
                        <label>User Roles</label>
                        <md-select ng-model="users.userDetails.roleId" required placeholder="User Roles" name="role">
                            <md-option value="{{role.id}}"  ng-repeat="role in users.roles | orderBy: 'name'" >{{role.name}}</md-option>
                        </md-select>
                        <div ng-show="usersForm.role.$touched" ng-messages="usersForm.role.$error">
                            <span ng-message="required" class="error">You must enter a role.</span>
                        </div>
                    </md-input-container>



                    <md-input-container flex="25" wraps-tooltip="users-form-status">
                        <label>Status</label>
                        <md-select ng-model="users.userDetails.status"  name="status" placeholder="User Status">
                            <md-option value=null  >Active</md-option>
                            <md-option value="{{status.statusId}}"  ng-repeat="status in users.usersStatus | orderBy: 'status.statusName'" >{{status.statusName}}</md-option>
                        </md-select>
                        <!--<div ng-show="usersForm.status.$touched" ng-messages="usersForm.status.$error">-->
                        <!--<span ng-message="required" class="error">You must enter a status.</span>-->
                        <!--</div>-->
                    </md-input-container>




                </div>
				<div layout="row" wraps-tooltip="users-form-notes">
					<md-input-container flex="100">
                        <label>Notes</label>
						<textarea ng-model="users.userDetails.bupaNotes" name="bupaNotes"></textarea>
                    </md-input-container>
				</div>

                <div layout="row">



                        <md-input-container flex="33" wraps-tooltip="users-form-restricted">
                            <md-checkbox class="" ng-model="users.userDetails.limitDocs" ng-true-value="1"  ng-true-value=false aria-label="Restricted User" style="margin: 0px;">
                                Restricted User
                            </md-checkbox>
                        </md-input-container>


                    <md-input-container flex="33" wraps-tooltip="users-form-permission-group">
                        <label>Permissions Group</label>
                        <md-select ng-model="users.userDetails.permissionGroup" placeholder="Permission Group">
                            <md-option value="{{group.groupId}}"  ng-repeat="group in users.permissionGroups | orderBy: 'groupName'" >{{group.groupName}}</md-option>
                        </md-select>
                    </md-input-container>

                    <md-input-container flex="33" wraps-tooltip="users-form-permission-group">
                        <label>Division</label>
                        <md-select ng-model="users.userDetails.divisionId" placeholder="Division">
                            <md-option value="{{division.divisionId}}"  ng-repeat="division in users.divisions | orderBy: 'division'" >{{division.division}}</md-option>
                        </md-select>
                    </md-input-container>


                </div>






                <div layout="row"  layout-wrap>
                    <md-input-container flex="33" wraps-tooltip="users-form-change-password">
                        <md-checkbox ng-if="users.userDetails.id" class="" ng-model="users.temp.changepassword" aria-label="Change Password" style="margin: 0px;">
                            Change Password
                        </md-checkbox>
                    </md-input-container>

                    <md-input-container flex="33" ng-if="users.temp.changepassword || !users.userDetails.id" wraps-tooltip="users-form-password">
                        <label>Password</label>
                        <input ng-model="users.userDetails.password" type="password" required ng-minlength="8" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).+" name="password">
                        <div ng-show="usersForm.password.$touched" ng-messages="usersForm.password.$error" role="alert" class="t-sm">
                            <div ng-message="minlength" class="error">Your password must be at least 8 characters</div>
                            <div ng-message="pattern" class="error">The password must contain a combination of uppercase, lowercase, numeric characters and at least one of the following: !@#$%^&amp;*.</div>
                            <div ng-message="validation" class="error">This email address has already been registered</div>
                        </div>

                    </md-input-container>


                </div>
    </form>



</md-content>
