(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name library.directive:libraryContentRow
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="library">
       <file name="index.html">
        <library-content-row></library-content-row>
       </file>
     </example>
   *
   */
  angular
    .module('library')
    .directive('childItems', childItems)
    .directive('contentMenu', contentMenu)
    .directive('wcr', wcr)
    .directive('doc', doc)
    .directive('docItems', docItems)
    .directive('rightMenu', rightMenu)
    .directive('leftMenu', leftMenu)
    .directive('leftComments', leftComments)
    .directive('addComments', addComments)
    .directive('getContents', getContents);




  function childItems($compile) {
    return {
      restrict: "A",
      templateUrl: 'library/library-directives/library-row.tpl.html',
      scope: true,
      link: function (scope, element, attrs) {
        if (angular.isArray(scope.child.Children)) {
         var newList = angular.element("<md-list ng-show=\"$storage.selectedChapters\"><md-list-item child-items layout-wrap  ng-repeat=\"child in child.Children | orderBy: 'order'\" child=\"child\" ></md-list-item></md-list>");
         element.append(newList);
         $compile(newList)(scope);
        }

      }

    };
  }

  function contentMenu($compile) {
    return {
      restrict: "E",
      templateUrl: 'library/library-directives/library-menu.tpl.html',
      scope: false,

      link: function(scope, element, attributes) {
        //console.log(scope.$eval(attributes.parentbook));

        scope.parentbook = scope.$eval(attributes.parentbook);
        scope.parent = scope.$eval(attributes.parent);
        scope.child = scope.$eval(attributes.child);
        scope.indexNo = scope.$eval(attributes.index);
      }



    };
  }

  function wcr($compile) {
    return {
      restrict: "E",
      templateUrl: 'library/library-directives/library-wcr.tpl.html',
      scope: false,
      link: function(scope, element, attributes){
        //console.log(scope.child);
        scope.book = scope.$eval(attributes.book);
      }


    };
  }

  function doc($compile) {
    return {
      restrict: "E",
      templateUrl: 'library/library-directives/library-doc.tpl.html',
      scope: false,
      link: function(scope, element, attributes){
        //console.log(scope.child);
        scope.book = scope.$eval(attributes.book);
      }


    };
  }

  function docItems($compile) {
    return {
      restrict: "E",
      templateUrl: 'library/library-directives/library-docItems.tpl.html',
      scope: false,
      replace: true,
      transclude: true,
      link: function(scope, element, attributes){
        scope.book = scope.$eval(attributes.book);
        scope.parent = scope.$eval(attributes.parent);
        scope.child = scope.$eval(attributes.child);
        scope.indexNo = scope.$eval(attributes.index);
        scope.child.indexNo = scope.$eval(attributes.index);
        scope.child.prev = scope.$eval(attributes.prev);
        scope.child.next = scope.$eval(attributes.next);



        scope.$watch(attributes.next, function (newValue) {
          scope.child.next = scope.$eval(attributes.next);
        });
        scope.$watch(attributes.prev, function (newValue) {
          scope.child.prev = scope.$eval(attributes.prev);
        });
        scope.$watch(attributes.index, function (newValue) {
          scope.child.indexNo = scope.$eval(attributes.index);
        });



      }


    };
  }

  function rightMenu($compile) {
    return {
      restrict: "E",
      templateUrl: 'library/library-directives/library-rightMenu.tpl.html',
      scope: false,
      link: function(scope, element, attributes){
        //scope.book = scope.$eval(attributes.book);
      }
    };
  }

  function leftMenu($compile) {
    return {
      restrict: "E",
      templateUrl: 'library/library-directives/library-leftMenu.tpl.html',
      scope: false,
      link: function(scope, element, attributes){

      }
    };
  }

  function leftComments($compile) {
    return {
      restrict: "E",
      templateUrl: 'library/library-directives/library-leftComments.tpl.html',
      scope: true,
      controller:  libraryleftCommentsCtrl,
      controllerAs: 'leftComments',
      link: function(scope, element, attributes){

      }
    };
  }
  var libraryleftCommentsCtrl = function($scope, $element, $attrs, Wraps, $interval){
    var vm = this;
    //TODO not ideal
    Wraps.selectedEvents = [];
    Wraps.intervalLoadComments = $interval(function () {
      vm.isLoading = true;
      Wraps.loadComments(Wraps.commentsBook).then(function(events) {
        vm.events = Wraps.selectedEvents;
        vm.isLoading = false;
        //console.log('from interval');
      });
    }, 60000);

    vm.book = Wraps.commentsBook;
    $scope.$watch( function () { return Wraps.selectedEvents.length; }, function (data) {
      vm.isLoading = true;
      Wraps.loadComments(Wraps.commentsBook).then(function(events) {
        vm.events = Wraps.selectedEvents;
        vm.isLoading = false;
        //console.log('from watch');
      });

    });
  }



  function addComments($compile) {
    return {
      restrict: "E",
      templateUrl: 'library/library-directives/library-addComments.tpl.html',
      scope: {
        child: '='
      },
      controller:  libraryAddCommentsCtrl,
      controllerAs: 'addComments',
      link: function(scope, element, attributes){
        //console.log("addComments link");
        //scope.book = scope.$eval(attributes.book);
      }
    };
  }


  var libraryAddCommentsCtrl = function($scope, clientConfig, $element, $attrs, $interval, $q, $localStorage, $mdConstant, apiUrl, Wraps, Users){
     var vm = this;
     vm.apiUrl = apiUrl;


    vm.clientConfig = clientConfig;

      $scope.$storage = $localStorage;
      vm.keys = [$mdConstant.KEY_CODE.COMMA];
      vm.additionalRecipients = [];
      vm.selected = [];
      vm.userslist = [];

     //console.log("addComments libraryAddCommentsCtrl start");
      vm.defaultContacts = [{
          name: 'Wraps Team',
          email: vm.clientConfig.email,
          status: 1
      }];

      vm.mergeSelected = function () {
        var mergedContactList = vm.selected.concat(vm.additionalRecipients);
        return mergedContactList;
      }

     vm.loadContacts = function() {

       Users.approvalListUsers(vm.book.bookId).$promise.then(function (contacts) {
         vm.allContacts = contacts.map(function (c, index) {
           var contact = {
             name: c.name,
             email: c.email,
             status: c.status
           };
           contact._lowername = contact.name.toLowerCase();
           return contact;
         });

         vm.contacts = [];
       });

       Users.find({ 'filter': { 'include': ['Teams', 'UsersStatus'] } }).$promise.then(function (results) {
           vm.userslist = results.map(function (c, index) {
             var contact = {
               name: c.Teams.teamName + ' / ' + c.firstName + ' ' + c.lastName,
               email: c.contactEmail,
               status: c.status
             };
             contact._lowername = contact.name.toLowerCase();
             return contact;
           }).filter(function (c) {
             return c.status === null;
           });
       });
     };

     vm.toggle = function (item, list) {
       var idx = list.indexOf(item);
       if (idx > -1) {
         list.splice(idx, 1);
       }
       else {
         list.push(item);
       }
     };

     vm.exists = function (item, list) {
       return list.indexOf(item) > -1;
     };

     vm.isIndeterminate = function() {
       return (vm.allContacts &&
           vm.selected.length !== 0 &&
           vm.selected.length !== vm.allContacts.length);
     };

     vm.isChecked = function() {
       if(vm.selected) {
         return (vm.allContacts &&
             vm.selected.length === vm.allContacts.length);
       }else {
         return false;
       }
     };

     vm.toggleAll = function() {
       if (vm.selected.length === vm.allContacts.length) {
         vm.selected = [];
       } else if (vm.selected.length === 0 || vm.selected.length > 0) {
         //console.log(vm.allContacts);
         vm.selected = vm.allContacts.slice(0);
       }
     };


     vm.book = Wraps.book;
     vm.contacts = '';

     vm.asyncContacts = [];
     vm.filterSelected = false;
    vm.loadContacts();

      vm.querySearch = function (criteria) {
          return criteria ? vm.userslist.filter(createFilterFor(criteria)) : [];
      }
      function createFilterFor(query) {
          var lowercaseQuery = query.toLowerCase();

          return function filterFn(contact) {
              return (contact._lowername.indexOf(lowercaseQuery) !== -1);
          };

      }


     vm.saveComments = function(thisBook, thisChild, thisComment, emailAddresses){
       vm.showForm = false;
       vm.comment = {};
       vm.selected = [];
       vm.commentForm.$setPristine();
       vm.commentForm.$setUntouched();
          Wraps.saveComments(thisBook, thisChild, thisComment, emailAddresses).then(function(){
            if(thisChild){
               // console.log("load workflow dialog events"); //loadEvents();
                $scope.$emit('newCommentCreated');
            }else{
                Wraps.selectedEvents.push("test");
            }
          });
       };

  }




  function getContents($compile) {
    return {
      restrict: "E",
      templateUrl: 'library/library-directives/library-getContents.tpl.html',
      scope: {
        item: '=item'
      },
      controllerAs: 'vm',
      controller: function($scope, $element,$attrs, Wraps) {
        var vm = this;
        //vm.book = angular.copy(Wraps.book);
        vm.book = Wraps.book;
        vm.nl2br = Wraps.nl2br;

        vm.item = $scope.item;
      },
      link: function(scope, element, attributes){
        //console.log(scope.$eval(attributes.item));
        //scope.item = scope.$eval(attributes.item);
      }


    };
  }





}());
