(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name tooltip.controller:TooltipCtrl
   *
   * @description
   *
   */
  angular
    .module('tooltip')
    .controller('TooltipCtrl', TooltipCtrl)
	.directive('wrapsTooltip', wrapsTooltip);


  function TooltipCtrl($localStorage, $location, $mdDialog, $rootScope, $scope, Tooltips, Wraps) {
    var vm = this;
    vm.ctrlName = 'TooltipCtrl';
    //vm.showToolTip = false;
	vm.defaultTitle = 'Tool tips';
	vm.defaultText = 'Hover over elements in the page to find out what they do';

    $scope.$storage = $localStorage;

    vm.showToolTip = Wraps.toolTip.showToolTip;

    //if(!Users.isAuthenticated()){$location.path('/');}
	 vm.hide = function(){
         Wraps.toolTip.showToolTip = vm.showToolTip = false;
	 }
	 vm.hasPermissions = Wraps.hasPermissions;
	 $scope.$watch('tooltip.showToolTip',function(neue,old){
		 if(neue== true){
			 angular.element(document).find('md-sidenav').addClass("tooltip-open");
		 }else{
			 angular.element(document).find('md-sidenav').removeClass("tooltip-open");
		 }
		 
	 });


    vm.isActive = function (viewLocation) {
      return viewLocation === $location.path();
    };

  

    vm.users = function () {
      $mdDialog.show(
        $mdDialog.alert()
          .title('Document Cascader')
          .content('You do not have permissions to use this area')
          .ok('Close')
      );
    };

    $rootScope.$on("toggleTooltip", function () {
      vm.helpTitle = 'Loading tooltips...';
	  vm.helpText = '';
	  if (!vm.showToolTip && !Wraps.tooltips){
		 Tooltips.__get__notDeleted(function (tooltiparray) {
			Wraps.tooltips = tooltiparray;
			vm.helpTitle = vm.defaultTitle;
			vm.helpText = vm.defaultText;
		}); 
	  }
        Wraps.toolTip.showToolTip = vm.showToolTip = (vm.showToolTip ? false : true);
    });

    $rootScope.$on("openTooltip", function (event, helpCode) { //ToDo rewrite with promises & maybe functions to share with other event anonymous functions in this controller. - a lot of copy-paste
        if (!vm.showToolTip && !Wraps.tooltips){
            vm.helpTitle = 'Loading tooltips...';
            vm.helpText = '';
            Tooltips.__get__notDeleted(function (tooltiparray) {
                Wraps.tooltips = tooltiparray;
                if(helpCode){
                    var $activetip = Wraps.tooltips.filter(function(arrayel){
                        return helpCode == arrayel.tooltipCode;
                    });
                    if($activetip[0]) {
                        vm.helpTitle = $activetip[0].title;
                        vm.helpText = $activetip[0].description;
                        vm.helpCode = helpCode;
                    }else{
                        vm.helpTitle = vm.defaultTitle;
                        vm.helpText = vm.defaultText;
                        vm.helpCode = helpCode;
                    }
                }else{
                    vm.helpTitle = vm.defaultTitle;
                    vm.helpText = vm.defaultText;
                    vm.helpCode = '';
                }
            });
        }else{
            if(helpCode){
                var $activetip = Wraps.tooltips.filter(function(arrayel){
                    return helpCode == arrayel.tooltipCode;
                });
                if($activetip[0]) {
                    vm.helpTitle = $activetip[0].title;
                    vm.helpText = $activetip[0].description;
                    vm.helpCode = helpCode;
                }else{
                    vm.helpTitle = vm.defaultTitle;
                    vm.helpText = vm.defaultText;
                    vm.helpCode = helpCode;
                }
            }else{
                vm.helpTitle = vm.defaultTitle;
                vm.helpText = vm.defaultText;
                vm.helpCode = '';
            }
        }

      	Wraps.toolTip.showToolTip = vm.showToolTip = true;
    });


    $rootScope.$on("tooltip", function (event, helpCode) {
      if(vm.showToolTip) {
		if(helpCode == false || !Wraps.tooltips){
			vm.helpTitle = vm.defaultTitle;
			vm.helpText = vm.defaultText;
			vm.helpCode = '';
			$scope.$apply();
		}else{
			var $activetip = Wraps.tooltips.filter(function(arrayel){
				return helpCode == arrayel.tooltipCode;
			});
			if($activetip[0]) {
				vm.helpTitle = $activetip[0].title;
				vm.helpText = $activetip[0].description;
				vm.helpCode = helpCode;
				$scope.$apply();
			}else{
				vm.helpTitle = vm.defaultTitle;
				vm.helpText = vm.defaultText;
				vm.helpCode = helpCode;
				$scope.$apply();
			}
		}

      }

    });

  }
}());

function wrapsTooltip(){
	return {
		scope: {
			wrapsTooltip: '&'
		},
		restrict: 'A',
		controllerAs: 'vm',
		controller:function($scope, $element, $attrs, $rootScope){
			$element.on('mouseleave', function () {
				$rootScope.$emit("tooltip", false);
			});
			$element.on('mouseenter', function () {
				$rootScope.$emit("tooltip", $attrs.wrapsTooltip);
			});
		}
	};
}