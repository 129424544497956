(function () {
    'use strict';

    angular
        .module('newLibrary')
        .config(config);

    function config($stateProvider) {
        $stateProvider
            .state('newLibrary', {
                url: '/new-library',

                views: {
                    navigation: {
                        templateUrl: 'navigation/navigation.tpl.html',
                        controller: 'NavigationCtrl',
                        controllerAs: 'navigation'
                    },
                    main: {
                        templateUrl: 'new-library/new-library.tpl.html',
                        controller: 'NewLibraryCtrl',
                        controllerAs: 'newLibrary'
                    },
                    footer: {
                        templateUrl: 'footer/footer.tpl.html',
                        controller: 'FooterCtrl',
                        controllerAs: 'footer'
                    }
                }
            });
    }
}());
