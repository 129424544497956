angular
    .module('library')
    .component('tabContent', {
        templateUrl: 'library/library-components/right-menu/tab-content/tab-content.tpl.html',
        controller: tabContentCtrl,
        controllerAs:'tabContent',
        bindings:{
            book:'<',
            disabled:'<'
        }

    });
tabContentCtrl.$inject = ['Wraps'];
function tabContentCtrl(Wraps){
    var vm = this;

    Wraps.initVars.then(function() {
        vm.languages = Wraps.languages;
        vm.docContents = Wraps.docContents;
    });
}