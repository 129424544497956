<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">

    <div class="md-whiteframe-z1 default-box" style="" ng-if="users.hasAccess" >
        <md-button ng-click="users.openRightMenu('new', {})" class="md-fab wraps-add-external"   aria-label="Add user" wraps-tooltip="users-add-btn">
            <ng-md-icon icon="add" style="fill: white" size="30"></ng-md-icon>
        </md-button>


        <md-progress-circular style="position: absolute; top: 10px; left: 50%; margin-left: -50px;"  md-diameter="80" md-mode="indeterminate" ng-show="users.isLoading"></md-progress-circular>

        <md-data-table-toolbar><h2 flex wraps-tooltip="users-title">User Management</h2>

        <md-button aria-label="Download CSV" class="md-icon-button" ng-if="users.hasPermissions('downloadUsers')" ng-csv="users.downloadCSV()"  lazy-load="true" filename="users-download.csv" add-bom="true" charset="utf-8" csv-label="true">
            <ng-md-icon icon="file_download" style="fill: grey; " size="24" wraps-tooltip="users-data-download"></ng-md-icon>
        </md-button>
        <md-button aria-label="Refresh Users" class="md-icon-button" ng-click="users.updateUsers()"   aria-label="Refresh Users" wraps-tooltip="users-refresh-btn">
			<ng-md-icon icon="refresh" style="fill: grey;" size="24"></ng-md-icon>
		</md-button>
        <md-button aria-label="Open search" class="md-icon-button" ng-hide="users.searchUser" ng-click="users.searchUser=true"  aria-label="Open search" wraps-tooltip="users-search-btn">
            <ng-md-icon icon="search" style="fill: grey;" size="24"></ng-md-icon>
        </md-button>
        </md-data-table-toolbar>

        <div layout="row"  layout-wrap>
            <md-input-container flex="5">
            </md-input-container>
            <md-input-container flex="45">
				<md-switch ng-model="users.filterUsers.status" ng-true-value="2" ng-false-value=null aria-label="Restricted User" style="margin: 0px;" wraps-tooltip="users-view-disabled">
                    View disabled
               </md-switch>
            </md-input-container>

            <md-input-container flex="45">

                <p style="text-align: right;" wraps-tooltip="users-licenses">Currently using {{users.noOfUsers}} of {{users.noOfSubs}} licenses</p>
            </md-input-container>
        </div>

        <md-data-table-container>




            <table md-data-table  > <!--md-row-select="selected"-->
                <thead ng-show="users.searchUser">
                <tr wraps-tooltip="users-search-fields">
                    <td></td>
                    <td>
                        <md-input-container  style="width: 45%; float:left;"><input ng-model="users.filterUsers.firstName" style="width: 90%;" aria-label="Filter first name"></md-input-container>
                        <md-input-container style="width: 45%; float:left;"><input ng-model="users.filterUsers.lastName" style="width: 90%;" aria-label="Filter last name"></md-input-container>
                    </td>
                    <td><md-input-container><input ng-model="users.filterUsers.email" style="width: 90%;" aria-label="Filter email"></md-input-container></td>
                    <td><md-input-container><input ng-model="users.filterUsers.Teams.teamName" style="width: 90%;" aria-label="Filter team"></md-input-container></td>
                    <td><md-input-container><input ng-model="users.filterUsers.Locations.locationName" style="width: 90%;" aria-label="Filter location"></md-input-container></td>

                    <td><md-input-container><input ng-model="users.filterUsers.Roles.name" style="width: 90%;" aria-label="Filter role"></md-input-container></td>
                    <td><md-input-container><input ng-model="users.filterUsers.limitDocs" style="width: 90%;" aria-label="Filter restricted"></md-input-container></td>
                    <td><md-input-container><input ng-model="users.filterUsers.Divisions.division" style="width: 90%;" aria-label="Filter division"></md-input-container></td>
					<td><md-input-container><input ng-model="users.filterUsers.bupaNotes" style="width: 90%;" aria-label="Filter notes"></md-input-container></td>

                    <td><md-button aria-label="Close Search" class="md-icon-button" style="position: relative; top: 6px; right:-00px;" ng-click="users.searchUser=false; users.filterUsers={}" wraps-tooltip="users-search-close">
                        <ng-md-icon icon="close" style="fill: grey; " size="24"></ng-md-icon>
                    </md-button></td>
                </tr>
                </thead>
                <thead  md-order="users.usersQuery.order">
                <tr wraps-tooltip="users-table-heading">
                    <!--<th order-by="id" numeric>ID</th>-->
                    <th></th>
                    <th order-by="firstName">Name</th>
                    <th order-by="email">Email</th>
                    <th order-by="Teams.teamName">Team</th>
                    <th order-by="Locations.locationName">Location</th>
                    <th order-by="Roles.name">Role</th>
                    <th order-by="limitDocs">Restricted</th>
                    <th order-by="Divisions.division">Division</th>
					<th order-by="bupaNotes">Notes</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr ng-class="user.status==2?'userDisabled':''" ng-repeat="user in  users.filteredUsers = (users.users | filter: users.filterUsers) |  orderBy: users.usersQuery.order   | limitTo: users.usersQuery.limit: (users.usersQuery.page- 1) * users.usersQuery.limit"  ng-click="users.openRightMenu('edit', user)" ng-hide="$storage.selectedUser === user.id"  class="data-rows" wraps-tooltip="users-table-row">
                    <!--<td><a >{{ user.id }}</a></td>-->
                    <td></td>
                    <td>{{user.firstName}} {{user.lastName }}</td>
                    <td>{{user.email }}</td>
                    <td>{{user.Teams.teamName }}</td>
                    <td>{{user.Locations.locationName }}</td>
                    <td>{{user.Roles.name}}</td>
                    <td>{{user.limitDocs == 1?"Yes":""}}</td>
					<td>{{user.Divisions.division}}</td>
					<td>{{user.bupaNotes}}</td>
                    <td></td>
                </tr>
                </tbody>
            </table>
        </md-data-table-container>

        <md-data-table-pagination md-limit="users.usersQuery.limit" md-page="users.usersQuery.page" md-total="{{users.filteredUsers.length}}"></md-data-table-pagination>






    </div>
    <div class="md-whiteframe-z1 default-box" style="" ng-if="users.hasAccess === false">
		<md-data-table-toolbar><h2  flex wraps-tooltip="users-access-denied">Access denied<br /><br /><br /></h2>

		</md-data-table-toolbar>
	</div>
	<div class="md-whiteframe-z1 default-box" style="" ng-if="users.hasAccess === null" >
		 <md-data-table-toolbar><h2  flex><br/></h2></md-data-table-toolbar>
            <md-progress-circular style="position: absolute; top: 10px; left: 50%; margin-left: -50px;"  md-diameter="80" md-mode="indeterminate"></md-progress-circular>
	</div>

</md-content>


<md-sidenav class="md-sidenav-right md-whiteframe-z3 library-sidenav" md-component-id="right" style="position: fixed;">
    <users-right-menu></users-right-menu>
</md-sidenav>
