<md-dialog aria-label="Reason for Change "  class="changedialog"   ng-cloak>

    <md-toolbar>
        <div class="md-toolbar-tools">
            <h2 ng-if="changedialog.filter != 'rfd' && changedialog.filter != 'rfe'">Reason for Change</h2>
            <h2 ng-if="changedialog.filter === 'rfd'">Reason for Duplication</h2>
            <h2 ng-if="changedialog.filter === 'rfe'">Reason for Expiry</h2>
            <span flex></span>

            <md-button ng-click="changedialog.dialogCancel(false)" class="md-icon-button">
                <ng-md-icon  icon="close" size="24"  aria-label="Close dialog"></ng-md-icon>
            </md-button>
        </div>
    </md-toolbar>
    <md-dialog-content  class="dialog-content big" >




        <md-content class="md-padding" flex="100">
            <form ng-submit="$event.preventDefault()" name="rejectForm" layout="column" style="padding: 20px;">

                <md-input-container class="md-block">
                    <label>Change Category</label>
                    <md-select name="reason1" ng-model="changedialog.reason.reason"  required>



                        <md-option ng-repeat="category in changedialog.rfcReasons | filter: {'type':changedialog.filter} | orderBy:'name' track by category.id" value="{{category.id}}">{{category.name}}</md-option>
                    </md-select>

                </md-input-container>


                <md-input-container class="md-block">
                    <label>Change message</label>
                    <textarea name="message" ng-model="changedialog.reason.message"   rows="3" required></textarea>
                </md-input-container>
                <div class="errors" ng-messages="rejectForm.message.$error" ng-if="rejectForm.$dirty">
                    <div ng-message="required">Required</div>
                </div>


                <md-input-container class="md-block" ng-if="changedialog.filter != 'rfd' && !changedialog.noCheckbox && changedialog.filter != 'rfe'">


                    <md-checkbox ng-model="changedialog.reason.significant">Significant</md-checkbox>


                </md-input-container>
            </form>

        </md-content>



    </md-dialog-content>

    <md-dialog-actions>

        <md-button ng-if="$storage.lastRFC" ng-click="changedialog.reason = changedialog.getRFC(changedialog.reason)">Use last RFC</md-button>
        <span flex></span>
        <md-button class="md-grey"  ng-click="changedialog.dialogCancel(false)">Cancel<ng-md-icon icon="close" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>
        <md-button  ng-disabled="!(changedialog.reason.reason && changedialog.reason.message) || changedialog.isLoading" class="md-green"  ng-click="changedialog.dialogHide(changedialog.reason);">Submit Changes<ng-md-icon icon="done" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>

    </md-dialog-actions>
</md-dialog>