<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">

    <div class="md-whiteframe-z1 default-box" style="" >


        <md-progress-circular style="position: absolute; top: 10px; left: 50%; margin-left: -50px;"  md-diameter="80" md-mode="indeterminate" ng-show="accessDenied.isLoading"></md-progress-circular>

        <md-data-table-toolbar>
        	<h2 flex>Access Denied</h2>
        </md-data-table-toolbar>
		<md-content>
			<p>You do not have permission to access the {{accessDenied.src}} page.</p>
			<p>If you believe this is an error please contact {{accessDenied.clientConfig.email}} or report a technical issue via the <md-button class="md-primary" href="#" target="_blank">help portal</md-button>.</p>
			<md-button ui-sref="home" class="md-primary md-raised">Go to dashboard</md-button>
		</md-content>
	</div>
</md-content>
