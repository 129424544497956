(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableRole
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableRole', readableRole);

  function readableRole(Wraps) {
    return function (id) {
      var role;
      if ((role = _.find(Wraps.roles, {id: parseInt(id)})) && role.description) {
        return role.description;
      }
      return null;
    };
  }
}());
