(function () {
  'use strict';

  /* @ngdoc object
   * @name home
   * @description
   *
   */
  angular
    .module('home', [
      'ui.router'
    ])
	.directive('doughnutChart', doughnutChart);
}());

function doughnutChart() {
  return {
	restrict: 'E',
	templateUrl: 'home/home-doughnut-chart.tpl.html',
	scope: {
      primary: '=',
      total: '=',
      series: '='
	}
  };
}
