(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.contentdialog.controller:ContentdialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.contentdialog')
    .controller('ContentdialogCtrl', ContentdialogCtrl);

  function ContentdialogCtrl($localStorage, $mdDialog, $q, $scope, book, Books, child, Contents, ContentTypes, Events, History, Structures, Teams, Users, Versions, Wraps, WrapsBooks) {
    var vm = this;
    vm.ctrlName = 'ContentdialogCtrl';
    $scope.$storage = $localStorage;
    if(!Users.isAuthenticated()){$location.path('/');}


    vm.book = book;
    vm.child = child;

    vm.getTableWidths = Wraps.getTableWidths;

    // speed tweak to pre select products
    vm.contentOwner = 14;

    /// creates list of used ids for checkboxes
    var usedContentIds = [];
    Structures.find({
      filter: {
        where: { bookId: child.bookId, "parent": child.structureId  },
        include: "versions"
      }
    }).$promise.then(function(data){
        usedContentIds = _.map(data,'versions.contentId');
    });

    vm.thisContentId = child.versions.Contents.ContentTypes.contentTypeId;
    vm.thisChapterTypeId = child.versions.chapterTypeId;
    
    
    vm.thisParentName = child.versions.versiondata.replace(/<\/?[^>]+>/gi, '');
    //console.log(child.versions.Contents.ContentTypes.contentTypeId);


    //Teams for add content owners
    vm.teams = Teams.__get__notDeleted();

    //content types for add content types
    vm.contentTypes = ContentTypes.find({});

    vm.tableHeader = [
    {"name":"Column Name","width":20,"active":true}
    ];



    vm.removeTableHeader = function ($index) {
      vm.tableHeader.splice($index, 1);
    }

    vm.addTableHeader = function () {
      vm.tableHeader.push({"name":"Column Name","width":20,"active":true});
    }


    vm.nl2br = function(string){
      try {
        return string.replace(/\n/g, '<br>');
      }catch(err){
        //return false;
      }
    }

    vm.json2Obj = function(obj){

      try{ obj = angular.extend(obj,angular.fromJson(obj.versions.versiondata));}
      catch (err){    console.log(err.name + ': "' + err.message +  '" occurred when assigning string.'); return false;}
      return obj;

    }

    vm.getContent = function (struct){
      if(struct && struct.bookId && child.versions.contentId) {
        vm.isLoading = true;
        Structures.getChildContent({bookId: struct.bookId, "contentId": child.versions.contentId})
          .$promise.then(function (data) {
            _.forEach(data, function (item) {
              item.disabled = usedContentIds.indexOf(item.versions.contentId) >= 0;
              item.selected = item.disabled;




              if (item.versions.versiondata && item.versions.versiondata.charAt(0) === "{") {
                item = vm.json2Obj(item);
              }
            });
            vm.contentlist = data;

            vm.isLoading = false;
          },
          function(data) { //error
            vm.isLoading = false;
            vm.contentlist = [];

          });
      }else{

        vm.contentlist = [];

      }
    }



    vm.openChangeDialog = function (filter) {
      var deferred = $q.defer();
      return $mdDialog.show({
        controller: 'ChangedialogCtrl',
        controllerAs: 'changedialog',
        templateUrl: 'library/changedialog/changedialog.tpl.html',
        parent: angular.element(document.body),
        locals: {filter: filter},
        bindToController: true,
        clickOutsideToClose: true
      })
          .then(function (data) {

            //book.update(true);
            return data;
          }, function () {
            return false; //cancel
          });
    };


    vm.addContent = function (contentItems){


      vm.openChangeDialog().then(function(eventData) {
        if (eventData) {

          vm.isLoading = true;
          _.remove(contentItems, function (contentItem) {
            return contentItem.disabled === true;
          });
          _.remove(contentItems, function (contentItem) {
            return contentItem.selected === false;
          });
          var defer = $q.defer();
          var promises = [];

          angular.forEach(contentItems, function (contentItem) {
            promises.push(
                Structures
                    .duplicateDeep({
                      "bookId": child.bookId,
                      "status": 0,
                      "approvalStatus": child.approvalStatus,
                      "structureId": contentItem.structureId,
                      "parent": child.structureId,
                      "eventData": eventData,
                      "type": "pull"
                    })
                    .$promise.then(function (response) {
                  defer.resolve();
                })
            );
          });

          $q.all(promises).then(function () {
            Books.upsert({"bookId": child.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)}).$promise.then(function () {

              vm.dialogHide(true); //true = refresh parent
              WrapsBooks.updateBookById(book.bookId);
              //book.update(true);
              //vm.isLoading = false;
              Wraps.updateBookData = book.bookId;
            });
          });

        }
      });
    }



    vm.addNewContent = function (returnData){
      vm.openChangeDialog().then(function(eventData) {
        if (eventData) {

          
          vm.isLoading = true;
          Contents.create({
            contentId: 0,
            contentTypeId: returnData.contentType.contentTypeId,
            contentName: vm.thisParentName + " - " + returnData.contentType.contentTypeName,
            contentOwner: returnData.contentOwner
          }, function (data1) {
  
            Versions.create({
              versionId: 0,
              contentId: data1.contentId,
              versiondata: returnData.contentData
            }, function (data2) {
  
              Structures.create({
                structureId: 0,
                versionId: data2.versionId,
                bookId: child.bookId,
                "status": 0,
                "approvalStatus": book.userInBook[0],
                contentId: data1.contentId,
                parent: child.structureId,
                order: data2.versionId
              }, function (data3) {
  
                Books.upsert({"bookId": child.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                History.create({
                  "structureId": data3.structureId,
                  "versionIdOld": 0,
                  "versionIdNew": data3.versionId,
                  "historyId": 0
                }, function (historyResponse) {
                  Events.create({
                    "eventId": 0,
                    "bookId": child.bookId,
                    "structureId": data3.structureId,
                    "contentId": data1.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Added",
                    "eventData": Wraps.toJson(eventData)
                  });
                });
                vm.dialogHide(true); //true = refresh parent
                WrapsBooks.updateBookById(book.bookId);
                //book.update(true);
                //vm.isLoading = false;
                Wraps.updateBookData = book.bookId;
              });
            });
          });
        }
      });
    }
    
    
    
    vm.addNewBenefit = function (returnData){
      vm.openChangeDialog().then(function(eventData) {
        if (eventData) {
          vm.isLoading = true;
          var benefit = {};
          if (_.size(returnData.benefit.options) === 0) {
            delete returnData.benefit.options;
          }
          benefit.benefit = returnData.benefit;
          Contents.create({
            contentId: 0,
            contentTypeId: 5,
            contentName: vm.thisParentName + " - " + returnData.benefit.name,
            contentOwner: returnData.contentOwner
          }, function (data1) {
            Versions.create({
              versionId: 0,
              contentId: data1.contentId,
              versiondata: Wraps.toJson(benefit)
            }, function (data2) {
              Structures.create({
                structureId: 0,
                versionId: data2.versionId,
                bookId: child.bookId,
                "status": 0,
                "approvalStatus": book.userInBook[0],
                contentId: data1.contentId,
                parent: child.structureId,
                order: data2.versionId
              }, function (data3) {

                Books.upsert({"bookId": child.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                History.create({
                  "structureId": data3.structureId,
                  "versionIdOld": 0,
                  "versionIdNew": data3.versionId,
                  "historyId": 0
                }, function (historyResponse) {
                  Events.create({
                    "eventId": 0,
                    "bookId": child.bookId,
                    "structureId": data3.structureId,
                    "contentId": data1.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Added",
                    "eventData": Wraps.toJson(eventData)
                  });
                });

                vm.dialogHide(true); //true = refresh parent
                WrapsBooks.updateBookById(book.bookId);
                //book.update(true);
                //vm.isLoading = false;
                Wraps.updateBookData = book.bookId;
              });
            });
          });

        }
      });
    }


    vm.addNewGlossary = function (returnData){
      vm.openChangeDialog().then(function(eventData) {
        if (eventData) {
          vm.isLoading = true;
          var glossary = {};
          glossary.glossary = returnData.glossary;
          Contents.create({
            contentId: 0,
            contentTypeId: 6,
            contentName: vm.thisParentName + " - " + returnData.glossary.term,
            contentOwner: returnData.contentOwner
          }, function (data1) {
            Versions.create({
              versionId: 0,
              contentId: data1.contentId,
              versiondata: Wraps.toJson(glossary)
            }, function (data2) {
              Structures.create({
                structureId: 0,
                versionId: data2.versionId,
                bookId: child.bookId,
                "status": 0,
                "approvalStatus": book.userInBook[0],
                contentId: data1.contentId,
                parent: child.structureId,
                order: data2.versionId
              }, function (data3) {

                Books.upsert({"bookId": child.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                History.create({
                  "structureId": data3.structureId,
                  "versionIdOld": 0,
                  "versionIdNew": data3.versionId,
                  "historyId": 0
                }, function (historyResponse) {
                  Events.create({
                    "eventId": 0,
                    "bookId": child.bookId,
                    "structureId": data3.structureId,
                    "contentId": data1.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Added",
                    "eventData": Wraps.toJson(eventData)
                  });
                });

                vm.dialogHide(true); //true = refresh parent
                WrapsBooks.updateBookById(book.bookId);
                //book.update(true);
                //vm.isLoading = false;
                Wraps.updateBookData = book.bookId;
              });
            });
          });
        }
      });

    }

    vm.addNewExclusion = function (returnData){
      vm.openChangeDialog().then(function(eventData) {
        if (eventData) {
          vm.isLoading = true;
          var exclusion = {};
          exclusion.exclusion = returnData.exclusion;
          Contents.create({
            contentId: 0,
            contentTypeId: 7,
            contentName: vm.thisParentName + " - " + returnData.exclusion.name,
            contentOwner: returnData.contentOwner
          }, function (data1) {
            Versions.create({
              versionId: 0,
              contentId: data1.contentId,
              versiondata: Wraps.toJson(exclusion)
            }, function (data2) {
              Structures.create({
                structureId: 0,
                versionId: data2.versionId,
                bookId: child.bookId,
                "status": 0,
                "approvalStatus": book.userInBook[0],
                contentId: data1.contentId,
                parent: child.structureId,
                order: data2.versionId
              }, function (data3) {

                Books.upsert({"bookId": child.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                History.create({
                  "structureId": data3.structureId,
                  "versionIdOld": 0,
                  "versionIdNew": data3.versionId,
                  "historyId": 0
                }, function (historyResponse) {
                  Events.create({
                    "eventId": 0,
                    "bookId": child.bookId,
                    "structureId": data3.structureId,
                    "contentId": data1.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Added",
                    "eventData": Wraps.toJson(eventData)
                  });
                });

                vm.dialogHide(true); //true = refresh parent
                WrapsBooks.updateBookById(book.bookId);
                //book.update(true);
                //vm.isLoading = false;
                Wraps.updateBookData = book.bookId;
              });
            });
          });
        }
      });

    }

    vm.addNewMedical = function (returnData){
      vm.openChangeDialog().then(function(eventData) {
        if (eventData) {
          vm.isLoading = true;
          var medical = {};
          medical.medical = returnData.medical;
          Contents.create({
            contentId: 0,
            contentTypeId: 8,
            contentName: vm.thisParentName + " - " + returnData.medical.term,
            contentOwner: returnData.contentOwner
          }, function (data1) {
            Versions.create({
              versionId: 0,
              contentId: data1.contentId,
              versiondata: Wraps.toJson(medical)
            }, function (data2) {
              Structures.create({
                structureId: 0,
                versionId: data2.versionId,
                bookId: child.bookId,
                "status": 0,
                "approvalStatus": book.userInBook[0],
                contentId: data1.contentId,
                parent: child.structureId,
                order: data2.versionId
              }, function (data3) {

                Books.upsert({"bookId": child.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                History.create({
                  "structureId": data3.structureId,
                  "versionIdOld": 0,
                  "versionIdNew": data3.versionId,
                  "historyId": 0
                }, function (historyResponse) {
                  Events.create({
                    "eventId": 0,
                    "bookId": child.bookId,
                    "structureId": data3.structureId,
                    "contentId": data1.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Added",
                    "eventData": Wraps.toJson(eventData)
                  });
                });

                vm.dialogHide(true); //true = refresh parent
                WrapsBooks.updateBookById(book.bookId);
                //book.update(true);
                //vm.isLoading = false;
                Wraps.updateBookData = book.bookId;
              });
            });
          });
        }
      });

    }


    vm.saveTableHeader = function (returnData){
      vm.openChangeDialog().then(function(eventData) {
        if (eventData) {
          vm.isLoading = true;
          var tableHeader = {};
          tableHeader.tableHeader = returnData.tableHeader;
          _.forEach(tableHeader.tableHeader, function (item, index) {
            item.index = index;
          });
          Contents.create({
            contentId: 0,
            contentTypeId: 11,
            contentName: vm.thisParentName + " - Table Header",
            contentOwner: returnData.contentOwner
          }, function (data1) {
            Versions.create({
              versionId: 0,
              contentId: data1.contentId,
              versiondata: Wraps.toJson(tableHeader)
            }, function (data2) {
              Structures.create({
                structureId: 0,
                versionId: data2.versionId,
                bookId: child.bookId,
                "status": 0,
                "approvalStatus": book.userInBook[0],
                contentId: data1.contentId,
                parent: child.structureId,
                order: data2.versionId
              }, function (data3) {

                Books.upsert({"bookId": child.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                History.create({
                  "structureId": data3.structureId,
                  "versionIdOld": 0,
                  "versionIdNew": data3.versionId,
                  "historyId": 0
                }, function (historyResponse) {
                  Events.create({
                    "eventId": 0,
                    "bookId": child.bookId,
                    "structureId": data3.structureId,
                    "contentId": data1.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Added",
                    "eventData": Wraps.toJson(eventData)
                  });
                });

                vm.dialogHide(true); //true = refresh parent
                WrapsBooks.updateBookById(book.bookId);
                //book.update(true);
                //vm.isLoading = false;
                Wraps.updateBookData = book.bookId;
              });
            });
          });
        }
      });
    }

    vm.saveTableRow = function (returnData){
      vm.openChangeDialog().then(function(eventData) {
        if (eventData) {
          vm.isLoading = true;
          var tableRow = {};
          tableRow.tableRow = [];
          //tableRow.tableRow = returnData.tableRow;
          _.forEach(returnData.tableRow, function (item, index) {
            item.index = index;

            tableRow.tableRow.push(item);
          });
          Contents.create({
            contentId: 0,
            contentTypeId: 12,
            contentName: "Table Header - Table Row",
            contentOwner: returnData.contentOwner
          }, function (data1) {
            Versions.create({
              versionId: 0,
              contentId: data1.contentId,
              versiondata: Wraps.toJson(tableRow)
            }, function (data2) {
              Structures.create({
                structureId: 0,
                versionId: data2.versionId,
                bookId: child.bookId,
                "status": 0,
                "approvalStatus": book.userInBook[0],
                contentId: data1.contentId,
                parent: child.structureId,
                order: data2.versionId
              }, function (data3) {

                Books.upsert({"bookId": child.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                History.create({
                  "structureId": data3.structureId,
                  "versionIdOld": 0,
                  "versionIdNew": data3.versionId,
                  "historyId": 0
                }, function (historyResponse) {
                  Events.create({
                    "eventId": 0,
                    "bookId": child.bookId,
                    "structureId": data3.structureId,
                    "contentId": data1.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Added",
                    "eventData": Wraps.toJson(eventData)
                  });
                });

                vm.dialogHide(true); //true = refresh parent
                WrapsBooks.updateBookById(book.bookId);
                //book.update(true);
                //vm.isLoading = false;
                Wraps.updateBookData = book.bookId;
              });
            });
          });
        }
      });
    }



    vm.saveBGHP = function (returnData, sectionType){

      vm.isLoading = true;
      var contentTypeId;
      var contentTypeName;
      var versiondata;

      switch(sectionType) {
        case 'section':
          contentTypeId = 14;
          contentTypeName = "BGHP Section";
          versiondata = returnData.contentData;
          break;
        case 'subSection':
          contentTypeId = 15;
          contentTypeName = "BGHP Sub Section";
          versiondata = returnData.contentData;
          break;
        case 'intro':
          contentTypeId = 16;
          contentTypeName = "BGHP Section Intro";
          versiondata = returnData.contentData;
          break;
        case 'benefit':
          contentTypeId = 17;
          contentTypeName = "BGHP Benefit";
          var benefit = {};
          if (_.size(returnData.benefit.options) === 0) {
            delete returnData.benefit.options;
          }
          benefit.benefit = returnData.benefit;
          versiondata = Wraps.toJson(benefit);
          break;
        case 'termSection':
          contentTypeId = 18;
          contentTypeName = "BGHP Term Section";
          versiondata = returnData.contentData;
          break;
        case 'term':
          contentTypeId = 19;
          contentTypeName = "BGHP Term";
          versiondata = returnData.contentData;
          break;
        case 'exclusion':
          contentTypeId = 20;
          contentTypeName = "BGHP Exclusion";
          var exclusion = {};
          if (_.size(returnData.exclusion.options) === 0) {
            delete returnData.exclusion.options;
          }
          exclusion.exclusion = returnData.exclusion;
          versiondata = Wraps.toJson(exclusion);
          break;
        case 'exclusionSection':
          contentTypeId = 21;
          contentTypeName = "BGHP Exclusion Section";
          versiondata = returnData.contentData;
          break;
      }


      vm.openChangeDialog().then(function(eventData) {
        if (eventData) {


          vm.isLoading = true;
          Contents.create({
            contentId: 0,
            contentTypeId: contentTypeId,
            contentName: vm.thisParentName + " - " + contentTypeName,
            contentOwner: returnData.contentOwner
          }, function (data1) {

            Versions.create({
              versionId: 0,
              contentId: data1.contentId,
              versiondata: versiondata
            }, function (data2) {

              Structures.create({
                structureId: 0,
                versionId: data2.versionId,
                bookId: child.bookId,
                "status": 0,
                "approvalStatus": book.userInBook[0],
                contentId: data1.contentId,
                parent: child.structureId,
                order: data2.versionId
              }, function (data3) {

                Books.upsert({"bookId": child.bookId, "lastUpdated": Date.now()});
                History.create({
                  "structureId": data3.structureId,
                  "versionIdOld": 0,
                  "versionIdNew": data3.versionId,
                  "historyId": 0
                }, function (historyResponse) {
                  Events.create({
                    "eventId": 0,
                    "bookId": child.bookId,
                    "structureId": data3.structureId,
                    "contentId": data1.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Added",
                    "eventData": Wraps.toJson(eventData)
                  });
                });
                vm.dialogHide(true); //true = refresh parent
                WrapsBooks.updateBookById(book.bookId);
                //book.update(true);
                //vm.isLoading = false;
                Wraps.updateBookData = book.bookId;
              });
            });
          });
        }
      });
    }




    vm.dialogHide = function(data) {
      $mdDialog.hide(data);
    };
    vm.dialogCancel = function(data) {
      $mdDialog.cancel(data);
    };



//TODO fix bug that allows user to delete all text and reset tag to P
//TODO  nice to have - save content as temp on switch
 vm.changeEditor = function(cId){

   var content1 = "<h3>Section text goes here</h3>";
   var content2 = "<h4>Subsection text goes here</h4>";
   var content3 = "<p>Copy text goes here</p>";

   switch(cId) {
     case 2:
       vm.contentData = content1;
       break;
     case 3:
       vm.contentData = content2;
       break;
     case 4:
       vm.contentData = content3;
       break;
     default:
       vm.contentData = "";
   }
 }


//TODO look at why the editor only works once you have clicked in editor
    // CKEditor options.
    vm.options = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      removeButtons: 'Subscript,Superscript',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools',bottom: 'ckfoot'},
      toolbar: [
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
        { name: 'paragraph', items: [  'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',] },
        { name: 'wrapsplugins', items: [  'Highlight','UnBold' ] },
        { name: 'format', items: [  'RemoveFormat' ] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };

    vm.options2 = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      enterMode: CKEDITOR.ENTER_BR,
      autoParagraph: false,
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools',bottom: 'ckfoot'},
      toolbar: [
        { name: 'wrapsplugins', items: [  'Highlight'] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };

    vm.options3 = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      removeButtons: 'Subscript,Superscript',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools2',bottom: 'ckfoot2'},
      toolbar: [
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
        { name: 'paragraph', items: [  'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',] },
        { name: 'wrapsplugins', items: [  'Highlight','UnBold' ] },
        { name: 'format', items: [  'RemoveFormat' ] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };


    vm.options4 = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      removeButtons: 'Subscript,Superscript',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools3',bottom: 'ckfoot3'},
      toolbar: [
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
        { name: 'paragraph', items: [  'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',] },
        { name: 'wrapsplugins', items: [  'Highlight','UnBold' ] },
        { name: 'format', items: [  'RemoveFormat' ] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };

    vm.options5 = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      removeButtons: 'Subscript,Superscript',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools5',bottom: 'ckfoot5'},
      toolbar: [
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
        { name: 'paragraph', items: [  'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',] },
        { name: 'wrapsplugins', items: [  'Highlight','UnBold' ] },
        { name: 'format', items: [  'RemoveFormat' ] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };
    vm.options6 = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      removeButtons: 'Subscript,Superscript',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools6',bottom: 'ckfoot6'},
      toolbar: [
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
        { name: 'paragraph', items: [  'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',] },
        { name: 'wrapsplugins', items: [  'Highlight','UnBold' ] },
        { name: 'format', items: [  'RemoveFormat' ] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };
    vm.options7 = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      enterMode: CKEDITOR.ENTER_BR,
      autoParagraph: false,
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools7',bottom: 'ckfoot7'},
      toolbar: [
        { name: 'wrapsplugins', items: [  'Highlight'] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };


    // vm.updateOption = function(benfit,product){
    //   if(!benfit.option[product]){
    //     delete benfit.options[product];
    //     //benfit[product] = vm.tempData.benefit[product];
    //   }
    // }
    //
    // vm.updateOptions = function(benfit,product){
    //   if(benfit.options[product]==="Linked"){
    //     benfit[product] = "";
    //   }else if(benfit.options[product]==="Not covered"){
    //
    //     benfit[product] = "Not covered";
    //   }else if(benfit.options[product]==="Paid in full"){
    //
    //     benfit[product] = "Paid in full";
    //   }
    // }
    // vm.updateLinked = function(benfit,product){
    //   if(!benfit.options[product]){
    //     delete benfit.options[product];
    //     //benfit[product] = vm.tempData.benefit[product];
    //   }else{
    //     benfit[product] = "";
    //   }
    // }


    vm.disabledProducts = {};
    vm.updateOption = function(benfit,product){

      if(benfit.option) {
        if (!benfit.option[product]) {
          delete benfit.options[product];
          //benfit[product] = vm.tempData.benefit[product];
          vm.disabledProducts[product] = false;
        }
      }
    }

    vm.updateOptions = function(benfit,product){
      if(benfit.options[product]==="Linked"){
        benfit[product] = "";
        vm.disabledProducts[product] = true;
      }else if(benfit.options[product]==="Not covered"){

        benfit[product] = "Not covered";
        vm.disabledProducts[product] = true;
      }else if(benfit.options[product]==="Paid in full"){

        benfit[product] = "Paid in full";
        vm.disabledProducts[product] = true;
      }else if(benfit.options[product]==="Optional") {
        //benfit[product] = vm.tempData.benefit[product];
        vm.disabledProducts[product] = false;
      }else if(benfit.options[product]==="Show in summary") {
        //benfit[product] = vm.tempData.benefit[product];
        vm.disabledProducts[product] = false;
      }
    }
    vm.updateLinked = function(benfit,product){
      if(!benfit.options[product]){
        delete benfit.options[product];
        //benfit[product] = vm.tempData.benefit[product];
      }else{
        benfit[product] = "";
      }
    }
    
    
    
    
    
    


    vm.selectedContent = [];
    vm.setselectedContent = function (chapter){
      if(chapter.selected==true){
        vm.selectedContent.push(chapter);
      }else{
        _.pullAt(vm.selectedContent, _.findKey(vm.selectedContent, 'versionId', chapter.versionId));
      }
    }




    //for autocomplete
    vm.books = Books.find({ filter: { where: {"and":[{ bookId: {neq: book.bookId}},{"docTypeId":book.docTypeId}]},fields: {bookId: true, bookCode: true, bookName:true}}  });

    vm.querySearch = function (query) {
      var results = query ? vm.books.filter( createFilterFor(query) ) : vm.books,
        deferred;
      return results;
    }

    function createFilterFor(query) {
      return function filterFn(item) {
        return (item.bookCode.toLowerCase().indexOf(query.toLowerCase()) >= 0);
      };
    }


  }
}());
