(function () {
  'use strict';

  angular
    .module('library.workflowdialog')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('workflowdialog', {
        url: '/workflowdialog',
        templateUrl: 'library/workflowdialog/workflowdialog.tpl.html',
        controller: 'WorkflowdialogCtrl',
        controllerAs: 'workflowdialog'
      });
  }
}());
