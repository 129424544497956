(function () {
  'use strict';

  angular
    .module('library.metachangedialog')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('metachangedialog', {
        url: '/metachangedialog',
        templateUrl: 'library/metachangedialog/metachangedialog.tpl.html',
        controller: 'MetachangedialogCtrl',
        controllerAs: 'metachangedialog'
      });
  }
}());
