(function () {
  'use strict';

  /* @ngdoc object
   * @name library.notifications
   * @description
   *
   */
  angular
    .module('library.notifications', [
      'ui.router'
    ]);
}());
