(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:urlsToLinks
   *
   * @description Turns urls into links - to be used within ng-bind-html
   *
   * @param {string} the html string to filter
   * @returns {string} The filtered html string
   *
   */
  angular
    .module('library')
    .filter('urlsToLinks', urlsToLinks);

  function urlsToLinks() {
    return function (str) {
        str = str.replace(/(&nbsp;)/g,' ');
        var regex = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        return str.replace(regex, '<a href="$1" target="_blank" ng-click="$event.stopPropagation()">$1</a>');
    };
  }
}());
