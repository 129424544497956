angular
    .module('library')
    .component('tabWorkflow', {
        templateUrl: 'library/library-components/right-menu/tab-workflow/tab-workflow.tpl.html',
        controller: tabWorkflowCtrl,
        controllerAs:'tabWorkflow',
        bindings:{
            book:'<',
            visible:'<',
            disabled:'<'
        }

    });
tabWorkflowCtrl.$inject = ['$scope','Wraps','dragularService'];
function tabWorkflowCtrl($scope,Wraps,dragularService){
    var vm = this;

    vm.validDoc = true;
    vm.validBrief = true;
    vm.validArtwork = true;
    vm.validFinal = true;

    //console.log(dragularService);
    // dragularService('#docWorkflow, #briefWorkflow, #artworkWorkflow, #finalWorkflow', {
    //     moves: function (el, container, handle) {
    //         return handle.className === 'statbar-mini';
    //     }
    // });
    vm.options = {
        moves: function (el, container, handle) {
            return !vm.disabled && !(angular.element(el).hasClass('fixed')) && (angular.element(handle).hasClass('drag-handle'));
        },
        accepts: function( el, target, source, sibling, sourceModel){
            //console.log(target, source, sourceModel, sibling, sibling === null, !angular.element(sibling).hasClass('fixed'));
            return target === source && (sibling === null || !angular.element(sibling).hasClass('fixed'));
        },
        revertOnSpill: true,
        lockY:true
    }
    vm.docOptions = angular.copy(vm.options);
    vm.briefOptions = angular.copy(vm.options);
    vm.artworkOptions = angular.copy(vm.options);
    vm.finalOptions = angular.copy(vm.options);


    $scope.$watch('tabWorkflow.visible',function() {
        if (vm.book && vm.book.workflowArtwork && _.findIndex(vm.book.workflowArtwork, ['value',true]) >=0 ) {
            vm.enableWorkflowArtwork = true;
        } else {
            vm.enableWorkflowArtwork = false;
        }
    });

    // if any of the values are false the workflow is invalid
    $scope.$watchGroup(['tabWorkflow.validDoc', 'tabWorkflow.validBrief', 'tabWorkflow.validArtwork', 'tabWorkflow.validFinal'], function(newValues, oldValues, scope) {
        if(vm.book){
            vm.book.isWorkflowInvalid = _.findIndex(newValues, function(item) { return item === false}) >= 0;
        }
    },true);


    vm.checkArtwork = function () {
        _.map(vm.book.workflowArtwork, function(i) {
            i.value = vm.enableWorkflowArtwork;
            return i;
        });
    }

    vm.addWorkflow = function (roleId, content) {
        var arr = [];
        var obj = { content: content, role: roleId, team: '', user: '', value: true }
        switch(content){
            case 'doc':
                arr = vm.book.workflowDoc;
                break;
            case 'brief':
                arr = vm.book.workflowBrief;
                break;
            case 'artwork':
                arr = vm.book.workflowArtwork;
                break;
            case 'final':
                arr = vm.book.workflowFinal;
                break;

        }
        arr.push(obj);
    }

    vm.removeWorkflow = function (content, $index) {
        var arr = [];
        switch(content){
            case 'doc':
                arr = vm.book.workflowDoc;
                break;
            case 'brief':
                arr = vm.book.workflowBrief;
                break;
            case 'artwork':
                arr = vm.book.workflowArtwork;
                break;
            case 'final':
                arr = vm.book.workflowFinal;
                break;
        }
        arr.splice($index,1);

    }

    vm.checkValidWorkflow = function(){
        vm.isValid = vm.validDoc && vm.validBrief && vm.validArtwork && vm.validFinal;
    }

    vm.labels = {
        artwork:'Artwork Draft Workflow',
        brief: 'Document or Brief Workflow',
        final: 'Publishing Workflow',
        doc: 'Document Workflow'

    };
}