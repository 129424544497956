<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">


    <div class="md-whiteframe-z1 default-box" style="" >
        <md-content layout-padding >
            <h2  class="" wraps-tooltip="profile-title">Profile</h2>
    <table width="100%"> <!--md-row-select="selected"-->
        <tr>
            <td >


                <form autocomplete="off" name="usersForm">
                    <div style="padding:0px 20px; background-color: #eee;  text-align: left; cursor: pointer;">
                        <h3 flex wraps-tooltip="profile-name">{{ profile.userForm.firstName }} {{ profile.userForm.lastName }}</h3>
                        <span flex></span>
                    </div>

                    <div layout="row"  layout-wrap>
                        <md-input-container flex wraps-tooltip="profile-form-firstname">
                            <label>First Name</label>
                            <input ng-model="profile.userForm.firstName">
                        </md-input-container>
                        <md-input-container flex wraps-tooltip="profile-form-lastname">
                            <label>Last Name</label>
                            <input ng-model="profile.userForm.lastName">
                        </md-input-container>
                        <md-input-container flex wraps-tooltip="profile-form-job">
                            <label>Job Title</label>
                            <input ng-model="profile.userForm.jobTitle">
                        </md-input-container>
                    </div>


                    <div layout="row">
                        <md-input-container flex wraps-tooltip="profile-form-email">
                            <label>Email address</label>

                            <input ng-model="profile.userForm.email" >
                        </md-input-container>
                        <md-input-container flex wraps-tooltip="profile-form-contact-email">
                            <label>Contact Email</label>
                            <input ng-model="profile.userForm.contactEmail">
                        </md-input-container>
                        <md-input-container  flex wraps-tooltip="profile-form-tel">
                            <label>Phone Number</label>
                            <input ng-model="profile.userForm.tel">
                        </md-input-container>

                    </div>



                    <div layout="row">

                        <!--<md-input-container flex wraps-tooltip="profile-form-team">-->
                            <!--<label>Team</label>-->
                            <!--<md-select ng-model="$storage.userForm.teamId" placeholder="Team">-->
                                <!--<md-option value="{{team.teamId}}" ng-repeat="team in profile.teams" >{{team.Departments.departmentName}} - {{team.teamName}}</md-option>-->
                            <!--</md-select>-->

                        <!--</md-input-container>-->

                        <md-input-container flex="33" wraps-tooltip="profile-form-location">
                            <label>Location</label>
                            <md-select ng-model="profile.userForm.locationId" placeholder="Location">
                                <md-option value="{{location.locationId}}" ng-repeat="location in profile.locations" >{{location.locationName}}</md-option>
                            </md-select>

                        </md-input-container>

                        <!--<md-input-container flex wraps-tooltip="profile-form-role">
                            <label>User Roles</label>
                            <md-select ng-model="$storage.userForm.roleId" placeholder="Role">
                                <md-option value="{{role.id}}" ng-repeat="role in profile.roles">{{role.name}}</md-option>
                            </md-select>

                        </md-input-container>-->



                            <md-input-container flex="33" wraps-tooltip="profile-form-change-password">
                                <md-checkbox class="" ng-model="profile.temp.changepassword" aria-label="Change Password" style="margin: 0px;">
                                    Change Password
                                </md-checkbox>
                            </md-input-container>

                            <md-input-container flex="33" ng-if="profile.temp.changepassword" wraps-tooltip="profile-form-password">
                                <label>Password</label>
                                <input ng-model="profile.userForm.password" type="password" required ng-minlength="8" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).+" name="password">
                                <div ng-show="usersForm.password.$touched" ng-messages="usersForm.password.$error" role="alert" class="t-sm">
                                    <div ng-message="minlength" class="error">Your password must be at least 8 characters</div>
                                    <div ng-message="pattern" class="error">The password must contain a combination of uppercase, lowercase, numeric characters and at least one of the following: !@#$%^&amp;*.</div>
                                    <div ng-message="validation" class="error">This email address has already been registered</div>
                                </div>

                            </md-input-container>

                    </div>



                    <!--<div layout="row">
                        <md-input-container flex="33">
                            <label>Change Password</label>
                            <input ng-model="$storage.userForm.password">
                        </md-input-container>
                    </div>-->


                    <div layout="row">
                        <md-input-container flex="33">
                            <label flex="33"><div flex="33">Profile Picture</div></label>
                        </md-input-container>

                    </div>


                    <div layout="row">
                        <div wraps-tooltip="profile-form-avatar">
                            <md-input-container ng-if="profile.userForm.profilePic != '' && profile.userForm.profilePic != null" class="profilePicContainer">
                                <!--<div class="profilePicDelete" ng-click="profile.deleteProfilePic($storage.userForm);">
                                    <ng-md-icon icon="delete_forever" size="30"></ng-md-icon>
                                </div>-->
                                <img ng-src="{{profile.apiUrl}}/containers/getthumbnail/{{profile.userForm.profilePic}}" class="user-avatar large" alt="User Avatar"/>
                            </md-input-container>
							 <md-input-container ng-if="profile.userForm.profilePic == '' || profile.userForm.profilePic == null">
                            	<div class="user-avatar initials large {{profile.userForm.lastName|lowercase|limitTo:1}}">{{profile.userForm.firstName|uppercase|limitTo:1}}{{profile.userForm.lastName|uppercase|limitTo:1}}</div>
							</md-input-container>
                        </div>

                        <div flex layout="row" layout-wrap>
							<ul class="file-ui" ng-if="profile.loadedavatar.filename != '' && profile.loadedavatar.filename != null && profile.userForm.profilePic != '' && profile.userForm.profilePic != null && !profile.profilePic">
                                <li style="font:smaller" class="file-li animate-if" wraps-tooltip="profile-form-filename">{{profile.loadedavatar.filename}}
                                    <div class="file-prog" ng-mouseenter="profile.loadedavatar.hovered = true" ng-mouseleave="profile.loadedavatar.hovered = false">
                                        <ng-md-icon class="animate-if file-complete" icon="check_circle" size="30"  aria-label="Profile Picture"></ng-md-icon>
										<ng-md-icon class="file-remove" ng-if="profile.loadedavatar.hovered" icon="cancel" size="30" aria-label="Remove file" ng-click="profile.deleteProfilePic($storage.userForm);" wraps-tooltip="profile-form-avatar-delete"></ng-md-icon>
                                    </div>
                                </li>
                            </ul>
                            <ul class="file-ui">
                                <li ng-repeat="f in profile.profilePic.filesList" style="font:smaller" class="file-li animate-if" wraps-tooltip="profile-form-filename">{{f.fileName}}
                                    <div class="file-prog" ng-mouseenter="f.hovered = true" ng-mouseleave="f.hovered = false">
                                        <md-progress-circular class="animate-if" ng-if="f.determinateValue < 100" md-mode="determinate" value="{{f.determinateValue}}" md-diameter="30"></md-progress-circular>
                                        <span class="animate-ifx" ng-if="f.determinateValue < 100" >{{f.determinateValue}}%</span>
                                        <ng-md-icon class="animate-if file-complete" ng-if="f.determinateValue === 100"  icon="check_circle" size="30"  aria-label="Upload complete"></ng-md-icon>
										<ng-md-icon class="file-remove" ng-if="f.hovered" icon="cancel" size="30" aria-label="Remove file" ng-click="profile.removeFile(profile.profilePic.filesList, $index)" wraps-tooltip="profile-form-avatar-delete"></ng-md-icon>
                                    </div>
                                </li>
                            </ul>
							
                        </div>
                        <md-input-container wraps-tooltip="profile-form-uploader">
                            <label>Profile picture</label>
                            <div ngf-drop ngf-select ng-model="profile.profilePic" class="drop-box"
                                 ngf-drag-over-class="'dragover'" ngf-multiple="false" ngf-allow-dir="true"
                                 accept="image/*"
                                 ngf-pattern="'image/*'"><ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop profile picture here or<br /><strong style="text-decoration: underline;">Click to upload</strong><br />a new one</div>
                            <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>
                        </md-input-container>
                    </div>
                    <br /><br />
                    <md-button type="button" class="md-raised md-primary save-button" ng-disabled="usersForm.$invalid || usersForm.$pristine || profile.isLoading || profile.compareJson(profile.userForm,user)"  ng-click="profile.saveRow(profile.userForm);" wraps-tooltip="profile-form-save">Save</md-button>

                    <md-progress-circular  style=" display:inline;  margin-left: 10px; top:-4px;" class="animate-if"  md-diameter="30" md-mode="indeterminate" ng-if="profile.isLoading"></md-progress-circular>
					<p ng-if="profile.thumbgen" wraps-tooltip="profile-form-generating">Generating thumbnail...</p>
					<p ng-if="usersForm.$invalid" wraps-tooltip="profile-form-error-prevent">There are errors with the form</p>
					<p ng-if="profile.saved == 'success' && usersForm.$pristine" wraps-tooltip="profile-form-save-success">Saved changes successfully</p>
					<p ng-if="profile.saved == 'error' && usersForm.$pristine" wraps-tooltip="profile-form-error-fail">Unable to save changes</p>
                </form>

            </td>
        </tr>
        </tbody>
    </table>
        </md-content>
    </div>
</md-content>