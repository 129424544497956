(function () {
  'use strict';

  angular
    .module('library.approvedialog')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('approvedialog', {
        url: '/approvedialog',
        templateUrl: 'library/approvedialog/approvedialog.tpl.html',
        controller: 'ApprovedialogCtrl',
        controllerAs: 'approvedialog'
      });
  }
}());
