(function () {
  'use strict';

  angular
    .module('healthcheck')
    .config(config);

  function config($stateProvider) {
    $stateProvider
        .state('healthcheck', {
            url: '/healthcheck/:doc',
            params: {
                doc: {
                    dynamic: true,
                    value: null,
                    squash: true
                }
            },
            views: {
                tooltip: {
                    templateUrl: 'tooltip/tooltip.tpl.html',
                    controller: 'TooltipCtrl',
                    controllerAs: 'tooltip'
                },
                navigation: {
                    templateUrl: 'navigation/navigation.tpl.html',
                    controller: 'NavigationCtrl',
                    controllerAs: 'navigation'
                },
                main: {
                    templateUrl: 'healthcheck/healthcheck.tpl.html',
                    controller: 'HealthcheckCtrl',
                    controllerAs: 'healthcheck'
                },
                footer: {
                    templateUrl: 'footer/footer.tpl.html',
                    controller: 'FooterCtrl',
                    controllerAs: 'footer'
                }
            }
        });
  }
}());
