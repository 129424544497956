<md-content  layout="row" layout-wrap  layout-align="center start" class="login-box">

    <div class="md-whiteframe-z1 " >
        <md-content layout-padding >
            <h2   class="">Reset Password</h2>
            <form name="loginForm" novalidate   ng-submit="reset.submitReset()" autocomplete="off">

                <div  layout="row">
                    <md-input-container flex  >
                        <label>Password</label>
                        <input ng-model="reset.credentials.password" type="password"  required name="password"  minlength="5" ng-focus="reset.passwordFocus = true">
                        <div ng-show="reset.passwordFocus"  ng-messages="reset.password.$error">
                            <div ng-message="required">Please enter a valid password</div>
                            <div ng-message="minlength, maxlength">Your password must at least 5 characters</div>
                        </div>
                    </md-input-container>
                </div>
                <div  layout="row" class="relative">
                    <md-input-container flex >
                        <label>Confirm Password</label>
                        <input ng-model="reset.credentials.password2" type="password"  required name="password2"  minlength="5" ng-focus="reset.password2Focus = true">
                        <div ng-show="reset.password2Focus"  ng-messages="reset.password2.$error">
                            <div ng-message="required">Please enter a valid password</div>
                            <div ng-message="minlength, maxlength">Your password must at least 5 characters</div>
                        </div>
                    </md-input-container>
                    <p ng-if="reset.error" class="animate-if error">{{reset.error}}</p>
                </div>
                <section layout="row" layout-sm="column" layout-align="end center" class="relative">
                    <md-progress-circular style="position: absolute; top: 10px; left: 0px; "  md-diameter="100" md-mode="indeterminate" ng-if="reset.isLoading" class="animate-if"></md-progress-circular>
                    <div><md-button type="submit" name="loginSubmit" class="md-accent md-raised button-padding" >Reset Password</md-button></div>
                </section>
            </form>

        </md-content>

    </div>

</md-content>