<md-content>
    <div>
        <md-data-table-container class="table-outer">
            <form name="searchForm">
            <table md-data-table  > <!--md-row-select="selected"-->
                <!--search bar panels-->
                <thead>
                <tr>
                    <td colspan="99" style="padding-left: 20px; font-size: 12px; color:#999;">
                        <div layout="row" layout-wrap>
                            <!--applied filters and clear-filter button-->
                            <div flex="75" ng-if="tabList.stringifySearch($storage.pagination.filterLibrary) !== ''">
                                <h4><span wraps-tooltip="library-search-applied">Applied searches: {{tabList.stringifySearch($storage.pagination.filterLibrary)}}</span>&nbsp;
                                    <md-button aria-label="Clear search" class="md-gray" style="margin-left:20px;" ng-click="$storage.pagination.filterLibrary={}; tabList.library.searchBooksText=''" wraps-tooltip="library-search-clear">
                                        <ng-md-icon icon="backspace" style="fill: #999; position: relative; left:4px;" size="18"></ng-md-icon>clear search
                                    </md-button>
                                </h4>
                            </div>
                            <div flex layout="row" layout-align="end center">
                                <!--download filtered library csv button-->
                                <md-button aria-label="Download CSV" class="md-icon-button" ng-csv="tabList.library.downloadCSV()"  lazy-load="true" filename="library-search-report.csv" add-bom="true" charset="utf-8" csv-label="true">
                                    <ng-md-icon icon="file_download" style="fill: grey; " size="24" wraps-tooltip="library-search-download"></ng-md-icon>
                                </md-button>
                                <!--save search button-->
                                <md-button aria-label="Save search" class="md-icon-button" ng-click="tabList.library.openLeftMenu(1);$mdTabsCtrl.select(1);$event.stopPropagation()" wraps-tooltip="library-search-save">
                                    <ng-md-icon icon="save" style="fill: grey; " size="24"></ng-md-icon>
                                </md-button>

                                <!--reload library button-->
                                <md-button aria-label="Refresh books" class="md-icon-button" ng-click="tabList.library.updateBooks()" wraps-tooltip="library-refresh-icon">
                                    <ng-md-icon icon="refresh" style="fill: grey;" size="24"></ng-md-icon>
                                </md-button>
                                <!--open library settings button-->
                                <md-button aria-label="Library settings" class="md-icon-button"  ng-click="tabList.library.openLeftMenu()"  wraps-tooltip="library-settings-icon">
                                    <ng-md-icon icon="settings" style="fill: grey;" size="24"></ng-md-icon>
                                </md-button>
                                <!--open searchbar button--><!--
                                    <md-button aria-label="Open search" class="md-icon-button" style="position: relative; top: -15px; right:-5px;"  ng-click="tabList.library.searchLibrary=true"  wraps-tooltip="library-search-icon">
                                        <ng-md-icon icon="search" style="fill: grey;" size="24"></ng-md-icon>
                                    </md-button>


									<!--close searchbar button--><!--
                                    <md-button aria-label="Close search" class="md-icon-button" style="position: relative; right:0px;" ng-click="tabList.library.searchLibrary=false; tabList.pagination.filterLibrary=''; tabList.library.searchBooksText=''" wraps-tooltip="library-search-close">
                                        <ng-md-icon icon="close" style="fill: grey; " size="24"></ng-md-icon>
                                    </md-button>-->
                            </div>
                        </div>




                    </td>
                </tr>
                <!--for users on team 10 only - can filter to a random XX% of the total library-->
                <!--<tr ng-if="$storage.user.teamId === 10">
                    <td colspan="99" >
                        <md-input-container style="width: 200px;margin: 18px 0 20px 20px;" >
                            <label>Search percentage %</label>
                            <input type="number" ng-model="tabList.library.searchPercentage" style="width: 80%;" aria-label="Search percentage %"  md-placeholder="Search percentage %">
                        </md-input-container>

                        <md-button  type="button" class="md-grey" style="background: #9c9392; position: relative; left:-20px;"  ng-click="tabList.library.showRandom(tabList.library.searchPercentage)">Run search<ng-md-icon icon="star" style="fill: white; position: relative; top:4px; padding-left: 4px; " size="20"  aria-label="Run search"></ng-md-icon></md-button>

                    </td>
                </tr>
                <!-- full text search-->
                <tr>
                    <td colspan="99">
                        <div layout="row" layout-align="space-between center">
                            <md-input-container style="min-width:50%;width:480px;max-width:90%;margin: 18px 0 0px 18px;" wraps-tooltip="library-search-full-text" >
                                <label>Full text search</label>
                                <input ng-model="tabList.temp.filterLibrary.textSearch" minlength="4" name="textSearch" id="textSearch" aria-label="Search book content" md-placeholder="Full text search" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                                <div ng-messages="searchForm.textSearch.$error">
                                    <div ng-message="minlength">Keyword(s) must be 4 characters or longer</div>
                                </div>
                            </md-input-container>
                            <md-button aria-label="search" class="md-fab md-mini md-accent" ng-animate ng-disabled="searchForm.$pristine || searchForm.$invalid" ng-click="tabList.updateFilter();"  wraps-tooltip="library-search-icon">
                                <ng-md-icon ng-animate icon="search" size="24"></ng-md-icon>
                            </md-button>
                        </div>

                        <!--<md-input-container style="width: 280px;"><input ng-model="tabList.pagination.filterLibrary.searchBook" style="width: 80%;" aria-label="Filter book name"></md-input-container>-->
                    </td>
                </tr>

                <tr>


                    <!--all possible column-specific search inputs--->
                    <td wraps-tooltip="library-search-filters-id">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.bookSearchId" name="bookSearchId" id="bookSearchId"
                                                   aria-label="Filter book id" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.code" wraps-tooltip="library-search-filters-code">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.bookCode" name="bookCode"
                                                   aria-label="Filter book code" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.psiCode" wraps-tooltip="library-search-filters-psi-code">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.psiCode" name="psiCode"
                                                   aria-label="Filter psi code" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.name" wraps-tooltip="library-search-filters-name">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.bookName" name="bookName"
                                                    aria-label="Filter book name" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.insurer" wraps-tooltip="library-search-filters-insurer">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.insurerName" name="insurerName"
                                                   aria-label="Filter insurer type" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.insurerDetails" wraps-tooltip="library-search-filters-insurer">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.insurerDetails" name="insurerDetails"
                                                   aria-label="Filter insurer type" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.product" wraps-tooltip="library-search-filters-product">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.productName" name="productName"
                                                   aria-label="Filter product name" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>

                    <td ng-if="$storage.showFilters.audience" wraps-tooltip="library-search-filters-audience">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.audienceName" name="audienceName"
                                                   aria-label="Filter audience name" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.region" wraps-tooltip="library-search-filters-region">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.regionName" name="regionName"
                                                   aria-label="Filter region name" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.productType" wraps-tooltip="library-search-filters-product-type">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.productTypeName" name="productTypeName"
                                                   aria-label="Filter Customer types" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>

                    <td ng-if="$storage.showFilters.docTypeCatName" wraps-tooltip="library-search-filters-doc-type-cat">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.DocTypes.docTypeCatName" name="docTypeCatName"
                                                    aria-label="Master Doc Type" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.doc" wraps-tooltip="library-search-filters-doc">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.docTypeName" name="docTypeName"
                                                    aria-label="Filter doc type" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.finProm" wraps-tooltip="library-search-filters-fin-prom">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.finProm" name="finProm"
                                                    aria-label="Filter fin prom" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.clients" wraps-tooltip="library-search-filters-clients">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.clientName" name="clientName"
                                                    aria-label="Filter clients" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.Owner.fullName" wraps-tooltip="library-search-filters-full-name">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.ownerFullName" name="ownerFullName"
                                                    aria-label="Filter owner" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.Owner.teamName" wraps-tooltip="library-search-filters-team-name">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.ownerTeamName" name="ownerTeamName"
                                                    aria-label="Filter owner team" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.division" wraps-tooltip="library-search-filters-division">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.division" name="division"
                                                   aria-label="Filter division" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.workflowStatus"
                        wraps-tooltip="library-search-filters-workflow-status">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.workflowStatus" name="workflowStatus"
                                                   aria-label="Filter workflow status" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.documentStatus"
                        wraps-tooltip="library-search-filters-document-status">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.documentStatus" name="documentStatus"
                                                   aria-label="Filter document status" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.projectName" wraps-tooltip="library-search-filters-document-status">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.projectName" name="projectName"
                                                    aria-label="Project Name" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>

                    <td ng-if="$storage.showFilters.date" wraps-tooltip="library-search-filters-date">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.createdDisplayDate" name="createdDisplayDate"
                                                    aria-label="Filter date" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.lastUpdated" wraps-tooltip="library-search-filters-last-updated">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.lastUpdatedDisplayDate" name="lastUpdatedDisplayDate"
                                                    aria-label="Filter date" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.activeDate" wraps-tooltip="library-search-filters-active-date">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.activeDateDisplayDate" name="activeDateDisplayDate"
                                                    aria-label="Filter date" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.liveDate" wraps-tooltip="library-search-filters-live-date">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.liveDateDisplayDate" name="liveDateDisplayDate"
                                                    aria-label="Filter date" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.reviewDate" wraps-tooltip="library-search-filters-review-date">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.reviewDateDisplayDate" name="reviewDateDisplayDate"
                                                    aria-label="Filter date" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.expiryDate" wraps-tooltip="library-search-filters-expiry-date">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.expiryDateDisplayDate" name="expiryDateDisplayDate"
                                                    aria-label="Filter date" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td ng-if="$storage.showFilters.inWorkflow" wraps-tooltip="library-search-filters-in-workflow">
                        <md-input-container><input ng-model="tabList.temp.filterLibrary.inWorkflow" name="inWorkflow"
                                                   aria-label="In Workflow" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : ''">
                        </md-input-container>
                    </td>
                    <td>
                        <md-input-container>
                            <label>Action</label>
                            <md-select multiple ng-model="tabList.temp.filterLibrary.actionStatus" name="actionStatus" aria-label="Action Status" md-selected-html="tabList.getActionText()" class="action-filter-select">
                                <md-option ng-value="1">Black Asset <ng-md-icon icon="{{tabList.library.icons[0]}}" md-colors="{fill:tabList.library.colors[0]}" size="20"  class="animate-if" wraps-tooltip="action-icon"></ng-md-icon>
                                </md-option>
                                <md-option ng-value="2">Personal Action <ng-md-icon icon="{{tabList.library.icons[1]}}" md-colors="{fill:tabList.library.colors[1]}" size="20"  class="animate-if" wraps-tooltip="action-icon"></ng-md-icon></md-option>
                                <md-option ng-value="3">Team Action <ng-md-icon icon="{{tabList.library.icons[2]}}" md-colors="{fill:tabList.library.colors[2]}" size="20"  class="animate-if" wraps-tooltip="action-icon"></ng-md-icon></md-option>
                            </md-select>
                            <div class="md-errors-spacer"></div>
                        </md-input-container>
                    </td>

                </tr>
                </thead>
                <thead>
                <tr>
                    <!-- all possible table headings-->
                    <th ng-click="tabList.sortBy('bookId')">
                        <span wraps-tooltip="library-heading-id">ID</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'bookId', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.code" ng-click="tabList.sortBy('bookCode')">
                        <span wraps-tooltip="library-heading-code">Code</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'bookCode', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.psiCode" ng-click="tabList.sortBy('psiCode')">
                        <span wraps-tooltip="library-heading-psi-code">PSI Code</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                                    ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'psiCode', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.name" ng-click="tabList.sortBy('bookName')">
                        <span wraps-tooltip="library-heading-name">Name</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'bookName', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.insurer" ng-click="tabList.sortBy('insurerName')">
                        <span wraps-tooltip="library-heading-insurer">Insured By</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'insurerName', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.insurerDetails" ng-click="tabList.sortBy('insurerDetails')">
                        <span wraps-tooltip="library-heading-insurer">Insurer Details</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                                    ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'insurerDetails', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.product" ng-click="tabList.sortBy('productName')">
                        <span wraps-tooltip="library-heading-product">Product</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                          ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'productName', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>

                    <th ng-if="$storage.showFilters.audience" ng-click="tabList.sortBy('audienceName')">
                        <span wraps-tooltip="library-heading-audience">Audiences</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'audienceName', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.region" ng-click="tabList.sortBy('regionName')">
                        <span wraps-tooltip="library-heading-region">Regions</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'regionName', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.productType" ng-click="tabList.sortBy('productTypeName')">
                        <span wraps-tooltip="library-heading-product-type">Customer Types</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'productTypeName', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>

                    <th ng-if="$storage.showFilters.docTypeCatName" ng-click="tabList.sortBy('docTypeCatName')">
                        <span wraps-tooltip="library-heading-doc-type-cat">Master Doc Type</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'docTypeCatName', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.doc" ng-click="tabList.sortBy('docTypeName')">
                        <span wraps-tooltip="library-heading-doc">Doc Type</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'docTypeName', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.finProm" ng-click="tabList.sortBy('finProm')">
                        <span wraps-tooltip="library-heading-fin-prom">Fin Prom</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'finProm', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.clients" ng-click="tabList.sortBy('clients')">
                        <span wraps-tooltip="library-heading-clients">Client</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'clients', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.Owner.fullName" ng-click="tabList.sortBy('ownerFullName')">
                        <span wraps-tooltip="library-heading-full-name">Owner</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'ownerFullName', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.Owner.teamName" ng-click="tabList.sortBy('ownerTeamName')">
                        <span wraps-tooltip="library-heading-team-name">Owner Team</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                          ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'ownerTeamName', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.division" ng-click="tabList.sortBy('division')">
                        <span wraps-tooltip="library-heading-workflow-status">Division</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                                    ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'division', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.workflowStatus" ng-click="tabList.sortBy('workflowStatus')">
                        <span wraps-tooltip="library-heading-workflow-status">Workflow Status</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'workflowStatus', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.documentStatus" ng-click="tabList.sortBy('documentStatus')">
                        <span wraps-tooltip="library-heading-document-status">Document Status</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'documentStatus', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.projectName" ng-click="tabList.sortBy('projectName')">
                        <span wraps-tooltip="library-heading-document-status">Project Name</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'projectName', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>

                    <th ng-if="$storage.showFilters.date" ng-click="tabList.sortBy('created')">
                        <span wraps-tooltip="library-heading-date">Created</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'created', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.lastUpdated" ng-click="tabList.sortBy('lastUpdated')">
                        <span wraps-tooltip="library-heading-last-updated">Last Updated</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'lastUpdated', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.activeDate" ng-click="tabList.sortBy('activeDate')">
                        <span wraps-tooltip="library-heading-active-date">Active Date</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'activeDate', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.liveDate" ng-click="tabList.sortBy('liveDate')">
                        <span wraps-tooltip="library-heading-live-date">Live Date</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'liveDate', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.reviewDate" ng-click="tabList.sortBy('reviewDate')">
                        <span wraps-tooltip="library-heading-review-date">Review Date</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'reviewDate', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.expiryDate" ng-click="tabList.sortBy('expiryDate')">
                        <span wraps-tooltip="library-heading-expiry-date">Expiry Date</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'expiryDate', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-if="$storage.showFilters.inWorkflow" ng-click="tabList.sortBy('inWorkflow')">
                        <span wraps-tooltip="library-heading-in-workflow">In Workflow</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'inWorkflow', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>
                    <th ng-click="tabList.sortBy('actionStatus')">
                        <span wraps-tooltip="library-heading-action">Action</span>
                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"
                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === 'actionStatus', reverse: tabList.pagination.filterLibrary.sortDirection == 'desc'}"></ng-md-icon>
                    </th>

                </tr>
                </thead>
                <tbody>
                <!-- book listing data--->
                <tr ng-repeat="book in tabList.library.books"
                    ng-click="tabList.library.addBookTab(book.bookId)"
                    class="data-rows"
                    ng-class="{ 'complianceSelect': book.complianceSelect}"
                >
                    <td style="text-indent: 2px;">{{ book.bookIdLong }}</td>

                    <td ng-if="$storage.showFilters.code">{{ book.bookCode }}</td>
                    <td ng-if="$storage.showFilters.psiCode">{{ book.psiCode }}</td>
                    <td ng-if="$storage.showFilters.name">{{ book.bookName }}</td>
                    <td ng-if="$storage.showFilters.insurer">{{ book.Insurers.insurerName }}</td>
                    <td ng-if="$storage.showFilters.insurerDetails">{{ book.InsurerDetailsText }}</td>
                    <td ng-if="$storage.showFilters.product">{{ book.Products.productName }}</td>

                    <td ng-if="$storage.showFilters.audience">{{ book.Audiences.audienceName }}</td>
                    <td ng-if="$storage.showFilters.region">{{ book.Regions.regionName }}</td>
                    <td ng-if="$storage.showFilters.productType">{{ book.ProductTypes.productTypeName }}</td>

                    <td ng-if="$storage.showFilters.docTypeCatName">{{ book.DocTypes.DocTypeCats.docTypeCatName }}</td>
                    <td ng-if="$storage.showFilters.doc">{{ book.DocTypes.docTypeName }}</td>
                    <td ng-if="$storage.showFilters.finProm">{{ book.finProm }}</td>
                    <td ng-if="$storage.showFilters.clients">{{ book.Clients.clientName }}</td>
                    <td ng-if="$storage.showFilters.Owner.fullName">{{ book.Owner.fullName }}</td>
                    <td ng-if="$storage.showFilters.Owner.teamName">{{ book.Owner.teamName }}</td>

                    <td ng-if="$storage.showFilters.division" ng-bind="book.Divisions.division"></td>
                    <td ng-if="$storage.showFilters.workflowStatus" ng-bind="tabList.library.replaceSpaceUni(book.workflowStatus)"></td>
                    <td ng-if="$storage.showFilters.documentStatus" ng-bind="tabList.library.replaceSpaceUni(book.documentStatus)"></td>
                    <td ng-if="$storage.showFilters.projectName" ng-bind="book.Projects.projectName"></td>

                    <td ng-if="$storage.showFilters.date">{{ book.createdDisplayDate}}</td>
                    <td ng-if="$storage.showFilters.lastUpdated">{{ book.lastUpdatedDisplayDate}}</td>
                    <td ng-if="$storage.showFilters.activeDate">{{ book.activeDateDisplayDate}}</td>
                    <td ng-if="$storage.showFilters.liveDate">{{ book.liveDateDisplayDate}}</td>
                    <td ng-if="$storage.showFilters.reviewDate">{{ book.reviewDateDisplayDate}}</td>
                    <td ng-if="$storage.showFilters.expiryDate">{{ book.expiryDateDisplayDate}}</td>
                    <td ng-if="$storage.showFilters.inWorkflow">{{ book.inWorkflow}}</td>

                    <td>
                        <ng-md-icon ng-if="book.actionStatus" ng-class="" icon="{{tabList.library.icons[book.actionStatus -1]}}" md-colors="{fill:tabList.library.colors[book.actionStatus -1]}" style="vertical-align:middle;" size="30"  class="animate-if" wraps-tooltip="action-icon"></ng-md-icon>
                        <span  ng-if="book.searchcount>0" class="searchCount">{{book.searchcount}}</span>
                    </td>
                </tr>
                </tbody>
            </table>
            </form>
        </md-data-table-container>
        <div class="md-data-table-pagination">
            <!-- md-limit="tabList.library.queryLibrary.limit" md-page="tabList.library.queryLibrary.page" md-total="{{tabList.library.books.total[0].totalRows}}" -->
            <div><span class="label ng-binding">Rows per page:</span>
                <md-select ng-model="tabList.pagination.noResults" ng-change="tabList.updateBooks()" aria-label="Row Count: 10" placeholder="25"
                           class="ng-pristine ng-untouched ng-valid ng-empty">
                    <md-content>
                        <md-option ng-repeat="row in [10, 25, 50, 100]" ng-value="row">{{row}}</md-option>
                    </md-content>
                </md-select>
                <span class="ng-binding">{{tabList.pagination.startRow + 1}} - {{tabList.lastOnPage()}} of {{tabList.pagination.totalRows}}</span>
            </div>
            <div>
                <button class="md-button md-ink-ripple" type="button" ng-click="tabList.first()" ng-disabled="!tabList.hasPrevious()"
                        aria-label="First">
                    <md-icon role="img" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fit=""
                             preserveAspectRatio="xMidYMid meet" focusable="false">
                            <path d="M7 6 v12 h2 v-12 h-2z M17.41 7.41L16 6l-6 6 6 6 1.41-1.41L12.83 12z"></path>
                        </svg>
                    </md-icon>
                </button>
                <button class="md-button md-ink-ripple" type="button" ng-click="tabList.previous()" ng-disabled="!tabList.hasPrevious()"
                        aria-label="Previous">
                    <md-icon role="img" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fit=""
                             preserveAspectRatio="xMidYMid meet" focusable="false">
                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                        </svg>
                    </md-icon>
                </button>
                <button class="md-button md-ink-ripple" type="button" ng-click="tabList.next()" ng-disabled="!tabList.hasNext()"
                        aria-label="Next">
                    <md-icon role="img" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fit=""
                             preserveAspectRatio="xMidYMid meet" focusable="false">
                            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                        </svg>
                    </md-icon>
                </button>
                <button class="md-button md-ink-ripple" type="button" ng-click="tabList.last()" ng-disabled="!tabList.hasNext()"
                        aria-label="Last">
                    <md-icon class="ng-scope" role="img" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fit=""
                             preserveAspectRatio="xMidYMid meet" focusable="false">
                            <path d="M15 6 v12 h2 v-12 h-2z M8 6L6.59 7.41 11.17 12l-4.58 4.59L8 18l6-6z"></path>
                        </svg>
                    </md-icon>
                </button>
            </div>
        </div>
    </div>
</md-content>