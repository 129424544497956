<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">


    <div class="md-whiteframe-z1 default-box" style="" >
        <md-content layout-padding >


            <h2  class="" wraps-tooltip="version-log-title">Version Log</h2>
            <md-list flex>
                <md-list-item style="padding-bottom: 10px;"  ng-repeat="version in versions.versions| orderBy : appVersionId : true"><p><strong wraps-tooltip="version-log-number">{{version.appVersionId}}</strong> <small style="font-size:9px;" wraps-tooltip="version-log-date">- {{version.created | date:'dd/MM/yyyy'}}</small><br /><small wraps-tooltip="version-log-info">{{version.appVersionDescription}}</small></p></md-list-item>
            </md-list>



            <p align="center"><md-button ng-click="versions.reload()" wraps-tooltip="version-log-reload">Reload Application</md-button></p>

        </md-content>



    </div>

</md-content>