<md-dialog aria-label="Add Chapter"  class="chaptersdialog">

    <md-toolbar>
        <div class="md-toolbar-tools">
            <h2>Add Chapter</h2>
            <span flex></span>

            <md-button ng-click="chaptersdialog.dialogCancel(false)" class="md-icon-button">
                <ng-md-icon  icon="close" size="24"  aria-label="Close dialog"></ng-md-icon>
            </md-button>
        </div>
    </md-toolbar>
    <md-dialog-content class="dialog-content big" style="">

        <md-tabs md-dynamic-height md-border-bottom md-selected="chaptersdialog.tabIndex">
                <md-tab label="Existing Chapter">
                    <md-content class="md-padding"><br />
                        <form ng-submit="$event.preventDefault()">
                            <md-autocomplete
                                    md-selected-item="chaptersdialog.selectedItem"
                                    md-search-text="chaptersdialog.searchText"
                                    md-selected-item-change="chaptersdialog.getChapters(chaptersdialog.selectedItem.bookId)"
                                    md-items="item in chaptersdialog.querySearch(chaptersdialog.searchText)"
                                    md-item-text="item.bookCode"
                                    md-min-length="1"
                                    ng-model-options="{ debounce: 200 }"
                                    placeholder="Please select a book"
                                    md-menu-class="autocomplete-custom-template"
                            <!--md-select-on-match="true"-->
                            md-autoselect="true">
                            <md-item-template><span class="item-title"  md-highlight-text="chaptersdialog.searchText" md-highlight-flags="^i">{{item.bookCode}} </span></md-item-template>
                                <md-not-found>
                                    No matches found.
                                </md-not-found>
                                </md-autocomplete>
                        </form>

                        <div ng-show="chaptersdialog.chapters">
                            <h3>Select chapters to copy:</h3>
                            <div ng-repeat="chapter in chaptersdialog.chapters | orderBy: order">
                                <md-checkbox ng-model="chapter.selected"  ng-disabled="chapter.disabled" ng-change="chaptersdialog.setSelectedChapters(chapter)" >
                                    <div ng-bind-html="chapter.versions.versiondata"></div>
                                </md-checkbox>
                            </div>
                        </div>

                        <!--<pre>{{chaptersdialog.testbook | json}}</pre>-->
                    </md-content>
                </md-tab>
                <md-tab label="New Chapter">
                    <md-content class="md-padding">
                        <br />
                        <form ng-submit="$event.preventDefault()" name="chapterForm" layout="column"  flex="90">
                            <!--<md-input-container>-->
                                <!--<label>Chapter description</label>-->
                                <!--<input ng-model="chaptersdialog.chapterName"   ng-minlength="3" required>-->

                            <!--</md-input-container>-->


                            <md-input-container>
                                <label>Content Owner</label>
                                <md-select ng-model="chaptersdialog.chapterTeam" placeholder="Content Owner"    ng-model-options="{trackBy: '$value.teamId'}" required>
                                    <md-option ng-value="team" ng-repeat="team in chaptersdialog.teams | orderBy: 'teamName'" >{{team.teamName}}</md-option>
                                </md-select>
                            </md-input-container>

                            <md-input-container flex="80">
                                <label>Chapter Type</label>
                                <md-select ng-model="chaptersdialog.chapterType" placeholder="Chapter Type" ng-init="chaptersdialog.chapterType = chaptersdialog.chapterTypes[0]"    ng-model-options="{trackBy: '$value.chapterTypeId'}" required>
                                    <md-option ng-value="types" ng-repeat="types in chaptersdialog.chapterTypes | orderBy: 'chapterTypeName'" >{{types.chapterTypeName}}</md-option>
                                </md-select>
                            </md-input-container>

                            <md-input-container ng-if="chaptersdialog.chapterType.chapterTypeId === 3">
                                <label>QREF</label>
                                <md-select ng-model="chaptersdialog.QREF" placeholder="Choose QREF"  ng-model-options="{trackBy: '$value.bookId'}" required>
                                    <md-option ng-value="QREF" ng-repeat="QREF in chaptersdialog.QREFs | filter: {isLive: true} | orderBy: 'bookCode'" >{{QREF.bookCode}}</md-option>
                                </md-select>
                            </md-input-container>


                            <div layout="row" layout-wrap ng-if="chaptersdialog.chapterType.chapterTypeId === 2">
                                <div flex="70" layout="row" layout-wrap>

                                    <ul class="file-ui">
                                        <li ng-repeat="f in chaptersdialog.chapterType.filesList" style="font:smaller" class="file-li animate-if">{{f.fileName}}
                                            <div class="file-prog" ng-mouseenter="f.hovered = true" ng-mouseleave="f.hovered = false">
                                                <md-progress-circular class="animate-if" ng-if="f.determinateValue < 100" md-mode="determinate" value="{{f.determinateValue}}" md-diameter="30"></md-progress-circular>
                                                <span class="animate-ifx" ng-if="f.determinateValue < 100" >{{f.determinateValue}}%</span>
                                                <ng-md-icon class="animate-if file-complete" ng-if="f.determinateValue === 100"  icon="check_circle" size="30"  aria-label="Upload complete"></ng-md-icon>
                                                <ng-md-icon class="file-remove" ng-if="f.hovered"  icon="cancel" size="30"  aria-label="Remove file" ng-click="chaptersdialog.removeFile(chaptersdialog.chapterType.filesList, $index)"></ng-md-icon>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div flex="5"></div>
                                <div flex="25">
                                    <div ngf-drop ngf-select ng-model="chaptersdialog.chapterFiles" class="drop-box"
                                         ngf-drag-over-class="'dragover'" ngf-multiple="false" ngf-allow-dir="true"
                                         accept="image/*"
                                         ngf-pattern="'image/*'"><ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop logo image here or<br /><strong style="text-decoration: underline;">Click to upload</strong></div>
                                    <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>



                                </div>
                            </div>



                            <div layout="row" style="padding-top:10px;" layout-wrap ng-if="chaptersdialog.chapterType.chapterTypeId === 2">
                                <div flex="70" layout="row" layout-wrap>

                                    <ul class="file-ui">
                                        <li ng-repeat="f in chaptersdialog.chapterType.PDFList" style="font:smaller" class="file-li animate-if">{{f.fileName}}
                                            <div class="file-prog" ng-mouseenter="f.hovered = true" ng-mouseleave="f.hovered = false">
                                                <md-progress-circular class="animate-if" ng-if="f.determinateValue < 100" md-mode="determinate" value="{{f.determinateValue}}" md-diameter="30"></md-progress-circular>
                                                <span class="animate-ifx" ng-if="f.determinateValue < 100" >{{f.determinateValue}}%</span>
                                                <ng-md-icon class="animate-if file-complete" ng-if="f.determinateValue === 100"  icon="check_circle" size="30"  aria-label="Upload complete"></ng-md-icon>
                                                <ng-md-icon class="file-remove" ng-if="f.hovered" icon="cancel" size="30" aria-label="Remove file" ng-click="chaptersdialog.removeFile(chaptersdialog.chapterType.PDFList, $index)"></ng-md-icon>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div flex="5"></div>
                                <div flex="25">
                                    <div ngf-drop ngf-select ng-model="chaptersdialog.frontCoverPDF" class="drop-box"
                                         ngf-drag-over-class="'dragover'" ngf-multiple="false" ngf-allow-dir="true"
                                         accept="application/pdf"
                                         ngf-pattern="'application/pdf'"><ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop front cover PDF here or<br /><strong style="text-decoration: underline;">Click to upload</strong></div>
                                    <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>
                                </div>
                            </div>



                            <md-input-container  ng-if="chaptersdialog.chapterType.chapterTypeId != 3 && chaptersdialog.chapterType.chapterTypeId != 2 && chaptersdialog.chapterType.chapterTypeId != 5 && chaptersdialog.chapterType.chapterTypeId != 6">
                                <label>Chapter copy</label>
                                <input ng-model="chaptersdialog.chapterData"   ng-minlength="3" required>
                            </md-input-container>

                        </form>
                    </md-content>
                </md-tab>
</md-tabs>







        <md-progress-linear ng-if="chaptersdialog.isLoading" md-mode="indeterminate" style="position: absolute;"></md-progress-linear>
    </md-dialog-content>
    <md-dialog-actions layout="row">

        <span flex></span>
        <md-button class="md-grey"  ng-click="chaptersdialog.dialogCancel(false)">Cancel<ng-md-icon icon="close" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>
        <md-button ng-if="(chaptersdialog.tabIndex==0)" ng-disabled="(chaptersdialog.isLoading || chaptersdialog.selectedChapters.length==0)" class="md-green"  ng-click="chaptersdialog.addChapters(chaptersdialog.chapters)">Add<ng-md-icon icon="add" style="fill: white; position: relative; top:4px; padding-left: 4px; " size="20"></ng-md-icon></md-button>
        <md-button ng-if="(chaptersdialog.tabIndex==1)" ng-disabled="(chaptersdialog.isLoading || !chapterForm.$valid)" class="md-green"  ng-click="chaptersdialog.addNewChapter(chaptersdialog)">Add New<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>

    </md-dialog-actions>
</md-dialog>

