angular
    .module('library')
    .component('tabMain', {
        templateUrl: 'library/library-components/right-menu/tab-main/tab-main.tpl.html',
        controller: tabMainCtrl,
        controllerAs: 'tabMain',
        bindings: {
            book: '=',
            allbooks: '=',
            disabled: '=',
            visible: '=',
            newlanguage: '=',
            switch:'<'
        }

    });
tabMainCtrl.$inject = ['$q','$filter','$localStorage','$mdDialog','$scope','apiUrl','Books','Upload','Wraps'];
function tabMainCtrl($q, $filter, $localStorage, $mdDialog, $scope, apiUrl, Books, Upload, Wraps) {
    var vm = this;
    vm.bookCodeList = [];
    var tempCode = '';
    $scope.$storage = $localStorage;
    //console.log("main");
    Wraps.initVars.then(function () {
        vm.audiences = Wraps.audiences;
        vm.BGHPColour = Wraps.BGHPColour;
        vm.clients = Wraps.clients;
        vm.distributors = Wraps.distributors;
        vm.divisions = Wraps.divisions;
        vm.docTypeCats = Wraps.docTypeCats;
        vm.docTypes = Wraps.docTypes;
        vm.emphasis = Wraps.emphasis;
        vm.insurers = Wraps.insurers;
        vm.languages = Wraps.languages;
        vm.products = Wraps.products;
        vm.productTypes = Wraps.productTypes;
        vm.projects = Wraps.projects;
        vm.regions = Wraps.regions;
        vm.socialChannels = Wraps.socialChannels;
        vm.subInsurers = Wraps.subInsurers;
        vm.users = Wraps.users;
    });
    vm.hasPermissions = Wraps.hasPermissions;
    vm.documentInfoForm = $scope.$parent.documentInfoForm;


    $scope.$watch('tabMain.visible', function () {


        if(vm.book){
            vm.book.limitDoc = vm.book.limitDoc == 1 ? true : false;
            vm.originalInsurerId = angular.copy(vm.book.insurerId);
            if (vm.book.DocTypes) {
                vm.temp = {DocTypeCat: vm.book.DocTypes.DocTypeCats.docTypeCatId};
            } else {
                vm.temp = {};
            }
            if(vm.book.insurerId == 38/*non specific insurer id*/) {
                vm.showInsurerDetails = true;
            }

            vm.hasPsi = (vm.book.psiCode !== null && typeof vm.book.psiCode != 'undefined');

            vm.filteredMultiInsurers    = vm.currentlySelectedOrSelectableFilter(vm.insurers, 'insurerDetailsSelectedArray', 'insurer');
            vm.filteredInsurers         = vm.currentlySelectedOrSelectableFilter(vm.insurers, 'insurerId', 'insurer');
            vm.filteredProjects         = vm.currentlySelectedOrSelectableFilter(vm.projects, 'projectId', 'project');
        }

        if (vm.book) {
            tempCode = vm.book.bookCode;
        }
        
        if(vm.book && (typeof vm.book.bookCode == 'undefined' || vm.book.bookCode == '')) {
            if(vm.book.bookCodeOld !== 'disableAutoCode'){
                Books.generateDocumentCode({}).$promise.then(function(result) {
                    vm.book.bookCode = result[0][0].bookCode;
                });
            }
        }
    });

    vm.setWorkflow = function (docTypeTemplate) {
        vm.book.initWorkflowList();
    }

    vm.setPsi = function () {
        if (!vm.hasPsi){
            vm.book.psiCode = null;
        }

    }

    // function to filter drop-downs to contain the current value and selectable values only.
    vm.currentlySelectedOrSelectableFilter = function (array, bookProperty, fieldPrefix){
        var filteredArray = array.filter(function(item){
            if (
                (// current item is in book's division and it isn't deleted.
                item.divisionId === vm.book.divisionId && (parseInt(item[fieldPrefix + 'Status']) === 1)) ||
                // current item is the selected value for bookProperty
                item[fieldPrefix + 'Id'] === vm.book[bookProperty] ||
                // bookProperty is array and current item is one of the elements selected.
                (typeof vm.book[bookProperty] === 'object' &&
                    (vm.book[bookProperty].indexOf(item[fieldPrefix + 'Id']) !== -1 ||
                    vm.book[bookProperty].indexOf(item[fieldPrefix + 'Id'] + '') !== -1) // hack for arrays of strings
                )
            ) {
                return true;
            } else {
                return false;
            }
        });
        // console.log('filter run', filteredArray);
        return filteredArray;
    }


    vm.allowedDocTypeEdit = function () {
        //for ng-disable so true = disabled;
        if (vm.book &&
            ($scope.$storage.user.id == 49 ||
            !vm.book.DocTypes ||
            (!vm.book.bookId && vm.book.DocTypes.docTypeTemplate !== 1))
        ) {
            return false; //enable
        } else {
            return true; //disable
        }

    }

    // 3 FUNCTIONS FOR AUTOCOMPLETE
    vm.queryBooks = function (query) {
        //var results = query ? vm.bookCodeList.filter( createFilterFor(query) ) : [];
        //var results = query ? $filter('filter')(vm.bookCodeList, query) : [];
        return Books.getBookCodesBySearch({"codeSearchText":query}).$promise.then(function (results) {

            _.pull(results.books, tempCode);
              vm.bookCodeList = results.books;
              // Map the response object to the data object.
              return results.books;
        });
    }

    function createFilterFor(query) {
        return function filterFn(item) {
            return (item.toLowerCase().indexOf(query.toLowerCase()) >= 0);
        };
    }

    vm.checkCodes = function (text) {
        //console.log(_.includes(vm.bookCodeList, text),vm.bookCodeList,text);
        vm.book.checkCode = _.includes(vm.bookCodeList, text);
        return vm.book.checkCode;
    }

    vm.checkBlank = function (text) {
        return text === '' || typeof text === 'undefined';
    };
    //console.log("/main");


    $scope.$watch('tabMain.JSONFile', function () {
        if (vm.JSONFile && vm.JSONFile.name.search(/\.json/i) > -1) {
            vm.upload(vm.JSONFile).then(function (statuses) {
                vm.isLoading = false;
            });
        }
    });

    vm.upload = function (file) {
        var timestamp = new Date().getTime();
        var S3_BUCKET_URL = apiUrl + '/containers/wraps-files/upload';
        var deferred = $q.defer();

        if (file) {
            if (!file.$error) {
                vm.isLoading = true;

                file.container = 'wraps-files';
                file.fileLocation = 'translationFiles/' + timestamp;
                file.fileName = file.name;
                file.fileSize = file.size;
                vm.JSONfilesList = [];
                vm.JSONfilesList.push(file);
                //console.log(vm.JSONFile);

                //TODO add timeout to animate files
                var newName = file.fileLocation + '/' + file.name;
                Upload.rename(file, newName);
                Upload.upload({
                    url: S3_BUCKET_URL,
                    file: file

                }).then(function (resp) {
                    //console.log(resp);
                    vm.book.languageFile = newName;
                    deferred.resolve();
                }, null, function (evt) {
                    file.determinateValue = parseInt(100.0 * evt.loaded / evt.total);

                });
            }
        }

        return deferred.promise;
    };

    vm.clearDivisionDataDialog = function (previousValue) {

        var confirm = $mdDialog.confirm()
            .title('Change Division')
            .htmlContent('By changing division all division data entered so far will be lost. Would you like to proceed?<br />')
            .ariaLabel('Confirm division change')
            .ok('Change')
            .cancel('Cancel');

        $mdDialog.show(confirm)
            .then(function () {
                vm.clearDivisionData();
            }, function () {
                vm.book.divisionId = previousValue;
            });

    }

    vm.insurerDetailsDialog = function () {
        if(vm.book.insurerId == 38) {
            var confirm = $mdDialog.confirm()
                .title('Insurer Details')
                .htmlContent('You need to list every insurer that will use this document to enable other users to identify where this document is in use.<br />')
                .ariaLabel('Confirm Insurer Details')
                .ok('Add Insurer Details')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    vm.showInsurerDetails = true;
                }, function () {
                    vm.book.insurerId = angular.copy(vm.originalInsurerId);
                    vm.showInsurerDetails = false;
                });
        }
    }

    vm.generateDocCode = function() {
        if(vm.book.bookCodeOld !== 'disableAutoCode'){
            var codeComponents = {
                "bookId": vm.book.bookId,
                "insurerId": vm.book.insurerId,
                "productId": vm.book.productId,
                "docType": vm.book.DocTypes ? vm.book.DocTypes.docTypeCode : '',
                "languageId": vm.book.language,
                "clientId": vm.book.clientId,
                "createdDate": vm.book.created
            };

            Books.generateDocumentCode(codeComponents).$promise.then(function(result) {
                vm.book.bookCode = result[0][0].bookCode;
            });
        }
    }

    vm.validateCharactors = function($event) {
        var regex = new RegExp("^[^<>:\"\/\\\\|?*]+$");
        var key = String.fromCharCode(!$event.charCode ? $event.which : $event.charCode);
       
        if (!regex.test(key)) {
           $event.preventDefault();
           return false;
        }  
    }

    vm.clearDivisionData = function () {
        vm.book.bookOwner = '';
        vm.temp.DocTypeCat = '';
        vm.book.DocTypes = '';
        vm.book.insurerId = '';
        vm.book.productId = '';
        vm.book.regionId = '';
        vm.book.productTypeId = '';
        vm.book.clientId = '';
        vm.book.audienceId = '';
        vm.book.language = '';
        vm.book.subInsurerId = '';
        vm.book.distributorID = '';
        vm.book.projectId = '';
        vm.book.finProm = '';
        vm.book.limitDoc = '';
        vm.book.docContents = '';
        vm.book.languages = '';
        vm.book.production = {};
        vm.book.support = {};
        vm.book.inSitu = {};
        vm.book.fulfilment = {};
        vm.book.digital = {};
        vm.book.languageVersions = {};
        vm.book.bookContent = {};

        // set the publishing switches to NA on clear
        vm.switch.production.flag = true;
        vm.switch.fulfilment.flag = true;
        vm.switch.digital.flag = true;
        vm.switch.support.flag = true;
        vm.switch.inSitu.flag = true;
    }


}