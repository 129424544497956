angular
    .module('library')
    .component('tabLevels', {
        templateUrl: 'library/library-components/right-menu/tab-levels/tab-levels.tpl.html',
        controller: tabLevelsCtrl,
        controllerAs:'tabLevels',
        bindings:{
            book:'<',
            disabled:'<'
        }

    });
tabLevelsCtrl.$inject = [];
function tabLevelsCtrl(){
    //var vm = this;
}