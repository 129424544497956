<md-toolbar layout="row" class="md-whiteframe-z0" layout-wrap layout-align="center start" ng-class="{ 'login-toolbar': (navigation.isActive('/login') || navigation.isActive('/reset')), 'default-toolbar': (!navigation.isActive('/login') && !navigation.isActive('/reset')), 'switch':  navigation.system == 'switch' }" >

    <div class="md-toolbar-tools">
		<!--Left Header-->
		<div flex layout="row" layout-align="start center" ng-if="(!navigation.isActive('/login') && !navigation.isActive('/reset'))">
			<md-button class="md-icon-button" ng-href="/home" aria-label="Home" wraps-tooltip="navigation-home">
				<ng-md-icon icon="home" style="fill: white" size="24"></ng-md-icon>
			</md-button>

			<md-button class="md-button" ng-href="/library" aria-label="Library"  ng-if="navigation.hasPermissions('viewLibrary')" wraps-tooltip="navigation-library">
				Library
			</md-button>

			<md-button ng-click="" ng-href="/drive" aria-label="Drive" ng-if="navigation.hasPermissions('viewDrive')" wraps-tooltip="navigation-drive">
				Drive
			</md-button>

			<md-button ng-click="" href=" {{navigation.clientConfig.helpsiteLink}} " target="_blank" wraps-tooltip="navigation-help" aria-label="help">
				Help
			</md-button>

			<div class="md-menu" ng-if="navigation.hasPermissions('viewHealthcheck') ||
                                        navigation.hasAnyOfPermissionCat(3) ||
                                        navigation.hasAnyOfPermissionCat(10) ||
                                        navigation.hasAnyOfPermissionCat(5) ||
                                        navigation.hasPermissions('adminReports') ||
                                        navigation.hasPermissions('adminUsers')">
				<div class="menu-container" layout-align="center right" layout="column">
					<md-menu md-position-mode="target"  md-offset="-8 36">
						<div aria-label="Additional Navigation Items" ng-click="$mdOpenMenu()" wraps-tooltip="navigation-more">
							<ng-md-icon icon="more_vert"></ng-md-icon>
						</div>
						<md-menu-content>

							<md-menu-item>
								<md-button ng-click="" ng-href="/healthcheck" aria-label="Meta"  ng-if="navigation.hasPermissions('viewHealthcheck')" wraps-tooltip="navigation-maintenance">
									Health Check
								</md-button>
							</md-menu-item>
							<md-menu-item>
								<md-button ng-click="" ng-href="/maintenance" aria-label="Meta"  ng-if="navigation.hasAnyOfPermissionCat(3) || navigation.hasAnyOfPermissionCat(6)" wraps-tooltip="navigation-maintenance">
									Meta Lists
								</md-button>
							</md-menu-item>
							<md-menu-item>
								<md-button ng-click="" ng-href="/permissionmanagement" aria-label="Meta"  ng-if="navigation.hasAnyOfPermissionCat(5)" wraps-tooltip="navigation-permissions">
									Permissions
								</md-button>
							</md-menu-item>

							<md-menu-item>
								<md-button ng-click="" ng-href="/reports" aria-label="Reports"  ng-if="navigation.hasPermissions('adminReports')" wraps-tooltip="navigation-reports">
									Reports
								</md-button>
							</md-menu-item>

							<md-menu-item>
								<md-button ng-click="" ng-href="/users" aria-label="Users"  ng-if="navigation.hasPermissions('adminUsers')" wraps-tooltip="navigation-users">
									Users
								</md-button>
							</md-menu-item>

						</md-menu-content>
					</md-menu>
				</div>

			</div>
		</div>
		<!--Logo-->
    <div flex layout="row"></div>

		<img ng-if="navigation.logo != 'switch' " src="/images/wraps-logo.png"  class="wraps-logo"  wraps-tooltip="wraps-logo">
    <img ng-if="navigation.logo == 'switch' " src="/images/switch-logo.png" class="switch-logo" wraps-tooltip="switch-logo">

		<div flex layout="row"></div>
		<!--Right Header-->
        <div flex layout="row" layout-align="end center" ng-if="(!navigation.isActive('/login') && !navigation.isActive('/reset'))">
			<md-button class="md-icon-button has-badge" ng-click="navigation.navbarStatsDialog(0)" wraps-tooltip="navbar-black-assets">
				<ng-md-icon icon="warning" style="fill: white" ng-class="{'no-action':navigation.notificationCount.blackAssetsByOwner.blackAssets <= 0}"></ng-md-icon>
				<span ng-if="navigation.notificationCount.blackAssetsByOwner.blackAssets > 0" class="badge" aria-label="Black Assets" wraps-tooltip="notifications-black-assets">{{navigation.notificationCount.blackAssetsByOwner.blackAssets}}</span>
				<md-tooltip>Black Assets</md-tooltip>
			</md-button>
			<md-button class="md-icon-button has-badge" ng-click="navigation.navbarStatsDialog(1)" wraps-tooltip="navbar-actions-user">
				<ng-md-icon icon="error" style="fill: white" ng-class="{'no-action':navigation.notificationCount.documentsWithActionsByOwnerPersonal.actions <= 0}"></ng-md-icon>
				<span ng-if="navigation.notificationCount.documentsWithActionsByOwnerPersonal.actions > 0" class="badge" aria-label="Personal Actions" wraps-tooltip="notifications-personal-actions">{{navigation.notificationCount.documentsWithActionsByOwnerPersonal.actions}}</span>
				<md-tooltip>Personal Actions</md-tooltip>
			</md-button>
			<md-button class="md-icon-button has-badge" ng-click="navigation.navbarStatsDialog(2)" wraps-tooltip="navbar-actions-team">
				<ng-md-icon icon="error_outline" style="fill: white" ng-class="{'no-action':navigation.notificationCount.documentsWithActionsByOwnerTeam.actions <= 0}"></ng-md-icon>
				<span ng-if="navigation.notificationCount.documentsWithActionsByOwnerTeam.actions > 0" class="badge" aria-label="Team Actions" wraps-tooltip="notifications-team-actions">{{navigation.notificationCount.documentsWithActionsByOwnerTeam.actions}}</span>
				<md-tooltip>Team Actions</md-tooltip>
			</md-button>
			<md-button class="md-icon-button has-badge" ng-click="navigation.navbarStatsDialog(3)" wraps-tooltip="navbar-actions-review">
				<ng-md-icon icon="stars" style="fill: white" ng-class="{'no-action':navigation.notificationCount.documentsInReview.inReview <= 0}"></ng-md-icon>
				<span ng-if="navigation.notificationCount.documentsInReview.inReview > 0" class="badge" aria-label="In review" wraps-tooltip="notifications-team-review">{{navigation.notificationCount.documentsInReview.inReview}}</span>
				<md-tooltip>In Review</md-tooltip>
			</md-button>



			<md-button aria-label="Tool tips" class="md-icon-button" style="padding-left:15px;" wraps-tooltip="tooltip-icon" ng-click="$emit('toggleTooltip');" ng-if="navigation.hasPermissions('viewTooltips')">
				<md-tooltip>Tool tips</md-tooltip>
				<ng-md-icon icon="help" style="fill: white;" size="24"></ng-md-icon>
			</md-button>

			<small style="font-size: 10px; position: relative; top:0px; margin-left:15px; white-space:nowrap;"><md-tooltip ng-if="navigation.spoofingUser()">Impersonating</md-tooltip><ng-md-icon style="vertical-align:middle" icon="person_outline" ng-if="navigation.spoofingUser()" wraps-tooltip="impersonating"></ng-md-icon><b>{{$storage.user.firstName}}</b><!-- - {{$storage.user.Roles.name}} - {{$storage.user.Teams.teamName}}--></small>

			<div class="md-menu" style="margin-top:5px;" >
				<div class="menu-container" layout-align="center right" layout="column">
					<md-menu md-position-mode="target-right target"  md-offset="5 50">
						<div aria-label="Open tools menu" class="avatar-container" ng-click="$mdOpenMenu()" wraps-tooltip="user-profile-icon">
							<img ng-if="$storage.user.profilePic != '' && $storage.user.profilePic != null" ng-src="{{navigation.apiUrl}}/containers/getthumbnail/{{$storage.user.profilePic}}" class="user-avatar" alt="User Avatar"/>
							<div ng-if="$storage.user.profilePic == '' || $storage.user.profilePic == null" class="user-avatar initials {{$storage.user.lastName|lowercase|limitTo:1}}">{{$storage.user.firstName|uppercase|limitTo:1}}{{$storage.user.lastName|uppercase|limitTo:1}}</div>
						</div>
						<md-menu-content>
							<md-menu-item>
								<md-button ng-click="" ng-href="/profile">

									<div layout="row">
										<p flex>User Profile</p>

										<ng-md-icon icon="person" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
									</div>
								</md-button>
							</md-menu-item>
							<md-menu-item ng-if="navigation.hasPermissions('switchUser') || navigation.spoofingUser()">
								<md-button ng-click="navigation.switchToUserDiag();">

									<div layout="row">
										<p flex>Impersonate User</p>

										<ng-md-icon icon="person_outline" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
									</div>
								</md-button>
							</md-menu-item>
							<md-menu-item ng-if="navigation.spoofingUser()">
								<md-button ng-click="navigation.editAsMe();">

									<div layout="row">
										<p flex>Revert To Your User</p>

										<ng-md-icon icon="sync" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
									</div>
								</md-button>
							</md-menu-item>
							<md-menu-item>
								<md-button ng-href="/login" class="md-button" aria-label="Log out">

									<div layout="row">
										<p flex>Log Out</p>
										<ng-md-icon icon="logout" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
									</div>
								</md-button>
							</md-menu-item>
						</md-menu-content>
					</md-menu>
				</div>

			</div>
        </div>

    </div>
</md-toolbar>
<div class="floating-impersonation-bar" md-colors="{background:'primary'}" ng-if="navigation.spoofingUser() && !navigation.isActive('/login')" wraps-tooltip="impersonating">
	<ng-md-icon icon="person_outline" ng-if="navigation.spoofingUser()"></ng-md-icon> {{$storage.loggedInUser.firstName}}, you are impersonating {{$storage.user.firstName}} {{$storage.user.lastName}}. <md-button ng-click="navigation.editAsMe();">Revert</md-button>
</div>
