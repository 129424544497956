(function () {
  'use strict';

  angular
    .module('workflow')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('workflow', {
        url: '/workflow',
        views: {
          navigation: {
            templateUrl: 'navigation/navigation.tpl.html',
            controller: 'NavigationCtrl',
            controllerAs: 'navigation'
          },
          main: {
            templateUrl: 'workflow/workflow.tpl.html',
            controller: 'WorkflowCtrl',
            controllerAs: 'workflow'
          }
        }

      });
  }
}());
