<md-dialog aria-label="Switch to user" class="changedialog" ng-cloak>

    <md-toolbar>
        <div class="md-toolbar-tools">
            <h2>
                Impersonate user
            </h2>
            <span flex></span>

            <md-button ng-click="navigation.dialogCancel()" class="md-icon-button">
                <ng-md-icon icon="close" size="24" aria-label="Close dialog"></ng-md-icon>
            </md-button>
        </div>
    </md-toolbar>
    <md-dialog-content class="dialog-content">


        <md-content class="md-padding" flex="100">
            <form ng-submit="$event.preventDefault()" layout-align="start stretch" name="switchUserForm" layout="column">
                    <md-input-container>
                        <md-select ng-model="navigation.thisTeam" placeholder="Filter by team (optional)"
                                   wraps-tooltip="library-switch-user" ng-change="navigation.thisUser = undefined">
                            <md-option ng-value="">All</md-option>
                            <md-option ng-value="team.teamId" ng-repeat="team in navigation.teamslist | orderBy:'teamName'">
                                {{team.teamName}}
                            </md-option>
                        </md-select>
                    </md-input-container>
                    <md-input-container>
                        <md-select md-autofocus="true" ng-model="navigation.thisUser" placeholder="Select user"
                                   wraps-tooltip="library-switch-user"
                                   ng-change="navigation.checkUser()" >
                            <md-option ng-value="user" ng-repeat="user in navigation.userslist | filter: {Teams: {teamId: navigation.thisTeam}}:(navigation.thisTeam > 0) | orderBy: ['firstName','lastName']">
                                {{user.firstName}} {{user.lastName}} - {{user.Roles.name}} - {{user.Divisions.division}}/{{user.Teams.teamName}} {{user.status === 2 ? "(disabled)" : ""}}
                            </md-option>
                        </md-select>
                    </md-input-container>
            </form>

            <div>
                <p>Please note that some tabs may not be accessible due to user's permissions</p>
            </div>

        </md-content>


    </md-dialog-content>

    <md-dialog-actions>

        <span flex></span>
        <md-button class="md-primary" ng-click="navigation.dialogCancel()">Cancel</md-button>
        <md-button class="md-raised md-primary" ng-disabled="!navigation.thisUser" ng-click="navigation.switchUser(navigation.thisUser)">Impersonate</md-button>

    </md-dialog-actions>
</md-dialog>