(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.metachangedialog.controller:MetachangedialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.metachangedialog')
    .controller('MetachangedialogCtrl', MetachangedialogCtrl);

  MetachangedialogCtrl.$inject = ['$mdDialog', '$q', '$sce', '$scope', 'apiUrl', 'change'];
  function MetachangedialogCtrl($mdDialog, $q, $sce, $scope, apiUrl, change) {
    var vm = this;
    vm.ctrlName = 'MetachangedialogCtrl';

    vm.dialogCancel = function(data) {
        $mdDialog.cancel(data);
    };
  }
}());
