angular
    .module('library')
    .component('tabEnclosures', {
        templateUrl: 'library/library-components/right-menu/tab-enclosures/tab-enclosures.tpl.html',
        controller: tabEnclosuresCtrl,
        controllerAs:'tabEnclosures',
        bindings:{
            book:'=',
            disabled:'='
        }

    });

tabEnclosuresCtrl.$inject = ['$scope', 'EnclosureTypes', 'Books'];
function tabEnclosuresCtrl($scope, EnclosureTypes, Books){
    var vm = this;
    //vm.book = tabEnclosures.book;
    vm.docTypes = [1, 4, 32, 80, 82, 93, 10, 29, 97, 9, 109, 58, 106];

    EnclosureTypes.find({}, function (enclosureTypes) {
        vm.enclosureTypes = enclosureTypes;
    });

    Books.find({
        filter: {
            fields:{
                "bookId": true,
                "docTypeId": true,
                "bookName": true,
                "bookCode": true
            },
            where: {
                docTypeId: { inq: vm.docTypes},
                expiryDate: {gt: moment(new Date())},
                deleted: 0,
                and: [{
                    or: [{
                        earlyExpiryDate: {gt: moment(new Date())}
                    }, {
                        earlyExpiryDate: null
                    }]
                }]
            }
        }
    }).$promise.then(function (booksForSuggestiveSelect) {
        vm.booksForSuggestiveSelect = booksForSuggestiveSelect;
    });

    vm.getBooksForEnclosureType = function(textSearch, docTypes) {
        var docTypesArray
        if(docTypes) {
            if (docTypes.length > 0) {
                docTypesArray = docTypes.split(',');
            } else {
                docTypesArray = false;
            }

            // filtering by comma separated cleverness
            //var pattern = new RegExp(/[^\s,]+$/g);

            docTypesArray = docTypesArray.map(function (x) {
                return parseInt(x, 10);
            });

            var filtered = vm.booksForSuggestiveSelect.filter(
                function (e) {
                    //searchString = textSearch;
                    //searchString = pattern.exec(textSearch);

                    if (docTypesArray !== false) {
                        return docTypesArray.indexOf(e.docTypeId) >= 0 && e.bookCode.toLowerCase().includes(textSearch.toLowerCase());
                    } else {
                        return e.bookCode.toLowerCase().includes(textSearch.toLowerCase());
                    }

                }
            );

            return filtered;
        }else{
            return false;
        }
    }
}