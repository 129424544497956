<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">

    <div class="md-whiteframe-z1 default-box" style="" ng-if="drive.hasAccess == true" >
        

            <md-data-table-toolbar>
            	<h2 flex wraps-tooltip="drive-title">Wraps Drive</h2>
				<!--<pre>d = {{drive.params|json}}</pre>-->
            </md-data-table-toolbar>
			<div class="breadcrumbs">
				<span ng-click="drive.driveHome()" wraps-tooltip="drive-breadcrumbs-home">Wraps Drive</span> 
				<ng-md-icon icon="keyboard_arrow_right" ng-if="drive.params.insurer != null"></ng-md-icon><span ng-click="drive.openInsurer(drive.params.insurer)" wraps-tooltip="drive-breadcrumbs-insurer">{{drive.params.insurer}}</span> 
				<ng-md-icon icon="keyboard_arrow_right" ng-if="drive.params.product != null"></ng-md-icon><span ng-click="drive.openProduct(drive.params.product)" wraps-tooltip="drive-breadcrumbs-product">{{drive.params.product}}</span> 
				<ng-md-icon icon="keyboard_arrow_right" ng-if="drive.params.category != null"></ng-md-icon><span ng-click="drive.openCategory(drive.params.category)" wraps-tooltip="drive-breadcrumbs-category">{{drive.params.category}}</span> 
				<ng-md-icon icon="keyboard_arrow_right" ng-if="drive.params.dtype != null"></ng-md-icon><span ng-click="drive.openDocType(drive.params.dtype)" wraps-tooltip="drive-breadcrumbs-doctype">{{drive.params.dtype}}</span>
				<ng-md-icon icon="keyboard_arrow_right" ng-if="drive.params.doc != null"></ng-md-icon><span wraps-tooltip="drive-breadcrumbs-doctype">{{drive.params.doc}}</span>
			</div>

            

            <md-data-table-container ng-if="drive.params.dtype == null">
               	<md-progress-linear md-mode="query" ng-show="drive.isLoading"></md-progress-linear>
				<div ng-repeat="(insurerKey, insurer) in drive.data.insurers">
					<div  ng-click="drive.toggleInsurer(insurer.insurerCode)" ng-class="{active: drive.params.insurer == insurer.insurerCode}" class="folder" wraps-tooltip="drive-folder-insurer">
						<ng-md-icon ng-if="drive.params.insurer != insurerKey" icon="folder" size="20"></ng-md-icon><ng-md-icon ng-if="drive.params.insurer == insurerKey" icon="folder_open" size="20"></ng-md-icon>{{insurer.insurerName}} ({{insurer.docCount}})
					</div>
					<div ng-repeat="(productKey, product) in drive.data.products"  ng-if="drive.params.insurer == insurerKey">
						<div class="folder child" ng-click="drive.toggleProduct(productKey);" ng-class="{active: drive.params.product == productKey}" wraps-tooltip="drive-folder-product">
							<ng-md-icon ng-if="drive.params.product != productKey" icon="folder" size="20"></ng-md-icon><ng-md-icon ng-if="drive.params.product == productKey" icon="folder_open" size="20"></ng-md-icon>{{product.productName}} ({{product.docCount}})
						</div>
						<div ng-repeat="(categoryKey, category) in drive.data.categories" ng-if="drive.params.product == productKey">
							<div class="folder grandchild" ng-click="drive.toggleCategory(categoryKey);" ng-class="{active: drive.params.category == categoryKey}" wraps-tooltip="drive-folder-category">
								<ng-md-icon ng-if="drive.params.category != categoryKey" icon="folder" size="20"></ng-md-icon><ng-md-icon ng-if="drive.params.category == categoryKey" icon="folder_open" size="20"></ng-md-icon>{{category.docTypeCatName}} ({{category.docCount}})
							</div>
							<div ng-repeat="(docTypeKey, type) in drive.data.docTypes" ng-if="drive.params.category == categoryKey">
								<div class="folder greatgrandchild" ng-click="drive.openDocType(docTypeKey)" wraps-tooltip="drive-folder-doctype">
									<ng-md-icon icon="folder" size="20"></ng-md-icon>{{type.docTypeName}} ({{type.docCount}})
								</div>
							</div>
							<div ng-if="category.docTypes.length == 0 && !drive.isLoading" class="folder greatgrandchild">
								There are no document types associated with this category.
							</div>
						</div>
						<div ng-if="product.categories.length == 0 && !drive.isLoading" class="folder grandchild">
							There are no categories associated with this product.
						</div>
					</div>
					<div ng-if="insurer.products.length == 0 && !drive.isLoading" class="folder child">
						There are no products associated with this insurer.
					</div>
				</div>
            </md-data-table-container>
		
             <md-data-table-container ng-if="drive.params.dtype != null" class="table-outer">
				
				<md-progress-linear md-mode="query" ng-show="drive.isLoading"></md-progress-linear>
                <table md-data-table  > <!--md-row-select="selected"-->
					<thead>
						<tr ng-show="drive.data.docs.length > 0">
							<th ng-click="sortBy('bookId')" width="60px"><span wraps-tooltip="drive-heading-id">ID <ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: drive.orderBy === 'bookId', reverse: drive.reverse}"></ng-md-icon></span></th>
							<th ng-click="sortBy('bookName')"><span wraps-tooltip="drive-heading-name">Name <ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: drive.orderBy === 'bookName', reverse: drive.reverse}"></ng-md-icon></span></th>
							<th ng-click="sortBy('bookCode')"><span wraps-tooltip="drive-heading-code">Code <ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: drive.orderBy === 'bookCode', reverse: drive.reverse}"></ng-md-icon></span></th>
							<th ng-click="sortBy('expiryDate')"><span wraps-tooltip="drive-heading-expiry">Expiry Date <ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: drive.orderBy === expiryDate || drive.orderBy[0] === expiryDate, reverse: drive.reverse}"></ng-md-icon></span></th>
								
						</tr>
					</thead>
                    <tbody ng-repeat="book in drive.data.docs | orderBy: drive.orderBy : drive.reverse">
						<tr class="data-rows" ng-if="drive.params.doc != book.bookId" ng-click="drive.openDoc(book.bookId)" wraps-tooltip="drive-doc-list">
							<td>{{book.bookId | seven}}</td>
							<td>{{book.bookName}}</td>
							<td>{{book.bookCode}}</td>
							<td>{{book.expiryDate | date:'dd/MM/yyyy':'+0000'}}</td>
						</tr>
						<tr class="details-row" id="anchorBook{{book.bookId}}" ng-if="drive.params.doc == book.bookId">
							<td colspan="99" class="bookTd">
                       			<div class="main-outer">
									<div class="main-inner md-whiteframe-z2">
										<div class="greyBarWrapper layout-row" layout="row" ng-click="drive.openDocType(drive.params.dtype)" ng-class="{ 'complianceSelect': book.complianceSelect}" role="button" tabindex="0">
											<h3 flex="" class="ng-binding flex">{{book.bookName}}</h3>
											<!--refresh book button-->
											<button class="md-icon-button bookIconPosition md-button ng-scope ng-isolate-scope md-ink-ripple" type="button" ng-if="drive.hasPermissions('refreshDocumentTemp')" ng-click="drive.getBook(book.bookId); $event.stopPropagation();" aria-label="Refresh book" wraps-tooltip="drive-doc-refresh-icon">
												<ng-md-icon icon="refresh" style="fill: grey; position: relative;" size="24" class="ng-scope"></ng-md-icon>
											</button>
										</div>
										<div class="drive-data" id="book-info-{{book.bookId}}">
											<div class="downloads-container">
												<div class="download-row" layout-padding="20">
													<div style="float:right;" ng-if="drive.hasPermissions('viewLibrary')"><md-button class="md-raised" ng-click="drive.openDocInLibrary(book.bookId)">Open document in Library</md-button></div>
													<h3><span style="font-weight: 700 !important;" class="ng-binding">{{book.bookName}}</span><br>
														Code: <span style="font-weight: 500 !important;" class="ng-binding">{{book.bookCode}}</span><br>
														Expiry date: <span style="font-weight: 500 !important;" class="ng-binding">{{book.expiryDate | date: "dd-MM-yyyy"}}</span><br>
														ID: <span style="font-weight: 500 !important;" class="ng-binding">{{book.bookId|seven}}</span></h3>
													<div flex="100" class="divider"><div></div></div>
												</div>
												<div ng-repeat="download in drive.data.docDetail.downloads" ng-if="drive.data.docDetail.noDownloads != ''" layout="row" layout-wrap layout-padding="20" class="download-row">
													<a href="{{download.fileLocation}}" target="_blank">
														<div  class="thumbnail-block" wraps-tooltip="drive-doc-download">
															<img class="thumbnail-preview" ng-src="{{download.thumbLocation}}" err-SRC="/images/wraps-logo.png" alt="{{download.fileName}}"/>
															<div class="overlay"><h3 md-truncate><ng-md-icon icon="file_download" size="24"></ng-md-icon> {{download.fileSize}}</h3></div>
														</div>
													</a>
													<div flex>
														<h3 md-truncate>{{download.fileName}}</h3>
														<h3 md-truncate>{{download.fileSize}}</h3>
													</div>
													<div flex="100" class="divider"><div></div></div>
												</div>
												<div ng-if="drive.data.docDetail.noDownloads" layout-padding="20">
													<div ng-bind-html="'Description: ' + drive.data.docDetail.noDownloadsMessage"></div>
													<div ng-if="drive.hasPermissions('viewLibrary')"><md-button ng-click="drive.openDocInLibrary(book.bookId)">Open document in Library</md-button></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</td>
						</tr>
                    </tbody>
					<tbody ng-if="drive.data.docs.length == 0 && !drive.isLoading">
						<tr class="data-rows">
							<td></td>
							<td>There are no documents in this folder.</td>
							<td></td>
							<td></td>
						</tr>
					</tbody>


                </table>
            </md-data-table-container>



	</div>
	<div class="md-whiteframe-z1 default-box" style="" ng-if="drive.hasAccess === false">
		<md-data-table-toolbar><h2  flex>Access denied<br /><br /><br /></h2>

		</md-data-table-toolbar>
	</div>
	 <div class="md-whiteframe-z1 default-box" style="" ng-if="drive.hasAccess === null" >
		 <md-data-table-toolbar><h2  flex><br/></h2></md-data-table-toolbar>
            <md-progress-circular style="position: absolute; top: 10px; left: 50%; margin-left: -50px;"  md-diameter="80" md-mode="indeterminate"></md-progress-circular>
	</div>
	

</md-content>