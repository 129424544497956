(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name maintenance.filter:titlecase
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('maintenance')
    .filter('titlecase', titlecase);

  function titlecase() {
    return function (str) {
    	var withspaces = str.replace(/([a-z])([A-Z])/g, "$1 $2");
		return withspaces.charAt(0).toUpperCase() + withspaces.slice(1);
    };
  }
}());
