<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">

    <div class="md-whiteframe-z1 default-box" style="" ng-if="healthcheck.hasAccess == true" >


        <md-data-table-toolbar>
            <h2 flex wraps-tooltip="healthcheck-title">Health Check</h2>
            <md-button class="md-raised md-primary" ng-click="healthcheck.getData()">Re-run Health Check</md-button>
            <!--<pre>d = {{healthcheck.params|json}}</pre>-->
        </md-data-table-toolbar>

        <md-data-table-container class="table-outer">

            <md-progress-linear md-mode="query" ng-show="healthcheck.isLoading"></md-progress-linear>
            <table md-data-table  > <!--md-row-select="selected"-->
                <thead ng-show="!healthcheck.isLoading">
                    <td wraps-tooltip="healthcheck-search-filters-id">
                        <md-input-container style="width:100%">
                            <label>Filter ID</label>
                            <input ng-model="healthcheck.listFilter.bookIdLong" aria-label="Filter book id">
                        </md-input-container>
                    </td>
                    <td wraps-tooltip="healthcheck-search-filters-code">
                        <md-input-container style="width:100%">
                            <label>Filter Code</label>
                            <input ng-model="healthcheck.listFilter.bookCode" aria-label="Filter book code">
                        </md-input-container></td>
                    <td wraps-tooltip="healthcheck-search-filters-error">
                        <md-input-container style="width:100%">
                            <label>Filter Error</label>
                            <input ng-model="healthcheck.listFilter.error" aria-label="Filter book error">
                        </md-input-container>
                    </td>
                </thead>
                <thead>
                <tr ng-show="healthcheck.data.docErrors">
                    <th ng-click="sortBy('bookIdLong')" width="60px"><span wraps-tooltip="healthcheck-heading-id">ID <ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: healthcheck.orderBy === 'bookIdLong', reverse: healthcheck.reverse}"></ng-md-icon></span></th>
                    <th ng-click="sortBy('bookCode')"><span wraps-tooltip="healthcheck-heading-code">Code <ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: healthcheck.orderBy === 'bookCode', reverse: healthcheck.reverse}"></ng-md-icon></span></th>
                    <th ng-click="sortBy('error')"><span wraps-tooltip="healthcheck-heading-error">Error <ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: healthcheck.orderBy === 'error', reverse: healthcheck.reverse}"></ng-md-icon></span></th>
                </tr>
                </thead>
                <tbody ng-repeat="book in healthcheck.data.docErrors | filter: healthcheck.listFilter | orderBy: healthcheck.orderBy : healthcheck.reverse | limitTo: healthcheck.bookQuery.limit: (healthcheck.bookQuery.page- 1) * healthcheck.bookQuery.limit">
                <tr class="data-rows" ng-if="healthcheck.params.doc != book.bookIdLong" ng-click="healthcheck.openDoc(book.bookIdLong)" wraps-tooltip="healthcheck-doc-list">
                    <td>{{book.bookIdLong}}</td>
                    <td>{{book.bookCode}}</td>
                    <td>{{book.error}}</td>
                </tr>
                <tr class="details-row" id="anchorBook{{book.bookId}}" ng-show="healthcheck.params.doc == book.bookId">
                    <td colspan="99" class="bookTd">
                        <div class="main-outer">
                            <div class="main-inner md-whiteframe-z2">
                                <div class="greyBarWrapper layout-row" layout="row" ng-click="healthcheck.closeDoc()" ng-class="{ 'complianceSelect': book.complianceSelect}" role="button" tabindex="0">
                                    <h3 flex="" class="ng-binding flex">{{book.bookCode}}<span ng-if="!book.bookCode">{{book.bookId | seven}}</span></h3>
                                </div>
                                <div class="healthcheck-data layout-padding" id="book-info-{{book.bookId | seven}}">
                                    <div style="float:right;" ng-if="healthcheck.hasPermissions('viewLibrary')"><md-button class="md-raised" ng-click="healthcheck.openDocInLibrary(book.bookIdLong)">Open document in Library</md-button></div>
                                    <h3><span style="font-weight: 700 !important;" class="ng-binding">{{book.bookName}}</span><br>
                                        Code: <span style="font-weight: 500 !important;" class="ng-binding">{{book.bookCode}}</span><br>
                                        ID: <span style="font-weight: 500 !important;" class="ng-binding">{{book.bookId|seven}}</span></h3>
                                    <md-progress-linear md-mode="query" ng-show="healthcheck.docLoading"></md-progress-linear>
                                    <div ng-if="book.workflow_error && !healthcheck.docLoading" class="healthcheck-workflows">
                                        <h4>{{book.workflow_error}}</h4>
                                        Document Status: <span style="font-weight: 500 !important;" ng-if="book.workflow_workflow_status == 0">IN</span><span style="font-weight: 500 !important;">COMPLETE</span><br/>
                                        Workflow Position: <span style="font-weight: 500 !important;">{{book.workflow_approvalStatus + 1}} of {{book.workflow_approvalListEnd + 1}} - {{book.workflow_approvalList[book.workflow_approvalStatus].Role}}</span><br/>
                                        <h4>Workflow:</h4>
                                        <div class="healthcheck-table">
                                            <md-data-table-container>
                                                <table class="hc-table">
                                                    <tr class="hc-tableHeading">
                                                        <th>Position</th>
                                                        <th>Content</th>
                                                        <th>Role</th> 
                                                        <th>Team</th>
                                                        <th>User</th>
                                                    </tr>
                                                    <tr ng-repeat="stage in book.workflow_approvalList">
                                                        <td md-colors="$index == book.workflow_approvalStatus?{background:'primary-100'}:{}">{{$index + 1}}</td>
                                                        <td md-colors="$index == book.workflow_approvalStatus?{background:'primary-100'}:{}">{{stage.content}}</td>
                                                        <td md-colors="$index == book.workflow_approvalStatus?{background:'primary-100'}:{}">{{stage.Role}}</td>
                                                        <td md-colors="$index == book.workflow_approvalStatus?{background:'primary-100'}:{}">{{stage.Team}}</td>
                                                        <td md-colors="$index == book.workflow_approvalStatus?{background:'primary-100'}:{}">{{stage.User}}</td>
                                                    </tr>
                                                </table>
                                            </md-data-table-container>
                                        </div>
                                        <h4>Content Statuses:</h4>
                                        <div class="healthcheck-table">
                                            <md-data-table-container>
                                                <table class="hc-table">
                                                    <tr class="hc-tableHeading">
                                                        <th>Content Type Group</th>
                                                        <th>Workflow Position</th>
                                                        <th>Count</th>
                                                        <th>Status</th>
                                                    </tr>
                                                    <tr ng-repeat="content in book.workflow_contentStatuses">
                                                        <td>{{content.ContentTypeGroup}}</td>
                                                        <td>{{content.approvalStatus + 1}} of {{book.workflow_approvalListEnd + 1}} - {{book.workflow_approvalList[content.approvalStatus].Role}}</td>
                                                        <td>{{content.count}}</td>
                                                        <td><span style="font-weight: 500 !important;" ng-if="book.status == 0">IN</span><span style="font-weight: 500 !important;">COMPLETE</span></td>
                                                    </tr>
                                                </table>
                                            </md-data-table-container>
                                        </div>
                                    </div>
                                    <div ng-if="book.validation_errors && !healthcheck.docLoading">
                                        <h4>Validation errors on the following fields:</h4>
                                        <p ng-repeat="field in book.validation_errors">{{field|titlecase}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
                <tbody ng-if="!healthcheck.data.docErrors && !healthcheck.isLoading">
                <tr class="data-rows">
                    <td></td>
                    <td>All is well.</td>
                    <td></td>
                    <td></td>
                </tr>
                </tbody>


            </table>
        </md-data-table-container>
        <md-data-table-pagination md-limit="healthcheck.bookQuery.limit" md-page="healthcheck.bookQuery.page" md-total="{{(healthcheck.data.docErrors | filter: healthcheck.listFilter).length}}"></md-data-table-pagination>

    </div>
</md-content>
