<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">


    <div class="md-whiteframe-z1 default-box home-intro" >
        <md-content layout-padding >

			<!--intro-->
            <h2  class="" wraps-tooltip="dash-title">Welcome to Wraps</h2>

            <h4 wraps-tooltip="dash-intro">Write, Review, Approve, Publish, Store. All your {{home.clientConfig.name}} documents. Here, in

            one place.</h4>

			<div layout="row" layout-align="center center" ng-if="home.hasPermissions('viewLibrary')">
				<md-button class="md-button md-primary md-raised" ui-sref="library" wraps-tooltip="dash-library-btn">Go to Library</md-button>
			</div>


        </md-content>



    </div>


</md-content>
<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">
	<div class="default-box" layout="row" layout-wrap  layout-align="start start">
		<md-grid-list class="dashboardgrid"
				md-cols-xs="1" md-cols-sm="2" md-cols-md="3" md-cols-gt-md="3" md-cols-gt-lg="5"
				md-row-height-gt-md="1:1" md-row-height="2:2"
				md-gutter="12px" md-gutter-gt-sm="8px" flex="100" >
			<md-grid-tile class="md-whiteframe-z1" md-colspan-sm="2" md-colspan-xs="1">
				<md-content layout-padding layout="row" layout-wrap>
					<div flex="100">
						<h3 wraps-tooltip="dash-profile-title">Your profile</h3>
					</div>
					<div width="50px" layout="row" layout-align="center center" wraps-tooltip="dash-profile-avatar">
						<img ng-if="$storage.user.profilePic != '' && $storage.user.profilePic != null" ng-src="{{home.apiUrl}}/containers/getthumbnail/{{$storage.user.profilePic}}" class="user-avatar" alt="User Avatar"/>
						<div ng-if="$storage.user.profilePic == '' || $storage.user.profilePic == null" class="user-avatar initials {{$storage.user.lastName|lowercase|limitTo:1}}" >{{$storage.user.firstName|uppercase|limitTo:1}}{{$storage.user.lastName|uppercase|limitTo:1}}</div>
					</div>
					<div flex="auto" layout="row" layout-align="start center" wraps-tooltip="dash-profile-name">
						<h4>{{$storage.user.firstName}} {{$storage.user.lastName}}</h4>
					</div>
					<div flex="100" >
						<p wraps-tooltip="dash-profile-role-team">Wraps role: <strong>{{$storage.user.Roles.name}} - {{$storage.user.Teams.teamName}}</strong></p>
						<p wraps-tooltip="dash-profile-contact-email">Notification email: <strong>{{$storage.user.contactEmail}}</strong></p>
					</div>
					<div flex="100" layout="row" layout-align="end end" >
						<md-button class="md-primary" ui-sref="profile" wraps-tooltip="dash-profile-btn">Edit details</md-button>
					</div>
				</md-content>
			</md-grid-tile>
			<!--<md-grid-tile class="md-whiteframe-z1">
				<md-content layout-padding layout="row" layout-wrap>
					<div>
						<h3 wraps-tooltip="dash-risk-title">User Risk Profile</h3>
					</div>
					<div flex="100" wraps-tooltip="dash-risk-chart">
						<doughnut-chart primary="{value:64,colour:'#FFC107'}"></doughnut-chart>
					</div>
					<div flex="100" >
						<p wraps-tooltip="dash-risk-rating"><strong>64</strong> - high risk</p>
					</div>
				</md-content>
			</md-grid-tile>-->

			<md-grid-tile class="md-whiteframe-z1" md-rowspan="1" md-colspan="2" md-colspan-xs="1">
				<md-content layout-padding layout="row" layout-wrap>
					<div flex="100">
						<h3 wraps-tooltip="dash-actions-title">Your Actions</h3>
						<p>Actions will appear in the Library as <ng-md-icon md-colors="{color:'blue'}" icon="error" size="18"></ng-md-icon>or <ng-md-icon md-colors="{color:'blue'}" icon="error_outline" size="18"></ng-md-icon>icons; these mean that you or your team have actions to carry out, like submitting, reviewing or publishing. A <ng-md-icon md-colors="{color:'red'}" icon="warning" size="18"></ng-md-icon>icon means you have a black asset and need to withdraw the document.</p>

					</div>
					<div flex="33" ng-if="!home.isLoading" wraps-tooltip="dash-actions-chart">
						<doughnut-chart total="home.actionsDoughnutTotal" series="home.actionsDoughnutSeries"></doughnut-chart>
					</div>
					<div flex="33" ng-if="home.isLoading">
						<md-progress-circular style="left: 50%; margin-left: -50px;" class="animate-if"  md-diameter="80" md-mode="indeterminate"></md-progress-circular>
					</div>
					<div flex="66" >
						<p wraps-tooltip="dash-actions-all"><strong>{{home.activeDocuments.totalActiveDocuments.documents}}</strong> Open documents that are with you or your team</p>
						<p wraps-tooltip="dash-actions-team"><strong>{{home.activeDocuments.teamActiveDocuments.documents}}</strong> Open documents that are with your team</p>
						<p wraps-tooltip="dash-actions-user"><strong>{{home.activeDocuments.personalActiveDocuments.documents}}</strong> Open documents that are with you</p>
					</div>
				</md-content>
			</md-grid-tile>
			<md-grid-tile class="md-whiteframe-z1" md-rowspan="1" md-colspan="2" md-colspan-xs="1">
				<md-content layout-padding layout="row" layout-wrap>
					<div flex="100">
						<h3 wraps-tooltip="dash-involved-title">Open documents you're involved in <ng-md-icon icon="help" size="20" style="fill:grey;"><md-tooltip>Open the tool tips bar for more information</md-tooltip></ng-md-icon></h3>
						<p>Open documents are either 'in-work' or 'live' and in the process of being published and completed. You are involved in these documents - either you created them, or have been added to the workflow by a colleague. They may be on their way to you, on their way back, or heading off for completion.</p>
					</div>
					<div flex="33" ng-if="!home.isLoading" wraps-tooltip="dash-involved-chart">
						<doughnut-chart primary="home.primaryDocumentsInvolvedIn" total="home.totalDocumentsInvolvedIn" series="home.seriesDocumentsInvolvedIn"></doughnut-chart>
					</div>
					<div flex="33" ng-if="home.isLoading">
						<md-progress-circular style="left: 50%; margin-left: -50px;" class="animate-if"  md-diameter="80" md-mode="indeterminate"></md-progress-circular>
					</div>
					<div flex="66" >
						<p wraps-tooltip="dash-involved-total"><strong>{{home.documents.totalDocuments.documents}}</strong> Total open documents in Wraps</p>
						<p wraps-tooltip="dash-involved-all"><strong>{{home.documents.totalDocumentsInWorkFlow}}</strong> Open documents that you or your team are involved in</p>
						<p wraps-tooltip="dash-involved-team"><strong>{{home.documents.totalDocumentsInWorkFlowTeam}}</strong> Open documents that your team are involved in</p>
						<p wraps-tooltip="dash-involved-user"><strong>{{home.documents.totalDocumentsInWorkFlowPersonal}}</strong> Open documents that you are involved in</p>
					</div>
				</md-content>
			</md-grid-tile>



			<md-grid-tile class="md-whiteframe-z1" md-rowspan="2" md-colspan-sm="2" md-colspan-xs="1">
				<md-content layout-padding layout="row" layout-wrap>
					<div flex="100">
						<h3 wraps-tooltip="dash-status-title">Your documents</h3>
						<p>Information about all the documents owned by you.</p>
					</div>
					<div flex></div>
					<div flex="66" flex-sm="33" flex-xs="33"ng-if="!home.isLoading" wraps-tooltip="dash-status-chart">
						<doughnut-chart total="home.youDocsDoughnutTotal" series="home.youDocsDoughnutSeries"></doughnut-chart>
					</div>
					<div flex="66" flex-sm="33" flex-xs="33" ng-if="home.isLoading">
						<md-progress-circular style="left: 50%; margin-left: -50px;" class="animate-if"  md-diameter="80" md-mode="indeterminate"></md-progress-circular>
					</div>
					<div flex></div>
					<div flex="100">
						<p wraps-tooltip="dash-status-all"><strong>{{home.yourDocuments.total}}</strong> Documents owned by you in Wraps history</p>
						<p wraps-tooltip="dash-status-open"><strong>{{home.yourDocuments.open}}</strong> Open documents owned by you </p>
						<p wraps-tooltip="dash-status-inwork"><strong>{{home.yourDocuments.inWork}}</strong> In-Work documents owned by you</p>
						<p wraps-tooltip="dash-status-ready"><strong>{{home.yourDocuments.ready}}</strong> Ready documents owned by you</p>
						<p wraps-tooltip="dash-status-active"><strong>{{home.yourDocuments.actv}}</strong> Active documents owned by you</p>
						<p wraps-tooltip="dash-status-live"><strong>{{home.yourDocuments.live}}</strong> Live documents owned by you</p>
						<p wraps-tooltip="dash-status-review"><strong>{{home.yourDocuments.liveR}}</strong> Live (Review) documents owned by you</p>
						<p wraps-tooltip="dash-status-expired"><strong>{{home.yourDocuments.expd}}</strong> Expired documents owned by you</p>
						<p wraps-tooltip="dash-status-forced"><strong>{{home.yourDocuments.expdF}}</strong> Expired (Forced) documents owned by you</p>
						<p wraps-tooltip="dash-status-withdrawn"><strong>{{home.yourDocuments.expdW}}</strong> Expired (Withdrawn) documents owned by you</p>
					</div>
				</md-content>
			</md-grid-tile>

			<!--<md-grid-tile class="md-whiteframe-z1" md-rowspan="2" md-colspan="2" md-colspan-sm="1" md-colspan-xs="1">
				<md-content layout-padding layout="row" layout-wrap>
					<div flex="100">
						<h3 wraps-tooltip="dash-submissions-title">Your Submissions</h3>
					</div>
					<div flex="50" wraps-tooltip="dash-submissions-chart">
						<doughnut-chart total="{value:560,colour:'#ECEFF1'}" series="[{value:15, colour:'#F44336'},{value:435, colour:'#FFC107'},{value:79, colour:'#8BC34A'}]"></doughnut-chart>
					</div>
					<div flex="50" >
						<p wraps-tooltip="dash-submissions-all"><strong>560</strong> Documents you've submitted</p>
						<p wraps-tooltip="dash-submissions-rejected"><strong>15</strong> Documents rejected</p>
						<p wraps-tooltip="dash-submissions-review"><strong>435</strong> Documents in review</p>
						<p wraps-tooltip="dash-submissions-approved"><strong>79</strong> Documents approved</p>
					</div>
					<div flex="50" layout="row" layout-align="end center">
						<p wraps-tooltip="dash-submissions-rating"><strong>35%</strong> First time approval rate</p>
					</div>
					<div flex="50"  wraps-tooltip="dash-submissions-rating-chart">
						<doughnut-chart primary="{value:97,colour:'#8BC34A'}"></doughnut-chart>
					</div>
				</md-content>
			</md-grid-tile>-->

			<md-grid-tile class="md-whiteframe-z1" md-rowspan="1" md-colspan="2" md-colspan-xs="1">
				<md-content layout-padding layout="row" layout-wrap>
					<div flex="100">
						<h3 wraps-tooltip="dash-black-assets-title">Black assets <ng-md-icon icon="help" size="20" style="fill:grey;"><md-tooltip>Open the tool tips bar for more information</md-tooltip></ng-md-icon></h3>
						<p>A black asset is a document that is published outside its approval window (i.e. it has expired yet still available for viewing).</p>
					</div>
					<div flex="33" ng-if="!home.isLoading" wraps-tooltip="dash-black-assets-chart">
						<doughnut-chart primary="home.blackAssetsPrimary" total="home.blackAssetsTotal" series="home.blackAssetsSeries"></doughnut-chart>
					</div>
					<div flex="33" ng-if="home.isLoading">
						<md-progress-circular style="left: 50%; margin-left: -50px;" class="animate-if"  md-diameter="80" md-mode="indeterminate"></md-progress-circular>
					</div>
					<div flex="66" >
						<p wraps-tooltip="dash-black-assets-total"><strong>{{home.blackAssets.totalBlackAssets.blackAssets}}</strong> Black assets in Wraps Library</p>
						<p wraps-tooltip="dash-black-assets-all"><strong>{{home.blackAssets.blackAssetsOwned.blackAssets}}</strong> Black assets owned by you</p>
						<p wraps-tooltip="dash-black-assets-finprom"><strong>{{home.blackAssets.blackAssetsFinProms.blackAssets}}</strong> Financial promotion black assets</p>
						<p wraps-tooltip="dash-black-assets-nonfinprom"><strong>{{home.blackAssets.blackAssetsNonFinProms.blackAssets}}</strong> Non-financial promotion black assets</p>
					</div>
				</md-content>
			</md-grid-tile>

		  </md-grid-list>
	</div>
</md-content>
