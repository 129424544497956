(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name footer.controller:FooterCtrl
   *
   * @description
   *
   */
  angular
    .module('footer')
    .controller('FooterCtrl', FooterCtrl);

  function FooterCtrl(
    $document,
    $filter,
    $interval,
    $localStorage,
    $location,
    $mdToast,
    $q,
    $scope,
    AppVersions,
    Wraps) {
    var vm = this;
    vm.ctrlName = 'FooterCtrl';

	//Check for WRAPS app-version updates.
    $interval(function () {
      if (!Wraps.updateToast) {
        AppVersions.findOne({filter: {order: "appVersionId DESC"}}).$promise.then(function (version) {
          if (Wraps.thisAppVersion !== version.appVersionId) {
            Wraps.updateToast = true;
            $mdToast.show(
              $mdToast.simple()
                .textContent('A newer version of WRAPS (' + version.appVersionId + ') is available')
                .hideDelay(0)
                .action('Update')
                .highlightAction(true)
                .highlightClass('md-accent')
                .toastClass('update-toast')
                .position('bottom')
            ).then(function (response) {
              if (response === 'ok') {
                Wraps.reload();
              }
            }).catch(function () {
                Wraps.updateToast = false;
                console.warn('Versions toast closed prematurely due to another toast being opened');
            });
          }
        });
      }
    }, 300000);

    // Displays errors as Material Design "Toast" elements
    $scope.$watch(function () {
      return Wraps.httpErrors;
    }, function () {
      // console.log("httpErrors:",newVal,oldVal);
      if (Wraps.httpErrors) {
        $mdToast.show(
            $mdToast.simple()
                .textContent(Wraps.httpErrors.message)
                .hideDelay(0)
                .action('Close')
                .highlightAction(true)
                .highlightClass('md-accent')
                .toastClass('update-toast')
                .hideDelay(3000)
                .position('bottom')
        ).then(function () {
          Wraps.httpErrors = false;
        }).catch(function () {
            console.warn('Http errors toast closed prematurely due to another toast being opened');
        });
      }
    });

	// Displays messages as Material Design "Toast" elements
    $scope.$watch(function () {
      return Wraps.toastMessages;
    }, function () {
      // console.log("toastMessages:",newVal,oldVal);
      if (Wraps.toastMessages) {
        $mdToast.show(
            $mdToast.simple()
                .textContent(Wraps.toastMessages.message)
                .hideDelay(0)
                .action('Close')
                .highlightAction(true)
                .highlightClass('md-accent')
                .toastClass('update-toast')
                .hideDelay(5000)
                .position('bottom')
        ).then(function () {
          Wraps.toastMessages = false;
        }).catch(function () {
            console.warn('Messages toast closed prematurely due to another toast being opened');
        });
      }
    });
  }
}());
