<md-list id="chapterSort-{{book.bookId}}" style="padding-bottom: 10px;">
    <md-list-item layout="row" flex="100" layout-wrap ng-repeat="chapter in book.Children">
        <div ng-class="{ 'selectedChapter' : tabBook.inSelected(chapter.structureId)}" flex="100" layout="row" layout-wrap ng-click="tabBook.toggleChapter(chapter.structureId); tabBook.library.goToAnchor('anchorChapter' + chapter.contentId)" id="anchorChapter{{chapter.contentId}}" ng-if="chapter.versions.Contents.contentTypeId != 13">
            <ng-md-icon ng-if="chapter.chapterTypeId!=1 && chapter.chapterTypeId" icon="star_rate" style="fill: black;  width:30px; height: 30px; position: absolute; top:5px; left:-25px;" size="30"></ng-md-icon>
            <div flex ng-class="[{ 'caps' : book.DocTypes.docTypeId == 93 }, chapter.versions.Contents.ContentTypes.contentTypeName, chapter.versions.Contents.Departments.departmentName,  tabBook.library.checkVersionChildren(chapter), tabBook.library.checkVersion(chapter) ]" ng-bind-html="tabBook.showSearch(chapter.versions.versiondata)"></div>
            <span style="margin: 7px" ng-if="chapter.searchTotal>0 && !tabBook.inSelected(chapter.structureId)" class="searchCount">{{chapter.searchTotal}}</span>
            <md-button wraps-tooltip="expland-all-sections" aria-label="Expand all sections" class="md-icon-button" style="height:30px;" ng-show="tabBook.opentab.selected.structure[0] == chapter.structureId || tabBook.inSelected(chapter.structureId) && !tabBook.library.isReorderingChildren(chapter)" ng-click="$event.stopPropagation(); tabBook.expandSections(chapter.Children);">
                <md-tooltip>Expand all sections</md-tooltip>
                <ng-md-icon icon="format_line_spacing" style="fill: grey;" size="20"></ng-md-icon>
            </md-button>
            <md-button wraps-tooltip="collaps-all-sections" aria-label="Collapse all sections" class="md-icon-button" style="height:30px;" ng-show="tabBook.opentab.selected.structure[0] == chapter.structureId || tabBook.inSelected(chapter.structureId) && !tabBook.library.isReorderingChildren(chapter)" ng-click="$event.stopPropagation(); tabBook.collapseSections(chapter.Children);">
                <md-tooltip>Collapse all sections</md-tooltip>
                <ng-md-icon icon="vertical_align_center" style="fill: grey;" size="20"></ng-md-icon>
            </md-button>
            <content-menu index="$index" ng-if="!tabBook.library.isReorderingChildren(book)" child="chapter" parentbook="book" parent="book"></content-menu>


            <div ng-if="tabBook.library.isReorderingChildren(book)">
                <md-button wraps-tooltip="reorder-content" style="float:right;" aria-label="Order" class="drag-handle">
                    <ng-md-icon class="drag-handle" icon="drag_handle"
                        style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
                </md-button>
            </div>
        </div>

        <div flex="100" layout="row" layout-wrap ng-if="book.DocTypes.docTypeId == 1" ng-if="chapter.versions.Contents.contentTypeId != 13">
            <div flex="100">

                <!--LEVEL1 LIST-->
                <md-list ng-if="tabBook.inSelected(chapter.structureId)">

                    <md-list-item ng-if="level1.versions.Contents.contentTypeId < 6" class="level1" ng-class="{ 'selectedGroup' : tabBook.opentab.selected.structure[0] == level1.structureId }" layout="row" layout-wrap ng-repeat="level1 in chapter.Children ">

                        <div ng-class="{ 'selectedCopy' : tabBook.opentab.selected.structure[0] == level1.structureId }"
                            layout="row" flex="100" ng-click=" tabBook.toggleSection(level1.structureId);">
                            <div flex ng-class="[level1.versions.Contents.ContentTypes.contentTypeName, level1.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(level1)]" ng-bind-html="tabBook.makeBold(level1.versions.versiondata,book.boldWords,book.emphasis)">
                            </div>

                            <content-menu index="$index" child="level1" parentbook="book" parent="chapter">
                            </content-menu>
                            <span style="margin: 5px 10px;" ng-if="level1.searchTotal>0 && tabBook.opentab.selected.structure[0] != level1.structureId" class="searchCount">{{level1.searchTotal}}</span>


                        </div>

                        <div layout="row" flex="100" ng-show="tabBook.inSection(level1.structureId)">
                            <!--LEVEL2 LIST-->

                            <md-list style="width: 100%;">
                                <md-list-item class="level1" ng-class="{ 'selectedGroup' : tabBook.opentab.selected.structure[0] == level2.structureId }" layout="row" layout-wrap ng-repeat="level2 in level1.Children |  filter: {versions: {Contents: {contentTypeId: '!5'}}} | orderBy:'order'">
                                    <div layout="row" flex="100" ng-if="level2.versions.Contents.contentTypeId === 11" class="Benefit TableHeader">
                                        <!--<pre>{{level1.Children | json }}</pre>-->
                                        <table width="100%">
                                            <thead>
                                                <tr ng-click="tabBook.opentab.selected.structure[0] = (tabBook.opentab.selected.structure[0]==level2.structureId ? false : level2.structureId )" ng-class="[{ 'selectedCopy' : tabBook.opentab.selected.structure[0] == level2.structureId },tabBook.library.checkVersion(level2)]">
                                                    <th ng-repeat="item in level2.tableHeader | orderBy:'order'" width="{{tabBook.library.getTableWidths(item.width, level2.tableHeader)}}%">
                                                        <div ng-bind-html="item.name"></div>
                                                    </th>
                                                    <th width="5%">
                                                        <content-menu index="$index" child="level2" parentbook="book"
                                                            parent="level1"></content-menu>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr ng-click="tabBook.toggleStructure(row.structureId)" ng-class="[{ 'selectedCopy' : tabBook.opentab.selected.structure[0] == row.structureId },tabBook.library.checkVersion(row)]" ng-repeat="row in level2.Children | filter: {versions: {Contents: {contentTypeId: 12}}}  | orderBy:'order'">
                                                    <td ng-repeat="item in row.tableRow | orderBy:'order'">
                                                        <div
                                                            ng-bind-html="tabBook.makeBold(item.data,book.boldWords,book.emphasis)">
                                                        </div>
                                                    </td>
                                                    <td width="5%">
                                                        <content-menu index="$index" child="row" parentbook="book" parent="level2"></content-menu>
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>

                                    </div>




                                    <div flex="100" layout="row" ng-if="level2.versions.Contents.contentTypeId !== 11" ng-class="{ 'selectedCopy' : tabBook.opentab.selected.structure[0] == level2.structureId }" ng-click="tabBook.toggleStructure(level2.structureId)">

                                        <div flex ng-class="[level2.versions.Contents.ContentTypes.contentTypeName, level2.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(level2)]" ng-bind-html="tabBook.makeBold(level2.versions.versiondata,book.boldWords,book.emphasis)">
                                        </div>
                                        <content-menu index="$index" child="level2" parentbook="book" parent="level1">
                                        </content-menu>
                                    </div>

                                    <div layout="row" flex="100" ng-if="level2.versions.Contents.contentTypeId !== 11">
                                        <!--LEVEL3 LIST-->
                                        <md-list style="width: 100%;">
                                            <md-list-item class="level1" ng-class="{ 'selectedGroup' : tabBook.opentab.selected.structure[0] == level3.structureId }" layout="row" layout-wrap ng-repeat="level3 in level2.Children | orderBy:'order'">

                                                <div flex="100" layout="row" ng-class="{ 'selectedCopy' : tabBook.opentab.selected.structure[0] == level3.structureId }" ng-click="tabBook.opentab.selected.structure[0] = (tabBook.opentab.selected.structure[0]==level3.structureId ? false : level3.structureId )">
                                                    <div flex ng-class="[level3.versions.Contents.ContentTypes.contentTypeName, level3.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(level3)]" ng-bind-html="tabBook.makeBold(level3.versions.versiondata,book.boldWords,book.emphasis)">
                                                    </div>
                                                    <content-menu index="$index" child="level3" parentbook="book" parent="level2"></content-menu>
                                                </div>
                                            </md-list-item>
                                        </md-list>

                                    </div>
                                </md-list-item>

                                <md-list-item style="width: 100%;" ng-if="tabBook.library.hasContentType(level1,5)" flex="100" class="Benefit" layout="row" layout-wrap>
                                    <div layout="row" flex="100">


                                        <table width="100%" id="anchorSection{{level1.Children[0].contentId}}">
                                            <thead>
                                                <tr>
                                                    <th>Benefits</th>
                                                    <th width="12%" ng-if="book.levels.level1.active" ng-bind-html="book.levels.level1.name"></th>
                                                    <th width="12%" ng-if="book.levels.level2.active" ng-bind-html="book.levels.level2.name"></th>
                                                    <th width="12%" ng-if="book.levels.level3.active" ng-bind-html="book.levels.level3.name"></th>
                                                    <th width="12%" ng-if="book.levels.level4.active" ng-bind-html="book.levels.level4.name"></th>
                                                    <th width="12%" ng-if="book.levels.payment.active" ng-bind-html="book.levels.payment.name"></th>
                                                    <th ng-style="{ width: book.expWidth}">Explanation of Benefits</th>
                                                </tr>
                                            </thead>
                                            <tbody class="paragraphSort" id="sort-{{level1.structureId}}">
                                                <tr ng-click="tabBook.opentab.selected.structure[0] = (tabBook.opentab.selected.structure[0]==benefit.structureId ? false : benefit.structureId )" ng-class="[{ 'selectedCopy' : tabBook.opentab.selected.structure[0] == benefit.structureId },tabBook.library.checkVersion(benefit)]" ng-repeat="benefit in level1.Children" data-index="{{$index}}" data-id="{{benefit.structureId}}" data-order="{{$index}}">
                                                    <td ng-if-start="benefit.versions.Contents.contentTypeId == 5" ng-class="benefit.versions.Contents.Departments.departmentName">
                                                        <div ng-bind-html="tabBook.makeBold(tabBook.library.nl2br(benefit.benefit.name),book.boldWords,book.emphasis)"></div>
                                                    </td>
                                                    <td ng-if="book.levels.level1.active" ng-class="{'linked' : benefit.benefit.options.level1 == 'Linked'}">
                                                        <div ng-bind-html="tabBook.makeBold(tabBook.library.nl2br(benefit.benefit.level1),book.boldWords,book.emphasis)"></div>
                                                    </td>
                                                    <td ng-if="book.levels.level2.active" ng-class="{'linked' : benefit.benefit.options.level2 == 'Linked'}">
                                                        <div ng-bind-html="tabBook.makeBold(tabBook.library.nl2br(benefit.benefit.level2),book.boldWords,book.emphasis)"></div>
                                                    </td>
                                                    <td ng-if="book.levels.level3.active" ng-class="{'linked' : benefit.benefit.options.level3 == 'Linked'}">
                                                        <div ng-bind-html="tabBook.makeBold(tabBook.library.nl2br(benefit.benefit.level3),book.boldWords,book.emphasis)"></div>
                                                    </td>
                                                    <td ng-if="book.levels.level4.active" ng-class="{'linked' : benefit.benefit.options.level4 == 'Linked'}">
                                                        <div ng-bind-html="tabBook.makeBold(tabBook.library.nl2br(benefit.benefit.level4),book.boldWords,book.emphasis)"></div>
                                                    </td>

                                                    <td ng-if="book.levels.payment.active" ng-class="{'linked' : benefit.benefit.options.payment === 'Linked'}">
                                                        <div ng-bind-html="tabBook.makeBold(benefit.benefit.payment,book.boldWords,book.emphasis)"></div>
                                                    </td>
                                                    <td ng-if-end ng-class="{'linked' : benefit.benefit.options.explanation === 'Linked'}">
                                                        {{benefits}}
                                                        <span ng-if="!tabBook.library.isReorderingChildren(level1)">
                                                            <content-menu index="$index" child="benefit" parentbook="book" parent="level1"></content-menu>
                                                        </span>

                                                        <div ng-if="tabBook.library.isReorderingChildren(level1)">
                                                            <md-button style="float:right;" aria-label="Order" class="drag-handle">
                                                                <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
                                                            </md-button>
                                                        </div>

                                                        <div ng-bind-html="tabBook.makeBold(benefit.benefit.explanation,book.boldWords,book.emphasis)"></div>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>



                                </md-list-item>

                                <!--<md-list-item style="width: 100%;" ng-if="tabBook.library.hasContentType(level1,11)"  flex="100" class="Benefit TableHeader"  layout="row" layout-wrap >-->

                                <!--<div   layout="row"  flex="100"  ng-repeat="header in level1.Children | filter: {versions: {Contents: {contentTypeId: 11}}}  | orderBy:'order'">-->
                                <!--&lt;!&ndash;<pre>{{level1.Children | json }}</pre>&ndash;&gt;-->
                                <!--<table width="100%"  >-->
                                <!--<thead>-->
                                <!--<tr-->
                                <!--ng-click="tabBook.opentab.selected.structure[0] = (tabBook.opentab.selected.structure[0]==header.structureId ? false : header.structureId )"-->
                                <!--ng-class="[{ 'selectedCopy' : tabBook.opentab.selected.structure[0] == header.structureId },tabBook.library.checkVersion(header)]">-->
                                <!--<th ng-repeat="item in header.tableHeader | orderBy:'order'" width="{{tabBook.library.getTableWidths(item.width, header.tableHeader)}}%">-->
                                <!--<div ng-bind-html="item.name"></div>-->
                                <!--</th>-->
                                <!--<th width="5%"><content-menu index="$index" child="header"  parentbook="book" parent="level1"></content-menu></th>-->
                                <!--</tr>-->
                                <!--</thead>-->
                                <!--<tbody>-->
                                <!--<tr-->
                                <!--ng-click="tabBook.opentab.selected.structure[0] = (tabBook.opentab.selected.structure[0]==row.structureId ? false : row.structureId )"-->
                                <!--ng-class="[{ 'selectedCopy' : tabBook.opentab.selected.structure[0] == row.structureId },tabBook.library.checkVersion(row)]"-->
                                <!--ng-repeat="row in header.Children | filter: {versions: {Contents: {contentTypeId: 12}}}  | orderBy:'order'"-->
                                <!--&gt;-->
                                <!--<td ng-repeat="item in row.tableRow | orderBy:'order'" >-->
                                <!--<div ng-bind-html="item.data"></div>-->
                                <!--</td>-->
                                <!--<td width="5%"><content-menu index="$index" child="row"  parentbook="book" parent="header"></content-menu></td>-->
                                <!--</tr>-->
                                <!--</tbody>-->

                                <!--</table>-->

                                <!--</div>-->
                                <!--</md-list-item>-->

                                <!--</md-list>-->





                        </div>
                    </md-list-item>
                    <md-list-item style="width: 100%;" ng-if="tabBook.library.hasContentType(chapter,6)" flex="100" class="Benefit Glossary" layout="row" layout-wrap>

                        <div layout="row" flex="100" ng-class="">

                            <table width="100%">
                                <thead>
                                    <tr>
                                        <th>Defined term</th>
                                        <th>Description</th>
                                        <th>Search words</th>
                                    </tr>
                                </thead>
                                <tbody class="paragraphSort" id="sort-{{chapter.structureId}}">
                                    <tr data-index="{{$index}}" data-id="{{glossary.structureId}}" data-order="{{$index}}" ng-click="tabBook.opentab.selected.structure[0] = (tabBook.opentab.selected.structure[0]==glossary.structureId ? false : glossary.structureId )" ng-class="[{ 'selectedCopy' : tabBook.opentab.selected.structure[0] == glossary.structureId },tabBook.library.checkVersion(glossary)]" ng-repeat="glossary in chapter.Children">
                                        <td ng-if-start="glossary.versions.Contents.contentTypeId === 6" ng-class="glossary.versions.Contents.Departments.departmentName">
                                            <div ng-bind-html="tabBook.makeBold(glossary.glossary.term,book.boldWords,book.emphasis)"></div>
                                        </td>
                                        <td width="50%">
                                            <div ng-bind-html="tabBook.makeBold(glossary.glossary.description,book.boldWords,book.emphasis)"></div>
                                        </td>
                                        <td ng-if-end>
                                            <span ng-if="!tabBook.library.isReorderingChildren(chapter)">
                                                <content-menu index="$index" child="glossary" parentbook="book" parent="chapter"></content-menu>
                                            </span>

                                            <div ng-if="tabBook.library.isReorderingChildren(chapter)">
                                                <md-button style="float:right;" aria-label="Order" class="drag-handle">
                                                    <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
                                                </md-button>
                                            </div>

                                            <div ng-model="glossary.glossary.words">{{glossary.glossary.words}}</div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>




                    </md-list-item>

                    <md-list-item style="width: 100%;" ng-if="tabBook.library.hasContentType(chapter,7)" flex="100" class="Benefit" layout="row" layout-wrap>

                        <div layout="row" flex="100">


                            <table width="100%">
                                <thead>
                                    <tr>
                                        <th>Exclusion</th>
                                        <th>Notes</th>
                                        <th>Rules</th>
                                    </tr>
                                </thead>
                                <tbody class="paragraphSort" id="sort-{{chapter.structureId}}">
                                    <tr data-index="{{$index}}" data-id="{{exclusion.structureId}}" data-order="{{$index}}" ng-click="tabBook.opentab.selected.structure[0] = (tabBook.opentab.selected.structure[0]==exclusion.structureId ? false : exclusion.structureId )" ng-class="[{ 'selectedCopy' : tabBook.opentab.selected.structure[0] == exclusion.structureId },tabBook.library.checkVersion(exclusion)]" ng-repeat="exclusion in chapter.Children">

                                        <td ng-if-start="exclusion.versions.Contents.contentTypeId === 7" ng-class="exclusion.versions.Contents.Departments.departmentName">
                                            <div ng-bind-html="tabBook.makeBold(exclusion.exclusion.name,book.boldWords,book.emphasis)">
                                            </div>
                                        </td>
                                        <td>
                                            <div ng-bind-html="tabBook.makeBold(exclusion.exclusion.notes,book.boldWords,book.emphasis)">
                                            </div>
                                        </td>
                                        <td ng-if-end width="50%">
                                            <span ng-if="!tabBook.library.isReorderingChildren(chapter)">
                                                <content-menu index="$index" child="exclusion" parentbook="book" parent="chapter"></content-menu>
                                            </span>
                                            <div ng-if="tabBook.library.isReorderingChildren(chapter)">
                                                <md-button style="float:right;" aria-label="Order" class="drag-handle">
                                                    <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
                                                </md-button>
                                            </div>
                                            <div ng-bind-html="tabBook.makeBold(exclusion.exclusion.rules,book.boldWords,book.emphasis)"></div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>


                    </md-list-item>

                    <!-- medical word -->
                    <md-list-item style="width: 100%;" ng-if="tabBook.library.hasContentType(chapter,8)" flex="100" class="Benefit Glossary" layout="row" layout-wrap>

                        <div layout="row" flex="100">


                            <table width="100%">
                                <thead>
                                    <tr>
                                        <th>Term</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody class="paragraphSort" id="sort-{{chapter.structureId}}">
                                    <tr data-index="{{$index}}" data-id="{{glossary.structureId}}" data-order="{{$index}}" ng-click="tabBook.opentab.selected.structure[0] = (tabBook.opentab.selected.structure[0]==medical.structureId ? false : medical.structureId )" ng-class="[{ 'selectedCopy' : tabBook.opentab.selected.structure[0] == medical.structureId },tabBook.library.checkVersion(medical)]" ng-repeat="medical in chapter.Children">

                                        <td ng-if-start="medical.versions.Contents.contentTypeId === 8" ng-class="medical.versions.Contents.Departments.departmentName">
                                            <div ng-bind-html="tabBook.makeBold(medical.medical.term,book.boldWords,book.emphasis)"></div>
                                        </td>
                                        <td ng-if-end width="60%">
                                            <span ng-if="!tabBook.library.isReorderingChildren(chapter)">
                                                <content-menu index="$index" child="medical" parentbook="book" parent="chapter"></content-menu>
                                            </span>

                                            <div ng-if="tabBook.library.isReorderingChildren(chapter)">
                                                <md-button style="float:right;" aria-label="Order" class="drag-handle">
                                                    <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>
                                                </md-button>
                                            </div>
                                            <div ng-bind-html="tabBook.makeBold(medical.medical.description,book.boldWords,book.emphasis)"></div>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>


                    </md-list-item>

                </md-list>
            </div>
        </div>




        <div flex="100" layout="row" layout-wrap ng-if="book.DocTypes.docTypeId == 93" ng-if="chapter.versions.Contents.contentTypeId != 13">
            <div flex="100">

                <!--LEVEL1 LIST-->
                <md-list ng-if="tabBook.inSelected(chapter.structureId)"
                    class="paragraphSort" id="sort-{{chapter.structureId}}">

                    <md-list-item class="level1" ng-class="{ 'selectedGroup' : tabBook.opentab.selected.structure[0] == level1.structureId }" layout="row" layout-wrap ng-repeat="level1 in chapter.Children ">

                        <doc-items index="$index" child="level1" parentbook="book" parent="chapter" book="book" prev="chapter.Children[$index-1].versions.Contents.contentTypeId" next="chapter.Children[$index+1].versions.Contents.contentTypeId"></doc-items>

                        <!--LEVEL2 LIST-->
                        <div layout="row" flex="100">
                            <md-list style="width: 100%;" class="paragraphSort" id="sort-{{level1.structureId}}">
                                <!--<div ng-click="tabBook.library.localStorage.structureId = level1.Children[0].parent; tabBook.library.goToAnchor('anchorSection' + level1.Children[0].contentId)" ng-show="(tabBook.library.localStorage.structureId != level1.Children[0].parent) && (level1.Children[0].versions.Contents.contentTypeId == 15 || level1.Children[0].versions.Contents.contentTypeId == 16 || level1.Children[0].versions.Contents.contentTypeId == 17)" ng-if="level1.Children[0]">...</div>-->
                                <md-list-item class="level1" ng-class="{ 'selectedGroup' : tabBook.opentab.selected.structure[0] == level2.structureId }" layout="row" layout-wrap ng-repeat="level2 in level1.Children" ng-show="tabBook.inSection(level1.structureId)">
                                    <doc-items index="$index" child="level2" parentbook="book" parent="level1" book="book" prev="level1.Children[$index-1].versions.Contents.contentTypeId" next="level1.Children[$index+1].versions.Contents.contentTypeId"></doc-items>

                                    <!--LEVEL3 LIST-->
                                    <div layout="row" flex="100">
                                        <md-list style="width: 100%;" class="paragraphSort" id="sort-{{level2.structureId}}">
                                            <md-list-item class="level1" ng-class="{ 'selectedGroup' : tabBook.opentab.selected.structure[0] == level3.structureId }" layout="row" layout-wrap ng-repeat="level3 in level2.Children">

                                                <doc-items index="$index" child="level3" parentbook="book" parent="level2" book="book" prev="level2.Children[$index-1].versions.Contents.contentTypeId" next="level2.Children[$index+1].versions.Contents.contentTypeId">
                                                </doc-items>

                                            </md-list-item>
                                        </md-list>
                                    </div>

                                </md-list-item>
                            </md-list>
                        </div>


                    </md-list-item>

                </md-list>

            </div>
        </div>


        <md-divider></md-divider>
    </md-list-item>
</md-list>
