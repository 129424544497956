(function () {
  'use strict';

  angular
    .module('users')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('users', {
        url: '/users',
        views: {
		  tooltip: {
              templateUrl: 'tooltip/tooltip.tpl.html',
              controller: 'TooltipCtrl',
              controllerAs: 'tooltip'
          },
          navigation: {
            templateUrl: 'navigation/navigation.tpl.html',
            controller: 'NavigationCtrl',
            controllerAs: 'navigation'
          },
          main: {
            templateUrl: 'users/users.tpl.html',
            controller: 'UsersCtrl',
            controllerAs: 'users'
          },
          footer: {
            templateUrl: 'footer/footer.tpl.html',
            controller: 'FooterCtrl',
            controllerAs: 'footer'
          }
        }
      });
  }
}());
