(function () {
  'use strict';

  angular
    .module('reset')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('reset', {
        url: '/reset/:accesstoken',
        views: {
          navigation: {
            templateUrl: 'navigation/navigation.tpl.html',
            controller: 'NavigationCtrl',
            controllerAs: 'navigation'
          },
          main: {
            templateUrl: 'reset/reset.tpl.html',
            controller: 'ResetCtrl',
            controllerAs: 'reset'
          }
        }

      });
  }
}());
