angular
    .module('library')
    .component('inputSwitchlist', {
        templateUrl: 'library/library-components/right-menu/input-switchlist/input-switchlist.tpl.html',
        controller: inputSwitchlistCtrl,
        controllerAs:'inputSwitchlist',
        bindings:{
            book:'=',
            disabled:'=',
            array:'=',
            switch:'=',
            label:'@',
            model:'@'
        }

    });



    
inputSwitchlistCtrl.$inject = [];
function inputSwitchlistCtrl(){
    var vm = this;
    vm.toggleSwitchGroup = function(){
        if(vm.switch.flag){
            _.forEach(vm.book[vm.model], function(value, key) {
                vm.book[vm.model][key] = false;
            });
        }else{
            vm.switch.flag = true;
        }
    }

    vm.toggleSwitchNa = function(){
        if(vm.switch) {
            if (_.indexOf(_.values(vm.book[vm.model]), true) >= 0) {
                vm.switch.flag = false;
            } else {
                vm.switch.flag = true;
            }
        }

    }



}