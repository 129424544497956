(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableUser', readableUser);

  function readableUser(Wraps) {
    return function (id) {
      var user;
      if ((user = _.find(Wraps.users, {id: parseInt(id)})) && user.firstName) {
        return user.firstName + ' ' + user.lastName;
      }
      return 'All';
    };
  }
}());
