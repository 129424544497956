(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.chaptersdialog.controller:ChaptersdialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.chaptersdialog')
    .controller('ChaptersdialogCtrl', ChaptersdialogCtrl);

  var usedChapters;

  function ChaptersdialogCtrl($localStorage, $mdDialog, $q, $scope, $timeout, apiUrl, book, Books, Contents, Containers, Events, History, Structures, Teams, Upload, Users, Versions, Wraps) {
    var vm = this;
    vm.ctrlName = 'ChaptersdialogCtrl';
    $scope.$storage = $localStorage;
    if(!Users.isAuthenticated()){$location.path('/');}


    vm.chapterTypes = Wraps.chapterTypes;

    // speed tweak to pre select products
    vm.chapterTeam = {"teamId":14,"teamName":"Products","shortName":"PROD","departmentId":8,"docOrd":6,"briefOrd":null,"itemOrd":null};

    //vm.books = Books.find({ filter: { where: { bookId: {neq: book.bookId} } } });

    vm.books = Books.find({ filter: { where: {"and":[{ bookId: {neq: book.bookId}},{"docTypeId":book.docTypeId}]},fields: {bookId: true, bookCode: true, bookName:true}}  });

    //Teams for add content owners
    vm.teams = Teams.__get__notDeleted();

    Structures.find({
      filter: {
        where: { bookId: book.bookId, "parent": null  },
        include: "versions"
      }
    }).$promise.then(function(data){
        usedChapters = _.map(data,'versions.contentId');

    });

    vm.updateBookData = Wraps.updateBookData;

    vm.dialogHide = function(data) {
      $mdDialog.hide(data);
    };
    vm.dialogCancel = function(data) {
      $mdDialog.cancel(data);
    };


    vm.getChapters = function (bookId){
      if(bookId) {
        vm.isLoading = true;
        Structures.find({
          filter: {
            where: {bookId: bookId, "parent": null},
            "include": ["versions", {"Children": {"Children": "Children"}}]
          }
        }).$promise.then(function (data) {
          angular.forEach(data, function (item) {
            item.disabled = usedChapters.indexOf(item.versions.contentId) >= 0;
            item.selected = item.disabled;

             if(item.versions.versiondata.charAt(0) === "{"){
               var json = angular.fromJson(item.versions.versiondata);
               item.versions.versiondata = json.chapterCopy;
             }
            
          });
          vm.chapters = data;

          vm.isLoading = false;
        });
      }else{
        vm.chapters = '';
      }
    }

    Wraps.getQREFs(vm.isLoading).then(function(data) {
      vm.QREFs = data;
      //console.log("vm.QREFs",vm.QREFs);
    });




    vm.openChangeDialog = function (filter) {
      var deferred = $q.defer();
      return $mdDialog.show({
        controller: 'ChangedialogCtrl',
        controllerAs: 'changedialog',
        templateUrl: 'library/changedialog/changedialog.tpl.html',
        parent: angular.element(document.body),
        locals: {filter: filter},
        bindToController: true,
        clickOutsideToClose: false
      })
          .then(function (data) {
            return data;
          }, function () {
            return false; //cancel
          });
    };



    vm.addChapters = function (chapters){
      vm.openChangeDialog().then(function(eventData){
        if(eventData) {

          vm.isLoading = true;
          _.remove(chapters, function (chapter) {
            return chapter.disabled === true;
          });
          _.remove(chapters, function (chapter) {
            return chapter.selected === false;
          });
          var defer = $q.defer();
          var promises = [];
          angular.forEach(chapters, function (chapter) {
            promises.push(
                Structures
                    .duplicateDeep({
                      "bookId": book.bookId,
                      "structureId": chapter.structureId,
                      "status": 0,
                      "approvalStatus": book.userInBook[0],
                      "parent": null,
                      "eventData": eventData
                    })
                    .$promise.then(function (response) {
                  defer.resolve();
                })
            );
          });

          $q.all(promises).then(function () {
            Books.upsert({"bookId": book.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)}).$promise.then(function () {
              vm.dialogHide(true); //true = refresh parent
              //vm.isLoading = false;
              Wraps.updateBookData = book.bookId;
            });
          });

        }
      });

    }

    
    vm.addNewChapter = function (chapterData){
      vm.openChangeDialog().then(function(eventData) {
        if (eventData) {
          vm.isLoading = true;
          //TODO add ckeditor for copy
          //console.log(chapterData);
          Contents.create({
            contentId: 0,
            contentTypeId: 1,
            contentName: chapterData.chapterData + " - Chapter",
            contentOwner: chapterData.chapterTeam.teamId
          }, function (data1) {


            // if(chapterData.chapterType.chapterTypeId===1){
            //   var chapterJson = "<h2>" + chapterData.chapterData + "</h2>\n";
            // }else if(chapterData.chapterType.chapterTypeId===3){
            //   var chapterJson = Wraps.toJson({chapterTypeId: chapterData.chapterType.chapterTypeId, chapterLinkId: chapterData.QREF.bookId, chapterCopy: "<h2>QREF</h2>\n" });
            // }else{
            //   var chapterJson = Wraps.toJson({chapterTypeId: chapterData.chapterType.chapterTypeId, chapterCopy: "<h2>" + chapterData.chapterData + "</h2>\n" });
            // }

            if(chapterData.chapterType.chapterTypeId===1){
              var chapterJson = "<h2>" + chapterData.chapterData + "</h2>\n";
            }else if(chapterData.chapterType.chapterTypeId===2){
              var chapterObj = {};

              chapterObj.chapterTypeId  = chapterData.chapterType.chapterTypeId;
              chapterObj.chapterLinkId = chapterData.chapterType.chapterLinkId;
              chapterObj.chapterCopy = "<h2>Front Cover</h2>\n";
              if(chapterData.chapterType.filesList){
                chapterObj.filesList = chapterData.chapterType.filesList;
              }
              if(chapterData.chapterType.PDFList){
                chapterObj.PDFList = chapterData.chapterType.PDFList;
              }
              var chapterJson = Wraps.toJson(chapterObj); //front cover
            }else if(chapterData.chapterType.chapterTypeId===3){
              var chapterJson = Wraps.toJson({chapterTypeId: chapterData.chapterType.chapterTypeId, chapterLinkId: chapterData.QREF.bookId, chapterCopy: "<h2>QREF</h2>\n" }); //qref
            }else if(chapterData.chapterType.chapterTypeId===4){
              var chapterJson = Wraps.toJson({chapterTypeId: chapterData.chapterType.chapterTypeId, chapterCopy: "<h2>" + chapterData.chapterData + "</h2>\n" }); //contents
            }else if(chapterData.chapterType.chapterTypeId===5){
              var chapterJson = Wraps.toJson({chapterTypeId: chapterData.chapterType.chapterTypeId, chapterCopy: "<h2>Summary of benefits and Exclusions</h2>\n" }); //summary
            }else if(chapterData.chapterType.chapterTypeId===6){
              var chapterJson = Wraps.toJson({chapterTypeId: chapterData.chapterType.chapterTypeId,  chapterCopy: "<h2>Back Cover</h2>\n" }); //back cover
            }else if(chapterData.chapterType.chapterTypeId===7) {
              var chapterJson = Wraps.toJson({ chapterTypeId: chapterData.chapterType.chapterTypeId, chapterCopy: "<h2>" + chapterData.chapterData + "</h2>\n" }); //BGHP Benefits
            }else if(chapterData.chapterType.chapterTypeId===8) {
              var chapterJson = Wraps.toJson({ chapterTypeId: chapterData.chapterType.chapterTypeId, chapterCopy: "<h2>" + chapterData.chapterData + "</h2>\n" }); //BGHP Terms
            }else if(chapterData.chapterType.chapterTypeId===9) {
              var chapterJson = Wraps.toJson({ chapterTypeId: chapterData.chapterType.chapterTypeId, chapterCopy: "<h2>" + chapterData.chapterData + "</h2>\n" }); //BGHP Exclusions
            }else{
              var chapterJson = Wraps.toJson({ chapterTypeId: chapterData.chapterType.chapterTypeId, chapterCopy: "<h2>" + chapterData.chapterData + "</h2>\n" }); //default
            }



            
            Versions.create({
              versionId: 0,
              contentId: data1.contentId,
              versiondata: chapterJson
            }, function (data2) {

              Structures.create({
                structureId: 0,
                versionId: data2.versionId,
                contentId: data1.contentId,
                bookId: book.bookId,
                "status": 0,
                approvalStatus: book.userInBook[0],
                parent: null,
                order: data2.versionId
              }, function (data3) {

                Books.upsert({"bookId": book.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                History.create({
                  "structureId": data3.structureId,
                  "versionIdOld": 0,
                  "versionIdNew": data3.versionId,
                  "historyId": 0
                }, function (historyResponse) {
                  Events.create({
                    "eventId": 0,
                    "bookId": book.bookId,
                    "structureId": data3.structureId,
                    "contentId": data1.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Added",
                    "eventData": Wraps.toJson(eventData)
                  });
                });

                vm.dialogHide(true);
                vm.isLoading = false;
                Wraps.updateBookData = book.bookId;
              });
            });
          });
        }
      });
    }


    vm.removeFile = function(array, index){
      array.splice(index, 1);
    }


    var timestamp = new Date().getTime();


    $scope.$watch('chaptersdialog.chapterFiles', function () {
      vm.upload(vm.chapterFiles,'logo').then(function(statuses){
        vm.isLoading = false;
      });
    });


    $scope.$watch('chaptersdialog.frontCoverPDF', function () {
      vm.upload(vm.frontCoverPDF,'frontCoverPDF').then(function(statuses){
        vm.isLoading = false;
      });
    });

    vm.log = '';
    //vm.filesList = [];
    vm.upload = function (files,itemType) {

      // var S3_BUCKET_URL = apiUrl+'/containers/wraps-files%2f'+itemType+'%2f'+timestamp+'/upload';
      var S3_BUCKET_URL = apiUrl+'/containers/wraps-files/upload';
      var deferred = $q.defer();

      if (files && !files.length){ //if multiple = false
        var tempFiles = [files];
        files = tempFiles;
      }

      if (files && files.length) {
        _.forEach(files, function (file) {
          if (!file.$error) {
            vm.isLoading = true;

            file.container = 'wraps-files';
            file.fileLocation = itemType+'/'+timestamp;
            file.fileName = file.name;
            file.fileSize = file.size;

            if(itemType==='logo'){
              if(!vm.chapterType.filesList){
                vm.chapterType.filesList=[];
              }
              if(tempFiles){
                vm.chapterType.filesList = [];
              }
              vm.chapterType.filesList.push(file);

            }else if(itemType==='frontCoverPDF') {

              if(!vm.chapterType.PDFList){
                vm.chapterType.PDFList=[];
              }
              if(tempFiles){
                vm.chapterType.PDFList = [];
              }
              vm.chapterType.PDFList.push(file);
            }


            //TODO add timeout to animate files
            var newName = file.fileLocation + '/' + file.name;
            Upload.rename(file, newName );
            Upload.upload({
              url: S3_BUCKET_URL,
              file: file

            }).then(function (resp) {
              console.log(resp);

              deferred.resolve();
            }, null, function (evt) {
              file.determinateValue = parseInt(100.0 *   evt.loaded / evt.total);

            });
          }
        });
      }

      return deferred.promise;
    };






    vm.selectedChapters = [];
    vm.setSelectedChapters = function (chapter){
      if(chapter.selected==true){
        vm.selectedChapters.push(chapter);
      }else{
        _.pullAt(vm.selectedChapters, _.findKey(vm.selectedChapters, 'versionId', chapter.versionId));
      }
    }

    vm.querySearch = function (query) {
      var results = query ? vm.books.filter( createFilterFor(query) ) : vm.books,
        deferred;
      return results;
    }

    /**
     * Create filter function for a query string
     */
    function createFilterFor(query) {
      return function filterFn(item) {
        return (item.bookCode.toLowerCase().indexOf(query.toLowerCase()) >= 0);
      };
    }

  }
}());
