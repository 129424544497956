(function () {
  'use strict';

  /* @ngdoc object
   * @name library
   * @description
   *
   */
  angular
    .module('library', [
      'ui.router',
      'library.bottomsheet',
      'library.historydialog',
      'library.chaptersdialog',
      'library.contentdialog',
      'library.docdiffdialog',
      'library.emaildetailsdialog',
      'library.pushdialog',
      'library.workflowdialog',
      'library.approvedialog',
      'library.addbrief',
      'library.addartwork',
      'library.changedialog',
      'library.swapteamuserdialog',
      'library.metachangedialog'
    ]);
}());
