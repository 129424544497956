<md-content layout-padding>
    <h4>Level Labels</h4>
    <input-level book="tabLevels.book" disabled="tabLevels.disabled" level="level1"></input-level>
    <input-level book="tabLevels.book" disabled="tabLevels.disabled" level="level2"></input-level>
    <input-level book="tabLevels.book" disabled="tabLevels.disabled" level="level3"></input-level>
    <input-level book="tabLevels.book" disabled="tabLevels.disabled" level="level4"></input-level>

    <md-divider></md-divider>

    <input-level book="tabLevels.book" disabled="tabLevels.disabled" level="payment"></input-level>
</md-content>