<md-dialog aria-label="Document info changes"  class="docdiffdialog">

    <md-toolbar>
        <div class="md-toolbar-tools">
            <h2>Document Info Changes</h2>
            <span flex></span>

            <md-button ng-click="metachangedialog.dialogCancel(false)" class="md-icon-button">
                <ng-md-icon  icon="close" size="24"  aria-label="Close dialog"></ng-md-icon>
            </md-button>
        </div>
    </md-toolbar>
    <md-dialog-content class="dialog-content">


        <md-content flex layout-padding>
            <div flex layout="column" style="max-width:1000px; min-width: 800px; font-size: 14px;" class="changes-col">
              <div layout="row" flex="100" layout-wrap style="padding:8px 6px; color:#aaa; font-weight: bold;">
                <div flex="20" style="text-align:left;">Field</div>
                <div flex="40" style="text-align:left;">Old</div>
                <div flex="40" style="text-align:left;">New</div>
              </div>


              <div layout="row" flex="100" layout-wrap ng-repeat="change in metachangedialog.change" style="border-top:1px #eee solid; padding:4px 6px; margin-bottom: 10px;">
                  <div flex="20"  style=" padding-right:10px;" layout-wrap layout="row" flex ><p style="font-weight: bold;" >{{change.label}}:</p></div>
                  <div flex="40"  style=" padding-right:10px;color: #999;" layout-wrap layout="row">
                    <p ng-if="change.type === 'string'" flex ng-bind-html="change.lhs" style="width:100%"></p>

                    <ul ng-if="change.type === 'array'" style="width:100%">
                      <li flex  ng-repeat="(key, value) in change.lhs track by $index">{{value}}</li>
                    </ul>

                    <ul ng-if="change.type === 'workflow'" ng-repeat="(key, value) in change.lhs track by $index" style="width:100%">
                      <lh  style="margin-left: -20px; font-size: smaller;">{{key | uppercase}}</lh>
                      <li ng-repeat="(k, v) in value track by $index" >{{v.role}} - {{v.team}}/{{v.user}}</li>
                    </ul>
                  </div>
                  <div flex="40"  style=" padding-right:10px;" layout-wrap layout="row">
                    <p ng-if="change.type === 'string'" flex ng-bind-html="change.rhs" style="width:100%"></p>

                    <ul ng-if="change.type === 'array'" style="width:100%">
                      <li flex  ng-repeat="(key, value) in change.rhs track by $index">{{value}}</li>
                    </ul>

                    <ul ng-if="change.type === 'workflow'" ng-repeat="(key, value) in change.rhs track by $index" style="width:100%">
                      <lh  style="margin-left: -20px; font-size: smaller;">{{key | uppercase}}</lh>
                      <li ng-repeat="(k, v) in value track by $index" >{{v.role}} - {{v.team}}/{{v.user}}</li>
                    </ul>

                  </div>
              </div>


            </div>
        </md-content>


    </md-dialog-content>

</md-dialog>
