(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.controller:HomeCtrl
   *
   * @description
   *
   */
  angular
    .module('home')
    .controller('HomeCtrl', HomeCtrl);

  function HomeCtrl(
    $filter,
    $localStorage,
    $location,
    $q,
    $rootScope,
    $scope,
    clientConfig,
	apiUrl,
    Books,
    Color,
    Roles,
    Teams,
    Users,
    Wraps) {
    var vm = this;
    vm.ctrlName = 'HomeCtrl';
    vm.isLoading = true;
    vm.hasPermissions = Wraps.hasPermissions;


    vm.clientConfig = clientConfig;

    Wraps.searchByBook = {};
    Wraps.searchByBook.id = '';

	// sends user to root if not logged in and trying to access site.
    if (!Users.isAuthenticated()) {
      $location.path('/');
    }

    $scope.$storage = $localStorage;
    vm.apiUrl = apiUrl;

	// console logs where the user clicks
    vm.onClick = function (points, evt) {
      console.log(points, evt);
    };

    vm.openTooltips = function (code) {
      $rootScope.$emit("openTooltip", code);
    };

    vm.getData = function () {
      var actionsDefer = $q.defer();
      var blackAssetsDefer = $q.defer();
      var involvedDefer = $q.defer();
      var statusDefer = $q.defer();
      var promises = {
        actions: actionsDefer.promise,
        blackAssets: blackAssetsDefer.promise,
        involved: involvedDefer.promise,
        status: statusDefer.promise
      };

      Books.getActiveDocumentsByOwner().$promise.then(function (results) {
        vm.activeDocuments = results;
        vm.actionsDoughnutTotal = {value: results.totalActiveDocuments.documents === 0 ? 0.4 : results.totalActiveDocuments.documents, colour: '#ECEFF1'};
        vm.actionsDoughnutSeries = [{value: results.teamActiveDocuments.documents, colour: '#FFC107'}, {value: results.personalActiveDocuments.documents, colour: '#FF5722'}];
        actionsDefer.resolve();
      });

      Books.getBlackAssets().$promise.then(function (results) {
        vm.blackAssets = results;
        vm.blackAssetsSeries = [{value: results.blackAssetsFinProms.blackAssets, colour: '#F44336'}, {value: results.blackAssetsNonFinProms.blackAssets, colour: '#FF9800'}];
        vm.blackAssetsPrimary = {value: results.blackAssetsOwned.blackAssets, colour: '#ECEFF1'};
        vm.blackAssetsTotal = {value: results.totalBlackAssets.blackAssets === 0 ? 0.4 : results.totalBlackAssets.blackAssets, colour: '#ECEFF1'};
        blackAssetsDefer.resolve();
      });

      Books.getDocumentsUserInvolvedIn().$promise.then(function (results) {
        vm.documents = results;
        vm.primaryDocumentsInvolvedIn = {value: vm.documents.totalDocumentsInWorkFlow, colour: '#ECEFF1'};
        vm.totalDocumentsInvolvedIn = {value: vm.documents.totalDocuments.documents === 0 ? 0.4 : vm.documents.totalDocuments.documents, colour: '#ECEFF1'};
        vm.seriesDocumentsInvolvedIn = [{value: vm.documents.totalDocumentsInWorkFlowPersonal, colour: '#3F51B5'}, {value: vm.documents.totalDocumentsInWorkFlowTeam, colour: '#03A9F4'}];
        involvedDefer.resolve();
      });

      Books.getYourDocumentsStats().$promise.then(function (results) {
        vm.yourDocuments = results;
        vm.youDocsDoughnutTotal = {value: results.total === 0 ? 0.4 : results.total, colour: '#ECEFF1'};
        vm.youDocsDoughnutSeries = [
          {value: results.total, colour: '#009688'},
          {value: results.ready, colour: '#673AB7'},
          {value: results.actv, colour: '#8BC34A'},
          {value: results.liveR, colour: '#FFEB3B'},
          {value: results.live, colour: '#9C27B0'},
          {value: results.inWork, colour: '#03A9F4'},
          {value: results.expd, colour: '#FF9800'},
          {value: results.expdF, colour: '#FF5722'}];
        statusDefer.resolve();
      });
      return $q.all(promises);
    };

    vm.getData().then(function () {
      vm.isLoading = false;
    });

    $rootScope.$on('updateHomeStats', function () {
      vm.getData();
    });
  }
}());
