(function () {
  'use strict';

  angular
    .module('library.historydialog')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('historydialog', {
        url: '/historydialog',
        templateUrl: 'library/historydialog/historydialog.tpl.html',
        controller: 'HistorydialogCtrl',
        controllerAs: 'historydialog'
      });
  }
}());
