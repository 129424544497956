<md-dialog aria-label="Add Artwork"  class="addartwork">

    <md-toolbar>
        <div class="md-toolbar-tools">
            <h2 wraps-tooltip="draftbox-addartwork_form">Add Artwork - {{addartwork.bookCode}}</h2>
            <span flex></span>

            <md-button ng-click="addartwork.dialogCancel(false)" class="md-icon-button" wraps-tooltip="draftbox-addartwork_formclose">
                <ng-md-icon  icon="close" style="fill: white;"  aria-label="Close dialog"></ng-md-icon>
            </md-button>
        </div>
    </md-toolbar>
    <md-dialog-content class="dialog-content big" style="padding-left: 20px;">

        <md-content class="md-padding">
            <br />
            <form ng-submit="$event.preventDefault()" name="addArtworkForm" layout="column">

                <div layout="row" layout-wrap>
                    <div flex="70" layout="row" layout-wrap>
                        <div flex="100">
                            <md-input-container class="cke-container">
                                <label>Artwork description here...</label>
                                <textarea  ng-model="addartwork.artwork.description"  contenteditable="true" ckeditor="addartwork.options" required></textarea>
                            </md-input-container>



                            <div id="cktools" flex></div>
                            <div id="ckfoot"></div>
                        </div>
                    </div>
                    <div flex="5"></div>
                    <div flex="25">
                        <div ngf-drop ngf-select ng-model="addartwork.files" class="drop-box"
                             ngf-drag-over-class="'dragover'" ngf-multiple="true" ngf-allow-dir="true"
                             accept="{{addartwork.safeFileList}}"
                             ngf-pattern="addartwork.safeFileList"
                             wraps-tooltip="briefbox-adddraft_upload">
                            <ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop files here or<br /><strong style="text-decoration: underline;">Click to upload</strong>
                        </div>
                        <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>

                    </div>
                    <div flex="50" class="downloads-container" ng-if="addartwork.filesList.length > 0">
                        <h5>Uploads</h5>
                        <div ng-repeat="upload in addartwork.filesList" layout="row" layout-wrap layout-padding="20" class="download-row small"  ng-class="{'file-name-error': !addartwork.filenameInCodeCheck(upload.fileName,addartwork.bookCode)}">
                            <md-tooltip ng-if="!addartwork.filenameInCodeCheck(upload.fileName,addartwork.bookCode);" class="statbar"  md-direction="top">This filename does not contain the current job code</md-tooltip>                      
                            <a href="{{upload.ngfName|fileurl}}" target="_blank">
                                <div class="thumbnail-block" wraps-tooltip="artwork-upload-thumb">
                                    <md-progress-circular  ng-if="upload.determinateValue < 100" md-mode="determinate" value="{{upload.determinateValue}}"></md-progress-circular>
                                    <md-progress-circular ng-if="upload.determinateValue === 100 && upload.isLoading"></md-progress-circular>

                                    <img ng-if="!upload.isLoading" class="thumbnail-preview" ng-src="{{upload.ngfName|thumburl}}" err-SRC="/images/wraps-logo.png" alt="{{upload.fileName}}"/>
                                    <div class="overlay text-center"><ng-md-icon icon="file_download" size="24"></ng-md-icon></div>
                                </div>
                            </a>
                            <div flex>
                                <h3 md-truncate>{{upload.fileName}}</h3>
                                <h3 md-truncate>{{upload.fileSize | filesize}} <a class="md-accent" style="cursor:pointer; font-weight: bold;" ng-click="addartwork.removeFile(addartwork.filesList, $index)">Remove</a></h3>
                            </div>
                            <div flex="100" class="divider"><div></div></div>
                        </div>
                    </div>
                </div>

            </form>

        </md-content>
    </md-dialog-content>
    <md-dialog-actions layout="row">

        <span flex></span>
        <md-button class="md-primary" ng-click="addartwork.dialogCancel(false)" wraps-tooltip="briefbox-adddraft_cancel">Cancel</md-button>
        <md-button ng-disabled="(addartwork.isLoading || !addArtworkForm.$valid)" class="md-raised md-accent"  ng-click="addartwork.addNewArtwork(addartwork)"wraps-tooltip="draftbox-addartwork_saveartwork">Save Artwork</md-button>

    </md-dialog-actions>
</md-dialog>

