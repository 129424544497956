(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name maintenance.filter:titlecase
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('drive')
    .filter('thumburl', thumburl);

  function thumburl(apiUrl) {
    return function(ngfName) {
        return encodeURI(apiUrl + '/containers/getthumbnail/' + ngfName.replace(/\.[^\.]+$/, '.jpg'));
    };
  }
}());
