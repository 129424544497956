(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableLanguage', readableLanguage);

  function readableLanguage(Wraps) {
    return function (id) {
      var language;
      if ((language = _.find(Wraps.languages, {id: parseInt(id)})) && language.name) {
        return language.name;
      }
      return null;
    };
  }
}());
