(function () {
  'use strict';

  angular
    .module('library.changedialog')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('changedialog', {
        url: '/changedialog',
        templateUrl: 'library/changedialog/changedialog.tpl.html',
        controller: 'ChangedialogCtrl',
        controllerAs: 'changedialog'
      });
  }
}());
