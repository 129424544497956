(function () {
  'use strict';

  angular
    .module('library.bottomsheet')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('bottomsheet', {
        url: '/bottomsheet',
        templateUrl: 'library/bottomsheet/bottomsheet.tpl.html',
        controller: 'BottomsheetCtrl',
        controllerAs: 'bottomsheet'
      });
  }
}());
