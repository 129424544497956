angular
    .module('library')
    .component('tabNotes', {
        templateUrl: 'library/library-components/right-menu/tab-notes/tab-notes.tpl.html',
        controller: tabNotesCtrl,
        controllerAs:'tabNotes',
        bindings:{
            book:'<',
            disabled:'<'
        }

    });
tabNotesCtrl.$inject = ['$scope'];
function tabNotesCtrl($scope){
    //var vm = this;

}