<md-dialog aria-label="Notification details"  class="docdiffdialog">

    <md-toolbar>
        <div class="md-toolbar-tools">
            <h2>Notification details</h2>
            <span flex></span>

            <md-button ng-click="emaildetailsdialog.dialogCancel(false)" class="md-icon-button">
                <ng-md-icon  icon="close" size="24"  aria-label="Close dialog"></ng-md-icon>
            </md-button>
        </div>
    </md-toolbar>
    <md-dialog-content class="dialog-content" style="">


        <md-content flex layout-padding>
            <div flex layout="column" style="width:620px;">
                <div>
                    <md-content>
                        <md-list flex>
                            <md-list-item class="md-3-line" ng-click="null">
                                <div class="md-list-item-text" layout="column">
                                    <h3>{{emaildetailsdialog.events[0].Messages.to}}</h3>
                                    <h4>From {{emaildetailsdialog.events[0].Messages.from}}</h4>
                                    <p>{{emaildetailsdialog.events[0].Messages.created | date : 'medium'}}</p>
                                </div>
                            </md-list-item>
                            <md-divider ></md-divider>


                        </md-list>
                    </md-content>
                </div>

                <div class="md-subhead" style="padding: 0px 10px 10px;" >
                    <p  ng-if="emaildetailsdialog.events[0].Messages.subject"><strong>Subject: </strong>{{emaildetailsdialog.events[0].Messages.subject}}</p>

                </div>


                <div ng-if="emaildetailsdialog.events[0].Messages.html" ng-bind-html="emaildetailsdialog.trustAsHtml(emaildetailsdialog.events[0].Messages.html)"></div>

                <md-divider style="padding-top:10px;" ></md-divider>



                <div class="md-subhead" style="padding:10px;" ng-if="emaildetailsdialog.events[0].Messages.response"><strong>Server response</strong>
                    <p style="padding-top:0px; margin-top:0px;padding-bottom:0px; margin-bottom:0px" ng-repeat="(responseName, responseValue) in emaildetailsdialog.events[0].Messages.response" ng-if="responseName != 'envelope'">
                        <strong>{{responseName}}</strong> {{responseValue}}
                    </p>

                </div>

            </div>
        </md-content>


    </md-dialog-content>

</md-dialog>
