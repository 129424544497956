(function () {
  'use strict';

  /* @ngdoc object
   * @name library.changedialog
   * @description
   *
   */
  angular
    .module('library.changedialog', [
      'ui.router'
    ]);
}());
