(function () {
  'use strict';

  /* @ngdoc object
   * @name wrapsv1
   * @description
   *
   */
  angular
    .module('wrapsv1', [
      'ngAria',
      'ngMaterial',
      'ngMessages',
      'ngSanitize',
      'ngMdIcons',
      'ngStorage',
      'ngFileUpload',
      'lbServices',
      'md.data.table',
      'diff-match-patch',
      'chart.js',
      'ckeditor',
      'htmldiff',
      'dragularModule',
      'ui.router',
      'ngCsv',
      'home',
      'login',
      'users',
      'navigation',
      'library',
      'workflow',
      'reset',
      'reports',
      'maintenance',
      'permissionmanagement',
      'versions',
      'duScroll',
      'footer',
      'tooltip',
      'profile',
      'drive',
      'newLibrary',
      'accessDenied',
      'healthcheck'
    ])
    .config(["$provide", "$locationProvider", "$mdThemingProvider", "$mdIconProvider", "LoopBackResourceProvider", "$httpProvider", "$mdDateLocaleProvider", function ($provide, $locationProvider, $mdThemingProvider, $mdIconProvider, LoopBackResourceProvider, $httpProvider, $mdDateLocaleProvider) {
      //$locationProvider.html5Mode(true).hashPrefix('#!');

      $locationProvider.html5Mode(true);

      //default config
      $provide.value(
          'clientConfig',{
            email: "wraps@bupa.com",
             name: "Bupa",
             helpsiteLink: "http://help.bupa.wraps.io",
             pdfUrl: "https://pdf.bupa.wraps.io",
             logo: "wraps"
            });

      // Use a custom auth header instead of the default 'Authorization'
      LoopBackResourceProvider.setAuthHeader('X-Access-Token');
      if (
        document.location.hostname.substring(0, 7) === '0.0.0.0' ||
        document.location.hostname.substring(0, 9) === 'localhost'
      ){
        LoopBackResourceProvider.setUrlBase('http://localhost:3000/api');
        $provide.value('system','local');
        $provide.value('api', 'LOCAL-');
        $provide.value('apiUrl', 'http://localhost:3000/api');
        $provide.value('pdfUrl', 'http://pdf');
        var color = 'blue';
        var colorOptions = { 'default': '800' };
        var accentColor = 'red';
        var accentOptions = {   'default': 'A700' };

      } else if(
        document.location.hostname.substring(0, 8) === '192.168.' ||
        document.location.hostname.substring(0, 3) === '10.'
      ){
        LoopBackResourceProvider.setUrlBase('http://'+document.location.hostname+':3000/api');
        $provide.value('system','local');
        $provide.value('api', 'LOCAL-');
        $provide.value('apiUrl', 'http://localhost:3000/api');
        $provide.value('pdfUrl', 'http://pdf');
        var color = 'green';
        var colorOptions = {  };
        var accentColor = 'pink';
        var accentOptions = {  };
      } else if (
        document.location.hostname.substring(0, 6) === 'wraps.' ||
        document.location.hostname.substring(0, 17) === 'www.bupa.wraps.io'||
        document.location.hostname.substring(0, 13) === 'bupa.wraps.io'
      ){
        if (document.location.protocol === 'http' ) {
          document.location.href = document.location.href.replace('http', 'https');
        }
        LoopBackResourceProvider.setUrlBase('https://api.bupa.wraps.io/api');
        $provide.value('system','live');
        $provide.value('api', '');
        $provide.value('apiUrl', 'https://api.bupa.wraps.io/api');
        $provide.value('pdfUrl', 'https://pdf.bupa.wraps.io');
        var color = 'blue-grey';
        var colorOptions = {  };
        var accentColor = 'pink';
        var accentOptions = {  };
      } else if (
        document.location.hostname.substring(0, 11) === 'demo.wraps.' ||
        document.location.hostname.substring(0, 22) === 'www.demo.bupa.wraps.io'||
        document.location.hostname.substring(0, 18) === 'demo.bupa.wraps.io'
      ){
        if (document.location.protocol === 'http' ) {
          document.location.href = document.location.href.replace('http', 'https');
        }
        // Change the URL to access the LoopBack REST API server
        LoopBackResourceProvider.setUrlBase('https://api.demo.bupa.wraps.io/api');
        $provide.value('system','demo');
        $provide.value('api', 'DEMO-');
        $provide.value('apiUrl', 'https://api.demo.bupa.wraps.io/api');
        $provide.value('pdfUrl', 'https://pdf.bupa.wraps.io');
        var color = 'teal';
        var colorOptions = {  };
        var accentColor = 'pink';
        var accentOptions = {  };
      }else if (
        document.location.hostname.substring(0, 13) === 'switch.wraps.' ||
        document.location.hostname.substring(0, 19) === 'www.switch.wraps.io'||
        document.location.hostname.substring(0, 15) === 'switch.wraps.io'
      ){
        if (document.location.protocol === 'http' ) {
          document.location.href = document.location.href.replace('http', 'https');
        }
        // Change the URL to access the LoopBack REST API server
        LoopBackResourceProvider.setUrlBase('https://api.switch.wraps.io/api');
        $provide.value('system','switch');
        $provide.value('api', 'SWITCH-');
        $provide.value('apiUrl', 'https://api.switch.wraps.io/api');
        $provide.value('pdfUrl', 'https://pdf.switch.wraps.io');

        $provide.value('clientConfig',{
          email: "switch@wraps.io",
          name: "",
          helpsiteLink: "http://help.switch.wraps.io",
          pdfUrl: "https://pdf.switch.wraps.io",
          logo: "wraps"
         });

        var color = 'indigo';
        var colorOptions = { 'default': '900' };
        var accentColor = 'red';
        var accentOptions = {   'default': 'A700' };
      } else {
        // Change the URL to access the LoopBack REST API server
        LoopBackResourceProvider.setUrlBase('https://api.staging.bupa.wraps.io/api');
        $provide.value('system','staging');
        $provide.value('api', 'STAGING-');
        $provide.value('apiUrl', 'https://api.staging.bupa.wraps.io/api');
        $provide.value('pdfUrl', 'https://pdf.bupa.wraps.io');
        var color = 'cyan';
        var colorOptions = {  };
        var accentColor = 'pink';
        var accentOptions = {  };
      }



      $mdDateLocaleProvider.parseDate = function(dateString) {
        var m = moment(dateString, 'DD-MM-YYYY', true);
        return m.isValid() ? m.toDate() : new Date(NaN);
      };
      $mdDateLocaleProvider.formatDate = function(date) {
        return moment(date).format('DD-MM-YYYY');
      };


      $mdThemingProvider.theme('default')
        .primaryPalette(color, colorOptions)
        .accentPalette( accentColor, accentOptions);

      $mdIconProvider.iconSet('social', 'img/icons/sets/social-icons.svg', 24).defaultIconSet('img/icons/sets/core-icons.svg', 24);

      // Inside app config block
      $httpProvider.interceptors.push(["$q", "$location", "LoopBackAuth", function($q, $location, LoopBackAuth) {
        //console.log('interceptors');
        return {
          response: function(response) {
             //all http



             return response;
           },
          responseError: function(rejection) {
             if (rejection.status === 401) {
               //Now clearing the loopback values from client browser for safe logout...
               LoopBackAuth.clearUser();
               LoopBackAuth.clearStorage();
               if($location.path() !== '/login' && $location.path() !== '/'){
                 $location.nextAfterLogin = $location.path();
                 $location.path('/login');
               }
             }
             return $q.reject(rejection);
           }
        };
      }]);

    }])

    .run(["$location", "$window", "system", "Users", "$rootScope", "$templateCache", "Wraps", function($location, $window,system, Users, $rootScope, $templateCache, Wraps){

      // window.onbeforeunload = function () {
      //   console.log('Reloading');
      // };

      //console.log("$location.path()",$location.path().substring(0, 7));
      if(!Users.isAuthenticated()) {
        //console.log('auth');
        if ($location.path() !== '/login' && $location.path().substring(0, 7) !== '/reset/' && $location.path() !== '/') {
          $location.nextAfterLogin = $location.path();
          $location.path('/login');
        }
      }

    }]).controller('AppCtrl', AppCtrl).factory('$exceptionHandler', ['$injector', function($injector) {
      var errorCount = 0;
      return function myExceptionHandler(exception, cause) {

          if(_.isString(exception) && exception.indexOf('jsonpCallbackParam') !== -1) {

          }else if(errorCount < 100) {
              var ErrorLogs = $injector.get('ErrorLogs');
              var $location = $injector.get('$location');
              var url = $location.absUrl()
              var stack = '';

              if(typeof exception.stack !== 'undefined') {
                  stack = exception.stack.replace(/(\r\n|\n|\r)/gm,"\n ○ ");
              }

              ErrorLogs.insertError({"message":exception.message, "stackTrace": stack, "cause": cause, "url": url});
              errorCount++;
              console.error(exception);
          }else{
            console.log("error count exceeded!")
          }
      };
  }]);

  function AppCtrl($scope, api, Wraps) {

    var vm = this;
    vm.ctrlName = 'AppCtrl';

    $scope.api = api;

    Wraps.appVersion.$promise.then(function(version) {
      Wraps.thisAppVersion = version.appVersionId;
      $scope.appVersion = Wraps.thisAppVersion;
      $scope.docTitle = api + 'WRAPS - v' + version.appVersionId;
    });

    Wraps.fetchedUser =  Wraps.getCurrentUser();


  }
  AppCtrl.$inject = ["$scope", "api", "Wraps"];

}());

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-components/right-menu/tab-workflow/tab-workflow.tpl.html',
    '<md-content layout-padding>\n' +
    '    <div ng-show="tabWorkflow.book.workflowDoc"  class="workflow">\n' +
    '        <h4 wraps-tooltip="docman-wf-input_docapprovers">Document Workflow</h4>\n' +
    '        <input-wf-step ownerid="tabWorkflow.book.bookOwner" array="tabWorkflow.book.workflowDoc" disabled="tabWorkflow.disabled" dragular="tabWorkflow.docOptions" dragular-model="tabWorkflow.book.workflowDoc" isvalid="tabWorkflow.validDoc"></input-wf-step>\n' +
    '        <input-wf-speedial content="\'doc\'" disabled="tabWorkflow.disabled"  ng-show="(true && !tabWorkflow.disabled)"></input-wf-speedial>\n' +
    '    </div>\n' +
    '\n' +
    '    <div  ng-show="tabWorkflow.book.workflowBrief"  class="workflow">\n' +
    '        <h4 wraps-tooltip="docman-wf-input_briefapprovers">Document or Brief Workflow</h4>\n' +
    '        <input-wf-step ownerid="tabWorkflow.book.bookOwner" array="tabWorkflow.book.workflowBrief" disabled="tabWorkflow.disabled" dragular="tabWorkflow.briefOptions" dragular-model="tabWorkflow.book.workflowBrief" isvalid="tabWorkflow.validBrief"></input-wf-step>\n' +
    '        <input-wf-speedial content="\'brief\'" disabled="tabWorkflow.disabled" ng-show="(true && !tabWorkflow.disabled)"></input-wf-speedial>\n' +
    '    </div>\n' +
    '\n' +
    '    <div  ng-show="tabWorkflow.book.workflowArtwork"  class="workflow">\n' +
    '        <h4 wraps-tooltip="docman-wf-input_artworkapprovers">\n' +
    '            Artwork Draft Workflow\n' +
    '            <md-switch style="display: inline-block; margin: -3px 10px;" ng-model="tabWorkflow.enableWorkflowArtwork"  aria-label="active" ng-change="tabWorkflow.checkArtwork()"  ng-disabled="tabWorkflow.disabled"></md-switch>\n' +
    '        </h4>\n' +
    '        <input-wf-step ownerid="tabWorkflow.book.bookOwner" array="tabWorkflow.book.workflowArtwork" disabled="tabWorkflow.disabled" ng-show="tabWorkflow.enableWorkflowArtwork" dragular="tabWorkflow.artworkOptions" dragular-model="tabWorkflow.book.workflowArtwork" isvalid="tabWorkflow.validArtwork"></input-wf-step>\n' +
    '        <input-wf-speedial content="\'artwork\'" disabled="tabWorkflow.disabled" ng-show="(tabWorkflow.enableWorkflowArtwork && !tabWorkflow.disabled)"></input-wf-speedial>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-show="tabWorkflow.book.workflowFinal"  class="workflow">\n' +
    '        <h4 wraps-tooltip="docman-wf-input_finalapprovers">Publishing Workflow</h4>\n' +
    '        <input-wf-step ownerid="tabWorkflow.book.bookOwner" array="tabWorkflow.book.workflowFinal" disabled="tabWorkflow.disabled" dragular="tabWorkflow.finalOptions" dragular-model="tabWorkflow.book.workflowFinal" isvalid="tabWorkflow.validFinal"></input-wf-step>\n' +
    '    </div>\n' +
    '\n' +
    '</md-content>\n' +
    '\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-components/right-menu/tab-publish/tab-publish.tpl.html',
    '<md-content layout-padding>\n' +
    '\n' +
    '    <div layout="row"  layout-wrap    class="publish">\n' +
    '        <div flex="100">\n' +
    '            <h4>Document Publishing</h4>\n' +
    '\n' +
    '            <div layout="row"  layout-wrap    class="publish">\n' +
    '                <input-switchlist flex="33" book="tabPublish.book" disabled="tabPublish.disabled" array="tabPublish.productionList" switch="tabPublish.switch.production" label="Production Manager" model="production"></input-switchlist>\n' +
    '                <input-switchlist flex="33" book="tabPublish.book" disabled="tabPublish.disabled" array="tabPublish.fulfilmentList" switch="tabPublish.switch.fulfilment" label="Service Centre" model="fulfilment"></input-switchlist>\n' +
    '                <input-switchlist flex="33" book="tabPublish.book" disabled="tabPublish.disabled" array="tabPublish.digitalList" switch="tabPublish.switch.digital" label="Digital & Social" model="digital"></input-switchlist>\n' +
    '                <input-switchlist flex="33" book="tabPublish.book" disabled="tabPublish.disabled" array="tabPublish.supportList" switch="tabPublish.switch.support" label="Support Tools" model="support"></input-switchlist>\n' +
    '                <input-switchlist flex="33" book="tabPublish.book" disabled="tabPublish.disabled" array="tabPublish.inSituList" switch="tabPublish.switch.inSitu" label="Evidence" model="inSitu"></input-switchlist>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-components/right-menu/tab-products/tab-products.tpl.html',
    '<md-content layout-padding class="products-tab">\n' +
    '    <div layout="row">\n' +
    '        <h4  flex wraps-tooltip="productids-title">Products</h4>\n' +
    '        <md-button aria-label="Open search" class="md-icon-button" ng-hide="tabProducts.searchProductIds" ng-click="tabProducts.searchProductIds=true" wraps-tooltip="productids-search-button"  aria-label="Open search">\n' +
    '            <ng-md-icon icon="search" style="fill: grey;" size="24"></ng-md-icon>\n' +
    '        </md-button>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <div layout="column">\n' +
    '        <div ng-show="tabProducts.selectedRows && tabProducts.selectedRows.length > 0" flex="100">\n' +
    '            <md-chips md-removable="true" readonly="true" ng-model="tabProducts.selectedRows">\n' +
    '                <md-chip-template>\n' +
    '                    <strong>{{$chip.productId}}</strong> - {{$chip.Insurers.insurerName}} - {{$chip.masterProductGroup}}\n' +
    '                </md-chip-template>\n' +
    '\n' +
    '            </md-chips>\n' +
    '        </div>\n' +
    '\n' +
    '        <md-data-table-container>\n' +
    '            <table md-data-table style="width:100%"> <!--md-row-select="selected"-->\n' +
    '                <thead>\n' +
    '                <tr ng-show="tabProducts.searchProductIds">\n' +
    '\n' +
    '                    <td wraps-tooltip="productids-search-input">\n' +
    '                        <md-input-container>\n' +
    '                            <input ng-model="tabProducts[\'filterProductIds\'].Insurers.insurerName" style="width: 90%;"\n' +
    '                                   aria-label="Filter Insurer Name"></md-input-container>\n' +
    '                    </td>\n' +
    '\n' +
    '                    <td wraps-tooltip="productids-search-input">\n' +
    '                        <md-input-container>\n' +
    '                            <input ng-model="tabProducts[\'filterProductIds\'].masterProductGroup" style="width: 90%;"\n' +
    '                                   aria-label="Filter Master Product Group"></md-input-container>\n' +
    '                    </td>\n' +
    '\n' +
    '                    <td wraps-tooltip="productids-search-input">\n' +
    '                        <md-input-container>\n' +
    '                            <input ng-model="tabProducts[\'filterProductIds\'].productCategory" style="width: 90%;"\n' +
    '                                   aria-label="Filter Product Category / Group"></md-input-container>\n' +
    '                    </td>\n' +
    '\n' +
    '                    <td wraps-tooltip="productids-search-input">\n' +
    '                        <md-input-container>\n' +
    '                            <input ng-model="tabProducts[\'filterProductIds\'].productName" style="width: 90%;"\n' +
    '                                   aria-label="Filter Product Name"></md-input-container>\n' +
    '                    </td>\n' +
    '\n' +
    '                    <td wraps-tooltip="productids-search-input">\n' +
    '                        <md-input-container>\n' +
    '                            <input ng-model="tabProducts[\'filterProductIds\'].subProduct" style="width: 90%;"\n' +
    '                                   aria-label="Filter Sub Product"></md-input-container>\n' +
    '                    </td>\n' +
    '\n' +
    '                    <td wraps-tooltip="productids-search-input">\n' +
    '                        <md-input-container>\n' +
    '                            <input ng-model="tabProducts[\'filterProductIds\'].indiOrSME" style="width: 90%;"\n' +
    '                                   aria-label="Filter Indi / SME"></md-input-container>\n' +
    '                    </td>\n' +
    '\n' +
    '                    <td wraps-tooltip="productids-search-input">\n' +
    '                        <md-input-container>\n' +
    '                            <input ng-model="tabProducts[\'filterProductIds\'].productId" style="width: 90%;"\n' +
    '                                   aria-label="Filter Product Ids"></md-input-container>\n' +
    '                    </td>\n' +
    '\n' +
    '                    <td style="padding:0 10px 0 3px">\n' +
    '                        <div class="md-actions" style="float:right">\n' +
    '                            <md-button aria-label="Close Search" class="md-icon-button"\n' +
    '                                       ng-click="tabProducts.searchProductIds=false;"\n' +
    '                                       wraps-tooltip="productids-search-close">\n' +
    '                                <ng-md-icon icon="close" style="fill: grey; " size="24"></ng-md-icon>\n' +
    '                            </md-button>\n' +
    '                        </div>\n' +
    '                    </td>\n' +
    '                </tr>\n' +
    '                </thead>\n' +
    '                <thead>\n' +
    '                <tr>\n' +
    '                    <th ng-click="sortBy(\'insurerName\')" wraps-tooltip="tabProducts-table-heading">Insurer Name<ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: tabProducts.orderBy === \'insurerName\', reverse: tabProducts.reverse}"></th>\n' +
    '                    <th ng-click="sortBy(\'masterProductGroup\')" wraps-tooltip="tabProducts-table-heading">Master Product Group<ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: tabProducts.orderBy === \'masterProductGroup\', reverse: tabProducts.reverse}"></th>\n' +
    '                    <th ng-click="sortBy(\'productCategory\')" wraps-tooltip="tabProducts-table-heading">Product Category / Group<ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: tabProducts.orderBy === \'productCategory\', reverse: tabProducts.reverse}"></th>\n' +
    '                    <th ng-click="sortBy(\'productName\')" wraps-tooltip="tabProducts-table-heading">Product Name<ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: tabProducts.orderBy === \'productName\', reverse: tabProducts.reverse}"></th>\n' +
    '                    <th ng-click="sortBy(\'subProduct\')" wraps-tooltip="tabProducts-table-heading">Sub Product<ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: tabProducts.orderBy === \'subProduct\', reverse: tabProducts.reverse}"></th>\n' +
    '                    <th ng-click="sortBy(\'indiOrSME\')" wraps-tooltip="tabProducts-table-heading">Indi / SME<ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: tabProducts.orderBy === \'indiOrSME\', reverse: tabProducts.reverse}"></th>\n' +
    '                    <th ng-click="sortBy(\'productId\')" wraps-tooltip="tabProducts-table-heading">Product Id <ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: tabProducts.orderBy === \'productId\', reverse: tabProducts.reverse}"></ng-md-icon></th>\n' +
    '                    <th></th>\n' +
    '                </tr>\n' +
    '                </thead>\n' +
    '\n' +
    '                <tbody>\n' +
    '\n' +
    '                <tr ng-repeat="productIds in tabProducts.filteredRows = (tabProducts.productIds | filter:tabProducts[\'filterProductIds\']) | orderBy:tabProducts.orderBy:tabProducts.reverse | limitTo: tabProducts.productsQuery.limit: (tabProducts.productsQuery.page- 1) * tabProducts.productsQuery.limit">\n' +
    '                    <td>\n' +
    '                        <span>{{productIds.Insurers.insurerName}}</span>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                        <span>{{productIds.masterProductGroup}}</span>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                        <span>{{productIds.productCategory}}</span>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                        <span>{{productIds.productName}}</span>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                        <span>{{productIds.subProduct}}</span>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                        <span>{{productIds.indiOrSME}}</span>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                        <span>{{productIds.productId}}</span>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                        <md-checkbox ng-model="tabProducts.book.productIdsSelectedArray[productIds.productId]" ng-disabled="tabProducts.disabled" aria-label="checkbox"\n' +
    '                                     wraps-tooltip="productids-select-row"></md-checkbox>\n' +
    '                    </td>\n' +
    '                </tr>\n' +
    '                </tbody>\n' +
    '            </table>\n' +
    '        </md-data-table-container>\n' +
    '        <md-data-table-pagination md-limit="tabProducts.productsQuery.limit" md-page="tabProducts.productsQuery.page" md-total="{{tabProducts.filteredRows.length}}" style="width:100%"></md-data-table-pagination>\n' +
    '    </div>\n' +
    '</md-content>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-components/right-menu/tab-main/tab-main.tpl.html',
    '<md-content layout-padding>\n' +
    '    <div layout="row"  layout-wrap>\n' +
    '        <md-input-container flex="100" wraps-tooltip="docman-main-input_name">\n' +
    '            <label>Name *</label>\n' +
    '            <input ng-model="tabMain.book.bookName" ng-keypress="tabMain.validateCharactors($event)" ng-disabled="tabMain.disabled" name="bookName" flex="66"> <span flex="33" class="charter-length-text" ng-class="{error: (tabMain.book.bookName + \'\').length > 50}"> {{(tabMain.book.bookName + \'\').length}}</span>\n' +
    '            <div class="clear-left charter-length-text"> (the following characters are not allowed: <>:"/\\|?*)</div>\n' +
    '            <span ng-if="tabMain.checkBlank(tabMain.book.bookName) && tabMain.documentInfoForm.bookName.$touched" class="error animate-error-if" style="clear:left;">Mandatory field, please complete</span>\n' +
    '            <span ng-if="(tabMain.book.bookName + \'\').length > 50" class="error animate-error-if" style="clear:left;">Some systems have a 50 character limit</span>\n' +
    '\n' +
    '        </md-input-container>\n' +
    '    </div>\n' +
    '\n' +
    '    <div layout="row"  layout-wrap>\n' +
    '        <md-input-container flex="100" wraps-tooltip="docman-main-input_code">\n' +
    '            <label>Code *</label>\n' +
    '            <input ng-model="tabMain.book.bookCode" ng-disabled="tabMain.book.bookCodeOld !== \'disableAutoCode\'" name="bookCode" flex="66">\n' +
    '        </md-input-container>\n' +
    '\n' +
    '        <!-- <md-input-container flex="33" wraps-tooltip="docman-main-input_psi-code">\n' +
    '            <md-checkbox class="" ng-model="tabMain.hasPsi" aria-label="PSI" style="margin: 0px;" ng-disabled="tabMain.disabled" flex="33" ng-change="tabMain.setPsi()">\n' +
    '                PSI\n' +
    '            </md-checkbox>\n' +
    '        </md-input-container>\n' +
    '        <md-input-container flex="66" wraps-tooltip="docman-main-input_psi-code" ng-show="tabMain.hasPsi" >\n' +
    '            <label>PSI Code</label>\n' +
    '            <input ng-model="tabMain.book.psiCode" ng-disabled="tabMain.disabled" name="bookCode">\n' +
    '        </md-input-container> -->\n' +
    '    </div>\n' +
    '\n' +
    '    <div layout="row" layout-wrap>\n' +
    '        <md-input-container flex="33">\n' +
    '            <label>Division *</label>\n' +
    '            <md-select ng-model="tabMain.book.divisionId" ng-change="tabMain.clearDivisionDataDialog(\'{{tabMain.book.divisionId}}\')" placeholder="Division *"  ng-disabled="tabMain.allowedDocTypeEdit() || !tabMain.hasPermissions(\'editDivisionOnDocs\')"  name="DocTypeCat">\n' +
    '                <md-option   ng-value="item.divisionId" ng-repeat="item in tabMain.divisions | orderBy:[\'division\']" >{{item.division}}</md-option>\n' +
    '            </md-select>\n' +
    '            <span ng-if="tabMain.temp.division == false && tabMain.documentInfoForm.DocTypeCat.$touched" class="error animate-error-if">Mandatory field, please complete</span>\n' +
    '        </md-input-container>\n' +
    '        <md-input-container flex="66" wraps-tooltip="docman-main-input_owner">\n' +
    '            <label>Owner *</label>\n' +
    '            <md-select ng-model="tabMain.book.bookOwner" placeholder="Owner *"  ng-disabled="tabMain.disabled" name="bookOwner">\n' +
    '                <md-option value="{{user.id}}" ng-if="user.status != 2" ng-repeat="user in tabMain.users | filter: {divisionId:tabMain.hasPermissions(\'editDivisionOnDocs\')? undefined : tabMain.book.divisionId} | orderBy:[ \'firstName\', \'lastName\', \'Teams.teamName\']" > {{user.firstName}} {{user.lastName}} - <b>({{user.Divisions.division}}/{{user.Teams.teamName}})</b></md-option>\n' +
    '            </md-select>\n' +
    '            <span ng-if="tabMain.checkBlank(tabMain.book.bookOwner) && tabMain.documentInfoForm.bookOwner.$touched" class="error animate-error-if">Mandatory field, please complete</span>\n' +
    '        </md-input-container>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <div layout="row" ng-if="tabMain.newlanguage === true" layout-wrap>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '        <md-input-container flex="33">\n' +
    '            <label>JSON translation file</label>\n' +
    '        </md-input-container>\n' +
    '\n' +
    '        <!--<div layout-margin layout-padding>\n' +
    '            <input ng-model="tabMain.book.jsonUpload" onchange="angular.element(this).tabMain.uploadFile(this.files)" class="ng-hide" id="input-file-id" type="file" />\n' +
    '            <label for="input-file-id" class="md-button md-raised md-primary">Choose Files</label>\n' +
    '\n' +
    '        </div>-->\n' +
    '\n' +
    '        <div layout="row">\n' +
    '            <div flex="66" layout="row" layout-wrap>\n' +
    '\n' +
    '                <ul class="file-ui">\n' +
    '                    <li ng-repeat="f in tabMain.JSONfilesList" style="font:smaller" class="file-li animate-if">{{f.fileName}}\n' +
    '                        <div class="file-prog" ng-mouseenter="f.hovered = true" ng-mouseleave="f.hovered = false">\n' +
    '                            <md-progress-circular class="animate-if" ng-if="f.determinateValue < 100" md-mode="determinate" value="{{f.determinateValue}}" md-diameter="30"></md-progress-circular>\n' +
    '                            <span class="animate-ifx" ng-if="f.determinateValue < 100" >{{f.determinateValue}}%</span>\n' +
    '                            <ng-md-icon class="animate-if file-complete" ng-if="f.determinateValue === 100"  icon="check_circle" size="24"  aria-label="Upload complete"></ng-md-icon>\n' +
    '                            <ng-md-icon class="file-remove" ng-if="f.hovered"  icon="cancel" size="24"  aria-label="Remove file" ng-click="tabMain.removeFile(tabMain.JSONfilesList, $index)"></ng-md-icon>\n' +
    '                        </div>\n' +
    '                    </li>\n' +
    '                </ul>\n' +
    '            </div>\n' +
    '            <div flex="5"></div>\n' +
    '            <div flex="33">\n' +
    '                <div ngf-drop ngf-select ng-model="tabMain.JSONFile" class="drop-box"\n' +
    '                     ngf-drag-over-class="\'dragover\'" ngf-multiple="false" ngf-allow-dir="true"\n' +
    '                     accept="*"\n' +
    '                     ngf-pattern="\'*\'"><ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop files here or<br /><strong style="text-decoration: underline;">Click to upload</strong></div>\n' +
    '                <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <div layout="row"  layout-wrap>\n' +
    '\n' +
    '        <md-input-container flex="33">\n' +
    '            <label>Master Doc Type *</label>\n' +
    '            <md-select ng-model="tabMain.temp.DocTypeCat" placeholder="Master Doc Type *" ng-disabled="tabMain.allowedDocTypeEdit()" name="DocTypeCat">\n' +
    '                <md-option ng-if="docTypeCat.divisionId == tabMain.book.divisionId"  ng-value="docTypeCat.docTypeCatId" ng-repeat="docTypeCat in tabMain.docTypeCats | filter: {divisionId:tabMain.book.divisionId} | orderBy: \'docTypeCatName\'" >{{docTypeCat.docTypeCatName}}</md-option>\n' +
    '            </md-select>\n' +
    '            <span ng-if="tabMain.temp.DocTypeCat == false && tabMain.documentInfoForm.DocTypeCat.$touched" class="error animate-error-if">Mandatory field, please complete</span>\n' +
    '        </md-input-container>\n' +
    '\n' +
    '        <md-input-container flex="33" ng-show="tabMain.temp.DocTypeCat" wraps-tooltip="docman-main-input_subdoctype" ng-if="tabMain.book.divisionId">\n' +
    '            <label>Doc Type *</label>\n' +
    '            <md-select ng-model="tabMain.book.DocTypes" placeholder="Doc Type *"  ng-disabled="tabMain.allowedDocTypeEdit()"  ng-model-options="{trackBy: \'$value.docTypeId\'}" ng-change="tabMain.setWorkflow(); tabMain.generateDocCode();"  name="DocTypes">\n' +
    '                <md-option   ng-value="docType" ng-repeat="docType in tabMain.docTypes | filter: {docTypeCatId: tabMain.temp.DocTypeCat, docTypeStatus: 1, divisionId: tabMain.book.divisionId} | orderBy: \'docTypeName\'" >{{docType.docTypeName}}</md-option>\n' +
    '            </md-select>\n' +
    '            <span ng-if="tabMain.checkBlank(tabMain.book.DocTypes) && tabMain.documentInfoForm.DocTypes.$touched" class="error animate-error-if">Mandatory field, please complete</span>\n' +
    '        </md-input-container>\n' +
    '\n' +
    '        <md-input-container flex="33" ng-if="tabMain.book.DocTypes.docTypeTemplate === 1" wraps-tooltip="docman-main-input_glossemph">\n' +
    '            <label>Glossary Emphasis *</label>\n' +
    '            <md-select ng-model="tabMain.book.emphasis" placeholder="Glossary Emphasis *"  ng-disabled="tabMain.disabled" name="emphasis">\n' +
    '                <md-option value="{{emphasis.id}}"  ng-repeat="emphasis in tabMain.emphasis | orderBy: \'name\'" >{{emphasis.name}}</md-option>\n' +
    '            </md-select>\n' +
    '            <span ng-if="tabMain.checkBlank(tabMain.book.emphasis) && tabMain.documentInfoForm.emphasis.$touched" class="error animate-error-if">Mandatory field, please complete</span>\n' +
    '        </md-input-container>\n' +
    '\n' +
    '        <md-input-container flex="33" ng-if="tabMain.book.DocTypes.docTypeId == 93">\n' +
    '            <label>BGHP Colour *</label>\n' +
    '            <md-select ng-model="tabMain.book.extras.BGHPColour" placeholder="BGHP Colour *"  ng-disabled="tabMain.disabled" name="BGHPColour">\n' +
    '                <md-option value="{{colour.id}}"  ng-repeat="colour in tabMain.BGHPColour | orderBy:\'name\'" >{{colour.name}}</md-option>\n' +
    '            </md-select>\n' +
    '            <span ng-if="tabMain.checkBlank(tabMain.book.extras.BGHPColour) && tabMain.documentInfoForm.emphasis.$touched" class="error animate-error-if">Mandatory field, please complete</span>\n' +
    '        </md-input-container>\n' +
    '\n' +
    '        <md-input-container flex="33" ng-if="tabMain.book.DocTypes.docTypeId == 182"> <!-- TODO change 182 to Social Asset DocType Id -->\n' +
    '            <label>Social Channels *</label>\n' +
    '            <md-select ng-model="tabMain.book.socialChannelIds" placeholder="Social Channels *"  ng-disabled="tabMain.disabled" name="socialChannels" multiple>\n' +
    '                <md-option value="{{channel.socialChannelId}}"  ng-repeat="channel in tabMain.socialChannels | unique: \'socialChannelId\' | orderBy:\'socialChannelName\'" >{{channel.socialChannelName}}</md-option>\n' +
    '            </md-select>\n' +
    '            <span ng-if="tabMain.checkBlank(tabMain.book.socialChannelIds) && tabMain.documentInfoForm.socialChannels.$touched" class="error animate-error-if">Mandatory field, please complete</span>\n' +
    '        </md-input-container>\n' +
    '\n' +
    '    </div>\n' +
    '    <div layout="row"  layout-wrap>\n' +
    '\n' +
    '\n' +
    '        <md-input-container flex="33" wraps-tooltip="docman-main-input_insuredby" ng-if="tabMain.book.divisionId">\n' +
    '            <label>Insured By *</label>\n' +
    '            <md-select ng-model="tabMain.book.insurerId"\n' +
    '                    ng-change="tabMain.insurerDetailsDialog(); tabMain.generateDocCode(); tabMain.filteredInsurers = tabMain.currentlySelectedOrSelectableFilter(tabMain.insurers, \'insurerId\', \'insurer\');" placeholder="Insured By *"  ng-disabled="tabMain.disabled"  name="insurerId">\n' +
    '                <md-option value="{{insurer.insurerId}}"  ng-repeat="insurer in tabMain.filteredInsurers | unique: \'insurerId\' | orderBy: \'insurerName\'" >{{insurer.insurerName}}<i ng-if="insurer.insurerStatus === 0 "> - disabled </i></md-option>\n' +
    '            </md-select>\n' +
    '            <span ng-if="tabMain.checkBlank(tabMain.book.insurerId) && tabMain.documentInfoForm.insurerId.$touched" class="error animate-error-if">Mandatory field, please complete</span>\n' +
    '        </md-input-container>\n' +
    '\n' +
    '        <md-input-container flex="33" ng-if="tabMain.book.divisionId">\n' +
    '            <label>Product *</label>\n' +
    '            <md-select ng-model="tabMain.book.productId" placeholder="Product *" ng-change="tabMain.generateDocCode()"  ng-disabled="tabMain.disabled"  name="productId" wraps-tooltip="docman-main-input_product">\n' +
    '                <md-option value="{{product.productId}}"  ng-repeat="product in tabMain.products | filter: {divisionId:tabMain.book.divisionId} | orderBy:\'productName\'" >{{product.productName}}</md-option>\n' +
    '            </md-select>\n' +
    '            <span ng-if="tabMain.checkBlank(tabMain.book.productId) && tabMain.documentInfoForm.productId.$touched" class="error animate-error-if">Mandatory field, please complete</span>\n' +
    '        </md-input-container>\n' +
    '\n' +
    '        <md-input-container flex="33" wraps-tooltip="docman-main-input_region" ng-if="tabMain.book.divisionId">\n' +
    '            <label>Region *</label>\n' +
    '            <md-select ng-model="tabMain.book.regionId" placeholder="Region *" ng-disabled="tabMain.disabled" name="regionId" >\n' +
    '                <md-option value="{{region.regionId}}"  ng-repeat="region in tabMain.regions | filter: {divisionId:tabMain.book.divisionId} | orderBy: \'regionName\'" >{{region.regionName}}</md-option>\n' +
    '            </md-select>\n' +
    '            <span ng-if="tabMain.checkBlank(tabMain.book.regionId) && tabMain.documentInfoForm.regionId.$touched" class="error animate-error-if">Mandatory field, please complete</span>\n' +
    '        </md-input-container>\n' +
    '\n' +
    '        <md-input-container flex="33" wraps-tooltip="docman-main-input_insurerDetails" ng-if="tabMain.book.divisionId" ng-hide="tabMain.book.insurerId != \'38\' || tabMain.showInsurerDetails !== true"><!-- non specific insurer id -->\n' +
    '            <label>Insurer Details *</label>\n' +
    '            <md-select multiple ng-model="tabMain.book.insurerDetailsSelectedArray" placeholder="Insurer Details *"  ng-disabled="tabMain.disabled" name="insurerId"\n' +
    '                       ng-change="tabMain.filteredMultiInsurers = tabMain.currentlySelectedOrSelectableFilter(tabMain.insurers, \'insurerDetailsSelectedArray\', \'insurer\')">\n' +
    '                <md-option ng-if="insurer.insurerId !== 38"\n' +
    '                        value="{{insurer.insurerId}}"  ng-repeat="insurer in tabMain.filteredMultiInsurers | unique: \'insurerId\' | orderBy: \'insurerName\'">{{insurer.insurerName}}<i ng-if="insurer.insurerStatus === 0 "> - disabled </i></md-option>\n' +
    '            </md-select>\n' +
    '            <span ng-if="tabMain.checkBlank(tabMain.book.insurerId) && tabMain.documentInfoForm.insurerId.$touched" class="error animate-error-if">Mandatory field, please complete</span>\n' +
    '        </md-input-container>\n' +
    '\n' +
    '\n' +
    '    </div>\n' +
    '    <div layout="row"  layout-wrap>\n' +
    '\n' +
    '        <md-input-container flex="33" ng-if="tabMain.book.divisionId">\n' +
    '            <label>Customer Type *</label>\n' +
    '            <md-select ng-model="tabMain.book.productTypeId" placeholder="Customer Type *"  ng-disabled="tabMain.disabled"  name="productTypeId">\n' +
    '                <md-option value="{{productType.productTypeId}}"  ng-repeat="productType in tabMain.productTypes | filter: {divisionId:tabMain.book.divisionId} | orderBy: \'productTypeName\'" >{{productType.productTypeName}}</md-option>\n' +
    '            </md-select>\n' +
    '            <span ng-if="tabMain.checkBlank(tabMain.book.productTypeId) && tabMain.documentInfoForm.productTypeId.$touched" class="error animate-error-if">Mandatory field, please complete</span>\n' +
    '        </md-input-container>\n' +
    '\n' +
    '        <md-input-container flex="33" ng-if="tabMain.book.productTypeId == 4 && tabMain.book.divisionId">\n' +
    '            <label>Client *</label>\n' +
    '            <md-select ng-model="tabMain.book.clientId" placeholder="Client *" ng-change="tabMain.generateDocCode()" ng-disabled="tabMain.disabled" name="clientId">\n' +
    '                <md-option value="{{client.clientId}}"  ng-repeat="client in tabMain.clients | filter: {divisionId:tabMain.book.divisionId} | orderBy: \'clientName\'" >{{client.clientName}}</md-option>\n' +
    '            </md-select>\n' +
    '            <span ng-if="tabMain.checkBlank(tabMain.book.clientId) && tabMain.book.productTypeId == 4 && tabMain.documentInfoForm.clientId.$touched" class="error animate-error-if">Mandatory field, please complete</span>\n' +
    '        </md-input-container>\n' +
    '\n' +
    '        <md-input-container flex="33" wraps-tooltip="docman-main-input_audience" ng-if="tabMain.book.divisionId">\n' +
    '            <label>Audience *</label>\n' +
    '            <md-select ng-model="tabMain.book.audienceId" placeholder="Audience *"  ng-disabled="tabMain.disabled"   name="audienceId">\n' +
    '                <md-option value="{{audience.audienceId}}"  ng-repeat="audience in tabMain.audiences | filter: {divisionId:tabMain.book.divisionId} | orderBy: \'audienceName\'" >{{audience.audienceName}}</md-option>\n' +
    '            </md-select>\n' +
    '            <span ng-if="tabMain.checkBlank(tabMain.book.audienceId) && tabMain.documentInfoForm.audienceId.$touched" class="error animate-error-if">Mandatory field, please complete</span>\n' +
    '        </md-input-container>\n' +
    '\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '    <div layout="row"  layout-wrap>\n' +
    '\n' +
    '        <md-input-container flex="33" wraps-tooltip="docman-main-input_language" ng-if="tabMain.book.divisionId">\n' +
    '            <label>Language *</label>\n' +
    '            <md-select ng-model="tabMain.book.language" placeholder="Language *"  ng-disabled="tabMain.disabled" ng-change="tabMain.generateDocCode()" name="language">\n' +
    '                <md-option value="{{language.id}}"  ng-repeat="language in tabMain.languages | filter: {divisionId:tabMain.book.divisionId} | orderBy: \'name\'" >{{language.name}}</md-option>\n' +
    '            </md-select>\n' +
    '            <span ng-if="tabMain.checkBlank(tabMain.book.language) && tabMain.documentInfoForm.language.$touched" class="error animate-error-if">Mandatory field, please complete</span>\n' +
    '        </md-input-container>\n' +
    '\n' +
    '        <md-input-container flex="33" ng-if="tabMain.book.divisionId" ng-hide="divisionSubInsurers == 0">\n' +
    '            <label>Sub Insured By</label>\n' +
    '            <md-select ng-model="tabMain.book.subInsurerId" placeholder="Sub Insured By"  ng-disabled="tabMain.disabled">\n' +
    '                <md-option value="">None</md-option>\n' +
    '                <md-option value="{{subInsurer.subInsurerId}}"  ng-repeat="subInsurer in divisionSubInsurers = (tabMain.subInsurers | filter: {divisionId:tabMain.book.divisionId} | orderBy: \'SubInsurerName\')" >{{subInsurer.subInsurerName}}</md-option>\n' +
    '            </md-select>\n' +
    '        </md-input-container>\n' +
    '\n' +
    '\n' +
    '        <md-input-container flex="33" ng-if="tabMain.book.divisionId" ng-hide="divisionDistributors == 0">\n' +
    '            <label>Distributor </label>\n' +
    '            <md-select ng-model="tabMain.book.distributorID" placeholder="Distributor"  ng-disabled="tabMain.disabled">\n' +
    '                <md-option value="">None</md-option>\n' +
    '                <md-option value="{{distributor.distributorId}}"  ng-repeat="distributor in divisionDistributors = (tabMain.distributors | filter: {divisionId:tabMain.book.divisionId} | orderBy: \'DistributorName\')" >{{distributor.distributorName}}</md-option>\n' +
    '            </md-select>\n' +
    '        </md-input-container>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <div layout="row"  layout-wrap>\n' +
    '\n' +
    '        <md-input-container flex="33" ng-if="tabMain.book.divisionId">\n' +
    '            <label>Project *</label>\n' +
    '            <md-select ng-model="tabMain.book.projectId" placeholder="Project *"  ng-disabled="tabMain.disabled" name="Project" ng-change="tabMain.filteredProjects = tabMain.currentlySelectedOrSelectableFilter(tabMain.projects, \'projectId\', \'project\');">\n' +
    '                <md-option value="{{project.projectId}}"  ng-repeat="project in tabMain.filteredProjects | unique: \'projectId\' | orderBy: \'projectName\'" >{{project.projectName}}</md-option>\n' +
    '            </md-select>\n' +
    '            <span ng-if="tabMain.checkBlank(tabMain.book.projectId) && tabMain.documentInfoForm.Project.$touched" class="error animate-error-if">Mandatory field, please complete</span>\n' +
    '        </md-input-container>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '    <div layout="row"  layout-wrap>\n' +
    '        <md-input-container flex="33" wraps-tooltip="docman-main-input_finprom">\n' +
    '            <span>Financial Promotion *\n' +
    '                <ng-md-icon icon="help">\n' +
    '                    <md-tooltip md-direction="top">\n' +
    '                        <p>A financial promotion is a communication that promotes brand, health and travel insurance plans in an attempt to persuade customers to purchase them, through any medium, print or digital.</p>\n' +
    '                        <p>If you require further information or support, contact Conduct Risk Marketing team.</p>\n' +
    '                    </md-tooltip>\n' +
    '                </ng-md-icon></span>\n' +
    '            <md-radio-group ng-model="tabMain.book.finProm">\n' +
    '                <md-radio-button value="true">Yes</md-radio-button>\n' +
    '                <md-radio-button value="false">No</md-radio-button>\n' +
    '            </md-radio-group>\n' +
    '        </md-input-container>\n' +
    '\n' +
    '        <md-input-container flex="33">\n' +
    '            <md-checkbox class=""  ng-model="tabMain.book.limitDoc" ng-disabled="tabMain.disabled" aria-label="Private Document" style="margin: 0px;">\n' +
    '                Private Document\n' +
    '            </md-checkbox>\n' +
    '        </md-input-container>\n' +
    '\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '</md-content>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-components/right-menu/tab-notes/tab-notes.tpl.html',
    '<md-content layout-padding>\n' +
    '    <div layout="row"  layout-wrap>\n' +
    '        <md-input-container flex="100" >\n' +
    '            <label>Notes</label>\n' +
    '            <textarea ng-model="tabNotes.book.notes"  ng-disabled="tabNotes.disabled"></textarea>\n' +
    '        </md-input-container>\n' +
    '    </div>\n' +
    '</md-content>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-components/right-menu/tab-levels/tab-levels.tpl.html',
    '<md-content layout-padding>\n' +
    '    <h4>Level Labels</h4>\n' +
    '    <input-level book="tabLevels.book" disabled="tabLevels.disabled" level="level1"></input-level>\n' +
    '    <input-level book="tabLevels.book" disabled="tabLevels.disabled" level="level2"></input-level>\n' +
    '    <input-level book="tabLevels.book" disabled="tabLevels.disabled" level="level3"></input-level>\n' +
    '    <input-level book="tabLevels.book" disabled="tabLevels.disabled" level="level4"></input-level>\n' +
    '\n' +
    '    <md-divider></md-divider>\n' +
    '\n' +
    '    <input-level book="tabLevels.book" disabled="tabLevels.disabled" level="payment"></input-level>\n' +
    '</md-content>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-components/right-menu/tab-enclosures/tab-enclosures.tpl.html',
    '<md-content layout-padding class="enclosures-tab">\n' +
    '    <h4>Enclosures</h4>\n' +
    '    <p>Enter the enclosures to be linked to this letter in the appropriate fields; leave the field\n' +
    '        blank if there is no correlating enclosure.<br /> Enclosures should be written either as per the\n' +
    '        Wraps ID, or in the case of generic documents, as per their Wraps name.<br/>\n' +
    '        Note that these enclosures should be correct at the time of publishing.</p>\n' +
    '\n' +
    '\n' +
    '    <div layout="row" layout-wrap>\n' +
    '        <table flex="100">\n' +
    '            <tbody>\n' +
    '            <tr ng-repeat="enclosureType in tabEnclosures.enclosureTypes">\n' +
    '                <td>\n' +
    '                    <span style="font-size:0.9em">{{$index + 1}} &nbsp; {{enclosureType.enclosureTypeName}}</span>\n' +
    '                </td>\n' +
    '                <td style="width:75%">\n' +
    '                    <md-input-container style="width:100%">\n' +
    '                        <md-autocomplete\n' +
    '                                md-selected-item="selectedItem"\n' +
    '                                md-search-text="tabEnclosures.book.enclosures[\'enclosure_\'+enclosureType.enclosureTypeId]"\n' +
    '                                md-delay="200"\n' +
    '                                md-min-length="3"\n' +
    '                                md-items="item in tabEnclosures.getBooksForEnclosureType(tabEnclosures.book.enclosures[\'enclosure_\'+enclosureType.enclosureTypeId], enclosureType.enclosureDocTypeId)"\n' +
    '                                md-item-text="item.bookCode"\n' +
    '                                ng-disabled="tabEnclosures.disabled">\n' +
    '                            <span md-highlight-text="searchText">{{item.bookCode}}</span>\n' +
    '                        </md-autocomplete>\n' +
    '                    </md-input-container>\n' +
    '                </td>\n' +
    '            </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-components/right-menu/tab-dates/tab-dates.tpl.html',
    '<md-content layout-padding>\n' +
    '    <div layout="row" layout-wrap>\n' +
    '        <div style="margin-top:10px" flex="100" layout="row" layout-align="center center"">\n' +
    '        <!--<span class="timeline-step" wraps-tooltip="docman-date-event_active" ng-if="tabDates.reviewDateDescription != \'Expiry Date\'">\n' +
    '            <span class="inner" md-colors="tabDates.focus == \'active\' || tabDates.focus == \'approval\' ? {background:\'accent\'} : {color:\'primary\'}"></span>\n' +
    '            <span class="label">Active</span>\n' +
    '        </span>-->\n' +
    '        <date-icon date="tabDates.book.activeDate" color="tabDates.focus == \'active\' ? \'accent\' : \'background-500\'" label="Active" ng-if="tabDates.reviewDateDescription != \'Expiry Date\'"></date-icon>\n' +
    '        <div flex class="timeline" md-colors="tabDates.focus == \'active\' ? {background:\'accent\'} : {color:\'primary\'}" ng-if="tabDates.reviewDateDescription != \'Expiry Date\'"></div>\n' +
    '        <date-icon date="tabDates.book.liveDate" color="tabDates.focus == \'live\' || tabDates.focus == \'approval\' ? \'accent\' : \'background-500\'" label="Live" notsetlabel="\'on,App,\'"></date-icon>\n' +
    '        <div flex  class="timeline" md-colors="tabDates.focus == \'expiry\' ? {background:\'accent\'} : {color:\'primary\'}"></div>\n' +
    '        <div flex  class="timeline" md-colors="tabDates.focus == \'expiry\' ? {background:\'accent\'} : {color:\'primary\'}"></div>\n' +
    '        <date-icon date="tabDates.book.reviewDate" color="tabDates.focus == \'review\' ? \'accent\' : \'background-500\'" label="Review" notsetlabel="tabDates.book.dates.reviewPeriod"></date-icon>\n' +
    '        <div flex  class="timeline" md-colors="tabDates.focus == \'expiry\' || tabDates.focus == \'review\' ? {background:\'accent\'} : {color:\'primary\'}"></div>\n' +
    '        <span class="timeline-placeholder timeline" md-colors="tabDates.focus == \'expiry\' ? {background:\'accent\'} : {color:\'primary\'}" ng-if="tabDates.reviewDateDescription != \'Expiry Date\'">\n' +
    '                    </span>\n' +
    '\n' +
    '        <div flex ng-if="tabDates.reviewDateDescription != \'Expiry Date\'" class="timeline" md-colors="tabDates.focus == \'expiry\' ? {background:\'accent\'} : {color:\'primary\'}"></div>\n' +
    '        <date-icon date="tabDates.book.expiryDate" color="tabDates.focus == \'expiry\' ? \'accent\' : \'background-500\'" label="Expiry" notsetlabel="tabDates.book.dates.lifecyclePeriod"></date-icon>\n' +
    '\n' +
    '    </div>\n' +
    '    <div style="margin-top:25px" flex="100" layout="row" layout-align="center center" ng-if="tabDates.reviewDateDescription != \'Expiry Date\'">\n' +
    '         <span class="timeline-placeholder"></span>\n' +
    '        <div flex></div>\n' +
    '        <span class="timeline-placeholder"></span>\n' +
    '        <div flex></div>\n' +
    '        <div flex></div>\n' +
    '        <span class="timeline-placeholder"></span>\n' +
    '        <div flex></div>\n' +
    '        <date-icon date="tabDates.book.dates.activeDateAnniversary" color="tabDates.focus == \'active\' ? \'accent-300\' : \'background-400\'" label="{{tabDates.reviewDateDescription}}"></date-icon>\n' +
    '        <div flex class="timeline" md-colors="tabDates.focus == \'active\' ? {background:\'accent-300\'} : {background:\'background-400\'}"></div>\n' +
    '        <date-icon date="tabDates.book.dates.liveDateAnniversary" color="tabDates.focus == \'expiry\' ? \'accent-300\' : \'background-400\'" label="Next Live"></date-icon>\n' +
    '    </div>\n' +
    '    <h4 flex="100">&nbsp;</h4>\n' +
    '\n' +
    '\n' +
    '    <md-input-container flex="0">\n' +
    '    <!--Hidden until LatAm release-->\n' +
    '        <!--<labeL>Timezone for Document Dates</labeL>-->\n' +
    '        <!--<md-select  flex="50" ng-model="tabDates.book.dates.timezone" ng-change="tabDates.changeLiveDate(tabDates.book)">-->\n' +
    '            <!--<md-option ng-repeat="zone in tabDates.timezones" ng-value="zone">{{zone}}</md-option>-->\n' +
    '        <!--</md-select>-->\n' +
    '    </md-input-container>\n' +
    '\n' +
    '    <!--tabDates.disabled: {{tabDates.disabled}}-->\n' +
    '\n' +
    '    <div flex="100">\n' +
    '    <md-input-container ng-mouseover="tabDates.focus = \'approval\'" ng-mouseleave="tabDates.focus = \'\'">\n' +
    '        <md-switch ng-model="tabDates.book.liveOnApproval" aria-label="Go Live on Approval" ng-change="tabDates.goLiveOnApproval()"  ng-disabled="tabDates.disabled">\n' +
    '            Go live on approval\n' +
    '            <md-button aria-label="Go Live on Approval Help" class="md-icon-button" style="position: relative; right:10px;" >\n' +
    '                <ng-md-icon icon="help" style="fill: darkgray;" size="20"></ng-md-icon>\n' +
    '                <!--<md-tooltip md-direction="right" >Go Live on Approval</md-tooltip>-->\n' +
    '                <md-tooltip md-direction="right" >Set the Live Date of this document to occur when the document has been approved</md-tooltip>\n' +
    '            </md-button>\n' +
    '        </md-switch>\n' +
    '    </md-input-container>\n' +
    '    </div>\n' +
    '\n' +
    '    <div flex="50">\n' +
    '    <md-input-container ng-if="!tabDates.book.liveOnApproval" ng-mouseover="tabDates.focus = \'active\'" ng-mouseleave="tabDates.focus = \'\'" >\n' +
    '\n' +
    '        <h4 wraps-tooltip="docman-dates-input_active" >\n' +
    '            Active Date\n' +
    '            <md-button aria-label="Active Date Help" class="md-icon-button" style="position: relative; right:10px;" >\n' +
    '                <ng-md-icon icon="help" style="fill: darkgray;" size="20"></ng-md-icon>\n' +
    '                --><md-tooltip md-direction="right" >Set the date when the document first becomes available to the end-user. <br/>This may be different to the Live Date e.g if the document needs to be assessed by customers prior to a product renewal.</md-tooltip>\n' +
    '            </md-button></h4>\n' +
    '\n' +
    '        <span class="dateDisplaySmall" >{{tabDates.book.activeDate | date:\'dd MMM yyyy HH:mm Z\'}}</span>\n' +
    '\n' +
    '        <md-radio-group ng-model="tabDates.book.dates.previewPeriod" ng-disabled="tabDates.disabled" ng-change="tabDates.changeActiveDate(tabDates.book)">\n' +
    '            <md-radio-button value="0,months">Same as Live</md-radio-button>\n' +
    '            <md-radio-button value="2,months">2 months before Live</md-radio-button>\n' +
    '            <md-radio-button value="1,months">1 month before Live</md-radio-button>\n' +
    '        </md-radio-group>\n' +
    '        <p ng-if="tabDates.book.activeDate  && tabDates.book.activeDate < tabDates.today" class="error animate-error-if">Active Date in the past</p>\n' +
    '\n' +
    '    </md-input-container>\n' +
    '    </div>\n' +
    '    <div flex="50">\n' +
    '    <md-input-container  ng-if="!tabDates.book.liveOnApproval" ng-mouseover="tabDates.focus = \'live\'" ng-mouseleave="tabDates.focus = \'\'">\n' +
    '        <h4 wraps-tooltip="docman-dates-input_live" >\n' +
    '            Live Date\n' +
    '            <md-button aria-label="Live Date Help" class="md-icon-button" style="position: relative; right:10px;" >\n' +
    '                <ng-md-icon icon="help" style="fill: darkgray;" size="20"></ng-md-icon>\n' +
    '                <!--<md-tooltip md-direction="right">This is the date that the content of the document is Live from.</md-tooltip>-->\n' +
    '                <md-tooltip md-direction="right">Set the date that the content of the document is Live from.</md-tooltip>\n' +
    '            </md-button>\n' +
    '        </h4>\n' +
    '        <span class="dateDisplaySmall" >{{tabDates.book.liveDate | date:\'dd MMM yyyy HH:mm Z\'}}</span> <!--{{tabDates.book.liveDate | date:\'dd MMM yyyy HH:mm Z\'}}-->\n' +
    '\n' +
    '        <!--<label class="animate-if" ng-if="tabDates.book.liveDate">Live</label>-->\n' +
    '        <md-datepicker  ng-model-options="{timezone:tabDates.book.dates.timezone}" ng-required="!tabDates.book.liveOnApproval"  ng-model="tabDates.book.liveDate" md-placeholder="Live Date"  ng-disabled="tabDates.disabled" flex  ng-change="tabDates.changeLiveDate(tabDates.book)" name="livedate"></md-datepicker>\n' +
    '        <div ng-messages="documentInfoForm.livedate.$error" role="alert" class="error animate-error-if">\n' +
    '            <div ng-message="required">Live Date not set</div>\n' +
    '            <div ng-message-default>Live Date is invalid</div>\n' +
    '        </div>\n' +
    '\n' +
    '        <p ng-if="tabDates.book.liveDate  && tabDates.book.liveDate < tabDates.today" class="error animate-error-if">Live Date in the past</p>\n' +
    '    </md-input-container>\n' +
    '    </div>\n' +
    '\n' +
    '    <div flex="50">\n' +
    '    <md-input-container ng-mouseover="tabDates.focus = \'review\'" ng-mouseleave="tabDates.focus = \'\'">\n' +
    '\n' +
    '        <h4 wraps-tooltip="docman-dates-input_review">\n' +
    '            Review Date\n' +
    '            <md-button aria-label="Review Date Help" class="md-icon-button" style="position: relative; right:10px;" >\n' +
    '                <ng-md-icon icon="help" style="fill: darkgray;" size="20"></ng-md-icon>\n' +
    '                <md-tooltip md-direction="right">Set the length of time for document reviews to take place. This will set a date based on when the next active date would be for renewing products, or based on the expiry date if no active period is set.</md-tooltip>\n' +
    '                <!--<md-tooltip md-direction="right">This date is when the review window opens before the end date. It gives the Content Owners and the Document Lifecycle Owner time to review, make amends and approve the new version of the document (if necessary). The Review Date is typically one year from the Active Date, minus the Review Date selected.</md-tooltip>\n' +
    '            --></md-button></h4>\n' +
    '        <span class="dateDisplaySmall" >{{tabDates.book.reviewDate | date:\'dd MMM yyyy HH:mm Z\'}}</span>\n' +
    '\n' +
    '\n' +
    '\n' +
    '        <md-radio-group ng-model="tabDates.book.dates.reviewPeriod"   ng-disabled="tabDates.disabled" ng-change="tabDates.changeReviewDate(tabDates.book)"  >\n' +
    '            <md-radio-button value="3,months">3 months before {{tabDates.reviewDateDescription}}</md-radio-button>\n' +
    '            <md-radio-button value="2,months" >2 months before {{tabDates.reviewDateDescription}}</md-radio-button>\n' +
    '            <md-radio-button value="1,months" >1 month before {{tabDates.reviewDateDescription}}</md-radio-button>\n' +
    '            <md-radio-button value="2,weeks" >2 weeks before {{tabDates.reviewDateDescription}}</md-radio-button>\n' +
    '        </md-radio-group>\n' +
    '        <p ng-if="tabDates.book.reviewDate  && tabDates.book.reviewDate < tabDates.today" class="error animate-error-if">Review Date in the past</p>\n' +
    '        <p ng-if="tabDates.book.reviewDate && tabDates.book.liveDate  && tabDates.book.reviewDate < tabDates.book.liveDate" class="error animate-error-if">Review Date before Live Date</p>\n' +
    '        <p ng-if="tabDates.book.dates.reviewPeriod != \'3,months\' && tabDates.book.dates.reviewPeriod != \'2,months\' && tabDates.book.dates.reviewPeriod != \'1,months\' && tabDates.book.dates.reviewPeriod != \'2,weeks\'" class="error animate-error-if">Review Period is non-standard</p>\n' +
    '        <!--<p>{{tabDates.book.reviewDate}}</p>-->\n' +
    '    </md-input-container>\n' +
    '    </div>\n' +
    '\n' +
    '    <div flex="50">\n' +
    '    <md-input-container ng-mouseover="tabDates.focus = \'expiry\'" ng-mouseleave="tabDates.focus = \'\'" >\n' +
    '        <h4 wraps-tooltip="docman-dates-input_expiry">\n' +
    '            Expiry Date\n' +
    '            <md-button aria-label="Expiry Date Help" class="md-icon-button" style="position: relative; right:10px;" >\n' +
    '                <ng-md-icon icon="help" style="fill: darkgray;" size="20"></ng-md-icon>\n' +
    '                <md-tooltip md-direction="right">Set the date when the document can no longer be used.<br/>Renewing documents will require a new version to be created and approved.</md-tooltip>\n' +
    '                <!--<md-tooltip md-direction="right">This is the date from which the document can no longer be used.\n' +
    '                    If the document is to be renewed, a new version will need to be created and approved as per the normal process;\n' +
    '                    however, this version of the document will no longer be in use past the Expiry Date.\n' +
    '                    <br><br>\n' +
    '                    <i>Note: If documents are found to still be in use after the recorded expiration date,\n' +
    '                        a risk event may need to be raised and reported. Non-compliant customer-facing\n' +
    '                        documentation can lead to serious consequences for our business and as such can\n' +
    '                        be very high profile. It is imperative that every effort is made to ensure that\n' +
    '                        all available customer-facing documentation is compliant, and if it is expired, that it is no longer in use.</i></md-tooltip>-->\n' +
    '            </md-button></h4>\n' +
    '\n' +
    '        <span class="dateDisplaySmall" >{{tabDates.book.expiryDate | date:\'dd MMM yyyy HH:mm Z\'}}</span>\n' +
    '\n' +
    '        <!--<label class="animate-if" ng-if="tabDates.book.expiryDate">Expiry</label>-->\n' +
    '        <md-radio-group ng-model="tabDates.book.dates.lifecyclePeriod"   ng-disabled="tabDates.disabled" ng-change="tabDates.changeExpiryDate(tabDates.book)" >\n' +
    '            <md-radio-button value="12,months">12 months from live date</md-radio-button>\n' +
    '            <md-radio-button value="9,months">9 months from live date</md-radio-button>\n' +
    '            <md-radio-button value="6,months">6 months from live date</md-radio-button>\n' +
    '            <md-radio-button value="3,months">3 months from live date</md-radio-button>\n' +
    '            <md-radio-button value="0,months">Specific date:\n' +
    '            </md-radio-button>\n' +
    '        </md-radio-group>\n' +
    '        <md-datepicker ng-if="tabDates.book.dates.lifecyclePeriod == \'0,months\'" md-min-date="tabDates.book.liveDate" ng-model="tabDates.expiryDate" md-placeholder="Expiry Date"  ng-disabled="tabDates.disabled" ng-change="tabDates.changeExpiryDate(tabDates.book)" flex></md-datepicker>\n' +
    '        <p ng-if="tabDates.book.expiryDate && tabDates.book.liveDate  && tabDates.book.expiryDate < tabDates.book.liveDate" class="error animate-error-if">Expiry Date  before Live Date</p>\n' +
    '        <p ng-if="tabDates.book.expiryDate  && tabDates.book.expiryDate < tabDates.today" class="error animate-error-if">Expiry Date in the past</p>\n' +
    '        <p ng-if="!tabDates.book.expiryDate  && tabDates.book.dates.lifecyclePeriod == \'0,months\'" class="error animate-error-if">Expiry Date not set</p>\n' +
    '\n' +
    '\n' +
    '\n' +
    '    </md-input-container>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-components/right-menu/input-wf-step/input-wf-step.tpl.html',
    '<div layout="row" style="font-size: 14px; color:#666; " ng-class="{\'fixed\':item.role != 3 && item.role != 9 && item.role != 8 && item.role != 10, \'off\':item.value === false}" ng-repeat="item in inputWfStep.array" class="wf-step">\n' +
    '    <div flex="15" class="statbar-mini">\n' +
    '                        <span class="outer">\n' +
    '                            <span class="inner" ng-class="{grey:!isCurrent, green: isCurrent}"></span>\n' +
    '                            <span class="label ">{{inputWfStep.getRoleById(item.role).name}} {{inputWfStep.enableWorkflowArtwork}}</span>\n' +
    '                        </span>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="inputWfStep.disabled || (item.content == \'final\' && $index == 1 && item.team == 20)"  style="padding: 6px 8px;" flex="50">{{inputWfStep.getTeamById(item.team).teamName}}</div>\n' +
    '    <div ng-if="!inputWfStep.disabled && !(item.content == \'final\' && $index == 1 && item.team == 20)" style="padding: 0px 6px;" flex="50">\n' +
    '\n' +
    '        <md-select ng-model="item.team" placeholder="Select team" style="font-size: 14px; margin: -2px 0 0 0;" ng-disabled="item.value === false">\n' +
    '            <md-option ng-value="team.teamId" ng-repeat="team in inputWfStep.teams  | orderBy: [\'teamName\'] " >{{team.teamName}}  <span ng-if="team.disabled">- (disabled)</span></md-option>\n' +
    '        </md-select>\n' +
    '        <p ng-if="!item.team || item.team == \'\'" class="error animate-error-if">You must select a team</p>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="inputWfStep.disabled"  style="padding: 6px 8px;" flex="50">{{inputWfStep.getUserById(item.user).firstName}} {{inputWfStep.getUserById(item.user).lastName}}</div>\n' +
    '    <div ng-if="!inputWfStep.disabled" style="padding: 0px 6px;" flex="50">\n' +
    '\n' +
    '        <md-select ng-model="item.user" placeholder="All" style="font-size: 14px; margin: -2px 0 0 0;" ng-disabled="item.value === false || item.team === \'\'">\n' +
    '            <md-option ng-value="undefined" ng-if="!item.user">All</md-option>\n' +
    '            <md-option ng-value="\'\'" ng-if="item.user">All</md-option>\n' +
    '            <md-option\n' +
    '                ng-value="user.id"\n' +
    '                ng-repeat="user in inputWfStep.users | filter: { teamId: item.team } : true | orderBy: [\'firstName\', \'lastName\'] "\n' +
    '                ng-disabled="(item.role == 8 || item.role == 3)  && (inputWfStep.ownerid == user.id || inputWfStep.arrayMCOUser == user.id)"\n' +
    '                ng-if="!user.disabled ||  user.id === item.user "\n' +
    '                >{{user.firstName}} {{user.lastName}} <span ng-if="user.disabled">- (disabled)</span></md-option>\n' +
    '        </md-select>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="!inputWfStep.disabled" flex="20" style="padding: 0px 6px;" layout="row">\n' +
    '        <md-switch ng-if="item.role === 1" ng-model="item.value"  aria-label="active" style="margin: -2px 0 0 0;" ng-disabled="(inputWfStep.disabled)" ></md-switch>\n' +
    '\n' +
    '        <div  ng-if="item.role === 3 || item.role === 8 || item.role === 9 || item.role === 10" wraps-tooltip="docman-wf-input_delete">\n' +
    '            <md-button ng-disabled="inputWfStep.disabled" ng-if="!inputWfStep.disabled" class="md-icon-button" ng-click="inputWfStep.removeWorkflow(item.content,$index)">\n' +
    '                <ng-md-icon icon="clear"  aria-label="remove"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '        <div  ng-if="item.role === 3 || item.role === 8 || item.role === 9 || item.role === 10" wraps-tooltip="docman-wf-input_drag">\n' +
    '            <md-button ng-disabled="inputWfStep.disabled" ng-if="!inputWfStep.disabled" class="md-icon-button drag-handle">\n' +
    '                <ng-md-icon class="drag-handle" icon="drag_handle" aria-label="move"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-components/right-menu/tab-content/tab-content.tpl.html',
    '<md-content layout-padding>\n' +
    '    <div layout="row" layout-wrap>\n' +
    '        <div flex="50" >\n' +
    '\n' +
    '            <h4 wraps-tooltip="docman-content-input_doccontenttags">Document Content</h4>\n' +
    '\n' +
    '            <md-switch ng-repeat="item in tabContent.docContents | filter: { docContentStatus: 1, divisionId:tabContent.book.divisionId} | orderBy: \'name\'" name="{{item.name}}"    ng-model="tabContent.book.bookContent[item.id]"    ng-disabled="tabContent.disabled">{{item.name}}</md-switch>\n' +
    '            <!--{{tabContent.selected.book.bookContent}}-->\n' +
    '\n' +
    '\n' +
    '        </div>\n' +
    '        <div flex="50" >\n' +
    '\n' +
    '            <h4 wraps-tooltip="docman-content-input_langversiontags">Language Versions</h4>\n' +
    '            <md-switch ng-repeat="item in tabContent.languages | filter: { languageStatus: 1, divisionId:tabContent.book.divisionId}| orderBy: \'name\'" name="{{item.name}}"    ng-model="tabContent.book.languageVersions[item.id]"    ng-disabled="tabContent.disabled">{{item.name}}</md-switch>\n' +
    '            <!--{{tabContent.selected.book.languageVersions}}-->\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</md-content>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-components/right-menu/input-wf-speedial/input-wf-speedial.tpl.html',
    '<md-fab-speed-dial \n' +
    '        md-direction="right" \n' +
    '        class="md-scale " \n' +
    '        md-open="false" \n' +
    '        style="padding-left: 10px; padding-top:5px" \n' +
    '        >\n' +
    '    <md-fab-trigger>\n' +
    '        <md-button   target="_blank"  class="md-fab md-mini" aria-label="add workflow step">\n' +
    '            <ng-md-icon  icon="add" wraps-tooltip="docman-wf-input_addnew"></ng-md-icon>\n' +
    '        </md-button>\n' +
    '    </md-fab-trigger>\n' +
    '\n' +
    '    <md-fab-actions >\n' +
    '        <md-button ng-click="inputWfSpeedial.addWorkflow(3, inputWfSpeedial.content)" target="_blank" type="button" class="speeddial-button green-button" wraps-tooltip="docman-wf-input_addnew_approver">APPROVER</md-button>\n' +
    '        <md-button ng-click="inputWfSpeedial.addWorkflow(8, inputWfSpeedial.content)" target="_blank" type="button" class="speeddial-button blue-button" wraps-tooltip="docman-wf-input_addnew_proof">PROOFREADER</md-button>\n' +
    '        <md-button ng-click="inputWfSpeedial.addWorkflow(9, inputWfSpeedial.content)" target="_blank" type="button" class="speeddial-button orange-button" wraps-tooltip="docman-wf-input_addnew_partner">PARTNER</md-button>\n' +
    '        <md-button ng-click="inputWfSpeedial.addWorkflow(10, inputWfSpeedial.content)" target="_blank" type="button" class="speeddial-button purple-button" wraps-tooltip="docman-wf-input_addnew_client">CLIENT</md-button>\n' +
    '    </md-fab-actions>\n' +
    '</md-fab-speed-dial>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-components/right-menu/input-switchlist/input-switchlist.tpl.html',
    '<h5 wraps-tooltip="docman-pub-input_insitu">{{inputSwitchlist.label}}</h5> <!--ToDo make tooltip code dynamic-->\n' +
    '<md-switch ng-if="inputSwitchlist.switch"\n' +
    '           name="{{inputSwitchlist.model}}Na"\n' +
    '           ng-model="inputSwitchlist.switch.flag"\n' +
    '           ng-change="inputSwitchlist.toggleSwitchGroup()"\n' +
    '           ng-disabled="inputSwitchlist.disabled"\n' +
    '>N/A</md-switch>\n' +
    '<md-switch ng-change="inputSwitchlist.toggleSwitchNa()" ng-repeat="item in inputSwitchlist.array" name="{{item.name}}" ng-model="inputSwitchlist.book[inputSwitchlist.model][item.id]" ng-disabled="inputSwitchlist.disabled" >{{item.name}}</md-switch>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-components/right-menu/input-level/input-level.tpl.html',
    '<div layout="row"  layout-wrap>\n' +
    '    <div flex="10">\n' +
    '        <md-switch ng-model="inputLevel.book.levels[inputLevel.level].active" aria-label="{{inputLevel.level}} active"   ng-disabled="inputLevel.disabled"></md-switch>\n' +
    '    </div>\n' +
    '    <div flex="40">\n' +
    '        <md-input-container md-no-float>\n' +
    '            <label>{{inputLevel.level|titlecase}} Label</label>\n' +
    '            <input ng-model="inputLevel.book.levels[inputLevel.level].name" placeholder="{{inputLevel.level}} label" aria-label="{{inputLevel.level}} label"  ng-disabled="inputLevel.disabled">\n' +
    '        </md-input-container>\n' +
    '    </div>\n' +
    '</div>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-components/tab-list/tab-list.tpl.html',
    '<md-content>\n' +
    '    <div>\n' +
    '        <md-data-table-container class="table-outer">\n' +
    '            <form name="searchForm">\n' +
    '            <table md-data-table  > <!--md-row-select="selected"-->\n' +
    '                <!--search bar panels-->\n' +
    '                <thead>\n' +
    '                <tr>\n' +
    '                    <td colspan="99" style="padding-left: 20px; font-size: 12px; color:#999;">\n' +
    '                        <div layout="row" layout-wrap>\n' +
    '                            <!--applied filters and clear-filter button-->\n' +
    '                            <div flex="75" ng-if="tabList.stringifySearch($storage.pagination.filterLibrary) !== \'\'">\n' +
    '                                <h4><span wraps-tooltip="library-search-applied">Applied searches: {{tabList.stringifySearch($storage.pagination.filterLibrary)}}</span>&nbsp;\n' +
    '                                    <md-button aria-label="Clear search" class="md-gray" style="margin-left:20px;" ng-click="$storage.pagination.filterLibrary={}; tabList.library.searchBooksText=\'\'" wraps-tooltip="library-search-clear">\n' +
    '                                        <ng-md-icon icon="backspace" style="fill: #999; position: relative; left:4px;" size="18"></ng-md-icon>clear search\n' +
    '                                    </md-button>\n' +
    '                                </h4>\n' +
    '                            </div>\n' +
    '                            <div flex layout="row" layout-align="end center">\n' +
    '                                <!--download filtered library csv button-->\n' +
    '                                <md-button aria-label="Download CSV" class="md-icon-button" ng-csv="tabList.library.downloadCSV()"  lazy-load="true" filename="library-search-report.csv" add-bom="true" charset="utf-8" csv-label="true">\n' +
    '                                    <ng-md-icon icon="file_download" style="fill: grey; " size="24" wraps-tooltip="library-search-download"></ng-md-icon>\n' +
    '                                </md-button>\n' +
    '                                <!--save search button-->\n' +
    '                                <md-button aria-label="Save search" class="md-icon-button" ng-click="tabList.library.openLeftMenu(1);$mdTabsCtrl.select(1);$event.stopPropagation()" wraps-tooltip="library-search-save">\n' +
    '                                    <ng-md-icon icon="save" style="fill: grey; " size="24"></ng-md-icon>\n' +
    '                                </md-button>\n' +
    '\n' +
    '                                <!--reload library button-->\n' +
    '                                <md-button aria-label="Refresh books" class="md-icon-button" ng-click="tabList.library.updateBooks()" wraps-tooltip="library-refresh-icon">\n' +
    '                                    <ng-md-icon icon="refresh" style="fill: grey;" size="24"></ng-md-icon>\n' +
    '                                </md-button>\n' +
    '                                <!--open library settings button-->\n' +
    '                                <md-button aria-label="Library settings" class="md-icon-button"  ng-click="tabList.library.openLeftMenu()"  wraps-tooltip="library-settings-icon">\n' +
    '                                    <ng-md-icon icon="settings" style="fill: grey;" size="24"></ng-md-icon>\n' +
    '                                </md-button>\n' +
    '                                <!--open searchbar button--><!--\n' +
    '                                    <md-button aria-label="Open search" class="md-icon-button" style="position: relative; top: -15px; right:-5px;"  ng-click="tabList.library.searchLibrary=true"  wraps-tooltip="library-search-icon">\n' +
    '                                        <ng-md-icon icon="search" style="fill: grey;" size="24"></ng-md-icon>\n' +
    '                                    </md-button>\n' +
    '\n' +
    '\n' +
    '									<!--close searchbar button--><!--\n' +
    '                                    <md-button aria-label="Close search" class="md-icon-button" style="position: relative; right:0px;" ng-click="tabList.library.searchLibrary=false; tabList.pagination.filterLibrary=\'\'; tabList.library.searchBooksText=\'\'" wraps-tooltip="library-search-close">\n' +
    '                                        <ng-md-icon icon="close" style="fill: grey; " size="24"></ng-md-icon>\n' +
    '                                    </md-button>-->\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '                    </td>\n' +
    '                </tr>\n' +
    '                <!--for users on team 10 only - can filter to a random XX% of the total library-->\n' +
    '                <!--<tr ng-if="$storage.user.teamId === 10">\n' +
    '                    <td colspan="99" >\n' +
    '                        <md-input-container style="width: 200px;margin: 18px 0 20px 20px;" >\n' +
    '                            <label>Search percentage %</label>\n' +
    '                            <input type="number" ng-model="tabList.library.searchPercentage" style="width: 80%;" aria-label="Search percentage %"  md-placeholder="Search percentage %">\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                        <md-button  type="button" class="md-grey" style="background: #9c9392; position: relative; left:-20px;"  ng-click="tabList.library.showRandom(tabList.library.searchPercentage)">Run search<ng-md-icon icon="star" style="fill: white; position: relative; top:4px; padding-left: 4px; " size="20"  aria-label="Run search"></ng-md-icon></md-button>\n' +
    '\n' +
    '                    </td>\n' +
    '                </tr>\n' +
    '                <!-- full text search-->\n' +
    '                <tr>\n' +
    '                    <td colspan="99">\n' +
    '                        <div layout="row" layout-align="space-between center">\n' +
    '                            <md-input-container style="min-width:50%;width:480px;max-width:90%;margin: 18px 0 0px 18px;" wraps-tooltip="library-search-full-text" >\n' +
    '                                <label>Full text search</label>\n' +
    '                                <input ng-model="tabList.temp.filterLibrary.textSearch" minlength="4" name="textSearch" id="textSearch" aria-label="Search book content" md-placeholder="Full text search" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                                <div ng-messages="searchForm.textSearch.$error">\n' +
    '                                    <div ng-message="minlength">Keyword(s) must be 4 characters or longer</div>\n' +
    '                                </div>\n' +
    '                            </md-input-container>\n' +
    '                            <md-button aria-label="search" class="md-fab md-mini md-accent" ng-animate ng-disabled="searchForm.$pristine || searchForm.$invalid" ng-click="tabList.updateFilter();"  wraps-tooltip="library-search-icon">\n' +
    '                                <ng-md-icon ng-animate icon="search" size="24"></ng-md-icon>\n' +
    '                            </md-button>\n' +
    '                        </div>\n' +
    '\n' +
    '                        <!--<md-input-container style="width: 280px;"><input ng-model="tabList.pagination.filterLibrary.searchBook" style="width: 80%;" aria-label="Filter book name"></md-input-container>-->\n' +
    '                    </td>\n' +
    '                </tr>\n' +
    '\n' +
    '                <tr>\n' +
    '\n' +
    '\n' +
    '                    <!--all possible column-specific search inputs--->\n' +
    '                    <td wraps-tooltip="library-search-filters-id">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.bookSearchId" name="bookSearchId" id="bookSearchId"\n' +
    '                                                   aria-label="Filter book id" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.code" wraps-tooltip="library-search-filters-code">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.bookCode" name="bookCode"\n' +
    '                                                   aria-label="Filter book code" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.psiCode" wraps-tooltip="library-search-filters-psi-code">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.psiCode" name="psiCode"\n' +
    '                                                   aria-label="Filter psi code" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.name" wraps-tooltip="library-search-filters-name">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.bookName" name="bookName"\n' +
    '                                                    aria-label="Filter book name" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.insurer" wraps-tooltip="library-search-filters-insurer">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.insurerName" name="insurerName"\n' +
    '                                                   aria-label="Filter insurer type" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.insurerDetails" wraps-tooltip="library-search-filters-insurer">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.insurerDetails" name="insurerDetails"\n' +
    '                                                   aria-label="Filter insurer type" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.product" wraps-tooltip="library-search-filters-product">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.productName" name="productName"\n' +
    '                                                   aria-label="Filter product name" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '\n' +
    '                    <td ng-if="$storage.showFilters.audience" wraps-tooltip="library-search-filters-audience">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.audienceName" name="audienceName"\n' +
    '                                                   aria-label="Filter audience name" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.region" wraps-tooltip="library-search-filters-region">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.regionName" name="regionName"\n' +
    '                                                   aria-label="Filter region name" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.productType" wraps-tooltip="library-search-filters-product-type">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.productTypeName" name="productTypeName"\n' +
    '                                                   aria-label="Filter Customer types" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '\n' +
    '                    <td ng-if="$storage.showFilters.docTypeCatName" wraps-tooltip="library-search-filters-doc-type-cat">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.DocTypes.docTypeCatName" name="docTypeCatName"\n' +
    '                                                    aria-label="Master Doc Type" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.doc" wraps-tooltip="library-search-filters-doc">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.docTypeName" name="docTypeName"\n' +
    '                                                    aria-label="Filter doc type" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.finProm" wraps-tooltip="library-search-filters-fin-prom">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.finProm" name="finProm"\n' +
    '                                                    aria-label="Filter fin prom" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.clients" wraps-tooltip="library-search-filters-clients">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.clientName" name="clientName"\n' +
    '                                                    aria-label="Filter clients" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.Owner.fullName" wraps-tooltip="library-search-filters-full-name">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.ownerFullName" name="ownerFullName"\n' +
    '                                                    aria-label="Filter owner" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.Owner.teamName" wraps-tooltip="library-search-filters-team-name">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.ownerTeamName" name="ownerTeamName"\n' +
    '                                                    aria-label="Filter owner team" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.division" wraps-tooltip="library-search-filters-division">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.division" name="division"\n' +
    '                                                   aria-label="Filter division" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.workflowStatus"\n' +
    '                        wraps-tooltip="library-search-filters-workflow-status">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.workflowStatus" name="workflowStatus"\n' +
    '                                                   aria-label="Filter workflow status" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.documentStatus"\n' +
    '                        wraps-tooltip="library-search-filters-document-status">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.documentStatus" name="documentStatus"\n' +
    '                                                   aria-label="Filter document status" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.projectName" wraps-tooltip="library-search-filters-document-status">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.projectName" name="projectName"\n' +
    '                                                    aria-label="Project Name" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '\n' +
    '                    <td ng-if="$storage.showFilters.date" wraps-tooltip="library-search-filters-date">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.createdDisplayDate" name="createdDisplayDate"\n' +
    '                                                    aria-label="Filter date" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.lastUpdated" wraps-tooltip="library-search-filters-last-updated">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.lastUpdatedDisplayDate" name="lastUpdatedDisplayDate"\n' +
    '                                                    aria-label="Filter date" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.activeDate" wraps-tooltip="library-search-filters-active-date">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.activeDateDisplayDate" name="activeDateDisplayDate"\n' +
    '                                                    aria-label="Filter date" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.liveDate" wraps-tooltip="library-search-filters-live-date">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.liveDateDisplayDate" name="liveDateDisplayDate"\n' +
    '                                                    aria-label="Filter date" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.reviewDate" wraps-tooltip="library-search-filters-review-date">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.reviewDateDisplayDate" name="reviewDateDisplayDate"\n' +
    '                                                    aria-label="Filter date" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.expiryDate" wraps-tooltip="library-search-filters-expiry-date">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.expiryDateDisplayDate" name="expiryDateDisplayDate"\n' +
    '                                                    aria-label="Filter date" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td ng-if="$storage.showFilters.inWorkflow" wraps-tooltip="library-search-filters-in-workflow">\n' +
    '                        <md-input-container><input ng-model="tabList.temp.filterLibrary.inWorkflow" name="inWorkflow"\n' +
    '                                                   aria-label="In Workflow" ng-keyup="$event.keyCode == 13 ? tabList.updateFilter() : \'\'">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                        <md-input-container>\n' +
    '                            <label>Action</label>\n' +
    '                            <md-select multiple ng-model="tabList.temp.filterLibrary.actionStatus" name="actionStatus" aria-label="Action Status" md-selected-html="tabList.getActionText()" class="action-filter-select">\n' +
    '                                <md-option ng-value="1">Black Asset <ng-md-icon icon="{{tabList.library.icons[0]}}" md-colors="{fill:tabList.library.colors[0]}" size="20"  class="animate-if" wraps-tooltip="action-icon"></ng-md-icon>\n' +
    '                                </md-option>\n' +
    '                                <md-option ng-value="2">Personal Action <ng-md-icon icon="{{tabList.library.icons[1]}}" md-colors="{fill:tabList.library.colors[1]}" size="20"  class="animate-if" wraps-tooltip="action-icon"></ng-md-icon></md-option>\n' +
    '                                <md-option ng-value="3">Team Action <ng-md-icon icon="{{tabList.library.icons[2]}}" md-colors="{fill:tabList.library.colors[2]}" size="20"  class="animate-if" wraps-tooltip="action-icon"></ng-md-icon></md-option>\n' +
    '                            </md-select>\n' +
    '                            <div class="md-errors-spacer"></div>\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '\n' +
    '                </tr>\n' +
    '                </thead>\n' +
    '                <thead>\n' +
    '                <tr>\n' +
    '                    <!-- all possible table headings-->\n' +
    '                    <th ng-click="tabList.sortBy(\'bookId\')">\n' +
    '                        <span wraps-tooltip="library-heading-id">ID</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'bookId\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.code" ng-click="tabList.sortBy(\'bookCode\')">\n' +
    '                        <span wraps-tooltip="library-heading-code">Code</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'bookCode\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.psiCode" ng-click="tabList.sortBy(\'psiCode\')">\n' +
    '                        <span wraps-tooltip="library-heading-psi-code">PSI Code</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                                    ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'psiCode\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.name" ng-click="tabList.sortBy(\'bookName\')">\n' +
    '                        <span wraps-tooltip="library-heading-name">Name</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'bookName\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.insurer" ng-click="tabList.sortBy(\'insurerName\')">\n' +
    '                        <span wraps-tooltip="library-heading-insurer">Insured By</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'insurerName\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.insurerDetails" ng-click="tabList.sortBy(\'insurerDetails\')">\n' +
    '                        <span wraps-tooltip="library-heading-insurer">Insurer Details</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                                    ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'insurerDetails\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.product" ng-click="tabList.sortBy(\'productName\')">\n' +
    '                        <span wraps-tooltip="library-heading-product">Product</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                          ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'productName\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '\n' +
    '                    <th ng-if="$storage.showFilters.audience" ng-click="tabList.sortBy(\'audienceName\')">\n' +
    '                        <span wraps-tooltip="library-heading-audience">Audiences</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'audienceName\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.region" ng-click="tabList.sortBy(\'regionName\')">\n' +
    '                        <span wraps-tooltip="library-heading-region">Regions</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'regionName\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.productType" ng-click="tabList.sortBy(\'productTypeName\')">\n' +
    '                        <span wraps-tooltip="library-heading-product-type">Customer Types</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'productTypeName\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '\n' +
    '                    <th ng-if="$storage.showFilters.docTypeCatName" ng-click="tabList.sortBy(\'docTypeCatName\')">\n' +
    '                        <span wraps-tooltip="library-heading-doc-type-cat">Master Doc Type</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'docTypeCatName\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.doc" ng-click="tabList.sortBy(\'docTypeName\')">\n' +
    '                        <span wraps-tooltip="library-heading-doc">Doc Type</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'docTypeName\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.finProm" ng-click="tabList.sortBy(\'finProm\')">\n' +
    '                        <span wraps-tooltip="library-heading-fin-prom">Fin Prom</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'finProm\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.clients" ng-click="tabList.sortBy(\'clients\')">\n' +
    '                        <span wraps-tooltip="library-heading-clients">Client</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'clients\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.Owner.fullName" ng-click="tabList.sortBy(\'ownerFullName\')">\n' +
    '                        <span wraps-tooltip="library-heading-full-name">Owner</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'ownerFullName\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.Owner.teamName" ng-click="tabList.sortBy(\'ownerTeamName\')">\n' +
    '                        <span wraps-tooltip="library-heading-team-name">Owner Team</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                          ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'ownerTeamName\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.division" ng-click="tabList.sortBy(\'division\')">\n' +
    '                        <span wraps-tooltip="library-heading-workflow-status">Division</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                                    ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'division\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.workflowStatus" ng-click="tabList.sortBy(\'workflowStatus\')">\n' +
    '                        <span wraps-tooltip="library-heading-workflow-status">Workflow Status</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'workflowStatus\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.documentStatus" ng-click="tabList.sortBy(\'documentStatus\')">\n' +
    '                        <span wraps-tooltip="library-heading-document-status">Document Status</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'documentStatus\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.projectName" ng-click="tabList.sortBy(\'projectName\')">\n' +
    '                        <span wraps-tooltip="library-heading-document-status">Project Name</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'projectName\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '\n' +
    '                    <th ng-if="$storage.showFilters.date" ng-click="tabList.sortBy(\'created\')">\n' +
    '                        <span wraps-tooltip="library-heading-date">Created</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'created\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.lastUpdated" ng-click="tabList.sortBy(\'lastUpdated\')">\n' +
    '                        <span wraps-tooltip="library-heading-last-updated">Last Updated</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'lastUpdated\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.activeDate" ng-click="tabList.sortBy(\'activeDate\')">\n' +
    '                        <span wraps-tooltip="library-heading-active-date">Active Date</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'activeDate\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.liveDate" ng-click="tabList.sortBy(\'liveDate\')">\n' +
    '                        <span wraps-tooltip="library-heading-live-date">Live Date</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'liveDate\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.reviewDate" ng-click="tabList.sortBy(\'reviewDate\')">\n' +
    '                        <span wraps-tooltip="library-heading-review-date">Review Date</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'reviewDate\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.expiryDate" ng-click="tabList.sortBy(\'expiryDate\')">\n' +
    '                        <span wraps-tooltip="library-heading-expiry-date">Expiry Date</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'expiryDate\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-if="$storage.showFilters.inWorkflow" ng-click="tabList.sortBy(\'inWorkflow\')">\n' +
    '                        <span wraps-tooltip="library-heading-in-workflow">In Workflow</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'inWorkflow\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '                    <th ng-click="tabList.sortBy(\'actionStatus\')">\n' +
    '                        <span wraps-tooltip="library-heading-action">Action</span>\n' +
    '                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                         ng-class="{active: tabList.pagination.filterLibrary.sortOrder === \'actionStatus\', reverse: tabList.pagination.filterLibrary.sortDirection == \'desc\'}"></ng-md-icon>\n' +
    '                    </th>\n' +
    '\n' +
    '                </tr>\n' +
    '                </thead>\n' +
    '                <tbody>\n' +
    '                <!-- book listing data--->\n' +
    '                <tr ng-repeat="book in tabList.library.books"\n' +
    '                    ng-click="tabList.library.addBookTab(book.bookId)"\n' +
    '                    class="data-rows"\n' +
    '                    ng-class="{ \'complianceSelect\': book.complianceSelect}"\n' +
    '                >\n' +
    '                    <td style="text-indent: 2px;">{{ book.bookIdLong }}</td>\n' +
    '\n' +
    '                    <td ng-if="$storage.showFilters.code">{{ book.bookCode }}</td>\n' +
    '                    <td ng-if="$storage.showFilters.psiCode">{{ book.psiCode }}</td>\n' +
    '                    <td ng-if="$storage.showFilters.name">{{ book.bookName }}</td>\n' +
    '                    <td ng-if="$storage.showFilters.insurer">{{ book.Insurers.insurerName }}</td>\n' +
    '                    <td ng-if="$storage.showFilters.insurerDetails">{{ book.InsurerDetailsText }}</td>\n' +
    '                    <td ng-if="$storage.showFilters.product">{{ book.Products.productName }}</td>\n' +
    '\n' +
    '                    <td ng-if="$storage.showFilters.audience">{{ book.Audiences.audienceName }}</td>\n' +
    '                    <td ng-if="$storage.showFilters.region">{{ book.Regions.regionName }}</td>\n' +
    '                    <td ng-if="$storage.showFilters.productType">{{ book.ProductTypes.productTypeName }}</td>\n' +
    '\n' +
    '                    <td ng-if="$storage.showFilters.docTypeCatName">{{ book.DocTypes.DocTypeCats.docTypeCatName }}</td>\n' +
    '                    <td ng-if="$storage.showFilters.doc">{{ book.DocTypes.docTypeName }}</td>\n' +
    '                    <td ng-if="$storage.showFilters.finProm">{{ book.finProm }}</td>\n' +
    '                    <td ng-if="$storage.showFilters.clients">{{ book.Clients.clientName }}</td>\n' +
    '                    <td ng-if="$storage.showFilters.Owner.fullName">{{ book.Owner.fullName }}</td>\n' +
    '                    <td ng-if="$storage.showFilters.Owner.teamName">{{ book.Owner.teamName }}</td>\n' +
    '\n' +
    '                    <td ng-if="$storage.showFilters.division" ng-bind="book.Divisions.division"></td>\n' +
    '                    <td ng-if="$storage.showFilters.workflowStatus" ng-bind="tabList.library.replaceSpaceUni(book.workflowStatus)"></td>\n' +
    '                    <td ng-if="$storage.showFilters.documentStatus" ng-bind="tabList.library.replaceSpaceUni(book.documentStatus)"></td>\n' +
    '                    <td ng-if="$storage.showFilters.projectName" ng-bind="book.Projects.projectName"></td>\n' +
    '\n' +
    '                    <td ng-if="$storage.showFilters.date">{{ book.createdDisplayDate}}</td>\n' +
    '                    <td ng-if="$storage.showFilters.lastUpdated">{{ book.lastUpdatedDisplayDate}}</td>\n' +
    '                    <td ng-if="$storage.showFilters.activeDate">{{ book.activeDateDisplayDate}}</td>\n' +
    '                    <td ng-if="$storage.showFilters.liveDate">{{ book.liveDateDisplayDate}}</td>\n' +
    '                    <td ng-if="$storage.showFilters.reviewDate">{{ book.reviewDateDisplayDate}}</td>\n' +
    '                    <td ng-if="$storage.showFilters.expiryDate">{{ book.expiryDateDisplayDate}}</td>\n' +
    '                    <td ng-if="$storage.showFilters.inWorkflow">{{ book.inWorkflow}}</td>\n' +
    '\n' +
    '                    <td>\n' +
    '                        <ng-md-icon ng-if="book.actionStatus" ng-class="" icon="{{tabList.library.icons[book.actionStatus -1]}}" md-colors="{fill:tabList.library.colors[book.actionStatus -1]}" style="vertical-align:middle;" size="30"  class="animate-if" wraps-tooltip="action-icon"></ng-md-icon>\n' +
    '                        <span  ng-if="book.searchcount>0" class="searchCount">{{book.searchcount}}</span>\n' +
    '                    </td>\n' +
    '                </tr>\n' +
    '                </tbody>\n' +
    '            </table>\n' +
    '            </form>\n' +
    '        </md-data-table-container>\n' +
    '        <div class="md-data-table-pagination">\n' +
    '            <!-- md-limit="tabList.library.queryLibrary.limit" md-page="tabList.library.queryLibrary.page" md-total="{{tabList.library.books.total[0].totalRows}}" -->\n' +
    '            <div><span class="label ng-binding">Rows per page:</span>\n' +
    '                <md-select ng-model="tabList.pagination.noResults" ng-change="tabList.updateBooks()" aria-label="Row Count: 10" placeholder="25"\n' +
    '                           class="ng-pristine ng-untouched ng-valid ng-empty">\n' +
    '                    <md-content>\n' +
    '                        <md-option ng-repeat="row in [10, 25, 50, 100]" ng-value="row">{{row}}</md-option>\n' +
    '                    </md-content>\n' +
    '                </md-select>\n' +
    '                <span class="ng-binding">{{tabList.pagination.startRow + 1}} - {{tabList.lastOnPage()}} of {{tabList.pagination.totalRows}}</span>\n' +
    '            </div>\n' +
    '            <div>\n' +
    '                <button class="md-button md-ink-ripple" type="button" ng-click="tabList.first()" ng-disabled="!tabList.hasPrevious()"\n' +
    '                        aria-label="First">\n' +
    '                    <md-icon role="img" aria-hidden="true">\n' +
    '                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fit=""\n' +
    '                             preserveAspectRatio="xMidYMid meet" focusable="false">\n' +
    '                            <path d="M7 6 v12 h2 v-12 h-2z M17.41 7.41L16 6l-6 6 6 6 1.41-1.41L12.83 12z"></path>\n' +
    '                        </svg>\n' +
    '                    </md-icon>\n' +
    '                </button>\n' +
    '                <button class="md-button md-ink-ripple" type="button" ng-click="tabList.previous()" ng-disabled="!tabList.hasPrevious()"\n' +
    '                        aria-label="Previous">\n' +
    '                    <md-icon role="img" aria-hidden="true">\n' +
    '                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fit=""\n' +
    '                             preserveAspectRatio="xMidYMid meet" focusable="false">\n' +
    '                            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>\n' +
    '                        </svg>\n' +
    '                    </md-icon>\n' +
    '                </button>\n' +
    '                <button class="md-button md-ink-ripple" type="button" ng-click="tabList.next()" ng-disabled="!tabList.hasNext()"\n' +
    '                        aria-label="Next">\n' +
    '                    <md-icon role="img" aria-hidden="true">\n' +
    '                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fit=""\n' +
    '                             preserveAspectRatio="xMidYMid meet" focusable="false">\n' +
    '                            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>\n' +
    '                        </svg>\n' +
    '                    </md-icon>\n' +
    '                </button>\n' +
    '                <button class="md-button md-ink-ripple" type="button" ng-click="tabList.last()" ng-disabled="!tabList.hasNext()"\n' +
    '                        aria-label="Last">\n' +
    '                    <md-icon class="ng-scope" role="img" aria-hidden="true">\n' +
    '                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24" fit=""\n' +
    '                             preserveAspectRatio="xMidYMid meet" focusable="false">\n' +
    '                            <path d="M15 6 v12 h2 v-12 h-2z M8 6L6.59 7.41 11.17 12l-4.58 4.59L8 18l6-6z"></path>\n' +
    '                        </svg>\n' +
    '                    </md-icon>\n' +
    '                </button>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</md-content>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-components/tab-book/tab-book.tpl.html',
    '<md-progress-linear ng-if="tabBook.isloading" md-mode="indeterminate"></md-progress-linear>\n' +
    '<md-content ng-if="!tabBook.isloading && !tabBook.book.docTypeId" class="details-row">\n' +
    '    <div class="greyBarWrapper" layout="row" >\n' +
    '        <h3 flex>Unable to display book</h3>\n' +
    '        <md-button aria-label="Close" ng-click="tabBook.library.closeTab(tabBook.opentab.bookId)" class="md-icon-button" wraps-tooltip="jobbox-close-icon">\n' +
    '            <ng-md-icon icon="close" style="fill:grey"></ng-md-icon>\n' +
    '        </md-button>\n' +
    '    </div>\n' +
    '    <div style="padding:10px 20px 40px; text-align:left;" class="library-data">\n' +
    '        <h3 ng-if="!tabBook.book.permissionDenied" >No job with that Id could be found</h3>\n' +
    '        <h3 ng-if="tabBook.book.permissionDenied" >Sorry, either you do not have permission to view this job or it does not exist</h3>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '<md-content id="anchorBook{{tabBook.book.bookId}}" class="details-row" ng-if="tabBook.book.docTypeId">\n' +
    '    <div layout="column">\n' +
    '                    <!--inline style to be replaced with css class-->\n' +
    '                    <div class="greyBarWrapper" layout="row" layout-align="start center" ng-class="{ \'complianceSelect\': tabBook.book.complianceSelect}" >\n' +
    '                        <h3 flex md-truncate>{{ tabBook.book.bookCode }}</h3>\n' +
    '                        <!--refresh book button-->\n' +
    '                        <md-button style="margin:4px 8px;" ng-if="tabBook.library.hasPermissions(\'refreshDocumentTemp\')" ng-click="tabBook.refresh()" class="margin:4px 8px;" ng-class="{\'md-icon-button\':!tabBook.opentab.updateRequired, \'md-fab\':tabBook.opentab.updateRequired, \'md-mini\':tabBook.opentab.updateRequired, \'md-accent\':tabBook.opentab.updateRequired}" aria-label="Refresh book" wraps-tooltip="jobbox-refresh-icon">\n' +
    '                            <ng-md-icon  icon="refresh" size="24" md-colors="{fill:tabBook.opentab.updateRequired?\'grey-50\':\'grey-600\'}"></ng-md-icon>\n' +
    '                        </md-button>\n' +
    '                        <!--toggle search button-->\n' +
    '                        <md-button  aria-label="toggle search" ng-if="tabBook.book.DocTypes.docTypeTemplate === 1" ng-click="tabBook.openSearch = !tabBook.openSearch"  class="md-icon-button" ng-class="{\'md-focused\':tabBook.openSearch, \'highlight\':!(tabBook.openSearch) && tabBook.hasLibraryTextSearch()}" wraps-tooltip="jobbox-icon-search">\n' +
    '                            <ng-md-icon  icon="search" size="24" md-colors="{fill:!tabBook.openSearch && tabBook.hasLibraryTextSearch()?\'grey-50\':\'grey-600\'}"></ng-md-icon>\n' +
    '                        </md-button>\n' +
    '                        <!--view comments button-->\n' +
    '                        <md-button  aria-label="All Comments" ng-click="tabBook.library.openLeftComments(tabBook.book); $event.stopPropagation()"  class="md-icon-button " wraps-tooltip="jobbox-comments-icon">\n' +
    '                            <ng-md-icon  icon="comment" size="24" md-colors="{fill:\'grey-600\'}"></ng-md-icon>\n' +
    '                        </md-button>\n' +
    '                        <!--open book meta-data panel-->\n' +
    '                        <md-button ng-disabled="tabBook.library.isLoading || tabBook.book.isLoading" aria-label="Book info" ng-click="tabBook.library.openRightMenu(tabBook.book)" class="md-icon-button" wraps-tooltip="jobbox-menu_docman" style="opacity: 0.6;">\n' +
    '                            <ng-md-icon icon="info" size="24"></ng-md-icon>\n' +
    '                        </md-button>\n' +
    '                        <!--book menu-->\n' +
    '                        <div ng-if="tabBook.library.hasPermissions(\'duplicateDocuments\')||\n' +
    '                        (tabBook.library.insertAccessChildren(tabBook.book) && tabBook.book.DocTypes.docTypeTemplate === 1) ||\n' +
    '                        (tabBook.library.isDLO(tabBook.book) && tabBook.book.approvalList[tabBook.book.approvalStatus].role === 1 && tabBook.book.approvalStatus < 2) ||\n' +
    '                        tabBook.library.submitForApprovalVisibility(tabBook.book) ||\n' +
    '                        tabBook.library.viewResetToBrief(tabBook.book) ||\n' +
    '                        tabBook.library.viewResetToMCO(tabBook.book) ||\n' +
    '                        (tabBook.library.showApproveAll(tabBook.book) && !tabBook.library.viewMakeBookLive(tabBook.book)) ||\n' +
    '                        tabBook.library.showWithdraw(tabBook.book) ||\n' +
    '                        tabBook.library.viewEarlyExpire(tabBook.book) ||\n' +
    '                        tabBook.library.isMemUploadable(tabBook.book) ||\n' +
    '                        tabBook.library.isSwapTeamUser(tabBook.book)\n' +
    '">\n' +
    '                            <div class="menu-container" layout-align="center right" layout="column">\n' +
    '\n' +
    '                                <md-menu md-position-mode="target-right target"  md-offset="-10 10">\n' +
    '                                    <!-- book menu trigger-->\n' +
    '                                    <md-button aria-label="Open tools menu" class="md-icon-button " ng-click="$mdMenu.open();$event.stopPropagation()" wraps-tooltip="jobbox-menu-icon">\n' +
    '                                        <ng-md-icon icon="more_vert" size="24" md-colors="{fill:\'grey-600\'}"></ng-md-icon>\n' +
    '                                    </md-button>\n' +
    '\n' +
    '                                    <!--book menu content-->\n' +
    '                                    <md-menu-content>\n' +
    '                                        <md-menu-item >\n' +
    '                                            <!-- duplicate book-->\n' +
    '                                            <md-button aria-label="Duplicate book" ng-click="tabBook.library.duplicateBookDialog(tabBook.book)" ng-if="tabBook.library.hasPermissions(\'duplicateDocuments\')" wraps-tooltip="jobbox-menu_duplicate-record">\n' +
    '                                                <div layout="row">\n' +
    '                                                    <p flex>Duplicate Document</p>\n' +
    '                                                    <ng-md-icon icon="call_split" style="fill: grey; padding-left:8px; position:relative; top:0px;" size="20"></ng-md-icon>\n' +
    '                                                </div>\n' +
    '                                            </md-button>\n' +
    '                                        </md-menu-item>\n' +
    '                                        <md-menu-item ng-if="tabBook.library.insertAccessChildren(tabBook.book) && tabBook.book.DocTypes.docTypeTemplate === 1" wraps-tooltip="jobbox-menu_addchapter">\n' +
    '                                            <!--add chapter-->\n' +
    '                                            <md-button  ng-click="tabBook.library.openChapterDialog(tabBook.book)" aria-label="Add Chapter">\n' +
    '                                                <div layout="row">\n' +
    '                                                    <p flex>Add Chapter</p>\n' +
    '                                                    <ng-md-icon icon="playlist_add" class="threeDotBookMenuPositioning" size="20"></ng-md-icon>\n' +
    '                                                </div>\n' +
    '                                            </md-button>\n' +
    '                                        </md-menu-item>\n' +
    '                                        <md-menu-item ng-if="(tabBook.book.documentStatus.substring(0, 4) != \'EXPD\' && tabBook.library.isDLO(tabBook.book) && tabBook.book.approvalList[tabBook.book.approvalStatus].role === 1 && tabBook.book.approvalStatus < 2)" >\n' +
    '                                            <!--submit to content owner-->\n' +
    '                                            <md-button  ng-click="tabBook.library.changeStatusNextDialog(tabBook.book)" aria-label="Edit Status" wraps-tooltip="jobbox-menu_submitmco">\n' +
    '                                                <div layout="row">\n' +
    '                                                    <p flex>Submit to MCO </p>\n' +
    '\n' +
    '                                                    <ng-md-icon icon="forward" class="threeDotBookMenuPositioning" size="20"></ng-md-icon>\n' +
    '                                                </div>\n' +
    '                                            </md-button>\n' +
    '                                        </md-menu-item>\n' +
    '                                        <md-menu-item ng-if="tabBook.library.submitForApprovalVisibility(tabBook.book);">\n' +
    '                                            <!--submit for approval-->\n' +
    '                                            <md-button  ng-click="tabBook.library.changeStatusNextDialog(tabBook.book)" aria-label="Edit Status" wraps-tooltip="jobbox-menu_submitapp">\n' +
    '                                                <div layout="row">\n' +
    '                                                    <p flex>Submit for Approval</p>\n' +
    '\n' +
    '                                                    <ng-md-icon icon="forward"class="threeDotBookMenuPositioning" size="20"></ng-md-icon>\n' +
    '                                                </div>\n' +
    '                                            </md-button>\n' +
    '                                        </md-menu-item>\n' +
    '\n' +
    '                                        <md-menu-item  ng-if="tabBook.library.viewResetToBrief(tabBook.book)">\n' +
    '                                            <!--reset to brief-->\n' +
    '                                            <md-button  ng-click="tabBook.library.resetToBriefDialog(tabBook.book)" aria-label="Reset to Brief" wraps-tooltip="jobbox-menu_resettobrief">\n' +
    '                                                <div layout="row">\n' +
    '                                                    <p flex>Reset to Brief</p>\n' +
    '\n' +
    '                                                    <ng-md-icon icon="skip_previous" class="threeDotBookMenuPositioning" size="20"></ng-md-icon>\n' +
    '                                                </div>\n' +
    '                                            </md-button>\n' +
    '                                        </md-menu-item>\n' +
    '\n' +
    '                                        <md-menu-item  ng-if="tabBook.library.viewResetToMCO(tabBook.book)">\n' +
    '                                            <!--reset to content owner-->\n' +
    '                                            <md-button  ng-click="tabBook.library.resetToMCODialog(tabBook.book)" aria-label="Reset to MCO" wraps-tooltip="jobbox-menu_resettomco">\n' +
    '                                                <div layout="row">\n' +
    '                                                    <p flex>Reset to MCO</p>\n' +
    '\n' +
    '                                                    <ng-md-icon icon="skip_previous" class="threeDotBookMenuPositioning" size="20"></ng-md-icon>\n' +
    '                                                </div>\n' +
    '                                            </md-button>\n' +
    '                                        </md-menu-item>\n' +
    '\n' +
    '                                        <!--approve all content-->\n' +
    '                                        <md-menu-item  ng-if="tabBook.library.showApproveAll(tabBook.book) && !tabBook.library.viewMakeBookLive(tabBook.book)">\n' +
    '                                            <md-button  ng-click="tabBook.library.approveContentDialog(\'all\',tabBook.book);" aria-label="Make Book Live" wraps-tooltip="jobbox-menu_approveall">\n' +
    '                                                <div layout="row">\n' +
    '                                                    <p flex>Approve All Content</p>\n' +
    '\n' +
    '                                                    <ng-md-icon icon="check_circle" class="threeDotBookMenuPositioning" size="20"></ng-md-icon>\n' +
    '                                                </div>\n' +
    '                                            </md-button>\n' +
    '                                        </md-menu-item>\n' +
    '                                        <!--withdraw book-->\n' +
    '                                        <md-menu-item  ng-if="tabBook.library.showWithdraw(tabBook.book)">\n' +
    '                                            <md-button  ng-click="tabBook.library.withdraw(tabBook.book);" aria-label="Widthdraw" wraps-tooltip="jobbox-menu_withdraw">\n' +
    '                                                <div layout="row">\n' +
    '                                                    <p flex>Withdraw</p>\n' +
    '\n' +
    '                                                    <ng-md-icon icon="archive" class="threeDotBookMenuPositioning" size="20"></ng-md-icon>\n' +
    '                                                </div>\n' +
    '                                            </md-button>\n' +
    '                                        </md-menu-item>\n' +
    '\n' +
    '\n' +
    '                                        <!--Request deletion of this book - MCO only - request sends to Document lifecycle owner-->\n' +
    '                                        <!--hidden until 4.0 or removal-->\n' +
    '                                        <!--<md-menu-item ng-if="tabBook.library.showDLORequestDelete(tabBook.book)">-->\n' +
    '                                        <!--<md-button  ng-click="tabBook.library.requestDelete(\'book\', tabBook.book.bookId, \'\', \'\');" aria-label="Request Delete">-->\n' +
    '                                        <!--<div layout="row">-->\n' +
    '                                        <!--<p flex>Request Delete</p>-->\n' +
    '                                        <!--<ng-md-icon icon="delete_forever" class="threeDotBookMenuPositioning" size="20"></ng-md-icon>-->\n' +
    '                                        <!--</div>-->\n' +
    '                                        <!--</md-button>-->\n' +
    '                                        <!--</md-menu-item>-->\n' +
    '\n' +
    '                                        <!--Delete the book - DLO only & when MCO has requested-->\n' +
    '                                        <!--hidden until 4.0 or removal-->\n' +
    '                                        <!--<md-menu-item ng-if="tabBook.library.showDLODeleteBook(tabBook.book)">-->\n' +
    '                                        <!--<md-button  ng-click="tabBook.library.DLODelete(tabBook.book);" aria-label="Delete">-->\n' +
    '                                        <!--<div layout="row">-->\n' +
    '                                        <!--<p flex>DLO Delete</p>-->\n' +
    '                                        <!--<ng-md-icon icon="delete_forever" class="threeDotBookMenuPositioning" size="20"></ng-md-icon>-->\n' +
    '                                        <!--</div>-->\n' +
    '                                        <!--</md-button>-->\n' +
    '                                        <!--</md-menu-item>-->\n' +
    '\n' +
    '                                        <!-- Early Expire Document-->\n' +
    '                                        <md-menu-item ng-if="tabBook.library.viewEarlyExpire(tabBook.book)" >\n' +
    '                                            <md-button ng-click="tabBook.library.earlyExpireDialog(tabBook.book)" wraps-tooltip="jobbox-menu_earlyexpire">\n' +
    '                                                <div layout="row">\n' +
    '                                                    <p flex>Early Expire Document</p>\n' +
    '                                                    <ng-md-icon icon="pan_tool" class="threeDotBookMenuPositioning" size="16"></ng-md-icon>\n' +
    '                                                </div>\n' +
    '                                            </md-button>\n' +
    '                                        </md-menu-item>\n' +
    '\n' +
    '                                        <!-- upload to finals/publishing - this is a temp fix only seen by admin-->\n' +
    '                                        <md-menu-item ng-if="tabBook.library.isMemUploadable(tabBook.book)" >\n' +
    '                                          <md-button ng-click="tabBook.library.uploadPDF(tabBook.book)" wraps-tooltip="jobbox-menu_uploadtopublishing">\n' +
    '                                              <div layout="row">\n' +
    '                                                  <p flex>Upload to Publishing</p>\n' +
    '                                                  <ng-md-icon icon="file_upload" class="threeDotBookMenuPositioning" size="16"></ng-md-icon>\n' +
    '                                              </div>\n' +
    '                                          </md-button>\n' +
    '                                        </md-menu-item>\n' +
    '\n' +
    '                                        <!-- check workflow - this is a temp fix only seen by admin-->\n' +
    '                                        <md-menu-item ng-if="tabBook.library.isRepairWorkflow(tabBook.book)" >\n' +
    '                                            <md-button ng-click="tabBook.library.repairWorkflow(tabBook.book)" wraps-tooltip="jobbox-menu_repairworkflow">\n' +
    '                                                <div layout="row">\n' +
    '                                                    <p flex>Check and Repair Workflow</p>\n' +
    '                                                    <ng-md-icon icon="build" class="threeDotBookMenuPositioning" size="16"></ng-md-icon>\n' +
    '                                                </div>\n' +
    '                                            </md-button>\n' +
    '                                        </md-menu-item>\n' +
    '\n' +
    '\n' +
    '                                        <!-- Swap Team User, currently testing for design and final files -->\n' +
    '                                        <md-menu-item ng-if="tabBook.library.isSwapTeamUser(tabBook.book)" >\n' +
    '                                            <md-button ng-click="tabBook.library.swapTeamUserDialog(tabBook.book)">\n' +
    '                                                <div layout="row">\n' +
    '                                                    <p flex>Swap User in Team</p>\n' +
    '                                                    <ng-md-icon icon="people" class="threeDotBookMenuPositioning" size="16"></ng-md-icon>\n' +
    '                                                </div>\n' +
    '                                            </md-button>\n' +
    '                                        </md-menu-item>\n' +
    '\n' +
    '                                    </md-menu-content>\n' +
    '                                </md-menu>\n' +
    '\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                        <md-button aria-label="Close" ng-click="tabBook.library.closeTab(tabBook.book.bookId)" class="md-icon-button" wraps-tooltip="jobbox-close-icon">\n' +
    '                            <ng-md-icon icon="close" md-colors="{fill:\'grey-600\'}"></ng-md-icon>\n' +
    '                        </md-button>\n' +
    '\n' +
    '                    </div>\n' +
    '                    <div class="booksearchbar" layout="row" layout-align="start center" ng-show="tabBook.openSearch" ng-animate>\n' +
    '                        <form name="searchForm">\n' +
    '                            <md-input-container style="min-width:50%;width:480px;max-width:90%;" wraps-tooltip="doc-search-full-text" >\n' +
    '                                <label>Full text search</label>\n' +
    '                                <input ng-model="tabBook.searchBooksText" ng-model-options="{debounce:1000}"  md-select-on-focus minlength="4" name="textSearch" id="textSearch" aria-label="Search book content" md-placeholder="Full text search" ng-change="tabBook.getSearchByBook()">\n' +
    '                                <div ng-messages="searchForm.textSearch.$error">\n' +
    '                                    <div ng-message="minlength">Keyword(s) must be 4 characters or longer</div>\n' +
    '                                </div>\n' +
    '                            </md-input-container>\n' +
    '                        </form>\n' +
    '                    </div>\n' +
    '\n' +
    '\n' +
    '                    <md-progress-linear ng-if="tabBook.book.isLoading" md-mode="indeterminate" style="position: absolute;"></md-progress-linear>\n' +
    '                    <div  ng-if="!tabBook.book.isNew" style="padding:10px 20px 40px; text-align:left;" class="library-data" id="book-info-{{tabBook.book.bookId}}" ng-init="tabBook.library.showApproveToast(tabBook.book,\'book-info-{{tabBook.book.bookId}}\')"  >\n' +
    '\n' +
    '                        <!--{{ tabBook.book.approvalList[tabBook.book.approvalStatus]}}-->\n' +
    '\n' +
    '                        <div layout="row" layout-wrap>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '                            <div layout="row" layout-wrap flex="100" class="book-info" style="    margin-bottom: 10px;">\n' +
    '                                <div flex="100">\n' +
    '                                    <h3><span style="font-weight: 700 !important;">{{ tabBook.book.bookName }}</span><br />\n' +
    '                                        Code: <span style="font-weight: 500 !important;" ng-class="{\'code-change-error\': (tabBook.book.bookCodeOld != \'disableAutoCode\'  && tabBook.book.bookCodeOld.length > 0 && tabBook.book.bookCodeOld != tabBook.book.bookCode)}">\n' +
    '\n' +
    '                                                    <md-tooltip ng-if="(tabBook.book.bookCodeOld != \'disableAutoCode\'  && tabBook.book.bookCodeOld.length > 0 && tabBook.book.bookCodeOld != tabBook.book.bookCode)" class="statbar"  md-direction="top">\n' +
    '                                                        This filename has changed since creation ({{ tabBook.book.bookCodeOld }})\n' +
    '                                                    </md-tooltip>\n' +
    '\n' +
    '                                                    {{ tabBook.book.bookCode }}\n' +
    '\n' +
    '                                                </span><br />\n' +
    '\n' +
    '                                        <!-- <span ng-if="(tabBook.book.bookCodeOld != \'disableAutoCode\'  && tabBook.book.bookCodeOld.length > 0 && tabBook.book.bookCodeOld != tabBook.book.bookCode)">Initial Code: <span style="font-weight: 500 !important;">{{ tabBook.book.bookCodeOld }}</span><br /></span> -->\n' +
    '\n' +
    '                                        Owner: <span style="font-weight: 500 !important;">{{tabBook.book.Owner.fullName}}</span><br />\n' +
    '                                        ID: <span style="font-weight: 500 !important;">{{tabBook.book.bookIdLong}}</span></h3>\n' +
    '                                </div>\n' +
    '                                <div flex="25">\n' +
    '                                    <h4>Division: <b>{{ tabBook.book.Divisions.division }}</b></h4>\n' +
    '                                    <h4 ng-if="tabBook.book.insurerId != 38"><!-- non specific insurer id -->Insured by: <b>{{ tabBook.book.Insurers.insurerName}}</b></h4>\n' +
    '                                    <h4 ng-if="tabBook.book.insurerId == 38"><!-- non specific insurer id -->Insured by: <b>{{ tabBook.book.InsurerDetailsText }}</b></h4>\n' +
    '                                    <h4>Product: <b>{{tabBook.book.Products.productName}}</b></h4>\n' +
    '                                    <h4>Doc Type: <b>{{ tabBook.book.DocTypes.docTypeName }}</b></h4>\n' +
    '                                    <h4 ng-if="tabBook.book.socialChannelIds && tabBook.book.socialChannelIds.length > 0">Social Channels:\n' +
    '                                        <b ng-repeat="channelId in tabBook.book.socialChannelIds">{{ channelId |readableSocialChannel}}<span ng-if="!$last">, </span></b>\n' +
    '                                    </h4>\n' +
    '                                    <br />\n' +
    '                                    <md-fab-speed-dial md-direction="right" class="md-scale" md-open="false" style=" padding-top: 10px;">\n' +
    '                                        <md-fab-trigger>\n' +
    '                                            <md-button ng-if="tabBook.book.DocTypes.docTypeTemplate === 1"  target="_blank"  class="md-fab " style=" background: #4CAF50;"   aria-label="alert" wraps-tooltip="jobbox-download">\n' +
    '                                                <ng-md-icon  icon="file_download" style="fill: white;    position: relative;" size="32"></ng-md-icon>\n' +
    '                                            </md-button>\n' +
    '                                        </md-fab-trigger>\n' +
    '\n' +
    '                                        <md-fab-actions ng-if="tabBook.book.DocTypes.docTypeId === 1">\n' +
    '                                            <md-button href="{{tabBook.library.pdfUrl}}{{tabBook.book.pdfVersionUrl}}/?book={{ tabBook.book.bookId}}{{tabBook.library.linkExtras}}" target="_blank" type="button" class=" speeddial-button green-button" wraps-tooltip="jobbox-download-pdf">PDF</md-button>\n' +
    '                                            <md-button href="{{tabBook.library.pdfUrl}}{{tabBook.book.pdfVersionUrl}}/?book={{ tabBook.book.bookId}}{{tabBook.library.linkExtras}}&hl=true" target="_blank" type="button" class=" speeddial-button orange-button" wraps-tooltip="jobbox-download-changes">CHANGES</md-button>\n' +
    '                                            <md-button href="{{tabBook.library.pdfUrl}}/wcr/?book={{ tabBook.book.bookId}}{{tabBook.library.linkExtras}}&hl=true" target="_blank" type="button" class=" speeddial-button blue-button" wraps-tooltip="jobbox-download-wcr">WCR</md-button>\n' +
    '                                            <md-button ng-if="tabBook.library.isDLO(tabBook.book)" href="{{tabBook.library.pdfUrl}}{{tabBook.book.pdfVersionUrl}}/?book={{ tabBook.book.bookId}}{{tabBook.library.linkExtras}}&big=true" target="_blank"  type="button" class=" speeddial-button purple-button" wraps-tooltip="jobbox-download-large">LARGE FORMAT</md-button>\n' +
    '                                            <md-button ng-if="tabBook.library.isDLO(tabBook.book) && tabBook.book.status===1" href="{{tabBook.library.pdfUrl}}/translations/?book={{ tabBook.book.bookId}}{{tabBook.library.linkExtras}}" target="_blank"  type="button" class=" speeddial-button pink-button" wraps-tooltip="jobbox-download-translation-json">TRANSLATION JSON</md-button>\n' +
    '\n' +
    '\n' +
    '                                        </md-fab-actions>\n' +
    '\n' +
    '                                        <md-fab-actions ng-if="tabBook.book.DocTypes.docTypeId === 93">\n' +
    '                                            <md-button href="{{tabBook.library.pdfUrl}}{{tabBook.book.pdfVersionUrl}}/?book={{ tabBook.book.bookId}}{{tabBook.library.linkExtras}}" target="_blank" type="button" class=" speeddial-button green-button" wraps-tooltip="jobbox-download-pdf">PDF</md-button>\n' +
    '                                            <md-button href="{{tabBook.library.pdfUrl}}{{tabBook.book.pdfVersionUrl}}/?book={{ tabBook.book.bookId}}{{tabBook.library.linkExtras}}&print=true" target="_blank" type="button" class=" speeddial-button purple-button" wraps-tooltip="jobbox-download-hr-pdf">HR PDF</md-button>\n' +
    '                                            <md-button href="{{tabBook.library.pdfUrl}}/wcr/?book={{ tabBook.book.bookId}}{{tabBook.library.linkExtras}}&hl=true" target="_blank" type="button" class=" speeddial-button blue-button" wraps-tooltip="jobbox-download-wcr">WCR</md-button>\n' +
    '\n' +
    '\n' +
    '                                        </md-fab-actions>\n' +
    '                                    </md-fab-speed-dial>\n' +
    '\n' +
    '\n' +
    '                                </div>\n' +
    '                                <div flex="25">\n' +
    '                                    <h4>Audience: <b>{{tabBook.book.Audiences.audienceName}}</b></h4>\n' +
    '                                    <h4>Region: <b>{{tabBook.book.Regions.regionName}}</b></h4>\n' +
    '                                    <h4 ng-if="tabBook.book.SubInsurers.subInsurerName">Sub Insurer: <b>{{tabBook.book.SubInsurers.subInsurerName}}</b></h4>\n' +
    '                                    <h4 ng-if="tabBook.book.Clients.clientName">Client: <b>{{tabBook.book.Clients.clientName}}</b></h4>\n' +
    '                                    <h4 ng-if="tabBook.book.Clients.Projects.projectName">Project: <b>{{tabBook.book.Projects.projectName}}</b></h4>\n' +
    '                                </div>\n' +
    '\n' +
    '                                <div flex="25">\n' +
    '\n' +
    '                                    <h4 ng-if="tabBook.book.Distributors.distributorName">Distributor: <b>{{tabBook.book.Distributors.distributorName}}</b></h4>\n' +
    '                                    <h4>Customer Type: <b>{{tabBook.book.ProductTypes.productTypeName}}</b></h4>\n' +
    '                                    <h4>Workflow Status: <b>{{ tabBook.book.workflowStatus }}</b></h4>\n' +
    '                                    <h4>Document Status: <b>{{ tabBook.book.documentStatus }}</b></h4>\n' +
    '                                    <h4 ng-if="tabBook.book.sourceId" ><span class="meta-label">Duplication ID: </span><a class="md-accent" style="font-weight: bold;" ng-href="/library/{{ tabBook.book.sourceId }}">{{ tabBook.book.sourceIdLong }}</a></h4>\n' +
    '                                </div>\n' +
    '\n' +
    '                                <div flex="25">\n' +
    '\n' +
    '                                    <h4>Created Date: <b>{{tabBook.book.createdDisplayDate }}</b></h4>\n' +
    '                                    <h4>Active Date: <b>{{tabBook.book.activeDateDisplayDate }}</b></h4>\n' +
    '                                    <h4>Live Date: <b>{{tabBook.book.liveDateDisplayDate }}</b></h4>\n' +
    '                                    <h4>Review Date: <b>{{tabBook.book.reviewDateDisplayDate }}</b></h4>\n' +
    '                                    <h4>Expiry Date: <b>{{tabBook.book.expiryDateDisplayDate }}</b></h4>\n' +
    '                                    <h4 ng-if="tabBook.book.earlyExpiryDate">Early Expiry Date: <b>{{tabBook.book.earlyExpiryDateDisplayDate }}</b></h4>\n' +
    '                                    <h4 ng-if="tabBook.book.withdrawDate">Withdrawn Date: <b>{{tabBook.book.withdrawDate | date:\'dd/MM/yyyy\'}}</b></h4>\n' +
    '                                </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '                            </div>\n' +
    '\n' +
    '                        </div>\n' +
    '\n' +
    '                        <div ng-if="tabBook.book.Children">\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '                            <md-divider ng-if="tabBook.book.DocTypes.docTypeTemplate === 1"></md-divider>\n' +
    '                            <h1  ng-if="tabBook.book.DocTypes.docTypeTemplate === 1" ng-click="tabBook.opentab.selected.doc = !tabBook.opentab.selected.doc; tabBook.library.goToAnchor(\'anchorBook\' + tabBook.book.bookId)"  ng-class="{\'active-header\': tabBook.opentab.selected.doc}"  style=" cursor: pointer; padding:0 0 10px 10px;  " layout="row" layout-align="space-between center">Document Content\n' +
    '\n' +
    '                                <ng-md-icon ng-if="tabBook.book.action && !tabBook.opentab.selected.doc" icon="error" md-colors="{fill: \'light-blue\'}" size="33"></ng-md-icon>\n' +
    '                                <span  ng-if="tabBook.book.searchCount>0" class="searchCount">{{tabBook.book.searchCount}}</span>\n' +
    '\n' +
    '                                <div layout="row" layout-wrap flex="70" ng-if="tabBook.book.docStatusBar">\n' +
    '                                    <div flex></div>\n' +
    '                                    <div flex="nogrow" ng-if="!tabBook.library.isReorderingChildren(tabBook.book)">\n' +
    '                                        <div class="statbar" style="">\n' +
    '                                            <!--<h2 style="margin:5px;">Document:</h2>-->\n' +
    '                                            <span class="outer "  ng-repeat="stat in tabBook.book.docStatusBar">\n' +
    '                                                          <span class="inner" ng-class="stat.colour">{{stat.count}}<md-tooltip class="statbar"  md-direction="top">{{stat.tooltip}}</md-tooltip></span>\n' +
    '                                                          <span class="label">{{stat.name}}</span>\n' +
    '\n' +
    '                                                        </span>\n' +
    '                                        </div>\n' +
    '                                    </div>\n' +
    '                                    <div ng-if="tabBook.library.isReorderingChildren(tabBook.book)" style="padding:5px 3px 0px 0px">\n' +
    '                                        <md-button class="md-grey"  ng-click="tabBook.library.reorderClose(tabBook.book);tabBook.book.updateBook(true);$event.stopPropagation();">Cancel</md-button>\n' +
    '                                        <md-button class="md-green" ng-click="tabBook.library.reorderSave(tabBook.book,null,tabBook.book);$event.stopPropagation();">Re-Order</md-button>\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '\n' +
    '\n' +
    '                            </h1>\n' +
    '\n' +
    '                            <doc ng-if="tabBook.book.DocTypes.docTypeTemplate === 1 && tabBook.opentab.selected.doc" book="tabBook.book"></doc>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '                            <!--BRIEF-->\n' +
    '\n' +
    '\n' +
    '\n' +
    '                            <md-divider ng-if="tabBook.book.briefStatusBar"></md-divider>\n' +
    '                            <h1  ng-if="tabBook.book.briefStatusBar" ng-click="tabBook.opentab.selected.brief = !tabBook.opentab.selected.brief"  ng-class="{\'active-header\': tabBook.opentab.selected.brief}"  style="padding:0 0 10px 10px; cursor: pointer;" layout="row" layout-align="space-between center" wraps-tooltip="itembrief">Document or Brief\n' +
    '\n' +
    '                                <div layout="row" layout-wrap flex="70" ng-if="tabBook.book.briefStatusBar">\n' +
    '\n' +
    '\n' +
    '                                    <div flex></div>\n' +
    '                                    <div flex="nogrow">\n' +
    '                                        <div class="statbar" style="">\n' +
    '                                            <!--<h2 style="margin:5px; z-index: 1;">Brief:</h2>-->\n' +
    '                                            <span class="outer "  ng-repeat="stat in tabBook.book.briefStatusBar">\n' +
    '                                                      <span class="inner" ng-class="stat.colour">{{stat.count}}<md-tooltip class="statbar"  md-direction="top">{{stat.tooltip}}</md-tooltip></span>\n' +
    '                                                      <span class="label">{{stat.name}}</span>\n' +
    '\n' +
    '                                                    </span>\n' +
    '                                        </div>\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '\n' +
    '                            </h1>\n' +
    '\n' +
    '                            <md-list ng-if="(tabBook.book.showBrief && tabBook.opentab.selected.brief)">\n' +
    '                                <md-button ng-if="tabBook.book.showBriefButton && tabBook.book.documentStatus.substring(0, 4) !== \'EXPD\'" type="button" class="md-fab md-mini add-brief" ng-click="tabBook.library.addbriefDialog(tabBook.book);$event.stopPropagation()" aria-label="Add Brief" wraps-tooltip="briefbox-addbrief">\n' +
    '                                    <ng-md-icon icon="add" size="26"  aria-label="add"></ng-md-icon>\n' +
    '                                </md-button>\n' +
    '\n' +
    '                                <md-list-item layout="row" flex="100" layout-wrap ng-repeat="brief in tabBook.book.Children | filter: {versions: {Contents: {contentTypeId: 9}}} | orderBy:\'order\'">\n' +
    '                                    <div ng-class="{ \'selectedChapter\' : tabBook.opentab.selected.structure[0] == brief.structureId}"  flex="100" layout="row"  layout-wrap ng-click="tabBook.toggleStructure(brief.structureId);">\n' +
    '\n' +
    '                                        <div flex="35" class="brief-text" ng-bind-html="brief.brief.description | urlsToLinks"  ></div>\n' +
    '                                        <!-- <ul flex="40" class="files-ul">\n' +
    '                                            <li ng-repeat="file in brief.brief.filesList" >\n' +
    '\n' +
    '                                                <ng-md-icon class="file-download"   icon="attach_file" size="30"  aria-label="Upload complete"></ng-md-icon><a   ng-href="{{tabBook.library.getFile(file.fileLocation,file.fileName)}}" target="_blank" >{{file.fileName}}</a>\n' +
    '\n' +
    '                                            </li>\n' +
    '                                        </ul> -->\n' +
    '                                        <div flex="50" class="file-ui downloads-container">\n' +
    '\n' +
    '\n' +
    '                                            <div ng-repeat="upload in brief.brief.filesList" layout="row" layout-wrap layout-padding="20" class="download-row small" >\n' +
    '\n' +
    '                                                <a href="{{upload.ngfName|fileurl}}" target="_blank">\n' +
    '                                                    <div class="thumbnail-block" wraps-tooltip="artwork-upload-thumb">\n' +
    '\n' +
    '                                                        <img ng-if="!upload.isLoading" class="thumbnail-preview" ng-src="{{upload.ngfName|thumburl}}" style="" err-SRC="/images/wraps-logo.png" alt="{{upload.fileName}}"/>\n' +
    '                                                        <div class="overlay text-center"><ng-md-icon icon="file_download" size="24"></ng-md-icon></div>\n' +
    '                                                    </div>\n' +
    '                                                </a>\n' +
    '                                                <div flex>\n' +
    '                                                    <h2 >{{upload.fileName}}</h2>\n' +
    '                                                    <h2 >{{upload.fileSize | filesize}} </h2>\n' +
    '                                                </div>\n' +
    '                                                <div flex="100" class="divider"><div></div></div>\n' +
    '                                            </div>\n' +
    '                                        </div>\n' +
    '                                        <div flex="15"><content-menu child="brief" parentbook="tabBook.book" parent="tabBook.book"></content-menu></div>\n' +
    '                                    </div>\n' +
    '\n' +
    '                                </md-list-item>\n' +
    '                            </md-list>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '                            <!--ARTWORK-->\n' +
    '\n' +
    '\n' +
    '                            <md-divider ng-if="tabBook.book.artworkStatusBar"></md-divider>\n' +
    '                            <h1  ng-if="tabBook.book.artworkStatusBar" ng-click="tabBook.opentab.selected.artwork = !tabBook.opentab.selected.artwork"  ng-class="{\'active-header\': tabBook.opentab.selected.artwork}"  style="  padding:0 0 10px 10px; cursor: pointer;" layout="row" layout-align="space-between center">Artwork Draft\n' +
    '                                <div layout="row" layout-wrap flex="70" ng-if="tabBook.book.artworkStatusBar">\n' +
    '\n' +
    '\n' +
    '\n' +
    '                                    <div flex></div>\n' +
    '                                    <div flex="nogrow">\n' +
    '                                        <div class="statbar" style="">\n' +
    '                                            <!--<h2 style="margin:5px; z-index: 1;">Artwork:</h2>-->\n' +
    '                                            <span class="outer "  ng-repeat="stat in tabBook.book.artworkStatusBar">\n' +
    '                                                      <span class="inner" ng-class="stat.colour">{{stat.count}}<md-tooltip class="statbar"  md-direction="top">{{stat.tooltip}}</md-tooltip></span>\n' +
    '                                                      <span class="label">{{stat.name}}</span>\n' +
    '\n' +
    '                                                    </span>\n' +
    '                                        </div>\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </h1>\n' +
    '                            <md-list ng-if="(tabBook.book.showArtwork && tabBook.opentab.selected.artwork)">\n' +
    '                                <md-button ng-if="tabBook.book.showArtworkButton && tabBook.book.documentStatus.substring(0, 4) != \'EXPD\'" type="button" class="md-fab md-mini add-brief" ng-click="tabBook.library.addartworkDialog(tabBook.book);$event.stopPropagation()" aria-label="Add Artwork">\n' +
    '                                    <ng-md-icon icon="add" size="26"  aria-label="add"></ng-md-icon>\n' +
    '                                </md-button>\n' +
    '                                <md-list-item layout="row" flex="100" layout-wrap ng-repeat="artwork in tabBook.book.Children | filter: {versions: {Contents: {contentTypeId: 10}}} | orderBy:\'order\'">\n' +
    '                                    <div ng-class="{ \'selectedChapter\' : tabBook.opentab.selected.structure[0] == artwork.structureId}"  flex="100" layout="row"  layout-wrap ng-click="tabBook.toggleStructure(artwork.structureId);">\n' +
    '\n' +
    '                                        <div flex="35" class="brief-text" ng-bind-html="artwork.artwork.description | urlsToLinks"  ></div>\n' +
    '\n' +
    '\n' +
    '                                        <div flex="50" class="file-ui downloads-container">\n' +
    '\n' +
    '\n' +
    '                                            <div ng-repeat="upload in artwork.artwork.filesList" layout="row" layout-wrap layout-padding="20" class="download-row small"  ng-class="{\'file-name-error\': !tabBook.filenameInCodeCheck(upload.fileName,tabBook.book.bookCode)}">\n' +
    '\n' +
    '                                                <md-tooltip ng-if="!tabBook.filenameInCodeCheck(upload.fileName,tabBook.book.bookCode);" class="statbar"  md-direction="top">This filename does not contain the current job code</md-tooltip>\n' +
    '                                                <a href="{{upload.ngfName|fileurl}}" target="_blank">\n' +
    '                                                    <div class="thumbnail-block" wraps-tooltip="artwork-upload-thumb">\n' +
    '\n' +
    '                                                        <img ng-if="!upload.isLoading" class="thumbnail-preview" ng-src="{{upload.ngfName|thumburl}}" err-SRC="/images/wraps-logo.png" alt="{{upload.fileName}}"/>\n' +
    '                                                        <div class="overlay text-center"><ng-md-icon icon="file_download" size="24"></ng-md-icon></div>\n' +
    '                                                    </div>\n' +
    '                                                </a>\n' +
    '                                                <div flex>\n' +
    '                                                    <h2 >{{upload.fileName}}</h2>\n' +
    '                                                    <h2 >{{upload.fileSize | filesize}} </h2>\n' +
    '                                                </div>\n' +
    '                                                <div flex="100" class="divider"><div></div></div>\n' +
    '                                            </div>\n' +
    '                                        </div>\n' +
    '                                        <div flex="15"><content-menu child="artwork" parentbook="tabBook.book" parent="tabBook.book"></content-menu></div>\n' +
    '                                    </div>\n' +
    '                                </md-list-item>\n' +
    '                            </md-list>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '                            <!--FINAL-->\n' +
    '\n' +
    '\n' +
    '                            <md-divider ng-if="tabBook.book.finalStatusBar"></md-divider>\n' +
    '                            <h1  ng-if="tabBook.book.finalStatusBar" ng-click="tabBook.opentab.selected.final = !tabBook.opentab.selected.final"  ng-class="{\'active-header\': tabBook.opentab.selected.final}"  style="  padding:0 0 10px 10px; cursor: pointer;" layout="row" layout-align="space-between center">Publishing\n' +
    '                                <div layout="row" layout-wrap flex="70" ng-if="tabBook.book.finalStatusBar">\n' +
    '\n' +
    '\n' +
    '\n' +
    '                                    <div flex></div>\n' +
    '                                    <div flex="nogrow">\n' +
    '                                        <div class="statbar" style="">\n' +
    '                                                    <span class="outer "  ng-repeat="stat in tabBook.book.finalStatusBar">\n' +
    '                                                      <span class="inner" ng-class="stat.colour">{{stat.count}}<md-tooltip class="statbar"  md-direction="top">{{stat.tooltip}}</md-tooltip></span>\n' +
    '                                                      <span ng-if="stat.name != \'DESIGN\'" class="label">{{stat.name}}</span>\n' +
    '                                                      <span ng-if="stat.name == \'DESIGN\'" class="label" wraps-tooltip="docman-wf-input_finalfiles">FINAL FILES</span>\n' +
    '\n' +
    '                                                    </span>\n' +
    '                                        </div>\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </h1>\n' +
    '\n' +
    '                            <md-list ng-if="(tabBook.book.showFinal && tabBook.opentab.selected.final)">\n' +
    '                                <md-button ng-if="tabBook.book.showFinalButton && tabBook.book.documentStatus.substring(0, 4) != \'EXPD\'" type="button" class="md-fab md-mini add-brief" ng-click="tabBook.library.addFinalDialog(tabBook.book);$event.stopPropagation()" aria-label="Add Publishing">\n' +
    '                                    <ng-md-icon icon="add" size="26"  aria-label="add"></ng-md-icon>\n' +
    '                                </md-button>\n' +
    '                                <md-list-item layout="row" flex="100" layout-wrap ng-repeat="final in tabBook.book.Children | filter: {versions: {Contents: {contentTypeId: 13}}} | orderBy:\'order\'">\n' +
    '                                    <div ng-class="{ \'selectedChapter\' : tabBook.opentab.selected.structure[0] == final.structureId}"  flex="100" layout="row"  layout-wrap ng-click="tabBook.toggleStructure(final.structureId);">\n' +
    '\n' +
    '                                        <div flex="35" class="brief-text" ng-bind-html="final.artwork.description | urlsToLinks"  ></div>\n' +
    '\n' +
    '                                        <!-- <ul flex="40" class="files-ul">\n' +
    '                                            <li ng-repeat="file in final.artwork.filesList" >\n' +
    '\n' +
    '\n' +
    '                                                <a   ng-class="{\'file-name-error\': !tabBook.filenameInCodeCheck(file.fileName,tabBook.book.bookCode)}" ng-href="{{tabBook.library.getFile(file.fileLocation,file.fileName)}}" target="_blank" >\n' +
    '                                                    <md-tooltip ng-if="!tabBook.filenameInCodeCheck(file.fileName,tabBook.book.bookCode);" class="statbar"  md-direction="top">This filename does not contain the current job code</md-tooltip>\n' +
    '                                                    <ng-md-icon class="file-download"   icon="insert_photo" size="30"  aria-label="Upload complete"></ng-md-icon>\n' +
    '                                                    {{file.fileName}}\n' +
    '                                                </a>\n' +
    '\n' +
    '                                            </li>\n' +
    '                                        </ul> -->\n' +
    '                                        <div flex="50" class="file-ui downloads-container">\n' +
    '\n' +
    '\n' +
    '                                            <div ng-repeat="upload in final.artwork.filesList" layout="row" layout-wrap layout-padding="20" class="download-row small"  ng-class="{\'file-name-error\': !tabBook.filenameInCodeCheck(upload.fileName,tabBook.book.bookCode)}">\n' +
    '\n' +
    '                                                <md-tooltip ng-if="!tabBook.filenameInCodeCheck(upload.fileName,tabBook.book.bookCode);" class="statbar"  md-direction="top">This filename does not contain the current job code</md-tooltip>\n' +
    '                                                <a href="{{upload.ngfName|fileurl}}" target="_blank">\n' +
    '                                                    <div class="thumbnail-block" wraps-tooltip="artwork-upload-thumb">\n' +
    '\n' +
    '                                                        <img ng-if="!upload.isLoading" class="thumbnail-preview" ng-src="{{upload.ngfName|thumburl}}" err-SRC="/images/wraps-logo.png" alt="{{upload.fileName}}"/>\n' +
    '                                                        <div class="overlay text-center"><ng-md-icon icon="file_download" size="24"></ng-md-icon></div>\n' +
    '                                                    </div>\n' +
    '                                                </a>\n' +
    '                                                <div flex>\n' +
    '                                                    <h2 >{{upload.fileName}}</h2>\n' +
    '                                                    <h2 >{{upload.fileSize | filesize}} </h2>\n' +
    '                                                </div>\n' +
    '                                                <div flex="100" class="divider"><div></div></div>\n' +
    '                                            </div>\n' +
    '                                        </div>\n' +
    '                                        <div flex="15"><content-menu child="final" parentbook="tabBook.book" parent="tabBook.book"></content-menu></div>\n' +
    '                                    </div>\n' +
    '                                </md-list-item>\n' +
    '                            </md-list>\n' +
    '\n' +
    '\n' +
    '                            <!--WCR-->\n' +
    '\n' +
    '                            <md-divider ng-if="tabBook.book.DocTypes.docTypeTemplate === 1"></md-divider>\n' +
    '                            <h1 ng-if="tabBook.book.DocTypes.docTypeTemplate === 1"  ng-click="tabBook.opentab.selected.wcr = !tabBook.opentab.selected.wcr" ng-class="{\'active-header\': tabBook.opentab.selected.wcr}" style=" cursor: pointer; padding:20px 0 20px 10px;">Wording Change Report </h1>\n' +
    '                            <wcr ng-if="tabBook.book.DocTypes.docTypeTemplate === 1; tabBook.opentab.selected.wcr;" book="tabBook.book"></wcr>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '                            <md-divider></md-divider>\n' +
    '\n' +
    '                        </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                    </div>\n' +
    '\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-components/date-icon/date-icon.tpl.html',
    '<div class="outer" md-colors="{background:dateIcon.color}">\n' +
    '    <span>&nbsp;{{dateIcon.date | date : \'dd\'}}<span ng-if="!dateIcon.date">{{dateIcon.notSetLabel[0]}}</span>&nbsp;</span>\n' +
    '    <div class="inner" md-colors="{color:dateIcon.color}">\n' +
    '        <span>{{dateIcon.date | date : \'MMM\'}}<span  ng-if="!dateIcon.date">{{dateIcon.notSetLabel[1]}}</span></span>\n' +
    '        <span class="sub">{{dateIcon.date | date : \'yyyy\'}}<span  ng-if="!dateIcon.date">{{dateIcon.notSetLabel[2]}}</span></span>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<span class="label" md-colors="{color:dateIcon.color}">{{dateIcon.label}}</span>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('users/users-directives/users-rightMenu.tpl.html',
    '<md-toolbar class="md-theme-light">\n' +
    '    <div class="md-toolbar-tools">\n' +
    '        <h1 flex class="md-title" wraps-tooltip="users-form-title">User Management</h1>\n' +
    '        <md-button ng-click="users.closeRightMenu()" class="md-icon-button" aria-label="Close right pane" wraps-tooltip="users-form-close">\n' +
    '            <ng-md-icon icon="close" size="24"></ng-md-icon>\n' +
    '        </md-button>\n' +
    '    </div>\n' +
    '</md-toolbar>\n' +
    '<md-content  layout-padding>\n' +
    '\n' +
    '    <form style="padding:0px 20px 80px;" autocomplete="off" name="usersForm" ng-model-options="{ allowInvalid: true}">\n' +
    '        <!--<pre>{{documentInfoForm.insurerId | json}}</pre>-->\n' +
    '        <div layout="row" layout-align="end none">\n' +
    '        <md-button  type="button" class="md-grey cancel-button"  ng-click="users.closeRightMenu()" wraps-tooltip="users-form-close">Cancel<ng-md-icon icon="close" style="fill: white; position: relative; padding-left: 4px; " size="20"  aria-label="Cancel"></ng-md-icon></md-button>\n' +
    '        <md-button\n' +
    '                type="button"\n' +
    '                class="md-green save-button"\n' +
    '                ng-click="users.saveUser(users.userDetails, usersForm)"\n' +
    '                ng-disabled="usersForm.$invalid" wraps-tooltip="users-form-save"\n' +
    '        >Save<ng-md-icon icon="check" style="fill: white; position: relative; padding-left: 4px; " size="20"  aria-label="Save changes"></ng-md-icon></md-button>\n' +
    '        <br />\n' +
    '		<input type="email" name="emailhacktopreventchromeautofill" style="display:none;"/>\n' +
    '		<input type="password" name="passwordhacktopreventchromeautofill" style="display:none;"/>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                <p wraps-tooltip="users-licenses">Currently using {{users.noOfUsers}} of {{users.noOfSubs}} licenses</p>\n' +
    '\n' +
    '                <div layout="row"  layout-wrap>\n' +
    '\n' +
    '                    <md-input-container flex="33" wraps-tooltip="users-form-firstname">\n' +
    '                        <label>First Name</label>\n' +
    '                        <input ng-model="users.userDetails.firstName" required name="firstName">\n' +
    '                        <div ng-show="usersForm.firstName.$touched" ng-messages="usersForm.firstName.$error">\n' +
    '                            <span ng-message="required" class="error">You must enter a first name.</span>\n' +
    '                        </div>\n' +
    '                    </md-input-container>\n' +
    '\n' +
    '                    <md-input-container flex="33" wraps-tooltip="users-form-lastname">\n' +
    '                        <label>Last Name</label>\n' +
    '                        <input ng-model="users.userDetails.lastName" required name="lastName">\n' +
    '                        <div ng-show="usersForm.lastName.$touched" ng-messages="usersForm.lastName.$error">\n' +
    '                            <span ng-message="required" class="error">You must enter a last name.</span>\n' +
    '                        </div>\n' +
    '                    </md-input-container>\n' +
    '\n' +
    '                    <md-input-container flex="33" wraps-tooltip="users-form-job">\n' +
    '                        <label>Job Title</label>\n' +
    '                        <input ng-model="users.userDetails.jobTitle" required name="jobTitle">\n' +
    '                        <div ng-show="usersForm.jobTitle.$touched" ng-messages="usersForm.jobTitle.$error">\n' +
    '                            <span ng-message="required" class="error">You must enter a job title.</span>\n' +
    '                        </div>\n' +
    '                    </md-input-container>\n' +
    '\n' +
    '                </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '                <div layout="row"  layout-wrap>\n' +
    '\n' +
    '                    <md-input-container flex="33" wraps-tooltip="users-form-email">\n' +
    '                        <label>Email address</label>\n' +
    '                        <input ng-model="users.userDetails.email" type="email" required name="email" ng-pattern="users.existingEmail">\n' +
    '                        <div ng-show="usersForm.email.$touched" ng-messages="usersForm.email.$error">\n' +
    '                            <span ng-message="pattern" class="error">This email address has already been registered.</span>\n' +
    '                            <span ng-message="required" class="error">You must enter an email address.</span>\n' +
    '                            <span ng-message="email" class="error">You must enter a valid email address.</span>\n' +
    '                        </div>\n' +
    '                    </md-input-container>\n' +
    '\n' +
    '                    <md-input-container flex="33" wraps-tooltip="users-form-contact-email">\n' +
    '                        <label>Contact Email Address</label>\n' +
    '                        <input ng-model="users.userDetails.contactEmail" required name="contactEmail">\n' +
    '                        <div ng-show="usersForm.contactEmail.$touched" ng-messages="usersForm.contactEmail.$error">\n' +
    '                            <span ng-message="required" class="error">You must enter an email address.</span>\n' +
    '                            <span ng-message="email" class="error">You must enter a valid email address.</span>\n' +
    '                        </div>\n' +
    '                    </md-input-container>\n' +
    '\n' +
    '                    <md-input-container flex="33" wraps-tooltip="users-form-tel">\n' +
    '                        <label>Phone Number</label>\n' +
    '                        <input ng-model="users.userDetails.tel" required name="phoneNumber">\n' +
    '                        <div ng-show="usersForm.phoneNumber.$touched" ng-messages="usersForm.phoneNumber.$error">\n' +
    '                            <span ng-message="required" class="error">You must enter a phone number.</span>\n' +
    '                        </div>\n' +
    '                    </md-input-container>\n' +
    '\n' +
    '                </div>\n' +
    '\n' +
    '                <div layout="row"  layout-wrap>\n' +
    '\n' +
    '                    <md-input-container flex="25" wraps-tooltip="users-form-team">\n' +
    '                        <label>Team</label>\n' +
    '                        <md-select ng-model="users.userDetails.teamId" required placeholder="Team" name="team">\n' +
    '                            <md-option value="{{team.teamId}}" name="team" required ng-repeat="team in users.teams | orderBy: [\'Departments.departmentName\',\'teamName\']" >{{team.Departments.departmentName}} / {{team.teamName}}</md-option>\n' +
    '                        </md-select>\n' +
    '                        <div ng-show="usersForm.team.$touched" ng-messages="usersForm.team.$error">\n' +
    '                            <span ng-message="required" class="error">You must enter a team.</span>\n' +
    '                        </div>\n' +
    '                    </md-input-container>\n' +
    '                    <md-input-container flex="25" wraps-tooltip="users-form-location">\n' +
    '                        <label>Location</label>\n' +
    '                        <md-select ng-model="users.userDetails.locationId" required placeholder="Location" name="location">\n' +
    '                            <md-option value="{{location.locationId}}"  ng-repeat="location in users.locations | orderBy: \'locationName\'" >{{location.locationName}}</md-option>\n' +
    '                        </md-select>\n' +
    '                        <div ng-show="usersForm.location.$touched" ng-messages="usersForm.location.$error">\n' +
    '                            <span ng-message="required" class="error">You must enter a location.</span>\n' +
    '                        </div>\n' +
    '                    </md-input-container>\n' +
    '\n' +
    '                    <md-input-container flex="25" wraps-tooltip="users-form-role">\n' +
    '                        <label>User Roles</label>\n' +
    '                        <md-select ng-model="users.userDetails.roleId" required placeholder="User Roles" name="role">\n' +
    '                            <md-option value="{{role.id}}"  ng-repeat="role in users.roles | orderBy: \'name\'" >{{role.name}}</md-option>\n' +
    '                        </md-select>\n' +
    '                        <div ng-show="usersForm.role.$touched" ng-messages="usersForm.role.$error">\n' +
    '                            <span ng-message="required" class="error">You must enter a role.</span>\n' +
    '                        </div>\n' +
    '                    </md-input-container>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                    <md-input-container flex="25" wraps-tooltip="users-form-status">\n' +
    '                        <label>Status</label>\n' +
    '                        <md-select ng-model="users.userDetails.status"  name="status" placeholder="User Status">\n' +
    '                            <md-option value=null  >Active</md-option>\n' +
    '                            <md-option value="{{status.statusId}}"  ng-repeat="status in users.usersStatus | orderBy: \'status.statusName\'" >{{status.statusName}}</md-option>\n' +
    '                        </md-select>\n' +
    '                        <!--<div ng-show="usersForm.status.$touched" ng-messages="usersForm.status.$error">-->\n' +
    '                        <!--<span ng-message="required" class="error">You must enter a status.</span>-->\n' +
    '                        <!--</div>-->\n' +
    '                    </md-input-container>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '                </div>\n' +
    '				<div layout="row" wraps-tooltip="users-form-notes">\n' +
    '					<md-input-container flex="100">\n' +
    '                        <label>Notes</label>\n' +
    '						<textarea ng-model="users.userDetails.bupaNotes" name="bupaNotes"></textarea>\n' +
    '                    </md-input-container>\n' +
    '				</div>\n' +
    '\n' +
    '                <div layout="row">\n' +
    '\n' +
    '\n' +
    '\n' +
    '                        <md-input-container flex="33" wraps-tooltip="users-form-restricted">\n' +
    '                            <md-checkbox class="" ng-model="users.userDetails.limitDocs" ng-true-value="1"  ng-true-value=false aria-label="Restricted User" style="margin: 0px;">\n' +
    '                                Restricted User\n' +
    '                            </md-checkbox>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '\n' +
    '                    <md-input-container flex="33" wraps-tooltip="users-form-permission-group">\n' +
    '                        <label>Permissions Group</label>\n' +
    '                        <md-select ng-model="users.userDetails.permissionGroup" placeholder="Permission Group">\n' +
    '                            <md-option value="{{group.groupId}}"  ng-repeat="group in users.permissionGroups | orderBy: \'groupName\'" >{{group.groupName}}</md-option>\n' +
    '                        </md-select>\n' +
    '                    </md-input-container>\n' +
    '\n' +
    '                    <md-input-container flex="33" wraps-tooltip="users-form-permission-group">\n' +
    '                        <label>Division</label>\n' +
    '                        <md-select ng-model="users.userDetails.divisionId" placeholder="Division">\n' +
    '                            <md-option value="{{division.divisionId}}"  ng-repeat="division in users.divisions | orderBy: \'division\'" >{{division.division}}</md-option>\n' +
    '                        </md-select>\n' +
    '                    </md-input-container>\n' +
    '\n' +
    '\n' +
    '                </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '                <div layout="row"  layout-wrap>\n' +
    '                    <md-input-container flex="33" wraps-tooltip="users-form-change-password">\n' +
    '                        <md-checkbox ng-if="users.userDetails.id" class="" ng-model="users.temp.changepassword" aria-label="Change Password" style="margin: 0px;">\n' +
    '                            Change Password\n' +
    '                        </md-checkbox>\n' +
    '                    </md-input-container>\n' +
    '\n' +
    '                    <md-input-container flex="33" ng-if="users.temp.changepassword || !users.userDetails.id" wraps-tooltip="users-form-password">\n' +
    '                        <label>Password</label>\n' +
    '                        <input ng-model="users.userDetails.password" type="password" required ng-minlength="8" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*]).+" name="password">\n' +
    '                        <div ng-show="usersForm.password.$touched" ng-messages="usersForm.password.$error" role="alert" class="t-sm">\n' +
    '                            <div ng-message="minlength" class="error">Your password must be at least 8 characters</div>\n' +
    '                            <div ng-message="pattern" class="error">The password must contain a combination of uppercase, lowercase, numeric characters and at least one of the following: !@#$%^&amp;*.</div>\n' +
    '                            <div ng-message="validation" class="error">This email address has already been registered</div>\n' +
    '                        </div>\n' +
    '\n' +
    '                    </md-input-container>\n' +
    '\n' +
    '\n' +
    '                </div>\n' +
    '    </form>\n' +
    '\n' +
    '\n' +
    '\n' +
    '</md-content>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('navigation/user-switch/user-switch.tpl.html',
    '<md-dialog aria-label="Switch to user" class="changedialog" ng-cloak>\n' +
    '\n' +
    '    <md-toolbar>\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h2>\n' +
    '                Impersonate user\n' +
    '            </h2>\n' +
    '            <span flex></span>\n' +
    '\n' +
    '            <md-button ng-click="navigation.dialogCancel()" class="md-icon-button">\n' +
    '                <ng-md-icon icon="close" size="24" aria-label="Close dialog"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content class="dialog-content">\n' +
    '\n' +
    '\n' +
    '        <md-content class="md-padding" flex="100">\n' +
    '            <form ng-submit="$event.preventDefault()" layout-align="start stretch" name="switchUserForm" layout="column">\n' +
    '                    <md-input-container>\n' +
    '                        <md-select ng-model="navigation.thisTeam" placeholder="Filter by team (optional)"\n' +
    '                                   wraps-tooltip="library-switch-user" ng-change="navigation.thisUser = undefined">\n' +
    '                            <md-option ng-value="">All</md-option>\n' +
    '                            <md-option ng-value="team.teamId" ng-repeat="team in navigation.teamslist | orderBy:\'teamName\'">\n' +
    '                                {{team.teamName}}\n' +
    '                            </md-option>\n' +
    '                        </md-select>\n' +
    '                    </md-input-container>\n' +
    '                    <md-input-container>\n' +
    '                        <md-select md-autofocus="true" ng-model="navigation.thisUser" placeholder="Select user"\n' +
    '                                   wraps-tooltip="library-switch-user"\n' +
    '                                   ng-change="navigation.checkUser()" >\n' +
    '                            <md-option ng-value="user" ng-repeat="user in navigation.userslist | filter: {Teams: {teamId: navigation.thisTeam}}:(navigation.thisTeam > 0) | orderBy: [\'firstName\',\'lastName\']">\n' +
    '                                {{user.firstName}} {{user.lastName}} - {{user.Roles.name}} - {{user.Divisions.division}}/{{user.Teams.teamName}} {{user.status === 2 ? "(disabled)" : ""}}\n' +
    '                            </md-option>\n' +
    '                        </md-select>\n' +
    '                    </md-input-container>\n' +
    '            </form>\n' +
    '\n' +
    '            <div>\n' +
    '                <p>Please note that some tabs may not be accessible due to user\'s permissions</p>\n' +
    '            </div>\n' +
    '\n' +
    '        </md-content>\n' +
    '\n' +
    '\n' +
    '    </md-dialog-content>\n' +
    '\n' +
    '    <md-dialog-actions>\n' +
    '\n' +
    '        <span flex></span>\n' +
    '        <md-button class="md-primary" ng-click="navigation.dialogCancel()">Cancel</md-button>\n' +
    '        <md-button class="md-raised md-primary" ng-disabled="!navigation.thisUser" ng-click="navigation.switchUser(navigation.thisUser)">Impersonate</md-button>\n' +
    '\n' +
    '    </md-dialog-actions>\n' +
    '</md-dialog>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/workflowdialog/workflowdialog.tpl.html',
    '<md-dialog aria-label="Workflow"  class="workflowdialog"   ng-cloak>\n' +
    '\n' +
    '    <md-toolbar ng-if-start="workflowdialog.type===\'Reject\'">\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h2>Comments - {{workflowdialog.type}}</h2>\n' +
    '            <span flex></span>\n' +
    '\n' +
    '            <md-button ng-click="workflowdialog.dialogCancel(false)" class="md-icon-button">\n' +
    '                <ng-md-icon  icon="close" style="fill: white;"  aria-label="Close dialog"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content  class="dialog-content big" style="">\n' +
    '\n' +
    '        <md-content class="md-padding">\n' +
    '            <form ng-submit="$event.preventDefault()" name="rejectForm" layout="column" style="padding: 20px;">\n' +
    '\n' +
    '\n' +
    '                <div layout="row"  layout-wrap>\n' +
    '\n' +
    '                    <md-input-container flex="60">\n' +
    '                        <label>Rejection Category</label>\n' +
    '                        <md-select ng-model="workflowdialog.reject.category" placeholder="Rejection Category"  required  name="category" ng-change="workflowdialog.reject.reason = undefined">\n' +
    '                            <md-option   ng-value="item.category" ng-repeat="item in workflowdialog.rejectionReasons | unique:\'category\' | orderBy: \'name\'" >{{item.Category.name}}</md-option>\n' +
    '                        </md-select>\n' +
    '                    </md-input-container>\n' +
    '\n' +
    '                    <md-input-container flex="90" ng-if="workflowdialog.reject.category">\n' +
    '                        <label>Rejection Reason</label>\n' +
    '                        <md-select ng-model="workflowdialog.reject.reason" placeholder="Rejection Reason"  required  name="reason" multiple="true">\n' +
    '                            <md-option   ng-value="item.reason" ng-repeat="item in workflowdialog.rejectionReasons | filter: {category: workflowdialog.reject.category} | orderBy: \'name\'" >{{item.Reason.name}}</md-option>\n' +
    '                        </md-select>\n' +
    '                    </md-input-container>\n' +
    '\n' +
    '\n' +
    '                </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                <!--<md-input-container class="md-block">-->\n' +
    '                    <!--<label>Rejection Category</label>-->\n' +
    '                    <!--<md-select name="reason1" ng-model="workflowdialog.reject.category" multiple required>-->\n' +
    '\n' +
    '\n' +
    '\n' +
    '                        <!--<md-option ng-repeat="category in workflowdialog.rfcReasons | filter: { type: \'rfrc\'}" value="{{category.id}}">{{category.name}}</md-option>-->\n' +
    '                    <!--</md-select>-->\n' +
    '\n' +
    '                <!--</md-input-container>-->\n' +
    '\n' +
    '                <!--<md-input-container class="md-block">-->\n' +
    '                    <!--<label>Rejection Reason</label>-->\n' +
    '                    <!--<md-select name="reason2" ng-model="workflowdialog.reject.reason" multiple required>-->\n' +
    '                        <!--<md-option ng-repeat="reason in workflowdialog.rfcReasons | filter: { type: \'rfr\'}" value="{{reason.id}}">{{reason.name}}</md-option>-->\n' +
    '\n' +
    '\n' +
    '                    <!--</md-select>-->\n' +
    '\n' +
    '<!--&lt;!&ndash;{{workflowdialog.reject.reason}}&ndash;&gt;-->\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '                <!--</md-input-container>-->\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '                <div layout="row" layout-wrap>\n' +
    '                    <div flex="70" layout="row" layout-wrap>\n' +
    '                        <div flex="100">\n' +
    '                            <md-input-container class="md-block">\n' +
    '                                <label>Rejection message</label>\n' +
    '                                <textarea name="message" ng-model="workflowdialog.reject.message"   rows="5" required></textarea>\n' +
    '                            </md-input-container>\n' +
    '                            <div class="errors" ng-messages="rejectForm.message.$error" ng-if="rejectForm.$dirty">\n' +
    '                                <div ng-message="required">Required</div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                        <ul class="file-ui">\n' +
    '                            <li ng-repeat="f in workflowdialog.reject.filesList" style="font:smaller" class="file-li animate-if">{{f.name}}\n' +
    '                                <div class="file-prog" ng-mouseenter="f.hovered = true" ng-mouseleave="f.hovered = false">\n' +
    '                                    <!--<md-progress-circular class="animate-if" ng-if="f.determinateValue < 100" md-mode="determinate" value="{{f.determinateValue}}" md-diameter="30"></md-progress-circular>-->\n' +
    '                                    <md-progress-circular class="animate-if" ng-if="!f.loaded" md-mode="indeterminate" md-diameter="30"></md-progress-circular>\n' +
    '                                    <!--<span class="animate-if" ng-if="f.determinateValue < 100" >{{f.determinateValue}}%</span>-->\n' +
    '                                    <ng-md-icon class="animate-if file-complete" ng-if="f.loaded"  icon="check_circle" size="30"  aria-label="Upload complete"></ng-md-icon>\n' +
    '                                    <ng-md-icon class="file-remove" ng-if="f.hovered"  icon="cancel" size="30"  aria-label="Remove file" ng-click="workflowdialog.removeFile(workflowdialog.reject.filesList, $index)"></ng-md-icon>\n' +
    '                                </div>\n' +
    '                            </li>\n' +
    '                        </ul>\n' +
    '                    </div>\n' +
    '                    <div flex="5"></div>\n' +
    '                    <div flex="25">\n' +
    '                        <div ngf-drop ngf-select ng-model="workflowdialog.files" class="drop-box"\n' +
    '                             ngf-drag-over-class="\'dragover\'" ngf-multiple="true" ngf-allow-dir="true"\n' +
    '                             accept="{{workflowdialog.safeFileList}}"\n' +
    '                             ngf-pattern="workflowdialog.safeFileList">\n' +
    '                            <ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop files here or<br /><strong style="text-decoration: underline;">Click to upload</strong>\n' +
    '                        </div>\n' +
    '                        <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                    </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                </div>\n' +
    '\n' +
    '\n' +
    '            </form>\n' +
    '\n' +
    '        </md-content>\n' +
    '\n' +
    '    </md-dialog-content>\n' +
    '\n' +
    '    <md-dialog-actions ng-if-end>\n' +
    '        <span flex></span>\n' +
    '        <md-button class="md-grey"  ng-click="workflowdialog.dialogCancel(false)">Cancel<ng-md-icon icon="close" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '        <md-button  ng-disabled="(workflowdialog.isLoading || rejectForm.$invalid)" class="md-green"  ng-click="workflowdialog.saveReject(workflowdialog.reject);">Submit Rejection<ng-md-icon icon="done" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '\n' +
    '    </md-dialog-actions>\n' +
    '\n' +
    '</md-dialog>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/swapteamuserdialog/swapteamuserdialog.tpl.html',
    '<md-dialog aria-label="Swpa User in Team" style="min-width:600px; overflow:hidden;"  ng-cloak>\n' +
    '\n' +
    '    <md-toolbar>\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h2>Swap User in Team</h2>\n' +
    '            <span flex></span>\n' +
    '\n' +
    '            <md-button ng-click="swapteamuserdialog.dialogCancel(false)" class="md-icon-button">\n' +
    '                <ng-md-icon  icon="close" style="fill: white;"  aria-label="Close dialog"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content class="dialog-content" style="">\n' +
    '        <div style="padding: 20px 20px;">\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <div ng-repeat="item in swapteamuserdialog.approvalList">\n' +
    '                <h3 style="margin: 20px 0 10px;padding: 0;" ng-if="swapteamuserdialog.approvalList[$index].content != swapteamuserdialog.approvalList[$index-1].content">{{item.labels.type}}</h3>\n' +
    '\n' +
    '                <div layout="row" style="font-size: 14px; color:#666; ">\n' +
    '                    <div flex="15" class="statbar-mini">\n' +
    '                        <span class="outer">\n' +
    '                            <span class="inner  grey"></span>\n' +
    '                            <span class="label ">{{item.labels.title}}</span>\n' +
    '                        </span>\n' +
    '                    </div>\n' +
    '                    <!--<div style="padding: 6px 0;" flex="30">{{item.labels.team}}</div>-->\n' +
    '\n' +
    '\n' +
    '                    <div ng-if="!swapteamuserdialog.canChangeTeam(item)"  style="padding: 6px 0;" flex="50">{{item.labels.team}}</div>\n' +
    '                    <div ng-if="swapteamuserdialog.canChangeTeam(item)" style="padding: 0px 0;" flex="50">\n' +
    '\n' +
    '                        <md-select ng-model="item.team" placeholder="Select team" style="font-size: 14px; margin: -2px 0 0 0;"  >\n' +
    '                            <md-option ng-value="team.teamId" ng-repeat="team in swapteamuserdialog.teams  | orderBy: [\'team\'] " >{{team.teamName}}</md-option>\n' +
    '                        </md-select>\n' +
    '\n' +
    '                    </div>\n' +
    '\n' +
    '\n' +
    '                    <div ng-if="!swapteamuserdialog.canChangeTeam(item)"  style="padding: 6px 0;" flex="50">{{item.labels.user}}</div>\n' +
    '                    <div ng-if="swapteamuserdialog.canChangeTeam(item)" style="padding: 0px 0;" flex="50">\n' +
    '\n' +
    '                        <md-select ng-model="item.user" placeholder="Select user" style="font-size: 14px; margin: -2px 0 0 0;"  >\n' +
    '                            <md-option ng-value="undefined">All</md-option>\n' +
    '                            <md-option ng-disabled="swapteamuserdialog.isMcoAsApprover(user,item,swapteamuserdialog.mco);" ng-value="user.id" ng-repeat="user in swapteamuserdialog.users | filter:{ teamId: item.team } | orderBy: [\'firstName\', \'lastName\'] " >{{user.firstName}} {{user.lastName}}</md-option>\n' +
    '                        </md-select>\n' +
    '\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions layout="row">\n' +
    '        <span flex></span>\n' +
    '\n' +
    '        <md-button ng-disabled="swapteamuserdialog.checkForChanges() || swapteamuserdialog.isLoading" class="md-primary md-raised"  ng-click="swapteamuserdialog.saveWorkflow()">Save</md-button>\n' +
    '\n' +
    '\n' +
    '\n' +
    '    </md-dialog-actions>\n' +
    '</md-dialog>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/notifications/notifications.tpl.html',
    '<md-dialog aria-label="Black Assets"  class="notifications">\n' +
    '\n' +
    '    <md-toolbar>\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h2 wraps-tooltip="dialog-notifications-title">Notifications</h2>\n' +
    '            <span flex></span>\n' +
    '\n' +
    '            <md-button aria-label="Refresh notifications" class="md-icon-button" ng-click="notifications.initNotifications()" wraps-tooltip="notifications-refresh-icon">\n' +
    '                <ng-md-icon icon="refresh" style="fill: white;" size="24"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button aria-label="Download CSV" class="md-icon-button" ng-csv="notifications.downloadCSV()"  lazy-load="true" filename="actions-report.csv" add-bom="true" charset="utf-8" csv-label="true">\n' +
    '                <ng-md-icon icon="file_download" size="24" wraps-tooltip="notifications-download-csv"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button ng-click="notifications.dialogCancel(false);" class="md-icon-button" wraps-tooltip="dialog-notifications-close">\n' +
    '                <ng-md-icon  icon="close" style="fill: white;" size="24"  aria-label="Close dialog"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content class="dialog-content big" style="">\n' +
    '\n' +
    '        <md-content class="md-padding">\n' +
    '\n' +
    '            <md-tabs md-dynamic-height md-border-bottom md-selected="notifications.activeContent">\n' +
    '                <md-tab>\n' +
    '					<md-tab-label><span wraps-tooltip="dialog-notifications-tab-black-assets">Black Assets ({{notifications.count.blackAssetsByOwner.blackAssets}})</span></md-tab-label>\n' +
    '                    <md-tab-body>\n' +
    '						<md-progress-linear ng-if="notifications.isLoading" md-mode="indeterminate"></md-progress-linear>\n' +
    '                        <md-data-table-container ng-if="notifications.list.blackAssetsByOwner">\n' +
    '                            <table md-data-table  > <!--md-row-select="selected"-->\n' +
    '                                <thead  md-order="notifications.list.blackAssetsByOwner.order" ng-if="notifications.list.blackAssetsByOwner.length > 0">\n' +
    '                                <tr wraps-tooltip="dialog-notifications-heading-black-assets">\n' +
    '                                    <th></th>\n' +
    '                                    <th order-by="bookId">ID</th>\n' +
    '                                    <th order-by="bookName">Book Name</th>\n' +
    '                                    <th order-by="bookCode">Code</th>\n' +
    '                                    <th order-by="lastUpdated">Last Updated</th>\n' +
    '                                    <th order-by="docTypeName">Doc Type</th>\n' +
    '                                    <th order-by="expiryDate">Expiry Date</th>\n' +
    '                                    <th order-by="earlyExpiryDate">Early Expiry Date</th>\n' +
    '                                    <th></th>\n' +
    '                                </tr>\n' +
    '                                </thead>\n' +
    '                                <tbody>\n' +
    '                                <tr ng-repeat="blackAsset in notifications.list.blackAssetsByOwner | orderBy: notifications.list.blackAssetsByOwner.order" class="data-rows" ng-click="notifications.dialogCancel(false); notifications.openDocument(blackAsset.bookId)"  wraps-tooltip="dialog-notifications-row-black-assets">\n' +
    '                                    <td></td>\n' +
    '                                    <td>{{blackAsset.bookId}}</td>\n' +
    '                                    <td>{{blackAsset.bookName}}</td>\n' +
    '                                    <td>{{blackAsset.bookCode}}</td>\n' +
    '                                    <td>{{blackAsset.lastUpdated | date:\'dd/MM/yyyy\'}}</td>\n' +
    '                                    <td>{{blackAsset.DocTypes.docTypeName}}</td>\n' +
    '                                    <td>{{blackAsset.expiryDate | date:\'dd/MM/yyyy\'}}</td>\n' +
    '                                    <td>{{blackAsset.earlyExpiryDate | date:\'dd/MM/yyyy\'}}</td>\n' +
    '                                    <td></td>\n' +
    '                                </tr>\n' +
    '                                </tbody>\n' +
    '                            </table>\n' +
    '                        </md-data-table-container>\n' +
    '						<p ng-if="(!notifications.list.blackAssetsByOwner || notifications.list.blackAssetsByOwner.length == 0) && !notifications.isLoading" wraps-tooltip="dialog-notifications-no-black-assets">You have no black assets</p>\n' +
    '                    </md-tab-body>\n' +
    '                </md-tab>\n' +
    '\n' +
    '\n' +
    '                <md-tab>\n' +
    '					<md-tab-label><span wraps-tooltip="dialog-notifications-tab-actions-user">Personal Actions ({{notifications.count.documentsWithActionsByOwnerPersonal.actions}})</span></md-tab-label>\n' +
    '                    <md-tab-body>\n' +
    '						<md-progress-linear ng-if="notifications.isLoading" md-mode="indeterminate"></md-progress-linear>\n' +
    '\n' +
    '                        <md-data-table-container ng-if="notifications.list.documentsWithActionsByOwnerPersonal">\n' +
    '\n' +
    '\n' +
    '                            <table md-data-table  > <!--md-row-select="selected"-->\n' +
    '                                <thead  md-order="notifications.list.documentsWithActionsByOwnerPersonal.order" ng-if="notifications.list.documentsWithActionsByOwnerPersonal.length > 0">\n' +
    '                                <tr wraps-tooltip="dialog-notifications-heading-actions-user">\n' +
    '                                    <th></th>\n' +
    '\n' +
    '                                    <th order-by="bookId">ID</th>\n' +
    '                                    <th order-by="bookName">Book Name</th>\n' +
    '                                    <th order-by="bookCode">Book Code</th>\n' +
    '                                    <th order-by="lastUpdated">Last Updated</th>\n' +
    '                                    <th order-by="docTypeName">Doc Type</th>\n' +
    '                                    <th order-by="currentWorkflowRole">Workflow Role</th>\n' +
    '                                    <th order-by="currentWorkflowContent">Workflow Content</th>\n' +
    '                                    <!--<th order-by="currentWorkflowUser">Workflow User</th>-->\n' +
    '                                    <th order-by="currentWorkflowTeam">Workflow Team</th>\n' +
    '\n' +
    '                                    <th></th>\n' +
    '                                </tr>\n' +
    '                                </thead>\n' +
    '                                <tbody>\n' +
    '                                <tr ng-repeat="action in notifications.list.documentsWithActionsByOwnerPersonal | orderBy: notifications.list.documentsWithActionsByOwnerPersonal.order" class="data-rows" ng-click="notifications.dialogCancel(false); notifications.openDocument(action.bookId)" wraps-tooltip="dialog-notifications-row-actions-user">\n' +
    '                                    <td></td>\n' +
    '                                    <td>{{action.bookId}}</td>\n' +
    '                                    <td>{{action.bookName}}</td>\n' +
    '                                    <td>{{action.bookCode}}</td>\n' +
    '                                    <td>{{action.lastUpdated | date: \'dd/MM/yyyy\'}}</td>\n' +
    '                                    <td>{{action.DocTypes.docTypeName}}</td>\n' +
    '                                    <td>{{action.workflowStatus}}</td>\n' +
    '                                    <td>{{action.currentWorkflow.content | uppercase}}</td>\n' +
    '                                    <!--<td>{{action.currentWorkflowUser}}</td>-->\n' +
    '                                    <td>{{action.currentWorkflowTeamName}}</td>\n' +
    '                                    <td></td>\n' +
    '                                </tr>\n' +
    '                                </tbody>\n' +
    '                            </table>\n' +
    '                        </md-data-table-container>\n' +
    '						<p ng-if="(!notifications.list.documentsWithActionsByOwnerPersonal || notifications.list.documentsWithActionsByOwnerPersonal.length == 0) && !notifications.isLoading" wraps-tooltip="dialog-notifications-no-actions-user">You have no personal actions</p>\n' +
    '                    </md-tab-body>\n' +
    '                </md-tab>\n' +
    '\n' +
    '\n' +
    '                <md-tab>\n' +
    '					<md-tab-label><span wraps-tooltip="dialog-notifications-tab-actions-team">Team Actions ({{notifications.count.documentsWithActionsByOwnerTeam.actions}})</span></md-tab-label>\n' +
    '                    <md-tab-body>\n' +
    '						<md-progress-linear ng-if="notifications.isLoading" md-mode="indeterminate"></md-progress-linear>\n' +
    '\n' +
    '                        <md-data-table-container ng-if="notifications.list.documentsWithActionsByOwnerTeam">\n' +
    '                            <table md-data-table  > <!--md-row-select="selected"-->\n' +
    '                                <thead  md-order="notifications.list.documentsWithActionsByOwnerTeam.order" ng-if="notifications.list.documentsWithActionsByOwnerTeam.length > 0">\n' +
    '                                <tr wraps-tooltip="dialog-notifications-heading-actions-team">\n' +
    '                                    <th></th>\n' +
    '\n' +
    '                                    <th order-by="bookId">ID</th>\n' +
    '                                    <th order-by="bookName">Book Name</th>\n' +
    '                                    <th order-by="bookCode">Book Code</th>\n' +
    '                                    <th order-by="lastUpdated">Last Updated</th>\n' +
    '                                    <th order-by="docTypeName">Doc Type</th>\n' +
    '                                    <th order-by="currentWorkflowRole">Workflow Role</th>\n' +
    '                                    <th order-by="currentWorkflowContent">Workflow Content</th>\n' +
    '                                    <th order-by="currentWorkflowTeam">Workflow Team</th>\n' +
    '\n' +
    '                                    <th></th>\n' +
    '                                </tr>\n' +
    '                                </thead>\n' +
    '                                <tbody>\n' +
    '                                <tr ng-repeat="action in notifications.list.documentsWithActionsByOwnerTeam | orderBy: notifications.list.documentsWithActionsByOwnerTeam.order" class="data-rows" ng-click="notifications.dialogCancel(false); notifications.openDocument(action.bookId)" wraps-tooltip="dialog-notifications-row-actions-team">\n' +
    '                                    <td></td>\n' +
    '                                    <td>{{action.bookId}}</td>\n' +
    '                                    <td>{{action.bookName}}</td>\n' +
    '                                    <td>{{action.bookCode}}</td>\n' +
    '                                    <td>{{action.lastUpdated | date:\'dd/MM/yyyy\'}}</td>\n' +
    '                                    <td>{{action.DocTypes.docTypeName}}</td>\n' +
    '                                    <td>{{action.workflowStatus}}</td>\n' +
    '                                    <td>{{action.currentWorkflow.content | uppercase}}</td>\n' +
    '                                    <td>{{action.currentWorkflowTeamName}}</td>\n' +
    '                                    <td></td>\n' +
    '                                </tr>\n' +
    '                                </tbody>\n' +
    '                            </table>\n' +
    '                        </md-data-table-container>\n' +
    '						<p ng-if="(!notifications.list.documentsWithActionsByOwnerTeam || notifications.list.documentsWithActionsByOwnerTeam.length == 0) && !notifications.isLoading" wraps-tooltip="dialog-notifications-no-actions-team">You have no team actions</p>\n' +
    '                    </md-tab-body>\n' +
    '                </md-tab>\n' +
    '\n' +
    '\n' +
    '                <md-tab>\n' +
    '                <md-tab-label><span wraps-tooltip="dialog-notifications-tab-actions-team">In Review ({{notifications.count.documentsInReview.inReview}})</span></md-tab-label>\n' +
    '                <md-tab-body>\n' +
    '                    <md-progress-linear ng-if="notifications.isLoading" md-mode="indeterminate"></md-progress-linear>\n' +
    '\n' +
    '                    <md-data-table-container ng-if="notifications.list.documentsInReview">\n' +
    '                        <table md-data-table  > <!--md-row-select="selected"-->\n' +
    '                            <thead  md-order="notifications.list.documentsInReview.order" ng-if="notifications.list.documentsInReview.length > 0">\n' +
    '                            <tr wraps-tooltip="dialog-notifications-heading-actions-team">\n' +
    '                                <th></th>\n' +
    '\n' +
    '                                <th order-by="bookId">ID</th>\n' +
    '                                <th order-by="bookName">Book Name</th>\n' +
    '                                <th order-by="bookCode">Book Code</th>\n' +
    '                                <th order-by="docTypeName">Doc Type</th>\n' +
    '                                <th order-by="reviewDate">Review Date</th>\n' +
    '                                <th order-by="expiryDate">Expiry Date</th>\n' +
    '                                <th order-by="expiryDate">Days until Expiry</th>\n' +
    '                            </tr>\n' +
    '                            </thead>\n' +
    '                            <tbody>\n' +
    '                            <tr ng-repeat="action in notifications.list.documentsInReview | orderBy: notifications.list.documentsInReview.order" class="data-rows" ng-click="notifications.dialogCancel(false); notifications.openDocument(action.bookId)" wraps-tooltip="dialog-notifications-row-actions-team">\n' +
    '                                <td></td>\n' +
    '                                <td>{{action.bookId}}</td>\n' +
    '                                <td>{{action.bookName}}</td>\n' +
    '                                <td>{{action.bookCode}}</td>\n' +
    '                                <td>{{action.DocTypes.docTypeName}}</td>\n' +
    '                                <td>{{action.reviewDate | date: \'dd/MM/yyyy\'}}</td>\n' +
    '                                <td>{{action.expiryDate | date: \'dd/MM/yyyy\'}}</td>\n' +
    '                                <td>{{action.reviewCountdown}}</td>\n' +
    '                            </tr>\n' +
    '                            </tbody>\n' +
    '                        </table>\n' +
    '                    </md-data-table-container>\n' +
    '                    <p ng-if="(!notifications.list.documentsInReview || notifications.list.documentsInReview.length == 0) && !notifications.isLoading" wraps-tooltip="dialog-notifications-no-actions-team">You have no documents in review</p>\n' +
    '                </md-tab-body>\n' +
    '            </md-tab>\n' +
    '            </md-tabs>\n' +
    '\n' +
    '\n' +
    '        </md-tabs>\n' +
    '\n' +
    '            <md-progress-circular   md-diameter="40" md-mode="indeterminate" ng-show="pushdialog.isLoading"></md-progress-circular>\n' +
    '\n' +
    '        </md-content>\n' +
    '\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions layout="row">\n' +
    '        <span flex></span>\n' +
    '        <md-button class="md-primary md-raised md-button"  ng-click="notifications.dialogCancel(false)" wraps-tooltip="dialog-notifications-close">Close</md-button>\n' +
    '    </md-dialog-actions>\n' +
    '</md-dialog>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/pushdialog/pushdialog.tpl.html',
    '<md-dialog aria-label="Push Changes"  class="pushdialog">\n' +
    '\n' +
    '    <md-toolbar>\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h2>Push Changes</h2>\n' +
    '            <span flex></span>\n' +
    '\n' +
    '            <md-button ng-click="pushdialog.dialogCancel(false)" class="md-icon-button">\n' +
    '                <ng-md-icon  icon="close" style="fill: white;" size="24"  aria-label="Close dialog"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content class="dialog-content" style="">\n' +
    '\n' +
    '        <md-content class="md-padding">\n' +
    '\n' +
    '\n' +
    '            <div ng-show="::pushdialog.contentlist">\n' +
    '                <h3>Select books to push changes to:</h3>\n' +
    '\n' +
    '                <md-input-container  style="margin:20px;">\n' +
    '                    <label><ng-md-icon icon="search" style="fill: #ccc; position: relative; padding-left: 2px; vertical-align:middle" size="20" wraps-tooltip="search-button"></ng-md-icon>  Filter book name</label>\n' +
    '                    <input ng-model="pushdialog.filter.Books.bookCode" aria-label="Filter book name">\n' +
    '                </md-input-container>\n' +
    '\n' +
    '                <div ng-repeat="content in pushdialog.contentlist | filter: pushdialog.filter | orderBy: \'Books.bookId\'" ng-if="content.structureId != pushdialog.activeContent.structureId" ng-init="content.selected = (content.versionId === pushdialog.activeContent.versionId ? content.structureId : false )">\n' +
    '                    <md-checkbox style="margin: 10px;" ng-model="content.selected" ng-true-value="{{::content.structureId}}"  ng-false-value="{{false}}"  ng-disabled="content.versionId === pushdialog.activeContent.versionId || (content.Books.currentWorkflowStatus.role != 1 && content.Books.currentWorkflowStatus.role != 2) "  ng-change="pushdialog.setSelected(content)" >\n' +
    '                        <span>{{::content.Books.bookCode}} - <small>{{content.Books.workflowStatus}} - {{content.Books.documentStatus}}</small></span>\n' +
    '\n' +
    '                        <get-contents item="content"></get-contents>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                    </md-checkbox>\n' +
    '                    <md-divider></md-divider>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <md-progress-circular   md-diameter="40" md-mode="indeterminate" ng-show="pushdialog.isLoading"></md-progress-circular>\n' +
    '\n' +
    '        </md-content>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions layout="row">\n' +
    '        <span flex></span>\n' +
    '\n' +
    '        <md-button class="md-primary" ng-click="pushdialog.dialogCancel(false)">Cancel</md-button>\n' +
    '\n' +
    '        <md-button  class="md-primary md-raised" ng-disabled="(pushdialog.isLoading || pushdialog.selected.length===0)" ng-click="pushdialog.pushContent(pushdialog.selected)">Push</md-button>\n' +
    '\n' +
    '    </md-dialog-actions>\n' +
    '</md-dialog>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/metachangedialog/metachangedialog.tpl.html',
    '<md-dialog aria-label="Document info changes"  class="docdiffdialog">\n' +
    '\n' +
    '    <md-toolbar>\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h2>Document Info Changes</h2>\n' +
    '            <span flex></span>\n' +
    '\n' +
    '            <md-button ng-click="metachangedialog.dialogCancel(false)" class="md-icon-button">\n' +
    '                <ng-md-icon  icon="close" size="24"  aria-label="Close dialog"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content class="dialog-content">\n' +
    '\n' +
    '\n' +
    '        <md-content flex layout-padding>\n' +
    '            <div flex layout="column" style="max-width:1000px; min-width: 800px; font-size: 14px;" class="changes-col">\n' +
    '              <div layout="row" flex="100" layout-wrap style="padding:8px 6px; color:#aaa; font-weight: bold;">\n' +
    '                <div flex="20" style="text-align:left;">Field</div>\n' +
    '                <div flex="40" style="text-align:left;">Old</div>\n' +
    '                <div flex="40" style="text-align:left;">New</div>\n' +
    '              </div>\n' +
    '\n' +
    '\n' +
    '              <div layout="row" flex="100" layout-wrap ng-repeat="change in metachangedialog.change" style="border-top:1px #eee solid; padding:4px 6px; margin-bottom: 10px;">\n' +
    '                  <div flex="20"  style=" padding-right:10px;" layout-wrap layout="row" flex ><p style="font-weight: bold;" >{{change.label}}:</p></div>\n' +
    '                  <div flex="40"  style=" padding-right:10px;color: #999;" layout-wrap layout="row">\n' +
    '                    <p ng-if="change.type === \'string\'" flex ng-bind-html="change.lhs" style="width:100%"></p>\n' +
    '\n' +
    '                    <ul ng-if="change.type === \'array\'" style="width:100%">\n' +
    '                      <li flex  ng-repeat="(key, value) in change.lhs track by $index">{{value}}</li>\n' +
    '                    </ul>\n' +
    '\n' +
    '                    <ul ng-if="change.type === \'workflow\'" ng-repeat="(key, value) in change.lhs track by $index" style="width:100%">\n' +
    '                      <lh  style="margin-left: -20px; font-size: smaller;">{{key | uppercase}}</lh>\n' +
    '                      <li ng-repeat="(k, v) in value track by $index" >{{v.role}} - {{v.team}}/{{v.user}}</li>\n' +
    '                    </ul>\n' +
    '                  </div>\n' +
    '                  <div flex="40"  style=" padding-right:10px;" layout-wrap layout="row">\n' +
    '                    <p ng-if="change.type === \'string\'" flex ng-bind-html="change.rhs" style="width:100%"></p>\n' +
    '\n' +
    '                    <ul ng-if="change.type === \'array\'" style="width:100%">\n' +
    '                      <li flex  ng-repeat="(key, value) in change.rhs track by $index">{{value}}</li>\n' +
    '                    </ul>\n' +
    '\n' +
    '                    <ul ng-if="change.type === \'workflow\'" ng-repeat="(key, value) in change.rhs track by $index" style="width:100%">\n' +
    '                      <lh  style="margin-left: -20px; font-size: smaller;">{{key | uppercase}}</lh>\n' +
    '                      <li ng-repeat="(k, v) in value track by $index" >{{v.role}} - {{v.team}}/{{v.user}}</li>\n' +
    '                    </ul>\n' +
    '\n' +
    '                  </div>\n' +
    '              </div>\n' +
    '\n' +
    '\n' +
    '            </div>\n' +
    '        </md-content>\n' +
    '\n' +
    '\n' +
    '    </md-dialog-content>\n' +
    '\n' +
    '</md-dialog>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-directives/library-wcr.tpl.html',
    '<md-list-item layout="row" flex="100" style="margin-bottom: 30px;" layout-wrap ng-repeat="change in book.wcrChanges | orderBy:\'order\'">\n' +
    '\n' +
    '    <div layout="row" flex="100" layout-wrap style="padding:6px; background:#bbb;">\n' +
    '        <div flex="100" style="text-align:left; color: #fff; font-size: 14px;" ng-bind-html="change.location"></div>\n' +
    '    </div>\n' +
    '    <div layout="row" flex="100" layout-wrap style="padding:4px 0; color:#aaa;">\n' +
    '        <div flex="45" style="text-align:center;">Old Wording</div>\n' +
    '        <div flex="45" style="text-align:center;">New Wording</div>\n' +
    '        <div flex="10" style="text-align:center;">Actions</div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div layout="row" flex="100" layout-wrap style="border-top:1px #eee solid; " class="changes-col">\n' +
    '\n' +
    '        <div ng-class="[change.contentTypeName]" ng-if="change.contentTypeId <= 4" flex="45" style="color: #999; padding-right:10px;"  ng-bind-html="change.versionData1"  ></div>\n' +
    '\n' +
    '        <div ng-class="[change.contentTypeName]" ng-if="change.contentTypeId <= 4" flex="45"  style=" padding-right:10px;"  ng-bind-html="change.versionData2"  ></div>\n' +
    '\n' +
    '\n' +
    '        <!-- Benefits-->\n' +
    '        <div ng-if="change.contentTypeId === 5" flex="90" layout-wrap layout="row" style="" class="benefit-changes" >\n' +
    '            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">\n' +
    '                <h5>Name:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.benefit.name)"></p>\n' +
    '            </div>\n' +
    '            <div flex="50"  style=" padding-right:10px;"   layout-wrap layout="row">\n' +
    '                <h5>Name:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.benefit.name)"></p>\n' +
    '            </div>\n' +
    '\n' +
    '            <div flex="50"  style=" padding-right:10px;color: #999;" ng-show="book.levels.level1.active === true" layout-wrap layout="row">\n' +
    '                <h5>{{book.levels.level1.name}}:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.benefit.level1)"></p>\n' +
    '            </div>\n' +
    '            <div flex="50"  style=" padding-right:10px;" ng-show="book.levels.level1.active === true" layout-wrap layout="row">\n' +
    '                <h5>{{book.levels.level1.name}}:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.benefit.level1)"></p>\n' +
    '            </div>\n' +
    '\n' +
    '            <div flex="50"  style=" padding-right:10px;color: #999;" ng-show="book.levels.level2.active === true" layout-wrap layout="row">\n' +
    '                <h5>{{book.levels.level2.name}}:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.benefit.level2)"></p>\n' +
    '            </div>\n' +
    '            <div flex="50"  style=" padding-right:10px;" ng-show="book.levels.level2.active === true" layout-wrap layout="row">\n' +
    '                <h5>{{book.levels.level2.name}}:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.benefit.level2)"></p>\n' +
    '            </div>\n' +
    '\n' +
    '            <div flex="50"  style=" padding-right:10px;color: #999;" ng-show="book.levels.level3.active === true" layout-wrap layout="row">\n' +
    '                <h5>{{book.levels.level3.name}}:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.benefit.level3)"></p>\n' +
    '            </div>\n' +
    '            <div flex="50"  style=" padding-right:10px;" ng-show="book.levels.level3.active === true" layout-wrap layout="row">\n' +
    '                <h5>{{book.levels.level3.name}}:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.benefit.level3)"></p>\n' +
    '            </div>\n' +
    '\n' +
    '            <div flex="50"  style=" padding-right:10px;color: #999;" ng-show="book.levels.level4.active === true" layout-wrap layout="row" >\n' +
    '                <h5>{{book.levels.level4.name}}:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.benefit.level4)"></p>\n' +
    '            </div>\n' +
    '            <div flex="50"  style=" padding-right:10px;" ng-show="book.levels.level4.active === true" layout-wrap layout="row">\n' +
    '                <h5>{{book.levels.level4.name}}:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.benefit.level4)"></p>\n' +
    '            </div>\n' +
    '\n' +
    '            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">\n' +
    '                <h5>Explanation:</h5><div flex ng-bind-html="change.json1.benefit.explanation"></div>\n' +
    '            </div>\n' +
    '            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">\n' +
    '                <h5>Explanation:</h5><div flex ng-bind-html="change.json2.benefit.explanation"></div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <!--Glossary-->\n' +
    '        <div ng-if="change.contentTypeId === 6" flex="90" layout-wrap layout="row" style="" class="benefit-changes" >\n' +
    '\n' +
    '            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">\n' +
    '                <h5>Term:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.glossary.term)"></p>\n' +
    '            </div>\n' +
    '            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">\n' +
    '                <h5>Term:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.glossary.term)"></p>\n' +
    '            </div>\n' +
    '\n' +
    '            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">\n' +
    '                <h5>Description:</h5><div flex ng-bind-html="change.json1.glossary.description"></div>\n' +
    '            </div>\n' +
    '            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">\n' +
    '                <h5>Description:</h5><div flex ng-bind-html="change.json2.glossary.description"></div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">\n' +
    '                <h5>Words:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.glossary.words)"></p>\n' +
    '            </div>\n' +
    '            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">\n' +
    '                <h5>Words:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.glossary.words)"></p>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <!--Exclusion-->\n' +
    '        <div ng-if="change.contentTypeId === 7" flex="90" layout-wrap layout="row" style="" class="benefit-changes" >\n' +
    '\n' +
    '            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">\n' +
    '                <h5>Name:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.exclusion.name)"></p>\n' +
    '            </div>\n' +
    '            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">\n' +
    '                <h5>Name:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.exclusion.name)"></p>\n' +
    '            </div>\n' +
    '\n' +
    '            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">\n' +
    '                <h5>Rules:</h5><div flex ng-bind-html="change.json1.exclusion.rules"></div>\n' +
    '            </div>\n' +
    '            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">\n' +
    '                <h5>Rules:</h5><div flex ng-bind-html="change.json2.exclusion.rules"></div>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <!--Medical-->\n' +
    '        <div ng-if="change.contentTypeId === 8" flex="90" layout-wrap layout="row" style="" class="benefit-changes" >\n' +
    '\n' +
    '            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">\n' +
    '                <h5>Term:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.medical.term)"></p>\n' +
    '            </div>\n' +
    '            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">\n' +
    '                <h5>Term:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.medical.term)"></p>\n' +
    '            </div>\n' +
    '\n' +
    '            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">\n' +
    '                <h5>Description:</h5><div flex ng-bind-html="change.json1.medical.description"></div>\n' +
    '            </div>\n' +
    '            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">\n' +
    '                <h5>Description:</h5><div flex ng-bind-html="change.json2.medical.description"></div>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '        <!--Table Header-->\n' +
    '        <div ng-if="change.contentTypeId === 11" flex="90" layout-wrap layout="row" style="" class="benefit-changes" >\n' +
    '\n' +
    '\n' +
    '            <div flex="100"   layout-wrap layout="row"  ng-repeat="col in change.json2.tableHeader">\n' +
    '                <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row" >\n' +
    '                    <h5>Column {{$index + 1}}:</h5><p flex ng-bind-html="change.json1.tableHeader[$index].name"></p>\n' +
    '                </div>\n' +
    '                <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row" >\n' +
    '                    <h5>Column {{$index + 1}}:</h5><p flex ng-bind-html="col.name"></p>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '        <!--Table Row-->\n' +
    '        <div ng-if="change.contentTypeId === 12" flex="90" layout-wrap layout="row" style="" class="benefit-changes" >\n' +
    '\n' +
    '\n' +
    '            <!--<pre>-->\n' +
    '                <!--{{change | json }}-->\n' +
    '            <!--</pre>-->\n' +
    '\n' +
    '            <div flex="100"   layout-wrap layout="row"  ng-repeat="col in change.json2.tableRow">\n' +
    '                <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row" >\n' +
    '                    <h5>Column {{$index + 1}}:</h5><p flex ng-bind-html="change.json1.tableRow[$index].data"></p>\n' +
    '                </div>\n' +
    '                <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row" >\n' +
    '                    <h5>Column {{$index + 1}}:</h5><p flex ng-bind-html="col.data"></p>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <!--BGHP -->\n' +
    '        <div ng-if="change.contentTypeId === 14 || change.contentTypeId === 15 || change.contentTypeId === 16 || change.contentTypeId === 18 || change.contentTypeId === 19 || change.contentTypeId === 21" flex="90" layout-wrap layout="row" style="" class="benefit-changes" >\n' +
    '\n' +
    '            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">\n' +
    '                <div flex ng-bind-html="change.versionData1" ></div>\n' +
    '            </div>\n' +
    '            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">\n' +
    '                <div flex ng-bind-html="change.versionData2" ></div>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <div ng-if="change.contentTypeId === 17" flex="90" layout-wrap layout="row" style="" class="benefit-changes" >\n' +
    '\n' +
    '            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">\n' +
    '                <h5>Name:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.benefit.name)"></p>\n' +
    '            </div>\n' +
    '            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">\n' +
    '                <h5>Name:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.benefit.name)"></p>\n' +
    '            </div>\n' +
    '\n' +
    '            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">\n' +
    '                <h5>Explanation:</h5><div flex ng-bind-html="change.json1.benefit.explanation"></div>\n' +
    '            </div>\n' +
    '            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">\n' +
    '                <h5>Explanation:</h5><div flex ng-bind-html="change.json2.benefit.explanation"></div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">\n' +
    '                <h5>Limit:</h5><p flex ng-bind-html="change.json1.benefit.limit"></p>\n' +
    '            </div>\n' +
    '            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">\n' +
    '                <h5>Limit:</h5><p flex ng-bind-html="change.json2.benefit.limit"></p>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '        <div ng-if="change.contentTypeId === 20" flex="90" layout-wrap layout="row" style="" class="benefit-changes" >\n' +
    '\n' +
    '            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">\n' +
    '                <h5>Name:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json1.exclusion.name)"></p>\n' +
    '            </div>\n' +
    '            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">\n' +
    '                <h5>Name:</h5><p flex ng-bind-html="tabBook.library.nl2br(change.json2.exclusion.name)"></p>\n' +
    '            </div>\n' +
    '\n' +
    '            <div flex="50"  style=" padding-right:10px;color: #999;"  layout-wrap layout="row">\n' +
    '                <h5>Rules:</h5><div flex ng-bind-html="change.json1.exclusion.rules"></div>\n' +
    '            </div>\n' +
    '            <div flex="50"  style=" padding-right:10px;"  layout-wrap layout="row">\n' +
    '                <h5>Rules:</h5><div flex ng-bind-html="change.json2.exclusion.rules"></div>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '        <div flex="10">\n' +
    '            <md-button   flex="50" wraps-tooltip="action-icon"  class="md-icon-button" style="margin:0 -6px;" ng-click="tabBook.library.openDocDiffDialog(change)"  aria-label="alert">\n' +
    '                <ng-md-icon icon="info" style="fill: #ccc; position: relative;" size="24"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '\n' +
    '<span ng-if="change.versionIdNew != 0">\n' +
    '            <md-button flex="50"  ng-click="tabBook.library.openWorkflowDialog(tabBook.library.childByStructureId(book,change.structureId), book, \'Reject\'); $event.stopPropagation()"\n' +
    '                       ng-if="tabBook.library.viewReject(tabBook.library.childByStructureId(book,change.structureId), book); "\n' +
    '                       class="md-icon-button" style="margin:0 -6px;"  aria-label="alert">\n' +
    '                <ng-md-icon  icon="cancel" style="fill: #F44336; position: relative;  " size="24"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button flex="50"  ng-click="tabBook.library.approveContentDialog(tabBook.library.childByStructureId(book,change.structureId),book); $event.stopPropagation()"\n' +
    '                       ng-if="tabBook.library.viewApprove(tabBook.library.childByStructureId(book,change.structureId), book);  "\n' +
    '                       class="md-icon-button" style="margin:0 -6px;"  aria-label="alert">\n' +
    '                <ng-md-icon  icon="check_circle" style="fill: #4CAF50; position: relative;  " size="24"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '</span>\n' +
    '\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '    </div>\n' +
    '    <md-divider></md-divider>\n' +
    '\n' +
    '</md-list-item>\n' +
    '<!--<div layout="row" flex="100" ng-if="tabBook.library.showApproveAll(book)">-->\n' +
    '    <!--<div flex></div><md-button type="button" class="md-green" ng-click="tabBook.library.approveContentDialog(\'all\',book);">approve all <ng-md-icon icon="check_circle" style="fill: white; padding-left:0px; position:relative; top:0px;" size="20"></ng-md-icon></md-button>-->\n' +
    '<!--</div>-->');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-directives/library-row.tpl.html',
    '\n' +
    '<div ng-class="[{ \'selectedChapter\' : child.selected},{\'level1\':child.versions.Contents.ContentTypes.contentTypeName != \'Chapter\'}]" flex="100" layout="row" >\n' +
    '    <div flex="100">\n' +
    '        <div flex ng-class="[child.versions.Contents.ContentTypes.contentTypeName, child.versions.Contents.Departments.departmentName, {\'changed\':child.History[0].versions.versiondata} ]" ng-bind-html="child.versions.versiondata"  ng-click="child.selected = library.toggle(child);"></div>\n' +
    '    </div>\n' +
    '</div>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-directives/library-rightMenu.tpl.html',
    '<form name="documentInfoForm" layout="column">\n' +
    '    <md-toolbar class="md-theme-light">\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h1 flex class="md-title" wraps-tooltip="doc-man-title">Document Management</h1>\n' +
    '            <md-button ng-click="library.closeRightMenu()" class="md-icon-button" aria-label="Close right pane">\n' +
    '                <ng-md-icon icon="close" size="24"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '        <!--<pre>{{documentInfoForm.insurerId | json}}</pre>-->\n' +
    '    <div layout="row" layout-align="end start" layout-padding>\n' +
    '        <div>\n' +
    '\n' +
    '            <md-button  type="button"  ng-click="library.closeRightMenu()">Cancel</md-button>\n' +
    '            <md-button\n' +
    '                    ng-if="(!library.disableBookEdit || (library.isOwner(library.selected.book)   && library.selected.book.status === 1))"\n' +
    '                    type="button"\n' +
    '                    class="md-raised md-primary"\n' +
    '                    ng-disabled="\n' +
    '                        library.isLoading ||\n' +
    '                        (\n' +
    '                        library.selected.book.checkCode ||\n' +
    '                        library.selected.book.isWorkflowInvalid ||\n' +
    '                        !(library.selected.book.finProm) ||\n' +
    '                        library.checkBlank(library.selected.book.bookName) ||\n' +
    '                        library.checkBlank(library.selected.book.bookCode) ||\n' +
    '                        library.checkBlank(library.selected.book.DocTypes) ||\n' +
    '                        library.checkBlank(library.selected.book.insurerId) ||\n' +
    '                        library.checkBlank(library.selected.book.productId) ||\n' +
    '                        library.checkBlank(library.selected.book.productTypeId) ||\n' +
    '                        library.checkBlank(library.selected.book.regionId) ||\n' +
    '                        library.checkBlank(library.selected.book.audienceId) ||\n' +
    '                        library.checkBlank(library.selected.book.language) ||\n' +
    '                        library.checkBlank(library.selected.book.bookOwner) ||\n' +
    '                        (library.checkBlank(library.selected.book.socialChannelIds) && library.selected.book.docTypeId == 182) ||\n' +
    '                        (library.checkBlank(library.selected.book.projectId) && library.selected.book.divisionId == 1) ||\n' +
    '                        (library.checkBlank(library.selected.book.clientId) && library.selected.book.productTypeId == 4) ||\n' +
    '                        ((library.selected.book.bookName + \'\').length > 50 && library.selected.book.fulfilment[7] && library.selected.book.DocTypes.docTypeId == 1) ||\n' +
    '                        ((!library.selected.book.dates.isValid) && !library.selected.book.bookCode) ||\n' +
    '                        (library.selected.book.docTypeId == 93 && library.checkBlank(library.selected.book.extras.BGHPColour)) ||\n' +
    '                        (library.productIdsAllowedSubDocTypes.indexOf(library.selected.book.DocTypes.docTypeId) !== -1 && library.selected.book.fulfilment[7] == true && (!library.selected.book.productIdsSelectedArray || !library.checkProductIdsSelectedArray(library.selected.book.productIdsSelectedArray))) ||\n' +
    '                        (library.selected.book.insurerId == 38 && (!library.selected.book.insurerDetailsSelectedArray || library.checkBlankArray(library.selected.book.insurerDetailsSelectedArray)))\n' +
    '                        )"\n' +
    '                    ng-click="library.saveBookInfoDialog(library.selected.book)"\n' +
    '            >Save</md-button><!-- non specific insurer id -->\n' +
    '            <!--/*TODO change  182 on line 34 for the Social Asset doc Type ID*/-->\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <md-tabs md-border-bottom md-selected="library.tabIndex" flex>\n' +
    '        <md-tab>\n' +
    '            <md-tab-label><span>Main</span> <ng-md-icon ng-if="(\n' +
    '                        library.selected.book.checkCode ||\n' +
    '                        !(library.selected.book.finProm) ||\n' +
    '                        library.checkBlank(library.selected.book.bookName) ||\n' +
    '                        library.checkBlank(library.selected.book.bookCode) ||\n' +
    '                        library.checkBlank(library.selected.book.DocTypes) ||\n' +
    '                        library.checkBlank(library.selected.book.insurerId) ||\n' +
    '                        library.checkBlank(library.selected.book.productId) ||\n' +
    '                        library.checkBlank(library.selected.book.productTypeId) ||\n' +
    '                        library.checkBlank(library.selected.book.regionId) ||\n' +
    '                        library.checkBlank(library.selected.book.audienceId) ||\n' +
    '                        library.checkBlank(library.selected.book.language) ||\n' +
    '                        library.checkBlank(library.selected.book.bookOwner) ||\n' +
    '                        ((!library.selected.book.socialChannelIds || library.selected.book.socialChannelIds.length == 0) && library.selected.book.docTypeId == 182) ||\n' +
    '                        (library.checkBlank(library.selected.book.projectId) && library.selected.book.divisionId == 1) ||\n' +
    '                        (library.checkBlank(library.selected.book.clientId) && library.selected.book.productTypeId == 4) ||\n' +
    '                        (library.selected.book.docTypeId == 93 && library.checkBlank(library.selected.book.extras.BGHPColour)) ||\n' +
    '                        ((library.selected.book.bookName + \'\').length > 50 && library.selected.book.fulfilment[7] && library.selected.book.DocTypes.docTypeId == 1) ||\n' +
    '                        (library.selected.book.insurerId == 38 && (!library.selected.book.insurerDetailsSelectedArray || library.checkBlankArray(library.selected.book.insurerDetailsSelectedArray))))\n' +
    '                        " icon="warning" size="20" md-colors="{fill:\'accent\'}"></ng-md-icon><!-- non specific insurer id -->\n' +
    '                <!--/*TODO change  182 on line 61 for the Social Asset doc Type ID*/-->\n' +
    '            </md-tab-label>\n' +
    '            <md-tab-body>\n' +
    '                <tab-main book="library.selected.book" visible="library.sideNavOpens" disabled="library.disableBookEdit" switch="library.switch" newlanguage="library.newLanguage"></tab-main>\n' +
    '            </md-tab-body>\n' +
    '        </md-tab>\n' +
    '\n' +
    '\n' +
    '        <md-tab label="Publish" class="publish" flex>\n' +
    '            <md-tab-label>Publish</md-tab-label>\n' +
    '            <md-tab-body>\n' +
    '                <tab-publish book="library.selected.book" visible="library.sideNavOpens" disabled="library.disableBookEdit" switch="library.switch"></tab-publish>\n' +
    '            </md-tab-body>\n' +
    '        </md-tab>\n' +
    '\n' +
    '\n' +
    '\n' +
    '        <md-tab label="Enclosures" ng-if="library.enclosuresAllowedSubDocTypes.indexOf(library.selected.book.DocTypes.docTypeId) !== -1 && library.selected.book.fulfilment[7] == true" flex>\n' +
    '            <md-tab-label>Enclosures</md-tab-label>\n' +
    '            <md-tab-body>\n' +
    '                <tab-enclosures book="library.selected.book" disabled="library.disableBookEdit"></tab-enclosures>\n' +
    '            </md-tab-body>\n' +
    '        </md-tab>\n' +
    '\n' +
    '\n' +
    '        <md-tab label="Products" ng-if="library.productIdsAllowedSubDocTypes.indexOf(library.selected.book.DocTypes.docTypeId) !== -1 && library.selected.book.fulfilment[7] == true" class="products" flex>\n' +
    '            <md-tab-label><span>Products</span> <ng-md-icon ng-if="!library.isLoading && ((library.productIdsAllowedSubDocTypes.indexOf(library.selected.book.DocTypes.docTypeId) !== -1 && library.selected.book.fulfilment[7] == true && (!library.selected.book.productIdsSelectedArray || !library.checkProductIdsSelectedArray(library.selected.book.productIdsSelectedArray))))" icon="warning" size="20" md-colors="{fill:\'accent\'}"></ng-md-icon></md-tab-label>\n' +
    '            <md-tab-body>\n' +
    '                <tab-products book="library.selected.book" disabled="library.disableBookEdit"></tab-products>\n' +
    '            </md-tab-body>\n' +
    '        </md-tab>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '        <md-tab label="Workflow" class="workflow" ng-disabled="!library.selected.book.DocTypes" flex>\n' +
    '            <md-tab-label><span>Workflow</span>\n' +
    '                <ng-md-icon ng-if="(\n' +
    '                    library.selected.book.isWorkflowInvalid\n' +
    '                )" icon="warning" size="20" md-colors="{fill:\'accent\'}"></ng-md-icon>\n' +
    '            </md-tab-label>\n' +
    '            <md-tab-body>\n' +
    '                <tab-workflow book="library.selected.book" visible="library.sideNavOpens" disabled="library.disableBookEdit"></tab-workflow>\n' +
    '            </md-tab-body>\n' +
    '\n' +
    '\n' +
    '            <!--<pre>{{library.selected.book | json }}</pre>-->\n' +
    '        </md-tab>\n' +
    '        <md-tab label="Levels" ng-disabled="!library.selected.book.workflowDoc || !library.selected.book.DocTypes"  ng-if="library.selected.book.DocTypes.docTypeId === 1" flex>\n' +
    '            <md-tab-label>Levels</md-tab-label>\n' +
    '            <md-tab-body>\n' +
    '                <tab-levels book="library.selected.book" disabled="library.disableBookEdit"></tab-levels>\n' +
    '            </md-tab-body>\n' +
    '\n' +
    '\n' +
    '        </md-tab>\n' +
    '        <md-tab label="Content" flex>\n' +
    '            <md-tab-label>Content</md-tab-label>\n' +
    '            <md-tab-body>\n' +
    '                <tab-content book="library.selected.book" disabled="library.disableBookEdit"></tab-content>\n' +
    '            </md-tab-body>\n' +
    '\n' +
    '        </md-tab>\n' +
    '        <md-tab label="Dates" flex>\n' +
    '            <md-tab-label><span>Dates</span> <ng-md-icon ng-if="!library.isLoading && !library.selected.book.dates.isValid" icon="warning" size="20" md-colors="{fill:\'accent\'}"></ng-md-icon></md-tab-label>\n' +
    '            <md-tab-body>\n' +
    '                <tab-dates book="library.selected.book" visible="library.sideNavOpens"  disabled="library.disableBookEdit"></tab-dates>\n' +
    '            </md-tab-body>\n' +
    '        </md-tab>\n' +
    '\n' +
    '\n' +
    '        <md-tab label="Notes" flex>\n' +
    '            <md-tab-label>Notes</md-tab-label>\n' +
    '            <md-tab-body>\n' +
    '                <tab-notes book="library.selected.book" disabled="library.disableBookEdit"></tab-notes>\n' +
    '            </md-tab-body>\n' +
    '        </md-tab>\n' +
    '    </md-tabs>\n' +
    '\n' +
    '</form>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-directives/library-menu.tpl.html',
    '<div   flex></div>\n' +
    '\n' +
    '<!--{{parent.Children.indexOf(child)}}-->\n' +
    '<div ng-if="(tabBook.opentab.selected.structure[0] == child.structureId || tabBook.inSelected(child.structureId)) &&  !tabBook.library.isReorderingChildren(child)" class="md-menu">\n' +
    '\n' +
    ' <div class="menu-container" layout-align="center right" layout="column" ng-if="tabBook.library.menuAccess(child,parentbook)">\n' +
    '  <md-menu md-position-mode="target-right target"  md-offset="-10 10">\n' +
    '   <md-button aria-label="Open tools menu" class="md-icon-button" ng-click="$mdOpenMenu();$event.stopPropagation()" wraps-tooltip="jobbox-menu">\n' +
    '    <ng-md-icon icon="more_vert" style="fill: grey" size="24"></ng-md-icon>\n' +
    '   </md-button>\n' +
    '   <md-menu-content>\n' +
    '    <md-menu-item ng-if="tabBook.book.documentStatus.substring(0, 4) !== \'EXPD\' && tabBook.library.editAccess(child,parentbook)">\n' +
    '     <md-button ng-click="tabBook.library.openEditCopy(child, parentbook)" wraps-tooltip="briefbox-menu_edit">\n' +
    '      <div layout="row">\n' +
    '       <p flex>Edit</p>\n' +
    '       <ng-md-icon icon="edit" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '      </div>\n' +
    '     </md-button>\n' +
    '    </md-menu-item>\n' +
    '\n' +
    '    <md-menu-item ng-if="tabBook.book.documentStatus.substring(0, 4) !== \'EXPD\' && tabBook.library.insertAccess(child,parentbook)">\n' +
    '     <md-button  ng-if="child.versions.Contents.ContentTypes.contentTypeId<4 || child.versions.Contents.ContentTypes.contentTypeId===11 || child.versions.Contents.ContentTypes.contentTypeId===14 || child.versions.Contents.ContentTypes.contentTypeId===15 || child.versions.Contents.ContentTypes.contentTypeId===18 || child.versions.Contents.ContentTypes.contentTypeId===21" ng-click="tabBook.library.insertContentDialog(child,parentbook)" aria-label="Insert content">\n' +
    '      <div layout="row">\n' +
    '       <p flex>Insert Content</p>\n' +
    '       <ng-md-icon icon="playlist_add" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '      </div>\n' +
    '     </md-button>\n' +
    '    </md-menu-item>\n' +
    '\n' +
    '       <md-menu-item  ng-if="parentbook.DocTypes.docTypeTemplate === 1">\n' +
    '        <md-button ng-click="tabBook.library.openDocDiffDialog(parentbook.getChangesByStructureId(child.structureId), child)" aria-label="alert" wraps-tooltip="action-icon" >\n' +
    '           <div layout="row">\n' +
    '               <p flex>Info</p>\n' +
    '               <ng-md-icon  icon="info" style="fill: grey;  padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '           </div>\n' +
    '       </md-button>\n' +
    '   </md-menu-item>\n' +
    '\n' +
    '\n' +
    '<!-- \n' +
    '       <md-menu-item >\n' +
    '            <md-button ng-click="tabBook.library.showHistory(child.structureId, \'menu\')" aria-label="alert" wraps-tooltip="briefbox-menu_history">\n' +
    '               <div layout="row">\n' +
    '                   <p flex>History</p>\n' +
    '                   <ng-md-icon  icon="history" style="fill: grey;  padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '               </div>\n' +
    '           </md-button>\n' +
    '       </md-menu-item>\n' +
    ' -->\n' +
    '\n' +
    '\n' +
    '       <md-menu-item   ng-if="tabBook.library.hasPermissions(\'pushChanges\')">\n' +
    '           <md-button  ng-click="tabBook.library.pushContentDialog(child)" aria-label="Push Changes">\n' +
    '               <div layout="row">\n' +
    '                   <p flex>Push Changes</p>\n' +
    '                   <ng-md-icon icon="share" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '               </div>\n' +
    '           </md-button>\n' +
    '       </md-menu-item>\n' +
    '   <md-menu-item ng-if="tabBook.book.documentStatus.substring(0, 4) !== \'EXPD\' && tabBook.library.deleteAccess(child, parentbook)">\n' +
    '       <md-button  ng-click="tabBook.library.deleteContentDialog(child, parent.Children)" aria-label="Delete content">\n' +
    '           <div layout="row">\n' +
    '               <p flex>Delete Content</p>\n' +
    '               <ng-md-icon icon="delete" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '           </div>\n' +
    '       </md-button>\n' +
    '   </md-menu-item>\n' +
    '\n' +
    '\n' +
    '   <md-menu-item ng-if="tabBook.book.documentStatus.substring(0, 4) !== \'EXPD\' && tabBook.library.moveAccess(child, parentbook) && (child.versions.Contents.contentTypeId === 1 || (child.versions.Contents.contentTypeId >= 5 &&  child.versions.Contents.contentTypeId <=8) || (child.versions.Contents.contentTypeId == 17) || (child.versions.Contents.contentTypeId == 19)|| (child.versions.Contents.contentTypeId == 20))" >\n' +
    '       <md-button ng-click="tabBook.library.reorderOpen(child, parent, child.versions.Contents.contentTypeId)">\n' +
    '           <div layout="row">\n' +
    '               <p flex>Re-Order All</p>\n' +
    '               <ng-md-icon icon="format_line_spacing" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '           </div>\n' +
    '       </md-button>\n' +
    '   </md-menu-item>\n' +
    '\n' +
    '       <md-menu-item ng-if="tabBook.book.documentStatus.substring(0, 4) !== \'EXPD\' && !$first && tabBook.library.moveAccess(child, parentbook)">\n' +
    '         <md-button ng-click="tabBook.library.moveUp(parent.Children.indexOf(child),parent)">\n' +
    '          <div layout="row">\n' +
    '           <p flex>Move Up</p>\n' +
    '           <ng-md-icon icon="arrow_drop_up" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '          </div>\n' +
    '         </md-button>\n' +
    '       </md-menu-item>\n' +
    '\n' +
    '       <md-menu-item ng-if="tabBook.book.documentStatus.substring(0, 4) !== \'EXPD\' && !$last && tabBook.library.moveAccess(child, parentbook)">\n' +
    '           <md-button ng-click="tabBook.library.moveDown(parent.Children.indexOf(child),parent)">\n' +
    '               <div layout="row">\n' +
    '                   <p flex>Move Down</p>\n' +
    '                   <ng-md-icon icon="arrow_drop_down" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '               </div>\n' +
    '           </md-button>\n' +
    '       </md-menu-item>\n' +
    '\n' +
    '       <md-menu-item ng-if="tabBook.book.documentStatus.substring(0, 4) !== \'EXPD\' && tabBook.library.moveAccess(child, parentbook) && ((child.versions.Contents.contentTypeId >= 5 &&  child.versions.Contents.contentTypeId <=8) || (child.versions.Contents.contentTypeId == 17) || (child.versions.Contents.contentTypeId == 19)|| (child.versions.Contents.contentTypeId == 20))" >\n' +
    '           <md-button ng-click="tabBook.library.ordAbcBookDialog(child,parent.Children)">\n' +
    '               <div layout="row">\n' +
    '                   <p flex>ABC sort Siblings</p>\n' +
    '                   <ng-md-icon icon="sort" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '               </div>\n' +
    '           </md-button>\n' +
    '       </md-menu-item>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '   </md-menu-content>\n' +
    '  </md-menu>\n' +
    '\n' +
    ' </div>\n' +
    '</div>\n' +
    '</div>\n' +
    '\n' +
    '<div ng-if="tabBook.library.isReorderingChildren(child)" style="z-index: 100; padding-right: 10px;">\n' +
    '    <md-button class="md-grey"  ng-click="tabBook.library.reorderClose(child);tabBook.library.updateBooks();$event.stopPropagation();">Cancel</md-button>\n' +
    '    <md-button class="md-green" ng-click="tabBook.library.reorderSave(parentbook, child.structureId, child);$event.stopPropagation();">Re-Order</md-button>\n' +
    '</div>\n' +
    '\n' +
    '<md-button\n' +
    '           ng-if="(tabBook.library.viewActionChildren(child, parentbook) && !tabBook.inSelected(child.structureId) && !tabBook.inSection(child.structureId))"\n' +
    '           class="md-icon-button" aria-label="alert"\n' +
    '           wraps-tooltip="briefbox-reject"><!--style="fill: #F44336;"-->\n' +
    '    <ng-md-icon  icon="error" md-colors="{fill:\'light-blue\'}" size="30"></ng-md-icon>\n' +
    '</md-button>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '<md-button ng-click="tabBook.library.openWorkflowDialog(child, parentbook, \'Reject\'); $event.stopPropagation()"\n' +
    '           ng-if="tabBook.library.viewReject(child, parentbook); "\n' +
    '           class="md-icon-button" aria-label="alert"\n' +
    '           wraps-tooltip="briefbox-reject"><!--style="fill: #F44336;"-->\n' +
    '    <ng-md-icon  icon="cancel" md-colors="{fill:\'red\'}" size="30"></ng-md-icon>\n' +
    '</md-button>\n' +
    '<md-button ng-click="tabBook.library.approveContentDialog(child, parentbook); $event.stopPropagation()"\n' +
    '           ng-if="tabBook.library.viewApprove(child, parentbook);  "\n' +
    '           class="md-icon-button" aria-label="alert"\n' +
    '           wraps-tooltip="briefbox-submit/approve"><!-- style="fill: #4CAF50;"-->\n' +
    '    <ng-md-icon  icon="check_circle" md-colors="{fill:\'green\'}" size="30"></ng-md-icon>\n' +
    '</md-button>\n' +
    '\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-directives/library-leftMenu.tpl.html',
    '<div>\n' +
    '<md-toolbar class="md-theme-light">\n' +
    '    <div class="md-toolbar-tools">\n' +
    '        <h1 flex class="md-title" wraps-tooltip="settings-title">Settings</h1>\n' +
    '        <md-button ng-click="library.closeLeftMenu()" class="md-icon-button" aria-label="Close left pane" wraps-tooltip="settings-close">\n' +
    '            <ng-md-icon icon="close" size="24"></ng-md-icon>\n' +
    '        </md-button>\n' +
    '    </div>\n' +
    '</md-toolbar>\n' +
    '<md-content  layout-padding style="padding-top:0px;">\n' +
    '\n' +
    '    <div >\n' +
    '        <md-tabs md-dynamic-height md-border-bottom md-selected="library.leftMenuTabIndex">\n' +
    '            <md-tab label="Column Visibility">\n' +
    '					<p>Please note that adding too many columns will cause readability issues.</p>\n' +
    '				<div layout="row" layout-wrap>\n' +
    '					<div flex="33"><md-switch name="Book Code" ng-model="$storage.showFilters.code">Book Code</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="PSI Code" ng-model="$storage.showFilters.psiCode">PSI Code</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Book Name" ng-model="$storage.showFilters.name">Book Name</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Insurer" ng-model="$storage.showFilters.insurer">Insurer</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="InsurerDetails" ng-model="$storage.showFilters.insurerDetails">Insurer Details</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Product" ng-model="$storage.showFilters.product">Product</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Audience" ng-model="$storage.showFilters.audience">Audience</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Region" ng-model="$storage.showFilters.region" >Region</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Product Type" ng-model="$storage.showFilters.productType">Customer Type</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Doc Type" ng-model="$storage.showFilters.docTypeCatName">Master Doc Type</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Doc Type" ng-model="$storage.showFilters.doc">Doc Type</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Fin Prom" ng-model="$storage.showFilters.finProm">Fin Prom</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Client" ng-model="$storage.showFilters.clients">Client</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Owner" ng-model="$storage.showFilters.Owner.fullName">Owner</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Owner Team" ng-model="$storage.showFilters.Owner.teamName">Owner Team</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Division" ng-model="$storage.showFilters.division">Division</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Workflow Status" ng-model="$storage.showFilters.workflowStatus">Workflow Status</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Document Status" ng-model="$storage.showFilters.documentStatus">Document Status</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Date" ng-model="$storage.showFilters.date">Date Created</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Date" ng-model="$storage.showFilters.lastUpdated">Date Updated</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Date" ng-model="$storage.showFilters.activeDate">Active Date</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Date" ng-model="$storage.showFilters.liveDate">Live Date</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Date" ng-model="$storage.showFilters.reviewDate">Review Date</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Date" ng-model="$storage.showFilters.expiryDate">Expiry Date</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Date" ng-model="$storage.showFilters.inWorkflow">In Workflow</md-switch></div>\n' +
    '                    <div flex="33"><md-switch name="Project Name" ng-model="$storage.showFilters.projectName">Project Name</md-switch></div>\n' +
    '				</div>\n' +
    '                <md-button class="md-primary" ng-click="library.resetColVisibility()">Use defaults</md-button>\n' +
    '                </md-tab>\n' +
    '                <md-tab label="Saved Searches">\n' +
    '					<div class="grey-area">\n' +
    '						<h4>Save current search:</h4>\n' +
    '						<p>{{library.stringifySearch($storage.pagination.filterLibrary)}}</p><p ng-if="library.stringifySearch($storage.pagination.filterLibrary) == \'\'">Apply a filter before trying to save search.</p>\n' +
    '						<div layout="row">\n' +
    '						<md-input-container flex="50">\n' +
    '							<label>Name of search</label>\n' +
    '							<input ng-model="library.searchName" name="saveSearch">\n' +
    '						</md-input-container>\n' +
    '						<md-input-container flex="50">\n' +
    '							<md-button ng-click="library.saveSearch()" ng-disabled="library.searchName == \'\' || library.stringifySearch($storage.pagination.filterLibrary) == \'\'" class="md-button md-raised md-primary" aria-label="Save current search">Save search</md-button>\n' +
    '						</md-input-container>\n' +
    '						</div>\n' +
    '					</div>\n' +
    '					<h4>Load a saved search:</h4>\n' +
    '					<md-list ng-if="library.savedSearches.length != 0">\n' +
    '						<md-list-item ng-repeat="search in library.savedSearches" ng-click="library.loadSearchBlob(search.searchId)">\n' +
    '							<p>{{search.searchName}}</p>\n' +
    '							<md-button class="md-icon-button" ng-click="library.deleteSearchSave(search.searchId, search.searchName, $event)" aria-label="Delete"><ng-md-icon icon="delete" style="fill:grey" size="24"></ng-md-icon></md-button>\n' +
    '						</md-list-item>\n' +
    '					</md-list>\n' +
    '					<div ng-if="library.savedSearches.length == 0">\n' +
    '						<p><strong>You have no saved searches.</strong><br/>To save a search, search the library as normal, then click the save icon and use the form above to name the search.</p>\n' +
    '					</div>\n' +
    '                </md-tab>\n' +
    '            </md-tabs>\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '</md-content>\n' +
    '</div>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-directives/library-leftComments.tpl.html',
    '<div>\n' +
    '    <md-toolbar class="md-theme-light">\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h1 flex class="md-title" wraps-tooltip="comments-title">Comments - {{leftComments.book.bookIdLong}}</h1>\n' +
    '            <md-button ng-click="library.closeLeftComments()" class="md-icon-button" aria-label="Close left pane">\n' +
    '                <ng-md-icon icon="close" size="24"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-content layout="column" flex class="comments-pane">\n' +
    '\n' +
    '        <add-comments book="leftComments.book" ng-if="library.hasPermissions(\'addComments\')"></add-comments>\n' +
    '        <div class="divider"></div>\n' +
    '        <md-progress-linear class="animate-if" md-mode="indeterminate" ng-if="leftComments.isLoading"></md-progress-linear>\n' +
    '        <div class="comments-tab" flex>\n' +
    '\n' +
    '            <div ng-repeat="event in leftComments.events | orderBy:\'-created\'" layout="row" layout-padding class="comment-container" ng-class="{\'self-comment\':$storage.user.id==event.Users.id}">\n' +
    '                <div aria-label="{{event.Users.firstName}} {{event.Users.lastName}}" class="avatar-container" wraps-tooltip="comment-user-avatar">\n' +
    '                    <img ng-if="event.Users && event.Users.profilePic != \'\' && event.Users.profilePic != null" ng-src="{{library.apiUrl}}/containers/getthumbnail/{{event.Users.profilePic}}" class="user-avatar" alt="User Avatar"/>\n' +
    '                    <div ng-if="event.Users && (event.Users.profilePic == \'\' || event.Users.profilePic == null)" class="user-avatar initials {{event.Users.lastName|lowercase|limitTo:1}}">{{event.Users.firstName|uppercase|limitTo:1}}{{event.Users.lastName|uppercase|limitTo:1}}</div>\n' +
    '                    <img ng-if="!event.Users" ng-src="/images/wraps-logo.png" md-colors="{background: \'primary\'}" class="user-avatar sys-notif" alt="WRAPS"/>\n' +
    '\n' +
    '                </div>\n' +
    '                <div flex layout="row" layout-wrap class="comment-text">\n' +
    '                    <span class="md-body-2" flex="100">{{event.eventName}}</span><br/>\n' +
    '                    <span class="md-caption" flex="50">{{event.Users.firstName}} {{event.Users.lastName}}</span><span class="md-caption timestamp" flex="50">{{event.created | date:\'dd MMM yyyy HH:mm\'}}</span>\n' +
    '                    <div flex="100" class="comment-bubble">\n' +
    '\n' +
    '                        <span ng-bind-html="event.Comment.message"></span>\n' +
    '                        <!--<span style="white-space: pre-wrap;" ng-if="event.eventType===\'Comment\'">{{event.Comment.message}}</span>-->\n' +
    '                        <span style="white-space: pre-wrap;" ng-if="!event.Comment">{{event.eventData}}</span>\n' +
    '                        <div style="" ng-if="event.categories.length > 0">\n' +
    '                            - Category: <span data-ng-repeat="category in event.categories">{{category}} </span>\n' +
    '                        </div>\n' +
    '                        <div style="" ng-if="event.rfcReasons.length > 0">\n' +
    '                            - Reason: <span data-ng-repeat="reason in event.rfcReasons">{{reason}} </span>\n' +
    '                        </div>\n' +
    '                        <div style="" ng-if="event.Comment.filesList.length > 0">\n' +
    '                            <ul class="files-ul" style="white-space: normal; margin-top:-10px; font-size: 12px;">\n' +
    '                                <li ng-repeat="file in event.Comment.filesList" >\n' +
    '                                    <a   ng-href="{{library.getFile(file.fileLocation,file.fileName)}}" target="_blank"  ng-switch="file.fileName.split(\'.\').pop()">\n' +
    '                                        <ng-md-icon ng-switch-when="pdf|doc|docx" ng-switch-when-separator="|" class="file-download" icon="insert_drive_file" size="24" aria-label="Upload complete"></ng-md-icon>\n' +
    '                                        <ng-md-icon ng-switch-when="jpg|png|jpeg|gif|svg" ng-switch-when-separator="|" class="file-download" icon="image" size="24" aria-label="Upload complete"></ng-md-icon>\n' +
    '                                        <ng-md-icon ng-switch-when="csv|xls|xlsx" ng-switch-when-separator="|" class="file-download" icon="border_all" size="24" aria-label="Upload complete"></ng-md-icon>\n' +
    '                                        <ng-md-icon ng-switch-when="htm|html|js|php" ng-switch-when-separator="|" class="file-download" icon="code" size="24" aria-label="Upload complete"></ng-md-icon>\n' +
    '                                        <ng-md-icon ng-switch-default class="file-download" icon="attach_file" size="24" aria-label="Upload complete"></ng-md-icon>{{file.fileName}}\n' +
    '                                    </a>\n' +
    '                                </li>\n' +
    '                            </ul>\n' +
    '                        </div>\n' +
    '\n' +
    '                    </div>\n' +
    '\n' +
    '                </div>\n' +
    '                <div style="width:48px; text-align:center">\n' +
    '                    <md-button class="md-icon-button" ng-if="event.Messages" ng-click="library.openEmailDetailsDialog(event.eventId)"  aria-label="alert">\n' +
    '                        <ng-md-icon  icon="email" style="fill: #ccc;" size="24"></ng-md-icon>\n' +
    '                    </md-button>\n' +
    '\n' +
    '                    <md-button ng-if="(event.structureId && leftComments.book.DocTypes.docTypeTemplate === 1) && !(event.eventName === \'Final Added\')" wraps-tooltip="action-icon"  class="md-icon-button" ng-click="library.openDocDiffDialog(leftComments.book.getChangesByStructureId(event.structureId))"  aria-label="alert">\n' +
    '                        <ng-md-icon  icon="info" style="fill: #ccc;" size="24"></ng-md-icon>\n' +
    '                    </md-button>\n' +
    '\n' +
    '                    <md-button ng-if="event.Comment.diff" wraps-tooltip="action-icon"  class="md-icon-button" ng-click="library.openMetaChangeDialog(event.Comment.diff)"  aria-label="alert">\n' +
    '                        <ng-md-icon  icon="info" style="fill: #ccc;" size="24"></ng-md-icon>\n' +
    '                    </md-button>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '    </md-content>\n' +
    '</div>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-directives/library-getContents.tpl.html',
    '<div style="font-size:10px">\n' +
    '\n' +
    '    <div  ng-if="vm.item.versions.Contents.contentTypeId <= 4" class="history_output" ng-bind-html="vm.item.versions.versiondata" ></div>\n' +
    '\n' +
    '\n' +
    '    <div ng-if="vm.item.versions.Contents.contentTypeId === 5"   layout="row" class="Benefit"  flex="100">\n' +
    '\n' +
    '\n' +
    '        <table width="100%">\n' +
    '            <thead>\n' +
    '                <tr>\n' +
    '                    <th>Benefits</th>\n' +
    '                    <th ng-bind-html="vm.book.levels.level1.name"></th>\n' +
    '                    <th ng-bind-html="vm.book.levels.level2.name"></th>\n' +
    '                    <th ng-bind-html="vm.book.levels.level3.name"></th>\n' +
    '                    <th ng-bind-html="vm.book.levels.level4.name"></th>\n' +
    '                    <th>Explanation of Benefits</th>\n' +
    '                </tr>\n' +
    '            </thead>\n' +
    '            <tbody>\n' +
    '            <tr>\n' +
    '\n' +
    '                <td><div ng-bind-html="vm.nl2br(item.benefit.name)"></div></td>\n' +
    '                <td width="12%"><div ng-bind-html="vm.nl2br(vm.item.benefit.level1)"></div></td>\n' +
    '                <td width="12%"><div ng-bind-html="vm.nl2br(vm.item.benefit.level2)"></div></td>\n' +
    '                <td width="12%"><div ng-bind-html="vm.nl2br(vm.item.benefit.level3)"></div></td>\n' +
    '                <td width="12%"><div ng-bind-html="vm.nl2br(vm.item.benefit.level4)"></div></td>\n' +
    '                <td width="38%"><div ng-bind-html="vm.item.benefit.explanation"></div></td>\n' +
    '            </tr>\n' +
    '\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <div ng-if="vm.item.versions.Contents.contentTypeId === 6"   layout="row" class="Benefit Glossary"  flex="100">\n' +
    '        <table width="100%">\n' +
    '            <thead><tr><th>Defined term</th><th>Description</th><th>Search words</th></tr></thead>\n' +
    '            <tbody>\n' +
    '            <tr >\n' +
    '                <td width="25%"><div ng-bind-html="vm.item.glossary.term"></div></td>\n' +
    '                <td width="50%"><div ng-bind-html="vm.item.glossary.description"></div></td>\n' +
    '                <td width="25%"><div ng-bind-html="vm.item.glossary.words"></div></td>\n' +
    '            </tr>\n' +
    '\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="vm.item.versions.Contents.contentTypeId === 7"   layout="row" class="Benefit"  flex="100">\n' +
    '        <table width="100%">\n' +
    '            <thead><tr><th>Exclusion</th><th>Notes</th><th>Rules</th></tr></thead>\n' +
    '            <tbody>\n' +
    '            <tr >\n' +
    '                <td width="30%"><div ng-bind-html="vm.item.exclusion.name"></div></td>\n' +
    '                <td width="20%"><div ng-bind-html="vm.item.exclusion.notes"></div></td>\n' +
    '                <td width="50%"><div ng-bind-html="vm.item.exclusion.rules"></div></td>\n' +
    '            </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="vm.item.versions.Contents.contentTypeId === 8"   layout="row" class="Benefit Glossary"  flex="100">\n' +
    '        <table width="100%">\n' +
    '            <thead><tr><th>Term</th><th>Description</th></tr></thead>\n' +
    '            <tbody>\n' +
    '            <tr >\n' +
    '                <td width="40%"><div ng-bind-html="vm.item.medical.term"></div></td>\n' +
    '                <td width="60%"><div ng-bind-html="vm.item.medical.description"></div></td>\n' +
    '            </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '    <div ng-if="vm.item.versions.Contents.contentTypeId === 9"   layout="row" class="Benefit"  flex="100">\n' +
    '        <div>\n' +
    '            <div ng-bind-html="vm.item.brief.description | urlsToLinks"></div>\n' +
    '            <ul class="file-ui">\n' +
    '                <li ng-repeat="file in item.brief.filesList" style="font:smaller" class="file-li animate-if">\n' +
    '                    <a   ng-href="{{historydialog.getFile(file.fileLocation,file.fileName)}}" target="_blank" >\n' +
    '                        <ng-md-icon class="file-download"   icon="insert_photo" size="20"  aria-label="Upload complete"></ng-md-icon>{{file.fileName}}\n' +
    '                    </a>\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="vm.item.versions.Contents.contentTypeId === 10"   layout="row" class="Benefit"  flex="100">\n' +
    '        <div>\n' +
    '            <div ng-bind-html="vm.item.artwork.description | urlsToLinks"></div>\n' +
    '            <ul class="file-ui">\n' +
    '                <li ng-repeat="file in item.artwork.filesList" style="font:smaller" class="file-li animate-if">\n' +
    '                    <a   ng-href="{{historydialog.getFile(file.fileLocation,file.fileName)}}" target="_blank" >\n' +
    '                        <ng-md-icon class="file-download"   icon="insert_photo" size="20"  aria-label="Upload complete"></ng-md-icon>{{file.fileName}}\n' +
    '                    </a>\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '    <div ng-if="vm.item.versions.Contents.contentTypeId === 11"   layout="row" class="Benefit"  flex="100">\n' +
    '        <table width="100%">\n' +
    '            <thead>\n' +
    '                <tr>\n' +
    '                    <th ng-repeat="column in item.tableHeader track by $index">Column {{$index + 1}}</th>\n' +
    '                </tr>\n' +
    '            </thead>\n' +
    '            <tbody>\n' +
    '            <tr >\n' +
    '                <td width=""  ng-repeat="column in item.tableHeader track by $index"><div ng-bind-html="column.name"></div></td>\n' +
    '            </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '    </div>\n' +
    '    \n' +
    '\n' +
    '\n' +
    '\n' +
    '    <div ng-if="vm.item.versions.Contents.contentTypeId === 12"   layout="row" class="Benefit"  flex="100">\n' +
    '            <table width="100%">\n' +
    '                <thead>\n' +
    '                    <tr>\n' +
    '                        <th ng-repeat="column in item.tableRow track by $index">Column {{$index + 1}}</th>\n' +
    '                    </tr>\n' +
    '                </thead>\n' +
    '                <tbody>\n' +
    '                <tr >\n' +
    '                    <td width=""  ng-repeat="column in item.tableRow track by $index"><div ng-bind-html="column.data"></div></td>\n' +
    '                </tr>\n' +
    '                </tbody>\n' +
    '            </table>\n' +
    '        </div>\n' +
    '        \n' +
    '            \n' +
    '    <div  ng-if="vm.item.versions.Contents.contentTypeId == 14" class="history_output" ng-bind-html="vm.item.versions.versiondata" ></div>\n' +
    '\n' +
    '    <div  ng-if="vm.item.versions.Contents.contentTypeId == 15" class="history_output" ng-bind-html="vm.item.versions.versiondata" ></div>\n' +
    '\n' +
    '    <div  ng-if="vm.item.versions.Contents.contentTypeId == 16" class="history_output" ng-bind-html="vm.item.versions.versiondata" ></div>\n' +
    '\n' +
    '    <div ng-if="vm.item.versions.Contents.contentTypeId === 17"   layout="row" class="Benefit"  flex="100">\n' +
    '        <table width="100%">\n' +
    '            <thead>\n' +
    '            <tr>\n' +
    '                <th>Benefits</th>\n' +
    '                <th>Explanation of Benefits</th>\n' +
    '                <th>Limits</th>\n' +
    '            </tr>\n' +
    '            </thead>\n' +
    '            <tbody>\n' +
    '            <tr>\n' +
    '                <td width="30%"><div ng-bind-html="vm.nl2br(item.benefit.name)"></div></td>\n' +
    '                <td width="50%"><div ng-bind-html="vm.item.benefit.explanation"></div></td>\n' +
    '                <td width="20%"><div ng-bind-html="vm.nl2br(vm.item.benefit.limit)"></div></td>\n' +
    '            </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <div  ng-if="vm.item.versions.Contents.contentTypeId == 18" class="history_output" ng-bind-html="vm.item.versions.versiondata" ></div>\n' +
    '\n' +
    '    <div  ng-if="vm.item.versions.Contents.contentTypeId == 19" class="history_output" ng-bind-html="vm.item.versions.versiondata" ></div>\n' +
    '\n' +
    '    <div ng-if="vm.item.versions.Contents.contentTypeId === 20"   layout="row" class="Benefit"  flex="100">\n' +
    '        <table width="100%">\n' +
    '            <thead><tr><th>Exclusion</th><th>Rules</th></tr></thead>\n' +
    '            <tbody>\n' +
    '            <tr >\n' +
    '                <td width="30%"><div ng-bind-html="vm.item.exclusion.name"></div></td>\n' +
    '                <td width="70%"><div ng-bind-html="vm.item.exclusion.rules"></div></td>\n' +
    '            </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '    </div>\n' +
    '\n' +
    '    <div  ng-if="vm.item.versions.Contents.contentTypeId == 21" class="history_output" ng-bind-html="vm.item.versions.versiondata" ></div>\n' +
    '\n' +
    '\n' +
    '</div>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-directives/library-docItems.tpl.html',
    '<div  layout="row" flex="100">\n' +
    '\n' +
    '<!-- Section, Subsection, Copy--->\n' +
    '\n' +
    '    <div ng-class="{ \'selectedCopy\' : tabBook.opentab.selected.structure[0] == child.structureId }" ng-click="tabBook.toggleSection(child.structureId);" ng-if="child.versions.Contents.contentTypeId === 2 || child.versions.Contents.contentTypeId === 3 || child.versions.Contents.contentTypeId === 4" layout="row" flex="100">\n' +
    '         <div flex  ng-class="[child.versions.Contents.ContentTypes.contentTypeName, child.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(child)]" ng-bind-html="tabBook.makeBold(child.versions.versiondata,book.boldWords,book.emphasis)"></div>\n' +
    '\n' +
    '            <span ng-if="!tabBook.library.isReorderingChildren(chapter)" style="float:right">\n' +
    '                <content-menu index="$index" child="child" parentbook="book" parent="parent"></content-menu>\n' +
    '            </span>\n' +
    '\n' +
    '            <div ng-if="tabBook.library.isReorderingChildren(parent)">\n' +
    '                <md-button style="float:right;" aria-label="Order">\n' +
    '                    <ng-md-icon icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '                </md-button>\n' +
    '            </div>\n' +
    '        <span style="margin: 5px 10px;"  ng-if="child.searchTotal>0 && tabBook.opentab.selected.structure[0] != child.structureId" class="searchCount">{{child.searchTotal}}</span>\n' +
    '\n' +
    '\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '<!-- Glossary -->\n' +
    '\n' +
    '    <table width="100%" ng-if="child.versions.Contents.contentTypeId == 6"  class="BGHPTable BGHPGlossary">\n' +
    '        <thead ng-if="child.prev != 6"><tr><th>DEFINED TERM</th><th>DESCRIPTION</th><th>SEARCH WORDS</th></tr></thead>\n' +
    '        <tbody>\n' +
    '        <tr data-index="{{$index}}" data-id="{{child.structureId}}" data-order="{{$index}}" ng-click="tabBook.toggleSection(child.structureId);" ng-class="[{ \'selectedCopy\' : tabBook.opentab.selected.structure[0] == child.structureId },tabBook.library.checkVersion(child)]"  >\n' +
    '            <td width="20%" ng-class="child.versions.Contents.Departments.departmentName"  class="BGHPTermNumber">\n' +
    '                <div ng-bind-html="tabBook.makeBold(child.glossary.term,book.boldWords,book.emphasis)"></div>\n' +
    '            </td>\n' +
    '            <td width="50%"  >\n' +
    '                <div ng-bind-html="tabBook.makeBold(child.glossary.description,book.boldWords,book.emphasis)"></div>\n' +
    '            </td>\n' +
    '            <td  width="30%">\n' +
    '\n' +
    '                <span ng-if="!tabBook.library.isReorderingChildren(parent)" style="float:right">\n' +
    '                    <content-menu index="$index" child="child" parentbook="book" parent="parent"></content-menu>\n' +
    '                </span>\n' +
    '\n' +
    '                <div ng-if="tabBook.library.isReorderingChildren(parent)">\n' +
    '                    <md-button style="float:right;" aria-label="Order" class="drag-handle">\n' +
    '                        <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '                    </md-button>\n' +
    '                </div>\n' +
    '\n' +
    '                <div ng-model="child.glossary.words">{{child.glossary.words}}</div>\n' +
    '                <span style="margin: 5px 10px;"  ng-if="child.searchTotal>0 && tabBook.opentab.selected.structure[0] != child.structureId" class="searchCount">{{child.searchTotal}}</span>\n' +
    '\n' +
    '            </td>\n' +
    '        </tr>\n' +
    '\n' +
    '        </tbody>\n' +
    '    </table>\n' +
    '\n' +
    '\n' +
    '<!-- Benefit Section, benefit subsection, benefit intro-->\n' +
    '\n' +
    '\n' +
    '\n' +
    '    <table id="anchorSection{{level1.Children[0].contentId}}" width="100%" ng-if="child.versions.Contents.contentTypeId === 14 || child.versions.Contents.contentTypeId === 15 || child.versions.Contents.contentTypeId === 16" class="BGHPTable" index="$index" ng-click="toggleStructure(level1.Children[0].parent.structureId);  tabBook.library.goToAnchor(\'anchorSection\' + level1.Children[0].contentId)">\n' +
    '        <thead ng-if="child.versions.Contents.contentTypeId === 14">\n' +
    '        <tr>\n' +
    '            <th width="70%">BENEFIT AND EXPLANATION</th>\n' +
    '            <th width="30%">LIMITS</th>\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '        <tbody ng-class="{ \'selectedCopy\' : tabBook.opentab.selected.structure[0] == child.structureId }" ng-click="tabBook.toggleSection(child.structureId);" >\n' +
    '        <tr data-index="{{indexNo}}" data-id="{{child.structureId}}" data-order="{{indexNo}}" >\n' +
    '            <td colspan="2" ng-class="{\'linked\' : child.benefit.options.limit == \'Linked\'}">\n' +
    '                <div ng-class="[child.versions.Contents.ContentTypes.contentTypeName, child.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(child)]">\n' +
    '                    <span ng-if="!tabBook.library.isReorderingChildren(parent)" style="float:right">\n' +
    '                    <content-menu index="indexNo" child="child" parentbook="book" parent="parent"></content-menu>\n' +
    '                    </span>\n' +
    '                    <div ng-if="tabBook.library.isReorderingChildren(parent)">\n' +
    '                        <md-button style="float:right;" aria-label="Order" class="drag-handle">\n' +
    '                            <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '                        </md-button>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div flex  ng-class="{ \'caps\' : child.versions.Contents.contentTypeId  != 16 }"  ng-bind-html="tabBook.makeBold(child.versions.versiondata,book.boldWords,book.emphasis)"></div>\n' +
    '                </div>\n' +
    '                <span style="margin: 5px 10px;"  ng-if="child.searchTotal>0 && tabBook.opentab.selected.structure[0] != child.structureId" class="searchCount">{{child.searchTotal}}</span>\n' +
    '            </td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '    </table>\n' +
    '\n' +
    '<!--Benefit-->\n' +
    '\n' +
    '    <table width="100%"  ng-if="child.versions.Contents.contentTypeId === 17" class="BGHPTable" ng-class="[{ \'first\' : child.prev != 17}, { \'last\' : child.next != 17}]">\n' +
    '        <tbody ng-class="{ \'selectedCopy\' : tabBook.opentab.selected.structure[0] == child.structureId }" ng-click="tabBook.toggleSection(child.structureId);">\n' +
    '        <tr data-index="{{indexNo}}" data-id="{{child.structureId}}" data-order="{{indexNo}}" >\n' +
    '            <td width="70%" ng-class="[child.versions.Contents.ContentTypes.contentTypeName, child.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(child)]" >\n' +
    '                <div class="BGHPBenefitName caps" ng-bind-html="tabBook.makeBold(tabBook.library.nl2br(child.benefit.name),book.boldWords,book.emphasis)"></div>\n' +
    '                <div class="BGHPBenefitExplanation"  ng-bind-html="tabBook.makeBold(child.benefit.explanation,book.boldWords,book.emphasis)"></div>\n' +
    '            </td>\n' +
    '            <td  width="30%" ng-class="{\'linked\' : child.benefit.options.limit == \'Linked\'}" class="BenefitLimit">\n' +
    '                <div ng-class="[child.versions.Contents.ContentTypes.contentTypeName, child.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(child)]" >\n' +
    '                    <span ng-if="!tabBook.library.isReorderingChildren(parent)" style="float:right">\n' +
    '                    <content-menu index="indexNo" child="child" parentbook="book" parent="parent"></content-menu>\n' +
    '                    </span>\n' +
    '                    <div ng-if="tabBook.library.isReorderingChildren(parent)">\n' +
    '                        <md-button style="float:right;" aria-label="Order" class="drag-handle">\n' +
    '                            <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '                        </md-button>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div    ng-bind-html="tabBook.makeBold(tabBook.library.nl2br(child.benefit.limit),book.boldWords,book.emphasis)"></div>\n' +
    '                </div>\n' +
    '                <span style="margin: 5px 10px;"  ng-if="child.searchTotal>0 && tabBook.opentab.selected.structure[0] != child.structureId" class="searchCount">{{child.searchTotal}}</span>\n' +
    '            </td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '    </table>\n' +
    '\n' +
    '<!--Term Section-->\n' +
    '\n' +
    '    <table width="100%"  ng-if="child.versions.Contents.contentTypeId === 18" class="BGHPTable">\n' +
    '        <thead ng-if="!(child.prev > 17)">\n' +
    '        <tr>\n' +
    '            <th width="10%">No.</th>\n' +
    '            <th width="90%">CLAUSE</th>\n' +
    '        </tr>\n' +
    '        </thead>\n' +
    '        <tbody ng-class="{ \'selectedCopy\' : tabBook.opentab.selected.structure[0] == child.structureId }" ng-click="tabBook.toggleSection(child.structureId); tabBook.library.localStorage.structureId = level1.Children[0].parent" >\n' +
    '        <tr data-index="{{indexNo}}" data-id="{{child.structureId}}" data-order="{{indexNo}}" >\n' +
    '            <td width="10%" class="BGHPTermSection">{{indexNo+1}}.</td>\n' +
    '            <td width="90%" class="BGHPTermSection">\n' +
    '\n' +
    '                <div ng-class="[child.versions.Contents.ContentTypes.contentTypeName, child.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(child)]" >\n' +
    '                    <span ng-if="!tabBook.library.isReorderingChildren(parent)" style="float:right">\n' +
    '                    <content-menu index="indexNo" child="child" parentbook="book" parent="parent"></content-menu>\n' +
    '                    </span>\n' +
    '                    <div ng-if="tabBook.library.isReorderingChildren(parent)">\n' +
    '                        <md-button style="float:right;" aria-label="Order" class="drag-handle">\n' +
    '                            <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '                        </md-button>\n' +
    '                    </div>\n' +
    '                    <div flex   ng-bind-html="tabBook.makeBold(child.versions.versiondata,book.boldWords,book.emphasis)"></div>\n' +
    '                </div>\n' +
    '                <span style="margin: 5px 10px;"  ng-if="child.searchTotal>0 && tabBook.opentab.selected.structure[0] != child.structureId" class="searchCount">{{child.searchTotal}}</span>\n' +
    '            </td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '    </table>\n' +
    '\n' +
    '<!-- Term -->\n' +
    '\n' +
    '    <table width="100%"  ng-if="child.versions.Contents.contentTypeId === 19" class="BGHPTable BGHPTerm">\n' +
    '\n' +
    '        <tbody ng-class="{ \'selectedCopy\' : tabBook.opentab.selected.structure[0] == child.structureId }" ng-click="tabBook.toggleSection(child.structureId);" >\n' +
    '        <tr data-index="{{indexNo}}" data-id="{{child.structureId}}" data-order="{{indexNo}}" >\n' +
    '            <td width="10%" class="BGHPTermNumber">{{parent.indexNo+1}}.{{child.indexNo+1}}</td>\n' +
    '            <td width="90%" class="">\n' +
    '\n' +
    '                <div ng-class="[child.versions.Contents.ContentTypes.contentTypeName, child.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(child)]" >\n' +
    '                    <span ng-if="!tabBook.library.isReorderingChildren(parent)" style="float:right">\n' +
    '                    <content-menu index="indexNo" child="child" parentbook="book" parent="parent"></content-menu>\n' +
    '                    </span>\n' +
    '                    <div ng-if="tabBook.library.isReorderingChildren(parent)">\n' +
    '                        <md-button style="float:right;" aria-label="Order" class="drag-handle">\n' +
    '                            <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '                        </md-button>\n' +
    '                    </div>\n' +
    '                    <div flex   ng-bind-html="tabBook.makeBold(child.versions.versiondata,book.boldWords,book.emphasis)"></div>\n' +
    '                </div>\n' +
    '                <span style="margin: 5px 10px;"  ng-if="child.searchTotal>0 && tabBook.opentab.selected.structure[0] != child.structureId" class="searchCount">{{child.searchTotal}}</span>\n' +
    '            </td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '    </table>\n' +
    '\n' +
    '<!--Exclusion-->\n' +
    '\n' +
    '    <table width="100%"  ng-if="child.versions.Contents.contentTypeId === 20" class="BGHPTable BGHPExclusion"  data-index="{{indexNo}}" data-id="{{child.structureId}}" data-order="{{indexNo}}" >\n' +
    '        <tbody ng-class="{ \'selectedCopy\' : tabBook.opentab.selected.structure[0] == child.structureId }" ng-click="tabBook.toggleSection(child.structureId);" >\n' +
    '        <tr>\n' +
    '            <td width="30%" class="BGHPTermNumber">\n' +
    '                <div flex  ng-class="[child.versions.Contents.ContentTypes.contentTypeName, child.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(child)]" ng-bind-html="tabBook.makeBold(child.exclusion.name,book.boldWords,book.emphasis)"></div>\n' +
    '            </td>\n' +
    '            <td width="70%"  class="">\n' +
    '\n' +
    '                <div ng-class="[child.versions.Contents.ContentTypes.contentTypeName, child.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(child)]" >\n' +
    '                    <span ng-if="!tabBook.library.isReorderingChildren(parent)" style="float:right">\n' +
    '                    <content-menu index="indexNo" child="child" parentbook="book" parent="parent"></content-menu>\n' +
    '                    </span>\n' +
    '                    <div ng-if="tabBook.library.isReorderingChildren(parent)">\n' +
    '                        <md-button style="float:right;" aria-label="Order" class="drag-handle">\n' +
    '                            <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '                        </md-button>\n' +
    '                    </div>\n' +
    '                    <div flex   ng-bind-html="tabBook.makeBold(child.exclusion.rules,book.boldWords,book.emphasis)"></div>\n' +
    '                </div>\n' +
    '                <span style="margin: 5px 10px;"  ng-if="child.searchTotal>0 && tabBook.opentab.selected.structure[0] != child.structureId" class="searchCount">{{child.searchTotal}}</span>\n' +
    '            </td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '    </table>\n' +
    '\n' +
    '    <!--Exclusion Section-->\n' +
    '\n' +
    '\n' +
    '    <table width="100%"  ng-if="child.versions.Contents.contentTypeId === 21" class="BGHPTable">\n' +
    '\n' +
    '        <tbody ng-class="{ \'selectedCopy\' : tabBook.opentab.selected.structure[0] == child.structureId }" ng-click="tabBook.toggleSection(child.structureId);" >\n' +
    '        <tr data-index="{{indexNo}}" data-id="{{child.structureId}}" data-order="{{indexNo}}" >\n' +
    '            <td class="BGHPExclusionSection">\n' +
    '                <div ng-class="[child.versions.Contents.ContentTypes.contentTypeName, child.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(child)]" >\n' +
    '                    <span ng-if="!tabBook.library.isReorderingChildren(parent)" style="float:right">\n' +
    '                    <content-menu index="indexNo" child="child" parentbook="book" parent="parent"></content-menu>\n' +
    '                    </span>\n' +
    '                    <div ng-if="tabBook.library.isReorderingChildren(parent)">\n' +
    '                        <md-button style="float:right;" aria-label="Order" class="drag-handle">\n' +
    '                            <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '                        </md-button>\n' +
    '                    </div>\n' +
    '                    <div flex class="caps" ng-bind-html="tabBook.makeBold(child.versions.versiondata,book.boldWords,book.emphasis)"></div>\n' +
    '                </div>\n' +
    '                <span style="margin: 5px 10px;"  ng-if="child.searchTotal>0 && tabBook.opentab.selected.structure[0] != child.structureId" class="searchCount">{{child.searchTotal}}</span>\n' +
    '            </td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '    </table>\n' +
    '\n' +
    '\n' +
    '\n' +
    '</div>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-directives/library-doc.tpl.html',
    '<md-list id="chapterSort-{{book.bookId}}" style="padding-bottom: 10px;">\n' +
    '    <md-list-item layout="row" flex="100" layout-wrap ng-repeat="chapter in book.Children">\n' +
    '        <div ng-class="{ \'selectedChapter\' : tabBook.inSelected(chapter.structureId)}" flex="100" layout="row" layout-wrap ng-click="tabBook.toggleChapter(chapter.structureId); tabBook.library.goToAnchor(\'anchorChapter\' + chapter.contentId)" id="anchorChapter{{chapter.contentId}}" ng-if="chapter.versions.Contents.contentTypeId != 13">\n' +
    '            <ng-md-icon ng-if="chapter.chapterTypeId!=1 && chapter.chapterTypeId" icon="star_rate" style="fill: black;  width:30px; height: 30px; position: absolute; top:5px; left:-25px;" size="30"></ng-md-icon>\n' +
    '            <div flex ng-class="[{ \'caps\' : book.DocTypes.docTypeId == 93 }, chapter.versions.Contents.ContentTypes.contentTypeName, chapter.versions.Contents.Departments.departmentName,  tabBook.library.checkVersionChildren(chapter), tabBook.library.checkVersion(chapter) ]" ng-bind-html="tabBook.showSearch(chapter.versions.versiondata)"></div>\n' +
    '            <span style="margin: 7px" ng-if="chapter.searchTotal>0 && !tabBook.inSelected(chapter.structureId)" class="searchCount">{{chapter.searchTotal}}</span>\n' +
    '            <md-button wraps-tooltip="expland-all-sections" aria-label="Expand all sections" class="md-icon-button" style="height:30px;" ng-show="tabBook.opentab.selected.structure[0] == chapter.structureId || tabBook.inSelected(chapter.structureId) && !tabBook.library.isReorderingChildren(chapter)" ng-click="$event.stopPropagation(); tabBook.expandSections(chapter.Children);">\n' +
    '                <md-tooltip>Expand all sections</md-tooltip>\n' +
    '                <ng-md-icon icon="format_line_spacing" style="fill: grey;" size="20"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button wraps-tooltip="collaps-all-sections" aria-label="Collapse all sections" class="md-icon-button" style="height:30px;" ng-show="tabBook.opentab.selected.structure[0] == chapter.structureId || tabBook.inSelected(chapter.structureId) && !tabBook.library.isReorderingChildren(chapter)" ng-click="$event.stopPropagation(); tabBook.collapseSections(chapter.Children);">\n' +
    '                <md-tooltip>Collapse all sections</md-tooltip>\n' +
    '                <ng-md-icon icon="vertical_align_center" style="fill: grey;" size="20"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '            <content-menu index="$index" ng-if="!tabBook.library.isReorderingChildren(book)" child="chapter" parentbook="book" parent="book"></content-menu>\n' +
    '\n' +
    '\n' +
    '            <div ng-if="tabBook.library.isReorderingChildren(book)">\n' +
    '                <md-button wraps-tooltip="reorder-content" style="float:right;" aria-label="Order" class="drag-handle">\n' +
    '                    <ng-md-icon class="drag-handle" icon="drag_handle"\n' +
    '                        style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '                </md-button>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div flex="100" layout="row" layout-wrap ng-if="book.DocTypes.docTypeId == 1" ng-if="chapter.versions.Contents.contentTypeId != 13">\n' +
    '            <div flex="100">\n' +
    '\n' +
    '                <!--LEVEL1 LIST-->\n' +
    '                <md-list ng-if="tabBook.inSelected(chapter.structureId)">\n' +
    '\n' +
    '                    <md-list-item ng-if="level1.versions.Contents.contentTypeId < 6" class="level1" ng-class="{ \'selectedGroup\' : tabBook.opentab.selected.structure[0] == level1.structureId }" layout="row" layout-wrap ng-repeat="level1 in chapter.Children ">\n' +
    '\n' +
    '                        <div ng-class="{ \'selectedCopy\' : tabBook.opentab.selected.structure[0] == level1.structureId }"\n' +
    '                            layout="row" flex="100" ng-click=" tabBook.toggleSection(level1.structureId);">\n' +
    '                            <div flex ng-class="[level1.versions.Contents.ContentTypes.contentTypeName, level1.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(level1)]" ng-bind-html="tabBook.makeBold(level1.versions.versiondata,book.boldWords,book.emphasis)">\n' +
    '                            </div>\n' +
    '\n' +
    '                            <content-menu index="$index" child="level1" parentbook="book" parent="chapter">\n' +
    '                            </content-menu>\n' +
    '                            <span style="margin: 5px 10px;" ng-if="level1.searchTotal>0 && tabBook.opentab.selected.structure[0] != level1.structureId" class="searchCount">{{level1.searchTotal}}</span>\n' +
    '\n' +
    '\n' +
    '                        </div>\n' +
    '\n' +
    '                        <div layout="row" flex="100" ng-show="tabBook.inSection(level1.structureId)">\n' +
    '                            <!--LEVEL2 LIST-->\n' +
    '\n' +
    '                            <md-list style="width: 100%;">\n' +
    '                                <md-list-item class="level1" ng-class="{ \'selectedGroup\' : tabBook.opentab.selected.structure[0] == level2.structureId }" layout="row" layout-wrap ng-repeat="level2 in level1.Children |  filter: {versions: {Contents: {contentTypeId: \'!5\'}}} | orderBy:\'order\'">\n' +
    '                                    <div layout="row" flex="100" ng-if="level2.versions.Contents.contentTypeId === 11" class="Benefit TableHeader">\n' +
    '                                        <!--<pre>{{level1.Children | json }}</pre>-->\n' +
    '                                        <table width="100%">\n' +
    '                                            <thead>\n' +
    '                                                <tr ng-click="tabBook.opentab.selected.structure[0] = (tabBook.opentab.selected.structure[0]==level2.structureId ? false : level2.structureId )" ng-class="[{ \'selectedCopy\' : tabBook.opentab.selected.structure[0] == level2.structureId },tabBook.library.checkVersion(level2)]">\n' +
    '                                                    <th ng-repeat="item in level2.tableHeader | orderBy:\'order\'" width="{{tabBook.library.getTableWidths(item.width, level2.tableHeader)}}%">\n' +
    '                                                        <div ng-bind-html="item.name"></div>\n' +
    '                                                    </th>\n' +
    '                                                    <th width="5%">\n' +
    '                                                        <content-menu index="$index" child="level2" parentbook="book"\n' +
    '                                                            parent="level1"></content-menu>\n' +
    '                                                    </th>\n' +
    '                                                </tr>\n' +
    '                                            </thead>\n' +
    '                                            <tbody>\n' +
    '                                                <tr ng-click="tabBook.toggleStructure(row.structureId)" ng-class="[{ \'selectedCopy\' : tabBook.opentab.selected.structure[0] == row.structureId },tabBook.library.checkVersion(row)]" ng-repeat="row in level2.Children | filter: {versions: {Contents: {contentTypeId: 12}}}  | orderBy:\'order\'">\n' +
    '                                                    <td ng-repeat="item in row.tableRow | orderBy:\'order\'">\n' +
    '                                                        <div\n' +
    '                                                            ng-bind-html="tabBook.makeBold(item.data,book.boldWords,book.emphasis)">\n' +
    '                                                        </div>\n' +
    '                                                    </td>\n' +
    '                                                    <td width="5%">\n' +
    '                                                        <content-menu index="$index" child="row" parentbook="book" parent="level2"></content-menu>\n' +
    '                                                    </td>\n' +
    '                                                </tr>\n' +
    '                                            </tbody>\n' +
    '\n' +
    '                                        </table>\n' +
    '\n' +
    '                                    </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '                                    <div flex="100" layout="row" ng-if="level2.versions.Contents.contentTypeId !== 11" ng-class="{ \'selectedCopy\' : tabBook.opentab.selected.structure[0] == level2.structureId }" ng-click="tabBook.toggleStructure(level2.structureId)">\n' +
    '\n' +
    '                                        <div flex ng-class="[level2.versions.Contents.ContentTypes.contentTypeName, level2.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(level2)]" ng-bind-html="tabBook.makeBold(level2.versions.versiondata,book.boldWords,book.emphasis)">\n' +
    '                                        </div>\n' +
    '                                        <content-menu index="$index" child="level2" parentbook="book" parent="level1">\n' +
    '                                        </content-menu>\n' +
    '                                    </div>\n' +
    '\n' +
    '                                    <div layout="row" flex="100" ng-if="level2.versions.Contents.contentTypeId !== 11">\n' +
    '                                        <!--LEVEL3 LIST-->\n' +
    '                                        <md-list style="width: 100%;">\n' +
    '                                            <md-list-item class="level1" ng-class="{ \'selectedGroup\' : tabBook.opentab.selected.structure[0] == level3.structureId }" layout="row" layout-wrap ng-repeat="level3 in level2.Children | orderBy:\'order\'">\n' +
    '\n' +
    '                                                <div flex="100" layout="row" ng-class="{ \'selectedCopy\' : tabBook.opentab.selected.structure[0] == level3.structureId }" ng-click="tabBook.opentab.selected.structure[0] = (tabBook.opentab.selected.structure[0]==level3.structureId ? false : level3.structureId )">\n' +
    '                                                    <div flex ng-class="[level3.versions.Contents.ContentTypes.contentTypeName, level3.versions.Contents.Departments.departmentName, tabBook.library.checkVersion(level3)]" ng-bind-html="tabBook.makeBold(level3.versions.versiondata,book.boldWords,book.emphasis)">\n' +
    '                                                    </div>\n' +
    '                                                    <content-menu index="$index" child="level3" parentbook="book" parent="level2"></content-menu>\n' +
    '                                                </div>\n' +
    '                                            </md-list-item>\n' +
    '                                        </md-list>\n' +
    '\n' +
    '                                    </div>\n' +
    '                                </md-list-item>\n' +
    '\n' +
    '                                <md-list-item style="width: 100%;" ng-if="tabBook.library.hasContentType(level1,5)" flex="100" class="Benefit" layout="row" layout-wrap>\n' +
    '                                    <div layout="row" flex="100">\n' +
    '\n' +
    '\n' +
    '                                        <table width="100%" id="anchorSection{{level1.Children[0].contentId}}">\n' +
    '                                            <thead>\n' +
    '                                                <tr>\n' +
    '                                                    <th>Benefits</th>\n' +
    '                                                    <th width="12%" ng-if="book.levels.level1.active" ng-bind-html="book.levels.level1.name"></th>\n' +
    '                                                    <th width="12%" ng-if="book.levels.level2.active" ng-bind-html="book.levels.level2.name"></th>\n' +
    '                                                    <th width="12%" ng-if="book.levels.level3.active" ng-bind-html="book.levels.level3.name"></th>\n' +
    '                                                    <th width="12%" ng-if="book.levels.level4.active" ng-bind-html="book.levels.level4.name"></th>\n' +
    '                                                    <th width="12%" ng-if="book.levels.payment.active" ng-bind-html="book.levels.payment.name"></th>\n' +
    '                                                    <th ng-style="{ width: book.expWidth}">Explanation of Benefits</th>\n' +
    '                                                </tr>\n' +
    '                                            </thead>\n' +
    '                                            <tbody class="paragraphSort" id="sort-{{level1.structureId}}">\n' +
    '                                                <tr ng-click="tabBook.opentab.selected.structure[0] = (tabBook.opentab.selected.structure[0]==benefit.structureId ? false : benefit.structureId )" ng-class="[{ \'selectedCopy\' : tabBook.opentab.selected.structure[0] == benefit.structureId },tabBook.library.checkVersion(benefit)]" ng-repeat="benefit in level1.Children" data-index="{{$index}}" data-id="{{benefit.structureId}}" data-order="{{$index}}">\n' +
    '                                                    <td ng-if-start="benefit.versions.Contents.contentTypeId == 5" ng-class="benefit.versions.Contents.Departments.departmentName">\n' +
    '                                                        <div ng-bind-html="tabBook.makeBold(tabBook.library.nl2br(benefit.benefit.name),book.boldWords,book.emphasis)"></div>\n' +
    '                                                    </td>\n' +
    '                                                    <td ng-if="book.levels.level1.active" ng-class="{\'linked\' : benefit.benefit.options.level1 == \'Linked\'}">\n' +
    '                                                        <div ng-bind-html="tabBook.makeBold(tabBook.library.nl2br(benefit.benefit.level1),book.boldWords,book.emphasis)"></div>\n' +
    '                                                    </td>\n' +
    '                                                    <td ng-if="book.levels.level2.active" ng-class="{\'linked\' : benefit.benefit.options.level2 == \'Linked\'}">\n' +
    '                                                        <div ng-bind-html="tabBook.makeBold(tabBook.library.nl2br(benefit.benefit.level2),book.boldWords,book.emphasis)"></div>\n' +
    '                                                    </td>\n' +
    '                                                    <td ng-if="book.levels.level3.active" ng-class="{\'linked\' : benefit.benefit.options.level3 == \'Linked\'}">\n' +
    '                                                        <div ng-bind-html="tabBook.makeBold(tabBook.library.nl2br(benefit.benefit.level3),book.boldWords,book.emphasis)"></div>\n' +
    '                                                    </td>\n' +
    '                                                    <td ng-if="book.levels.level4.active" ng-class="{\'linked\' : benefit.benefit.options.level4 == \'Linked\'}">\n' +
    '                                                        <div ng-bind-html="tabBook.makeBold(tabBook.library.nl2br(benefit.benefit.level4),book.boldWords,book.emphasis)"></div>\n' +
    '                                                    </td>\n' +
    '\n' +
    '                                                    <td ng-if="book.levels.payment.active" ng-class="{\'linked\' : benefit.benefit.options.payment === \'Linked\'}">\n' +
    '                                                        <div ng-bind-html="tabBook.makeBold(benefit.benefit.payment,book.boldWords,book.emphasis)"></div>\n' +
    '                                                    </td>\n' +
    '                                                    <td ng-if-end ng-class="{\'linked\' : benefit.benefit.options.explanation === \'Linked\'}">\n' +
    '                                                        {{benefits}}\n' +
    '                                                        <span ng-if="!tabBook.library.isReorderingChildren(level1)">\n' +
    '                                                            <content-menu index="$index" child="benefit" parentbook="book" parent="level1"></content-menu>\n' +
    '                                                        </span>\n' +
    '\n' +
    '                                                        <div ng-if="tabBook.library.isReorderingChildren(level1)">\n' +
    '                                                            <md-button style="float:right;" aria-label="Order" class="drag-handle">\n' +
    '                                                                <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '                                                            </md-button>\n' +
    '                                                        </div>\n' +
    '\n' +
    '                                                        <div ng-bind-html="tabBook.makeBold(benefit.benefit.explanation,book.boldWords,book.emphasis)"></div>\n' +
    '                                                    </td>\n' +
    '                                                </tr>\n' +
    '\n' +
    '                                            </tbody>\n' +
    '                                        </table>\n' +
    '\n' +
    '                                    </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                                </md-list-item>\n' +
    '\n' +
    '                                <!--<md-list-item style="width: 100%;" ng-if="tabBook.library.hasContentType(level1,11)"  flex="100" class="Benefit TableHeader"  layout="row" layout-wrap >-->\n' +
    '\n' +
    '                                <!--<div   layout="row"  flex="100"  ng-repeat="header in level1.Children | filter: {versions: {Contents: {contentTypeId: 11}}}  | orderBy:\'order\'">-->\n' +
    '                                <!--&lt;!&ndash;<pre>{{level1.Children | json }}</pre>&ndash;&gt;-->\n' +
    '                                <!--<table width="100%"  >-->\n' +
    '                                <!--<thead>-->\n' +
    '                                <!--<tr-->\n' +
    '                                <!--ng-click="tabBook.opentab.selected.structure[0] = (tabBook.opentab.selected.structure[0]==header.structureId ? false : header.structureId )"-->\n' +
    '                                <!--ng-class="[{ \'selectedCopy\' : tabBook.opentab.selected.structure[0] == header.structureId },tabBook.library.checkVersion(header)]">-->\n' +
    '                                <!--<th ng-repeat="item in header.tableHeader | orderBy:\'order\'" width="{{tabBook.library.getTableWidths(item.width, header.tableHeader)}}%">-->\n' +
    '                                <!--<div ng-bind-html="item.name"></div>-->\n' +
    '                                <!--</th>-->\n' +
    '                                <!--<th width="5%"><content-menu index="$index" child="header"  parentbook="book" parent="level1"></content-menu></th>-->\n' +
    '                                <!--</tr>-->\n' +
    '                                <!--</thead>-->\n' +
    '                                <!--<tbody>-->\n' +
    '                                <!--<tr-->\n' +
    '                                <!--ng-click="tabBook.opentab.selected.structure[0] = (tabBook.opentab.selected.structure[0]==row.structureId ? false : row.structureId )"-->\n' +
    '                                <!--ng-class="[{ \'selectedCopy\' : tabBook.opentab.selected.structure[0] == row.structureId },tabBook.library.checkVersion(row)]"-->\n' +
    '                                <!--ng-repeat="row in header.Children | filter: {versions: {Contents: {contentTypeId: 12}}}  | orderBy:\'order\'"-->\n' +
    '                                <!--&gt;-->\n' +
    '                                <!--<td ng-repeat="item in row.tableRow | orderBy:\'order\'" >-->\n' +
    '                                <!--<div ng-bind-html="item.data"></div>-->\n' +
    '                                <!--</td>-->\n' +
    '                                <!--<td width="5%"><content-menu index="$index" child="row"  parentbook="book" parent="header"></content-menu></td>-->\n' +
    '                                <!--</tr>-->\n' +
    '                                <!--</tbody>-->\n' +
    '\n' +
    '                                <!--</table>-->\n' +
    '\n' +
    '                                <!--</div>-->\n' +
    '                                <!--</md-list-item>-->\n' +
    '\n' +
    '                                <!--</md-list>-->\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '                        </div>\n' +
    '                    </md-list-item>\n' +
    '                    <md-list-item style="width: 100%;" ng-if="tabBook.library.hasContentType(chapter,6)" flex="100" class="Benefit Glossary" layout="row" layout-wrap>\n' +
    '\n' +
    '                        <div layout="row" flex="100" ng-class="">\n' +
    '\n' +
    '                            <table width="100%">\n' +
    '                                <thead>\n' +
    '                                    <tr>\n' +
    '                                        <th>Defined term</th>\n' +
    '                                        <th>Description</th>\n' +
    '                                        <th>Search words</th>\n' +
    '                                    </tr>\n' +
    '                                </thead>\n' +
    '                                <tbody class="paragraphSort" id="sort-{{chapter.structureId}}">\n' +
    '                                    <tr data-index="{{$index}}" data-id="{{glossary.structureId}}" data-order="{{$index}}" ng-click="tabBook.opentab.selected.structure[0] = (tabBook.opentab.selected.structure[0]==glossary.structureId ? false : glossary.structureId )" ng-class="[{ \'selectedCopy\' : tabBook.opentab.selected.structure[0] == glossary.structureId },tabBook.library.checkVersion(glossary)]" ng-repeat="glossary in chapter.Children">\n' +
    '                                        <td ng-if-start="glossary.versions.Contents.contentTypeId === 6" ng-class="glossary.versions.Contents.Departments.departmentName">\n' +
    '                                            <div ng-bind-html="tabBook.makeBold(glossary.glossary.term,book.boldWords,book.emphasis)"></div>\n' +
    '                                        </td>\n' +
    '                                        <td width="50%">\n' +
    '                                            <div ng-bind-html="tabBook.makeBold(glossary.glossary.description,book.boldWords,book.emphasis)"></div>\n' +
    '                                        </td>\n' +
    '                                        <td ng-if-end>\n' +
    '                                            <span ng-if="!tabBook.library.isReorderingChildren(chapter)">\n' +
    '                                                <content-menu index="$index" child="glossary" parentbook="book" parent="chapter"></content-menu>\n' +
    '                                            </span>\n' +
    '\n' +
    '                                            <div ng-if="tabBook.library.isReorderingChildren(chapter)">\n' +
    '                                                <md-button style="float:right;" aria-label="Order" class="drag-handle">\n' +
    '                                                    <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '                                                </md-button>\n' +
    '                                            </div>\n' +
    '\n' +
    '                                            <div ng-model="glossary.glossary.words">{{glossary.glossary.words}}</div>\n' +
    '                                        </td>\n' +
    '                                    </tr>\n' +
    '\n' +
    '                                </tbody>\n' +
    '                            </table>\n' +
    '\n' +
    '                        </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '                    </md-list-item>\n' +
    '\n' +
    '                    <md-list-item style="width: 100%;" ng-if="tabBook.library.hasContentType(chapter,7)" flex="100" class="Benefit" layout="row" layout-wrap>\n' +
    '\n' +
    '                        <div layout="row" flex="100">\n' +
    '\n' +
    '\n' +
    '                            <table width="100%">\n' +
    '                                <thead>\n' +
    '                                    <tr>\n' +
    '                                        <th>Exclusion</th>\n' +
    '                                        <th>Notes</th>\n' +
    '                                        <th>Rules</th>\n' +
    '                                    </tr>\n' +
    '                                </thead>\n' +
    '                                <tbody class="paragraphSort" id="sort-{{chapter.structureId}}">\n' +
    '                                    <tr data-index="{{$index}}" data-id="{{exclusion.structureId}}" data-order="{{$index}}" ng-click="tabBook.opentab.selected.structure[0] = (tabBook.opentab.selected.structure[0]==exclusion.structureId ? false : exclusion.structureId )" ng-class="[{ \'selectedCopy\' : tabBook.opentab.selected.structure[0] == exclusion.structureId },tabBook.library.checkVersion(exclusion)]" ng-repeat="exclusion in chapter.Children">\n' +
    '\n' +
    '                                        <td ng-if-start="exclusion.versions.Contents.contentTypeId === 7" ng-class="exclusion.versions.Contents.Departments.departmentName">\n' +
    '                                            <div ng-bind-html="tabBook.makeBold(exclusion.exclusion.name,book.boldWords,book.emphasis)">\n' +
    '                                            </div>\n' +
    '                                        </td>\n' +
    '                                        <td>\n' +
    '                                            <div ng-bind-html="tabBook.makeBold(exclusion.exclusion.notes,book.boldWords,book.emphasis)">\n' +
    '                                            </div>\n' +
    '                                        </td>\n' +
    '                                        <td ng-if-end width="50%">\n' +
    '                                            <span ng-if="!tabBook.library.isReorderingChildren(chapter)">\n' +
    '                                                <content-menu index="$index" child="exclusion" parentbook="book" parent="chapter"></content-menu>\n' +
    '                                            </span>\n' +
    '                                            <div ng-if="tabBook.library.isReorderingChildren(chapter)">\n' +
    '                                                <md-button style="float:right;" aria-label="Order" class="drag-handle">\n' +
    '                                                    <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '                                                </md-button>\n' +
    '                                            </div>\n' +
    '                                            <div ng-bind-html="tabBook.makeBold(exclusion.exclusion.rules,book.boldWords,book.emphasis)"></div>\n' +
    '                                        </td>\n' +
    '                                    </tr>\n' +
    '\n' +
    '                                </tbody>\n' +
    '                            </table>\n' +
    '\n' +
    '                        </div>\n' +
    '\n' +
    '\n' +
    '                    </md-list-item>\n' +
    '\n' +
    '                    <!-- medical word -->\n' +
    '                    <md-list-item style="width: 100%;" ng-if="tabBook.library.hasContentType(chapter,8)" flex="100" class="Benefit Glossary" layout="row" layout-wrap>\n' +
    '\n' +
    '                        <div layout="row" flex="100">\n' +
    '\n' +
    '\n' +
    '                            <table width="100%">\n' +
    '                                <thead>\n' +
    '                                    <tr>\n' +
    '                                        <th>Term</th>\n' +
    '                                        <th>Description</th>\n' +
    '                                    </tr>\n' +
    '                                </thead>\n' +
    '                                <tbody class="paragraphSort" id="sort-{{chapter.structureId}}">\n' +
    '                                    <tr data-index="{{$index}}" data-id="{{glossary.structureId}}" data-order="{{$index}}" ng-click="tabBook.opentab.selected.structure[0] = (tabBook.opentab.selected.structure[0]==medical.structureId ? false : medical.structureId )" ng-class="[{ \'selectedCopy\' : tabBook.opentab.selected.structure[0] == medical.structureId },tabBook.library.checkVersion(medical)]" ng-repeat="medical in chapter.Children">\n' +
    '\n' +
    '                                        <td ng-if-start="medical.versions.Contents.contentTypeId === 8" ng-class="medical.versions.Contents.Departments.departmentName">\n' +
    '                                            <div ng-bind-html="tabBook.makeBold(medical.medical.term,book.boldWords,book.emphasis)"></div>\n' +
    '                                        </td>\n' +
    '                                        <td ng-if-end width="60%">\n' +
    '                                            <span ng-if="!tabBook.library.isReorderingChildren(chapter)">\n' +
    '                                                <content-menu index="$index" child="medical" parentbook="book" parent="chapter"></content-menu>\n' +
    '                                            </span>\n' +
    '\n' +
    '                                            <div ng-if="tabBook.library.isReorderingChildren(chapter)">\n' +
    '                                                <md-button style="float:right;" aria-label="Order" class="drag-handle">\n' +
    '                                                    <ng-md-icon class="drag-handle" icon="drag_handle" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '                                                </md-button>\n' +
    '                                            </div>\n' +
    '                                            <div ng-bind-html="tabBook.makeBold(medical.medical.description,book.boldWords,book.emphasis)"></div>\n' +
    '                                        </td>\n' +
    '                                    </tr>\n' +
    '\n' +
    '                                </tbody>\n' +
    '                            </table>\n' +
    '\n' +
    '                        </div>\n' +
    '\n' +
    '\n' +
    '                    </md-list-item>\n' +
    '\n' +
    '                </md-list>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '        <div flex="100" layout="row" layout-wrap ng-if="book.DocTypes.docTypeId == 93" ng-if="chapter.versions.Contents.contentTypeId != 13">\n' +
    '            <div flex="100">\n' +
    '\n' +
    '                <!--LEVEL1 LIST-->\n' +
    '                <md-list ng-if="tabBook.inSelected(chapter.structureId)"\n' +
    '                    class="paragraphSort" id="sort-{{chapter.structureId}}">\n' +
    '\n' +
    '                    <md-list-item class="level1" ng-class="{ \'selectedGroup\' : tabBook.opentab.selected.structure[0] == level1.structureId }" layout="row" layout-wrap ng-repeat="level1 in chapter.Children ">\n' +
    '\n' +
    '                        <doc-items index="$index" child="level1" parentbook="book" parent="chapter" book="book" prev="chapter.Children[$index-1].versions.Contents.contentTypeId" next="chapter.Children[$index+1].versions.Contents.contentTypeId"></doc-items>\n' +
    '\n' +
    '                        <!--LEVEL2 LIST-->\n' +
    '                        <div layout="row" flex="100">\n' +
    '                            <md-list style="width: 100%;" class="paragraphSort" id="sort-{{level1.structureId}}">\n' +
    '                                <!--<div ng-click="tabBook.library.localStorage.structureId = level1.Children[0].parent; tabBook.library.goToAnchor(\'anchorSection\' + level1.Children[0].contentId)" ng-show="(tabBook.library.localStorage.structureId != level1.Children[0].parent) && (level1.Children[0].versions.Contents.contentTypeId == 15 || level1.Children[0].versions.Contents.contentTypeId == 16 || level1.Children[0].versions.Contents.contentTypeId == 17)" ng-if="level1.Children[0]">...</div>-->\n' +
    '                                <md-list-item class="level1" ng-class="{ \'selectedGroup\' : tabBook.opentab.selected.structure[0] == level2.structureId }" layout="row" layout-wrap ng-repeat="level2 in level1.Children" ng-show="tabBook.inSection(level1.structureId)">\n' +
    '                                    <doc-items index="$index" child="level2" parentbook="book" parent="level1" book="book" prev="level1.Children[$index-1].versions.Contents.contentTypeId" next="level1.Children[$index+1].versions.Contents.contentTypeId"></doc-items>\n' +
    '\n' +
    '                                    <!--LEVEL3 LIST-->\n' +
    '                                    <div layout="row" flex="100">\n' +
    '                                        <md-list style="width: 100%;" class="paragraphSort" id="sort-{{level2.structureId}}">\n' +
    '                                            <md-list-item class="level1" ng-class="{ \'selectedGroup\' : tabBook.opentab.selected.structure[0] == level3.structureId }" layout="row" layout-wrap ng-repeat="level3 in level2.Children">\n' +
    '\n' +
    '                                                <doc-items index="$index" child="level3" parentbook="book" parent="level2" book="book" prev="level2.Children[$index-1].versions.Contents.contentTypeId" next="level2.Children[$index+1].versions.Contents.contentTypeId">\n' +
    '                                                </doc-items>\n' +
    '\n' +
    '                                            </md-list-item>\n' +
    '                                        </md-list>\n' +
    '                                    </div>\n' +
    '\n' +
    '                                </md-list-item>\n' +
    '                            </md-list>\n' +
    '                        </div>\n' +
    '\n' +
    '\n' +
    '                    </md-list-item>\n' +
    '\n' +
    '                </md-list>\n' +
    '\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <md-divider></md-divider>\n' +
    '    </md-list-item>\n' +
    '</md-list>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library-directives/library-addComments.tpl.html',
    '<form ng-submit="$event.preventDefault()" name="addComments.commentForm" layout="column">\n' +
    '\n' +
    '    <div layout="row" layout-padding class="comment-container self-comment">\n' +
    '        <div aria-label="{{$storage.user.firstName}} {{$storage.user.lastName}}" class="avatar-container" wraps-tooltip="comment-user-avatar">\n' +
    '            <img ng-if="$storage.user.profilePic != \'\' && $storage.user.profilePic != null" ng-src="{{addComments.apiUrl}}/containers/getthumbnail/{{$storage.user.profilePic}}" class="user-avatar" alt="User Avatar"/>\n' +
    '            <div ng-if="$storage.user.profilePic == \'\' || $storage.user.profilePic == null" class="user-avatar initials {{$storage.user.lastName|lowercase|limitTo:1}}">{{$storage.user.firstName|uppercase|limitTo:1}}{{$storage.user.lastName|uppercase|limitTo:1}}</div>\n' +
    '\n' +
    '        </div>\n' +
    '        <div flex layout="row" layout-wrap class="comment-text" wraps-tooltip="comments-new-message">\n' +
    '            <span class="md-body-2" flex="100">{{event.eventName}}</span><br/>\n' +
    '            <span class="md-caption" flex="50">{{event.Users.firstName}} {{event.Users.lastName}}</span><span class="md-caption timestamp" flex="50">{{event.created | date:\'dd MMM yyyy HH:mm\'}}</span>\n' +
    '            <md-input-container flex="100" class="comment-bubble">\n' +
    '                <label>Comment message</label>\n' +
    '                <textarea name="message" ng-model="addComments.comment.message" required ng-focus="addComments.showForm = true" ng-blur="addComments.showForm = false"></textarea>\n' +
    '            </md-input-container>\n' +
    '            <div class="errors" ng-messages="addComments.commentForm.message.$error" ng-if="addComments.commentForm.message.$touched">\n' +
    '                <div ng-message="required">Required</div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '        </div>\n' +
    '        <div style="width:48px; text-align:center">\n' +
    '            <md-button class="md-icon-button" ng-if="addComments.commentForm.$dirty" ng-click="addComments.commentForm.$setPristine(); addComments.commentForm.$setUntouched(); addComments.comment.message = null" aria-label="cancel">\n' +
    '                <ng-md-icon style="fill:#ccc;" icon="close" size="24"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div layout="row" layout-wrap layout-padding class="add-comments animate-if" ng-if="addComments.commentForm.$dirty">\n' +
    '        <div flex="100" class="legend">\n' +
    '            <md-checkbox aria-label="Notify all users in workflow" wraps-tooltip="comments-all-contacts" ng-checked="addComments.isChecked()"  md-indeterminate="addComments.isIndeterminate()" ng-click="addComments.toggleAll()">\n' +
    '                <span class="md-subhead">Notify all users in workflow</span>\n' +
    '            </md-checkbox>\n' +
    '        </div>\n' +
    '        <div class="demo-select-all-checkboxes" flex="50" flex-gt-sm="33" ng-repeat="contact in addComments.allContacts " >\n' +
    '            <md-checkbox ng-checked="addComments.exists(contact, addComments.selected )" ng-click="addComments.toggle(contact, addComments.selected)">\n' +
    '                <strong><small>{{contact.name}}</small></strong><br/>\n' +
    '                <small>{{contact.email}}</small>\n' +
    '            </md-checkbox>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div layout="row" layout-wrap layout-padding class="animate-if" ng-if="addComments.commentForm.$dirty">\n' +
    '        <div class="demo-select-all-checkboxes" flex="100" flex-gt-sm="33" ng-repeat="contact in addComments.defaultContacts " >\n' +
    '            <md-checkbox style="margin:0px;" wraps-tooltip="comments-support-contact" ng-checked="addComments.exists(contact, addComments.selected )" ng-click="addComments.toggle(contact, addComments.selected)">\n' +
    '                <strong><small>{{contact.name}}</small></strong><br/>\n' +
    '                <small>{{contact.email}}</small>\n' +
    '            </md-checkbox>\n' +
    '            <md-tooltip>\n' +
    '                The Wraps team can help you with\n' +
    '                <ul>\n' +
    '                    <li>Meta-data creation & document coding</li>\n' +
    '                    <li>What should go into your brief section</li>\n' +
    '                    <li>Who should be on your workflows for approval</li>\n' +
    '                    <li>All technical and functional support needed</li>\n' +
    '                    <li>Reporting any unusual behaviour & faults.</li>\n' +
    '                </ul>\n' +
    '            </md-tooltip>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div layout="row" layout-wrap layout-padding class="animate-if" ng-if="addComments.commentForm.$dirty">\n' +
    '        <div flex="100">\n' +
    '            <md-contact-chips\n' +
    '                    ng-model="addComments.additionalRecipients"\n' +
    '                    md-contacts="addComments.querySearch($query)"\n' +
    '                    md-contact-name="name"\n' +
    '                    md-contact-email="email"\n' +
    '                    md-require-match="true"\n' +
    '                    md-separator-keys="addComments.keys"\n' +
    '                    md-highlight-flags="i"\n' +
    '                    placeholder="Other Wraps user"\n' +
    '                    secondary-placeholder="Add another recipient"\n' +
    '                    input-aria-label="Recipient not in workflow">\n' +
    '            </md-contact-chips>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div layout="row" layout-wrap layout-padding layout-align="end start" class="animate-if" ng-if="addComments.commentForm.$dirty">\n' +
    '        <div>\n' +
    '            <md-button class="md-raised md-primary" ng-hide="addComments.commentForm.$pristine" ng-disabled="addComments.commentForm.$invalid || addComments.commentForm.$pristine" ng-click="addComments.showForm=false; addComments.saveComments(addComments.book,child,addComments.comment, addComments.mergeSelected()); " aria-label="send" style="position:relative; top:-8px; margin:0px">\n' +
    '                Send\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</form>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/historydialog/historydialog.tpl.html',
    '<md-dialog aria-label="Content History" style="min-width:600px; overflow:hidden;"  ng-cloak>\n' +
    '\n' +
    '    <md-toolbar>\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h2>Content History</h2>\n' +
    '            <span flex></span>\n' +
    '\n' +
    '            <md-button ng-click="historydialog.dialogCancel(false)" class="md-icon-button">\n' +
    '                <ng-md-icon  icon="close" style="fill: white;" aria-label="Close dialog"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content class="dialog-content" style="">\n' +
    '        <div style="padding: 20px 20px;">\n' +
    '            <md-radio-group ng-model="historydialog.versions"  ng-repeat="history in historydialog.histories | orderBy:\'-historyId\' ">\n' +
    '                <md-radio-button ng-value="{{history.versions}}" class="md-primary" ng-if="!$first" aria-label="history select">\n' +
    '                    <get-contents item="history"></get-contents>\n' +
    '\n' +
    '\n' +
    '                    <small style="color: #bbbbbb;">{{history.lastUpdated | date:"dd/MM/yyyy HH:mm:ss"}}</small></md-radio-button>\n' +
    '\n' +
    '           <!--{{history.versions}}-->\n' +
    '            </md-radio-group>\n' +
    '        </div>\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions layout="row" ng-if="!historydialog.clickSource">\n' +
    '        <span flex></span>\n' +
    '\n' +
    '        <md-button class="md-grey"  ng-click="historydialog.dialogCancel(false)">Cancel<ng-md-icon icon="close" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '        <md-button ng-disabled="!historydialog.versions" class="md-green"  ng-click="historydialog.swapHistory(historydialog.versions)">Swap<ng-md-icon icon="swap_vert" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '\n' +
    '    </md-dialog-actions>\n' +
    '</md-dialog>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/emaildetailsdialog/emaildetailsdialog.tpl.html',
    '<md-dialog aria-label="Notification details"  class="docdiffdialog">\n' +
    '\n' +
    '    <md-toolbar>\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h2>Notification details</h2>\n' +
    '            <span flex></span>\n' +
    '\n' +
    '            <md-button ng-click="emaildetailsdialog.dialogCancel(false)" class="md-icon-button">\n' +
    '                <ng-md-icon  icon="close" size="24"  aria-label="Close dialog"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content class="dialog-content" style="">\n' +
    '\n' +
    '\n' +
    '        <md-content flex layout-padding>\n' +
    '            <div flex layout="column" style="width:620px;">\n' +
    '                <div>\n' +
    '                    <md-content>\n' +
    '                        <md-list flex>\n' +
    '                            <md-list-item class="md-3-line" ng-click="null">\n' +
    '                                <div class="md-list-item-text" layout="column">\n' +
    '                                    <h3>{{emaildetailsdialog.events[0].Messages.to}}</h3>\n' +
    '                                    <h4>From {{emaildetailsdialog.events[0].Messages.from}}</h4>\n' +
    '                                    <p>{{emaildetailsdialog.events[0].Messages.created | date : \'medium\'}}</p>\n' +
    '                                </div>\n' +
    '                            </md-list-item>\n' +
    '                            <md-divider ></md-divider>\n' +
    '\n' +
    '\n' +
    '                        </md-list>\n' +
    '                    </md-content>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="md-subhead" style="padding: 0px 10px 10px;" >\n' +
    '                    <p  ng-if="emaildetailsdialog.events[0].Messages.subject"><strong>Subject: </strong>{{emaildetailsdialog.events[0].Messages.subject}}</p>\n' +
    '\n' +
    '                </div>\n' +
    '\n' +
    '\n' +
    '                <div ng-if="emaildetailsdialog.events[0].Messages.html" ng-bind-html="emaildetailsdialog.trustAsHtml(emaildetailsdialog.events[0].Messages.html)"></div>\n' +
    '\n' +
    '                <md-divider style="padding-top:10px;" ></md-divider>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                <div class="md-subhead" style="padding:10px;" ng-if="emaildetailsdialog.events[0].Messages.response"><strong>Server response</strong>\n' +
    '                    <p style="padding-top:0px; margin-top:0px;padding-bottom:0px; margin-bottom:0px" ng-repeat="(responseName, responseValue) in emaildetailsdialog.events[0].Messages.response" ng-if="responseName != \'envelope\'">\n' +
    '                        <strong>{{responseName}}</strong> {{responseValue}}\n' +
    '                    </p>\n' +
    '\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '        </md-content>\n' +
    '\n' +
    '\n' +
    '    </md-dialog-content>\n' +
    '\n' +
    '</md-dialog>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/docdiffdialog/docdiffdialog.tpl.html',
    '<md-dialog aria-label="Add Chapter"  class="docdiffdialog">\n' +
    '\n' +
    '    <md-toolbar>\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h2>Content Changes</h2>\n' +
    '            <span flex></span>\n' +
    '\n' +
    '            <md-button ng-click="docdiffdialog.dialogCancel(false)" class="md-icon-button" >\n' +
    '                <ng-md-icon  icon="close" style="fill: white;" size="24"  aria-label="Close dialog"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content class="dialog-content" style="">\n' +
    '\n' +
    '\n' +
    '        <md-content flex>\n' +
    '            <div flex layout="column">\n' +
    '                <div layout="row" flex="100" layout-wrap style="padding:6px; background:#bbb;">\n' +
    '                    <div flex="100" style="text-align:left; color: #fff; font-size: 14px;" ng-bind-html="docdiffdialog.change.location"></div>\n' +
    '                </div>\n' +
    '\n' +
    '                <md-tabs md-dynamic-height md-border-bottom md-selected="docdiffdialog.tabIndex">\n' +
    '\n' +
    '                    <md-tab ng-if="docdiffdialog.history;" label="Content Changes">\n' +
    '\n' +
    '                        <div flex layout="column" layout-gt-md="row" layout-padding>\n' +
    '\n' +
    '                            <div ng-class="[docdiffdialog.change.contentTypeName]" class="diffDialog" style="border-left: 0;" ng-bind-html="docdiffdialog.diff"></div>\n' +
    '\n' +
    '                        </div>\n' +
    '                    </md-tab>\n' +
    '                    <md-tab ng-if="docdiffdialog.history;" label="Old">\n' +
    '\n' +
    '                        <div flex layout="column" layout-gt-md="row" layout-padding>\n' +
    '\n' +
    '                            <!--<div ng-class="[docdiffdialog.change.contentTypeName]" class="diffDialog" style="border-left: 0;" ng-bind-html="left"></div>-->\n' +
    '                            <get-contents item="docdiffdialog.left"></get-contents>\n' +
    '                        </div>\n' +
    '                    </md-tab>\n' +
    '                    <md-tab ng-if="docdiffdialog.history;" label="New">\n' +
    '\n' +
    '                        <div flex layout="column" layout-gt-md="row" layout-padding>\n' +
    '\n' +
    '                            <!--<div ng-class="[docdiffdialog.change.contentTypeName]" class="diffDialog" style="border-left: 0;" ng-bind-html="right"></div>-->\n' +
    '                            <get-contents item="docdiffdialog.right"></get-contents>\n' +
    '\n' +
    '\n' +
    '                        </div>\n' +
    '                    </md-tab>\n' +
    '\n' +
    '                    <md-tab ng-if="!docdiffdialog.history;" label="Content">\n' +
    '\n' +
    '                        <div flex layout="column" layout-gt-md="row" layout-padding>\n' +
    '\n' +
    '                            <!--<div ng-class="[docdiffdialog.change.contentTypeName]" class="diffDialog" style="border-left: 0;" ng-bind-html="right"></div>-->\n' +
    '                            <get-contents item="docdiffdialog.content"></get-contents>\n' +
    '\n' +
    '\n' +
    '                        </div>\n' +
    '                    </md-tab>\n' +
    '                    <md-tab label="Comments">\n' +
    '                        <md-content layout="column" flex class="comments-pane">\n' +
    '\n' +
    '                            <add-comments child="docdiffdialog.child" ng-if="docdiffdialog.child && docdiffdialog.hasPermissions(\'addComments\')"></add-comments>\n' +
    '                            <div class="divider"></div>\n' +
    '                            <md-progress-linear ng-if="docdiffdialog.isLoading" md-mode="indeterminate"></md-progress-linear>\n' +
    '                            <div class="comments-tab" flex>\n' +
    '\n' +
    '                                <div ng-repeat="event in docdiffdialog.events" layout="row" layout-padding class="comment-container" ng-class="{\'self-comment\':$storage.user.id==event.Users.id}">\n' +
    '                                    <div aria-label="{{event.Users.firstName}} {{event.Users.lastName}}" class="avatar-container" wraps-tooltip="comment-user-avatar">\n' +
    '                                        <img ng-if="event.Users && event.Users.profilePic != \'\' && event.Users.profilePic != null" ng-src="{{docdiffdialog.apiUrl}}/containers/getthumbnail/{{event.Users.profilePic}}" class="user-avatar" alt="User Avatar"/>\n' +
    '                                        <div ng-if="event.Users && (event.Users.profilePic == \'\' || event.Users.profilePic == null)" class="user-avatar initials {{event.Users.lastName|lowercase|limitTo:1}}">{{event.Users.firstName|uppercase|limitTo:1}}{{event.Users.lastName|uppercase|limitTo:1}}</div>\n' +
    '                                        <img ng-if="!event.Users" ng-src="/images/wraps-logo.png" md-colors="{background: \'primary\'}" class="user-avatar sys-notif" alt="WRAPS"/>\n' +
    '\n' +
    '                                    </div>\n' +
    '                                    <div flex layout="row" layout-wrap class="comment-text">\n' +
    '                                        <span class="md-body-2" flex="100">{{event.eventType}}</span><br/>\n' +
    '                                        <span class="md-caption" flex="50">{{event.Users.firstName}} {{event.Users.lastName}}</span><span class="md-caption timestamp" flex="50">{{event.created | date:\'dd MMM yyyy HH:mm\'}}</span>\n' +
    '                                        <div flex="100" class="comment-bubble">\n' +
    '\n' +
    '                                            <span >{{event.Comment.message}}</span>\n' +
    '                                            <!--<span style="white-space: pre-wrap;" ng-if="event.eventType===\'Comment\'">{{event.Comment.message}}</span>-->\n' +
    '                                            <span style="white-space: pre-wrap;" ng-if="!event.Comment">{{event.eventData}}</span>\n' +
    '                                            <div style="" ng-if="event.categories.length > 0">\n' +
    '                                                - Category: <span data-ng-repeat="category in event.categories">{{category}} </span>\n' +
    '                                            </div>\n' +
    '                                            <div style="" ng-if="event.rfcReasons.length > 0">\n' +
    '                                                - Reason: <span data-ng-repeat="reason in event.rfcReasons">{{reason}} </span>\n' +
    '                                            </div>\n' +
    '                                            <div style="" ng-if="event.Comment.filesList.length > 0">\n' +
    '                                                <ul class="files-ul" style="white-space: normal; margin-top:-10px; font-size: 12px;">\n' +
    '                                                    <li ng-repeat="file in event.Comment.filesList" ng-switch="file.fileName.split(\'.\').pop()">\n' +
    '                                                        <ng-md-icon ng-switch-when="pdf|doc|docx" ng-switch-when-separator="|" class="file-download" icon="insert_drive_file" size="24" aria-label="Upload complete"></ng-md-icon>\n' +
    '                                                        <ng-md-icon ng-switch-when="jpg|png|jpeg|gif|svg" ng-switch-when-separator="|" class="file-download" icon="image" size="24" aria-label="Upload complete"></ng-md-icon>\n' +
    '                                                        <ng-md-icon ng-switch-when="csv|xls|xlsx" ng-switch-when-separator="|" class="file-download" icon="border_all" size="24" aria-label="Upload complete"></ng-md-icon>\n' +
    '                                                        <ng-md-icon ng-switch-when="htm|html|js|php" ng-switch-when-separator="|" class="file-download" icon="code" size="24" aria-label="Upload complete"></ng-md-icon>\n' +
    '                                                        <ng-md-icon ng-switch-default class="file-download" icon="attach_file" size="24" aria-label="Upload complete"></ng-md-icon>\n' +
    '                                                        {{file.fileName}}\n' +
    '                                                    </li>\n' +
    '                                                </ul>\n' +
    '                                            </div>\n' +
    '\n' +
    '                                        </div>\n' +
    '\n' +
    '                                    </div>\n' +
    '                                    <div style="width:48px; text-align:center">\n' +
    '                                        <md-button class="md-icon-button" ng-if="event.Messages" ng-click="library.openEmailDetailsDialog(event.eventId)"  aria-label="alert">\n' +
    '                                            <ng-md-icon  icon="email" style="fill: #ccc;" size="24"></ng-md-icon>\n' +
    '                                        </md-button>\n' +
    '\n' +
    '                                        <md-button ng-if="event.structureId && leftComments.book.DocTypes.docTypeTemplate === 1" wraps-tooltip="action-icon"  class="md-icon-button" ng-click="library.openDocDiffDialog(library.getChangesByStructureId(event.structureId))"  aria-label="alert">\n' +
    '                                            <ng-md-icon  icon="info" style="fill: #ccc;" size="24"></ng-md-icon>\n' +
    '                                        </md-button>\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '\n' +
    '\n' +
    '                            </div>\n' +
    '                        </md-content>\n' +
    '                    </md-tab>\n' +
    '                </md-tabs>\n' +
    '\n' +
    '            </div>\n' +
    '        </md-content>\n' +
    '\n' +
    '\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions layout="row">\n' +
    '        <span flex></span>\n' +
    '\n' +
    '        <md-button class="md-grey"  ng-click="docdiffdialog.dialogCancel(false)">Close <ng-md-icon icon="close" size="20"></ng-md-icon></md-button>\n' +
    '\n' +
    '    </md-dialog-actions>\n' +
    '</md-dialog>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/contentdialog/contentdialog.tpl.html',
    '<md-dialog aria-label="Add Content"  class="contentdialog">\n' +
    '\n' +
    '    <md-toolbar>\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h2>Insert Content</h2>\n' +
    '            <span flex></span>\n' +
    '\n' +
    '            <md-button ng-click="contentdialog.dialogCancel(false)" class="md-icon-button">\n' +
    '                <ng-md-icon  icon="close" style="fill: white;"  aria-label="Close dialog"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content class="dialog-content big" style="">\n' +
    '        <md-tabs md-dynamic-height md-border-bottom md-selected="contentdialog.tabIndex">\n' +
    '            <md-tab label="Existing Content" md-on-select="contentdialog.currentTab = \'existing\'">\n' +
    '                <md-content class="md-padding"><br />\n' +
    '                    <form ng-submit="$event.preventDefault()">\n' +
    '                        <md-autocomplete\n' +
    '                            md-selected-item="contentdialog.selectedItem"\n' +
    '                            md-search-text="contentdialog.searchText"\n' +
    '                            md-selected-item-change="contentdialog.getContent(contentdialog.selectedItem)"\n' +
    '                            md-items="item in contentdialog.querySearch(contentdialog.searchText)"\n' +
    '                            md-item-text="item.bookCode"\n' +
    '                            md-min-length="1"\n' +
    '                            placeholder="Please select a book"\n' +
    '                            md-menu-class="autocomplete-custom-template"\n' +
    '                            ng-model-options="{ debounce: 200 }"\n' +
    '                            md-autoselect="true">\n' +
    '                        <md-item-template><span>{{item.bookCode}}</span></md-item-template>\n' +
    '                            <md-not-found>\n' +
    '                                No matches found.\n' +
    '                            </md-not-found>\n' +
    '                            </md-autocomplete>\n' +
    '                    </form>\n' +
    '                    <div ng-show="contentdialog.contentlist">\n' +
    '                        <h3 ng-if="contentdialog.contentlist.length == 0">No matching content found, try another document.</h3>\n' +
    '                        <h3 ng-if="contentdialog.contentlist.length > 0">Select content to copy:</h3>\n' +
    '                        <div ng-repeat="content in contentdialog.contentlist | orderBy: \'order\'">\n' +
    '                            <md-checkbox ng-model="content.selected"  ng-disabled="content.disabled" >\n' +
    '\n' +
    '\n' +
    '                                <get-contents item="content"></get-contents>\n' +
    '\n' +
    '\n' +
    '                            </md-checkbox>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <!--<pre>{{contentdialog.testbook | json}}</pre>-->\n' +
    '                </md-content>\n' +
    '            </md-tab>\n' +
    '\n' +
    '            <md-tab label="New Content" ng-if="contentdialog.thisContentId <= 3 " md-on-select="contentdialog.currentTab = \'content\'">\n' +
    '                <md-content class="md-padding">\n' +
    '                    <br />\n' +
    '                    <form ng-submit="$event.preventDefault()" name="contentdialog.contentForm" layout="column">\n' +
    '\n' +
    '\n' +
    '\n' +
    '                        <!--<md-input-container flex>-->\n' +
    '                            <!--<label>Content description</label>-->\n' +
    '                            <!--<input ng-model="contentdialog.contentName"    ng-minlength="3" required>-->\n' +
    '\n' +
    '                        <!--</md-input-container>-->\n' +
    '\n' +
    '\n' +
    '                        <md-input-container flex>\n' +
    '                            <label>Content Owner</label>\n' +
    '                            <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"   required>\n' +
    '                                <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: \'teamName\'" >{{team.teamName}}</md-option>\n' +
    '                            </md-select>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                        <md-input-container flex>\n' +
    '                            <label>Content Type</label>\n' +
    '                            <md-select ng-model="contentdialog.contentType" placeholder="Content Type"    ng-model-options="{trackBy: \'$value.contentTypeId\'}" ng-change="contentdialog.changeEditor(contentdialog.contentType.contentTypeId)"  required>\n' +
    '                                <md-option ng-if="(contentType.contentTypeId>contentdialog.thisContentId && contentType.contentTypeId<=4)" ng-value="contentType" ng-repeat="contentType in contentdialog.contentTypes | orderBy: \'contentTypeName\'" >{{contentType.contentTypeName}}</md-option>\n' +
    '                            </md-select>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '\n' +
    '                        <md-input-container flex ng-if="contentdialog.contentType.contentTypeId == 4 && contentdialog.tabIndex == 1" class="cke-container">\n' +
    '                            <label>Content</label>\n' +
    '                            <textarea  ng-model="contentdialog.contentData"  contenteditable="true" ckeditor="contentdialog.options"></textarea>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                        <md-input-container flex  ng-if="contentdialog.contentType.contentTypeId == 3 && contentdialog.tabIndex == 1" class="cke-container">\n' +
    '                            <label>Content</label>\n' +
    '                            <textarea  ng-model="contentdialog.contentData"   contenteditable="true" ckeditor="contentdialog.options2"></textarea>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                        <md-input-container flex  ng-if="contentdialog.contentType.contentTypeId == 2 && contentdialog.tabIndex == 1" class="cke-container">\n' +
    '                            <label>Content</label>\n' +
    '                            <textarea  ng-model="contentdialog.contentData"   contenteditable="true" ckeditor="contentdialog.options2"></textarea>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                        <div id="cktools" flex></div>\n' +
    '                        <div id="ckfoot"></div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                    </form>\n' +
    '                </md-content>\n' +
    '            </md-tab>\n' +
    '\n' +
    '            <md-tab label="New Benefit" ng-if="contentdialog.thisContentId == 2 && contentdialog.thisChapterTypeId != 7 && contentdialog.thisChapterTypeId != 8 && contentdialog.thisChapterTypeId != 9" md-on-select="contentdialog.currentTab = \'benefit\'">\n' +
    '            <md-content class="md-padding">\n' +
    '                <br />\n' +
    '                <form ng-submit="$event.preventDefault()" name="contentdialog.benefitForm">\n' +
    '\n' +
    '                    <div layout="row"  layout-wrap>\n' +
    '                        <md-input-container flex>\n' +
    '                            <label>Benefit Owner</label>\n' +
    '                            <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"  required >\n' +
    '                                <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: \'teamName\'" >{{team.teamName}}</md-option>\n' +
    '                            </md-select>\n' +
    '                        </md-input-container>\n' +
    '                    </div>\n' +
    '                    <div  class="Benefit"  layout="row"  flex="100"  style="padding-bottom: 20px;">\n' +
    '\n' +
    '                        <table width="100%">\n' +
    '                            <thead><tr><th>Benefits</th>\n' +
    '\n' +
    '                                <th ng-bind-html="contentdialog.book.levels.level1.name"></th>\n' +
    '                                <th ng-bind-html="contentdialog.book.levels.level2.name"></th>\n' +
    '                                <th ng-bind-html="contentdialog.book.levels.level3.name"></th>\n' +
    '                                <th ng-bind-html="contentdialog.book.levels.level4.name"></th>\n' +
    '\n' +
    '                                <th>Explanation of Benefits</th></tr></thead>\n' +
    '                            <tbody>\n' +
    '                            <tr>\n' +
    '\n' +
    '                                <td width=""><md-input-container><textarea  ng-model="contentdialog.benefit.name" aria-label="Benefit name" required></textarea></md-input-container></td>\n' +
    '                                <td width="12%">\n' +
    '                                    <md-input-container><textarea ng-disabled="contentdialog.disabledProducts.level1"  ng-model="contentdialog.benefit.level1" aria-label="Benefit level1" ></textarea></md-input-container>\n' +
    '\n' +
    '                                    <md-checkbox ng-change="contentdialog.updateOption(contentdialog.benefit,\'level1\')" class="linked" ng-model="contentdialog.benefit.option.level1" aria-label="level1">Options</md-checkbox>\n' +
    '\n' +
    '                                    <md-radio-group  ng-change="contentdialog.updateOptions(contentdialog.benefit,\'level1\')" ng-if="contentdialog.benefit.option.level1"  ng-model="contentdialog.benefit.options.level1">\n' +
    '                                        <md-radio-button value="Linked">Linked</md-radio-button>\n' +
    '                                        <md-radio-button value="Not covered">Not covered</md-radio-button>\n' +
    '                                        <md-radio-button value="Paid in full">Paid in full</md-radio-button>\n' +
    '                                        <md-radio-button value="Optional">Optional</md-radio-button>\n' +
    '                                        <md-radio-button value="Show in summary">Show in summary</md-radio-button>\n' +
    '                                    </md-radio-group>\n' +
    '\n' +
    '                                </td>\n' +
    '                                <td width="12%">\n' +
    '                                    <md-input-container><textarea  ng-disabled="contentdialog.disabledProducts.level2"  ng-model="contentdialog.benefit.level2" aria-label="Benefit level2" ></textarea></md-input-container>\n' +
    '\n' +
    '                                    <md-checkbox ng-change="contentdialog.updateOption(contentdialog.benefit,\'level2\')" class="linked" ng-model="contentdialog.benefit.option.level2" aria-label="level2">Options</md-checkbox>\n' +
    '\n' +
    '                                    <md-radio-group  ng-change="contentdialog.updateOptions(contentdialog.benefit,\'level2\')"  ng-if="contentdialog.benefit.option.level2"  ng-model="contentdialog.benefit.options.level2">\n' +
    '                                        <md-radio-button value="Linked">Linked</md-radio-button>\n' +
    '                                        <md-radio-button value="Not covered">Not covered</md-radio-button>\n' +
    '                                        <md-radio-button value="Paid in full">Paid in full</md-radio-button>\n' +
    '                                        <md-radio-button value="Optional">Optional</md-radio-button>\n' +
    '                                        <md-radio-button value="Show in summary">Show in summary</md-radio-button>\n' +
    '                                    </md-radio-group>\n' +
    '\n' +
    '                                </td>\n' +
    '                                <td width="12%">\n' +
    '                                    <md-input-container><textarea ng-disabled="contentdialog.disabledProducts.level3" ng-model="contentdialog.benefit.level3" aria-label="Benefit level3" ></textarea></md-input-container>\n' +
    '\n' +
    '                                    <md-checkbox ng-change="contentdialog.updateOption(contentdialog.benefit,\'level3\')" class="linked" ng-model="contentdialog.benefit.option.level3" aria-label="level3">Options</md-checkbox>\n' +
    '\n' +
    '                                    <md-radio-group  ng-change="contentdialog.updateOptions(contentdialog.benefit,\'level3\')"  ng-if="contentdialog.benefit.option.level3"  ng-model="contentdialog.benefit.options.level3">\n' +
    '                                        <md-radio-button value="Linked">Linked</md-radio-button>\n' +
    '                                        <md-radio-button value="Not covered">Not covered</md-radio-button>\n' +
    '                                        <md-radio-button value="Paid in full">Paid in full</md-radio-button>\n' +
    '                                        <md-radio-button value="Optional">Optional</md-radio-button>\n' +
    '                                        <md-radio-button value="Show in summary">Show in summary</md-radio-button>\n' +
    '                                    </md-radio-group>\n' +
    '\n' +
    '                                </td>\n' +
    '                                <td width="12%">\n' +
    '                                    <md-input-container><textarea ng-disabled="contentdialog.disabledProducts.level4" ng-model="contentdialog.benefit.level4" aria-label="Benefit level4" ></textarea></md-input-container>\n' +
    '\n' +
    '                                    <md-checkbox  ng-change="contentdialog.updateOption(contentdialog.benefit,\'level4\')" class="linked" ng-model="contentdialog.benefit.option.level4" aria-label="level4">Options</md-checkbox>\n' +
    '\n' +
    '                                    <md-radio-group  ng-change="contentdialog.updateOptions(contentdialog.benefit,\'level4\')"  ng-if="contentdialog.benefit.option.level4"  ng-model="contentdialog.benefit.options.level4">\n' +
    '                                        <md-radio-button value="Linked">Linked</md-radio-button>\n' +
    '                                        <md-radio-button value="Not covered">Not covered</md-radio-button>\n' +
    '                                        <md-radio-button value="Paid in full">Paid in full</md-radio-button>\n' +
    '                                        <md-radio-button value="Optional">Optional</md-radio-button>\n' +
    '                                        <md-radio-button value="Show in summary">Show in summary</md-radio-button>\n' +
    '                                    </md-radio-group>\n' +
    '\n' +
    '                                </td>\n' +
    '                                <td width="38%" style="position: relative;">\n' +
    '                                    <md-input-container class="cke-container">\n' +
    '                                        <textarea    ng-model="contentdialog.benefit.explanation"  contenteditable="true" ckeditor="contentdialog.options3"  placeholder="" aria-label="Benefit explanation"></textarea>\n' +
    '                                    </md-input-container>\n' +
    '                                    <div id="ckfoot2"></div>\n' +
    '                                    <div  style="position: absolute; bottom: -55px;" id="cktools2"></div>\n' +
    '                                    <md-checkbox class="linked right"  ng-change="contentdialog.updateLinked(contentdialog.benefit,\'explanation\')" ng-model="contentdialog.benefit.options.explanation"  ng-true-value="\'Linked\'" ng-false-value="false" aria-label="explanation">Linked</md-checkbox>\n' +
    '                                </td>\n' +
    '                            </tr>\n' +
    '\n' +
    '                            </tbody>\n' +
    '                        </table>\n' +
    '\n' +
    '                    </div>\n' +
    '\n' +
    '\n' +
    '                    <div layout="row"  layout-wrap ng-if="contentdialog.book.levels.payment">\n' +
    '                        <md-input-container flex>\n' +
    '                            <label ng-if="!contentdialog.benefit.options.payment">Payment for treatment</label>\n' +
    '                            <md-select ng-model="contentdialog.benefit.payment" placeholder="Payment for treatment"  ng-disabled="contentdialog.benefit.options.payment" >\n' +
    '                                <md-option value="Direct Billing" >Direct Billing</md-option>\n' +
    '                                <md-option value="Non applicable" >Non applicable</md-option>\n' +
    '                                <md-option value="Reimbursement" >Reimbursement</md-option>\n' +
    '                            </md-select>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                    </div>\n' +
    '                    <div layout="row"  layout-wrap ng-if="contentdialog.book.levels.Payment"  flex="100">\n' +
    '                        <md-checkbox class="linked"  ng-change="contentdialog.updateLinked(contentdialog.benefit,\'payment\')" ng-model="contentdialog.benefit.options.payment"  ng-true-value="\'Linked\'" ng-false-value="false" aria-label="payment">Linked</md-checkbox>\n' +
    '\n' +
    '                    </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '                </form>\n' +
    '\n' +
    '            </md-content>\n' +
    '        </md-tab>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <md-tab label="New Glossary Term" ng-if="contentdialog.thisContentId == 1 && contentdialog.thisChapterTypeId != 7 && contentdialog.thisChapterTypeId != 8 && contentdialog.thisChapterTypeId != 9" md-on-select="contentdialog.currentTab = \'glossary\'">\n' +
    '                <md-content class="md-padding">\n' +
    '                    <br />\n' +
    '                    <form ng-submit="$event.preventDefault()" name="contentdialog.glossaryForm">\n' +
    '\n' +
    '                        <div layout="row"  layout-wrap>\n' +
    '\n' +
    '\n' +
    '                            <md-input-container flex>\n' +
    '                                <label>Glossary Owner</label>\n' +
    '                                <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner" required>\n' +
    '                                    <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: \'teamName\'" >{{team.teamName}}</md-option>\n' +
    '                                </md-select>\n' +
    '                            </md-input-container>\n' +
    '\n' +
    '                        </div>\n' +
    '\n' +
    '\n' +
    '                        <div  class="Benefit Glossary"  layout="row"  flex="100"  style="padding-bottom: 20px;">\n' +
    '                            <table width="100%">\n' +
    '                                <thead><tr><th>Defined term</th><th>Description</th><th>Search words</th></tr></thead>\n' +
    '                                <tbody>\n' +
    '                                <tr>\n' +
    '\n' +
    '                                    <td width="25%">\n' +
    '                                        <md-input-container><textarea  ng-model="contentdialog.glossary.term" aria-label="Glossary term" required></textarea></md-input-container>\n' +
    '                                    </td>\n' +
    '\n' +
    '                                    <td width="50%" style="position: relative;">\n' +
    '                                        <md-input-container class="cke-container">\n' +
    '                                            <textarea     ng-model="contentdialog.glossary.description"  contenteditable="true" ckeditor="contentdialog.options4"  placeholder="" aria-label="Glossary description"></textarea>\n' +
    '                                        </md-input-container>\n' +
    '                                        <div id="ckfoot3"></div>\n' +
    '                                        <div  style="position: absolute; bottom: -55px;" id="cktools3"></div>\n' +
    '                                    </td>\n' +
    '\n' +
    '                                    <td width="25%">\n' +
    '                                        <md-input-container><textarea  ng-model="contentdialog.glossary.words" aria-label="Glossary words" ></textarea></md-input-container>\n' +
    '                                    </td>\n' +
    '                                </tr>\n' +
    '\n' +
    '                                </tbody>\n' +
    '                            </table>\n' +
    '\n' +
    '                        </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '                    </form>\n' +
    '                </md-content>\n' +
    '            </md-tab>\n' +
    '\n' +
    '\n' +
    '            <md-tab label="New Exclusion" ng-if="contentdialog.thisContentId == 1 && contentdialog.thisChapterTypeId != 7 && contentdialog.thisChapterTypeId != 8 && contentdialog.thisChapterTypeId != 9" md-on-select="contentdialog.currentTab = \'exclusion\'">\n' +
    '                <md-content class="md-padding">\n' +
    '                    <br />\n' +
    '                    <form ng-submit="$event.preventDefault()" name="contentdialog.exclusionForm">\n' +
    '\n' +
    '                        <div layout="row"  layout-wrap>\n' +
    '                            <md-input-container flex>\n' +
    '                                <label>Exclusion Owner</label>\n' +
    '                                <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"    required>\n' +
    '                                    <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: \'teamName\'" >{{team.teamName}}</md-option>\n' +
    '                                </md-select>\n' +
    '                            </md-input-container>\n' +
    '\n' +
    '                        </div>\n' +
    '                        <div  class="Benefit"  layout="row"  flex="100"  style="padding-bottom: 20px;">\n' +
    '\n' +
    '                            <table width="100%">\n' +
    '                                <thead><tr><th>Exclusion</th><th>Notes</th><th>Rules</th></tr></thead>\n' +
    '                                <tbody>\n' +
    '                                <tr>\n' +
    '\n' +
    '                                    <td width="30"><md-input-container><textarea  ng-model="contentdialog.exclusion.name" aria-label="Exclusion name" required></textarea></md-input-container></td>\n' +
    '                                    <td width="20"><md-input-container><textarea  ng-model="contentdialog.exclusion.notes" aria-label="Exclusion notes" ></textarea></md-input-container></td>\n' +
    '\n' +
    '                                    <td width="50%" style="position: relative;">\n' +
    '                                        <md-input-container class="cke-container">\n' +
    '                                            <textarea     ng-model="contentdialog.exclusion.rules"  contenteditable="true" ckeditor="contentdialog.options5"  placeholder="" aria-label="Exclusion rules"></textarea>\n' +
    '                                        </md-input-container>\n' +
    '                                        <div id="ckfoot5"></div>\n' +
    '                                        <div  style="position: absolute; bottom: -55px;" id="cktools5"></div>\n' +
    '                                    </td>\n' +
    '                                </tr>\n' +
    '\n' +
    '                                </tbody>\n' +
    '                            </table>\n' +
    '                            </div>\n' +
    '                        <div style="position: relative; top:-45px;" flex="50">\n' +
    '                            <md-checkbox ng-model="contentdialog.exclusion.level1" >{{bottomsheet.book.levels.level1.name}}</md-checkbox>\n' +
    '                            <md-checkbox ng-model="contentdialog.exclusion.level2" >{{bottomsheet.book.levels.level1.name}}</md-checkbox>\n' +
    '                            <md-checkbox ng-model="contentdialog.exclusion.level3" >{{bottomsheet.book.levels.level1.name}}</md-checkbox>\n' +
    '                            <md-checkbox ng-model="contentdialog.exclusion.level4" >{{bottomsheet.book.levels.level1.name}}</md-checkbox>\n' +
    '\n' +
    '                        </div>\n' +
    '\n' +
    '\n' +
    '                    </form>\n' +
    '                </md-content>\n' +
    '            </md-tab>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <md-tab label="New Medical Term" ng-if="contentdialog.thisContentId == 1 && contentdialog.thisChapterTypeId != 7 && contentdialog.thisChapterTypeId != 8 && contentdialog.thisChapterTypeId != 9"  md-on-select="contentdialog.currentTab = \'medical\'">\n' +
    '                <md-content class="md-padding">\n' +
    '                    <br />\n' +
    '                    <form ng-submit="$event.preventDefault()" name="contentdialog.medicalForm">\n' +
    '\n' +
    '                        <div layout="row"  layout-wrap>\n' +
    '                            <md-input-container flex>\n' +
    '                                <label>Exclusion Owner</label>\n' +
    '                                <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"  required>\n' +
    '                                    <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: \'teamName\'" >{{team.teamName}}</md-option>\n' +
    '                                </md-select>\n' +
    '                            </md-input-container>\n' +
    '\n' +
    '                        </div>\n' +
    '                        <div  class="Benefit Glossary"  layout="row"  flex="100"  style="padding-bottom: 20px;">\n' +
    '\n' +
    '                            <table width="100%">\n' +
    '                                <thead><tr><th>Term</th><th>Description</th></tr></thead>\n' +
    '                                <tbody>\n' +
    '                                <tr>\n' +
    '\n' +
    '                                    <td width="40"><md-input-container><textarea  ng-model="contentdialog.medical.term" aria-label="Medical term" required></textarea></md-input-container></td>\n' +
    '\n' +
    '                                    <td width="60%" style="position: relative;">\n' +
    '                                        <md-input-container class="cke-container">\n' +
    '                                            <textarea     ng-model="contentdialog.medical.description"  contenteditable="true" ckeditor="contentdialog.options6"  placeholder="" aria-label="Medical description"></textarea>\n' +
    '                                        </md-input-container>\n' +
    '                                        <div id="ckfoot6"></div></textarea>\n' +
    '                                        <div  style="position: absolute; bottom: -55px;" id="cktools6"></div>\n' +
    '                                    </td>\n' +
    '                                </tr>\n' +
    '\n' +
    '                                </tbody>\n' +
    '                            </table>\n' +
    '\n' +
    '                        </div>\n' +
    '\n' +
    '\n' +
    '                    </form>\n' +
    '                </md-content>\n' +
    '            </md-tab>\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <md-tab label="New Table" ng-if="contentdialog.thisContentId == 2 && contentdialog.thisChapterTypeId != 7 && contentdialog.thisChapterTypeId != 8 && contentdialog.thisChapterTypeId != 9"  md-on-select="contentdialog.currentTab = \'table\'">\n' +
    '                <md-content class="md-padding">\n' +
    '                    <br />\n' +
    '                    <form ng-submit="$event.preventDefault()" name="contentdialog.tableHeaderForm">\n' +
    '\n' +
    '                        <div layout="row"  layout-wrap>\n' +
    '                            <md-input-container flex="50">\n' +
    '                                <label>Table Owner</label>\n' +
    '                                <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"  required>\n' +
    '                                    <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: \'teamName\'" >{{team.teamName}}</md-option>\n' +
    '                                </md-select>\n' +
    '                            </md-input-container>\n' +
    '\n' +
    '                        </div>\n' +
    '                        <div  class="Benefit"  layout="row"  flex="100"  style="padding-bottom: 20px;">\n' +
    '\n' +
    '                            <div flex="100">\n' +
    '\n' +
    '                                <table width="100%" class="workflow-table">\n' +
    '                                    <tr>\n' +
    '                                        <th colspan="100" width="60%" align="left">NAME</th>\n' +
    '                                    </tr>\n' +
    '                                    <tr >\n' +
    '                                        <td ng-repeat="item in contentdialog.tableHeader">\n' +
    '                                            <div layout="row" flex="100">\n' +
    '                                                <md-input-container flex="100" >\n' +
    '                                                    <label>Column Name</label>\n' +
    '                                                    <input ng-model="item.name">\n' +
    '                                                </md-input-container>\n' +
    '                                            </div>\n' +
    '                                            <div layout="row" flex="100">\n' +
    '                                                <md-input-container flex="70" >\n' +
    '                                                    <label>Width %</label>\n' +
    '                                                    <input ng-model="item.width">\n' +
    '                                                </md-input-container>\n' +
    '                                                <md-input-container flex="30">\n' +
    '                                                    <md-button  class="md-icon-button md-red" ng-click="contentdialog.removeTableHeader($index)">\n' +
    '                                                        <ng-md-icon icon="clear"  size="22"  aria-label="remove"></ng-md-icon>\n' +
    '                                                    </md-button>\n' +
    '                                                </md-input-container>\n' +
    '                                            </div>\n' +
    '                                        </td>\n' +
    '                                    </tr>\n' +
    '                                </table>\n' +
    '\n' +
    '\n' +
    '                                <!--<pre>{{contentdialog.tableHeader | json}}</pre>-->\n' +
    '\n' +
    '                                <h5><md-button  ng-if="!library.disableBookEdit" type="button" class="md-icon-button md-green add-approver" ng-click="contentdialog.addTableHeader()">\n' +
    '                                    <ng-md-icon icon="add" size="22"  aria-label="add"></ng-md-icon>\n' +
    '                                </md-button>Add Column</h5>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '\n' +
    '\n' +
    '                    </form>\n' +
    '\n' +
    '                </md-content>\n' +
    '            </md-tab>\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <md-tab label="New Table Row" ng-if="contentdialog.thisContentId == 11 && contentdialog.thisChapterTypeId != 7 && contentdialog.thisChapterTypeId != 8 && contentdialog.thisChapterTypeId != 9" md-on-select="contentdialog.currentTab = \'row\'">\n' +
    '                <md-content class="md-padding">\n' +
    '                    <br />\n' +
    '                    <form ng-submit="$event.preventDefault()" name="contentdialog.tableRowForm">\n' +
    '\n' +
    '                        <div layout="row"  layout-wrap>\n' +
    '                            <md-input-container flex="50">\n' +
    '                                <label>Row Owner</label>\n' +
    '                                <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"  required>\n' +
    '                                    <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: \'teamName\'" >{{team.teamName}}</md-option>\n' +
    '                                </md-select>\n' +
    '                            </md-input-container>\n' +
    '\n' +
    '                        </div>\n' +
    '                        <div  class="Benefit Table"  layout="row"  flex="100"  style="padding-bottom: 20px;">\n' +
    '\n' +
    '                            <div flex="100">\n' +
    '\n' +
    '\n' +
    '\n' +
    '                                <table width="100%" >\n' +
    '                                    <thead>\n' +
    '                                    <tr>\n' +
    '                                        <th ng-repeat="item in contentdialog.child.tableHeader | orderBy:\'order\'"  width="{{contentdialog.getTableWidths(item.width, contentdialog.child.tableHeader)}}%">\n' +
    '                                            <div ng-bind-html="item.name"></div>\n' +
    '                                        </th>\n' +
    '                                    </tr>\n' +
    '                                    </thead>\n' +
    '                                    <tbody>\n' +
    '                                    <tr>\n' +
    '                                        <td ng-repeat="item in contentdialog.child.tableHeader | orderBy:\'order\'" >\n' +
    '                                            <md-input-container class="cke-container">\n' +
    '                                                <textarea ng-model="contentdialog.tableRow[item.index].data"   contenteditable="true" ckeditor="contentdialog.options6"></textarea>\n' +
    '                                            </md-input-container>\n' +
    '                                        </td>\n' +
    '                                    </tr>\n' +
    '                                    </tbody>\n' +
    '\n' +
    '                                </table>\n' +
    '\n' +
    '                                <div id="ckfoot6"></div>\n' +
    '                                <div  style="" id="cktools6"></div>\n' +
    '                                <!--<pre>{{contentdialog.tableRow | json}}</pre>-->\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '\n' +
    '\n' +
    '                    </form>\n' +
    '\n' +
    '                </md-content>\n' +
    '            </md-tab>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <md-tab label="New Benefit Section" ng-if="contentdialog.thisContentId == 1 && contentdialog.thisChapterTypeId == 7 && contentdialog.thisChapterTypeId != 8 && contentdialog.thisChapterTypeId != 9" md-on-select="contentdialog.currentTab = \'BGHPBenefitSection\'">\n' +
    '                <md-content class="md-padding">\n' +
    '                    <br />\n' +
    '                    <form ng-submit="$event.preventDefault()" name="contentdialog.BGHPBenefitSectionForm" layout="column">\n' +
    '\n' +
    '                        <md-input-container flex>\n' +
    '                            <label>Content Owner</label>\n' +
    '                            <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"   required>\n' +
    '                                <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: \'teamName\'" >{{team.teamName}}</md-option>\n' +
    '                            </md-select>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                        <md-input-container ng-init="contentdialog.changeEditor(2)" flex class="cke-container">\n' +
    '                            <label>Content</label>\n' +
    '                            <textarea  ng-model="contentdialog.contentData"   contenteditable="true" ckeditor="contentdialog.options7"></textarea>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                        <div id="cktools7" flex></div>\n' +
    '                        <div id="ckfoot7"></div>\n' +
    '\n' +
    '                    </form>\n' +
    '                </md-content>\n' +
    '            </md-tab>\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <md-tab label="New Benefit Section Intro" ng-if="contentdialog.thisContentId == 14" md-on-select="contentdialog.currentTab = \'BGHPBenefitIntro\'; contentdialog.changeEditor(4)">\n' +
    '                <md-content class="md-padding">\n' +
    '                    <br />\n' +
    '                    <form ng-submit="$event.preventDefault()" name="contentdialog.BGHPBenefitIntroForm" layout="column">\n' +
    '\n' +
    '                        <md-input-container flex>\n' +
    '                            <label>Content Owner</label>\n' +
    '                            <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"   required>\n' +
    '                                <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: \'teamName\'" >{{team.teamName}}</md-option>\n' +
    '                            </md-select>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                        <md-input-container  flex class="cke-container">\n' +
    '                            <label>Content</label>\n' +
    '                            <textarea  ng-model="contentdialog.contentData"   contenteditable="true" ckeditor="contentdialog.options6"></textarea>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                        <div id="cktools6" flex></div>\n' +
    '                        <div id="ckfoot6"></div>\n' +
    '\n' +
    '                    </form>\n' +
    '                </md-content>\n' +
    '            </md-tab>\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <md-tab label="New Benefit Sub Section" ng-if="contentdialog.thisContentId == 14" md-on-select="contentdialog.currentTab = \'BGHPBenefitSubSection\'; contentdialog.changeEditor(3)">\n' +
    '                <md-content class="md-padding">\n' +
    '                    <br />\n' +
    '                    <form ng-submit="$event.preventDefault()" name="contentdialog.BGHPBenefitSubSectionForm" layout="column">\n' +
    '\n' +
    '                        <md-input-container flex>\n' +
    '                            <label>Content Owner</label>\n' +
    '                            <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"   required>\n' +
    '                                <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: \'teamName\'" >{{team.teamName}}</md-option>\n' +
    '                            </md-select>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                        <md-input-container  flex class="cke-container">\n' +
    '                            <label>Content</label>\n' +
    '                            <textarea  ng-model="contentdialog.contentData"   contenteditable="true" ckeditor="contentdialog.options7"></textarea>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                        <div id="cktools7" flex></div>\n' +
    '                        <div id="ckfoot7"></div>\n' +
    '\n' +
    '                    </form>\n' +
    '                </md-content>\n' +
    '            </md-tab>\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <md-tab label="New Benefit" ng-if="contentdialog.thisContentId == 14 || contentdialog.thisContentId == 15" md-on-select="contentdialog.currentTab = \'BGHPBenefit\'">\n' +
    '                <md-content class="md-padding">\n' +
    '                    <br />\n' +
    '                    <form ng-submit="$event.preventDefault()" name="contentdialog.BGHPBenefitForm" layout="column">\n' +
    '\n' +
    '                        <md-input-container flex>\n' +
    '                            <label>Content Owner</label>\n' +
    '                            <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"   required>\n' +
    '                                <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: \'teamName\'" >{{team.teamName}}</md-option>\n' +
    '                            </md-select>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                        <div  class="Benefit"  layout="row"  flex="100"  style="padding-bottom: 20px;">\n' +
    '\n' +
    '                            <table width="100%">\n' +
    '                                <thead>\n' +
    '                                <tr>\n' +
    '                                    <th>Benefits</th>\n' +
    '                                    <th>Explanation</th>\n' +
    '                                    <th>Limit</th>\n' +
    '                                </tr>\n' +
    '                                </thead>\n' +
    '                                <tbody>\n' +
    '                                <tr>\n' +
    '                                    <td width="30%"><md-input-container><textarea  ng-model="contentdialog.benefit.name" aria-label="Benefit name" required></textarea></md-input-container></td>\n' +
    '\n' +
    '                                    <td width="50%" style="position: relative;">\n' +
    '                                        <md-input-container class="cke-container">\n' +
    '                                            <textarea    ng-model="contentdialog.benefit.explanation"  contenteditable="true" ckeditor="contentdialog.options3"  placeholder="" aria-label="Benefit explanation"></textarea>\n' +
    '                                        </md-input-container>\n' +
    '                                        <div id="ckfoot2"></div>\n' +
    '                                        <div  style="position: absolute; bottom: -55px;" id="cktools2"></div>\n' +
    '                                     </td>\n' +
    '\n' +
    '                                    <td width="20%">\n' +
    '                                        <md-input-container><textarea ng-disabled="contentdialog.benefit.options.limit"  ng-model="contentdialog.benefit.limit" aria-label="Benefit limit" ></textarea></md-input-container>\n' +
    '\n' +
    '                                        <md-checkbox ng-change="contentdialog.updateOption(contentdialog.benefit,\'limit\')" class="linked" ng-model="contentdialog.benefit.option.limit" aria-label="limit">Options</md-checkbox>\n' +
    '\n' +
    '                                        <md-radio-group  ng-change="contentdialog.updateOptions(contentdialog.benefit,\'limit\')" ng-if="contentdialog.benefit.option.limit"  ng-model="contentdialog.benefit.options.limit">\n' +
    '                                            <md-radio-button value="Linked">Linked</md-radio-button>\n' +
    '                                            <md-radio-button value="Not covered">Not covered</md-radio-button>\n' +
    '                                            <md-radio-button value="Paid in full">Paid in full</md-radio-button>\n' +
    '                                        </md-radio-group>\n' +
    '\n' +
    '                                    </td>\n' +
    '                                </tr>\n' +
    '\n' +
    '                                </tbody>\n' +
    '                            </table>\n' +
    '\n' +
    '                        </div>\n' +
    '\n' +
    '\n' +
    '                    </form>\n' +
    '                </md-content>\n' +
    '            </md-tab>\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <md-tab label="New Term Section" ng-if="contentdialog.thisContentId == 1 && contentdialog.thisChapterTypeId == 8" md-on-select="contentdialog.currentTab = \'BGHPTermSection\'">\n' +
    '                <md-content class="md-padding">\n' +
    '                    <br />\n' +
    '                    <form ng-submit="$event.preventDefault()" name="contentdialog.BGHPTermSectionForm" layout="column">\n' +
    '\n' +
    '                        <md-input-container flex>\n' +
    '                            <label>Content Owner</label>\n' +
    '                            <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"   required>\n' +
    '                                <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: \'teamName\'" >{{team.teamName}}</md-option>\n' +
    '                            </md-select>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                        <md-input-container ng-init="contentdialog.changeEditor(2)" flex class="cke-container">\n' +
    '                            <label>Content</label>\n' +
    '                            <textarea  ng-model="contentdialog.contentData"   contenteditable="true" ckeditor="contentdialog.options7"></textarea>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                        <div id="cktools7" flex></div>\n' +
    '                        <div id="ckfoot7"></div>\n' +
    '\n' +
    '                    </form>\n' +
    '                </md-content>\n' +
    '            </md-tab>\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <md-tab label="New Term " ng-if="contentdialog.thisContentId == 18" md-on-select="contentdialog.currentTab = \'BGHPTerm\'">\n' +
    '                <md-content class="md-padding">\n' +
    '                    <br />\n' +
    '                    <form ng-submit="$event.preventDefault()" name="contentdialog.BGHPTermForm" layout="column">\n' +
    '\n' +
    '                        <md-input-container flex>\n' +
    '                            <label>Content Owner</label>\n' +
    '                            <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"   required>\n' +
    '                                <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: \'teamName\'" >{{team.teamName}}</md-option>\n' +
    '                            </md-select>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                        <md-input-container  flex class="cke-container">\n' +
    '                            <label>Content</label>\n' +
    '                            <textarea  ng-model="contentdialog.contentData"   contenteditable="true" ckeditor="contentdialog.options6"></textarea>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                        <div id="cktools6" flex></div>\n' +
    '                        <div id="ckfoot6"></div>\n' +
    '\n' +
    '                    </form>\n' +
    '                </md-content>\n' +
    '            </md-tab>\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <md-tab label="New Exclusion " ng-if="contentdialog.thisContentId == 21 " md-on-select="contentdialog.currentTab = \'BGHPExclusion\'">\n' +
    '                <md-content class="md-padding">\n' +
    '                    <br />\n' +
    '                    <form ng-submit="$event.preventDefault()" name="contentdialog.BGHPExclusionForm" layout="column">\n' +
    '\n' +
    '                        <md-input-container flex>\n' +
    '                            <label>Content Owner</label>\n' +
    '                            <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"   required>\n' +
    '                                <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: \'teamName\'" >{{team.teamName}}</md-option>\n' +
    '                            </md-select>\n' +
    '                        </md-input-container>\n' +
    '                        <div  class="Benefit"  layout="row"  flex="100"  style="padding-bottom: 20px;">\n' +
    '\n' +
    '                            <table width="100%">\n' +
    '                                <thead><tr><th>Exclusion</th><th>Rules</th></tr></thead>\n' +
    '                                <tbody>\n' +
    '                                <tr>\n' +
    '\n' +
    '                                    <td width="30"><md-input-container><textarea  ng-model="contentdialog.exclusion.name" aria-label="Exclusion name" required></textarea></md-input-container></td>\n' +
    '\n' +
    '                                    <td width="50%" style="position: relative;">\n' +
    '                                        <md-input-container class="cke-container">\n' +
    '                                            <textarea ng-model="contentdialog.exclusion.rules"  contenteditable="true" ckeditor="contentdialog.options5"  placeholder="" aria-label="Exclusion rules"></textarea>\n' +
    '                                        </md-input-container>\n' +
    '                                        <div id="ckfoot5"></div>\n' +
    '                                        <div  style="position: absolute; bottom: -55px;" id="cktools5"></div>\n' +
    '                                    </td>\n' +
    '                                </tr>\n' +
    '\n' +
    '                                </tbody>\n' +
    '                            </table>\n' +
    '                        </div>\n' +
    '\n' +
    '                    </form>\n' +
    '                </md-content>\n' +
    '            </md-tab>\n' +
    '\n' +
    '\n' +
    '            <md-tab label="New Exclusion Section " ng-if="contentdialog.thisContentId == 1  && contentdialog.thisChapterTypeId == 9" md-on-select="contentdialog.currentTab = \'BGHPExclusionSection\'">\n' +
    '                <md-content class="md-padding">\n' +
    '                    <br />\n' +
    '                    <form ng-submit="$event.preventDefault()" name="contentdialog.BGHPExclusionSectionForm" layout="column">\n' +
    '\n' +
    '                        <md-input-container flex>\n' +
    '                            <label>Content Owner</label>\n' +
    '                            <md-select ng-model="contentdialog.contentOwner" placeholder="Content Owner"   required>\n' +
    '                                <md-option ng-value="team.teamId" ng-repeat="team in contentdialog.teams | orderBy: \'teamName\'" >{{team.teamName}}</md-option>\n' +
    '                            </md-select>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                        <md-input-container  flex class="cke-container">\n' +
    '                            <label>Content</label>\n' +
    '                            <textarea  ng-model="contentdialog.contentData"   contenteditable="true" ckeditor="contentdialog.options7"></textarea>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                        <div id="cktools7" flex></div>\n' +
    '                        <div id="ckfoot7"></div>\n' +
    '\n' +
    '                    </form>\n' +
    '                </md-content>\n' +
    '            </md-tab>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <md-tab label=""></md-tab>\n' +
    '\n' +
    '\n' +
    '\n' +
    '</md-tabs>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '        <md-progress-linear ng-if="contentdialog.isLoading" md-mode="indeterminate" style="position: absolute;"></md-progress-linear>\n' +
    '\n' +
    '        <!--<pre>{{contentdialog.thisChapterTypeId | json}}</pre>-->\n' +
    '\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions layout="row">\n' +
    '        <span flex></span>\n' +
    '        <md-button class="md-grey"  ng-click="contentdialog.dialogCancel(false)">Cancel<ng-md-icon icon="close" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '\n' +
    '        <md-button ng-if="contentdialog.currentTab === \'existing\'" ng-disabled="(contentdialog.isLoading || !contentdialog.contentlist || contentdialog.contentlist.length == 0)" class="md-green"  ng-click="contentdialog.addContent(contentdialog.contentlist)">Add Existing Content<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '        <md-button ng-if="contentdialog.currentTab === \'content\'" ng-disabled="(contentdialog.isLoading  || !contentdialog.contentForm.$valid)" class="md-green"  ng-click="contentdialog.addNewContent(contentdialog)">Add New Content<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '        <md-button ng-if="contentdialog.currentTab === \'benefit\'" ng-disabled="(contentdialog.isLoading  || !contentdialog.benefitForm.$valid)" class="md-green"  ng-click="contentdialog.addNewBenefit(contentdialog)">Add New Benefit<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '        <md-button ng-if="contentdialog.currentTab === \'glossary\'" ng-disabled="(contentdialog.isLoading  || !contentdialog.glossaryForm.$valid)" class="md-green"  ng-click="contentdialog.addNewGlossary(contentdialog)">Add New Glossary<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '        <md-button ng-if="contentdialog.currentTab === \'exclusion\'" ng-disabled="(contentdialog.isLoading  || !contentdialog.exclusionForm.$valid)" class="md-green"  ng-click="contentdialog.addNewExclusion(contentdialog)">Add New Exclusion<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '        <md-button ng-if="contentdialog.currentTab === \'medical\'" ng-disabled="(contentdialog.isLoading  || !contentdialog.medicalForm.$valid)" class="md-green"  ng-click="contentdialog.addNewMedical(contentdialog)">Add New Medical Term<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '\n' +
    '        <md-button  ng-if="contentdialog.currentTab === \'table\'" ng-disabled="(contentdialog.isLoading  || !contentdialog.tableHeaderForm.$valid)" class="md-green"  ng-click="contentdialog.saveTableHeader(contentdialog)">Add New Table<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '        <md-button  ng-if="contentdialog.currentTab === \'row\'"  ng-disabled="(contentdialog.isLoading  || !contentdialog.tableRowForm.$valid)" class="md-green"  ng-click="contentdialog.saveTableRow(contentdialog)">Add New Row<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '\n' +
    '        <md-button  ng-if="contentdialog.currentTab === \'BGHPBenefitSection\'"  ng-disabled="(contentdialog.isLoading  || !contentdialog.BGHPBenefitSectionForm.$valid)" class="md-green"  ng-click="contentdialog.saveBGHP(contentdialog, \'section\')">Add New Section<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '        <md-button  ng-if="contentdialog.currentTab === \'BGHPBenefitIntro\'"  ng-disabled="(contentdialog.isLoading  || !contentdialog.BGHPBenefitIntroForm.$valid)" class="md-green"  ng-click="contentdialog.saveBGHP(contentdialog, \'intro\')">Add New Section Intro<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '        <md-button  ng-if="contentdialog.currentTab === \'BGHPBenefitSubSection\'"  ng-disabled="(contentdialog.isLoading  || !contentdialog.BGHPBenefitSubSectionForm.$valid)" class="md-green"  ng-click="contentdialog.saveBGHP(contentdialog, \'subSection\')">Add New Sub Section<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '        <md-button  ng-if="contentdialog.currentTab === \'BGHPBenefit\'"  ng-disabled="(contentdialog.isLoading  || !contentdialog.BGHPBenefitForm.$valid)" class="md-green"  ng-click="contentdialog.saveBGHP(contentdialog, \'benefit\')">Add New Benefit<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '\n' +
    '        <md-button  ng-if="contentdialog.currentTab === \'BGHPTermSection\'"  ng-disabled="(contentdialog.isLoading  || !contentdialog.BGHPTermSectionForm.$valid)" class="md-green"  ng-click="contentdialog.saveBGHP(contentdialog, \'termSection\')">Add New Term Section<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '\n' +
    '        <md-button  ng-if="contentdialog.currentTab === \'BGHPTerm\'"  ng-disabled="(contentdialog.isLoading  || !contentdialog.BGHPTermForm.$valid)" class="md-green"  ng-click="contentdialog.saveBGHP(contentdialog, \'term\')">Add New Term<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '\n' +
    '        <md-button  ng-if="contentdialog.currentTab === \'BGHPExclusion\'"  ng-disabled="(contentdialog.isLoading  || !contentdialog.BGHPExclusionForm.$valid)" class="md-green"  ng-click="contentdialog.saveBGHP(contentdialog, \'exclusion\')">Add New Exclusion<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '\n' +
    '        <md-button  ng-if="contentdialog.currentTab === \'BGHPExclusionSection\'"  ng-disabled="(contentdialog.isLoading  || !contentdialog.BGHPExclusionSectionForm.$valid)" class="md-green"  ng-click="contentdialog.saveBGHP(contentdialog, \'exclusionSection\')">Add New Exclusion<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '    </md-dialog-actions>\n' +
    '</md-dialog>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/bottomsheet/bottomsheet.tpl.html',
    '\n' +
    '<md-bottom-sheet class="md-list md-has-header">\n' +
    '    <md-content  layout="row" layout-wrap  layout-align="center start">\n' +
    '        <div class=" default-box">\n' +
    '            <md-subheader></md-subheader>\n' +
    '\n' +
    '\n' +
    '            <div layout="row">\n' +
    '                <div  flex></div>\n' +
    '                <div>\n' +
    '\n' +
    '                    <md-button class="md-grey"  ng-click="bottomsheet.hideBottomSheet()">Cancel<ng-md-icon icon="close" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '      \n' +
    '                    \n' +
    '                    <md-button ng-disabled="bottomsheet.isLoading || bottomsheet.compareObjects(bottomsheet.tempData,bottomsheet.bottomSheetData)" class="md-green"  ng-click="bottomsheet.saveVersion(bottomsheet.bottomSheetData)">Save<ng-md-icon icon="check" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '\n' +
    '                    <md-progress-circular style="position: absolute; top: 0; left: 50%; margin-left: -50px;"  md-diameter="40" md-mode="indeterminate" ng-show="bottomsheet.isLoading"></md-progress-circular>\n' +
    '\n' +
    '                    <md-button aria-label="Open history" class="md-icon-button" id="history-button" ng-click="bottomsheet.showHistory(bottomsheet.bottomSheetData.structureId)">\n' +
    '                        <ng-md-icon icon="history" style="fill: grey;" size="24"></ng-md-icon>\n' +
    '                    </md-button>\n' +
    '\n' +
    '\n' +
    '                    <!--This is for the show and swap versions on same content ID-->\n' +
    '                    <!--<md-button aria-label="Open versions" class="md-icon-button" ng-click="bottomsheet.showVersions(bottomsheet.bottomSheetData.structureId)">-->\n' +
    '                        <!--<ng-md-icon icon="layers" style="fill: grey;" size="24"></ng-md-icon>-->\n' +
    '                    <!--</md-button>-->\n' +
    '\n' +
    '\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '<!--<pre>{{bottomsheet.tempData.benefit | json}}</pre>-->\n' +
    '<!--<pre>{{bottomsheet.bottomSheetData.benefit | json}}</pre>-->\n' +
    '\n' +
    '\n' +
    '            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId <= 4" layout="row" layout-wrap >\n' +
    '\n' +
    '                <div  ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 1"  flex="100"  >\n' +
    '                    <div layout="row">\n' +
    '                        <md-input-container  flex="80">\n' +
    '                            <label>Chapter Type</label>\n' +
    '                            <md-select ng-model="bottomsheet.bottomSheetData.chapterTypeId" placeholder="Chapter Type"  required>\n' +
    '                                <md-option ng-value="types.chapterTypeId" ng-repeat="types in bottomsheet.chapterTypes | orderBy: \'chapterTypeName\'" >{{types.chapterTypeName}}</md-option>\n' +
    '                            </md-select>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                    </div>\n' +
    '                    <div layout="row">\n' +
    '                        <md-input-container ng-if="bottomsheet.bottomSheetData.chapterTypeId === 3" flex="80">\n' +
    '                            <label>QREF</label>\n' +
    '                            <md-select ng-model="bottomsheet.bottomSheetData.chapterLinkId" placeholder="Choose QREF"  required>\n' +
    '                                <md-option ng-value="QREF.bookId" ng-repeat="QREF in bottomsheet.QREFs | filter: {isLive: true}  | orderBy: \'bookCode\'" >{{QREF.bookCode}}</md-option>\n' +
    '                            </md-select>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                    </div>\n' +
    '\n' +
    '\n' +
    '                    <div layout="row" layout-wrap ng-if="bottomsheet.bottomSheetData.chapterTypeId === 2">\n' +
    '                        <div flex="70" layout="row" layout-wrap>\n' +
    '\n' +
    '                            <ul class="file-ui">\n' +
    '                                <li ng-repeat="f in bottomsheet.bottomSheetData.filesList" style="font:smaller" class="file-li animate-if">{{f.fileName}}\n' +
    '                                    <div class="file-prog" ng-mouseenter="f.hovered = true" ng-mouseleave="f.hovered = false">\n' +
    '                                        <md-progress-circular class="animate-if" ng-if="f.determinateValue < 100" md-mode="determinate" value="{{f.determinateValue}}" md-diameter="30"></md-progress-circular>\n' +
    '                                        <span class="animate-ifx" ng-if="f.determinateValue < 100" >{{f.determinateValue}}%</span>\n' +
    '                                        <ng-md-icon class="animate-if file-complete" ng-if="!f.determinateValue || f.determinateValue === 100"  icon="check_circle" size="30"  aria-label="Upload complete"></ng-md-icon>\n' +
    '                                        <ng-md-icon class="file-remove" ng-if="f.hovered"  icon="cancel" size="30"  aria-label="Remove file" ng-click="bottomsheet.removeFile(bottomsheet.bottomSheetData.filesList, $index)"></ng-md-icon>\n' +
    '                                    </div>\n' +
    '                                </li>\n' +
    '                            </ul>\n' +
    '                        </div>\n' +
    '                        <div flex="5"></div>\n' +
    '                        <div flex="25">\n' +
    '                            <div ngf-drop ngf-select ng-model="bottomsheet.chapterFiles" class="drop-box"\n' +
    '                                 ngf-drag-over-class="\'dragover\'" ngf-multiple="false" ngf-allow-dir="true"\n' +
    '                                 accept="image/*"\n' +
    '                                 ngf-pattern="\'image/*\'"><ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop logo image here or<br /><strong style="text-decoration: underline;">Click to upload</strong></div>\n' +
    '                            <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div layout="row" style="padding-top:10px;" layout-wrap ng-if="bottomsheet.bottomSheetData.chapterTypeId === 2 && bottomsheet.bottomSheetData.versions.Contents.contentTypeId == 1">\n' +
    '                        <div flex="70" layout="row" layout-wrap>\n' +
    '\n' +
    '                            <ul class="file-ui">\n' +
    '                                <li ng-repeat="f in bottomsheet.bottomSheetData.PDFList" style="font:smaller" class="file-li animate-if">{{f.fileName}}\n' +
    '                                    <div class="file-prog" ng-mouseenter="f.hovered = true" ng-mouseleave="f.hovered = false">\n' +
    '                                        <md-progress-circular class="animate-if" ng-if="f.determinateValue < 100" md-mode="determinate" value="{{f.determinateValue}}" md-diameter="30"></md-progress-circular>\n' +
    '                                        <span class="animate-ifx" ng-if="f.determinateValue < 100" >{{f.determinateValue}}%</span>\n' +
    '                                        <ng-md-icon class="animate-if file-complete" ng-if="!f.determinateValue || f.determinateValue === 100"  icon="check_circle" size="30"  aria-label="Upload complete"></ng-md-icon>\n' +
    '                                        <ng-md-icon class="file-remove" ng-if="f.hovered" icon="cancel" size="30" aria-label="Remove file" ng-click="bottomsheet.removeFile(bottomsheet.bottomSheetData.PDFList, $index)"></ng-md-icon>\n' +
    '                                    </div>\n' +
    '                                </li>\n' +
    '                            </ul>\n' +
    '                        </div>\n' +
    '                        <div flex="5"></div>\n' +
    '                        <div flex="25">\n' +
    '                            <div ngf-drop ngf-select ng-model="bottomsheet.frontCoverPDF" class="drop-box"\n' +
    '                                 ngf-drag-over-class="\'dragover\'" ngf-multiple="true" ngf-allow-dir="true"\n' +
    '                                 accept="application/pdf"\n' +
    '                                 ngf-pattern="\'application/pdf\'"><ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop front cover PDF here or<br /><strong style="text-decoration: underline;">Click to upload</strong></div>\n' +
    '                            <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <div   flex="100"  ng-if="bottomsheet.bottomSheetData.chapterTypeId != 2 && bottomsheet.bottomSheetData.chapterTypeId != 3 && bottomsheet.bottomSheetData.chapterTypeId != 5 && bottomsheet.bottomSheetData.chapterTypeId != 6 " >\n' +
    '                    <md-input-container  flex="100" ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId == 4" class="cke-container">\n' +
    '                        <label>Text</label>\n' +
    '                        <textarea  ng-model="bottomsheet.bottomSheetData.versions.versiondata" contenteditable="true" ckeditor="bottomsheet.options" md-autofocus></textarea>\n' +
    '                    </md-input-container>\n' +
    '\n' +
    '                    <md-input-container flex="100"  ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId != 4" class="cke-container">\n' +
    '                        <label>Text</label>\n' +
    '                        <textarea  ng-model="bottomsheet.bottomSheetData.versions.versiondata"   contenteditable="true" ckeditor="bottomsheet.options2"  md-autofocus></textarea>\n' +
    '                    </md-input-container>\n' +
    '                </div>\n' +
    '				<div   flex="100" ng-if="bottomsheet.bottomSheetData.chapterTypeId != 2 && bottomsheet.bottomSheetData.chapterTypeId != 3 && bottomsheet.bottomSheetData.chapterTypeId != 5 && bottomsheet.bottomSheetData.chapterTypeId != 6 ">\n' +
    '	                <div id="ckfoot" flex="100"></div>\n' +
    '	                <div id="cktools"  flex="100"></div>\n' +
    '				</div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 5"  class="Benefit"  layout-wrap  layout="row"  flex="100"  style="padding-bottom: 20px;">\n' +
    '\n' +
    '                <div layout="row"  layout-wrap  flex="100">\n' +
    '                <table width="100%">\n' +
    '                    <thead><tr>\n' +
    '                        <th>Benefit</th>\n' +
    '                        <th ng-bind-html="bottomsheet.book.levels.level1.name"></th>\n' +
    '                        <th ng-bind-html="bottomsheet.book.levels.level2.name"></th>\n' +
    '                        <th ng-bind-html="bottomsheet.book.levels.level3.name"></th>\n' +
    '                        <th ng-bind-html="bottomsheet.book.levels.level4.name"></th>\n' +
    '                        <th>Explanation of Benefits</th></tr></thead>\n' +
    '                    <tbody>\n' +
    '                    <tr>\n' +
    '\n' +
    '                        <td width="">\n' +
    '                            <md-input-container><textarea  ng-model="bottomsheet.bottomSheetData.benefit.name" aria-label="Benefit name"  md-autofocus></textarea></md-input-container>\n' +
    '                        </td>\n' +
    '                        <td width="12%">\n' +
    '                            <md-input-container><textarea ng-disabled="bottomsheet.disabledProducts.level1"  ng-model="bottomsheet.bottomSheetData.benefit.level1" aria-label="Benefit level1" ></textarea></md-input-container>\n' +
    '\n' +
    '\n' +
    '                            <md-checkbox ng-change="bottomsheet.updateOption(bottomsheet.bottomSheetData.benefit,\'level1\')" class="linked" ng-model="bottomsheet.bottomSheetData.benefit.option.level1" aria-label="level1">Options</md-checkbox>\n' +
    '\n' +
    '                            <md-radio-group  ng-change="bottomsheet.updateOptions(bottomsheet.bottomSheetData.benefit,\'level1\')" ng-if="bottomsheet.bottomSheetData.benefit.option.level1"  ng-model="bottomsheet.bottomSheetData.benefit.options.level1">\n' +
    '                                <md-radio-button value="Linked">Linked</md-radio-button>\n' +
    '                                <md-radio-button value="Not covered">Not covered</md-radio-button>\n' +
    '                                <md-radio-button value="Paid in full">Paid in full</md-radio-button>\n' +
    '                                <md-radio-button value="Optional">Optional</md-radio-button>\n' +
    '                                <md-radio-button value="Show in summary">Show in summary</md-radio-button>\n' +
    '                            </md-radio-group>\n' +
    '\n' +
    '                        </td>\n' +
    '                        <td width="12%">\n' +
    '                            <md-input-container><textarea  ng-disabled="bottomsheet.disabledProducts.level2"  ng-model="bottomsheet.bottomSheetData.benefit.level2" aria-label="Benefit level2" ></textarea></md-input-container>\n' +
    '\n' +
    '                            <md-checkbox ng-change="bottomsheet.updateOption(bottomsheet.bottomSheetData.benefit,\'level2\')" class="linked" ng-model="bottomsheet.bottomSheetData.benefit.option.level2" aria-label="level2">Options</md-checkbox>\n' +
    '\n' +
    '                            <md-radio-group  ng-change="bottomsheet.updateOptions(bottomsheet.bottomSheetData.benefit,\'level2\')"  ng-if="bottomsheet.bottomSheetData.benefit.option.level2"  ng-model="bottomsheet.bottomSheetData.benefit.options.level2">\n' +
    '                                <md-radio-button value="Linked">Linked</md-radio-button>\n' +
    '                                <md-radio-button value="Not covered">Not covered</md-radio-button>\n' +
    '                                <md-radio-button value="Paid in full">Paid in full</md-radio-button>\n' +
    '                                <md-radio-button value="Optional">Optional</md-radio-button>\n' +
    '                                <md-radio-button value="Show in summary">Show in summary</md-radio-button>\n' +
    '                            </md-radio-group>\n' +
    '\n' +
    '                        </td>\n' +
    '                        <td width="12%">\n' +
    '                            <md-input-container><textarea ng-disabled="bottomsheet.disabledProducts.level3" ng-model="bottomsheet.bottomSheetData.benefit.level3" aria-label="Benefit level3" ></textarea></md-input-container>\n' +
    '\n' +
    '                            <md-checkbox ng-change="bottomsheet.updateOption(bottomsheet.bottomSheetData.benefit,\'level3\')" class="linked" ng-model="bottomsheet.bottomSheetData.benefit.option.level3" aria-label="level3">Options</md-checkbox>\n' +
    '\n' +
    '                            <md-radio-group  ng-change="bottomsheet.updateOptions(bottomsheet.bottomSheetData.benefit,\'level3\')"  ng-if="bottomsheet.bottomSheetData.benefit.option.level3"  ng-model="bottomsheet.bottomSheetData.benefit.options.level3">\n' +
    '                                <md-radio-button value="Linked">Linked</md-radio-button>\n' +
    '                                <md-radio-button value="Not covered">Not covered</md-radio-button>\n' +
    '                                <md-radio-button value="Paid in full">Paid in full</md-radio-button>\n' +
    '                                <md-radio-button value="Optional">Optional</md-radio-button>\n' +
    '                                <md-radio-button value="Show in summary">Show in summary</md-radio-button>\n' +
    '                            </md-radio-group>\n' +
    '\n' +
    '                        </td>\n' +
    '                        <td width="12%">\n' +
    '                            <md-input-container><textarea ng-disabled="bottomsheet.disabledProducts.level4" ng-model="bottomsheet.bottomSheetData.benefit.level4" aria-label="Benefit gold superior" ></textarea></md-input-container>\n' +
    '\n' +
    '                            <md-checkbox  ng-change="bottomsheet.updateOption(bottomsheet.bottomSheetData.benefit,\'level4\')" class="linked" ng-model="bottomsheet.bottomSheetData.benefit.option.level4" aria-label="level4">Options</md-checkbox>\n' +
    '\n' +
    '                            <md-radio-group  ng-change="bottomsheet.updateOptions(bottomsheet.bottomSheetData.benefit,\'level4\')"  ng-if="bottomsheet.bottomSheetData.benefit.option.level4"  ng-model="bottomsheet.bottomSheetData.benefit.options.level4">\n' +
    '                                <md-radio-button value="Linked">Linked</md-radio-button>\n' +
    '                                <md-radio-button value="Not covered">Not covered</md-radio-button>\n' +
    '                                <md-radio-button value="Paid in full">Paid in full</md-radio-button>\n' +
    '                                <md-radio-button value="Optional">Optional</md-radio-button>\n' +
    '                                <md-radio-button value="Show in summary">Show in summary</md-radio-button>\n' +
    '                            </md-radio-group>\n' +
    '\n' +
    '                        </td>\n' +
    '                        <td width="38%" style="position: relative;">\n' +
    '                            <md-input-container class="cke-container">\n' +
    '                                <textarea   ng-model="bottomsheet.bottomSheetData.benefit.explanation"  contenteditable="true" ckeditor="bottomsheet.options3"  placeholder="" aria-label="Benefit explanation"></textarea>\n' +
    '                            </md-input-container>\n' +
    '                            <div id="ckfoot2"></div>\n' +
    '                            <div  style="position: absolute; bottom: -55px;" id="cktools2"></div>\n' +
    '                            <md-checkbox class="linked right"  ng-change="bottomsheet.updateLinked(bottomsheet.bottomSheetData.benefit,\'explanation\')" ng-model="bottomsheet.bottomSheetData.benefit.options.explanation"  ng-true-value="\'Linked\'" ng-false-value="false" aria-label="explanation">Linked</md-checkbox>\n' +
    '                        </td>\n' +
    '                    </tr>\n' +
    '\n' +
    '                    </tbody>\n' +
    '                </table>\n' +
    '</div>\n' +
    '                <div layout="row"  layout-wrap ng-if="bottomsheet.book.levels.payment.active"  flex="100">\n' +
    '                    <md-input-container flex>\n' +
    '                        <label>Payment for treatment</label>\n' +
    '                        <md-select ng-model="bottomsheet.bottomSheetData.benefit.payment" placeholder="Payment for treatment"  required  ng-disabled="bottomsheet.bottomSheetData.benefit.options.payment" >\n' +
    '                            <md-option value="Direct Billing" >Direct Billing</md-option>\n' +
    '                            <md-option value="Non applicable" >Non applicable</md-option>\n' +
    '                            <md-option value="Reimbursement" >Reimbursement</md-option>\n' +
    '                        </md-select>\n' +
    '                    </md-input-container>\n' +
    '                </div>\n' +
    '                <div layout="row"  layout-wrap ng-if="bottomsheet.book.levels.payment.active"  flex="100">\n' +
    '                    <md-checkbox class="linked"  ng-change="bottomsheet.updateLinked(bottomsheet.bottomSheetData.benefit,\'payment\')" ng-model="bottomsheet.bottomSheetData.benefit.options.payment"  ng-true-value="\'Linked\'" ng-false-value="false" aria-label="payment">Linked</md-checkbox>\n' +
    '\n' +
    '                </div>\n' +
    '\n' +
    '\n' +
    '            </div>\n' +
    '            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 6"  class="Benefit Glossary"  layout="row"  flex="100"  style="padding-bottom: 20px;">\n' +
    '                <table width="100%">\n' +
    '                    <thead><tr><th>Defined term</th><th>Description</th><th>Search words</th></tr></thead>\n' +
    '                    <tbody>\n' +
    '                    <tr>\n' +
    '\n' +
    '                        <td width="25%">\n' +
    '                            <md-input-container><textarea  ng-model="bottomsheet.bottomSheetData.glossary.term" aria-label="Glossary term"  md-autofocus></textarea></md-input-container>\n' +
    '                        </td>\n' +
    '\n' +
    '                        <td width="50%" style="position: relative;">\n' +
    '                            <md-input-container class="cke-container">\n' +
    '                                <textarea    ng-model="bottomsheet.bottomSheetData.glossary.description"  contenteditable="true" ckeditor="bottomsheet.options4"  placeholder="" aria-label="Glossary description"></textarea>\n' +
    '                            </md-input-container>\n' +
    '                            <div id="ckfoot3"></div>\n' +
    '                            <div  style="position: absolute; bottom: -55px;" id="cktools3"></div>\n' +
    '                        </td>\n' +
    '\n' +
    '                        <td width="25%">\n' +
    '                            <md-input-container><textarea  ng-model="bottomsheet.bottomSheetData.glossary.words" aria-label="Glossary words" ></textarea></md-input-container>\n' +
    '                        </td>\n' +
    '                    </tr>\n' +
    '\n' +
    '                    </tbody>\n' +
    '                </table>\n' +
    '\n' +
    '            </div>\n' +
    '            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 7"  class="Benefit"  layout="row" layout-wrap  flex="100"  style="padding-bottom: 20px;">\n' +
    '                <div layout="row"  layout-wrap   flex="100">\n' +
    '                    <table width="100%">\n' +
    '                        <thead><tr><th>Exclusion</th><th>Notes</th><th>Rules</th></tr></thead>\n' +
    '                        <tbody>\n' +
    '                        <tr>\n' +
    '\n' +
    '                            <td width="30%">\n' +
    '                                <md-input-container><textarea  ng-model="bottomsheet.bottomSheetData.exclusion.name" aria-label="Exclusion"  md-autofocus></textarea></md-input-container>\n' +
    '                            </td>\n' +
    '\n' +
    '                            <td width="20%">\n' +
    '                                <md-input-container><textarea  ng-model="bottomsheet.bottomSheetData.exclusion.notes" aria-label="Exclusion Notes" ></textarea></md-input-container>\n' +
    '                            </td>\n' +
    '\n' +
    '                            <td width="50%" style="position: relative;">\n' +
    '                                <md-input-container class="cke-container">\n' +
    '                                    <textarea    ng-model="bottomsheet.bottomSheetData.exclusion.rules"  contenteditable="true" ckeditor="bottomsheet.options4"  placeholder="" aria-label="Exclusion Rules"></textarea>\n' +
    '                                </md-input-container>\n' +
    '                                <div id="ckfoot3"></div>\n' +
    '                                <div  style="position: absolute; bottom: -55px;" id="cktools3"></div>\n' +
    '                            </td>\n' +
    '                        </tr>\n' +
    '\n' +
    '                        </tbody>\n' +
    '                    </table>\n' +
    '                </div>\n' +
    '                <div style="position: relative; top:-30px;"  flex="50">\n' +
    '                    <md-checkbox ng-model="bottomsheet.bottomSheetData.exclusion.level1" >{{bottomsheet.book.levels.level1.name}}</md-checkbox>\n' +
    '                    <md-checkbox ng-model="bottomsheet.bottomSheetData.exclusion.level2" >{{bottomsheet.book.levels.level2.name}}</md-checkbox>\n' +
    '                    <md-checkbox ng-model="bottomsheet.bottomSheetData.exclusion.level3" >{{bottomsheet.book.levels.level3.name}}</md-checkbox>\n' +
    '                    <md-checkbox ng-model="bottomsheet.bottomSheetData.exclusion.level4" >{{bottomsheet.book.levels.level4.name}}</md-checkbox>\n' +
    '\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 8"  class="Benefit Glossary"  layout="row"  flex="100"  style="padding-bottom: 20px;">\n' +
    '                <table width="100%">\n' +
    '                    <thead><tr><th>Term</th><th>Description</th></tr></thead>\n' +
    '                    <tbody>\n' +
    '                    <tr>\n' +
    '\n' +
    '                        <td width="40%">\n' +
    '                            <md-input-container><textarea  ng-model="bottomsheet.bottomSheetData.medical.term" aria-label="Medical term"  md-autofocus></textarea></md-input-container>\n' +
    '                        </td>\n' +
    '\n' +
    '                        <td width="60%" style="position: relative;">\n' +
    '                            <md-input-container class="cke-container">\n' +
    '                                <textarea    ng-model="bottomsheet.bottomSheetData.medical.description"  contenteditable="true" ckeditor="bottomsheet.options4"  placeholder="" aria-label="Medical description"></textarea>\n' +
    '                            </md-input-container>\n' +
    '                            <div id="ckfoot3"></div>\n' +
    '                            <div  style="position: absolute; bottom: -55px;" id="cktools3"></div>\n' +
    '                        </td>\n' +
    '                    </tr>\n' +
    '\n' +
    '                    </tbody>\n' +
    '                </table>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 9" layout="row" layout-wrap >\n' +
    '\n' +
    '\n' +
    '\n' +
    '                <div layout="row" layout-wrap>\n' +
    '                    <div flex="70" layout="row" layout-wrap>\n' +
    '                        <div flex="100">\n' +
    '                            <md-input-container class="cke-container">\n' +
    '                                <label>Brief description</label>\n' +
    '                                <textarea  ng-model="bottomsheet.bottomSheetData.brief.description"  contenteditable="true" ckeditor="bottomsheet.options"></textarea>\n' +
    '                            </md-input-container>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                            <div id="cktools" flex></div>\n' +
    '                            <div id="ckfoot"></div>\n' +
    '                        </div>\n' +
    '                        <ul class="file-ui">\n' +
    '                            <li ng-repeat="f in bottomsheet.bottomSheetData.brief.filesList" style="font:smaller" class="file-li animate-if">{{f.fileName}}\n' +
    '                                <div class="file-prog" ng-mouseenter="f.hovered = true" ng-mouseleave="f.hovered = false">\n' +
    '                                    <md-progress-circular class="animate-if" ng-if="f.determinateValue < 100" md-mode="determinate" value="{{f.determinateValue}}" md-diameter="30"></md-progress-circular>\n' +
    '                                    <span class="animate-ifx" ng-if="f.determinateValue < 100" >{{f.determinateValue}}%</span>\n' +
    '                                    <ng-md-icon class="animate-if file-complete" ng-if="!f.determinateValue || f.determinateValue === 100"  icon="check_circle" size="30"  aria-label="Upload complete"></ng-md-icon>\n' +
    '                                    <ng-md-icon class="file-remove" ng-if="f.hovered"  icon="cancel" size="30"  aria-label="Remove file" ng-click="bottomsheet.removeFile(bottomsheet.bottomSheetData.brief.filesList, $index)"></ng-md-icon>\n' +
    '                                </div>\n' +
    '                            </li>\n' +
    '                        </ul>\n' +
    '                    </div>\n' +
    '                    <div flex="5"></div>\n' +
    '                    <div flex="25">\n' +
    '                        <div ngf-drop ngf-select ng-model="bottomsheet.briefFiles" class="drop-box"\n' +
    '                             ngf-drag-over-class="\'dragover\'" ngf-multiple="true" ngf-allow-dir="true"\n' +
    '                             accept="{{bottomsheet.safeFileList}}"\n' +
    '                             ngf-pattern="bottomsheet.safeFileList">\n' +
    '                                <ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop files here or<br /><strong style="text-decoration: underline;">Click to upload</strong>d\n' +
    '                        </div>\n' +
    '                        <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 10 || bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 13" layout="row" layout-wrap >\n' +
    '\n' +
    '\n' +
    '                <div layout="row" layout-wrap>\n' +
    '                    <div flex="70" layout="row" layout-wrap>\n' +
    '                        <div flex="100">\n' +
    '                            <md-input-container class="cke-container">\n' +
    '                                <label>Artwork description</label>\n' +
    '                                <textarea  ng-model="bottomsheet.bottomSheetData.artwork.description"  contenteditable="true" ckeditor="bottomsheet.options"></textarea>\n' +
    '                            </md-input-container>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                            <div id="cktools" flex></div>\n' +
    '                            <div id="ckfoot"></div>\n' +
    '                        </div>\n' +
    '                        <div class="file-ui downloads-container">\n' +
    '                            <!-- <li ng-repeat="f in bottomsheet.bottomSheetData.artwork.filesList" style="font:smaller" class="file-li animate-if">{{f.fileName}}\n' +
    '                                <div class="file-prog" ng-mouseenter="f.hovered = true" ng-mouseleave="f.hovered = false">\n' +
    '                                    <md-progress-circular class="animate-if" ng-if="f.determinateValue < 100" md-mode="determinate" value="{{f.determinateValue}}" md-diameter="30"></md-progress-circular>\n' +
    '                                    <span class="animate-ifx" ng-if="f.determinateValue < 100" >{{f.determinateValue}}%</span>\n' +
    '                                    <ng-md-icon class="animate-if file-complete" ng-if="!f.determinateValue || f.determinateValue === 100"  icon="check_circle" size="30"  aria-label="Upload complete"></ng-md-icon>\n' +
    '                                    <ng-md-icon class="file-remove" ng-if="f.hovered"  icon="cancel" size="30"  aria-label="Remove file"  ng-click="bottomsheet.removeFile(bottomsheet.bottomSheetData.artwork.filesList, $index)"></ng-md-icon>\n' +
    '                                </div>\n' +
    '                            </li> -->\n' +
    '\n' +
    '                            <div ng-repeat="upload in bottomsheet.bottomSheetData.artwork.filesList" layout="row" layout-wrap layout-padding="20" class="download-row small"  ng-class="{\'file-name-error\': !bottomsheet.filenameInCodeCheck(upload.fileName,bottomsheet.book.bookCode)}">\n' +
    '                                <md-tooltip ng-if="!bottomsheet.filenameInCodeCheck(upload.fileName,bottomsheet.book.bookCode);" class="statbar"  md-direction="top">This filename does not contain the current job code</md-tooltip>                      \n' +
    '                                <a href="{{upload.ngfName|fileurl}}" target="_blank">\n' +
    '                                    <div class="thumbnail-block" wraps-tooltip="artwork-upload-thumb">\n' +
    '\n' +
    '                                        <md-progress-circular  ng-if="upload.determinateValue < 100" md-mode="determinate" value="{{upload.determinateValue}}"></md-progress-circular>\n' +
    '                                        <md-progress-circular ng-if="upload.determinateValue === 100 && upload.isLoading"></md-progress-circular>\n' +
    '                                    \n' +
    '                                        <img ng-if="!upload.isLoading" class="thumbnail-preview" ng-src="{{upload.ngfName|thumburl}}" err-SRC="/images/wraps-logo.png" alt="{{upload.fileName}}"/>\n' +
    '                                        <div class="overlay text-center"><ng-md-icon icon="file_download" size="24"></ng-md-icon></div>\n' +
    '                                    </div>\n' +
    '                                </a>\n' +
    '                                <div flex>\n' +
    '                                    <h3 md-truncate>{{upload.fileName}}</h3>\n' +
    '                                    <h3 md-truncate>{{upload.fileSize | filesize}} <a class="md-accent" style="cursor:pointer; font-weight: bold;" ng-click="bottomsheet.removeFile(bottomsheet.bottomSheetData.artwork.filesList, $index)">Remove</a></h3>\n' +
    '                                </div>\n' +
    '                                <div flex="100" class="divider"><div></div></div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div flex="5"></div>\n' +
    '                    <div flex="25">\n' +
    '                        <div ngf-drop ngf-select ng-model="bottomsheet.artworkFiles" class="drop-box"\n' +
    '                             ngf-drag-over-class="\'dragover\'" ngf-multiple="true" ngf-allow-dir="true"\n' +
    '                             accept="{{bottomsheet.safeFileList}}"\n' +
    '                             ngf-pattern="bottomsheet.safeFileList">\n' +
    '                            <ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop files here or<br /><strong style="text-decoration: underline;">Click to upload</strong>\n' +
    '                        </div>\n' +
    '                        <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div flex="50" class="downloads-container" ng-if="addartwork.filesList.length > 0">\n' +
    '                            <h5>Uploads</h5>\n' +
    '                            \n' +
    '                    </div>\n' +
    '\n' +
    '                </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 11"  class="Benefit Table"  layout="row"  flex="100"  style="padding-bottom: 20px;">\n' +
    '\n' +
    '                <table width="100%" class="workflow-table">\n' +
    '                    <tr>\n' +
    '                        <th colspan="100" width="60%" align="left">NAME</th>\n' +
    '                    </tr>\n' +
    '                    <tr >\n' +
    '                        <td ng-repeat="item in bottomsheet.bottomSheetData.tableHeader" >\n' +
    '                            <div layout="row" flex="100">\n' +
    '                                <md-input-container flex="100" >\n' +
    '                                    <label>Column Name</label>\n' +
    '                                    <input ng-model="item.name">\n' +
    '                                </md-input-container>\n' +
    '                            </div>\n' +
    '                            <div layout="row" flex="100">\n' +
    '                                <md-input-container flex="70" >\n' +
    '                                    <label>Width %</label>\n' +
    '                                    <input ng-model="item.width">\n' +
    '                                </md-input-container>\n' +
    '                            </div>\n' +
    '                        </td>\n' +
    '                    </tr>\n' +
    '                </table>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 12"  class="Benefit Table"  layout="row"  flex="100"  style="padding-bottom: 20px;">\n' +
    '\n' +
    '\n' +
    '                <div flex="100" layout="row" layout-wrap>\n' +
    '                    <div flex="100">\n' +
    '                        <table width="100%" >\n' +
    '                            <thead>\n' +
    '                            <tr>\n' +
    '                                <th ng-repeat="item in bottomsheet.bottomSheetData.tableHeader | orderBy:\'order\'"  width="{{bottomsheet.getTableWidths(item.width, bottomsheet.bottomSheetData.tableHeader)}}%">\n' +
    '                                    <div ng-bind-html="item.name"></div>\n' +
    '                                </th>\n' +
    '                            </tr>\n' +
    '                            </thead>\n' +
    '                            <tbody>\n' +
    '                            <tr>\n' +
    '                                <td ng-repeat="item in bottomsheet.bottomSheetData.tableHeader | orderBy:\'order\'" >\n' +
    '                                    <md-input-container class="cke-container">\n' +
    '                                        <textarea ng-model="bottomsheet.bottomSheetData.tableRow[item.index].data"   contenteditable="true" ckeditor="bottomsheet.options"></textarea>\n' +
    '                                    </md-input-container>\n' +
    '                                </td>\n' +
    '                            </tr>\n' +
    '                            </tbody>\n' +
    '\n' +
    '                        </table>\n' +
    '                    </div>\n' +
    '                    <div flex="100">\n' +
    '                        <div id="ckfoot"></div>\n' +
    '                        <div  style="" id="cktools"></div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 14 || bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 15 || bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 18 || bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 21"  class="Benefit Table"  layout="row"  flex="100"  style="padding-bottom: 20px;">\n' +
    '                <div flex="100" layout="row" layout-wrap>\n' +
    '                    <div flex="100">\n' +
    '                        <md-input-container flex class="cke-container">\n' +
    '                            <label>Heading text</label>\n' +
    '                            <textarea  ng-model="bottomsheet.bottomSheetData.versions.versiondata" contenteditable="true" ckeditor="bottomsheet.options2" md-autofocus></textarea>\n' +
    '                        </md-input-container>\n' +
    '                    </div>\n' +
    '                    <div flex="100">\n' +
    '                        <div id="ckfoot"></div>\n' +
    '                        <div  id="cktools"></div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 16 || bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 19"  class="Benefit Table"  layout="row"  flex="100"  style="padding-bottom: 20px;">\n' +
    '                <div flex="100" layout="row" layout-wrap>\n' +
    '                    <div flex="100">\n' +
    '                        <md-input-container flex class="cke-container">\n' +
    '                            <label>Clause</label>\n' +
    '                            <textarea  ng-model="bottomsheet.bottomSheetData.versions.versiondata" contenteditable="true" ckeditor="bottomsheet.options" md-autofocus></textarea>\n' +
    '                        </md-input-container>\n' +
    '                    </div>\n' +
    '                    <div flex="100">\n' +
    '                        <div id="ckfoot"></div>\n' +
    '                        <div  id="cktools"></div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 17"  class="Benefit Table"  layout="row"  flex="100"  style="padding-bottom: 20px;">\n' +
    '                <div flex="100" layout="row" layout-wrap>\n' +
    '                    <div flex="100">\n' +
    '\n' +
    '\n' +
    '                        <table width="100%">\n' +
    '                            <thead>\n' +
    '                            <tr>\n' +
    '                                <th>Benefits</th>\n' +
    '                                <th>Explanation</th>\n' +
    '                                <th>Limit</th>\n' +
    '                            </tr>\n' +
    '                            </thead>\n' +
    '                            <tbody>\n' +
    '                            <tr>\n' +
    '                                <td width="30%"><md-input-container><textarea  ng-model="bottomsheet.bottomSheetData.benefit.name" aria-label="Benefit name" required></textarea></md-input-container></td>\n' +
    '\n' +
    '                                <td width="50%" style="position: relative;">\n' +
    '                                    <md-input-container class="cke-container">\n' +
    '                                        <label>Benefit explanation</label>\n' +
    '                                        <textarea    ng-model="bottomsheet.bottomSheetData.benefit.explanation"  contenteditable="true" ckeditor="bottomsheet.options"  placeholder="" aria-label="Benefit explanation"></textarea>\n' +
    '                                    </md-input-container>\n' +
    '                                    <div id="ckfoot"></div>\n' +
    '                                    <div  style="position: absolute; bottom: -55px;" id="cktools"></div>\n' +
    '                                </td>\n' +
    '\n' +
    '                                <td width="20%">\n' +
    '                                    <md-input-container><textarea ng-disabled="bottomsheet.bottomSheetData.benefit.options.limit"  ng-model="bottomsheet.bottomSheetData.benefit.limit" aria-label="Benefit limit" ></textarea></md-input-container>\n' +
    '\n' +
    '                                    <md-checkbox ng-change="bottomsheet.updateOption(bottomsheet.bottomSheetData.benefit,\'limit\')" class="linked" ng-model="bottomsheet.bottomSheetData.benefit.option.limit" aria-label="limit">Options</md-checkbox>\n' +
    '\n' +
    '                                    <md-radio-group  ng-change="bottomsheet.updateOptions(bottomsheet.bottomSheetData.benefit,\'limit\')" ng-if="bottomsheet.bottomSheetData.benefit.option.limit"  ng-model="bottomsheet.bottomSheetData.benefit.options.limit">\n' +
    '                                        <md-radio-button value="Linked">Linked</md-radio-button>\n' +
    '                                        <md-radio-button value="Not covered">Not covered</md-radio-button>\n' +
    '                                        <md-radio-button value="Paid in full">Paid in full</md-radio-button>\n' +
    '                                    </md-radio-group>\n' +
    '\n' +
    '                                </td>\n' +
    '                            </tr>\n' +
    '\n' +
    '                            </tbody>\n' +
    '                        </table>\n' +
    '\n' +
    '\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '            <div ng-if="bottomsheet.bottomSheetData.versions.Contents.contentTypeId === 20"  class="Benefit"  layout="row" layout-wrap  flex="100"  style="padding-bottom: 20px;">\n' +
    '                <div layout="row"  layout-wrap   flex="100">\n' +
    '                    <table width="100%">\n' +
    '                        <thead><tr><th>Exclusion</th><th>Rules</th></tr></thead>\n' +
    '                        <tbody>\n' +
    '                        <tr>\n' +
    '\n' +
    '                            <td width="30%">\n' +
    '                                <md-input-container><textarea  ng-model="bottomsheet.bottomSheetData.exclusion.name" aria-label="Exclusion"  md-autofocus></textarea></md-input-container>\n' +
    '                            </td>\n' +
    '\n' +
    '\n' +
    '                            <td width="70%" style="position: relative;">\n' +
    '                                <md-input-container class="cke-container">\n' +
    '                                    <textarea    ng-model="bottomsheet.bottomSheetData.exclusion.rules"  contenteditable="true" ckeditor="bottomsheet.options4"  placeholder="" aria-label="Exclusion Rules"></textarea>\n' +
    '                                </md-input-container>\n' +
    '                                <div id="ckfoot3"></div>\n' +
    '                                <div  style="position: absolute; bottom: -55px;" id="cktools3"></div>\n' +
    '                            </td>\n' +
    '                        </tr>\n' +
    '\n' +
    '                        </tbody>\n' +
    '                    </table>\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '    </md-content>\n' +
    '</md-bottom-sheet>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/chaptersdialog/chaptersdialog.tpl.html',
    '<md-dialog aria-label="Add Chapter"  class="chaptersdialog">\n' +
    '\n' +
    '    <md-toolbar>\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h2>Add Chapter</h2>\n' +
    '            <span flex></span>\n' +
    '\n' +
    '            <md-button ng-click="chaptersdialog.dialogCancel(false)" class="md-icon-button">\n' +
    '                <ng-md-icon  icon="close" size="24"  aria-label="Close dialog"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content class="dialog-content big" style="">\n' +
    '\n' +
    '        <md-tabs md-dynamic-height md-border-bottom md-selected="chaptersdialog.tabIndex">\n' +
    '                <md-tab label="Existing Chapter">\n' +
    '                    <md-content class="md-padding"><br />\n' +
    '                        <form ng-submit="$event.preventDefault()">\n' +
    '                            <md-autocomplete\n' +
    '                                    md-selected-item="chaptersdialog.selectedItem"\n' +
    '                                    md-search-text="chaptersdialog.searchText"\n' +
    '                                    md-selected-item-change="chaptersdialog.getChapters(chaptersdialog.selectedItem.bookId)"\n' +
    '                                    md-items="item in chaptersdialog.querySearch(chaptersdialog.searchText)"\n' +
    '                                    md-item-text="item.bookCode"\n' +
    '                                    md-min-length="1"\n' +
    '                                    ng-model-options="{ debounce: 200 }"\n' +
    '                                    placeholder="Please select a book"\n' +
    '                                    md-menu-class="autocomplete-custom-template"\n' +
    '                            <!--md-select-on-match="true"-->\n' +
    '                            md-autoselect="true">\n' +
    '                            <md-item-template><span class="item-title"  md-highlight-text="chaptersdialog.searchText" md-highlight-flags="^i">{{item.bookCode}} </span></md-item-template>\n' +
    '                                <md-not-found>\n' +
    '                                    No matches found.\n' +
    '                                </md-not-found>\n' +
    '                                </md-autocomplete>\n' +
    '                        </form>\n' +
    '\n' +
    '                        <div ng-show="chaptersdialog.chapters">\n' +
    '                            <h3>Select chapters to copy:</h3>\n' +
    '                            <div ng-repeat="chapter in chaptersdialog.chapters | orderBy: order">\n' +
    '                                <md-checkbox ng-model="chapter.selected"  ng-disabled="chapter.disabled" ng-change="chaptersdialog.setSelectedChapters(chapter)" >\n' +
    '                                    <div ng-bind-html="chapter.versions.versiondata"></div>\n' +
    '                                </md-checkbox>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '\n' +
    '                        <!--<pre>{{chaptersdialog.testbook | json}}</pre>-->\n' +
    '                    </md-content>\n' +
    '                </md-tab>\n' +
    '                <md-tab label="New Chapter">\n' +
    '                    <md-content class="md-padding">\n' +
    '                        <br />\n' +
    '                        <form ng-submit="$event.preventDefault()" name="chapterForm" layout="column"  flex="90">\n' +
    '                            <!--<md-input-container>-->\n' +
    '                                <!--<label>Chapter description</label>-->\n' +
    '                                <!--<input ng-model="chaptersdialog.chapterName"   ng-minlength="3" required>-->\n' +
    '\n' +
    '                            <!--</md-input-container>-->\n' +
    '\n' +
    '\n' +
    '                            <md-input-container>\n' +
    '                                <label>Content Owner</label>\n' +
    '                                <md-select ng-model="chaptersdialog.chapterTeam" placeholder="Content Owner"    ng-model-options="{trackBy: \'$value.teamId\'}" required>\n' +
    '                                    <md-option ng-value="team" ng-repeat="team in chaptersdialog.teams | orderBy: \'teamName\'" >{{team.teamName}}</md-option>\n' +
    '                                </md-select>\n' +
    '                            </md-input-container>\n' +
    '\n' +
    '                            <md-input-container flex="80">\n' +
    '                                <label>Chapter Type</label>\n' +
    '                                <md-select ng-model="chaptersdialog.chapterType" placeholder="Chapter Type" ng-init="chaptersdialog.chapterType = chaptersdialog.chapterTypes[0]"    ng-model-options="{trackBy: \'$value.chapterTypeId\'}" required>\n' +
    '                                    <md-option ng-value="types" ng-repeat="types in chaptersdialog.chapterTypes | orderBy: \'chapterTypeName\'" >{{types.chapterTypeName}}</md-option>\n' +
    '                                </md-select>\n' +
    '                            </md-input-container>\n' +
    '\n' +
    '                            <md-input-container ng-if="chaptersdialog.chapterType.chapterTypeId === 3">\n' +
    '                                <label>QREF</label>\n' +
    '                                <md-select ng-model="chaptersdialog.QREF" placeholder="Choose QREF"  ng-model-options="{trackBy: \'$value.bookId\'}" required>\n' +
    '                                    <md-option ng-value="QREF" ng-repeat="QREF in chaptersdialog.QREFs | filter: {isLive: true} | orderBy: \'bookCode\'" >{{QREF.bookCode}}</md-option>\n' +
    '                                </md-select>\n' +
    '                            </md-input-container>\n' +
    '\n' +
    '\n' +
    '                            <div layout="row" layout-wrap ng-if="chaptersdialog.chapterType.chapterTypeId === 2">\n' +
    '                                <div flex="70" layout="row" layout-wrap>\n' +
    '\n' +
    '                                    <ul class="file-ui">\n' +
    '                                        <li ng-repeat="f in chaptersdialog.chapterType.filesList" style="font:smaller" class="file-li animate-if">{{f.fileName}}\n' +
    '                                            <div class="file-prog" ng-mouseenter="f.hovered = true" ng-mouseleave="f.hovered = false">\n' +
    '                                                <md-progress-circular class="animate-if" ng-if="f.determinateValue < 100" md-mode="determinate" value="{{f.determinateValue}}" md-diameter="30"></md-progress-circular>\n' +
    '                                                <span class="animate-ifx" ng-if="f.determinateValue < 100" >{{f.determinateValue}}%</span>\n' +
    '                                                <ng-md-icon class="animate-if file-complete" ng-if="f.determinateValue === 100"  icon="check_circle" size="30"  aria-label="Upload complete"></ng-md-icon>\n' +
    '                                                <ng-md-icon class="file-remove" ng-if="f.hovered"  icon="cancel" size="30"  aria-label="Remove file" ng-click="chaptersdialog.removeFile(chaptersdialog.chapterType.filesList, $index)"></ng-md-icon>\n' +
    '                                            </div>\n' +
    '                                        </li>\n' +
    '                                    </ul>\n' +
    '                                </div>\n' +
    '                                <div flex="5"></div>\n' +
    '                                <div flex="25">\n' +
    '                                    <div ngf-drop ngf-select ng-model="chaptersdialog.chapterFiles" class="drop-box"\n' +
    '                                         ngf-drag-over-class="\'dragover\'" ngf-multiple="false" ngf-allow-dir="true"\n' +
    '                                         accept="image/*"\n' +
    '                                         ngf-pattern="\'image/*\'"><ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop logo image here or<br /><strong style="text-decoration: underline;">Click to upload</strong></div>\n' +
    '                                    <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                            <div layout="row" style="padding-top:10px;" layout-wrap ng-if="chaptersdialog.chapterType.chapterTypeId === 2">\n' +
    '                                <div flex="70" layout="row" layout-wrap>\n' +
    '\n' +
    '                                    <ul class="file-ui">\n' +
    '                                        <li ng-repeat="f in chaptersdialog.chapterType.PDFList" style="font:smaller" class="file-li animate-if">{{f.fileName}}\n' +
    '                                            <div class="file-prog" ng-mouseenter="f.hovered = true" ng-mouseleave="f.hovered = false">\n' +
    '                                                <md-progress-circular class="animate-if" ng-if="f.determinateValue < 100" md-mode="determinate" value="{{f.determinateValue}}" md-diameter="30"></md-progress-circular>\n' +
    '                                                <span class="animate-ifx" ng-if="f.determinateValue < 100" >{{f.determinateValue}}%</span>\n' +
    '                                                <ng-md-icon class="animate-if file-complete" ng-if="f.determinateValue === 100"  icon="check_circle" size="30"  aria-label="Upload complete"></ng-md-icon>\n' +
    '                                                <ng-md-icon class="file-remove" ng-if="f.hovered" icon="cancel" size="30" aria-label="Remove file" ng-click="chaptersdialog.removeFile(chaptersdialog.chapterType.PDFList, $index)"></ng-md-icon>\n' +
    '                                            </div>\n' +
    '                                        </li>\n' +
    '                                    </ul>\n' +
    '                                </div>\n' +
    '                                <div flex="5"></div>\n' +
    '                                <div flex="25">\n' +
    '                                    <div ngf-drop ngf-select ng-model="chaptersdialog.frontCoverPDF" class="drop-box"\n' +
    '                                         ngf-drag-over-class="\'dragover\'" ngf-multiple="false" ngf-allow-dir="true"\n' +
    '                                         accept="application/pdf"\n' +
    '                                         ngf-pattern="\'application/pdf\'"><ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop front cover PDF here or<br /><strong style="text-decoration: underline;">Click to upload</strong></div>\n' +
    '                                    <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                            <md-input-container  ng-if="chaptersdialog.chapterType.chapterTypeId != 3 && chaptersdialog.chapterType.chapterTypeId != 2 && chaptersdialog.chapterType.chapterTypeId != 5 && chaptersdialog.chapterType.chapterTypeId != 6">\n' +
    '                                <label>Chapter copy</label>\n' +
    '                                <input ng-model="chaptersdialog.chapterData"   ng-minlength="3" required>\n' +
    '                            </md-input-container>\n' +
    '\n' +
    '                        </form>\n' +
    '                    </md-content>\n' +
    '                </md-tab>\n' +
    '</md-tabs>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '        <md-progress-linear ng-if="chaptersdialog.isLoading" md-mode="indeterminate" style="position: absolute;"></md-progress-linear>\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions layout="row">\n' +
    '\n' +
    '        <span flex></span>\n' +
    '        <md-button class="md-grey"  ng-click="chaptersdialog.dialogCancel(false)">Cancel<ng-md-icon icon="close" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '        <md-button ng-if="(chaptersdialog.tabIndex==0)" ng-disabled="(chaptersdialog.isLoading || chaptersdialog.selectedChapters.length==0)" class="md-green"  ng-click="chaptersdialog.addChapters(chaptersdialog.chapters)">Add<ng-md-icon icon="add" style="fill: white; position: relative; top:4px; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '        <md-button ng-if="(chaptersdialog.tabIndex==1)" ng-disabled="(chaptersdialog.isLoading || !chapterForm.$valid)" class="md-green"  ng-click="chaptersdialog.addNewChapter(chaptersdialog)">Add New<ng-md-icon icon="add" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '\n' +
    '    </md-dialog-actions>\n' +
    '</md-dialog>\n' +
    '\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/changedialog/changedialog.tpl.html',
    '<md-dialog aria-label="Reason for Change "  class="changedialog"   ng-cloak>\n' +
    '\n' +
    '    <md-toolbar>\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h2 ng-if="changedialog.filter != \'rfd\' && changedialog.filter != \'rfe\'">Reason for Change</h2>\n' +
    '            <h2 ng-if="changedialog.filter === \'rfd\'">Reason for Duplication</h2>\n' +
    '            <h2 ng-if="changedialog.filter === \'rfe\'">Reason for Expiry</h2>\n' +
    '            <span flex></span>\n' +
    '\n' +
    '            <md-button ng-click="changedialog.dialogCancel(false)" class="md-icon-button">\n' +
    '                <ng-md-icon  icon="close" size="24"  aria-label="Close dialog"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content  class="dialog-content big" >\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '        <md-content class="md-padding" flex="100">\n' +
    '            <form ng-submit="$event.preventDefault()" name="rejectForm" layout="column" style="padding: 20px;">\n' +
    '\n' +
    '                <md-input-container class="md-block">\n' +
    '                    <label>Change Category</label>\n' +
    '                    <md-select name="reason1" ng-model="changedialog.reason.reason"  required>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                        <md-option ng-repeat="category in changedialog.rfcReasons | filter: {\'type\':changedialog.filter} | orderBy:\'name\' track by category.id" value="{{category.id}}">{{category.name}}</md-option>\n' +
    '                    </md-select>\n' +
    '\n' +
    '                </md-input-container>\n' +
    '\n' +
    '\n' +
    '                <md-input-container class="md-block">\n' +
    '                    <label>Change message</label>\n' +
    '                    <textarea name="message" ng-model="changedialog.reason.message"   rows="3" required></textarea>\n' +
    '                </md-input-container>\n' +
    '                <div class="errors" ng-messages="rejectForm.message.$error" ng-if="rejectForm.$dirty">\n' +
    '                    <div ng-message="required">Required</div>\n' +
    '                </div>\n' +
    '\n' +
    '\n' +
    '                <md-input-container class="md-block" ng-if="changedialog.filter != \'rfd\' && !changedialog.noCheckbox && changedialog.filter != \'rfe\'">\n' +
    '\n' +
    '\n' +
    '                    <md-checkbox ng-model="changedialog.reason.significant">Significant</md-checkbox>\n' +
    '\n' +
    '\n' +
    '                </md-input-container>\n' +
    '            </form>\n' +
    '\n' +
    '        </md-content>\n' +
    '\n' +
    '\n' +
    '\n' +
    '    </md-dialog-content>\n' +
    '\n' +
    '    <md-dialog-actions>\n' +
    '\n' +
    '        <md-button ng-if="$storage.lastRFC" ng-click="changedialog.reason = changedialog.getRFC(changedialog.reason)">Use last RFC</md-button>\n' +
    '        <span flex></span>\n' +
    '        <md-button class="md-grey"  ng-click="changedialog.dialogCancel(false)">Cancel<ng-md-icon icon="close" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '        <md-button  ng-disabled="!(changedialog.reason.reason && changedialog.reason.message) || changedialog.isLoading" class="md-green"  ng-click="changedialog.dialogHide(changedialog.reason);">Submit Changes<ng-md-icon icon="done" style="fill: white; position: relative; padding-left: 4px; " size="20"></ng-md-icon></md-button>\n' +
    '\n' +
    '    </md-dialog-actions>\n' +
    '</md-dialog>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/approvedialog/approvedialog.tpl.html',
    '<md-dialog aria-label="Approve" style="min-width:600px; overflow:hidden;"  ng-cloak>\n' +
    '\n' +
    '    <md-toolbar>\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h2>{{approvedialog.action}}</h2>\n' +
    '            <span flex></span>\n' +
    '\n' +
    '            <md-button ng-click="approvedialog.dialogCancel(false)" class="md-icon-button">\n' +
    '                <ng-md-icon  icon="close" size="24"  aria-label="Close dialog"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content class="dialog-content" style=""  md-autofocus>\n' +
    '        <div style="padding: 20px;"  ng-if="!approvedialog.isPublishing">\n' +
    '\n' +
    '            <div layout="column" ng-if="!approvedialog.showApproveAll">\n' +
    '                <div flex>\n' +
    '                    <md-checkbox ng-model="approvedialog.checkboxes.this" ng-init="approvedialog.checkboxes.this = true;" aria-label="Checkbox 1">\n' +
    '                    <label>{{approvedialog.action}} this item</label>\n' +
    '                    </md-checkbox>\n' +
    '                </div>\n' +
    '                <div flex ng-if="approvedialog.hasChildren">\n' +
    '                    <md-checkbox ng-model="approvedialog.checkboxes.all" aria-label="Checkbox 2">\n' +
    '                        <label>Approve all children of this item</label>\n' +
    '                    </md-checkbox>\n' +
    '                </div>\n' +
    '                <div ng-if="approvedialog.beforeList.length > 0" layout="row">\n' +
    '                    <div flex="100" class="downloads-container" ng-if="approvedialog.beforeList.length > 0">\n' +
    '                        <h5>Artwork draft</h5>\n' +
    '                        <div ng-repeat="upload in approvedialog.beforeList" layout="row" layout-wrap layout-padding="20" class="download-row small" ng-class="{\'file-name-error\': !approvedialog.filenameInCodeCheck(upload.fileName,approvedialog.book.bookCode)}">\n' +
    '                            <md-tooltip ng-if="!approvedialog.filenameInCodeCheck(upload.fileName,approvedialog.book.bookCode);" class="statbar"  md-direction="top">This filename does not contain the current job code</md-tooltip>           \n' +
    '                            <a href="{{upload.ngfName|fileurl}}" target="_blank">\n' +
    '                                <div class="thumbnail-block" wraps-tooltip="artwork-upload-thumb">\n' +
    '                                    <img class="thumbnail-preview" ng-src="{{upload.ngfName|thumburl}}" err-SRC="/images/wraps-logo.png" alt="{{upload.fileName}}"/>\n' +
    '                                    <div class="overlay text-center"><h3><ng-md-icon icon="file_download" size="24"></ng-md-icon>{{upload.fileSize | filesize}}</h3></div>\n' +
    '                                </div>\n' +
    '                            </a>\n' +
    '                            <div flex>\n' +
    '                                <h3 >{{upload.fileName}}</h3>\n' +
    '                                <h3 >{{upload.fileSize | filesize}}</h3>\n' +
    '                            </div>\n' +
    '                            <div flex="100" class="divider"><div></div></div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div flex="50" class="downloads-container" ng-if="approvedialog.afterList.length > 0">\n' +
    '                        <h5>Final Files</h5>\n' +
    '                        <div ng-repeat="upload in approvedialog.afterList" layout="row" layout-wrap layout-padding="20" class="download-row small" ng-class="{\'file-name-error\': !approvedialog.filenameInCodeCheck(upload.fileName,approvedialog.book.bookCode)}">\n' +
    '                            <md-tooltip ng-if="!approvedialog.filenameInCodeCheck(upload.fileName,approvedialog.book.bookCode);" class="statbar"  md-direction="top">This filename does not contain the current job code</md-tooltip>           \n' +
    '                            <a href="{{upload.ngfName|fileurl}}" target="_blank">\n' +
    '                                <div class="thumbnail-block" wraps-tooltip="artwork-upload-thumb">\n' +
    '                                    <img class="thumbnail-preview" ng-src="{{upload.ngfName|thumburl}}" err-SRC="/images/wraps-logo.png" alt="{{upload.fileName}}"/>\n' +
    '                                    <div class="overlay"><h3><ng-md-icon icon="file_download" size="24"></ng-md-icon>{{upload.fileSize | filesize}}</h3></div>\n' +
    '                                </div>\n' +
    '                            </a>\n' +
    '                            <div flex>\n' +
    '                                <h3>{{upload.fileName}}</h3>\n' +
    '                                <h3 >{{upload.fileSize | filesize}}</h3>\n' +
    '                            </div>\n' +
    '                            <div flex="100" class="divider"><div></div></div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div layout="column" ng-if="approvedialog.showApproveAll">\n' +
    '\n' +
    '                <div flex>\n' +
    '                    <md-checkbox ng-model="approvedialog.checkboxes.allOthers" aria-label="Checkbox 3">\n' +
    '                        <label>Approve ALL content items in this book?</label>\n' +
    '                    </md-checkbox>\n' +
    '                </div>\n' +
    '                <p>By clicking <strong>Approve All</strong>, you agree that you have reviewed all content within this document, it is correct and you are happy to take responsibility for this.\n' +
    '                    You are approving all of the copy within this document, not just the changes. If in doubt, do not use ‘approve all’; instead check through each content block approving the book in sections.</p>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '        <div style="padding: 20px 20px 0;"  ng-if="approvedialog.isPublishing">\n' +
    '\n' +
    '            <div layout="column" >\n' +
    '\n' +
    '                <div>\n' +
    '                    <div flex>\n' +
    '                        <md-checkbox ng-model="approvedialog.checkboxes.this" aria-label="Checkbox 4">\n' +
    '                            <label>Confirm Publishing</label>\n' +
    '                        </md-checkbox>\n' +
    '                    </div>\n' +
    '                    <ul>\n' +
    '                        <li ng-repeat="item in approvedialog.publishTypes">{{item.name}}</li>\n' +
    '                    </ul>\n' +
    '                </div>\n' +
    '\n' +
    '                <br />\n' +
    '\n' +
    '                <div ng-if="approvedialog.book.enclosures && approvedialog.isFulfilment">\n' +
    '                    <b>Enclosures:</b>\n' +
    '                    <ul>\n' +
    '                        <li ng-repeat="item in approvedialog.book.enclosures">{{item}}</li>\n' +
    '                    </ul>\n' +
    '                </div>\n' +
    '\n' +
    '                <div ng-if="approvedialog.selectedProductIds && approvedialog.isFulfilment">\n' +
    '\n' +
    '                    <b>Product Ids:</b>\n' +
    '\n' +
    '                    <ul>\n' +
    '                        <li ng-repeat="item in approvedialog.selectedProductIds">{{item.productId}} - {{item.Insurers.insurerName}} - {{item.masterProductGroup}}</li>\n' +
    '                    </ul>\n' +
    '\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '        <div layout="row" layout-wrap  flex="100"  style="padding: 0 20px;" ng-animate="approvedialog.needFile" ng-if="approvedialog.needFile">\n' +
    '            <h4>Approval files</h4>\n' +
    '            <div flex="100" layout="row" layout-wrap>\n' +
    '                <div flex="100">\n' +
    '                    <md-input-container class="md-block">\n' +
    '                        <label>Approval message</label>\n' +
    '                        <textarea name="message" ng-model="approvedialog.message"   rows="5" required></textarea>\n' +
    '                    </md-input-container>\n' +
    '                    <div class="errors" ng-messages="rejectForm.message.$error" ng-if="rejectForm.$dirty">\n' +
    '                        <div ng-message="required">Required</div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <ul class="file-ui">\n' +
    '                    <li ng-repeat="f in approvedialog.filesList" style="font:smaller" class="file-li animate-if">{{f.name}}\n' +
    '                        <div class="file-prog" ng-mouseenter="f.hovered = true" ng-mouseleave="f.hovered = false">\n' +
    '                            <!--<md-progress-circular class="animate-if" ng-if="f.determinateValue < 100" md-mode="determinate" value="{{f.determinateValue}}" md-diameter="30"></md-progress-circular>-->\n' +
    '                            <md-progress-circular class="animate-if" ng-if="!f.loaded" md-mode="indeterminate" md-diameter="30"></md-progress-circular>\n' +
    '                            <!--<span class="animate-if" ng-if="f.determinateValue < 100" >{{f.determinateValue}}%</span>-->\n' +
    '                            <ng-md-icon class="animate-if file-complete" ng-if="f.loaded"  icon="check_circle" size="30"  aria-label="Upload complete"></ng-md-icon>\n' +
    '                            <ng-md-icon class="file-remove" ng-if="f.hovered"  icon="cancel" size="30"  aria-label="Remove file" ng-click="approvedialog.removeFile(approvedialog.filesList, $index)"></ng-md-icon>\n' +
    '                        </div>\n' +
    '                    </li>\n' +
    '                </ul>\n' +
    '            </div>\n' +
    '            <div flex="5"></div>\n' +
    '            <div flex="30">\n' +
    '                <div ngf-drop ngf-select ng-model="approvedialog.files" class="drop-box"\n' +
    '                     ngf-drag-over-class="\'dragover\'" ngf-multiple="true" ngf-allow-dir="true"\n' +
    '                     accept="{{approvedialog.safeFileList}}"\n' +
    '                     ngf-pattern="approvedialog.safeFileList">\n' +
    '                    <ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop files here or<br /><strong style="text-decoration: underline;">Click to upload</strong>\n' +
    '                </div>\n' +
    '                <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions layout="row">\n' +
    '        <span flex></span>\n' +
    '\n' +
    '        <md-button class="md-primary" ng-click="approvedialog.dialogCancel(false)">Cancel</md-button>\n' +
    '        <md-button ng-disabled="(approvedialog.hasChecks(approvedialog.checkboxes) || approvedialog.isApproving) || (approvedialog.needFile && approvedialog.uploadMandatory && (!approvedialog.files[0].fileName || approvedialog.filesList.length <= 0))" class="md-raised md-accent" ng-click="approvedialog.approveItems(approvedialog.checkboxes,approvedialog.needFile)">{{approvedialog.action}}</md-button>\n' +
    '\n' +
    '    </md-dialog-actions>\n' +
    '</md-dialog>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/addbrief/addbrief.tpl.html',
    '<md-dialog aria-label="Add Brief"  class="addbrief">\n' +
    '\n' +
    '    <md-toolbar>\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h2 wraps-tooltip="briefbox-addbrief_form">Add Brief</h2>\n' +
    '            <span flex></span>\n' +
    '\n' +
    '            <md-button ng-click="addbrief.dialogCancel(false)" class="md-icon-button" wraps-tooltip="briefbox-addbrief_formclose">\n' +
    '                <ng-md-icon  icon="close" style="fill: white;"  aria-label="Close dialog"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content class="dialog-content big" style="padding-left: 20px;">\n' +
    '\n' +
    '                <md-content class="md-padding">\n' +
    '                    <br />\n' +
    '                    <form ng-submit="$event.preventDefault()" name="addBriefForm" layout="column">\n' +
    '\n' +
    '                        <div layout="row" layout-wrap>\n' +
    '                            <div flex="70" layout="row" layout-wrap>\n' +
    '                                <div flex="100">\n' +
    '                                    <md-input-container class="cke-container">\n' +
    '                                        <label>Brief description here...</label>\n' +
    '                                        <textarea  ng-model="addbrief.brief.description"  contenteditable="true" ckeditor="addbrief.options" required></textarea>\n' +
    '                                    </md-input-container>\n' +
    '\n' +
    '                                    <div id="cktools" flex></div>\n' +
    '                                    <div id="ckfoot"></div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                            <div flex="5"></div>\n' +
    '                            <div flex="25">\n' +
    '                                <div ngf-drop ngf-select ng-model="addbrief.files" class="drop-box"\n' +
    '                                     ngf-drag-over-class="\'dragover\'" ngf-multiple="true" ngf-allow-dir="true"\n' +
    '                                     accept="{{addbrief.safeFileList}}"\n' +
    '                                     ngf-pattern="addbrief.safeFileList"\n' +
    '                                     wraps-tooltip="briefbox-addbrief_upload">\n' +
    '                                    <ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop files here or<br /><strong style="text-decoration: underline;">Click to upload</strong>\n' +
    '                                </div>\n' +
    '                                <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>\n' +
    '                            </div>\n' +
    '                            <div flex="50" class="downloads-container" ng-if="addbrief.filesList.length > 0">\n' +
    '                                <h5>Uploads</h5>\n' +
    '                                <div ng-repeat="upload in addbrief.filesList" layout="row" layout-wrap layout-padding="20" class="download-row small">\n' +
    '                                    <a href="{{upload.ngfName|fileurl}}" target="_blank">\n' +
    '                                        <div class="thumbnail-block" wraps-tooltip="artwork-upload-thumb">\n' +
    '                                            <md-progress-circular ng-if="upload.isLoading"></md-progress-circular>\n' +
    '                                            <img ng-if="!upload.isLoading" class="thumbnail-preview" ng-src="{{upload.ngfName|thumburl}}" err-SRC="/images/wraps-logo.png" alt="{{upload.fileName}}"/>\n' +
    '                                            <div class="overlay text-center"><ng-md-icon icon="file_download" size="24"></ng-md-icon></div>\n' +
    '                                        </div>\n' +
    '                                    </a>\n' +
    '                                    <div flex>\n' +
    '                                        <h3 md-truncate>{{upload.fileName}}</h3>\n' +
    '                                        <h3 md-truncate>{{upload.fileSize | filesize}} <a class="md-accent" style="cursor:pointer" ng-click="addbrief.removeFile(addbrief.filesList, $index)">Remove</a></h3>\n' +
    '                                    </div>\n' +
    '                                    <div flex="100" class="divider"><div></div></div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '\n' +
    '                    </form>\n' +
    '                </md-content>\n' +
    '\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions layout="row">\n' +
    '\n' +
    '        <span flex></span>\n' +
    '        <md-button class="md-primary" ng-click="addbrief.dialogCancel(false)" wraps-tooltip="briefbox-addbrief_cancel">Cancel</md-button>\n' +
    '        <md-button  ng-disabled="(addbrief.isLoading || !addBriefForm.$valid)" class="md-raised md-accent" ng-click="addbrief.addNewBrief(addbrief)" wraps-tooltip="briefbox-addbrief_savebrief">Save Brief</md-button>\n' +
    '\n' +
    '    </md-dialog-actions>\n' +
    '</md-dialog>\n' +
    '\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/addartwork/addartwork.tpl.html',
    '<md-dialog aria-label="Add Artwork"  class="addartwork">\n' +
    '\n' +
    '    <md-toolbar>\n' +
    '        <div class="md-toolbar-tools">\n' +
    '            <h2 wraps-tooltip="draftbox-addartwork_form">Add Artwork - {{addartwork.bookCode}}</h2>\n' +
    '            <span flex></span>\n' +
    '\n' +
    '            <md-button ng-click="addartwork.dialogCancel(false)" class="md-icon-button" wraps-tooltip="draftbox-addartwork_formclose">\n' +
    '                <ng-md-icon  icon="close" style="fill: white;"  aria-label="Close dialog"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-toolbar>\n' +
    '    <md-dialog-content class="dialog-content big" style="padding-left: 20px;">\n' +
    '\n' +
    '        <md-content class="md-padding">\n' +
    '            <br />\n' +
    '            <form ng-submit="$event.preventDefault()" name="addArtworkForm" layout="column">\n' +
    '\n' +
    '                <div layout="row" layout-wrap>\n' +
    '                    <div flex="70" layout="row" layout-wrap>\n' +
    '                        <div flex="100">\n' +
    '                            <md-input-container class="cke-container">\n' +
    '                                <label>Artwork description here...</label>\n' +
    '                                <textarea  ng-model="addartwork.artwork.description"  contenteditable="true" ckeditor="addartwork.options" required></textarea>\n' +
    '                            </md-input-container>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                            <div id="cktools" flex></div>\n' +
    '                            <div id="ckfoot"></div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                    <div flex="5"></div>\n' +
    '                    <div flex="25">\n' +
    '                        <div ngf-drop ngf-select ng-model="addartwork.files" class="drop-box"\n' +
    '                             ngf-drag-over-class="\'dragover\'" ngf-multiple="true" ngf-allow-dir="true"\n' +
    '                             accept="{{addartwork.safeFileList}}"\n' +
    '                             ngf-pattern="addartwork.safeFileList"\n' +
    '                             wraps-tooltip="briefbox-adddraft_upload">\n' +
    '                            <ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop files here or<br /><strong style="text-decoration: underline;">Click to upload</strong>\n' +
    '                        </div>\n' +
    '                        <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>\n' +
    '\n' +
    '                    </div>\n' +
    '                    <div flex="50" class="downloads-container" ng-if="addartwork.filesList.length > 0">\n' +
    '                        <h5>Uploads</h5>\n' +
    '                        <div ng-repeat="upload in addartwork.filesList" layout="row" layout-wrap layout-padding="20" class="download-row small"  ng-class="{\'file-name-error\': !addartwork.filenameInCodeCheck(upload.fileName,addartwork.bookCode)}">\n' +
    '                            <md-tooltip ng-if="!addartwork.filenameInCodeCheck(upload.fileName,addartwork.bookCode);" class="statbar"  md-direction="top">This filename does not contain the current job code</md-tooltip>                      \n' +
    '                            <a href="{{upload.ngfName|fileurl}}" target="_blank">\n' +
    '                                <div class="thumbnail-block" wraps-tooltip="artwork-upload-thumb">\n' +
    '                                    <md-progress-circular  ng-if="upload.determinateValue < 100" md-mode="determinate" value="{{upload.determinateValue}}"></md-progress-circular>\n' +
    '                                    <md-progress-circular ng-if="upload.determinateValue === 100 && upload.isLoading"></md-progress-circular>\n' +
    '\n' +
    '                                    <img ng-if="!upload.isLoading" class="thumbnail-preview" ng-src="{{upload.ngfName|thumburl}}" err-SRC="/images/wraps-logo.png" alt="{{upload.fileName}}"/>\n' +
    '                                    <div class="overlay text-center"><ng-md-icon icon="file_download" size="24"></ng-md-icon></div>\n' +
    '                                </div>\n' +
    '                            </a>\n' +
    '                            <div flex>\n' +
    '                                <h3 md-truncate>{{upload.fileName}}</h3>\n' +
    '                                <h3 md-truncate>{{upload.fileSize | filesize}} <a class="md-accent" style="cursor:pointer; font-weight: bold;" ng-click="addartwork.removeFile(addartwork.filesList, $index)">Remove</a></h3>\n' +
    '                            </div>\n' +
    '                            <div flex="100" class="divider"><div></div></div>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '            </form>\n' +
    '\n' +
    '        </md-content>\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions layout="row">\n' +
    '\n' +
    '        <span flex></span>\n' +
    '        <md-button class="md-primary" ng-click="addartwork.dialogCancel(false)" wraps-tooltip="briefbox-adddraft_cancel">Cancel</md-button>\n' +
    '        <md-button ng-disabled="(addartwork.isLoading || !addArtworkForm.$valid)" class="md-raised md-accent"  ng-click="addartwork.addNewArtwork(addartwork)"wraps-tooltip="draftbox-addartwork_saveartwork">Save Artwork</md-button>\n' +
    '\n' +
    '    </md-dialog-actions>\n' +
    '</md-dialog>\n' +
    '\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('workflow/workflow.tpl.html',
    '\n' +
    '<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">\n' +
    '\n' +
    '    <div class="md-whiteframe-z1 default-box" style="" >\n' +
    '\n' +
    '        <md-content >\n' +
    '            <h2  class="">Workflow</h2>\n' +
    '\n' +
    '\n' +
    '            <md-list>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '            </md-list>\n' +
    '\n' +
    '\n' +
    '\n' +
    '        </md-content>\n' +
    '\n' +
    '\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '</md-content>\n' +
    '<md-sidenav class="md-sidenav-right md-whiteframe-z3 users-sidenav" md-component-id="right">\n' +
    '    <md-toolbar class="md-theme-light">\n' +
    '        <h3 class="md-toolbar-tools">Edit Item - {{}}</h3>\n' +
    '    </md-toolbar>\n' +
    '    <md-content  layout-padding>\n' +
    '        <form>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '        </form>\n' +
    '        <md-button ng-click="library.closeRightMenu()" class="md-fab">\n' +
    '            <ng-md-icon icon="close" style="fill: white" size="30"></ng-md-icon>\n' +
    '        </md-button>\n' +
    '    </md-content>\n' +
    '</md-sidenav>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('versions/versions.tpl.html',
    '<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">\n' +
    '\n' +
    '\n' +
    '    <div class="md-whiteframe-z1 default-box" style="" >\n' +
    '        <md-content layout-padding >\n' +
    '\n' +
    '\n' +
    '            <h2  class="" wraps-tooltip="version-log-title">Version Log</h2>\n' +
    '            <md-list flex>\n' +
    '                <md-list-item style="padding-bottom: 10px;"  ng-repeat="version in versions.versions| orderBy : appVersionId : true"><p><strong wraps-tooltip="version-log-number">{{version.appVersionId}}</strong> <small style="font-size:9px;" wraps-tooltip="version-log-date">- {{version.created | date:\'dd/MM/yyyy\'}}</small><br /><small wraps-tooltip="version-log-info">{{version.appVersionDescription}}</small></p></md-list-item>\n' +
    '            </md-list>\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <p align="center"><md-button ng-click="versions.reload()" wraps-tooltip="version-log-reload">Reload Application</md-button></p>\n' +
    '\n' +
    '        </md-content>\n' +
    '\n' +
    '\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '</md-content>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('users/users.tpl.html',
    '<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">\n' +
    '\n' +
    '    <div class="md-whiteframe-z1 default-box" style="" ng-if="users.hasAccess" >\n' +
    '        <md-button ng-click="users.openRightMenu(\'new\', {})" class="md-fab wraps-add-external"   aria-label="Add user" wraps-tooltip="users-add-btn">\n' +
    '            <ng-md-icon icon="add" style="fill: white" size="30"></ng-md-icon>\n' +
    '        </md-button>\n' +
    '\n' +
    '\n' +
    '        <md-progress-circular style="position: absolute; top: 10px; left: 50%; margin-left: -50px;"  md-diameter="80" md-mode="indeterminate" ng-show="users.isLoading"></md-progress-circular>\n' +
    '\n' +
    '        <md-data-table-toolbar><h2 flex wraps-tooltip="users-title">User Management</h2>\n' +
    '\n' +
    '        <md-button aria-label="Download CSV" class="md-icon-button" ng-if="users.hasPermissions(\'downloadUsers\')" ng-csv="users.downloadCSV()"  lazy-load="true" filename="users-download.csv" add-bom="true" charset="utf-8" csv-label="true">\n' +
    '            <ng-md-icon icon="file_download" style="fill: grey; " size="24" wraps-tooltip="users-data-download"></ng-md-icon>\n' +
    '        </md-button>\n' +
    '        <md-button aria-label="Refresh Users" class="md-icon-button" ng-click="users.updateUsers()"   aria-label="Refresh Users" wraps-tooltip="users-refresh-btn">\n' +
    '			<ng-md-icon icon="refresh" style="fill: grey;" size="24"></ng-md-icon>\n' +
    '		</md-button>\n' +
    '        <md-button aria-label="Open search" class="md-icon-button" ng-hide="users.searchUser" ng-click="users.searchUser=true"  aria-label="Open search" wraps-tooltip="users-search-btn">\n' +
    '            <ng-md-icon icon="search" style="fill: grey;" size="24"></ng-md-icon>\n' +
    '        </md-button>\n' +
    '        </md-data-table-toolbar>\n' +
    '\n' +
    '        <div layout="row"  layout-wrap>\n' +
    '            <md-input-container flex="5">\n' +
    '            </md-input-container>\n' +
    '            <md-input-container flex="45">\n' +
    '				<md-switch ng-model="users.filterUsers.status" ng-true-value="2" ng-false-value=null aria-label="Restricted User" style="margin: 0px;" wraps-tooltip="users-view-disabled">\n' +
    '                    View disabled\n' +
    '               </md-switch>\n' +
    '            </md-input-container>\n' +
    '\n' +
    '            <md-input-container flex="45">\n' +
    '\n' +
    '                <p style="text-align: right;" wraps-tooltip="users-licenses">Currently using {{users.noOfUsers}} of {{users.noOfSubs}} licenses</p>\n' +
    '            </md-input-container>\n' +
    '        </div>\n' +
    '\n' +
    '        <md-data-table-container>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '            <table md-data-table  > <!--md-row-select="selected"-->\n' +
    '                <thead ng-show="users.searchUser">\n' +
    '                <tr wraps-tooltip="users-search-fields">\n' +
    '                    <td></td>\n' +
    '                    <td>\n' +
    '                        <md-input-container  style="width: 45%; float:left;"><input ng-model="users.filterUsers.firstName" style="width: 90%;" aria-label="Filter first name"></md-input-container>\n' +
    '                        <md-input-container style="width: 45%; float:left;"><input ng-model="users.filterUsers.lastName" style="width: 90%;" aria-label="Filter last name"></md-input-container>\n' +
    '                    </td>\n' +
    '                    <td><md-input-container><input ng-model="users.filterUsers.email" style="width: 90%;" aria-label="Filter email"></md-input-container></td>\n' +
    '                    <td><md-input-container><input ng-model="users.filterUsers.Teams.teamName" style="width: 90%;" aria-label="Filter team"></md-input-container></td>\n' +
    '                    <td><md-input-container><input ng-model="users.filterUsers.Locations.locationName" style="width: 90%;" aria-label="Filter location"></md-input-container></td>\n' +
    '\n' +
    '                    <td><md-input-container><input ng-model="users.filterUsers.Roles.name" style="width: 90%;" aria-label="Filter role"></md-input-container></td>\n' +
    '                    <td><md-input-container><input ng-model="users.filterUsers.limitDocs" style="width: 90%;" aria-label="Filter restricted"></md-input-container></td>\n' +
    '                    <td><md-input-container><input ng-model="users.filterUsers.Divisions.division" style="width: 90%;" aria-label="Filter division"></md-input-container></td>\n' +
    '					<td><md-input-container><input ng-model="users.filterUsers.bupaNotes" style="width: 90%;" aria-label="Filter notes"></md-input-container></td>\n' +
    '\n' +
    '                    <td><md-button aria-label="Close Search" class="md-icon-button" style="position: relative; top: 6px; right:-00px;" ng-click="users.searchUser=false; users.filterUsers={}" wraps-tooltip="users-search-close">\n' +
    '                        <ng-md-icon icon="close" style="fill: grey; " size="24"></ng-md-icon>\n' +
    '                    </md-button></td>\n' +
    '                </tr>\n' +
    '                </thead>\n' +
    '                <thead  md-order="users.usersQuery.order">\n' +
    '                <tr wraps-tooltip="users-table-heading">\n' +
    '                    <!--<th order-by="id" numeric>ID</th>-->\n' +
    '                    <th></th>\n' +
    '                    <th order-by="firstName">Name</th>\n' +
    '                    <th order-by="email">Email</th>\n' +
    '                    <th order-by="Teams.teamName">Team</th>\n' +
    '                    <th order-by="Locations.locationName">Location</th>\n' +
    '                    <th order-by="Roles.name">Role</th>\n' +
    '                    <th order-by="limitDocs">Restricted</th>\n' +
    '                    <th order-by="Divisions.division">Division</th>\n' +
    '					<th order-by="bupaNotes">Notes</th>\n' +
    '                    <th></th>\n' +
    '                </tr>\n' +
    '                </thead>\n' +
    '                <tbody>\n' +
    '                <tr ng-class="user.status==2?\'userDisabled\':\'\'" ng-repeat="user in  users.filteredUsers = (users.users | filter: users.filterUsers) |  orderBy: users.usersQuery.order   | limitTo: users.usersQuery.limit: (users.usersQuery.page- 1) * users.usersQuery.limit"  ng-click="users.openRightMenu(\'edit\', user)" ng-hide="$storage.selectedUser === user.id"  class="data-rows" wraps-tooltip="users-table-row">\n' +
    '                    <!--<td><a >{{ user.id }}</a></td>-->\n' +
    '                    <td></td>\n' +
    '                    <td>{{user.firstName}} {{user.lastName }}</td>\n' +
    '                    <td>{{user.email }}</td>\n' +
    '                    <td>{{user.Teams.teamName }}</td>\n' +
    '                    <td>{{user.Locations.locationName }}</td>\n' +
    '                    <td>{{user.Roles.name}}</td>\n' +
    '                    <td>{{user.limitDocs == 1?"Yes":""}}</td>\n' +
    '					<td>{{user.Divisions.division}}</td>\n' +
    '					<td>{{user.bupaNotes}}</td>\n' +
    '                    <td></td>\n' +
    '                </tr>\n' +
    '                </tbody>\n' +
    '            </table>\n' +
    '        </md-data-table-container>\n' +
    '\n' +
    '        <md-data-table-pagination md-limit="users.usersQuery.limit" md-page="users.usersQuery.page" md-total="{{users.filteredUsers.length}}"></md-data-table-pagination>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '    </div>\n' +
    '    <div class="md-whiteframe-z1 default-box" style="" ng-if="users.hasAccess === false">\n' +
    '		<md-data-table-toolbar><h2  flex wraps-tooltip="users-access-denied">Access denied<br /><br /><br /></h2>\n' +
    '\n' +
    '		</md-data-table-toolbar>\n' +
    '	</div>\n' +
    '	<div class="md-whiteframe-z1 default-box" style="" ng-if="users.hasAccess === null" >\n' +
    '		 <md-data-table-toolbar><h2  flex><br/></h2></md-data-table-toolbar>\n' +
    '            <md-progress-circular style="position: absolute; top: 10px; left: 50%; margin-left: -50px;"  md-diameter="80" md-mode="indeterminate"></md-progress-circular>\n' +
    '	</div>\n' +
    '\n' +
    '</md-content>\n' +
    '\n' +
    '\n' +
    '<md-sidenav class="md-sidenav-right md-whiteframe-z3 library-sidenav" md-component-id="right" style="position: fixed;">\n' +
    '    <users-right-menu></users-right-menu>\n' +
    '</md-sidenav>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('tooltip/tooltip.tpl.html',
    '<md-toolbar ng-show="tooltip.showToolTip" class="tooltipplaceholder md-primary md-hue-2"></md-toolbar>\n' +
    '<md-toolbar layout="row" class="md-whiteframe-z0 md-primary md-hue-2 tooltipbar" ng-show="tooltip.showToolTip">\n' +
    '    <md-container layout="row">\n' +
    '        <md-button aria-label="Tool tips" class="md-icon-button" flex="none" ng-click="tooltip.hide()" wraps-tooltip="tooltip-close">\n' +
    '            <ng-md-icon icon="help" ></ng-md-icon>\n' +
    '        </md-button>\n' +
    '		<div flex="auto">\n' +
    '			<h4 wraps-tooltip="tooltip-title">{{tooltip.helpTitle}}<span style="opacity:0.4" ng-if="tooltip.hasPermissions(\'adminTooltips\') && tooltip.helpCode != \'\'" wraps-tooltip="tooltip-help-code"> {{tooltip.helpCode}}</span></h4>\n' +
    '        	<p wraps-tooltip="tooltip-description">{{tooltip.helpText}}</p>\n' +
    '		</div>\n' +
    '		<md-button aria-label="Close" class="md-icon-button" flex="none" ng-click="tooltip.hide()" wraps-tooltip="tooltip-close">\n' +
    '            <ng-md-icon icon="close" ></ng-md-icon>\n' +
    '        </md-button>\n' +
    '    </md-container>\n' +
    '</md-toolbar>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('reports/reports.tpl.html',
    '<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">\n' +
    '\n' +
    '\n' +
    '    <div class="md-whiteframe-z1 default-box" style="" ng-if="reports.hasAccess">\n' +
    '        <md-content layout-padding >\n' +
    '            <h2  class="">WRAPS Reports</h2>\n' +
    '			\n' +
    '			<h4>Marketing Compliance MI Report</h4>\n' +
    '			<md-select ng-model="reports.month" style="width:150px;">\n' +
    '				<md-option value="052018">May 2018</md-option>\n' +
    '				<md-option value="062018">June 2018</md-option>\n' +
    '				<md-option value="082018">November 2018</md-option>\n' +
    '			</md-select>\n' +
    '			<div layout="row">\n' +
    '				<md-button ng-click="reports.openPdf(reports.reportingData)" class="md-raised md-accent button-padding md-button md-ink-ripple">Download Full Monthly Report</md-button>\n' +
    '			</div>\n' +
    '			\n' +
    '			<div ng-repeat="table in reports.reportingData.tables" class="table-wrapper" ng-switch on="table.type">\n' +
    '				<table class="report" ng-switch-when="conditional">\n' +
    '					\n' +
    '					<thead>\n' +
    '						<tr>\n' +
    '							<th><ng-md-icon icon="help" size="18"></ng-md-icon> {{table.title}}\n' +
    '								<md-tooltip md-direction="left">{{table.helper}}</md-tooltip></th>\n' +
    '							<th class="data">%</th>\n' +
    '							<th class="data">#</th>\n' +
    '							<th class="data">Total</th>\n' +
    '						</tr>\n' +
    '					</thead>\n' +
    '					<tbody>\n' +
    '						<tr ng-repeat="row in table.data">\n' +
    '							<td><ng-md-icon icon="help" size="18"></ng-md-icon> <strong>{{row.name}} </strong><md-tooltip md-direction="left">{{row.helper}}</md-tooltip></td>\n' +
    '							<td class="{{row.color}} data">{{row.percentage}}</td>\n' +
    '							<td class="data">{{row.number}}</td>\n' +
    '							<td class="data">{{row.total}}</td>\n' +
    '						</tr>\n' +
    '					</tbody>\n' +
    '				</table>\n' +
    '				<table class="report" ng-switch-when="docInfo">\n' +
    '					<thead>\n' +
    '						<tr>\n' +
    '							<th colspan="4"><ng-md-icon icon="help" size="18"></ng-md-icon> {{table.title}}\n' +
    '							<md-tooltip md-direction="left">{{table.helper}}</md-tooltip></th>\n' +
    '						</tr>\n' +
    '						<tr>\n' +
    '							<th>ID</th>\n' +
    '							<th>Book Code</th>\n' +
    '							<th class="quart">Doc Type</th>\n' +
    '							<th class="quart">Owner</th>\n' +
    '						</tr>\n' +
    '					</thead>\n' +
    '					<tbody>\n' +
    '						<tr ng-repeat="row in table.data">\n' +
    '							<td class="data">{{row.id}}</td>\n' +
    '							<td>{{row.bookCode}}</td>\n' +
    '							<td>{{row.docType}}</td>\n' +
    '							<td>{{row.owner}}</td>\n' +
    '						</tr>\n' +
    '					</tbody>\n' +
    '				</table>\n' +
    '				<table class="report" ng-switch-when="trafficLights">\n' +
    '					<thead>\n' +
    '						<tr>\n' +
    '							<th><ng-md-icon icon="help" size="18"></ng-md-icon> {{table.title}}\n' +
    '								<md-tooltip md-direction="left">{{table.helper}}</md-tooltip></th>\n' +
    '							<th class="data">R</th>\n' +
    '							<th class="data">A</th>\n' +
    '							<th class="data">G</th>\n' +
    '							<th class="data"><ng-md-icon icon="help" size="18"></ng-md-icon> %<md-tooltip md-direction="left">% of Total MCOMP/Comp Submissions</md-tooltip></th>\n' +
    '						</tr>\n' +
    '					</thead>\n' +
    '					<tbody>\n' +
    '						<tr ng-repeat="row in table.data">\n' +
    '							<td>{{row.name}}</td>\n' +
    '							<td class="data red">{{row.red}}</td>\n' +
    '							<td class="data amber">{{row.amber}}</td>\n' +
    '							<td class="data green">{{row.green}}</td>\n' +
    '							<td class="data">{{row.percentage}}</td>\n' +
    '						</tr>\n' +
    '					</tbody>\n' +
    '				</table>\n' +
    '				<table class="report" ng-switch-when="twoCol">\n' +
    '					<thead>\n' +
    '						<tr>\n' +
    '							<th colspan="2"><ng-md-icon icon="help" size="18"></ng-md-icon> {{table.title}}\n' +
    '							<md-tooltip md-direction="left">{{table.helper}}</md-tooltip></th>\n' +
    '						</tr>\n' +
    '					</thead>\n' +
    '					<tbody>\n' +
    '						<tr ng-repeat="row in table.data">\n' +
    '							<td>{{row.name}}</td>\n' +
    '							<td class="data">{{row.val}}</td>\n' +
    '						</tr>\n' +
    '					</tbody>\n' +
    '				</table>\n' +
    '			</div>\n' +
    '			<div layout="row" layout-align-gt-md="end center">\n' +
    '				<md-button ng-click="" class="md-raised md-primary button-padding md-button md-ink-ripple">Download Force Expiry Report</md-button>\n' +
    '			</div>\n' +
    '        </md-content>\n' +
    '\n' +
    '\n' +
    '\n' +
    '    </div>\n' +
    '	<div class="md-whiteframe-z1 default-box" style="" ng-if="reports.hasAccess === false">\n' +
    '		<md-data-table-toolbar><h2  flex>Access denied<br /><br /><br /></h2>\n' +
    '\n' +
    '		</md-data-table-toolbar>\n' +
    '	</div>\n' +
    '	 <div class="md-whiteframe-z1 default-box" style="" ng-if="reports.hasAccess === null" >\n' +
    '		 <md-data-table-toolbar><h2  flex><br/></h2></md-data-table-toolbar>\n' +
    '            <md-progress-circular style="position: absolute; top: 10px; left: 50%; margin-left: -50px;"  md-diameter="80" md-mode="indeterminate"></md-progress-circular>\n' +
    '	</div>\n' +
    '\n' +
    '</md-content>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('reset/reset.tpl.html',
    '<md-content  layout="row" layout-wrap  layout-align="center start" class="login-box">\n' +
    '\n' +
    '    <div class="md-whiteframe-z1 " >\n' +
    '        <md-content layout-padding >\n' +
    '            <h2   class="">Reset Password</h2>\n' +
    '            <form name="loginForm" novalidate   ng-submit="reset.submitReset()" autocomplete="off">\n' +
    '\n' +
    '                <div  layout="row">\n' +
    '                    <md-input-container flex  >\n' +
    '                        <label>Password</label>\n' +
    '                        <input ng-model="reset.credentials.password" type="password"  required name="password"  minlength="5" ng-focus="reset.passwordFocus = true">\n' +
    '                        <div ng-show="reset.passwordFocus"  ng-messages="reset.password.$error">\n' +
    '                            <div ng-message="required">Please enter a valid password</div>\n' +
    '                            <div ng-message="minlength, maxlength">Your password must at least 5 characters</div>\n' +
    '                        </div>\n' +
    '                    </md-input-container>\n' +
    '                </div>\n' +
    '                <div  layout="row" class="relative">\n' +
    '                    <md-input-container flex >\n' +
    '                        <label>Confirm Password</label>\n' +
    '                        <input ng-model="reset.credentials.password2" type="password"  required name="password2"  minlength="5" ng-focus="reset.password2Focus = true">\n' +
    '                        <div ng-show="reset.password2Focus"  ng-messages="reset.password2.$error">\n' +
    '                            <div ng-message="required">Please enter a valid password</div>\n' +
    '                            <div ng-message="minlength, maxlength">Your password must at least 5 characters</div>\n' +
    '                        </div>\n' +
    '                    </md-input-container>\n' +
    '                    <p ng-if="reset.error" class="animate-if error">{{reset.error}}</p>\n' +
    '                </div>\n' +
    '                <section layout="row" layout-sm="column" layout-align="end center" class="relative">\n' +
    '                    <md-progress-circular style="position: absolute; top: 10px; left: 0px; "  md-diameter="100" md-mode="indeterminate" ng-if="reset.isLoading" class="animate-if"></md-progress-circular>\n' +
    '                    <div><md-button type="submit" name="loginSubmit" class="md-accent md-raised button-padding" >Reset Password</md-button></div>\n' +
    '                </section>\n' +
    '            </form>\n' +
    '\n' +
    '        </md-content>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '</md-content>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('profile/profile.tpl.html',
    '<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">\n' +
    '\n' +
    '\n' +
    '    <div class="md-whiteframe-z1 default-box" style="" >\n' +
    '        <md-content layout-padding >\n' +
    '            <h2  class="" wraps-tooltip="profile-title">Profile</h2>\n' +
    '    <table width="100%"> <!--md-row-select="selected"-->\n' +
    '        <tr>\n' +
    '            <td >\n' +
    '\n' +
    '\n' +
    '                <form autocomplete="off" name="usersForm">\n' +
    '                    <div style="padding:0px 20px; background-color: #eee;  text-align: left; cursor: pointer;">\n' +
    '                        <h3 flex wraps-tooltip="profile-name">{{ profile.userForm.firstName }} {{ profile.userForm.lastName }}</h3>\n' +
    '                        <span flex></span>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div layout="row"  layout-wrap>\n' +
    '                        <md-input-container flex wraps-tooltip="profile-form-firstname">\n' +
    '                            <label>First Name</label>\n' +
    '                            <input ng-model="profile.userForm.firstName">\n' +
    '                        </md-input-container>\n' +
    '                        <md-input-container flex wraps-tooltip="profile-form-lastname">\n' +
    '                            <label>Last Name</label>\n' +
    '                            <input ng-model="profile.userForm.lastName">\n' +
    '                        </md-input-container>\n' +
    '                        <md-input-container flex wraps-tooltip="profile-form-job">\n' +
    '                            <label>Job Title</label>\n' +
    '                            <input ng-model="profile.userForm.jobTitle">\n' +
    '                        </md-input-container>\n' +
    '                    </div>\n' +
    '\n' +
    '\n' +
    '                    <div layout="row">\n' +
    '                        <md-input-container flex wraps-tooltip="profile-form-email">\n' +
    '                            <label>Email address</label>\n' +
    '\n' +
    '                            <input ng-model="profile.userForm.email" >\n' +
    '                        </md-input-container>\n' +
    '                        <md-input-container flex wraps-tooltip="profile-form-contact-email">\n' +
    '                            <label>Contact Email</label>\n' +
    '                            <input ng-model="profile.userForm.contactEmail">\n' +
    '                        </md-input-container>\n' +
    '                        <md-input-container  flex wraps-tooltip="profile-form-tel">\n' +
    '                            <label>Phone Number</label>\n' +
    '                            <input ng-model="profile.userForm.tel">\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                    </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                    <div layout="row">\n' +
    '\n' +
    '                        <!--<md-input-container flex wraps-tooltip="profile-form-team">-->\n' +
    '                            <!--<label>Team</label>-->\n' +
    '                            <!--<md-select ng-model="$storage.userForm.teamId" placeholder="Team">-->\n' +
    '                                <!--<md-option value="{{team.teamId}}" ng-repeat="team in profile.teams" >{{team.Departments.departmentName}} - {{team.teamName}}</md-option>-->\n' +
    '                            <!--</md-select>-->\n' +
    '\n' +
    '                        <!--</md-input-container>-->\n' +
    '\n' +
    '                        <md-input-container flex="33" wraps-tooltip="profile-form-location">\n' +
    '                            <label>Location</label>\n' +
    '                            <md-select ng-model="profile.userForm.locationId" placeholder="Location">\n' +
    '                                <md-option value="{{location.locationId}}" ng-repeat="location in profile.locations" >{{location.locationName}}</md-option>\n' +
    '                            </md-select>\n' +
    '\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                        <!--<md-input-container flex wraps-tooltip="profile-form-role">\n' +
    '                            <label>User Roles</label>\n' +
    '                            <md-select ng-model="$storage.userForm.roleId" placeholder="Role">\n' +
    '                                <md-option value="{{role.id}}" ng-repeat="role in profile.roles">{{role.name}}</md-option>\n' +
    '                            </md-select>\n' +
    '\n' +
    '                        </md-input-container>-->\n' +
    '\n' +
    '\n' +
    '\n' +
    '                            <md-input-container flex="33" wraps-tooltip="profile-form-change-password">\n' +
    '                                <md-checkbox class="" ng-model="profile.temp.changepassword" aria-label="Change Password" style="margin: 0px;">\n' +
    '                                    Change Password\n' +
    '                                </md-checkbox>\n' +
    '                            </md-input-container>\n' +
    '\n' +
    '                            <md-input-container flex="33" ng-if="profile.temp.changepassword" wraps-tooltip="profile-form-password">\n' +
    '                                <label>Password</label>\n' +
    '                                <input ng-model="profile.userForm.password" type="password" required ng-minlength="8" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*]).+" name="password">\n' +
    '                                <div ng-show="usersForm.password.$touched" ng-messages="usersForm.password.$error" role="alert" class="t-sm">\n' +
    '                                    <div ng-message="minlength" class="error">Your password must be at least 8 characters</div>\n' +
    '                                    <div ng-message="pattern" class="error">The password must contain a combination of uppercase, lowercase, numeric characters and at least one of the following: !@#$%^&amp;*.</div>\n' +
    '                                    <div ng-message="validation" class="error">This email address has already been registered</div>\n' +
    '                                </div>\n' +
    '\n' +
    '                            </md-input-container>\n' +
    '\n' +
    '                    </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '                    <!--<div layout="row">\n' +
    '                        <md-input-container flex="33">\n' +
    '                            <label>Change Password</label>\n' +
    '                            <input ng-model="$storage.userForm.password">\n' +
    '                        </md-input-container>\n' +
    '                    </div>-->\n' +
    '\n' +
    '\n' +
    '                    <div layout="row">\n' +
    '                        <md-input-container flex="33">\n' +
    '                            <label flex="33"><div flex="33">Profile Picture</div></label>\n' +
    '                        </md-input-container>\n' +
    '\n' +
    '                    </div>\n' +
    '\n' +
    '\n' +
    '                    <div layout="row">\n' +
    '                        <div wraps-tooltip="profile-form-avatar">\n' +
    '                            <md-input-container ng-if="profile.userForm.profilePic != \'\' && profile.userForm.profilePic != null" class="profilePicContainer">\n' +
    '                                <!--<div class="profilePicDelete" ng-click="profile.deleteProfilePic($storage.userForm);">\n' +
    '                                    <ng-md-icon icon="delete_forever" size="30"></ng-md-icon>\n' +
    '                                </div>-->\n' +
    '                                <img ng-src="{{profile.apiUrl}}/containers/getthumbnail/{{profile.userForm.profilePic}}" class="user-avatar large" alt="User Avatar"/>\n' +
    '                            </md-input-container>\n' +
    '							 <md-input-container ng-if="profile.userForm.profilePic == \'\' || profile.userForm.profilePic == null">\n' +
    '                            	<div class="user-avatar initials large {{profile.userForm.lastName|lowercase|limitTo:1}}">{{profile.userForm.firstName|uppercase|limitTo:1}}{{profile.userForm.lastName|uppercase|limitTo:1}}</div>\n' +
    '							</md-input-container>\n' +
    '                        </div>\n' +
    '\n' +
    '                        <div flex layout="row" layout-wrap>\n' +
    '							<ul class="file-ui" ng-if="profile.loadedavatar.filename != \'\' && profile.loadedavatar.filename != null && profile.userForm.profilePic != \'\' && profile.userForm.profilePic != null && !profile.profilePic">\n' +
    '                                <li style="font:smaller" class="file-li animate-if" wraps-tooltip="profile-form-filename">{{profile.loadedavatar.filename}}\n' +
    '                                    <div class="file-prog" ng-mouseenter="profile.loadedavatar.hovered = true" ng-mouseleave="profile.loadedavatar.hovered = false">\n' +
    '                                        <ng-md-icon class="animate-if file-complete" icon="check_circle" size="30"  aria-label="Profile Picture"></ng-md-icon>\n' +
    '										<ng-md-icon class="file-remove" ng-if="profile.loadedavatar.hovered" icon="cancel" size="30" aria-label="Remove file" ng-click="profile.deleteProfilePic($storage.userForm);" wraps-tooltip="profile-form-avatar-delete"></ng-md-icon>\n' +
    '                                    </div>\n' +
    '                                </li>\n' +
    '                            </ul>\n' +
    '                            <ul class="file-ui">\n' +
    '                                <li ng-repeat="f in profile.profilePic.filesList" style="font:smaller" class="file-li animate-if" wraps-tooltip="profile-form-filename">{{f.fileName}}\n' +
    '                                    <div class="file-prog" ng-mouseenter="f.hovered = true" ng-mouseleave="f.hovered = false">\n' +
    '                                        <md-progress-circular class="animate-if" ng-if="f.determinateValue < 100" md-mode="determinate" value="{{f.determinateValue}}" md-diameter="30"></md-progress-circular>\n' +
    '                                        <span class="animate-ifx" ng-if="f.determinateValue < 100" >{{f.determinateValue}}%</span>\n' +
    '                                        <ng-md-icon class="animate-if file-complete" ng-if="f.determinateValue === 100"  icon="check_circle" size="30"  aria-label="Upload complete"></ng-md-icon>\n' +
    '										<ng-md-icon class="file-remove" ng-if="f.hovered" icon="cancel" size="30" aria-label="Remove file" ng-click="profile.removeFile(profile.profilePic.filesList, $index)" wraps-tooltip="profile-form-avatar-delete"></ng-md-icon>\n' +
    '                                    </div>\n' +
    '                                </li>\n' +
    '                            </ul>\n' +
    '							\n' +
    '                        </div>\n' +
    '                        <md-input-container wraps-tooltip="profile-form-uploader">\n' +
    '                            <label>Profile picture</label>\n' +
    '                            <div ngf-drop ngf-select ng-model="profile.profilePic" class="drop-box"\n' +
    '                                 ngf-drag-over-class="\'dragover\'" ngf-multiple="false" ngf-allow-dir="true"\n' +
    '                                 accept="image/*"\n' +
    '                                 ngf-pattern="\'image/*\'"><ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop profile picture here or<br /><strong style="text-decoration: underline;">Click to upload</strong><br />a new one</div>\n' +
    '                            <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>\n' +
    '                        </md-input-container>\n' +
    '                    </div>\n' +
    '                    <br /><br />\n' +
    '                    <md-button type="button" class="md-raised md-primary save-button" ng-disabled="usersForm.$invalid || usersForm.$pristine || profile.isLoading || profile.compareJson(profile.userForm,user)"  ng-click="profile.saveRow(profile.userForm);" wraps-tooltip="profile-form-save">Save</md-button>\n' +
    '\n' +
    '                    <md-progress-circular  style=" display:inline;  margin-left: 10px; top:-4px;" class="animate-if"  md-diameter="30" md-mode="indeterminate" ng-if="profile.isLoading"></md-progress-circular>\n' +
    '					<p ng-if="profile.thumbgen" wraps-tooltip="profile-form-generating">Generating thumbnail...</p>\n' +
    '					<p ng-if="usersForm.$invalid" wraps-tooltip="profile-form-error-prevent">There are errors with the form</p>\n' +
    '					<p ng-if="profile.saved == \'success\' && usersForm.$pristine" wraps-tooltip="profile-form-save-success">Saved changes successfully</p>\n' +
    '					<p ng-if="profile.saved == \'error\' && usersForm.$pristine" wraps-tooltip="profile-form-error-fail">Unable to save changes</p>\n' +
    '                </form>\n' +
    '\n' +
    '            </td>\n' +
    '        </tr>\n' +
    '        </tbody>\n' +
    '    </table>\n' +
    '        </md-content>\n' +
    '    </div>\n' +
    '</md-content>');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('permissionmanagement/permissionmanagement.tpl.html',
    '<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">\n' +
    '\n' +
    '    <div class="md-whiteframe-z1 default-box" style="" ng-if="permissionmanagement.hasAccess" >\n' +
    '        \n' +
    '\n' +
    '            <md-progress-circular style="position: absolute; top: 0; left: 50%; margin-left: -50px;"  md-diameter="100" md-mode="indeterminate" ng-show="permissionmanagement.isLoading"></md-progress-circular>\n' +
    '\n' +
    '            <md-data-table-toolbar>\n' +
    '            	<h2  flex wraps-tooltip="permissions-title">Permission Management</h2>\n' +
    '            	<md-button class="md-raised md-accent" ng-if="permissionmanagement.assignPermissionsToGroups === false" ng-click="permissionmanagement.openEdit()" wraps-tooltip="permissions-add-row-btn">Add Row</md-button>\n' +
    '            </md-data-table-toolbar>\n' +
    '            <!--<md-data-table-toolbar>-->\n' +
    '            <div>\n' +
    '                <md-tabs md-selected="permissionmanagement.selectedTabIndex" md-border-bottom md-autoselect md-swipe-content md-dynamic-height>\n' +
    '					<md-tab>\n' +
    '						<md-tab-label>\n' +
    '							<span wraps-tooltip="permissions-tab-assignment">Assign permissions to group</span>\n' +
    '						</md-tab-label>\n' +
    '						<md-tab-body>\n' +
    '							<form name="assignmentForm" class="assign-permissions-tab" role="form" id="assignmentForm" ng-model="permissionmanagement.assignmentForm">\n' +
    '									<p><strong>Note:</strong> These changes will take effect from next login or reloading of the WRAPS application.</p>\n' +
    '									<md-input-container wraps-tooltip="permissions-assignment-group">\n' +
    '										<md-select   ng-model="permissionmanagement.thisPermissionGroup" ng-change="permissionmanagement.getCurrentPermissions()" placeholder="Select group" style="width:300px;">\n' +
    '											<md-option ng-value="permissionGroup.groupId" ng-repeat="permissionGroup in permissionmanagement.permissionGroups">{{permissionGroup.groupName}}</md-option>\n' +
    '										</md-select>\n' +
    '									</md-input-container>\n' +
    '									<div ng-repeat="categories in permissionmanagement.PermissionCategories| orderBy:\'permissionCategoryName\'" class="permission-group-container">\n' +
    '										<md-checkbox class="permission-group-label" ng-model="permissionmanagement.isAllSelected[\'cat_\' + categories.permissionCategoryId]" ng-click="permissionmanagement.toggleAll(categories.permissionCategoryId)" aria-label="{{categories.permissionCategoryName}}" wraps-tooltip="permissions-assignment-category">{{categories.permissionCategoryName}}</md-checkbox>\n' +
    '										<div class="permission-group" layout="row" layout-wrap>\n' +
    '											<div flex="33" ng-repeat="permission in permissionmanagement.permissions| filter:{\'permissionStatus\': 1}| orderBy:\'permissionName\'" ng-if="permission.permissionCategoryId == categories.permissionCategoryId">\n' +
    '												<md-checkbox ng-model="permission.selected" area-label="{{permission.permissionName}}" ng-change="permissionmanagement.optionToggled(permission.permissionCategoryId)"  ng-disabled="permission.permissionCode == \'adminPermissions\'" wraps-tooltip="permissions-assignment-permission">{{permission.permissionName}}</md-checkbox>\n' +
    '											</div>\n' +
    '										</div>\n' +
    '									</div>\n' +
    '\n' +
    '									<div class="permission-group-container" ng-if="permissionmanagement.hasMisc">\n' +
    '										<md-checkbox class="permission-group-label" ng-model="permissionmanagement.isAllSelectedMisc" ng-click="permissionmanagement.toggleAllMisc()" aria-label="Miscellaneous" wraps-tooltip="permissions-assignment-category">Miscellaneous</md-checkbox>\n' +
    '										<div class="permission-group" layout="row" layout-wrap>\n' +
    '											<div flex="33" ng-repeat="permission in permissionmanagement.permissions| filter:{\'permissionCategoryId\': null, \'permissionStatus\': 1} | orderBy:\'permissionName\'">\n' +
    '												<md-checkbox ng-model="permission.selected" area-label="{{permission.permissionName}}" ng-change="permissionmanagement.optionToggled(permission.permissionCategoryId)" wraps-tooltip="permissions-assignment-permission">{{permission.permissionName}}</md-checkbox>\n' +
    '											</div>\n' +
    '										</div>\n' +
    '									</div>\n' +
    '									<div layout="row" layout-align="end start">\n' +
    '										<md-progress-circular  style=" display:inline;  margin-left: 10px; top:-4px;" class="animate-if"  md-diameter="30" md-mode="indeterminate" ng-if="permissionmanagement.isSaving"></md-progress-circular>\n' +
    '										<p ng-if="permissionmanagement.saved == \'success\' && assignmentForm.$pristine">Saved changes successfully</p>\n' +
    '										<p ng-if="permissionmanagement.saved == \'error\' && assignmentForm.$pristine">Unable to save changes</p>\n' +
    '										<md-button class="md-raised md-accent" ng-if="permissionmanagement.assignPermissionsToGroups === true" ng-disabled="assignmentForm.$pristine || !permissionmanagement.thisPermissionGroup || permissionmanagement.isSaving" ng-click="permissionmanagement.savePermissionsToGroup(assignmentForm)" wraps-tooltip="permissions-assignment-save">Save Permissions</md-button>\n' +
    '									</div>\n' +
    '							</form>\n' +
    '						</md-tab-body>\n' +
    '					</md-tab>\n' +
    '				  <md-tab ng-repeat="(tableName, tableProperties) in permissionmanagement.tables"\n' +
    '						  ng-disabled="tableProperties.disabled">\n' +
    '					  <md-tab-label><span wraps-tooltip="permissions-tab">{{tableProperties.friendlyName}}</span></md-tab-label>\n' +
    '					  <md-tab-body>\n' +
    '						  <md-data-table-container ng-if="permissionmanagement.tableName">\n' +
    '							  <md-progress-linear md-mode="query" ng-show="permissionmanagement.rows.length == 0"></md-progress-linear>\n' +
    '							  <table md-data-table  > <!--md-row-select="selected"-->\n' +
    '								  <thead>\n' +
    '								  <tr>\n' +
    '									  <!--<th order-by="id" numeric>ID</th>-->\n' +
    '									  <th style="width:80px; text-align:center;">\n' +
    '										  <md-button ng-if="$storage.selectedRow == 0" type="button" class="md-icon-button md-accent" ng-disabled="permissionmanagement.checkObj(permissionmanagement.deleteArray)" ng-click="permissionmanagement.deleteRows(permissionmanagement.deleteArray)" aria-label="Delete Selected Rows">\n' +
    '											  <ng-md-icon icon="delete" wraps-tooltip="permissions-delete-button"></ng-md-icon>\n' +
    '										  </md-button>\n' +
    '									  </th>\n' +
    '									  <th ng-repeat="col in permissionmanagement.cols" ng-click="sortBy(col.name)" wraps-tooltip="permissions-table-heading">{{col.name | titlecase}} <ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: permissionmanagement.orderBy === col.name || permissionmanagement.orderBy[0] === col.name, reverse: permissionmanagement.reverse}"></ng-md-icon></th>\n' +
    '									  <th style="width: 80px;"></th>\n' +
    '								  </tr>\n' +
    '								  </thead>\n' +
    '\n' +
    '\n' +
    '								  <tbody>\n' +
    '								  <tr ng-repeat="row in permissionmanagement.rows|orderBy:permissionmanagement.orderBy:permissionmanagement.reverse track by row[permissionmanagement.pk]" class="data-rows" wraps-tooltip="permissions-table-row-data" ng-if="permissionmanagement.tableName == tableName">\n' +
    '									  <td style="text-align: center;">\n' +
    '										  <md-checkbox ng-if="$storage.selectedRow == 0" ng-model="permissionmanagement.deleteArray[row[permissionmanagement.pk]]" ng-click="$event.stopPropagation()" wraps-tooltip="permissions-table-select-row">\n' +
    '											  <span ng-hide="true">Select Row</span>\n' +
    '										  </md-checkbox>\n' +
    '									  </td>\n' +
    '									  <td ng-repeat="col in permissionmanagement.cols">\n' +
    '										  <span ng-if="permissionmanagement.tables[permissionmanagement.tableName].dropDown.childColName == col.name">{{row[permissionmanagement.tables[permissionmanagement.tableName].dropDown.tableName][permissionmanagement.tables[permissionmanagement.tableName].dropDown.childColName]}}</span>\n' +
    '										  <span ng-if="permissionmanagement.tables[permissionmanagement.tableName].dropDown.childColName != col.name">{{row[col.name]}}</span></td>\n' +
    '									  <td>\n' +
    '										  <div class="md-actions" style="float:right">\n' +
    '											  <md-button aria-label="Edit Row" class="md-icon-button md-default" ng-click="$storage.selectedRow  = row[permissionmanagement.pk]; permissionmanagement.openEdit(row)" wraps-tooltip="permissions-table-edit-row"><ng-md-icon icon="mode_edit" ></ng-md-icon></md-button>\n' +
    '										  </div>\n' +
    '									  </td>\n' +
    '								  </tr>\n' +
    '\n' +
    '\n' +
    '								  </tbody>\n' +
    '\n' +
    '\n' +
    '							  </table>\n' +
    '						  </md-data-table-container>\n' +
    '					  </md-tab-body>\n' +
    '				  </md-tab>\n' +
    '				\n' +
    '				</md-tabs>\n' +
    '            </div>\n' +
    '            <!--</md-data-table-toolbar>-->\n' +
    '\n' +
    '            <!--<md-data-table-pagination md-limit="users.usersQuery.limit" md-page="users.usersQuery.page" md-total="{{users.filteredUsers.length}}"></md-data-table-pagination>-->\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '	</div>\n' +
    '	<div class="md-whiteframe-z1 default-box" style="" ng-if="permissionmanagement.hasAccess === false">\n' +
    '		<md-data-table-toolbar><h2  flex>Access denied<br /><br /><br /></h2>\n' +
    '\n' +
    '		</md-data-table-toolbar>\n' +
    '	</div>\n' +
    '	<div class="md-whiteframe-z1 default-box" style="" ng-if="permissionmanagement.hasAccess === null" >\n' +
    '		 <md-data-table-toolbar><h2  flex><br/></h2></md-data-table-toolbar>\n' +
    '            <md-progress-circular style="position: absolute; top: 10px; left: 50%; margin-left: -50px;"  md-diameter="80" md-mode="indeterminate"></md-progress-circular>\n' +
    '	</div>\n' +
    '	\n' +
    '	<md-sidenav class="md-sidenav-right md-whiteframe-z3 library-sidenav" md-component-id="right" style="position: fixed;">\n' +
    '		<permissionmanagement-row-edit></permissionmanagement-row-edit>\n' +
    '	</md-sidenav>\n' +
    '\n' +
    '</md-content>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('permissionmanagement/permissionmanagement-row-edit-directive.tpl.html',
    '<div>\n' +
    '<md-toolbar class="md-theme-light">\n' +
    '    <div class="md-toolbar-tools">\n' +
    '       	\n' +
    '		<h1 flex class="md-title" wraps-tooltip="permission-edit-title"><span ng-if="$storage.selectedRow == 0">Add Row</span><span ng-if="$storage.selectedRow != 0">Edit Row {{$storage.selectedRow}}</span> in {{permissionmanagement.tableName | titlecase}}</h1>\n' +
    '		<md-button aria-label="Close Pane" ng-click="permissionmanagement.closeEdit()" class="md-icon-button" wraps-tooltip="permission-edit-close">\n' +
    '            <ng-md-icon icon="close"></ng-md-icon>\n' +
    '        </md-button>\n' +
    '        \n' +
    '    </div>\n' +
    '</md-toolbar>\n' +
    '<md-content  layout-padding style="padding-left: 20px;">\n' +
    '\n' +
    '    <div style="padding:0px 20px 80px;"  >\n' +
    '			<div class="details-row">\n' +
    '				<!---EDIT FORM--->\n' +
    '				<div ng-repeat="col in permissionmanagement.cols" wraps-tooltip="permission-edit-field">\n' +
    '					<span ng-if="permissionmanagement.pk != col.name && permissionmanagement.tables[permissionmanagement.tableName].dropDown.childColName != col.name">\n' +
    '						<md-input-container ng-class="{\'md-input-invalid\': permissionmanagement.errors[col.name]}">\n' +
    '						<label>{{col.name | titlecase}}</label>\n' +
    '						<input ng-model="$storage[permissionmanagement.tableName][col.name]">\n' +
    '						<p class="error" ng-show="permissionmanagement.errors[col.name]">{{permissionmanagement.errors[col.name]}}</p>\n' +
    '						</md-input-container>\n' +
    '					</span>\n' +
    '					<span ng-if="permissionmanagement.tables[permissionmanagement.tableName].dropDown.childColName == col.name">\n' +
    '						<md-input-container ng-class="{\'md-input-invalid\': permissionmanagement.errors[col.name]}">\n' +
    '							<label>{{col.name | titlecase}}</label>\n' +
    '							<md-select ng-model="$storage[permissionmanagement.tableName][permissionmanagement.tables[permissionmanagement.tableName].dropDown.childPk]">\n' +
    '								<md-option value="{{opt[permissionmanagement.tables[permissionmanagement.tableName].dropDown.childPk]}}" ng-repeat="opt in permissionmanagement[permissionmanagement.tables[permissionmanagement.tableName].dropDown.tableName]">{{opt[permissionmanagement.tables[permissionmanagement.tableName].dropDown.childColName]}}</md-option>\n' +
    '							</md-select>\n' +
    '						<p class="error" ng-show="permissionmanagement.errors[col.name]">{{permissionmanagement.errors[col.name]}}</p>\n' +
    '						</md-input-container>\n' +
    '					</span>\n' +
    '				</div>\n' +
    '\n' +
    '				<div>\n' +
    '					<md-button  type="button" class="md-raised"  ng-click="$storage.selectedRow = 0; $storage[permissionmanagement.tableName] = {}; permissionmanagement.closeEdit();" wraps-tooltip="permission-edit-close">Cancel</md-button>\n' +
    '					<md-button  type="button" class="md-primary md-raised" ng-if="$storage.selectedRow != 0" ng-disabled="permissionmanagement.compareJson($storage[permissionmanagement.tableName],row)"  ng-click="permissionmanagement.saveRow($storage[permissionmanagement.tableName], \'edit\');" wraps-tooltip="permission-edit-save">Save Row</md-button>\n' +
    '					<md-button  type="button" class="md-raised md-primary" ng-if="$storage.selectedRow == 0" ng-disabled="permissionmanagement.checkObj($storage[permissionmanagement.tableName])"  ng-click="permissionmanagement.saveRow($storage[permissionmanagement.tableName], \'new\');" wraps-tooltip="permission-edit-save">Save New</md-button>\n' +
    '				</div>\n' +
    '			</div>\n' +
    '	</div>\n' +
    '\n' +
    '</md-content>\n' +
    '</div>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('maintenance/row-edit-directive.tpl.html',
    '<div>\n' +
    '<md-toolbar class="md-theme-light">\n' +
    '    <div class="md-toolbar-tools">\n' +
    '		\n' +
    '		<h1 flex class="md-title" wraps-tooltip="maintenance-form-title"><span ng-if="$storage.selectedRow == 0">Add Row</span><span ng-if="$storage.selectedRow != 0">Edit Row {{$storage.selectedRow}}</span> in {{maintenance.tableName}}</h1>\n' +
    '		<md-button ng-click="maintenance.closeEdit()" class="md-icon-button" aria-label="Close pane" wraps-tooltip="maintenance-form-close">\n' +
    '            <ng-md-icon icon="close" size="24"></ng-md-icon>\n' +
    '        </md-button>\n' +
    '        \n' +
    '    </div>\n' +
    '</md-toolbar>\n' +
    '<md-content  layout-padding>\n' +
    '	<form name="metaMaint">\n' +
    '		<div style="padding:20px 20px 80px;"  >\n' +
    '				<div class="details-row">\n' +
    '					<!---EDIT FORM--->\n' +
    '					<div ng-repeat="col in maintenance.cols" wraps-tooltip="maintenance-form-input">\n' +
    '						<md-input-container ng-if="maintenance.pk != col.name && maintenance.tables[maintenance.tableName].dropDown[col.name].childColName != col.name && col.fieldType != \'textarea\'" ng-class="{\'md-input-invalid\': maintenance.errors[col.name]}" style="width:100%">\n' +
    '						<label>{{col.name | titlecase}}</label>\n' +
    '						<input name="{{$storage[maintenance.tableName][col.name]}}" ng-model="$storage[maintenance.tableName][col.name]" ng-keypress="maintenance.validateCharactors($event, col.pattern)" ng-keyup="col.uppercase?$storage[maintenance.tableName][col.name]=$storage[maintenance.tableName][col.name].toUpperCase():\'\'" ng-maxlength="{{col.maxLength}}" md-maxlength="{{col.maxLength}}" maxlength="{{col.maxLength}}" ng-minlength="{{col.minLength}}" type="{{col.type?col.type:\'text\'}}">\n' +
    '						<p class="error" ng-show="maintenance.errors[col.name]">{{maintenance.errors[col.name]}}</p>\n' +
    '						<p class="error" ng-if="col.minLength && col.minLength != \'\' && !metaMaint[$storage[maintenance.tableName][col.name]].$valid">This field must be a minimum of {{col.minLength}}</p>\n' +
    '						</md-input-container>\n' +
    '						<md-input-container ng-if="maintenance.pk != col.name && maintenance.tables[maintenance.tableName].dropDown[col.name].childColName == col.name && col.fieldType != \'textarea\'" ng-class="{\'md-input-invalid\': maintenance.errors[col.name]}" style="width:100%">\n' +
    '							<label>{{col.name | titlecase}}</label>\n' +
    '						<md-select ng-model="$storage[maintenance.tableName][maintenance.tables[maintenance.tableName].dropDown[col.name].parentColName]">\n' +
    '							<md-option value="" ng-if="maintenance.tables[maintenance.tableName].dropDown[col.name].addNullValue == true">N/A</md-option>\n' +
    '							<md-option ng-if="!maintenance.tables[maintenance.tableName].dropDown[col.name].filter" value="{{opt[maintenance.tables[maintenance.tableName].dropDown[col.name].childPk]}}" ng-repeat="opt in maintenance.joinTableData[maintenance.tables[maintenance.tableName].dropDown[col.name].tableName]">{{opt[maintenance.tables[maintenance.tableName].dropDown[col.name].childColName]}}</md-option>\n' +
    '							<md-option ng-if="maintenance.tables[maintenance.tableName].dropDown[col.name].filter" value="{{opt[maintenance.tables[maintenance.tableName].dropDown[col.name].childPk]}}" ng-repeat="opt in maintenance.joinTableData[maintenance.tables[maintenance.tableName].dropDown[col.name].tableName] | filter: maintenance.tables[maintenance.tableName].dropDown[col.name].filter">{{opt[maintenance.tables[maintenance.tableName].dropDown[col.name].childColName]}}</md-option>\n' +
    '						</md-select>\n' +
    '						<p class="error" ng-show="maintenance.errors[col.name]">{{maintenance.errors[col.name]}}</p>\n' +
    '						</md-input-container>\n' +
    '\n' +
    '						<md-input-container ng-if="maintenance.pk != col.name && maintenance.tables[maintenance.tableName].dropDown[col.name].childColName != col.name && col.fieldType == \'textarea\'" ng-class="{\'md-input-invalid\': maintenance.errors[col.name]}" style="width:100%">\n' +
    '							<label>{{col.name | titlecase}}</label>\n' +
    '							<textarea>{{$storage[maintenance.tableName][col.name]}}</textarea>\n' +
    '							<p class="error" ng-show="maintenance.errors[col.name]">{{maintenance.errors[col.name]}}</p>\n' +
    '						</md-input-container>\n' +
    '					</div>\n' +
    '\n' +
    '					<span ng-if="maintenance.tableAdmin === true && maintenance.tableBehaviour == 1" ng-show="true"><!--hiding divisions-->\n' +
    '						<md-input-container>\n' +
    '							<md-checkbox ng-repeat="division in maintenance.divisions" ng-model="division.selected">{{division.division}}</md-checkbox><br />\n' +
    '						</md-input-container>\n' +
    '					</span>\n' +
    '\n' +
    '					<span ng-if="maintenance.tableAdmin === true && maintenance.tableBehaviour == 2" ng-show="true"><!--hiding divisions-->\n' +
    '						<md-radio-group ng-model="maintenance.selectedDivision" ng-change="maintenance.updateSelection(maintenance.selectedDivision, maintenance.divisions)">\n' +
    '							<md-radio-button ng-repeat="division in maintenance.divisions" ng-value="division.divisionId">{{division.division}}</md-radio-button><br />\n' +
    '						</md-radio-group>\n' +
    '					</span>\n' +
    '\n' +
    '					<div>\n' +
    '						<md-button  type="button" class="md-raised"  ng-click="$storage.selectedRow = 0; $storage[maintenance.tableName] = {}; maintenance.closeEdit();" wraps-tooltip="maintenance-form-close">Cancel</md-button>\n' +
    '						<md-button  type="button" class="md-primary md-raised" ng-disabled="maintenance.compareJson($storage[maintenance.tableName],row) || !metaMaint.$valid" ng-if="$storage.selectedRow != 0"  ng-click="maintenance.saveRow($storage[maintenance.tableName], \'edit\');" wraps-tooltip="maintenance-form-save">Save Row</md-button>\n' +
    '						<md-button  type="button" class="md-primary md-raised" ng-disabled="maintenance.compareJson($storage[maintenance.tableName],row) || !metaMaint.$valid" ng-if="$storage.selectedRow == 0"  ng-click="maintenance.saveRow($storage[maintenance.tableName], \'new\');" wraps-tooltip="maintenance-form-save">Save Row</md-button>\n' +
    '					</div>\n' +
    '				</div>\n' +
    '\n' +
    '\n' +
    '		</div>\n' +
    '	</form>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '</md-content>\n' +
    '</div>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('maintenance/maintenance.tpl.html',
    '<md-content layout="row" layout-wrap layout-align="center start" class="default-content">\n' +
    '\n' +
    '    <div class="md-whiteframe-z1 default-box" style="" ng-if="maintenance.hasAccess == true">\n' +
    '\n' +
    '\n' +
    '        <md-progress-circular style="position: absolute; top: 0; left: 50%; margin-left: -50px;" md-diameter="100"\n' +
    '                              md-mode="indeterminate" ng-show="maintenance.isLoading"></md-progress-circular>\n' +
    '\n' +
    '        <md-data-table-toolbar layout-wrap>\n' +
    '            <h2 flex wraps-tooltip="maintenance-title">Meta List Maintenance</h2>\n' +
    '            <md-button aria-label="Download CSV" class="md-icon-button" ng-csv="maintenance.downloadCsv()"  lazy-load="true" filename="{{maintenance.tableName | lowercase}}-download.csv" add-bom="true" charset="utf-8" csv-label="true">\n' +
    '                <ng-md-icon icon="file_download" style="fill: grey; " size="24" wraps-tooltip="table-data-download"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button aria-label="Open search" class="md-icon-button" ng-hide="maintenance.searchMaintenance"\n' +
    '                       ng-click="maintenance.searchMaintenance=true" wraps-tooltip="maintenance-search-button"\n' +
    '                       aria-label="Open search">\n' +
    '                <ng-md-icon icon="search" style="fill: grey;" size="24"></ng-md-icon>\n' +
    '            </md-button>\n' +
    '            <md-button class="md-raised md-accent" ng-click="maintenance.openEdit()"\n' +
    '                       wraps-tooltip="maintenance-add-btn"\n' +
    '                       ng-if="(maintenance.tableBehaviour == 1 && maintenance.tableAdmin == true) || maintenance.tableBehaviour == 2 || (maintenance.tableBehaviour == 3 && maintenance.tableAdmin == true)"\n' +
    '            >Add Row\n' +
    '            </md-button>\n' +
    '            <div layout="row" layout-align="space-between start" flex="100" class="md-caption" style="margin-bottom:8px">\n' +
    '                <span>Selected list: <strong>{{maintenance.tableName|titlecase}}</strong></span>\n' +
    '                <span>Division behaviour: <strong ng-if="maintenance.tableBehaviour == 1">Partially shared</strong><strong ng-if="maintenance.tableBehaviour == 2">Separate</strong><strong ng-if="maintenance.tableBehaviour == 3">Fully shared</strong></span>\n' +
    '                <span>Access level: <strong ng-if="maintenance.tableAdmin">Full admin</strong><strong ng-if="!maintenance.tableAdmin">Division admin</strong></span>\n' +
    '                <span>Your division: <strong>{{maintenance.divisionName|titlecase}}</strong></span>\n' +
    '            </div>\n' +
    '        </md-data-table-toolbar>\n' +
    '\n' +
    '        <!--<md-data-table-toolbar>-->\n' +
    '        <div>\n' +
    '            <md-tabs md-selected="maintenance.selectedTabIndex" md-border-bottom md-autoselect md-swipe-content\n' +
    '                     md-dynamic-height>\n' +
    '                <md-tab ng-repeat="(tableName, tableProperties) in maintenance.tables"\n' +
    '                        ng-disabled="tableProperties.disabled">\n' +
    '                    <md-tab-label>\n' +
    '                        <span wraps-tooltip="maintenance-tab">{{tableProperties.tableLabel | titlecase}}</span>\n' +
    '                    </md-tab-label>\n' +
    '                    <md-tab-body>\n' +
    '                        <md-data-table-container ng-if="maintenance.tableName">\n' +
    '                            <table md-data-table> <!--md-row-select="selected"-->\n' +
    '                                <thead ng-show="maintenance.searchMaintenance">\n' +
    '                                <tr>\n' +
    '                                    <td ng-if="maintenance.tableBehaviour != 3 || (maintenance.tableBehaviour == 3 && maintenance.tableAdmin == true)"></td>\n' +
    '                                    <td ng-repeat="col in maintenance.cols" wraps-tooltip="maintenance-search-input">\n' +
    '                                        <md-input-container\n' +
    '                                                ng-if="maintenance.tables[maintenance.tableName].dropDown[col.name].childColName != col.name">\n' +
    '                                            <input ng-model="maintenance[\'filter\' + maintenance.tableName][col.name]"\n' +
    '                                                   style="width: 90%;" aria-label="{{\'Filter \' + col.name}}">\n' +
    '                                        </md-input-container>\n' +
    '                                        <md-input-container\n' +
    '                                                ng-if="maintenance.tables[maintenance.tableName].dropDown[col.name].childColName == col.name">\n' +
    '                                            <input ng-model="maintenance[\'filter\' + maintenance.tableName][maintenance.tables[maintenance.tableName].dropDown[col.name].tableName][maintenance.tables[maintenance.tableName].dropDown[col.name].childColName]"\n' +
    '                                                   style="width: 90%;" aria-label="{{\'Filter \' + col.name}}">\n' +
    '                                        </md-input-container>\n' +
    '                                    </td>\n' +
    '\n' +
    '                                    <td ng-if="maintenance.tableAdmin == true && maintenance.tableBehaviour != 3" ng-show="true"></td><!--hiding divisions-->\n' +
    '\n' +
    '                                    <td style="padding:0 10px 0 3px">\n' +
    '                                        <div class="md-actions" style="float:right">\n' +
    '                                            <md-button aria-label="Close Search" class="md-icon-button"\n' +
    '                                                       ng-click="maintenance.searchMaintenance=false; maintenance[\'filter\' + maintenance.tableName]={}"\n' +
    '                                                       wraps-tooltip="maintenance-search-close">\n' +
    '                                                <ng-md-icon icon="close" style="fill: grey; " size="24"></ng-md-icon>\n' +
    '                                            </md-button>\n' +
    '                                        </div>\n' +
    '                                    </td>\n' +
    '                                </tr>\n' +
    '                                </thead>\n' +
    '                                <thead>\n' +
    '                                <tr>\n' +
    '                                    <!--<th order-by="id" numeric>ID</th>-->\n' +
    '                                    <th style="width:80px; text-align:center;">\n' +
    '                                        <md-button ng-if="maintenance.tableAdmin == true || maintenance.tableBehaviour == 2 || (maintenance.tableBehaviour == 3 && maintenance.tableAdmin == true)" type="button"\n' +
    '                                                   class="md-icon-button md-accent"\n' +
    '                                                   ng-disabled="maintenance.checkObj(maintenance.deleteArray)"\n' +
    '                                                   ng-click="maintenance.deleteRows(maintenance.deleteArray)"\n' +
    '                                                   aria-label="Delete Selected Rows"\n' +
    '                                                   wraps-tooltip="maintenance-delete-btn">\n' +
    '                                            <ng-md-icon icon="delete"></ng-md-icon>\n' +
    '                                        </md-button>\n' +
    '                                    </th>\n' +
    '                                    <th ng-repeat="col in maintenance.cols" ng-click="sortBy(col.name)"\n' +
    '                                        wraps-tooltip="maintenance-table-heading">{{col.name | titlecase}}\n' +
    '                                        <ng-md-icon icon="arrow_upwards" size="15" class="sorticon"\n' +
    '                                                    ng-class="{active: maintenance.orderBy === col.name || maintenance.orderBy[0] === col.name || maintenance.orderBy === maintenance.tables[maintenance.tableName].dropDown[col.name].tableName + \'.\' + col.name , reverse: maintenance.reverse}"></ng-md-icon>\n' +
    '                                    </th>\n' +
    '                                    <th ng-if="maintenance.tableAdmin == true && maintenance.tableBehaviour != 3" ng-show="true">Divisions</th><!--hiding divisions-->\n' +
    '                                    <th style="width: 80px;"></th>\n' +
    '                                </tr>\n' +
    '                                </thead>\n' +
    '\n' +
    '\n' +
    '                                <tbody>\n' +
    '                                <tr ng-repeat="row in maintenance.filteredRows = (maintenance.rows | filter:maintenance[\'filter\' + maintenance.tableName]) | orderBy:maintenance.orderBy:maintenance.reverse | limitTo: maintenance.maintQuery.limit: (maintenance.maintQuery.page- 1) * maintenance.maintQuery.limit track by row[maintenance.pk]"\n' +
    '                                    class="data-rows" ng-if="maintenance.tableName == tableName"\n' +
    '                                    wraps-tooltip="maintenance-table-row">\n' +
    '                                    <td style="text-align: center;">\n' +
    '                                        <md-checkbox ng-if="maintenance.tableAdmin == true || maintenance.tableBehaviour == 2 || (maintenance.tableBehaviour == 3 && maintenance.tableAdmin == true)"\n' +
    '                                                     ng-model="maintenance.deleteArray[row[maintenance.pk]]"\n' +
    '                                                     ng-click="$event.stopPropagation()"\n' +
    '                                                     wraps-tooltip="maintenance-select-row">\n' +
    '                                            <span ng-hide="true">Select Row</span>\n' +
    '                                        </md-checkbox>\n' +
    '                                        <md-switch\n' +
    '                                                ng-if="maintenance.tableAdmin == false && maintenance.tableBehaviour == 1"\n' +
    '                                                name="{{maintenance.tableName}}_{{row[maintenance.pk]}}"\n' +
    '                                                ng-model="row.selected"\n' +
    '                                                ng-change="maintenance.toggleDivisionMeta(row)"\n' +
    '                                                aria-label="{{maintenance.tableName}} toggle switch"\n' +
    '                                        >\n' +
    '                                        </md-switch>\n' +
    '                                    </td>\n' +
    '                                    <td ng-repeat="col in maintenance.cols">\n' +
    '                                        <span ng-if="maintenance.tables[maintenance.tableName].dropDown[col.name].childColName == col.name">{{row[maintenance.tables[maintenance.tableName].dropDown[col.name].tableName][col.name]}}</span>\n' +
    '                                        <span ng-if="maintenance.tables[maintenance.tableName].dropDown[col.name].childColName != col.name">{{row[col.name]}}</span>\n' +
    '                                    </td>\n' +
    '                                    <td ng-if="maintenance.tableAdmin == true && maintenance.tableBehaviour != 3" ng-show="true"><span>{{maintenance.joinDivisions(row.divisions)}}</span></td><!--hiding divisions-->\n' +
    '                                    <td>\n' +
    '                                        <div class="md-actions" style="float:right" ng-if="(maintenance.tableBehaviour == 1 && maintenance.tableAdmin == true) || maintenance.tableBehaviour == 2 || (maintenance.tableBehaviour == 3 && maintenance.tableAdmin == true)">\n' +
    '                                            <md-button aria-label="Edit Row" class="md-icon-button md-default"\n' +
    '                                                       ng-click="$storage.selectedRow  = row[maintenance.pk]; maintenance.openEdit(row)"\n' +
    '                                                       wraps-tooltip="maintenance-edit-row">\n' +
    '                                                <ng-md-icon icon="mode_edit"></ng-md-icon>\n' +
    '                                            </md-button>\n' +
    '                                        </div>\n' +
    '                                    </td>\n' +
    '                                </tr>\n' +
    '\n' +
    '\n' +
    '                                </tbody>\n' +
    '\n' +
    '\n' +
    '                            </table>\n' +
    '\n' +
    '                        </md-data-table-container>\n' +
    '                        <md-data-table-pagination md-limit="maintenance.maintQuery.limit"\n' +
    '                                                  md-page="maintenance.maintQuery.page"\n' +
    '                                                  md-total="{{maintenance.filteredRows.length}}"></md-data-table-pagination>\n' +
    '\n' +
    '                    </md-tab-body>\n' +
    '                </md-tab>\n' +
    '            </md-tabs>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--<md-data-table-pagination md-limit="users.usersQuery.limit" md-page="users.usersQuery.page" md-total="{{users.filteredUsers.length}}"></md-data-table-pagination>-->\n' +
    '\n' +
    '\n' +
    '    </div>\n' +
    '    <div class="md-whiteframe-z1 default-box" style="" ng-if="maintenance.hasAccess === false">\n' +
    '        <md-data-table-toolbar><h2 flex>Access denied<br/><br/><br/></h2>\n' +
    '\n' +
    '        </md-data-table-toolbar>\n' +
    '    </div>\n' +
    '    <div class="md-whiteframe-z1 default-box" style="" ng-if="maintenance.hasAccess === null">\n' +
    '        <md-data-table-toolbar><h2 flex><br/></h2></md-data-table-toolbar>\n' +
    '        <md-progress-circular style="position: absolute; top: 10px; left: 50%; margin-left: -50px;" md-diameter="80"\n' +
    '                              md-mode="indeterminate"></md-progress-circular>\n' +
    '    </div>\n' +
    '\n' +
    '    <md-sidenav class="md-sidenav-right md-whiteframe-z3 library-sidenav" md-component-id="right"\n' +
    '                style="position: fixed;">\n' +
    '        <row-edit></row-edit>\n' +
    '    </md-sidenav>\n' +
    '\n' +
    '</md-content>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('new-library/new-library.tpl.html',
    '<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">\n' +
    '\n' +
    '    <div class="md-whiteframe-z1 default-box" style="" >\n' +
    '        <md-content layout-padding >\n' +
    '            <h2  flex>New Library</h2>\n' +
    '\n' +
    '\n' +
    '            <md-progress-circular style="position: absolute; top: 10px; left: 50%; margin-left: -50px;" class="animate-if"  md-diameter="80" md-mode="indeterminate" ng-if="newLibrary.isLoading"></md-progress-circular>\n' +
    '\n' +
    '            <p ng-repeat="book in newLibrary.books"> {{book.bookId}} - <a ng-click="book.getBook()">{{book.bookCode}} - {{book.lastUpdated}}</a>  - - - {{book.Children[0].structureId}} </p>\n' +
    '\n' +
    '        </md-content>\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '\n' +
    '\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('navigation/navigation.tpl.html',
    '<md-toolbar layout="row" class="md-whiteframe-z0" layout-wrap layout-align="center start" ng-class="{ \'login-toolbar\': (navigation.isActive(\'/login\') || navigation.isActive(\'/reset\')), \'default-toolbar\': (!navigation.isActive(\'/login\') && !navigation.isActive(\'/reset\')), \'switch\':  navigation.system == \'switch\' }" >\n' +
    '\n' +
    '    <div class="md-toolbar-tools">\n' +
    '		<!--Left Header-->\n' +
    '		<div flex layout="row" layout-align="start center" ng-if="(!navigation.isActive(\'/login\') && !navigation.isActive(\'/reset\'))">\n' +
    '			<md-button class="md-icon-button" ng-href="/home" aria-label="Home" wraps-tooltip="navigation-home">\n' +
    '				<ng-md-icon icon="home" style="fill: white" size="24"></ng-md-icon>\n' +
    '			</md-button>\n' +
    '\n' +
    '			<md-button class="md-button" ng-href="/library" aria-label="Library"  ng-if="navigation.hasPermissions(\'viewLibrary\')" wraps-tooltip="navigation-library">\n' +
    '				Library\n' +
    '			</md-button>\n' +
    '\n' +
    '			<md-button ng-click="" ng-href="/drive" aria-label="Drive" ng-if="navigation.hasPermissions(\'viewDrive\')" wraps-tooltip="navigation-drive">\n' +
    '				Drive\n' +
    '			</md-button>\n' +
    '\n' +
    '			<md-button ng-click="" href=" {{navigation.clientConfig.helpsiteLink}} " target="_blank" wraps-tooltip="navigation-help" aria-label="help">\n' +
    '				Help\n' +
    '			</md-button>\n' +
    '\n' +
    '			<div class="md-menu" ng-if="navigation.hasPermissions(\'viewHealthcheck\') ||\n' +
    '                                        navigation.hasAnyOfPermissionCat(3) ||\n' +
    '                                        navigation.hasAnyOfPermissionCat(10) ||\n' +
    '                                        navigation.hasAnyOfPermissionCat(5) ||\n' +
    '                                        navigation.hasPermissions(\'adminReports\') ||\n' +
    '                                        navigation.hasPermissions(\'adminUsers\')">\n' +
    '				<div class="menu-container" layout-align="center right" layout="column">\n' +
    '					<md-menu md-position-mode="target"  md-offset="-8 36">\n' +
    '						<div aria-label="Additional Navigation Items" ng-click="$mdOpenMenu()" wraps-tooltip="navigation-more">\n' +
    '							<ng-md-icon icon="more_vert"></ng-md-icon>\n' +
    '						</div>\n' +
    '						<md-menu-content>\n' +
    '\n' +
    '							<md-menu-item>\n' +
    '								<md-button ng-click="" ng-href="/healthcheck" aria-label="Meta"  ng-if="navigation.hasPermissions(\'viewHealthcheck\')" wraps-tooltip="navigation-maintenance">\n' +
    '									Health Check\n' +
    '								</md-button>\n' +
    '							</md-menu-item>\n' +
    '							<md-menu-item>\n' +
    '								<md-button ng-click="" ng-href="/maintenance" aria-label="Meta"  ng-if="navigation.hasAnyOfPermissionCat(3) || navigation.hasAnyOfPermissionCat(6)" wraps-tooltip="navigation-maintenance">\n' +
    '									Meta Lists\n' +
    '								</md-button>\n' +
    '							</md-menu-item>\n' +
    '							<md-menu-item>\n' +
    '								<md-button ng-click="" ng-href="/permissionmanagement" aria-label="Meta"  ng-if="navigation.hasAnyOfPermissionCat(5)" wraps-tooltip="navigation-permissions">\n' +
    '									Permissions\n' +
    '								</md-button>\n' +
    '							</md-menu-item>\n' +
    '\n' +
    '							<md-menu-item>\n' +
    '								<md-button ng-click="" ng-href="/reports" aria-label="Reports"  ng-if="navigation.hasPermissions(\'adminReports\')" wraps-tooltip="navigation-reports">\n' +
    '									Reports\n' +
    '								</md-button>\n' +
    '							</md-menu-item>\n' +
    '\n' +
    '							<md-menu-item>\n' +
    '								<md-button ng-click="" ng-href="/users" aria-label="Users"  ng-if="navigation.hasPermissions(\'adminUsers\')" wraps-tooltip="navigation-users">\n' +
    '									Users\n' +
    '								</md-button>\n' +
    '							</md-menu-item>\n' +
    '\n' +
    '						</md-menu-content>\n' +
    '					</md-menu>\n' +
    '				</div>\n' +
    '\n' +
    '			</div>\n' +
    '		</div>\n' +
    '		<!--Logo-->\n' +
    '    <div flex layout="row"></div>\n' +
    '\n' +
    '		<img ng-if="navigation.logo != \'switch\' " src="/images/wraps-logo.png"  class="wraps-logo"  wraps-tooltip="wraps-logo">\n' +
    '    <img ng-if="navigation.logo == \'switch\' " src="/images/switch-logo.png" class="switch-logo" wraps-tooltip="switch-logo">\n' +
    '\n' +
    '		<div flex layout="row"></div>\n' +
    '		<!--Right Header-->\n' +
    '        <div flex layout="row" layout-align="end center" ng-if="(!navigation.isActive(\'/login\') && !navigation.isActive(\'/reset\'))">\n' +
    '			<md-button class="md-icon-button has-badge" ng-click="navigation.navbarStatsDialog(0)" wraps-tooltip="navbar-black-assets">\n' +
    '				<ng-md-icon icon="warning" style="fill: white" ng-class="{\'no-action\':navigation.notificationCount.blackAssetsByOwner.blackAssets <= 0}"></ng-md-icon>\n' +
    '				<span ng-if="navigation.notificationCount.blackAssetsByOwner.blackAssets > 0" class="badge" aria-label="Black Assets" wraps-tooltip="notifications-black-assets">{{navigation.notificationCount.blackAssetsByOwner.blackAssets}}</span>\n' +
    '				<md-tooltip>Black Assets</md-tooltip>\n' +
    '			</md-button>\n' +
    '			<md-button class="md-icon-button has-badge" ng-click="navigation.navbarStatsDialog(1)" wraps-tooltip="navbar-actions-user">\n' +
    '				<ng-md-icon icon="error" style="fill: white" ng-class="{\'no-action\':navigation.notificationCount.documentsWithActionsByOwnerPersonal.actions <= 0}"></ng-md-icon>\n' +
    '				<span ng-if="navigation.notificationCount.documentsWithActionsByOwnerPersonal.actions > 0" class="badge" aria-label="Personal Actions" wraps-tooltip="notifications-personal-actions">{{navigation.notificationCount.documentsWithActionsByOwnerPersonal.actions}}</span>\n' +
    '				<md-tooltip>Personal Actions</md-tooltip>\n' +
    '			</md-button>\n' +
    '			<md-button class="md-icon-button has-badge" ng-click="navigation.navbarStatsDialog(2)" wraps-tooltip="navbar-actions-team">\n' +
    '				<ng-md-icon icon="error_outline" style="fill: white" ng-class="{\'no-action\':navigation.notificationCount.documentsWithActionsByOwnerTeam.actions <= 0}"></ng-md-icon>\n' +
    '				<span ng-if="navigation.notificationCount.documentsWithActionsByOwnerTeam.actions > 0" class="badge" aria-label="Team Actions" wraps-tooltip="notifications-team-actions">{{navigation.notificationCount.documentsWithActionsByOwnerTeam.actions}}</span>\n' +
    '				<md-tooltip>Team Actions</md-tooltip>\n' +
    '			</md-button>\n' +
    '			<md-button class="md-icon-button has-badge" ng-click="navigation.navbarStatsDialog(3)" wraps-tooltip="navbar-actions-review">\n' +
    '				<ng-md-icon icon="stars" style="fill: white" ng-class="{\'no-action\':navigation.notificationCount.documentsInReview.inReview <= 0}"></ng-md-icon>\n' +
    '				<span ng-if="navigation.notificationCount.documentsInReview.inReview > 0" class="badge" aria-label="In review" wraps-tooltip="notifications-team-review">{{navigation.notificationCount.documentsInReview.inReview}}</span>\n' +
    '				<md-tooltip>In Review</md-tooltip>\n' +
    '			</md-button>\n' +
    '\n' +
    '\n' +
    '\n' +
    '			<md-button aria-label="Tool tips" class="md-icon-button" style="padding-left:15px;" wraps-tooltip="tooltip-icon" ng-click="$emit(\'toggleTooltip\');" ng-if="navigation.hasPermissions(\'viewTooltips\')">\n' +
    '				<md-tooltip>Tool tips</md-tooltip>\n' +
    '				<ng-md-icon icon="help" style="fill: white;" size="24"></ng-md-icon>\n' +
    '			</md-button>\n' +
    '\n' +
    '			<small style="font-size: 10px; position: relative; top:0px; margin-left:15px; white-space:nowrap;"><md-tooltip ng-if="navigation.spoofingUser()">Impersonating</md-tooltip><ng-md-icon style="vertical-align:middle" icon="person_outline" ng-if="navigation.spoofingUser()" wraps-tooltip="impersonating"></ng-md-icon><b>{{$storage.user.firstName}}</b><!-- - {{$storage.user.Roles.name}} - {{$storage.user.Teams.teamName}}--></small>\n' +
    '\n' +
    '			<div class="md-menu" style="margin-top:5px;" >\n' +
    '				<div class="menu-container" layout-align="center right" layout="column">\n' +
    '					<md-menu md-position-mode="target-right target"  md-offset="5 50">\n' +
    '						<div aria-label="Open tools menu" class="avatar-container" ng-click="$mdOpenMenu()" wraps-tooltip="user-profile-icon">\n' +
    '							<img ng-if="$storage.user.profilePic != \'\' && $storage.user.profilePic != null" ng-src="{{navigation.apiUrl}}/containers/getthumbnail/{{$storage.user.profilePic}}" class="user-avatar" alt="User Avatar"/>\n' +
    '							<div ng-if="$storage.user.profilePic == \'\' || $storage.user.profilePic == null" class="user-avatar initials {{$storage.user.lastName|lowercase|limitTo:1}}">{{$storage.user.firstName|uppercase|limitTo:1}}{{$storage.user.lastName|uppercase|limitTo:1}}</div>\n' +
    '						</div>\n' +
    '						<md-menu-content>\n' +
    '							<md-menu-item>\n' +
    '								<md-button ng-click="" ng-href="/profile">\n' +
    '\n' +
    '									<div layout="row">\n' +
    '										<p flex>User Profile</p>\n' +
    '\n' +
    '										<ng-md-icon icon="person" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '									</div>\n' +
    '								</md-button>\n' +
    '							</md-menu-item>\n' +
    '							<md-menu-item ng-if="navigation.hasPermissions(\'switchUser\') || navigation.spoofingUser()">\n' +
    '								<md-button ng-click="navigation.switchToUserDiag();">\n' +
    '\n' +
    '									<div layout="row">\n' +
    '										<p flex>Impersonate User</p>\n' +
    '\n' +
    '										<ng-md-icon icon="person_outline" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '									</div>\n' +
    '								</md-button>\n' +
    '							</md-menu-item>\n' +
    '							<md-menu-item ng-if="navigation.spoofingUser()">\n' +
    '								<md-button ng-click="navigation.editAsMe();">\n' +
    '\n' +
    '									<div layout="row">\n' +
    '										<p flex>Revert To Your User</p>\n' +
    '\n' +
    '										<ng-md-icon icon="sync" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '									</div>\n' +
    '								</md-button>\n' +
    '							</md-menu-item>\n' +
    '							<md-menu-item>\n' +
    '								<md-button ng-href="/login" class="md-button" aria-label="Log out">\n' +
    '\n' +
    '									<div layout="row">\n' +
    '										<p flex>Log Out</p>\n' +
    '										<ng-md-icon icon="logout" style="fill: grey; padding-left:10px; position:relative;" size="20"></ng-md-icon>\n' +
    '									</div>\n' +
    '								</md-button>\n' +
    '							</md-menu-item>\n' +
    '						</md-menu-content>\n' +
    '					</md-menu>\n' +
    '				</div>\n' +
    '\n' +
    '			</div>\n' +
    '        </div>\n' +
    '\n' +
    '    </div>\n' +
    '</md-toolbar>\n' +
    '<div class="floating-impersonation-bar" md-colors="{background:\'primary\'}" ng-if="navigation.spoofingUser() && !navigation.isActive(\'/login\')" wraps-tooltip="impersonating">\n' +
    '	<ng-md-icon icon="person_outline" ng-if="navigation.spoofingUser()"></ng-md-icon> {{$storage.loggedInUser.firstName}}, you are impersonating {{$storage.user.firstName}} {{$storage.user.lastName}}. <md-button ng-click="navigation.editAsMe();">Revert</md-button>\n' +
    '</div>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('library/library.tpl.html',
    '<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content"  ng-keydown="library.keyUpDown($event)">\n' +
    '\n' +
    '\n' +
    '\n' +
    '    <div class="md-whiteframe-z1 default-box" style=""  ng-if="library.hasAccess === true">\n' +
    '		<!-- "ADD" FAB button-->\n' +
    '        <md-button wraps-tooltip="adddoc-button" ng-disabled="library.isLoading" ng-click="library.openRightMenu()" class="md-fab wraps-add-external"  aria-label="Open right bar" ng-if="library.hasPermissions(\'addDocuments\')">\n' +
    '            <ng-md-icon  icon="add" style="fill: white" options="{\'rotation\': \'counterclock\'}" size="30" clickIconMorph></ng-md-icon>\n' +
    '        </md-button>\n' +
    '\n' +
    '\n' +
    '		<!--circular progress notifier-->\n' +
    '        <md-progress-circular style="position: absolute; top: 10px; left: 50%; margin-left: -50px;" class="animate-if"  md-diameter="80" md-mode="indeterminate" ng-if="library.isLoading"></md-progress-circular>\n' +
    '        <md-data-table-toolbar>\n' +
    '            <h2 class="" flex>Library</h2>\n' +
    '            <div ng-if="library.openTabs.length > 0" style="white-space:nowrap; margin-top:42px;" class="md-caption">\n' +
    '                <h4 style="display:inline-block">{{library.openTabs.length}} tab<span ng-if="library.openTabs.length > 1">s</span> open</h4>\n' +
    '                <md-button aria-label="Clear search" class="md-gray md-caption" style="font-size:12px;" ng-click="library.openTabs.length = 0; library.openBooks.length = 0; library.selectedTabIndex = 0" wraps-tooltip="library-search-clear">\n' +
    '                    <ng-md-icon icon="backspace" md-colors="{fill:\'grey\'}" size="18"></ng-md-icon>close all tabs\n' +
    '                </md-button>\n' +
    '            </div>\n' +
    '        </md-button>\n' +
    '\n' +
    '        </md-data-table-toolbar>\n' +
    '        <md-tabs md-selected="library.selectedTabIndex" md-border-bottom md-autoselect md-swipe-content md-dynamic-height>\n' +
    '            <md-tab ng-click="library.openLibraryList()">\n' +
    '                <md-tab-label><ng-md-icon icon="list"></ng-md-icon></md-tab-label>\n' +
    '                <md-tab-body>\n' +
    '                    <tab-list library="library"></tab-list>\n' +
    '                </md-tab-body>\n' +
    '            </md-tab>\n' +
    '            <md-tab ng-repeat="openTab in library.openTabs" ng-click="library.openDoc(openTab.bookId);">\n' +
    '                <md-tab-label><ng-md-icon ng-if="library.openBooks[$index].actionStatus" icon="{{library.icons[library.openBooks[$index].actionStatus -1]}}" md-colors="{fill:library.colors[library.openBooks[$index].actionStatus -1]}" size="20" class="animate-if" wraps-tooltip="action-icon"></ng-md-icon>\n' +
    '                    <ng-md-icon ng-if="!library.openBooks[$index].actionStatus" icon="description" class="tab-doc-icon"  size="20"  md-colors="{fill:openTab.updateRequired?\'accent\':\'grey-600\'}"></ng-md-icon> {{openTab.bookId|seven}}</md-tab-label>\n' +
    '                <md-tab-body>\n' +
    '                    <tab-book ng-if="openTab" book="library.openBooks[$index]" opentab="openTab" library="library" isloading="openTab.isLoading"></tab-book>\n' +
    '                </md-tab-body>\n' +
    '            </md-tab>\n' +
    '            <md-tab md-on-select="library.docOpenDialog()">\n' +
    '                <md-tab-label><ng-md-icon icon="add"></ng-md-icon></md-tab-label>\n' +
    '            </md-tab>\n' +
    '        </md-tabs>\n' +
    '\n' +
    '\n' +
    '            {{library.file}}\n' +
    '			\n' +
    '\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '	<div class="md-whiteframe-z1 default-box" style="" ng-if="library.hasAccess === null" >\n' +
    '		 <md-data-table-toolbar><h2  flex><br/></h2></md-data-table-toolbar>\n' +
    '            <md-progress-circular style="position: absolute; top: 10px; left: 50%; margin-left: -50px;"  md-diameter="80" md-mode="indeterminate"></md-progress-circular>\n' +
    '	</div>\n' +
    '\n' +
    '</md-content>\n' +
    '\n' +
    '<md-sidenav class="md-sidenav-left md-whiteframe-z3 library-sidenav" md-component-id="left" style="position: fixed;">\n' +
    '    <left-menu ng-if="library.leftContent===\'menu\'"></left-menu>\n' +
    '    <left-comments ng-if="library.leftContent===\'comments\'"></left-comments>\n' +
    '</md-sidenav>\n' +
    '\n' +
    '<md-sidenav class="md-sidenav-right md-whiteframe-z3 library-sidenav" md-component-id="right" style="position: fixed;">\n' +
    '    <right-menu></right-menu>\n' +
    '</md-sidenav>\n' +
    '\n' +
    '\n' +
    '<md-button ng-click="library.toTheTop()" class="md-fab md-fab-bottom-right docs-scroll-fab md-button md-ink-ripple"  ng-class="library.showScrollFabs" style="position: fixed;" aria-label="Scroll to top" wraps-tooltip="library-scroll-to-top">\n' +
    '    <ng-md-icon  icon="keyboard_arrow_up" style="fill: white"></ng-md-icon>\n' +
    '</md-button>\n' +
    '\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('login/login.tpl.html',
    '<md-content  layout="row" layout-wrap  layout-align="center start" class="login-box">\n' +
    '\n' +
    '\n' +
    '        <div class="md-whiteframe-z1 " >\n' +
    '            <md-content layout-padding >\n' +
    '                <h2 ng-if="!login.resend" class="">Login</h2>\n' +
    '                <h2  ng-if="login.resend" class="">Resend Password</h2>\n' +
    '                <form name="loginForm" novalidate   ng-submit="login.submitLogin(login.resend)" autocomplete="off">\n' +
    '                    <!--{{ loginForm.email.$error }}-->\n' +
    '                    <div layout="row" class="relative">\n' +
    '                        <md-input-container flex>\n' +
    '                            <label>Email</label>\n' +
    '                            <input ng-model="login.credentials.email"  required type="email" name="email"  minlength="10" >\n' +
    '                            <div   ng-show="loginForm.email.$touched"  ng-messages="loginForm.email.$error">\n' +
    '                                <div ng-message="required, email">Please enter a valid email</div>\n' +
    '                                <div ng-message="minlength">Your email must at least 5 characters</div>\n' +
    '                            </div>\n' +
    '                        </md-input-container>\n' +
    '                        <p ng-if="login.resendMsg" class="animate-if error">{{login.resendMsg}}</p>\n' +
    '                    </div>\n' +
    '                    <!--{{ loginForm.password.$error }}-->\n' +
    '                    <div ng-if="!login.resend"  class="animate-if relative" layout="row">\n' +
    '                        <md-input-container flex  >\n' +
    '                            <label>Password</label>\n' +
    '                            <input ng-model="login.credentials.password" type="password"  required name="password"  minlength="5" >\n' +
    '                            <div ng-show="loginForm.password.$touched" ng-messages="loginForm.password.$error">\n' +
    '                                <div ng-message="required">Please enter a valid password</div>\n' +
    '                                <div ng-message="minlength, maxlength">Your password must at least 5 characters</div>\n' +
    '                            </div>\n' +
    '                        </md-input-container>\n' +
    '                        <p ng-if="login.error" class="animate-if error">{{login.error}}</p>\n' +
    '\n' +
    '                    </div>\n' +
    '\n' +
    '                    <section layout="row" layout-sm="column" layout-align="end center" class="relative">\n' +
    '                        <md-progress-circular style="position: absolute; top: 10px; left: 0px; "  md-diameter="100" md-mode="indeterminate" ng-if="login.isLoading" class="animate-if"></md-progress-circular>\n' +
    '                        <div ng-hide="login.resend"><md-button type="button" class="md-primary button-skinny"  ng-click="login.resend = true">Resend password</md-button>&nbsp;&nbsp;<md-button type="submit" name="loginSubmit" class="md-accent md-raised button-padding" >Login</md-button></div>\n' +
    '                        <div ng-show="login.resend"><md-button type="button" class="md-primary button-skinny" ng-click="login.resend = false">Login</md-button>&nbsp;&nbsp;<md-button type="submit" name="resendSubmit" class="md-accent md-raised button-padding">Resend</md-button></div>\n' +
    '                    </section>\n' +
    '                </form>\n' +
    '\n' +
    '            </md-content>\n' +
    '\n' +
    '\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '</md-content>\n' +
    '\n' +
    '\n' +
    '\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/home.tpl.html',
    '<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">\n' +
    '\n' +
    '\n' +
    '    <div class="md-whiteframe-z1 default-box home-intro" >\n' +
    '        <md-content layout-padding >\n' +
    '\n' +
    '			<!--intro-->\n' +
    '            <h2  class="" wraps-tooltip="dash-title">Welcome to Wraps</h2>\n' +
    '\n' +
    '            <h4 wraps-tooltip="dash-intro">Write, Review, Approve, Publish, Store. All your {{home.clientConfig.name}} documents. Here, in\n' +
    '\n' +
    '            one place.</h4>\n' +
    '\n' +
    '			<div layout="row" layout-align="center center" ng-if="home.hasPermissions(\'viewLibrary\')">\n' +
    '				<md-button class="md-button md-primary md-raised" ui-sref="library" wraps-tooltip="dash-library-btn">Go to Library</md-button>\n' +
    '			</div>\n' +
    '\n' +
    '\n' +
    '        </md-content>\n' +
    '\n' +
    '\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '</md-content>\n' +
    '<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">\n' +
    '	<div class="default-box" layout="row" layout-wrap  layout-align="start start">\n' +
    '		<md-grid-list class="dashboardgrid"\n' +
    '				md-cols-xs="1" md-cols-sm="2" md-cols-md="3" md-cols-gt-md="3" md-cols-gt-lg="5"\n' +
    '				md-row-height-gt-md="1:1" md-row-height="2:2"\n' +
    '				md-gutter="12px" md-gutter-gt-sm="8px" flex="100" >\n' +
    '			<md-grid-tile class="md-whiteframe-z1" md-colspan-sm="2" md-colspan-xs="1">\n' +
    '				<md-content layout-padding layout="row" layout-wrap>\n' +
    '					<div flex="100">\n' +
    '						<h3 wraps-tooltip="dash-profile-title">Your profile</h3>\n' +
    '					</div>\n' +
    '					<div width="50px" layout="row" layout-align="center center" wraps-tooltip="dash-profile-avatar">\n' +
    '						<img ng-if="$storage.user.profilePic != \'\' && $storage.user.profilePic != null" ng-src="{{home.apiUrl}}/containers/getthumbnail/{{$storage.user.profilePic}}" class="user-avatar" alt="User Avatar"/>\n' +
    '						<div ng-if="$storage.user.profilePic == \'\' || $storage.user.profilePic == null" class="user-avatar initials {{$storage.user.lastName|lowercase|limitTo:1}}" >{{$storage.user.firstName|uppercase|limitTo:1}}{{$storage.user.lastName|uppercase|limitTo:1}}</div>\n' +
    '					</div>\n' +
    '					<div flex="auto" layout="row" layout-align="start center" wraps-tooltip="dash-profile-name">\n' +
    '						<h4>{{$storage.user.firstName}} {{$storage.user.lastName}}</h4>\n' +
    '					</div>\n' +
    '					<div flex="100" >\n' +
    '						<p wraps-tooltip="dash-profile-role-team">Wraps role: <strong>{{$storage.user.Roles.name}} - {{$storage.user.Teams.teamName}}</strong></p>\n' +
    '						<p wraps-tooltip="dash-profile-contact-email">Notification email: <strong>{{$storage.user.contactEmail}}</strong></p>\n' +
    '					</div>\n' +
    '					<div flex="100" layout="row" layout-align="end end" >\n' +
    '						<md-button class="md-primary" ui-sref="profile" wraps-tooltip="dash-profile-btn">Edit details</md-button>\n' +
    '					</div>\n' +
    '				</md-content>\n' +
    '			</md-grid-tile>\n' +
    '			<!--<md-grid-tile class="md-whiteframe-z1">\n' +
    '				<md-content layout-padding layout="row" layout-wrap>\n' +
    '					<div>\n' +
    '						<h3 wraps-tooltip="dash-risk-title">User Risk Profile</h3>\n' +
    '					</div>\n' +
    '					<div flex="100" wraps-tooltip="dash-risk-chart">\n' +
    '						<doughnut-chart primary="{value:64,colour:\'#FFC107\'}"></doughnut-chart>\n' +
    '					</div>\n' +
    '					<div flex="100" >\n' +
    '						<p wraps-tooltip="dash-risk-rating"><strong>64</strong> - high risk</p>\n' +
    '					</div>\n' +
    '				</md-content>\n' +
    '			</md-grid-tile>-->\n' +
    '\n' +
    '			<md-grid-tile class="md-whiteframe-z1" md-rowspan="1" md-colspan="2" md-colspan-xs="1">\n' +
    '				<md-content layout-padding layout="row" layout-wrap>\n' +
    '					<div flex="100">\n' +
    '						<h3 wraps-tooltip="dash-actions-title">Your Actions</h3>\n' +
    '						<p>Actions will appear in the Library as <ng-md-icon md-colors="{color:\'blue\'}" icon="error" size="18"></ng-md-icon>or <ng-md-icon md-colors="{color:\'blue\'}" icon="error_outline" size="18"></ng-md-icon>icons; these mean that you or your team have actions to carry out, like submitting, reviewing or publishing. A <ng-md-icon md-colors="{color:\'red\'}" icon="warning" size="18"></ng-md-icon>icon means you have a black asset and need to withdraw the document.</p>\n' +
    '\n' +
    '					</div>\n' +
    '					<div flex="33" ng-if="!home.isLoading" wraps-tooltip="dash-actions-chart">\n' +
    '						<doughnut-chart total="home.actionsDoughnutTotal" series="home.actionsDoughnutSeries"></doughnut-chart>\n' +
    '					</div>\n' +
    '					<div flex="33" ng-if="home.isLoading">\n' +
    '						<md-progress-circular style="left: 50%; margin-left: -50px;" class="animate-if"  md-diameter="80" md-mode="indeterminate"></md-progress-circular>\n' +
    '					</div>\n' +
    '					<div flex="66" >\n' +
    '						<p wraps-tooltip="dash-actions-all"><strong>{{home.activeDocuments.totalActiveDocuments.documents}}</strong> Open documents that are with you or your team</p>\n' +
    '						<p wraps-tooltip="dash-actions-team"><strong>{{home.activeDocuments.teamActiveDocuments.documents}}</strong> Open documents that are with your team</p>\n' +
    '						<p wraps-tooltip="dash-actions-user"><strong>{{home.activeDocuments.personalActiveDocuments.documents}}</strong> Open documents that are with you</p>\n' +
    '					</div>\n' +
    '				</md-content>\n' +
    '			</md-grid-tile>\n' +
    '			<md-grid-tile class="md-whiteframe-z1" md-rowspan="1" md-colspan="2" md-colspan-xs="1">\n' +
    '				<md-content layout-padding layout="row" layout-wrap>\n' +
    '					<div flex="100">\n' +
    '						<h3 wraps-tooltip="dash-involved-title">Open documents you\'re involved in <ng-md-icon icon="help" size="20" style="fill:grey;"><md-tooltip>Open the tool tips bar for more information</md-tooltip></ng-md-icon></h3>\n' +
    '						<p>Open documents are either \'in-work\' or \'live\' and in the process of being published and completed. You are involved in these documents - either you created them, or have been added to the workflow by a colleague. They may be on their way to you, on their way back, or heading off for completion.</p>\n' +
    '					</div>\n' +
    '					<div flex="33" ng-if="!home.isLoading" wraps-tooltip="dash-involved-chart">\n' +
    '						<doughnut-chart primary="home.primaryDocumentsInvolvedIn" total="home.totalDocumentsInvolvedIn" series="home.seriesDocumentsInvolvedIn"></doughnut-chart>\n' +
    '					</div>\n' +
    '					<div flex="33" ng-if="home.isLoading">\n' +
    '						<md-progress-circular style="left: 50%; margin-left: -50px;" class="animate-if"  md-diameter="80" md-mode="indeterminate"></md-progress-circular>\n' +
    '					</div>\n' +
    '					<div flex="66" >\n' +
    '						<p wraps-tooltip="dash-involved-total"><strong>{{home.documents.totalDocuments.documents}}</strong> Total open documents in Wraps</p>\n' +
    '						<p wraps-tooltip="dash-involved-all"><strong>{{home.documents.totalDocumentsInWorkFlow}}</strong> Open documents that you or your team are involved in</p>\n' +
    '						<p wraps-tooltip="dash-involved-team"><strong>{{home.documents.totalDocumentsInWorkFlowTeam}}</strong> Open documents that your team are involved in</p>\n' +
    '						<p wraps-tooltip="dash-involved-user"><strong>{{home.documents.totalDocumentsInWorkFlowPersonal}}</strong> Open documents that you are involved in</p>\n' +
    '					</div>\n' +
    '				</md-content>\n' +
    '			</md-grid-tile>\n' +
    '\n' +
    '\n' +
    '\n' +
    '			<md-grid-tile class="md-whiteframe-z1" md-rowspan="2" md-colspan-sm="2" md-colspan-xs="1">\n' +
    '				<md-content layout-padding layout="row" layout-wrap>\n' +
    '					<div flex="100">\n' +
    '						<h3 wraps-tooltip="dash-status-title">Your documents</h3>\n' +
    '						<p>Information about all the documents owned by you.</p>\n' +
    '					</div>\n' +
    '					<div flex></div>\n' +
    '					<div flex="66" flex-sm="33" flex-xs="33"ng-if="!home.isLoading" wraps-tooltip="dash-status-chart">\n' +
    '						<doughnut-chart total="home.youDocsDoughnutTotal" series="home.youDocsDoughnutSeries"></doughnut-chart>\n' +
    '					</div>\n' +
    '					<div flex="66" flex-sm="33" flex-xs="33" ng-if="home.isLoading">\n' +
    '						<md-progress-circular style="left: 50%; margin-left: -50px;" class="animate-if"  md-diameter="80" md-mode="indeterminate"></md-progress-circular>\n' +
    '					</div>\n' +
    '					<div flex></div>\n' +
    '					<div flex="100">\n' +
    '						<p wraps-tooltip="dash-status-all"><strong>{{home.yourDocuments.total}}</strong> Documents owned by you in Wraps history</p>\n' +
    '						<p wraps-tooltip="dash-status-open"><strong>{{home.yourDocuments.open}}</strong> Open documents owned by you </p>\n' +
    '						<p wraps-tooltip="dash-status-inwork"><strong>{{home.yourDocuments.inWork}}</strong> In-Work documents owned by you</p>\n' +
    '						<p wraps-tooltip="dash-status-ready"><strong>{{home.yourDocuments.ready}}</strong> Ready documents owned by you</p>\n' +
    '						<p wraps-tooltip="dash-status-active"><strong>{{home.yourDocuments.actv}}</strong> Active documents owned by you</p>\n' +
    '						<p wraps-tooltip="dash-status-live"><strong>{{home.yourDocuments.live}}</strong> Live documents owned by you</p>\n' +
    '						<p wraps-tooltip="dash-status-review"><strong>{{home.yourDocuments.liveR}}</strong> Live (Review) documents owned by you</p>\n' +
    '						<p wraps-tooltip="dash-status-expired"><strong>{{home.yourDocuments.expd}}</strong> Expired documents owned by you</p>\n' +
    '						<p wraps-tooltip="dash-status-forced"><strong>{{home.yourDocuments.expdF}}</strong> Expired (Forced) documents owned by you</p>\n' +
    '						<p wraps-tooltip="dash-status-withdrawn"><strong>{{home.yourDocuments.expdW}}</strong> Expired (Withdrawn) documents owned by you</p>\n' +
    '					</div>\n' +
    '				</md-content>\n' +
    '			</md-grid-tile>\n' +
    '\n' +
    '			<!--<md-grid-tile class="md-whiteframe-z1" md-rowspan="2" md-colspan="2" md-colspan-sm="1" md-colspan-xs="1">\n' +
    '				<md-content layout-padding layout="row" layout-wrap>\n' +
    '					<div flex="100">\n' +
    '						<h3 wraps-tooltip="dash-submissions-title">Your Submissions</h3>\n' +
    '					</div>\n' +
    '					<div flex="50" wraps-tooltip="dash-submissions-chart">\n' +
    '						<doughnut-chart total="{value:560,colour:\'#ECEFF1\'}" series="[{value:15, colour:\'#F44336\'},{value:435, colour:\'#FFC107\'},{value:79, colour:\'#8BC34A\'}]"></doughnut-chart>\n' +
    '					</div>\n' +
    '					<div flex="50" >\n' +
    '						<p wraps-tooltip="dash-submissions-all"><strong>560</strong> Documents you\'ve submitted</p>\n' +
    '						<p wraps-tooltip="dash-submissions-rejected"><strong>15</strong> Documents rejected</p>\n' +
    '						<p wraps-tooltip="dash-submissions-review"><strong>435</strong> Documents in review</p>\n' +
    '						<p wraps-tooltip="dash-submissions-approved"><strong>79</strong> Documents approved</p>\n' +
    '					</div>\n' +
    '					<div flex="50" layout="row" layout-align="end center">\n' +
    '						<p wraps-tooltip="dash-submissions-rating"><strong>35%</strong> First time approval rate</p>\n' +
    '					</div>\n' +
    '					<div flex="50"  wraps-tooltip="dash-submissions-rating-chart">\n' +
    '						<doughnut-chart primary="{value:97,colour:\'#8BC34A\'}"></doughnut-chart>\n' +
    '					</div>\n' +
    '				</md-content>\n' +
    '			</md-grid-tile>-->\n' +
    '\n' +
    '			<md-grid-tile class="md-whiteframe-z1" md-rowspan="1" md-colspan="2" md-colspan-xs="1">\n' +
    '				<md-content layout-padding layout="row" layout-wrap>\n' +
    '					<div flex="100">\n' +
    '						<h3 wraps-tooltip="dash-black-assets-title">Black assets <ng-md-icon icon="help" size="20" style="fill:grey;"><md-tooltip>Open the tool tips bar for more information</md-tooltip></ng-md-icon></h3>\n' +
    '						<p>A black asset is a document that is published outside its approval window (i.e. it has expired yet still available for viewing).</p>\n' +
    '					</div>\n' +
    '					<div flex="33" ng-if="!home.isLoading" wraps-tooltip="dash-black-assets-chart">\n' +
    '						<doughnut-chart primary="home.blackAssetsPrimary" total="home.blackAssetsTotal" series="home.blackAssetsSeries"></doughnut-chart>\n' +
    '					</div>\n' +
    '					<div flex="33" ng-if="home.isLoading">\n' +
    '						<md-progress-circular style="left: 50%; margin-left: -50px;" class="animate-if"  md-diameter="80" md-mode="indeterminate"></md-progress-circular>\n' +
    '					</div>\n' +
    '					<div flex="66" >\n' +
    '						<p wraps-tooltip="dash-black-assets-total"><strong>{{home.blackAssets.totalBlackAssets.blackAssets}}</strong> Black assets in Wraps Library</p>\n' +
    '						<p wraps-tooltip="dash-black-assets-all"><strong>{{home.blackAssets.blackAssetsOwned.blackAssets}}</strong> Black assets owned by you</p>\n' +
    '						<p wraps-tooltip="dash-black-assets-finprom"><strong>{{home.blackAssets.blackAssetsFinProms.blackAssets}}</strong> Financial promotion black assets</p>\n' +
    '						<p wraps-tooltip="dash-black-assets-nonfinprom"><strong>{{home.blackAssets.blackAssetsNonFinProms.blackAssets}}</strong> Non-financial promotion black assets</p>\n' +
    '					</div>\n' +
    '				</md-content>\n' +
    '			</md-grid-tile>\n' +
    '\n' +
    '		  </md-grid-list>\n' +
    '	</div>\n' +
    '</md-content>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('home/home-doughnut-chart.tpl.html',
    '\n' +
    '<svg width="100%" height="100%" viewBox="0 0 36 36" class="donut">\n' +
    '	<circle ng-if="total" class="doughnut-total" cx="18" cy="18" r="15.91549430918954" stroke="{{total.colour}}" fill="transparent" stroke-width="4" stroke-alignment="inner"></circle>\n' +
    '	<circle ng-if="!total" class="doughnut-total" cx="18" cy="18" r="15.91549430918954" stroke="{{primary.colour}}" fill="transparent" stroke-width="4" stroke-alignment="inner" stroke-dasharray="{{primary.value}} {{100-primary.value}}" stroke-dashoffset="25"></circle>\n' +
    '	<circle ng-if="total && primary" class="doughnut-segment" cx="18" cy="18" r="15.91549430918954" fill="transparent" stroke="{{primary.colour}}" stroke-width="4" stroke-alignment="inner" stroke-dasharray="{{primary.value/total.value*100}} {{(1-primary.value/total.value)*100}}" stroke-dashoffset="25"></circle>\n' +
    '	<circle ng-if="total" ng-repeat="segment in series" class="doughnut-segment" cx="18" cy="18" r="15.91549430918954" fill="transparent" stroke="{{segment.colour}}" stroke-width="4" stroke-alignment="inner" stroke-dasharray="{{segment.value/total.value*100}} {{(1-segment.value/total.value)*100}}"  ng-init="segment.runningTotal = series[$index-1].runningTotal + segment.value" stroke-dashoffset="{{ 125 -((series[$index-1].runningTotal+0)/total.value*100)}}"></circle>\n' +
    '	<circle ng-if="!total" ng-repeat="segment in series" class="doughnut-segment" cx="18" cy="18" r="15.91549430918954" fill="transparent" stroke="{{segment.colour}}" stroke-width="4" stroke-alignment="inner" stroke-dasharray="{{segment.value}} {{100-segment.value}}"  ng-init="segment.runningTotal = series[$index-1].runningTotal + segment.value" stroke-dashoffset="{{ 125 -((series[$index-1].runningTotal+0))}}"></circle>\n' +
    '	<g transform="translate(18,18)" class="chart-text">\n' +
    '		<text x="0" y="0" class="chart-primary" ng-if="primary">\n' +
    '		  {{primary.value}}<tspan ng-if="!total">%</tspan>\n' +
    '		</text>\n' +
    '		<text x="0" y="0" class="chart-primary" ng-if="!primary && total">\n' +
    '		  {{total.value | number:0}}\n' +
    '		</text>\n' +
    '		<text x="0" y="4" class="chart-total" ng-if="primary && total">\n' +
    '		  /{{total.value}}\n' +
    '		</text>\n' +
    '	  </g>\n' +
    '</svg>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('footer/footer.tpl.html',
    '\n' +
    '<p align="center" style="margin-top:-80px;">\n' +
    '    <small style="font-size: 9px;" wraps-tooltip="footer-wraps-version">{{api}}WRAPS - v{{appVersion}}</small><br />\n' +
    '    <a style="font-size: 8px; text-decoration: none; padding-bottom: 20px;" ng-href="/versions" wraps-tooltip="footer-verison-log">version log</a>\n' +
    '</p>\n' +
    '\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('healthcheck/healthcheck.tpl.html',
    '<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">\n' +
    '\n' +
    '    <div class="md-whiteframe-z1 default-box" style="" ng-if="healthcheck.hasAccess == true" >\n' +
    '\n' +
    '\n' +
    '        <md-data-table-toolbar>\n' +
    '            <h2 flex wraps-tooltip="healthcheck-title">Health Check</h2>\n' +
    '            <md-button class="md-raised md-primary" ng-click="healthcheck.getData()">Re-run Health Check</md-button>\n' +
    '            <!--<pre>d = {{healthcheck.params|json}}</pre>-->\n' +
    '        </md-data-table-toolbar>\n' +
    '\n' +
    '        <md-data-table-container class="table-outer">\n' +
    '\n' +
    '            <md-progress-linear md-mode="query" ng-show="healthcheck.isLoading"></md-progress-linear>\n' +
    '            <table md-data-table  > <!--md-row-select="selected"-->\n' +
    '                <thead ng-show="!healthcheck.isLoading">\n' +
    '                    <td wraps-tooltip="healthcheck-search-filters-id">\n' +
    '                        <md-input-container style="width:100%">\n' +
    '                            <label>Filter ID</label>\n' +
    '                            <input ng-model="healthcheck.listFilter.bookIdLong" aria-label="Filter book id">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                    <td wraps-tooltip="healthcheck-search-filters-code">\n' +
    '                        <md-input-container style="width:100%">\n' +
    '                            <label>Filter Code</label>\n' +
    '                            <input ng-model="healthcheck.listFilter.bookCode" aria-label="Filter book code">\n' +
    '                        </md-input-container></td>\n' +
    '                    <td wraps-tooltip="healthcheck-search-filters-error">\n' +
    '                        <md-input-container style="width:100%">\n' +
    '                            <label>Filter Error</label>\n' +
    '                            <input ng-model="healthcheck.listFilter.error" aria-label="Filter book error">\n' +
    '                        </md-input-container>\n' +
    '                    </td>\n' +
    '                </thead>\n' +
    '                <thead>\n' +
    '                <tr ng-show="healthcheck.data.docErrors">\n' +
    '                    <th ng-click="sortBy(\'bookIdLong\')" width="60px"><span wraps-tooltip="healthcheck-heading-id">ID <ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: healthcheck.orderBy === \'bookIdLong\', reverse: healthcheck.reverse}"></ng-md-icon></span></th>\n' +
    '                    <th ng-click="sortBy(\'bookCode\')"><span wraps-tooltip="healthcheck-heading-code">Code <ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: healthcheck.orderBy === \'bookCode\', reverse: healthcheck.reverse}"></ng-md-icon></span></th>\n' +
    '                    <th ng-click="sortBy(\'error\')"><span wraps-tooltip="healthcheck-heading-error">Error <ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: healthcheck.orderBy === \'error\', reverse: healthcheck.reverse}"></ng-md-icon></span></th>\n' +
    '                </tr>\n' +
    '                </thead>\n' +
    '                <tbody ng-repeat="book in healthcheck.data.docErrors | filter: healthcheck.listFilter | orderBy: healthcheck.orderBy : healthcheck.reverse | limitTo: healthcheck.bookQuery.limit: (healthcheck.bookQuery.page- 1) * healthcheck.bookQuery.limit">\n' +
    '                <tr class="data-rows" ng-if="healthcheck.params.doc != book.bookIdLong" ng-click="healthcheck.openDoc(book.bookIdLong)" wraps-tooltip="healthcheck-doc-list">\n' +
    '                    <td>{{book.bookIdLong}}</td>\n' +
    '                    <td>{{book.bookCode}}</td>\n' +
    '                    <td>{{book.error}}</td>\n' +
    '                </tr>\n' +
    '                <tr class="details-row" id="anchorBook{{book.bookId}}" ng-show="healthcheck.params.doc == book.bookId">\n' +
    '                    <td colspan="99" class="bookTd">\n' +
    '                        <div class="main-outer">\n' +
    '                            <div class="main-inner md-whiteframe-z2">\n' +
    '                                <div class="greyBarWrapper layout-row" layout="row" ng-click="healthcheck.closeDoc()" ng-class="{ \'complianceSelect\': book.complianceSelect}" role="button" tabindex="0">\n' +
    '                                    <h3 flex="" class="ng-binding flex">{{book.bookCode}}<span ng-if="!book.bookCode">{{book.bookId | seven}}</span></h3>\n' +
    '                                </div>\n' +
    '                                <div class="healthcheck-data layout-padding" id="book-info-{{book.bookId | seven}}">\n' +
    '                                    <div style="float:right;" ng-if="healthcheck.hasPermissions(\'viewLibrary\')"><md-button class="md-raised" ng-click="healthcheck.openDocInLibrary(book.bookIdLong)">Open document in Library</md-button></div>\n' +
    '                                    <h3><span style="font-weight: 700 !important;" class="ng-binding">{{book.bookName}}</span><br>\n' +
    '                                        Code: <span style="font-weight: 500 !important;" class="ng-binding">{{book.bookCode}}</span><br>\n' +
    '                                        ID: <span style="font-weight: 500 !important;" class="ng-binding">{{book.bookId|seven}}</span></h3>\n' +
    '                                    <md-progress-linear md-mode="query" ng-show="healthcheck.docLoading"></md-progress-linear>\n' +
    '                                    <div ng-if="book.workflow_error && !healthcheck.docLoading" class="healthcheck-workflows">\n' +
    '                                        <h4>{{book.workflow_error}}</h4>\n' +
    '                                        Document Status: <span style="font-weight: 500 !important;" ng-if="book.workflow_workflow_status == 0">IN</span><span style="font-weight: 500 !important;">COMPLETE</span><br/>\n' +
    '                                        Workflow Position: <span style="font-weight: 500 !important;">{{book.workflow_approvalStatus + 1}} of {{book.workflow_approvalListEnd + 1}} - {{book.workflow_approvalList[book.workflow_approvalStatus].Role}}</span><br/>\n' +
    '                                        <h4>Workflow:</h4>\n' +
    '                                        <div class="healthcheck-table">\n' +
    '                                            <md-data-table-container>\n' +
    '                                                <table class="hc-table">\n' +
    '                                                    <tr class="hc-tableHeading">\n' +
    '                                                        <th>Position</th>\n' +
    '                                                        <th>Content</th>\n' +
    '                                                        <th>Role</th> \n' +
    '                                                        <th>Team</th>\n' +
    '                                                        <th>User</th>\n' +
    '                                                    </tr>\n' +
    '                                                    <tr ng-repeat="stage in book.workflow_approvalList">\n' +
    '                                                        <td md-colors="$index == book.workflow_approvalStatus?{background:\'primary-100\'}:{}">{{$index + 1}}</td>\n' +
    '                                                        <td md-colors="$index == book.workflow_approvalStatus?{background:\'primary-100\'}:{}">{{stage.content}}</td>\n' +
    '                                                        <td md-colors="$index == book.workflow_approvalStatus?{background:\'primary-100\'}:{}">{{stage.Role}}</td>\n' +
    '                                                        <td md-colors="$index == book.workflow_approvalStatus?{background:\'primary-100\'}:{}">{{stage.Team}}</td>\n' +
    '                                                        <td md-colors="$index == book.workflow_approvalStatus?{background:\'primary-100\'}:{}">{{stage.User}}</td>\n' +
    '                                                    </tr>\n' +
    '                                                </table>\n' +
    '                                            </md-data-table-container>\n' +
    '                                        </div>\n' +
    '                                        <h4>Content Statuses:</h4>\n' +
    '                                        <div class="healthcheck-table">\n' +
    '                                            <md-data-table-container>\n' +
    '                                                <table class="hc-table">\n' +
    '                                                    <tr class="hc-tableHeading">\n' +
    '                                                        <th>Content Type Group</th>\n' +
    '                                                        <th>Workflow Position</th>\n' +
    '                                                        <th>Count</th>\n' +
    '                                                        <th>Status</th>\n' +
    '                                                    </tr>\n' +
    '                                                    <tr ng-repeat="content in book.workflow_contentStatuses">\n' +
    '                                                        <td>{{content.ContentTypeGroup}}</td>\n' +
    '                                                        <td>{{content.approvalStatus + 1}} of {{book.workflow_approvalListEnd + 1}} - {{book.workflow_approvalList[content.approvalStatus].Role}}</td>\n' +
    '                                                        <td>{{content.count}}</td>\n' +
    '                                                        <td><span style="font-weight: 500 !important;" ng-if="book.status == 0">IN</span><span style="font-weight: 500 !important;">COMPLETE</span></td>\n' +
    '                                                    </tr>\n' +
    '                                                </table>\n' +
    '                                            </md-data-table-container>\n' +
    '                                        </div>\n' +
    '                                    </div>\n' +
    '                                    <div ng-if="book.validation_errors && !healthcheck.docLoading">\n' +
    '                                        <h4>Validation errors on the following fields:</h4>\n' +
    '                                        <p ng-repeat="field in book.validation_errors">{{field|titlecase}}</p>\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '                    </td>\n' +
    '                </tr>\n' +
    '                </tbody>\n' +
    '                <tbody ng-if="!healthcheck.data.docErrors && !healthcheck.isLoading">\n' +
    '                <tr class="data-rows">\n' +
    '                    <td></td>\n' +
    '                    <td>All is well.</td>\n' +
    '                    <td></td>\n' +
    '                    <td></td>\n' +
    '                </tr>\n' +
    '                </tbody>\n' +
    '\n' +
    '\n' +
    '            </table>\n' +
    '        </md-data-table-container>\n' +
    '        <md-data-table-pagination md-limit="healthcheck.bookQuery.limit" md-page="healthcheck.bookQuery.page" md-total="{{(healthcheck.data.docErrors | filter: healthcheck.listFilter).length}}"></md-data-table-pagination>\n' +
    '\n' +
    '    </div>\n' +
    '</md-content>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('access-denied/access-denied.tpl.html',
    '<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">\n' +
    '\n' +
    '    <div class="md-whiteframe-z1 default-box" style="" >\n' +
    '\n' +
    '\n' +
    '        <md-progress-circular style="position: absolute; top: 10px; left: 50%; margin-left: -50px;"  md-diameter="80" md-mode="indeterminate" ng-show="accessDenied.isLoading"></md-progress-circular>\n' +
    '\n' +
    '        <md-data-table-toolbar>\n' +
    '        	<h2 flex>Access Denied</h2>\n' +
    '        </md-data-table-toolbar>\n' +
    '		<md-content>\n' +
    '			<p>You do not have permission to access the {{accessDenied.src}} page.</p>\n' +
    '			<p>If you believe this is an error please contact {{accessDenied.clientConfig.email}} or report a technical issue via the <md-button class="md-primary" href="#" target="_blank">help portal</md-button>.</p>\n' +
    '			<md-button ui-sref="home" class="md-primary md-raised">Go to dashboard</md-button>\n' +
    '		</md-content>\n' +
    '	</div>\n' +
    '</md-content>\n' +
    '');
}]);

angular.module('wrapsv1').run(['$templateCache', function($templateCache) {
  $templateCache.put('drive/drive.tpl.html',
    '<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">\n' +
    '\n' +
    '    <div class="md-whiteframe-z1 default-box" style="" ng-if="drive.hasAccess == true" >\n' +
    '        \n' +
    '\n' +
    '            <md-data-table-toolbar>\n' +
    '            	<h2 flex wraps-tooltip="drive-title">Wraps Drive</h2>\n' +
    '				<!--<pre>d = {{drive.params|json}}</pre>-->\n' +
    '            </md-data-table-toolbar>\n' +
    '			<div class="breadcrumbs">\n' +
    '				<span ng-click="drive.driveHome()" wraps-tooltip="drive-breadcrumbs-home">Wraps Drive</span> \n' +
    '				<ng-md-icon icon="keyboard_arrow_right" ng-if="drive.params.insurer != null"></ng-md-icon><span ng-click="drive.openInsurer(drive.params.insurer)" wraps-tooltip="drive-breadcrumbs-insurer">{{drive.params.insurer}}</span> \n' +
    '				<ng-md-icon icon="keyboard_arrow_right" ng-if="drive.params.product != null"></ng-md-icon><span ng-click="drive.openProduct(drive.params.product)" wraps-tooltip="drive-breadcrumbs-product">{{drive.params.product}}</span> \n' +
    '				<ng-md-icon icon="keyboard_arrow_right" ng-if="drive.params.category != null"></ng-md-icon><span ng-click="drive.openCategory(drive.params.category)" wraps-tooltip="drive-breadcrumbs-category">{{drive.params.category}}</span> \n' +
    '				<ng-md-icon icon="keyboard_arrow_right" ng-if="drive.params.dtype != null"></ng-md-icon><span ng-click="drive.openDocType(drive.params.dtype)" wraps-tooltip="drive-breadcrumbs-doctype">{{drive.params.dtype}}</span>\n' +
    '				<ng-md-icon icon="keyboard_arrow_right" ng-if="drive.params.doc != null"></ng-md-icon><span wraps-tooltip="drive-breadcrumbs-doctype">{{drive.params.doc}}</span>\n' +
    '			</div>\n' +
    '\n' +
    '            \n' +
    '\n' +
    '            <md-data-table-container ng-if="drive.params.dtype == null">\n' +
    '               	<md-progress-linear md-mode="query" ng-show="drive.isLoading"></md-progress-linear>\n' +
    '				<div ng-repeat="(insurerKey, insurer) in drive.data.insurers">\n' +
    '					<div  ng-click="drive.toggleInsurer(insurer.insurerCode)" ng-class="{active: drive.params.insurer == insurer.insurerCode}" class="folder" wraps-tooltip="drive-folder-insurer">\n' +
    '						<ng-md-icon ng-if="drive.params.insurer != insurerKey" icon="folder" size="20"></ng-md-icon><ng-md-icon ng-if="drive.params.insurer == insurerKey" icon="folder_open" size="20"></ng-md-icon>{{insurer.insurerName}} ({{insurer.docCount}})\n' +
    '					</div>\n' +
    '					<div ng-repeat="(productKey, product) in drive.data.products"  ng-if="drive.params.insurer == insurerKey">\n' +
    '						<div class="folder child" ng-click="drive.toggleProduct(productKey);" ng-class="{active: drive.params.product == productKey}" wraps-tooltip="drive-folder-product">\n' +
    '							<ng-md-icon ng-if="drive.params.product != productKey" icon="folder" size="20"></ng-md-icon><ng-md-icon ng-if="drive.params.product == productKey" icon="folder_open" size="20"></ng-md-icon>{{product.productName}} ({{product.docCount}})\n' +
    '						</div>\n' +
    '						<div ng-repeat="(categoryKey, category) in drive.data.categories" ng-if="drive.params.product == productKey">\n' +
    '							<div class="folder grandchild" ng-click="drive.toggleCategory(categoryKey);" ng-class="{active: drive.params.category == categoryKey}" wraps-tooltip="drive-folder-category">\n' +
    '								<ng-md-icon ng-if="drive.params.category != categoryKey" icon="folder" size="20"></ng-md-icon><ng-md-icon ng-if="drive.params.category == categoryKey" icon="folder_open" size="20"></ng-md-icon>{{category.docTypeCatName}} ({{category.docCount}})\n' +
    '							</div>\n' +
    '							<div ng-repeat="(docTypeKey, type) in drive.data.docTypes" ng-if="drive.params.category == categoryKey">\n' +
    '								<div class="folder greatgrandchild" ng-click="drive.openDocType(docTypeKey)" wraps-tooltip="drive-folder-doctype">\n' +
    '									<ng-md-icon icon="folder" size="20"></ng-md-icon>{{type.docTypeName}} ({{type.docCount}})\n' +
    '								</div>\n' +
    '							</div>\n' +
    '							<div ng-if="category.docTypes.length == 0 && !drive.isLoading" class="folder greatgrandchild">\n' +
    '								There are no document types associated with this category.\n' +
    '							</div>\n' +
    '						</div>\n' +
    '						<div ng-if="product.categories.length == 0 && !drive.isLoading" class="folder grandchild">\n' +
    '							There are no categories associated with this product.\n' +
    '						</div>\n' +
    '					</div>\n' +
    '					<div ng-if="insurer.products.length == 0 && !drive.isLoading" class="folder child">\n' +
    '						There are no products associated with this insurer.\n' +
    '					</div>\n' +
    '				</div>\n' +
    '            </md-data-table-container>\n' +
    '		\n' +
    '             <md-data-table-container ng-if="drive.params.dtype != null" class="table-outer">\n' +
    '				\n' +
    '				<md-progress-linear md-mode="query" ng-show="drive.isLoading"></md-progress-linear>\n' +
    '                <table md-data-table  > <!--md-row-select="selected"-->\n' +
    '					<thead>\n' +
    '						<tr ng-show="drive.data.docs.length > 0">\n' +
    '							<th ng-click="sortBy(\'bookId\')" width="60px"><span wraps-tooltip="drive-heading-id">ID <ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: drive.orderBy === \'bookId\', reverse: drive.reverse}"></ng-md-icon></span></th>\n' +
    '							<th ng-click="sortBy(\'bookName\')"><span wraps-tooltip="drive-heading-name">Name <ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: drive.orderBy === \'bookName\', reverse: drive.reverse}"></ng-md-icon></span></th>\n' +
    '							<th ng-click="sortBy(\'bookCode\')"><span wraps-tooltip="drive-heading-code">Code <ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: drive.orderBy === \'bookCode\', reverse: drive.reverse}"></ng-md-icon></span></th>\n' +
    '							<th ng-click="sortBy(\'expiryDate\')"><span wraps-tooltip="drive-heading-expiry">Expiry Date <ng-md-icon icon="arrow_upwards" size="15" class="sorticon" ng-class="{active: drive.orderBy === expiryDate || drive.orderBy[0] === expiryDate, reverse: drive.reverse}"></ng-md-icon></span></th>\n' +
    '								\n' +
    '						</tr>\n' +
    '					</thead>\n' +
    '                    <tbody ng-repeat="book in drive.data.docs | orderBy: drive.orderBy : drive.reverse">\n' +
    '						<tr class="data-rows" ng-if="drive.params.doc != book.bookId" ng-click="drive.openDoc(book.bookId)" wraps-tooltip="drive-doc-list">\n' +
    '							<td>{{book.bookId | seven}}</td>\n' +
    '							<td>{{book.bookName}}</td>\n' +
    '							<td>{{book.bookCode}}</td>\n' +
    '							<td>{{book.expiryDate | date:\'dd/MM/yyyy\':\'+0000\'}}</td>\n' +
    '						</tr>\n' +
    '						<tr class="details-row" id="anchorBook{{book.bookId}}" ng-if="drive.params.doc == book.bookId">\n' +
    '							<td colspan="99" class="bookTd">\n' +
    '                       			<div class="main-outer">\n' +
    '									<div class="main-inner md-whiteframe-z2">\n' +
    '										<div class="greyBarWrapper layout-row" layout="row" ng-click="drive.openDocType(drive.params.dtype)" ng-class="{ \'complianceSelect\': book.complianceSelect}" role="button" tabindex="0">\n' +
    '											<h3 flex="" class="ng-binding flex">{{book.bookName}}</h3>\n' +
    '											<!--refresh book button-->\n' +
    '											<button class="md-icon-button bookIconPosition md-button ng-scope ng-isolate-scope md-ink-ripple" type="button" ng-if="drive.hasPermissions(\'refreshDocumentTemp\')" ng-click="drive.getBook(book.bookId); $event.stopPropagation();" aria-label="Refresh book" wraps-tooltip="drive-doc-refresh-icon">\n' +
    '												<ng-md-icon icon="refresh" style="fill: grey; position: relative;" size="24" class="ng-scope"></ng-md-icon>\n' +
    '											</button>\n' +
    '										</div>\n' +
    '										<div class="drive-data" id="book-info-{{book.bookId}}">\n' +
    '											<div class="downloads-container">\n' +
    '												<div class="download-row" layout-padding="20">\n' +
    '													<div style="float:right;" ng-if="drive.hasPermissions(\'viewLibrary\')"><md-button class="md-raised" ng-click="drive.openDocInLibrary(book.bookId)">Open document in Library</md-button></div>\n' +
    '													<h3><span style="font-weight: 700 !important;" class="ng-binding">{{book.bookName}}</span><br>\n' +
    '														Code: <span style="font-weight: 500 !important;" class="ng-binding">{{book.bookCode}}</span><br>\n' +
    '														Expiry date: <span style="font-weight: 500 !important;" class="ng-binding">{{book.expiryDate | date: "dd-MM-yyyy"}}</span><br>\n' +
    '														ID: <span style="font-weight: 500 !important;" class="ng-binding">{{book.bookId|seven}}</span></h3>\n' +
    '													<div flex="100" class="divider"><div></div></div>\n' +
    '												</div>\n' +
    '												<div ng-repeat="download in drive.data.docDetail.downloads" ng-if="drive.data.docDetail.noDownloads != \'\'" layout="row" layout-wrap layout-padding="20" class="download-row">\n' +
    '													<a href="{{download.fileLocation}}" target="_blank">\n' +
    '														<div  class="thumbnail-block" wraps-tooltip="drive-doc-download">\n' +
    '															<img class="thumbnail-preview" ng-src="{{download.thumbLocation}}" err-SRC="/images/wraps-logo.png" alt="{{download.fileName}}"/>\n' +
    '															<div class="overlay"><h3 md-truncate><ng-md-icon icon="file_download" size="24"></ng-md-icon> {{download.fileSize}}</h3></div>\n' +
    '														</div>\n' +
    '													</a>\n' +
    '													<div flex>\n' +
    '														<h3 md-truncate>{{download.fileName}}</h3>\n' +
    '														<h3 md-truncate>{{download.fileSize}}</h3>\n' +
    '													</div>\n' +
    '													<div flex="100" class="divider"><div></div></div>\n' +
    '												</div>\n' +
    '												<div ng-if="drive.data.docDetail.noDownloads" layout-padding="20">\n' +
    '													<div ng-bind-html="\'Description: \' + drive.data.docDetail.noDownloadsMessage"></div>\n' +
    '													<div ng-if="drive.hasPermissions(\'viewLibrary\')"><md-button ng-click="drive.openDocInLibrary(book.bookId)">Open document in Library</md-button></div>\n' +
    '												</div>\n' +
    '											</div>\n' +
    '										</div>\n' +
    '									</div>\n' +
    '								</div>\n' +
    '							</td>\n' +
    '						</tr>\n' +
    '                    </tbody>\n' +
    '					<tbody ng-if="drive.data.docs.length == 0 && !drive.isLoading">\n' +
    '						<tr class="data-rows">\n' +
    '							<td></td>\n' +
    '							<td>There are no documents in this folder.</td>\n' +
    '							<td></td>\n' +
    '							<td></td>\n' +
    '						</tr>\n' +
    '					</tbody>\n' +
    '\n' +
    '\n' +
    '                </table>\n' +
    '            </md-data-table-container>\n' +
    '\n' +
    '\n' +
    '\n' +
    '	</div>\n' +
    '	<div class="md-whiteframe-z1 default-box" style="" ng-if="drive.hasAccess === false">\n' +
    '		<md-data-table-toolbar><h2  flex>Access denied<br /><br /><br /></h2>\n' +
    '\n' +
    '		</md-data-table-toolbar>\n' +
    '	</div>\n' +
    '	 <div class="md-whiteframe-z1 default-box" style="" ng-if="drive.hasAccess === null" >\n' +
    '		 <md-data-table-toolbar><h2  flex><br/></h2></md-data-table-toolbar>\n' +
    '            <md-progress-circular style="position: absolute; top: 10px; left: 50%; margin-left: -50px;"  md-diameter="80" md-mode="indeterminate"></md-progress-circular>\n' +
    '	</div>\n' +
    '	\n' +
    '\n' +
    '</md-content>');
}]);

(function () {
  'use strict';

  /* @ngdoc object
   * @name library
   * @description
   *
   */
  angular
    .module('library', [
      'ui.router',
      'library.bottomsheet',
      'library.historydialog',
      'library.chaptersdialog',
      'library.contentdialog',
      'library.docdiffdialog',
      'library.emaildetailsdialog',
      'library.pushdialog',
      'library.workflowdialog',
      'library.approvedialog',
      'library.addbrief',
      'library.addartwork',
      'library.changedialog',
      'library.swapteamuserdialog',
      'library.metachangedialog'
    ]);
}());

angular
    .module('library')
    .component('tabPublish', {
        templateUrl: 'library/library-components/right-menu/tab-publish/tab-publish.tpl.html',
        controller: tabPublishCtrl,
        controllerAs:'tabPublish',
        bindings:{
            book:'=',
            disabled:'=',
            visible:'<',
            switch:'='
        }

    });

tabPublishCtrl.$inject = ['$filter','$scope','Wraps'];
function tabPublishCtrl($filter, $scope, Wraps){
    var vm = this;
    //console.log("publish");



    $scope.$watch('tabPublish.book.divisionId',function(newValue, oldValue) {



        if(vm.book && oldValue && newValue != oldValue ) {
            setupPubs();
        }




    });

    $scope.$watch('tabPublish.visible',function() {
        if(vm.book) {
            setupPubs();
        }
        
    });


    var setupPubs = function(){
        Wraps.initVars.then(function () {

            vm.publishTypes = Wraps.publishTypes;
            vm.digitalList = $filter('filter')(Wraps.publishTypes, {
                category: 'Digital & Social',
                publishTypeStatus: 1,
                divisionId: vm.book.divisionId
            });
            vm.inSituList = $filter('filter')(Wraps.publishTypes, {
                category: 'Evidence',
                publishTypeStatus: 1,
                divisionId: vm.book.divisionId
            });
            vm.fulfilmentList = $filter('filter')(Wraps.publishTypes, {
                category: 'Fulfilment',
                publishTypeStatus: 1,
                divisionId: vm.book.divisionId
            });
            vm.productionList = $filter('filter')(Wraps.publishTypes, {
                category: 'Production Manager',
                publishTypeStatus: 1,
                divisionId: vm.book.divisionId
            });
            vm.supportList = $filter('filter')(Wraps.publishTypes, {
                category: 'Support Tools',
                publishTypeStatus: 1,
                divisionId: vm.book.divisionId
            });

        });
    }



    $scope.$watch('tabPublish.switch', function (newValue, oldValue) {

        //console.log('tabPublish.switch', angular.copy(vm.book));
        //console.log('tabPublish.switch', newValue);

        if(newValue && vm.book.workflowFinal) {
            
            if (newValue.production && newValue.production.flag == true) {
                ////console.log(_.find(vm.selected.book.workflowFinal, {role: 11}));
                _.find(vm.book.workflowFinal, {role: 11}).value = false;
            } else {
                _.find(vm.book.workflowFinal, {role: 11}).value = true;
            }
            
            if (newValue.fulfilment && newValue.fulfilment.flag == true) {
                //console.log(_.find(vm.selected.book.workflowFinal, {role: 12}));
                _.find(vm.book.workflowFinal, {role: 12}).value = false;
            } else {
                _.find(vm.book.workflowFinal, {role: 12}).value = true;
            }
            if (newValue.digital && newValue.digital.flag == true) {
                //console.log(_.find(vm.selected.book.workflowFinal, {role: 14}));
                _.find(vm.book.workflowFinal, {role: 14}).value = false;
            } else {
                _.find(vm.book.workflowFinal, {role: 14}).value = true;
            }
            if (newValue.support && newValue.support.flag == true) {
                //console.log(_.find(vm.selected.book.workflowFinal, {role: 15}));
                _.find(vm.book.workflowFinal, {role: 15}).value = false;
            } else {
                _.find(vm.book.workflowFinal, {role: 15}).value = true;
            }
            if (newValue.inSitu && newValue.inSitu.flag == true) {
                //console.log(_.find(vm.selected.book.workflowFinal, {role: 13}));
                _.find(vm.book.workflowFinal, {role: 13}).value = false;
            } else {
                _.find(vm.book.workflowFinal, {role: 13}).value = true;
            }
        }
    }, true);
    //console.log("/publish");

}
angular
    .module('library')
    .component('tabWorkflow', {
        templateUrl: 'library/library-components/right-menu/tab-workflow/tab-workflow.tpl.html',
        controller: tabWorkflowCtrl,
        controllerAs:'tabWorkflow',
        bindings:{
            book:'<',
            visible:'<',
            disabled:'<'
        }

    });
tabWorkflowCtrl.$inject = ['$scope','Wraps','dragularService'];
function tabWorkflowCtrl($scope,Wraps,dragularService){
    var vm = this;

    vm.validDoc = true;
    vm.validBrief = true;
    vm.validArtwork = true;
    vm.validFinal = true;

    //console.log(dragularService);
    // dragularService('#docWorkflow, #briefWorkflow, #artworkWorkflow, #finalWorkflow', {
    //     moves: function (el, container, handle) {
    //         return handle.className === 'statbar-mini';
    //     }
    // });
    vm.options = {
        moves: function (el, container, handle) {
            return !vm.disabled && !(angular.element(el).hasClass('fixed')) && (angular.element(handle).hasClass('drag-handle'));
        },
        accepts: function( el, target, source, sibling, sourceModel){
            //console.log(target, source, sourceModel, sibling, sibling === null, !angular.element(sibling).hasClass('fixed'));
            return target === source && (sibling === null || !angular.element(sibling).hasClass('fixed'));
        },
        revertOnSpill: true,
        lockY:true
    }
    vm.docOptions = angular.copy(vm.options);
    vm.briefOptions = angular.copy(vm.options);
    vm.artworkOptions = angular.copy(vm.options);
    vm.finalOptions = angular.copy(vm.options);


    $scope.$watch('tabWorkflow.visible',function() {
        if (vm.book && vm.book.workflowArtwork && _.findIndex(vm.book.workflowArtwork, ['value',true]) >=0 ) {
            vm.enableWorkflowArtwork = true;
        } else {
            vm.enableWorkflowArtwork = false;
        }
    });

    // if any of the values are false the workflow is invalid
    $scope.$watchGroup(['tabWorkflow.validDoc', 'tabWorkflow.validBrief', 'tabWorkflow.validArtwork', 'tabWorkflow.validFinal'], function(newValues, oldValues, scope) {
        if(vm.book){
            vm.book.isWorkflowInvalid = _.findIndex(newValues, function(item) { return item === false}) >= 0;
        }
    },true);


    vm.checkArtwork = function () {
        _.map(vm.book.workflowArtwork, function(i) {
            i.value = vm.enableWorkflowArtwork;
            return i;
        });
    }

    vm.addWorkflow = function (roleId, content) {
        var arr = [];
        var obj = { content: content, role: roleId, team: '', user: '', value: true }
        switch(content){
            case 'doc':
                arr = vm.book.workflowDoc;
                break;
            case 'brief':
                arr = vm.book.workflowBrief;
                break;
            case 'artwork':
                arr = vm.book.workflowArtwork;
                break;
            case 'final':
                arr = vm.book.workflowFinal;
                break;

        }
        arr.push(obj);
    }

    vm.removeWorkflow = function (content, $index) {
        var arr = [];
        switch(content){
            case 'doc':
                arr = vm.book.workflowDoc;
                break;
            case 'brief':
                arr = vm.book.workflowBrief;
                break;
            case 'artwork':
                arr = vm.book.workflowArtwork;
                break;
            case 'final':
                arr = vm.book.workflowFinal;
                break;
        }
        arr.splice($index,1);

    }

    vm.checkValidWorkflow = function(){
        vm.isValid = vm.validDoc && vm.validBrief && vm.validArtwork && vm.validFinal;
    }

    vm.labels = {
        artwork:'Artwork Draft Workflow',
        brief: 'Document or Brief Workflow',
        final: 'Publishing Workflow',
        doc: 'Document Workflow'

    };
}
angular
    .module('library')
    .component('tabProducts', {
        templateUrl: 'library/library-components/right-menu/tab-products/tab-products.tpl.html',
        controller: tabProductsCtrl,
        controllerAs: 'tabProducts',
        bindings: {
            book: '<',
            visible:'<',
            disabled: '<'
        }

    });
tabProductsCtrl.$inject = ['$localStorage', '$scope', 'ProductIds'];
function tabProductsCtrl($localStorage, $scope, ProductIds) {
    var vm = this;

    $scope.sortBy = function (propertyName) {
        vm.reverse = (vm.orderBy === propertyName) ? !vm.reverse : false;
        vm.orderBy = propertyName;
    };



    $scope.$watch('tabProducts.visible',function() {

        ProductIds.find({"filter": {"where": {"divisionId": vm.book.divisionId }, "include": ["Insurers"]}}, function (results) {
            vm.productIds = results;

            var filterKeys, filterObj;

            $scope.$watchCollection('tabProducts.book.productIdsSelectedArray', function (selectedProductIds) {
                vm.selectProductIdsArray = [];
                _.forEach(selectedProductIds, function (val, selectedProductId) {

                    if(val === true) {
                        vm.selectProductIdsArray.push(selectedProductId);
                    }
                });

                vm.selectProductIdsArray = vm.selectProductIdsArray.map(function (x) {
                    return parseInt(x, 10);
                });

                filterObj = {
                    "productId": vm.selectProductIdsArray
                };

                vm.selectedRows = vm.filterProds(vm.productIds, filterObj);
            });

            vm.filterProds = function(productIds, filter) {
                filterKeys = Object.keys(filter);
                // return productIds.filter((item) => {
                return productIds.filter(function(item) {
                    //  return filterKeys.every(key => !!~filter[key].indexOf(item[key]));
                    return filterKeys.every(function(key) {return !!~filter[key].indexOf(item[key])});
                });
            };

        });
    });


    $scope.$watchCollection('tabProducts.selectedRows', function (selectedRows) {

        //console.log("productIdsSelectedArray",vm.book.productIdsSelectedArray);
        //console.log("selectedRows",selectedRows);

        if(selectedRows) {
            _.forEach(vm.book.productIdsSelectedArray, function (item, key) {
                vm.book.productIdsSelectedArray[key] = false;

            });

            _.forEach(selectedRows, function (row) {
                vm.book.productIdsSelectedArray[row.productId] = true;

            });
        }
    });

    if (!vm.productsQuery) {
        vm.productsQuery = {
            order: 'id',
            limit: 15,
            page: 1
        };
    }

}
angular
    .module('library')
    .component('tabNotes', {
        templateUrl: 'library/library-components/right-menu/tab-notes/tab-notes.tpl.html',
        controller: tabNotesCtrl,
        controllerAs:'tabNotes',
        bindings:{
            book:'<',
            disabled:'<'
        }

    });
tabNotesCtrl.$inject = ['$scope'];
function tabNotesCtrl($scope){
    //var vm = this;

}
angular
    .module('library')
    .component('tabMain', {
        templateUrl: 'library/library-components/right-menu/tab-main/tab-main.tpl.html',
        controller: tabMainCtrl,
        controllerAs: 'tabMain',
        bindings: {
            book: '=',
            allbooks: '=',
            disabled: '=',
            visible: '=',
            newlanguage: '=',
            switch:'<'
        }

    });
tabMainCtrl.$inject = ['$q','$filter','$localStorage','$mdDialog','$scope','apiUrl','Books','Upload','Wraps'];
function tabMainCtrl($q, $filter, $localStorage, $mdDialog, $scope, apiUrl, Books, Upload, Wraps) {
    var vm = this;
    vm.bookCodeList = [];
    var tempCode = '';
    $scope.$storage = $localStorage;
    //console.log("main");
    Wraps.initVars.then(function () {
        vm.audiences = Wraps.audiences;
        vm.BGHPColour = Wraps.BGHPColour;
        vm.clients = Wraps.clients;
        vm.distributors = Wraps.distributors;
        vm.divisions = Wraps.divisions;
        vm.docTypeCats = Wraps.docTypeCats;
        vm.docTypes = Wraps.docTypes;
        vm.emphasis = Wraps.emphasis;
        vm.insurers = Wraps.insurers;
        vm.languages = Wraps.languages;
        vm.products = Wraps.products;
        vm.productTypes = Wraps.productTypes;
        vm.projects = Wraps.projects;
        vm.regions = Wraps.regions;
        vm.socialChannels = Wraps.socialChannels;
        vm.subInsurers = Wraps.subInsurers;
        vm.users = Wraps.users;
    });
    vm.hasPermissions = Wraps.hasPermissions;
    vm.documentInfoForm = $scope.$parent.documentInfoForm;


    $scope.$watch('tabMain.visible', function () {


        if(vm.book){
            vm.book.limitDoc = vm.book.limitDoc == 1 ? true : false;
            vm.originalInsurerId = angular.copy(vm.book.insurerId);
            if (vm.book.DocTypes) {
                vm.temp = {DocTypeCat: vm.book.DocTypes.DocTypeCats.docTypeCatId};
            } else {
                vm.temp = {};
            }
            if(vm.book.insurerId == 38/*non specific insurer id*/) {
                vm.showInsurerDetails = true;
            }

            vm.hasPsi = (vm.book.psiCode !== null && typeof vm.book.psiCode != 'undefined');

            vm.filteredMultiInsurers    = vm.currentlySelectedOrSelectableFilter(vm.insurers, 'insurerDetailsSelectedArray', 'insurer');
            vm.filteredInsurers         = vm.currentlySelectedOrSelectableFilter(vm.insurers, 'insurerId', 'insurer');
            vm.filteredProjects         = vm.currentlySelectedOrSelectableFilter(vm.projects, 'projectId', 'project');
        }

        if (vm.book) {
            tempCode = vm.book.bookCode;
        }
        
        if(vm.book && (typeof vm.book.bookCode == 'undefined' || vm.book.bookCode == '')) {
            if(vm.book.bookCodeOld !== 'disableAutoCode'){
                Books.generateDocumentCode({}).$promise.then(function(result) {
                    vm.book.bookCode = result[0][0].bookCode;
                });
            }
        }
    });

    vm.setWorkflow = function (docTypeTemplate) {
        vm.book.initWorkflowList();
    }

    vm.setPsi = function () {
        if (!vm.hasPsi){
            vm.book.psiCode = null;
        }

    }

    // function to filter drop-downs to contain the current value and selectable values only.
    vm.currentlySelectedOrSelectableFilter = function (array, bookProperty, fieldPrefix){
        var filteredArray = array.filter(function(item){
            if (
                (// current item is in book's division and it isn't deleted.
                item.divisionId === vm.book.divisionId && (parseInt(item[fieldPrefix + 'Status']) === 1)) ||
                // current item is the selected value for bookProperty
                item[fieldPrefix + 'Id'] === vm.book[bookProperty] ||
                // bookProperty is array and current item is one of the elements selected.
                (typeof vm.book[bookProperty] === 'object' &&
                    (vm.book[bookProperty].indexOf(item[fieldPrefix + 'Id']) !== -1 ||
                    vm.book[bookProperty].indexOf(item[fieldPrefix + 'Id'] + '') !== -1) // hack for arrays of strings
                )
            ) {
                return true;
            } else {
                return false;
            }
        });
        // console.log('filter run', filteredArray);
        return filteredArray;
    }


    vm.allowedDocTypeEdit = function () {
        //for ng-disable so true = disabled;
        if (vm.book &&
            ($scope.$storage.user.id == 49 ||
            !vm.book.DocTypes ||
            (!vm.book.bookId && vm.book.DocTypes.docTypeTemplate !== 1))
        ) {
            return false; //enable
        } else {
            return true; //disable
        }

    }

    // 3 FUNCTIONS FOR AUTOCOMPLETE
    vm.queryBooks = function (query) {
        //var results = query ? vm.bookCodeList.filter( createFilterFor(query) ) : [];
        //var results = query ? $filter('filter')(vm.bookCodeList, query) : [];
        return Books.getBookCodesBySearch({"codeSearchText":query}).$promise.then(function (results) {

            _.pull(results.books, tempCode);
              vm.bookCodeList = results.books;
              // Map the response object to the data object.
              return results.books;
        });
    }

    function createFilterFor(query) {
        return function filterFn(item) {
            return (item.toLowerCase().indexOf(query.toLowerCase()) >= 0);
        };
    }

    vm.checkCodes = function (text) {
        //console.log(_.includes(vm.bookCodeList, text),vm.bookCodeList,text);
        vm.book.checkCode = _.includes(vm.bookCodeList, text);
        return vm.book.checkCode;
    }

    vm.checkBlank = function (text) {
        return text === '' || typeof text === 'undefined';
    };
    //console.log("/main");


    $scope.$watch('tabMain.JSONFile', function () {
        if (vm.JSONFile && vm.JSONFile.name.search(/\.json/i) > -1) {
            vm.upload(vm.JSONFile).then(function (statuses) {
                vm.isLoading = false;
            });
        }
    });

    vm.upload = function (file) {
        var timestamp = new Date().getTime();
        var S3_BUCKET_URL = apiUrl + '/containers/wraps-files/upload';
        var deferred = $q.defer();

        if (file) {
            if (!file.$error) {
                vm.isLoading = true;

                file.container = 'wraps-files';
                file.fileLocation = 'translationFiles/' + timestamp;
                file.fileName = file.name;
                file.fileSize = file.size;
                vm.JSONfilesList = [];
                vm.JSONfilesList.push(file);
                //console.log(vm.JSONFile);

                //TODO add timeout to animate files
                var newName = file.fileLocation + '/' + file.name;
                Upload.rename(file, newName);
                Upload.upload({
                    url: S3_BUCKET_URL,
                    file: file

                }).then(function (resp) {
                    //console.log(resp);
                    vm.book.languageFile = newName;
                    deferred.resolve();
                }, null, function (evt) {
                    file.determinateValue = parseInt(100.0 * evt.loaded / evt.total);

                });
            }
        }

        return deferred.promise;
    };

    vm.clearDivisionDataDialog = function (previousValue) {

        var confirm = $mdDialog.confirm()
            .title('Change Division')
            .htmlContent('By changing division all division data entered so far will be lost. Would you like to proceed?<br />')
            .ariaLabel('Confirm division change')
            .ok('Change')
            .cancel('Cancel');

        $mdDialog.show(confirm)
            .then(function () {
                vm.clearDivisionData();
            }, function () {
                vm.book.divisionId = previousValue;
            });

    }

    vm.insurerDetailsDialog = function () {
        if(vm.book.insurerId == 38) {
            var confirm = $mdDialog.confirm()
                .title('Insurer Details')
                .htmlContent('You need to list every insurer that will use this document to enable other users to identify where this document is in use.<br />')
                .ariaLabel('Confirm Insurer Details')
                .ok('Add Insurer Details')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    vm.showInsurerDetails = true;
                }, function () {
                    vm.book.insurerId = angular.copy(vm.originalInsurerId);
                    vm.showInsurerDetails = false;
                });
        }
    }

    vm.generateDocCode = function() {
        if(vm.book.bookCodeOld !== 'disableAutoCode'){
            var codeComponents = {
                "bookId": vm.book.bookId,
                "insurerId": vm.book.insurerId,
                "productId": vm.book.productId,
                "docType": vm.book.DocTypes ? vm.book.DocTypes.docTypeCode : '',
                "languageId": vm.book.language,
                "clientId": vm.book.clientId,
                "createdDate": vm.book.created
            };

            Books.generateDocumentCode(codeComponents).$promise.then(function(result) {
                vm.book.bookCode = result[0][0].bookCode;
            });
        }
    }

    vm.validateCharactors = function($event) {
        var regex = new RegExp("^[^<>:\"\/\\\\|?*]+$");
        var key = String.fromCharCode(!$event.charCode ? $event.which : $event.charCode);
       
        if (!regex.test(key)) {
           $event.preventDefault();
           return false;
        }  
    }

    vm.clearDivisionData = function () {
        vm.book.bookOwner = '';
        vm.temp.DocTypeCat = '';
        vm.book.DocTypes = '';
        vm.book.insurerId = '';
        vm.book.productId = '';
        vm.book.regionId = '';
        vm.book.productTypeId = '';
        vm.book.clientId = '';
        vm.book.audienceId = '';
        vm.book.language = '';
        vm.book.subInsurerId = '';
        vm.book.distributorID = '';
        vm.book.projectId = '';
        vm.book.finProm = '';
        vm.book.limitDoc = '';
        vm.book.docContents = '';
        vm.book.languages = '';
        vm.book.production = {};
        vm.book.support = {};
        vm.book.inSitu = {};
        vm.book.fulfilment = {};
        vm.book.digital = {};
        vm.book.languageVersions = {};
        vm.book.bookContent = {};

        // set the publishing switches to NA on clear
        vm.switch.production.flag = true;
        vm.switch.fulfilment.flag = true;
        vm.switch.digital.flag = true;
        vm.switch.support.flag = true;
        vm.switch.inSitu.flag = true;
    }


}
angular
    .module('library')
    .component('tabLevels', {
        templateUrl: 'library/library-components/right-menu/tab-levels/tab-levels.tpl.html',
        controller: tabLevelsCtrl,
        controllerAs:'tabLevels',
        bindings:{
            book:'<',
            disabled:'<'
        }

    });
tabLevelsCtrl.$inject = [];
function tabLevelsCtrl(){
    //var vm = this;
}
angular
    .module('library')
    .component('tabEnclosures', {
        templateUrl: 'library/library-components/right-menu/tab-enclosures/tab-enclosures.tpl.html',
        controller: tabEnclosuresCtrl,
        controllerAs:'tabEnclosures',
        bindings:{
            book:'=',
            disabled:'='
        }

    });

tabEnclosuresCtrl.$inject = ['$scope', 'EnclosureTypes', 'Books'];
function tabEnclosuresCtrl($scope, EnclosureTypes, Books){
    var vm = this;
    //vm.book = tabEnclosures.book;
    vm.docTypes = [1, 4, 32, 80, 82, 93, 10, 29, 97, 9, 109, 58, 106];

    EnclosureTypes.find({}, function (enclosureTypes) {
        vm.enclosureTypes = enclosureTypes;
    });

    Books.find({
        filter: {
            fields:{
                "bookId": true,
                "docTypeId": true,
                "bookName": true,
                "bookCode": true
            },
            where: {
                docTypeId: { inq: vm.docTypes},
                expiryDate: {gt: moment(new Date())},
                deleted: 0,
                and: [{
                    or: [{
                        earlyExpiryDate: {gt: moment(new Date())}
                    }, {
                        earlyExpiryDate: null
                    }]
                }]
            }
        }
    }).$promise.then(function (booksForSuggestiveSelect) {
        vm.booksForSuggestiveSelect = booksForSuggestiveSelect;
    });

    vm.getBooksForEnclosureType = function(textSearch, docTypes) {
        var docTypesArray
        if(docTypes) {
            if (docTypes.length > 0) {
                docTypesArray = docTypes.split(',');
            } else {
                docTypesArray = false;
            }

            // filtering by comma separated cleverness
            //var pattern = new RegExp(/[^\s,]+$/g);

            docTypesArray = docTypesArray.map(function (x) {
                return parseInt(x, 10);
            });

            var filtered = vm.booksForSuggestiveSelect.filter(
                function (e) {
                    //searchString = textSearch;
                    //searchString = pattern.exec(textSearch);

                    if (docTypesArray !== false) {
                        return docTypesArray.indexOf(e.docTypeId) >= 0 && e.bookCode.toLowerCase().includes(textSearch.toLowerCase());
                    } else {
                        return e.bookCode.toLowerCase().includes(textSearch.toLowerCase());
                    }

                }
            );

            return filtered;
        }else{
            return false;
        }
    }
}
angular
    .module('library')
    .component('tabContent', {
        templateUrl: 'library/library-components/right-menu/tab-content/tab-content.tpl.html',
        controller: tabContentCtrl,
        controllerAs:'tabContent',
        bindings:{
            book:'<',
            disabled:'<'
        }

    });
tabContentCtrl.$inject = ['Wraps'];
function tabContentCtrl(Wraps){
    var vm = this;

    Wraps.initVars.then(function() {
        vm.languages = Wraps.languages;
        vm.docContents = Wraps.docContents;
    });
}
angular
    .module('library')
    .component('tabDates', {
        templateUrl: 'library/library-components/right-menu/tab-dates/tab-dates.tpl.html',
        controller: tabDatesCtrl,
        controllerAs:'tabDates',
        bindings:{
            book:'<',
            visible:'<',
            disabled:'<'
        }

    });

tabDatesCtrl.$inject = ['$scope'];
function tabDatesCtrl($scope){
    var vm = this;
    vm.today = moment().hours(0).minutes(0).seconds(0).toDate();
    var defaultLiveDate;

    vm.$onInit = function(){
        vm.timezones = moment.tz.names();
    }

    $scope.$watch('tabDates.visible',function(){

        vm.today = moment().hours(0).minutes(0).seconds(0).toDate();
        defaultLiveDate = moment().startOf('day').startOf('month').add(3, 'months').hours(0).toDate();

        if(vm.book && !vm.book.bookId && !vm.book.duplicateId){
        //new document set defaults
            vm.expiryDate = undefined;
            vm.book.liveDate = defaultLiveDate;
            vm.book.dates = {};
            vm.book.dates.previewPeriod = '0,months';
            vm.book.dates.reviewPeriod = '2,months';
            vm.book.dates.lifecyclePeriod = '12,months';
            vm.book.dates.timezone = moment.tz.guess();
            vm.changeLiveDate(vm.book);

        }else if(vm.book && !vm.book.dates){
        //if dates object is not present;
            vm.book.dates = {};
            vm.book.dates.timezone= 'Europe/London';

            vm.expiryDate = vm.book.expiryDate;
            if (vm.book.liveDate){
            //create new date object for full"

                vm.book.liveDate = moment(vm.book.liveDate).toDate();

                console.log("a",moment(vm.book.liveDate).diff(vm.book.activeDate,'months',true));
                console.log("b",moment(vm.book.expiryDate).subtract(moment(vm.book.liveDate).diff(vm.book.activeDate,'months',true), 'months').diff(vm.book.reviewDate,'months',true));


                vm.book.dates.previewPeriod = Math.round(moment(vm.book.liveDate).diff(vm.book.activeDate,'months',true)) + ',months';
                vm.book.dates.reviewPeriod = Math.round(moment(vm.book.expiryDate).subtract(Math.round(moment(vm.book.liveDate).diff(vm.book.activeDate,'months',true)), 'months').diff(vm.book.reviewDate,'months',true));
                if(vm.book.dates.reviewPeriod > 0){
                    vm.book.dates.reviewPeriod = vm.book.dates.reviewPeriod + ',months';
                }else{
                    vm.book.dates.reviewPeriod = '2,weeks';
                }
                vm.book.dates.lifecyclePeriod = moment(vm.book.expiryDate).startOf('day').add(1,'day').diff(moment(vm.book.liveDate).startOf('day'),'months',true);
                console.log("lifecyclePeriod", vm.book.dates.lifecyclePeriod);
                if(vm.book.dates.lifecyclePeriod == 3 || vm.book.dates.lifecyclePeriod == 6 || vm.book.dates.lifecyclePeriod == 9 || vm.book.dates.lifecyclePeriod == 12){
                    vm.book.dates.lifecyclePeriod = vm.book.dates.lifecyclePeriod + ',months';
                    //console.log("yep");
                }else{
                    vm.book.dates.lifecyclePeriod = "0,months";
                    //console.log("nope");
                }

                vm.changeLiveDate(vm.book);

            }else{
            //create new date object for live on approval
                console.log("c",moment(vm.book.expiryDate).diff(vm.book.reviewDate,'months',true));

                vm.book.liveOnApproval = true;
                vm.book.dates.previewPeriod = '0,months';
                vm.book.dates.reviewPeriod = Math.round(moment(vm.book.expiryDate).diff(vm.book.reviewDate,'months',true));
                if(vm.book.dates.reviewPeriod > 0){
                    vm.book.dates.reviewPeriod = vm.book.dates.reviewPeriod + ',months';
                }else{
                    vm.book.dates.reviewPeriod = '2,weeks';
                }
                vm.book.dates.lifecyclePeriod = "0,months";
                vm.goLiveOnApproval();

            }


        }else if(vm.book){
            vm.expiryDate = vm.book.expiryDate;
            if(typeof vm.book.dates == "string"){
                vm.book.dates = JSON.parse(vm.book.dates);
            }
            console.log("dates", vm.book.dates);
            if (vm.book.liveDate) {
                vm.book.liveOnApproval = false;
                vm.changeLiveDate(vm.book);
            }else{
                vm.book.liveOnApproval = true;
                vm.goLiveOnApproval();
            }
        }else{

        }
        //console.log(this,$scope, vm.book);

    });




    vm.checkRightMenuDates = function (book) {
        //book.activeDate
        //book.dates.previewPeriod
        //book.liveDate
        //book.liveOnApproval
        //book.reviewDate
        //book.beforeExpiry
        //book.expiryDate


        vm.reviewDateDescription = 'Expiry Date';

    }

    vm.changeActiveDate = function (book) {
        var selectVal = book.dates.previewPeriod.split(",");
        selectVal[0]=parseInt(selectVal[0]);
        book.activeDate = moment(book.liveDate).subtract(selectVal[0], selectVal[1]).toDate();
        if(selectVal[0] > 0){
            vm.reviewDateDescription = 'Next Active Date';
        }else{
            vm.reviewDateDescription = 'Expiry Date';
        }
        vm.changeActiveAnniversary(book);
    };
    vm.changeLiveDate = function(book){
        var dt = new Date();
        var tzMinutes = dt.getTimezoneOffset();

        console.log("Timezone Offset",tzMinutes);
        if(!vm.book.liveOnApproval){
            book.liveDate = moment(book.liveDate).subtract(tzMinutes,'minute').startOf('day').toDate();
        }
        vm.changeActiveDate(book);
        vm.changeExpiryDate(book);
    }


    vm.changeReviewDate = function (book) {
        var selectVal = book.dates.reviewPeriod.split(",");
        selectVal[0]=parseInt(selectVal[0]);
        if(book.dates.liveDateAnniversary){
            book.reviewDate = moment(book.dates.activeDateAnniversary).subtract(selectVal[0],selectVal[1]).toDate();
        }else{
            book.reviewDate = undefined;
            var timetoexpiry = book.dates.lifecyclePeriod.split(',');
            timetoexpiry = parseInt(timetoexpiry[0]);
            if (selectVal[1] == 'weeks'){
                selectVal[0] = selectVal[0] / 4;
            }
            book.dates.timeToReview = (timetoexpiry - selectVal[0]) + ',months';
        }
        vm.checkValidDates();
    };
    vm.checkValidDates = function(){
        if (
            // (vm.book.activeDate && moment(vm.book.activeDate).isBefore(vm.today, 'day'))    ||       //active date is before today
            (!vm.book.liveOnApproval && (!vm.book.liveDate || !vm.book.activeDate)) ||      //it's not live on approval but live date and active date are blank
            // (vm.book.liveDate && moment(vm.book.liveDate).isBefore(vm.today,'day')) ||            //live date is before today
            // (!vm.book.liveOnApproval && vm.book.expiryDate && !vm.book.reviewDate) ||               //review date is blank
            (moment(vm.book.reviewDate).isBefore(vm.today,'hour')) ||                              //review date is before today
            (vm.book.liveDate && moment(vm.book.reviewDate).isBefore(vm.book.liveDate,'hour')) ||  //review date is before Live date
            //(!vm.book.expiryDate) ||                                                        //expiry date is blank
            (moment(vm.book.expiryDate).isBefore(vm.today)) ||                                //expiry date is before today
            (vm.book.dates.reviewPeriod != '3,months' && vm.book.dates.reviewPeriod != '2,months' && vm.book.dates.reviewPeriod != '1,months' && vm.book.dates.reviewPeriod != '2,weeks') //review Period is non standard

        ){
            vm.book.dates.isValid = false;
        }else{
            vm.book.dates.isValid = true;
        }
    };

    vm.changeExpiryDate = function(book){

        var dt = new Date();
        var tzMinutes = dt.getTimezoneOffset();

        console.log("Timezone Offset",tzMinutes);

        var selectVal = book.dates.lifecyclePeriod.split(",");
        selectVal[0]=parseInt(selectVal[0]);

        if (selectVal[0] > 0) {
            if (book.liveDate){
                book.expiryDate = moment(book.liveDate).subtract(tzMinutes,'minute').startOf('day').subtract(1, 'minute').add(selectVal[0], selectVal[1]).toDate();
                book.dates.liveDateAnniversary = moment(book.expiryDate).add(1,'minute').toDate();
            }else{
                book.expiryDate = undefined;
                book.dates.liveDateAnniversary = undefined;
            }
            vm.expiryDate = undefined;
        } else {

            console.log("vm.expiryDate",vm.expiryDate);
            console.log("book.expiryDate",book.expiryDate);

            if(vm.book.liveOnApproval && !vm.expiryDate) {

                vm.expiryDate = moment().startOf('day').endOf('month').add(12, 'months').hours(23).minutes(59).seconds(0).toDate();

            }else if(!vm.expiryDate){

                vm.expiryDate  = moment(book.liveDate).startOf('day').subtract(1, 'minute').add(12, 'months').toDate();
            }


            book.expiryDate = moment(vm.expiryDate).hours(23).minutes(59).seconds(0).toDate();

            book.dates.liveDateAnniversary = moment(book.expiryDate).add(1,'minute').toDate();
        }

        vm.changeActiveAnniversary(book);
    }
    vm.changeActiveAnniversary = function(book){
        var selectVal = book.dates.previewPeriod.split(",");
        selectVal[0]=parseInt(selectVal[0]);
        if(book.dates.liveDateAnniversary) {
            book.dates.activeDateAnniversary = moment(book.dates.liveDateAnniversary).subtract(selectVal[0], selectVal[1]).toDate();
        }else{
            book.dates.activeDateAnniversary = undefined;
        }
        vm.changeReviewDate(book);
    }

    vm.goLiveOnApproval = function(){
        if(vm.book.liveOnApproval) {

            vm.book.dates.previewPeriod = '0,months';
            vm.book.liveDate = undefined;
            vm.book.activeDate = undefined;
            if (vm.book.dates.lifecyclePeriod != '0,months'){
                vm.book.expiryDate = undefined;
                vm.book.dates.liveDateAnniversary = undefined;
                vm.book.reviewDate = undefined;
            }
            vm.reviewDateDescription = 'Expiry Date';
            vm.changeExpiryDate(vm.book);

        }else{
            vm.book.dates.previewPeriod = '2,months';
            vm.book.liveDate = defaultLiveDate;
            vm.changeLiveDate(vm.book);
        }
    }

}
angular
    .module('library')
    .component('inputWfStep', {
        templateUrl: 'library/library-components/right-menu/input-wf-step/input-wf-step.tpl.html',
        controller: inputWfStepCtrl,
        controllerAs:'inputWfStep',
        bindings:{
            array:'=',
            disabled: '=',
            isvalid: '=',
            ownerid: '='
        }

    });

inputWfStepCtrl.$inject = ['$scope', 'Wraps'];
function inputWfStepCtrl($scope, Wraps){
    var vm = this;
    vm.removeWorkflow = $scope.$parent.tabWorkflow.removeWorkflow;
   // vm.arrayMCO = _.find(array, function(o) { return o.role === 2 && o.user > 0; });
//console.log("arrayMCO",vm.arrayMCO );



    Wraps.initVars.then(function () {
        vm.users = angular.copy(Wraps.users);
        vm.teams = angular.copy(Wraps.teams);
        _.forEach(vm.users, function(user, key) {
            if(user.status == 2){
                user.disabled = true;
            }
        });
        _.forEach(vm.teams, function(team, key) {
            if(team.teamStatus == 0){
                team.disabled = true;
            }
        });
    });



    vm.getRoleById = Wraps.getRoleById;
    vm.getUserById = Wraps.getUserById;
    vm.getTeamById = Wraps.getTeamById;


    vm.checkValidWorkflow = function(){

    };

    $scope.$watch(function () {
        return vm.array;
        }, function watchCallback(newValue, oldValue) {
            const noTeam = _.findIndex(newValue, function(item) { return (!item.team || item.team === '') && item.value === true; }) >= 0;
            vm.arrayMCO = _.find(newValue, function(o) { return o.role === 2 && o.user > 0; });
            if( typeof vm.arrayMCO  != 'undefined' ){ vm.arrayMCOUser = vm.arrayMCO.user; }
            else{ vm.arrayMCOUser = false; }

            //if team change - blank user
            if(typeof oldValue != 'undefined'){
              //console.log(newValue, oldValue);
              _.forEach(newValue, function(i, k) {
                  //this check if the user is in the correct team, if not it clears it
                  if(vm.getUserById(newValue[k].user) && vm.getUserById(newValue[k].user).teamId != newValue[k].team){
                    newValue[k].user= '';
                  }

              });
            }

            vm.isvalid = !noTeam;

    },true);


}

angular
    .module('library')
    .component('inputSwitchlist', {
        templateUrl: 'library/library-components/right-menu/input-switchlist/input-switchlist.tpl.html',
        controller: inputSwitchlistCtrl,
        controllerAs:'inputSwitchlist',
        bindings:{
            book:'=',
            disabled:'=',
            array:'=',
            switch:'=',
            label:'@',
            model:'@'
        }

    });



    
inputSwitchlistCtrl.$inject = [];
function inputSwitchlistCtrl(){
    var vm = this;
    vm.toggleSwitchGroup = function(){
        if(vm.switch.flag){
            _.forEach(vm.book[vm.model], function(value, key) {
                vm.book[vm.model][key] = false;
            });
        }else{
            vm.switch.flag = true;
        }
    }

    vm.toggleSwitchNa = function(){
        if(vm.switch) {
            if (_.indexOf(_.values(vm.book[vm.model]), true) >= 0) {
                vm.switch.flag = false;
            } else {
                vm.switch.flag = true;
            }
        }

    }



}
angular
    .module('library')
    .component('inputWfSpeedial', {
        templateUrl: 'library/library-components/right-menu/input-wf-speedial/input-wf-speedial.tpl.html',
        controller: inputWfSpeedialCtrl,
        controllerAs:'inputWfSpeedial',
        bindings:{
            content:'=',
            disabled:'='
        }

    });

inputWfSpeedialCtrl.$inject = ['$scope'];
function inputWfSpeedialCtrl($scope){
    var vm = this;
    vm.addWorkflow = $scope.$parent.tabWorkflow.addWorkflow;
    vm.enableWorkflowArtwork = $scope.$parent.tabWorkflow.enableWorkflowArtwork;
}
angular
    .module('library')
    .component('inputLevel', {
        templateUrl: 'library/library-components/right-menu/input-level/input-level.tpl.html',
        controller: inputLevelCtrl,
        controllerAs:'inputLevel',
        bindings:{
            book:'<',
            disabled:'<',
            level:'@'
        }

    });

inputLevelCtrl.$inject = [];
function inputLevelCtrl(){
    //var vm = this;
}
angular
    .module('library')
    .component('tabBook', {
        templateUrl: 'library/library-components/tab-book/tab-book.tpl.html',
        controller: tabBookCtrl,
        controllerAs:'tabBook',
        bindings:{
            library:'<',
            book:'<',
            opentab:'<',
            isloading:'<'
        }

    });

tabBookCtrl.$inject = ['$filter', '$localStorage', '$scope', '$state', 'Books', 'WrapsBooks'];
function tabBookCtrl($filter, $localStorage, $scope, $state, Books, WrapsBooks){
    var vm = this;

    var sortArrayByLength = function (arr, ascYN) {
        arr.sort(function (a, b) {           // sort array by length of text
            if (ascYN) return a.length - b.length;              // ASC -> a - b
            else return b.length - a.length;                    // DESC -> b - a
        });
    }

    vm.filenameInCodeCheck = WrapsBooks.filenameInCodeCheck;


    // vm.filenameInCodeCheck = function (filename, bookCode) {
    //     console.log(filename,bookCode,filename.search(bookCode));
    //     if(filename && filename.search(bookCode) >= 0){
    //         return true;
    //     }else{
    //         return false;
    //     }
    // }


    vm.toggleStructure = function (id) {
        if(!angular.isDefined(vm.opentab.selected.structure)){
            vm.opentab.selected.structure = [];
        }
        if (_.indexOf(vm.opentab.selected.structure, id) >= 0) {
            _.pull(vm.opentab.selected.structure, id);
        } else {
            vm.opentab.selected.structure.length = 0; //clears existing selection
            vm.opentab.selected.structure.push(id);
        }
    }
    vm.toggleChapter = function (id) {
        //console.log("toggleChapter", id, _.indexOf(vm.opentab.selected.level1, id));
        if(!angular.isDefined(vm.opentab.selected.level1)){
            vm.opentab.selected.level1 = [];
        }
        vm.opentab.selected.level2 = []; //collapse chapter children on close
        if (_.indexOf(vm.opentab.selected.level1, id) >= 0) {
            _.pull(vm.opentab.selected.level1, id);
        } else {
            vm.opentab.selected.level1.length = 0; //clears existing selection
            vm.opentab.selected.level1.push(id);
        }
    }
    vm.inSelected = function (id) {
        if(!angular.isDefined(vm.opentab.selected.level1)){
            vm.opentab.selected.level1 = [];
        }
        if (_.indexOf(vm.opentab.selected.level1, id) >= 0) {
            return true;
        } else {
            return false;
        }
    }
    vm.inSection = function (id) {
        if(!angular.isDefined(vm.opentab.selected.level2)){
            vm.opentab.selected.level2 = [];
        }
        if (_.indexOf(vm.opentab.selected.level2, id) >= 0) {
            return true;
        } else {
            return false;
        }
    }

    vm.hasLibraryTextSearch = function(){
        return !!$localStorage.pagination.filterLibrary.textSearch;
    }


    //collapse/expand sections
    vm.collapseSections = function (sects) {
        if(!angular.isDefined(vm.opentab.selected.level2)){
            vm.opentab.selected.level2 = [];
        }
        _.forEach(sects, function (section) {
           if(vm.opentab.selected.level2.indexOf(section.structureId) >= 0) {
               vm.opentab.selected.level2.splice(vm.opentab.selected.level2.indexOf(section.structureId), 1);
           }
        });
    };
    vm.expandSections = function (sects) {
        if(!angular.isDefined(vm.opentab.selected.level2)){
            vm.opentab.selected.level2 = [];
        }
        _.forEach(sects, function (section) {
            if (vm.opentab.selected.level2.indexOf(section.structureId) == -1) {
                vm.opentab.selected.level2.push(section.structureId);
            }
        });
    };
    vm.toggleSection = function (sect) {

        if(!angular.isDefined(vm.opentab.selected.level2)){
            vm.opentab.selected.level2 = [];
        }
        if(!angular.isDefined(vm.opentab.selected.structure)){
            vm.opentab.selected.structure = [];
        }
        //console.log('toggleSection');
        if (vm.opentab.selected.structure == sect) { // if selected copy
            if (vm.opentab.selected.level2.indexOf(sect) >= 0) { //if open
                vm.opentab.selected.level2.splice(vm.opentab.selected.level2.indexOf(sect), 1); //close it
                vm.opentab.selected.structure.length = 0; //deselect it
            } else { // if not open
                vm.opentab.selected.level2.push(sect);//open it
            }
        } else { //if not selected copy

            vm.opentab.selected.structure.length = 0; //remove previously selected
            vm.opentab.selected.structure = [];
            vm.opentab.selected.structure.push(sect);// set as selected.
            if (vm.opentab.selected.level2.indexOf(sect) == -1) { // if not open
                vm.opentab.selected.level2.push(sect);//open it
            }
        }
    }


    vm.makeBold = function (input, wordsToBold, emphasis) {
        if(!emphasis){emphasis = 1;}
        try {
            sortArrayByLength(wordsToBold);
            input = vm.showSearch(input);
            if (emphasis === 1) {
                input = input.replace("’", "'").replace(/&nbsp;/g, " ").replace(new RegExp('(\\b)(' + wordsToBold.join('|') + ')(\\b)', 'ig'), '$1<b>$2</b>$3');
            } else if (emphasis === 2) {
                input = input.replace("’", "'").replace(/&nbsp;/g, " ").replace(new RegExp('(\\b)(' + wordsToBold.join('|') + ')(\\b)', 'ig'), '$1<i>$2</i>$3');
            } else if (emphasis === 3) {
                input = input.replace("’", "'").replace(/&nbsp;/g, " ");
            }

            return input;
        } catch (err) {
            return input;
        }
    }

    vm.showSearch = function (input) {
        try {
            if (vm.searchBooksText) {
                input = input.replace(/&nbsp;/g, " ")
                    .replace(new RegExp('()(' + _.escapeRegExp(vm.searchBooksText) + '(?!([^<]+)?>))()', 'ig'), '$1<mark class="search">$2</mark>$3');
            }
            return input;
        } catch (err) {
            return input;
        }

    }

    vm.getSearchByBook = function () {
        //console.log("getSearchByBook");
        Books.getSearchByBook({
            bookId: vm.opentab.bookId,
            search: vm.searchBooksText
        }).$promise.then(function (searchResults) {
            var searchTotal = 0;
            _.forEach(vm.book.Children, function (copy1) {
                copy1.searchCount = _.result(_.find(searchResults, {'structureId': copy1.structureId}), 'count');
                searchTotal = copy1.searchCount;
                _.forEach(copy1.Children, function (copy2) {
                    copy2.searchCount = _.result(_.find(searchResults, {'structureId': copy2.structureId}), 'count');
                    searchTotal = searchTotal + copy2.searchCount;
                    copy2.searchTotal = copy2.searchCount;
                    _.forEach(copy2.Children, function (copy3) {
                        copy3.searchCount = _.result(_.find(searchResults, {'structureId': copy3.structureId}), 'count');
                        copy2.searchTotal = copy2.searchTotal + copy3.searchCount;
                        searchTotal = searchTotal + copy3.searchCount;
                        _.forEach(copy3.Children, function (copy4) {
                            copy4.searchCount = _.result(_.find(searchResults, {'structureId': copy4.structureId}), 'count');
                            copy2.searchTotal = copy2.searchTotal + copy4.searchCount;
                            searchTotal = searchTotal + copy4.searchCount;
                        });
                    });
                });
                copy1.searchTotal = searchTotal;
            });
        });

    }

    vm.refresh = function(){
        vm.book.updateBook().then(function(){vm.getSearchByBook()})
    }

    $scope.$watch('tabBook.openSearch', function(newState,oldState){
        if(newState == false && oldState == true){
            vm.searchBooksText = '';
            vm.getSearchByBook();
        }else if(newState == true){
            vm.searchBooksText = angular.copy($localStorage.pagination.filterLibrary.textSearch);
            vm.getSearchByBook();
        }
    });


    $scope.$watch('tabBook.isloading', function(current, old) {
        // on book load, open current workflow section by default.
        if(!vm.isloading && vm.book && vm.book.currentWorkflowStatus){
            if ((!vm.book.status || vm.book.status !== 1) && // don't force-open workflow-sections for completed books
                (!vm.book.documentStatus || vm.book.documentStatus.slice(0,4) !== 'EXPD') && // don't force-open workflow-sections for expired books
                vm.book.currentWorkflowStatus.content !== 'doc') // don't force-open the document content section
            {
                if (!vm.opentab.selected) {
                    vm.opentab.selected = {};
                }
                vm.opentab.selected[vm.book.currentWorkflowStatus.content] = true;
            }
        }
    });





   // vm.$onInit = function(){
        //vm.isLoading = true;
        //vm.book = $filter('filter')(WrapsBooks.openBooks, {bookId: vm.opentab.bookId})[0];
        //console.log(vm.book);
        //$scope.$watch('WrapsBooks.openBooks', function(current, old) {
            //console.log(current, WrapsBooks.openBooks);

            //console.log(book);
            // vm.isLoading=true;
            // WrapsBooks.getBooks({bookId: parseInt(vm.opentab.bookId)}).then(function(results){
            //     if(results.length > 0){
            //         vm.book = results[0];
            //         vm.book.getBook();
            //     }
            //     vm.isLoading=false;
            //
            // });
        //});
    //};


}

angular
    .module('library')
    .component('tabList', {
        templateUrl: 'library/library-components/tab-list/tab-list.tpl.html',
        controller: tabListCtrl,
        controllerAs:'tabList',
        bindings:{
            library:'<'
        }

    });

tabListCtrl.$inject = ['$scope','$localStorage', '$q', '$sce', 'WrapsBooks'];
function tabListCtrl($scope, $localStorage, $q, $sce,  WrapsBooks){
    var vm = this;
    $scope.$storage = $localStorage;
    vm.pagination = WrapsBooks.pagination;
    vm.temp = {};
    vm.temp.filterLibrary = angular.copy(vm.pagination.filterLibrary);
    //vm.filteredLibrary = WrapsBooks.filteredLibrary;
    //filtered Library is currently tabList.library.books;
    vm.isLoading = WrapsBooks.isLoading;


    vm.getActionText = function(){
        if(vm.temp.filterLibrary.actionStatus && vm.temp.filterLibrary.actionStatus.length > 0){
            var text = '';
            _.forEach(vm.temp.filterLibrary.actionStatus, function(item){
                if(item == 1) {
                    text = text + '<ng-md-icon style="fill: rgb(244, 67, 54);" class="animate-if ng-scope ng-isolate-scope" wraps-tooltip="action-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path></svg></ng-md-icon>';
                }else if(item == 2){
                    text = text + '<ng-md-icon style="fill: rgb(3, 169, 244);" class="animate-if ng-scope ng-isolate-scope" wraps-tooltip="action-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg></ng-md-icon>';
                }else if(item ==3){
                    text = text + '<ng-md-icon style="fill: rgb(3, 169, 244);" class="animate-if ng-scope ng-isolate-scope" wraps-tooltip="action-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path></svg></ng-md-icon>';
                }
            });
            return $sce.trustAsHtml(text);
        }else{
            return "Action";
        }
    }

    $scope.$watch('tabList.pagination.filterLibrary', function (before,after) {
        if(!angular.equals(before,after)){
            vm.temp.filterLibrary = angular.copy(vm.pagination.filterLibrary);
            vm.first();
        }
    },true);

    vm.updateFilter = function(){
        vm.pagination.filterLibrary = angular.copy(vm.temp.filterLibrary);
        $scope.searchForm.$setPristine();
    }

    vm.lastOnPage = function(){
        var lastOnPage = vm.pagination.startRow + vm.pagination.noResults;
        if(lastOnPage > vm.pagination.totalRows) {lastOnPage = vm.pagination.totalRows;}
        return lastOnPage;
    }

    vm.first = function(){
        vm.pagination.startRow = 0;
        vm.updateBooks();
    }

    vm.previous = function(){
        vm.pagination.startRow -= vm.pagination.noResults;
        vm.updateBooks();
    }

    vm.next = function(){
        vm.pagination.startRow += vm.pagination.noResults;
        vm.updateBooks();
    }

    vm.last = function(){
        var itemsOnLastPage = vm.pagination.totalRows % vm.pagination.noResults;
        if(itemsOnLastPage == 0){itemsOnLastPage = vm.pagination.noResults}
        vm.pagination.startRow = vm.pagination.totalRows - itemsOnLastPage;
        vm.updateBooks();
    }


    vm.hasPrevious = function () {

        if(vm.pagination.startRow == 0) {
            return false;
        }else {
            return true;
        }

    };


    vm.hasNext = function () {
        var itemsOnLastPage = vm.pagination.totalRows % vm.pagination.noResults;
        if(vm.pagination.startRow == vm.pagination.totalRows - itemsOnLastPage){
            return false;
        }else{
            return true;
        }

    };

    vm.sortBy = function(propertyName){

        if(vm.pagination.filterLibrary.sortOrder === propertyName && vm.pagination.filterLibrary.sortDirection == 'asc') {
            vm.pagination.filterLibrary.sortDirection = 'desc';
        }else{
            vm.pagination.filterLibrary.sortDirection = 'asc';
        }
        vm.pagination.filterLibrary.sortOrder = propertyName;
    }

    vm.updateBooks = WrapsBooks.getLibraryList;


    vm.stringifySearch = WrapsBooks.stringifySearch;




}
angular
    .module('library')
    .component('dateIcon', {
        templateUrl: 'library/library-components/date-icon/date-icon.tpl.html',
        controller: dateIconCtrl,
        controllerAs:'dateIcon',
        bindings:{
            date:'<',
            color:'<',
            label:'@',
            notsetlabel:'<'
        }

    });

dateIconCtrl.$inject = ['$scope'];
function dateIconCtrl($scope){
    var vm = this;
    $scope.$watch('dateIcon.notsetlabel', function(){
        if(vm.notsetlabel){
            vm.notSetLabel = vm.notsetlabel.split(',');
            if(vm.notSetLabel.length <3){
                vm.notSetLabel.unshift(' ');
            }
        }

    });
}
(function () {
  'use strict';

  /* @ngdoc object
   * @name library.workflowdialog
   * @description
   *
   */
  angular
    .module('library.workflowdialog', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('library.workflowdialog')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('workflowdialog', {
        url: '/workflowdialog',
        templateUrl: 'library/workflowdialog/workflowdialog.tpl.html',
        controller: 'WorkflowdialogCtrl',
        controllerAs: 'workflowdialog'
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.workflowdialog.controller:WorkflowdialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.workflowdialog')
    .controller('WorkflowdialogCtrl', WorkflowdialogCtrl);

  function WorkflowdialogCtrl($filter, $localStorage, $mdDialog, $q, $scope, apiUrl, book, Books, child, Containers, Events, Messages, Structures, type, Upload, Users, Wraps, WrapsBooks) {
    var vm = this;
    vm.ctrlName = 'WorkflowdialogCtrl';

    vm.apiUrl = apiUrl;
    vm.child = child;


    $scope.$storage = $localStorage;
    if(!Users.isAuthenticated()){$location.path('/');}


    vm.safeFileList = Wraps.safeFileList;

    vm.rfcReasons = Wraps.rfcReasons;
    vm.rfcCategories = Wraps.rfcCategories;
    vm.rejectionReasons = Wraps.rejectionReasons;

    vm.hasPermissions = Wraps.hasPermissions;

    vm.type = type;

    var loadEvents = function () {
      vm.isLoading = true;
      Events.find({"filter":{
          "where":{"structureId":child.structureId},
          "include": ["Messages","Users"],
          "order": "eventId DESC" }}).$promise.then(function(events){

        _.forEach(events, function (event) {
          if(event.eventData.charAt(0)==='{') {
            event.isJson=true;
            event.rfcReasons = [];
            event.categories = [];
            event.significant = false;
            event.Comment = angular.fromJson(event.eventData);
            //link id's with name from datalists

            if(!angular.isArray(event.Comment.reason && event.Comment.reason)){event.Comment.reason = [event.Comment.reason];}
            _.forEach(event.Comment.reason, function(val,key){
              event.rfcReasons.push(_.result(_.find(vm.rfcReasons, {'id': _.parseInt(val)}), 'name'));
            });
            event.rfcReasons= _.uniq(event.rfcReasons);


            if(!angular.isArray(event.Comment.category) && event.Comment.category){event.Comment.category = [event.Comment.category];}
            _.forEach(event.Comment.category, function(val,key){
              event.categories.push(_.result(_.find(vm.rfcCategories, {'id': _.parseInt(val)}), 'name'));
            });
            event.categories= _.uniq(event.categories);
            if(event.Comment.significant){event.significant = event.Comment.significant;}

          }else{
            event.Comment = {};
            event.Comment.message = event.eventData;
          }
        });
        vm.events = events;
        vm.isLoading = false;

        vm.selected = [];
        vm.toggle = function (item, list) {
          var idx = list.indexOf(item);
          if (idx > -1) {
            list.splice(idx, 1);
          }
          else {
            list.push(item);
          }
        };

        vm.exists = function (item, list) {
          return list.indexOf(item) > -1;
        };

        vm.isIndeterminate = function() {
          return (vm.selected.length !== 0 &&
            vm.selected.length !== vm.allContacts.length);
        };

        vm.isChecked = function() {
          return vm.allContacts && vm.selected.length === vm.allContacts.length;
        };

        vm.toggleAll = function() {
          if (vm.selected.length === vm.allContacts.length) {
            vm.selected = [];
          } else if (vm.selected.length === 0 || vm.selected.length > 0) {
            vm.selected = vm.allContacts.slice(0);
          }
        };


        vm.book = Wraps.book;
        vm.contacts = '';

        vm.asyncContacts = [];
        vm.filterSelected = false;

      });
    }

    loadEvents();

    $scope.$on('newCommentCreated', function(event){
        // console.log('comment event received');
        loadEvents();
    });

    /*console.log(_.findLastIndex(book.approvalList.slice(0,book.approvalStatus), function(chr) {
      return chr.type != 'team' && chr.value != false;
    }));*/

    vm.nl2br = function(string){
      try {
        return string.replace(/\n/g, '<br>');
      }catch(err){
        //return false;
      }
    }

    vm.json2Obj = function(obj){

      try{ obj = angular.extend(obj,angular.fromJson(obj.versions.versiondata));}
      catch (err){    console.log(err.name + ': "' + err.message + '" occurred when assigning string.'); return false;}
      console.log(obj.benefit);
      return obj;

    }



    vm.removeFile = function(array, index){
      array.splice(index, 1);
    }

    var timestamp = new Date().getTime();
    var S3_BUCKET_URL = apiUrl+'/Containers/wraps-files/upload';
    var itemType = "reject";
    $scope.$watch('workflowdialog.files', function (files) {
      //console.log('upload watch',test);
      vm.upload(files).then(function(statuses){
        vm.isLoading = false;
      });
    });

    vm.log = '';

    vm.upload = function (files) {
      if(!vm.reject){vm.reject = {};}
      if(vm.reject && !vm.reject.filesList){vm.reject.filesList = [];}
      var deferred = $q.defer();

      if (files && files.length) {
        _.forEach(files, function (file) {
          if (!file.$error) {
            vm.isLoading = true;

            file.container = 'wraps-files';
            file.fileLocation = itemType + '/' + timestamp;
            file.fileName = file.name;
            file.fileSize = file.size;
            vm.reject.filesList.push(file);


            //TODO add timeout to animate files

            var newName = file.fileLocation + '/' + file.name;
            Upload.rename(file, newName );
            Upload.upload({
              url: S3_BUCKET_URL,
              file: file
            }).then(function (resp) {
              file.loaded = true;

              deferred.resolve();
            },
            null,
            function (evt) {
              file.determinateValue = parseInt(100.0 *   evt.loaded / evt.total);
              //TODO get this working
              //console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '% file :'+ evt);
            });


          }
        });
      }
      return deferred.promise;
    };


    var previousEditor = _.findLastIndex(book.approvalList.slice(0,child.approvalStatus+child.status), function(chr) {
      return ((chr.role == 2 && chr.content === "doc") || (chr.role == 2 && chr.content === "brief") || (chr.role == 2 && chr.content === "artwork") || (chr.role == 6 && chr.content === "final") || (chr.role == 6 && chr.content === "artwork")) && chr.value != false;
    });

    //console.log("previousEditor",previousEditor);


    //set to CO unless user is co or book.co is false
    //update structure, book changed and add event
    vm.saveReject = function(reject){
      vm.isLoading = true;
      child.lastUpdated = moment.utc().format(Wraps.databaseDateFormat);

      var co = _.result(_.find(book.approvalList, { 'type': "co" }),'value'); // is co true

      var previousEditor = _.findLastIndex(book.approvalList.slice(0,child.approvalStatus+child.status), function(chr) {
        return ((chr.role == 2 && chr.content === "doc") || (chr.role == 2 && chr.content === "brief") || (chr.role == 2 && chr.content === "artwork") || (chr.role == 6 && chr.content === "final") || (chr.role == 6 && chr.content === "artwork")) && chr.value != false;
      });




      reject.status = child.status;
      reject.approvalStatus = previousEditor;


      child.status = 0;
      child.approvalStatus = previousEditor;

      var json = Wraps.toJson(reject);

      Structures.upsert(child, function () {
        Events.create({
          "eventId": 0,
          "bookId": child.bookId,
          "structureId": child.structureId,
          "contentId": child.versions.contentId,
          "historyId": child.History[child.History.length - 1].historyId,
          "userId": $scope.$storage.user.id,
          "eventType": "Rejected",
          "eventData": json
        }).$promise.then(function (event) {

          Books.upsert({"bookId": child.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)}).$promise.then(function (results) {
            WrapsBooks.setBookStatuses(book, vm.contacts).then(function (emailAction) {
              //book.update(true);

              //console.log("send rejection email");
              Wraps.sendRejectEmail(book, reject, previousEditor,event.eventId);

              vm.dialogHide(true);
              vm.isLoading = false;
            });

          });
        });


      });
    }

    vm.openEmailDetailsDialog = function (change) {
      $mdDialog.show({
        controller: 'EmaildetailsdialogCtrl',
        controllerAs: 'emaildetailsdialog',
        templateUrl: 'library/emaildetailsdialog/emaildetailsdialog.tpl.html',
        parent: angular.element(document.body),
        locals: {change: change},
        bindToController: true,
        clickOutsideToClose: true
      })
        .then(function (data) {

        }, function () {
          vm.status = 'You cancelled the dialog.';
        });
    };

    vm.dialogHide = function(data) {
      $mdDialog.hide(data);
    };

    vm.dialogCancel = function(data) {
      $mdDialog.cancel(data);
    };

    vm.isObjectEmpty = function(card){
      return Object.keys(card).length === 0;
    };

    vm.createFilterFor = function(query) {
      var lowercaseQuery = query.toLowerCase();

      return function filterFn(contact) {
        return (contact._lowername.indexOf(lowercaseQuery) != -1);
      };

    };

    vm.querySearch = function(criteria) {
      return criteria ? vm.allContacts.filter(vm.createFilterFor(criteria)) : [];
    };

    vm.asyncContacts = [];
    vm.filterSelected = true;
  }
  WorkflowdialogCtrl.$inject = ["$filter", "$localStorage", "$mdDialog", "$q", "$scope", "apiUrl", "book", "Books", "child", "Containers", "Events", "Messages", "Structures", "type", "Upload", "Users", "Wraps", "WrapsBooks"];;
}());


(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableUser', readableUser);

  function readableUser(Wraps) {
    return function (id) {
      var user;
      if ((user = _.find(Wraps.users, {id: parseInt(id)})) && user.firstName) {
        return user.firstName + ' ' + user.lastName;
      }
      return 'All';
    };
  }
  readableUser.$inject = ["Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableTeam
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableTeam', readableTeam);

  function readableTeam(Wraps) {
    return function (id) {
      var team;
      if ((team = _.find(Wraps.teams, {teamId: parseInt(id)})) && team.teamName) {
        return team.teamName;
      }
      return null;
    };
  }
  readableTeam.$inject = ["Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableSubInsurer', readableSubInsurer);

  function readableSubInsurer(Wraps) {
    return function (id) {
      var subInsurer;
      if ((subInsurer = _.find(Wraps.subInsurers, {subInsurerId: parseInt(id)})) && subInsurer.subInsurerName) {
        return subInsurer.subInsurerName;
      }
      return null;
    };
  }
  readableSubInsurer.$inject = ["Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableSocialChannel', readableSocialChannel);

  function readableSocialChannel(Wraps) {
    return function (id) {
      var socialChannel;
      id = parseInt(id);
      if (angular.isNumber(id)) {
        if ((socialChannel = _.find(Wraps.socialChannels, {socialChannelId: parseInt(id)})) && socialChannel.socialChannelName) {
          return socialChannel.socialChannelName;
        }
      }
      return null;
    };
  }
  readableSocialChannel.$inject = ["Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableRole
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableRole', readableRole);

  function readableRole(Wraps) {
    return function (id) {
      var role;
      if ((role = _.find(Wraps.roles, {id: parseInt(id)})) && role.description) {
        return role.description;
      }
      return null;
    };
  }
  readableRole.$inject = ["Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableRegion', readableRegion);

  function readableRegion(Wraps) {
    return function (id) {
      var region;
      if ((region = _.find(Wraps.regions, {regionId: parseInt(id)})) && region.regionName) {
        return region.regionName;
      }
      return null;
    };
  }
  readableRegion.$inject = ["Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readablePublishTypes', readablePublishTypes);

  function readablePublishTypes(Wraps) {
    return function (id) {
      var pubType;
      if ((pubType = _.find(Wraps.publishTypes, {id: parseInt(id)})) && pubType.name) {
        return pubType.name;
      }
      return null;
    };
  }
  readablePublishTypes.$inject = ["Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableProject', readableProject);

  function readableProject(Wraps) {
    return function (id) {
      var project;
      if ((project = _.find(Wraps.projects, {projectId: parseInt(id)})) && project.projectName) {
        return project.projectName;
      }
      return null;
    };
  }
  readableProject.$inject = ["Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableProductType', readableProductType);

  function readableProductType(Wraps) {
    return function (id) {
      var productType;
      if ((productType = _.find(Wraps.productTypes, {productTypeId: parseInt(id)})) && productType.productTypeName) {
        return productType.productTypeName;
      }
      return null;
    };
  }
  readableProductType.$inject = ["Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableProduct', readableProduct);

  function readableProduct(Wraps) {
    return function (id) {
      var product;
      if ((product = _.find(Wraps.products, {productId: parseInt(id)})) && product.productName) {
        return product.productName;
      }
      return null;
    };
  }
  readableProduct.$inject = ["Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableMaterDocType
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableMasterDocType', readableMasterDocType);

  function readableMasterDocType(Wraps) {
    return function (id) {
      var docType, docTypeCat;
      if ((docType = _.find(Wraps.docTypes, {docTypeId: parseInt(id)})) && docType.docTypeCatId) {
        if ((docTypeCat = _.find(Wraps.docTypeCats, {docTypeCatId: parseInt(docType.docTypeCatId)})) && docTypeCat.docTypeCatName) {
          return docTypeCat.docTypeCatName;
        }
      }
      return null;
    };
  }
  readableMasterDocType.$inject = ["Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableLanguage', readableLanguage);

  function readableLanguage(Wraps) {
    return function (id) {
      var language;
      if ((language = _.find(Wraps.languages, {id: parseInt(id)})) && language.name) {
        return language.name;
      }
      return null;
    };
  }
  readableLanguage.$inject = ["Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableInsurer', readableInsurer);

  function readableInsurer(Wraps) {
    return function (id) {
      var insurer;
      if ((insurer = _.find(Wraps.insurers, {insurerId: parseInt(id)})) && insurer.insurerName) {
        return insurer.insurerName;
      }
      return null;
    };
  }
  readableInsurer.$inject = ["Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableEmphasis', readableEmphasis);

  function readableEmphasis(Wraps) {
    return function (id) {
      var emphasis;
      if ((emphasis = _.find(Wraps.emphasis, {id: parseInt(id)})) && emphasis.name) {
        return emphasis.name;
      }
      return null;
    };
  }
  readableEmphasis.$inject = ["Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableDocType', readableDocType);

  function readableDocType(Wraps) {
    return function (id) {
      var docType;
      if ((docType = _.find(Wraps.docTypes, {docTypeId: parseInt(id)})) && docType.docTypeName) {
        return docType.docTypeName;
      }
      return null;
    };
  }
  readableDocType.$inject = ["Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableDivision', readableDivision);

  function readableDivision(Wraps) {
    return function (id) {
      var division;
      if ((division = _.find(Wraps.divisions, {divisionId: parseInt(id)})) && division.division) {
        return division.division;
      }
      return null;
    };
  }
  readableDivision.$inject = ["Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableDistributor', readableDistributor);

  function readableDistributor(Wraps) {
    return function (id) {
      var dist;
      if ((dist = _.find(Wraps.distributors, {distributorId: parseInt(id)})) && dist.distributorName) {
        return dist.distributorName;
      }
      return null;
    };
  }
  readableDistributor.$inject = ["Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableContent', readableContent);

  function readableContent(Wraps) {
    return function (id) {
      var content;
      if ((content = _.find(Wraps.docContents, {id: parseInt(id)})) && content.name) {
        return content.name;
      }
      return null;
    };
  }
  readableContent.$inject = ["Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableClient', readableClient);

  function readableClient(Wraps) {
    return function (id) {
      var client;
      if ((client = _.find(Wraps.clients, {clientId: parseInt(id)})) && client.clientName) {
        return client.clientName;
      }
      return null;
    };
  }
  readableClient.$inject = ["Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableBGHPColour', readableBGHPColour);

  function readableBGHPColour(Wraps) {
    return function (id) {
      var colour;
      if ((colour = _.find(Wraps.BGHPColour, {id: parseInt(id)}))  && colour.name) {
        return colour.name;
      }
      return null;
    };
  }
  readableBGHPColour.$inject = ["Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableAudience
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableAudience', readableAudience);

  function readableAudience(Wraps) {
    return function (id) {
      var audience;
      if ((audience = _.find(Wraps.audiences, {audienceId: parseInt(id)})) && audience.audienceName) {
        return audience.audienceName;
      }
      return null;
    };
  }
  readableAudience.$inject = ["Wraps"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name library.swapteamuserdialog
   * @description
   *
   */
  angular
    .module('library.swapteamuserdialog', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('library.swapteamuserdialog')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('swapteamuserdialog', {
        url: '/swapteamuserdialog',
        templateUrl: 'library/swapteamuserdialog/swapteamuserdialog.tpl.html',
        controller: 'SwapteamuserdialogCtrl',
        controllerAs: 'swapteamuserdialog'
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.swapteamuserdialog.controller:SwapteamuserdialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.swapteamuserdialog')
    .controller('SwapteamuserdialogCtrl', SwapteamuserdialogCtrl);

  function SwapteamuserdialogCtrl(
    $filter,
    $localStorage,
    $mdDialog,
    $scope,
    $sessionStorage,
    book,
    Books,
    Events,
    Teams,
    Users,
    Wraps,
    WrapsBooks) {
    var vm = this;
    vm.ctrlName = 'SwapteamuserdialogCtrl';

    if(!Users.isAuthenticated()){$location.path('/');}

    $scope.$storage = $localStorage;
    $scope.$session = $sessionStorage;

    vm.booId = book.bookId;
    vm.bookName = book.bookName;
    var approvalList = angular.copy(book.approvalList);


    // add team to list
    vm.teamList = [$scope.$storage.user.teamId];

    // if team = design add unigraph team to list
    if($scope.$storage.user.teamId == 20){
      vm.teamList.push(21);
    }

    Users.find({"filter": {"where": {"and":[{"teamId":{"inq": vm.teamList}},{"status":null}]}}}).$promise.then(function(results){
      vm.users = results;
    });


    Teams.find({"filter": {"where": {"and":[{"teamId":{"inq": vm.teamList}},{"teamStatus":1}]}}}).$promise.then(function(results){
      vm.teams = results;
    });

    _.forEach(approvalList, function (item, index) {

      item.labels = {}

      switch (item.content){
        case 'doc':
          item.labels.type = 'Document';
          break;
        case 'brief':
          item.labels.type = 'Document or Brief';
          break;
        case 'artwork':
          item.labels.type = 'Artwork Draft';
          break;
        case 'final':
          item.labels.type = 'Publishing';
          break;
        default:
      }

      if (item.team) {
        item.labels.team = Wraps.getTeamById(item.team).teamName;
      }
      if (item.user) {
        item.labels.user = Wraps.getUserById(item.user).firstName + ' ' + Wraps.getUserById(item.user).lastName;
      }else{
        item.labels.user = 'All';
      }

      if (item.role) {
        if (item.role != 3) {
          if (item.role == 6 && item.content == 'final') {
            item.labels.title = 'FINAL FILES';
          }else{
            item.labels.title = $filter('uppercase')(Wraps.getRoleById(item.role).description);
          }
        }
        else if (parseInt(item.team) > 0) {
          item.labels.title = $filter('uppercase')(Wraps.getTeamById(item.team).shortName);
        }
        else{
          //console.log("error 1",item);
        }
      }else{
       //console.log("error 2",item);
      }
    });

    vm.approvalList = angular.copy(approvalList);
    vm.approvalListCopy = angular.copy(approvalList); // for validation checks

    vm.canChangeTeam = function (item) {
      // if user team in teamList and length > 1
      if (vm.teamList.length >= 1 && vm.teamList.includes(item.team)) {

        if (item.role === 3 || item.role === 6 || item.role === 11) {
          return true;
        }
      }
      return false;
    }

    vm.mco = _.find(vm.approvalList, function (o) { return o.role === 2 && o.user > 0; });

    vm.isMcoAsApprover = function (user, item, mco) {
      if (
        angular.isObject(item) &&
        item.role &&
        (item.role === 3 || item.role === 8) &&
        user.id === mco.user
        ) {
        return true;
      }
      return false;
    }

    vm.checkForChanges = function(){
      return Wraps.toJson(vm.approvalList) == Wraps.toJson(vm.approvalListCopy);
    }

    vm.saveWorkflow = function(){
      vm.isLoading = true;
      var thisList = angular.copy(vm.approvalList);

      var newList = [];
      _.forEach(thisList, function (item, index) { //remove labels and sort keys to make sure the order matches with existing format
        delete item.labels;
        newList[index] = {};
        _(item).keys().sort().each(function (key) {
          newList[index][key] = item[key];
        });
      });
      Books.upsert({
        "bookId": book.bookId,
        "approvalList": Wraps.toJson(newList)
      }).$promise.then(function (results) {
        Events.create({
          "eventId": 0,
          "bookId": book.bookId,
          "userId": $scope.$storage.user.id,
          "eventType": "Edited Workflow",
          "eventData": "{\"before\":" + Wraps.toJson(thisList) + ",\"after\":" + Wraps.toJson(newList) + "}"
        }).$promise.then(function (results) {
          WrapsBooks.updateBookById(book.bookId);
          //book.update(true);
          vm.dialogHide();
        });
      });




    }

    vm.dialogHide = function() {
      $mdDialog.hide();
    };

    vm.dialogCancel = function() {
      $mdDialog.cancel();
    };
  }
  SwapteamuserdialogCtrl.$inject = ["$filter", "$localStorage", "$mdDialog", "$scope", "$sessionStorage", "book", "Books", "Events", "Teams", "Users", "Wraps", "WrapsBooks"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name library.pushdialog
   * @description
   *
   */
  angular
    .module('library.pushdialog', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('library.pushdialog')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('pushdialog', {
        url: '/pushdialog',
        templateUrl: 'library/pushdialog/pushdialog.tpl.html',
        controller: 'PushdialogCtrl',
        controllerAs: 'pushdialog'
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.pushdialog.controller:PushdialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.pushdialog')
    .controller('PushdialogCtrl', PushdialogCtrl);

  function PushdialogCtrl($localStorage, $mdDialog, $q, $scope, activeContent, Books, Events, History, Structures, Users, Wraps) {
    var vm = this;
    vm.ctrlName = 'PushdialogCtrl';

    $scope.$storage = $localStorage;
    if(!Users.isAuthenticated()){$location.path('/');}

    vm.activeContent = activeContent;

    Structures.find({
      filter: {"where":{"contentId":vm.activeContent.contentId}, "include": [{"versions":{"Contents":"ContentTypes"}},"Books"]}
    }).$promise.then(function(data){
      _.remove(data, function (item) { if(!item.Books){return item;} });
      _.remove(data, function (item) { if(item.Books){return item.Books.status > 0;} });

      _.forEach(data, function (item, key) {

        if(item.versions.Contents.contentTypeId == 1 && item.versions.versiondata.charAt(0) === "{"){
          var json = angular.fromJson(item.versions.versiondata);
          item.versions.versiondata = json.chapterCopy;
        }

        if (item.versions.Contents.ContentTypes.isJson === true) {
          Wraps.json2Obj(item);
        }
        try{  item.Books.approvalList = angular.fromJson(item.Books.approvalList); } catch (err) { }
        item.Books.userInBook = userInBookApprovalList(item.Books, $scope.$storage.user);
        if(item.Books.approvalList && item.Books.approvalList[item.Books.approvalStatus]){
          item.Books.currentWorkflowStatus = item.Books.approvalList[item.Books.approvalStatus];
        }
      });
      _.remove(data, function (item) { if(item.Books.userInBook[0] != item.Books.approvalStatus){return item;} });

      vm.contentlist = data;
    });


// gets array of indexes current user is in book
    var userInBookApprovalList = function (book, user) {
      var indexes = [];
      _.forEach(book.approvalList, function (item, index) {
        item.role = parseInt(item.role);
        if((item.role === 3 || item.role === 8) && book.bookOwner === $scope.$storage.user.id ){

        }else{
          if(item.user === user.id && item.team === user.teamId ){//&& item.role === user.roleId){
            indexes.push(index);
          }else if((angular.isUndefined(item.user) || item.user === '') && item.team === user.teamId ){//&& item.role === user.roleId){
            indexes.push(index);
          }else if((angular.isUndefined(item.user) || item.user === '') && (angular.isUndefined(item.team) || item.team === '') ){//&& item.role === user.roleId){
            indexes.push(index);
          }
        }
      });
      return indexes;
    }



    vm.selected = [];
    vm.setSelected = function (chapter){
      if(chapter.selected>0){
        vm.selected.push(chapter);
      }else{
        _.pullAt(vm.selected, _.findKey(vm.selected, 'structureId', chapter.structureId));
      }
    }




    vm.pushContent = function (selected){
      vm.isLoading = true;


          //TODO this might want to copy previous RFC?

          angular.forEach(selected, function (item) {
            Structures.upsert({"versionId": activeContent.versionId, "structureId": item.structureId}, function () {
                  Books.upsert({"bookId": item.Books.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                  History.create({
                    "structureId": item.structureId,
                    "versionIdOld": item.versionId,
                    "versionIdNew": activeContent.versionId,
                    "historyId": 0
                  }, function (historyResponse) {

                    //console.log(vm.activeContent);
                    Events.findOne({filter:{"order":"created DESC", "limit":1, "where":{"bookId":vm.activeContent.bookId, "structureId":vm.activeContent.structureId, "eventType":"Edited"}}}).$promise.then(function (results) {
                      Events.create({
                        "eventId": 0,
                        "bookId": item.Books.bookId,
                        "structureId": item.structureId,
                        "contentId": vm.activeContent.contentId,
                        "historyId": historyResponse.historyId,
                        "userId": $scope.$storage.user.id,
                        "eventType": "Changed",
                        "eventData": results.eventData
                      });
                    });

                  });
                  vm.dialogHide(true); //true = refresh parent
                  vm.isLoading = false;
                }, function () {
                  //console.log('error saving');
                }
            );
          });

    }

    vm.nl2br = function(string){
      try {
        return string.replace(/\n/g, '<br>');
      }catch(err){
        //return false;
      }
    }

    vm.json2Obj = function(obj){

      try{ obj = angular.extend(obj,angular.fromJson(obj.versions.versiondata));}
      catch (err){    console.log(err.name + ': "' + err.message +  '" occurred when assigning string.'); return false;}
      console.log(obj.benefit);
      return obj;

    }


    vm.dialogHide = function(data) {
      $mdDialog.hide(data);
    };
    vm.dialogCancel = function(data) {
      $mdDialog.cancel(data);
    };









  }
  PushdialogCtrl.$inject = ["$localStorage", "$mdDialog", "$q", "$scope", "activeContent", "Books", "Events", "History", "Structures", "Users", "Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name library.service:Wraps
   *
   * @description
   *
   */
  angular
    .module('library')
    .service('Notifications', Notifications);

  function Notifications($interval, $localStorage, $rootScope, $q, Books) {
    var self = this;

    self.get = function () {
      return 'Notifications';
    };

    self.list = {loading:true};
    self.count = {};

    self.getNotifications = function(){
		var deferred = $q.defer();
		Books.getNotifications({"selector":"rows"}).$promise.then(function(res){
            self.list = res;
            deferred.resolve();
		});
		return deferred.promise;
    };

    self.getCounts = function(){
        //console.log("getting counts");
      var deferred = $q.defer();
      Books.getNotifications({"selector":"count"}).$promise.then(function(res){
        if(!angular.equals(res, self.count)){
          self.count = res;
          $rootScope.$broadcast('NOTIFICATION_COUNT_UPDATE');
        }
        deferred.resolve();
      });
      return deferred.promise;
    };

      $rootScope.$watch(function(){return $localStorage.user}, function(current, old) {
          if(current && current.id){
              //start interval
              if (!angular.isDefined($rootScope.notifCountInterval)) {
                  $rootScope.notifCountInterval = $interval(function () {
                      self.getCounts();
                  }, 60000);
              }
          }else{
              if (angular.isDefined($rootScope.notifCountInterval)){
                  $interval.cancel($rootScope.notifCountInterval);
              }
          }


      });

  }
  Notifications.$inject = ["$interval", "$localStorage", "$rootScope", "$q", "Books"];

}());


(function () {
  'use strict';

  /* @ngdoc object
   * @name library.notifications
   * @description
   *
   */
  angular
    .module('library.notifications', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('library.notifications')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('notifications', {
        url: '/notifications',
        templateUrl: 'library/notifications/notifications.tpl.html',
        controller: 'NotificationsCtrl',
        controllerAs: 'notifications'
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.notifications.controller:notificationsCtrl
   *
   * @description
   *
   */
  angular
    .module('library.notifications')
    .controller('NotificationsCtrl', NotificationsCtrl);

  function NotificationsCtrl(
      $localStorage,
      $location,
      $mdDialog,
      $q,
      $scope,
      $state,
      $window,
      activeContent,
      Books,
      Events,
      History,
      Notifications,
      Structures,
      Users,
      Wraps,
      WrapsBooks
  ) {
    var vm = this;
    vm.ctrlName = 'NotificationsCtrl';
	vm.isLoading = true;
	vm.list = {};
	vm.now = new Date();

    $scope.$storage = $localStorage;
    if(!Users.isAuthenticated()){$location.path('/');}




    vm.initNotifications = function() {
        var promises = {};
        var tabArray = [
           'blackAssetsByOwner',
            'documentsWithActionsByOwnerPersonal',
            'documentsWithActionsByOwnerTeam',
            'documentsInReview'
        ];

        _.forEach(tabArray, function (tab, ind){
            var deferred = $q.defer();
            var actionStatus = [];
            var docStatus = '';
            var owner = '';
            var sortOrder = 'bookId';
            if( tab === 'documentsInReview' ){
                docStatus = '(r)';
                owner = Wraps.user.firstName + ' ' + Wraps.user.lastName;
                sortOrder = 'reviewDate';
            } else {
                actionStatus = [(ind + 1)];
            }

            var searchArray = {
                docTypeName: '',
                documentStatus: docStatus,
                sortDirection: 'asc',
                sortOrder: sortOrder,
                ownerFullName: owner,
                workflowStatus: '',
                bookCode: '',
                division: '',
                actionStatus: actionStatus,
                productName: '',
                bookSearchId: '',
                insurerDetails: '',
            };

            var filterObject = {'startRow':0, 'noResults':0, 'searchArray':searchArray};

            Books.getLibrarySearch(filterObject).$promise.then(function (results) {
                if( tab === 'documentsInReview' ){
                  //results.data.countDown = 10;
                  console.log("results.data",results.data);
                }
                vm.list[tab] = results.data;
                deferred.resolve();
            });
            promises[tab] = deferred.promise;
        });

        var countsDeferred = $q.defer();
        Notifications.getCounts().then(function () {
            vm.count = Notifications.count;
            countsDeferred.resolve();
        });

        promises.counts = countsDeferred.promise;

        return $q.all(promises);
    };

    vm.initNotifications().then(function(){
        vm.isLoading = false;
    });


    vm.dialogHide = function(data) {
      $mdDialog.hide(data);
    };
    vm.dialogCancel = function(data) {
      $mdDialog.cancel(data);
    };


    vm.openDocument = function (bookId) {
		  $state.go('library',{bookId : bookId});
    };


    if(!vm.blackAssetsQuery) {
      vm.blackAssetsQuery = {
        order: 'bookId'
      };
    }

    vm.downloadCSV = function () {
        var actionStatus = [];
        var docStatus = '';
        var owner = '';
        var sortOrder = 'bookId';
        if( vm.activeContent === 3 ){
            docStatus = '(r)';
            owner = Wraps.user.firstName + ' ' + Wraps.user.lastName;
            sortOrder = 'reviewDate';
        } else {
            actionStatus = [(vm.activeContent + 1)];
        }


      var searchArray = {
          docTypeName: '',
          documentStatus: docStatus,
          sortDirection: 'asc',
          sortOrder: sortOrder,
          ownerFullName: owner,
          workflowStatus: '',
          bookCode: '',
          division: '',
          actionStatus: actionStatus,
          productName: '',
          bookSearchId: '',
          insurerDetails: '',
          insurerName: '',
          psiCode: ''
      };

        var deferred = $q.defer();
        vm.isLoading = true;
        WrapsBooks.downloadCSV(searchArray).then(function(csv){
            vm.isLoading = false;
            deferred.resolve(csv);
        });
        return deferred.promise;
    };









  }
  NotificationsCtrl.$inject = ["$localStorage", "$location", "$mdDialog", "$q", "$scope", "$state", "$window", "activeContent", "Books", "Events", "History", "Notifications", "Structures", "Users", "Wraps", "WrapsBooks"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name library.metachangedialog
   * @description
   *
   */
  angular
    .module('library.metachangedialog', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('library.metachangedialog')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('metachangedialog', {
        url: '/metachangedialog',
        templateUrl: 'library/metachangedialog/metachangedialog.tpl.html',
        controller: 'MetachangedialogCtrl',
        controllerAs: 'metachangedialog'
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.metachangedialog.controller:MetachangedialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.metachangedialog')
    .controller('MetachangedialogCtrl', MetachangedialogCtrl);

  MetachangedialogCtrl.$inject = ['$mdDialog', '$q', '$sce', '$scope', 'apiUrl', 'change'];
  function MetachangedialogCtrl($mdDialog, $q, $sce, $scope, apiUrl, change) {
    var vm = this;
    vm.ctrlName = 'MetachangedialogCtrl';

    vm.dialogCancel = function(data) {
        $mdDialog.cancel(data);
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:urlsToLinks
   *
   * @description Turns urls into links - to be used within ng-bind-html
   *
   * @param {string} the html string to filter
   * @returns {string} The filtered html string
   *
   */
  angular
    .module('library')
    .filter('urlsToLinks', urlsToLinks);

  function urlsToLinks() {
    return function (str) {
        str = str.replace(/(&nbsp;)/g,' ');
        var regex = /(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
        return str.replace(regex, '<a href="$1" target="_blank" ng-click="$event.stopPropagation()">$1</a>');
    };
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name library.historydialog
   * @description
   *
   */
  angular
    .module('library.historydialog', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('library.historydialog')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('historydialog', {
        url: '/historydialog',
        templateUrl: 'library/historydialog/historydialog.tpl.html',
        controller: 'HistorydialogCtrl',
        controllerAs: 'historydialog'
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.historydialog.controller:HistorydialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.historydialog')
    .controller('HistorydialogCtrl', HistorydialogCtrl);
//TODO add tab for history from other books
  function HistorydialogCtrl($mdDialog, $scope, apiUrl, clickSource, History, structureId, Users, Wraps) {
    var vm = this;
    vm.ctrlName = 'HistorydialogCtrl';
    if(!Users.isAuthenticated()){$location.path('/');}

     vm.clickSource = clickSource;
     History.find({ filter: { where: { structureId: structureId }, "include": [{"versionsNew": {"Contents":"ContentTypes"} }] }}).$promise.then(function (results) {


       _.forEach(results, function (item) {
         item.versions = item.versionsNew;
         if (item.versions.Contents.ContentTypes.isJson === true) {
           Wraps.json2Obj(item);
         }
         if(item.versions.Contents.contentTypeId == 1 && item.versions.versiondata.charAt(0) === "{"){
           var json = angular.fromJson(item.versions.versiondata);
           item.versions.versiondata = json.chapterCopy;
         }
       });
       vm.histories = results;

    });


    vm.dialogHide = function() {
      $mdDialog.hide();
    };
    vm.dialogCancel = function() {
      $mdDialog.cancel();
    };




    vm.getFile = function(container, file){
      return apiUrl+"/containers/wraps-files%2f"+container.slice(0, -1).replace(/\//g, "%2f")+"/download/"+file;
      //return "http://wraps-files.s3-website-us-east-1.amazonaws.com/"+container+file;
    }

    vm.swapHistory= function(data) {
	   
			$mdDialog.hide(data.versiondata);
	    
	    
    };
  }
  HistorydialogCtrl.$inject = ["$mdDialog", "$scope", "apiUrl", "clickSource", "History", "structureId", "Users", "Wraps"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name library.chaptersdialog
   * @description
   *
   */
  angular
    .module('library.chaptersdialog', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('library.chaptersdialog')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('chaptersdialog', {
        url: '/chaptersdialog',
        templateUrl: 'library/chaptersdialog/chaptersdialog.tpl.html',
        controller: 'ChaptersdialogCtrl',
        controllerAs: 'chaptersdialog'
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.chaptersdialog.controller:ChaptersdialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.chaptersdialog')
    .controller('ChaptersdialogCtrl', ChaptersdialogCtrl);

  var usedChapters;

  function ChaptersdialogCtrl($localStorage, $mdDialog, $q, $scope, $timeout, apiUrl, book, Books, Contents, Containers, Events, History, Structures, Teams, Upload, Users, Versions, Wraps) {
    var vm = this;
    vm.ctrlName = 'ChaptersdialogCtrl';
    $scope.$storage = $localStorage;
    if(!Users.isAuthenticated()){$location.path('/');}


    vm.chapterTypes = Wraps.chapterTypes;

    // speed tweak to pre select products
    vm.chapterTeam = {"teamId":14,"teamName":"Products","shortName":"PROD","departmentId":8,"docOrd":6,"briefOrd":null,"itemOrd":null};

    //vm.books = Books.find({ filter: { where: { bookId: {neq: book.bookId} } } });

    vm.books = Books.find({ filter: { where: {"and":[{ bookId: {neq: book.bookId}},{"docTypeId":book.docTypeId}]},fields: {bookId: true, bookCode: true, bookName:true}}  });

    //Teams for add content owners
    vm.teams = Teams.__get__notDeleted();

    Structures.find({
      filter: {
        where: { bookId: book.bookId, "parent": null  },
        include: "versions"
      }
    }).$promise.then(function(data){
        usedChapters = _.map(data,'versions.contentId');

    });

    vm.updateBookData = Wraps.updateBookData;

    vm.dialogHide = function(data) {
      $mdDialog.hide(data);
    };
    vm.dialogCancel = function(data) {
      $mdDialog.cancel(data);
    };


    vm.getChapters = function (bookId){
      if(bookId) {
        vm.isLoading = true;
        Structures.find({
          filter: {
            where: {bookId: bookId, "parent": null},
            "include": ["versions", {"Children": {"Children": "Children"}}]
          }
        }).$promise.then(function (data) {
          angular.forEach(data, function (item) {
            item.disabled = usedChapters.indexOf(item.versions.contentId) >= 0;
            item.selected = item.disabled;

             if(item.versions.versiondata.charAt(0) === "{"){
               var json = angular.fromJson(item.versions.versiondata);
               item.versions.versiondata = json.chapterCopy;
             }
            
          });
          vm.chapters = data;

          vm.isLoading = false;
        });
      }else{
        vm.chapters = '';
      }
    }

    Wraps.getQREFs(vm.isLoading).then(function(data) {
      vm.QREFs = data;
      //console.log("vm.QREFs",vm.QREFs);
    });




    vm.openChangeDialog = function (filter) {
      var deferred = $q.defer();
      return $mdDialog.show({
        controller: 'ChangedialogCtrl',
        controllerAs: 'changedialog',
        templateUrl: 'library/changedialog/changedialog.tpl.html',
        parent: angular.element(document.body),
        locals: {filter: filter},
        bindToController: true,
        clickOutsideToClose: false
      })
          .then(function (data) {
            return data;
          }, function () {
            return false; //cancel
          });
    };



    vm.addChapters = function (chapters){
      vm.openChangeDialog().then(function(eventData){
        if(eventData) {

          vm.isLoading = true;
          _.remove(chapters, function (chapter) {
            return chapter.disabled === true;
          });
          _.remove(chapters, function (chapter) {
            return chapter.selected === false;
          });
          var defer = $q.defer();
          var promises = [];
          angular.forEach(chapters, function (chapter) {
            promises.push(
                Structures
                    .duplicateDeep({
                      "bookId": book.bookId,
                      "structureId": chapter.structureId,
                      "status": 0,
                      "approvalStatus": book.userInBook[0],
                      "parent": null,
                      "eventData": eventData
                    })
                    .$promise.then(function (response) {
                  defer.resolve();
                })
            );
          });

          $q.all(promises).then(function () {
            Books.upsert({"bookId": book.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)}).$promise.then(function () {
              vm.dialogHide(true); //true = refresh parent
              //vm.isLoading = false;
              Wraps.updateBookData = book.bookId;
            });
          });

        }
      });

    }

    
    vm.addNewChapter = function (chapterData){
      vm.openChangeDialog().then(function(eventData) {
        if (eventData) {
          vm.isLoading = true;
          //TODO add ckeditor for copy
          //console.log(chapterData);
          Contents.create({
            contentId: 0,
            contentTypeId: 1,
            contentName: chapterData.chapterData + " - Chapter",
            contentOwner: chapterData.chapterTeam.teamId
          }, function (data1) {


            // if(chapterData.chapterType.chapterTypeId===1){
            //   var chapterJson = "<h2>" + chapterData.chapterData + "</h2>\n";
            // }else if(chapterData.chapterType.chapterTypeId===3){
            //   var chapterJson = Wraps.toJson({chapterTypeId: chapterData.chapterType.chapterTypeId, chapterLinkId: chapterData.QREF.bookId, chapterCopy: "<h2>QREF</h2>\n" });
            // }else{
            //   var chapterJson = Wraps.toJson({chapterTypeId: chapterData.chapterType.chapterTypeId, chapterCopy: "<h2>" + chapterData.chapterData + "</h2>\n" });
            // }

            if(chapterData.chapterType.chapterTypeId===1){
              var chapterJson = "<h2>" + chapterData.chapterData + "</h2>\n";
            }else if(chapterData.chapterType.chapterTypeId===2){
              var chapterObj = {};

              chapterObj.chapterTypeId  = chapterData.chapterType.chapterTypeId;
              chapterObj.chapterLinkId = chapterData.chapterType.chapterLinkId;
              chapterObj.chapterCopy = "<h2>Front Cover</h2>\n";
              if(chapterData.chapterType.filesList){
                chapterObj.filesList = chapterData.chapterType.filesList;
              }
              if(chapterData.chapterType.PDFList){
                chapterObj.PDFList = chapterData.chapterType.PDFList;
              }
              var chapterJson = Wraps.toJson(chapterObj); //front cover
            }else if(chapterData.chapterType.chapterTypeId===3){
              var chapterJson = Wraps.toJson({chapterTypeId: chapterData.chapterType.chapterTypeId, chapterLinkId: chapterData.QREF.bookId, chapterCopy: "<h2>QREF</h2>\n" }); //qref
            }else if(chapterData.chapterType.chapterTypeId===4){
              var chapterJson = Wraps.toJson({chapterTypeId: chapterData.chapterType.chapterTypeId, chapterCopy: "<h2>" + chapterData.chapterData + "</h2>\n" }); //contents
            }else if(chapterData.chapterType.chapterTypeId===5){
              var chapterJson = Wraps.toJson({chapterTypeId: chapterData.chapterType.chapterTypeId, chapterCopy: "<h2>Summary of benefits and Exclusions</h2>\n" }); //summary
            }else if(chapterData.chapterType.chapterTypeId===6){
              var chapterJson = Wraps.toJson({chapterTypeId: chapterData.chapterType.chapterTypeId,  chapterCopy: "<h2>Back Cover</h2>\n" }); //back cover
            }else if(chapterData.chapterType.chapterTypeId===7) {
              var chapterJson = Wraps.toJson({ chapterTypeId: chapterData.chapterType.chapterTypeId, chapterCopy: "<h2>" + chapterData.chapterData + "</h2>\n" }); //BGHP Benefits
            }else if(chapterData.chapterType.chapterTypeId===8) {
              var chapterJson = Wraps.toJson({ chapterTypeId: chapterData.chapterType.chapterTypeId, chapterCopy: "<h2>" + chapterData.chapterData + "</h2>\n" }); //BGHP Terms
            }else if(chapterData.chapterType.chapterTypeId===9) {
              var chapterJson = Wraps.toJson({ chapterTypeId: chapterData.chapterType.chapterTypeId, chapterCopy: "<h2>" + chapterData.chapterData + "</h2>\n" }); //BGHP Exclusions
            }else{
              var chapterJson = Wraps.toJson({ chapterTypeId: chapterData.chapterType.chapterTypeId, chapterCopy: "<h2>" + chapterData.chapterData + "</h2>\n" }); //default
            }



            
            Versions.create({
              versionId: 0,
              contentId: data1.contentId,
              versiondata: chapterJson
            }, function (data2) {

              Structures.create({
                structureId: 0,
                versionId: data2.versionId,
                contentId: data1.contentId,
                bookId: book.bookId,
                "status": 0,
                approvalStatus: book.userInBook[0],
                parent: null,
                order: data2.versionId
              }, function (data3) {

                Books.upsert({"bookId": book.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                History.create({
                  "structureId": data3.structureId,
                  "versionIdOld": 0,
                  "versionIdNew": data3.versionId,
                  "historyId": 0
                }, function (historyResponse) {
                  Events.create({
                    "eventId": 0,
                    "bookId": book.bookId,
                    "structureId": data3.structureId,
                    "contentId": data1.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Added",
                    "eventData": Wraps.toJson(eventData)
                  });
                });

                vm.dialogHide(true);
                vm.isLoading = false;
                Wraps.updateBookData = book.bookId;
              });
            });
          });
        }
      });
    }


    vm.removeFile = function(array, index){
      array.splice(index, 1);
    }


    var timestamp = new Date().getTime();


    $scope.$watch('chaptersdialog.chapterFiles', function () {
      vm.upload(vm.chapterFiles,'logo').then(function(statuses){
        vm.isLoading = false;
      });
    });


    $scope.$watch('chaptersdialog.frontCoverPDF', function () {
      vm.upload(vm.frontCoverPDF,'frontCoverPDF').then(function(statuses){
        vm.isLoading = false;
      });
    });

    vm.log = '';
    //vm.filesList = [];
    vm.upload = function (files,itemType) {

      // var S3_BUCKET_URL = apiUrl+'/containers/wraps-files%2f'+itemType+'%2f'+timestamp+'/upload';
      var S3_BUCKET_URL = apiUrl+'/containers/wraps-files/upload';
      var deferred = $q.defer();

      if (files && !files.length){ //if multiple = false
        var tempFiles = [files];
        files = tempFiles;
      }

      if (files && files.length) {
        _.forEach(files, function (file) {
          if (!file.$error) {
            vm.isLoading = true;

            file.container = 'wraps-files';
            file.fileLocation = itemType+'/'+timestamp;
            file.fileName = file.name;
            file.fileSize = file.size;

            if(itemType==='logo'){
              if(!vm.chapterType.filesList){
                vm.chapterType.filesList=[];
              }
              if(tempFiles){
                vm.chapterType.filesList = [];
              }
              vm.chapterType.filesList.push(file);

            }else if(itemType==='frontCoverPDF') {

              if(!vm.chapterType.PDFList){
                vm.chapterType.PDFList=[];
              }
              if(tempFiles){
                vm.chapterType.PDFList = [];
              }
              vm.chapterType.PDFList.push(file);
            }


            //TODO add timeout to animate files
            var newName = file.fileLocation + '/' + file.name;
            Upload.rename(file, newName );
            Upload.upload({
              url: S3_BUCKET_URL,
              file: file

            }).then(function (resp) {
              console.log(resp);

              deferred.resolve();
            }, null, function (evt) {
              file.determinateValue = parseInt(100.0 *   evt.loaded / evt.total);

            });
          }
        });
      }

      return deferred.promise;
    };






    vm.selectedChapters = [];
    vm.setSelectedChapters = function (chapter){
      if(chapter.selected==true){
        vm.selectedChapters.push(chapter);
      }else{
        _.pullAt(vm.selectedChapters, _.findKey(vm.selectedChapters, 'versionId', chapter.versionId));
      }
    }

    vm.querySearch = function (query) {
      var results = query ? vm.books.filter( createFilterFor(query) ) : vm.books,
        deferred;
      return results;
    }

    /**
     * Create filter function for a query string
     */
    function createFilterFor(query) {
      return function filterFn(item) {
        return (item.bookCode.toLowerCase().indexOf(query.toLowerCase()) >= 0);
      };
    }

  }
  ChaptersdialogCtrl.$inject = ["$localStorage", "$mdDialog", "$q", "$scope", "$timeout", "apiUrl", "book", "Books", "Contents", "Containers", "Events", "History", "Structures", "Teams", "Upload", "Users", "Versions", "Wraps"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name library.emaildetailsdialog
   * @description
   *
   */
  angular
    .module('library.emaildetailsdialog', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('library.emaildetailsdialog')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('emaildetailsdialog', {
        url: '/emaildetailsdialog',
        templateUrl: 'library/emaildetailsdialog/emaildetailsdialog.tpl.html',
        controller: 'EmaildetailsdialogCtrl',
        controllerAs: 'emaildetailsdialog'
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.emaildetailsdialog.controller:EmaildetailsdialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.emaildetailsdialog')
    .controller('EmaildetailsdialogCtrl', EmaildetailsdialogCtrl);

  function EmaildetailsdialogCtrl($mdDialog, $q, $sce, $scope, change, Contents, Events, History, Roles, Structures, Users, Versions, Wraps) {
    var vm = this;
    vm.ctrlName = 'EmaildetailsdialogCtrl';
    if(!Users.isAuthenticated()){$location.path('/');}

    vm.change = change;
    vm.isObj = angular.isObject

    vm.dialogHide = function(data) {
      $mdDialog.hide(data);
    };
    vm.dialogCancel = function(data) {
      $mdDialog.cancel(data);
    };



    vm.trustAsHtml = function(string) {
      return $sce.trustAsHtml(string);
    };




    var loadEvents = function () {

      var filter = {"filter":{"where":{"eventId":change},"include": [{"relation":"Messages","Users": { "order": "eventId DESC"}}]}};

      Events.find(filter).$promise.then(function(events){


        events[0].Messages.html = events[0].Messages.html.replace(/undefined/g, "");



        events[0].Messages.response = JSON.parse(events[0].Messages.response);

        vm.events = events;
      });


    }

    loadEvents();

  }
  EmaildetailsdialogCtrl.$inject = ["$mdDialog", "$q", "$sce", "$scope", "change", "Contents", "Events", "History", "Roles", "Structures", "Users", "Versions", "Wraps"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name library.docdiffdialog
   * @description
   *
   */
  angular
    .module('library.docdiffdialog', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('library.docdiffdialog')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('docdiffdialog', {
        url: '/docdiffdialog',
        templateUrl: 'library/docdiffdialog/docdiffdialog.tpl.html',
        controller: 'DocdiffdialogCtrl',
        controllerAs: 'docdiffdialog'
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.docdiffdialog.controller:DocdiffdialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.docdiffdialog')
    .controller('DocdiffdialogCtrl', DocdiffdialogCtrl);

  function DocdiffdialogCtrl($mdDialog, $q, $sce, $scope, apiUrl, change, child, Contents, Events, History, htmldiff, Roles, Structures, Users, Versions, Wraps) {
    var vm = this;
    vm.ctrlName = 'DocdiffdialogCtrl';
    if(!Users.isAuthenticated()){$location.path('/');}

    vm.change = change;
    vm.child = child;
    vm.apiUrl = apiUrl;
    vm.hasPermissions = Wraps.hasPermissions;

    vm.dialogHide = function(data) {
      $mdDialog.hide(data);
    };
    vm.dialogCancel = function(data) {
      $mdDialog.cancel(data);
    };


    vm.rfcReasons = Wraps.rfcReasons;
    vm.rfcCategories = Wraps.rfcCategories;

    vm.book = Wraps.book;

    //vm.histories = History.find({ filter: { where: { structureId: change.structureId }, "include": [{"versions": "Contents"}] }});
    //vm.events = Events.find({ filter: { where: { structureId: change.structureId }, "include": ["Users","Contents", {"History":["versions","versionsNew"]}] }});


    var loadEvents = function () {
      Events.find({"filter":{"where":{"structureId":change.structureId},"include":"Users",order: 'eventId DESC', }}).$promise.then(function(events){
        //console.log(events);
        _.forEach(events, function (event) {
          if(event.eventData && event.eventData.charAt(0)==='{') {
            event.rfcReasons = [];
            event.categories = [];
            event.Comment = angular.fromJson(event.eventData);
            //link id's with name from datalists
            _.forEach(event.Comment.reason, function(val,key){
              event.rfcReasons.push(_.result(_.find(vm.rfcReasons, {'id': _.parseInt(val)}), 'name'));
            });
            _.forEach(event.Comment.category, function(val,key){
              event.categories.push(_.result(_.find(vm.rfcCategories, {'id': _.parseInt(val)}), 'name'));
            });
            //console.log(event.Comment.rfcReasons);

          }
        });
        vm.events = events;

      });
    }

    $scope.$on('newCommentCreated', function(event){
        // console.log('comment event received');
        loadEvents();
    });

    if(vm.change) {
      loadEvents();

      $scope.left = vm.change.versionData1;
      $scope.right = vm.change.versionData2;
      $scope.content = vm.change.versionData;

      if(vm.change.versionData1 === null && vm.change.versionData2 === null){
        vm.history = false;
        console.log();
      }else{
        vm.history = true;
      }



      var checkUndefined = function(data){
        if(angular.isUndefined(data) || data === null) {
          data = "";
        }
        return data;
      }
      
      var htmldiffUndefined = function(left, right){
        return htmldiff(checkUndefined(left),checkUndefined(right));
      }


      if(vm.change.contentTypeId >= 5){

        $scope.left = angular.fromJson($scope.left);
        $scope.right = angular.fromJson($scope.right);
        $scope.content = angular.fromJson($scope.content);

        vm.left = $scope.left;
        if(vm.left){vm.left.versions = { "Contents":{"contentTypeId" : vm.change.contentTypeId}};}
        vm.right = $scope.right;
        if(vm.right){vm.right.versions = { "Contents":{"contentTypeId" : vm.change.contentTypeId}};}
        vm.content = $scope.content;
        if(vm.content){vm.content.versions = { "Contents":{"contentTypeId" : vm.change.contentTypeId}};}

        if(vm.change.contentTypeId === 5){
          if(!$scope.left){ $scope.left={benefit:{}}; }
          if(!$scope.right){ $scope.right={benefit:{}}; }

          vm.diff = "<strong>Name:</strong><br />"+htmldiffUndefined($scope.left.benefit.name, $scope.right.benefit.name);
          vm.diff += "<br /><br /><strong>"+vm.book.levels.level1.name+":</strong><br />"+htmldiffUndefined($scope.left.benefit.level1, $scope.right.benefit.level1);
          vm.diff += "<br /><br /><strong>"+vm.book.levels.level2.name+":</strong><br />"+htmldiffUndefined($scope.left.benefit.level2, $scope.right.benefit.level2);
          vm.diff += "<br /><br /><strong>"+vm.book.levels.level3.name+":</strong><br />"+htmldiffUndefined($scope.left.benefit.level3, $scope.right.benefit.level3);
          vm.diff += "<br /><br /><strong>"+vm.book.levels.level4.name+":</strong><br />"+htmldiffUndefined($scope.left.benefit.level4, $scope.right.benefit.level4);
          vm.diff += "<br /><br /><strong>Explanation:</strong><br />"+htmldiffUndefined($scope.left.benefit.explanation, $scope.right.benefit.explanation);

        }else if(vm.change.contentTypeId === 6){
           if(!$scope.left){ $scope.left={glossary:{}}; }
            if(!$scope.right){ $scope.right={glossary:{}}; }
           vm.diff = "<strong>Term:</strong><br />"+htmldiffUndefined($scope.left.glossary.term, $scope.right.glossary.term);
           vm.diff += "<br /><br /><strong>Description:</strong><br />"+htmldiffUndefined($scope.left.glossary.description, $scope.right.glossary.description);
           vm.diff += "<br /><br /><strong>Words:</strong><br />"+htmldiffUndefined($scope.left.glossary.words, $scope.right.glossary.words);

        }else if(vm.change.contentTypeId === 7){
          if(!$scope.left){ $scope.left={exclusion:{}}; }
          if(!$scope.right){ $scope.right={exclusion:{}}; }

          vm.diff = "<strong>Name:</strong><br />"+htmldiffUndefined($scope.left.exclusion.name, $scope.right.exclusion.name);
          vm.diff += "<br /><br /><strong>Rules:</strong><br />"+htmldiffUndefined($scope.left.exclusion.rules, $scope.right.exclusion.rules);

        }else if(vm.change.contentTypeId === 8) {
          if(!$scope.left){ $scope.left={medical:{}}; }
          if(!$scope.right){ $scope.right={medical:{}}; }

          vm.diff = "<strong>Term:</strong><br />" + htmldiffUndefined($scope.left.medical.term, $scope.right.medical.term);
          vm.diff += "<br /><br /><strong>Description:</strong><br />" + htmldiffUndefined($scope.left.medical.description, $scope.right.medical.description);

          
        }else if(vm.change.contentTypeId === 11) {
          if(!$scope.left){ $scope.left={tableHeader:{}}; }
          if(!$scope.right){ $scope.right={tableHeader:{}}; }
          vm.diff = "";
          _.forEach($scope.left.tableHeader, function (item,key) {

              vm.diff += "<br /><br /><strong>Column " + (key + 1) + ":</strong><br />" + htmldiffUndefined($scope.left.tableHeader[key].name, $scope.right.tableHeader[key].name);
          });
          
        }else if(vm.change.contentTypeId === 12) {
          if(!$scope.left){ $scope.left={tableRow:{}}; }
          if(!$scope.right){ $scope.right={tableRow:{}}; }
          vm.diff = "";
          _.forEach($scope.left.tableRow, function (item,key) {

              vm.diff += "<br /><br /><strong>Column " + (key + 1) + ":</strong><br />" + htmldiffUndefined($scope.left.tableRow[key].data, $scope.right.tableRow[key].data);
          });
          
        }else if(vm.change.contentTypeId === 17) {
          if(!$scope.left){ $scope.left={benefit:{}}; }
          if(!$scope.right){ $scope.right={benefit:{}}; }

          vm.diff = "<strong>Name:</strong><br />" + htmldiffUndefined($scope.left.benefit.name, $scope.right.benefit.name);
          vm.diff += "<br /><br /><strong>Explanation:</strong><br />" + htmldiffUndefined($scope.left.benefit.explanation, $scope.right.benefit.explanation);
          vm.diff += "<br /><br /><strong>Limit:</strong><br />" + htmldiffUndefined($scope.left.benefit.limit, $scope.right.benefit.limit);

        }else if(vm.change.contentTypeId === 20) {
          if(!$scope.left){ $scope.left={exclusion:{}}; }
          if(!$scope.right){ $scope.right={exclusion:{}}; }

          vm.diff = "<strong>Name:</strong><br />" + htmldiffUndefined($scope.left.exclusion.name, $scope.right.exclusion.name);
          vm.diff += "<br /><br /><strong>Rules:</strong><br />" + htmldiffUndefined($scope.left.exclusion.rules, $scope.right.exclusion.rules);
        }


      }else{
        if(!$scope.left) {
          $scope.left = "";
        }

        //vm.diff = diff($scope.left, $scope.right);

        vm.left = {};
        vm.left.versions = { "Contents":{"contentTypeId" : vm.change.contentTypeId},"versiondata":$scope.left};

        vm.right = {};
        vm.right.versions = { "Contents":{"contentTypeId" : vm.change.contentTypeId},"versiondata":$scope.right};

        vm.content = {};
        vm.content.versions = { "Contents":{"contentTypeId" : vm.change.contentTypeId},"versiondata":$scope.content};

        vm.diff = $sce.trustAsHtml(htmldiffUndefined($scope.left, $scope.right));

      }


    }



  }
  DocdiffdialogCtrl.$inject = ["$mdDialog", "$q", "$sce", "$scope", "apiUrl", "change", "child", "Contents", "Events", "History", "htmldiff", "Roles", "Structures", "Users", "Versions", "Wraps"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name library.contentdialog
   * @description
   *
   */
  angular
    .module('library.contentdialog', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('library.contentdialog')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('contentdialog', {
        url: '/contentdialog',
        templateUrl: 'library/contentdialog/contentdialog.tpl.html',
        controller: 'ContentdialogCtrl',
        controllerAs: 'contentdialog'
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.contentdialog.controller:ContentdialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.contentdialog')
    .controller('ContentdialogCtrl', ContentdialogCtrl);

  function ContentdialogCtrl($localStorage, $mdDialog, $q, $scope, book, Books, child, Contents, ContentTypes, Events, History, Structures, Teams, Users, Versions, Wraps, WrapsBooks) {
    var vm = this;
    vm.ctrlName = 'ContentdialogCtrl';
    $scope.$storage = $localStorage;
    if(!Users.isAuthenticated()){$location.path('/');}


    vm.book = book;
    vm.child = child;

    vm.getTableWidths = Wraps.getTableWidths;

    // speed tweak to pre select products
    vm.contentOwner = 14;

    /// creates list of used ids for checkboxes
    var usedContentIds = [];
    Structures.find({
      filter: {
        where: { bookId: child.bookId, "parent": child.structureId  },
        include: "versions"
      }
    }).$promise.then(function(data){
        usedContentIds = _.map(data,'versions.contentId');
    });

    vm.thisContentId = child.versions.Contents.ContentTypes.contentTypeId;
    vm.thisChapterTypeId = child.versions.chapterTypeId;
    
    
    vm.thisParentName = child.versions.versiondata.replace(/<\/?[^>]+>/gi, '');
    //console.log(child.versions.Contents.ContentTypes.contentTypeId);


    //Teams for add content owners
    vm.teams = Teams.__get__notDeleted();

    //content types for add content types
    vm.contentTypes = ContentTypes.find({});

    vm.tableHeader = [
    {"name":"Column Name","width":20,"active":true}
    ];



    vm.removeTableHeader = function ($index) {
      vm.tableHeader.splice($index, 1);
    }

    vm.addTableHeader = function () {
      vm.tableHeader.push({"name":"Column Name","width":20,"active":true});
    }


    vm.nl2br = function(string){
      try {
        return string.replace(/\n/g, '<br>');
      }catch(err){
        //return false;
      }
    }

    vm.json2Obj = function(obj){

      try{ obj = angular.extend(obj,angular.fromJson(obj.versions.versiondata));}
      catch (err){    console.log(err.name + ': "' + err.message +  '" occurred when assigning string.'); return false;}
      return obj;

    }

    vm.getContent = function (struct){
      if(struct && struct.bookId && child.versions.contentId) {
        vm.isLoading = true;
        Structures.getChildContent({bookId: struct.bookId, "contentId": child.versions.contentId})
          .$promise.then(function (data) {
            _.forEach(data, function (item) {
              item.disabled = usedContentIds.indexOf(item.versions.contentId) >= 0;
              item.selected = item.disabled;




              if (item.versions.versiondata && item.versions.versiondata.charAt(0) === "{") {
                item = vm.json2Obj(item);
              }
            });
            vm.contentlist = data;

            vm.isLoading = false;
          },
          function(data) { //error
            vm.isLoading = false;
            vm.contentlist = [];

          });
      }else{

        vm.contentlist = [];

      }
    }



    vm.openChangeDialog = function (filter) {
      var deferred = $q.defer();
      return $mdDialog.show({
        controller: 'ChangedialogCtrl',
        controllerAs: 'changedialog',
        templateUrl: 'library/changedialog/changedialog.tpl.html',
        parent: angular.element(document.body),
        locals: {filter: filter},
        bindToController: true,
        clickOutsideToClose: true
      })
          .then(function (data) {

            //book.update(true);
            return data;
          }, function () {
            return false; //cancel
          });
    };


    vm.addContent = function (contentItems){


      vm.openChangeDialog().then(function(eventData) {
        if (eventData) {

          vm.isLoading = true;
          _.remove(contentItems, function (contentItem) {
            return contentItem.disabled === true;
          });
          _.remove(contentItems, function (contentItem) {
            return contentItem.selected === false;
          });
          var defer = $q.defer();
          var promises = [];

          angular.forEach(contentItems, function (contentItem) {
            promises.push(
                Structures
                    .duplicateDeep({
                      "bookId": child.bookId,
                      "status": 0,
                      "approvalStatus": child.approvalStatus,
                      "structureId": contentItem.structureId,
                      "parent": child.structureId,
                      "eventData": eventData,
                      "type": "pull"
                    })
                    .$promise.then(function (response) {
                  defer.resolve();
                })
            );
          });

          $q.all(promises).then(function () {
            Books.upsert({"bookId": child.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)}).$promise.then(function () {

              vm.dialogHide(true); //true = refresh parent
              WrapsBooks.updateBookById(book.bookId);
              //book.update(true);
              //vm.isLoading = false;
              Wraps.updateBookData = book.bookId;
            });
          });

        }
      });
    }



    vm.addNewContent = function (returnData){
      vm.openChangeDialog().then(function(eventData) {
        if (eventData) {

          
          vm.isLoading = true;
          Contents.create({
            contentId: 0,
            contentTypeId: returnData.contentType.contentTypeId,
            contentName: vm.thisParentName + " - " + returnData.contentType.contentTypeName,
            contentOwner: returnData.contentOwner
          }, function (data1) {
  
            Versions.create({
              versionId: 0,
              contentId: data1.contentId,
              versiondata: returnData.contentData
            }, function (data2) {
  
              Structures.create({
                structureId: 0,
                versionId: data2.versionId,
                bookId: child.bookId,
                "status": 0,
                "approvalStatus": book.userInBook[0],
                contentId: data1.contentId,
                parent: child.structureId,
                order: data2.versionId
              }, function (data3) {
  
                Books.upsert({"bookId": child.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                History.create({
                  "structureId": data3.structureId,
                  "versionIdOld": 0,
                  "versionIdNew": data3.versionId,
                  "historyId": 0
                }, function (historyResponse) {
                  Events.create({
                    "eventId": 0,
                    "bookId": child.bookId,
                    "structureId": data3.structureId,
                    "contentId": data1.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Added",
                    "eventData": Wraps.toJson(eventData)
                  });
                });
                vm.dialogHide(true); //true = refresh parent
                WrapsBooks.updateBookById(book.bookId);
                //book.update(true);
                //vm.isLoading = false;
                Wraps.updateBookData = book.bookId;
              });
            });
          });
        }
      });
    }
    
    
    
    vm.addNewBenefit = function (returnData){
      vm.openChangeDialog().then(function(eventData) {
        if (eventData) {
          vm.isLoading = true;
          var benefit = {};
          if (_.size(returnData.benefit.options) === 0) {
            delete returnData.benefit.options;
          }
          benefit.benefit = returnData.benefit;
          Contents.create({
            contentId: 0,
            contentTypeId: 5,
            contentName: vm.thisParentName + " - " + returnData.benefit.name,
            contentOwner: returnData.contentOwner
          }, function (data1) {
            Versions.create({
              versionId: 0,
              contentId: data1.contentId,
              versiondata: Wraps.toJson(benefit)
            }, function (data2) {
              Structures.create({
                structureId: 0,
                versionId: data2.versionId,
                bookId: child.bookId,
                "status": 0,
                "approvalStatus": book.userInBook[0],
                contentId: data1.contentId,
                parent: child.structureId,
                order: data2.versionId
              }, function (data3) {

                Books.upsert({"bookId": child.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                History.create({
                  "structureId": data3.structureId,
                  "versionIdOld": 0,
                  "versionIdNew": data3.versionId,
                  "historyId": 0
                }, function (historyResponse) {
                  Events.create({
                    "eventId": 0,
                    "bookId": child.bookId,
                    "structureId": data3.structureId,
                    "contentId": data1.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Added",
                    "eventData": Wraps.toJson(eventData)
                  });
                });

                vm.dialogHide(true); //true = refresh parent
                WrapsBooks.updateBookById(book.bookId);
                //book.update(true);
                //vm.isLoading = false;
                Wraps.updateBookData = book.bookId;
              });
            });
          });

        }
      });
    }


    vm.addNewGlossary = function (returnData){
      vm.openChangeDialog().then(function(eventData) {
        if (eventData) {
          vm.isLoading = true;
          var glossary = {};
          glossary.glossary = returnData.glossary;
          Contents.create({
            contentId: 0,
            contentTypeId: 6,
            contentName: vm.thisParentName + " - " + returnData.glossary.term,
            contentOwner: returnData.contentOwner
          }, function (data1) {
            Versions.create({
              versionId: 0,
              contentId: data1.contentId,
              versiondata: Wraps.toJson(glossary)
            }, function (data2) {
              Structures.create({
                structureId: 0,
                versionId: data2.versionId,
                bookId: child.bookId,
                "status": 0,
                "approvalStatus": book.userInBook[0],
                contentId: data1.contentId,
                parent: child.structureId,
                order: data2.versionId
              }, function (data3) {

                Books.upsert({"bookId": child.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                History.create({
                  "structureId": data3.structureId,
                  "versionIdOld": 0,
                  "versionIdNew": data3.versionId,
                  "historyId": 0
                }, function (historyResponse) {
                  Events.create({
                    "eventId": 0,
                    "bookId": child.bookId,
                    "structureId": data3.structureId,
                    "contentId": data1.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Added",
                    "eventData": Wraps.toJson(eventData)
                  });
                });

                vm.dialogHide(true); //true = refresh parent
                WrapsBooks.updateBookById(book.bookId);
                //book.update(true);
                //vm.isLoading = false;
                Wraps.updateBookData = book.bookId;
              });
            });
          });
        }
      });

    }

    vm.addNewExclusion = function (returnData){
      vm.openChangeDialog().then(function(eventData) {
        if (eventData) {
          vm.isLoading = true;
          var exclusion = {};
          exclusion.exclusion = returnData.exclusion;
          Contents.create({
            contentId: 0,
            contentTypeId: 7,
            contentName: vm.thisParentName + " - " + returnData.exclusion.name,
            contentOwner: returnData.contentOwner
          }, function (data1) {
            Versions.create({
              versionId: 0,
              contentId: data1.contentId,
              versiondata: Wraps.toJson(exclusion)
            }, function (data2) {
              Structures.create({
                structureId: 0,
                versionId: data2.versionId,
                bookId: child.bookId,
                "status": 0,
                "approvalStatus": book.userInBook[0],
                contentId: data1.contentId,
                parent: child.structureId,
                order: data2.versionId
              }, function (data3) {

                Books.upsert({"bookId": child.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                History.create({
                  "structureId": data3.structureId,
                  "versionIdOld": 0,
                  "versionIdNew": data3.versionId,
                  "historyId": 0
                }, function (historyResponse) {
                  Events.create({
                    "eventId": 0,
                    "bookId": child.bookId,
                    "structureId": data3.structureId,
                    "contentId": data1.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Added",
                    "eventData": Wraps.toJson(eventData)
                  });
                });

                vm.dialogHide(true); //true = refresh parent
                WrapsBooks.updateBookById(book.bookId);
                //book.update(true);
                //vm.isLoading = false;
                Wraps.updateBookData = book.bookId;
              });
            });
          });
        }
      });

    }

    vm.addNewMedical = function (returnData){
      vm.openChangeDialog().then(function(eventData) {
        if (eventData) {
          vm.isLoading = true;
          var medical = {};
          medical.medical = returnData.medical;
          Contents.create({
            contentId: 0,
            contentTypeId: 8,
            contentName: vm.thisParentName + " - " + returnData.medical.term,
            contentOwner: returnData.contentOwner
          }, function (data1) {
            Versions.create({
              versionId: 0,
              contentId: data1.contentId,
              versiondata: Wraps.toJson(medical)
            }, function (data2) {
              Structures.create({
                structureId: 0,
                versionId: data2.versionId,
                bookId: child.bookId,
                "status": 0,
                "approvalStatus": book.userInBook[0],
                contentId: data1.contentId,
                parent: child.structureId,
                order: data2.versionId
              }, function (data3) {

                Books.upsert({"bookId": child.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                History.create({
                  "structureId": data3.structureId,
                  "versionIdOld": 0,
                  "versionIdNew": data3.versionId,
                  "historyId": 0
                }, function (historyResponse) {
                  Events.create({
                    "eventId": 0,
                    "bookId": child.bookId,
                    "structureId": data3.structureId,
                    "contentId": data1.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Added",
                    "eventData": Wraps.toJson(eventData)
                  });
                });

                vm.dialogHide(true); //true = refresh parent
                WrapsBooks.updateBookById(book.bookId);
                //book.update(true);
                //vm.isLoading = false;
                Wraps.updateBookData = book.bookId;
              });
            });
          });
        }
      });

    }


    vm.saveTableHeader = function (returnData){
      vm.openChangeDialog().then(function(eventData) {
        if (eventData) {
          vm.isLoading = true;
          var tableHeader = {};
          tableHeader.tableHeader = returnData.tableHeader;
          _.forEach(tableHeader.tableHeader, function (item, index) {
            item.index = index;
          });
          Contents.create({
            contentId: 0,
            contentTypeId: 11,
            contentName: vm.thisParentName + " - Table Header",
            contentOwner: returnData.contentOwner
          }, function (data1) {
            Versions.create({
              versionId: 0,
              contentId: data1.contentId,
              versiondata: Wraps.toJson(tableHeader)
            }, function (data2) {
              Structures.create({
                structureId: 0,
                versionId: data2.versionId,
                bookId: child.bookId,
                "status": 0,
                "approvalStatus": book.userInBook[0],
                contentId: data1.contentId,
                parent: child.structureId,
                order: data2.versionId
              }, function (data3) {

                Books.upsert({"bookId": child.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                History.create({
                  "structureId": data3.structureId,
                  "versionIdOld": 0,
                  "versionIdNew": data3.versionId,
                  "historyId": 0
                }, function (historyResponse) {
                  Events.create({
                    "eventId": 0,
                    "bookId": child.bookId,
                    "structureId": data3.structureId,
                    "contentId": data1.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Added",
                    "eventData": Wraps.toJson(eventData)
                  });
                });

                vm.dialogHide(true); //true = refresh parent
                WrapsBooks.updateBookById(book.bookId);
                //book.update(true);
                //vm.isLoading = false;
                Wraps.updateBookData = book.bookId;
              });
            });
          });
        }
      });
    }

    vm.saveTableRow = function (returnData){
      vm.openChangeDialog().then(function(eventData) {
        if (eventData) {
          vm.isLoading = true;
          var tableRow = {};
          tableRow.tableRow = [];
          //tableRow.tableRow = returnData.tableRow;
          _.forEach(returnData.tableRow, function (item, index) {
            item.index = index;

            tableRow.tableRow.push(item);
          });
          Contents.create({
            contentId: 0,
            contentTypeId: 12,
            contentName: "Table Header - Table Row",
            contentOwner: returnData.contentOwner
          }, function (data1) {
            Versions.create({
              versionId: 0,
              contentId: data1.contentId,
              versiondata: Wraps.toJson(tableRow)
            }, function (data2) {
              Structures.create({
                structureId: 0,
                versionId: data2.versionId,
                bookId: child.bookId,
                "status": 0,
                "approvalStatus": book.userInBook[0],
                contentId: data1.contentId,
                parent: child.structureId,
                order: data2.versionId
              }, function (data3) {

                Books.upsert({"bookId": child.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                History.create({
                  "structureId": data3.structureId,
                  "versionIdOld": 0,
                  "versionIdNew": data3.versionId,
                  "historyId": 0
                }, function (historyResponse) {
                  Events.create({
                    "eventId": 0,
                    "bookId": child.bookId,
                    "structureId": data3.structureId,
                    "contentId": data1.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Added",
                    "eventData": Wraps.toJson(eventData)
                  });
                });

                vm.dialogHide(true); //true = refresh parent
                WrapsBooks.updateBookById(book.bookId);
                //book.update(true);
                //vm.isLoading = false;
                Wraps.updateBookData = book.bookId;
              });
            });
          });
        }
      });
    }



    vm.saveBGHP = function (returnData, sectionType){

      vm.isLoading = true;
      var contentTypeId;
      var contentTypeName;
      var versiondata;

      switch(sectionType) {
        case 'section':
          contentTypeId = 14;
          contentTypeName = "BGHP Section";
          versiondata = returnData.contentData;
          break;
        case 'subSection':
          contentTypeId = 15;
          contentTypeName = "BGHP Sub Section";
          versiondata = returnData.contentData;
          break;
        case 'intro':
          contentTypeId = 16;
          contentTypeName = "BGHP Section Intro";
          versiondata = returnData.contentData;
          break;
        case 'benefit':
          contentTypeId = 17;
          contentTypeName = "BGHP Benefit";
          var benefit = {};
          if (_.size(returnData.benefit.options) === 0) {
            delete returnData.benefit.options;
          }
          benefit.benefit = returnData.benefit;
          versiondata = Wraps.toJson(benefit);
          break;
        case 'termSection':
          contentTypeId = 18;
          contentTypeName = "BGHP Term Section";
          versiondata = returnData.contentData;
          break;
        case 'term':
          contentTypeId = 19;
          contentTypeName = "BGHP Term";
          versiondata = returnData.contentData;
          break;
        case 'exclusion':
          contentTypeId = 20;
          contentTypeName = "BGHP Exclusion";
          var exclusion = {};
          if (_.size(returnData.exclusion.options) === 0) {
            delete returnData.exclusion.options;
          }
          exclusion.exclusion = returnData.exclusion;
          versiondata = Wraps.toJson(exclusion);
          break;
        case 'exclusionSection':
          contentTypeId = 21;
          contentTypeName = "BGHP Exclusion Section";
          versiondata = returnData.contentData;
          break;
      }


      vm.openChangeDialog().then(function(eventData) {
        if (eventData) {


          vm.isLoading = true;
          Contents.create({
            contentId: 0,
            contentTypeId: contentTypeId,
            contentName: vm.thisParentName + " - " + contentTypeName,
            contentOwner: returnData.contentOwner
          }, function (data1) {

            Versions.create({
              versionId: 0,
              contentId: data1.contentId,
              versiondata: versiondata
            }, function (data2) {

              Structures.create({
                structureId: 0,
                versionId: data2.versionId,
                bookId: child.bookId,
                "status": 0,
                "approvalStatus": book.userInBook[0],
                contentId: data1.contentId,
                parent: child.structureId,
                order: data2.versionId
              }, function (data3) {

                Books.upsert({"bookId": child.bookId, "lastUpdated": Date.now()});
                History.create({
                  "structureId": data3.structureId,
                  "versionIdOld": 0,
                  "versionIdNew": data3.versionId,
                  "historyId": 0
                }, function (historyResponse) {
                  Events.create({
                    "eventId": 0,
                    "bookId": child.bookId,
                    "structureId": data3.structureId,
                    "contentId": data1.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Added",
                    "eventData": Wraps.toJson(eventData)
                  });
                });
                vm.dialogHide(true); //true = refresh parent
                WrapsBooks.updateBookById(book.bookId);
                //book.update(true);
                //vm.isLoading = false;
                Wraps.updateBookData = book.bookId;
              });
            });
          });
        }
      });
    }




    vm.dialogHide = function(data) {
      $mdDialog.hide(data);
    };
    vm.dialogCancel = function(data) {
      $mdDialog.cancel(data);
    };



//TODO fix bug that allows user to delete all text and reset tag to P
//TODO  nice to have - save content as temp on switch
 vm.changeEditor = function(cId){

   var content1 = "<h3>Section text goes here</h3>";
   var content2 = "<h4>Subsection text goes here</h4>";
   var content3 = "<p>Copy text goes here</p>";

   switch(cId) {
     case 2:
       vm.contentData = content1;
       break;
     case 3:
       vm.contentData = content2;
       break;
     case 4:
       vm.contentData = content3;
       break;
     default:
       vm.contentData = "";
   }
 }


//TODO look at why the editor only works once you have clicked in editor
    // CKEditor options.
    vm.options = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      removeButtons: 'Subscript,Superscript',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools',bottom: 'ckfoot'},
      toolbar: [
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
        { name: 'paragraph', items: [  'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',] },
        { name: 'wrapsplugins', items: [  'Highlight','UnBold' ] },
        { name: 'format', items: [  'RemoveFormat' ] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };

    vm.options2 = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      enterMode: CKEDITOR.ENTER_BR,
      autoParagraph: false,
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools',bottom: 'ckfoot'},
      toolbar: [
        { name: 'wrapsplugins', items: [  'Highlight'] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };

    vm.options3 = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      removeButtons: 'Subscript,Superscript',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools2',bottom: 'ckfoot2'},
      toolbar: [
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
        { name: 'paragraph', items: [  'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',] },
        { name: 'wrapsplugins', items: [  'Highlight','UnBold' ] },
        { name: 'format', items: [  'RemoveFormat' ] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };


    vm.options4 = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      removeButtons: 'Subscript,Superscript',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools3',bottom: 'ckfoot3'},
      toolbar: [
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
        { name: 'paragraph', items: [  'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',] },
        { name: 'wrapsplugins', items: [  'Highlight','UnBold' ] },
        { name: 'format', items: [  'RemoveFormat' ] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };

    vm.options5 = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      removeButtons: 'Subscript,Superscript',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools5',bottom: 'ckfoot5'},
      toolbar: [
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
        { name: 'paragraph', items: [  'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',] },
        { name: 'wrapsplugins', items: [  'Highlight','UnBold' ] },
        { name: 'format', items: [  'RemoveFormat' ] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };
    vm.options6 = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      removeButtons: 'Subscript,Superscript',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools6',bottom: 'ckfoot6'},
      toolbar: [
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
        { name: 'paragraph', items: [  'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',] },
        { name: 'wrapsplugins', items: [  'Highlight','UnBold' ] },
        { name: 'format', items: [  'RemoveFormat' ] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };
    vm.options7 = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      enterMode: CKEDITOR.ENTER_BR,
      autoParagraph: false,
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools7',bottom: 'ckfoot7'},
      toolbar: [
        { name: 'wrapsplugins', items: [  'Highlight'] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };


    // vm.updateOption = function(benfit,product){
    //   if(!benfit.option[product]){
    //     delete benfit.options[product];
    //     //benfit[product] = vm.tempData.benefit[product];
    //   }
    // }
    //
    // vm.updateOptions = function(benfit,product){
    //   if(benfit.options[product]==="Linked"){
    //     benfit[product] = "";
    //   }else if(benfit.options[product]==="Not covered"){
    //
    //     benfit[product] = "Not covered";
    //   }else if(benfit.options[product]==="Paid in full"){
    //
    //     benfit[product] = "Paid in full";
    //   }
    // }
    // vm.updateLinked = function(benfit,product){
    //   if(!benfit.options[product]){
    //     delete benfit.options[product];
    //     //benfit[product] = vm.tempData.benefit[product];
    //   }else{
    //     benfit[product] = "";
    //   }
    // }


    vm.disabledProducts = {};
    vm.updateOption = function(benfit,product){

      if(benfit.option) {
        if (!benfit.option[product]) {
          delete benfit.options[product];
          //benfit[product] = vm.tempData.benefit[product];
          vm.disabledProducts[product] = false;
        }
      }
    }

    vm.updateOptions = function(benfit,product){
      if(benfit.options[product]==="Linked"){
        benfit[product] = "";
        vm.disabledProducts[product] = true;
      }else if(benfit.options[product]==="Not covered"){

        benfit[product] = "Not covered";
        vm.disabledProducts[product] = true;
      }else if(benfit.options[product]==="Paid in full"){

        benfit[product] = "Paid in full";
        vm.disabledProducts[product] = true;
      }else if(benfit.options[product]==="Optional") {
        //benfit[product] = vm.tempData.benefit[product];
        vm.disabledProducts[product] = false;
      }else if(benfit.options[product]==="Show in summary") {
        //benfit[product] = vm.tempData.benefit[product];
        vm.disabledProducts[product] = false;
      }
    }
    vm.updateLinked = function(benfit,product){
      if(!benfit.options[product]){
        delete benfit.options[product];
        //benfit[product] = vm.tempData.benefit[product];
      }else{
        benfit[product] = "";
      }
    }
    
    
    
    
    
    


    vm.selectedContent = [];
    vm.setselectedContent = function (chapter){
      if(chapter.selected==true){
        vm.selectedContent.push(chapter);
      }else{
        _.pullAt(vm.selectedContent, _.findKey(vm.selectedContent, 'versionId', chapter.versionId));
      }
    }




    //for autocomplete
    vm.books = Books.find({ filter: { where: {"and":[{ bookId: {neq: book.bookId}},{"docTypeId":book.docTypeId}]},fields: {bookId: true, bookCode: true, bookName:true}}  });

    vm.querySearch = function (query) {
      var results = query ? vm.books.filter( createFilterFor(query) ) : vm.books,
        deferred;
      return results;
    }

    function createFilterFor(query) {
      return function filterFn(item) {
        return (item.bookCode.toLowerCase().indexOf(query.toLowerCase()) >= 0);
      };
    }


  }
  ContentdialogCtrl.$inject = ["$localStorage", "$mdDialog", "$q", "$scope", "book", "Books", "child", "Contents", "ContentTypes", "Events", "History", "Structures", "Teams", "Users", "Versions", "Wraps", "WrapsBooks"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name library.changedialog
   * @description
   *
   */
  angular
    .module('library.changedialog', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('library.changedialog')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('changedialog', {
        url: '/changedialog',
        templateUrl: 'library/changedialog/changedialog.tpl.html',
        controller: 'ChangedialogCtrl',
        controllerAs: 'changedialog'
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.changedialog.controller:ChangedialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.changedialog')
    .controller('ChangedialogCtrl', ChangedialogCtrl);

  function ChangedialogCtrl($localStorage, $mdDialog, $scope, filter, Wraps) {
    var vm = this;
    vm.ctrlName = 'ChangedialogCtrl';

    $scope.$storage = $localStorage;

    vm.rfcReasons = Wraps.rfcReasons;

    if (($scope.$storage.user.roleId != 5 && $scope.$storage.user.roleId != 1) ) {
      vm.rfcReasons = _.filter(vm.rfcReasons, function (reason) { return reason.id !== 48; });
    }

    if(angular.isUndefined(filter)){vm.filter = 'rfc';}else{
      var splitFilt = filter.split('-')
      if(splitFilt[1] && splitFilt[1] != '1'){
          vm.rfcReasons = _.filter(vm.rfcReasons, function (reason) { return reason.id !== 48; });
      }
      if(splitFilt[0] == "resetToBrief" || splitFilt[0] == "resetToMCO") {
          vm.noCheckbox = true;
          vm.filter = 'rfc';
      }else{
          vm.filter = splitFilt[0];
      }

    }

      //console.log(vm.filter);


    vm.getRFC = function(){
      return angular.copy($scope.$storage.lastRFC);
    }

    vm.dialogHide = function(data) {
      vm.isLoading = true;
      $scope.$storage.lastRFC = angular.copy(data);
      $mdDialog.hide(data);
    };

    vm.dialogCancel = function(data) {
      $mdDialog.cancel(data);
    };
  }
  ChangedialogCtrl.$inject = ["$localStorage", "$mdDialog", "$scope", "filter", "Wraps"];
}());
(function () {
  'use strict';

  /* @ngdoc object
   * @name library.approvedialog
   * @description
   *
   */
  angular
    .module('library.approvedialog', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('library.approvedialog')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('approvedialog', {
        url: '/approvedialog',
        templateUrl: 'library/approvedialog/approvedialog.tpl.html',
        controller: 'ApprovedialogCtrl',
        controllerAs: 'approvedialog'
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.approvedialog.controller:ApprovedialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.approvedialog')
    .controller('ApprovedialogCtrl', ApprovedialogCtrl);

  function ApprovedialogCtrl(
	$filter,
	$localStorage, 
	$location, 
	$mdDialog, 
	$q, 
	$scope, 
	$timeout,  
	apiUrl, 
	book, 
	Books,
	child, 
	Containers, 
	Contents, 
	Events, 
	History, 
	Messages,
    ProductIds,
	needFile, 
	pdfUrl,  
	Structures, 
	system,  
	Upload, 
	Users, 
	Versions, 
	Wraps,
  Notifications,
  WrapsBooks
	) {
    var vm = this;
    vm.ctrlName = 'ApprovedialogCtrl';

    vm.action = "Approve";

    $scope.$storage = $localStorage;


    vm.safeFileList = Wraps.safeFileList;
    
    vm.book.bookCode = book.bookCode;
    
    vm.filenameInCodeCheck = WrapsBooks.filenameInCodeCheck;


    if(!Users.isAuthenticated()){$location.path('/');}

    vm.dialogHide = function(data) {
      $mdDialog.hide(data);
    };
    vm.dialogCancel = function() {
      $mdDialog.cancel();
    };


    vm.nl2br = function(string){
      try {
        return string.replace(/\n/g, '<br>');
      }catch(err){
        //return false;
      }
    }

    vm.json2Obj = function(obj){

      try{ obj = angular.extend(obj,angular.fromJson(obj.versions.versiondata));}
      catch (err){    console.log(err.name + ': "' + err.message +  '" occurred when assigning string.'); return false;}
      console.log(obj.benefit);
      return obj;

    }


    vm.isFulfilment = false;
    vm.uploadMandatory = false;


    if(book.currentWorkflow.role == 9 || book.currentWorkflow.role == 10 || book.currentWorkflow.role == 13 ) {

      vm.uploadMandatory = true;
    }
    if (book.currentWorkflow.role === 6 && (book.currentWorkflow.content === 'artwork' || book.currentWorkflow.content === 'final') ||
        book.currentWorkflow.content === 'brief' && (book.currentWorkflow.role === 2 || book.currentWorkflow.role === 1)) {
        vm.action = 'Submit';
    }


    //init publishing roles
    if(book.currentWorkflow.role == 11 || book.currentWorkflow.role == 12 || book.currentWorkflow.role == 13 || book.currentWorkflow.role == 14 || book.currentWorkflow.role == 15){
      vm.isPublishing = true;
      //vm.publishTypes = [{name:"test 1"},{name:"test 2"},{name:"test 3"}];
      vm.publishTypes = [];
      if(book.currentWorkflow.role == 11){
        _.forEach(book.production, function(value,key) {
          vm.publishTypes.push(_.find(Wraps.publishTypes, { 'id': _.toInteger(key) }));
        });
        //console.log(vm.publishTypes);
      }else if(book.currentWorkflow.role == 12){
        vm.isFulfilment = true;
        _.forEach(book.fulfilment, function(value,key) {
          vm.publishTypes.push(_.find(Wraps.publishTypes, { 'id': _.toInteger(key) }));
        });
        //console.log(vm.publishTypes);
      }else if(book.currentWorkflow.role == 13){
        _.forEach(book.inSitu, function(value,key) {
          vm.publishTypes.push(_.find(Wraps.publishTypes, { 'id': _.toInteger(key) }));
        });
        //console.log(vm.publishTypes);
      }else if(book.currentWorkflow.role == 14){
        _.forEach(book.digital, function(value,key) {
          vm.publishTypes.push(_.find(Wraps.publishTypes, { 'id': _.toInteger(key) }));
        });
        //console.log(vm.publishTypes);
      }else if(book.currentWorkflow.role == 15){
        _.forEach(book.support, function(value,key) {
          vm.publishTypes.push(_.find(Wraps.publishTypes, { 'id': _.toInteger(key) }));
        });
        //console.log(vm.publishTypes);
      }

      vm.hasChildren  =  false;
    }
    else if((child.Children && child.Children.length > 0) && (book.approvalStatus === child.approvalStatus && book.status === child.status)){vm.hasChildren  =  true;}
    else{vm.hasChildren  =  false;}
    

    if(child ==="all"){vm.showApproveAll  =  true;}
    else{vm.showApproveAll  =  false;}

    var updateList = {structureIdList:[]};
    
    $scope.$watch('approvedialog.checkboxes', function (newCheckboxes, oldVal) {
      //console.log(child);
      if(child ==="all"){var thisApprovalStatus = book.approvalStatus; }else{var thisApprovalStatus = child.approvalStatus;}
      if (book.approvalList[thisApprovalStatus] && (book.approvalList[thisApprovalStatus].role == 9 || book.approvalList[thisApprovalStatus].role == 10 || book.approvalList[thisApprovalStatus].role == 11 || book.approvalList[thisApprovalStatus].role == 12 || book.approvalList[thisApprovalStatus].role == 13) || book.currentWorkflow.role == 14 || book.currentWorkflow.role == 15 ){
        if (newCheckboxes) {
          //console.log('checkboxes', newCheckboxes);
          var tempBook = angular.copy(book);
          var tempChild = angular.copy(child);
          updateList = {structureIdList: []};


          if (newCheckboxes.this) {
            setChildStatuses(tempChild, tempBook, tempBook.approvalStatus, tempBook.status);
            //console.log('tempChild', tempChild);
          }
          if (newCheckboxes.all) {
            getAll(tempChild, tempBook, tempBook.approvalStatus, tempBook.status);
          }
          if (newCheckboxes.allOthers) {
            getAll(tempBook, tempBook, tempBook.approvalStatus, tempBook.status);
          }
          //console.log('updateList', updateList);

          vm.failedCount = 0;

          checkAll(tempBook, tempBook, updateList);
          //console.log('vm.failedCount', vm.failedCount);

          if (vm.failedCount === 0) {
            needFile = true;
            vm.needFile = true;
          } else {
            needFile = false;
            vm.needFile = false;
          }
        }
      }
    }, true);


    vm.approveItems = function(checkboxes,needFile){
      vm.isApproving = true;
      updateList = {structureIdList:[]};
      if(checkboxes) {

        if (checkboxes.all) {
          getAll(child, book, book.approvalStatus, book.status);
        }
        if (checkboxes.this) {
          setChildStatuses(child, book, book.approvalStatus, book.status);
        }
        if (checkboxes.allOthers) {
          getAll(book, book, book.approvalStatus, book.status);
        }

        if (updateList.structureIdList.length > 0) {
          book.isLoading = true;
          updateStructuresAndEvents(book.bookId, updateList).then(function (response) {

            //console.log("updateStructuresAndEvents response",response);
            //console.log("updateStructuresAndEvents book",book);
            //console.log(vm.filesList);
            if (vm.filesList.length >= 1 || vm.message) {

              Events.create({
                "eventId": 0,
                "bookId": book.bookId,
                "userId": $scope.$storage.user.id,
                "eventType": "Approve Files",
                "eventData": Wraps.toJson({"message": vm.message, "filesList": vm.filesList})
              });
              //console.log("file done!");

            }
              WrapsBooks.setBookStatuses(book).then(function (emailAction) {
                book.isLoading = false;
              $mdDialog.hide();
            });
          });
        } else {
          $mdDialog.hide();
        }
      }
      $mdDialog.hide();





    }

   // {"artwork":{
    // "description":"<p>Here is the last file1234</p>\n","filesList":
    // [{"container":"wraps-files",
    // "fileLocation":"artwork/1493119101051",
    // "fileName":"Stone_floor_01.jpg",
    // "fileSize":4838115,
    // "ngfName":"artwork/1493119101051/Stone_floor_01.jpg",
    // "determinateValue":100,"hovered":false}]
    // }}










    var getAll = function (parent, thisBook,approvalStatus,status) {
      _.forEach(parent.Children, function (item) {
        if(approvalStatus === item.approvalStatus && status === item.status) {
          setChildStatuses(item, thisBook, approvalStatus, status);
        }
        getAll(item, thisBook, approvalStatus, status);
      });
    }

    var checkAll = function (parent, thisBook, thisUpdatelist) {
      _.forEach(parent.Children, function (item) {
        if(thisBook.approvalList[thisBook.approvalStatus] && _.indexOf(thisUpdatelist.structureIdList ,item.structureId)<0 && !(item.approvalStatus >= thisUpdatelist.approvalStatus && item.status >= thisUpdatelist.status) && thisBook.approvalList[thisBook.approvalStatus].content === thisBook.approvalList[item.approvalStatus].content ){
          vm.failedCount++;
        }
        checkAll(item, thisBook, thisUpdatelist);
      });
    }



    var updateStructuresAndEvents = function(bookId, thisUpdateList) {
      var deferred = $q.defer();
      Structures.updateStructuresAndEvents(
          {
            "bookId": bookId,
            "status": thisUpdateList.status,
            "approvalStatus": thisUpdateList.approvalStatus,
            "eventType": "Approved",
            "eventData": "Content Approved - Status:" + thisUpdateList.status + "\nApprover:" + thisUpdateList.approvalStatus,
            "userId": $scope.$storage.user.id,
            "structureIdArray": thisUpdateList.structureIdList
          }
      ).$promise.then(function (response) {
        deferred.resolve(response);

      });
      return deferred.promise;
    }




    var setChildStatuses = function(thisChild, thisBook,approvalStatus,status){
      if(thisBook.approvalList[thisChild.approvalStatus]) {
          var type = thisBook.approvalList[thisChild.approvalStatus].content;
      }

      // console.log("type",type);
      // console.log("type2",thisBook.approvalList[thisChild.approvalStatus].content);
      // console.log("thisChild.approvalStatus",thisChild.approvalStatus);
      // console.log("thisChild.approvalStatus === _.findLastIndex(thisBook.approvalList,{'content':type})",thisChild.approvalStatus === _.findLastIndex(thisBook.approvalList,{'content':type}));

      if(type && thisChild.approvalStatus === _.findLastIndex(thisBook.approvalList,{'content':type})){  //child approval is end of TYPE workflow
        thisChild.status = 1; //set child to ready
      }else{
        thisChild.approvalStatus++; //move child up
      }
      updateList.status = thisChild.status;
      updateList.approvalStatus = thisChild.approvalStatus;
      updateList.structureIdList.push(thisChild.structureId);
      return true;


    }

    var productIdsArray = [];
    _.forEach(book.productIdsSelectedArray, function (toggled, id) {
      if(toggled === true) {
        productIdsArray.push(id);
      }
    })

    ProductIds.find({'filter':{'where':{'productId':{'inq':productIdsArray}}, "include": ["Insurers"]}}, function (results) {
      vm.selectedProductIds = results;
    });

    
    if(book.currentWorkflow.content == "artwork" || book.currentWorkflow.content == "final"){
      console.log("book.currentWorkflow.content",book.currentWorkflow.content);
      vm.beforeList = $filter('filter')(book.Children,{versions: {Contents: {contentTypeId: 10}}})[0] ? $filter('filter')(book.Children,{versions: {Contents: {contentTypeId: 10}}})[0].artwork.filesList : [];
      vm.afterList = $filter('filter')(book.Children,{versions: {Contents: {contentTypeId: 13}}})[0] ? $filter('filter')(book.Children,{versions: {Contents: {contentTypeId: 13}}})[0].artwork.filesList : [];
    }

    






    vm.removeFile = function(array, index){
      array.splice(index, 1);
    }

    var timestamp = new Date().getTime();
    var S3_BUCKET_URL = apiUrl+'/Containers/wraps-files/upload';
    var itemType = "approveFiles";

    $scope.$watch('approvedialog.files', function (files) {
      //console.log('upload watch',test);
      vm.upload(files).then(function(statuses){
        vm.isLoading = false;
      });
    });

    vm.log = '';

    vm.upload = function (files) {
      if(!vm.filesList){vm.filesList = [];}
      var deferred = $q.defer();

      if (files && files.length) {
        _.forEach(files, function (file) {
          if (!file.$error) {
            vm.isLoading = true;
            //console.log(file);

            file.container = 'wraps-files';
            file.fileLocation = itemType+'/'+timestamp;
            file.fileName = file.name;
            file.fileSize = file.size;
            vm.filesList.push(file);


            //TODO add timeout to animate files

            var newName = file.fileLocation + '/' + file.name;
            Upload.rename(file, newName );
            Upload.upload({
              url: S3_BUCKET_URL,
              file: file
            }).then(function (resp) {
                file.loaded = true;

                deferred.resolve();
              },
              null,
              function (evt) {
                file.determinateValue = parseInt(100.0 *   evt.loaded / evt.total);
                //TODO get this working
                //console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '% file :'+ evt);
              });


          }
        });
      }
      return deferred.promise;
    };


    vm.hasChecks = function(checks){
      return !(_.indexOf(_.values(checks), true)>=0);
    }





  }
  ApprovedialogCtrl.$inject = ["$filter", "$localStorage", "$location", "$mdDialog", "$q", "$scope", "$timeout", "apiUrl", "book", "Books", "child", "Containers", "Contents", "Events", "History", "Messages", "ProductIds", "needFile", "pdfUrl", "Structures", "system", "Upload", "Users", "Versions", "Wraps", "Notifications", "WrapsBooks"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name library.bottomsheet
   * @description
   *
   */
  angular
    .module('library.bottomsheet', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('library.bottomsheet')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('bottomsheet', {
        url: '/bottomsheet',
        templateUrl: 'library/bottomsheet/bottomsheet.tpl.html',
        controller: 'BottomsheetCtrl',
        controllerAs: 'bottomsheet'
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.bottomsheet.controller:BottomsheetCtrl
   *
   * @description
   *
   */
  angular
    .module('library.bottomsheet')
    .controller('BottomsheetCtrl', BottomsheetCtrl);

  function BottomsheetCtrl($localStorage, $mdBottomSheet, $mdDialog, $q, $scope, $timeout, $interval, $filter, apiUrl, book, Books, Containers, Events, History, item, Structures, Upload, Users, Versions, Wraps, WrapsBooks) {
    var vm = this;
    vm.ctrlName = 'BottomsheetCtrl';

    $scope.$storage = $localStorage;
    if(!Users.isAuthenticated()){$location.path('/');}


    //vm.safeFileList = Wraps.safeFileList;

    
    vm.bottomSheetData = item;
    vm.tempData = angular.copy(item);

    vm.book = book;

    vm.hideBottomSheet = function () {
      $mdBottomSheet.cancel();
    }


    vm.nl2br = Wraps.nl2br;
    vm.json2Obj = Wraps.json2Obj;

    vm.getTableWidths = Wraps.getTableWidths;


    vm.filenameInCodeCheck = WrapsBooks.filenameInCodeCheck;


    if(item.versions.Contents.contentTypeId === 12) {
      Wraps.attachTableHeader(vm.bottomSheetData).then(function (results) {
        vm.bottomSheetData.tableHeader = results;
      });

    }

    vm.chapterTypes = Wraps.chapterTypes;

    vm.compareObjects = function(obj1,obj2){
		return  angular.equals(obj1,obj2);
	  }


    vm.openChangeDialog = function (filter) {
      var deferred = $q.defer();
      if($scope.$storage.user.roleId === 6){
        return $q.resolve( {"message": "Ammendment made"} );
      }
      else {
        return $mdDialog.show({
          controller: 'ChangedialogCtrl',
          controllerAs: 'changedialog',
          templateUrl: 'library/changedialog/changedialog.tpl.html',
          parent: angular.element(document.body),
          locals: {filter: filter},
          bindToController: true,
          clickOutsideToClose: true
        })
          .then(function (data) {
            return data;
          }, function () {
            return false; //cancel
          });
      }
    };

    Wraps.getQREFs(vm.isLoading).then(function(data) {
      vm.QREFs = data;
      //console.log("vm.QREFs",vm.QREFs);
    });




    vm.saveVersion = function (content) {
      var test = '';
      //if(1===1) {
      vm.openChangeDialog().then(function(eventData){
        if(eventData) {


          //console.log(eventData);
          vm.isLoading = true;
          var version = angular.copy(content.versions);
          var content_copy = angular.copy(content);
          delete content_copy.versions;


          if (version.Contents.contentTypeId === 1) {
            //console.log(version);

            if(content.chapterTypeId===2){
              var chapterObj = {};

              chapterObj.chapterTypeId  = content.chapterTypeId;
              chapterObj.chapterLinkId = content.chapterLinkId;
              chapterObj.chapterCopy = "<h2>Front Cover</h2>\n";
              if(content.filesList){
                chapterObj.filesList = content.filesList;
              }
              if(content.PDFList){
                chapterObj.PDFList = content.PDFList;
              }
              
              var chapterJson = Wraps.toJson(chapterObj); //front cover
              version.versiondata = chapterJson;
            }else if(content.chapterTypeId===3){
              var chapterJson = Wraps.toJson({chapterTypeId: content.chapterTypeId, chapterLinkId: content.chapterLinkId, chapterCopy: "<h2>QREF</h2>\n" }); //qref
              version.versiondata = chapterJson;
            }else if(content.chapterTypeId===4){
              var chapterJson = Wraps.toJson({chapterTypeId: content.chapterTypeId,  chapterCopy: version.versiondata }); //contents
              version.versiondata = chapterJson;
            }else if(content.chapterTypeId===5){
              var chapterJson = Wraps.toJson({chapterTypeId: content.chapterTypeId,  chapterCopy: "<h2>Summary of benefits and Exclusions</h2>\n" }); //summary
              version.versiondata = chapterJson;
            }else if(content.chapterTypeId===6){
              var chapterJson = Wraps.toJson({chapterTypeId: content.chapterTypeId,  chapterCopy: "<h2>Back Cover</h2>\n" }); //back cover
              version.versiondata = chapterJson;
            }else if(content.chapterTypeId===7){
              var chapterJson = Wraps.toJson({chapterTypeId: content.chapterTypeId,  chapterCopy: version.versiondata }); //BGHP Benefits
              version.versiondata = chapterJson;
            }else if(content.chapterTypeId===8){
              var chapterJson = Wraps.toJson({chapterTypeId: content.chapterTypeId,  chapterCopy: version.versiondata }); //BGHP Terms
              version.versiondata = chapterJson;
            }else if(content.chapterTypeId===9){
              var chapterJson = Wraps.toJson({chapterTypeId: content.chapterTypeId,  chapterCopy: version.versiondata }); //BGHP Exclusions
              version.versiondata = chapterJson;
            }else{
              var chapterJson = Wraps.toJson({chapterTypeId: content.chapterTypeId,  chapterCopy: version.versiondata }); //default
              version.versiondata = chapterJson;
            }

          } else if (version.Contents.contentTypeId === 5) {
            var benefit = {};
            if (_.size(content_copy.benefit.options) === 0) {
              delete content_copy.benefit.options;
            }
            benefit.benefit = content_copy.benefit;
            version.versiondata = Wraps.toJson(benefit);
            //console.log('benefit');
          } else if (version.Contents.contentTypeId === 6) {
            var glossary = {};
            glossary.glossary = content_copy.glossary;
            version.versiondata = Wraps.toJson(glossary);
            //console.log('glossary');
          } else if (version.Contents.contentTypeId === 7) {
            var exclusion = {};
            exclusion.exclusion = content_copy.exclusion;
            version.versiondata = Wraps.toJson(exclusion);
            //console.log('exclusion');
          } else if (version.Contents.contentTypeId === 8) {
            var medical = {};
            medical.medical = content_copy.medical;
            version.versiondata = Wraps.toJson(medical);
            //console.log('medical');
          } else if (version.Contents.contentTypeId === 9) {
            var brief = {};
            brief.brief = content_copy.brief;
            version.versiondata = Wraps.toJson(brief);
            //console.log('brief');
          } else if (version.Contents.contentTypeId === 10) {
            var artwork = {};
            artwork.artwork = content_copy.artwork;
            version.versiondata = Wraps.toJson(artwork);
            //console.log('artwork');
          } else if (version.Contents.contentTypeId === 11) {
            var tableHeader = {};
            tableHeader.tableHeader = content_copy.tableHeader;
            version.versiondata = Wraps.toJson(tableHeader);
            //console.log('tableHeader');
          } else if (version.Contents.contentTypeId === 12) {
            var tableRow = {};
            tableRow.tableRow = content_copy.tableRow;
            version.versiondata = Wraps.toJson(tableRow);
            //console.log('tableRow');
          }else if (version.Contents.contentTypeId === 13) {
            var artwork = {};
            artwork.artwork = content_copy.artwork;
            version.versiondata = Wraps.toJson(artwork);
            //console.log('final');
          } else if (version.Contents.contentTypeId === 17) {
            var benefit = {};
            if (_.size(content_copy.benefit.options) === 0) {
              delete content_copy.benefit.options;
            }
            benefit.benefit = content_copy.benefit;
            version.versiondata = Wraps.toJson(benefit);
            //console.log('benefit');
          } else if (version.Contents.contentTypeId === 20) {
            var exclusion = {};

            exclusion.exclusion = content_copy.exclusion;
            version.versiondata = Wraps.toJson(exclusion);
            //console.log('exclusion');
          }



          Versions.checkVersion({
            "versiondata": version.versiondata,
            "contentId": version.contentId
          }).$promise.then(function (newVersionId) {

            // if we find a version with the same text we reuse it rather than create duplicates
            if (!angular.isUndefined(newVersionId.versionId)) {

              content_copy.versionId = newVersionId.versionId;
              content_copy.lastUpdated = moment.utc().format(Wraps.databaseDateFormat);
              Structures.upsert(content_copy, function () {
                Books.upsert({"bookId": content_copy.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                History.create({
                  "structureId": content_copy.structureId,
                  "versionIdOld": content.versionId,
                  "versionIdNew": content_copy.versionId,
                  "historyId": 0
                }, function (historyResponse) {
                  Events.create({
                    "eventId": 0,
                    "bookId": content_copy.bookId,
                    "structureId": content_copy.structureId,
                    "contentId": version.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Edited",
                    "eventData": Wraps.toJson(eventData)
                  });
                  content.versionId = content_copy.versionId;
                  $mdBottomSheet.hide();
                  vm.isLoading = false;
                });
              });

            } else {
              version.versionId = 0;
              version.created = moment.utc().format(Wraps.databaseDateFormat);
              version.lastUpdated = moment.utc().format(Wraps.databaseDateFormat);
              version.status = 1;


              Versions.create(version, function (data) {
                content_copy.versionId = data.versionId;
                //console.log(content.versionId);
                content_copy.lastUpdated = moment.utc().format(Wraps.databaseDateFormat);
                Structures.upsert(content_copy, function () {

                  Books.upsert({"bookId": content_copy.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
                  History.create({
                    "structureId": content_copy.structureId,
                    "versionIdOld": content.versionId,
                    "versionIdNew": content_copy.versionId,
                    "historyId": 0
                  }, function (historyResponse) {
                    Events.create({
                      "eventId": 0,
                      "bookId": content_copy.bookId,
                      "structureId": content_copy.structureId,
                      "contentId": version.contentId,
                      "historyId": historyResponse.historyId,
                      "userId": $scope.$storage.user.id,
                      "eventType": "Edited",
                      "eventData": Wraps.toJson(eventData)
                    });
                    content.versionId = content_copy.versionId;
                    $mdBottomSheet.hide().then(function () {
                      vm.isLoading = false;
                    });

                  });
                });
              });
            }
          });
        }
      });
    };
    vm.disabledProducts = {};
    vm.updateOption = function(benfit,product){

      if(benfit.option) {
        if (!benfit.option[product]) {
          delete benfit.options[product];
          benfit[product] = vm.tempData.benefit[product];
          vm.disabledProducts[product] = false;
        }
      }
    }

    vm.updateOptions = function(benfit,product){
      if(benfit.options[product]==="Linked"){
        benfit[product] = "";
        vm.disabledProducts[product] = true;
      }else if(benfit.options[product]==="Not covered"){

        benfit[product] = "Not covered";
        vm.disabledProducts[product] = true;
      }else if(benfit.options[product]==="Paid in full"){

        benfit[product] = "Paid in full";
        vm.disabledProducts[product] = true;
      }else if(benfit.options[product]==="Optional") {
        benfit[product] = vm.tempData.benefit[product];
        vm.disabledProducts[product] = false;
      }else if(benfit.options[product]==="Show in summary") {
        benfit[product] = vm.tempData.benefit[product];
        vm.disabledProducts[product] = false;
      }
    }
    vm.updateLinked = function(benfit,product){
      if(!benfit.options[product]){
        delete benfit.options[product];
        benfit[product] = vm.tempData.benefit[product];
      }else{
        benfit[product] = "";
      }
    }

    vm.showHistory = function(id) {
      $mdDialog.show({
        controller: 'HistorydialogCtrl',
        controllerAs: 'historydialog',
        templateUrl: 'library/historydialog/historydialog.tpl.html',
        parent: angular.element(document.html),
        locals:  {structureId: id, clickSource: false },
        clickOutsideToClose:true
      })
        .then(function(data) {

          if(data){
            vm.bottomSheetData.versions.versiondata = data;
            vm.json2Obj(vm.bottomSheetData);
            
            
            
            //console.log(vm.bottomSheetData.versions);
            //console.log(data);


          }

        }, function() {
          vm.status = 'You cancelled the dialog.';
        });
    };


    // CKEditor options.
    vm.options = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      removeButtons: 'Subscript,Superscript',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools',bottom: 'ckfoot'},
      toolbar: [
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
        { name: 'paragraph', items: [  'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent'] },
        { name: 'wrapsplugins', items: [ 'Highlight','UnBold' ] },
        { name: 'format', items: [  'RemoveFormat' ] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };

    vm.options2 = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      enterMode: CKEDITOR.ENTER_BR,
      autoParagraph: false,
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools',bottom: 'ckfoot'},
      toolbar: [
        { name: 'wrapsplugins', items: [  'Highlight' ] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };

    vm.options3 = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      removeButtons: 'Subscript,Superscript',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools2',bottom: 'ckfoot2'},
      toolbar: [
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
        { name: 'paragraph', items: [  'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent'] },
        { name: 'wrapsplugins', items: [  'Highlight','UnBold' ] },
        { name: 'format', items: [  'RemoveFormat' ] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };

    vm.options4 = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
      forcePasteAsPlainText: true,
      pasteFilter: 'plain-text',
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      removeButtons: 'Subscript,Superscript',
      extraPlugins: 'sharedspace,wrapsplugins,texttransform',
      sharedSpaces: { top: 'cktools3',bottom: 'ckfoot3'},
      toolbar: [
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
        { name: 'paragraph', items: [  'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',] },
        { name: 'wrapsplugins', items: [  'Highlight','UnBold' ] },
        { name: 'format', items: [  'RemoveFormat' ] },
        { name: 'texttransform', items: [ 'TransformTextToUppercase', 'TransformTextToLowercase'/*, 'TransformTextCapitalize', 'TransformTextSwitcher'*/] }
      ]
    };


    vm.removeFile = function(array, index){
      array.splice(index, 1);
    }


    var timestamp = new Date().getTime();
    $scope.$watch('bottomsheet.briefFiles', function () {

      vm.upload(vm.briefFiles,'brief').then(function(statuses){
        vm.isLoading = false;
      });
    });

    $scope.$watch('bottomsheet.artworkFiles', function () {

      vm.upload(vm.artworkFiles,'artwork').then(function(statuses){
        vm.isLoading = false;
      });
    });

    $scope.$watch('bottomsheet.chapterFiles', function () {
      vm.upload(vm.chapterFiles,'logo').then(function(statuses){
        vm.isLoading = false;
      });
    });

    $scope.$watch('bottomsheet.frontCoverPDF', function () {
      vm.upload(vm.frontCoverPDF,'frontCoverPDF').then(function(statuses){
        vm.isLoading = false;
        });
    });

    vm.log = '';
    //vm.filesList = [];
    vm.upload = function (files,itemType) {

      var promises = {};
     // var S3_BUCKET_URL = apiUrl+'/containers/wraps-files%2f'+itemType+'%2f'+timestamp+'/upload';
      var S3_BUCKET_URL = apiUrl+'/containers/wraps-files/upload';
      var deferred = $q.defer();

       if (files && !files.length){ //if multiple = false
         var tempFiles = [files];
         files = tempFiles;
      }

      if (files && files.length) {
        _.forEach(files, function (file) {
          if (!file.$error) {
            vm.isLoading = true;
            var thisFileDefer = $q.defer();
            promises[file.name] = thisFileDefer.promise;
            //console.log(file);

            file.container = 'wraps-files';
            file.fileLocation = itemType+'/'+timestamp;
            file.fileName = file.name;
            file.fileSize = file.size;
            file.isLoading = true;
            if(itemType==='brief'){vm.bottomSheetData.brief.filesList.push(file);}
            else if(itemType==='artwork'){vm.bottomSheetData.artwork.filesList.push(file);}
            else if(itemType==='final'){vm.bottomSheetData.artwork.filesList.push(file);}
              
            else if(itemType==='logo') {
              if(!vm.bottomSheetData.filesList){
                vm.bottomSheetData.filesList=[];
              }
              if(tempFiles){
                vm.bottomSheetData.filesList = [];
              }
              vm.bottomSheetData.filesList.push(file);
            }else if(itemType==='frontCoverPDF') {
                if(!vm.bottomSheetData.PDFList){
                    vm.bottomSheetData.PDFList=[];
                }
                if(tempFiles){
                    vm.bottomSheetData.PDFList = [];
                }
                vm.bottomSheetData.PDFList.push(file);
            }


            //TODO add timeout to animate files
            var newName = file.fileLocation + '/' + file.name;
            Upload.rename(file, newName );
            Upload.upload({
              url: S3_BUCKET_URL,
              file: file}).then(function (resp) {
                var count = 0;
                var interval = $interval( function(){
                    Wraps.isImage($filter('thumburl')(file.ngfName)).then(function(rtn){
                        if (rtn === true){
                            $interval.cancel(interval);
                            //console.log(resp.config.file);
                            file.isLoading = false;
                            thisFileDefer.resolve(resp);
                        }
                    });
                    if(count++ >= 20){ //waiting 10 seconds before finishing
                        $interval.cancel(interval);
                        file.isLoading = false;
                        thisFileDefer.resolve('no thumbnail');
                    }
                }, 1000);
            }, null, function (evt) {
              file.determinateValue = parseInt(100.0 *   evt.loaded / evt.total);
              //console.log("file.determinateValue",file.determinateValue);

            });
          }
        });
      }

      return $q.all(promises);
    };


    
    // $scope.$watch('addartwork.files', function () {

    //   vm.upload(vm.files).then(function(statuses){
    //     vm.isLoading = false;
    //   });
    // });

    //vm.filesList = [];

    // vm.upload = function (files) {

    //   var promises = {};

    //   if (files && files.length) {
    //     _.forEach(files, function (file) {
    //       if (!file.$error) {
    //         vm.isLoading = true;
    //         var thisFileDefer = $q.defer();
    //         promises[file.name] = thisFileDefer.promise;

    //         file.container = 'wraps-files';
    //         file.fileLocation = itemType+'/'+timestamp;
    //         file.fileName = file.name;
    //         file.fileSize = file.size;
    //         vm.filesList.push(file);
    //         file.isLoading = true;


    //         //TODO add timeout to animate files

    //         var newName = file.fileLocation + '/' + file.name;
    //         Upload.rename(file, newName );
    //         Upload.upload({
    //           url: S3_BUCKET_URL,
    //           file: file}).then(function (resp) {
    //             var count = 0;
    //             var interval = $interval( function(){
    //                 Wraps.isImage($filter('thumburl')(file.ngfName)).then(function(rtn){
    //                     if (rtn === true){
    //                         $interval.cancel(interval);
    //                         //console.log(resp.config.file);
    //                         file.isLoading = false;
    //                         thisFileDefer.resolve(resp);
    //                     }
    //                 });
    //                 if(count++ >= 10){ //waiting 10 seconds before finishing
    //                     $interval.cancel(interval);
    //                     file.isLoading = false;
    //                     thisFileDefer.resolve('no thumbnail');
    //                 }
    //             }, 1000);
    //         }, null, function (evt) {
    //           file.determinateValue = parseInt(100.0 *   evt.loaded / evt.total);

    //         });
    //       }
    //     });
    //   }
    //   return $q.all(promises);
    // };



    // Called when the editor is completely ready.
    vm.onReady = function () {
      // ...
    };
  }
  BottomsheetCtrl.$inject = ["$localStorage", "$mdBottomSheet", "$mdDialog", "$q", "$scope", "$timeout", "$interval", "$filter", "apiUrl", "book", "Books", "Containers", "Events", "History", "item", "Structures", "Upload", "Users", "Versions", "Wraps", "WrapsBooks"];
}());




(function () {
  'use strict';

  /* @ngdoc object
   * @name library.addartwork
   * @description
   *
   */
  angular
    .module('library.addartwork', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('library.addartwork')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('addartwork', {
        url: '/addartwork',
        templateUrl: 'library/addartwork/addartwork.tpl.html',
        controller: 'AddartworkCtrl',
        controllerAs: 'addartwork'
      });
  }
  config.$inject = ["$stateProvider"];
}());




(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.addartwork.controller:AddartworkCtrl
   *
   * @description
   *
   */
  angular
      .module('library.addartwork')
      .controller('AddartworkCtrl', AddartworkCtrl);

  function AddartworkCtrl($filter, $interval, $localStorage, $mdDialog, $q, $scope, $timeout, apiUrl, book, Books, Containers, Contents, Events, History, Structures, Teams, type, Upload, Users, Versions, Wraps, WrapsBooks) {
    var vm = this;
    vm.ctrlName = 'AddartworkCtrl';
    $scope.$storage = $localStorage;
    if(!Users.isAuthenticated()){$location.path('/');}



    //Teams for add content owners
    vm.teams = Teams.__get__notDeleted();


    vm.safeFileList = Wraps.safeFileList;

    
    vm.bookCode = book.bookCode;

    vm.dialogHide = function(data) {
      $mdDialog.hide(data);
    };
    vm.dialogCancel = function(data) {
      $mdDialog.cancel(data);
    };

    vm.removeFile = function(array, index){
      array.splice(index, 1);
    }

    
    vm.filenameInCodeCheck = WrapsBooks.filenameInCodeCheck;

    if(type=='artwork'){
      var thisContentTypeId = 10;
      var thisContentName = "Artwork";
      var thisItemType = "artwork";
      
    }else if(type=='final'){
      var thisContentTypeId = 13;
      var thisContentName = "Final";
      var thisItemType = "final";
    }

    vm.addNewArtwork = function (artworkData){
      vm.isLoading = true;
      var artwork ={};
      artwork.artwork = artworkData.artwork;
      artwork.artwork.filesList = artworkData.filesList;
      
      //console.log("data",artworkData.fileList);
      var owner = 20; //design -  was artworkData.artworkTeam.teamId
      Contents.create({contentId: 0, contentTypeId: thisContentTypeId, contentName: thisContentName, contentOwner: owner}, function (data1) {

        Versions.create({versionId: 0, contentId: data1.contentId, versiondata: Wraps.toJson(artwork)}, function (data2) {

          Structures.create({structureId: 0, versionId: data2.versionId, contentId: data1.contentId, bookId: book.bookId, "status": 0, "approvalStatus": book.approvalStatus , parent: null, order: data2.versionId}, function (data3) {

            Books.upsert({ "bookId": book.bookId,  "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
            History.create({
              "structureId": data3.structureId,
              "versionIdOld": 0,
              "versionIdNew": data3.versionId,
              "historyId": 0
            },function(historyResponse){
              Events.create({
                "eventId": 0,
                "bookId": book.bookId,
                "structureId": data3.structureId,
                "contentId": data1.contentId,
                "historyId": historyResponse.historyId,
                "userId": $scope.$storage.user.id,
                "eventType": "Added",
                "eventData": thisContentName + " added"
              });
            });

            vm.dialogHide(true);
            //vm.isLoading = false;

          });
        });
      });

    }

    var timestamp = new Date().getTime();
    //var S3_BUCKET_URL = apiUrl+'/containers/wraps-files%2fartwork%2f'+timestamp+'/upload';
    var S3_BUCKET_URL = apiUrl+'/Containers/wraps-files/upload';
    var itemType = thisItemType;
    $scope.$watch('addartwork.files', function () {

      vm.upload(vm.files).then(function(statuses){
        vm.isLoading = false;
      });
    });
    vm.log = '';
    vm.filesList = [];
    vm.filesNames = [];
    vm.upload = function (files) {

      var promises = {};

      if (files && files.length) {
        _.forEach(files, function (file) {
          if (!file.$error) {
            vm.isLoading = true;
            var thisFileDefer = $q.defer();
            promises[file.name] = thisFileDefer.promise;

            file.container = 'wraps-files';
            file.fileLocation = itemType+'/'+timestamp;
            file.fileName = file.name;
            file.fileSize = file.size;
            vm.filesList.push(file);
            file.isLoading = true;


            //TODO add timeout to animate files

            var newName = file.fileLocation + '/' + file.name;
            Upload.rename(file, newName );
            Upload.upload({
              url: S3_BUCKET_URL,
              file: file}).then(function (resp) {
                var count = 0;
                var interval = $interval( function(){
                    Wraps.isImage($filter('thumburl')(file.ngfName)).then(function(rtn){
                        if (rtn === true){
                            $interval.cancel(interval);
                            //console.log(resp.config.file);
                            file.isLoading = false;
                            thisFileDefer.resolve(resp);
                        }
                    });
                    if(count++ >= 10){ //waiting 10 seconds before finishing
                        $interval.cancel(interval);
                        file.isLoading = false;
                        thisFileDefer.resolve('no thumbnail');
                    }
                }, 1000);
            }, null, function (evt) {
              file.determinateValue = parseInt(100.0 *   evt.loaded / evt.total);

            });
          }
        });
      }
      return $q.all(promises);
    };



    vm.options = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
        fillEmptyBlocks:true,
      forcePasteAsPlainText: false,
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      removeButtons: 'Subscript,Superscript',
      extraPlugins: 'sharedspace',
      sharedSpaces: { top: 'cktools',bottom: 'ckfoot'},
      toolbar: [
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
        { name: 'paragraph', items: [  'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',] },
        { name: 'format', items: [  'RemoveFormat' ] }
      ]
    };





  }
  AddartworkCtrl.$inject = ["$filter", "$interval", "$localStorage", "$mdDialog", "$q", "$scope", "$timeout", "apiUrl", "book", "Books", "Containers", "Contents", "Events", "History", "Structures", "Teams", "type", "Upload", "Users", "Versions", "Wraps", "WrapsBooks"];
}());


(function () {
  'use strict';

  /* @ngdoc object
   * @name library.addbrief
   * @description
   *
   */
  angular
    .module('library.addbrief', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('library.addbrief')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('addbrief', {
        url: '/addbrief',
        templateUrl: 'library/addbrief/addbrief.tpl.html',
        controller: 'AddbriefCtrl',
        controllerAs: 'addbrief'
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.addbrief.controller:AddbriefCtrl
   *
   * @description
   *
   */
  angular
    .module('library.addbrief')
    .controller('AddbriefCtrl', AddbriefCtrl);

  function AddbriefCtrl($filter, $interval, $localStorage, $mdDialog, $q, $scope, $timeout, apiUrl, book, Books, Containers, Contents, Events, History, Structures, Teams, Upload, Users, Versions, Wraps) {
  var vm = this;
  vm.ctrlName = 'AddbriefCtrl';
  $scope.$storage = $localStorage;
  if(!Users.isAuthenticated()){$location.path('/');}



  //Teams for add content owners
  vm.teams = Teams.__get__notDeleted();
  
    
  vm.safeFileList = Wraps.safeFileList;

  vm.dialogHide = function(data) {
    $mdDialog.hide(data);
  };
  vm.dialogCancel = function(data) {
    $mdDialog.cancel(data);
  };


    vm.removeFile = function(array, index){
      array.splice(index, 1);
    }

  vm.currentApprovalPosition = 1;

  vm.addNewBrief = function (briefData){
    vm.isLoading = true;
    var brief ={};
    brief.brief = briefData.brief;
    brief.brief.filesList = briefData.filesList;
    //console.log("data",briefData.fileList);
    var owner = 20; //design -  was briefData.briefTeam.teamId
    Contents.create({contentId: 0, contentTypeId: 9, contentName: "Brief", contentOwner: owner}, function (data1) {

      Versions.create({versionId: 0, contentId: data1.contentId, versiondata: Wraps.toJson(brief)}, function (data2) {

        Structures.create({structureId: 0, versionId: data2.versionId, contentId: data1.contentId, bookId: book.bookId, "status": 0, "approvalStatus" : book.userInBook[0], parent: null, order: data2.versionId}, function (data3) {

          Books.upsert({ "bookId": book.bookId,  "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});
          History.create({
            "structureId": data3.structureId,
            "versionIdOld": 0,
            "versionIdNew": data3.versionId,
            "historyId": 0
          },function(historyResponse){
            Events.create({
              "eventId": 0,
              "bookId": book.bookId,
              "structureId": data3.structureId,
              "contentId": data1.contentId,
              "historyId": historyResponse.historyId,
              "userId": $scope.$storage.user.id,
              "eventType": "Added",
              "eventData": "Brief added"
            });
          });

          vm.dialogHide(true);
          //vm.isLoading = false;

        });
      });
    });

  }



    var timestamp = new Date().getTime();
    var S3_BUCKET_URL = apiUrl+'/Containers/wraps-files/upload';
    // var S3_BUCKET_URL = apiUrl+'/containers/wraps-files%2fbrief%2f'+timestamp+'/upload';
    var itemType = "brief";
    $scope.$watch('addbrief.files', function () {

      vm.upload(vm.files).then(function(statuses){
        vm.isLoading = false;
      });
    });
    vm.log = '';
    vm.filesList = [];
    vm.filesNames = [];
    vm.upload = function (files) {

      var promises = {};

      if (files && files.length) {
        _.forEach(files, function (file) {
          if (!file.$error) {
            vm.isLoading = true;
            var thisFileDefer = $q.defer();
            promises[file.name] = thisFileDefer.promise;

            file.container = 'wraps-files';
            file.fileLocation = itemType+'/'+timestamp;
            file.fileName = file.name;
            file.fileSize = file.size;
            vm.filesList.push(file);
            file.isLoading = true;


            //TODO add timeout to animate files

            var newName = file.fileLocation + '/' + file.name;
            Upload.rename(file, newName );
              Upload.upload({
                url: S3_BUCKET_URL,
                file: file,
                params:{
                  orderId: 1,
                  customerId: 1,
                  otherImageInfo:[]
                }}).then(function (resp) {
                  var count = 0;
                  var interval = $interval( function(){
                      Wraps.isImage($filter('thumburl')(file.ngfName)).then(function(rtn){
                          if (rtn === true){
                              $interval.cancel(interval);
                              //console.log(resp.config.file);
                              file.isLoading = false;
                              thisFileDefer.resolve(resp);
                          }
                      });
                      if(count++ >= 10){ //waiting 10 seconds before finishing
                          $interval.cancel(interval);
                          file.isLoading = false;
                          thisFileDefer.resolve('no thumbnail');
                      }
                  }, 1000);
                }, null, function (evt) {
                  file.determinateValue = parseInt(100.0 *   evt.loaded / evt.total);
              });
          }
        });
      }
      return $q.all(promises);
    };



    vm.options = {
      language: 'en',
      allowedContent: true,
      entities: false,
      uiColor: '#ffffff',
        fillEmptyBlocks:true,
      forcePasteAsPlainText: false,
      removePlugins: 'resize,liststyle,tabletools,tableselection,scayt,menubutton,contextmenu',
      removeButtons: 'Subscript,Superscript',
      extraPlugins: 'sharedspace',
      sharedSpaces: { top: 'cktools',bottom: 'ckfoot'},
      toolbar: [
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline'] },
        { name: 'paragraph', items: [  'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent',] },
        { name: 'format', items: [  'RemoveFormat' ] }
      ]
    };





}
AddbriefCtrl.$inject = ["$filter", "$interval", "$localStorage", "$mdDialog", "$q", "$scope", "$timeout", "apiUrl", "book", "Books", "Containers", "Contents", "Events", "History", "Structures", "Teams", "Upload", "Users", "Versions", "Wraps"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name workflow
   * @description
   *
   */
  angular
    .module('workflow', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('workflow')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('workflow', {
        url: '/workflow',
        views: {
          navigation: {
            templateUrl: 'navigation/navigation.tpl.html',
            controller: 'NavigationCtrl',
            controllerAs: 'navigation'
          },
          main: {
            templateUrl: 'workflow/workflow.tpl.html',
            controller: 'WorkflowCtrl',
            controllerAs: 'workflow'
          }
        }

      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name workflow.controller:WorkflowCtrl
   *
   * @description
   *
   */
  angular
    .module('workflow')
    .controller('WorkflowCtrl', WorkflowCtrl);

  function WorkflowCtrl() {
    var vm = this;
    vm.ctrlName = 'WorkflowCtrl';
  }
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name versions
   * @description
   *
   */
  angular
    .module('versions', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('versions')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('versions', {
        url: '/versions',

        views: {
		  tooltip: {
              templateUrl: 'tooltip/tooltip.tpl.html',
              controller: 'TooltipCtrl',
              controllerAs: 'tooltip'
          },
          navigation: {
            templateUrl: 'navigation/navigation.tpl.html',
            controller: 'NavigationCtrl',
            controllerAs: 'navigation'
          },
          main: {
            templateUrl: 'versions/versions.tpl.html',
            controller: 'VersionsCtrl',
            controllerAs: 'versions'
          },
          footer: {
            templateUrl: 'footer/footer.tpl.html',
            controller: 'FooterCtrl',
            controllerAs: 'footer'
          }
        }
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name versions.controller:VersionsCtrl
   *
   * @description
   *
   */
  angular
    .module('versions')
    .controller('VersionsCtrl', VersionsCtrl);

  function VersionsCtrl(
    $window,
    $scope,
    $q,
    $filter,
    $location,
    $localStorage,
    AppVersions,
    Wraps) {
    var vm = this;
    vm.ctrlName = 'VersionsCtrl';

    AppVersions.find().$promise.then(function (versions) {
      vm.versions = versions;
    });

    vm.reload = function(){
      Wraps.reload();
    }
    
  }
  VersionsCtrl.$inject = ["$window", "$scope", "$q", "$filter", "$location", "$localStorage", "AppVersions", "Wraps"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name users
   * @description
   *
   */
  angular
    .module('users', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('users')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('users', {
        url: '/users',
        views: {
		  tooltip: {
              templateUrl: 'tooltip/tooltip.tpl.html',
              controller: 'TooltipCtrl',
              controllerAs: 'tooltip'
          },
          navigation: {
            templateUrl: 'navigation/navigation.tpl.html',
            controller: 'NavigationCtrl',
            controllerAs: 'navigation'
          },
          main: {
            templateUrl: 'users/users.tpl.html',
            controller: 'UsersCtrl',
            controllerAs: 'users'
          },
          footer: {
            templateUrl: 'footer/footer.tpl.html',
            controller: 'FooterCtrl',
            controllerAs: 'footer'
          }
        }
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name library.directive:libraryContentRow
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="library">
       <file name="index.html">
        <library-content-row></library-content-row>
       </file>
     </example>
   *
   */
  angular
    .module('users')
    .directive('usersRightMenu', rightMenu);

  function rightMenu($compile) {
    return {
      restrict: "E",
      templateUrl: 'users/users-directives/users-rightMenu.tpl.html',
      scope: false,
      link: function(scope, element, attributes){
        //scope.book = scope.$eval(attributes.book);
      }
    };
  }
  rightMenu.$inject = ["$compile"];

}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name users.controller:UsersCtrl
   *
   * @description
   *
   */
  angular
    .module('users')
    .controller('UsersCtrl', UsersCtrl);

  function UsersCtrl($localStorage, $location, $mdDialog, $mdSidenav, $q, $scope, Departments, Divisions, Locations, PermissionGroups, Roles, Subscriptions, Teams, Users, UsersStatus, Wraps) {
    var vm = this;
    vm.ctrlName = 'UsersCtrl';

    $scope.$storage = $localStorage;
    if(!Users.isAuthenticated()){$location.path('/');}

	vm.existingEmail = '^((?!test@example\.com).)*$';
	vm.hasAccess = null;
	vm.hasPermissions = Wraps.hasPermissions;

	Wraps.fetchedUser.then(function(){
    	vm.hasAccess = vm.hasPermissions('adminUsers');
	});

    vm.roles = Roles.find({});
    PermissionGroups.__get__notDeleted(function (perms) {

      if($scope.$storage.user.permissionGroup !== 2 ){  //WRAPS Admin - remove team
        _.remove(perms, function(p) {
          return p.groupId == 2;
        });
      }
      vm.permissionGroups = perms;
    });

    Divisions.__get__notDeleted(function (divisions) {
      vm.divisions = divisions;
    });

    vm.departments = Departments.__get__notDeleted();
    vm.teams = Teams.find({"filter":{"include":"Departments", "where":{"teamStatus":1}}});
    vm.locations = Locations.__get__notDeleted();
    vm.usersStatus = UsersStatus.find({});

    vm.compareJson = function(objA, objB){
      return (Wraps.toJson(objA) == Wraps.toJson(objB));
    }

    vm.updateUsers = function (callback) {
      vm.isLoading = true;
      Users.find({filter: {include: ['Departments', 'Teams', 'Locations', 'Roles', 'Divisions']}}, function (userResponse) {
        if($scope.$storage.user.permissionGroup !== 2 ){  //WRAPS Admin - remove users
          _.remove(userResponse, function(u) {
            return u.permissionGroup == 2;
          });
        }
        vm.users = userResponse;
        vm.isLoading = false;
        // commented out waiting for latam
        //Subscriptions.find({filter: {where: {"divisionId": $localStorage.loggedInUser.divisionId}}}).$promise.then(function (subscriptions) {
        Subscriptions.find().$promise.then(function (subscriptions) {
          Users.find({filter: {where: {and: [{"divisionId": $localStorage.loggedInUser.divisionId}, {status: null}, {permissionGroup:{ nlike: "2"}}]}}}).$promise.then(function (userCount) {
            vm.noOfSubs = _.sumBy(subscriptions, 'subscriptions');
            vm.noOfUsers = parseInt(userCount.length);
          });
        });

        if(typeof callback === 'function'){callback();}
      });
    }

    vm.updateUsers(function(){ vm.filterUsers = {};});


    vm.saveRow = function (user) {
      vm.isLoading = true;
      user.lastUpdated = moment.utc().format(Wraps.databaseDateFormat);
      Users.prototype$updateAttributes({id: user.id}, user, function(){
        vm.updateUsers(function(){$scope.$storage.selectedUser = 0;});
      },
      function(){
        //error
        }
      );
    };

    vm.openRightMenu = function (mode, user) {
      if(vm.noOfUsers > vm.noOfSubs && mode == 'new') {
        var confirm = $mdDialog.confirm()
          .title('Licence number exceeded')
          .htmlContent("You currently have <strong>" + (vm.noOfSubs - vm.noOfUsers) + "</strong> users licences left, do you wish to continue anyway?")
          .ok('Add user')
          .cancel('Cancel');

        $mdDialog.show(confirm)
          .then(function () {
            vm.userDetails = angular.copy(user);
            vm.mode = mode;
			vm.existingEmail = '^((?!test@example\.com).)*$';
            $mdSidenav('right').toggle();

          }, function () {

          });
      }else {
        vm.userDetails = angular.copy(user);
        vm.mode = mode;
		vm.existingEmail = '^((?!test@example\.com).)*$';
        $mdSidenav('right').toggle();
      }
    };

    vm.closeRightMenu = function () {
      vm.tabIndex = 0;
	  $scope.usersForm.$setUntouched();
	  $scope.usersForm.$setPristine();
	  vm.userDetails = {};
		
      $mdSidenav('right').close()
        .then(function () {
          //console.log(vm);
        });
    };


    vm.saveUser = function (userDetails, usersForm) {
      //userDetails.divisionId = $localStorage.loggedInUser.divisionId;
      if(userDetails.password == ""){delete userDetails.password;}
      delete userDetails.lastUpdated;
      if(userDetails.status === "null"){userDetails.status = null;}
      if(userDetails.limitDocs){userDetails.limitDocs = 1;}else{userDetails.limitDocs = null;}
      if((userDetails.status === 2 || userDetails.status === '2') && userDetails.dateDisabled === null){userDetails.dateDisabled = moment().format("YYYY-MM-DD HH:mm:ss")}else if(userDetails.status !== 2 && userDetails.status !== '2'){ userDetails.dateDisabled = null}
      Users.prototype$updateAttributes({id: userDetails.id}, userDetails, function(response){
          vm.tabIndex = 0;
          $mdSidenav('right').close()
            .then(function () {
              vm.updateUsers(function(){$scope.$storage.selectedUser = 0;});
              if (userDetails.id === $scope.$storage.user.id) {
                  $scope.$storage.user = Object.assign($localStorage.user, userDetails);
              }
          });
      }, function (response) {
        if(response.data &&  response.data.error && response.data.error.name && response.data.error.name == "ValidationError") {
			vm.existingEmail = '^((?!' + vm.userDetails.email.replace(".", "\.") + ').)*$';
          //usersForm.email.$error.validation = true;
        }
      });
    }


    //table filter info
    if(!vm.usersQuery) {
      vm.usersQuery = {
        order: 'id',
        limit: 10,
        page: 1
      };
    }

    $scope.$watch('users.filterUsers', function() {
      vm.usersQuery = {
        limit: 10,
        page: 1
      };
    },true);

    vm.downloadCSV = function () {
      var newArr = [];
      if(vm.hasPermissions('downloadUsers')) {
          _.forEach(vm.users, function (user) {
              var newUser = {};
              newUser.firstName = user.firstName;
              newUser.lastName = user.lastName;
              newUser.email = user.email;
              newUser.created = moment(user.created).format("DD/MM/YYYY HH:mm");
              newUser.lastUpdated = moment(user.lastUpdated).format("DD/MM/YYYY HH:mm");
              newUser.division = user.Divisions.division;
              newUser.location = user.Locations.locationName;
              newUser.team = user.Teams.shortName;
              newUser.role = user.Roles.name;
              newUser.restricted = user.limitDocs ? 'restricted' : '';
              newUser.status = user.status == 2 ? 'disabled' : '';
              newUser.dateDisabled = user.dateDisabled;
              newUser.notes = user.bupaNotes;
              newArr.push(newUser);
          });
      }
      return newArr;
    };



  }
  UsersCtrl.$inject = ["$localStorage", "$location", "$mdDialog", "$mdSidenav", "$q", "$scope", "Departments", "Divisions", "Locations", "PermissionGroups", "Roles", "Subscriptions", "Teams", "Users", "UsersStatus", "Wraps"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name permissionmanagement
   * @description
   *
   */
  angular
    .module('permissionmanagement', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name permissionmanagement.filter:titlecase
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('permissionmanagement')
    .filter('permissionmanagement-titlecase', titlecase);

  function titlecase() {
    return function (str) {
    	var withspaces = str.replace(/([A-Z])/g, " $1");
		return withspaces.charAt(0).toUpperCase() + withspaces.slice(1);
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name permissionmanagement.directive:rowEdit
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="permissionmanagement">
       <file name="index.html">
        <row-edit></row-edit>
       </file>
     </example>
   *
   */
  angular
    .module('permissionmanagement')
    .directive('permissionmanagementRowEdit', rowEdit);

  function rowEdit() {
    return {
      restrict: 'E',
      scope: false,
      templateUrl: 'permissionmanagement/permissionmanagement-row-edit-directive.tpl.html',
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  angular
    .module('permissionmanagement')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('permissionmanagement', {
        url: '/permissionmanagement',
        views: {
		  tooltip: {
              templateUrl: 'tooltip/tooltip.tpl.html',
              controller: 'TooltipCtrl',
              controllerAs: 'tooltip'
          },
          navigation: {
            templateUrl: 'navigation/navigation.tpl.html',
            controller: 'NavigationCtrl',
            controllerAs: 'navigation'
          },
          main: {
            templateUrl: 'permissionmanagement/permissionmanagement.tpl.html',
            controller: 'PermissionmanagementCtrl',
            controllerAs: 'permissionmanagement'
          },
          footer: {
            templateUrl: 'footer/footer.tpl.html',
            controller: 'FooterCtrl',
            controllerAs: 'footer'
          }
        }
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name permissionmanagement.controller:PermissionmanagementCtrl
     *
     * @description
     *
     */
    angular
        .module('permissionmanagement')
        .controller('PermissionmanagementCtrl', PermissionmanagementCtrl);

    function PermissionmanagementCtrl($filter, $localStorage, $location, $mdDialog, $mdSidenav, $q, $scope, $timeout, PermissionCategories, PermissionGroupJoin, PermissionGroups, Permissions, Users, Wraps) {
        var vm = this;
        vm.ctrlName = 'PermissionmanagementCtrl';


        $scope.$storage = $localStorage;

        if (!Users.isAuthenticated()) {
            $location.path('/');
        }
		vm.hasAccess = null;
		vm.hasPermissions = Wraps.hasPermissions;
		vm.hasAnyOfPermissionCat = Wraps.hasAnyOfPermissionCat;

        vm.tables = {
            'Permissions': {
                'friendlyName': 'Permissions',
                'pk': 'permissionId',
                'deletedCol': 'permissionStatus',
                'orderby': ['permissionName'],
				'disabled':true,
                'fields': [
                    {name: 'permissionId', unique: true, required: true},
                    {name: 'permissionName', unique: true, required: true},
                    {name: 'permissionDescription', unique: false, required: true},
                    {name: 'permissionCode', unique: false, required: true},
                    {name: 'permissionCategoryName', unique: false, required: true},
                ],
                'dropDown': {
                    'parentColName':'permissionCategoryId',
                    'tableName':'PermissionCategories',
                    'childPk':'permissionCategoryId',
                    'childColName':'permissionCategoryName'
                }
            },
            'PermissionGroups': {
                'friendlyName': 'Permission Groups',
                'pk': 'groupId',
                'deletedCol': 'groupStatus',
                'orderby': ['groupName'],
				'disabled':true,
                'fields': [
                    {name: 'groupId', unique: true, required: true},
                    {name: 'groupName', unique: true, required: true},
                    {name: 'groupDescription', unique: false, required: true},
                ]
            },
            'PermissionCategories': {
                'friendlyName': 'Permission Categories',
                'pk': 'permissionCategoryId',
                'deletedCol': 'permissionCategoryStatus',
                'orderby': ['permissionCategoryName'],
				'disabled':true,
                'fields': [
                    {name: 'permissionCategoryId', unique: true, required: true},
                    {name: 'permissionCategoryName', unique: true, required: true},
                    {name: 'permissionCategoryDescription', unique: false, required: true},
                ],
				'hasMany':{'table':'Permissions','fk':'permissionCategoryId', 'fdel':'permissionStatus'}
            }
        };
		//console.log(Wraps.fetchedUser);
		Wraps.fetchedUser.then(function(){
			vm.tables.Permissions.disabled = !vm.hasPermissions('adminPermissions');
			vm.tables.PermissionGroups.disabled = !vm.hasPermissions('adminPermissions');
			vm.tables.PermissionCategories.disabled = !vm.hasPermissions('adminPermissions');
			vm.hasAccess = vm.hasAnyOfPermissionCat(5);
		});

        var tmp = {};
        Object.keys(vm.tables).sort().forEach(function (key) {
            tmp[key] = vm.tables[key];
        });
        vm.tables = tmp;
        vm.assignPermissionsToGroups = false;

        vm.changeTable = function (tableName) {
			//console.log(tableName);
            vm.assignPermissionsToGroups = false;
            vm.cols = vm.tables[tableName].fields;
            vm.pk = vm.tables[tableName].pk;
            vm.tableName = tableName;
            vm.deletedCol = vm.tables[tableName].deletedCol;
            vm.orderBy = vm.tables[tableName].orderby;
            vm.reverse = false;
			$scope.$storage.selectedRow = ''; 
			$scope.$storage[tableName] = {};
            vm.deleteArray = {};
            vm.grabRows(tableName);
			
        };

        $scope.sortBy = function (propertyName) {
            vm.reverse = (vm.orderBy === propertyName) ? !vm.reverse : false;
            vm.orderBy = propertyName;
        };

        vm.clearCats = function () {
            _.forEach(vm.PermissionCategories, function (cat) {
                vm.isAllSelected = ['cat_' + cat.permissionCategoryId];
                vm.isAllSelected['cat_' + cat.permissionCategoryId] = false;
            });
            vm.isAllSelectedMisc = false;
        }

        vm.grabRows = function (tableName) {
            vm.rows = [];
			vm.isLoading = true;
            vm.deleteArray = {};

            var args = {};
            if(typeof vm.tables[tableName].dropDown !== 'undefined') {
                args = {"filter":{"include":vm.tables[tableName].dropDown.tableName, "where":{}}};
                args.filter.where[vm.deletedCol]=1;
				PermissionCategories.__get__notDeleted(function (PermissionCategories) {
					vm.PermissionCategories = PermissionCategories;
					vm.clearCats();
				});
            }else{
				args = {"filter":{"where":{}}};
                args.filter.where[vm.deletedCol]=1;
			}

            eval(tableName).find(args).$promise.then(function (results) {
               /* _.forEach(results, function (row) {
                    if (row[vm.deletedCol] == 1) {
                            vm.rows.push(row);
                    }
                });*/
				vm.rows = results;
				vm.isLoading = false;
            });



        };


        vm.toggleAll = function (checkboxCategory) {
            var toggleStatus = [checkboxCategory];
            toggleStatus[checkboxCategory] = !vm.isAllSelected['cat_' + checkboxCategory];

            angular.forEach(vm.permissions, function (itm) {
                if (itm.permissionCategoryId == checkboxCategory && itm.permissionCode != 'adminPermissions') {
                    itm.selected = toggleStatus[checkboxCategory];
                }
            });
        };

        vm.optionToggled = function (checkboxCategory) {
            vm.isAllSelected['cat_' + checkboxCategory] = true;

            var keepGoing = true;

            angular.forEach(vm.permissions, function (itm) {
                if (keepGoing === true && (itm.permissionCategoryId == checkboxCategory) && itm.selected !== true) {
                    vm.isAllSelected['cat_' + checkboxCategory] = false;
                    keepGoing = false;
                    //break
                }
            });
        };

        vm.toggleAllMisc = function () {
            var toggleStatusMisc;
            toggleStatusMisc = !vm.isAllSelectedMisc;

            angular.forEach(vm.permissions, function (itm) {
                if (itm.permissionCategoryId == null) {
                    itm.selected = toggleStatusMisc;
                }
            });
        };

        vm.optionToggledMisc = function () {
            vm.isAllSelectedMisc = true;

            var keepGoing = true;

            angular.forEach(vm.permissions, function (itm) {
                //console.log(itm.permissionCategoryId);
                if (keepGoing === true && (itm.permissionCategoryId == null) && itm.selected !== true) {
                    vm.isAllSelectedMisc = false;
                    keepGoing = false;
                    //break
                }
            });
        }

        vm.compareJson = function (objA, objB) {
            return (angular.toJson(objA) == angular.toJson(objB));
        };

        vm.saveRow = function (row, action) {
            vm.errors = {};
            vm.isLoading = true;
            var pkObj = {};
            var colName;
            pkObj[vm.pk] = row[vm.pk];

            _.forEach(vm.cols, function (col) { //loop through columns/fields

                colName = col.name;
                if(colName == 'permissionCategoryName') {
                    colName = 'permissionCategoryId';
                }

                if (col.required &&
                    !row[colName] && colName != vm.pk) {
                    vm.errors[colName] = 'This field is required';
                } else if (col.unique && colName != vm.pk) { //check they are meant to be unique and that they're not the primary key.
                    var found = _.find(vm.rows, function (item) { //find matching data in existing rows
                        return (item[colName] + '').toLowerCase().trim() == (row[colName] + '').toLowerCase().trim();
                    });
                    if (found && found[vm.pk] != row[vm.pk]) {  //check for found row unless it's the current row.
                        vm.errors[colName] = 'An entry with this value already exists';
                    }
                }

            });

            if(typeof vm.tables[vm.tableName].dropDown != 'undefined') {
                delete row[vm.tables[vm.tableName].dropDown.tableName];
            }

            if (_.isEmpty(vm.errors)) {
                if(action == 'edit') {
                    eval(vm.tableName).upsert(row).$promise.then(function () {
                        $scope.$storage[vm.tableName] = {};
                        vm.closeEdit();
                        vm.grabRows(vm.tableName);
                        $scope.$storage.selectedRow = 0;
                        vm.isLoading = false;
                    },
                    function () {
                        //error
                    });
                }else if(action == 'new') {
                    row[vm.pk] = 0;
                    eval(vm.tableName).create(row).$promise.then(function () {
                        $scope.$storage[vm.tableName] = {};
                        $scope.$storage.selectedRow = 0;
                        vm.closeEdit();
                        vm.grabRows(vm.tableName);
                    });
                }
            }

        };
		
		vm.deleteRows = function (selectedRows) {
            var confirm = $mdDialog.confirm()
                .title('Delete rows')
                .htmlContent('Are you sure you want to delete these rows?<br />')
                .ariaLabel('Confirm Delete')
                .ok('Delete')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function () {
				var finishedDeletes = {};
				var relationshipErrors = [];
                _.forEach(selectedRows, function (tbd, key) { //tbd : to be deleted (bool)
					var rowDefer = $q.defer();
					finishedDeletes[key] = rowDefer.promise;
					if(tbd == true){ 
						//if we actually want to delete this row.
						
						var relation;
						if(relation = vm.tables[vm.tableName].hasMany){
							var fk = relation.fk;
							var del = relation.fdel;
							var args = {"filter":{"where":{}}};
							args.filter.where[relation.fk] = parseInt(key);
							args.filter.where[relation.fdel] = 1;
							//if table has a hasMany relationship with another table.
							eval(relation.table).find(args).$promise.then(function(res){ // make call to child table to check for children
								if(res.length > 0){
									//has children - push row info to error array for prevent alert.
									relationshipErrors.push({rowId:key, count:res.length, childTable: relation.table});
									rowDefer.resolve();
									
								   
								}else{
									//no children associated - okay to delete
									vm.doDelete(key).then(function(){
										rowDefer.resolve();
									});
								}
							});
						}else{
							//no relationship - okay to delete.
							vm.doDelete(key).then(function(){
								rowDefer.resolve();
							});
						}
					}else{
						rowDefer.resolve();
					}
                });
				
				$q.all(finishedDeletes).then(function(){
					//when all promises are resolved;
					if (relationshipErrors.length > 0){
						//console.log(relationshipErrors);
						//check for relational errors + build error modal.
						var errorString = '';
						_.forEach(relationshipErrors, function(row){
							errorString += '<li>Row ' + row.rowId + ' has ' + row.count + ' ' + row.childTable + ' assigned to it.</li>'
						});
						var preventAlert = $mdDialog.alert().title('Unable to delete all rows').htmlContent( 'Unable to delete the following rows due to dependencies in other tables. <ul>' + errorString + '</ul>Please delete or reassign these dependants before attempting to delete this row.').ok('Accept');
						$mdDialog.show(preventAlert);
					}		
					vm.grabRows(vm.tableName);	//refresh
				});
            });
        };
		
		vm.doDelete = function(key){
			var doDelDefer = $q.defer();
			_.forEach(vm.rows, function (row) {
				if (row[vm.pk] == key) {
					row[vm.deletedCol] = 0;
					eval(vm.tableName).upsert(row, function () {
						doDelDefer.resolve();
					});
				}
			});
			return doDelDefer.promise;
		}

        vm.checkObj = function (obj) {
            var conclusion = true;
            _.forEach(obj, function (element) {

                if (element != '') {
                    conclusion = false;
                }
            });
            return conclusion;
        };


   

        vm.openEdit = function (row) {
            vm.errors = {};
            if (!row) {
                $scope.$storage.selectedRow = 0;
                $scope.$storage[vm.tableName] = {};
            } else {
                $scope.$storage[vm.tableName] = angular.copy(row);
            }
            $mdSidenav('right').toggle();
        }

        vm.closeEdit = function () {
            $mdSidenav('right').toggle();
        }

        vm.getCurrentPermissions = function () {
            PermissionGroupJoin.find({"filter": {"where": {"groupId": vm.thisPermissionGroup}}}, function (assignedGroupPermissions) {
                Permissions.__get__notDeleted(function (permissions) {
                    vm.permissions = permissions;
                    vm.clearCats();
                    _.forEach(assignedGroupPermissions, function (assignedGroupPermission) {
                        _.forEach(vm.permissions, function (permission) {
                            if (permission.permissionId == assignedGroupPermission.permissionId) {
                                permission.selected = true;
                                vm.optionToggled(permission.permissionCategoryId);
                                vm.optionToggledMisc();
                            }
                        });
                    });
                });
            });
        }

        vm.savePermissionsToGroup = function (form) {
			vm.isSaving = true;
            /*PermissionGroupJoin.markPermissionGroupJoinAsDeleted({"groupId": vm.thisPermissionGroup}).$promise.then(function (result) {
                _.forEach(vm.permissions, function (permission) {
                    if (permission.selected && permission.selected === true) {
                        PermissionGroupJoin.create({
                            "groupId": vm.thisPermissionGroup,
                            "permissionId": permission.permissionId
                        }).$promise.then(function (result) {
                            //console.log(result);
							vm.isSaving=false;
		  					vm.saved = "success";
		  					form.$setPristine();
                        }, function(err){
							vm.isSaving=false;
							console.error(err); 
							vm.saved = "error"; 
							form.$setPristine();
						});
                    }else{
						vm.isSaving=false;
						console.error("permnotselected");
						form.$setPristine();
					}
                });
            });*/
			PermissionGroupJoin.assignPermissions({"groupId":vm.thisPermissionGroup, "permissions":vm.permissions}).$promise.then(function(rtn){
				vm.isSaving=false;
				vm.saved = "success";
				form.$setPristine();
			},function(err){
				vm.isSaving=false;
				console.error(err); 
				vm.saved = "error"; 
				form.$setPristine();
			});
        };
		
		vm.initView = function(){
			PermissionCategories.__get__notDeleted(function (PermissionCategories) {
                vm.PermissionCategories = PermissionCategories;
                vm.clearCats();
            });
            Permissions.__get__notDeleted(function (permissions) {
                vm.permissions = permissions;
				if ($filter('filter')(vm.permissions, {'permissionCategoryId': null}).length > 0){
					vm.hasMisc = true;
				}else{
					vm.hasMisc = false;
				}
            });
            PermissionGroups.__get__notDeleted(function (permissionGroups) {
				//console.log($scope.$storage.loggedInUser);
				vm.permissionGroups = [];
				_.forEach(permissionGroups, function(group){
					if(group.groupId != 2 || $scope.$storage.loggedInUser.permissionGroup == 2){
					   vm.permissionGroups.push(group);
					   }
				});
            });
			vm.assignPermissionsToGroups = true;
			
			/*vm.cols = [];
            vm.pk = '';
            vm.tableName = '';*/
            
		};
		vm.cols = [];
		vm.pk = '';
		vm.tableName = '';
		vm.initView();


        /*for (var tableName in vm.tables) {
            vm.changeTable(tableName);
            break;
        }*/
		

        document.touchstart = {
            setup: function (_, ns, handle) {
                if (ns.includes("noPreventDefault")) {
                    this.addEventListener("touchstart", handle, {passive: false});
                } else {
                    this.addEventListener("touchstart", handle, {passive: true});
                }
            }
        };
		
		$scope.$watch('permissionmanagement.selectedTabIndex', function(current, old) {
            if(parseInt(current) == 0 || current == undefined){
				vm.initView();
			}else{
				var i = 1;
				_.forEach(vm.tables, function(tableinfo, tableNa){
					if(i == current){
					   vm.changeTable(tableNa);
					}
					i++;
				});
			}
        });


    }
    PermissionmanagementCtrl.$inject = ["$filter", "$localStorage", "$location", "$mdDialog", "$mdSidenav", "$q", "$scope", "$timeout", "PermissionCategories", "PermissionGroupJoin", "PermissionGroups", "Permissions", "Users", "Wraps"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name tooltip
   * @description
   *
   */
  angular
    .module('tooltip', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('tooltip')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('tooltip', {
        url: '/tooltip',
        templateUrl: 'tooltip/tooltip.tpl.html',
        controller: 'TooltipCtrl',
        controllerAs: 'tooltip'
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';
	/*--if you're looking for the wraps-tooltip wrapsTooltip directive, look in the tooltip-controller--*/
  /**
   * @ngdoc directive
   * @name library.directive:libraryContentRow
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="library">
       <file name="index.html">
        <library-content-row></library-content-row>
       </file>
     </example>
   *
   */
  /*angular
    .module('tooltip')
    .directive('helpMe', helpMe);

  function helpMe($compile) {
    return {
      scope: {
        helpMethod: '&'
      },
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.on('mouseenter', function () {
          //console.log('asd');
          scope.helpMethod({helpbutton: ''});
        });
        /!*element.on('mouseleave', function () {
        element.css('background-color', 'white');
       });*!/
      }
    };
  }*/

}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name tooltip.controller:TooltipCtrl
   *
   * @description
   *
   */
  angular
    .module('tooltip')
    .controller('TooltipCtrl', TooltipCtrl)
	.directive('wrapsTooltip', wrapsTooltip);


  function TooltipCtrl($localStorage, $location, $mdDialog, $rootScope, $scope, Tooltips, Wraps) {
    var vm = this;
    vm.ctrlName = 'TooltipCtrl';
    //vm.showToolTip = false;
	vm.defaultTitle = 'Tool tips';
	vm.defaultText = 'Hover over elements in the page to find out what they do';

    $scope.$storage = $localStorage;

    vm.showToolTip = Wraps.toolTip.showToolTip;

    //if(!Users.isAuthenticated()){$location.path('/');}
	 vm.hide = function(){
         Wraps.toolTip.showToolTip = vm.showToolTip = false;
	 }
	 vm.hasPermissions = Wraps.hasPermissions;
	 $scope.$watch('tooltip.showToolTip',function(neue,old){
		 if(neue== true){
			 angular.element(document).find('md-sidenav').addClass("tooltip-open");
		 }else{
			 angular.element(document).find('md-sidenav').removeClass("tooltip-open");
		 }
		 
	 });


    vm.isActive = function (viewLocation) {
      return viewLocation === $location.path();
    };

  

    vm.users = function () {
      $mdDialog.show(
        $mdDialog.alert()
          .title('Document Cascader')
          .content('You do not have permissions to use this area')
          .ok('Close')
      );
    };

    $rootScope.$on("toggleTooltip", function () {
      vm.helpTitle = 'Loading tooltips...';
	  vm.helpText = '';
	  if (!vm.showToolTip && !Wraps.tooltips){
		 Tooltips.__get__notDeleted(function (tooltiparray) {
			Wraps.tooltips = tooltiparray;
			vm.helpTitle = vm.defaultTitle;
			vm.helpText = vm.defaultText;
		}); 
	  }
        Wraps.toolTip.showToolTip = vm.showToolTip = (vm.showToolTip ? false : true);
    });

    $rootScope.$on("openTooltip", function (event, helpCode) { //ToDo rewrite with promises & maybe functions to share with other event anonymous functions in this controller. - a lot of copy-paste
        if (!vm.showToolTip && !Wraps.tooltips){
            vm.helpTitle = 'Loading tooltips...';
            vm.helpText = '';
            Tooltips.__get__notDeleted(function (tooltiparray) {
                Wraps.tooltips = tooltiparray;
                if(helpCode){
                    var $activetip = Wraps.tooltips.filter(function(arrayel){
                        return helpCode == arrayel.tooltipCode;
                    });
                    if($activetip[0]) {
                        vm.helpTitle = $activetip[0].title;
                        vm.helpText = $activetip[0].description;
                        vm.helpCode = helpCode;
                    }else{
                        vm.helpTitle = vm.defaultTitle;
                        vm.helpText = vm.defaultText;
                        vm.helpCode = helpCode;
                    }
                }else{
                    vm.helpTitle = vm.defaultTitle;
                    vm.helpText = vm.defaultText;
                    vm.helpCode = '';
                }
            });
        }else{
            if(helpCode){
                var $activetip = Wraps.tooltips.filter(function(arrayel){
                    return helpCode == arrayel.tooltipCode;
                });
                if($activetip[0]) {
                    vm.helpTitle = $activetip[0].title;
                    vm.helpText = $activetip[0].description;
                    vm.helpCode = helpCode;
                }else{
                    vm.helpTitle = vm.defaultTitle;
                    vm.helpText = vm.defaultText;
                    vm.helpCode = helpCode;
                }
            }else{
                vm.helpTitle = vm.defaultTitle;
                vm.helpText = vm.defaultText;
                vm.helpCode = '';
            }
        }

      	Wraps.toolTip.showToolTip = vm.showToolTip = true;
    });


    $rootScope.$on("tooltip", function (event, helpCode) {
      if(vm.showToolTip) {
		if(helpCode == false || !Wraps.tooltips){
			vm.helpTitle = vm.defaultTitle;
			vm.helpText = vm.defaultText;
			vm.helpCode = '';
			$scope.$apply();
		}else{
			var $activetip = Wraps.tooltips.filter(function(arrayel){
				return helpCode == arrayel.tooltipCode;
			});
			if($activetip[0]) {
				vm.helpTitle = $activetip[0].title;
				vm.helpText = $activetip[0].description;
				vm.helpCode = helpCode;
				$scope.$apply();
			}else{
				vm.helpTitle = vm.defaultTitle;
				vm.helpText = vm.defaultText;
				vm.helpCode = helpCode;
				$scope.$apply();
			}
		}

      }

    });

  }
  TooltipCtrl.$inject = ["$localStorage", "$location", "$mdDialog", "$rootScope", "$scope", "Tooltips", "Wraps"];
}());

function wrapsTooltip(){
	return {
		scope: {
			wrapsTooltip: '&'
		},
		restrict: 'A',
		controllerAs: 'vm',
		controller:["$scope", "$element", "$attrs", "$rootScope", function($scope, $element, $attrs, $rootScope){
			$element.on('mouseleave', function () {
				$rootScope.$emit("tooltip", false);
			});
			$element.on('mouseenter', function () {
				$rootScope.$emit("tooltip", $attrs.wrapsTooltip);
			});
		}]
	};
}
(function () {
  'use strict';

  /* @ngdoc object
   * @name reset
   * @description
   *
   */
  angular
    .module('reset', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('reset')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('reset', {
        url: '/reset/:accesstoken',
        views: {
          navigation: {
            templateUrl: 'navigation/navigation.tpl.html',
            controller: 'NavigationCtrl',
            controllerAs: 'navigation'
          },
          main: {
            templateUrl: 'reset/reset.tpl.html',
            controller: 'ResetCtrl',
            controllerAs: 'reset'
          }
        }

      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name reset.controller:ResetCtrl
   *
   * @description
   *
   */
  angular
    .module('reset')
    .controller('ResetCtrl', ResetCtrl);

  function ResetCtrl($location, $rootScope, $scope, $stateParams, $timeout, LoopBackAuth, Users) {
    var vm = this;
    vm.ctrlName = 'ResetCtrl';



    //console.log('$stateParams',$stateParams.accesstoken);



    vm.submitReset = function(){

      //console.log('123');
      vm.isLoading = true;
      vm.error = false;
      vm.resendMsg = false;

      var accesstoken = $stateParams.accesstoken

      //console.log(vm.credentials);
      $timeout(function() {
        if (accesstoken){
          if(vm.credentials) {
            LoopBackAuth.accessTokenId = accesstoken;

            //verify passwords match
            if (!vm.credentials.password || !vm.credentials.password2 ||
              vm.credentials.password !== vm.credentials.password2) {
              vm.error = 'Passwords do not match';
              $timeout(function () { vm.error = ''; }, 3000);
            } else {

              Users.setNewPassword({"password": vm.credentials.password}).$promise.then(function (err, response) {
                vm.error = 'Password reset';
                $rootScope.pw = vm.credentials.password;
                $timeout(function () {vm.error = ''; $location.path('/');}, 2000);

              }, function () {
                  vm.error = 'Tolken too old please resend';
                  $timeout(function() { vm.error = '';},3000);

              });
            }

          }else{
            vm.error = 'Please enter password';
            $timeout(function() { vm.error = '';},3000);
          }
        }else{
          vm.error = 'URL error';
          $timeout(function() { vm.error = '';},3000);
        }

        vm.isLoading = false;
      },1000);

    }


  }
  ResetCtrl.$inject = ["$location", "$rootScope", "$scope", "$stateParams", "$timeout", "LoopBackAuth", "Users"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name reports
   * @description
   *
   */
  angular
    .module('reports', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('reports')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('reports', {
        url: '/reports',

          views: {
			  tooltip: {
				  templateUrl: 'tooltip/tooltip.tpl.html',
				  controller: 'TooltipCtrl',
				  controllerAs: 'tooltip'
			  },
              navigation: {
                  templateUrl: 'navigation/navigation.tpl.html',
                  controller: 'NavigationCtrl',
                  controllerAs: 'navigation'
              },
              main: {
                  templateUrl: 'reports/reports.tpl.html',
                  controller: 'ReportsCtrl',
                  controllerAs: 'reports'
              },
              footer: {
                templateUrl: 'footer/footer.tpl.html',
                controller: 'FooterCtrl',
                controllerAs: 'footer'
              }
          }
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name reports.controller:ReportsCtrl
   *
   * @description
   *
   */
  angular
    .module('reports')
    .controller('ReportsCtrl', ReportsCtrl);

  function ReportsCtrl($filter, $localStorage, $location, $mdDialog, $scope, $timeout, Reports, Users, Wraps) {
    var vm = this;
    vm.ctrlName = 'ReportsCtrl';

    if(!Users.isAuthenticated()){$location.path('/');}

    $scope.$storage = $localStorage;
	vm.hasAccess = null;
	vm.hasPermissions = Wraps.hasPermissions;
	  
	Wraps.fetchedUser.then(function(){
		 vm.hasAccess = vm.hasPermissions('adminReports');
	 });
	
    var myDate = new Date();

    var previousMonth = $filter('date')(myDate.setMonth(myDate.getMonth()-1), 'MMMM yyyy');
    var previous6Month = $filter('date')(myDate.setMonth(myDate.getMonth()-5), 'MMMM yyyy');
    var previous12Month = $filter('date')(myDate.setMonth(myDate.getMonth()-6), 'MMMM yyyy');


    vm.monthlyDate = previousMonth;
    vm.biAnnualDate = previous6Month + " -  " + previousMonth;
    vm.annualDate  = previous12Month + " -  " + previousMonth;
	
	vm.green = '#ddebcc';
	vm.yellow = '#fdf5c9';
	vm.amber = '#fce3bc';
	vm.red = '#f2c9c5';
	  
	var layout1 = {
		hLineWidth: function (i, node) {
			return (i === 0 || i === node.table.body.length) ? 0 : 1;
		},
		vLineWidth: function (i, node) {
			return 0;
		},
		hLineColor: function (i, node) {
			return (i === 1) ? 'gray' : 'lightgray';
		},
		paddingLeft: function(i, node) { return 10; },
		paddingRight: function(i, node) { return 10; },
		paddingTop: function(i, node) { return 3; },
		paddingBottom: function(i, node) { return 3; }
	};
	  
	var layout2 = {
		hLineWidth: function (i, node) {
			return (i === 0 || i === node.table.body.length) ? 0 : 1;
		},
		vLineWidth: function (i, node) {
			return 0;
		},
		hLineColor: function (i, node) {
			return (i === 2) ? 'gray' : 'lightgray';
		},
		paddingLeft: function(i, node) { return 10; },
		paddingRight: function(i, node) { return 10; },
		paddingTop: function(i, node) { return 3; },
		paddingBottom: function(i, node) { return 3; }
	}
	
	//Table DD constructor functions
	function conditionalTable(title, helper, data){
		var tblObj = {};
		tblObj.style = 'tableExample';
		tblObj.table = {
			headerRows:1,
			widths: ['*', 30, 30,30],
			body : [
				[{text:title + ' \n(' + helper + ')',style:'tableHeader'}, {text:'%',style:'tableHeader', alignment:'right'}, {text:'#',style:'tableHeader',alignment:'right'}, {text:'Total',style:'tableHeader',alignment:'right'}]
			]
		};
		_.forEach(data, function(rowData){
			var newRow = [{text:[{text:rowData.name + ' ', bold:true}, rowData.helper]}, {text:rowData.percentage, alignment:'right', fillColor:vm[rowData.color]},  {text: rowData.number, alignment:'right'},  {text:rowData.total, alignment:'right'}];
			tblObj.table.body.push(newRow);
		})
		tblObj.layout = layout1;
		return tblObj;
	};
	  
	function docInfoTable(title, helper, data){
		var tblObj = {};
		tblObj.style = 'tableExample';
		tblObj.table = {
			headerRows: 2,
			widths: [30,'*', 100, 100,],
			body : [
				[{text:title + ' \n(' + helper + ')',style:'tableHeader',colSpan: 4},{},{},{}]
			]
		};
		_.forEach(data, function(rowData){
			var newRow = [{text:rowData.id}, {text:rowData.bookCode},  {text:rowData.docType},  {text:rowData.owner}];
			tblObj.table.body.push(newRow);
		})
		tblObj.layout = layout2;
		return tblObj;
	};
	function trafficLightsTable(title, helper, data){
		var tblObj = {};
		tblObj.style = 'tableExample';
		tblObj.table = {
			headerRows:1,
			widths: ['*',30,30,30,30],
			body : [
				[{text:title + ' \n(' + helper + ')',style:'tableHeader'}, {text:'R',style:'tableHeader', alignment:'right'}, {text:'A',style:'tableHeader',alignment:'right'}, {text:'G',style:'tableHeader',alignment:'right'}, {text:'%',style:'tableHeader',alignment:'right'}]
			]
		};
		_.forEach(data, function(rowData){
			var newRow = [{text:rowData.name, bold:true}, {text:rowData.red,alignment:'right',fillColor:vm.red},  {text:rowData.amber,alignment:'right',fillColor:vm.amber},  {text:rowData.green,alignment:'right',fillColor:vm.green},{text:rowData.percentage,alignment:'right'}];
			tblObj.table.body.push(newRow);
		})
		tblObj.layout = layout1;
		return tblObj;
	};
	
	function twoColTable(title, helper, data){
		var tblObj = {};
		tblObj.style = 'tableExample';
		tblObj.table = {
			headerRows:1,
			widths: ['*', 30],
			body : [
				[{text:title + ' \n(' + helper + ')', style:'tableHeader', colSpan:'2'}, {}]
			]
		};
		_.forEach(data, function(rowData){
			var newRow = [{text:rowData.name, bold:true}, {text:rowData.val,alignment:'right'}];
			tblObj.table.body.push(newRow);
		});
		tblObj.layout = layout1;
		return tblObj;
	};
	  
	
	// pdf report document definition object
	var fullReportDD = {
		content: [
			{text: 'Marketing Compliance MI Report ', style: 'header'},
			{text: '', style: 'subheader'}
		],
		styles: {
			header: {
				fontSize: 18,
				bold: true,
				margin: [0, 0, 0, 10],
			},
			subheader: {
				fontSize: 16,
				bold: true,
				margin: [0, 10, 0, 5]
			},
			tableExample: {
				margin: [0, 5, 0, 15],
				fontSize:10,
				width:'100%'
			},
			tableHeader: {
				bold: true,
				color: 'gray',
				fillColor:'#f6f6f6'
			}
		},
		defaultStyle: {
			color:'#1e1e1e'
			// alignment: 'justify'
		}

	};  
	  
	// download full report PDF
	vm.openPdf = function(reportingData){
		//create Document definition object using JSON Data.
		_.forEach(reportingData.tables, function(tableData){
			switch(tableData.type){
				case 'conditional':
					var tempTable = new conditionalTable(tableData.title, tableData.helper, tableData.data);
					break;
				case 'trafficLights':
					var tempTable = new trafficLightsTable(tableData.title, tableData.helper, tableData.data);
					break;
				case 'docInfo':
					var tempTable = new docInfoTable(tableData.title, tableData.helper, tableData.data);
					break;
				case 'twoCol':
					var tempTable = new twoColTable(tableData.title, tableData.helper, tableData.data);
					break;
				default:
					
			}
			fullReportDD.content.push(tempTable);  
		});
		
		
		fullReportDD.content[1].text = reportingData.date;
		//generate and download PDF
 		pdfMake.createPdf(fullReportDD).download('Marketing Compliance MI Report - ' + vm.monthlyDate);
	}
	//old reporting functions - replaced July 2018
/*
    var sendReport = function(period){
      Reports.createReport({
        "reportType": period,
        "to": $scope.$storage.loggedInUser.email
      }).$promise.then(function (response) {
        console.log("createReport", response);
      });
    }


    vm.sendReportDialog = function (period) {
      var confirm = $mdDialog.confirm()
        .title('Email report')
        .htmlContent('Send Excel report to:'+$scope.$storage.loggedInUser.email+'<br />')
        .ariaLabel('Email report')
        .ok('Send')
        .cancel('Cancel');

      $mdDialog.show(confirm)
        .then(function () {
          sendReport(period);
        }, function () {

        });
    }
	*/
	//dummy JSON Data
	
    vm.reportingData = {
		date:'May 2018',
		tables:[
			{
				title:'Dummy Data: Fin Proms',
				helper:'Shown as % of Live Fin Proms',
				type:'conditional',
				data:[
					{
						name:'Not real data',
						helper:'It\'s just made up to test my code',
						percentage:'25%',
						color:'red',
						number:'7',
						total:'354'
					},
					{
						name:'BGEU Black Assets',
						helper:'% of Fin Proms that have expired but not yet withdrawn, as at date of report',
						percentage:'0%',
						color:'green',
						number:'0',
						total:'42'
					},
					{
						name:'Example Black Assets',
						helper:'% of Fin Proms that have expired but not yet withdrawn, as at date of report',
						percentage:'12%',
						color:'amber',
						number:'1',
						total:'8'
					},
					{
						name:'Some other Black Assets',
						helper:'% of Fin Proms that have expired but not yet withdrawn, as at date of report',
						percentage:'5%',
						color:'yellow',
						number:'3',
						total:'61'
					}
				]
			},
			{
				title:'Force Expiry',
				helper:'Live / Published documents expired in reporting month due to "Compliance" or "External Regulatory Body"',
				type:'docInfo',
				data:[
					{
						id:'6396',
						bookCode:'BIN-LIFE-IPID-EN-1802-v1.2-XXXX-0003400',
						docType:'IPID',
						owner:'Henry Kasparian'
					},
					{
						id:'1643',
						bookCode:'BIN-GENE-BROA-EN-0412-v1.03-XXXX-0003981',
						docType:'Broadcasting (TV, Radio, Video)',
						owner:'Lucy Donaldson'
					}
				]
			},
			{
				title:'Rejections: Global',
				helper:'Rejected by MCOMP/Compliance in reporting month, ranked by severity - incl. documents not live at time of report',
				type:'trafficLights',
				data:[
					{
						name:'Total Global Rejections',
						red:'8',
						amber:'54',
						green:'',
						percentage:'35%'
					},
					{
						name:'Department: BG Travel',
						red:'',
						amber:'',
						green:'',
						percentage:'-'
					},
					{
						name:'Department: Business Services',
						red:'6',
						amber:'1',
						green:'3',
						percentage:'8%'
					},
					{
						name:'Department: Customer Services',
						red:'8',
						amber:'54',
						green:'',
						percentage:'35%'
					}
				]
			},
			{
				title:'Incomplete Records',
				helper:'Documents that have been in the Publishing workflow for +1 month, excluding Corportate Product Owned Documents',
				type:'twoCol',
				data:[
					{
						name:'Total number of Documents (See "Incomplete Records" for full details',
						val:'188'
					},
					{
						name:'Number of which were on April\'s MI report',
						val:'98'
					}
				]
			},
			{
				title:'A second Traffic light table',
				helper:'just to prove traffic light tables work',
				type:'trafficLights',
				data:[
					{
						name:'different data',
						red:'7',
						amber:'',
						green:'53',
						percentage:'74%'
					},
					{
						name:'Department: Business Services',
						red:'6',
						amber:'1',
						green:'3',
						percentage:'8%'
					},
					{
						name:'No problems here',
						'red':'',
						amber:'',
						green:'',
						percentage:'-'
					},
					{
						name:'Department: Customer Services',
						red:'8',
						amber:'54',
						green:'',
						percentage:'35%'
					}
				]
			},

		]
	}
    

  }
  ReportsCtrl.$inject = ["$filter", "$localStorage", "$location", "$mdDialog", "$scope", "$timeout", "Reports", "Users", "Wraps"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name profile
   * @description
   *
   */
  angular
    .module('profile', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('profile')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('profile', {
        url: '/profile',

          views: {
			  tooltip: {
				  templateUrl: 'tooltip/tooltip.tpl.html',
				  controller: 'TooltipCtrl',
				  controllerAs: 'tooltip'
			  },
              navigation: {
                  templateUrl: 'navigation/navigation.tpl.html',
                  controller: 'NavigationCtrl',
                  controllerAs: 'navigation'
              },
              main: {
                  templateUrl: 'profile/profile.tpl.html',
                  controller: 'ProfileCtrl',
                  controllerAs: 'profile'
              },
              footer: {
                templateUrl: 'footer/footer.tpl.html',
                controller: 'FooterCtrl',
                controllerAs: 'footer'
              }
          }
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name profile.controller:ProfileCtrl
   *
   * @description
   *
   */
  angular
    .module('profile')
    .controller('ProfileCtrl', ProfileCtrl);

  function ProfileCtrl($interval, $localStorage, $location,  $q, $scope, $timeout, apiUrl, Locations, Roles, Teams, Upload, Users, Wraps) {
    var vm = this;
    vm.ctrlName = 'ProfileCtrl';
    $scope.$storage = $localStorage;

    delete $scope.$storage.userForm; // should delete legacy data

    //console.log($localStorage);
    if(!Users.isAuthenticated()){$location.path('/');}

    vm.apiUrl = apiUrl;

    //vm.initials = $scope.$storage.userForm.firstName.charAt(0).toUpperCase() + $scope.$storage.userForm.lastName.charAt(0).toUpperCase();

    Users.find({filter: {where: {id: $localStorage.user.id}}}).$promise.then(function (user) {
      vm.userForm = user[0];
		if(user[0].profilePic){
			vm.loadedavatar = {filename:user[0].profilePic.split('/').pop()}; //profile pic filename profilePic
		}
    });
    
    vm.roles = Roles.find({});
    vm.locations = Locations.__get__notDeleted();
    vm.teams = Teams.__get__notDeleted();

    vm.deleteProfilePic = function(user) {
		vm.userForm.profilePic = '';
		$scope.usersForm.$setDirty();
    }

    vm.saveRow = function (user) {
      vm.isLoading = true;
      user.lastUpdated = Date.now();
      Users.prototype$updateAttributes({id: user.id}, user, function(){
        $timeout(function () {
          $localStorage.user.firstName = user.firstName;
          $localStorage.user.lastName = user.lastName;
          $localStorage.user.jobTitle = user.jobTitle;
          $localStorage.user.email = user.email;
          $localStorage.user.contactEmail = user.contactEmail;
          $localStorage.user.tel = user.tel;
          $localStorage.user.location = user.location;
		  $localStorage.user.profilePic = user.profilePic;
          vm.initials = $localStorage.user.firstName.charAt(0) + $localStorage.user.lastName.charAt(0);
          vm.isLoading = false;
		  vm.saved = "success";
		  $scope.usersForm.$setPristine();
        }, 1000);
      }, function(err){console.error(err); vm.saved = "error"; $scope.usersForm.$setPristine();});
    };
	var possibleclasses = "abcdefghijklmnopq";
	//vm.avatarColor = possibleclasses.charAt(Math.floor(Math.random() * possibleclasses.length));

    var timestamp = new Date().getTime();
    $scope.$watch('profile.profilePic', function () {
      if(vm.profilePic) {
        vm.upload(vm.profilePic).then(function (statuses) {
          vm.isLoading = false;
        }).catch(function (error) {
          console.log("Error:", error);
          vm.isLoading = false;
        });
      }
    });
    
    vm.upload = function (file) {
      var S3_BUCKET_URL = apiUrl + '/containers/wraps-files/upload';
      var deferred = $q.defer();

      if (file && !file.$error) {
        vm.isLoading = true;

        file.container = 'wraps-files';
        file.fileLocation = 'profilePics/' + timestamp;
        file.fileName = file.name;
        file.fileSize = file.size;
        vm.profilePic.filesList = [];
        vm.profilePic.filesList.push(file);
        //console.log(vm.JSONFile);

        //TODO add timeout to animate files
        var newName = file.fileLocation + '/' + file.name;
        Upload.rename(file, newName );
        Upload.upload({
          url: S3_BUCKET_URL,
          file: file

        }).then(function (resp) {
          resp.config.file.fileName = resp.config.file.fileName.replace(/\.[^\.]+$/, '.jpg');
          var count = 0;
		  vm.thumbgen = true;
          var interval = $interval( function(){
              Wraps.isImage(apiUrl + '/containers/getthumbnail/' + resp.config.file.fileLocation + '/' + resp.config.file.fileName).then(function(rtn){
            	if (rtn === true){
                  $interval.cancel(interval);
                  //console.log(resp.config.file);
                  vm.userForm.profilePic = resp.config.file.fileLocation + '/' + resp.config.file.fileName;
                  vm.thumbgen = false;
                  deferred.resolve(resp);
            	}
            });
            if(count++ >= 10){ //waiting 10 seconds before finishing
              $interval.cancel(interval);
			  vm.thumbgen = false;
              deferred.reject("thumbnail timeout");
            }
          }, 1000);

          
        }, null, function (evt) {
          file.determinateValue = parseInt(100.0 *   evt.loaded / evt.total);
        });
      }else{
		  deferred.reject("upload image error");
	  }
      return deferred.promise;
    };



    vm.removeFile = function(array, index){
    	//console.log(index);
    	array.splice(index, 1);
		vm.userForm.profilePic = '';
    };
    
    
  }
  ProfileCtrl.$inject = ["$interval", "$localStorage", "$location", "$q", "$scope", "$timeout", "apiUrl", "Locations", "Roles", "Teams", "Upload", "Users", "Wraps"];;

}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name newLibrary
   * @description
   *
   */
  angular
    .module('newLibrary', [
      'ui.router'
    ]);
}());

(function () {
    'use strict';

    angular
        .module('newLibrary')
        .config(config);

    function config($stateProvider) {
        $stateProvider
            .state('newLibrary', {
                url: '/new-library',

                views: {
                    navigation: {
                        templateUrl: 'navigation/navigation.tpl.html',
                        controller: 'NavigationCtrl',
                        controllerAs: 'navigation'
                    },
                    main: {
                        templateUrl: 'new-library/new-library.tpl.html',
                        controller: 'NewLibraryCtrl',
                        controllerAs: 'newLibrary'
                    },
                    footer: {
                        templateUrl: 'footer/footer.tpl.html',
                        controller: 'FooterCtrl',
                        controllerAs: 'footer'
                    }
                }
            });
    }
    config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name newLibrary.controller:NewLibraryCtrl
   *
   * @description
   *
   */
  angular
    .module('newLibrary')
    .controller('NewLibraryCtrl', NewLibraryCtrl);

  function NewLibraryCtrl(WrapsBooks) {
    var vm = this;
    vm.ctrlName = 'NewLibraryCtrl';

    vm.isLoading = true;
    WrapsBooks.books.then(function (books) {
      vm.isLoading = false;
      vm.books = books;
    });

    // setInterval(function(){
    //   self.books = getBooks();
    // },10000);
    
  }
  NewLibraryCtrl.$inject = ["WrapsBooks"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name navigation
   * @description
   *
   */
  angular
    .module('navigation', [
      'ui.router',
      'library.notifications'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('navigation')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('navigation', {
        url: '/navigation',
        templateUrl: 'navigation/navigation.tpl.html',
        controller: 'NavigationCtrl',
        controllerAs: 'navigation'
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name navigation.controller:NavigationCtrl
   *
   * @description
   *
   */
  angular
    .module('navigation')
    .controller('NavigationCtrl', NavigationCtrl);

  function NavigationCtrl($injector, $rootScope, $localStorage, $location, clientConfig, $mdDialog, $mdSidenav, $window, $scope, $state, system, apiUrl, Books, Notifications, Teams, Users, Wraps) {
    var vm = this;
    vm.ctrlName = 'NavigationCtrl';
    vm.thisTeam = '';

    vm.system = system;
    vm.clientConfig = clientConfig;
    $scope.$storage = $localStorage;
    vm.apiUrl = apiUrl;

    vm.isActive = function (viewLocation) {
      var locationPath = $location.path().substring(0, 7) == "/reset/" ? $location.path().substring(0, 6) : $location.path();
      return viewLocation === locationPath;
    };

    if (Users.isAuthenticated()) {

      vm.hasPermissions = Wraps.hasPermissions;
      vm.hasAnyOfPermissionCat = Wraps.hasAnyOfPermissionCat;

      Users.find({ "filter": { "include": ["Roles", "Teams", "UsersStatus", "Divisions", { "PermissionGroups": [{ "PermissionGroupJoin": ["Permissions"] }] }] } }).$promise.then(function (results) {
        //Users.find({"filter": {"include": ["Teams", "Roles", "Divisions"]}}).$promise.then(function (results) {
        vm.userslist = results;
      });

      Teams.__get__notDeleted(function (results) {
        vm.teamslist = results;
      });


      if ($scope.$storage.loggedInUser && _.isNumber($scope.$storage.loggedInUser.loggedInAs) && $scope.$storage.loggedInUser.loggedInAs !== 0) {
        Users.find({ "filter": {
          "where": { "id": $scope.$storage.loggedInUser.loggedInAs },
          "include": ["Roles", "Teams", "UsersStatus", "Divisions", { "PermissionGroups": [{ "PermissionGroupJoin": ["Permissions"] }] }]
        } }, function (spoofedUser) {
            if (spoofedUser[0].PermissionGroups && spoofedUser[0].PermissionGroups.PermissionGroupJoin.length && spoofedUser[0].PermissionGroups.PermissionGroupJoin.length > 0) {
                var permissions = _.map(spoofedUser[0].PermissionGroups.PermissionGroupJoin, 'Permissions');
                delete spoofedUser[0].PermissionGroups;
                if (permissions && permissions.length > 0) {
                    spoofedUser[0].permissions = permissions;
                }
            }
          $scope.$storage.user = spoofedUser[0];
        });
      }

      Notifications.getCounts();
      vm.notificationCount = Notifications.count; //init the obj binding

      $rootScope.$on('NOTIFICATION_COUNT_UPDATE', function () {
        vm.notificationCount = Notifications.count;
      });

      vm.switchToUserDiag = function () {
          vm.showSwitchUserDiag([], []);
      };

      vm.showSwitchUserDiag = function () {
        $mdDialog.show({
          controller: 'NavigationCtrl',
          controllerAs: 'navigation',
          templateUrl: 'navigation/user-switch/user-switch.tpl.html',
          parent: angular.element(document.body),
          /*locals: { restrictedDocs: restrictedDocs, openBooksArray: openBooksArray },*/
          bindToController: true,
          clickOutsideToClose: true
        })
          .then(function () {
          }, function () {
          });
      }

      vm.switchUser = function (user) {
        vm.thisUser = user;
        $scope.$storage.user = user;
        $scope.$storage.loggedInUser.loggedInAs = user.id;
        var userInWorkflow;
        Users.update({ "where": { "id": $localStorage.loggedInUser.id } }, { "loggedInAs": user.id }, function () {
          if (user.PermissionGroups && user.PermissionGroups.PermissionGroupJoin.length && user.PermissionGroups.PermissionGroupJoin.length > 0) {
            var permissions = _.map(user.PermissionGroups.PermissionGroupJoin, 'Permissions');
            delete user.PermissionGroups;

            Wraps.user = vm.thisUser;
            if (permissions && permissions.length > 0) {
              Wraps.user.permissions = permissions;
            }
            $scope.$storage.user = Wraps.user;
          }

          /*_.forEach($scope.$storage.openTabs, function (openTab) {
            _.forEach(restrictedDocs, function (restrictedDoc) {
              if (restrictedDoc.bookId == openTab.bookId) {
                $rootScope.$emit('closeUnauthorisedTab', { "bookId": openTab.bookId });
              }
            });
          });*/

          if ($location.path().includes('/library')) {
            var WrapsBooks = $injector.get('WrapsBooks');
            WrapsBooks.getLibraryList();
            /*if(_.isNumber($state.params.bookId) && $state.params.bookId > 0) {
              WrapsBooks.updateBookById($state.params.bookId);
            }*/
            WrapsBooks.updateAllTabs();
          } else if ($location.path().includes('/home')) {
            $rootScope.$emit('updateHomeStats');
          }else if (!$location.path().includes('/home') && !$location.path().includes('/library')) {
            $window.location.reload();
          }
          Notifications.getCounts();
          $mdDialog.hide();
        });
      };

      vm.dialogCancel = function () {
        $mdDialog.cancel();
      };



      vm.userProfile = function () {
        vm.tabIndex = 0;
        $mdSidenav('right').toggle();
      };
      $scope.helpMethodLibFun = function (helpCode) {
        Wraps.displayHelpText(helpCode);
      }

      vm.navbarStatsDialog = function (tab) {
        $mdDialog.show({
          controller: 'NotificationsCtrl',
          controllerAs: 'notifications',
          templateUrl: 'library/notifications/notifications.tpl.html',
          parent: angular.element(document.body),
          locals: { activeContent: tab },
          bindToController: true,
          clickOutsideToClose: true
        })
          .then(function () {
            /*if (data) {
             var book = vm.bookById(activeContent.bookId);
             book.update();
             }*/
          }, function () {
            //vm.status = 'You cancelled the dialog.';
          });
      };
      vm.users = function () {
        $mdDialog.show(
          $mdDialog.alert()
            .title('User Area')
            .content('You do not have permissions to use this area')
            .ok('Close')
        );
      };

      vm.editAsMe = function () {
        Users.update({ "where": { "id": $localStorage.loggedInUser.id } }, { "loggedInAs": 0 }, function () {
          $scope.$storage.user = $scope.$storage.loggedInUser;
          Wraps.user = $scope.$storage.loggedInUser;
          vm.thisUser = /*angular.copy(*/$scope.$storage.loggedInUser/*)*/;
          Wraps.getCurrentUser(false).then(function () {
            vm.updateForUser();
            Notifications.getCounts();
            if (!$location.path().includes('/home') && !$location.path().includes('/library')) {
              $window.location.reload();
            }
          })
        });
      };

      vm.updateForUser = function () {
        if ($location.path().includes('/library')) {
          var WrapsBooks = $injector.get('WrapsBooks');
          WrapsBooks.getLibraryList();
          /*if(_.isNumber($state.params.bookId) && $state.params.bookId > 0) {
            WrapsBooks.updateBookById($state.params.bookId);
          }*/
          WrapsBooks.updateAllTabs();
        } else if ($location.path().includes('/home')) {
          $rootScope.$emit('updateHomeStats');
        }
        Notifications.getCounts();
      }

      vm.spoofingUser = function () {
        var mismatchedIds = false;
        if ($scope.$storage.user && $scope.$storage.loggedInUser &&
            $scope.$storage.user.id && $scope.$storage.loggedInUser.id) {
          mismatchedIds = $scope.$storage.user.id !== $scope.$storage.loggedInUser.id;
        }
        return mismatchedIds;

      }

      vm.checkUser = function () {
        vm.viewRestrictedDocs = false;
        _.forEach(vm.thisUser.PermissionGroups.PermissionGroupJoin, function (permission) {
          if (permission.permissionId == 54) {
            vm.viewRestrictedDocs = true;
          }
        })
      }

    }
  }
  NavigationCtrl.$inject = ["$injector", "$rootScope", "$localStorage", "$location", "clientConfig", "$mdDialog", "$mdSidenav", "$window", "$scope", "$state", "system", "apiUrl", "Books", "Notifications", "Teams", "Users", "Wraps"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name users
   * @description
   *
   */
  angular
    .module('maintenance', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name maintenance.filter:titlecase
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('maintenance')
    .filter('titlecase', titlecase);

  function titlecase() {
    return function (str) {
    	var withspaces = str.replace(/([a-z])([A-Z])/g, "$1 $2");
		return withspaces.charAt(0).toUpperCase() + withspaces.slice(1);
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name maintenance.directive:rowEdit
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="maintenance">
       <file name="index.html">
        <row-edit></row-edit>
       </file>
     </example>
   *
   */
  angular
    .module('maintenance')
    .directive('rowEdit', rowEdit);

  function rowEdit() {
    return {
      restrict: 'E',
      scope: false,
      templateUrl: 'maintenance/row-edit-directive.tpl.html',
      link: function (scope, element, attrs) {
        /* jshint unused:false */
        /* eslint "no-unused-vars": [2, {"args": "none"}] */
      }
    };
  }
}());

(function () {
  'use strict';

  angular
    .module('maintenance')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('maintenance', {
        url: '/maintenance',
        views: {
		  tooltip: {
              templateUrl: 'tooltip/tooltip.tpl.html',
              controller: 'TooltipCtrl',
              controllerAs: 'tooltip'
          },
          navigation: {
            templateUrl: 'navigation/navigation.tpl.html',
            controller: 'NavigationCtrl',
            controllerAs: 'navigation'
          },
          main: {
            templateUrl: 'maintenance/maintenance.tpl.html',
            controller: 'MaintenanceCtrl',
            controllerAs: 'maintenance'
          },
          footer: {
            templateUrl: 'footer/footer.tpl.html',
            controller: 'FooterCtrl',
            controllerAs: 'footer'
          }
        }
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name maintenance.controller:MaintenanceCtrl
     *
     * @description
     * Table definitions-
     * Seporate: 2
     * Conditional: 1
     * Shared: 3
     *
     */
    angular
        .module('maintenance')
        .controller('MaintenanceCtrl', MaintenanceCtrl);

    function MaintenanceCtrl(
        $filter,
        $localStorage,
        $location,
        $mdDialog,
        $mdSidenav,
        $q,
        $scope,
        $state,
        $timeout,
        Audiences,
        Clients,
        Departments,
        Distributors,
        DivisionMetaLink,
        Divisions,
        DocContents,
        DocTypes,
        DocTypeCats,
        EnclosureTypes,
        Insurers,
        Languages,
        Locations,
        MetaTables,
        NotificationTypes,
        Products,
        ProductTypes,
        ProductIds,
        Projects,
        Regions,
        RfcCategories,
        RfcReasons,
        Roles,
        SocialChannels,
        SubInsurers,
        Teams,
        Tooltips,
        Users,
        Wraps) {
        var vm = this;
        vm.ctrlName = 'MaintenanceCtrl';


        $scope.$storage = $localStorage;
        vm.hasAccess = null;
        vm.hasPermissions = Wraps.hasPermissions;
        vm.hasAnyOfPermissionCat = Wraps.hasAnyOfPermissionCat;
        vm.tables = {};
        vm.divisionId = $scope.$storage.loggedInUser.Divisions.divisionId;
        vm.divisionName = $scope.$storage.loggedInUser.Divisions.division;
        //vm.searchMaintenance = false;

        if (!Users.isAuthenticated()) {
            $location.path('/');
        }

        MetaTables.find({ filter: { order: "tableName" } }, function (results) {
            //vm.tables = results;
            _.forEach(results, function (tables) {
                vm.tables[tables.tableName] = tables;
                vm.tables[tables.tableName].fields = angular.fromJson(vm.tables[tables.tableName].fields);
                vm.tables[tables.tableName].dropDown = angular.fromJson(vm.tables[tables.tableName].dropDown);
                vm.tables[tables.tableName].hasMany = angular.fromJson(vm.tables[tables.tableName].hasMany);
                vm.tables[tables.tableName].disabled = true;
            });

            Wraps.fetchedUser.then(function () {
                _.forEach(vm.tables, function (val, tableName) {
                    vm.tables[tableName].disabled = !(vm.hasPermissions('admin' + tableName) || vm.hasPermissions('adminDivision' + tableName));
                });

                vm.hasAccess = vm.hasAnyOfPermissionCat(3) || vm.hasAnyOfPermissionCat(6);
                if (vm.hasAccess == false) {
                    $state.go('accessDenied', { src: 'Meta List Maintenance' });
                }

                for (var tableName in vm.tables) {
                    if (!vm.tables[tableName].disabled) {
                        vm.changeTable(tableName);
                        break;
                    }
                }
            });

        });

        $scope.$watch('maintenance.filteredRows.length', function () {
            vm.maintQuery.page = 1;
        }, true);


        vm.changeTable = function (tableName) {
            vm.cols = vm.tables[tableName].fields;
            vm.pk = vm.tables[tableName].pk;
            vm.tableId = vm.tables[tableName].metaTableId;
            vm.tableName = tableName;
            vm.deletedCol = vm.tables[tableName].deletedCol;
            vm.orderBy = vm.tables[tableName].orderby;
            vm.tableBehaviour = vm.tables[tableName].tableBehaviour;
            vm.reverse = false;
            vm.maintQuery = {
                limit: 15,
                page: 1
            };

            if (vm.hasPermissions('admin' + tableName)) {
                vm.tableAdmin = true;
            } else if (vm.hasPermissions('adminDivision' + tableName)) {
                vm.tableAdmin = false;
            }

            //$scope.$storage.selectedRow = '';
            $scope.$storage[tableName] = {};
            vm.grabRows(tableName);
        };

        vm.toggleDivisionMeta = function (row) {
            var status;
            if (row.selected === true) {
                status = 1;
            } else {
                status = 0;
            }

            DivisionMetaLink.upsertWithWhere(
                {
                    "where": {
                        "metaTableId": vm.tables[vm.tableName].metaTableId,
                        "metaRowId": row[vm.pk],
                        "divisionId": vm.divisionId
                    }
                },
                {
                    "metaTableId": vm.tables[vm.tableName].metaTableId,
                    "metaRowId": row[vm.pk],
                    "divisionId": vm.divisionId,
                    "status": status
                }
            );

        };

        $scope.sortBy = function (propertyName) {
            //console.log(vm.tables[vm.tableName].dropDown);
            if (vm.tables[vm.tableName].dropDown && vm.tables[vm.tableName].dropDown[propertyName] && vm.tables[vm.tableName].dropDown[propertyName].childColName == propertyName) {
                //for dropdown fields
                vm.reverse = (vm.orderBy === vm.tables[vm.tableName].dropDown[propertyName].tableName + "." + propertyName) ? !vm.reverse : false;
                vm.orderBy = vm.tables[vm.tableName].dropDown[propertyName].tableName + "." + propertyName;
            } else {
                vm.reverse = (vm.orderBy === propertyName) ? !vm.reverse : false;
                vm.orderBy = propertyName;
            }
        };

        //table filter info
        if (!vm.maintQuery) {
            vm.maintQuery = {
                order: 'id',
                limit: 15,
                page: 1
            };
        }


        $scope.$watch('maintenance.selectedTabIndex', function (current, old) {
            var i = 0;
            _.forEach(vm.tables, function (tableinfo, tableName) {
                if (i == current) {
                    vm.changeTable(tableName);
                }
                i++;
            });
        });

        vm.grabRows = function (tableName) {

            vm.rows = [];
            vm.isLoading = true;
            vm.deleteArray = {};
            vm.joinTableData = {};


            if (vm.tableAdmin === true) {
                vm.getRowsForAdminUser();
            } else {
                vm.getRowsForDivisionUser();
            }
        };

        vm.getRowsForDivisionUser = function () {
            var args = {};
            var divisionMetaLink;
            var rowData = [];

            DivisionMetaLink.find({
                "filter": {
                    "where": {
                        "metaTableId": vm.tables[vm.tableName].metaTableId,
                        "divisionId": vm.divisionId,
                        "status": 1
                    }
                }

            }, function (divisionMetaLinks) {

                if (vm.tables[vm.tableName].dropDown) {
                    var joinTables = [];
                    _.forEach(vm.tables[vm.tableName].dropDown, function (dropDown) {
                        joinTables.push(dropDown.tableName);
                        args = { "filter": { "where": {}, "order": dropDown.childColName + ' ASC' } };
                        args.filter.where[dropDown.parentStatusCol] = 1;
                        vm.joinTableData[dropDown.tableName] = eval(dropDown.tableName).find(args);
                    });
                    args = { "filter": { "include": joinTables, "where": {} } };
                    args.filter.where[vm.deletedCol] = 1;
                } else {
                    args = { "filter": { "where": {} } };
                    args.filter.where[vm.deletedCol] = 1;
                }


                eval(vm.tableName).find(args).$promise.then(function (results) {
                    _.forEach(results, function (row, key) {
                        divisionMetaLink = divisionMetaLinks.filter(function (item) {
                            return item.metaTableId == vm.tables[vm.tableName].metaTableId && item.metaRowId == row[vm.pk] && item.divisionId == vm.divisionId;
                        });

                        if (typeof divisionMetaLink[0] !== 'undefined') {

                            if (vm.tableBehaviour == 1) {
                                results[key].divisionLinkId = divisionMetaLink[0].divisionMetaLinkId;
                                results[key].selected = true;
                            } else if (vm.tableBehaviour == 2 && divisionMetaLink[0].divisionId == vm.divisionId) {
                                rowData.push(row);
                            }
                        }
                    });

                    if (vm.tableBehaviour == 1 || vm.tableBehaviour == 3) {
                        vm.rows = results;
                    } else if (vm.tableBehaviour == 2) {
                        vm.rows = rowData;
                    }
                    vm.isLoading = false;
                });

            });
        }

        vm.getRowsForAdminUser = function () {
            var args = {};

            DivisionMetaLink.find({
                "filter": {
                    "include": "Divisions",
                    "where": {
                        "metaTableId": vm.tables[vm.tableName].metaTableId,
                        "status": 1
                    }
                }

            }, function (divisionMetaLinks) {
                if (vm.tables[vm.tableName].dropDown) {
                    var joinTables = [];
                    _.forEach(vm.tables[vm.tableName].dropDown, function (dropDown) {
                        joinTables.push(dropDown.tableName);
                        args = { "filter": { "where": {}, "order": dropDown.childColName + ' ASC' } };
                        args.filter.where[dropDown.parentStatusCol] = 1;
                        vm.joinTableData[dropDown.tableName] = eval(dropDown.tableName).find(args);
                    });
                    args = { "filter": { "include": joinTables, "where": {} } };
                    args.filter.where[vm.deletedCol] = 1;
                } else {
                    args = { "filter": { "where": {} } };
                    args.filter.where[vm.deletedCol] = 1;
                }


                var divisionMetaLink;
                var divisions = [];

                eval(vm.tableName).find(args).$promise.then(function (results) {
                    _.forEach(results, function (row, key) {
                        divisionMetaLink = divisionMetaLinks.filter(function (item) {
                            return item.metaTableId == vm.tables[vm.tableName].metaTableId && item.metaRowId == row[vm.pk];
                        });
                        if (typeof divisionMetaLink !== 'undefined' && !_.isEmpty(divisionMetaLink)) {
                            _.forEach(divisionMetaLink, function (link) {
                                divisions.push(link.Divisions);
                            });
                            results[key].divisions = divisions;
                            divisions = [];
                        }
                    });
                    vm.rows = results;
                    vm.isLoading = false;
                });
            });
        }

        vm.compareJson = function (objA, objB) {
            return (angular.toJson(objA) == angular.toJson(objB));
        };

        vm.saveRow = function (row, action) {

            vm.errors = {};
            vm.isLoading = true;
            var pkObj = {};
            pkObj[vm.pk] = row[vm.pk];

            _.forEach(vm.cols, function (col) { //loop through columns/fields
                var colName = col.name;

                if (typeof vm.tables[vm.tableName].dropDown != 'undefined' && vm.tables[vm.tableName].dropDown !== null && typeof vm.tables[vm.tableName].dropDown[colName] != 'undefined') {
                    delete row[vm.tables[vm.tableName].dropDown[colName].tableName];
                    colName = vm.tables[vm.tableName].dropDown[colName].parentColName;
                }

                if (col.required && !row[colName] && colName != vm.pk) {
                    vm.errors[colName] = 'This field is required';
                } else if (col.unique && colName != vm.pk) { //check they are meant to be unique and that they're not the primary key.
                    var found = _.find(vm.rows, function (item) { //find matching data in existing rows
                        return (item[colName] + '').toLowerCase().trim() == (row[colName] + '').toLowerCase().trim();
                    });
                    if (found && found[vm.pk] != row[vm.pk]) {  //check for found row unless it's the current row.
                        vm.errors[colName] = 'An entry with this value already exists';
                        vm.isLoading = false;
                    }
                }
            });
            if (_.isEmpty(vm.errors)) {
                if (action == 'edit') {
                    eval(vm.tableName).prototype$updateAttributes(pkObj, row, function (row) {
                        if (vm.tableBehaviour == 1 || vm.tableBehaviour == 2) {
                            var promises = {};


                            _.forEach(vm.divisions, function (division) {

                                var divisionDefer = $q.defer();
                                promises[division.divisionId] = divisionDefer.promise;

                                if (typeof division.selected === 'undefined') {
                                    division.selected = false;
                                }

                                if (vm.tableAdmin === false && division.divisionId === vm.divisionId) {
                                    division.selected = true;
                                }

                                DivisionMetaLink.upsertWithWhere(
                                    {
                                        "where": {
                                            "metaTableId": vm.tableId,
                                            "metaRowId": row[vm.pk],
                                            "divisionId": division.divisionId
                                        }
                                    },
                                    {
                                        "metaTableId": vm.tableId,
                                        "metaRowId": row[vm.pk],
                                        "divisionId": division.divisionId,
                                        "status": division.selected //division.divisionId == 1 ? true : false //division.selected //---hiding divisions---//
                                    }, function () {
                                        divisionDefer.resolve();
                                    });
                            });
                            $q.all(promises).then(function () {
                                //all division meta link upserts are complete.
                                vm.closeEditAndGrabRows();
                            });
                        } else {
                            vm.closeEditAndGrabRows();
                        }

                    },
                        function () {
                            //error
                        });
                } else if (action == 'new') {
                    row[vm.pk] = 0;
                    eval(vm.tableName).create(row, function (result) {

                        if (vm.tableBehaviour == 1 || vm.tableBehaviour == 2) {
                            vm.insertIntoMetaLinkTable(result[vm.pk]);
                        } else {
                            vm.closeEditAndGrabRows();
                        }
                    });
                }
            } else {
                vm.isLoading = false;
            }

        };

        vm.insertIntoMetaLinkTable = function (metaRowId) {

            if (vm.tableAdmin === true) {
                _.forEach(vm.divisions, function (division) {

                    if (typeof division.selected === 'undefined') {
                        division.selected = false;
                    }

                    DivisionMetaLink.upsertWithWhere(
                        {
                            "where": {
                                "metaTableId": vm.tableId,
                                "metaRowId": metaRowId,
                                "divisionId": division.divisionId
                            }
                        },
                        {
                            "metaTableId": vm.tableId,
                            "metaRowId": metaRowId,
                            "divisionId": division.divisionId,
                            "status": division.divisionId == 1 ? true : false //division.selected //---hiding divisions---//
                        }, function () {
                            vm.closeEditAndGrabRows();
                        });
                });
            } else {
                DivisionMetaLink.create({
                    "metaTableId": vm.tableId,
                    "metaRowId": metaRowId,
                    "divisionId": vm.divisionId
                }, function () {
                    vm.closeEditAndGrabRows();
                });
            }


        }


        vm.closeEditAndGrabRows = function () {
            $scope.$storage[vm.tableName] = {};
            vm.closeEdit();
            vm.grabRows(vm.tableName);
        }


        vm.deleteRows = function (selectedRows) {
            var confirm = $mdDialog.confirm()
                .title('Delete rows')
                .htmlContent('Are you sure you want to delete these rows?<br />')
                .ariaLabel('Confirm Delete')
                .ok('Delete')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function () {
                var finishedDeletes = {};
                var relationshipErrors = [];
                _.forEach(selectedRows, function (tbd, key) { //tbd : to be deleted (bool)
                    var rowDefer = $q.defer();
                    finishedDeletes[key] = rowDefer.promise;
                    if (tbd == true) {
                        //if we actually want to delete this row.

                        var relation;
                        if (relation = vm.tables[vm.tableName].hasMany) {
                            var args = { "filter": { "where": {} } };
                            args.filter.where[relation.fk] = parseInt(key);
                            args.filter.where[relation.fdel] = 1;
                            //if table has a hasMany relationship with another table.
                            eval(relation.table).find(args).$promise.then(function (res) { // make call to child table to check for children
                                if (res.length > 0) {
                                    //has children - push row info to error array for prevent alert.
                                    relationshipErrors.push({
                                        rowId: key,
                                        count: res.length,
                                        childTable: relation.table
                                    });
                                    rowDefer.resolve();


                                } else {
                                    //no children associated - okay to delete
                                    vm.doDelete(key).then(function () {
                                        rowDefer.resolve();
                                    });
                                }
                            });
                        } else {
                            //no relationship - okay to delete.
                            vm.doDelete(key).then(function () {
                                rowDefer.resolve();
                            });
                        }
                    } else {
                        rowDefer.resolve();
                    }
                });

                $q.all(finishedDeletes).then(function () {
                    //when all promises are resolved;
                    if (relationshipErrors.length > 0) {
                        //check for relational errors + build error modal.
                        var errorString = '';
                        _.forEach(relationshipErrors, function (row) {
                            errorString += '<li>Row ' + row.rowId + ' has ' + row.count + ' ' + row.childTable + ' assigned to it.</li>'
                        });
                        var preventAlert = $mdDialog.alert().title('Unable to delete all rows').htmlContent('Unable to delete the following rows due to dependencies in other tables. <ul>' + errorString + '</ul>Please delete or reassign these dependants before attempting to delete this row.').ok('Accept');
                        $mdDialog.show(preventAlert);
                    }
                    vm.grabRows(vm.tableName);	//refresh
                });
            });
        };

        vm.doDelete = function (key) {
            var doDelDefer = $q.defer();
            _.forEach(vm.rows, function (row) {
                if (row[vm.pk] == key) {
                    row[vm.deletedCol] = 0;
                    eval(vm.tableName).upsert(row, function () {
                        doDelDefer.resolve();
                    });
                }
            });
            return doDelDefer.promise;
        }

        vm.checkObj = function (obj) {
            var conclusion = true;
            _.forEach(obj, function (element) {

                if (element != '') {
                    conclusion = false;
                }
            });
            return conclusion;
        };


        

        vm.openEdit = function (row) {

            vm.errors = {};
            if (typeof row !== 'undefined') {
                Divisions.find({}, function (divisions) {
                    vm.selectedDivision = vm.divisionId;
                    _.forEach(row.divisions, function (rowDivision) {
                        vm.selectedDivision = rowDivision.divisionId;
                        _.forEach(divisions, function (division, key) {
                            if (rowDivision.divisionId === division.divisionId) {
                                divisions[key].selected = true;
                            }
                        });
                    });
                    vm.divisions = divisions;
                });
            } else {
                Divisions.find({}, function (divisions) {
                    vm.divisions = divisions;
                    vm.selectedDivision = vm.divisionId;
                });
            }

            if (!row) {
                $scope.$storage[vm.tableName] = {};
            } else {
                $scope.$storage[vm.tableName] = angular.copy(row);
            }
            $mdSidenav('right').toggle();
        }

        vm.updateSelection = function (id, entities) {
            angular.forEach(entities, function (division, index) {
                if (division.divisionId == id) {
                    division.selected = true;
                } else {
                    division.selected = false;
                }

            });
        }

        vm.closeEdit = function () {
            vm.isLoading = false;
            $mdSidenav('right').toggle();
        }

        vm.joinDivisions = function (divisions) {
            return _.map(divisions, 'division').join(', ');
        }

        vm.downloadCsv = function () {
            var rows = {};
            var content = [];
            var currentRow;
            var tmp = {};

            _.forEach(vm.filteredRows, function (row) {
                _.forEach(vm.cols, function (col) {

                    if (vm.tables[vm.tableName].dropDown &&
                        vm.tables[vm.tableName].dropDown[col.name] &&
                        row[vm.tables[vm.tableName].dropDown[col.name].tableName]) {
                        rows[col.name] = row[vm.tables[vm.tableName].dropDown[col.name].tableName][col.name];
                    } else {
                        rows[col.name] = row[col.name];
                    }

                });

                if (vm.tableBehaviour != 3) {
                    if (vm.tableAdmin) {
                        tmp[vm.pk] = row[vm.pk];
                        currentRow = _.filter(vm.rows, tmp);
                        rows['divisions'] = vm.joinDivisions(currentRow[0].divisions);
                    } else {
                        rows['divisions'] = vm.divisionName;
                    }
                }

                content.push(rows);
                rows = {};
            });

            return content;
        }

        vm.validateCharactors = function($event, pattern) {
            console.log(pattern);
            var regex = new RegExp(pattern);
            var key = String.fromCharCode(!$event.charCode ? $event.which : $event.charCode);

            if (!regex.test(key)) {
                $event.preventDefault();
                return false;
            }else {
                return true;
            }


        }

        vm.toUppercase = function(val, doit) {
            console.log(val);
            //var key = String.fromCharCode(!$event.charCode ? $event.which : $event.charCode);
            if(val && doit) {
                val = val.toUpperCase();
                //key = key.toUpperCase();
                //console.log(key);
                //$event = key.toUpperCase();
            }
        }


        document.touchstart = {
            setup: function (_, ns, handle) {
                if (ns.includes("noPreventDefault")) {
                    this.addEventListener("touchstart", handle, { passive: false });
                } else {
                    this.addEventListener("touchstart", handle, { passive: true });
                }
            }
        };


    }
    MaintenanceCtrl.$inject = ["$filter", "$localStorage", "$location", "$mdDialog", "$mdSidenav", "$q", "$scope", "$state", "$timeout", "Audiences", "Clients", "Departments", "Distributors", "DivisionMetaLink", "Divisions", "DocContents", "DocTypes", "DocTypeCats", "EnclosureTypes", "Insurers", "Languages", "Locations", "MetaTables", "NotificationTypes", "Products", "ProductTypes", "ProductIds", "Projects", "Regions", "RfcCategories", "RfcReasons", "Roles", "SocialChannels", "SubInsurers", "Teams", "Tooltips", "Users", "Wraps"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name login
   * @description
   *
   */
  angular
    .module('login', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('login')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('login', {
        url: '/login',
        views: {
          navigation: {
            templateUrl: 'navigation/navigation.tpl.html',
            controller: 'NavigationCtrl',
            controllerAs: 'navigation'
          },
          main: {
            templateUrl: 'login/login.tpl.html',
            controller: 'LoginCtrl',
            controllerAs: 'login'
          }
        }

      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name login.controller:LoginCtrl
   *
   * @description
   *
   */
  angular
    .module('login')
    .controller('LoginCtrl', LoginCtrl);

  function LoginCtrl($location, $localStorage, $rootScope, $scope, $timeout, Users, Wraps) {
    var vm = this;
    vm.ctrlName = 'LoginCtrl';
    $scope.$storage = $localStorage;

    vm.isLoginPage = true;

    delete $scope.$storage.userForm; // should delete legacy data
    
    if(Users.isAuthenticated()) {
      Users.logout();
      $scope.$storage.user = {};
      $scope.$storage.loggedInUser = {};

    }

    if($rootScope.pw) {
      vm.credentials = {};
      vm.credentials.password = $rootScope.pw;
      $rootScope.pw = false;
    }

    vm.submitLogin = function (resend) {
      vm.isLoading = true;
      vm.error = false;
      vm.resendMsg = false;

      $timeout(function() {
        if(!resend) {
          Users.login({rememberMe: false}, vm.credentials, function () {
            Wraps.fetchedUser =  Wraps.getCurrentUser(true);

            Wraps.fetchedUser.then(function(){
                var next = $location.nextAfterLogin || '/home';

                $location.nextAfterLogin = null;
                $location.path(next);

                vm.resendMsg = "Login successful";
                vm.isLoading = false;
            }, function(){
                $scope.$storage.user = {};
                vm.error = "Failed to load user";
                vm.isLoading = false;
                $timeout(function() {vm.error = '';},3000);
            });

          }, function () {
            $scope.$storage.user = {};

              vm.error = "Login details incorrect";
              $timeout(function() {vm.error = '';},3000);
              vm.isLoading = false;

          });
        }else{
          Users.resetPassword({ email: vm.credentials.email }, function(){

            //vm.credentials.password = "";


                vm.resendMsg = "Password reset sent to email";
                $timeout(function() {vm.resendMsg = '';vm.resend = false;},3000);
                vm.isLoading = false;

            //vm.isLoading = false;
          },function () {

            vm.resendError = "Email incorrect";
            $timeout(function() {vm.resendError = '';},3000);
          });
        }
      },1000);
    };
    
    
  }
  LoginCtrl.$inject = ["$location", "$localStorage", "$rootScope", "$scope", "$timeout", "Users", "Wraps"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name library.service:Wraps
   *
   * @description
   *
   */
  angular
    .module('library')
    .service('Wraps', Wraps);

  function Wraps(
	$filter,
	$localStorage,
	$location,
	$q,
	$rootScope,
	$sessionStorage,
	$templateCache,
	$timeout,
	$window,
  clientConfig,
	AppVersions,
	Books,
	BooksVersions,
	Clients,
	Containers,
	Contents,
    DivisionMetaLink,
	Divisions,
	DocContents,
    EnclosureTypes,
	Events,
	History,
	Languages,
	Messages,
	NotificationTypes,
    Notifications,
	MetaTables,
	PdfVersions,
	Projects,
	PublishTypes,
	RfcCategories,
	RfcJoin,
	RfcReasons,
	Roles,
	SocialChannels,
	Structures,
	system,
	Teams,
	Users,
	Versions) {
    var self = this;

    self.get = function () {
      return 'Wraps';
    };

    self.$storage = $localStorage;
    self.$session = $sessionStorage;
    self.user = self.$storage.user;



    self.clientConfig = clientConfig;

    self.httpErrors =  false;
    self.toastMessages = false;



    var outerJoin = function(name, array1, link1, array2, link2){
      _.each(array1, function(item){
        item[name] = _.find(array2, [link2, item[link1]]);
      });
      return array1;
    }





    self.emphasis = [

      {"id":1,"name":"Bold"},
      {"id":2,"name":"Italic"},
      {"id":3,"name":"None"}
    ];

    self.BGHPColour = [
      {"id":0,"name":"Gold"},
      {"id":1,"name":"Silver"},
      {"id":2,"name":"Navy"}
    ];



    self.states = [
      {  "id": 1, "name": "DLO" },
      { "id": 2, "name": "MCO" },
      {  "id": 3, "name": "Design"  },
      {  "id": 4, "name": "Approve" }
    ];

    self.levels = {
      "level1": {"name": "Essential", "active": true},
      "level2": {"name": "Classic", "active": true},
      "level3": {"name": "Gold", "active": true},
      "level4": {"name": "Gold Superior", "active": true},
      "payment": {"name": "Payment for treatment", "active": false}
    };

    self.chapterTypes = [
      { "chapterTypeId": 1, "chapterTypeName": "Text" },
      { "chapterTypeId": 2, "chapterTypeName": "Front Cover" },
      { "chapterTypeId": 3, "chapterTypeName": "QREF" },
      { "chapterTypeId": 4, "chapterTypeName": "Table of Contents"  },
      { "chapterTypeId": 5, "chapterTypeName": "Summary of Benefits and Exclusions"  },
      { "chapterTypeId": 7, "chapterTypeName": "BGHP Benefits"  },
      { "chapterTypeId": 9, "chapterTypeName": "BGHP Exclusions"  },
      { "chapterTypeId": 8, "chapterTypeName": "BGHP Terms and Conditions"  },
      { "chapterTypeId": 6, "chapterTypeName": "Back Cover" }
    ];






    self.safeFileList =
          ".idml," +
          ".msg," +
          ".oft," +
          "image/*," +
          "application/pdf," +
          "application/json," +
          "text/*," +
          "audio/*," +
          "video/*," +
          "message/*," +
          "application/vnd.ms-outlook," +
          "application/zip," +
          "application/x-rar-compressed," +
          "application/octet-stream," +
          "application/postscript," +
          "application/x-photoshop," +
          "application/x-shockwave-flash," +
          "application/powerpoint," +
          "application/vnd.ms-powerpoint," +
          "application/vnd.openxmlformats-officedocument.presentationml.presentation," +
          "application/excel," +
          "application/vnd.ms-excel," +
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet," +
          "application/msword," +
          "application/vnd.ms-word," +
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document," +
          "application/vnd.oasis.opendocument.text";


    self.databaseDateFormat = "YYYY-MM-DD HH:mm:ss";



  // .msg / .eml / .pst
  // .indd / .idml / .psd / .ai / .eps / .ps
  //  .zip
  // we might need movie files too.

    self.updateBookData = false;


    self.searchByBook = {id:''};
    self.toolTip = {showToolTip:false};

    self.appVersion = AppVersions.findOne({filter: {"order": "appVersionId DESC"}});

    self.reload = function(){
      $window.location.reload(true);
    }

    self.initData = function(){
      console.log("Init data fired.");
      var promises = {};
      if(!self.lastUpdated){self.lastUpdated = {};}

      //reasons for change call
      var rfcReasonsDefer = $q.defer();
      var rfcCategoriesDefer = $q.defer();
      var rfcJoinDefer = $q.defer();
      // console.log("Deferrers set, fetching promises...");
      RfcReasons.find().$promise.then(function(results){
        // console.log('RfcReasons fetched: ',results, "Trying categories...")
        self.rfcReasons = results; rfcReasonsDefer.resolve(results);
        RfcCategories.find().$promise.then(function(results){
          // console.log("Categories fetched: ",results,'Trying joins...')
          self.rfcCategories = results; rfcCategoriesDefer.resolve(results);
          RfcJoin.find().$promise.then(function(results){
            // console.log('Joins fetched: ',results,'Resolve results.')
            self.rfcJoin = results; rfcJoinDefer.resolve(results);
            // console.log('Populate rejection reasons.');
            self.rejectionReasons = outerJoin('Reason',outerJoin('Category',self.rfcJoin, 'category', self.rfcCategories, 'id'), 'reason', self.rfcReasons, 'id');

          });
        });
      });

      // console.log('All promises completed, populating global promise variables.');
      promises.rfcReasons = rfcReasonsDefer.promise;
      promises.rfcCategories = rfcCategoriesDefer.promise;
      promises.rfcJoin = rfcJoinDefer.promise;

      //publish types call
      // console.log('------------------------------')
      // console.log('Set up publish types deferrer');
      var publishTypesDefer = $q.defer();
      // console.log('Get undeleted things')
      PublishTypes.__get__notDeleted(function (results) {
        self.publishTypes = results;
        self.lastUpdated.PublishTypes = moment().utc().format('YYYY-MM-DD HH:mm:ss');
        // console.log("Done, resolving publiser types.");
        publishTypesDefer.resolve(results);
      });

      // console.log('------------------------------')
      // console.log('Set up publish types not filtered deferrer');
      var publishTypesNotFilteredDefer = $q.defer();
      PublishTypes.find({}, function (results) {
        self.publishTypesNotFiltered = results;
        self.lastUpdated.PublishTypesNotFiltered = moment().utc().format('YYYY-MM-DD HH:mm:ss');
        // console.log("Done, resolving publiser types.");
        publishTypesNotFilteredDefer.resolve(results);
      });
      // console.log("Set publish types promise and not filtered promise variables");
      promises.publishTypes = publishTypesDefer.promise;
      promises.publishTypesNotFiltered = publishTypesNotFilteredDefer.promise;

      //users call
      // console.log('------------------------------')
      // console.log('Set up user query promise.');
      var usersDefer = $q.defer();
      Users.find({"filter": {"include": ["Teams", "Roles", "Divisions"]}}).$promise.then(function (results) {
        self.users = results;
        self.lastUpdated.Users = moment().utc().format('YYYY-MM-DD HH:mm:ss');
        // console.log("Done, resolving...");
        usersDefer.resolve(results);
      });
      // console.log('Set user promise variable')
      promises.users = usersDefer.promise;

      // //divisions call
      // console.log('------------------------------')
      // console.log('Set up divisions promise.');
      var divisionDefer = $q.defer();
      Divisions.__get__notDeleted(function (results) {
        self.divisions = results;
        // console.log("Done, resolving divisions...");
        divisionDefer.resolve();
      });
      // console.log("Set divisions variable");
      promises.divisions = divisionDefer.promise;


      // console.log('------------------------------')
      // console.log('Set up Team promise.');
      var teamDefer = $q.defer();
      Teams.__get__notDeleted(function (results) {
        self.teams = results;
        // console.log("Resolving Team");
        teamDefer.resolve();
      });
      // console.log("Set Team  variables");
      promises.teams = teamDefer.promise;


      // console.log('------------------------------')
      // console.log('Set up roles promise.');
      //roles call
      var rolesDefer = $q.defer();
      Roles.find().$promise.then(function (results) {
        self.roles = results;
        self.lastUpdated.Roles = moment().utc().format('YYYY-MM-DD HH:mm:ss');
        // console.log('resolving roles');
        rolesDefer.resolve(results);
      });
      // console.log("set roles variable");
      promises.roles = rolesDefer.promise;

      /*console.log('------------------------------')
      console.log('Set up notification promise.');*/
      var NotificationTypesDefer = $q.defer();
      NotificationTypes.find().$promise.then(function (results) {
        self.notificationTypes = results;
        self.lastUpdated.NotificationTypes = moment().utc().format('YYYY-MM-DD HH:mm:ss');
        //console.log('Resolve notification');
        NotificationTypesDefer.resolve(results);
      });
      //console.log('set notification variable');
      promises.notificationTypes = NotificationTypesDefer.promise;


      //meta-list calls that can be updated through maintenance area.
      /*console.log('------------------------------')
      console.log('Set up meta tables query promise.');*/
      var metaDefer = $q.defer();
      self.updateData().then(function(){
        //console.log('Defer meta tables');
        metaDefer.resolve();
      });
      // console.log('set meta tables variable');
      promises.metaTables = metaDefer.promise;

     /* console.log('------------------------------')
      console.log('RETURN PROMISES TO CALLER!!!');
      console.log('------------------------------')*/
      return $q.all(promises);

    }

    // self.initData().then(function(data){
    //   self.rejectionReasons = outerJoin('Reason',outerJoin('Category',data.rfcJoin, 'category', data.rfcCategories, 'id'), 'reason', data.rfcReasons, 'id');
    // });


    self.updateData = function(){
      var promises = {};
      if(!self.lastUpdated){self.lastUpdated = {};}
      //var includeDivisions = self.hasPermissions('editDivisionOnDocs') ? '1,2' : self.$storage.user.divisionId; //@todo make dynamic
      var includeDivisions = '1,2';
      var lastUpdatedCountsPromise = $q.defer();
      MetaTables.getLastUpdatedCounts(self.lastUpdated, function (countLU) {
        //console.log("lstupdated co", countLU);
        countLU = countLU.response[0];

        if(countLU.Audiences > 0){
          var AudiencesDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'Audiences'}, function (results) {
            self.audiences = results['response'][0];
            self.lastUpdated.Audiences = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            AudiencesDefer.resolve(results);
          });
          promises.audiences = AudiencesDefer.promise;
        }

        if(countLU.Clients > 0){
          var clientsDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'Clients'},function (results) {
            self.clients = results['response'][0];
            self.lastUpdated.Clients = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            clientsDefer.resolve(results);
          });
          promises.clients = clientsDefer.promise;
        }

        if(countLU.Distributors > 0){
          var DistributorsDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'Distributors'}, function (results) {
            self.distributors = results['response'][0];
            self.lastUpdated.Distributors = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            DistributorsDefer.resolve(results);
          });
          promises.distributors = DistributorsDefer.promise;
        }

        if(countLU.DocContents > 0){
          var docContentsDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'DocContents'}, function (results) {
            self.docContents = results['response'][0];
            self.lastUpdated.DocContents = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            docContentsDefer.resolve(results);
          });
          promises.docContents = docContentsDefer.promise;
        }

        if(countLU.DocTypes > 0){
          var DocTypesDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'DocTypes'}, function (results) {
            self.docTypes = results['response'][0];
            self.lastUpdated.DocTypes = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            DocTypesDefer.resolve(results);
          });
          promises.docTypes = DocTypesDefer.promise;
        }

        if(countLU.DocTypeCats > 0){
          var DocTypeCatsDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'DocTypeCats'}, function (results) {
            self.docTypeCats = results['response'][0];
            self.lastUpdated.DocTypeCats = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            DocTypeCatsDefer.resolve(results);
          });
          promises.docTypeCats = DocTypeCatsDefer.promise;
        }

        if(countLU.Insurers > 0){
          var InsurersDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'Insurers'}, function (results) {
            self.insurers = results['response'][0];
            self.lastUpdated.Insurers = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            InsurersDefer.resolve(results);
          });
          promises.insurers = InsurersDefer.promise;
        }

        if(countLU.Languages > 0){
          var languagesDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'Languages'},function (results) {
            self.languages = results['response'][0];
            self.lastUpdated.Languages = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            languagesDefer.resolve(results);
          });
          promises.languages = languagesDefer.promise;
        }

        if(countLU.ProductTypes > 0){
          var ProductTypesDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'ProductTypes'}, function (results) {
            self.productTypes = results['response'][0];
            self.lastUpdated.ProductTypes = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            ProductTypesDefer.resolve(results);
          });
          promises.productTypes = ProductTypesDefer.promise;
        }

        if(countLU.Products > 0) {
          var ProductsDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'Products'}, function (results) {
            self.products = results['response'][0];
            self.lastUpdated.Products = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            ProductsDefer.resolve(results);
          });
          promises.products = ProductsDefer.promise;
        }

        if(countLU.Projects > 0) {
          var projectsDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'Projects'}, function (results) {
            self.projects = results['response'][0];
            self.lastUpdated.Projects = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            projectsDefer.resolve(results);
          });
          promises.projects = projectsDefer.promise;
        }

        if(countLU.Regions > 0){
          var RegionsDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'Regions'}, function (results) {
            self.regions = results['response'][0];
            self.lastUpdated.Regions = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            RegionsDefer.resolve(results);
          });
          promises.regions = RegionsDefer.promise;
        }

        if(countLU.SocialChannels > 0){
            var SocialChannelsDefer = $q.defer();
            DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'SocialChannels'}, function (results) {
                self.socialChannels = results['response'][0];
                self.lastUpdated.SocialChannels = moment().utc().format('YYYY-MM-DD HH:mm:ss');
                SocialChannelsDefer.resolve(results);
            });
            promises.socialChannels = SocialChannelsDefer.promise;
        }

        if(countLU.SubInsurers > 0){
          var SubInsurersDefer = $q.defer();
          DivisionMetaLink.getMetaTableData({divisionIds:includeDivisions, tableName:'SubInsurers'}, function (results) {
            self.subInsurers = results['response'][0];
            self.lastUpdated.SubInsurers = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            SubInsurersDefer.resolve(results);
          });
          promises.subInsurers = SubInsurersDefer.promise;
        }

        //todo division-meta-link call this one. - don't use includeDivsions variable on this, because we need to return all teams in all divisions for the workflows.
        if(countLU.Teams > 0){
          var teamsDefer = $q.defer();
          Teams.find({"filter": {"include": "Departments", "order": "docOrd ASC", "where":{"teamStatus":1}}}).$promise.then(function(results){
            self.teams = results;
            //console.log('teams', self.teams);
            self.lastUpdated.Teams = moment().utc().format('YYYY-MM-DD HH:mm:ss');
            teamsDefer.resolve(results);
          });
          promises.teams = teamsDefer.promise;
        }

        $q.all(promises).then(function(){
          lastUpdatedCountsPromise.resolve();
        });
      });

      return lastUpdatedCountsPromise.promise;
    }


    self.json2Obj = function(obj){
      try{ obj = angular.extend(obj,angular.fromJson(obj.versions.versiondata)); }
      catch (err){
        console.log(err.name + ': "' + err.message +  '" occurred when assigning string.');
        return false;
      }
      return obj;
    }


    self.json2ObjWCR = function(obj){
      try{
        obj.json1 = angular.fromJson(obj.versionData1);
        obj.json2 = angular.fromJson(obj.versionData2);
        obj.json = true;
        //console.log(obj);
      }
      catch (err){    console.log(err.name + ': "' + err.message +  '" occurred when assigning string.'); return false;}

      return obj;

    }

    self.nl2br = function(string){
      try {
        return string.replace(/\n/g, '<br>');
      }catch(err){
        //return false;
      }
    }

    self.isUndefinedOrZero = function(val) {
      return angular.isUndefined(val)? val : 0;
    }


    self.getEmailsFromApproval = function(approval) {
      //console.log(approval);
      if(approval.user!="" && !angular.isUndefined(approval.user)){
        return Users.findOne( {"filter": {"where":{"and":[{"status":null},{"id": approval.user}] }}} ).$promise.then(function(user){
          var userEmails = [];
          userEmails.push({'email':user.email,'name':user.firstName});
          return userEmails;
        });
      }else if(approval.team!="" && !angular.isUndefined(approval.team)){
        return Users.find({"filter": {"where":{"and":[{"status":null}, {"teamId": approval.team}]},"include": "Teams"}}).$promise.then(function(userList){
          var userEmails = [];
          _.forEach(userList, function(user, key) {
            userEmails.push({'email':user.contactEmail,'name':user.Teams.teamName});
          });
          return _.uniqBy(userEmails, 'email');
        });
      }else if(approval.role!="" && !angular.isUndefined(approval.role)){
        return Users.find({"filter": {"where":{"and":[{"status":null}, {"roleId": approval.role}]},"include": "Roles"}}).$promise.then(function(userList){
          var userEmails = [];
          _.forEach(userList, function(user, key) {
            userEmails.push({'email':user.contactEmail,'name':user.Roles.name});
          });
          return _.uniqBy(userEmails, 'email');
        });
      }

    }


    self.getEmailsFromDocument = function(thisApprovalList){
      var deferred = $q.defer();
      var promises = [];
      var userEmails = [];
      _.forEach(thisApprovalList, function(approval, key) {
        var innerdeferred = $q.defer();
        self.getEmailsFromApproval(approval).then(function(email){
          userEmails = _.concat(userEmails,email);
          innerdeferred.resolve(email);
        });
        promises.push(innerdeferred.promise);
      });
      $q.all(promises).then(function() {
          deferred.resolve(_.uniqBy(userEmails, 'email'));
      });
      return deferred.promise;
    }


    self.sendmail = function (to, subject, message, header, info) {
      return Messages.sendmail({
        to: to, // list of receivers
        subject: subject, // Subject line
        message: message, // html message
        header: header,
        info:info,
        content: "",
        contact: "Have questions? Contact your account administrator at " + self.clientConfig.email,
        disclaimer: "The information contained in this email and any attachments is confidential and may be subject to copyright or other intellectual property protection."+
        "If you are not the intended recipient, you are not authorised to use or disclose this information, and we request that you notify us by reply mail or telephone and "+
        "delete the original message from your mail system."
      }, function (response) {
        //console.log(response);
        if(response){
          self.toastMessages = {message:"Email Sent to: "+response.to};


        }

      });
    }


    self.hasPermissions = function(thisPermission){
      if(self.user && _.findIndex(self.user.permissions, {permissionCode: thisPermission}) >= 0){
        return true;
      }else{
        return false;
      }
    }


    self.hasAnyOfPermissionCat = function(thisCat){
      if(self.user && _.findIndex(self.user.permissions, {permissionCategoryId: thisCat}) >= 0){
        return true;
      }else{
        return false;
      }
    }



    /*self.getCurrentUser = function () {
      if(Users.isAuthenticated()) {

        Users.getCurrent({"filter": {"include": ["Roles", "Teams", "UsersStatus", {"PermissionGroups": [{"PermissionGroupJoin": ["Permissions"]}]}]}}).$promise.then(function (result) {
          if (result.PermissionGroups && result.PermissionGroups.PermissionGroupJoin.length && result.PermissionGroups.PermissionGroupJoin.length > 0) {
            var permissions = _.map(result.PermissionGroups.PermissionGroupJoin, 'Permissions');
            delete result.PermissionGroups;
          }
          self.LoggedInUser = result;
          if (permissions && permissions.length > 0) {
            self.LoggedInUser.permissions = permissions;
          }
          self.$storage.user = result;
          self.$storage.loggedInUser = result;
        });

      }

    }*/
	  self.getCurrentUser = function (atLogin) {
      if(Users.isAuthenticated()) {
        var deferred = $q.defer();

        if(atLogin) {
          Users.getCurrent().$promise.then(function (currentUser) {
            Users.update({"where": {"id": currentUser.id}}, {"loggedInAs":0}, function () {
              Users.getCurrent({"filter": {"include": ["Roles", "Teams", "UsersStatus", "Divisions", {"PermissionGroups": [{"PermissionGroupJoin": ["Permissions"]}]}]}}).$promise.then(function (result) {
                if (result.PermissionGroups && result.PermissionGroups.PermissionGroupJoin.length && result.PermissionGroups.PermissionGroupJoin.length > 0) {
                  result.permissions = _.map(result.PermissionGroups.PermissionGroupJoin, 'Permissions');
                  delete result.PermissionGroups;
                }

                self.LoggedInUser = result;
                deferred.resolve(true);
                // if (permissions && permissions.length > 0) {
                //   self.LoggedInUser.permissions = permissions;
                //   self.user.permissions = permissions;
                // }

                self.user = result;
                self.$storage.user = result;
                self.$storage.loggedInUser = result;
              });
            });
          });
        }else {
          Users.getCurrent().$promise.then(function (currentUser) {
            Users.getCurrent({"filter": {"include": ["Roles", "Teams", "UsersStatus", "Divisions", {"PermissionGroups": [{"PermissionGroupJoin": ["Permissions"]}]}]}}).$promise.then(function (result) {
              if (result.PermissionGroups && result.PermissionGroups.PermissionGroupJoin.length && result.PermissionGroups.PermissionGroupJoin.length > 0) {
                var permissions = _.map(result.PermissionGroups.PermissionGroupJoin, 'Permissions');
                delete result.PermissionGroups;
              }

              self.LoggedInUser = result;
              deferred.resolve(true);
              if (permissions && permissions.length > 0) {
                self.LoggedInUser.permissions = permissions;
              }
              self.$storage.loggedInUser = result;
            });
          });
        }

        return deferred.promise;
      }

    }


    self.padZeros = function(number, length) {
      var str = '' + number;
      while (str.length < length) {
        str = '0' + str;
      }
      return str;
    }

    self.sendCommentEmail = function (thisBook, thisComment, checkedEmailUsers, eventId) {
      //console.log("sendCommentEmail");

      if(angular.isObject(thisComment)) {
        thisComment = thisComment.message;
      }

      var deferred = $q.defer();
      var emailList = _.map(checkedEmailUsers, 'email').join();

     if(thisBook.liveDate == null){
     var liveDate = 'Live on Approval';}
     else{
     var liveDate = $filter('date')(thisBook.liveDate, "dd/MM/yyyy");
     }

     //var emailList = _.map(users, 'email').join();
      var toEmail = self.emailSystemSwap(emailList);
      var url = $location.$$protocol + '://' + $location.$$host +($location.$$port?':'+$location.$$port:'') + "/library/" + thisBook.bookId;

      thisBook.displayStatus = self.getDisplayStatus(thisBook);

        self.sendmail(
          toEmail,  //to
          thisBook.bookIdLong + " - " + "You have a notification about - " + thisBook.bookName , //subject
          "Comment :<br>" + thisComment + "" +
          "<br><br>" +
          "Click this link to view the document: <a href='" + url + "'>" + thisBook.bookName + " ("+ thisBook.bookId + ")" + "</a> " +
          "<br><br><br>" +
          "This email has been sent to the following people: " + emailList.replace(/,/g,", ").replace(/,\s*$/, ""), //message
          self.$storage.user.firstName + " " + self.$storage.user.lastName + " has commented on a document you are involved with.", //header
          "Document Name: " + thisBook.bookName +
          "<br>Document Code: " + thisBook.bookCode +
          "<br>Document ID: " + thisBook.bookIdLong +
          "<br>Document Owner: " + thisBook.Owner.fullName +
          "<br>Status: " + thisBook.displayStatus +
          "<br>Live Date: " + liveDate +
          "<br>Submitted By: " + self.$storage.user.firstName + " " + self.$storage.user.lastName + "" //info
        ).$promise.then(function (messageDetails) {
          Events.upsert({"eventId":eventId, "messageId":messageDetails.messageId}).$promise.then(function (results) {
            deferred.resolve(results);
          });

        });
      return deferred.promise;
    }



    //as per google doc
    self.sendApprovedEmail = function(thisBook,eventId) {
      //console.log("sendApprovedEmail");

      var deferred = $q.defer();
      if(thisBook.liveDate == null){
        var liveDate = 'Live on Approval';}
      else{
        var liveDate = $filter('date')(thisBook.liveDate, "dd/MM/yyyy");
      }

      var toEmail = self.emailSystemSwap(thisBook.Owner.email);
        var url = $location.$$protocol + '://' + $location.$$host +($location.$$port?':'+$location.$$port:'') + "/library/" + thisBook.bookId;

        thisBook.displayStatus = self.getDisplayStatus(thisBook);

        self.sendmail(
          toEmail,  //to
          thisBook.bookIdLong + " - " + "A document you have sent for review has been approved - " + thisBook.bookCode, //subject
          "Hi " + thisBook.Owner.firstName + "<br><br>" +
          "A document you have sent for review has been approved.  It may be part of an approval chain and therefore still on its way through the workflow.<br><br>" +
          "Click this link to view the document: <a href='" + url + "'>" + thisBook.bookName + " ("+ thisBook.bookId + ")" + "</a> " +
          "<br><br>" +
          "Note: if you believe you are not the right person or team responsible for reviewing or approving this document, please contact " +
          self.clientConfig.email + "as soon as possible. All delays are logged and reportable.<br><br>" +
          "This email has been sent to the following people: " + thisBook.Owner.email, //message
          "Congratulations! A document you have sent for review has been approved.", //header
          "Document Name: " + thisBook.bookName + "<br>Document Code: " + thisBook.bookCode + "<br>Document ID: " + thisBook.bookIdLong + "<br>Document Owner: " + thisBook.Owner.fullName + "<br>Status: " + thisBook.displayStatus + "<br>Live Date: " + liveDate + "<br>Submitted By: " + self.$storage.user.firstName + " " + self.$storage.user.lastName + "" //info
        ).$promise.then(function (messageDetails) {
          Events.upsert({"eventId":eventId, "messageId":messageDetails.messageId}).$promise.then(function (results) {
            deferred.resolve(results);
          });

        });
      return deferred.promise;
    }

    //as per google doc
    self.sendReviewEmail = function(thisBook, eventId) {
      //console.log("sendReviewEmail",eventId);
      var deferred = $q.defer();
      self.getEmailsFromApproval(thisBook.approvalList[thisBook.approvalStatus]).then(function (users) {

        if(thisBook.liveDate == null){
          var liveDate = 'Live on Approval';}
        else{
          var liveDate = $filter('date')(thisBook.liveDate, "dd/MM/yyyy");
        }

        var emailList = _.map(users, 'email').join();
        var toEmail = self.emailSystemSwap(emailList);

        var url = $location.$$protocol + '://' + $location.$$host +($location.$$port?':'+$location.$$port:'') + "/library/" + thisBook.bookId;


        thisBook.displayStatus = self.getDisplayStatus(thisBook);

        if(users.length===1){var name = users[0].name;}else{var name = "";}





        if (thisBook.approvalList[thisBook.approvalStatus] && thisBook.approvalList[thisBook.approvalStatus].role == 12) {

          var enclosureText = '';
          enclosureText = "Enclosures:<br/ >";
          EnclosureTypes.__get__notDeleted().$promise.then(function (enclosureTypes) {
            //console.log("ENCLOSURE TYPES FOR EMAIL" , enclosureTypes, thisBook, thisBook.Enclosures);
            _.forEach(enclosureTypes, function (item) {
              var encType = "";
              //var colName = "enclosure" + item.enclosureTypeName.replace(/ /g, "");
              if (encType = _.filter(thisBook.Enclosures, {'enclosureType': item.enclosureTypeId + ""})[0]) {
                //console.log("encType", encType, item.enclosureTypeName);
                //console.log(encType.enclosureBookCode);
                enclosureText += item.enclosureTypeName + ": " + encType.enclosureBookCode + "<br />";
              }
            });
            return enclosureText;
            //console.log(enclosureText);
          }).then(function (enclosureText) {
            var productIdText = '';
            if (!_.isEmpty(thisBook.productIdsSelectedArray)) {
              productIdText = '<br />Product Ids:<br />'
              _.forEach(thisBook.productIdsSelectedArray, function (state, productId) {
                if (state === true) {
                  productIdText += productId + '<br />';
                }
              });
            }


            self.sendmail(
              toEmail,  //to
              thisBook.bookIdLong + " - " + "You have a new document to review - " + thisBook.bookCode, //subject
              "Hi " + name + "<br><br>" +
              "There are documents waiting for your review or approval.<br><br>" +
              "Click this link to view the document: <a href='" + url + "'>" + thisBook.bookName + " (" + thisBook.bookId + ")" + "</a> " +
              "<br><br>" +

              enclosureText +
              productIdText +

              "<br><br>" +
              "Note: if you believe you are not the right person or team responsible for reviewing or approving this document, please contact " + self.clientConfig.email + " as soon as possible. All delays are logged and reportable.<br><br>" +
              "This email has been sent to the following people: " + emailList, //message
              "There are documents waiting for your review or approval.", //header
              "Document Name: " + thisBook.bookName + "<br>Document Code: " + thisBook.bookCode + "<br>Document ID: " + thisBook.bookIdLong + "<br>Document Owner: " + thisBook.Owner.fullName + "<br>Status: " + thisBook.displayStatus + "<br>Live Date: " + liveDate + "<br>Submitted By: " + self.$storage.user.firstName + " " + self.$storage.user.lastName + "" //info
            ).$promise.then(function (messageDetails) {

              //console.log("messageDetails",messageDetails);
              Events.upsert({
                "eventId": eventId,
                "messageId": messageDetails.messageId
              }).$promise.then(function (results) {
                deferred.resolve(results);
              });

            });
          });


        } else {

          self.sendmail(
            toEmail,  //to
            thisBook.bookIdLong + " - " + "You have a new document to review - " + thisBook.bookCode, //subject
            "Hi " + name + "<br><br>" +
            "There are documents waiting for your review or approval.<br><br>" +
            "Click this link to view the document: <a href='" + url + "'>" + thisBook.bookName + " (" + thisBook.bookId + ")" + "</a> " +
            "<br><br>" +
            "Note: if you believe you are not the right person or team responsible for reviewing or approving this document, please contact "+ self.clientConfig.email +" as soon as possible. All delays are logged and reportable.<br><br>" +
            "This email has been sent to the following people: " + emailList, //message
            "There are documents waiting for your review or approval.", //header
            "Document Name: " + thisBook.bookName + "<br>Document Code: " + thisBook.bookCode + "<br>Document ID: " + thisBook.bookIdLong + "<br>Document Owner: " + thisBook.Owner.fullName + "<br>Status: " + thisBook.displayStatus + "<br>Live Date: " + liveDate + "<br>Submitted By: " + self.$storage.user.firstName + " " + self.$storage.user.lastName + "" //info
          ).$promise.then(function (messageDetails) {

            //console.log("messageDetails",messageDetails);
            Events.upsert({
              "eventId": eventId,
              "messageId": messageDetails.messageId
            }).$promise.then(function (results) {
              deferred.resolve(results);
            });


          });

        }










      });
      return deferred.promise;
    }

    //as per google doc
    self.sendRejectEmail = function(thisBook, reject, previousEditor,eventId) {
      //console.log("sendRejectEmail");
      var deferred = $q.defer();
      self.getEmailsFromApproval(thisBook.approvalList[previousEditor]).then(function (users) {

        if(thisBook.liveDate == null){
          var liveDate = 'Live on Approval';}
        else{
          var liveDate = $filter('date')(thisBook.liveDate, "dd/MM/yyyy");
        }

        var emailList = _.map(users, 'email').join();
        var toEmail = self.emailSystemSwap(emailList);

        var url = $location.$$protocol + '://' + $location.$$host +($location.$$port?':'+$location.$$port:'') + "/library/" + thisBook.bookId;


        thisBook.displayStatus = self.getDisplayStatus(thisBook);

        if(users.length===1){var name = users[0].name;}else{var name = "";}

        //console.log(reject);
        var reasons ="";//"<b>Reasons</b><br>";
        var categories ="";//"<b>Categories</b><br>";

        if(!angular.isArray(reject.reason)){ reject.reason = [reject.reason];}
        _.forEach(reject.reason, function(val,key){
          reasons = reasons + "<li>"+_.result(_.find(self.rfcReasons, {'id': _.parseInt(val)}), 'name')+"</li>";
        });

        if(!angular.isArray(reject.category)){ reject.category = [reject.category];}
        _.forEach(reject.category, function(val,key){
          categories = categories + "<li>"+_.result(_.find(self.rfcCategories, {'id': _.parseInt(val)}), 'name')+"</li>";
        });

        self.sendmail(
          toEmail,  //to
            thisBook.bookIdLong + " - " + "A document you have sent for review has been rejected - "+ thisBook.bookCode, //subject
          "Hi "+name+"<br><br>" +

          "A document you have sent for review has been rejected. The reason for rejection is:<br><br>" +
          "<blockquote>" +
          "<strong>Rejection Category:</strong> <ul>"+categories+"</ul>" +
          "<strong>Reason for Rejection:</strong> <ul>"+reasons + "</ul>" +
          "<strong>Rejection Message:</strong> <br>"+ reject.message+"</blockquote><br><br>" +
          "Click this link to view the document: <a href='" + url + "'>" + thisBook.bookName + " ("+ thisBook.bookId + ")" + "</a> " +
          "<br><br>" +


          "<i>Note: the review and approve window is open for a limited time only, so your timely attention to this rejection is key to the success of the " +
          "process. Delays beyond the expiration date could put us in breach of our TCF obligations.</i><br><br>" +

          "<i>If you believe you are not the right person or team responsible for reviewing or approving this document, " +
          "please contact " + self.clientConfig.email + " as soon as possible. All rejections are logged and reportable.</i><br><br>" +

          "This email has been sent to the following people: "+emailList , //message

          "A document you have sent for review has been rejected", //header
          "Document Name: " + thisBook.bookName + "<br>Document Code: " + thisBook.bookCode + "<br>Document ID: " + thisBook.bookIdLong + "<br>Document Owner: "+thisBook.Owner.fullName+"<br>Status: "+thisBook.displayStatus+"<br>Live Date: "+liveDate+"<br>Rejected By: "+self.$storage.user.firstName+" "+self.$storage.user.lastName+"" //info
        ).$promise.then(function (messageDetails) {
          //console.log("messageDetails",messageDetails);
          Events.upsert({"eventId":eventId, "messageId":messageDetails.messageId}).$promise.then(function (results) {
            deferred.resolve(results);
          });

        });
      });
      return deferred.promise;
    }

    self.sendEarlyExpiryEmail = function(book, dateOfExpiry){
      var funcPromise = $q.defer();

      // in finals and not at artwork upload step
      if(book.approvalList[book.approvalStatus] && book.approvalList[book.approvalStatus].content == 'final' && book.approvalList[book.approvalStatus].role != 6){
        var publishStep = _.filter(book.approvalList, function(obj , index){

          return obj.content == 'final' && obj.role != 6 && index <= book.approvalStatus;
        });

        //approval list logic--//
        var users = [];
        var promises = [];

        var production = '';
        var fulfilment = '';
        var digital = '';
        var support = '';

        _.forEach(publishStep, function (step) {
          var loopDefered = $q.defer();
          //get where things are published for this step and assign it to a variable to output within the blockquote of the email.
          switch(step.role) {
              case 11:
                  _.forEach(book.production, function (value, key) {
                          production = production + "<li>"+_.result(_.find(self.publishTypes, {'id': _.toInteger(key)}), 'name')+"</li>";
                      });
                  break;
              case 12:
                  _.forEach(book.fulfilment, function (value, key) {
                      fulfilment = fulfilment + "<li>"+_.result(_.find(self.publishTypes, {'id': _.toInteger(key)}), 'name')+"</li>";
                  });
                  break;
              case 14:
                  _.forEach(book.digital, function (value, key) {
                      digital = digital + "<li>"+_.result(_.find(self.publishTypes, {'id': _.toInteger(key)}), 'name')+"</li>";
                  });
                  break;
              case 15:
                  _.forEach(book.support, function (value, key) {
                      support = support + "<li>"+_.result(_.find(self.publishTypes, {'id': _.toInteger(key)}), 'name')+"</li>";
                  });
                  break;
              default:
                break;
          }

          // get users that are assigned to this step.
          self.getEmailsFromApproval(step).then(function(res){
            users = _.concat(users,res);
            loopDefered.resolve();
          });
          promises.push(loopDefered.promise);
        });

        $q.all(promises).then(function(res){
          console.log("users",users);
          if(users && users.length > 0){
                var emailList = _.map(users, 'email').join();
                var toEmail = self.emailSystemSwap(emailList);
                console.log(production, fulfilment, digital, support);

                if (users.length === 1) {
                    var name = users[0].name;
                } else {
                    var name = "";
                }


                var earlyExpiryDate = $filter('date')(dateOfExpiry, "dd/MM/yyyy");

                if (book.liveDate == null) {
                    var liveDate = 'Live on Approval';
                }
                else {
                    var liveDate = $filter('date')(book.liveDate, "dd/MM/yyyy");
                }

                if (book.expiryDate == null) {
                    var expiryDate = 'Set from Live Date';
                }
                else {
                    var expiryDate = $filter('date')(book.expiryDate, "dd/MM/yyyy");
                }

                var url = $location.$$protocol + '://' + $location.$$host + ($location.$$port ? ':' + $location.$$port : '') + "/library/" + book.bookId;



                self.sendmail(
                    toEmail,  //to
                    book.bookIdLong + " - " + "This document has been expired early - " + book.bookCode, //subject
                    "Hi " + name + "<br><br>" +
                    "The owner of this document has set it to expire early, so it will be no longer needed from " + earlyExpiryDate + ". The document is published in the following places:<br><br>" +
                    "<blockquote>" +

                    ( production ? "<strong>Production Manager:</strong> <ul>" + production + "</ul>" : "" ) +
                    ( fulfilment ? "<strong>Fulfilment:</strong> <ul>" + fulfilment + "</ul>" : "" ) +
                    ( digital ? "<strong>Digital and Social:</strong> <ul>" + digital + "</ul>" : "" ) +
                    ( support ? "<strong>Support Tools:</strong> <ul>" + support + "</ul>" : "" ) +


                    "</blockquote><br><br>" +
                    "As this document was expired from a LIVE status, it is likely that is has been published on various drives and sites. You are recorded as a publisher of this document; please remove it from any places you have published it, and contact and print houses to ensure it is no longer available for ordering if relevant. <br><br>" +
                    "Click this link to view the item: <a href='" + url + "'>" + book.bookName + " (" + book.bookId + ")" + "</a> <br><br>" +
                    "<strong>Important: documents may still need to be accessible by various departments, business units and customers; ensure that any removed documents are moved to archive folders. If in doubt, contact the owner, " + book.Owner.fullName + ", for further information.</strong><br><br>" +
                    "<i> Note: if you believe you are not the right person or team responsible for taking this action, please contact " + self.clientConfig.email + " as soon as possible.</i><br><br>" +
                    "This email has been sent to the following people: " + emailList,
                    "This document has been expired early",
                    "Document Name: " + book.bookName +
                    "<br>Document Code: " + book.bookCode +
                    "<br>Document ID: " + book.bookIdLong +
                    "<br>Document Owner: " + book.Owner.fullName +
                    "<br>Live Date: " + liveDate +
                    "<br>Expiry Date: " + expiryDate +
                    "<br>Early Expiry Date: " + earlyExpiryDate
                ).$promise.then(function (msg) {
                    funcPromise.resolve(msg);
                });
                //return msg;

          }else{
            funcPromise.resolve();
          }
        });
      }else{
        funcPromise.resolve();
      }
      return funcPromise.promise;
    }



    self.getDisplayStatus = function (thisBook){

      var displayStatus ="";
      if (thisBook.approvalList.length > 0 && thisBook.approvalList) {
        thisBook.thisAppStatus = thisBook.approvalList[thisBook.approvalStatus];
        var now = new Date();
        if (thisBook.status === 1) {




          if (!thisBook.activeDate) {
            displayStatus = 'NO DATE';
          }
          else if (thisBook.withdrawDate && now >= new Date(thisBook.withdrawDate) ) {
            displayStatus = 'EXPD(W)';
          }
          else if (thisBook.earlyExpiryDate && now >= new Date(thisBook.earlyExpiryDate) ) {
            displayStatus = 'EXPD(F)';
          }
          else if (now >= new Date(thisBook.expiryDate)) {
            displayStatus = 'EXPD';
            //console.log(now, "-", new Date(book.expiryDate));
          }
          else if (now >= new Date(thisBook.reviewDate)) {
            displayStatus = 'LIVE(R)';
          }
          else if (now >= new Date(thisBook.liveDate)) {
            displayStatus = 'LIVE';
          }
          else if (now >= new Date(thisBook.activeDate)) {
            displayStatus = 'ACTV';
          }
          else {
            displayStatus = 'READY';
          }

        } else {
          var pend = '';
          if (now >= new Date(thisBook.activeDate) && thisBook.status === 0) {
            pend = "(P)";
          }

          if (thisBook.withdrawDate && now >= new Date(thisBook.withdrawDate) ) {
            displayStatus = 'EXPD(W)';
          }
          else if (thisBook.earlyExpiryDate && now >= new Date(thisBook.earlyExpiryDate) ) {
            displayStatus = 'EXPD(F)';
          }
          else if (now >= new Date(thisBook.expiryDate)) {
            displayStatus = 'EXPD' + pend;
          }else{
           // displayStatus = displayStatus + self.getUserStatus(thisBook.approvalList[thisBook.approvalStatus], pend);
           // if(thisBook.approvalList[thisBook.approvalStatus] && thisBook.approvalList[thisBook.approvalStatus].role == 6 && thisBook.approvalList[thisBook.approvalStatus].content == "final"){displayStatus = "FINAL FILES";}
            displayStatus = 'IN WORK';
          }


        }
      }
      var docStatus = "";
      if (thisBook.status === 0) {
        docStatus =  self.getUserStatus(thisBook.approvalList[thisBook.approvalStatus], pend);
        if(thisBook.approvalList[thisBook.approvalStatus] && thisBook.approvalList[thisBook.approvalStatus].role == 6 && thisBook.approvalList[thisBook.approvalStatus].content == "final"){docStatus = "FINAL FILES";}
      }else{
        docStatus = "COMPLETE";
      }

      return docStatus +" - "+displayStatus;
    }




    self.getUserStatus = function(thisCurrentApproval, pend){
      var displayStatus;
      if (thisCurrentApproval && thisCurrentApproval.role) {
        if (thisCurrentApproval.role != 3) {
          displayStatus = $filter('uppercase')(self.getRoleById(thisCurrentApproval.role).description) + pend;
        } else if (parseInt(thisCurrentApproval.team) > 0) {
          displayStatus = $filter('uppercase')(self.getTeamById(thisCurrentApproval.team).shortName) + pend;
        }
      }
      return displayStatus;
    }





    self.getRoleById = function(roleId){
      return _.find(self.roles, {'id': parseInt(roleId)});
    }
    self.getTeamById = function(teamId){
      return _.find(self.teams, {'teamId': parseInt(teamId)});
    }
    self.getUserById = function(userId){
      return _.find(self.users, {'id': parseInt(userId)});
    }

    self.getTableWidths = function(width, tableHeaders){
      var total = _.sumBy(tableHeaders, function(o){return _.toInteger(o.width);});
      return (width / total) * 100;

    }


    self.attachTableHeader = function(row){
      var deferred = $q.defer();
      Structures.findOne({ filter: { "where":{"structureId": row.parent }, "include":[ {"versions":[{"Contents":"ContentTypes"}]} ] } }).$promise.then(function (results) {
        if (results.versions.Contents.ContentTypes.isJson === true) {
          self.json2Obj(results);
          //console.log(results);
          var rtn = results.tableHeader;
        }else{
          var rtn = false;
        }
        deferred.resolve(rtn);
      });
      return deferred.promise;
    }





    self.getListChildren = function(book,field,type){
      //console.log(book);
      var list = [];
      _.forEach(book.Children, function (val1) {
        if(!type || val1.versions.Contents.ContentTypes.contentTypeGroup === type){ list.push(val1[field]); }
          _.forEach(val1.Children, function (val2) {
            if(!type || val2.versions.Contents.ContentTypes.contentTypeGroup === type){ list.push(val2[field]); }
            _.forEach(val2.Children, function (val3) {
              if(!type || val3.versions.Contents.ContentTypes.contentTypeGroup === type){ list.push(val3[field]); }
              _.forEach(val3.Children, function (val4) {
                if(!type || val4.versions.Contents.ContentTypes.contentTypeGroup === type){ list.push(val4[field]); }
              });
            });
          });

      });

      //console.log(list);
      //console.log(_.uniq(list));

      return _.uniq(list);

    }

    self.getListChildrenNew = function(book,fields,type){
      //console.log(book);
      var list = [];
      _.forEach(book.Children, function (val1) {
        if(!type || val1.versions.Contents.ContentTypes.contentTypeGroup === type){ list.push(_.pick(val1, fields));  }
        _.forEach(val1.Children, function (val2) {
          if(!type || val2.versions.Contents.ContentTypes.contentTypeGroup === type){ list.push(_.pick(val2, fields)); }
          _.forEach(val2.Children, function (val3) {
            if(!type || val3.versions.Contents.ContentTypes.contentTypeGroup === type){ list.push(_.pick(val3, fields));  }
            _.forEach(val3.Children, function (val4) {
              if(!type || val4.versions.Contents.ContentTypes.contentTypeGroup === type){ list.push(_.pick(val4, fields));  }
            });
          });
        });

      });

      return  _.uniqWith(list, _.isEqual);

    }



    self.emailSystemSwap = function(thisEmail){
      if(system === "local") {
        return "jason.hampton@gmail.com";
      }else if(system === "demo" || system === "staging" || system === "switch"){
        return "jason@wraps.io,clark@wraps.io";
      }else{
        return  thisEmail;
      }
    }


    self.saveComments = function(thisBook, thisChild, thisComment, checkedEmailUsers){
      var deferred = $q.defer();
      if(thisChild){
        Events.create({
          "eventId": 0,
          "bookId": thisBook.bookId,
          "structureId": thisChild.structureId,
          "contentId": thisChild.versions.contentId,
          "historyId": thisChild.History[thisChild.History.length - 1].historyId,
          "userId": self.$storage.user.id,
          "eventType": "Comment",
          "eventData": thisComment.message
        }).$promise.then(function(event){
          //console.log("Save Child Comments");
          if(checkedEmailUsers.length > 0){
                self.sendCommentEmail(thisBook, thisComment, checkedEmailUsers, event.eventId).then(function (messageDetails) {

                    deferred.resolve();
                });
          }else{
            deferred.resolve();
          }
        });
      }else{
        Events.create({
          "eventId": 0,
          "bookId": thisBook.bookId,
          "structureId": null,
          "contentId": null,
          "historyId": null,
          "userId": self.$storage.user.id,
          "eventType": "Comment",
            "eventData": thisComment.message
        }).$promise.then(function(event){
          //console.log("Save Doc Comments");

          self.commentsUpdate = event.eventId;
          if(checkedEmailUsers.length > 0){
            self.sendCommentEmail(thisBook, thisComment, checkedEmailUsers, event.eventId).then(function (messageDetails) {

              deferred.resolve();
            });
          }else{
            deferred.resolve();
          }




        });
      }

      return deferred.promise;
    }

    self.loadComments = function (thisBook) {
      //console.log('loading comments for book:'+thisBook.bookId);

      var deferred = $q.defer();

      var filter = {"filter":{
                      "where":{"bookId":thisBook.bookId,"eventType":{"inq": ["Comment","Approved", "Reset to MCO","Reset to Brief", "Delete", "Withdraw", "Early Expired","Edited","Edited Workflow", "Added", "Rejected", "Approve Files","System Notification","Delete Document Request","Delete Document", "Document Created"]}},
                      "include": ["Messages","Users", {History:'versionsNew'}],
                      "order": "eventId DESC"}};


      Events.find(filter).$promise.then(function(events){
        if(thisBook.DocTypes.docTypeTemplate === 1){
          var deleted = _.remove(events, function (item) { //removes document approvals
            return item.structureId != null && item.eventType === "Approved";
          });
        }else{
          var deleted = _.remove(events, function (item) { //removes item approvals for book if it hasn't got a message (pre 2018-08-09 when messages went live)
            return item.structureId === null && item.eventType === "Approved" && item.messageId === null;
          });

          var deleted2 = _.remove(events, function (item) { //removes item approvals for content if after 2018-08-09 - bit a horrid hack!
            return item.structureId  != null && item.eventType === "Approved" && item.messageId  === null && item.created > "2018-08-09";
          });
        }


        _.forEach(events, function (event,index) {
          //TODO this might be replacing user inputs on the word book
          if(event.eventData){event.eventData = event.eventData.replace(/Book/g, "Document");}


          var eventContent;
          if(event.eventData && event.eventData.charAt(0)==='{') {
            event.isJson=true;
            event.rfcReasons = [];
            event.categories = [];
            event.significant = false;
            event.Comment = angular.fromJson(event.eventData);

            if(event.eventType === "System Notification"){
              //event.eventName = "Document " + event.eventType;
              event.eventName = event.eventType;
              //console.log("System Notification debug",self.notificationTypes);

              event.Comment.message = _.find(self.notificationTypes, {'notificationTypeId': parseInt(event.Comment.notificationTypeId)}).notificationTypeDescription;
            }else if(event.eventType === "Delete Document Request"){
              //event.eventName = "Document " + event.eventType;
              event.eventName = event.eventType;
              //console.log("Delete Document Request",self.notificationTypes);

            }else if(event.eventType === "Comment"){
                event.eventName = "" + event.eventType;
            }else if(event.eventType === "Edited Workflow"){

              event.eventName = event.eventType;
              event.Comment.message = "Team workflow reallocation";

            }else {
              //link id's with name from datalists
                //console.log(event.Comment);

              if (!angular.isArray(event.Comment.reason) && event.Comment.reason) {
                event.Comment.reason = [event.Comment.reason];
              }
              _.forEach(event.Comment.reason, function (val, key) {
                event.rfcReasons.push(_.result(_.find(self.rfcReasons, {'id': _.parseInt(val)}), 'name'));
              });

              if (!angular.isArray(event.Comment.category) && event.Comment.category) {
                event.Comment.category = [event.Comment.category];
              }
              _.forEach(event.Comment.category, function (val, key) {
                event.categories.push(_.result(_.find(self.rfcCategories, {'id': _.parseInt(val)}), 'name'));
              });
              if (event.Comment.significant) {
                event.significant = event.Comment.significant;
              }



              if(event.filesList){
                event.Comment.filesList = event.filesList;
              }

              if(event.eventType == "Edited" || event.eventType == "Added"){
                  //console.log("event",event);
                event.eventName = event.eventType;
                var versiondata = null;

                if(event.History && event.History.versionIdNew != event.History.versionIdOld) {
                    if(self.isValidJsonStr(event.History.versionsNew.versiondata)){
                      var parsedVersions = JSON.parse(event.History.versionsNew.versiondata);
                      var contentType = Object.keys(parsedVersions)[0];
                      versiondata = parsedVersions[contentType];
                      if(versiondata){
                        event.Comment.message = versiondata.description;
                        event.Comment.filesList = versiondata.filesList;
                      }
                    }else{
                      // had it here for possible mem guide stuff later
                      //event.Comment.message = event.History.versionsNew.versiondata;
                    }
                }


              }else if (event.Comment.approvalStatus && event.eventType != "Rejected") {

                if(angular.fromJson(event.eventData).message){
                  event.Comment.message += '- Message: ' + angular.fromJson(event.eventData).message;
                }

                if (event.Comment.approvalStatus == 0) {
                  eventContent = thisBook.approvalList[event.Comment.approvalStatus] ? thisBook.approvalList[event.Comment.approvalStatus].content.ucFirst() : '';
                } else {
                  eventContent = thisBook.approvalList[event.Comment.approvalStatus - 1] ? thisBook.approvalList[event.Comment.approvalStatus - 1].content.ucFirst() : '';
                }

                event.eventName = eventContent + " " + event.eventType;
              }else {
                event.eventName = event.eventType;
                if (event.eventType == "Rejected" && event.Comment.approvalStatus){

                  if(event.filesList){
                    event.Comment.filesList = event.filesList;
                  }

                  event.Comment.approvalStatus < 0 ? event.Comment.approvalStatus = 0 : '';
                  eventContent = thisBook.approvalList[event.Comment.approvalStatus] ? thisBook.approvalList[event.Comment.approvalStatus].content.ucFirst() : '';
                  status = self.getUserStatus(thisBook.approvalList[event.Comment.approvalStatus],'');
                  event.Comment.message = eventContent + " " + "workflow reset to " + status + "\n\n" + event.Comment.message;
                  event.eventName = eventContent + " " + event.eventType;

                }

              }
              //console.log("event", event);
            }
          }else{
            //console.log(event);
            event.Comment = {};
            var status;

            if (event.eventType === "Approved") {

              var approvalStatus = self.charToFollowString(event.eventData,"approvalStatus:") | self.charToFollowString(event.eventData,"Approver:");

              if (approvalStatus != undefined){
                if(approvalStatus==0){
                  eventContent = thisBook.approvalList[approvalStatus] ? thisBook.approvalList[approvalStatus].content.ucFirst() : '';
                }else if(approvalStatus % 10 == 0) {
                  //handle the random approvalStatus:20 event data
                    //console.log(approvalStatus, thisBook.approvalList[approvalStatus/10]);
                    eventContent = thisBook.approvalList[approvalStatus/10-1] ? thisBook.approvalList[approvalStatus/10-1].content.ucFirst() : '';
                    //eventContent = self.getUserStatus(thisBook.approvalList[approvalStatus/10],"");
                }else{
                  eventContent = thisBook.approvalList[approvalStatus-1] ? thisBook.approvalList[approvalStatus-1].content.ucFirst() : '';
                }
              }

              event.eventName = eventContent + " " + event.eventType;
              if(self.charToFollowString(event.eventData," Status:")==1){
                status = " to READY";
              }else if(approvalStatus % 10 == 0 && approvalStatus != 0){
                //handles the linear approval status 20 format - where we don't understand the data;
                  status = "";
              }else{
                status = " to " + self.getUserStatus(thisBook.approvalList[approvalStatus],"");
              }

              event.Comment.message = eventContent + " " + "workflow moved" + status+"";



            }else if((event.eventType === "Reset to Brief" || event.eventType === "Reset to MCO") && self.charToFollowString(event.eventData,"approvalStatus:")){

              if(self.charToFollowString(event.eventData,"approvalStatus:")==0){
                eventContent = thisBook.approvalList[self.charToFollowString(event.eventData,"approvalStatus:")] ? thisBook.approvalList[self.charToFollowString(event.eventData,"approvalStatus:")].content.ucFirst() : '';
              }else{
                eventContent = thisBook.approvalList[self.charToFollowString(event.eventData,"approvalStatus:")-1] ? thisBook.approvalList[self.charToFollowString(event.eventData,"approvalStatus:")-1].content.ucFirst() : '';
              }

              event.eventName = event.eventType;
              status = self.getUserStatus(thisBook.approvalList[self.charToFollowString(event.eventData,"approvalStatus:")],"");


              event.Comment.message = eventContent + " " + "workflow reset from " + status+"";

            }else if(event.eventType === "Added"){
              var versiondata = null;
              if(event.History) {
                var parsedVersions = JSON.parse(event.History.versionsNew.versiondata);
                var contentType = Object.keys(parsedVersions)[0];;
                versiondata = parsedVersions[contentType];
              }
              event.eventName = event.eventData.split(' ')[0] + " " + event.eventType;
              event.Comment.message = versiondata ? versiondata.description : event.eventData;
              event.Comment.filesList = versiondata ? versiondata.filesList : [];

            }else if(event.structureId === null && event.eventType === "Comment"){
              //event.eventName = "Document " + event.eventType;
              event.eventName = "" + event.eventType;
              event.Comment.message = event.eventData;
            }else{

              //console.log('event',event);
              //console.log('charToFollowString',self.charToFollowString(event.eventData,"Approver:"));

              if(self.charToFollowString(event.eventData,"Approver:")){

                if(self.charToFollowString(event.eventData," Status:")==1){
                  status = "READY";
                }else{
                  status = self.getUserStatus(thisBook.approvalList[self.charToFollowString(event.eventData,"Approver:")],"");
                }



                eventContent = thisBook.approvalList[self.charToFollowString(event.eventData,"Approver:")] ? thisBook.approvalList[self.charToFollowString(event.eventData,"Approver:")].content.ucFirst() : '';

                event.Comment.message = eventContent + " " + "workflow moved to " + status+"";
                event.eventName = eventContent + " " + event.eventType;
              }else {

                event.eventName = event.eventType;
                event.Comment.message = event.eventData;
              }
            }
          }

          if(event.eventName){event.eventName = event.eventName.replace(/Doc /g, "Document ");}
          if(event.Comment.message){event.Comment.message = event.Comment.message.replace(/Doc /g, "Document ");}
        });




        BooksVersions.find({
            "filter": {
                "where": {"bookId": thisBook.bookId},
                order: 'created ASC'
            }
        }).$promise.then(function (versions) {

          var promises = [];
          if(thisBook.sourceId ){

            var promise1 = $q.defer();
            Books.findOne({"filter": {"where": {"bookId": thisBook.sourceId}}}).$promise.then(function(sourceBook) {
              events.push({
                  eventName: "Document Duplicated",
                  created: Date.parse(moment(thisBook.created).subtract(1, 'minutes')), //this is to set the duplication msg to come before the version data
                  Users: thisBook.Owner,
                  Comment: {message: "Duplicated from: " + thisBook.sourceIdLong + "\n" + sourceBook.bookName + "\n" + sourceBook.bookCode}
              });
              promise1.resolve();
              console.log(thisBook.Owner);
            });
            promises.push(promise1.promise);
          }else{
            if(versions && versions[0] && versions[0].fromBlob == '{}'){
                events.push( {eventName: "Document Created",created: thisBook.created, Users: self.getUserById(versions[0].userId), Comment: {message: "This document was created manually"}});
            }else{
                events.push( {eventName: "Document Created",created: thisBook.created, Users: {'firstName':'User', 'lastName':'Unknown'}, Comment: {message: "This document was created manually"}});
            }
          }

          $q.all(promises).then(function () {
            _.forEach(versions, function (version, ind) {
              if(self.isValidJsonStr(version.diff)){

                var diffs = JSON.parse(version.diff);

                _.forEach(diffs, function (diff, diff_ind) {
                  if(diff.path[0]) { diff.path = diff.path[0]; }
                  if(self.isValidJsonStr(diff.lhs)){
                    diff.lhs = JSON.parse(diff.lhs)
                  }
                  if(self.isValidJsonStr(diff.rhs)){
                    diff.rhs = JSON.parse(diff.rhs)
                  }

                  //clears out non changing entries
                  if  (
                        !diff.path ||
                        (
                          (!diff.lhs || diff.lhs === 0 || diff.lhs === null || angular.equals({}, diff.lhs)) &&
                          (!diff.rhs || diff.rhs === 0 || diff.rhs === null || angular.equals({}, diff.rhs))
                        )
                      ) {
                    diff.delete = true;
                    return;
                  }

                  //set default type to string
                  diff.type = 'string';

                  //only manipulates the fields needed
                  switch (diff.path) {
                    case 'bookId':
                        diff.label = 'ID';
                        break;
                    case 'bookName':
                        diff.label = 'Name';
                        break;
                    case 'bookCode':
                        diff.label = 'Code';
                        break;
                    case 'psiCode':
                        diff.label = 'PSI Code';
                        break;
                    case 'divisionId':
                        diff.label = 'Division';
                        diff.lhs = diff.lhs?$filter('readableDivision')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableDivision')(diff.rhs):null;
                        break;
                    case 'bookOwner':
                        diff.label = 'Owner';
                        diff.lhs = diff.lhs?$filter('readableUser')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableUser')(diff.rhs):null;
                        break;
                    case 'docTypeId':
                        diff.label = 'Document Type';
                        // gets master doctype too
                        diff.lhs = diff.lhs?$filter('readableMasterDocType')(diff.lhs)+" / "+$filter('readableDocType')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableMasterDocType')(diff.rhs)+" / "+$filter('readableDocType')(diff.rhs):null;
                        break;
                    case 'approvalList':
                        diff.label = 'Workflow';
                        diff.type = 'workflow';
                        //removes value:false
                        diff.lhs = _.filter(diff.lhs, function(o) { return o.value; });
                        //swaps out id for details
                        _.forEach(diff.lhs, function(v,i){
                          diff.lhs[i].role = $filter('readableRole')(parseInt(diff.lhs[i].role));
                          diff.lhs[i].team = $filter('readableTeam')(parseInt(diff.lhs[i].team));
                          diff.lhs[i].user = $filter('readableUser')(parseInt(diff.lhs[i].user));
                        });
                        //groups by workflow type - breif/artwork etc
                        diff.lhs = _.groupBy(diff.lhs, 'content');

                        diff.rhs = _.filter(diff.rhs, function(o) { return o.value; });
                        _.forEach(diff.rhs, function(v,i){
                          diff.rhs[i].role = $filter('readableRole')(parseInt(diff.rhs[i].role));
                          diff.rhs[i].team = $filter('readableTeam')(parseInt(diff.rhs[i].team));
                          diff.rhs[i].user = $filter('readableUser')(parseInt(diff.rhs[i].user));
                        });
                        diff.rhs = _.groupBy(diff.rhs, 'content');

                        console.log(diff.rhs);
                        break;
                    case 'emphasis':
                        if(thisBook.DocTypes.docTypeTemplate != 1){ diff.delete = true;}
                        else{
                          diff.label = 'Document Emphasis';
                          diff.lhs = diff.lhs?$filter('readableEmphasis')(diff.lhs):null;
                          diff.rhs = diff.rhs?$filter('readableEmphasis')(diff.rhs):null;
                        }
                        break;
                    case 'extras':
                        diff.label = 'BGHP Colour';
                        //currently the only field being used is bghp colour
                        diff.lhs = diff.lhs && diff.lhs.BGHPColour?$filter('readableBGHPColour')(parseInt(diff.lhs.BGHPColour)):null;
                        diff.rhs = diff.rhs && diff.rhs.BGHPColour?$filter('readableBGHPColour')(parseInt(diff.rhs.BGHPColour)):null;
                        break;
                    case 'insurerId':
                        diff.label = 'Insurer';
                        diff.lhs = diff.lhs?$filter('readableInsurer')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableInsurer')(diff.rhs):null;
                        break;
                    case 'productId':
                        diff.label = 'Product';
                        diff.lhs = diff.lhs?$filter('readableProduct')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableProduct')(diff.rhs):null;
                        break;
                    case 'regionId':
                        diff.label = 'Region';
                        diff.lhs = diff.lhs?$filter('readableRegion')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableRegion')(diff.rhs):null;
                        break;
                    case 'productTypeId':
                        diff.label = 'Product Type';
                        diff.lhs = diff.lhs?$filter('readableProductType')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableProductType')(diff.rhs):null;
                        break;
                    case 'clientId':
                        diff.label = 'Client';
                        diff.lhs = diff.lhs?$filter('readableClient')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableClient')(diff.rhs):null;
                        break;
                    case 'audienceId':
                        diff.label = 'Audience';
                        diff.lhs = diff.lhs?$filter('readableAudience')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableAudience')(diff.rhs):null;
                        break;
                    case 'language':
                        diff.label = 'Language';
                        diff.lhs = diff.lhs?$filter('readableLanguage')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableLanguage')(diff.rhs):null;
                        break;
                    case 'subInsurerId':
                        diff.label = 'Sub Insurer';
                        diff.lhs = diff.lhs?$filter('readableSubInsurer')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableSubInsurer')(diff.rhs):null;
                        break;
                    case 'distributorID':
                        diff.label = 'Distributor';
                        diff.lhs = diff.lhs?$filter('readableDistributor')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableDistributor')(diff.rhs):null;
                        break;
                    case 'projectId':
                        diff.label = 'Project';
                        diff.lhs = diff.lhs?$filter('readableProject')(diff.lhs):null;
                        diff.rhs = diff.rhs?$filter('readableProject')(diff.rhs):null;
                        break;
                    case 'socialChannelIds':
                        diff.label = "Social Channels";
                        if (diff.lhs && diff.lhs.length > 0) {
                            var count = 0;
                            var tempLhs = "";
                            _.forEach(diff.lhs, function(id){
                                if (count === 0) {
                                    count++;
                                } else {
                                    tempLhs += ", ";
                                }
                                tempLhs += $filter('readableSocialChannel')(id);
                            });
                            diff.lhs = tempLhs;
                        } else {
                            diff.lhs = null;
                        }
                        if (diff.rhs && diff.rhs.length > 0) {
                            var count = 0;
                            var tempRhs = "";
                            _.forEach(diff.rhs, function(id){
                                if (count === 0) {
                                    count++;
                                } else {
                                    tempRhs += ", ";
                                }
                                tempRhs += $filter('readableSocialChannel')(id);
                            });
                            diff.rhs = tempRhs;
                        } else {
                            diff.rhs = null;
                        }

                        break;
                    case 'finProm':
                        diff.label = 'Financial Promotion';
                        //might need some checks
                        diff.lhs = diff.lhs;
                        diff.rhs = diff.rhs;
                        break;
                    case 'limitDoc':
                        diff.label = 'Private Document';
                        //might need some checks
                        diff.lhs = diff.lhs;
                        diff.rhs = diff.rhs;
                        break;
                    case 'production':
                        diff.label = 'Production Manager';
                        diff.type = 'array'
                        _.forEach(diff.lhs, function(v,i){ diff.lhs[i] = $filter('readablePublishTypes')(parseInt(i)); });
                        _.forEach(diff.rhs, function(v,i){ diff.rhs[i] = $filter('readablePublishTypes')(parseInt(i)); });
                        break;
                    case 'fulfilment':
                        diff.label = 'Fulfilment';
                        diff.type = 'array'
                        _.forEach(diff.lhs, function(v,i){ diff.lhs[i] = $filter('readablePublishTypes')(parseInt(i)); });
                        _.forEach(diff.rhs, function(v,i){ diff.rhs[i] = $filter('readablePublishTypes')(parseInt(i)); });
                        break;
                    case 'digital':
                        diff.label = 'Digital & Social';
                        diff.type = 'array'
                        _.forEach(diff.lhs, function(v,i){ diff.lhs[i] = $filter('readablePublishTypes')(parseInt(i)); });
                        _.forEach(diff.rhs, function(v,i){ diff.rhs[i] = $filter('readablePublishTypes')(parseInt(i)); });
                        break;
                    case 'support':
                        diff.label = 'Support Tools';
                        diff.type = 'array'
                        _.forEach(diff.lhs, function(v,i){ diff.lhs[i] = $filter('readablePublishTypes')(parseInt(i)); });
                        _.forEach(diff.rhs, function(v,i){ diff.rhs[i] = $filter('readablePublishTypes')(parseInt(i)); });
                        break;
                    case 'inSitu':
                        diff.label = 'Evidence';
                        diff.type = 'array'
                        _.forEach(diff.lhs, function(v,i){ diff.lhs[i] = $filter('readablePublishTypes')(parseInt(i)); });
                        _.forEach(diff.rhs, function(v,i){ diff.rhs[i] = $filter('readablePublishTypes')(parseInt(i)); });
                        break;
                    case 'levels':
                        if(thisBook.DocTypes.docTypeTemplate != 1){ diff.delete = true;}
                        else {
                          diff.label = 'Levels';
                          diff.type = 'array';
                          diff.lhs = _.filter(diff.lhs, function(o) { return o.active; });
                          diff.rhs = _.filter(diff.rhs, function(o) { return o.active; });
                          _.forEach(diff.lhs, function(v,i){ diff.lhs[i] = diff.lhs[i].name; });
                          _.forEach(diff.rhs, function(v,i){ diff.rhs[i] = diff.rhs[i].name; });
                        }
                        break;
                    case 'bookContent':
                        diff.label = 'Content';
                        diff.type = 'array';
                        //revoves false objects
                        diff.lhs = _.pickBy(diff.lhs, _.identity);
                        diff.rhs = _.pickBy(diff.rhs, _.identity);
                        _.forEach(diff.lhs, function(v,i){ diff.lhs[i] = $filter('readableContent')(parseInt(i)); });
                        _.forEach(diff.rhs, function(v,i){ diff.rhs[i] = $filter('readableContent')(parseInt(i)); });
                        break;
                    case 'languageVersions':
                        diff.label = 'Language Versions';
                        diff.type = 'array';
                        //revoves false objects
                        diff.lhs = _.pickBy(diff.lhs, _.identity);
                        diff.rhs = _.pickBy(diff.rhs, _.identity);
                        _.forEach(diff.lhs, function(v,i){ diff.lhs[i] = $filter('readableLanguage')(parseInt(i)); });
                        _.forEach(diff.rhs, function(v,i){ diff.rhs[i] = $filter('readableLanguage')(parseInt(i)); });
                        break;
                    case 'created':
                        diff.label = 'Created Date';
                        diff.lhs = diff.lhs?$filter('date')(diff.lhs, 'dd MMM yyyy'):null;
                        diff.rhs = diff.rhs?$filter('date')(diff.rhs, 'dd MMM yyyy'):null;
                        break;
                    case 'activeDate':
                        diff.label = 'Active Date';
                        diff.lhs = diff.lhs?$filter('date')(diff.lhs, 'dd MMM yyyy'):null;
                        diff.rhs = diff.rhs?$filter('date')(diff.rhs, 'dd MMM yyyy'):null;
                        break;
                    case 'liveDate':
                        diff.label = 'Live Date';
                        diff.lhs = diff.lhs?$filter('date')(diff.lhs, 'dd MMM yyyy'):null;
                        diff.rhs = diff.rhs?$filter('date')(diff.rhs, 'dd MMM yyyy'):null;
                        break;
                    case 'reviewDate':
                        diff.label = 'Review Date';
                        diff.lhs = diff.lhs?$filter('date')(diff.lhs, 'dd MMM yyyy'):null;
                        diff.rhs = diff.rhs?$filter('date')(diff.rhs, 'dd MMM yyyy'):null;
                        break;
                    case 'expiryDate':
                        diff.label = 'Expiry Date';
                        diff.lhs = diff.lhs?$filter('date')(diff.lhs, 'dd MMM yyyy'):null;
                        diff.rhs = diff.rhs?$filter('date')(diff.rhs, 'dd MMM yyyy'):null;
                        break;
                    case 'withdrawDate':
                        diff.label = 'Withdraw Date';
                        diff.lhs = diff.lhs?$filter('date')(diff.lhs, 'dd MMM yyyy'):null;
                        diff.rhs = diff.rhs?$filter('date')(diff.rhs, 'dd MMM yyyy'):null;
                        break;
                    case 'notes':
                        diff.label = 'Notes';
                        break;
                    default:
                      //sets unused fields to deleted
                      diff.delete = true;
                  }
                });

                //deletes unrequired fields
                var changes = _.filter(diffs, function(o) { return !o.delete; });

                //sets display order
                var order = [
                              'bookName',
                              'bookCode',
                              'psiCode',
                              'divisionId',
                              'bookOwner',
                              'docTypeId',
                              'approvalList',
                              'emphasis',
                              'extras',
                              'insurerId',
                              'productId',
                              'regionId',
                              'productTypeId',
                              'clientId',
                              'audienceId',
                              'language',
                              'subInsurerId',
                              'distributorID',
                              'projectId',
                              'finProm',
                              'limitDoc',
                              'production',
                              'fulfilment',
                              'digital',
                              'support',
                              'inSitu',
                              'levels',
                              'bookContent',
                              'languageVersions',
                              'created',
                              'activeDate',
                              'liveDate',
                              'reviewDate',
                              'expiryDate',
                              'withdrawDate',
                              'notes',
                            ];

                //sorts as per order
                changes.sort(function(a, b){
                  return order.indexOf(a.path) - order.indexOf(b.path)
                });

                //console.log("changes",version.bookVersionId,changes);

                //         change.path == 'approvalList' ||



                // adds event with message for each qualifing version
                if (changes && changes.length > 0) {
                  var fieldsString = changes.length > 2 ?
                      changes[0].label + ', ' + changes[1].label + ' and ' + (changes.length - 2) + ' other fields edited.' :
                      changes.length > 1 ?
                          changes[0].label + ' and ' + changes[1].label + ' edited.':
                          changes[0].label + ' edited.';

                  var event = {
                      eventName: 'Document Info Edited',
                      created: version.created,
                      Users: self.getUserById(version.userId),
                      Comment: {
                          message: fieldsString,
                          diff: changes
                      }
                  }
                  events.push(event);
                }

              }

            });

            angular.extend(self.selectedEvents, events);
            deferred.resolve(events);
          });
        });


      });


      return deferred.promise;
    };







    self.getQREFs = function (isLoading){

      var deferred = $q.defer();
      isLoading = true;
      Books.find({
        filter: {
          where: {
            docTypeId: 33,
            expiryDate: {gt: moment(new Date())},
            and: [{
              or: [{
                earlyExpiryDate: {gt: moment(new Date())}
              }, {
                earlyExpiryDate: null
              }]
            }]
          }
        }
      }).$promise.then(function (data) {

        _.forEach(data, function(value, key) {
          var inFinals = _.findIndex(value.approvalList, ['content', 'final']);
          if(inFinals >= 0 || value.status == 1){
            value.isLive = true;
          }
        });

        isLoading = false;
        deferred.resolve(data);
      });
      return deferred.promise;
    }


      self.toggleTooltip = function(helpText) {
        /*self.helpText = 'Hover over elements in the page to find out what they do';
        self.showToolTip = (self.showToolTip ? false : true);*/
        $rootScope.$emit("toggleTooltip");
      };

      self.displayHelpText = function (helpText) {
        $rootScope.$emit("tooltip", helpText);
      };




    self.toJson = function(data) {
      if(angular.isObject(data)){
        return angular.toJson(data);
      }else{
        return data;
      }
    }

    self.isValidJsonStr = function(str) {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
    }


    self.charToFollowString = function(str, search) {
      var result;
      var regexp = new RegExp(search+'\\s*(\\d+)', 'i');
      if((result = regexp.exec(str)) && str){
        return result[1];
      }else{
        return false;
      }


      //created regexp version above to replace
      // if(str && str.indexOf(search)>=0) {
      //   if(str.charAt(str.indexOf(search) + search.length)==" "){
      //     return str.charAt(str.indexOf(search) + search.length+1);
      //   }else{
      //
      //     return str.charAt(str.indexOf(search) + search.length);
      //   }
      //
      // }else{
      //   return false;
      // }
    }

    self.isImage = function(src) {

      var deferred = $q.defer();

      var image = new Image();
      image.onerror = function() {
        deferred.resolve(false);
      };
      image.onload = function() {
        deferred.resolve(true);
      };
      image.src = src;

      return deferred.promise;
    }


    String.prototype.ucFirst = function()
    {
      return this.charAt(0).toUpperCase() + this.substr(1);
    }


  }
  Wraps.$inject = ["$filter", "$localStorage", "$location", "$q", "$rootScope", "$sessionStorage", "$templateCache", "$timeout", "$window", "clientConfig", "AppVersions", "Books", "BooksVersions", "Clients", "Containers", "Contents", "DivisionMetaLink", "Divisions", "DocContents", "EnclosureTypes", "Events", "History", "Languages", "Messages", "NotificationTypes", "Notifications", "MetaTables", "PdfVersions", "Projects", "PublishTypes", "RfcCategories", "RfcJoin", "RfcReasons", "Roles", "SocialChannels", "Structures", "system", "Teams", "Users", "Versions"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name library.service:WrapsBooks
   *
   * @description
   *
   */
  angular
    .module('library')
    .service('WrapsBooks', WrapsBooks);

  function WrapsBooks(
      $filter,
      $location,
      $localStorage,
      $mdDialog,
      $q,
      $rootScope,
      $sessionStorage,
      $state,
      clientConfig,
      system,
      Books,
      Containers,
      Contents,
      EnclosureTypes,
      Versions,
      PdfVersions,
      Notifications,
      History,
      Events,
      Structures,
      Wraps) {
    var self = this;

    self.get = function () {
      return 'WrapsBooks';
    };


      if(!$localStorage.pagination){
          $localStorage.pagination = {};
      }
      if(!$localStorage.pagination.startRow){
          $localStorage.pagination.startRow = 0;
      }
      if(!$localStorage.pagination.noResults){
          $localStorage.pagination.noResults = 100;
      }
      if(!$localStorage.pagination.filterLibrary){
          $localStorage.pagination.filterLibrary = {};
      }
      self.pagination = $localStorage.pagination;
      self.pagination.totalRows = 0;

    self.clientConfig = clientConfig;

    self.getBooks = function (filterLibrary, startRow) {
      var isTab = false;
      //console.log('startRow', startRow);
      if(typeof startRow == "undefined"){
        isTab = true;
        startRow = 0;
      }

      var filterObject = {"startRow":startRow, "noResults":self.pagination.noResults, "searchArray":filterLibrary};

      var deferred = $q.defer();

      // self.statuses = Structures.getStatuses();
      //sql = "SELECT bookId,  approvalStatus as id, status , count(approvalStatus) as count FROM Structures S  WHERE S.deleted = 0  GROUP BY approvalStatus, bookId , status"

      Books.getLibrarySearch(filterObject).$promise.then(function (results) {
        var books = results.data;
        if(!isTab) {
            self.pagination.totalRows = results.totalRows[0].result_count;
        }

        _.forEach(books, function (book) {

          /*self.statuses.$promise.then(function (statuses) {
            checkBookActions(book, statuses, $localStorage.user);
          });*/

          book.userInBook = userInBookApprovalList(book, $localStorage.user);

          book.updateBook = updateBook;
        });

        deferred.resolve(books);
      });
      return deferred.promise;
    };


    self.books = self.getBooks(self.pagination.filterLibrary, self.pagination.startRow); //initial call and assignment



    self.getLibraryList = function(){
        self.books = self.getBooks(self.pagination.filterLibrary, self.pagination.startRow);
      return self.books;
    }



    var updateBook = function(){
      var book = this;
      var deferred = $q.defer();
      book.isLoading = true;
      getBook(book.bookId).then(function (results) {

         _.forEach(book, function(element, i) { // removes all element before merge but keeps binding
             delete book[i];
         });
        _.merge(book, results);
        //book.isLoading = false;

        deferred.resolve(book);
      }).catch(function(error){
        console.log("book updateBook error:", error);
        deferred.reject(error);
      });
      return deferred.promise;
    }



    self.updateBookById = function(bookId){
      bookId = _.toInteger(bookId);
      var deferred = $q.defer();
      var book = _.find(self.openBooks, { 'bookId': bookId});

      //console.log(self.openBooks, bookId);

      book.updateBook().then(function () {
        deferred.resolve();
      }).catch(function(error){
        console.log("book updateBookById error:", error);
        deferred.reject(error);
      });
      return deferred.promise;
    }


    self.getBookById = function(bookId){
      bookId = _.toInteger(bookId);
      var book = _.find(self.openBooks, { 'bookId': bookId});
      return book;
    }


    self.updateAllTabs = function() {
      _.forEach(self.openBooks, function (book, ind) {
        book.updateBook();
      });
    }


    var getBook = function (bookId) {
      var book = {};
      var deferred = $q.defer();
      book.isLoading = true;
      var tabindex  = _.findIndex(self.openTabs, function(o) { return _.toInteger(o.bookId) == _.toInteger(bookId) });
      var tab       = self.openTabs[tabindex];
      if(tab){tab.updateRequired = false};

      if(bookId > 0) {
        self.getBooks({bookId: bookId}).then(function (results) {

          if (results[0]) {
            //console.log(results[0]);
            book = results[0];
            book.isLoading = true;

            // this is so the newly called book does not try to update,
            // the other opentabs might need to wait for the next cycle though - this might need to be chacked
            self.lastChecked = "3000-01-01";

            Structures.find({
              filter: {
                "where": {"and": [{"bookId": book.bookId}, {"parent": null}]},
                "order": "order ASC",
                "include": [{"versions": {"Contents": ["ContentTypes", "Teams"]}}, "History",
                  {
                    "Children": [{"versions": {"Contents": ["ContentTypes", "Teams"]}}, "History",
                      {
                        "Children": [{"versions": {"Contents": ["ContentTypes", "Teams"]}}, "History",
                          {"Children": [{"versions": {"Contents": ["ContentTypes", "Teams"]}}, "History"]}
                        ]
                      }
                    ]
                  }]
              }
            }).$promise.then(function (bookResults) {

              book.Children = bookResults;




              _.forEach(book.Children, function (chapter) {
                if (Wraps.json2Obj(chapter)) {
                  chapter.versions.versiondata = chapter.chapterCopy;
                  chapter.versions.chapterTypeId = chapter.chapterTypeId;

                  if (chapter.chapterTypeId === 3) {

                    const qrefFilterObject = {"startRow":0, "noResults":1, "searchArray":{bookId: chapter.chapterLinkId}};
                    Books.getLibrarySearch(qrefFilterObject).$promise.then(function (qrefResults) {

                      qrefResults = qrefResults.data[0];

                      chapter.versions.versiondata = chapter.versions.versiondata + "" +
                          "<b>ID:</b> " + qrefResults.bookIdLong + "<br /><b>Name:</b> " + qrefResults.bookName + "<br /><b>Code:</b> " + qrefResults.bookCode + "<br /><b>Workflow Status:</b> " + qrefResults.workflowStatus + "<br /><b>Document Status:</b> " + qrefResults.documentStatus;
                    });
                  } else if (chapter.chapterTypeId === 2) {
                    if (chapter.PDFList && chapter.PDFList[0]) {
                      var br = "<br />";
                      chapter.versions.versiondata = chapter.versions.versiondata + "" + "<b>Cover Filename:</b> " + chapter.PDFList[0].fileName;
                    } else {
                      var br = "";
                    }
                    if (chapter.filesList && chapter.filesList[0]) {
                      chapter.versions.versiondata = chapter.versions.versiondata + br + "<b>Logo Filename:</b> " + chapter.filesList[0].fileName;
                    }
                  }
                } else {
                  chapter.chapterTypeId = 1;
                }

                if (chapter.versions.Contents.contentTypeId === 9) {
                  book.hasBrief = true;
                  chapter.hasBrief = true;
                  //console.log("hasBrief", book);

                } else if (chapter.versions.Contents.contentTypeId === 10) {
                  book.hasArtwork = true;
                  chapter.hasArtwork = true;
                  //console.log("hasArtwork", chapter);

                } else if (chapter.versions.Contents.contentTypeId === 13) {
                  book.hasFinal = true;
                  chapter.hasFinal = true;
                  //console.log("hasFinal", chapter);
                }

                if (chapter.versions.Contents.ContentTypes.isJson === true) {
                  Wraps.json2Obj(chapter);
                }

                chapter.Children = _.orderBy(chapter.Children, 'order', 'asc');
                _.forEach(chapter.Children, function (level1) {
                  if (level1.versions.Contents.ContentTypes.isJson === true) {
                    Wraps.json2Obj(level1);
                  }
                  level1.Children = _.orderBy(level1.Children, 'order', 'asc');
                  _.forEach(level1.Children, function (level2) {
                    if (level2.versions.Contents.ContentTypes.isJson === true) {
                      Wraps.json2Obj(level2);
                    }
                    level2.Children = _.orderBy(level2.Children, 'order', 'asc');
                    _.forEach(level2.Children, function (level3) {
                      if (level3.versions.Contents.ContentTypes.isJson === true) {
                        Wraps.json2Obj(level3);
                      }
                    });
                  });
                });

                if (self.hasContentType(chapter, 6)) {
                  //Needs to be fixed at a later point - the words do not update live
                    $rootScope.$watch(function () {
                       return chapter;
                    }, function (newVal, oldVal) {
                       self.setBoldWords(chapter, book);
                       //console.log("setBoldWords watch",book.boldWords.length);
                    }, true);
                }

              });

              //TODO put this in a function
              Structures.getStatuses({'bookId': book.bookId}).$promise.then(function (thisStatuses) {

                if (angular.isArray(book.approvalList)) {
                  //ole.log('test1');
                  book.docApprovalList = _.filter(book.approvalList, {'content': "doc"});
                  book.briefApprovalList = _.filter(book.approvalList, {'content': "brief"});
                  book.artworkApprovalList = _.filter(book.approvalList, {'content': "artwork"});
                  book.finalApprovalList = _.filter(book.approvalList, {'content': "final"});
                }

                //checkBookActions(book, thisStatuses, $localStorage.user);

                // book.statusBar = setStatusBar(book, self.statuses, book.approvalList);

                if (book.docApprovalList.length > 0) {
                  book.docStatusBar = setStatusBar(book, thisStatuses, book.docApprovalList);
                }
                if (book.briefApprovalList.length > 0) {
                  book.briefStatusBar = setStatusBar(book, thisStatuses, book.briefApprovalList);
                }
                if (book.artworkApprovalList.length > 0) {
                  book.artworkStatusBar = setStatusBar(book, thisStatuses, book.artworkApprovalList);
                }
                if (book.finalApprovalList.length > 0) {
                  book.finalStatusBar = setStatusBar(book, thisStatuses, book.finalApprovalList);
                }

                book.enclosures = {};
                //console.log("vm.enclosureData",vm.enclosureData);
                _.forEach(book.Enclosures, function (enclosure) {
                  book.enclosures['enclosure_' + enclosure.enclosureType] = enclosure.enclosureBookCode;
                });

                book.productIdsSelectedArray = [];
                _.forEach(book.ProductIdsBookLink, function (prodId) {
                  book.productIdsSelectedArray[prodId.productId] = true;
                });


                book.insurerDetailsSelectedArray = [];
                _.forEach(book.InsurerDetails, function (insurerDetail) {
                  book.insurerDetailsSelectedArray.push(parseInt(insurerDetail.insurerId));
                });
                if(book.insurerDetailsSelectedArray.length<=0 && book.insurerId != 38){
                  book.insurerDetailsSelectedArray.push(parseInt(book.insurerId));
                }


                //book.insurerDetailsSelectedArray = [8,37];
                //console.log(book.insurerDetailsSelectedArray);



                book.userInBook = userInBookApprovalList(book, $localStorage.user); //array of indexes on approval list of current user

                if (book.currentWorkflowStatus) {
                  //show brief
                  //console.log('book.approvalStatus',book.approvalStatus);
                  //console.log('book.currentWorkflowStatus',book.currentWorkflowStatus);
                  //console.log('book.userInBook',book.userInBook);

                  if (book.hasBrief || (book.currentWorkflowStatus.content === 'brief' && (book.currentWorkflowStatus.role === 1 || book.currentWorkflowStatus.role === 2))) {
                    book.showBrief = true;
                  }

                  //show add brief button
                  if (!book.hasBrief && book.currentWorkflowStatus.content === 'brief' && _.indexOf(book.userInBook, book.approvalStatus) >= 0 && (book.currentWorkflowStatus.role === 1 || book.currentWorkflowStatus.role === 2)) {
                    book.showBriefButton = true;
                  } else {
                    book.showBriefButton = false;
                  }

                  //show artwork
                  if (book.hasArtwork || (book.currentWorkflowStatus.content === 'artwork' && book.currentWorkflowStatus.role === 6)) {
                    book.showArtwork = true;
                  }

                  //show artwork button
                  if (!book.hasArtwork && book.currentWorkflowStatus.content === 'artwork' && _.indexOf(book.userInBook, book.approvalStatus) >= 0 && book.currentWorkflowStatus.role === 6) {
                    book.showArtworkButton = true;
                  } else {
                    book.showArtworkButton = false;
                  }

                  //show final
                  if (book.hasFinal || (book.currentWorkflowStatus.content === 'final' && book.currentWorkflowStatus.role === 6)) {
                    book.showFinal = true;
                  }

                  //show add final artwork button
                  if (!book.hasFinal && book.currentWorkflowStatus.content === 'final' && _.indexOf(book.userInBook, book.approvalStatus) >= 0 && book.currentWorkflowStatus.role === 6) {
                    book.showFinalButton = true;
                  } else {
                    book.showFinalButton = false;
                  }
                }

                if (book.pdfVersion) {
                  book.pdfVersionUrl = '/' + book.pdfVersion;
                } else {
                  if (book.docTypeId == 93) {
                    book.pdfVersionUrl = '/bghp';
                  }
                }

                if (_.size(_.filter(book.levels, {'active': true})) === 5) {
                  book.expWidth = "24%";
                } else {
                  book.expWidth = "38%";
                }

                book.isLoading = false;
                if($state.params.bookId == book.bookId){
                  Wraps.book = book;
                }

                if (book.DocTypes && book.DocTypes.docTypeTemplate === 1) { //only get WCR if dynamic doc

                  Structures.getWCR({"bookId": book.bookId}).$promise.then(function (results) {

                    book.changes = addChanges(results, null);

                    book.getChangesByStructureId = function (structureId) {
                      //console.log("getChangesByStructureId test: ", _.find(book.changes, {"structureId": structureId}));
                      return _.find(book.changes, {"structureId": structureId});
                    }

                    book.wcrChanges = angular.copy(book.changes);
                    _.remove(book.wcrChanges, {'versionIdNew': null});
                    _.remove(book.wcrChanges, {'versionIdNew': 0, 'versionIdOld': 0});


                    //console.log("book.changes",book.changes);
                    deferred.resolve(book);
                  });
                }else{
                  deferred.resolve(book);
                }
              });
            });

          } else {
            book = {'bookId':bookId, 'isLoading':false, 'permissionDenied': true};
            book.updateBook = updateBook;
            deferred.resolve(book);
            //deferred.reject("no results returned from getbook");
          }
        });
      } else {
        deferred.reject("invalid bookId");
      }
      return deferred.promise;
    };

    //openBooks / tabs
    if(typeof $localStorage.openTabs == "undefined"){
        $localStorage.openTabs = [];
    }

     // this is so nothing gets updated in the fist cycle,
     // it shouldn't need to anyways as it's just loaded,
     // after the fist cycle this time is set from the server api return
     self.lastChecked = "3000-01-01";

    $localStorage.openTabs = _.uniqBy($localStorage.openTabs, 'bookId'); //removes duplicates
    self.openTabs = $localStorage.openTabs;
    self.openBooks = [];



    //
    self.loadTab = function(bookId){
      var deferred = $q.defer();

      var tabindex  = _.findIndex(self.openTabs, function(o) { return _.toInteger(o.bookId) == _.toInteger(bookId) });
      var tab       = self.openTabs[tabindex];
      var book      = self.openBooks[tabindex];

      //console.log("test",tabindex,tab,book,self.openTabs);
      if(tab && !book){
          tab.isLoading = true;
          getBook(bookId).then(function(results) {
              if (results) {
                  self.openBooks[tabindex] = results;
                  tab.isLoading = false;
                  tab.updateRequired == false;
                  deferred.resolve();
              }
          }).catch(function(error) {
            // delete from tabs if not returned
            self.openTabs.splice(tabindex, 1);
            deferred.reject();
          });
      }else{
        if(bookId == 0){
          self.openTabs.splice(tabindex, 1);
        }
          deferred.resolve();
      }
      return deferred.promise;
    }



      _.forEach(self.openTabs, function (openTab, ind) {
        self.loadTab(openTab.bookId);
      });




    //end openBooks / tabs


    // gets array of indexes current user is in book
    var userInBookApprovalList = function (book, user) {
      var indexes = [];
      _.forEach(book.approvalList, function (item, index) {
        item.role = parseInt(item.role);
        if (!((item.role === 3 || item.role === 8) && book.bookOwner === $localStorage.user.id)) { //not approver or proofreader and owner (owner cannot approver their own documents)

          if (item.user === user.id) {
            indexes.push(index);
          } else if ((angular.isUndefined(item.user) || item.user === '') && item.team === user.teamId) {
            indexes.push(index);
          }
        }
      });
      //console.log(indexes);
      return indexes;
    }


    // user for WCR
    var addChanges = function (list, parentId, location) {
      var changes = [];

      var levels = _.remove(list, {'parent': parentId});
      _.forEach(levels, function (item) {

        if (item.contentTypeId === 1 && item.versionData && item.versionData.charAt(0) === "{") {
          var json = angular.fromJson(item.versionData);
          item.versionData = json.chapterCopy;
        }


        if (item.contentTypeId === 5 || item.contentTypeId === 6 || item.contentTypeId === 7 || item.contentTypeId === 8 || item.contentTypeId === 11 || item.contentTypeId === 12 || item.contentTypeId === 17 || item.contentTypeId === 20) {
          Wraps.json2ObjWCR(item);
        }

        if (parentId === null) {
          item.location = "<strong>" + item.contentTypeName + "</strong>";
        } else {
          item.location = location + " • " + "<strong>" + item.contentTypeName + "</strong>";
        }
        ;
        changes = changes.concat(item);

        if (item.contentTypeId === 11) {
          if (item.json2 !== null) {
            var location2 = item.location + ": " + item.json2.tableHeader[0].name;
          }
        } else {
          if (item.versionData) {
            var location2 = item.location + ": " + item.versionData.replace(/<\/?[^>]+>/gi, '');
          }
        }

        if (item.contentTypeId === 1) {

          if (item.versionData1 && item.versionData1.charAt(0) === "{") {
            var json1 = angular.fromJson(item.versionData1);
            item.versionData1 = json1.chapterCopy;
            if (json1['chapterTypeId'] == 3) {
              item.versionData1 += "<span>QREF DOC ID: " + json1.chapterLinkId + "</span>";
            } else if (json1['chapterTypeId'] == 2) {
              if (json1.PDFList && json1.PDFList[0]) {
                item.versionData1 += "<span>Cover Filename: " + json1.PDFList[0].fileName + "</span>";
              }
              if (json1.filesList && json1.filesList[0]) {
                item.versionData1 += "<span>Logo Filename: " + json1.filesList[0].fileName + "</span>";
              }
            }
          }
          if (item.versionData2 && item.versionData2.charAt(0) === "{") {
            var json2 = angular.fromJson(item.versionData2);
            item.versionData2 = json2.chapterCopy;
            if (json2['chapterTypeId'] == 3) {
              item.versionData2 += "<span>QREF DOC ID: " + json2.chapterLinkId + "</span>";
            } else if (json2['chapterTypeId'] == 2) {
              if (json2.PDFList && json2.PDFList[0]) {
                item.versionData2 += "<span>Cover Filename: " + json2.PDFList[0].fileName + "</span>";
              }
              if (json2.filesList && json2.filesList[0]) {
                item.versionData2 += "<span>Logo Filename: " + json2.filesList[0].fileName + "</span>";
              }
            }
          }

        }

        changes = changes.concat(addChanges(list, item.structureId, location2));
      });
      //console.log("changes",changes);
      return changes;
    }

    // gets array of active approval statuses for a book
    var findApproversInBook = function (book, statuses) {
      return _.map(_.filter(statuses, {'status': 0, 'bookId': book.bookId}), 'id');
    }

    /*var checkBookActions = function (book, statuses, user) {
      book.action = false;
      book.actionColour = '';

      if (!book.approvalList[book.approvalStatus - 1]) {
        //console.log('xxx',book.bookId,book.approvalList);
      }

      var now = moment().format('YYYY-MM-DD');
      if (book.status === 0 && ((book.earlyExpiryDate == null || moment(book.earlyExpiryDate).format('YYYY-MM-DD') > now) && moment(book.expiryDate).format('YYYY-MM-DD') > now)) {
        var count = 0;
        var lastRole = false;
        // if membguides / BGHP
        if (book.DocTypes && book.DocTypes.docTypeTemplate == 1) {
          _.forEach(findApproversInBook(book, statuses), function (item, index) {//findApproversInBook returns array of appstatus
            count++;
            if (count === 2 && lastRole === 6) {
              book.action = false;
            }
            if (book.approvalList[item]) {
              lastRole = book.approvalList[item].role;
            }

            if (_.indexOf(book.userInBook, item) >= 0 && book.approvalStatus >= item) {
              //console.log(book.bookId,book.approvalList[item]);
              book.action = true;
            }
          });
        }

        if (_.indexOf(book.userInBook, book.approvalStatus) >= 0) { //current user approvelist indexes in  book status
          book.action = true;
        }
      } else if (

        ((book.earlyExpiryDate != null && now >= moment(book.earlyExpiryDate).format('YYYY-MM-DD')) || now >= moment(book.expiryDate).format('YYYY-MM-DD'))
        &&
        (book.status === 1 || (book.approvalStatus > 0 && book.approvalList[book.approvalStatus] && book.approvalList[book.approvalStatus].content == 'final'))
        &&
        book.bookOwner === $localStorage.user.id
        &&
        (book.withdrawDate == null || now < moment(book.withdrawDate).format('YYYY-MM-DD'))
      ) {
        book.action = true;
        book.actionColour = 'redAction';
      } else {
        book.action = false;
        book.actionColour = '';
      }

    }*/


    var setStatusBar = function (book, statuses, approvalList) {
      var statusBar = [];
      var ord = 0;
      //console.log("statuses",statuses);
      var newIndex = 0;
      _.forEach(approvalList, function (item, index) {
        if (approvalList[0].content == "artwork") {
          newIndex = index + _.findIndex(book.approvalList, ['content', 'artwork']);
        } else if (approvalList[0].content == "final") {
          newIndex = index + _.findIndex(book.approvalList, ['content', 'final']);
        } else {
          newIndex = index;
        }
        if (item.value) {

          var stat = {};
          stat.ord = ord++;
          if (item.role) {
            if (_.isInteger(parseInt(item.role)) && item.role != 3) {
              stat.name = $filter('uppercase')(Wraps.getRoleById(item.role).description);
            } else if (parseInt(item.team) > 0) {
              stat.name = $filter('uppercase')(Wraps.getTeamById(item.team).shortName);
            }
            if (Wraps.getTeamById(item.team)) {
              stat.tooltip = Wraps.getTeamById(item.team).teamName;
            } else {
              stat.tooltip = "All";
            }
            if (Wraps.getUserById(item.user)) {
              stat.tooltip += " - " + Wraps.getUserById(item.user).firstName + " " + Wraps.getUserById(item.user).lastName;
            } else {
              stat.tooltip += " - All";
            }
          } else {
            stat.name = $filter('uppercase')(item.type);
          }


          //console.log("type",type);

          stat.count = _.result(_.find(statuses, {
            'status': 0,
            'id': newIndex,
            'bookId': book.bookId
          }), 'count');
          if (angular.isUndefined(stat.count)) {
            stat.count = '';
          }

          if (stat.count !== '' && book.approvalStatus >= newIndex) {
            stat.colour = 'green';
          } else if (book.approvalStatus >= newIndex || book.status === 1) {
            stat.colour = 'grey'
          } else {
            stat.colour = 'white'
          }

          statusBar.push(stat);
        }
      });

      if (statusBar.length > 0) {
        var stat = {};
        stat.ord = ord++;
        stat.name = "READY";
        stat.tooltip = "Ready";
        stat.count = _.result(_.find(statuses, {'status': 1, 'id': newIndex, 'bookId': book.bookId}), 'count');
        if (angular.isUndefined(stat.count)) {
          stat.count = '';
        }
        if (book.status === 1) {
          stat.colour = 'green';
        } else {
          stat.colour = 'white';
        }
        statusBar.push(stat);
      }


      return statusBar;

    }

    self.hasContentType = function (parent, type) {
      if (parent.Children) {
        for (var i = 0; i < parent.Children.length; i++) {
          if (parent.Children[i].versions.Contents.contentTypeId === type) {
            return true;
            break;
          }
        }
      }

    }


    self.setBoldWords = function (chapter, book) {

      book.boldWords = [];
      angular.forEach(chapter.Children, function (child) {

        //console.log(angular.copy(child), child.glossary);
        if (child.glossary) {

          if (angular.isArray(book.boldWords)) {
            if (child.glossary.words) {
              book.boldWords = book.boldWords.concat(child.glossary.words.trim()
                .replace("&nbsp;", " ")
                .replace(/\s*,[,\sp]*\b/g, ",")
                .replace(/,*\s*$/, "")
                .replace("’", "'") //this is new for apostophe
                .split(","));
            }
          } else {
            book.boldWords = child.glossary.words.trim()
              .replace(/&nbsp;/g, " ")
              .replace(/\s*,[,\sp]*\b/g, "")
              .replace(/,*\s*$/, ",")
              .replace("’", "'") //this is new for apostophe
              .split(",");
          }
        }
      });
    }

    self.stringifySearch = function (searchObj) {
      searchObj = angular.copy(searchObj);
      var searchString = [];
      var count = 0;
      if(searchObj && searchObj['sortOrder']) {
        delete searchObj['sortOrder'];
      }
      if(searchObj && searchObj['sortDirection']) {
        delete searchObj['sortDirection'];
      }
      _.forEach(searchObj, function (search, field) {
        if(search == '') {
          delete searchObj[search];
        }else {
          count++;
          searchString.push(_.startCase(_.camelCase(field)));
        }
      })

      if(count===0) {
        return '';
      }else {
        return count + ' (' +searchString.join(', ') + ')';
      }
    }





    self.uploadPDF = function (book){

      if(system === "local") {
        var linkExtras = "";
      }else if(system === "demo") {
        var linkExtras = "&demo=true";
      }
      var deferred = $q.defer();
      var timestamp = new Date().getTime();
      var itemType = "final";
      if(book.docTypeId == 93) {
        var pdfaddress = self.clientConfig.pdfUrl + "/bghp/?book=" + book.bookId + linkExtras;
      }else{

        var pdfaddress = self.clientConfig.pdfUrl + "/?book=" + book.bookId + linkExtras;
      }


      var description = "<p>Final PDF</p>\n";
      var container = 'wraps-files';
      var fileLocation = itemType+'/'+timestamp;
      var fileName = book.bookCode+".pdf";


      Containers.uploadFinalPDF({"url":pdfaddress,"container":container, "filename":fileLocation + "/" + fileName}).$promise.then(function (response) {
        //console.log(response);

        var artwork = {"artwork":{
          "description": description,
          "filesList":[
            {
              "container": container,
              "fileLocation":fileLocation,
              "fileName": fileName,
              "fileSize": response[0].filesize,
              "ngfName": fileLocation + "/" + fileName
            }
          ]
        }};

        var owner = 20; //design -  was artworkData.artworkTeam.teamId

        Contents.create({contentId: 0, contentTypeId: 13, contentName: "Final PDF", contentOwner: owner}, function (data1) {

          Versions.create({versionId: 0, contentId: data1.contentId, versiondata: Wraps.toJson(artwork)}, function (data2) {

            Structures.create({structureId: 0, versionId: data2.versionId, contentId: data1.contentId, bookId: book.bookId, "status": 0, "approvalStatus": book.approvalStatus , parent: null, order: data2.versionId}, function (data3) {
              Books.upsert({ "bookId": book.bookId,  "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});


              PdfVersions.find({filter: {"where": {"docTypeId": book.docTypeId }, "order": "pdfVersionId DESC", "limit": 1}}, function (pdfData) {
                Books.upsert({ "bookId": book.bookId,  "lastUpdated": Date.now(), pdfVersion: pdfData.pdfVersionId });
              });
              History.create({
                "structureId": data3.structureId,
                "versionIdOld": 0,
                "versionIdNew": data3.versionId,
                "historyId": 0
              },function(historyResponse){
                Events.create({
                  "eventId": 0,
                  "bookId": book.bookId,
                  "structureId": data3.structureId,
                  "contentId": data1.contentId,
                  "historyId": historyResponse.historyId,
                  "userId": Wraps.$storage.user.id,
                  "eventType": "Added",
                  "eventData": "Final PDF added"
                });
              });
              //self.updateBookById(book.bookId);
              //book.getBook(true);
              deferred.resolve();


            });
          });
        });

      });
      return deferred.promise;
    }

    self.setBookStatuses = function(thisBook){
      //console.log('setBookStatuses:',thisBook.bookId);


      var deferred = $q.defer();

      // waiting for api logic move
      Books.setBookStatuses({bookId: thisBook.bookId, userId:Wraps.$storage.user.id }).$promise.then(function (results) {
        //console.log(results);

        self.updateBookById(thisBook.bookId);
        Notifications.getCounts();
        deferred.resolve(results);
      });
      return deferred.promise;


    }

    self.filenameInCodeCheck = function (filename, bookCode) {
      //console.log(filename,bookCode,filename.search(bookCode));
      if(filename && filename.search(bookCode) >= 0){
          return true;
      }else{
          return false;
      }
    }

      self.downloadCSV = function (searchArray) {

          var deferred = $q.defer();
          var csv;
          EnclosureTypes.find({
              "filter": {
                  "where": {
                      enclosureTypeStatus: 1
                  },
                  "order": "enclosureTypeOrder ASC"
              }
          }).$promise.then(function (enclosureTypes) {
              self.enclosureTypes = enclosureTypes;
              //console.log("enclosureTypes", enclosureTypes);
              self.buildCSV(searchArray).then(function(csv){

                  deferred.resolve(csv);

              });
          });
          return deferred.promise;
      }


      self.buildCSV = function (searchArray) {
          var booksCSV = [];
          var deferred = $q.defer();

          var getWorkflowUsers = function (approvalList) {
              var workflowUsers = [];
              _.forEach(approvalList, function (value, name) {
                  // if(Wraps.getUserById(value.user)) {
                  //     workflowUsers.push(Wraps.getUserById(value.user).firstName + ' ' + Wraps.getUserById(value.user).lastName);
                  // }
                  var thisUser = '';
                  if (Wraps.getTeamById(value.team)) {
                      thisUser = Wraps.getRoleById(value.role).description + "-" + Wraps.getTeamById(value.team).teamName;
                  } else {
                      thisUser = "All";
                  }
                  if (Wraps.getUserById(value.user)) {
                      thisUser = Wraps.getRoleById(value.role).description + "-" + Wraps.getUserById(value.user).firstName + " " + Wraps.getUserById(value.user).lastName;
                  } else {
                      thisUser += "-All";
                  }
                  if (thisUser.length > 0) {
                      workflowUsers.push(thisUser);
                  }
              });
              return workflowUsers;
          }

          var getPublishing = function (array) {
              if(array[0] == '{') {
                  var newarray = Object.values(array);
                  newarray = newarray.join('');
                  try{
                      array = angular.fromJson(newarray);
                  }catch(err){
                      array = angular.fromJson(array);
                  }
              }else{
                  array = angular.fromJson(array);
              }

              var publishTypes = [];
              _.forEach(array, function (value, name) {
                  if (value == true) {
                      publishTypes.push(_.get(_.find(Wraps.publishTypesNotFiltered, {'id': _.toInteger(name)}), 'name'));
                  }
              });
              return publishTypes;
          }

          if (!searchArray) {
            searchArray = self.pagination.filterLibrary;
          }

          var filterObject = {"startRow":0, "noResults":0, "searchArray":searchArray};


          Books.getLibrarySearch(filterObject).$promise.then(function (results) {

              var books = results.data;


              _.forEach(books, function (book) {
                  var loopDefered = $q.defer();
                  //console.log(book);
                  var ContentList = [];
                  if (book.bookContent) {
                      book.bookContent = angular.fromJson(book.bookContent);
                      _.forEach(book.bookContent, function (value, key) {
                          if (value) {
                              ContentList.push(_.get(_.find(Wraps.docContents, {'id': _.toInteger(key)}), 'name'));
                          }
                      });
                      ContentList = ContentList.toString();
                  } else {
                      ContentList = "";
                  }

                  var otherLanguageVersions = [];
                  if (book.languageVersions) {
                      book.languageVersions = angular.fromJson(book.languageVersions);
                      _.forEach(book.languageVersions, function (value, key) {
                          if (value) {
                              if (book.bookId === 1308) {
                                  //console.log("val:", value);
                              }
                              otherLanguageVersions.push(_.get(_.find(Wraps.languages, {'id': _.toInteger(key)}), 'name'));
                          }
                      });
                      otherLanguageVersions = otherLanguageVersions.toString();
                  } else {
                      otherLanguageVersions = "";
                  }


                  var workflowUsersDoc = getWorkflowUsers(_.filter(book.approvalList, {content: 'doc'}));
                  var workflowUsersBrief = getWorkflowUsers(_.filter(book.approvalList, {content: 'brief'}));
                  var workflowUsersArtwork = getWorkflowUsers(_.filter(book.approvalList, {content: 'artwork'}));
                  var workflowUsersFinal = getWorkflowUsers(_.filter(book.approvalList, {content: 'final'}));
                  var enclosures;


                  var newBook = {};

                  var owner = "";
                  if (Wraps.getUserById(book.bookOwner)) {
                      owner = Wraps.getUserById(book.bookOwner).firstName + " " + Wraps.getUserById(book.bookOwner).lastName;
                  }

                  newBook.bookId = book.bookId;
                  newBook.bookName = book.bookName;
                  newBook.bookCode = book.bookCode;
                  //newBook.psiCode = book.psiCode;

                  newBook.DocTypeCatName = book.DocTypes ? book.DocTypes.DocTypeCats.docTypeCatName : "";
                  newBook.DocType = book.DocTypes ? book.DocTypes.docTypeName : "";
                  newBook.Owner = owner;
                  newBook.division = book.Divisions ? book.Divisions.division : "";
                  newBook.workflowStatus = book.workflowStatus;
                  newBook.documentStatus = book.documentStatus;

                  newBook.Insurer = book.Insurers ? book.Insurers.insurerName : "";
                  newBook.InsurerDetails = book.InsurerDetailsText ? book.InsurerDetailsText : "";
                  newBook.Audience = book.Audiences ? book.Audiences.audienceName : "";
                  newBook.Region = book.Regions ? book.Regions.regionName : "";
                  newBook.Product = book.Products ? book.Products.productName : "";
                  newBook.CustomerType = book.ProductTypes ? book.ProductTypes.productTypeName : "";
                  newBook.SubInsurer = book.SubInsurer ? book.SubInsurer.subInsurerName : "";
                  newBook.Distributor = book.Distributor ? book.Distributor.distributorName : "";

                  if(book.finProm == null){
                    newBook.finProm = "Not set";
                  }else if(book.finProm  == "true" ){
                    newBook.finProm = "Yes";
                  }else{
                    newBook.finProm = "No";
                  }

                  newBook.limitDoc = book.limitDoc ? "Yes" : "No";
                 // newBook.BGHPColour = book.extras && book.extras.BGHPColour ? $filter('readableBGHPColour')(book.extras.BGHPColour) : "";

                  // if (book.socialChannelIds && book.socialChannelIds.length > 0) {
                  //   var count = 0;
                  //   newBook.socialChannels = "";
                  //   _.forEach(book.socialChannelIds, function(id){
                  //     if (count === 0) {
                  //       count++;
                  //     } else {
                  //         newBook.socialChannels += ", ";
                  //     }
                  //     newBook.socialChannels += $filter('readableSocialChannel')(id);
                  //   });
                  // } else {
                  //     newBook.socialChannels = "";
                  // }

                  newBook.workflowUsersDoc = workflowUsersDoc.join(", ");
                  newBook.workflowUsersBrief = workflowUsersBrief.join(", ");
                  newBook.workflowUsersArtwork = workflowUsersArtwork.join(", ");
                  newBook.workflowUsersPublishing = workflowUsersFinal.join(", ");
                  newBook.language = book.language ? book.Languages.languageName : "";

                  // newBook.production = book.production ? getPublishing(book.production).join(", ") : "";
                  // newBook.fulfilment = book.fulfilment ? getPublishing(book.fulfilment).join(", ") : "";
                  // newBook.digital = book.digital ? getPublishing(book.digital).join(", ") : "";
                  // newBook.support = book.support ? getPublishing(book.support).join(", ") : "";
                  // newBook.inSitu = book.inSitu ? getPublishing(book.inSitu).join(", ") : "";


                  newBook.client = book.clientId ? book.Clients.clientName : "";
                  newBook.sourceId = book.sourceId;
                  newBook.projectName = book.projectId ? book.Projects.projectName : "";

                  newBook.createdDate = $filter('date')(book.created, "dd/MM/yyyy");
                  newBook.activeDate = $filter('date')(book.activeDate, "dd/MM/yyyy");
                  newBook.liveDate = $filter('date')(book.liveDate, "dd/MM/yyyy");
                  if ($filter('date')(book.reviewDate, "yyyy") !== '3000') {
                      newBook.reviewDate = $filter('date')(book.reviewDate, "dd/MM/yyyy");
                  } else {
                      newBook.reviewDate = '';
                  }
                  if ($filter('date')(book.expiryDate, "yyyy") !== '3000') {
                      newBook.expiryDate = $filter('date')(book.expiryDate, "dd/MM/yyyy");
                  } else {
                      newBook.expiryDate = '';
                  }
                  newBook.earlyExpiryDate = $filter('date')(book.earlyExpiryDate, "dd/MM/yyyy");
                  newBook.withdrawDate = $filter('date')(book.withdrawDate, "dd/MM/yyyy");
                  //newBook.lastUpdated = $filter('date')(book.lastUpdated, "dd/MM/yyyy");

                  newBook.notes = book.notes;
                  newBook.ContentList = ContentList;
                  newBook.otherLanguageVersions = otherLanguageVersions;


                  var now = moment().format('YYYY-MM-DD');
                  var expiryDate = $filter('date')(book.expiryDate, "yyyy-MM-dd");
                  var earlyExpiryDate = $filter('date')(book.earlyExpiryDate, "yyyy-MM-dd");

                  var isLive = (book.status || (_.findIndex(book.approvalList, ['content', 'final']) > 0 && book.approvalStatus >= _.findIndex(book.approvalList, ['content', 'final'])));

                  //newBook.isLive  = isLive?"Yes":"No";

                  if (!book.withdrawDate && (expiryDate < now || earlyExpiryDate < now) && isLive) {
                      newBook.blackAsset = "Yes";
                      var tempExpiry = (earlyExpiryDate == null || expiryDate < earlyExpiryDate) ? expiryDate : earlyExpiryDate;


                      newBook.daysAsBlackAsset = moment(now).subtract(1, 'day').diff(moment(tempExpiry), 'days');
                  } else {
                      newBook.blackAsset = "No";
                      newBook.daysAsBlackAsset = "";
                  }


                  //newBook.MCOMPinWorkflow = _.findIndex(book.approvalList, ['team', 10]) > 0 ? "Yes" : "No";

                  //newBook.COMPinWorkflow = _.findIndex(book.approvalList, ['team', 6]) > 0 ? "Yes" : "No";

                  //now in SP from endpoint
                  // this was excluding the mco but the SP is including it, we need to check with Sara to see what the right thing to do is?
                  newBook.MCOMPinWorkflow = book.MCOMPinWorkflow;
                  newBook.COMPinWorkflow = book.COMPinWorkflow;;

                  // newBook.productIds = _.map(book.ProductIdsBookLink, 'productId').join(', ');

                  // _.forEach(self.enclosureTypes, function (item) {
                  //     var encType = "";
                  //     var colName = "enclosure" + item.enclosureTypeName.replace(/ /g, "");
                  //     newBook[colName] = "";
                  //     if (encType = _.filter(book.Enclosures, {'enclosureType': "" + item.enclosureTypeId})[0]) {
                  //         //console.log("encType", encType);
                  //         newBook[colName] = encType.enclosureBookCode;
                  //     }
                  // });

                  booksCSV.push(newBook);

              });
              deferred.resolve(booksCSV);
          });
          return deferred.promise;

      }


  }
  WrapsBooks.$inject = ["$filter", "$location", "$localStorage", "$mdDialog", "$q", "$rootScope", "$sessionStorage", "$state", "clientConfig", "system", "Books", "Containers", "Contents", "EnclosureTypes", "Versions", "PdfVersions", "Notifications", "History", "Events", "Structures", "Wraps"];
}());

(function () {
  'use strict';

  angular
    .module('library')
    .config(config);

  function config($stateProvider) {
    $stateProvider
        .state('library', {
            url: '/library/:bookId',
            params: {
                bookId: {
                    dynamic: true,
                    value: null,
                    squash: true
                }
            },
            views: {
                tooltip: {
                  templateUrl: 'tooltip/tooltip.tpl.html',
                  controller: 'TooltipCtrl',
                  controllerAs: 'tooltip'
                },
                navigation: {
                  templateUrl: 'navigation/navigation.tpl.html',
                  controller: 'NavigationCtrl',
                  controllerAs: 'navigation'
                },
                main: {
                  templateUrl: 'library/library.tpl.html',
                  controller: 'LibraryCtrl',
                  controllerAs: 'library'
                },
                footer: {
                  templateUrl: 'footer/footer.tpl.html',
                  controller: 'FooterCtrl',
                  controllerAs: 'footer'
                }
            }
        }).state('libraryLink', {
      url: '/library/link/:docId',
      views: {
        main: {

          controller: ["$scope", "$localStorage", "$sessionStorage", "$location", "$stateParams", "atob", function ($scope, $localStorage, $sessionStorage, $location, $stateParams, atob) {
            var selected;
            // get the id
            $scope.id = $stateParams.docId;


            $scope.$storage = $localStorage;
            $scope.$session = $sessionStorage;

            // console.log('$location', $location);
            // console.log('$location.$$url', $location.$$url);
            if ($stateParams.docId) {
              selected = angular.fromJson(atob(decodeURIComponent($stateParams.docId)));
              // console.log("selected", selected);

              $scope.$storage.selectedBook = selected.selectedBook;
              $scope.$storage.selectedCopy = selected.structureId;
              $scope.$storage.filterLibrary = {bookIdLong: selected.selectedBook};
              $scope.$session.selectedList = selected.selectedList;

              $location.url('/library');
            }
          }]
        }
      }
    });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name library.directive:libraryContentRow
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="library">
       <file name="index.html">
        <library-content-row></library-content-row>
       </file>
     </example>
   *
   */
  angular
    .module('library')
    .directive('childItems', childItems)
    .directive('contentMenu', contentMenu)
    .directive('wcr', wcr)
    .directive('doc', doc)
    .directive('docItems', docItems)
    .directive('rightMenu', rightMenu)
    .directive('leftMenu', leftMenu)
    .directive('leftComments', leftComments)
    .directive('addComments', addComments)
    .directive('getContents', getContents);




  function childItems($compile) {
    return {
      restrict: "A",
      templateUrl: 'library/library-directives/library-row.tpl.html',
      scope: true,
      link: function (scope, element, attrs) {
        if (angular.isArray(scope.child.Children)) {
         var newList = angular.element("<md-list ng-show=\"$storage.selectedChapters\"><md-list-item child-items layout-wrap  ng-repeat=\"child in child.Children | orderBy: 'order'\" child=\"child\" ></md-list-item></md-list>");
         element.append(newList);
         $compile(newList)(scope);
        }

      }

    };
  }
  childItems.$inject = ["$compile"];

  function contentMenu($compile) {
    return {
      restrict: "E",
      templateUrl: 'library/library-directives/library-menu.tpl.html',
      scope: false,

      link: function(scope, element, attributes) {
        //console.log(scope.$eval(attributes.parentbook));

        scope.parentbook = scope.$eval(attributes.parentbook);
        scope.parent = scope.$eval(attributes.parent);
        scope.child = scope.$eval(attributes.child);
        scope.indexNo = scope.$eval(attributes.index);
      }



    };
  }
  contentMenu.$inject = ["$compile"];

  function wcr($compile) {
    return {
      restrict: "E",
      templateUrl: 'library/library-directives/library-wcr.tpl.html',
      scope: false,
      link: function(scope, element, attributes){
        //console.log(scope.child);
        scope.book = scope.$eval(attributes.book);
      }


    };
  }
  wcr.$inject = ["$compile"];

  function doc($compile) {
    return {
      restrict: "E",
      templateUrl: 'library/library-directives/library-doc.tpl.html',
      scope: false,
      link: function(scope, element, attributes){
        //console.log(scope.child);
        scope.book = scope.$eval(attributes.book);
      }


    };
  }
  doc.$inject = ["$compile"];

  function docItems($compile) {
    return {
      restrict: "E",
      templateUrl: 'library/library-directives/library-docItems.tpl.html',
      scope: false,
      replace: true,
      transclude: true,
      link: function(scope, element, attributes){
        scope.book = scope.$eval(attributes.book);
        scope.parent = scope.$eval(attributes.parent);
        scope.child = scope.$eval(attributes.child);
        scope.indexNo = scope.$eval(attributes.index);
        scope.child.indexNo = scope.$eval(attributes.index);
        scope.child.prev = scope.$eval(attributes.prev);
        scope.child.next = scope.$eval(attributes.next);



        scope.$watch(attributes.next, function (newValue) {
          scope.child.next = scope.$eval(attributes.next);
        });
        scope.$watch(attributes.prev, function (newValue) {
          scope.child.prev = scope.$eval(attributes.prev);
        });
        scope.$watch(attributes.index, function (newValue) {
          scope.child.indexNo = scope.$eval(attributes.index);
        });



      }


    };
  }
  docItems.$inject = ["$compile"];

  function rightMenu($compile) {
    return {
      restrict: "E",
      templateUrl: 'library/library-directives/library-rightMenu.tpl.html',
      scope: false,
      link: function(scope, element, attributes){
        //scope.book = scope.$eval(attributes.book);
      }
    };
  }
  rightMenu.$inject = ["$compile"];

  function leftMenu($compile) {
    return {
      restrict: "E",
      templateUrl: 'library/library-directives/library-leftMenu.tpl.html',
      scope: false,
      link: function(scope, element, attributes){

      }
    };
  }
  leftMenu.$inject = ["$compile"];

  function leftComments($compile) {
    return {
      restrict: "E",
      templateUrl: 'library/library-directives/library-leftComments.tpl.html',
      scope: true,
      controller:  libraryleftCommentsCtrl,
      controllerAs: 'leftComments',
      link: function(scope, element, attributes){

      }
    };
  }
  leftComments.$inject = ["$compile"];
  var libraryleftCommentsCtrl = function($scope, $element, $attrs, Wraps, $interval){
    var vm = this;
    //TODO not ideal
    Wraps.selectedEvents = [];
    Wraps.intervalLoadComments = $interval(function () {
      vm.isLoading = true;
      Wraps.loadComments(Wraps.commentsBook).then(function(events) {
        vm.events = Wraps.selectedEvents;
        vm.isLoading = false;
        //console.log('from interval');
      });
    }, 60000);

    vm.book = Wraps.commentsBook;
    $scope.$watch( function () { return Wraps.selectedEvents.length; }, function (data) {
      vm.isLoading = true;
      Wraps.loadComments(Wraps.commentsBook).then(function(events) {
        vm.events = Wraps.selectedEvents;
        vm.isLoading = false;
        //console.log('from watch');
      });

    });
  }
  libraryleftCommentsCtrl.$inject = ["$scope", "$element", "$attrs", "Wraps", "$interval"];



  function addComments($compile) {
    return {
      restrict: "E",
      templateUrl: 'library/library-directives/library-addComments.tpl.html',
      scope: {
        child: '='
      },
      controller:  libraryAddCommentsCtrl,
      controllerAs: 'addComments',
      link: function(scope, element, attributes){
        //console.log("addComments link");
        //scope.book = scope.$eval(attributes.book);
      }
    };
  }
  addComments.$inject = ["$compile"];


  var libraryAddCommentsCtrl = function($scope, clientConfig, $element, $attrs, $interval, $q, $localStorage, $mdConstant, apiUrl, Wraps, Users){
     var vm = this;
     vm.apiUrl = apiUrl;


    vm.clientConfig = clientConfig;

      $scope.$storage = $localStorage;
      vm.keys = [$mdConstant.KEY_CODE.COMMA];
      vm.additionalRecipients = [];
      vm.selected = [];
      vm.userslist = [];

     //console.log("addComments libraryAddCommentsCtrl start");
      vm.defaultContacts = [{
          name: 'Wraps Team',
          email: vm.clientConfig.email,
          status: 1
      }];

      vm.mergeSelected = function () {
        var mergedContactList = vm.selected.concat(vm.additionalRecipients);
        return mergedContactList;
      }

     vm.loadContacts = function() {

       Users.approvalListUsers(vm.book.bookId).$promise.then(function (contacts) {
         vm.allContacts = contacts.map(function (c, index) {
           var contact = {
             name: c.name,
             email: c.email,
             status: c.status
           };
           contact._lowername = contact.name.toLowerCase();
           return contact;
         });

         vm.contacts = [];
       });

       Users.find({ 'filter': { 'include': ['Teams', 'UsersStatus'] } }).$promise.then(function (results) {
           vm.userslist = results.map(function (c, index) {
             var contact = {
               name: c.Teams.teamName + ' / ' + c.firstName + ' ' + c.lastName,
               email: c.contactEmail,
               status: c.status
             };
             contact._lowername = contact.name.toLowerCase();
             return contact;
           }).filter(function (c) {
             return c.status === null;
           });
       });
     };

     vm.toggle = function (item, list) {
       var idx = list.indexOf(item);
       if (idx > -1) {
         list.splice(idx, 1);
       }
       else {
         list.push(item);
       }
     };

     vm.exists = function (item, list) {
       return list.indexOf(item) > -1;
     };

     vm.isIndeterminate = function() {
       return (vm.allContacts &&
           vm.selected.length !== 0 &&
           vm.selected.length !== vm.allContacts.length);
     };

     vm.isChecked = function() {
       if(vm.selected) {
         return (vm.allContacts &&
             vm.selected.length === vm.allContacts.length);
       }else {
         return false;
       }
     };

     vm.toggleAll = function() {
       if (vm.selected.length === vm.allContacts.length) {
         vm.selected = [];
       } else if (vm.selected.length === 0 || vm.selected.length > 0) {
         //console.log(vm.allContacts);
         vm.selected = vm.allContacts.slice(0);
       }
     };


     vm.book = Wraps.book;
     vm.contacts = '';

     vm.asyncContacts = [];
     vm.filterSelected = false;
    vm.loadContacts();

      vm.querySearch = function (criteria) {
          return criteria ? vm.userslist.filter(createFilterFor(criteria)) : [];
      }
      function createFilterFor(query) {
          var lowercaseQuery = query.toLowerCase();

          return function filterFn(contact) {
              return (contact._lowername.indexOf(lowercaseQuery) !== -1);
          };

      }


     vm.saveComments = function(thisBook, thisChild, thisComment, emailAddresses){
       vm.showForm = false;
       vm.comment = {};
       vm.selected = [];
       vm.commentForm.$setPristine();
       vm.commentForm.$setUntouched();
          Wraps.saveComments(thisBook, thisChild, thisComment, emailAddresses).then(function(){
            if(thisChild){
               // console.log("load workflow dialog events"); //loadEvents();
                $scope.$emit('newCommentCreated');
            }else{
                Wraps.selectedEvents.push("test");
            }
          });
       };

  }
  libraryAddCommentsCtrl.$inject = ["$scope", "clientConfig", "$element", "$attrs", "$interval", "$q", "$localStorage", "$mdConstant", "apiUrl", "Wraps", "Users"];




  function getContents($compile) {
    return {
      restrict: "E",
      templateUrl: 'library/library-directives/library-getContents.tpl.html',
      scope: {
        item: '=item'
      },
      controllerAs: 'vm',
      controller: ["$scope", "$element", "$attrs", "Wraps", function($scope, $element,$attrs, Wraps) {
        var vm = this;
        //vm.book = angular.copy(Wraps.book);
        vm.book = Wraps.book;
        vm.nl2br = Wraps.nl2br;

        vm.item = $scope.item;
      }],
      link: function(scope, element, attributes){
        //console.log(scope.$eval(attributes.item));
        //scope.item = scope.$eval(attributes.item);
      }


    };
  }
  getContents.$inject = ["$compile"];





}());

(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name library.controller:LibraryCtrl
     *
     * @description
     *
     */
    angular
        .module('library')
        .controller('LibraryCtrl', LibraryCtrl)
        .filter('unique', function () {
            return function (arr, field) {
                return _.uniqBy(arr, field);
            };
        });

    function LibraryCtrl(
        $document,
        $element,
        $filter,
        $interval,
        $localStorage,
        $location,
        $mdBottomSheet,
        $mdDialog,
        $mdSidenav,
        $mdToast,
        $q,
        $rootScope,
        $scope,
        $sessionStorage,
        $state,
        $timeout,
        $window,
        apiUrl,
        Audiences,
        Books,
        BooksVersions,
        Containers,
        Distributors,
        DocTypeCats,
        DocTypes,
        dragularService,
        Enclosures,
        EnclosureTypes,
        Events,
        History,
        InsurerDetails,
        Insurers,
        Messages,
        Notifications,
        OrderHistory,
        pdfUrl,
        Products,
        ProductIds,
        ProductIdsBookLink,
        ProductTypes,
        Regions,
        SaveSearch,
        Structures,
        SubInsurers,
        system,
        Upload,
        Users,
        Versions,
        Wraps,
        WrapsBooks
    ) {

        var vm = this;
        var bookMetaOriginalState = {};
        vm.ctrlName = 'LibraryCtrl';
        vm.apiUrl = apiUrl;

        if(system=='switch'){
          vm.enclosuresAllowedSubDocTypes = [];
          vm.productIdsAllowedSubDocTypes = angular.copy(vm.enclosuresAllowedSubDocTypes);
        }else{
          vm.enclosuresAllowedSubDocTypes = [55, 56, 57, 76, 77, 78, 80, 81, 82, 83, 84, 85, 117];
          vm.productIdsAllowedSubDocTypes = angular.copy(vm.enclosuresAllowedSubDocTypes);
          vm.productIdsAllowedSubDocTypes.push(1, 93, 32, 29, 97);
        }



        // [10, 29, 32, 55, 56, 57, 58, 61, 76, 77, 78, 80, 81, 82, 83, 84, 85, 86, 93, 97];
        // changed to match enclosure type re: Laura's email 31st Dec 2018, point 5
        // also includes, member guide, member guide (BGHP), member guide (GHP, HSAP & Bespoke only), Changes (List & Significant), IPID;

        $scope.$storage = $localStorage;
        vm.localStorage = $localStorage;
        $scope.$session = $sessionStorage;

        if (!Users.isAuthenticated()) {
            $location.path('/');
        }
        vm.hasAccess = null;
        Wraps.fetchedUser.then(function () {
            if (!vm.hasPermissions('viewLibrary')) {
                $state.go('login', {nextAfterLogin: 'Library'});
            } else {
                vm.hasAccess = true;
            }
        });


        vm.showScrollFabs = 'hideScrollFab';
        vm.pixelsScrolledToTop = 0;

        $document.on('scroll', function () {

            vm.pixelsScrolledToTop = $window.scrollY;
            if (vm.pixelsScrolledToTop > 250 && vm.showScrollFabs == 'hideScrollFab') {
                $scope.$applyAsync(function () {
                    vm.showScrollFabs = 'showScrollFab';
                });
            } else if (vm.pixelsScrolledToTop < 250 && vm.showScrollFabs == 'showScrollFab') {
                $scope.$applyAsync(function () {
                    vm.showScrollFabs = 'hideScrollFab';
                });
            }

        });

        vm.toTheTop = function () {
            $document.scrollTop(0, 1000);
        }


        //console.log(apiUrl);
        //console.log("system",system);

        vm.pdfUrl = pdfUrl;
        vm.system = system;

        if (system === "local") {
            vm.linkExtras = "&local=true";
        } else if (system === "demo") {
            vm.linkExtras = "&demo=true";
        } else if (system === "staging") {
            vm.linkExtras = "&staging=true";
        }

        vm.selected = [];
        vm.filterLibrary = {};
        vm.filterLibrary = angular.copy($scope.$storage.filterLibrary);
        vm.searchLibrary = angular.copy($scope.$storage.searchLibrary);
        vm.queryLibrary = angular.copy($scope.$storage.queryLibrary);

        if (angular.isUndefined($scope.reorderToggle)) {
            $scope.reorderToggle = false;
        }



        //loads from wraps service
        vm.states = Wraps.states;
        vm.levels = Wraps.levels;
        vm.emphasis = Wraps.emphasis;
        vm.setBookStatuses = WrapsBooks.setBookStatuses;
        vm.BGHPColour = Wraps.BGHPColour;

        vm.hasPermissions = Wraps.hasPermissions;

        vm.getTableWidths = Wraps.getTableWidths;

        vm.updateBookData = Wraps.updateBookData;

        vm.test = Wraps.test;

        vm.thisUser = angular.copy($scope.$storage.user);

        $scope.$storage.pdfVersion = 'v1.3'; //TODO connect to PDF API getLatestVersion


        vm.uploadPDF = function (book) {
            WrapsBooks.uploadPDF(book);
        }


        vm.isMemUploadable = function (book) {
            if (book.DocTypes.docTypeTemplate == 1 && $scope.$storage.user.id == 42) { //doctype
                return true;
            } else {
                return false;
            }
        }

        vm.isRepairWorkflow = function (book) {
          if (vm.hasPermissions('viewHealthcheck')) {
              return true;
          }
          return false;
        }

        vm.repairWorkflow = function (book) {
            WrapsBooks.setBookStatuses(book).then(function (emailAction) {
              vm.book.updateBook().then(function(){ });
          });
        }

        //TODO - use a model to generate this and control the view with ng-repeats on the filter list and columns
        if (!$scope.$storage.showFilters) {
            $scope.$storage.showFilters = {}

            //$scope.$storage.showFilters.id = true;
            $scope.$storage.showFilters.code = true;
            $scope.$storage.showFilters.name = false;
            $scope.$storage.showFilters.insurer = false;
            $scope.$storage.showFilters.product = true;

            $scope.$storage.showFilters.audience = false;
            $scope.$storage.showFilters.region = false;
            $scope.$storage.showFilters.productType = false;

            $scope.$storage.showFilters.doc = true;
            $scope.$storage.showFilters.docTypeCatName = false;
            $scope.$storage.showFilters.finProm = false;
            $scope.$storage.showFilters.clients = false;
            $scope.$storage.showFilters.projectName = false;

            $scope.$storage.showFilters.Owner = {};
            $scope.$storage.showFilters.Owner.fullName = true;
            $scope.$storage.showFilters.Owner.teamName = false;
            $scope.$storage.showFilters.workflowStatus = true;
            $scope.$storage.showFilters.documentStatus = true;
            $scope.$storage.showFilters.date = false;
            $scope.$storage.showFilters.lastUpdated = false;
            $scope.$storage.showFilters.activeDate = false;
            $scope.$storage.showFilters.liveDate = true;
            $scope.$storage.showFilters.reviewDate = false;
            $scope.$storage.showFilters.expiryDate = true;
            //$scope.$storage.showFilters.action = true;
        }


        Wraps.initVars = Wraps.initData();
        Wraps.initVars.then(function (data) {
            console.log("Init Data finished");
            //console.log("init: ",data);
            // vm.teams = data.teams;
            vm.users = data.users;
            // vm.roles = data.roles;
            //
            // vm.docContents = data.docContents;
            // vm.languages = data.languages;
             vm.publishTypes = data.publishTypes;
             vm.publishTypesNotFiltered = data.publishTypesNotFiltered;
            //
            // vm.rfcReasons = data.rfcReasons;
            // vm.rfcCategories = data.rfcCategories;
            // vm.rfcJoin = data.rfcJoin;
            //
            vm.clients = Wraps.clients;
            vm.projects = Wraps.projects;
            ////vm.updateBooks();
        });


        if (vm.filterLibrary && Object.keys(vm.filterLibrary).length > 0) {
            vm.searchLibrary = true;
        }



        SaveSearch.find({
            "filter": {
                "where": {
                    userId: $scope.$storage.loggedInUser.id,
                    deleted: 0
                }
            }
        }).$promise.then(function (saveSearch) {
            vm.savedSearches = saveSearch;
        });

        vm.saveSearch = function () {
            if (vm.searchName !== '') {
                SaveSearch.upsert({
                    userId: $scope.$storage.loggedInUser.id,
                    searchName: vm.searchName,
                    saveBlob: JSON.stringify({
                        filterLibrary: $scope.$storage.pagination.filterLibrary
                    }),
                    dateCreated: Date.now()
                }, function () {
                    SaveSearch.find({
                        "filter": {
                            "where": {
                                userId: $scope.$storage.loggedInUser.id,
                                deleted: 0
                            }
                        }
                    }).$promise.then(function (saveSearch) {
                        vm.savedSearches = saveSearch;
                    });
                })
            }
        }


        vm.checkBlankArray = function(arrayToTest) {
            return _.isEmpty(arrayToTest);
        }


        vm.openBook = function (book) {
            //$scope.$storage.selectedBook = book.bookId;
            WrapsBooks.updateBookById(book.bookId);
            //book.updateBook();
            vm.goToAnchor('anchorBook' + book.bookId);
        }


        $scope.$watch( function() { return WrapsBooks.books; }, function(returnedBooks) {
            vm.isLoading = true;
            if(returnedBooks) {
              returnedBooks.then(function (books) {
                vm.isLoading = false;
                vm.books = books;
              });
            }
        });

        const defaultCols = {
            code: true,
            name: true,
            insurer: true,
            Owner: {
                fullName: true
            },
            doc:true,
            documentStatus: true,
            workflowStatus: true,
            liveDate: true,
            expiryDate: true
        }

        vm.resetColVisibility = function(){
            $scope.$storage.showFilters = angular.copy(defaultCols);
        }



        vm.updateBooks = WrapsBooks.getLibraryList;


        vm.goToAnchor = function (anchor) {
            var anchorFunction = function () {
                var section2 = angular.element(document.getElementById(anchor));
                $document.scrollTo(section2, 84, 1000);
            }
            $timeout(anchorFunction, 100);
        }

        vm.showRandom = function (percentage) {

            _.forEach(vm.books, function (book) {
                book.complianceSelect = false;
            });

            var booksNeeded = _.ceil(vm.filteredLibrary.length * percentage / 100);
            var bookIds = _.take(_.shuffle(_.map(vm.filteredLibrary, 'bookId')), booksNeeded);

            _.forEach(bookIds, function (bookId) {
                _.find(vm.filteredLibrary, {'bookId': bookId}).complianceSelect = true;
            });


        }




        $scope.$watch(function () {
            return Wraps.updateBookData;
        }, function watchCallback(newValue, oldValue) {
            if (newValue) {
                var book = vm.bookById(Wraps.updateBookData);
                WrapsBooks.updateBookById(book.bookId);
                //book.updateBook();
                Wraps.updateBookData = false;
            }
        });

        //
        // var updateBook = function (force) {
        //     var thisBook = this;
        //     console.log("updateBook:", thisBook);
        //     if (angular.isUndefined(thisBook.Children)) {
        //         vm.getBook(thisBook);
        //         thisBook.isNew = true;
        //     } else {
        //         Books.findOne({
        //             "filter": {
        //                 "where": {"bookId": thisBook.bookId}
        //             }
        //         }).$promise.then(function (results) {
        //             if ((results.lastUpdated > thisBook.lastUpdated) || force) {
        //                 vm.getBook(thisBook);
        //             } else {
        //                 Wraps.book = thisBook;
        //             }
        //         });
        //     }
        //
        // }


        vm.bookById = function (id) {
            return _.find(vm.openBooks, {'bookId': id});
        }

        vm.childByStructureId = function (parent, structureId) {
            var child;
            if (parent.structureId === structureId) {
                child = parent;
            } else {
                angular.forEach(parent.Children, function (val1) {
                    if (!child) {
                        child = vm.childByStructureId(val1, structureId);
                    }
                });
            }
            return child;
        }

        //for testing the above function
        vm.rectest = function (parent, structureId) {
            console.log("test", vm.childByStructureId(parent, structureId));
        }

        vm.checkHistory = function (obj) {
            if (!angular.isUndefined(obj.History)) {
                if (obj.History[0].versionIdOld != obj.versionId) {
                    return 'changed';
                }
            }
            return false;
        }

        vm.checkHistoryChildren = function (obj) {
            var test = '';
            if (!angular.isUndefined(obj.Children)) {
                angular.forEach(obj.Children, function (child) {
                    if (vm.checkHistory(child)) {
                        test = 'chapterChanged';
                    } else {
                        if (vm.checkHistoryChildren(child)) {
                            test = 'chapterChanged';
                        }
                    }

                });
            }
            return test;
        }

        vm.checkVersion = function (obj) {
            if (obj.versions.status <= 1) {
                return 'changed';
            }
            return false;
        }

        vm.checkVersionChildren = function (obj) {
            var test = '';
            if (!angular.isUndefined(obj.Children)) {
                angular.forEach(obj.Children, function (child) {
                    if (vm.checkVersion(child)) {
                        test = 'chapterChanged';
                    } else {
                        if (vm.checkVersionChildren(child)) {
                            test = 'chapterChanged';
                        }
                    }

                });
            }
            return test;
        }


        vm.toggleSelected = function (id) {
            if (_.indexOf($scope.$session.selectedList, id) >= 0) {
                _.pull($scope.$session.selectedList, id);
            } else {
                //ADDED TO SHOW ONE ONE CHAPTER
                //$scope.$session.selectedList = [];
                //$scope.$storage.selectedCopy = 0;

                $scope.$session.selectedList.push(id);
            }
        }


        vm.consolelog = function (text) {
            console.log(text);
        }




        // vm.setBoldWords = function (chapter, book) {
        //
        //     book.boldWords = [];
        //     angular.forEach(chapter.Children, function (child) {
        //         if (child.glossary) {
        //
        //             if (angular.isArray(book.boldWords)) {
        //                 if (child.glossary.words) {
        //                     book.boldWords = book.boldWords.concat(child.glossary.words.trim()
        //                         .replace("&nbsp;", " ")
        //                         .replace(/\s*,[,\sp]*\b/g, ",")
        //                         .replace(/,*\s*$/, "")
        //                         .replace("’", "'") //this is new for apostophe
        //                         .split(","));
        //                 }
        //             } else {
        //                 book.boldWords = child.glossary.words.trim()
        //                     .replace(/&nbsp;/g, " ")
        //                     .replace(/\s*,[,\sp]*\b/g, "")
        //                     .replace(/,*\s*$/, ",")
        //                     .replace("’", "'") //this is new for apostophe
        //                     .split(",");
        //             }
        //         }
        //     });
        // }




        vm.nl2br = function (string) {
            try {
                return string.replace(/\n/g, '<br>');
            } catch (err) {
            }
        }

        vm.sideNavOpens = 0;


        vm.openRightMenu = function (book) {
            vm.sideNavOpens++;
            $scope.documentInfoForm.$setUntouched();
            $scope.documentInfoForm.$setPristine();

            vm.tabIndex = 0;
            $mdSidenav('right').toggle();
            if (!book) {
                vm.selected = [];
                vm.selected.book = {};
                vm.selected.book.co = true;

                vm.selected.book.divisionId = angular.copy($scope.$storage.user.divisionId);


                //vm.selected.book.earlyExpiryDate = moment(new Date());
                vm.selected.book.bookOwner = $scope.$storage.user.id;
                initWorkflowList();
                vm.selected.book.levels = vm.levels;
                //---dates---//


                vm.disableBookEdit = false;

                vm.temp = {DocTypeCatName: false};
                vm.switch = {};
                vm.switch.production = {flag: true};
                vm.switch.fulfilment = {flag: true};
                vm.switch.digital = {flag: true};
                vm.switch.support = {flag: true};
                vm.switch.inSitu = {flag: true};


                //console.log(vm.selected.book);
            } else {

                if (book.eventData && book.eventData.reason && book.eventData.reason == 48) {
                    vm.newLanguage = true;
                } else {
                    vm.newLanguage = false;
                }


                // if (book.activeDate) {
                //   book.activeDate = new Date(book.activeDate);
                // }
                // if (book.liveDate) {
                //   book.liveDate = new Date(book.liveDate);
                // }


                if(book.DocTypes){
                  vm.temp = {DocTypeCat : book.DocTypes.docTypeCatId};
                }else{
                  vm.temp = {};
                }

                vm.switch = {};
                if (_.isEmpty(book.production)) {
                    vm.switch.production = {flag: true};
                } else {
                    vm.switch.production = {flag: false};
                }

                if (_.isEmpty(book.fulfilment)) {
                    vm.switch.fulfilment = {flag: true};
                } else {
                    vm.switch.fulfilment = {flag: false};
                }

                if (_.isEmpty(book.digital)) {
                    vm.switch.digital = {flag: true};
                } else {
                    vm.switch.digital = {flag: false};
                }

                if (_.isEmpty(book.support)) {
                    vm.switch.support = {flag: true};
                } else {
                    vm.switch.support = {flag: false};
                }

                if (_.isEmpty(book.inSitu)) {
                    vm.switch.inSitu = {flag: true};
                } else {
                    vm.switch.inSitu = {flag: false};
                }



                vm.selected.book = angular.copy(book);


                if (vm.hasPermissions('masterDocumentEdit')) { // has permission to always edit.
                    vm.disableBookEdit = false;
                    // console.log('admin');
                } else if (book.duplicateId) {
                    if(vm.hasPermissions('duplicateDocuments')){
                        vm.disableBookEdit = false;
                        //console.log('duplicateId perms true');
                    }else if($scope.$storage.user.roleId === 2){ //need to remove this once transitioned to perms
                        vm.disableBookEdit = false;
                        //console.log('duplicateId mco true');
                    }else{
                        vm.disableBookEdit = true;
                        //console.log('duplicateId perms false');
                    }
                } else if (book.documentStatus.substring(0, 4) === 'EXPD'){ // expired book
                    vm.disableBookEdit = true;
                    // console.log('expired');
                } else if (_.indexOf(book.userInBook, book.approvalStatus) >= 0) {
                    if ($scope.$storage.user.roleId === 6) {
                        vm.disableBookEdit = true;
                        //console.log('design');
                    } else if (book.status >= 1) {
                        vm.disableBookEdit = true;
                        //console.log('live');
                    } else if (book.approvalList[book.approvalStatus] && book.approvalList[book.approvalStatus].role === 1 && book.approvalStatus == 0) {
                        vm.disableBookEdit = false;
                        //console.log('dlo');
                    } else if (book.approvalList[book.approvalStatus] && book.approvalList[book.approvalStatus].role === 2 && book.approvalStatus <= 1) {
                        vm.disableBookEdit = false;
                        //console.log('mco');
                    } else if (book.duplicateId) {
                        vm.disableBookEdit = false;
                        //console.log('duplicateId');
                    } else {
                        vm.disableBookEdit = true;
                        //console.log('else 1');
                    }
                } else {
                    vm.disableBookEdit = true;
                    //console.log('user not in book');
                }
                delete vm.selected.book.Children;

                //console.log("openRightMenu editing:",vm.selected.book);
                initWorkflowList();

                bookMetaOriginalState = angular.copy(vm.selected.book);
                delete bookMetaOriginalState.lastUpdated;
                delete bookMetaOriginalState.created;
                delete bookMetaOriginalState.Teams;
                delete bookMetaOriginalState.Insurers;
                delete bookMetaOriginalState.SubInsurers;
                delete bookMetaOriginalState.Distributors;
                delete bookMetaOriginalState.Audiences;
                delete bookMetaOriginalState.Regions;
                delete bookMetaOriginalState.Products;
                delete bookMetaOriginalState.ProductTypes;
                delete bookMetaOriginalState.changes;
                delete bookMetaOriginalState.boldWords;
                delete bookMetaOriginalState.Children;

                //if (bookMetaOriginalState.levels) { bookMetaOriginalState.levels = Wraps.toJson(bookMetaOriginalState.levels); }
                //if (bookMetaOriginalState.bookContent) {  bookMetaOriginalState.bookContent = Wraps.toJson(bookMetaOriginalState.bookContent); }
                //if (bookMetaOriginalState.languageVersions) { bookMetaOriginalState.languageVersions = Wraps.toJson(bookMetaOriginalState.languageVersions); }

                //---dates--//
                // vm.setBeforeLive(vm.selected.book);
                //vm.setBeforeExpiry(vm.selected.book);
                //vm.changeActiveDate(vm.selected.book);

            }

            vm.bookCodeList = _.map(vm.books, 'bookCode');
            _.pull(vm.bookCodeList, vm.selected.book.bookCode);

        };

        vm.closeRightMenu = function () {
            vm.tabIndex = 0;
            $mdSidenav('right').close()
                .then(function () {
                    //console.log(vm);
                });
        };

        vm.removeFile = function (array, index) {
            //console.log(index);
            array.splice(index, 1);
        }


        var timestamp = new Date().getTime();
        $scope.$watch('library.JSONFile', function () {
            if (vm.JSONFile && vm.JSONFile.name.search(/\.json/i) > -1) {
                vm.upload(vm.JSONFile).then(function (statuses) {
                    vm.isLoading = false;
                });
            }
        });

        vm.log = '';
        vm.upload = function (file) {

            var S3_BUCKET_URL = apiUrl + '/containers/wraps-files/upload';
            var deferred = $q.defer();

            if (file) {
                if (!file.$error) {
                    vm.isLoading = true;

                    file.container = 'wraps-files';
                    file.fileLocation = 'translationFiles/' + timestamp;
                    file.fileName = file.name;
                    file.fileSize = file.size;
                    vm.JSONFile.filesList = [];
                    vm.JSONFile.filesList.push(file);
                    //console.log(vm.JSONFile);

                    //TODO add timeout to animate files
                    var newName = file.fileLocation + '/' + file.name;
                    Upload.rename(file, newName);
                    Upload.upload({
                        url: S3_BUCKET_URL,
                        file: file

                    }).then(function (resp) {
                        //console.log(resp);
                        vm.selected.book.languageFile = newName;
                        deferred.resolve();
                    }, null, function (evt) {
                        file.determinateValue = parseInt(100.0 * evt.loaded / evt.total);

                    });
                }
            }

            return deferred.promise;
        };


        vm.setNow = function (check) {
            if (check) {
                vm.selected.book.earlyExpiryDate = new Date();
            } else {
                vm.selected.book.earlyExpiryDate = null;
            }

        }


        vm.openLeftMenu = function (selectTab) {
            vm.leftContent = "menu";
            $mdSidenav('left').toggle();
            if (!isNaN(selectTab)) {
                vm.searchName = '';
                vm.leftMenuTabIndex = selectTab;
            }
        };

        vm.loadSearchBlob = function (searchId) {
            SaveSearch.findOne({"filter": {"where": {searchId: searchId}}}, function (searchDetails) {
                searchDetails.saveBlob = JSON.parse(searchDetails.saveBlob);
                $localStorage.pagination.filterLibrary = searchDetails.saveBlob.filterLibrary;
                if(searchDetails.saveBlob.searchBooksText){
                    $localStorage.pagination.filterLibrary.textSearch = searchDetails.saveBlob.searchBooksText;
                }
                vm.searchLibrary = true;
                vm.closeLeftMenu();
            });
        }

        vm.deleteSearchSave = function (saveSearchId, saveSearchName, ev) {
            var confirm = $mdDialog.confirm()
                .title('Delete this search?')
                .textContent('This deletion is permanent. Are you sure you want to delete "' + saveSearchName + '"?')
                .ariaLabel('Delete search ID:' + saveSearchId)
                .targetEvent(ev)
                .ok('Delete')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function () {
                SaveSearch.upsert({searchId: saveSearchId, deleted: 1, dateDeleted: Date.now()}, function () {
                    SaveSearch.find({
                        "filter": {
                            "where": {
                                userId: $scope.$storage.loggedInUser.id,
                                deleted: 0
                            }
                        }
                    }).$promise.then(function (saveSearch) {
                        vm.savedSearches = saveSearch;
                    });
                });
            }, function () {

            });

        }

        vm.closeLeftMenu = function () {
            $mdSidenav('left').close()
                .then(function () {
                    vm.leftContent = "";
                });
        };


        vm.openLeftComments = function (thisBook) {
            vm.leftContent = "comments";
            Wraps.commentsBook = thisBook;
            $mdSidenav('left').toggle();

            $mdSidenav('left').onClose(function () {
                vm.leftContent = "";
                //console.log('closing');
                $interval.cancel(Wraps.intervalLoadComments);
            });
        }

        vm.closeLeftComments = function () {
            $mdSidenav('left').close();
        };


        vm.reorderClose = function (parent) {
            vm.orderingId = false;
            //console.log("close");
        }


        vm.reorderOpen = function (item, parent, contentType) {
            //console.log(parent);
            vm.orderingId = item.structureId;
            var siblings = parent.Children;
            initParaDrag(siblings, parent.structureId, contentType);
            $scope.$storage.selectedCopy = 0;
            if (contentType == 1) {
                //TODO move this whole function into tabBook component and toggleStructure
                //vm.toggleSelected(false);
                var tabindex  = _.findIndex(vm.openTabs, function(o) { return _.toInteger(o.bookId) == _.toInteger(item.bookId) });
                vm.openTabs[tabindex].selected.level1 = [];
            }
        }


        vm.isReorderingChildren = function (parent) {
            //if(_.find(parent.Children, { 'structureId': vm.orderingId })){console.log(parent);}
            return _.find(parent.Children, {'structureId': vm.orderingId});
        }


        vm.reorderSave = function (thisBook, itemId, parent) {

            var bookId = thisBook.bookId
            var confirm = $mdDialog.confirm()
                .title('Re-Order Siblings')
                .htmlContent('Are you sure you want to save the order of the siblings?<br /><b>This action cannot be undone.</b><br />')
                .ariaLabel('Confirm Order')
                .ok('Save')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function () {
                var newOrder = [];
                var oldOrder = [];

                angular.forEach(parent.Children, function (value, key) {
                    oldOrder.push({structureId: value.structureId, order: value.order});
                    newOrder.push({structureId: value.structureId, order: parseInt(key) + 1});
                });

                oldOrder = _.sortBy(oldOrder, 'order');

                Structures.updateOrder({
                    "bookId": bookId,
                    "parentStructureId": itemId,
                    "orderOld": Wraps.toJson(oldOrder),
                    "orderNew": Wraps.toJson(newOrder),
                    "userId": $scope.$storage.user.id
                }).$promise.then(function (response) {
                    //console.log(response);
                });
                vm.reorderClose(parent);
            }, function () {
                WrapsBooks.updateBookById(bookId);
                //thisBook.updateBook();

            });


        }

        vm.ordAbcBookDialog = function (item, siblings) {
            var confirm = $mdDialog.confirm()
                .title('Sort Siblings Alphabetically')
                .htmlContent('Are you sure you want to sort these siblings alphabetically?<br /> <b>This action cannot be undone.</b><br />')
                .ariaLabel('Confirm Order')
                .ok('Sort')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    vm.ordAbc(item, siblings);
                }, function () {

                });
        }

        vm.ordAbc = function (item, siblings) {

            var contentTypeId = item.versions.Contents.contentTypeId;
            var newOrder = [];
            var oldOrder = [];

            if ((contentTypeId >= 5 && contentTypeId <= 8) || contentTypeId == 17 || contentTypeId == 19 || contentTypeId == 20) {

                if (contentTypeId === 5 || contentTypeId == 17) {
                    siblings.sort(function (a, b) {
                        if (a.versions.Contents.contentTypeId === contentTypeId && b.versions.Contents.contentTypeId === contentTypeId) {
                            return a.benefit.name.localeCompare(b.benefit.name);
                        }
                    });

                } else if (contentTypeId === 6) {
                    siblings.sort(function (a, b) {
                        if (a.versions.Contents.contentTypeId === contentTypeId && b.versions.Contents.contentTypeId === contentTypeId) {
                            return a.glossary.term.localeCompare(b.glossary.term);
                        }
                    });

                } else if (contentTypeId === 7 || contentTypeId === 20) {
                    siblings.sort(function (a, b) {
                        if (a.versions.Contents.contentTypeId == contentTypeId && b.versions.Contents.contentTypeId == contentTypeId) {
                            return a.exclusion.name.localeCompare(b.exclusion.name);
                        }
                    });

                } else if (contentTypeId === 8) {
                    siblings.sort(function (a, b) {
                        if (a.versions.Contents.contentTypeId == contentTypeId && b.versions.Contents.contentTypeId == contentTypeId) {
                            return a.medical.term.localeCompare(b.medical.term);
                        }
                    });
                } else if (contentTypeId === 19) {
                    siblings.sort(function (a, b) {
                        if (a.versions.Contents.contentTypeId == contentTypeId && b.versions.Contents.contentTypeId == contentTypeId) {
                            return a.versions.versiondata.localeCompare(b.versions.versiondata);
                        }
                    });
                }

                _.forEach(siblings, function (val, key) {
                    siblings[key].order = key;
                });

                _.forEach(siblings, function (val, key) {
                    oldOrder.push({structureId: val.structureId, order: val.order});
                    newOrder.push({structureId: val.structureId, order: key});

                });

                oldOrder = _.sortBy(oldOrder, 'order');

                Structures.updateOrder({
                        "bookId": item.bookId,
                        "parentStructureId": item.parent,
                        "orderOld": Wraps.toJson(oldOrder),
                        "orderNew": Wraps.toJson(newOrder),
                        "userId": $scope.$storage.user.id
                    },
                    function () {
                    },
                    function () {
                    }
                );
            }

        }

        vm.getIndex = function (b, c) {
            return b.Children.indexOf(c);

        }

        vm.moveDown = function (indexNo, parent) {

            var siblings = parent.Children;
            var temp = siblings[indexNo];
            var newOrder = [];
            var oldOrder = [];

            siblings[indexNo] = siblings[indexNo + 1];
            siblings[indexNo + 1] = temp;

            _.forEach(siblings, function (val, key) {
                oldOrder.push({structureId: val.structureId, order: val.order});
                newOrder.push({structureId: val.structureId, order: key});
            });

            oldOrder = _.sortBy(oldOrder, 'order');

            Structures.updateOrder({
                    "bookId": parent.bookId,
                    "parentStructureId": parent.structureId,
                    "orderOld": Wraps.toJson(oldOrder),
                    "orderNew": Wraps.toJson(newOrder),
                    "userId": $scope.$storage.user.id
                },
                function () {
                },
                function () {
                }
            );
        }

        vm.moveUp = function (indexNo, parent) {

            var siblings = parent.Children;
            var temp = siblings[indexNo];
            var newOrder = [];
            var oldOrder = [];

            siblings[indexNo] = siblings[indexNo - 1];
            siblings[indexNo - 1] = temp;

            _.forEach(siblings, function (val, key) {
                oldOrder.push({structureId: val.structureId, order: val.order});
                newOrder.push({structureId: val.structureId, order: key});
            });

            oldOrder = _.sortBy(oldOrder, 'order');

            Structures.updateOrder({
                    "bookId": parent.bookId,
                    "parentStructureId": parent.structureId,
                    "orderOld": Wraps.toJson(oldOrder),
                    "orderNew": Wraps.toJson(newOrder),
                    "userId": $scope.$storage.user.id
                },
                function () {
                },
                function () {
                }
            );

        }

        vm.textDiff = function (left, right, switchDiff) {
            if (switchDiff) {
                right
                    = String(right).replace(/\n/g, '').replace(/<p>/g, '').replace(/<\/li>/g, '\n').replace(/<\/p>/g, '\n').replace(/<li>/g, '• ').replace(/<[^>]+>/gm, '');
                if (!left) {
                    left = angular.copy(right);
                } else {
                    left = String(left).replace(/\n/g, '').replace(/<p>/g, '').replace(/<\/li>/g, '\n').replace(/<\/p>/g, '\n').replace(/<li>/g, '• ').replace(/<[^>]+>/gm, '');
                }

                var dmp = new diff_match_patch();
                var diffs = dmp.diff_main(left, right);
                dmp.diff_cleanupSemantic(diffs);
                var html = dmp.diff_prettyHtml(diffs).replace(/\n/g, '<br>').replace(/&amp;nbsp;/g, ' ');
                //console.log(html);
                return html;
            } else {
                return right;
            }
        }


        // vm.hasContentType = function (parent, type) {
        //     if (parent.Children) {
        //         for (var i = 0; i < parent.Children.length; i++) {
        //             if (parent.Children[i].versions.Contents.contentTypeId === type) {
        //                 return true;
        //                 break;
        //             }
        //         }
        //     }
        //
        // }


        // temp so library-doc.tpl.html can access
        vm.hasContentType = WrapsBooks.hasContentType;


        vm.openEditCopy = function (item, book) {

            vm.tempData = angular.copy(item);

            $mdBottomSheet.show({
                templateUrl: 'library/bottomsheet/bottomsheet.tpl.html',
                controller: 'BottomsheetCtrl',
                controllerAs: 'bottomsheet',
                disableParentScroll: false,
                clickOutsideToClose: false,
                parent: angular.element(document.body),
                locals: {item: item, book: book}
            }).then(function (clickedItem) {
                //vm.updateBook(book);
                WrapsBooks.updateBookById(book.bookId);
                //book.updateBook();
            }, function (reason) {
                //alert('cancel');
                item.versions.versiondata = vm.tempData.versions.versiondata;
                if (item.glossary) {
                    item.glossary = vm.tempData.glossary;
                }
                if (item.benefit) {
                    item.benefit = vm.tempData.benefit;
                }
                if (item.brief) {
                    item.brief = vm.tempData.brief;
                }
                if (item.artwork) {
                    item.artwork = vm.tempData.artwork;
                }
            });
        };


        vm.openChapterDialog = function (book) {
            $mdDialog.show({
                controller: 'ChaptersdialogCtrl',
                controllerAs: 'chaptersdialog',
                templateUrl: 'library/chaptersdialog/chaptersdialog.tpl.html',
                parent: angular.element(document.body),
                locals: {book: book},
                bindToController: true,
                clickOutsideToClose: true,

                skipHide: true,
            })
                .then(function (data) {
                    //console.log("chapt close:",data);
                    if (data) {
                        //vm.updateBook(book);
                        WrapsBooks.updateBookById(book.bookId);
                        //book.updateBook();
                    }
                }, function () {
                    //vm.status = 'You cancelled the dialog.';

                });
        };


        vm.addbriefDialog = function (book) {
            $mdDialog.show({
                controller: 'AddbriefCtrl',
                controllerAs: 'addbrief',
                templateUrl: 'library/addbrief/addbrief.tpl.html',
                parent: angular.element(document.body),
                locals: {book: book},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {
                    if (data) {
                        //vm.updateBook(book);
                        console.log("updated from add brief dialog");
                        $scope.$session.selectedBrief = [book.bookId]; //makes sure section is selected/open
                        WrapsBooks.updateBookById(book.bookId);
                        //book.updateBook();
                    }
                }, function () {
                    //vm.status = 'You cancelled the dialog.';
                });
        };

        vm.addartworkDialog = function (book) {
            $mdDialog.show({
                controller: 'AddartworkCtrl',
                controllerAs: 'addartwork',
                templateUrl: 'library/addartwork/addartwork.tpl.html',
                parent: angular.element(document.body),
                locals: {book: book, type: "artwork"},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {
                    if (data) {
                        //vm.updateBook(book);
                        $scope.$session.selectedArtwork = [book.bookId]; //makes sure section is selected/open
                        WrapsBooks.updateBookById(book.bookId);
                        //book.updateBook();
                    }
                }, function () {
                    //vm.status = 'You cancelled the dialog.';
                });
        };

        vm.addFinalDialog = function (book) {
            $mdDialog.show({
                controller: 'AddartworkCtrl',
                controllerAs: 'addartwork',
                templateUrl: 'library/addartwork/addartwork.tpl.html',
                parent: angular.element(document.body),
                locals: {book: book, type: "final"},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {
                    if (data) {
                        //vm.updateBook(book);
                        $scope.$session.selectedFinal = [book.bookId]; //makes sure section is selected/open
                        WrapsBooks.updateBookById(book.bookId);
                        //book.updateBook();
                    }
                }, function () {
                    //vm.status = 'You cancelled the dialog.';
                });
        };


        vm.openDocDiffDialog = function (change, child) {
            $mdDialog.show({
                controller: 'DocdiffdialogCtrl',
                controllerAs: 'docdiffdialog',
                templateUrl: 'library/docdiffdialog/docdiffdialog.tpl.html',
                parent: angular.element(document.body),
                locals: {change: change, child: child},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {

                }, function () {
                    vm.status = 'You cancelled the dialog.';
                });
        };

        vm.openMetaChangeDialog = function (change) {
            $mdDialog.show({
                controller: 'MetachangedialogCtrl',
                controllerAs: 'metachangedialog',
                templateUrl: 'library/metachangedialog/metachangedialog.tpl.html',
                parent: angular.element(document.body),
                locals: {change: change},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {

                }, function () {
                    vm.status = 'You cancelled the dialog.';
                });
        };

        vm.openEmailDetailsDialog = function (change) {
            $mdDialog.show({
                controller: 'EmaildetailsdialogCtrl',
                controllerAs: 'emaildetailsdialog',
                templateUrl: 'library/emaildetailsdialog/emaildetailsdialog.tpl.html',
                parent: angular.element(document.body),
                locals: {change: change},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {

                }, function () {
                    vm.status = 'You cancelled the dialog.';
                });
        };


        vm.openWorkflowDialog = function (child, book, type) {
            $mdDialog.show({
                controller: 'WorkflowdialogCtrl',
                controllerAs: 'workflowdialog',
                templateUrl: 'library/workflowdialog/workflowdialog.tpl.html',
                parent: angular.element(document.body),
                locals: {child: child, book: book, type: type},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {
                    //vm.updateBook(book);
                    WrapsBooks.updateBookById(book.bookId);
                    //book.updateBook();
                }, function () {
                    //vm.status = 'You cancelled the dialog.';
                });
        };


//filter for the content repeat
        vm.lessThan6 = function (item) {
            return item.versions.Contents.contentTypeId < 6 ? true : false;
        };


        function deleteRecursive(Structs, eventData) {
            Structures.trash({"structureId": Structs.structureId}, function () {
            });
            History.create({
                "structureId": Structs.structureId,
                "versionIdOld": Structs.versionId,
                "versionIdNew": 0,
                "historyId": 0
            }, function (historyResponse) {
                Events.create({
                    "eventId": 0,
                    "bookId": Structs.bookId,
                    "structureId": Structs.structureId,
                    "contentId": Structs.versions.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Delete",
                    "eventData": Wraps.toJson(eventData)
                });
            });
            angular.forEach(Structs.Children, function (Struct) {
                deleteRecursive(Struct);
            });

        }

        vm.deleteContentDialog = function (child, parent) {
            var confirm = $mdDialog.confirm()
                .title('Delete content')
                .htmlContent('Are you sure you want to delete this content and all it\'s children?<br /> <b>This action cannot be undone.</b>')
                .ariaLabel('Confirm delete')
                .ok('Delete')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {

                    vm.openChangeDialog().then(function (eventData) {
                        if (eventData) {


                            //console.log(eventData); //TODO send data to evetns and show different list

                            _.remove(parent, function (n) {
                                return child === n
                            });
                            deleteRecursive(child, eventData);
                            Books.upsert({"bookId": child.bookId}).$promise.then(function (results) {
                                vm.setBookStatuses(vm.bookById(child.bookId));
                                //book.updateBook();
                                WrapsBooks.updateBookById(child.bookId);

                            });
                        }
                    });
                }, function () {

                });
        }


        vm.getListChildren = function (book, field) {
            //console.log(book);
            var list = [];
            _.forEach(book.Children, function (val) {
                list.push(val[field]);
                _.forEach(val.Children, function (val2) {
                    list.push(val2[field]);
                    _.forEach(val2.Children, function (val3) {
                        list.push(val3[field]);
                        _.forEach(val3.Children, function (val4) {
                            list.push(val4[field]);
                        });
                    });
                });
            });

            //console.log(list);
            //console.log(_.uniq(list));
            return _.uniq(list);

        }

        vm.approveContentDialog = function (child, book, needFile) {
            $mdDialog.show({
                controller: 'ApprovedialogCtrl',
                controllerAs: 'approvedialog',
                templateUrl: 'library/approvedialog/approvedialog.tpl.html',
                locals: {child: child, book: book, needFile: needFile},
                bindToController: true,
                clickOutsideToClose: false
            })
                .then(function (data) {
                    // Books.upsert({"bookId": book.bookId, "lastUpdated":  moment(new Date())}).$promise.then(function (results) {
                    //   console.log("results",results);
                    //   book.update();
                    // });
                }, function () {

                });
        };


        vm.showApproveAll = function (thisBook) {
            if (thisBook.documentStatus.substring(0, 4) != 'EXPD' && thisBook.approvalList[thisBook.approvalStatus] && thisBook.approvalList[thisBook.approvalStatus].role != 2 && thisBook.DocTypes && thisBook.DocTypes.docTypeTemplate == 1) {
                var bookStatuses = Wraps.getListChildrenNew(thisBook, ['approvalStatus', 'status']);
                var userIndex = _.indexOf(thisBook.userInBook, thisBook.approvalStatus);
                var userInChildrenIndex = _.findIndex(bookStatuses, function (o) {
                    return o.approvalStatus == thisBook.userInBook[userIndex] && o.status == 0;
                })

                if (userIndex >= 0 && thisBook.status === 0 && userInChildrenIndex >= 0) {
                    return true;
                } else {
                    return false;
                }
            }
        }


        vm.showWithdraw = function (thisBook) {
            var now = new Date();
            if (
                thisBook.withdrawDate === null &&
                (thisBook.bookOwner === $scope.$storage.user.id || vm.isMCO(thisBook)) &&
                ((thisBook.approvalList[thisBook.approvalStatus] && thisBook.approvalList[thisBook.approvalStatus].content == 'final') || thisBook.status == 1) &&
                (thisBook.documentStatus == "EXPD" || thisBook.documentStatus == "EXPD(F)" )
            ) {
                return true;
            } else {
                return false;
            }
        }

        vm.withdraw = function (thisBook) {
            $mdDialog.show({
                clickOutsideToClose: true,
                //scope: $scope,        // use parent scope in template
                preserveScope: true,  // do not forget this if use parent scope
                parent: angular.element(document.body),
                locals: {dialogBook: thisBook},
                template: '<md-dialog>' +
                    '<md-toolbar>' +
                '<div class="md-toolbar-tools">' +
                        '  <h2>Withdraw</h2>' +
                    '   <span flex></span>' +
                    '   <md-button ng-click="closeDialog(false)" class="md-icon-button">' +
                    '     <ng-md-icon  icon="close" aria-label="Close dialog"></ng-md-icon>' +
                    '   </md-button>' +
                    '  </div>' +
                    '  </md-toolbar>' +
                    '  <md-dialog-content style="max-width: 800px;">' +
                    '   <div class="md-padding" >' +
                    '     <p>This document has now expired, and the expiration is recorded and reportable.  ' +
                    'Non-compliant customer-facing documentation can lead to serious consequences for ' +
                    'our business and as such can be very high profile.  It is imperative that every ' +
                    'effort is made to ensure that all available customer-facing documentation is compliant.</p>' +


                    '     <p><strong>Items:</strong> If this document is found to still be in use after the recorded expiration ' +
                    'date, a risk event may need to be raised and reported.  By ticking the ‘withdrawn’' +
                    ' box, I am confirming that the document is no longer in use and I have removed it ' +
                    'from wherever it is published (on and offline), remaining stocks destroyed, ' +
                    'advised any agencies to destroy/delete and removed from the P/G Drive etc.</p>' +

                    '     <p><strong>Membership Guides:</strong> This Membership Guide has expired and must not be used for ' +
                    'new sales.  Customers will continue to use this Membership Guide to access the ' +
                    'cover from their health plan until their next renewal.  If this Membership Guide ' +
                    'is found to still be in use for news sales, a risk event may need to be raised ' +
                    'and reported.  By ticking the ‘withdrawn’ box, I am confirming that this ' +
                    'Membership Guide is no longer available for new sales.  It has been replaced ' +
                    'wherever it is published, on and off line.</p>' +


                    '     <md-input-container flex="33">' +
                    '       <md-checkbox class="" ng-model="withdrawAgree" aria-label="Withdraw" style="margin: 0px;">' +
                    '         Withdrawn' +
                    '       </md-checkbox>' +
                    '     </md-input-container> ' +

                    '   </div>' +
                    '  </md-dialog-content>' +

                    '<md-dialog-actions layout="row">' +
                    ' <span flex></span>' +

                    '   <md-button class="md-grey" ng-click="closeDialog(false)">Cancel<ng-md-icon icon="close" style="fill: white; padding-left: 4px; " size="20"></ng-md-icon></md-button>' +
                    '   <md-button ng-disabled="!withdrawAgree" class="md-green"  ng-click="saveDialog()">Approve<ng-md-icon icon="done" style="fill: white; padding-left: 4px; " size="20"></ng-md-icon></md-button>' +

                    '  </md-dialog-actions>' +
                    '</md-dialog>',
                controller: ["$scope", "$localStorage", "$mdDialog", "dialogBook", function withdrawController($scope, $localStorage, $mdDialog, dialogBook) {

                    $scope.$storage = $localStorage;

                    $scope.saveDialog = function () {
                        Books.upsert({
                            "bookId": dialogBook.bookId,
                            "withdrawDate": moment.utc().format(Wraps.databaseDateFormat)
                        }).$promise.then(function (results) {
                            Events.create({
                                "eventId": 0,
                                "bookId": dialogBook.bookId,
                                "userId": $scope.$storage.user.id,
                                "eventType": "Withdraw",
                                "eventData": "Book Withdrawn"
                            });

                            Notifications.getCounts();
                            WrapsBooks.updateBookById(dialogBook.bookId);
                            //dialogBook.updateBook();
                        });
                        $mdDialog.hide();
                    }
                    $scope.closeDialog = function () {
                        $mdDialog.hide();
                    }
                }]
            });

        }

        vm.showDLORequestDelete = function (thisBook) {

            if ($scope.$storage.user.id === thisBook.bookOwner && thisBook.requestDelete != 1) {
                return true;
            } else {
                return false;
            }
        }

        vm.requestDelete = function (bookOrContent, bookId, contentId, versionId) {
            $mdDialog.show({
                clickOutsideToClose: true,
                //scope: $scope,        // use parent scope in template
                preserveScope: true,  // do not forget this if use parent scope
                parent: angular.element(document.body),
                locals: {dialogBook: bookId},
                template: '<md-dialog>' +
                    '  <md-dialog-content style="overflow: hidden;">' +

                    '    <md-toolbar>' +
                    '<div class="md-toolbar-tools">' +
                    '  <h4>Request deletion</h4>' +
                    '   <span flex></span>' +
                    '   <md-button ng-click="closeDialog(false)" class="md-icon-button" style="position: relative;  left:20px;">' +
                    '     <ng-md-icon  icon="close" style="fill: white; position: relative; top: 4px; right:10px;" size="20"  aria-label="Close dialog"></ng-md-icon>' +
                    '   </md-button>' +
                    '  </div>' +
                    '  </md-toolbar>' +
                    '   <div style="padding: 20px;"  >' +
                    '     <p>If you feel this document should be deleted. Please complete the form below and the DLO will be notified.</p>' +

                    '     <md-input-container flex="66">' +
                    '       <label>Reason for deletion</label>' +
                    '       <textarea class="" ng-model="requestDeletion" aria-label="Request Deletion" style="margin: 0px;"></textarea>' +
                    '     </md-input-container> ' +
                    '   </md-dialog-content>' +

                    '<md-dialog-actions layout="row">' +
                    ' <span flex></span>' +

                    '   <md-button class="md-grey"  ng-click="closeDialog(false)">Cancel<ng-md-icon icon="close" style="fill: white; position: relative; top:4px; padding-left: 4px; " size="20"></ng-md-icon></md-button>' +
                    '   <md-button ng-disabled="!requestDeletion" class="md-green"  ng-click="saveDialog()">Request Deletion<ng-md-icon icon="delete_forever" style="fill: white; position: relative; top:4px; padding-left: 4px; " size="20"></ng-md-icon></md-button>' +

                    '  </md-dialog-actions>' +
                    '</md-dialog>',
                controller: ["$scope", "$localStorage", "$mdDialog", "dialogBook", function requestDeletionController($scope, $localStorage, $mdDialog, dialogBook) {
                    $scope.$storage = $localStorage;
                    $scope.saveDialog = function () {

                        var args = {
                            userId: $scope.$storage.user.id,
                            bookId: bookId,
                            reason: $scope.requestDeletion
                        };

                        Books.requestDeletion(args).$promise.then(
                            function (results) {
                                //console.log(results);
                                if (results) {

                                    Wraps.toastMessages = {message: "Email Sent to: " + results.to};
                                }
                                vm.updateBooks();
                                $mdDialog.hide();


                            }, function (error) {
                                // error
                                console.log("Error:", error);

                                if (error.data) {
                                    error.data.error.timestamp = new Date();
                                    angular.extend(Wraps.httpErrors, error.data.error);
                                }


                                $mdDialog.hide();
                            });

                    };
                    $scope.closeDialog = function () {
                        $mdDialog.hide();
                    }
                }]
            });

        }


        vm.showDLODeleteBook = function (thisBook) {
            if (thisBook.requestDelete === 1 && $scope.$storage.user.roleId === 1) {
                return true;
            }
            return false;
        };

        vm.DLODelete = function (thisBook) {
            var confirm = $mdDialog.confirm()
                .title('Delete document')
                .htmlContent('Are you sure you want to delete this document?<br /> <b>This action cannot be undone.</b>')
                .ariaLabel('Confirm delete')
                .ok('Delete')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    Books.trash({
                        bookId: thisBook.bookId,
                        clientId: thisBook.clientId,
                        reason: 'DLODelete',
                        userId: $localStorage.loggedInUser.id
                    }, function (results) {
                        if (results) {
                            Wraps.toastMessages = {message: "Email Sent to: " + results.to};
                        }
                        //$scope.$storage.selectedBook = 0;
                        vm.updateBooks();
                    });
                });
        };


        vm.contentRequestDeleteShow = function (requestDelete) {
            if (requestDelete !== 1) {
                return true;
            } else {
                return false;
            }
        }

        vm.contentDLODeleteShow = function (DLODelete, book) {
            if (DLODelete === 1 && book.requestDeleteDLO === $localStorage.loggedInUser.id) {
                return true;
            } else {
                return false;
            }
        };


        vm.DLOContentDelete = function (bookOrContent, bookId, contentId, versionId) {
            var confirm = $mdDialog.confirm()
                .title('Delete document')
                .htmlContent('Are you sure you want to delete this?<br /> <b>This action cannot be undone.</b>')
                .ariaLabel('Confirm delete')
                .ok('Delete')
                .cancel('Cancel');


            $mdDialog.show(confirm)
                .then(function () {
                    Structures.trash({
                        versionId: versionId,
                        contentId: contentId,
                        bookId: bookId,
                        userId: $localStorage.loggedInUser.id
                    });

                    //vm.updateBooks();
                    WrapsBooks.updateBookById(bookId);
                    //book.updateBook();
                });
        };


        vm.pushContentDialog = function (activeContent) {
            $mdDialog.show({
                controller: 'PushdialogCtrl',
                controllerAs: 'pushdialog',
                templateUrl: 'library/pushdialog/pushdialog.tpl.html',
                parent: angular.element(document.body),
                locals: {activeContent: activeContent},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {
                    if (data) {
                        var book = vm.bookById(activeContent.bookId);
                        WrapsBooks.updateBookById(book.bookId);
                        //book.updateBook();
                    }
                }, function () {
                    //vm.status = 'You cancelled the dialog.';
                });
        };


        vm.insertContentDialog = function (child, book) {
            $mdDialog.show({
                controller: 'ContentdialogCtrl',
                controllerAs: 'contentdialog',
                templateUrl: 'library/contentdialog/contentdialog.tpl.html',
                parent: angular.element(document.body),
                locals: {child: child, book: book},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {
                    console.log('update', data);
                    if (data) {
                        //vm.updateBook(vm.bookById(child.bookId));
                        WrapsBooks.updateBookById(book.bookId);
                        //book.updateBook();

                    }
                }, function () {
                    //vm.status = 'You cancelled the dialog.';
                });
        };






        vm.editAccess = function (copy, book) {


            //TODO brief/artwork
            if (book.status === 0) {
                var now = new Date();
                if ((book.earlyExpiryDate && now >= new Date(book.earlyExpiryDate)) || now >= new Date(book.expiryDate)) {
                    return false;
                }

                if (_.indexOf(book.userInBook, copy.approvalStatus) >= 0 && book.approvalList[copy.approvalStatus]) {
                    if (book.approvalList[copy.approvalStatus].role === 1 || book.approvalList[copy.approvalStatus].role === 2 && (!copy.hasArtwork) && copy.status == 0) {
                        return true;
                    } else if (book.approvalList[copy.approvalStatus].role === 6 && (copy.hasArtwork || copy.hasFinal) && (book.approvalStatus >= copy.approvalStatus) && copy.status == 0) {
                        return true;
                    } else if ($scope.$storage.loggedInUser.roleId == 6 && vm.isMCO(book) && copy.chapterTypeId === 1) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }

        }

        vm.diffAccess = function (book) {
            //TODO needs some work still
            return false;
        }

        vm.insertAccess = function (copy, book) {

            if (book.status === 0 && book.documentStatus.substring(0, 4) !== 'EXPD') {
                if (_.indexOf(book.userInBook, copy.approvalStatus) >= 0) {
                    if (book.approvalList[copy.approvalStatus] && book.approvalList[copy.approvalStatus].role === 1 || book.approvalList[copy.approvalStatus].role === 2) {

                        if (book.bookId === 89) {
                            console.log('t1');
                        }
                        return true;
                    } else {
                        if (book.bookId === 89) {
                            console.log('f1');
                        }
                        return false;
                    }
                } else {
                    if (book.bookId === 89) {
                        console.log('book.userInBook', book.userInBook);
                    }
                    return false;
                }
            }
        }

        vm.insertAccessChildren = function (book) {
            var rtrn = [];
            rtrn.push(vm.insertAccess(book, book));
            _.forEach(book.Children, function (val) {
                rtrn.push(vm.insertAccess(val, book));
                _.forEach(val.Children, function (val2) {
                    rtrn.push(vm.insertAccess(val2, book));
                    _.forEach(val2.Children, function (val3) {
                        rtrn.push(vm.insertAccess(val3, book));
                        _.forEach(val3.Children, function (val4) {
                            rtrn.push(vm.insertAccess(val4, book));
                        });
                    });
                });
            });
            if (rtrn.indexOf(true) >= 0) {
                return true;
            }
        }



        vm.deleteAccess = function (copy, book) {


            if (book.status === 0 && book.DocTypes && book.DocTypes.docTypeTemplate === 1) {
                if (_.indexOf(book.userInBook, copy.approvalStatus) >= 0 && book.approvalList[copy.approvalStatus]) {

                    if (book.approvalList[copy.approvalStatus].role === 1 || book.approvalList[copy.approvalStatus].role === 2 || (book.approvalList[copy.approvalStatus].role === 6 && book.approvalStatus >= copy.approvalStatus)) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }


        }


        vm.moveAccess = function (copy, book) {

            //TODO might need to set it so that an MCO can move other content while they are editing single one
            if (book.status === 0) {
                if (book.approvalList[copy.approvalStatus] && _.indexOf(book.userInBook, copy.approvalStatus) >= 0) {
                    if (book.approvalList[copy.approvalStatus].role === 1 || book.approvalList[copy.approvalStatus].role === 2) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
        }

        vm.menuAccess = function (copy, book) {
            return true;
        }


        vm.isOwner = function (thisBook) {
            if (thisBook.bookOwner === $scope.$storage.user.id) {
                return true;
            } else {
                return false;
            }
        }


        vm.isMCO = function (thisBook) {
            //console.log("xx", _.indexOf(thisBook.userInBook, thisBook.approvalStatus),thisBook.userInBook);


            if (($scope.$storage.user.roleId === 2 && _.indexOf(thisBook.userInBook, thisBook.approvalStatus) >= 0) || $scope.$storage.user.roleId === 5) {
                return true;
            } else {
                return false;
            }
        }

        vm.isDLO = function (book) {
            var isDLOinBook = (_.findIndex(book.approvalList, function(o) { return o.role === 1 && (o.user == $scope.$storage.user.id || (o.team === $scope.$storage.user.team && !o.user)); }) >=0);
            //console.log('isDLOinBook', isDLOinBook , $scope.$storage.user);
            if ( isDLOinBook || $scope.$storage.user.roleId === 5 ) {
                return true;
            } else {
                return false;
            }
        }


        vm.viewAction = function (copy, book) {
            var now = moment().format('YYYY-MM-DD');
            if (copy.status === 0 && ((book.earlyExpiryDate == null || book.earlyExpiryDate) > now || book.expiryDate > now)) {

                //console.log(book.bookId, book.earlyExpiryDate  == null);
                if (_.indexOf(book.userInBook, copy.approvalStatus) >= 0) {
                    //if(book.approvalList[copy.approvalStatus].role >= 3 || (book.approvalList[copy.approvalStatus].role === 2 && book.approvalList[book.approvalStatus].role > 2)){
                    if (book.approvalList[copy.approvalStatus] && (
                        ((book.approvalList[copy.approvalStatus].role == 3 || book.approvalList[copy.approvalStatus].role == 8) && book.bookOwner != $scope.$storage.user.id)
                        || book.approvalList[copy.approvalStatus].role == 9
                        || book.approvalList[copy.approvalStatus].role == 10
                        || (book.approvalList[copy.approvalStatus].role === 2 && book.approvalList[book.approvalStatus].role > 2)
                    )) {

                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
            return false;


        }

        vm.viewActionChildren = function (copy, book) {
            var rtrn = [];
            if (copy.parent === null || !angular.isUndefined(copy.bookName) || copy.Children) {
                _.forEach(copy.Children, function (val) {
                    rtrn.push(vm.viewAction(val, book));
                    _.forEach(val.Children, function (val2) {
                        rtrn.push(vm.viewAction(val2, book));
                        _.forEach(val2.Children, function (val3) {
                            rtrn.push(vm.viewAction(val3, book));
                            _.forEach(val3.Children, function (val4) {
                                rtrn.push(vm.viewAction(val4, book));
                            });
                        });
                    });
                });
                if (rtrn.indexOf(true) >= 0) {
                    return true;
                }
            }

        }


        vm.viewActionBook = function (bookId) {

        }


        //--- ROLES
        // 1	DLO
        // 2	MCO
        // 3	Approver
        // 4	CO
        // 5	Admin
        // 6	Designer
        // 7	Digital
        // 8	proofreader
        // 9	Partner
        // 10	Client


        vm.viewReject = function (copy, book) {
            if (book.status === 0 &&
                copy &&
                book.documentStatus.substring(0, 4) != 'EXPD'
            ) {
                //TODO setup CO logic
                switch (copy.versions.Contents.ContentTypes.contentTypeGroup) {
                    case 'brief':
                        var arrayMCO = _.find(book.approvalList, function(o) { return o.role === 2 && o.user > 0 && o.content === 'brief';}) || {user: 0 };
                        // artwork is on design and brief is status 1
                        var artworkStats = Wraps.getListChildren(book, 'approvalStatus', 'artwork');
                        if (
                            _.indexOf(book.userInBook, copy.approvalStatus + 1) >= 0 &&
                            book.approvalList[book.approvalStatus] &&
                            book.approvalList[book.approvalStatus].content === 'artwork' &&
                            book.approvalList[book.approvalStatus].role == 6 &&
                            copy.status === 1 // Design
                        ) {
                            return true;
                        }
                        else if (
                            _.indexOf(book.userInBook, copy.approvalStatus) >= 0 &&
                            book.approvalList[copy.approvalStatus] &&
                            (
                                book.approvalList[copy.approvalStatus].role == 9 ||
                                book.approvalList[copy.approvalStatus].role == 10 ||
                                (
                                    (book.approvalList[copy.approvalStatus].role == 3 || book.approvalList[copy.approvalStatus].role == 8) &&
                                    book.bookOwner != $scope.$storage.user.id &&
                                    arrayMCO.user != $scope.$storage.user.id
                                )
                            ) &&
                            book.approvalStatus >= copy.approvalStatus &&
                            copy.status === 0 // Approver
                        ) {
                            return true;
                        }
                        else {
                            return false;
                        }
                        break;
                    case 'artwork':
                        var arrayMCO = _.find(book.approvalList, function(o) { return o.role === 2 && o.user > 0 && o.content === 'artwork';}) || {user: 0 };
                        if (
                            _.indexOf(book.userInBook, copy.approvalStatus) >= 0 &&
                            book.approvalList[copy.approvalStatus] &&
                            book.approvalList[copy.approvalStatus].role == 2 &&
                            book.approvalStatus >= copy.approvalStatus &&
                            copy.status === 0 // MCO
                        ) {
                            return true;
                        }
                        else if (
                            _.indexOf(book.userInBook, copy.approvalStatus) >= 0 &&
                            book.approvalList[copy.approvalStatus] &&
                            (
                                book.approvalList[copy.approvalStatus].role == 9 ||
                                book.approvalList[copy.approvalStatus].role == 10 ||
                                (
                                    (book.approvalList[copy.approvalStatus].role == 3 || book.approvalList[copy.approvalStatus].role == 8) &&
                                    book.bookOwner != $scope.$storage.user.id &&
                                    arrayMCO.user != $scope.$storage.user.id
                                ) // Approver
                            ) &&
                            book.approvalStatus >= copy.approvalStatus &&
                            copy.status === 0
                        ) {
                            return true;
                        }
                        else {
                            return false;
                        }
                        break;
                    case 'doc':
                        var arrayMCO = _.find(book.approvalList, function(o) { return o.role === 2 && o.user > 0 && o.content === 'doc';}) || {user: 0 };
                        if (
                            _.indexOf(book.userInBook, copy.approvalStatus) >= 0 &&
                            book.approvalList[copy.approvalStatus] &&
                            (
                                book.approvalList[copy.approvalStatus].role == 9 ||
                                book.approvalList[copy.approvalStatus].role == 10 ||
                                (
                                    (book.approvalList[copy.approvalStatus].role == 3 || book.approvalList[copy.approvalStatus].role == 8) &&
                                    book.bookOwner != $scope.$storage.user.id &&
                                    arrayMCO.user != $scope.$storage.user.id
                                )
                            ) &&
                            book.approvalStatus >= copy.approvalStatus &&
                            copy.status === 0 // Approver
                        ) {
                            return true;
                        }
                        else {
                            return false;
                        }
                        break;
                    case 'final':
                        if (
                            _.indexOf(book.userInBook, copy.approvalStatus) >= 0 &&
                            book.approvalList[copy.approvalStatus] &&
                            book.approvalList[copy.approvalStatus].role == 11 &&
                            book.DocTypes &&
                            book.DocTypes.docTypeTemplate != 1 // Approver
                        ) {
                            return true;
                        }
                        else {
                            return false;
                        }
                        break;
                    default:
                        return false;
                }
            } else {
                return false;
            }
        };


        vm.viewResetToBrief = function (book) { // to set brief back to MCO1 and artwork back to designer
            if (
                book.documentStatus &&
                book.status === 0 &&
                book.approvalList[book.approvalStatus] &&
                book.approvalList[book.approvalStatus].content != 'final' && // book is not in finals
                book.documentStatus.substring(0, 4) != 'EXPD' && // book is not expired
                book.hasBrief &&
                (book.approvalList[book.approvalStatus].role != 2 || book.approvalList[book.approvalStatus].content != 'brief') && // not already at first brief
                book.userInBook &&
                book.approvalList[book.userInBook[0]] &&
                book.approvalList[book.userInBook[0]].role === 2 &&
                book.approvalList[book.userInBook[0]].content === 'brief' // user is mco of brief in book
            ) {
                return true;
            }
        };

        vm.viewResetToMCO = function (book) { // to set doc back to MCO
            if (
                book.documentStatus &&
                book.status === 0 &&
                book.approvalList[book.approvalStatus] &&
                book.approvalList[book.approvalStatus].content != 'final' && // not in finals
                book.documentStatus.substring(0, 4) != 'EXPD' && // not expired
                typeof book.approvalList[book.approvalStatus] != 'undefined' &&
                book.approvalList[book.approvalStatus].role > 2 &&
                book.approvalList[book.approvalStatus].content === 'doc' && // not already at first brief
                book.userInBook && book.approvalList[book.userInBook[0]] &&
                book.approvalList[book.userInBook[0]].role === 2
            ) {
                return true;
            }
        };

        vm.viewEarlyExpire = function (book) { // Force / Early Expire Doc
            if (
                book.bookOwner == $scope.$storage.user.id && // is owner
                book.earlyExpiryDate == null &&
                book.documentStatus.substring(0, 4) != 'EXPD' // not already expired
            ) {
                return true;
            }

        };

        vm.viewMakeBookLive = function (book) { // to make book live
            var bookStatus = Wraps.getListChildren(book, 'status');
            if (
                book.status === 0 &&
                bookStatus.length === 1 &&
                bookStatus[0] === 1 &&
                book.documentStatus.substring(0, 4) != 'EXPD' && // not expired
                book.approvalList.length === (book.approvalStatus + 1) // last status before live
            ) {
                if (
                    _.find(book.userInBook, function (val) {
                        return book.approvalList[val].role == 2
                    })
                ) {
                    return true;
                } else if (_.indexOf(book.userInBook, book.approvalStatus) >= 0) { // in workflow and has action
                    return true;
                }
            }
        };

        /*vm.allowedDocTypeEdit = function (thisBook) {
            //for ng-disable so true = disabled;
            if ($scope.$storage.user.id == 49 || !thisBook.DocTypes) {
                return false;
            } else if (!thisBook.bookId) {
                return false;
            } else {
                return true;
            }


        }*/


        vm.viewApprove = function (copy, book) {
            if (
                copy &&
                copy.status === 0 &&
                book.status === 0 &&
                _.indexOf(book.userInBook, copy.approvalStatus) >= 0 &&
                book.approvalStatus >= copy.approvalStatus &&
                book.documentStatus.substring(0, 4) != 'EXPD'
            ) {
                //TODO setup CO logic
                switch(copy.versions.Contents.ContentTypes.contentTypeGroup) {
                    case 'brief':
                        var arrayMCO = _.find(book.approvalList, function(o) { return o.role === 2 && o.user > 0 && o.content === 'brief';}) || {user: 0 };
                        if (
                            book.approvalList[copy.approvalStatus] &&
                            (
                                book.approvalList[copy.approvalStatus].role == 2 ||
                                book.approvalList[copy.approvalStatus].role == 9 ||
                                book.approvalList[copy.approvalStatus].role == 10 ||
                                (
                                    (book.approvalList[copy.approvalStatus].role == 3 || book.approvalList[copy.approvalStatus].role == 8) &&
                                    book.bookOwner != $scope.$storage.user.id &&
                                    arrayMCO.user != $scope.$storage.user.id
                                )
                            )
                        ) {
                            return true;
                        }
                        else {
                            return false;
                        }
                        break;
                    case 'artwork':
                        var arrayMCO = _.find(book.approvalList, function(o) { return o.role === 2 && o.user > 0 && o.content === 'artwork';}) || {user: 0 };
                        var briefStats = Wraps.getListChildren(book, 'status', 'brief');
                        if (
                            book.approvalList[copy.approvalStatus] &&
                            book.approvalList[copy.approvalStatus].role == 6 &&
                            briefStats.length === 1 &&
                            briefStats[0] === 1 // Design, all the same status and stat = 1
                        ) {
                            return true;
                        }
                        else if (
                            book.approvalList[copy.approvalStatus] &&
                            (
                                book.approvalList[copy.approvalStatus].role == 2  ||  // MCO
                                book.approvalList[copy.approvalStatus].role == 9 ||
                                book.approvalList[copy.approvalStatus].role == 10 ||
                                (
                                    (book.approvalList[copy.approvalStatus].role == 3 || book.approvalList[copy.approvalStatus].role == 8) &&
                                    book.bookOwner != $scope.$storage.user.id &&
                                    arrayMCO.user != $scope.$storage.user.id
                                ) // Approver

                            )
                        ) {
                            return true;
                        }
                        else {
                            return false;
                        }
                        break;
                    case 'doc':
                        var arrayMCO = _.find(book.approvalList, function(o) { return o.role === 2 && o.user > 0 && o.content === 'doc';}) || {user: 0 };
                        if (
                            book.approvalList[copy.approvalStatus] &&
                            book.approvalList[copy.approvalStatus].role == 2 &&
                            book.approvalStatus > copy.approvalStatus // MCO and has been rejected
                        ) {
                            return true;
                        }
                        else if (
                            book.approvalList[copy.approvalStatus] &&
                            (
                                book.approvalList[copy.approvalStatus].role == 9 ||
                                book.approvalList[copy.approvalStatus].role == 10 ||
                                (
                                    (book.approvalList[copy.approvalStatus].role == 3 || book.approvalList[copy.approvalStatus].role == 8) &&
                                    book.bookOwner != $scope.$storage.user.id &&
                                    arrayMCO.user != $scope.$storage.user.id
                                ) // Approver
                            )
                        ) {
                            return true;
                        }
                        else {
                            return false;
                        }
                        break;
                    case 'final':
                        if (
                            book.approvalList[copy.approvalStatus] &&
                            (
                                book.approvalList[copy.approvalStatus].role == 6 ||
                                book.approvalList[copy.approvalStatus].role == 11 ||
                                book.approvalList[copy.approvalStatus].role == 12 ||
                                book.approvalList[copy.approvalStatus].role == 13 ||
                                book.approvalList[copy.approvalStatus].role == 14 ||
                                book.approvalList[copy.approvalStatus].role == 15
                            ) // Approver
                        ) {
                            return true;
                        }
                        else {
                            return false;
                        }
                        break;
                    default:
                        return false;
                }

            } else {
                return false;
            }
        };


        vm.audiences = Audiences.__get__notDeleted();
        vm.insurers = Insurers.__get__notDeleted();
        vm.subInsurers = SubInsurers.__get__notDeleted();
        vm.distributors = Distributors.__get__notDeleted();
        vm.regions = Regions.__get__notDeleted();
        vm.products = Products.__get__notDeleted();
        vm.productTypes = ProductTypes.__get__notDeleted();
        vm.docTypes = DocTypes.find({"filter": {"include": "DocTypeCats", "where": {"docTypeStatus": 1}}});



        vm.submitForApprovalVisibility = function (book) {
            return  book.approvalList[book.approvalStatus] &&
                    vm.isMCO(book) && book.approvalList[book.approvalStatus].role === 2 &&
                    book.approvalStatus < 2 && book.status === 0 &&
                    book.approvalList[book.approvalStatus].content === 'doc' &&
                    book.documentStatus.substring(0, 4) !== 'EXPD';
        }


        vm.changeStatusNextDialog = function (book) {
            var confirm = $mdDialog.confirm()
                .title('Change Book Status')
                .htmlContent('Are you sure you want to change the status of this book?<br /> <b>This action cannot be undone.</b>')
                .ariaLabel('Confirm status change')
                .ok('Change status')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    vm.changeStatusNext(book);
                }, function () {

                });
        }

        vm.changeStatusNext = function (thisBook) {

            var approvalStatus = thisBook.approvalStatus;
            var status = 0;

            var type = 'doc';
            if (thisBook.approvalStatus === _.findLastIndex(thisBook.approvalList, {'content': type})) {  //child approval is end of TYPE workflow
                status = 1; //set child to ready
            } else {
                approvalStatus++; //move child up
            }

            if (thisBook.approvalStatus === thisBook.approvalList.length - 1) {  //child approval is end of TYPE workflow
                thisBook.status = 1; //set book to ready
            } else {
                thisBook.approvalStatus++; //move child up
            }

                Events.create({
                    "eventId": 0,
                    "bookId": thisBook.bookId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Approved",
                    "eventData": "Book Approved - approvalStatus:" + approvalStatus + " - Status:" + status
                }).$promise.then(function (event) {

                    if (thisBook.status === 0 && thisBook.DocTypes && thisBook.DocTypes.docTypeTemplate === 1 && thisBook.approvalStatus === _.findIndex(thisBook.approvalList, ['content', 'final'])) {
                        thisBook.isLoading = true;
                        WrapsBooks.uploadPDF(thisBook).then(function () {
                            thisBook.isLoading = false;
                            WrapsBooks.updateBookById(thisBook.bookId);
                            //thisBook.updateBook();
                            console.log('pdf saved');
                        });

                    }

                    //thisBook.status = results.approvalStatus;
                    //thisBook.approvalStatus = results.approvalStatus;

                    Structures.updateAll({
                        "where": {
                            "bookId": thisBook.bookId,
                            "approvalStatus": {"lte": approvalStatus}
                        }
                    }, {
                        "status": status,
                        "approvalStatus": approvalStatus
                    }).$promise.then(function (results) {

                        if (status === 1) {
                            Versions.approveVersionsByBook({"bookId": thisBook.bookId}).$promise.then(function (response) {
                                //console.log(response);
                            });
                            Wraps.sendApprovedEmail(thisBook);
                        } else {
                            Wraps.sendReviewEmail(thisBook,event.eventId);
                        }


                        var liveDate = $filter('date')(thisBook.liveDate, "dd/MM/yyyy");

                        //this is to update the lastupdated date so the trigger get set after the structure changes


                        Books.upsert({
                            "bookId": thisBook.bookId,
                            "status": thisBook.status,
                            "approvalStatus": thisBook.approvalStatus
                        }).$promise.then(function (results) {
                            WrapsBooks.updateBookById(thisBook.bookId);
                            //thisBook.updateBook();
                        });
                    });
                });


        }


        vm.resetToBriefDialog = function (book) {
            var confirm = $mdDialog.confirm()
                .title('Reset to Brief')
                .htmlContent('Are you sure you want to reset the status of this book?<br />• Brief will return to MCO<br />• Artwork will return to Design<br /><br /> <b>This action cannot be undone.</b>')
                .ariaLabel('Confirm Reset to Brief')
                .ok('Reset to Brief')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    vm.openChangeDialog('resetToBrief').then(function (eventData) {
                        if (eventData) {
                            vm.resetToBrief(book, eventData);
                        }
                    });
                }, function () {

                });
        }


        vm.resetToMCODialog = function (book) {
            var confirm = $mdDialog.confirm()
                .title('Reset to MCO')
                .htmlContent('Are you sure you want to reset the status of this book?<br />• All content will return to MCO<br /><br /> <b>This action cannot be undone.</b>')
                .ariaLabel('Confirm Reset to MCO')
                .ok('Reset to MCO')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    vm.openChangeDialog('resetToMCO').then(function (eventData) {
                        if (eventData) {
                            vm.resetToMCO(book, eventData);
                        }
                    });
                }, function () {

                });
        }


        vm.resetToMCO = function (book, eventData) {

            var statusofMCO1 = _.findIndex(book.approvalList, function (item) {
                return item.role == 2 && item.content === 'doc';
            });
            Structures.updateAll({"where": {"bookId": book.bookId}}, {
                "approvalStatus": statusofMCO1,
                "status": 0
            }).$promise.then(function (results) {
                Books.upsert({
                    "bookId": book.bookId,
                    "approvalStatus": statusofMCO1
                }).$promise.then(function (results) {
                    Events.create({
                        "eventId": 0,
                        "bookId": book.bookId,
                        "userId": $scope.$storage.user.id,
                        "eventType": "Reset to MCO",
                        "eventData": Wraps.toJson(eventData)
                    }).$promise.then(function (results) {
                        //vm.updateBooks();
                        WrapsBooks.updateBookById(book.bookId);
                        //book.updateBook();
                    });
                });
            });
        }


        vm.earlyExpireDialog2 = function (book) {
            var confirm = $mdDialog.confirm()
                .title('Early Expiry Date')
                .htmlContent("<md-datepicker ng-model=\"library.earlyExpiryDate\" md-placeholder=\"Early Expiry Date\"   flex></md-datepicker>")
                .ariaLabel('Early Expire Document')
                .ok('Expire Document')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    vm.openChangeDialog().then(function (eventData) {
                        if (eventData) {
                            //vm.resetToMCO(book, eventData);
                        }
                    });
                }, function () {

                });
        }

        vm.makeBookLiveDialog = function (book) {
            var confirm = $mdDialog.confirm()
                .title('Confirm Make Live')
                .htmlContent('Are you sure you want to make this book live?<br /><br /> <b>This action cannot be undone.</b>')
                .ariaLabel('Confirm Make Live')
                .ok('Confirm Make Live')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    vm.makeBookLive(book);
                }, function () {

                });
        }

        vm.earlyExpireDialog = function (book) {

            $mdDialog.show({
                clickOutsideToClose: false,

                scope: $scope,        // use parent scope in template
                preserveScope: true,  // do not forget this if use parent scope
                locals: {book: book},
                template:
                    '<md-dialog style="width:400px;">' +
                    '<md-dialog-content class="md-padding" flex="100">' +
                    '<h2 class="md-title">Early Expire Document</h2>' +
                    '<h4>Expiry Date</h4>' +
                    '<md-datepicker ng-model="earlyExpiryDate" md-placeholder="Early Expiry Date"   flex></md-datepicker>' +
                    '</md-dialog-content>' +
                    '<md-dialog-actions>' +
                    '<md-button ng-click="closeDialog()" class="md-primary">Cancel</md-button>' +
                    '<md-button ng-click="saveDialog()" class="md-green"  >Expire</md-button>' +
                    '</md-dialog-actions>' +
                    '</md-dialog>',

                controller: ["$scope", "$mdDialog", "book", function DialogController($scope, $mdDialog, book) {
                    $scope.book = book;
                    $scope.earlyExpiryDate = new Date();
                    $scope.closeDialog = function () {
                        $mdDialog.hide();
                    }
                    $scope.saveDialog = function () {
                        $mdDialog.hide();
                        vm.openChangeDialog('rfe').then(function (eventData) {
                            if (eventData) {
                                //console.log($scope.earlyExpiryDate);

                                vm.earlyExpire(book, $scope.earlyExpiryDate, eventData);
                            }
                        });
                    }
                }]
            });
        }

        vm.earlyExpire = function (book, earlyExpiryDate, eventData) {

            Books.upsert({
                "bookId": book.bookId,
                "lastUpdated": moment.utc().format(Wraps.databaseDateFormat),
                "earlyExpiryDate": earlyExpiryDate
            }).$promise.then(function (result) {




                Wraps.sendEarlyExpiryEmail(book, earlyExpiryDate).then(function(res){
                    var msgid = null;
                    if(res && res.messageId){
                        msgid = res.messageId;
                    }
                    Events.create({
                        "eventId": 0,
                        "bookId": book.bookId,
                        "userId": $scope.$storage.user.id,
                        "messageId": msgid,
                        "eventType": "Early Expired",
                        "eventData": Wraps.toJson(eventData)

                    });
                    Notifications.getCounts();
                    WrapsBooks.updateBookById(book.bookId);
                });

                //book.updateBook();

            });

        }

        vm.makeBookLive = function (book) {

            var bookStatus = Wraps.getListChildren(book, 'status');
            if (book.status === 0 && bookStatus.length === 1 && bookStatus[0] === 1) {
                if (book.approvalList.length === (book.approvalStatus + 1)) { //last status before live
                    var liveDate = $filter('date')(book.liveDate, "dd/MM/yyyy");
                    book.status = 1;

                    Books.upsert({
                        "bookId": book.bookId,
                        "status": book.status,
                        "pdfVersion": book.pdfVersion,
                        "approvalStatus": book.approvalStatus,
                        "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)
                    }).$promise.then(function (results) {

                        Wraps.sendApprovedEmail(book);

                        Versions.approveVersionsByBook({"bookId": book.bookId});

                        Events.create({
                            "eventId": 0,
                            "bookId": book.bookId,
                            "userId": $scope.$storage.user.id,
                            "eventType": "Approved",
                            "eventData": "Book Approved - Status:" + book.status + "\napprovalStatus:" + book.approvalStatus

                        });
                        WrapsBooks.updateBookById(book.bookId);
                        //book.updateBook();
                    });
                }
            }
        }


        vm.resetToBrief = function (book, eventData) {
            var statusofMCO1 = _.findIndex(book.approvalList, function (item) {
                return item.role == 2 && item.content === 'brief';
            });
            var statusofDesign = _.findIndex(book.approvalList, function (item) {
                return item.role == 6 && item.content === 'artwork';
            });
            var statusofDesignFinal = _.findIndex(book.approvalList, function (item) {
                return item.role == 6 && item.content === 'final';
            });


            Events.create({
                "eventId": 0,
                "bookId": book.bookId,
                "userId": $scope.$storage.user.id,
                "eventType": "Reset to Brief",
                "eventData": Wraps.toJson(eventData)
            });

            var prom = [];
            _.forEach(book.Children, function (item, index) {
                var thisStatus = false;
                if (item.hasBrief) {
                    thisStatus = statusofMCO1;
                } else if (item.hasArtwork) {
                    thisStatus = statusofDesign;
                } else if (item.hasFinal) {
                    thisStatus = statusofDesignFinal;
                }

                //console.log("thisStatus", thisStatus);
                if (thisStatus || thisStatus === 0) {
                    prom.push(
                        Structures.upsert({
                            "structureId": item.structureId,
                            "approvalStatus": thisStatus,
                            "status": 0
                        }).$promise.then(function (results) {
                            //events update
                            Events.create({
                                "eventId": 0,
                                "bookId": book.bookId,
                                "structureId": item.structureId,
                                "contentId": item.versions.contentId,
                                "historyId": item.History[item.History.length - 1].historyId,
                                "userId": $scope.$storage.user.id,
                                "eventType": "Reset to Brief",
                                "eventData": "Reset to Brief - Status:" + item.status + "\napprovalStatus:" + item.approvalStatus
                            });
                        })
                    );
                }
            });
            $q.all(prom).then(function () {
                //book approval status to MCO1
                Books.upsert({
                    "bookId": book.bookId,
                    "approvalStatus": statusofMCO1
                }).$promise.then(function (results) {

                    Events.create({
                        "eventId": 0,
                        "bookId": book.bookId,
                        "userId": $scope.$storage.user.id,
                        "eventType": "Reset to Brief",
                        "eventData": Wraps.toJson(eventData)
                    }).$promise.then(function (results) {
                        //when done
                        //vm.updateBook(book);
                        WrapsBooks.updateBookById(book.bookId);
                        //book.updateBook();
                    });

                });
            });

        }


        vm.saveBookInfoDialog = function (book) {
            if (vm.selected.book.DocTypes && vm.selected.book.DocTypes.docTypeId === 1) {

                var levels = false;
                if (book.levels) {
                    angular.forEach(book.levels, function (value, name) {
                        if (value.active === true) {
                            levels = true;
                        }
                    });
                }

                if (levels === false) {
                    var confirm = $mdDialog.confirm()
                        .title('Save Book')
                        .htmlContent('Are you sure you want to save this book without any Levels set?<br />')
                        .ariaLabel('Confirm Save')
                        .ok('Save')
                        .cancel('Cancel');

                    $mdDialog.show(confirm)
                        .then(function () {
                            vm.saveBookInfo(book);
                        }, function () {
                        });
                } else {
                    vm.saveBookInfo(book);
                }

            } else {
                vm.saveBookInfo(book);
            }
        }


        vm.saveEnclosures = function (bookId, enclosures) {

            var deferred = $q.defer();
            var enclosureIdResults;
            var enclosureId;
            var whereData, upsertData;
            var promises = [];

            _.forEach(enclosures, function (enclosure, key) {

                var pattern = new RegExp(/_([0-9]+)$/g);

                enclosureIdResults = pattern.exec(key);
                if (_.isArray(enclosureIdResults)) {
                    enclosureId = enclosureIdResults[1]
                } else {
                    enclosureId = '';
                }

                if (enclosureId != '' && enclosure != '' && !_.isNaN(bookId)) {

                    whereData = {
                        'enclosureBookId': bookId,
                        'enclosureBookCode': enclosure,
                        'enclosureType': enclosureId
                    };

                    upsertData = {
                        'enclosureBookId': bookId,
                        'enclosureBookCode': enclosure,
                        'enclosureType': enclosureId,
                        'status': 1
                    };

                    var loopDefered = $q.defer();
                    Enclosures.upsertWithWhere({"where": whereData}, upsertData).$promise.then(function (results) {
                        //console.log("Enclosures.upsertWithWhere", results);
                        loopDefered.resolve(results);
                    });
                    promises.push(loopDefered.promise);
                }

            });
            $q.all(promises).then(function (results) {
                //console.log("Enclosures all");

                deferred.resolve(results);
            });

            return deferred.promise;
        };

        vm.saveProductIds = function (bookId, productIdsSelectedArray) {

            var deferred = $q.defer();
            var whereData, insertData;


            //ProductIdsBookLink.prototype$updateAttributes({bookId: bookId}, {status: 0});
            //ProductIdsBookLink.setStatusByBookId({bookId: bookId});

            var promises = [];
            _.forEach(productIdsSelectedArray, function (state, productId) {

                if (state === true) {

                    whereData = {
                        'bookId': bookId,
                        'productId': productId
                    };

                    insertData = {
                        'bookId': bookId,
                        'productId': productId,
                        'status': 1
                    };
                    var loopDefered = $q.defer();
                    ProductIdsBookLink.upsertWithWhere({"where": whereData}, insertData).$promise.then(function (results) {
                        loopDefered.resolve();
                    });
                    promises.push(loopDefered.promise);
                }


            });
            $q.all(promises).then(function () {
                deferred.resolve();
            });
            return deferred.promise;
        };

        vm.saveInsurerDetails = function (bookId, insurerDetails) {

            //console.log("insurerDetails", insurerDetails);
            var deferred = $q.defer();
            var whereData, upsertData;
            var promises = [];

            _.forEach(insurerDetails, function (insurer) {

                if (!_.isNaN(bookId)) {

                    whereData = {
                        'bookId': bookId,
                        'insurerId': insurer,
                    };

                    upsertData = {
                        'bookId': bookId,
                        'insurerId': insurer,
                        'status': 1
                    };

                    var loopDefered = $q.defer();
                    InsurerDetails.upsertWithWhere({"where": whereData}, upsertData).$promise.then(function (results) {
                        //console.log("InsurerDetails.upsertWithWhere", results);
                        loopDefered.resolve(results);
                    });
                    promises.push(loopDefered.promise);
                }

            });
            $q.all(promises).then(function (results) {
                //console.log("Insurer details all");

                deferred.resolve(results);
            });

            return deferred.promise;
        };


        vm.saveBookInfo = function (book) {

            var languageFile = '';
            if (vm.newLanguage) {
                languageFile = vm.selected.book.languageFile;
                book.languageFile = languageFile;
                //console.log(book.languageFile);
            }

            vm.isLoading = true;

            if (book.liveOnApproval) {
                book.activeDate = null;
                book.liveDate = null;
                if(book.dates && book.dates.lifecyclePeriod && book.dates.lifecyclePeriod != '0,months' && book.dates.lifecyclePeriod != ''){
                    book.expiryDate = "3000-01-01";
                    book.reviewDate = "3000-01-01";
                }
            }

            // If not corporate then clear client id
            if (book.productTypeId != 4) {
                book.clientId = null;
            }

            book.approvalList = [];
            if (book.workflowDoc) {
                book.approvalList = book.approvalList.concat(book.workflowDoc);
            }
            if (book.workflowBrief) {
                book.approvalList = book.approvalList.concat(book.workflowBrief);
            }
            if (book.workflowArtwork) {
                book.approvalList = book.approvalList.concat(book.workflowArtwork);
            }
            if (book.workflowFinal) {
                book.approvalList = book.approvalList.concat(book.workflowFinal);
            }


            if (book.approvalList) {

                var deleted = _.remove(book.approvalList, function (item) {
                    return item.value === false;
                });


                if (!book.bookId) {

                    book.approvalStatus = 0; //workflow can now always start at the begining!! (14-11-19 after call with Laura)

                }
                book.approvalList = Wraps.toJson(book.approvalList);
            }

            if (book.production) {
                book.production = Wraps.toJson(_.pickBy(book.production, _.identity));
            }
            if (book.fulfilment) {
                book.fulfilment = Wraps.toJson(_.pickBy(book.fulfilment, _.identity));
            }
            if (book.digital) {
                book.digital = Wraps.toJson(_.pickBy(book.digital, _.identity));
            }
            if (book.support) {

                book.support = Wraps.toJson(_.pickBy(book.support, _.identity));
            }
            if (book.inSitu) {
                book.inSitu = Wraps.toJson(_.pickBy(book.inSitu, _.identity));
            }
            if (book.extras) {
                book.extras = Wraps.toJson(book.extras);
            }
            if (book.dates && typeof book.dates == "object") {
                book.dates = Wraps.toJson(book.dates);
            }

            if (book.levels) {
                book.levels = Wraps.toJson(book.levels);
            }
            if (book.bookContent) {
                book.bookContent = Wraps.toJson(book.bookContent);
            }
            if (book.languageVersions) {
                book.languageVersions = Wraps.toJson(book.languageVersions);
            }
            if (book.docTypeId !== 182) { //TODO replace 182 with social asset doc type id
                delete book.socialChannelIds;
            }
            if (book.socialChannelIds && typeof book.socialChannelIds == 'object') {
                book.socialChannelIds = Wraps.toJson(book.socialChannelIds);
            }

            if(book.insurerDetailsSelectedArray && book.insurerDetailsSelectedArray.length == 1) {
                if(book.insurerId == 38){
                    book.insurerId = book.insurerDetailsSelectedArray[0];
                }else{
                    book.insurerDetailsSelectedArray = [];
                    book.insurerDetailsSelectedArray[0] = book.insurerId;
                }
            }else if(book.insurerId != 38){
                book.insurerDetailsSelectedArray = [];
                book.insurerDetailsSelectedArray[0] = book.insurerId;
            }

            if (!book.bookId) { //new
                book.bookId = 0;
                book.status = 0;


                delete book.lastUpdated;
                delete book.created;
                delete book.Teams; //TODO change to work with track by same as doctype
                delete book.Clients; //TODO change to work with track by same as doctype
                delete book.Insurers; //TODO change to work with track by same as doctype
                delete book.SubInsurers; //TODO change to work with track by same as doctype
                delete book.Distributors; //TODO change to work with track by same as doctype
                delete book.Audiences; //TODO change to work with track by same as doctype
                delete book.Regions; //TODO change to work with track by same as doctype
                delete book.Products; //TODO change to work with track by same as doctype
                delete book.ProductTypes; //TODO change to work with track by same as doctype
                delete book.bookCodeOld;
                delete book.changes;
                delete book.wcrChanges;
                delete book.boldWords;
                delete book.Children;
                delete book.withdrawDate;
                delete book.Projects;

                Books.upsert(book).$promise.then(function (result) {

                    var codeComponents = {
                        "bookId": result.bookId,
                        "update": "true"
                    };

                    Books.generateDocumentCode(codeComponents);

                    if (book.duplicateId) {
                        //console.log("duplicate");

                        Structures.find({
                            filter: {
                                where: {
                                    bookId: book.duplicateId,
                                    "parent": null
                                }
                            }
                        }).$promise.then(function (chapters) {

                            //this was stopping the duplication removing the data if no products/enclosures were selected
                            //Enclosures.duplicateByBookId({newBookId: result.bookId, oldBookId: book.duplicateId});
                            //ProductIdsBookLink.duplicateByBookId({newBookId: result.bookId, oldBookId: book.duplicateId});

                            delete book.duplicateId;
                            if (book.DocTypes && book.DocTypes.docTypeTemplate === 1) {
                                angular.forEach(chapters, function (chapter) {
                                    //console.log("vm.newLanguage", vm.newLanguage);

                                    Structures.duplicateDeep({
                                        "bookId": result.bookId,
                                        "userId": $scope.$storage.user.id,
                                        "structureId": chapter.structureId,
                                        "status": result.status,
                                        "approvalStatus": result.approvalStatus,
                                        "parent": null,
                                        "eventData": book.eventData,
                                        "newLanguage": vm.newLanguage,
                                        "languageFile": languageFile,
                                        "languageId": vm.selected.book.language
                                    }).$promise.then(function (response) {

                                    });
                                });

                            }

                            var insurerDetailsDeferred = $q.defer();
                            InsurerDetails.setStatusByBookId({bookId: result.bookId}, function () {
                                //console.log('insurerDetails.setStatusByBookId');
                                vm.saveInsurerDetails(result.bookId, book.insurerDetailsSelectedArray).then(function () {
                                    insurerDetailsDeferred.resolve();
                                });
                            });

                            Notifications.getCounts();
                            var enclosureDeferred = $q.defer();
                            Enclosures.setStatusByBookId({'bookId': result.bookId}, function () {
                                if (result.fulfilment && vm.enclosuresAllowedSubDocTypes.indexOf(result.docTypeId) !== -1 && angular.fromJson(result.fulfilment)[7] == true) {
                                    vm.saveEnclosures(result.bookId, book.enclosures).then(function (enclosuresResult) {
                                        enclosureDeferred.resolve(enclosuresResult);
                                    });
                                }else{
                                    enclosureDeferred.resolve();
                                }
                            });

                            var productIdsDeferred = $q.defer();
                            ProductIdsBookLink.setStatusByBookId({bookId: result.bookId}, function () {
                                if (result.fulfilment && vm.productIdsAllowedSubDocTypes.indexOf(result.docTypeId) !== -1 && angular.fromJson(result.fulfilment)[7] == true) {
                                    vm.saveProductIds(result.bookId, book.productIdsSelectedArray).then(function () {

                                        productIdsDeferred.resolve();
                                    });
                                }else{
                                    productIdsDeferred.resolve();
                                }
                            });

                            $q.all([enclosureDeferred.promise, productIdsDeferred.promise, insurerDetailsDeferred.promise]).then(function () {
                                 vm.closeRightMenu();
                                 //open new book in new tab;
                                 vm.addBookTab(result.bookId).then(function () {
                                    checkTabUpdates().then(function(){
                                        WrapsBooks.updateBookById(result.bookId);
                                    });
                                 });

                                 vm.isLoading = false;
                             });



                        });
                    } else {
                        var insurerDetailsDeferred = $q.defer();
                        InsurerDetails.setStatusByBookId({bookId: result.bookId}, function () {

                            vm.saveInsurerDetails(result.bookId, book.insurerDetailsSelectedArray).then(function () {
                                insurerDetailsDeferred.resolve();
                            });
                        });

                        Notifications.getCounts();
                        var enclosureDeferred = $q.defer();
                        Enclosures.setStatusByBookId({'bookId': result.bookId}, function () {
                            if (result.fulfilment && vm.enclosuresAllowedSubDocTypes.indexOf(result.docTypeId) !== -1 && angular.fromJson(result.fulfilment)[7] == true) {
                                vm.saveEnclosures(result.bookId, book.enclosures).then(function (enclosuresResult) {
                                    enclosureDeferred.resolve(enclosuresResult);
                                });
                            }else{
                                enclosureDeferred.resolve();
                            }
                        });

                        var productIdsDeferred = $q.defer();
                        ProductIdsBookLink.setStatusByBookId({bookId: result.bookId}, function () {
                            if (result.fulfilment && vm.productIdsAllowedSubDocTypes.indexOf(result.docTypeId) !== -1 && angular.fromJson(result.fulfilment)[7] == true) {
                                vm.saveProductIds(result.bookId, book.productIdsSelectedArray).then(function () {
                                    productIdsDeferred.resolve();
                                });
                            }else{
                                productIdsDeferred.resolve();
                            }
                        });

                        $q.all([enclosureDeferred.promise, productIdsDeferred.promise, insurerDetailsDeferred.promise]).then(function () {
                            vm.closeRightMenu();
                            //open new book in new tab;
                            vm.addBookTab(result.bookId).then(function () {
                                checkTabUpdates().then(function(){
                                    WrapsBooks.updateBookById(result.bookId);
                                });
                            });

                            vm.isLoading = false;
                        });
                    }
                    if(book.eventData && book.eventData.duplication && book.eventData.duplication === true) {
                        Events.create({
                            "eventId": 0,
                            "bookId":result.bookId,
                            "messageId":book.eventData.reason,
                            "eventType":"Book Duplication",
                            "eventData":book.eventData.message,
                            "userId":$scope.$storage.user.id
                        });
                    }else{
                        Events.create({
                            "eventId": 0,
                            "bookId":result.bookId,
                            "eventType":"Document Created",
                            "eventData":'{"message":"This document was created manually"}',
                            "userId":$scope.$storage.user.id
                        });
                    }
                });
            } else {

                Enclosures.setStatusByBookId({'bookId': book.bookId}, function () {
                    if (book.fulfilment && vm.enclosuresAllowedSubDocTypes.indexOf(book.docTypeId) !== -1 && angular.fromJson(book.fulfilment)[7] == true) {
                        vm.saveEnclosures(book.bookId, book.enclosures);
                    }
                });

                ProductIdsBookLink.setStatusByBookId({bookId: book.bookId}, function () {
                    if (book.fulfilment && vm.productIdsAllowedSubDocTypes.indexOf(book.docTypeId) !== -1 && angular.fromJson(book.fulfilment)[7] == true) {
                        vm.saveProductIds(book.bookId, book.productIdsSelectedArray);
                    }
                });

                InsurerDetails.setStatusByBookId({bookId: book.bookId}, function () {

                    vm.saveInsurerDetails(book.bookId, book.insurerDetailsSelectedArray)

                });



                delete book.lastUpdated;
                delete book.created;
                delete book.Teams; //TODO change to work with track by same as doctype
                delete book.Clients; //TODO change to work with track by same as doctype
                delete book.Projects; //TODO change to work with track by same as doctype
                delete book.Insurers; //TODO change to work with track by same as doctype
                delete book.SubInsurers; //TODO change to work with track by same as doctype
                delete book.Distributors; //TODO change to work with track by same as doctype
                delete book.Audiences; //TODO change to work with track by same as doctype
                delete book.Regions; //TODO change to work with track by same as doctype
                delete book.Products; //TODO change to work with track by same as doctype
                delete book.ProductTypes; //TODO change to work with track by same as doctype
                delete book.changes;
                delete book.boldWords;
                delete book.Children;
                delete book.ownerName;
                delete book.ownerEmail;
                delete book.bookIdLong;
                delete book.sourceIdLong;

                //console.log(bookMetaOriginalState);

                //BooksVersions.upsert({bookId: book.bookId, userId: $scope.$storage.user.id, fromBlob: JSON.stringify(bookMetaOriginalState), toBlob: JSON.stringify(book)});


                //console.log("pre save test",book);
                Books.upsert(book).$promise.then(function (result) {

                    if(result.bookCodeOld !== 'disableAutoCode'){
                        var codeComponents = {
                            "bookId": result.bookId,
                            "update": "true"
                        };
                        Books.generateDocumentCode(codeComponents);
                    }
                    Notifications.getCounts();
                    //console.log("update result",result);
                    //book.updateBook();
                    checkTabUpdates().then(function(){
                        WrapsBooks.updateBookById(book.bookId);
                    });
                    vm.closeRightMenu();
                    vm.isLoading = false;
                });
            }
        }


        vm.openChangeDialog = function (filter) {
            var deferred = $q.defer();
            return $mdDialog.show({
                controller: 'ChangedialogCtrl',
                controllerAs: 'changedialog',
                templateUrl: 'library/changedialog/changedialog.tpl.html',
                parent: angular.element(document.body),
                locals: {filter: filter},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {
                    return data;
                }, function () {
                    return false; //cancel
                });
        };


        vm.duplicateBookDialog = function (book) {
            var confirm = $mdDialog.confirm()
                .title('Duplicate Book')
                .htmlContent('Are you sure you want to duplicate this book?<br />')
                .ariaLabel('Confirm Duplication')
                .ok('Duplicate')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    vm.duplicateBook(book);
                }, function () {

                });
        }


        vm.duplicateBook = function (thisBook) {
            //console.log(thisBook.approvalList);
            //console.log(_.findIndex(thisBook.approvalList,{role: 2} ));

            vm.openChangeDialog('rfd-' + thisBook.DocTypes.docTypeTemplate).then(function (eventData) {
                if (eventData) {
                    eventData.duplication = true;

                    var newBook = angular.copy(thisBook);
                    //console.log(eventData); //TODO send data to evetns and show different list

                    vm.isLoading = true;

                    newBook.created = moment.utc().format(Wraps.databaseDateFormat);
                    newBook.sourceId = newBook.bookId;

                    newBook.bookId = 0;
                    newBook.status = 0;
                    newBook.earlyExpiryDate = null;
                    newBook.pdfVersion = null;
                    newBook.projectId = null;
                    newBook.approvalStatus = 0;
                    newBook.bookCodeOld = null;
                    newBook.finProm = null;
                    newBook.socialChannelIds = null;

                    //workflow user edits
                    _.forEach(newBook.approvalList, function(workflowStep){
                        //make Design or Unigraph default to "Design/All" for Artwork Design or Final
                        // 20 = Design, 21 = Unigraph
                        if( (workflowStep.team == 20 || workflowStep.team == 21) && (workflowStep.content == "final" || (workflowStep.content == "artwork" && workflowStep.role == 6))){
                            workflowStep.user = undefined;
                            workflowStep.team = 20;
                        }
                        if(workflowStep.user > 0){
                            //if user is disabled the user id and team id gets cleared
                            if(_.findIndex(vm.users, { 'id': workflowStep.user, 'status': 2 })>0 ){
                                workflowStep.team = undefined;
                                workflowStep.user = undefined;
                            }
                        }
                    });

                    var firstMCOIndex = _.findIndex(newBook.approvalList, {role: 2});
                    if (!firstMCOIndex || firstMCOIndex < 0) {
                        firstMCOIndex = 0;
                    }


                    if ($scope.$storage.user.roleId != 1 || $scope.$storage.user.roleId != 5) { //if not DLO or admin make current user owner and first MCO
                        //console.log("firstMCOIndex", firstMCOIndex);
                        newBook.bookOwner = $scope.$storage.user.id;
                        newBook.approvalList[firstMCOIndex].team = $scope.$storage.user.teamId;
                        newBook.approvalList[firstMCOIndex].user = $scope.$storage.user.id;
                    }


                    // if (angular.isObject(newBook.approvalList)) {
                    //   newBook.approvalList = Wraps.toJson(newBook.approvalList);
                    // }
                    //
                    // if (angular.isObject(newBook.levels)) {
                    //   newBook.levels = Wraps.toJson(newBook.levels);
                    // }
                    // if (angular.isObject(newBook.bookContent)) {
                    //   newBook.bookContent = Wraps.toJson(newBook.bookContent);
                    // }
                    // if (angular.isObject(newBook.languageVersions)) {
                    //   newBook.languageVersions = Wraps.toJson(newBook.languageVersions);
                    // }


                    var codeComponents = {
                        "bookId": newBook.bookId,
                        "insurerId": thisBook.insurerId,
                        "productId": thisBook.productId,
                        "docType": thisBook.DocTypes ? thisBook.DocTypes.docTypeCode : '',
                        "languageId": thisBook.language,
                        "clientId": thisBook.clientId,
                        "createdDate": moment().format('MMYY')
                    };

                    Books.generateDocumentCode(codeComponents).$promise.then(function(result) {
                        //vm.book.bookCode = result[0][0].bookCode;
                        newBook.bookCode = result[0][0].bookCode;
                    });


                    newBook.bookName = newBook.bookName;
                    //newBook.bookCode = '';//newBook.bookCode;
                    newBook.duplicateId = thisBook.bookId;
                    newBook.eventData = eventData;

                    delete newBook.Children;
                    delete newBook.boldWords;
                    delete newBook.changes;

                    vm.openRightMenu(newBook);
                    vm.isLoading = false;


                }
            });
        }


        vm.removeFalseFromList = function (obj) {
            obj = _.pick(obj, _.isNumber);
            //console.log(obj);

        }

        vm.getFile2 = function (cont, loc, file) {
            var fileLoc = loc.concat(file);
            Container.download({container: cont, file: fileLoc}, function (res) {
                return res;
            }, function (cb) {
                //console.log(cb);
            });

        }


        var initParaDrag = function (siblings, parentId, contentType) {

          var bookId = siblings[0].bookId;
          dragularService.cleanEnviroment();

          if(contentType == 1) {
            var container = document.getElementById('chapterSort-'+bookId);
            dragularService(container, {

              containersModel: siblings,
              boundingBox: container,
              revertOnSpill: true
            });
          }
          else {
            var container = document.getElementById('sort-' + parentId);
            dragularService(container, {
              moves: function (el, container, handle) {
                return angular.element(handle).hasClass('drag-handle');
              },
              containersModel: siblings,
              boundingBox: container,
              lockY: true
            });
          }
        }


        var initWorkflowList = function () {

            //console.log(angular.copy(vm.selected.book.approvalList));

            var tempApprovalList = angular.copy(vm.selected.book.approvalList);


            // vm.selected.book.workflowTest = [
            //     {content: 'doc', role: 1, team: 20, user: 1, value: true},
            //     {content: 'doc', role: 2, team: $scope.$storage.user.teamId, user: $scope.$storage.user.id, value: true}
            // ];

            vm.selected.book.workflowDoc = [
                {content: 'doc', role: 1, team: 20, user: 1, value: false},
                {content: 'doc', role: 2, team: $scope.$storage.user.teamId, user: $scope.$storage.user.id, value: true}

                //{content: 'doc', role: 4, team: '', user: '', value: true} //CO
            ];
            vm.selected.book.workflowBrief = [
                {content: 'brief', role: 1, team: 20, user: 1, value: false},
                {content: 'brief', role: 2, team: $scope.$storage.user.teamId, user: $scope.$storage.user.id, value: true}
            ];


            vm.selected.book.workflowArtwork = [
                {content: 'artwork', role: 6, team: 20, user: '', value: true},
                {
                    content: 'artwork',
                    role: 2,
                    team: $scope.$storage.user.teamId,
                    user: $scope.$storage.user.id,
                    value: true
                }
            ];

            if (!vm.selected.book.workflowFinal) {
                vm.selected.book.workflowFinal = [
                    {content: 'final', role: 6, team: 20, user: '', value: true},
                    {content: 'final', role: 11, team: 20, user: '', value: false},
                    {content: 'final', role: 12, team: 27, user: '', value: false},
                    {content: 'final', role: 14, team: 44, user: '', value: false},
                    {content: 'final', role: 15, team: 43, user: '', value: false},
                    {content: 'final', role: 13, team: $scope.$storage.user.teamId,  user: $scope.$storage.user.id, value: false }
                ];
            } else if (vm.selected.book.workflowFinal.length === 3) {
                vm.selected.book.workflowFinal = _.concat({content: 'final', role: 6, team: 20, user: '',  value: true}, vm.selected.book.workflowFinal);
            }
            //console.log(angular.copy(vm.selected.book.approvalList));

            if (vm.selected.book.approvalList && vm.selected.book.approvalList.length > 0) {

                //console.log(angular.copy(vm.selected.book.approvalList));

                if (vm.selected.book.DocTypes && vm.selected.book.DocTypes.docTypeTemplate === 1) {
                    //vm.selected.book.workflowFinal.splice(0, 1); // removes design for membership guides

                    vm.selected.book.workflowDocTemp = _.remove(vm.selected.book.approvalList, {content: 'doc'});
                    //console.log("vm.selected.book.workflowDocTemp",vm.selected.book.workflowDocTemp);
                    //console.log("vm.selected.book.workflowDoc1",vm.selected.book.workflowDoc);
                    _.forEach(vm.selected.book.workflowDoc, function (item, index) {
                        if (!_.find(vm.selected.book.workflowDocTemp, {'role': item.role})) {
                            item.value = false;
                            vm.selected.book.workflowDocTemp.splice(index, 0, item);
                        }
                    });
                    vm.selected.book.workflowDoc = vm.selected.book.workflowDocTemp;

                    //console.log("vm.selected.book.workflowDoc2",vm.selected.book.workflowDoc);

                    //TODO vm.selected.book.workflowFinal remove first one
                    vm.selected.book.workflowFinalTemp = _.remove(vm.selected.book.approvalList, {content: 'final'});
                    _.forEach(vm.selected.book.workflowFinal, function (item, index) {
                        if (!_.find(vm.selected.book.workflowFinalTemp, {'role': item.role})) {
                            item.value = false;
                            vm.selected.book.workflowFinalTemp.splice(index, 0, item);
                        }
                    });
                    vm.selected.book.workflowFinal = vm.selected.book.workflowFinalTemp;

                    delete vm.selected.book.workflowBrief;
                    delete vm.selected.book.workflowArtwork;

                } else if (vm.selected.book.DocTypes && vm.selected.book.DocTypes.docTypeTemplate != 1) {

                    vm.selected.book.workflowBriefTemp = _.remove(vm.selected.book.approvalList, {content: 'brief'});
                    _.forEach(vm.selected.book.workflowBrief, function (item, index) {
                        if (!_.find(vm.selected.book.workflowBriefTemp, {'role': item.role})) {
                            item.value = false;
                            vm.selected.book.workflowBriefTemp.splice(index, 0, item);
                        }
                    });
                    vm.selected.book.workflowBrief = vm.selected.book.workflowBriefTemp;

                    vm.selected.book.workflowArtworkTemp = _.remove(vm.selected.book.approvalList, {content: 'artwork'});
                    _.forEach(vm.selected.book.workflowArtwork, function (item, index) {
                        if (!_.find(vm.selected.book.workflowArtworkTemp, {'role': item.role})) {
                            item.value = false;
                            vm.selected.book.workflowArtworkTemp.splice(index, 0, item);
                        }
                    });
                    vm.selected.book.workflowArtwork = vm.selected.book.workflowArtworkTemp;


                    vm.selected.book.workflowFinalTemp = _.remove(vm.selected.book.approvalList, {content: 'final'});
                    _.forEach(vm.selected.book.workflowFinal, function (item, index) {
                        if (!_.find(vm.selected.book.workflowFinalTemp, {'role': item.role})) {
                            item.value = false;
                            vm.selected.book.workflowFinalTemp.splice(index, 0, item);
                        }
                    });
                    vm.selected.book.workflowFinal = vm.selected.book.workflowFinalTemp;

                    delete vm.selected.book.workflowDoc;
                }
            } else {
                if (vm.selected.book.DocTypes && vm.selected.book.DocTypes.docTypeTemplate === 1) {

                    delete vm.selected.book.workflowBrief;
                    delete vm.selected.book.workflowArtwork;
                    vm.selected.book.workflowFinal.splice(0, 1); // removes design for membership guides
                    vm.selected.book.workflowDoc.push({content: 'doc', role: 3, team: 10, user: "", value: true});
                } else if (vm.selected.book.DocTypes && vm.selected.book.DocTypes.docTypeTemplate != 1) {
                    delete vm.selected.book.workflowDoc;
                    vm.selected.book.workflowBrief.push({content: 'brief', role: 3, team: 10, user: "", value: true});
                    vm.selected.book.workflowArtwork.push({content: 'artwork', role: 3,  team: 10, user: "", value: true });
                }

                //console.log(angular.copy(vm.selected.book));
            }
            vm.selected.book.approvalList = tempApprovalList;
            //console.log(angular.copy(vm.selected.book.approvalList));


            vm.selected.book.initWorkflowList = initWorkflowList;
        }




        vm.showHistory = function (id, clickSource) {
            $mdDialog.show({
                controller: 'HistorydialogCtrl',
                controllerAs: 'historydialog',
                templateUrl: 'library/historydialog/historydialog.tpl.html',
                parent: angular.element(document.html),
                locals: {structureId: id, clickSource: clickSource},
                clickOutsideToClose: true
            })
                .then(function (data) {

                    if (data) {
                        vm.bottomSheetData.versions.versiondata = data;
                        vm.json2Obj(vm.bottomSheetData);


                        //console.log(vm.bottomSheetData.versions);
                        //console.log(data);


                    }

                }, function () {
                    vm.status = 'You cancelled the dialog.';
                });
        };

        //TODO move approval of book stages here
        vm.showApproveToast = function (book, elId) {
            //var el = angular.element(document.getElementById(elId));
            //
            //$mdToast.show(
            //    $mdToast.simple()
            //        .textContent('Approve book')
            //        .position('top right')
            //        .parent(el)
            //        .hideDelay(0)
            //        .action('OK')
            //        .capsule(true)
            //).then(function(){
            //  console.log('toast closed');
            //});
        };



        vm.getFile = function (container, file) {
            return apiUrl + "/containers/getfile/" + container + "/" + file;
            //return apiUrl + "/containers/wraps-files/download/" + file;
            //return "http://wraps-test.s3-website-us-east-1.amazonaws.com/"+container+file;
        }


        vm.getRoleById = Wraps.getRoleById;


        // 3 FUNCTIONS FOR AUTOCOMPLETE
        /*vm.queryBooks = function (query) {
            var results = query ? vm.bookCodeList.filter(createFilterFor(query)) : [];
            return results;
        }

        function createFilterFor(query) {
            return function filterFn(item) {
                return (item.toLowerCase().indexOf(query.toLowerCase()) >= 0);
            };
        }
        */
        //needed for the validation
        //TODO move template-based right-menu tab validation logic to the tab-main component & pass the value up to rightmenu
        vm.checkCodes = function (text) {
            //console.log(_.includes(vm.bookCodeList, text));
            return _.includes(vm.bookCodeList, text);
        }
        vm.checkBlank = function (text) {
            //console.log(text);
            return text === '' || typeof text === 'undefined' || text === null;
        }

        vm.checkBlankArray = function(arrayVariable) {
            return _.isEmpty(arrayVariable) || typeof arrayVariable === 'undefined';
        }

        vm.checkProductIdsSelectedArray = function (productIdsSelectedArray) {
            var hitme = false;
            _.forEach(productIdsSelectedArray, function (val) {
                if (val === true) {
                    hitme = true;
                }
            });
            if (hitme === true) {
                return true;
            } else {
                return false;
            }
        }


        // // FUNCTIONS FOR CLIENT AUTOCOMPLETE
        // vm.queryClients = function (query) {
        //   var results = query ? vm.bookClientList.filter( createFilterFor(query) ) : [];
        //   return results;
        // }


        vm.toggleSwitchGroup = function (thisNa, thisGroup) {
            if (thisNa.flag) {
                _.forEach(thisGroup, function (value, key) {
                    thisGroup[key] = false;
                });
            } else {
                thisNa.flag = true;
            }
        }

        /*vm.toggleSwitchNa = function (thisGroup, thisNa) {
            if (_.indexOf(_.values(thisGroup), true) >= 0) {
                thisNa.flag = false;
            } else {
                thisNa.flag = true;
            }

        }*/


        vm.lockSwitch = function (item) {
            if (item.role == 6) {
                item.value = true;
            } else {

                item.value = !item.value;
            }
        }

        vm.replaceSpaceUni = function (txt) {
            if (txt) {
                txt = txt.replace("NO DATE", "NO\u00A0DATE");
                txt = txt.replace("IN WORK", "IN\u00A0WORK");
                return txt;
            }
        }


        vm.isSwapTeamUser = function (thisBook) {

            var now = moment().format('YYYY-MM-DD');

            if (
                $scope.$storage.user.limitDocs === null &&
                thisBook.limitDoc === 0 &&
                thisBook.status == 0 &&
                (thisBook.documentStatus.substring(0, 4) != 'EXPD') &&
                (
                    ($scope.$storage.user.teamId == 20 && typeof _.find(thisBook.approvalList, function (i) { return (i.team == 20 || i.team == 21) && i.role == 6; }) !== 'undefined') ||
                    //($scope.$storage.user.teamId == 10 && typeof _.find(thisBook.approvalList, function (i) { return (i.team == 10) && i.role == 3; }) !== 'undefined') ||
                    (typeof _.find(thisBook.approvalList, function (i) { return (i.team == $scope.$storage.user.teamId ) && i.role == 3; }) !== 'undefined')
                )
            ) {
                return true;
            } else {
                return false;
            }
        };

        vm.swapTeamUserDialog = function (thisBook) {
            return $mdDialog.show({
                controller: 'SwapteamuserdialogCtrl',
                controllerAs: 'swapteamuserdialog',
                templateUrl: 'library/swapteamuserdialog/swapteamuserdialog.tpl.html',
                parent: angular.element(document.body),
                locals: {book: thisBook},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {
                    return data;
                }, function () {
                    return false; //cancel
                });
        };

        //---Routes + doc tabs ---//
        vm.params = $state.params;
        vm.openTabs = WrapsBooks.openTabs;
        vm.openBooks = WrapsBooks.openBooks;
        if(!$scope.$storage.tabHistory){$scope.$storage.tabHistory = []}
        vm.tabHistory = $scope.$storage.tabHistory;

        vm.newTab = function(bookId){

            var deferred = $q.defer();
            //console.log("bookId",bookId);

            bookId = _.toInteger(bookId);
            if(bookId >= 0){
                //console.log("bookId2",bookId);
                bookId = parseInt(bookId);

                $state.go('.', {'bookId':bookId});
                WrapsBooks.loadTab(bookId).then(function(){

                    deferred.resolve();
                }).catch(function(){

                    $state.go('.', {'bookId':null});
                    vm.selectedTabIndex = 0;

                    if(bookId == 'NaN'){bookId = 'Not a number';}

                    var prompt = $mdDialog.alert().title('Unable to return job ' + bookId + '' ).textContent('This may be because this ID doesn\'t exist or you do not have access to this job.').ok('Close');
                    $mdDialog.show(prompt).then(function(res){

                    });
                    deferred.resolve();
                });
            }

            return deferred.promise;
        };

        vm.addBookTab = function(bookId){

            var deferred = $q.defer();
            //console.log('addBookTab');
            bookId = _.toInteger(bookId);
            var tabindex  = _.findIndex(vm.openTabs, function(o) { return _.toInteger(o.bookId) == _.toInteger(bookId) });
            if(tabindex >= 0){
                $state.go('.', {'bookId':bookId});
                vm.selectedTabIndex = tabindex + 1;
            }else{
                vm.openTabs.push({bookId: bookId, isLoading: true});
                vm.newTab(bookId).then(function(){
                    deferred.resolve();
                });
            }

            return deferred.promise;
        }

        if ($state.params.bookId ){
            var bookId = _.toInteger($state.params.bookId);
            if(!_.includes(_.map(vm.openTabs, 'bookId'), bookId)){
                // not in openTabs
                vm.addBookTab(bookId);
            }else{
                // already in openTabs
            }
            for( var i = 0; i < vm.tabHistory.length-1; i++){
                if ( vm.tabHistory[i] === bookId) {
                    vm.tabHistory.splice(i, 1); //remove any previous instances of that book in the history
                }
            }
            vm.tabHistory.push(bookId); //add bookid to end of history array.
        }else{
            vm.tabHistory = $scope.$storage.tabHistory = []; //reset history array
        }


        vm.selectedTabIndex = _.indexOf(_.map(vm.openTabs, 'bookId'),_.toInteger($state.params.bookId)) + 1;



        vm.openLibraryList = function(){
            $state.go('.', {'bookId':null});
        };

        vm.uiOnParamsChanged = function(newParams) {
            //console.log('opc', newParams);
            var bookId = _.toInteger($state.params.bookId);

            if(bookId && bookId == 0){
                $state.go('.', {'bookId':null});
                vm.selectedTabIndex = 0;
            }else if (bookId && !_.includes(_.map(vm.openTabs, 'bookId'),bookId)){
                vm.addBookTab($state.params.bookId);
            }else if($state.params.bookId){
                vm.selectedTabIndex = _.indexOf(_.map(vm.openTabs, 'bookId'),bookId) + 1;
            }

            if(bookId){
                for( var i = 0; i < vm.tabHistory.length-1; i++){
                    if ( vm.tabHistory[i] === bookId) {
                        vm.tabHistory.splice(i, 1); //remove any previous instances of that book in the history
                    }
                }
                vm.tabHistory.push(bookId); //add bookid to end of history array.
            }else{
                vm.tabHistory = $scope.$storage.tabHistory = []; //reset history array
            }
        }

        $scope.$watch('library.selectedTabIndex', function(current, old) {

            //console.log("sti", current);
            if(parseInt(current) == 0 || current == undefined){
                vm.openLibraryList();
                //TODO add a check?
                if(!angular.equals(current, old)) {
                    vm.updateBooks(); //refresh book list.
                }
                //start interval
                $scope.listInterval = $interval(function () {
                    vm.updateBooks();
                }, 300000);
            }else{
                var currentIndex = parseInt(current)-1;

                if (angular.isDefined($scope.listInterval)){
                    $interval.cancel($scope.listInterval);
                }

                var openTab = vm.openTabs[currentIndex];
                var openBook = vm.openBooks[currentIndex];
                Wraps.book = openBook;
                //console.log("openBook",openBook);
                if(openBook && openTab && openTab.updateRequired == true){ //if update flag is set
                    WrapsBooks.updateBookById(openBook.bookId);
                    //openBook.updateBook();
                    openTab.updateRequired = false; //clear flag
                }
                if(openTab){

                    $state.go('.', {'bookId':openTab.bookId});
                }
            }


        });


        $rootScope.$watch(function(){return $localStorage.user}, function(current, old) {
            if(current && current.id){
                //start interval
                if (!angular.isDefined(vm.checkInterval)) {
                    vm.checkInterval = $interval(function(){
                        //every 1 minute
                        checkTabUpdates();
                    },60000);
                }
            }else{
                if (angular.isDefined(vm.checkInterval)){
                    $interval.cancel(vm.checkInterval);
                }
            }


        });


        var checkTabUpdates = function(){

            var deferred = $q.defer();
            var checkableTabs = [];

            //also check all open books that don't have an update flag already set.
            _.forEach(vm.openTabs,function(openTab, ind) {
                if(!openTab.updateRequired || openTab.updateRequired == false){
                    checkableTabs.push(openTab.bookId);
                }
            });
            if(checkableTabs.length > 0) {
                Books.getUpdatedBookIds({
                    "ids": checkableTabs,
                    "lastClientUpdated": WrapsBooks.lastChecked
                }).$promise.then(function (res) {
                    //if there's an update waiting, set an update flag on that book.
                    _.forEach(vm.openTabs, function (tab) {
                        if (res.updatedBookIds.indexOf(parseInt(tab.bookId)) >= 0) {
                            tab.updateRequired = true;
                        }
                    });
                    WrapsBooks.lastChecked = res.updateTime;
                    deferred.resolve();
                });
            }
            return deferred.promise;
        }

        vm.closeTab = function(bookId){
            var tabindex  = _.findIndex(vm.openTabs, function(o) { return _.toInteger(o.bookId) == _.toInteger(bookId) });
            //console.log(tabindex);
            vm.openTabs.splice(tabindex, 1);
            vm.openBooks.splice(tabindex, 1);
            if (vm.tabHistory.length > 1){
                var newTabId = vm.tabHistory[vm.tabHistory.length-2];
                var newTabIndex = _.findIndex(vm.openTabs, function(o) { return _.toInteger(o.bookId) == _.toInteger(newTabId) });
                vm.tabHistory.length = vm.tabHistory.length-2;
                vm.selectedTabIndex = newTabIndex + 1;
                $state.go('.', {'bookId':newTabId});
            }else{
                vm.selectedTabIndex = 0;
                $state.go('.', {'bookId':null});
            }

        };


        vm.docOpenDialog = function(ev){
            var prompt = $mdDialog.prompt().title('Open a document').textContent('Enter the ID of the document you wish to open').placeholder('0000000').targetEvent(ev).required(true).ok('Open').cancel('Cancel');
            $mdDialog.show(prompt).then(function(bookId){

                var bookId = _.toInteger(bookId);
                vm.addBookTab(bookId);

            },function(){
                vm.selectedTabIndex = 0;
                $state.go('.', {'bookId':null});
            });
        };

        //--- end doc-tabs ---//

        vm.stringifySearch = WrapsBooks.stringifySearch;

        //--action icons --//
        vm.icons = ['warning','error', 'error_outline'];
        vm.colors = ['red', 'light-blue', 'light-blue'];
        //-- end action icons --//

        $scope.$on('$destroy', function() {
            if (angular.isDefined(vm.checkInterval)){
                $interval.cancel(vm.checkInterval);
            }
        });

        vm.downloadCSV = function () {
            var deferred = $q.defer();
            vm.isLoading = true;
            WrapsBooks.downloadCSV().then(function(csv){
                vm.isLoading = false;
                deferred.resolve(csv);
            });
            return deferred.promise;
        }


    }
    LibraryCtrl.$inject = ["$document", "$element", "$filter", "$interval", "$localStorage", "$location", "$mdBottomSheet", "$mdDialog", "$mdSidenav", "$mdToast", "$q", "$rootScope", "$scope", "$sessionStorage", "$state", "$timeout", "$window", "apiUrl", "Audiences", "Books", "BooksVersions", "Containers", "Distributors", "DocTypeCats", "DocTypes", "dragularService", "Enclosures", "EnclosureTypes", "Events", "History", "InsurerDetails", "Insurers", "Messages", "Notifications", "OrderHistory", "pdfUrl", "Products", "ProductIds", "ProductIdsBookLink", "ProductTypes", "Regions", "SaveSearch", "Structures", "SubInsurers", "system", "Upload", "Users", "Versions", "Wraps", "WrapsBooks"];



}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name healthcheck
   * @description
   *
   */
  angular
    .module('healthcheck', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('healthcheck')
    .config(config);

  function config($stateProvider) {
    $stateProvider
        .state('healthcheck', {
            url: '/healthcheck/:doc',
            params: {
                doc: {
                    dynamic: true,
                    value: null,
                    squash: true
                }
            },
            views: {
                tooltip: {
                    templateUrl: 'tooltip/tooltip.tpl.html',
                    controller: 'TooltipCtrl',
                    controllerAs: 'tooltip'
                },
                navigation: {
                    templateUrl: 'navigation/navigation.tpl.html',
                    controller: 'NavigationCtrl',
                    controllerAs: 'navigation'
                },
                main: {
                    templateUrl: 'healthcheck/healthcheck.tpl.html',
                    controller: 'HealthcheckCtrl',
                    controllerAs: 'healthcheck'
                },
                footer: {
                    templateUrl: 'footer/footer.tpl.html',
                    controller: 'FooterCtrl',
                    controllerAs: 'footer'
                }
            }
        });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name healthcheck.controller:HealthcheckCtrl
   *
   * @description
   *
   */
  angular
    .module('healthcheck')
    .controller('HealthcheckCtrl', HealthcheckCtrl);

  function HealthcheckCtrl($filter, $q, $scope, $state, Books, Wraps) {
    var vm = this;
    vm.ctrlName = 'HealthcheckCtrl';
    vm.isLoading = true;
    vm.orderBy = 'bookId';
    vm.hasPermissions = Wraps.hasPermissions;
    vm.params = $state.params;
    vm.listFilter = {};
    Wraps.initData().then(function () {
      vm.getData();
    });

    vm.data = {};

    $scope.sortBy = function (propertyName) {
      vm.reverse = (vm.orderBy === propertyName) ? !vm.reverse : false;
      vm.orderBy = propertyName;
    };

    vm.getData = function () {
      vm.isLoading = true;
      Books.checkForErrors().$promise.then(function (res) {
        vm.data = res;
        _.forEach(res.docErrors, function (err) {
          err.error = '';
          err.bookIdLong = $filter('seven')(err.bookId);
          if (err.workflow_error) {
            err.error = err.workflow_error + '. ';
          }
          if (err.validation_errors && angular.isString(err.validation_errors)) {
            err.validation_errors = angular.fromJson(err.validation_errors.replace(/'/g, '"'));
            err.error = err.error + 'Invalid data in ' + err.validation_errors.map(function (field) {
              return $filter('titlecase')(field);
            }).join(', ') + '.';
          }
          vm.getDoc(err);
        });
        vm.data = res;
        vm.isLoading = false;
      });
    };

    vm.openDoc = function (docId) {
      $state.go('.', {doc: docId});
    };
    vm.closeDoc = function () {
      $state.go('.', {doc: null});
    };
    vm.getDoc = function (doc) {
      var deferred = $q.defer();
      if (doc) {
        doc.docId = parseInt(doc.docId, 10);
        vm.docLoading = true;
        if (doc.workflow_approvalList) {
          // if it's a string: replace single quotes with double-quotes and then parse it
          if (angular.isString(doc.workflow_approvalList)) {
            doc.workflow_approvalList = angular.fromJson(doc.workflow_approvalList.replace(/'/g, '"'));
          }

          // if it's a string: replace single quotes with double-quotes and then parse it
          if (angular.isString(doc.workflow_contentStatuses)) {
            doc.workflow_contentStatuses = angular.fromJson(doc.workflow_contentStatuses.replace(/'/g, '"'));
          }
          _.forEach(doc.workflow_approvalList, function (workflowStage) {
            if (!workflowStage.user || workflowStage.user === '') {
              workflowStage.User = 'All';
            } else {
              workflowStage.User = Wraps.getUserById(workflowStage.user);
              if (workflowStage.User) {
                workflowStage.User = workflowStage.User.firstName + ' ' + workflowStage.User.lastName;
              } else {
                workflowStage.User = 'Unknown user';
              }
            }
            if (!workflowStage.team || workflowStage.team === '') {
              workflowStage.Team = 'All';
            } else {
              workflowStage.Team = Wraps.getTeamById(workflowStage.team).teamName;
            }
            workflowStage.Role = Wraps.getRoleById(workflowStage.role).description;
          });
          doc.lastStage = doc.workflow_approvalList[doc.workflow_approvalListEnd];
        }
        deferred.resolve();
      } else {
        deferred.resolve();
      }
      deferred.promise.then(function () {
        vm.docLoading = false;
      });
    };

    Wraps.fetchedUser.then(function () {
      vm.hasAccess = vm.hasPermissions('viewHealthcheck');
    });

    vm.openDocInLibrary = function (bookId) {
      $state.go('library', {bookId: bookId});
    };

    // table filter info
    if (!vm.bookQuery) {
      vm.bookQuery = {
          limit: 10,
          page: 1
      };
    }

    $scope.$watch('healthcheck.data.errorList.length', function () {
      vm.bookQuery.page = 1;
    }, true);

    $scope.$watch('healthcheck.listFilter', function () {
      vm.bookQuery.page = 1;
    }, true);
  }
  HealthcheckCtrl.$inject = ["$filter", "$q", "$scope", "$state", "Books", "Wraps"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name home
   * @description
   *
   */
  angular
    .module('home', [
      'ui.router'
    ])
	.directive('doughnutChart', doughnutChart);
}());

function doughnutChart() {
  return {
	restrict: 'E',
	templateUrl: 'home/home-doughnut-chart.tpl.html',
	scope: {
      primary: '=',
      total: '=',
      series: '='
	}
  };
}

(function () {
  'use strict';

  angular
    .module('home')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('home', {
        url: '/home',

        views: {
          tooltip: {
              templateUrl: 'tooltip/tooltip.tpl.html',
              controller: 'TooltipCtrl',
              controllerAs: 'tooltip'
          },
          navigation: {
            templateUrl: 'navigation/navigation.tpl.html',
            controller: 'NavigationCtrl',
            controllerAs: 'navigation'
          },
          main: {
            templateUrl: 'home/home.tpl.html',
            controller: 'HomeCtrl',
            controllerAs: 'home'
          },
          footer: {
            templateUrl: 'footer/footer.tpl.html',
            controller: 'FooterCtrl',
            controllerAs: 'footer'
          }
        }
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.controller:HomeCtrl
   *
   * @description
   *
   */
  angular
    .module('home')
    .controller('HomeCtrl', HomeCtrl);

  function HomeCtrl(
    $filter,
    $localStorage,
    $location,
    $q,
    $rootScope,
    $scope,
    clientConfig,
	apiUrl,
    Books,
    Color,
    Roles,
    Teams,
    Users,
    Wraps) {
    var vm = this;
    vm.ctrlName = 'HomeCtrl';
    vm.isLoading = true;
    vm.hasPermissions = Wraps.hasPermissions;


    vm.clientConfig = clientConfig;

    Wraps.searchByBook = {};
    Wraps.searchByBook.id = '';

	// sends user to root if not logged in and trying to access site.
    if (!Users.isAuthenticated()) {
      $location.path('/');
    }

    $scope.$storage = $localStorage;
    vm.apiUrl = apiUrl;

	// console logs where the user clicks
    vm.onClick = function (points, evt) {
      console.log(points, evt);
    };

    vm.openTooltips = function (code) {
      $rootScope.$emit("openTooltip", code);
    };

    vm.getData = function () {
      var actionsDefer = $q.defer();
      var blackAssetsDefer = $q.defer();
      var involvedDefer = $q.defer();
      var statusDefer = $q.defer();
      var promises = {
        actions: actionsDefer.promise,
        blackAssets: blackAssetsDefer.promise,
        involved: involvedDefer.promise,
        status: statusDefer.promise
      };

      Books.getActiveDocumentsByOwner().$promise.then(function (results) {
        vm.activeDocuments = results;
        vm.actionsDoughnutTotal = {value: results.totalActiveDocuments.documents === 0 ? 0.4 : results.totalActiveDocuments.documents, colour: '#ECEFF1'};
        vm.actionsDoughnutSeries = [{value: results.teamActiveDocuments.documents, colour: '#FFC107'}, {value: results.personalActiveDocuments.documents, colour: '#FF5722'}];
        actionsDefer.resolve();
      });

      Books.getBlackAssets().$promise.then(function (results) {
        vm.blackAssets = results;
        vm.blackAssetsSeries = [{value: results.blackAssetsFinProms.blackAssets, colour: '#F44336'}, {value: results.blackAssetsNonFinProms.blackAssets, colour: '#FF9800'}];
        vm.blackAssetsPrimary = {value: results.blackAssetsOwned.blackAssets, colour: '#ECEFF1'};
        vm.blackAssetsTotal = {value: results.totalBlackAssets.blackAssets === 0 ? 0.4 : results.totalBlackAssets.blackAssets, colour: '#ECEFF1'};
        blackAssetsDefer.resolve();
      });

      Books.getDocumentsUserInvolvedIn().$promise.then(function (results) {
        vm.documents = results;
        vm.primaryDocumentsInvolvedIn = {value: vm.documents.totalDocumentsInWorkFlow, colour: '#ECEFF1'};
        vm.totalDocumentsInvolvedIn = {value: vm.documents.totalDocuments.documents === 0 ? 0.4 : vm.documents.totalDocuments.documents, colour: '#ECEFF1'};
        vm.seriesDocumentsInvolvedIn = [{value: vm.documents.totalDocumentsInWorkFlowPersonal, colour: '#3F51B5'}, {value: vm.documents.totalDocumentsInWorkFlowTeam, colour: '#03A9F4'}];
        involvedDefer.resolve();
      });

      Books.getYourDocumentsStats().$promise.then(function (results) {
        vm.yourDocuments = results;
        vm.youDocsDoughnutTotal = {value: results.total === 0 ? 0.4 : results.total, colour: '#ECEFF1'};
        vm.youDocsDoughnutSeries = [
          {value: results.total, colour: '#009688'},
          {value: results.ready, colour: '#673AB7'},
          {value: results.actv, colour: '#8BC34A'},
          {value: results.liveR, colour: '#FFEB3B'},
          {value: results.live, colour: '#9C27B0'},
          {value: results.inWork, colour: '#03A9F4'},
          {value: results.expd, colour: '#FF9800'},
          {value: results.expdF, colour: '#FF5722'}];
        statusDefer.resolve();
      });
      return $q.all(promises);
    };

    vm.getData().then(function () {
      vm.isLoading = false;
    });

    $rootScope.$on('updateHomeStats', function () {
      vm.getData();
    });
  }
  HomeCtrl.$inject = ["$filter", "$localStorage", "$location", "$q", "$rootScope", "$scope", "clientConfig", "apiUrl", "Books", "Color", "Roles", "Teams", "Users", "Wraps"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name footer
   * @description
   *
   */
  angular
    .module('footer', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
    .module('footer')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('footer', {
        url: '/footer',
        templateUrl: 'footer/footer.tpl.html',
        controller: 'FooterCtrl',
        controllerAs: 'footer'
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name footer.controller:FooterCtrl
   *
   * @description
   *
   */
  angular
    .module('footer')
    .controller('FooterCtrl', FooterCtrl);

  function FooterCtrl(
    $document,
    $filter,
    $interval,
    $localStorage,
    $location,
    $mdToast,
    $q,
    $scope,
    AppVersions,
    Wraps) {
    var vm = this;
    vm.ctrlName = 'FooterCtrl';

	//Check for WRAPS app-version updates.
    $interval(function () {
      if (!Wraps.updateToast) {
        AppVersions.findOne({filter: {order: "appVersionId DESC"}}).$promise.then(function (version) {
          if (Wraps.thisAppVersion !== version.appVersionId) {
            Wraps.updateToast = true;
            $mdToast.show(
              $mdToast.simple()
                .textContent('A newer version of WRAPS (' + version.appVersionId + ') is available')
                .hideDelay(0)
                .action('Update')
                .highlightAction(true)
                .highlightClass('md-accent')
                .toastClass('update-toast')
                .position('bottom')
            ).then(function (response) {
              if (response === 'ok') {
                Wraps.reload();
              }
            }).catch(function () {
                Wraps.updateToast = false;
                console.warn('Versions toast closed prematurely due to another toast being opened');
            });
          }
        });
      }
    }, 300000);

    // Displays errors as Material Design "Toast" elements
    $scope.$watch(function () {
      return Wraps.httpErrors;
    }, function () {
      // console.log("httpErrors:",newVal,oldVal);
      if (Wraps.httpErrors) {
        $mdToast.show(
            $mdToast.simple()
                .textContent(Wraps.httpErrors.message)
                .hideDelay(0)
                .action('Close')
                .highlightAction(true)
                .highlightClass('md-accent')
                .toastClass('update-toast')
                .hideDelay(3000)
                .position('bottom')
        ).then(function () {
          Wraps.httpErrors = false;
        }).catch(function () {
            console.warn('Http errors toast closed prematurely due to another toast being opened');
        });
      }
    });

	// Displays messages as Material Design "Toast" elements
    $scope.$watch(function () {
      return Wraps.toastMessages;
    }, function () {
      // console.log("toastMessages:",newVal,oldVal);
      if (Wraps.toastMessages) {
        $mdToast.show(
            $mdToast.simple()
                .textContent(Wraps.toastMessages.message)
                .hideDelay(0)
                .action('Close')
                .highlightAction(true)
                .highlightClass('md-accent')
                .toastClass('update-toast')
                .hideDelay(5000)
                .position('bottom')
        ).then(function () {
          Wraps.toastMessages = false;
        }).catch(function () {
            console.warn('Messages toast closed prematurely due to another toast being opened');
        });
      }
    });
  }
  FooterCtrl.$inject = ["$document", "$filter", "$interval", "$localStorage", "$location", "$mdToast", "$q", "$scope", "AppVersions", "Wraps"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name drive
   * @description
   *
   */
  angular
    .module('drive', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name maintenance.filter:titlecase
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('drive')
    .filter('thumburl', thumburl);

  function thumburl(apiUrl) {
    return function(ngfName) {
        return encodeURI(apiUrl + '/containers/getthumbnail/' + ngfName.replace(/\.[^\.]+$/, '.jpg'));
    };
  }
  thumburl.$inject = ["apiUrl"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name maintenance.filter:titlecase
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('drive')
    .filter('seven', seven);

  function seven() {
    return function (id) {
      return ("0000000" + id).slice(-7);
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name maintenance.filter:titlecase
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('drive')
    .filter('fileurl', fileurl);

  function fileurl(apiUrl) {
    return function(ngfName) {
        return encodeURI(apiUrl + '/containers/getfile/' + ngfName);
    };
  }
  fileurl.$inject = ["apiUrl"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name maintenance.filter:titlecase
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('drive')
    .filter('filesize', filesize);

  function filesize() {
      return function(bytes, decimals) {
          if (bytes === 0) return '0 Bytes';
          var k = 1024,
              dm = decimals <= 0 ? 0 : decimals || 2,
              sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
              i = Math.floor(Math.log(bytes) / Math.log(k));
          return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      }
  }
}());

(function () {
  'use strict';

  angular
    .module('drive')
    .directive('errSrc', checkThumbnail);

  function checkThumbnail() {
    return {
      link: function (scope, element, attrs) {
        element.bind('error', function () {
          if (attrs.src !== attrs.errSrc) {
            attrs.$set('src', attrs.errSrc);
          }
        });

        attrs.$observe('ngSrc', function (value) {
          if (!value && attrs.errSrc) {
            attrs.$set('src', attrs.errSrc);
          }
        });
      }
    };
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name drive.service:Drive
   *
   * @description
   *
   */
  angular
    .module('drive')
    .service('Drive', Drive);

  function Drive($q, Books, DocTypeCats, DocTypes, Insurers, Products, apiUrl) {
    var self = this;

    self.get = function () {
      return 'Drive';
    };
	self.data = {};
	self.data.insurers = {};
	self.data.products = {};
	self.data.categories = {};
	self.data.docTypes = {};
	self.data.docs = [];
	self.data.docDetail = {};

	self.getRows = function (newParams, currentParams) {
		var deferred = $q.defer();
		self.getInsurers().then(function () {
			if (newParams.dtype !== null && newParams.dtype !== undefined) {
				console.log(newParams.dtype, 'dty');
				self.getBooks(newParams.dtype, currentParams).then(function () {
					self.getBook(newParams.doc).then(function () {
						deferred.resolve();
					});
				});
			} else if (newParams.doc !== null && newParams.doc !== undefined) {
                console.log(newParams.doc, 'doc');
				self.getBook(newParams.doc).then(function () {
					deferred.resolve();
				});
			} else {
                console.log(newParams.insurer, newParams.product, newParams.category);
				self.getProducts(newParams.insurer).then(function () {
					self.getCategories(newParams.product, currentParams).then(function () {
						self.getDocTypes(newParams.category, currentParams).then(function () {
							deferred.resolve();
						});
					});
				});
			}
		});
		return deferred.promise;
	}; // end of getRows()

	  self.getBook = function (id) {
		  var bookDeferred = $q.defer();
		  var message = '';
		  var download = {};
		  if (id !== null && id !== undefined) {
			  Books.getDownloadsForDrive({"bookId":id}).$promise.then(function (bookRtn) {
				  self.data.docDetail = {};
                  self.data.docDetail.downloads = [];
                  
                  var downloadDetails = bookRtn[0];
				  // _.forEach(bookRtn, function (downloadDetails) {
				  	message = '';
                    downloadDetails.versionData = angular.fromJson(downloadDetails.versionData);

                    message = '';
                    if (typeof downloadDetails.versionData.artwork !== 'undefined' && downloadDetails.versionData.artwork.filesList.length === 0) {
                    	message = downloadDetails.versionData.artwork.description;
					}

				  	if (typeof downloadDetails.versionData.artwork !== 'undefined' && downloadDetails.versionData.artwork.filesList.length > 0) {

				  		_.forEach(downloadDetails.versionData.artwork.filesList, function (fileData) {
							download = {};
                            download.fileLocation = encodeURI(apiUrl + '/containers/getfile/' + fileData.ngfName);
                            download.thumbLocation = encodeURI(apiUrl + '/containers/getthumbnail/' + fileData.ngfName.replace(/\.[^\.]+$/, '.jpg'));
                            download.fileName = fileData.fileName;
                            download.fileSize = formatBytes(fileData.fileSize);
                            self.data.docDetail.downloads.push(download);
						});

					}

				  if (downloadDetails.versionData.artwork.filesList.length === 0 ) {
					  self.data.docDetail.noDownloads = true;
					  self.data.docDetail.noDownloadsMessage = message;
				  }
                  // });

				  // self.data.docDetail.downloads = [{name:'my first download that is awesome'},{name:'my second download that is just as awesome as the first'}, {name:'my third download that is not awesome'}, {name:'my fourth download'},{name:'my fifth download'},{name:'my sixth download'}, {name:'my seventh download'}]; //dummy data
				  bookDeferred.resolve();
			  });
		  }else{
			  bookDeferred.resolve();
		  }
		  return bookDeferred.promise;
	  };

      function formatBytes(bytes, decimals) {
          if (bytes === 0) return '0 Bytes';
          var k = 1024,
              dm = decimals <= 0 ? 0 : decimals || 2,
              sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
              i = Math.floor(Math.log(bytes) / Math.log(k));
          return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
      }

	  self.getBooks = function(dtype, currentParams){
		  var booksDeferred = $q.defer();
		  if (dtype !== null && dtype !== undefined){
			  Books.getBooksForDrive(currentParams).$promise.then(function(bookRtn){
				  self.data.docs = bookRtn;
				  booksDeferred.resolve();
			  });
		  }else{
			  booksDeferred.resolve();
		  }
		  return booksDeferred.promise;
	  };

	  self.getCategories = function (product, currentParams) {
		  var catsDeferred = $q.defer();
		  if (product !== null && product !== undefined){
			  self.data.categories={};
			  DocTypeCats.getDocTypeCatsAndDocCount(currentParams).$promise.then(function(catRtn){
				  // console.log("category call");
				  _.forEach(catRtn,function(cat){
					  self.data.categories[cat.docTypeCatCode]=cat;
				  });
				  catsDeferred.resolve();
			  });
		  }else{
			  catsDeferred.resolve();
		  }
		  return catsDeferred.promise;
	  };
	  self.getDocTypes = function (cat, currentParams) {
		  var typesDeferred = $q.defer();
		  
		  if (cat !== null && cat !== undefined){
			  self.data.docTypes = {};
			  DocTypes.getDocTypesAndDocCount(currentParams).$promise.then(function(typeRtn){
				  // console.log("doc types call");
				  _.forEach(typeRtn,function(type){
					  self.data.docTypes[type.docTypeCode]=type;
				  });
				  typesDeferred.resolve();
			  });
		  } else {
			  typesDeferred.resolve();
		  }
		  return typesDeferred.promise;
	  };

	  self.getInsurers = function () {
		  var insDeferred = $q.defer();
		  if (_.isEmpty(self.data.insurers)) {
			  Insurers.getInsurersAndDocCount().$promise.then(function(insurerRtn){
				  //console.log("insurers call");
				  _.forEach(insurerRtn,function(insurer){
					  self.data.insurers[insurer.insurerCode]=insurer;
				  });
				  insDeferred.resolve();
			  });
		  } else {
			  // console.log("insurer object already exists: ", _.isEmpty(self.data.insurers));
			  insDeferred.resolve();
		  }
		  return insDeferred.promise;
	  };
	  self.getProducts = function (insurer) {
		  var productDeferred = $q.defer();
		  if (insurer !== null && insurer !== undefined) {
			  Products.getProductsAndDocCount({"insurerCode":insurer}).$promise.then(function(prodRtn){
				  // console.log("products call");
				  self.data.products = {};
				  _.forEach(prodRtn,function(prod){
					  self.data.products[prod.productCode]=prod;
				  });
				  productDeferred.resolve();
			  });
		  } else {
			  productDeferred.resolve();
		  }
		  return productDeferred.promise;
	  };
  }
  Drive.$inject = ["$q", "Books", "DocTypeCats", "DocTypes", "Insurers", "Products", "apiUrl"];
}());

(function () {
  'use strict';

  angular
    .module('drive')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('drive', {
        url: '/drive/:insurer/:product/:category/:dtype/:doc',
		params: {
			insurer: {
				dynamic: true,
				value: null,
				squash: true
			},
			product: {
				dynamic: true,
				value: null,
				squash: true
			},
			category: {
				dynamic: true,
				value: null,
				squash: true
			},
			dtype: {
				dynamic: true,
				value: null,
				squash: true
			},
			doc: {
				dynamic: true,
				value: null,
				squash: true
			}
		},
        views: {
          tooltip: {
              templateUrl: 'tooltip/tooltip.tpl.html',
              controller: 'TooltipCtrl',
              controllerAs: 'tooltip'
          },
          navigation: {
            templateUrl: 'navigation/navigation.tpl.html',
            controller: 'NavigationCtrl',
            controllerAs: 'navigation'
          },
          main: {
            templateUrl: 'drive/drive.tpl.html',
            controller: 'DriveCtrl',
            controllerAs: 'drive'
          },
          footer: {
            templateUrl: 'footer/footer.tpl.html',
            controller: 'FooterCtrl',
            controllerAs: 'footer'
          }
        }
      });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name drive.controller:DriveCtrl
   *
   * @description
   *
   */
  angular
    .module('drive')
    .controller('DriveCtrl', DriveCtrl);

  function DriveCtrl($scope, $state, $timeout, Books, DocTypes, Drive, Wraps) {
    var vm = this;
    vm.ctrlName = 'DriveCtrl';
    vm.hasPermissions = Wraps.hasPermissions;
    vm.hasAccess = null;
    vm.data = Drive.data;
    vm.getBook = function (id) {
      vm.isLoading = true;
      Drive.getBook(id).then(function () {
        vm.isLoading = false;
      });
    };

    // Note this module uses state params to display the folder structure. $stateParams = {category:null,dtype:null}  <== Default
    vm.params = $state.params;

    vm.uiOnParamsChanged = function (newParams) {
      vm.isLoading = true;
      Drive.getRows(newParams, vm.params).then(function () {
        vm.isLoading = false;
      });
    };

    vm.isLoading = true;
    Drive.getRows(vm.params, vm.params).then(function () {
      vm.isLoading = false;
    });

    Wraps.fetchedUser.then(function () {
      vm.hasAccess = vm.hasPermissions('viewDrive');
    });

    $scope.sortBy = function (propertyName) {
      vm.reverse = (vm.orderBy === propertyName) ? !vm.reverse : false;
      vm.orderBy = propertyName;
    };

    vm.toggleInsurer = function (ins) {
      if (ins === vm.params.insurer && vm.params.dtype === null) {
        $state.go('.', {insurer: null, product: null, category: null, dtype: null, doc: null});
        // close active insurer
      } else {
        vm.openInsurer(ins);
      }
    };
    vm.toggleProduct = function (prod) {
      if (prod === vm.params.product && vm.params.dtype === null) {
        $state.go('.', {product: null, category: null, dtype: null, doc: null});
        // close active product
      } else {
        vm.openProduct(prod);
      }
    };
    vm.toggleCategory = function (cat) {
      if (cat === vm.params.category && vm.params.dtype === null) {
        $state.go('.', {category: null, dtype: null, doc: null});
        // close active category
      } else {
        vm.openCategory(cat);
      }
    };

    vm.openInsurer = function (ins) {
      $state.go('.', {insurer: ins, product: null, category: null, dtype: null, doc: null});
      // open insurer
    };
    vm.openProduct = function (prod) {
      $state.go('.', {product: prod, category: null, dtype: null, doc: null});
      // open product
    };
    vm.openCategory = function (cat) {
      $state.go('.', {category: cat, dtype: null, doc: null});
      // open category
    };

    vm.openDocType = function (documentType) {
      $state.go('.', {dtype: documentType, doc: null});
    };
    vm.driveHome = function () {
      $state.go('.', {category: null, dtype: null, doc: null, insurer: null, product: null});
    };
    vm.openDoc = function (docId) {
      $state.go('.', {doc: docId});
    };

    vm.openDocInLibrary = function (bookId) {
      $state.go('library', {bookId: bookId});
    };

    vm.getBook = function (bookId) {
      Drive.getBook(bookId);
    };
  }
  DriveCtrl.$inject = ["$scope", "$state", "$timeout", "Books", "DocTypes", "Drive", "Wraps"];
}());

(function () {
  'use strict';

  /* @ngdoc object
   * @name accessDenied
   * @description
   *
   */
  angular
    .module('accessDenied', [
      'ui.router'
    ]);
}());

(function () {
  'use strict';

  angular
      .module('accessDenied')
      .config(config);

  function config($stateProvider) {
    $stateProvider
        .state('accessDenied', {
            url: '/access-denied?src',
            params: {
                src: {
                    value: null,
                    squash: true
                }
            },
            views: {
                tooltip: {
                    templateUrl: 'tooltip/tooltip.tpl.html',
                    controller: 'TooltipCtrl',
                    controllerAs: 'tooltip'
                },
                navigation: {
                    templateUrl: 'navigation/navigation.tpl.html',
                    controller: 'NavigationCtrl',
                    controllerAs: 'navigation'
                },
                main: {
                    templateUrl: 'access-denied/access-denied.tpl.html',
                    controller: 'AccessDeniedCtrl',
                    controllerAs: 'accessDenied'
                },
                footer: {
                    templateUrl: 'footer/footer.tpl.html',
                    controller: 'FooterCtrl',
                    controllerAs: 'footer'
                }
            }
        });
  }
  config.$inject = ["$stateProvider"];
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accessDenied.controller:AccessDeniedCtrl
   *
   * @description
   *
   */
  angular
    .module('accessDenied')
    .controller('AccessDeniedCtrl', AccessDeniedCtrl);

  function AccessDeniedCtrl($state,clientConfig) {
    var vm = this;
    vm.clientConfig = clientConfig;
    vm.ctrlName = 'AccessDeniedCtrl';
    vm.src = $state.params.src;
  }
  AccessDeniedCtrl.$inject = ["$state", "clientConfig"];
}());

// CommonJS package manager support
if (typeof module !== 'undefined' && typeof exports !== 'undefined' &&
  module.exports === exports) {
  // Export the *name* of this Angular module
  // Sample usage:
  //
  //   import lbServices from './lb-services';
  //   angular.module('app', [lbServices]);
  //
  module.exports = "lbServices";
}

(function(window, angular, undefined) {
  'use strict';

  var urlBase = "/api";
  var authHeader = 'authorization';

  function getHost(url) {
    var m = url.match(/^(?:https?:)?\/\/([^\/]+)/);
    return m ? m[1] : null;
  }

  var urlBaseHost = getHost(urlBase) || location.host;

/**
 * @ngdoc overview
 * @name lbServices
 * @module
 * @description
 *
 * The `lbServices` module provides services for interacting with
 * the models exposed by the LoopBack server via the REST API.
 *
 */
  var module = angular.module("lbServices", ['ngResource']);

/**
 * @ngdoc object
 * @name lbServices.AppVersions
 * @header lbServices.AppVersions
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `AppVersions` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "AppVersions",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/AppVersions/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#create
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/AppVersions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#createMany
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/AppVersions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#upsert
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/AppVersions",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#replaceOrCreate
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/AppVersions/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#upsertWithWhere
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/AppVersions/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#exists
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/AppVersions/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#findById
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/AppVersions/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#replaceById
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/AppVersions/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#find
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/AppVersions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#findOne
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/AppVersions/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#updateAll
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/AppVersions/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#deleteById
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/AppVersions/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#count
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/AppVersions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#prototype$updateAttributes
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AppVersions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/AppVersions/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#createChangeStream
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/AppVersions/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.AppVersions#patchOrCreate
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#updateOrCreate
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#patchOrCreateWithWhere
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#update
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#destroyById
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#removeById
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.AppVersions#patchAttributes
             * @methodOf lbServices.AppVersions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - AppVersions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AppVersions` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.AppVersions#modelName
        * @propertyOf lbServices.AppVersions
        * @description
        * The name of the model represented by this $resource,
        * i.e. `AppVersions`.
        */
        R.modelName = "AppVersions";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Audiences
 * @header lbServices.Audiences
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Audiences` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Audiences",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Audiences/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Audiences#__get__notDeleted
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Queries notDeleted of Audiences.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Audiences/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#__create__notDeleted
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Audiences/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#__delete__notDeleted
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Audiences/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#__count__notDeleted
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Counts notDeleted of Audiences.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Audiences/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#create
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Audiences",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#createMany
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Audiences",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#upsert
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Audiences",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#replaceOrCreate
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Audiences/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#upsertWithWhere
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Audiences/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#exists
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Audiences/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#findById
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Audiences/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#replaceById
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Audiences/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#find
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Audiences",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#findOne
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Audiences/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#updateAll
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Audiences/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#deleteById
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Audiences/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#count
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Audiences/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#prototype$updateAttributes
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Audiences id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Audiences/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Audiences#createChangeStream
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Audiences/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Books.Audiences() instead.
            "::get::Books::Audiences": {
              url: urlBase + "/Books/:id/Audiences",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Audiences#patchOrCreate
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Audiences#updateOrCreate
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Audiences#patchOrCreateWithWhere
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Audiences#update
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Audiences#destroyById
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Audiences#removeById
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Audiences#patchAttributes
             * @methodOf lbServices.Audiences
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Audiences id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Audiences#modelName
        * @propertyOf lbServices.Audiences
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Audiences`.
        */
        R.modelName = "Audiences";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Books
 * @header lbServices.Books
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Books` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Books",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Books/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Books.Audiences() instead.
            "prototype$__get__Audiences": {
              url: urlBase + "/Books/:id/Audiences",
              method: "GET",
            },

            // INTERNAL. Use Books.Clients() instead.
            "prototype$__get__Clients": {
              url: urlBase + "/Books/:id/Clients",
              method: "GET",
            },

            // INTERNAL. Use Books.Distributors() instead.
            "prototype$__get__Distributors": {
              url: urlBase + "/Books/:id/Distributors",
              method: "GET",
            },

            // INTERNAL. Use Books.DocTypes() instead.
            "prototype$__get__DocTypes": {
              url: urlBase + "/Books/:id/DocTypes",
              method: "GET",
            },

            // INTERNAL. Use Books.Enclosures.findById() instead.
            "prototype$__findById__Enclosures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Enclosures/:fk",
              method: "GET",
            },

            // INTERNAL. Use Books.Enclosures.destroyById() instead.
            "prototype$__destroyById__Enclosures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Enclosures/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Enclosures.updateById() instead.
            "prototype$__updateById__Enclosures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Enclosures/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Books.Insurers() instead.
            "prototype$__get__Insurers": {
              url: urlBase + "/Books/:id/Insurers",
              method: "GET",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.findById() instead.
            "prototype$__findById__ProductIdsBookLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/ProductIdsBookLink/:fk",
              method: "GET",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.destroyById() instead.
            "prototype$__destroyById__ProductIdsBookLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/ProductIdsBookLink/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.updateById() instead.
            "prototype$__updateById__ProductIdsBookLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/ProductIdsBookLink/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Books.Products() instead.
            "prototype$__get__Products": {
              url: urlBase + "/Books/:id/Products",
              method: "GET",
            },

            // INTERNAL. Use Books.ProductTypes() instead.
            "prototype$__get__ProductTypes": {
              url: urlBase + "/Books/:id/ProductTypes",
              method: "GET",
            },

            // INTERNAL. Use Books.Projects() instead.
            "prototype$__get__Projects": {
              url: urlBase + "/Books/:id/Projects",
              method: "GET",
            },

            // INTERNAL. Use Books.Regions() instead.
            "prototype$__get__Regions": {
              url: urlBase + "/Books/:id/Regions",
              method: "GET",
            },

            // INTERNAL. Use Books.Children.findById() instead.
            "prototype$__findById__Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Children/:fk",
              method: "GET",
            },

            // INTERNAL. Use Books.Children.destroyById() instead.
            "prototype$__destroyById__Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Children/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Children.updateById() instead.
            "prototype$__updateById__Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Children/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Books.Structures.findById() instead.
            "prototype$__findById__Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Structures/:fk",
              method: "GET",
            },

            // INTERNAL. Use Books.Structures.destroyById() instead.
            "prototype$__destroyById__Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Structures/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Structures.updateById() instead.
            "prototype$__updateById__Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Structures/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Books.SubInsurers() instead.
            "prototype$__get__SubInsurers": {
              url: urlBase + "/Books/:id/SubInsurers",
              method: "GET",
            },

            // INTERNAL. Use Books.Users() instead.
            "prototype$__get__Users": {
              url: urlBase + "/Books/:id/Users",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__get__getStructure
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Queries getStructure of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "__get__getStructure": {
              isArray: true,
              url: urlBase + "/Books/getStructure",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__create__getStructure
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Creates a new instance in getStructure of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "__create__getStructure": {
              url: urlBase + "/Books/getStructure",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__delete__getStructure
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Deletes all getStructure of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__getStructure": {
              url: urlBase + "/Books/getStructure",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__count__getStructure
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Counts getStructure of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__getStructure": {
              url: urlBase + "/Books/getStructure/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__get__getStructureVersions
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Queries getStructureVersions of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "__get__getStructureVersions": {
              isArray: true,
              url: urlBase + "/Books/getStructureVersions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__create__getStructureVersions
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Creates a new instance in getStructureVersions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "__create__getStructureVersions": {
              url: urlBase + "/Books/getStructureVersions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__delete__getStructureVersions
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Deletes all getStructureVersions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__getStructureVersions": {
              url: urlBase + "/Books/getStructureVersions",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__count__getStructureVersions
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Counts getStructureVersions of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__getStructureVersions": {
              url: urlBase + "/Books/getStructureVersions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__get__getStructureVersionsContents
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Queries getStructureVersionsContents of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "__get__getStructureVersionsContents": {
              isArray: true,
              url: urlBase + "/Books/getStructureVersionsContents",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__create__getStructureVersionsContents
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Creates a new instance in getStructureVersionsContents of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "__create__getStructureVersionsContents": {
              url: urlBase + "/Books/getStructureVersionsContents",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__delete__getStructureVersionsContents
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Deletes all getStructureVersionsContents of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__getStructureVersionsContents": {
              url: urlBase + "/Books/getStructureVersionsContents",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__count__getStructureVersionsContents
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Counts getStructureVersionsContents of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__getStructureVersionsContents": {
              url: urlBase + "/Books/getStructureVersionsContents/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__get__getBooks
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Queries getBooks of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "__get__getBooks": {
              isArray: true,
              url: urlBase + "/Books/getBooks",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__create__getBooks
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Creates a new instance in getBooks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "__create__getBooks": {
              url: urlBase + "/Books/getBooks",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__delete__getBooks
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Deletes all getBooks of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__getBooks": {
              url: urlBase + "/Books/getBooks",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#__count__getBooks
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Counts getBooks of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__getBooks": {
              url: urlBase + "/Books/getBooks/count",
              method: "GET",
            },

            // INTERNAL. Use Books.Enclosures() instead.
            "prototype$__get__Enclosures": {
              isArray: true,
              url: urlBase + "/Books/:id/Enclosures",
              method: "GET",
            },

            // INTERNAL. Use Books.Enclosures.create() instead.
            "prototype$__create__Enclosures": {
              url: urlBase + "/Books/:id/Enclosures",
              method: "POST",
            },

            // INTERNAL. Use Books.Enclosures.destroyAll() instead.
            "prototype$__delete__Enclosures": {
              url: urlBase + "/Books/:id/Enclosures",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Enclosures.count() instead.
            "prototype$__count__Enclosures": {
              url: urlBase + "/Books/:id/Enclosures/count",
              method: "GET",
            },

            // INTERNAL. Use Books.ProductIdsBookLink() instead.
            "prototype$__get__ProductIdsBookLink": {
              isArray: true,
              url: urlBase + "/Books/:id/ProductIdsBookLink",
              method: "GET",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.create() instead.
            "prototype$__create__ProductIdsBookLink": {
              url: urlBase + "/Books/:id/ProductIdsBookLink",
              method: "POST",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.destroyAll() instead.
            "prototype$__delete__ProductIdsBookLink": {
              url: urlBase + "/Books/:id/ProductIdsBookLink",
              method: "DELETE",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.count() instead.
            "prototype$__count__ProductIdsBookLink": {
              url: urlBase + "/Books/:id/ProductIdsBookLink/count",
              method: "GET",
            },

            // INTERNAL. Use Books.Children() instead.
            "prototype$__get__Children": {
              isArray: true,
              url: urlBase + "/Books/:id/Children",
              method: "GET",
            },

            // INTERNAL. Use Books.Children.create() instead.
            "prototype$__create__Children": {
              url: urlBase + "/Books/:id/Children",
              method: "POST",
            },

            // INTERNAL. Use Books.Children.destroyAll() instead.
            "prototype$__delete__Children": {
              url: urlBase + "/Books/:id/Children",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Children.count() instead.
            "prototype$__count__Children": {
              url: urlBase + "/Books/:id/Children/count",
              method: "GET",
            },

            // INTERNAL. Use Books.Structures() instead.
            "prototype$__get__Structures": {
              isArray: true,
              url: urlBase + "/Books/:id/Structures",
              method: "GET",
            },

            // INTERNAL. Use Books.Structures.create() instead.
            "prototype$__create__Structures": {
              url: urlBase + "/Books/:id/Structures",
              method: "POST",
            },

            // INTERNAL. Use Books.Structures.destroyAll() instead.
            "prototype$__delete__Structures": {
              url: urlBase + "/Books/:id/Structures",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Structures.count() instead.
            "prototype$__count__Structures": {
              url: urlBase + "/Books/:id/Structures/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#create
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Books",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#createMany
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Books",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#upsert
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Books",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#replaceOrCreate
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Books/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#upsertWithWhere
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Books/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#exists
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Books/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#findById
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Books/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#replaceById
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Books/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#find
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Books",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#findOne
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Books/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#updateAll
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Books/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#deleteById
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Books/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#count
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Books/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#prototype$updateAttributes
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Books/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#createChangeStream
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Books/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getLibrarySearch
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Get Library Search
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getLibrarySearch": {
              url: urlBase + "/Books/getLibrarySearch",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#checkForErrors
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Check all documents for errors
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "checkForErrors": {
              url: urlBase + "/Books/checkForErrors",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#sendExpired
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Send email for expired books
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "sendExpired": {
              isArray: true,
              url: urlBase + "/Books/sendExpired",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getSearchOverview
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Get list of books in search with count
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getSearchOverview": {
              isArray: true,
              url: urlBase + "/Books/getSearchOverview",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getSearchByBook
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Get list of books in search with count
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getSearchByBook": {
              isArray: true,
              url: urlBase + "/Books/getSearchByBook",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#trash
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Set books to deleted
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "trash": {
              url: urlBase + "/Books/trash",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#requestDeletion
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Request Document gets deleted
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "requestDeletion": {
              url: urlBase + "/Books/requestDeletion",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#setBookStatuses
             * @methodOf lbServices.Books
             *
             * @description
             *
             * check and set book status
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "setBookStatuses": {
              isArray: true,
              url: urlBase + "/Books/setBookStatuses",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getNotifications
             * @methodOf lbServices.Books
             *
             * @description
             *
             * get black assets by owner
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getNotifications": {
              url: urlBase + "/Books/getNotifications",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getActiveDocumentsByOwner
             * @methodOf lbServices.Books
             *
             * @description
             *
             * get black assets by owner
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getActiveDocumentsByOwner": {
              url: urlBase + "/Books/getActiveDocumentsByOwner",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getBlackAssets
             * @methodOf lbServices.Books
             *
             * @description
             *
             * get black assets by owner
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getBlackAssets": {
              url: urlBase + "/Books/getBlackAssets",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getDocumentsUserInvolvedIn
             * @methodOf lbServices.Books
             *
             * @description
             *
             * get black assets by owner
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getDocumentsUserInvolvedIn": {
              url: urlBase + "/Books/getDocumentsUserInvolvedIn",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getInCycleDocuments
             * @methodOf lbServices.Books
             *
             * @description
             *
             * get black assets by owner
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getInCycleDocuments": {
              url: urlBase + "/Books/getInCycleDocuments",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getFulfilment
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Get list of books for fulfilment
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getFulfilment": {
              url: urlBase + "/Books/getFulfilment",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getYourDocumentsStats
             * @methodOf lbServices.Books
             *
             * @description
             *
             * get documents by owner
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getYourDocumentsStats": {
              url: urlBase + "/Books/getYourDocumentsStats",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getBooksForDrive
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Get a book for drive with params
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getBooksForDrive": {
              isArray: true,
              url: urlBase + "/Books/getBooksForDrive",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getDownloadsForDrive
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Get a download paths for drive with book id
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getDownloadsForDrive": {
              isArray: true,
              url: urlBase + "/Books/getDownloadsForDrive",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getUpdatedBookIds
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Get ids of books that have been updated
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getUpdatedBookIds": {
              url: urlBase + "/Books/getUpdatedBookIds",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#generateDocumentCode
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Auto generates a document code
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "generateDocumentCode": {
              isArray: true,
              url: urlBase + "/Books/generateDocumentCode",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#fixProductionDoubleEncoding
             * @methodOf lbServices.Books
             *
             * @description
             *
             * normalise double-encoded data in production, fullfilment, inSitu, digital and support fields
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "fixProductionDoubleEncoding": {
              isArray: true,
              url: urlBase + "/Books/fixProductionDoubleEncoding",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#fixMultiEscaping
             * @methodOf lbServices.Books
             *
             * @description
             *
             * normalise over-escaped data in levels field
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "fixMultiEscaping": {
              isArray: true,
              url: urlBase + "/Books/fixMultiEscaping",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Books#getBookCodesBySearch
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Get codes by book search
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
            "getBookCodesBySearch": {
              url: urlBase + "/Books/getBookCodesBySearch",
              method: "POST",
            },

            // INTERNAL. Use DocTypes.Books.findById() instead.
            "::findById::DocTypes::Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypes/:id/Books/:fk",
              method: "GET",
            },

            // INTERNAL. Use DocTypes.Books.destroyById() instead.
            "::destroyById::DocTypes::Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypes/:id/Books/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use DocTypes.Books.updateById() instead.
            "::updateById::DocTypes::Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypes/:id/Books/:fk",
              method: "PUT",
            },

            // INTERNAL. Use DocTypes.Books() instead.
            "::get::DocTypes::Books": {
              isArray: true,
              url: urlBase + "/DocTypes/:id/Books",
              method: "GET",
            },

            // INTERNAL. Use DocTypes.Books.create() instead.
            "::create::DocTypes::Books": {
              url: urlBase + "/DocTypes/:id/Books",
              method: "POST",
            },

            // INTERNAL. Use DocTypes.Books.createMany() instead.
            "::createMany::DocTypes::Books": {
              isArray: true,
              url: urlBase + "/DocTypes/:id/Books",
              method: "POST",
            },

            // INTERNAL. Use DocTypes.Books.destroyAll() instead.
            "::delete::DocTypes::Books": {
              url: urlBase + "/DocTypes/:id/Books",
              method: "DELETE",
            },

            // INTERNAL. Use DocTypes.Books.count() instead.
            "::count::DocTypes::Books": {
              url: urlBase + "/DocTypes/:id/Books/count",
              method: "GET",
            },

            // INTERNAL. Use Structures.Books() instead.
            "::get::Structures::Books": {
              url: urlBase + "/Structures/:id/Books",
              method: "GET",
            },

            // INTERNAL. Use Users.Books.findById() instead.
            "::findById::Users::Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Books/:fk",
              method: "GET",
            },

            // INTERNAL. Use Users.Books.destroyById() instead.
            "::destroyById::Users::Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Books/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Users.Books.updateById() instead.
            "::updateById::Users::Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Books/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Users.Books() instead.
            "::get::Users::Books": {
              isArray: true,
              url: urlBase + "/Users/:id/Books",
              method: "GET",
            },

            // INTERNAL. Use Users.Books.create() instead.
            "::create::Users::Books": {
              url: urlBase + "/Users/:id/Books",
              method: "POST",
            },

            // INTERNAL. Use Users.Books.createMany() instead.
            "::createMany::Users::Books": {
              isArray: true,
              url: urlBase + "/Users/:id/Books",
              method: "POST",
            },

            // INTERNAL. Use Users.Books.destroyAll() instead.
            "::delete::Users::Books": {
              url: urlBase + "/Users/:id/Books",
              method: "DELETE",
            },

            // INTERNAL. Use Users.Books.count() instead.
            "::count::Users::Books": {
              url: urlBase + "/Users/:id/Books/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Books#patchOrCreate
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Books#updateOrCreate
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - Model instance data
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Books#patchOrCreateWithWhere
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Books#update
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Books#destroyById
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Books#removeById
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Books#patchAttributes
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` - An object of model property name/value pairs
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Books#modelName
        * @propertyOf lbServices.Books
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Books`.
        */
        R.modelName = "Books";


            /**
             * @ngdoc method
             * @name lbServices.Books#Audiences
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation Audiences.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Audiences` object.)
             * </em>
             */
        R.Audiences = function() {
          var TargetResource = $injector.get("Audiences");
          var action = TargetResource["::get::Books::Audiences"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books#Clients
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation Clients.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
        R.Clients = function() {
          var TargetResource = $injector.get("Clients");
          var action = TargetResource["::get::Books::Clients"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books#Distributors
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation Distributors.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
        R.Distributors = function() {
          var TargetResource = $injector.get("Distributors");
          var action = TargetResource["::get::Books::Distributors"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books#DocTypes
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation DocTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R.DocTypes = function() {
          var TargetResource = $injector.get("DocTypes");
          var action = TargetResource["::get::Books::DocTypes"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Books.Enclosures
     * @header lbServices.Books.Enclosures
     * @object
     * @description
     *
     * The object `Books.Enclosures` groups methods
     * manipulating `Enclosures` instances related to `Books`.
     *
     * Call {@link lbServices.Books#Enclosures Books.Enclosures()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Books#Enclosures
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Queries Enclosures of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R.Enclosures = function() {
          var TargetResource = $injector.get("Enclosures");
          var action = TargetResource["::get::Books::Enclosures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Enclosures#count
             * @methodOf lbServices.Books.Enclosures
             *
             * @description
             *
             * Counts Enclosures of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Enclosures.count = function() {
          var TargetResource = $injector.get("Enclosures");
          var action = TargetResource["::count::Books::Enclosures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Enclosures#create
             * @methodOf lbServices.Books.Enclosures
             *
             * @description
             *
             * Creates a new instance in Enclosures of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R.Enclosures.create = function() {
          var TargetResource = $injector.get("Enclosures");
          var action = TargetResource["::create::Books::Enclosures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Enclosures#createMany
             * @methodOf lbServices.Books.Enclosures
             *
             * @description
             *
             * Creates a new instance in Enclosures of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R.Enclosures.createMany = function() {
          var TargetResource = $injector.get("Enclosures");
          var action = TargetResource["::createMany::Books::Enclosures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Enclosures#destroyAll
             * @methodOf lbServices.Books.Enclosures
             *
             * @description
             *
             * Deletes all Enclosures of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Enclosures.destroyAll = function() {
          var TargetResource = $injector.get("Enclosures");
          var action = TargetResource["::delete::Books::Enclosures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Enclosures#destroyById
             * @methodOf lbServices.Books.Enclosures
             *
             * @description
             *
             * Delete a related item by id for Enclosures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for Enclosures
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Enclosures.destroyById = function() {
          var TargetResource = $injector.get("Enclosures");
          var action = TargetResource["::destroyById::Books::Enclosures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Enclosures#findById
             * @methodOf lbServices.Books.Enclosures
             *
             * @description
             *
             * Find a related item by id for Enclosures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for Enclosures
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R.Enclosures.findById = function() {
          var TargetResource = $injector.get("Enclosures");
          var action = TargetResource["::findById::Books::Enclosures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Enclosures#updateById
             * @methodOf lbServices.Books.Enclosures
             *
             * @description
             *
             * Update a related item by id for Enclosures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `fk` – `{*}` - Foreign key for Enclosures
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R.Enclosures.updateById = function() {
          var TargetResource = $injector.get("Enclosures");
          var action = TargetResource["::updateById::Books::Enclosures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books#Insurers
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation Insurers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
        R.Insurers = function() {
          var TargetResource = $injector.get("Insurers");
          var action = TargetResource["::get::Books::Insurers"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Books.ProductIdsBookLink
     * @header lbServices.Books.ProductIdsBookLink
     * @object
     * @description
     *
     * The object `Books.ProductIdsBookLink` groups methods
     * manipulating `ProductIdsBookLink` instances related to `Books`.
     *
     * Call {@link lbServices.Books#ProductIdsBookLink Books.ProductIdsBookLink()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Books#ProductIdsBookLink
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Queries ProductIdsBookLink of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R.ProductIdsBookLink = function() {
          var TargetResource = $injector.get("ProductIdsBookLink");
          var action = TargetResource["::get::Books::ProductIdsBookLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.ProductIdsBookLink#count
             * @methodOf lbServices.Books.ProductIdsBookLink
             *
             * @description
             *
             * Counts ProductIdsBookLink of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.ProductIdsBookLink.count = function() {
          var TargetResource = $injector.get("ProductIdsBookLink");
          var action = TargetResource["::count::Books::ProductIdsBookLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.ProductIdsBookLink#create
             * @methodOf lbServices.Books.ProductIdsBookLink
             *
             * @description
             *
             * Creates a new instance in ProductIdsBookLink of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R.ProductIdsBookLink.create = function() {
          var TargetResource = $injector.get("ProductIdsBookLink");
          var action = TargetResource["::create::Books::ProductIdsBookLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.ProductIdsBookLink#createMany
             * @methodOf lbServices.Books.ProductIdsBookLink
             *
             * @description
             *
             * Creates a new instance in ProductIdsBookLink of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R.ProductIdsBookLink.createMany = function() {
          var TargetResource = $injector.get("ProductIdsBookLink");
          var action = TargetResource["::createMany::Books::ProductIdsBookLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.ProductIdsBookLink#destroyAll
             * @methodOf lbServices.Books.ProductIdsBookLink
             *
             * @description
             *
             * Deletes all ProductIdsBookLink of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.ProductIdsBookLink.destroyAll = function() {
          var TargetResource = $injector.get("ProductIdsBookLink");
          var action = TargetResource["::delete::Books::ProductIdsBookLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.ProductIdsBookLink#destroyById
             * @methodOf lbServices.Books.ProductIdsBookLink
             *
             * @description
             *
             * Delete a related item by id for ProductIdsBookLink.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for ProductIdsBookLink
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.ProductIdsBookLink.destroyById = function() {
          var TargetResource = $injector.get("ProductIdsBookLink");
          var action = TargetResource["::destroyById::Books::ProductIdsBookLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.ProductIdsBookLink#findById
             * @methodOf lbServices.Books.ProductIdsBookLink
             *
             * @description
             *
             * Find a related item by id for ProductIdsBookLink.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for ProductIdsBookLink
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R.ProductIdsBookLink.findById = function() {
          var TargetResource = $injector.get("ProductIdsBookLink");
          var action = TargetResource["::findById::Books::ProductIdsBookLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.ProductIdsBookLink#updateById
             * @methodOf lbServices.Books.ProductIdsBookLink
             *
             * @description
             *
             * Update a related item by id for ProductIdsBookLink.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `fk` – `{*}` - Foreign key for ProductIdsBookLink
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R.ProductIdsBookLink.updateById = function() {
          var TargetResource = $injector.get("ProductIdsBookLink");
          var action = TargetResource["::updateById::Books::ProductIdsBookLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books#Products
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation Products.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
        R.Products = function() {
          var TargetResource = $injector.get("Products");
          var action = TargetResource["::get::Books::Products"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books#ProductTypes
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation ProductTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
        R.ProductTypes = function() {
          var TargetResource = $injector.get("ProductTypes");
          var action = TargetResource["::get::Books::ProductTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books#Projects
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation Projects.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
        R.Projects = function() {
          var TargetResource = $injector.get("Projects");
          var action = TargetResource["::get::Books::Projects"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books#Regions
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation Regions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
        R.Regions = function() {
          var TargetResource = $injector.get("Regions");
          var action = TargetResource["::get::Books::Regions"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Books.Children
     * @header lbServices.Books.Children
     * @object
     * @description
     *
     * The object `Books.Children` groups methods
     * manipulating `Structures` instances related to `Books`.
     *
     * Call {@link lbServices.Books#Children Books.Children()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Books#Children
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Queries Children of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Children = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::get::Books::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Children#count
             * @methodOf lbServices.Books.Children
             *
             * @description
             *
             * Counts Children of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Children.count = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::count::Books::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Children#create
             * @methodOf lbServices.Books.Children
             *
             * @description
             *
             * Creates a new instance in Children of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Children.create = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::create::Books::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Children#createMany
             * @methodOf lbServices.Books.Children
             *
             * @description
             *
             * Creates a new instance in Children of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Children.createMany = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::createMany::Books::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Children#destroyAll
             * @methodOf lbServices.Books.Children
             *
             * @description
             *
             * Deletes all Children of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Children.destroyAll = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::delete::Books::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Children#destroyById
             * @methodOf lbServices.Books.Children
             *
             * @description
             *
             * Delete a related item by id for Children.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for Children
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Children.destroyById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::destroyById::Books::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Children#findById
             * @methodOf lbServices.Books.Children
             *
             * @description
             *
             * Find a related item by id for Children.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for Children
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Children.findById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::findById::Books::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Children#updateById
             * @methodOf lbServices.Books.Children
             *
             * @description
             *
             * Update a related item by id for Children.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `fk` – `{*}` - Foreign key for Children
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Children.updateById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::updateById::Books::Children"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Books.Structures
     * @header lbServices.Books.Structures
     * @object
     * @description
     *
     * The object `Books.Structures` groups methods
     * manipulating `Structures` instances related to `Books`.
     *
     * Call {@link lbServices.Books#Structures Books.Structures()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Books#Structures
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Queries Structures of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `filter` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::get::Books::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Structures#count
             * @methodOf lbServices.Books.Structures
             *
             * @description
             *
             * Counts Structures of Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Structures.count = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::count::Books::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Structures#create
             * @methodOf lbServices.Books.Structures
             *
             * @description
             *
             * Creates a new instance in Structures of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures.create = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::create::Books::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Structures#createMany
             * @methodOf lbServices.Books.Structures
             *
             * @description
             *
             * Creates a new instance in Structures of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures.createMany = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::createMany::Books::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Structures#destroyAll
             * @methodOf lbServices.Books.Structures
             *
             * @description
             *
             * Deletes all Structures of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `where` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Structures.destroyAll = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::delete::Books::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Structures#destroyById
             * @methodOf lbServices.Books.Structures
             *
             * @description
             *
             * Delete a related item by id for Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for Structures
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Structures.destroyById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::destroyById::Books::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Structures#findById
             * @methodOf lbServices.Books.Structures
             *
             * @description
             *
             * Find a related item by id for Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `fk` – `{*}` - Foreign key for Structures
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures.findById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::findById::Books::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books.Structures#updateById
             * @methodOf lbServices.Books.Structures
             *
             * @description
             *
             * Update a related item by id for Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `fk` – `{*}` - Foreign key for Structures
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             *  - `data` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures.updateById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::updateById::Books::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books#SubInsurers
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation SubInsurers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
        R.SubInsurers = function() {
          var TargetResource = $injector.get("SubInsurers");
          var action = TargetResource["::get::Books::SubInsurers"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Books#Users
             * @methodOf lbServices.Books
             *
             * @description
             *
             * Fetches belongsTo relation Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Books id
             *
             *  - `options` – `{object=}` -
             *
             *  - `refresh` – `{boolean=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::get::Books::Users"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.BooksVersions
 * @header lbServices.BooksVersions
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `BooksVersions` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "BooksVersions",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/BooksVersions/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#create
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/BooksVersions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#createMany
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/BooksVersions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#upsert
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/BooksVersions",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#replaceOrCreate
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/BooksVersions/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#upsertWithWhere
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/BooksVersions/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#exists
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/BooksVersions/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#findById
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/BooksVersions/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#replaceById
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/BooksVersions/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#find
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/BooksVersions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#findOne
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/BooksVersions/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#updateAll
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/BooksVersions/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#deleteById
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/BooksVersions/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#count
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/BooksVersions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#prototype$updateAttributes
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BooksVersions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/BooksVersions/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#createChangeStream
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/BooksVersions/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#patchOrCreate
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#updateOrCreate
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#patchOrCreateWithWhere
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#update
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#destroyById
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#removeById
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.BooksVersions#patchAttributes
             * @methodOf lbServices.BooksVersions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - BooksVersions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `BooksVersions` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.BooksVersions#modelName
        * @propertyOf lbServices.BooksVersions
        * @description
        * The name of the model represented by this $resource,
        * i.e. `BooksVersions`.
        */
        R.modelName = "BooksVersions";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Clients
 * @header lbServices.Clients
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Clients` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Clients",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Clients/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Clients.Teams() instead.
            "prototype$__get__Teams": {
              url: urlBase + "/Clients/:id/Teams",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#__get__notDeleted
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Queries notDeleted of Clients.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Clients/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#__create__notDeleted
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Clients/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#__delete__notDeleted
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Clients/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#__count__notDeleted
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Counts notDeleted of Clients.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Clients/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#create
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Clients",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#createMany
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Clients",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#upsert
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Clients",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#replaceOrCreate
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Clients/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#upsertWithWhere
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Clients/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#exists
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Clients/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#findById
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Clients/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#replaceById
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Clients/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#find
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Clients",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#findOne
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Clients/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#updateAll
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Clients/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#deleteById
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Clients/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#count
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Clients/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#prototype$updateAttributes
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Clients id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Clients/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Clients#createChangeStream
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Clients/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Books.Clients() instead.
            "::get::Books::Clients": {
              url: urlBase + "/Books/:id/Clients",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Clients#patchOrCreate
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Clients#updateOrCreate
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Clients#patchOrCreateWithWhere
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Clients#update
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Clients#destroyById
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Clients#removeById
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Clients#patchAttributes
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Clients id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Clients` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Clients#modelName
        * @propertyOf lbServices.Clients
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Clients`.
        */
        R.modelName = "Clients";


            /**
             * @ngdoc method
             * @name lbServices.Clients#Teams
             * @methodOf lbServices.Clients
             *
             * @description
             *
             * Fetches belongsTo relation Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Clients id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R.Teams = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::get::Clients::Teams"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Color
 * @header lbServices.Color
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Color` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Color",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Colors/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Color#create
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Colors",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#createMany
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Colors",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#upsert
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Colors",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#replaceOrCreate
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Colors/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#upsertWithWhere
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Colors/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#exists
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Colors/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#findById
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Colors/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#replaceById
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Colors/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#find
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Colors",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#findOne
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Colors/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#updateAll
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Colors/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#deleteById
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Colors/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#count
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Colors/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#prototype$updateAttributes
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Color id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Colors/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#createChangeStream
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Colors/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Color#upvote
             * @methodOf lbServices.Color
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `id` – `{number=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "upvote": {
              url: urlBase + "/Colors/upvote",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Color#patchOrCreate
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Color#updateOrCreate
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Color#patchOrCreateWithWhere
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Color#update
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Color#destroyById
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Color#removeById
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Color#patchAttributes
             * @methodOf lbServices.Color
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Color id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Color` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Color#modelName
        * @propertyOf lbServices.Color
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Color`.
        */
        R.modelName = "Color";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Containers
 * @header lbServices.Containers
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Containers` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Containers",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Containers/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Containers#getContainers
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Containers` object.)
             * </em>
             */
            "getContainers": {
              isArray: true,
              url: urlBase + "/Containers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#createContainer
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Containers` object.)
             * </em>
             */
            "createContainer": {
              url: urlBase + "/Containers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#destroyContainer
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "destroyContainer": {
              url: urlBase + "/Containers/:container",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#getContainer
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Containers` object.)
             * </em>
             */
            "getContainer": {
              url: urlBase + "/Containers/:container",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#getFiles
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Containers` object.)
             * </em>
             */
            "getFiles": {
              isArray: true,
              url: urlBase + "/Containers/:container/files",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#getFile
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` -
             *
             *  - `file` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Containers` object.)
             * </em>
             */
            "getFile": {
              url: urlBase + "/Containers/:container/files/:file",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#removeFile
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` -
             *
             *  - `file` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "removeFile": {
              url: urlBase + "/Containers/:container/files/:file",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#upload
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `result` – `{object=}` -
             */
            "upload": {
              url: urlBase + "/Containers/:container/upload",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#download
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `container` – `{string=}` -
             *
             *  - `file` – `{string=}` -
             *
             *  - `req` – `{object=}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "download": {
              url: urlBase + "/Containers/:container/download/:file",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#test1
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * test1
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Containers` object.)
             * </em>
             */
            "test1": {
              isArray: true,
              url: urlBase + "/Containers/test1",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#prepareFinalPDF
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * Upload Doc to S3
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Containers` object.)
             * </em>
             */
            "prepareFinalPDF": {
              isArray: true,
              url: urlBase + "/Containers/prepareFinalPDF",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#uploadFinalPDF
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * Upload Doc to S3
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Containers` object.)
             * </em>
             */
            "uploadFinalPDF": {
              isArray: true,
              url: urlBase + "/Containers/uploadFinalPDF",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#downloadTest
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * Download Doc from S3
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `args` – `{object}` - args
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Containers` object.)
             * </em>
             */
            "downloadTest": {
              isArray: true,
              url: urlBase + "/Containers/downloadTest",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#getfile
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `file1` – `{string}` -
             *
             *  - `file2` – `{string}` -
             *
             *  - `file3` – `{string}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "getfile": {
              url: urlBase + "/Containers/getfile/:file1/:file2/:file3",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#getFulfilmentFile
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `args` – `{object=}` - args
             *
             *  - `file1` – `{string}` -
             *
             *  - `file2` – `{string}` -
             *
             *  - `file3` – `{string}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "getFulfilmentFile": {
              url: urlBase + "/Containers/getFulfilmentFile/:file1/:file2/:file3",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#getthumbnail
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `file1` – `{string}` -
             *
             *  - `file2` – `{string}` -
             *
             *  - `file3` – `{string}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "getthumbnail": {
              url: urlBase + "/Containers/getthumbnail/:file1/:file2/:file3",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#dlrq
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Containers` object.)
             * </em>
             */
            "dlrq": {
              isArray: true,
              url: urlBase + "/Containers/dlrq/",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Containers#getAuthFile
             * @methodOf lbServices.Containers
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `file` – `{string}` -
             *
             *  - `token` – `{string}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "getAuthFile": {
              url: urlBase + "/Containers/getAuthFile/:file",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.Containers#modelName
        * @propertyOf lbServices.Containers
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Containers`.
        */
        R.modelName = "Containers";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Contents
 * @header lbServices.Contents
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Contents` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Contents",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Contents/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Contents.ContentTypes() instead.
            "prototype$__get__ContentTypes": {
              url: urlBase + "/Contents/:id/ContentTypes",
              method: "GET",
            },

            // INTERNAL. Use Contents.Teams() instead.
            "prototype$__get__Teams": {
              url: urlBase + "/Contents/:id/Teams",
              method: "GET",
            },

            // INTERNAL. Use Contents.Versions.findById() instead.
            "prototype$__findById__Versions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Contents/:id/Versions/:fk",
              method: "GET",
            },

            // INTERNAL. Use Contents.Versions.destroyById() instead.
            "prototype$__destroyById__Versions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Contents/:id/Versions/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Contents.Versions.updateById() instead.
            "prototype$__updateById__Versions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Contents/:id/Versions/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#__get__ContentType
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Queries ContentType of Contents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "__get__ContentType": {
              isArray: true,
              url: urlBase + "/Contents/ContentType",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#__create__ContentType
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Creates a new instance in ContentType of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "__create__ContentType": {
              url: urlBase + "/Contents/ContentType",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#__delete__ContentType
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Deletes all ContentType of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__ContentType": {
              url: urlBase + "/Contents/ContentType",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#__count__ContentType
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Counts ContentType of Contents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__ContentType": {
              url: urlBase + "/Contents/ContentType/count",
              method: "GET",
            },

            // INTERNAL. Use Contents.Versions() instead.
            "prototype$__get__Versions": {
              isArray: true,
              url: urlBase + "/Contents/:id/Versions",
              method: "GET",
            },

            // INTERNAL. Use Contents.Versions.create() instead.
            "prototype$__create__Versions": {
              url: urlBase + "/Contents/:id/Versions",
              method: "POST",
            },

            // INTERNAL. Use Contents.Versions.destroyAll() instead.
            "prototype$__delete__Versions": {
              url: urlBase + "/Contents/:id/Versions",
              method: "DELETE",
            },

            // INTERNAL. Use Contents.Versions.count() instead.
            "prototype$__count__Versions": {
              url: urlBase + "/Contents/:id/Versions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#create
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Contents",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#createMany
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Contents",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#upsert
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Contents",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#replaceOrCreate
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Contents/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#upsertWithWhere
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Contents/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#exists
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Contents/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#findById
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Contents/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#replaceById
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Contents/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#find
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Contents",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#findOne
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Contents/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#updateAll
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Contents/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#deleteById
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Contents/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#count
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Contents/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#prototype$updateAttributes
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Contents/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Contents#createChangeStream
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Contents/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Events.Contents() instead.
            "::get::Events::Contents": {
              url: urlBase + "/Events/:id/Contents",
              method: "GET",
            },

            // INTERNAL. Use Structures.Contents() instead.
            "::get::Structures::Contents": {
              url: urlBase + "/Structures/:id/Contents",
              method: "GET",
            },

            // INTERNAL. Use Teams.Contents.findById() instead.
            "::findById::Teams::Contents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Contents/:fk",
              method: "GET",
            },

            // INTERNAL. Use Teams.Contents.destroyById() instead.
            "::destroyById::Teams::Contents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Contents/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Teams.Contents.updateById() instead.
            "::updateById::Teams::Contents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Contents/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Teams.Contents() instead.
            "::get::Teams::Contents": {
              isArray: true,
              url: urlBase + "/Teams/:id/Contents",
              method: "GET",
            },

            // INTERNAL. Use Teams.Contents.create() instead.
            "::create::Teams::Contents": {
              url: urlBase + "/Teams/:id/Contents",
              method: "POST",
            },

            // INTERNAL. Use Teams.Contents.createMany() instead.
            "::createMany::Teams::Contents": {
              isArray: true,
              url: urlBase + "/Teams/:id/Contents",
              method: "POST",
            },

            // INTERNAL. Use Teams.Contents.destroyAll() instead.
            "::delete::Teams::Contents": {
              url: urlBase + "/Teams/:id/Contents",
              method: "DELETE",
            },

            // INTERNAL. Use Teams.Contents.count() instead.
            "::count::Teams::Contents": {
              url: urlBase + "/Teams/:id/Contents/count",
              method: "GET",
            },

            // INTERNAL. Use Versions.Contents() instead.
            "::get::Versions::Contents": {
              url: urlBase + "/Versions/:id/Contents",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Contents#patchOrCreate
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Contents#updateOrCreate
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Contents#patchOrCreateWithWhere
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Contents#update
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Contents#destroyById
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Contents#removeById
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Contents#patchAttributes
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Contents#modelName
        * @propertyOf lbServices.Contents
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Contents`.
        */
        R.modelName = "Contents";


            /**
             * @ngdoc method
             * @name lbServices.Contents#ContentTypes
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Fetches belongsTo relation ContentTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
        R.ContentTypes = function() {
          var TargetResource = $injector.get("ContentTypes");
          var action = TargetResource["::get::Contents::ContentTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Contents#Teams
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Fetches belongsTo relation Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R.Teams = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::get::Contents::Teams"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Contents.Versions
     * @header lbServices.Contents.Versions
     * @object
     * @description
     *
     * The object `Contents.Versions` groups methods
     * manipulating `Versions` instances related to `Contents`.
     *
     * Call {@link lbServices.Contents#Versions Contents.Versions()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Contents#Versions
             * @methodOf lbServices.Contents
             *
             * @description
             *
             * Queries Versions of Contents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R.Versions = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::get::Contents::Versions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Contents.Versions#count
             * @methodOf lbServices.Contents.Versions
             *
             * @description
             *
             * Counts Versions of Contents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Versions.count = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::count::Contents::Versions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Contents.Versions#create
             * @methodOf lbServices.Contents.Versions
             *
             * @description
             *
             * Creates a new instance in Versions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R.Versions.create = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::create::Contents::Versions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Contents.Versions#createMany
             * @methodOf lbServices.Contents.Versions
             *
             * @description
             *
             * Creates a new instance in Versions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R.Versions.createMany = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::createMany::Contents::Versions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Contents.Versions#destroyAll
             * @methodOf lbServices.Contents.Versions
             *
             * @description
             *
             * Deletes all Versions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Versions.destroyAll = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::delete::Contents::Versions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Contents.Versions#destroyById
             * @methodOf lbServices.Contents.Versions
             *
             * @description
             *
             * Delete a related item by id for Versions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             *  - `fk` – `{*}` - Foreign key for Versions
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Versions.destroyById = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::destroyById::Contents::Versions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Contents.Versions#findById
             * @methodOf lbServices.Contents.Versions
             *
             * @description
             *
             * Find a related item by id for Versions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             *  - `fk` – `{*}` - Foreign key for Versions
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R.Versions.findById = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::findById::Contents::Versions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Contents.Versions#updateById
             * @methodOf lbServices.Contents.Versions
             *
             * @description
             *
             * Update a related item by id for Versions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Contents id
             *
             *  - `fk` – `{*}` - Foreign key for Versions
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R.Versions.updateById = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::updateById::Contents::Versions"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ContentTypes
 * @header lbServices.ContentTypes
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ContentTypes` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ContentTypes",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ContentTypes/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#create
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ContentTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#createMany
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ContentTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#upsert
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/ContentTypes",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#replaceOrCreate
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ContentTypes/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#upsertWithWhere
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ContentTypes/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#exists
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ContentTypes/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#findById
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ContentTypes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#replaceById
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ContentTypes/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#find
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ContentTypes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#findOne
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ContentTypes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#updateAll
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ContentTypes/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#deleteById
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ContentTypes/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#count
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ContentTypes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#prototype$updateAttributes
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ContentTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/ContentTypes/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#createChangeStream
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ContentTypes/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Contents.ContentTypes() instead.
            "::get::Contents::ContentTypes": {
              url: urlBase + "/Contents/:id/ContentTypes",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#patchOrCreate
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#updateOrCreate
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#patchOrCreateWithWhere
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#update
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#destroyById
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#removeById
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ContentTypes#patchAttributes
             * @methodOf lbServices.ContentTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ContentTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ContentTypes` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ContentTypes#modelName
        * @propertyOf lbServices.ContentTypes
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ContentTypes`.
        */
        R.modelName = "ContentTypes";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Departments
 * @header lbServices.Departments
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Departments` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Departments",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Departments/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Departments.Teams.findById() instead.
            "prototype$__findById__Teams": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Teams/:fk",
              method: "GET",
            },

            // INTERNAL. Use Departments.Teams.destroyById() instead.
            "prototype$__destroyById__Teams": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Teams/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Departments.Teams.updateById() instead.
            "prototype$__updateById__Teams": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Teams/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Departments.Users.findById() instead.
            "prototype$__findById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Departments.Users.destroyById() instead.
            "prototype$__destroyById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Departments.Users.updateById() instead.
            "prototype$__updateById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Users/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#__get__notDeleted
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Queries notDeleted of Departments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Departments/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#__create__notDeleted
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Departments/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#__delete__notDeleted
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Departments/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#__count__notDeleted
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Counts notDeleted of Departments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Departments/notDeleted/count",
              method: "GET",
            },

            // INTERNAL. Use Departments.Teams() instead.
            "prototype$__get__Teams": {
              isArray: true,
              url: urlBase + "/Departments/:id/Teams",
              method: "GET",
            },

            // INTERNAL. Use Departments.Teams.create() instead.
            "prototype$__create__Teams": {
              url: urlBase + "/Departments/:id/Teams",
              method: "POST",
            },

            // INTERNAL. Use Departments.Teams.destroyAll() instead.
            "prototype$__delete__Teams": {
              url: urlBase + "/Departments/:id/Teams",
              method: "DELETE",
            },

            // INTERNAL. Use Departments.Teams.count() instead.
            "prototype$__count__Teams": {
              url: urlBase + "/Departments/:id/Teams/count",
              method: "GET",
            },

            // INTERNAL. Use Departments.Users() instead.
            "prototype$__get__Users": {
              isArray: true,
              url: urlBase + "/Departments/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Departments.Users.create() instead.
            "prototype$__create__Users": {
              url: urlBase + "/Departments/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Departments.Users.destroyAll() instead.
            "prototype$__delete__Users": {
              url: urlBase + "/Departments/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use Departments.Users.count() instead.
            "prototype$__count__Users": {
              url: urlBase + "/Departments/:id/Users/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#create
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Departments",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#createMany
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Departments",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#upsert
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Departments",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#replaceOrCreate
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Departments/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#upsertWithWhere
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Departments/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#exists
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Departments/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#findById
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Departments/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#replaceById
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Departments/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#find
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Departments",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#findOne
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Departments/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#updateAll
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Departments/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#deleteById
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Departments/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#count
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Departments/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#prototype$updateAttributes
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Departments/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Departments#createChangeStream
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Departments/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Teams.Departments() instead.
            "::get::Teams::Departments": {
              url: urlBase + "/Teams/:id/Departments",
              method: "GET",
            },

            // INTERNAL. Use Users.Departments() instead.
            "::get::Users::Departments": {
              url: urlBase + "/Users/:id/Departments",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Departments#patchOrCreate
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Departments#updateOrCreate
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Departments#patchOrCreateWithWhere
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Departments#update
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Departments#destroyById
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Departments#removeById
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Departments#patchAttributes
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Departments#modelName
        * @propertyOf lbServices.Departments
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Departments`.
        */
        R.modelName = "Departments";

    /**
     * @ngdoc object
     * @name lbServices.Departments.Teams
     * @header lbServices.Departments.Teams
     * @object
     * @description
     *
     * The object `Departments.Teams` groups methods
     * manipulating `Teams` instances related to `Departments`.
     *
     * Call {@link lbServices.Departments#Teams Departments.Teams()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Departments#Teams
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Queries Teams of Departments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R.Teams = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::get::Departments::Teams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Teams#count
             * @methodOf lbServices.Departments.Teams
             *
             * @description
             *
             * Counts Teams of Departments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Teams.count = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::count::Departments::Teams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Teams#create
             * @methodOf lbServices.Departments.Teams
             *
             * @description
             *
             * Creates a new instance in Teams of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R.Teams.create = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::create::Departments::Teams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Teams#createMany
             * @methodOf lbServices.Departments.Teams
             *
             * @description
             *
             * Creates a new instance in Teams of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R.Teams.createMany = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::createMany::Departments::Teams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Teams#destroyAll
             * @methodOf lbServices.Departments.Teams
             *
             * @description
             *
             * Deletes all Teams of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Teams.destroyAll = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::delete::Departments::Teams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Teams#destroyById
             * @methodOf lbServices.Departments.Teams
             *
             * @description
             *
             * Delete a related item by id for Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `fk` – `{*}` - Foreign key for Teams
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Teams.destroyById = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::destroyById::Departments::Teams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Teams#findById
             * @methodOf lbServices.Departments.Teams
             *
             * @description
             *
             * Find a related item by id for Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `fk` – `{*}` - Foreign key for Teams
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R.Teams.findById = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::findById::Departments::Teams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Teams#updateById
             * @methodOf lbServices.Departments.Teams
             *
             * @description
             *
             * Update a related item by id for Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `fk` – `{*}` - Foreign key for Teams
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R.Teams.updateById = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::updateById::Departments::Teams"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Departments.Users
     * @header lbServices.Departments.Users
     * @object
     * @description
     *
     * The object `Departments.Users` groups methods
     * manipulating `Users` instances related to `Departments`.
     *
     * Call {@link lbServices.Departments#Users Departments.Users()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Departments#Users
             * @methodOf lbServices.Departments
             *
             * @description
             *
             * Queries Users of Departments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::get::Departments::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Users#count
             * @methodOf lbServices.Departments.Users
             *
             * @description
             *
             * Counts Users of Departments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Users.count = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::count::Departments::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Users#create
             * @methodOf lbServices.Departments.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.create = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::create::Departments::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Users#createMany
             * @methodOf lbServices.Departments.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.createMany = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::createMany::Departments::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Users#destroyAll
             * @methodOf lbServices.Departments.Users
             *
             * @description
             *
             * Deletes all Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyAll = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::delete::Departments::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Users#destroyById
             * @methodOf lbServices.Departments.Users
             *
             * @description
             *
             * Delete a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::destroyById::Departments::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Users#findById
             * @methodOf lbServices.Departments.Users
             *
             * @description
             *
             * Find a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.findById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::findById::Departments::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Departments.Users#updateById
             * @methodOf lbServices.Departments.Users
             *
             * @description
             *
             * Update a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Departments id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.updateById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::updateById::Departments::Users"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Distributors
 * @header lbServices.Distributors
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Distributors` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Distributors",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Distributors/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Distributors#__get__notDeleted
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Queries notDeleted of Distributors.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Distributors/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#__create__notDeleted
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Distributors/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#__delete__notDeleted
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Distributors/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#__count__notDeleted
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Counts notDeleted of Distributors.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Distributors/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#create
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Distributors",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#createMany
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Distributors",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#upsert
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Distributors",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#replaceOrCreate
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Distributors/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#upsertWithWhere
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Distributors/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#exists
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Distributors/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#findById
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Distributors/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#replaceById
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Distributors/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#find
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Distributors",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#findOne
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Distributors/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#updateAll
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Distributors/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#deleteById
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Distributors/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#count
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Distributors/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#prototype$updateAttributes
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Distributors id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Distributors/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Distributors#createChangeStream
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Distributors/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Books.Distributors() instead.
            "::get::Books::Distributors": {
              url: urlBase + "/Books/:id/Distributors",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Distributors#patchOrCreate
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Distributors#updateOrCreate
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Distributors#patchOrCreateWithWhere
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Distributors#update
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Distributors#destroyById
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Distributors#removeById
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Distributors#patchAttributes
             * @methodOf lbServices.Distributors
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Distributors id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Distributors` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Distributors#modelName
        * @propertyOf lbServices.Distributors
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Distributors`.
        */
        R.modelName = "Distributors";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.DivisionMetaLink
 * @header lbServices.DivisionMetaLink
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `DivisionMetaLink` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "DivisionMetaLink",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/DivisionMetaLinks/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use DivisionMetaLink.Divisions() instead.
            "prototype$__get__Divisions": {
              url: urlBase + "/DivisionMetaLinks/:id/Divisions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#create
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/DivisionMetaLinks",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#createMany
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/DivisionMetaLinks",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#upsert
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/DivisionMetaLinks",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#replaceOrCreate
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/DivisionMetaLinks/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#upsertWithWhere
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/DivisionMetaLinks/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#exists
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/DivisionMetaLinks/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#findById
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/DivisionMetaLinks/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#replaceById
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/DivisionMetaLinks/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#find
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/DivisionMetaLinks",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#findOne
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/DivisionMetaLinks/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#updateAll
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/DivisionMetaLinks/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#deleteById
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/DivisionMetaLinks/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#count
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/DivisionMetaLinks/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#prototype$updateAttributes
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DivisionMetaLink id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/DivisionMetaLinks/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#createChangeStream
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/DivisionMetaLinks/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#getMetaTableData
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Get Division Meta Data. Division can ether be an int or comma separated string i.e 1,5,4,3. Then pass in the table name
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
            "getMetaTableData": {
              url: urlBase + "/DivisionMetaLinks/getMetaTableData",
              method: "POST",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.findById() instead.
            "::findById::Divisions::DivisionMetaLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/DivisionMetaLink/:fk",
              method: "GET",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.destroyById() instead.
            "::destroyById::Divisions::DivisionMetaLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/DivisionMetaLink/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.updateById() instead.
            "::updateById::Divisions::DivisionMetaLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/DivisionMetaLink/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink() instead.
            "::get::Divisions::DivisionMetaLink": {
              isArray: true,
              url: urlBase + "/Divisions/:id/DivisionMetaLink",
              method: "GET",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.create() instead.
            "::create::Divisions::DivisionMetaLink": {
              url: urlBase + "/Divisions/:id/DivisionMetaLink",
              method: "POST",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.createMany() instead.
            "::createMany::Divisions::DivisionMetaLink": {
              isArray: true,
              url: urlBase + "/Divisions/:id/DivisionMetaLink",
              method: "POST",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.destroyAll() instead.
            "::delete::Divisions::DivisionMetaLink": {
              url: urlBase + "/Divisions/:id/DivisionMetaLink",
              method: "DELETE",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.count() instead.
            "::count::Divisions::DivisionMetaLink": {
              url: urlBase + "/Divisions/:id/DivisionMetaLink/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#patchOrCreate
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#updateOrCreate
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#patchOrCreateWithWhere
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#update
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#destroyById
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#removeById
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#patchAttributes
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DivisionMetaLink id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.DivisionMetaLink#modelName
        * @propertyOf lbServices.DivisionMetaLink
        * @description
        * The name of the model represented by this $resource,
        * i.e. `DivisionMetaLink`.
        */
        R.modelName = "DivisionMetaLink";


            /**
             * @ngdoc method
             * @name lbServices.DivisionMetaLink#Divisions
             * @methodOf lbServices.DivisionMetaLink
             *
             * @description
             *
             * Fetches belongsTo relation Divisions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DivisionMetaLink id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
        R.Divisions = function() {
          var TargetResource = $injector.get("Divisions");
          var action = TargetResource["::get::DivisionMetaLink::Divisions"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Divisions
 * @header lbServices.Divisions
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Divisions` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Divisions",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Divisions/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Divisions.DivisionMetaLink.findById() instead.
            "prototype$__findById__DivisionMetaLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/DivisionMetaLink/:fk",
              method: "GET",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.destroyById() instead.
            "prototype$__destroyById__DivisionMetaLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/DivisionMetaLink/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.updateById() instead.
            "prototype$__updateById__DivisionMetaLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/DivisionMetaLink/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Divisions.Users.findById() instead.
            "prototype$__findById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Divisions.Users.destroyById() instead.
            "prototype$__destroyById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Divisions.Users.updateById() instead.
            "prototype$__updateById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/Users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink() instead.
            "prototype$__get__DivisionMetaLink": {
              isArray: true,
              url: urlBase + "/Divisions/:id/DivisionMetaLink",
              method: "GET",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.create() instead.
            "prototype$__create__DivisionMetaLink": {
              url: urlBase + "/Divisions/:id/DivisionMetaLink",
              method: "POST",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.destroyAll() instead.
            "prototype$__delete__DivisionMetaLink": {
              url: urlBase + "/Divisions/:id/DivisionMetaLink",
              method: "DELETE",
            },

            // INTERNAL. Use Divisions.DivisionMetaLink.count() instead.
            "prototype$__count__DivisionMetaLink": {
              url: urlBase + "/Divisions/:id/DivisionMetaLink/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#__get__notDeleted
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Queries notDeleted of Divisions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Divisions/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#__create__notDeleted
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Divisions/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#__delete__notDeleted
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Divisions/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#__count__notDeleted
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Counts notDeleted of Divisions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Divisions/notDeleted/count",
              method: "GET",
            },

            // INTERNAL. Use Divisions.Users() instead.
            "prototype$__get__Users": {
              isArray: true,
              url: urlBase + "/Divisions/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Divisions.Users.create() instead.
            "prototype$__create__Users": {
              url: urlBase + "/Divisions/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Divisions.Users.destroyAll() instead.
            "prototype$__delete__Users": {
              url: urlBase + "/Divisions/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use Divisions.Users.count() instead.
            "prototype$__count__Users": {
              url: urlBase + "/Divisions/:id/Users/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#create
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Divisions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#createMany
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Divisions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#upsert
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Divisions",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#replaceOrCreate
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Divisions/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#upsertWithWhere
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Divisions/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#exists
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Divisions/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#findById
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Divisions/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#replaceById
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Divisions/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#find
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Divisions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#findOne
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Divisions/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#updateAll
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Divisions/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#deleteById
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Divisions/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#count
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Divisions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#prototype$updateAttributes
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Divisions/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Divisions#createChangeStream
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Divisions/change-stream",
              method: "POST",
            },

            // INTERNAL. Use DivisionMetaLink.Divisions() instead.
            "::get::DivisionMetaLink::Divisions": {
              url: urlBase + "/DivisionMetaLinks/:id/Divisions",
              method: "GET",
            },

            // INTERNAL. Use Users.Divisions() instead.
            "::get::Users::Divisions": {
              url: urlBase + "/Users/:id/Divisions",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Divisions#patchOrCreate
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Divisions#updateOrCreate
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Divisions#patchOrCreateWithWhere
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Divisions#update
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Divisions#destroyById
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Divisions#removeById
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Divisions#patchAttributes
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Divisions#modelName
        * @propertyOf lbServices.Divisions
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Divisions`.
        */
        R.modelName = "Divisions";

    /**
     * @ngdoc object
     * @name lbServices.Divisions.DivisionMetaLink
     * @header lbServices.Divisions.DivisionMetaLink
     * @object
     * @description
     *
     * The object `Divisions.DivisionMetaLink` groups methods
     * manipulating `DivisionMetaLink` instances related to `Divisions`.
     *
     * Call {@link lbServices.Divisions#DivisionMetaLink Divisions.DivisionMetaLink()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Divisions#DivisionMetaLink
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Queries DivisionMetaLink of Divisions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R.DivisionMetaLink = function() {
          var TargetResource = $injector.get("DivisionMetaLink");
          var action = TargetResource["::get::Divisions::DivisionMetaLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.DivisionMetaLink#count
             * @methodOf lbServices.Divisions.DivisionMetaLink
             *
             * @description
             *
             * Counts DivisionMetaLink of Divisions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.DivisionMetaLink.count = function() {
          var TargetResource = $injector.get("DivisionMetaLink");
          var action = TargetResource["::count::Divisions::DivisionMetaLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.DivisionMetaLink#create
             * @methodOf lbServices.Divisions.DivisionMetaLink
             *
             * @description
             *
             * Creates a new instance in DivisionMetaLink of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R.DivisionMetaLink.create = function() {
          var TargetResource = $injector.get("DivisionMetaLink");
          var action = TargetResource["::create::Divisions::DivisionMetaLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.DivisionMetaLink#createMany
             * @methodOf lbServices.Divisions.DivisionMetaLink
             *
             * @description
             *
             * Creates a new instance in DivisionMetaLink of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R.DivisionMetaLink.createMany = function() {
          var TargetResource = $injector.get("DivisionMetaLink");
          var action = TargetResource["::createMany::Divisions::DivisionMetaLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.DivisionMetaLink#destroyAll
             * @methodOf lbServices.Divisions.DivisionMetaLink
             *
             * @description
             *
             * Deletes all DivisionMetaLink of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.DivisionMetaLink.destroyAll = function() {
          var TargetResource = $injector.get("DivisionMetaLink");
          var action = TargetResource["::delete::Divisions::DivisionMetaLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.DivisionMetaLink#destroyById
             * @methodOf lbServices.Divisions.DivisionMetaLink
             *
             * @description
             *
             * Delete a related item by id for DivisionMetaLink.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `fk` – `{*}` - Foreign key for DivisionMetaLink
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.DivisionMetaLink.destroyById = function() {
          var TargetResource = $injector.get("DivisionMetaLink");
          var action = TargetResource["::destroyById::Divisions::DivisionMetaLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.DivisionMetaLink#findById
             * @methodOf lbServices.Divisions.DivisionMetaLink
             *
             * @description
             *
             * Find a related item by id for DivisionMetaLink.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `fk` – `{*}` - Foreign key for DivisionMetaLink
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R.DivisionMetaLink.findById = function() {
          var TargetResource = $injector.get("DivisionMetaLink");
          var action = TargetResource["::findById::Divisions::DivisionMetaLink"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.DivisionMetaLink#updateById
             * @methodOf lbServices.Divisions.DivisionMetaLink
             *
             * @description
             *
             * Update a related item by id for DivisionMetaLink.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `fk` – `{*}` - Foreign key for DivisionMetaLink
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DivisionMetaLink` object.)
             * </em>
             */
        R.DivisionMetaLink.updateById = function() {
          var TargetResource = $injector.get("DivisionMetaLink");
          var action = TargetResource["::updateById::Divisions::DivisionMetaLink"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Divisions.Users
     * @header lbServices.Divisions.Users
     * @object
     * @description
     *
     * The object `Divisions.Users` groups methods
     * manipulating `Users` instances related to `Divisions`.
     *
     * Call {@link lbServices.Divisions#Users Divisions.Users()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Divisions#Users
             * @methodOf lbServices.Divisions
             *
             * @description
             *
             * Queries Users of Divisions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::get::Divisions::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.Users#count
             * @methodOf lbServices.Divisions.Users
             *
             * @description
             *
             * Counts Users of Divisions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Users.count = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::count::Divisions::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.Users#create
             * @methodOf lbServices.Divisions.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.create = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::create::Divisions::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.Users#createMany
             * @methodOf lbServices.Divisions.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.createMany = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::createMany::Divisions::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.Users#destroyAll
             * @methodOf lbServices.Divisions.Users
             *
             * @description
             *
             * Deletes all Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyAll = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::delete::Divisions::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.Users#destroyById
             * @methodOf lbServices.Divisions.Users
             *
             * @description
             *
             * Delete a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::destroyById::Divisions::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.Users#findById
             * @methodOf lbServices.Divisions.Users
             *
             * @description
             *
             * Find a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.findById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::findById::Divisions::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Divisions.Users#updateById
             * @methodOf lbServices.Divisions.Users
             *
             * @description
             *
             * Update a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Divisions id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.updateById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::updateById::Divisions::Users"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.DocContents
 * @header lbServices.DocContents
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `DocContents` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "DocContents",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/DocContents/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.DocContents#__get__notDeleted
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Queries notDeleted of DocContents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/DocContents/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#__create__notDeleted
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/DocContents/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#__delete__notDeleted
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/DocContents/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#__count__notDeleted
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Counts notDeleted of DocContents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/DocContents/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#create
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/DocContents",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#createMany
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/DocContents",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#upsert
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/DocContents",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#replaceOrCreate
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/DocContents/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#upsertWithWhere
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/DocContents/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#exists
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/DocContents/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#findById
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/DocContents/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#replaceById
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/DocContents/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#find
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/DocContents",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#findOne
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/DocContents/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#updateAll
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/DocContents/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#deleteById
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/DocContents/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#count
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/DocContents/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#prototype$updateAttributes
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocContents id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/DocContents/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocContents#createChangeStream
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/DocContents/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.DocContents#patchOrCreate
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.DocContents#updateOrCreate
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.DocContents#patchOrCreateWithWhere
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.DocContents#update
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.DocContents#destroyById
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.DocContents#removeById
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.DocContents#patchAttributes
             * @methodOf lbServices.DocContents
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocContents id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocContents` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.DocContents#modelName
        * @propertyOf lbServices.DocContents
        * @description
        * The name of the model represented by this $resource,
        * i.e. `DocContents`.
        */
        R.modelName = "DocContents";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.DocTypeCats
 * @header lbServices.DocTypeCats
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `DocTypeCats` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "DocTypeCats",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/DocTypeCats/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use DocTypeCats.DocTypes.findById() instead.
            "prototype$__findById__DocTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypeCats/:id/DocTypes/:fk",
              method: "GET",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.destroyById() instead.
            "prototype$__destroyById__DocTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypeCats/:id/DocTypes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.updateById() instead.
            "prototype$__updateById__DocTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypeCats/:id/DocTypes/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#__get__notDeleted
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Queries notDeleted of DocTypeCats.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/DocTypeCats/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#__create__notDeleted
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/DocTypeCats/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#__delete__notDeleted
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/DocTypeCats/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#__count__notDeleted
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Counts notDeleted of DocTypeCats.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/DocTypeCats/notDeleted/count",
              method: "GET",
            },

            // INTERNAL. Use DocTypeCats.DocTypes() instead.
            "prototype$__get__DocTypes": {
              isArray: true,
              url: urlBase + "/DocTypeCats/:id/DocTypes",
              method: "GET",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.create() instead.
            "prototype$__create__DocTypes": {
              url: urlBase + "/DocTypeCats/:id/DocTypes",
              method: "POST",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.destroyAll() instead.
            "prototype$__delete__DocTypes": {
              url: urlBase + "/DocTypeCats/:id/DocTypes",
              method: "DELETE",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.count() instead.
            "prototype$__count__DocTypes": {
              url: urlBase + "/DocTypeCats/:id/DocTypes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#create
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/DocTypeCats",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#createMany
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/DocTypeCats",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#upsert
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/DocTypeCats",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#replaceOrCreate
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/DocTypeCats/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#upsertWithWhere
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/DocTypeCats/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#exists
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/DocTypeCats/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#findById
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/DocTypeCats/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#replaceById
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/DocTypeCats/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#find
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/DocTypeCats",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#findOne
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/DocTypeCats/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#updateAll
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/DocTypeCats/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#deleteById
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/DocTypeCats/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#count
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/DocTypeCats/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#prototype$updateAttributes
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypeCats id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/DocTypeCats/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#createChangeStream
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/DocTypeCats/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#getDocTypeCatsAndDocCount
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Get a list Doc Type Cats and a count of documents associated with them
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
            "getDocTypeCatsAndDocCount": {
              isArray: true,
              url: urlBase + "/DocTypeCats/getDocTypeCatsAndDocCount",
              method: "POST",
            },

            // INTERNAL. Use DocTypes.DocTypeCats() instead.
            "::get::DocTypes::DocTypeCats": {
              url: urlBase + "/DocTypes/:id/DocTypeCats",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#patchOrCreate
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#updateOrCreate
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#patchOrCreateWithWhere
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#update
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#destroyById
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#removeById
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#patchAttributes
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypeCats id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.DocTypeCats#modelName
        * @propertyOf lbServices.DocTypeCats
        * @description
        * The name of the model represented by this $resource,
        * i.e. `DocTypeCats`.
        */
        R.modelName = "DocTypeCats";

    /**
     * @ngdoc object
     * @name lbServices.DocTypeCats.DocTypes
     * @header lbServices.DocTypeCats.DocTypes
     * @object
     * @description
     *
     * The object `DocTypeCats.DocTypes` groups methods
     * manipulating `DocTypes` instances related to `DocTypeCats`.
     *
     * Call {@link lbServices.DocTypeCats#DocTypes DocTypeCats.DocTypes()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats#DocTypes
             * @methodOf lbServices.DocTypeCats
             *
             * @description
             *
             * Queries DocTypes of DocTypeCats.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypeCats id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R.DocTypes = function() {
          var TargetResource = $injector.get("DocTypes");
          var action = TargetResource["::get::DocTypeCats::DocTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats.DocTypes#count
             * @methodOf lbServices.DocTypeCats.DocTypes
             *
             * @description
             *
             * Counts DocTypes of DocTypeCats.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypeCats id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.DocTypes.count = function() {
          var TargetResource = $injector.get("DocTypes");
          var action = TargetResource["::count::DocTypeCats::DocTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats.DocTypes#create
             * @methodOf lbServices.DocTypeCats.DocTypes
             *
             * @description
             *
             * Creates a new instance in DocTypes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypeCats id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R.DocTypes.create = function() {
          var TargetResource = $injector.get("DocTypes");
          var action = TargetResource["::create::DocTypeCats::DocTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats.DocTypes#createMany
             * @methodOf lbServices.DocTypeCats.DocTypes
             *
             * @description
             *
             * Creates a new instance in DocTypes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypeCats id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R.DocTypes.createMany = function() {
          var TargetResource = $injector.get("DocTypes");
          var action = TargetResource["::createMany::DocTypeCats::DocTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats.DocTypes#destroyAll
             * @methodOf lbServices.DocTypeCats.DocTypes
             *
             * @description
             *
             * Deletes all DocTypes of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypeCats id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.DocTypes.destroyAll = function() {
          var TargetResource = $injector.get("DocTypes");
          var action = TargetResource["::delete::DocTypeCats::DocTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats.DocTypes#destroyById
             * @methodOf lbServices.DocTypeCats.DocTypes
             *
             * @description
             *
             * Delete a related item by id for DocTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypeCats id
             *
             *  - `fk` – `{*}` - Foreign key for DocTypes
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.DocTypes.destroyById = function() {
          var TargetResource = $injector.get("DocTypes");
          var action = TargetResource["::destroyById::DocTypeCats::DocTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats.DocTypes#findById
             * @methodOf lbServices.DocTypeCats.DocTypes
             *
             * @description
             *
             * Find a related item by id for DocTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypeCats id
             *
             *  - `fk` – `{*}` - Foreign key for DocTypes
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R.DocTypes.findById = function() {
          var TargetResource = $injector.get("DocTypes");
          var action = TargetResource["::findById::DocTypeCats::DocTypes"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypeCats.DocTypes#updateById
             * @methodOf lbServices.DocTypeCats.DocTypes
             *
             * @description
             *
             * Update a related item by id for DocTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypeCats id
             *
             *  - `fk` – `{*}` - Foreign key for DocTypes
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R.DocTypes.updateById = function() {
          var TargetResource = $injector.get("DocTypes");
          var action = TargetResource["::updateById::DocTypeCats::DocTypes"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.DocTypes
 * @header lbServices.DocTypes
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `DocTypes` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "DocTypes",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/DocTypes/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use DocTypes.Books.findById() instead.
            "prototype$__findById__Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypes/:id/Books/:fk",
              method: "GET",
            },

            // INTERNAL. Use DocTypes.Books.destroyById() instead.
            "prototype$__destroyById__Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypes/:id/Books/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use DocTypes.Books.updateById() instead.
            "prototype$__updateById__Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypes/:id/Books/:fk",
              method: "PUT",
            },

            // INTERNAL. Use DocTypes.DocTypeCats() instead.
            "prototype$__get__DocTypeCats": {
              url: urlBase + "/DocTypes/:id/DocTypeCats",
              method: "GET",
            },

            // INTERNAL. Use DocTypes.Books() instead.
            "prototype$__get__Books": {
              isArray: true,
              url: urlBase + "/DocTypes/:id/Books",
              method: "GET",
            },

            // INTERNAL. Use DocTypes.Books.create() instead.
            "prototype$__create__Books": {
              url: urlBase + "/DocTypes/:id/Books",
              method: "POST",
            },

            // INTERNAL. Use DocTypes.Books.destroyAll() instead.
            "prototype$__delete__Books": {
              url: urlBase + "/DocTypes/:id/Books",
              method: "DELETE",
            },

            // INTERNAL. Use DocTypes.Books.count() instead.
            "prototype$__count__Books": {
              url: urlBase + "/DocTypes/:id/Books/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#__get__notDeleted
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Queries notDeleted of DocTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/DocTypes/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#__create__notDeleted
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/DocTypes/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#__delete__notDeleted
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/DocTypes/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#__count__notDeleted
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Counts notDeleted of DocTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/DocTypes/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#create
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/DocTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#createMany
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/DocTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#upsert
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/DocTypes",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#replaceOrCreate
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/DocTypes/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#upsertWithWhere
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/DocTypes/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#exists
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/DocTypes/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#findById
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/DocTypes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#replaceById
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/DocTypes/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#find
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/DocTypes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#findOne
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/DocTypes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#updateAll
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/DocTypes/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#deleteById
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/DocTypes/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#count
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/DocTypes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#prototype$updateAttributes
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/DocTypes/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#createChangeStream
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/DocTypes/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#getDocTypesAndDocCount
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Get a list Doc Cats and a count of documents associated with them
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
            "getDocTypesAndDocCount": {
              isArray: true,
              url: urlBase + "/DocTypes/getDocTypesAndDocCount",
              method: "POST",
            },

            // INTERNAL. Use Books.DocTypes() instead.
            "::get::Books::DocTypes": {
              url: urlBase + "/Books/:id/DocTypes",
              method: "GET",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.findById() instead.
            "::findById::DocTypeCats::DocTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypeCats/:id/DocTypes/:fk",
              method: "GET",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.destroyById() instead.
            "::destroyById::DocTypeCats::DocTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypeCats/:id/DocTypes/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.updateById() instead.
            "::updateById::DocTypeCats::DocTypes": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/DocTypeCats/:id/DocTypes/:fk",
              method: "PUT",
            },

            // INTERNAL. Use DocTypeCats.DocTypes() instead.
            "::get::DocTypeCats::DocTypes": {
              isArray: true,
              url: urlBase + "/DocTypeCats/:id/DocTypes",
              method: "GET",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.create() instead.
            "::create::DocTypeCats::DocTypes": {
              url: urlBase + "/DocTypeCats/:id/DocTypes",
              method: "POST",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.createMany() instead.
            "::createMany::DocTypeCats::DocTypes": {
              isArray: true,
              url: urlBase + "/DocTypeCats/:id/DocTypes",
              method: "POST",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.destroyAll() instead.
            "::delete::DocTypeCats::DocTypes": {
              url: urlBase + "/DocTypeCats/:id/DocTypes",
              method: "DELETE",
            },

            // INTERNAL. Use DocTypeCats.DocTypes.count() instead.
            "::count::DocTypeCats::DocTypes": {
              url: urlBase + "/DocTypeCats/:id/DocTypes/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.DocTypes#patchOrCreate
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#updateOrCreate
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#patchOrCreateWithWhere
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#update
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#destroyById
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#removeById
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#patchAttributes
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypes` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.DocTypes#modelName
        * @propertyOf lbServices.DocTypes
        * @description
        * The name of the model represented by this $resource,
        * i.e. `DocTypes`.
        */
        R.modelName = "DocTypes";

    /**
     * @ngdoc object
     * @name lbServices.DocTypes.Books
     * @header lbServices.DocTypes.Books
     * @object
     * @description
     *
     * The object `DocTypes.Books` groups methods
     * manipulating `Books` instances related to `DocTypes`.
     *
     * Call {@link lbServices.DocTypes#Books DocTypes.Books()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.DocTypes#Books
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Queries Books of DocTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::get::DocTypes::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypes.Books#count
             * @methodOf lbServices.DocTypes.Books
             *
             * @description
             *
             * Counts Books of DocTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Books.count = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::count::DocTypes::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypes.Books#create
             * @methodOf lbServices.DocTypes.Books
             *
             * @description
             *
             * Creates a new instance in Books of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books.create = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::create::DocTypes::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypes.Books#createMany
             * @methodOf lbServices.DocTypes.Books
             *
             * @description
             *
             * Creates a new instance in Books of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books.createMany = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::createMany::DocTypes::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypes.Books#destroyAll
             * @methodOf lbServices.DocTypes.Books
             *
             * @description
             *
             * Deletes all Books of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Books.destroyAll = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::delete::DocTypes::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypes.Books#destroyById
             * @methodOf lbServices.DocTypes.Books
             *
             * @description
             *
             * Delete a related item by id for Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             *  - `fk` – `{*}` - Foreign key for Books
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Books.destroyById = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::destroyById::DocTypes::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypes.Books#findById
             * @methodOf lbServices.DocTypes.Books
             *
             * @description
             *
             * Find a related item by id for Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             *  - `fk` – `{*}` - Foreign key for Books
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books.findById = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::findById::DocTypes::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypes.Books#updateById
             * @methodOf lbServices.DocTypes.Books
             *
             * @description
             *
             * Update a related item by id for Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             *  - `fk` – `{*}` - Foreign key for Books
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books.updateById = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::updateById::DocTypes::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.DocTypes#DocTypeCats
             * @methodOf lbServices.DocTypes
             *
             * @description
             *
             * Fetches belongsTo relation DocTypeCats.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - DocTypes id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DocTypeCats` object.)
             * </em>
             */
        R.DocTypeCats = function() {
          var TargetResource = $injector.get("DocTypeCats");
          var action = TargetResource["::get::DocTypes::DocTypeCats"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Enclosures
 * @header lbServices.Enclosures
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Enclosures` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Enclosures",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Enclosures/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#__get__notDeleted
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Queries notDeleted of Enclosures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Enclosures/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#__create__notDeleted
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Enclosures/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#__delete__notDeleted
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Enclosures/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#__count__notDeleted
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Counts notDeleted of Enclosures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Enclosures/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#create
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Enclosures",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#createMany
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Enclosures",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#upsert
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Enclosures",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#replaceOrCreate
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Enclosures/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#upsertWithWhere
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Enclosures/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#exists
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Enclosures/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#findById
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Enclosures/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#replaceById
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Enclosures/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#find
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Enclosures",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#findOne
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Enclosures/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#updateAll
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Enclosures/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#deleteById
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Enclosures/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#count
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Enclosures/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#prototype$updateAttributes
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Enclosures id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Enclosures/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#createChangeStream
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Enclosures/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#getEnclosures
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Get Enclosures based on Division ID
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "getEnclosures": {
              isArray: true,
              url: urlBase + "/Enclosures/getEnclosures",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#duplicateByBookId
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Set Product IDs status to 0 based on book id
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "duplicateByBookId": {
              isArray: true,
              url: urlBase + "/Enclosures/duplicateByBookId",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#setStatusByBookId
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Set Product IDs status to 0 based on book id
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
            "setStatusByBookId": {
              isArray: true,
              url: urlBase + "/Enclosures/setStatusByBookId",
              method: "POST",
            },

            // INTERNAL. Use Books.Enclosures.findById() instead.
            "::findById::Books::Enclosures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Enclosures/:fk",
              method: "GET",
            },

            // INTERNAL. Use Books.Enclosures.destroyById() instead.
            "::destroyById::Books::Enclosures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Enclosures/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Enclosures.updateById() instead.
            "::updateById::Books::Enclosures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Enclosures/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Books.Enclosures() instead.
            "::get::Books::Enclosures": {
              isArray: true,
              url: urlBase + "/Books/:id/Enclosures",
              method: "GET",
            },

            // INTERNAL. Use Books.Enclosures.create() instead.
            "::create::Books::Enclosures": {
              url: urlBase + "/Books/:id/Enclosures",
              method: "POST",
            },

            // INTERNAL. Use Books.Enclosures.createMany() instead.
            "::createMany::Books::Enclosures": {
              isArray: true,
              url: urlBase + "/Books/:id/Enclosures",
              method: "POST",
            },

            // INTERNAL. Use Books.Enclosures.destroyAll() instead.
            "::delete::Books::Enclosures": {
              url: urlBase + "/Books/:id/Enclosures",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Enclosures.count() instead.
            "::count::Books::Enclosures": {
              url: urlBase + "/Books/:id/Enclosures/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Enclosures#patchOrCreate
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#updateOrCreate
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#patchOrCreateWithWhere
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#update
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#destroyById
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#removeById
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Enclosures#patchAttributes
             * @methodOf lbServices.Enclosures
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Enclosures id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Enclosures` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Enclosures#modelName
        * @propertyOf lbServices.Enclosures
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Enclosures`.
        */
        R.modelName = "Enclosures";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.EnclosureTypes
 * @header lbServices.EnclosureTypes
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `EnclosureTypes` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "EnclosureTypes",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/EnclosureTypes/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#__get__notDeleted
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Queries notDeleted of EnclosureTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/EnclosureTypes/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#__create__notDeleted
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/EnclosureTypes/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#__delete__notDeleted
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/EnclosureTypes/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#__count__notDeleted
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Counts notDeleted of EnclosureTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/EnclosureTypes/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#create
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/EnclosureTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#createMany
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/EnclosureTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#upsert
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/EnclosureTypes",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#replaceOrCreate
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/EnclosureTypes/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#upsertWithWhere
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/EnclosureTypes/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#exists
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/EnclosureTypes/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#findById
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/EnclosureTypes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#replaceById
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/EnclosureTypes/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#find
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/EnclosureTypes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#findOne
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/EnclosureTypes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#updateAll
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/EnclosureTypes/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#deleteById
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/EnclosureTypes/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#count
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/EnclosureTypes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#prototype$updateAttributes
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - EnclosureTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/EnclosureTypes/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#createChangeStream
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/EnclosureTypes/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#patchOrCreate
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#updateOrCreate
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#patchOrCreateWithWhere
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#update
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#destroyById
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#removeById
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.EnclosureTypes#patchAttributes
             * @methodOf lbServices.EnclosureTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - EnclosureTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `EnclosureTypes` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.EnclosureTypes#modelName
        * @propertyOf lbServices.EnclosureTypes
        * @description
        * The name of the model represented by this $resource,
        * i.e. `EnclosureTypes`.
        */
        R.modelName = "EnclosureTypes";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ErrorLogs
 * @header lbServices.ErrorLogs
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ErrorLogs` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ErrorLogs",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ErrorLogs/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#create
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ErrorLogs",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#createMany
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ErrorLogs",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#upsert
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/ErrorLogs",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#replaceOrCreate
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ErrorLogs/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#upsertWithWhere
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ErrorLogs/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#exists
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ErrorLogs/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#findById
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ErrorLogs/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#replaceById
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ErrorLogs/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#find
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ErrorLogs",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#findOne
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ErrorLogs/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#updateAll
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ErrorLogs/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#deleteById
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ErrorLogs/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#count
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ErrorLogs/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#prototype$updateAttributes
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ErrorLogs id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/ErrorLogs/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#createChangeStream
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ErrorLogs/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#insertError
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Log error into the error log table
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
            "insertError": {
              isArray: true,
              url: urlBase + "/ErrorLogs/insertError",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#patchOrCreate
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#updateOrCreate
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#patchOrCreateWithWhere
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#update
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#destroyById
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#removeById
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ErrorLogs#patchAttributes
             * @methodOf lbServices.ErrorLogs
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ErrorLogs id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ErrorLogs` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ErrorLogs#modelName
        * @propertyOf lbServices.ErrorLogs
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ErrorLogs`.
        */
        R.modelName = "ErrorLogs";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Events
 * @header lbServices.Events
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Events` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Events",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Events/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Events.Contents() instead.
            "prototype$__get__Contents": {
              url: urlBase + "/Events/:id/Contents",
              method: "GET",
            },

            // INTERNAL. Use Events.History() instead.
            "prototype$__get__History": {
              url: urlBase + "/Events/:id/History",
              method: "GET",
            },

            // INTERNAL. Use Events.Messages() instead.
            "prototype$__get__Messages": {
              url: urlBase + "/Events/:id/Messages",
              method: "GET",
            },

            // INTERNAL. Use Events.Users() instead.
            "prototype$__get__Users": {
              url: urlBase + "/Events/:id/Users",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#create
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Events",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#createMany
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Events",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#upsert
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Events",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#replaceOrCreate
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Events/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#upsertWithWhere
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Events/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#exists
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Events/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#findById
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Events/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#replaceById
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Events/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#find
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Events",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#findOne
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Events/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#updateAll
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Events/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#deleteById
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Events/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#count
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Events/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#prototype$updateAttributes
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Events id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Events/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Events#createChangeStream
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Events/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Users.Events.findById() instead.
            "::findById::Users::Events": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Events/:fk",
              method: "GET",
            },

            // INTERNAL. Use Users.Events.destroyById() instead.
            "::destroyById::Users::Events": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Events/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Users.Events.updateById() instead.
            "::updateById::Users::Events": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Events/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Users.Events() instead.
            "::get::Users::Events": {
              isArray: true,
              url: urlBase + "/Users/:id/Events",
              method: "GET",
            },

            // INTERNAL. Use Users.Events.create() instead.
            "::create::Users::Events": {
              url: urlBase + "/Users/:id/Events",
              method: "POST",
            },

            // INTERNAL. Use Users.Events.createMany() instead.
            "::createMany::Users::Events": {
              isArray: true,
              url: urlBase + "/Users/:id/Events",
              method: "POST",
            },

            // INTERNAL. Use Users.Events.destroyAll() instead.
            "::delete::Users::Events": {
              url: urlBase + "/Users/:id/Events",
              method: "DELETE",
            },

            // INTERNAL. Use Users.Events.count() instead.
            "::count::Users::Events": {
              url: urlBase + "/Users/:id/Events/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Events#patchOrCreate
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Events#updateOrCreate
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Events#patchOrCreateWithWhere
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Events#update
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Events#destroyById
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Events#removeById
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Events#patchAttributes
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Events id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Events#modelName
        * @propertyOf lbServices.Events
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Events`.
        */
        R.modelName = "Events";


            /**
             * @ngdoc method
             * @name lbServices.Events#Contents
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Fetches belongsTo relation Contents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Events id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R.Contents = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::get::Events::Contents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Events#History
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Fetches belongsTo relation History.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Events id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::get::Events::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Events#Messages
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Fetches belongsTo relation Messages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Events id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
        R.Messages = function() {
          var TargetResource = $injector.get("Messages");
          var action = TargetResource["::get::Events::Messages"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Events#Users
             * @methodOf lbServices.Events
             *
             * @description
             *
             * Fetches belongsTo relation Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Events id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::get::Events::Users"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.History
 * @header lbServices.History
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `History` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "History",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Histories/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use History.Structures() instead.
            "prototype$__get__Structures": {
              url: urlBase + "/Histories/:id/Structures",
              method: "GET",
            },

            // INTERNAL. Use History.versions() instead.
            "prototype$__get__versions": {
              url: urlBase + "/Histories/:id/versions",
              method: "GET",
            },

            // INTERNAL. Use History.versionsNew() instead.
            "prototype$__get__versionsNew": {
              url: urlBase + "/Histories/:id/versionsNew",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#create
             * @methodOf lbServices.History
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Histories",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#createMany
             * @methodOf lbServices.History
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Histories",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#upsert
             * @methodOf lbServices.History
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Histories",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#replaceOrCreate
             * @methodOf lbServices.History
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Histories/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#upsertWithWhere
             * @methodOf lbServices.History
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Histories/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#exists
             * @methodOf lbServices.History
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Histories/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#findById
             * @methodOf lbServices.History
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Histories/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#replaceById
             * @methodOf lbServices.History
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Histories/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#find
             * @methodOf lbServices.History
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Histories",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#findOne
             * @methodOf lbServices.History
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Histories/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#updateAll
             * @methodOf lbServices.History
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Histories/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#deleteById
             * @methodOf lbServices.History
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Histories/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#count
             * @methodOf lbServices.History
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Histories/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#prototype$updateAttributes
             * @methodOf lbServices.History
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - History id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Histories/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.History#createChangeStream
             * @methodOf lbServices.History
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Histories/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Events.History() instead.
            "::get::Events::History": {
              url: urlBase + "/Events/:id/History",
              method: "GET",
            },

            // INTERNAL. Use Structures.History.findById() instead.
            "::findById::Structures::History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/History/:fk",
              method: "GET",
            },

            // INTERNAL. Use Structures.History.destroyById() instead.
            "::destroyById::Structures::History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/History/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Structures.History.updateById() instead.
            "::updateById::Structures::History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/History/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Structures.History() instead.
            "::get::Structures::History": {
              isArray: true,
              url: urlBase + "/Structures/:id/History",
              method: "GET",
            },

            // INTERNAL. Use Structures.History.create() instead.
            "::create::Structures::History": {
              url: urlBase + "/Structures/:id/History",
              method: "POST",
            },

            // INTERNAL. Use Structures.History.createMany() instead.
            "::createMany::Structures::History": {
              isArray: true,
              url: urlBase + "/Structures/:id/History",
              method: "POST",
            },

            // INTERNAL. Use Structures.History.destroyAll() instead.
            "::delete::Structures::History": {
              url: urlBase + "/Structures/:id/History",
              method: "DELETE",
            },

            // INTERNAL. Use Structures.History.count() instead.
            "::count::Structures::History": {
              url: urlBase + "/Structures/:id/History/count",
              method: "GET",
            },

            // INTERNAL. Use Versions.History.findById() instead.
            "::findById::Versions::History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/History/:fk",
              method: "GET",
            },

            // INTERNAL. Use Versions.History.destroyById() instead.
            "::destroyById::Versions::History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/History/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Versions.History.updateById() instead.
            "::updateById::Versions::History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/History/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Versions.History() instead.
            "::get::Versions::History": {
              isArray: true,
              url: urlBase + "/Versions/:id/History",
              method: "GET",
            },

            // INTERNAL. Use Versions.History.create() instead.
            "::create::Versions::History": {
              url: urlBase + "/Versions/:id/History",
              method: "POST",
            },

            // INTERNAL. Use Versions.History.createMany() instead.
            "::createMany::Versions::History": {
              isArray: true,
              url: urlBase + "/Versions/:id/History",
              method: "POST",
            },

            // INTERNAL. Use Versions.History.destroyAll() instead.
            "::delete::Versions::History": {
              url: urlBase + "/Versions/:id/History",
              method: "DELETE",
            },

            // INTERNAL. Use Versions.History.count() instead.
            "::count::Versions::History": {
              url: urlBase + "/Versions/:id/History/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.History#patchOrCreate
             * @methodOf lbServices.History
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.History#updateOrCreate
             * @methodOf lbServices.History
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.History#patchOrCreateWithWhere
             * @methodOf lbServices.History
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.History#update
             * @methodOf lbServices.History
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.History#destroyById
             * @methodOf lbServices.History
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.History#removeById
             * @methodOf lbServices.History
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.History#patchAttributes
             * @methodOf lbServices.History
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - History id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.History#modelName
        * @propertyOf lbServices.History
        * @description
        * The name of the model represented by this $resource,
        * i.e. `History`.
        */
        R.modelName = "History";


            /**
             * @ngdoc method
             * @name lbServices.History#Structures
             * @methodOf lbServices.History
             *
             * @description
             *
             * Fetches belongsTo relation Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - History id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::get::History::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.History#versions
             * @methodOf lbServices.History
             *
             * @description
             *
             * Fetches belongsTo relation versions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - History id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R.versions = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::get::History::versions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.History#versionsNew
             * @methodOf lbServices.History
             *
             * @description
             *
             * Fetches belongsTo relation versionsNew.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - History id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R.versionsNew = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::get::History::versionsNew"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Insurers
 * @header lbServices.Insurers
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Insurers` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Insurers",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Insurers/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Insurers.ProductIds.findById() instead.
            "prototype$__findById__ProductIds": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Insurers/:id/ProductIds/:fk",
              method: "GET",
            },

            // INTERNAL. Use Insurers.ProductIds.destroyById() instead.
            "prototype$__destroyById__ProductIds": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Insurers/:id/ProductIds/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Insurers.ProductIds.updateById() instead.
            "prototype$__updateById__ProductIds": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Insurers/:id/ProductIds/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#__get__notDeleted
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Queries notDeleted of Insurers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Insurers/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#__create__notDeleted
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Insurers/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#__delete__notDeleted
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Insurers/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#__count__notDeleted
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Counts notDeleted of Insurers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Insurers/notDeleted/count",
              method: "GET",
            },

            // INTERNAL. Use Insurers.ProductIds() instead.
            "prototype$__get__ProductIds": {
              isArray: true,
              url: urlBase + "/Insurers/:id/ProductIds",
              method: "GET",
            },

            // INTERNAL. Use Insurers.ProductIds.create() instead.
            "prototype$__create__ProductIds": {
              url: urlBase + "/Insurers/:id/ProductIds",
              method: "POST",
            },

            // INTERNAL. Use Insurers.ProductIds.destroyAll() instead.
            "prototype$__delete__ProductIds": {
              url: urlBase + "/Insurers/:id/ProductIds",
              method: "DELETE",
            },

            // INTERNAL. Use Insurers.ProductIds.count() instead.
            "prototype$__count__ProductIds": {
              url: urlBase + "/Insurers/:id/ProductIds/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#create
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Insurers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#createMany
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Insurers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#upsert
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Insurers",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#replaceOrCreate
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Insurers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#upsertWithWhere
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Insurers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#exists
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Insurers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#findById
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Insurers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#replaceById
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Insurers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#find
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Insurers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#findOne
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Insurers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#updateAll
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Insurers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#deleteById
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Insurers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#count
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Insurers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#prototype$updateAttributes
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Insurers id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Insurers/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#createChangeStream
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Insurers/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insurers#getInsurersAndDocCount
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Get a list insurers and a count of documents associated with them
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
            "getInsurersAndDocCount": {
              isArray: true,
              url: urlBase + "/Insurers/getInsurersAndDocCount",
              method: "POST",
            },

            // INTERNAL. Use Books.Insurers() instead.
            "::get::Books::Insurers": {
              url: urlBase + "/Books/:id/Insurers",
              method: "GET",
            },

            // INTERNAL. Use ProductIds.Insurers() instead.
            "::get::ProductIds::Insurers": {
              url: urlBase + "/ProductIds/:id/Insurers",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Insurers#patchOrCreate
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Insurers#updateOrCreate
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Insurers#patchOrCreateWithWhere
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Insurers#update
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Insurers#destroyById
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Insurers#removeById
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Insurers#patchAttributes
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Insurers id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Insurers#modelName
        * @propertyOf lbServices.Insurers
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Insurers`.
        */
        R.modelName = "Insurers";

    /**
     * @ngdoc object
     * @name lbServices.Insurers.ProductIds
     * @header lbServices.Insurers.ProductIds
     * @object
     * @description
     *
     * The object `Insurers.ProductIds` groups methods
     * manipulating `ProductIds` instances related to `Insurers`.
     *
     * Call {@link lbServices.Insurers#ProductIds Insurers.ProductIds()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Insurers#ProductIds
             * @methodOf lbServices.Insurers
             *
             * @description
             *
             * Queries ProductIds of Insurers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Insurers id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R.ProductIds = function() {
          var TargetResource = $injector.get("ProductIds");
          var action = TargetResource["::get::Insurers::ProductIds"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Insurers.ProductIds#count
             * @methodOf lbServices.Insurers.ProductIds
             *
             * @description
             *
             * Counts ProductIds of Insurers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Insurers id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.ProductIds.count = function() {
          var TargetResource = $injector.get("ProductIds");
          var action = TargetResource["::count::Insurers::ProductIds"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Insurers.ProductIds#create
             * @methodOf lbServices.Insurers.ProductIds
             *
             * @description
             *
             * Creates a new instance in ProductIds of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Insurers id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R.ProductIds.create = function() {
          var TargetResource = $injector.get("ProductIds");
          var action = TargetResource["::create::Insurers::ProductIds"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Insurers.ProductIds#createMany
             * @methodOf lbServices.Insurers.ProductIds
             *
             * @description
             *
             * Creates a new instance in ProductIds of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Insurers id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R.ProductIds.createMany = function() {
          var TargetResource = $injector.get("ProductIds");
          var action = TargetResource["::createMany::Insurers::ProductIds"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Insurers.ProductIds#destroyAll
             * @methodOf lbServices.Insurers.ProductIds
             *
             * @description
             *
             * Deletes all ProductIds of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Insurers id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.ProductIds.destroyAll = function() {
          var TargetResource = $injector.get("ProductIds");
          var action = TargetResource["::delete::Insurers::ProductIds"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Insurers.ProductIds#destroyById
             * @methodOf lbServices.Insurers.ProductIds
             *
             * @description
             *
             * Delete a related item by id for ProductIds.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Insurers id
             *
             *  - `fk` – `{*}` - Foreign key for ProductIds
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.ProductIds.destroyById = function() {
          var TargetResource = $injector.get("ProductIds");
          var action = TargetResource["::destroyById::Insurers::ProductIds"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Insurers.ProductIds#findById
             * @methodOf lbServices.Insurers.ProductIds
             *
             * @description
             *
             * Find a related item by id for ProductIds.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Insurers id
             *
             *  - `fk` – `{*}` - Foreign key for ProductIds
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R.ProductIds.findById = function() {
          var TargetResource = $injector.get("ProductIds");
          var action = TargetResource["::findById::Insurers::ProductIds"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Insurers.ProductIds#updateById
             * @methodOf lbServices.Insurers.ProductIds
             *
             * @description
             *
             * Update a related item by id for ProductIds.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Insurers id
             *
             *  - `fk` – `{*}` - Foreign key for ProductIds
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R.ProductIds.updateById = function() {
          var TargetResource = $injector.get("ProductIds");
          var action = TargetResource["::updateById::Insurers::ProductIds"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.InsurerDetails
 * @header lbServices.InsurerDetails
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `InsurerDetails` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "InsurerDetails",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/InsurerDetails/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use InsurerDetails.Users.findById() instead.
            "prototype$__findById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InsurerDetails/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use InsurerDetails.Users.destroyById() instead.
            "prototype$__destroyById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InsurerDetails/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use InsurerDetails.Users.updateById() instead.
            "prototype$__updateById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InsurerDetails/:id/Users/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#__get__notDeleted
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Queries notDeleted of InsurerDetails.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/InsurerDetails/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#__create__notDeleted
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/InsurerDetails/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#__delete__notDeleted
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/InsurerDetails/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#__count__notDeleted
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Counts notDeleted of InsurerDetails.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/InsurerDetails/notDeleted/count",
              method: "GET",
            },

            // INTERNAL. Use InsurerDetails.Users() instead.
            "prototype$__get__Users": {
              isArray: true,
              url: urlBase + "/InsurerDetails/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use InsurerDetails.Users.create() instead.
            "prototype$__create__Users": {
              url: urlBase + "/InsurerDetails/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use InsurerDetails.Users.destroyAll() instead.
            "prototype$__delete__Users": {
              url: urlBase + "/InsurerDetails/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use InsurerDetails.Users.count() instead.
            "prototype$__count__Users": {
              url: urlBase + "/InsurerDetails/:id/Users/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#create
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/InsurerDetails",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#createMany
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/InsurerDetails",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#upsert
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/InsurerDetails",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#replaceOrCreate
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/InsurerDetails/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#upsertWithWhere
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/InsurerDetails/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#exists
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/InsurerDetails/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#findById
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/InsurerDetails/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#replaceById
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/InsurerDetails/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#find
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/InsurerDetails",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#findOne
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/InsurerDetails/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#updateAll
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/InsurerDetails/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#deleteById
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/InsurerDetails/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#count
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/InsurerDetails/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#prototype$updateAttributes
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - InsurerDetails id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/InsurerDetails/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#createChangeStream
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/InsurerDetails/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#setStatusByBookId
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Set Product IDs status to 0 based on book id
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "setStatusByBookId": {
              isArray: true,
              url: urlBase + "/InsurerDetails/setStatusByBookId",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#duplicateByBookId
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Set Product IDs status to 0 based on book id
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
            "duplicateByBookId": {
              isArray: true,
              url: urlBase + "/InsurerDetails/duplicateByBookId",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#patchOrCreate
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#updateOrCreate
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#patchOrCreateWithWhere
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#update
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#destroyById
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#removeById
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#patchAttributes
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - InsurerDetails id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `InsurerDetails` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.InsurerDetails#modelName
        * @propertyOf lbServices.InsurerDetails
        * @description
        * The name of the model represented by this $resource,
        * i.e. `InsurerDetails`.
        */
        R.modelName = "InsurerDetails";

    /**
     * @ngdoc object
     * @name lbServices.InsurerDetails.Users
     * @header lbServices.InsurerDetails.Users
     * @object
     * @description
     *
     * The object `InsurerDetails.Users` groups methods
     * manipulating `Users` instances related to `InsurerDetails`.
     *
     * Call {@link lbServices.InsurerDetails#Users InsurerDetails.Users()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails#Users
             * @methodOf lbServices.InsurerDetails
             *
             * @description
             *
             * Queries Users of InsurerDetails.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - InsurerDetails id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::get::InsurerDetails::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails.Users#count
             * @methodOf lbServices.InsurerDetails.Users
             *
             * @description
             *
             * Counts Users of InsurerDetails.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - InsurerDetails id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Users.count = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::count::InsurerDetails::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails.Users#create
             * @methodOf lbServices.InsurerDetails.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - InsurerDetails id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.create = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::create::InsurerDetails::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails.Users#createMany
             * @methodOf lbServices.InsurerDetails.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - InsurerDetails id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.createMany = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::createMany::InsurerDetails::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails.Users#destroyAll
             * @methodOf lbServices.InsurerDetails.Users
             *
             * @description
             *
             * Deletes all Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - InsurerDetails id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyAll = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::delete::InsurerDetails::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails.Users#destroyById
             * @methodOf lbServices.InsurerDetails.Users
             *
             * @description
             *
             * Delete a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - InsurerDetails id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::destroyById::InsurerDetails::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails.Users#findById
             * @methodOf lbServices.InsurerDetails.Users
             *
             * @description
             *
             * Find a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - InsurerDetails id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.findById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::findById::InsurerDetails::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.InsurerDetails.Users#updateById
             * @methodOf lbServices.InsurerDetails.Users
             *
             * @description
             *
             * Update a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - InsurerDetails id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.updateById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::updateById::InsurerDetails::Users"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Languages
 * @header lbServices.Languages
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Languages` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Languages",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Languages/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Languages#__get__notDeleted
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Queries notDeleted of Languages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Languages/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#__create__notDeleted
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Languages/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#__delete__notDeleted
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Languages/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#__count__notDeleted
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Counts notDeleted of Languages.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Languages/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#create
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Languages",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#createMany
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Languages",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#upsert
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Languages",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#replaceOrCreate
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Languages/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#upsertWithWhere
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Languages/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#exists
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Languages/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#findById
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Languages/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#replaceById
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Languages/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#find
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Languages",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#findOne
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Languages/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#updateAll
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Languages/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#deleteById
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Languages/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#count
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Languages/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#prototype$updateAttributes
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Languages id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Languages/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Languages#createChangeStream
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Languages/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Languages#patchOrCreate
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Languages#updateOrCreate
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Languages#patchOrCreateWithWhere
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Languages#update
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Languages#destroyById
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Languages#removeById
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Languages#patchAttributes
             * @methodOf lbServices.Languages
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Languages id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Languages` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Languages#modelName
        * @propertyOf lbServices.Languages
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Languages`.
        */
        R.modelName = "Languages";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Locations
 * @header lbServices.Locations
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Locations` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Locations",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Locations/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Locations.Users.findById() instead.
            "prototype$__findById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Locations/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Locations.Users.destroyById() instead.
            "prototype$__destroyById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Locations/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Locations.Users.updateById() instead.
            "prototype$__updateById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Locations/:id/Users/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#__get__notDeleted
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Queries notDeleted of Locations.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Locations/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#__create__notDeleted
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Locations/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#__delete__notDeleted
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Locations/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#__count__notDeleted
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Counts notDeleted of Locations.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Locations/notDeleted/count",
              method: "GET",
            },

            // INTERNAL. Use Locations.Users() instead.
            "prototype$__get__Users": {
              isArray: true,
              url: urlBase + "/Locations/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Locations.Users.create() instead.
            "prototype$__create__Users": {
              url: urlBase + "/Locations/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Locations.Users.destroyAll() instead.
            "prototype$__delete__Users": {
              url: urlBase + "/Locations/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use Locations.Users.count() instead.
            "prototype$__count__Users": {
              url: urlBase + "/Locations/:id/Users/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#create
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Locations",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#createMany
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Locations",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#upsert
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Locations",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#replaceOrCreate
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Locations/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#upsertWithWhere
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Locations/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#exists
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Locations/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#findById
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Locations/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#replaceById
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Locations/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#find
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Locations",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#findOne
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Locations/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#updateAll
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Locations/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#deleteById
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Locations/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#count
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Locations/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#prototype$updateAttributes
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Locations id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Locations/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Locations#createChangeStream
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Locations/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Users.Locations() instead.
            "::get::Users::Locations": {
              url: urlBase + "/Users/:id/Locations",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Locations#patchOrCreate
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Locations#updateOrCreate
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Locations#patchOrCreateWithWhere
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Locations#update
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Locations#destroyById
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Locations#removeById
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Locations#patchAttributes
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Locations id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Locations#modelName
        * @propertyOf lbServices.Locations
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Locations`.
        */
        R.modelName = "Locations";

    /**
     * @ngdoc object
     * @name lbServices.Locations.Users
     * @header lbServices.Locations.Users
     * @object
     * @description
     *
     * The object `Locations.Users` groups methods
     * manipulating `Users` instances related to `Locations`.
     *
     * Call {@link lbServices.Locations#Users Locations.Users()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Locations#Users
             * @methodOf lbServices.Locations
             *
             * @description
             *
             * Queries Users of Locations.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Locations id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::get::Locations::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Locations.Users#count
             * @methodOf lbServices.Locations.Users
             *
             * @description
             *
             * Counts Users of Locations.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Locations id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Users.count = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::count::Locations::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Locations.Users#create
             * @methodOf lbServices.Locations.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Locations id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.create = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::create::Locations::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Locations.Users#createMany
             * @methodOf lbServices.Locations.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Locations id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.createMany = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::createMany::Locations::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Locations.Users#destroyAll
             * @methodOf lbServices.Locations.Users
             *
             * @description
             *
             * Deletes all Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Locations id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyAll = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::delete::Locations::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Locations.Users#destroyById
             * @methodOf lbServices.Locations.Users
             *
             * @description
             *
             * Delete a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Locations id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::destroyById::Locations::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Locations.Users#findById
             * @methodOf lbServices.Locations.Users
             *
             * @description
             *
             * Find a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Locations id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.findById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::findById::Locations::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Locations.Users#updateById
             * @methodOf lbServices.Locations.Users
             *
             * @description
             *
             * Update a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Locations id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.updateById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::updateById::Locations::Users"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Messages
 * @header lbServices.Messages
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Messages` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Messages",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Messages/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Messages#create
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Messages",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#createMany
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Messages",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#upsert
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Messages",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#replaceOrCreate
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Messages/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#upsertWithWhere
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Messages/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#exists
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Messages/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#findById
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Messages/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#replaceById
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Messages/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#find
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Messages",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#findOne
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Messages/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#updateAll
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Messages/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#deleteById
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Messages/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#count
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Messages/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#prototype$updateAttributes
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Messages id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Messages/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#createChangeStream
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Messages/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Messages#sendmail
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * sendmail
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
            "sendmail": {
              url: urlBase + "/Messages/sendmail",
              method: "POST",
            },

            // INTERNAL. Use Events.Messages() instead.
            "::get::Events::Messages": {
              url: urlBase + "/Events/:id/Messages",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Messages#patchOrCreate
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Messages#updateOrCreate
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Messages#patchOrCreateWithWhere
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Messages#update
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Messages#destroyById
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Messages#removeById
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Messages#patchAttributes
             * @methodOf lbServices.Messages
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Messages id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Messages` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Messages#modelName
        * @propertyOf lbServices.Messages
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Messages`.
        */
        R.modelName = "Messages";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.MetaTables
 * @header lbServices.MetaTables
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `MetaTables` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "MetaTables",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/MetaTables/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#create
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/MetaTables",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#createMany
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/MetaTables",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#upsert
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/MetaTables",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#replaceOrCreate
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/MetaTables/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#upsertWithWhere
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/MetaTables/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#exists
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/MetaTables/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#findById
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/MetaTables/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#replaceById
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/MetaTables/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#find
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/MetaTables",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#findOne
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/MetaTables/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#updateAll
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/MetaTables/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#deleteById
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/MetaTables/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#count
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/MetaTables/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#prototype$updateAttributes
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - MetaTables id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/MetaTables/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#createChangeStream
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/MetaTables/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#getLastUpdatedCounts
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Get a count of how many meta-list items have been updated since the users last fetch
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
            "getLastUpdatedCounts": {
              url: urlBase + "/MetaTables/getLastUpdatedCounts",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.MetaTables#patchOrCreate
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#updateOrCreate
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#patchOrCreateWithWhere
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#update
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#destroyById
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#removeById
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.MetaTables#patchAttributes
             * @methodOf lbServices.MetaTables
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - MetaTables id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `MetaTables` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.MetaTables#modelName
        * @propertyOf lbServices.MetaTables
        * @description
        * The name of the model represented by this $resource,
        * i.e. `MetaTables`.
        */
        R.modelName = "MetaTables";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Notifications
 * @header lbServices.Notifications
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Notifications` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Notifications",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Notifications/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Notifications#create
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Notifications",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#createMany
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Notifications",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#upsert
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Notifications",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#replaceOrCreate
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Notifications/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#upsertWithWhere
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Notifications/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#exists
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Notifications/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#findById
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Notifications/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#replaceById
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Notifications/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#find
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Notifications",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#findOne
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Notifications/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#updateAll
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Notifications/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#deleteById
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Notifications/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#count
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Notifications/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#prototype$updateAttributes
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notifications id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Notifications/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#createChangeStream
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Notifications/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#sendApprovalNotifications_day3
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Send Approval Notifications Day 3
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "sendApprovalNotifications_day3": {
              url: urlBase + "/Notifications/sendApprovalNotifications_day3",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#sendApprovalNotifications_day5
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Send Approval Notifications Day 5
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "sendApprovalNotifications_day5": {
              url: urlBase + "/Notifications/sendApprovalNotifications_day5",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#sendApprovalNotifications_day7
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Send Approval Notifications Day 7
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "sendApprovalNotifications_day7": {
              url: urlBase + "/Notifications/sendApprovalNotifications_day7",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#sendExpiryNotifications
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Send Review Notifications
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "sendExpiryNotifications": {
              url: urlBase + "/Notifications/sendExpiryNotifications",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#sendTestNotification
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * sendTestNotification
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "sendTestNotification": {
              url: urlBase + "/Notifications/sendTestNotification",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#sendExpiryReminderNotifications
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Send Review Notifications
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "sendExpiryReminderNotifications": {
              url: urlBase + "/Notifications/sendExpiryReminderNotifications",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#sendReviewNotifications
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Send Review Notifications
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "sendReviewNotifications": {
              url: urlBase + "/Notifications/sendReviewNotifications",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#sendReviewEventNotification
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Send Review Event Notification
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "sendReviewEventNotification": {
              url: urlBase + "/Notifications/sendReviewEventNotification",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifications#sendEventNotification
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Send Event Notification
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
            "sendEventNotification": {
              url: urlBase + "/Notifications/sendEventNotification",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Notifications#patchOrCreate
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Notifications#updateOrCreate
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Notifications#patchOrCreateWithWhere
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Notifications#update
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Notifications#destroyById
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Notifications#removeById
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Notifications#patchAttributes
             * @methodOf lbServices.Notifications
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Notifications id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifications` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Notifications#modelName
        * @propertyOf lbServices.Notifications
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Notifications`.
        */
        R.modelName = "Notifications";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.NotificationTypes
 * @header lbServices.NotificationTypes
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `NotificationTypes` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "NotificationTypes",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/NotificationTypes/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#create
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/NotificationTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#createMany
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/NotificationTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#upsert
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/NotificationTypes",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#replaceOrCreate
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/NotificationTypes/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#upsertWithWhere
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/NotificationTypes/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#exists
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/NotificationTypes/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#findById
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/NotificationTypes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#replaceById
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/NotificationTypes/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#find
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/NotificationTypes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#findOne
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/NotificationTypes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#updateAll
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/NotificationTypes/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#deleteById
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/NotificationTypes/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#count
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/NotificationTypes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#prototype$updateAttributes
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - NotificationTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/NotificationTypes/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#createChangeStream
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/NotificationTypes/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#patchOrCreate
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#updateOrCreate
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#patchOrCreateWithWhere
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#update
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#destroyById
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#removeById
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.NotificationTypes#patchAttributes
             * @methodOf lbServices.NotificationTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - NotificationTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationTypes` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.NotificationTypes#modelName
        * @propertyOf lbServices.NotificationTypes
        * @description
        * The name of the model represented by this $resource,
        * i.e. `NotificationTypes`.
        */
        R.modelName = "NotificationTypes";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.OrderHistory
 * @header lbServices.OrderHistory
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `OrderHistory` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "OrderHistory",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/OrderHistories/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use OrderHistory.Structures() instead.
            "prototype$__get__Structures": {
              url: urlBase + "/OrderHistories/:id/Structures",
              method: "GET",
            },

            // INTERNAL. Use OrderHistory.versions() instead.
            "prototype$__get__versions": {
              url: urlBase + "/OrderHistories/:id/versions",
              method: "GET",
            },

            // INTERNAL. Use OrderHistory.versionsNew() instead.
            "prototype$__get__versionsNew": {
              url: urlBase + "/OrderHistories/:id/versionsNew",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#create
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/OrderHistories",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#createMany
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/OrderHistories",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#upsert
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/OrderHistories",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#replaceOrCreate
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/OrderHistories/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#upsertWithWhere
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/OrderHistories/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#exists
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/OrderHistories/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#findById
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/OrderHistories/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#replaceById
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/OrderHistories/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#find
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/OrderHistories",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#findOne
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/OrderHistories/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#updateAll
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/OrderHistories/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#deleteById
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/OrderHistories/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#count
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/OrderHistories/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#prototype$updateAttributes
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - OrderHistory id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/OrderHistories/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#createChangeStream
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/OrderHistories/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#patchOrCreate
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#updateOrCreate
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#patchOrCreateWithWhere
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#update
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#destroyById
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#removeById
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#patchAttributes
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - OrderHistory id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `OrderHistory` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.OrderHistory#modelName
        * @propertyOf lbServices.OrderHistory
        * @description
        * The name of the model represented by this $resource,
        * i.e. `OrderHistory`.
        */
        R.modelName = "OrderHistory";


            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#Structures
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Fetches belongsTo relation Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - OrderHistory id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::get::OrderHistory::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#versions
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Fetches belongsTo relation versions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - OrderHistory id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R.versions = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::get::OrderHistory::versions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.OrderHistory#versionsNew
             * @methodOf lbServices.OrderHistory
             *
             * @description
             *
             * Fetches belongsTo relation versionsNew.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - OrderHistory id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R.versionsNew = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::get::OrderHistory::versionsNew"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.PdfVersions
 * @header lbServices.PdfVersions
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `PdfVersions` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "PdfVersions",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/PdfVersions/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#create
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/PdfVersions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#createMany
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/PdfVersions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#upsert
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/PdfVersions",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#replaceOrCreate
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/PdfVersions/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#upsertWithWhere
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/PdfVersions/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#exists
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/PdfVersions/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#findById
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/PdfVersions/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#replaceById
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/PdfVersions/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#find
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/PdfVersions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#findOne
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/PdfVersions/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#updateAll
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/PdfVersions/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#deleteById
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/PdfVersions/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#count
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/PdfVersions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#prototype$updateAttributes
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PdfVersions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/PdfVersions/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#createChangeStream
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/PdfVersions/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#patchOrCreate
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#updateOrCreate
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#patchOrCreateWithWhere
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#update
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#destroyById
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#removeById
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PdfVersions#patchAttributes
             * @methodOf lbServices.PdfVersions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PdfVersions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PdfVersions` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.PdfVersions#modelName
        * @propertyOf lbServices.PdfVersions
        * @description
        * The name of the model represented by this $resource,
        * i.e. `PdfVersions`.
        */
        R.modelName = "PdfVersions";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.PermissionCategories
 * @header lbServices.PermissionCategories
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `PermissionCategories` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "PermissionCategories",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/PermissionCategories/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use PermissionCategories.Permissions.findById() instead.
            "prototype$__findById__Permissions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionCategories/:id/Permissions/:fk",
              method: "GET",
            },

            // INTERNAL. Use PermissionCategories.Permissions.destroyById() instead.
            "prototype$__destroyById__Permissions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionCategories/:id/Permissions/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use PermissionCategories.Permissions.updateById() instead.
            "prototype$__updateById__Permissions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionCategories/:id/Permissions/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#__get__notDeleted
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Queries notDeleted of PermissionCategories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/PermissionCategories/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#__create__notDeleted
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/PermissionCategories/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#__delete__notDeleted
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/PermissionCategories/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#__count__notDeleted
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Counts notDeleted of PermissionCategories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/PermissionCategories/notDeleted/count",
              method: "GET",
            },

            // INTERNAL. Use PermissionCategories.Permissions() instead.
            "prototype$__get__Permissions": {
              isArray: true,
              url: urlBase + "/PermissionCategories/:id/Permissions",
              method: "GET",
            },

            // INTERNAL. Use PermissionCategories.Permissions.create() instead.
            "prototype$__create__Permissions": {
              url: urlBase + "/PermissionCategories/:id/Permissions",
              method: "POST",
            },

            // INTERNAL. Use PermissionCategories.Permissions.destroyAll() instead.
            "prototype$__delete__Permissions": {
              url: urlBase + "/PermissionCategories/:id/Permissions",
              method: "DELETE",
            },

            // INTERNAL. Use PermissionCategories.Permissions.count() instead.
            "prototype$__count__Permissions": {
              url: urlBase + "/PermissionCategories/:id/Permissions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#create
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/PermissionCategories",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#createMany
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/PermissionCategories",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#upsert
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/PermissionCategories",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#replaceOrCreate
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/PermissionCategories/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#upsertWithWhere
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/PermissionCategories/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#exists
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/PermissionCategories/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#findById
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/PermissionCategories/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#replaceById
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/PermissionCategories/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#find
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/PermissionCategories",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#findOne
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/PermissionCategories/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#updateAll
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/PermissionCategories/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#deleteById
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/PermissionCategories/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#count
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/PermissionCategories/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#prototype$updateAttributes
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionCategories id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/PermissionCategories/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#createChangeStream
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/PermissionCategories/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Permissions.PermissionCategories() instead.
            "::get::Permissions::PermissionCategories": {
              url: urlBase + "/Permissions/:id/PermissionCategories",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#patchOrCreate
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#updateOrCreate
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#patchOrCreateWithWhere
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#update
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#destroyById
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#removeById
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#patchAttributes
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionCategories id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.PermissionCategories#modelName
        * @propertyOf lbServices.PermissionCategories
        * @description
        * The name of the model represented by this $resource,
        * i.e. `PermissionCategories`.
        */
        R.modelName = "PermissionCategories";

    /**
     * @ngdoc object
     * @name lbServices.PermissionCategories.Permissions
     * @header lbServices.PermissionCategories.Permissions
     * @object
     * @description
     *
     * The object `PermissionCategories.Permissions` groups methods
     * manipulating `Permissions` instances related to `PermissionCategories`.
     *
     * Call {@link lbServices.PermissionCategories#Permissions PermissionCategories.Permissions()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories#Permissions
             * @methodOf lbServices.PermissionCategories
             *
             * @description
             *
             * Queries Permissions of PermissionCategories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionCategories id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R.Permissions = function() {
          var TargetResource = $injector.get("Permissions");
          var action = TargetResource["::get::PermissionCategories::Permissions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories.Permissions#count
             * @methodOf lbServices.PermissionCategories.Permissions
             *
             * @description
             *
             * Counts Permissions of PermissionCategories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionCategories id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Permissions.count = function() {
          var TargetResource = $injector.get("Permissions");
          var action = TargetResource["::count::PermissionCategories::Permissions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories.Permissions#create
             * @methodOf lbServices.PermissionCategories.Permissions
             *
             * @description
             *
             * Creates a new instance in Permissions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionCategories id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R.Permissions.create = function() {
          var TargetResource = $injector.get("Permissions");
          var action = TargetResource["::create::PermissionCategories::Permissions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories.Permissions#createMany
             * @methodOf lbServices.PermissionCategories.Permissions
             *
             * @description
             *
             * Creates a new instance in Permissions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionCategories id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R.Permissions.createMany = function() {
          var TargetResource = $injector.get("Permissions");
          var action = TargetResource["::createMany::PermissionCategories::Permissions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories.Permissions#destroyAll
             * @methodOf lbServices.PermissionCategories.Permissions
             *
             * @description
             *
             * Deletes all Permissions of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionCategories id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Permissions.destroyAll = function() {
          var TargetResource = $injector.get("Permissions");
          var action = TargetResource["::delete::PermissionCategories::Permissions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories.Permissions#destroyById
             * @methodOf lbServices.PermissionCategories.Permissions
             *
             * @description
             *
             * Delete a related item by id for Permissions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionCategories id
             *
             *  - `fk` – `{*}` - Foreign key for Permissions
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Permissions.destroyById = function() {
          var TargetResource = $injector.get("Permissions");
          var action = TargetResource["::destroyById::PermissionCategories::Permissions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories.Permissions#findById
             * @methodOf lbServices.PermissionCategories.Permissions
             *
             * @description
             *
             * Find a related item by id for Permissions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionCategories id
             *
             *  - `fk` – `{*}` - Foreign key for Permissions
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R.Permissions.findById = function() {
          var TargetResource = $injector.get("Permissions");
          var action = TargetResource["::findById::PermissionCategories::Permissions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionCategories.Permissions#updateById
             * @methodOf lbServices.PermissionCategories.Permissions
             *
             * @description
             *
             * Update a related item by id for Permissions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionCategories id
             *
             *  - `fk` – `{*}` - Foreign key for Permissions
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R.Permissions.updateById = function() {
          var TargetResource = $injector.get("Permissions");
          var action = TargetResource["::updateById::PermissionCategories::Permissions"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.PermissionGroupJoin
 * @header lbServices.PermissionGroupJoin
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `PermissionGroupJoin` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "PermissionGroupJoin",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/PermissionGroupJoins/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use PermissionGroupJoin.Permissions() instead.
            "prototype$__get__Permissions": {
              url: urlBase + "/PermissionGroupJoins/:id/Permissions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#create
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/PermissionGroupJoins",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#createMany
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/PermissionGroupJoins",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#upsert
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/PermissionGroupJoins",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#replaceOrCreate
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/PermissionGroupJoins/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#upsertWithWhere
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/PermissionGroupJoins/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#exists
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/PermissionGroupJoins/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#findById
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/PermissionGroupJoins/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#replaceById
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/PermissionGroupJoins/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#find
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/PermissionGroupJoins",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#findOne
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/PermissionGroupJoins/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#updateAll
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/PermissionGroupJoins/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#deleteById
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/PermissionGroupJoins/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#count
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/PermissionGroupJoins/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#prototype$updateAttributes
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroupJoin id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/PermissionGroupJoins/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#createChangeStream
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/PermissionGroupJoins/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#markPermissionGroupJoinAsDeleted
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Get list of books in search with count
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "markPermissionGroupJoinAsDeleted": {
              url: urlBase + "/PermissionGroupJoins/markPermissionGroupJoinAsDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#assignPermissions
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * remove existing joins for current permission group and set new ones
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
            "assignPermissions": {
              url: urlBase + "/PermissionGroupJoins/assignPermissions",
              method: "POST",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.findById() instead.
            "::findById::PermissionGroups::PermissionGroupJoin": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin/:fk",
              method: "GET",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.destroyById() instead.
            "::destroyById::PermissionGroups::PermissionGroupJoin": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.updateById() instead.
            "::updateById::PermissionGroups::PermissionGroupJoin": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin/:fk",
              method: "PUT",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin() instead.
            "::get::PermissionGroups::PermissionGroupJoin": {
              isArray: true,
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin",
              method: "GET",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.create() instead.
            "::create::PermissionGroups::PermissionGroupJoin": {
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin",
              method: "POST",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.createMany() instead.
            "::createMany::PermissionGroups::PermissionGroupJoin": {
              isArray: true,
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin",
              method: "POST",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.destroyAll() instead.
            "::delete::PermissionGroups::PermissionGroupJoin": {
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin",
              method: "DELETE",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.count() instead.
            "::count::PermissionGroups::PermissionGroupJoin": {
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin/count",
              method: "GET",
            },

            // INTERNAL. Use Permissions.PermissionGroupJoin() instead.
            "::get::Permissions::PermissionGroupJoin": {
              url: urlBase + "/Permissions/:id/PermissionGroupJoin",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#patchOrCreate
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#updateOrCreate
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#patchOrCreateWithWhere
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#update
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#destroyById
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#removeById
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#patchAttributes
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroupJoin id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.PermissionGroupJoin#modelName
        * @propertyOf lbServices.PermissionGroupJoin
        * @description
        * The name of the model represented by this $resource,
        * i.e. `PermissionGroupJoin`.
        */
        R.modelName = "PermissionGroupJoin";


            /**
             * @ngdoc method
             * @name lbServices.PermissionGroupJoin#Permissions
             * @methodOf lbServices.PermissionGroupJoin
             *
             * @description
             *
             * Fetches belongsTo relation Permissions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroupJoin id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R.Permissions = function() {
          var TargetResource = $injector.get("Permissions");
          var action = TargetResource["::get::PermissionGroupJoin::Permissions"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.PermissionGroups
 * @header lbServices.PermissionGroups
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `PermissionGroups` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "PermissionGroups",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/PermissionGroups/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.findById() instead.
            "prototype$__findById__PermissionGroupJoin": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin/:fk",
              method: "GET",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.destroyById() instead.
            "prototype$__destroyById__PermissionGroupJoin": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.updateById() instead.
            "prototype$__updateById__PermissionGroupJoin": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin/:fk",
              method: "PUT",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin() instead.
            "prototype$__get__PermissionGroupJoin": {
              isArray: true,
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin",
              method: "GET",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.create() instead.
            "prototype$__create__PermissionGroupJoin": {
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin",
              method: "POST",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.destroyAll() instead.
            "prototype$__delete__PermissionGroupJoin": {
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin",
              method: "DELETE",
            },

            // INTERNAL. Use PermissionGroups.PermissionGroupJoin.count() instead.
            "prototype$__count__PermissionGroupJoin": {
              url: urlBase + "/PermissionGroups/:id/PermissionGroupJoin/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#__get__notDeleted
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Queries notDeleted of PermissionGroups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/PermissionGroups/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#__create__notDeleted
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/PermissionGroups/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#__delete__notDeleted
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/PermissionGroups/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#__count__notDeleted
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Counts notDeleted of PermissionGroups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/PermissionGroups/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#create
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/PermissionGroups",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#createMany
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/PermissionGroups",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#upsert
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/PermissionGroups",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#replaceOrCreate
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/PermissionGroups/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#upsertWithWhere
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/PermissionGroups/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#exists
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/PermissionGroups/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#findById
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/PermissionGroups/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#replaceById
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/PermissionGroups/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#find
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/PermissionGroups",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#findOne
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/PermissionGroups/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#updateAll
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/PermissionGroups/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#deleteById
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/PermissionGroups/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#count
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/PermissionGroups/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#prototype$updateAttributes
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroups id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/PermissionGroups/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#createChangeStream
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/PermissionGroups/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Users.PermissionGroups() instead.
            "::get::Users::PermissionGroups": {
              url: urlBase + "/Users/:id/PermissionGroups",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#patchOrCreate
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#updateOrCreate
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#patchOrCreateWithWhere
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#update
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#destroyById
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#removeById
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#patchAttributes
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroups id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.PermissionGroups#modelName
        * @propertyOf lbServices.PermissionGroups
        * @description
        * The name of the model represented by this $resource,
        * i.e. `PermissionGroups`.
        */
        R.modelName = "PermissionGroups";

    /**
     * @ngdoc object
     * @name lbServices.PermissionGroups.PermissionGroupJoin
     * @header lbServices.PermissionGroups.PermissionGroupJoin
     * @object
     * @description
     *
     * The object `PermissionGroups.PermissionGroupJoin` groups methods
     * manipulating `PermissionGroupJoin` instances related to `PermissionGroups`.
     *
     * Call {@link lbServices.PermissionGroups#PermissionGroupJoin PermissionGroups.PermissionGroupJoin()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups#PermissionGroupJoin
             * @methodOf lbServices.PermissionGroups
             *
             * @description
             *
             * Queries PermissionGroupJoin of PermissionGroups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroups id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R.PermissionGroupJoin = function() {
          var TargetResource = $injector.get("PermissionGroupJoin");
          var action = TargetResource["::get::PermissionGroups::PermissionGroupJoin"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups.PermissionGroupJoin#count
             * @methodOf lbServices.PermissionGroups.PermissionGroupJoin
             *
             * @description
             *
             * Counts PermissionGroupJoin of PermissionGroups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroups id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.PermissionGroupJoin.count = function() {
          var TargetResource = $injector.get("PermissionGroupJoin");
          var action = TargetResource["::count::PermissionGroups::PermissionGroupJoin"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups.PermissionGroupJoin#create
             * @methodOf lbServices.PermissionGroups.PermissionGroupJoin
             *
             * @description
             *
             * Creates a new instance in PermissionGroupJoin of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroups id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R.PermissionGroupJoin.create = function() {
          var TargetResource = $injector.get("PermissionGroupJoin");
          var action = TargetResource["::create::PermissionGroups::PermissionGroupJoin"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups.PermissionGroupJoin#createMany
             * @methodOf lbServices.PermissionGroups.PermissionGroupJoin
             *
             * @description
             *
             * Creates a new instance in PermissionGroupJoin of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroups id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R.PermissionGroupJoin.createMany = function() {
          var TargetResource = $injector.get("PermissionGroupJoin");
          var action = TargetResource["::createMany::PermissionGroups::PermissionGroupJoin"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups.PermissionGroupJoin#destroyAll
             * @methodOf lbServices.PermissionGroups.PermissionGroupJoin
             *
             * @description
             *
             * Deletes all PermissionGroupJoin of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroups id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.PermissionGroupJoin.destroyAll = function() {
          var TargetResource = $injector.get("PermissionGroupJoin");
          var action = TargetResource["::delete::PermissionGroups::PermissionGroupJoin"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups.PermissionGroupJoin#destroyById
             * @methodOf lbServices.PermissionGroups.PermissionGroupJoin
             *
             * @description
             *
             * Delete a related item by id for PermissionGroupJoin.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroups id
             *
             *  - `fk` – `{*}` - Foreign key for PermissionGroupJoin
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.PermissionGroupJoin.destroyById = function() {
          var TargetResource = $injector.get("PermissionGroupJoin");
          var action = TargetResource["::destroyById::PermissionGroups::PermissionGroupJoin"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups.PermissionGroupJoin#findById
             * @methodOf lbServices.PermissionGroups.PermissionGroupJoin
             *
             * @description
             *
             * Find a related item by id for PermissionGroupJoin.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroups id
             *
             *  - `fk` – `{*}` - Foreign key for PermissionGroupJoin
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R.PermissionGroupJoin.findById = function() {
          var TargetResource = $injector.get("PermissionGroupJoin");
          var action = TargetResource["::findById::PermissionGroups::PermissionGroupJoin"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.PermissionGroups.PermissionGroupJoin#updateById
             * @methodOf lbServices.PermissionGroups.PermissionGroupJoin
             *
             * @description
             *
             * Update a related item by id for PermissionGroupJoin.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PermissionGroups id
             *
             *  - `fk` – `{*}` - Foreign key for PermissionGroupJoin
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R.PermissionGroupJoin.updateById = function() {
          var TargetResource = $injector.get("PermissionGroupJoin");
          var action = TargetResource["::updateById::PermissionGroups::PermissionGroupJoin"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Permissions
 * @header lbServices.Permissions
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Permissions` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Permissions",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Permissions/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Permissions.PermissionGroupJoin() instead.
            "prototype$__get__PermissionGroupJoin": {
              url: urlBase + "/Permissions/:id/PermissionGroupJoin",
              method: "GET",
            },

            // INTERNAL. Use Permissions.PermissionCategories() instead.
            "prototype$__get__PermissionCategories": {
              url: urlBase + "/Permissions/:id/PermissionCategories",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#__get__notDeleted
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Queries notDeleted of Permissions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Permissions/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#__create__notDeleted
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Permissions/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#__delete__notDeleted
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Permissions/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#__count__notDeleted
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Counts notDeleted of Permissions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Permissions/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#create
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Permissions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#createMany
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Permissions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#upsert
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Permissions",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#replaceOrCreate
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Permissions/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#upsertWithWhere
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Permissions/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#exists
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Permissions/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#findById
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Permissions/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#replaceById
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Permissions/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#find
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Permissions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#findOne
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Permissions/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#updateAll
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Permissions/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#deleteById
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Permissions/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#count
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Permissions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#prototype$updateAttributes
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Permissions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Permissions/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Permissions#createChangeStream
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Permissions/change-stream",
              method: "POST",
            },

            // INTERNAL. Use PermissionCategories.Permissions.findById() instead.
            "::findById::PermissionCategories::Permissions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionCategories/:id/Permissions/:fk",
              method: "GET",
            },

            // INTERNAL. Use PermissionCategories.Permissions.destroyById() instead.
            "::destroyById::PermissionCategories::Permissions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionCategories/:id/Permissions/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use PermissionCategories.Permissions.updateById() instead.
            "::updateById::PermissionCategories::Permissions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/PermissionCategories/:id/Permissions/:fk",
              method: "PUT",
            },

            // INTERNAL. Use PermissionCategories.Permissions() instead.
            "::get::PermissionCategories::Permissions": {
              isArray: true,
              url: urlBase + "/PermissionCategories/:id/Permissions",
              method: "GET",
            },

            // INTERNAL. Use PermissionCategories.Permissions.create() instead.
            "::create::PermissionCategories::Permissions": {
              url: urlBase + "/PermissionCategories/:id/Permissions",
              method: "POST",
            },

            // INTERNAL. Use PermissionCategories.Permissions.createMany() instead.
            "::createMany::PermissionCategories::Permissions": {
              isArray: true,
              url: urlBase + "/PermissionCategories/:id/Permissions",
              method: "POST",
            },

            // INTERNAL. Use PermissionCategories.Permissions.destroyAll() instead.
            "::delete::PermissionCategories::Permissions": {
              url: urlBase + "/PermissionCategories/:id/Permissions",
              method: "DELETE",
            },

            // INTERNAL. Use PermissionCategories.Permissions.count() instead.
            "::count::PermissionCategories::Permissions": {
              url: urlBase + "/PermissionCategories/:id/Permissions/count",
              method: "GET",
            },

            // INTERNAL. Use PermissionGroupJoin.Permissions() instead.
            "::get::PermissionGroupJoin::Permissions": {
              url: urlBase + "/PermissionGroupJoins/:id/Permissions",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Permissions#patchOrCreate
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Permissions#updateOrCreate
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Permissions#patchOrCreateWithWhere
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Permissions#update
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Permissions#destroyById
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Permissions#removeById
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Permissions#patchAttributes
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Permissions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Permissions` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Permissions#modelName
        * @propertyOf lbServices.Permissions
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Permissions`.
        */
        R.modelName = "Permissions";


            /**
             * @ngdoc method
             * @name lbServices.Permissions#PermissionGroupJoin
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Fetches belongsTo relation PermissionGroupJoin.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Permissions id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroupJoin` object.)
             * </em>
             */
        R.PermissionGroupJoin = function() {
          var TargetResource = $injector.get("PermissionGroupJoin");
          var action = TargetResource["::get::Permissions::PermissionGroupJoin"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Permissions#PermissionCategories
             * @methodOf lbServices.Permissions
             *
             * @description
             *
             * Fetches belongsTo relation PermissionCategories.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Permissions id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionCategories` object.)
             * </em>
             */
        R.PermissionCategories = function() {
          var TargetResource = $injector.get("PermissionCategories");
          var action = TargetResource["::get::Permissions::PermissionCategories"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ProductIds
 * @header lbServices.ProductIds
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ProductIds` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ProductIds",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ProductIds/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use ProductIds.Insurers() instead.
            "prototype$__get__Insurers": {
              url: urlBase + "/ProductIds/:id/Insurers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#__get__notDeleted
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Queries notDeleted of ProductIds.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/ProductIds/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#__create__notDeleted
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/ProductIds/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#__delete__notDeleted
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/ProductIds/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#__count__notDeleted
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Counts notDeleted of ProductIds.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/ProductIds/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#create
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ProductIds",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#createMany
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ProductIds",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#upsert
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/ProductIds",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#replaceOrCreate
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ProductIds/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#upsertWithWhere
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ProductIds/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#exists
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ProductIds/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#findById
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ProductIds/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#replaceById
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ProductIds/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#find
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ProductIds",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#findOne
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ProductIds/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#updateAll
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ProductIds/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#deleteById
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ProductIds/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#count
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ProductIds/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#prototype$updateAttributes
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProductIds id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/ProductIds/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#createChangeStream
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ProductIds/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Insurers.ProductIds.findById() instead.
            "::findById::Insurers::ProductIds": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Insurers/:id/ProductIds/:fk",
              method: "GET",
            },

            // INTERNAL. Use Insurers.ProductIds.destroyById() instead.
            "::destroyById::Insurers::ProductIds": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Insurers/:id/ProductIds/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Insurers.ProductIds.updateById() instead.
            "::updateById::Insurers::ProductIds": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Insurers/:id/ProductIds/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Insurers.ProductIds() instead.
            "::get::Insurers::ProductIds": {
              isArray: true,
              url: urlBase + "/Insurers/:id/ProductIds",
              method: "GET",
            },

            // INTERNAL. Use Insurers.ProductIds.create() instead.
            "::create::Insurers::ProductIds": {
              url: urlBase + "/Insurers/:id/ProductIds",
              method: "POST",
            },

            // INTERNAL. Use Insurers.ProductIds.createMany() instead.
            "::createMany::Insurers::ProductIds": {
              isArray: true,
              url: urlBase + "/Insurers/:id/ProductIds",
              method: "POST",
            },

            // INTERNAL. Use Insurers.ProductIds.destroyAll() instead.
            "::delete::Insurers::ProductIds": {
              url: urlBase + "/Insurers/:id/ProductIds",
              method: "DELETE",
            },

            // INTERNAL. Use Insurers.ProductIds.count() instead.
            "::count::Insurers::ProductIds": {
              url: urlBase + "/Insurers/:id/ProductIds/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ProductIds#patchOrCreate
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#updateOrCreate
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#patchOrCreateWithWhere
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#update
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#destroyById
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#removeById
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIds#patchAttributes
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProductIds id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIds` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ProductIds#modelName
        * @propertyOf lbServices.ProductIds
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ProductIds`.
        */
        R.modelName = "ProductIds";


            /**
             * @ngdoc method
             * @name lbServices.ProductIds#Insurers
             * @methodOf lbServices.ProductIds
             *
             * @description
             *
             * Fetches belongsTo relation Insurers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProductIds id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insurers` object.)
             * </em>
             */
        R.Insurers = function() {
          var TargetResource = $injector.get("Insurers");
          var action = TargetResource["::get::ProductIds::Insurers"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ProductIdsBookLink
 * @header lbServices.ProductIdsBookLink
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ProductIdsBookLink` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ProductIdsBookLink",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ProductIdsBookLinks/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#__get__notDeleted
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Queries notDeleted of ProductIdsBookLink.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/ProductIdsBookLinks/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#__create__notDeleted
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/ProductIdsBookLinks/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#__delete__notDeleted
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/ProductIdsBookLinks/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#__count__notDeleted
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Counts notDeleted of ProductIdsBookLink.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/ProductIdsBookLinks/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#create
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ProductIdsBookLinks",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#createMany
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ProductIdsBookLinks",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#upsert
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/ProductIdsBookLinks",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#replaceOrCreate
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ProductIdsBookLinks/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#upsertWithWhere
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ProductIdsBookLinks/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#exists
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ProductIdsBookLinks/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#findById
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ProductIdsBookLinks/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#replaceById
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ProductIdsBookLinks/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#find
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ProductIdsBookLinks",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#findOne
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ProductIdsBookLinks/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#updateAll
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ProductIdsBookLinks/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#deleteById
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ProductIdsBookLinks/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#count
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ProductIdsBookLinks/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#prototype$updateAttributes
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProductIdsBookLink id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/ProductIdsBookLinks/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#createChangeStream
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ProductIdsBookLinks/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#setStatusByBookId
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Set Product IDs status to 0 based on book id
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "setStatusByBookId": {
              isArray: true,
              url: urlBase + "/ProductIdsBookLinks/setStatusByBookId",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#duplicateByBookId
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Set Product IDs status to 0 based on book id
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
            "duplicateByBookId": {
              isArray: true,
              url: urlBase + "/ProductIdsBookLinks/duplicateByBookId",
              method: "POST",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.findById() instead.
            "::findById::Books::ProductIdsBookLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/ProductIdsBookLink/:fk",
              method: "GET",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.destroyById() instead.
            "::destroyById::Books::ProductIdsBookLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/ProductIdsBookLink/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.updateById() instead.
            "::updateById::Books::ProductIdsBookLink": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/ProductIdsBookLink/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Books.ProductIdsBookLink() instead.
            "::get::Books::ProductIdsBookLink": {
              isArray: true,
              url: urlBase + "/Books/:id/ProductIdsBookLink",
              method: "GET",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.create() instead.
            "::create::Books::ProductIdsBookLink": {
              url: urlBase + "/Books/:id/ProductIdsBookLink",
              method: "POST",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.createMany() instead.
            "::createMany::Books::ProductIdsBookLink": {
              isArray: true,
              url: urlBase + "/Books/:id/ProductIdsBookLink",
              method: "POST",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.destroyAll() instead.
            "::delete::Books::ProductIdsBookLink": {
              url: urlBase + "/Books/:id/ProductIdsBookLink",
              method: "DELETE",
            },

            // INTERNAL. Use Books.ProductIdsBookLink.count() instead.
            "::count::Books::ProductIdsBookLink": {
              url: urlBase + "/Books/:id/ProductIdsBookLink/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#patchOrCreate
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#updateOrCreate
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#patchOrCreateWithWhere
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#update
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#destroyById
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#removeById
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ProductIdsBookLink#patchAttributes
             * @methodOf lbServices.ProductIdsBookLink
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProductIdsBookLink id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductIdsBookLink` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ProductIdsBookLink#modelName
        * @propertyOf lbServices.ProductIdsBookLink
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ProductIdsBookLink`.
        */
        R.modelName = "ProductIdsBookLink";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Products
 * @header lbServices.Products
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Products` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Products",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Products/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Products#__get__notDeleted
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Queries notDeleted of Products.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Products/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#__create__notDeleted
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Products/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#__delete__notDeleted
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Products/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#__count__notDeleted
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Counts notDeleted of Products.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Products/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#create
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Products",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#createMany
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Products",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#upsert
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Products",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#replaceOrCreate
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Products/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#upsertWithWhere
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Products/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#exists
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Products/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#findById
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Products/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#replaceById
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Products/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#find
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Products",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#findOne
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Products/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#updateAll
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Products/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#deleteById
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Products/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#count
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Products/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#prototype$updateAttributes
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Products id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Products/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#createChangeStream
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Products/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Products#getProductsAndDocCount
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Get a list Products and a count of documents associated with them
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
            "getProductsAndDocCount": {
              isArray: true,
              url: urlBase + "/Products/getProductsAndDocCount",
              method: "POST",
            },

            // INTERNAL. Use Books.Products() instead.
            "::get::Books::Products": {
              url: urlBase + "/Books/:id/Products",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Products#patchOrCreate
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Products#updateOrCreate
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Products#patchOrCreateWithWhere
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Products#update
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Products#destroyById
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Products#removeById
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Products#patchAttributes
             * @methodOf lbServices.Products
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Products id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Products` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Products#modelName
        * @propertyOf lbServices.Products
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Products`.
        */
        R.modelName = "Products";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ProductTypes
 * @header lbServices.ProductTypes
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ProductTypes` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ProductTypes",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/ProductTypes/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#__get__notDeleted
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Queries notDeleted of ProductTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/ProductTypes/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#__create__notDeleted
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/ProductTypes/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#__delete__notDeleted
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/ProductTypes/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#__count__notDeleted
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Counts notDeleted of ProductTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/ProductTypes/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#create
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/ProductTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#createMany
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/ProductTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#upsert
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/ProductTypes",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#replaceOrCreate
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/ProductTypes/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#upsertWithWhere
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/ProductTypes/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#exists
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/ProductTypes/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#findById
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/ProductTypes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#replaceById
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/ProductTypes/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#find
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/ProductTypes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#findOne
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/ProductTypes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#updateAll
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/ProductTypes/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#deleteById
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/ProductTypes/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#count
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/ProductTypes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#prototype$updateAttributes
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProductTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/ProductTypes/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#createChangeStream
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/ProductTypes/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Books.ProductTypes() instead.
            "::get::Books::ProductTypes": {
              url: urlBase + "/Books/:id/ProductTypes",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#patchOrCreate
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#updateOrCreate
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#patchOrCreateWithWhere
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#update
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#destroyById
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#removeById
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.ProductTypes#patchAttributes
             * @methodOf lbServices.ProductTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - ProductTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ProductTypes` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.ProductTypes#modelName
        * @propertyOf lbServices.ProductTypes
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ProductTypes`.
        */
        R.modelName = "ProductTypes";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Projects
 * @header lbServices.Projects
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Projects` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Projects",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Projects/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Projects#__get__notDeleted
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Queries notDeleted of Projects.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Projects/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#__create__notDeleted
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Projects/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#__delete__notDeleted
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Projects/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#__count__notDeleted
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Counts notDeleted of Projects.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Projects/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#create
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Projects",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#createMany
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Projects",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#upsert
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Projects",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#replaceOrCreate
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Projects/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#upsertWithWhere
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Projects/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#exists
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Projects/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#findById
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Projects/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#replaceById
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Projects/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#find
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Projects",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#findOne
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Projects/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#updateAll
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Projects/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#deleteById
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Projects/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#count
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Projects/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#prototype$updateAttributes
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Projects id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Projects/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Projects#createChangeStream
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Projects/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Books.Projects() instead.
            "::get::Books::Projects": {
              url: urlBase + "/Books/:id/Projects",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Projects#patchOrCreate
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Projects#updateOrCreate
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Projects#patchOrCreateWithWhere
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Projects#update
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Projects#destroyById
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Projects#removeById
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Projects#patchAttributes
             * @methodOf lbServices.Projects
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Projects id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Projects` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Projects#modelName
        * @propertyOf lbServices.Projects
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Projects`.
        */
        R.modelName = "Projects";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.PublishTypes
 * @header lbServices.PublishTypes
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `PublishTypes` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "PublishTypes",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/PublishTypes/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#__get__notDeleted
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Queries notDeleted of PublishTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/PublishTypes/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#__create__notDeleted
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/PublishTypes/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#__delete__notDeleted
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/PublishTypes/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#__count__notDeleted
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Counts notDeleted of PublishTypes.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/PublishTypes/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#create
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/PublishTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#createMany
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/PublishTypes",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#upsert
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/PublishTypes",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#replaceOrCreate
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/PublishTypes/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#upsertWithWhere
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/PublishTypes/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#exists
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/PublishTypes/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#findById
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/PublishTypes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#replaceById
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/PublishTypes/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#find
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/PublishTypes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#findOne
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/PublishTypes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#updateAll
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/PublishTypes/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#deleteById
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/PublishTypes/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#count
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/PublishTypes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#prototype$updateAttributes
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PublishTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/PublishTypes/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#createChangeStream
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/PublishTypes/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#patchOrCreate
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#updateOrCreate
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#patchOrCreateWithWhere
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#update
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#destroyById
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#removeById
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.PublishTypes#patchAttributes
             * @methodOf lbServices.PublishTypes
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PublishTypes id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PublishTypes` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.PublishTypes#modelName
        * @propertyOf lbServices.PublishTypes
        * @description
        * The name of the model represented by this $resource,
        * i.e. `PublishTypes`.
        */
        R.modelName = "PublishTypes";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Regions
 * @header lbServices.Regions
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Regions` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Regions",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Regions/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Regions#__get__notDeleted
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Queries notDeleted of Regions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Regions/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#__create__notDeleted
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Regions/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#__delete__notDeleted
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Regions/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#__count__notDeleted
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Counts notDeleted of Regions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Regions/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#create
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Regions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#createMany
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Regions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#upsert
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Regions",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#replaceOrCreate
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Regions/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#upsertWithWhere
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Regions/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#exists
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Regions/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#findById
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Regions/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#replaceById
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Regions/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#find
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Regions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#findOne
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Regions/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#updateAll
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Regions/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#deleteById
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Regions/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#count
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Regions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#prototype$updateAttributes
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Regions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Regions/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Regions#createChangeStream
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Regions/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Books.Regions() instead.
            "::get::Books::Regions": {
              url: urlBase + "/Books/:id/Regions",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Regions#patchOrCreate
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Regions#updateOrCreate
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Regions#patchOrCreateWithWhere
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Regions#update
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Regions#destroyById
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Regions#removeById
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Regions#patchAttributes
             * @methodOf lbServices.Regions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Regions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Regions` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Regions#modelName
        * @propertyOf lbServices.Regions
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Regions`.
        */
        R.modelName = "Regions";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Reports
 * @header lbServices.Reports
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Reports` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Reports",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Reports/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Reports#create
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Reports",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#createMany
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Reports",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#upsert
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Reports",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#replaceOrCreate
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Reports/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#upsertWithWhere
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Reports/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#exists
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Reports/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#findById
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Reports/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#replaceById
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Reports/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#find
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Reports",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#findOne
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Reports/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#updateAll
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Reports/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#deleteById
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Reports/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#count
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Reports/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#prototype$updateAttributes
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Reports id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Reports/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#createChangeStream
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Reports/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#createReport
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Process 
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "createReport": {
              isArray: true,
              url: urlBase + "/Reports/createReport",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Reports#sendReport
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Process 
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
            "sendReport": {
              isArray: true,
              url: urlBase + "/Reports/sendReport",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Reports#patchOrCreate
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Reports#updateOrCreate
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Reports#patchOrCreateWithWhere
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Reports#update
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Reports#destroyById
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Reports#removeById
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Reports#patchAttributes
             * @methodOf lbServices.Reports
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Reports id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Reports` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Reports#modelName
        * @propertyOf lbServices.Reports
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Reports`.
        */
        R.modelName = "Reports";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.RfcCategories
 * @header lbServices.RfcCategories
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `RfcCategories` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "RfcCategories",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/RfcCategories/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#create
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/RfcCategories",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#createMany
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/RfcCategories",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#upsert
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/RfcCategories",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#replaceOrCreate
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/RfcCategories/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#upsertWithWhere
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/RfcCategories/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#exists
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/RfcCategories/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#findById
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/RfcCategories/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#replaceById
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/RfcCategories/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#find
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/RfcCategories",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#findOne
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/RfcCategories/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#updateAll
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/RfcCategories/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#deleteById
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/RfcCategories/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#count
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/RfcCategories/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#prototype$updateAttributes
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - RfcCategories id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/RfcCategories/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#createChangeStream
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/RfcCategories/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#patchOrCreate
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#updateOrCreate
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#patchOrCreateWithWhere
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#update
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#destroyById
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#removeById
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.RfcCategories#patchAttributes
             * @methodOf lbServices.RfcCategories
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - RfcCategories id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcCategories` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.RfcCategories#modelName
        * @propertyOf lbServices.RfcCategories
        * @description
        * The name of the model represented by this $resource,
        * i.e. `RfcCategories`.
        */
        R.modelName = "RfcCategories";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.RfcJoin
 * @header lbServices.RfcJoin
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `RfcJoin` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "RfcJoin",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/RfcJoins/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#create
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/RfcJoins",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#createMany
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/RfcJoins",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#upsert
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/RfcJoins",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#replaceOrCreate
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/RfcJoins/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#upsertWithWhere
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/RfcJoins/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#exists
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/RfcJoins/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#findById
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/RfcJoins/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#replaceById
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/RfcJoins/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#find
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/RfcJoins",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#findOne
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/RfcJoins/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#updateAll
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/RfcJoins/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#deleteById
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/RfcJoins/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#count
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/RfcJoins/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#prototype$updateAttributes
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - RfcJoin id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/RfcJoins/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#createChangeStream
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/RfcJoins/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#patchOrCreate
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#updateOrCreate
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#patchOrCreateWithWhere
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#update
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#destroyById
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#removeById
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.RfcJoin#patchAttributes
             * @methodOf lbServices.RfcJoin
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - RfcJoin id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcJoin` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.RfcJoin#modelName
        * @propertyOf lbServices.RfcJoin
        * @description
        * The name of the model represented by this $resource,
        * i.e. `RfcJoin`.
        */
        R.modelName = "RfcJoin";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.RfcReasons
 * @header lbServices.RfcReasons
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `RfcReasons` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "RfcReasons",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/RfcReasons/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#create
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/RfcReasons",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#createMany
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/RfcReasons",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#upsert
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/RfcReasons",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#replaceOrCreate
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/RfcReasons/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#upsertWithWhere
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/RfcReasons/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#exists
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/RfcReasons/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#findById
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/RfcReasons/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#replaceById
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/RfcReasons/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#find
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/RfcReasons",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#findOne
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/RfcReasons/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#updateAll
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/RfcReasons/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#deleteById
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/RfcReasons/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#count
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/RfcReasons/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#prototype$updateAttributes
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - RfcReasons id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/RfcReasons/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#createChangeStream
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/RfcReasons/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#patchOrCreate
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#updateOrCreate
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#patchOrCreateWithWhere
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#update
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#destroyById
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#removeById
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.RfcReasons#patchAttributes
             * @methodOf lbServices.RfcReasons
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - RfcReasons id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RfcReasons` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.RfcReasons#modelName
        * @propertyOf lbServices.RfcReasons
        * @description
        * The name of the model represented by this $resource,
        * i.e. `RfcReasons`.
        */
        R.modelName = "RfcReasons";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Roles
 * @header lbServices.Roles
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Roles` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Roles",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Roles/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Roles#prototype$__findById__principals
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Find a related item by id for principals.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `fk` – `{*}` - Foreign key for principals
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "prototype$__findById__principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/principals/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#prototype$__destroyById__principals
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Delete a related item by id for principals.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `fk` – `{*}` - Foreign key for principals
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/principals/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#prototype$__updateById__principals
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Update a related item by id for principals.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `fk` – `{*}` - Foreign key for principals
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "prototype$__updateById__principals": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/principals/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Roles.Users.findById() instead.
            "prototype$__findById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Roles.Users.destroyById() instead.
            "prototype$__destroyById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Roles.Users.updateById() instead.
            "prototype$__updateById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/Users/:fk",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#prototype$__get__principals
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Queries principals of Roles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "prototype$__get__principals": {
              isArray: true,
              url: urlBase + "/Roles/:id/principals",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#prototype$__create__principals
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Creates a new instance in principals of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "prototype$__create__principals": {
              url: urlBase + "/Roles/:id/principals",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#prototype$__delete__principals
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Deletes all principals of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__principals": {
              url: urlBase + "/Roles/:id/principals",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#prototype$__count__principals
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Counts principals of Roles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__principals": {
              url: urlBase + "/Roles/:id/principals/count",
              method: "GET",
            },

            // INTERNAL. Use Roles.Users() instead.
            "prototype$__get__Users": {
              isArray: true,
              url: urlBase + "/Roles/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Roles.Users.create() instead.
            "prototype$__create__Users": {
              url: urlBase + "/Roles/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Roles.Users.destroyAll() instead.
            "prototype$__delete__Users": {
              url: urlBase + "/Roles/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use Roles.Users.count() instead.
            "prototype$__count__Users": {
              url: urlBase + "/Roles/:id/Users/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#create
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Roles",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#createMany
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Roles",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#upsert
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Roles",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#replaceOrCreate
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Roles/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#upsertWithWhere
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Roles/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#exists
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Roles/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#findById
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Roles/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#replaceById
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Roles/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#find
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Roles",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#findOne
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Roles/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#updateAll
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Roles/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#deleteById
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Roles/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#count
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Roles/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#prototype$updateAttributes
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Roles/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Roles#createChangeStream
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Roles/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Users.Roles() instead.
            "::get::Users::Roles": {
              url: urlBase + "/Users/:id/Roles",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Roles#patchOrCreate
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Roles#updateOrCreate
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Roles#patchOrCreateWithWhere
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Roles#update
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Roles#destroyById
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Roles#removeById
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Roles#patchAttributes
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Roles#modelName
        * @propertyOf lbServices.Roles
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Roles`.
        */
        R.modelName = "Roles";

    /**
     * @ngdoc object
     * @name lbServices.Roles.Users
     * @header lbServices.Roles.Users
     * @object
     * @description
     *
     * The object `Roles.Users` groups methods
     * manipulating `Users` instances related to `Roles`.
     *
     * Call {@link lbServices.Roles#Users Roles.Users()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Roles#Users
             * @methodOf lbServices.Roles
             *
             * @description
             *
             * Queries Users of Roles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::get::Roles::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Roles.Users#count
             * @methodOf lbServices.Roles.Users
             *
             * @description
             *
             * Counts Users of Roles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Users.count = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::count::Roles::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Roles.Users#create
             * @methodOf lbServices.Roles.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.create = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::create::Roles::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Roles.Users#createMany
             * @methodOf lbServices.Roles.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.createMany = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::createMany::Roles::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Roles.Users#destroyAll
             * @methodOf lbServices.Roles.Users
             *
             * @description
             *
             * Deletes all Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyAll = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::delete::Roles::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Roles.Users#destroyById
             * @methodOf lbServices.Roles.Users
             *
             * @description
             *
             * Delete a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::destroyById::Roles::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Roles.Users#findById
             * @methodOf lbServices.Roles.Users
             *
             * @description
             *
             * Find a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.findById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::findById::Roles::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Roles.Users#updateById
             * @methodOf lbServices.Roles.Users
             *
             * @description
             *
             * Update a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Roles id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.updateById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::updateById::Roles::Users"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SaveSearch
 * @header lbServices.SaveSearch
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SaveSearch` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SaveSearch",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SaveSearches/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#create
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/SaveSearches",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#createMany
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/SaveSearches",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#upsert
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/SaveSearches",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#replaceOrCreate
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/SaveSearches/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#upsertWithWhere
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/SaveSearches/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#exists
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SaveSearches/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#findById
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SaveSearches/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#replaceById
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/SaveSearches/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#find
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SaveSearches",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#findOne
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/SaveSearches/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#updateAll
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/SaveSearches/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#deleteById
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SaveSearches/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#count
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SaveSearches/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#prototype$updateAttributes
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SaveSearch id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/SaveSearches/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#createChangeStream
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SaveSearches/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#patchOrCreate
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#updateOrCreate
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#patchOrCreateWithWhere
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#update
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#destroyById
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#removeById
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SaveSearch#patchAttributes
             * @methodOf lbServices.SaveSearch
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SaveSearch id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SaveSearch` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.SaveSearch#modelName
        * @propertyOf lbServices.SaveSearch
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SaveSearch`.
        */
        R.modelName = "SaveSearch";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SocialChannels
 * @header lbServices.SocialChannels
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SocialChannels` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SocialChannels",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SocialChannels/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#__get__notDeleted
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Queries notDeleted of SocialChannels.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/SocialChannels/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#__create__notDeleted
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/SocialChannels/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#__delete__notDeleted
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/SocialChannels/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#__count__notDeleted
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Counts notDeleted of SocialChannels.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/SocialChannels/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#create
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/SocialChannels",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#createMany
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/SocialChannels",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#upsert
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/SocialChannels",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#replaceOrCreate
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/SocialChannels/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#upsertWithWhere
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/SocialChannels/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#exists
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SocialChannels/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#findById
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SocialChannels/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#replaceById
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/SocialChannels/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#find
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SocialChannels",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#findOne
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/SocialChannels/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#updateAll
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/SocialChannels/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#deleteById
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SocialChannels/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#count
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SocialChannels/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#prototype$updateAttributes
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SocialChannels id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/SocialChannels/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#createChangeStream
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SocialChannels/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#patchOrCreate
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#updateOrCreate
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#patchOrCreateWithWhere
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#update
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#destroyById
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#removeById
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SocialChannels#patchAttributes
             * @methodOf lbServices.SocialChannels
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SocialChannels id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SocialChannels` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.SocialChannels#modelName
        * @propertyOf lbServices.SocialChannels
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SocialChannels`.
        */
        R.modelName = "SocialChannels";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Structures
 * @header lbServices.Structures
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Structures` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Structures",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Structures/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Structures.Books() instead.
            "prototype$__get__Books": {
              url: urlBase + "/Structures/:id/Books",
              method: "GET",
            },

            // INTERNAL. Use Structures.Contents() instead.
            "prototype$__get__Contents": {
              url: urlBase + "/Structures/:id/Contents",
              method: "GET",
            },

            // INTERNAL. Use Structures.Children.findById() instead.
            "prototype$__findById__Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/Children/:fk",
              method: "GET",
            },

            // INTERNAL. Use Structures.Children.destroyById() instead.
            "prototype$__destroyById__Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/Children/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Structures.Children.updateById() instead.
            "prototype$__updateById__Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/Children/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Structures.History.findById() instead.
            "prototype$__findById__History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/History/:fk",
              method: "GET",
            },

            // INTERNAL. Use Structures.History.destroyById() instead.
            "prototype$__destroyById__History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/History/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Structures.History.updateById() instead.
            "prototype$__updateById__History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/History/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Structures.versions() instead.
            "prototype$__get__versions": {
              url: urlBase + "/Structures/:id/versions",
              method: "GET",
            },

            // INTERNAL. Use Structures.Children() instead.
            "prototype$__get__Children": {
              isArray: true,
              url: urlBase + "/Structures/:id/Children",
              method: "GET",
            },

            // INTERNAL. Use Structures.Children.create() instead.
            "prototype$__create__Children": {
              url: urlBase + "/Structures/:id/Children",
              method: "POST",
            },

            // INTERNAL. Use Structures.Children.destroyAll() instead.
            "prototype$__delete__Children": {
              url: urlBase + "/Structures/:id/Children",
              method: "DELETE",
            },

            // INTERNAL. Use Structures.Children.count() instead.
            "prototype$__count__Children": {
              url: urlBase + "/Structures/:id/Children/count",
              method: "GET",
            },

            // INTERNAL. Use Structures.History() instead.
            "prototype$__get__History": {
              isArray: true,
              url: urlBase + "/Structures/:id/History",
              method: "GET",
            },

            // INTERNAL. Use Structures.History.create() instead.
            "prototype$__create__History": {
              url: urlBase + "/Structures/:id/History",
              method: "POST",
            },

            // INTERNAL. Use Structures.History.destroyAll() instead.
            "prototype$__delete__History": {
              url: urlBase + "/Structures/:id/History",
              method: "DELETE",
            },

            // INTERNAL. Use Structures.History.count() instead.
            "prototype$__count__History": {
              url: urlBase + "/Structures/:id/History/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#create
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Structures",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#createMany
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Structures",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#upsert
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Structures",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#replaceOrCreate
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Structures/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#upsertWithWhere
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Structures/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#exists
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Structures/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#findById
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Structures/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#replaceById
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Structures/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#find
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Structures",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#findOne
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Structures/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#updateAll
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Structures/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#deleteById
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Structures/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#count
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Structures/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#prototype$updateAttributes
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Structures/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#createChangeStream
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Structures/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#duplicateDeep
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `structureId` – `{number=}` -
             */
            "duplicateDeep": {
              url: urlBase + "/Structures/duplicatedeep",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#trash
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Set structure to deleted
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "trash": {
              url: urlBase + "/Structures/trash",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#getChildContent
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Get list of children using bookId and contetId
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "getChildContent": {
              isArray: true,
              url: urlBase + "/Structures/getChildContent",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#getStatuses
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Get list of all book statuses
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "getStatuses": {
              isArray: true,
              url: urlBase + "/Structures/getStatuses",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#updateStructuresAndEvents
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Updates structures and events from csv list of structure ids
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "updateStructuresAndEvents": {
              url: urlBase + "/Structures/updateStructuresAndEvents",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#updateOrder
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Updates structures order
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "updateOrder": {
              url: urlBase + "/Structures/updateOrder",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#getWCR
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Get list of all changes to a book
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "getWCR": {
              isArray: true,
              url: urlBase + "/Structures/getWCR",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#requestDeletion
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Request Document gets deleted
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "requestDeletion": {
              isArray: true,
              url: urlBase + "/Structures/requestDeletion",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Structures#getStatusesAndContent
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Get list of all book statuses with content
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
            "getStatusesAndContent": {
              isArray: true,
              url: urlBase + "/Structures/getStatusesAndContent",
              method: "POST",
            },

            // INTERNAL. Use Books.Children.findById() instead.
            "::findById::Books::Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Children/:fk",
              method: "GET",
            },

            // INTERNAL. Use Books.Children.destroyById() instead.
            "::destroyById::Books::Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Children/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Children.updateById() instead.
            "::updateById::Books::Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Children/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Books.Structures.findById() instead.
            "::findById::Books::Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Structures/:fk",
              method: "GET",
            },

            // INTERNAL. Use Books.Structures.destroyById() instead.
            "::destroyById::Books::Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Structures/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Structures.updateById() instead.
            "::updateById::Books::Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Books/:id/Structures/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Books.Children() instead.
            "::get::Books::Children": {
              isArray: true,
              url: urlBase + "/Books/:id/Children",
              method: "GET",
            },

            // INTERNAL. Use Books.Children.create() instead.
            "::create::Books::Children": {
              url: urlBase + "/Books/:id/Children",
              method: "POST",
            },

            // INTERNAL. Use Books.Children.createMany() instead.
            "::createMany::Books::Children": {
              isArray: true,
              url: urlBase + "/Books/:id/Children",
              method: "POST",
            },

            // INTERNAL. Use Books.Children.destroyAll() instead.
            "::delete::Books::Children": {
              url: urlBase + "/Books/:id/Children",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Children.count() instead.
            "::count::Books::Children": {
              url: urlBase + "/Books/:id/Children/count",
              method: "GET",
            },

            // INTERNAL. Use Books.Structures() instead.
            "::get::Books::Structures": {
              isArray: true,
              url: urlBase + "/Books/:id/Structures",
              method: "GET",
            },

            // INTERNAL. Use Books.Structures.create() instead.
            "::create::Books::Structures": {
              url: urlBase + "/Books/:id/Structures",
              method: "POST",
            },

            // INTERNAL. Use Books.Structures.createMany() instead.
            "::createMany::Books::Structures": {
              isArray: true,
              url: urlBase + "/Books/:id/Structures",
              method: "POST",
            },

            // INTERNAL. Use Books.Structures.destroyAll() instead.
            "::delete::Books::Structures": {
              url: urlBase + "/Books/:id/Structures",
              method: "DELETE",
            },

            // INTERNAL. Use Books.Structures.count() instead.
            "::count::Books::Structures": {
              url: urlBase + "/Books/:id/Structures/count",
              method: "GET",
            },

            // INTERNAL. Use History.Structures() instead.
            "::get::History::Structures": {
              url: urlBase + "/Histories/:id/Structures",
              method: "GET",
            },

            // INTERNAL. Use OrderHistory.Structures() instead.
            "::get::OrderHistory::Structures": {
              url: urlBase + "/OrderHistories/:id/Structures",
              method: "GET",
            },

            // INTERNAL. Use Structures.Children.findById() instead.
            "::findById::Structures::Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/Children/:fk",
              method: "GET",
            },

            // INTERNAL. Use Structures.Children.destroyById() instead.
            "::destroyById::Structures::Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/Children/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Structures.Children.updateById() instead.
            "::updateById::Structures::Children": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Structures/:id/Children/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Structures.Children() instead.
            "::get::Structures::Children": {
              isArray: true,
              url: urlBase + "/Structures/:id/Children",
              method: "GET",
            },

            // INTERNAL. Use Structures.Children.create() instead.
            "::create::Structures::Children": {
              url: urlBase + "/Structures/:id/Children",
              method: "POST",
            },

            // INTERNAL. Use Structures.Children.createMany() instead.
            "::createMany::Structures::Children": {
              isArray: true,
              url: urlBase + "/Structures/:id/Children",
              method: "POST",
            },

            // INTERNAL. Use Structures.Children.destroyAll() instead.
            "::delete::Structures::Children": {
              url: urlBase + "/Structures/:id/Children",
              method: "DELETE",
            },

            // INTERNAL. Use Structures.Children.count() instead.
            "::count::Structures::Children": {
              url: urlBase + "/Structures/:id/Children/count",
              method: "GET",
            },

            // INTERNAL. Use Versions.Structures.findById() instead.
            "::findById::Versions::Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/Structures/:fk",
              method: "GET",
            },

            // INTERNAL. Use Versions.Structures.destroyById() instead.
            "::destroyById::Versions::Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/Structures/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Versions.Structures.updateById() instead.
            "::updateById::Versions::Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/Structures/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Versions.Structures() instead.
            "::get::Versions::Structures": {
              isArray: true,
              url: urlBase + "/Versions/:id/Structures",
              method: "GET",
            },

            // INTERNAL. Use Versions.Structures.create() instead.
            "::create::Versions::Structures": {
              url: urlBase + "/Versions/:id/Structures",
              method: "POST",
            },

            // INTERNAL. Use Versions.Structures.createMany() instead.
            "::createMany::Versions::Structures": {
              isArray: true,
              url: urlBase + "/Versions/:id/Structures",
              method: "POST",
            },

            // INTERNAL. Use Versions.Structures.destroyAll() instead.
            "::delete::Versions::Structures": {
              url: urlBase + "/Versions/:id/Structures",
              method: "DELETE",
            },

            // INTERNAL. Use Versions.Structures.count() instead.
            "::count::Versions::Structures": {
              url: urlBase + "/Versions/:id/Structures/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Structures#patchOrCreate
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Structures#updateOrCreate
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Structures#patchOrCreateWithWhere
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Structures#update
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Structures#destroyById
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Structures#removeById
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Structures#patchAttributes
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Structures#modelName
        * @propertyOf lbServices.Structures
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Structures`.
        */
        R.modelName = "Structures";


            /**
             * @ngdoc method
             * @name lbServices.Structures#Books
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Fetches belongsTo relation Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::get::Structures::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures#Contents
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Fetches belongsTo relation Contents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R.Contents = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::get::Structures::Contents"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Structures.Children
     * @header lbServices.Structures.Children
     * @object
     * @description
     *
     * The object `Structures.Children` groups methods
     * manipulating `Structures` instances related to `Structures`.
     *
     * Call {@link lbServices.Structures#Children Structures.Children()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Structures#Children
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Queries Children of Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Children = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::get::Structures::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.Children#count
             * @methodOf lbServices.Structures.Children
             *
             * @description
             *
             * Counts Children of Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Children.count = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::count::Structures::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.Children#create
             * @methodOf lbServices.Structures.Children
             *
             * @description
             *
             * Creates a new instance in Children of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Children.create = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::create::Structures::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.Children#createMany
             * @methodOf lbServices.Structures.Children
             *
             * @description
             *
             * Creates a new instance in Children of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Children.createMany = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::createMany::Structures::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.Children#destroyAll
             * @methodOf lbServices.Structures.Children
             *
             * @description
             *
             * Deletes all Children of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Children.destroyAll = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::delete::Structures::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.Children#destroyById
             * @methodOf lbServices.Structures.Children
             *
             * @description
             *
             * Delete a related item by id for Children.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `fk` – `{*}` - Foreign key for Children
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Children.destroyById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::destroyById::Structures::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.Children#findById
             * @methodOf lbServices.Structures.Children
             *
             * @description
             *
             * Find a related item by id for Children.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `fk` – `{*}` - Foreign key for Children
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Children.findById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::findById::Structures::Children"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.Children#updateById
             * @methodOf lbServices.Structures.Children
             *
             * @description
             *
             * Update a related item by id for Children.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `fk` – `{*}` - Foreign key for Children
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Children.updateById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::updateById::Structures::Children"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Structures.History
     * @header lbServices.Structures.History
     * @object
     * @description
     *
     * The object `Structures.History` groups methods
     * manipulating `History` instances related to `Structures`.
     *
     * Call {@link lbServices.Structures#History Structures.History()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Structures#History
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Queries History of Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::get::Structures::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.History#count
             * @methodOf lbServices.Structures.History
             *
             * @description
             *
             * Counts History of Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.History.count = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::count::Structures::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.History#create
             * @methodOf lbServices.Structures.History
             *
             * @description
             *
             * Creates a new instance in History of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History.create = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::create::Structures::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.History#createMany
             * @methodOf lbServices.Structures.History
             *
             * @description
             *
             * Creates a new instance in History of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History.createMany = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::createMany::Structures::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.History#destroyAll
             * @methodOf lbServices.Structures.History
             *
             * @description
             *
             * Deletes all History of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.History.destroyAll = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::delete::Structures::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.History#destroyById
             * @methodOf lbServices.Structures.History
             *
             * @description
             *
             * Delete a related item by id for History.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `fk` – `{*}` - Foreign key for History
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.History.destroyById = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::destroyById::Structures::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.History#findById
             * @methodOf lbServices.Structures.History
             *
             * @description
             *
             * Find a related item by id for History.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `fk` – `{*}` - Foreign key for History
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History.findById = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::findById::Structures::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures.History#updateById
             * @methodOf lbServices.Structures.History
             *
             * @description
             *
             * Update a related item by id for History.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `fk` – `{*}` - Foreign key for History
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History.updateById = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::updateById::Structures::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Structures#versions
             * @methodOf lbServices.Structures
             *
             * @description
             *
             * Fetches belongsTo relation versions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Structures id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R.versions = function() {
          var TargetResource = $injector.get("Versions");
          var action = TargetResource["::get::Structures::versions"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SubInsurers
 * @header lbServices.SubInsurers
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SubInsurers` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SubInsurers",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/SubInsurers/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#__get__notDeleted
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Queries notDeleted of SubInsurers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/SubInsurers/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#__create__notDeleted
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/SubInsurers/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#__delete__notDeleted
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/SubInsurers/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#__count__notDeleted
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Counts notDeleted of SubInsurers.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/SubInsurers/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#create
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/SubInsurers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#createMany
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/SubInsurers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#upsert
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/SubInsurers",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#replaceOrCreate
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/SubInsurers/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#upsertWithWhere
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/SubInsurers/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#exists
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/SubInsurers/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#findById
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/SubInsurers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#replaceById
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/SubInsurers/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#find
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/SubInsurers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#findOne
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/SubInsurers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#updateAll
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/SubInsurers/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#deleteById
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/SubInsurers/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#count
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/SubInsurers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#prototype$updateAttributes
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SubInsurers id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/SubInsurers/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#createChangeStream
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/SubInsurers/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Books.SubInsurers() instead.
            "::get::Books::SubInsurers": {
              url: urlBase + "/Books/:id/SubInsurers",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#patchOrCreate
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#updateOrCreate
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#patchOrCreateWithWhere
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#update
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#destroyById
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#removeById
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.SubInsurers#patchAttributes
             * @methodOf lbServices.SubInsurers
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - SubInsurers id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SubInsurers` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.SubInsurers#modelName
        * @propertyOf lbServices.SubInsurers
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SubInsurers`.
        */
        R.modelName = "SubInsurers";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Subscriptions
 * @header lbServices.Subscriptions
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Subscriptions` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Subscriptions",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Subscriptions/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#create
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Subscriptions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#createMany
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Subscriptions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#upsert
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Subscriptions",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#replaceOrCreate
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Subscriptions/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#upsertWithWhere
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Subscriptions/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#exists
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Subscriptions/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#findById
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Subscriptions/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#replaceById
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Subscriptions/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#find
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Subscriptions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#findOne
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Subscriptions/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#updateAll
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Subscriptions/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#deleteById
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Subscriptions/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#count
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Subscriptions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#prototype$updateAttributes
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Subscriptions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Subscriptions/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#createChangeStream
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Subscriptions/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#patchOrCreate
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#updateOrCreate
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#patchOrCreateWithWhere
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#update
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#destroyById
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#removeById
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Subscriptions#patchAttributes
             * @methodOf lbServices.Subscriptions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Subscriptions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Subscriptions` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Subscriptions#modelName
        * @propertyOf lbServices.Subscriptions
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Subscriptions`.
        */
        R.modelName = "Subscriptions";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Teams
 * @header lbServices.Teams
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Teams` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Teams",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Teams/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Teams.Contents.findById() instead.
            "prototype$__findById__Contents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Contents/:fk",
              method: "GET",
            },

            // INTERNAL. Use Teams.Contents.destroyById() instead.
            "prototype$__destroyById__Contents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Contents/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Teams.Contents.updateById() instead.
            "prototype$__updateById__Contents": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Contents/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Teams.Departments() instead.
            "prototype$__get__Departments": {
              url: urlBase + "/Teams/:id/Departments",
              method: "GET",
            },

            // INTERNAL. Use Teams.Users.findById() instead.
            "prototype$__findById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Teams.Users.destroyById() instead.
            "prototype$__destroyById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Teams.Users.updateById() instead.
            "prototype$__updateById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Teams.Contents() instead.
            "prototype$__get__Contents": {
              isArray: true,
              url: urlBase + "/Teams/:id/Contents",
              method: "GET",
            },

            // INTERNAL. Use Teams.Contents.create() instead.
            "prototype$__create__Contents": {
              url: urlBase + "/Teams/:id/Contents",
              method: "POST",
            },

            // INTERNAL. Use Teams.Contents.destroyAll() instead.
            "prototype$__delete__Contents": {
              url: urlBase + "/Teams/:id/Contents",
              method: "DELETE",
            },

            // INTERNAL. Use Teams.Contents.count() instead.
            "prototype$__count__Contents": {
              url: urlBase + "/Teams/:id/Contents/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#__get__notDeleted
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Queries notDeleted of Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Teams/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#__create__notDeleted
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Teams/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#__delete__notDeleted
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Teams/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#__count__notDeleted
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Counts notDeleted of Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Teams/notDeleted/count",
              method: "GET",
            },

            // INTERNAL. Use Teams.Users() instead.
            "prototype$__get__Users": {
              isArray: true,
              url: urlBase + "/Teams/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Teams.Users.create() instead.
            "prototype$__create__Users": {
              url: urlBase + "/Teams/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Teams.Users.destroyAll() instead.
            "prototype$__delete__Users": {
              url: urlBase + "/Teams/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use Teams.Users.count() instead.
            "prototype$__count__Users": {
              url: urlBase + "/Teams/:id/Users/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#create
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Teams",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#createMany
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Teams",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#upsert
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Teams",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#replaceOrCreate
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Teams/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#upsertWithWhere
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Teams/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#exists
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Teams/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#findById
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Teams/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#replaceById
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Teams/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#find
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Teams",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#findOne
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Teams/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#updateAll
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Teams/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#deleteById
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Teams/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#count
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Teams/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#prototype$updateAttributes
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Teams/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Teams#createChangeStream
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Teams/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Clients.Teams() instead.
            "::get::Clients::Teams": {
              url: urlBase + "/Clients/:id/Teams",
              method: "GET",
            },

            // INTERNAL. Use Contents.Teams() instead.
            "::get::Contents::Teams": {
              url: urlBase + "/Contents/:id/Teams",
              method: "GET",
            },

            // INTERNAL. Use Departments.Teams.findById() instead.
            "::findById::Departments::Teams": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Teams/:fk",
              method: "GET",
            },

            // INTERNAL. Use Departments.Teams.destroyById() instead.
            "::destroyById::Departments::Teams": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Teams/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Departments.Teams.updateById() instead.
            "::updateById::Departments::Teams": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Teams/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Departments.Teams() instead.
            "::get::Departments::Teams": {
              isArray: true,
              url: urlBase + "/Departments/:id/Teams",
              method: "GET",
            },

            // INTERNAL. Use Departments.Teams.create() instead.
            "::create::Departments::Teams": {
              url: urlBase + "/Departments/:id/Teams",
              method: "POST",
            },

            // INTERNAL. Use Departments.Teams.createMany() instead.
            "::createMany::Departments::Teams": {
              isArray: true,
              url: urlBase + "/Departments/:id/Teams",
              method: "POST",
            },

            // INTERNAL. Use Departments.Teams.destroyAll() instead.
            "::delete::Departments::Teams": {
              url: urlBase + "/Departments/:id/Teams",
              method: "DELETE",
            },

            // INTERNAL. Use Departments.Teams.count() instead.
            "::count::Departments::Teams": {
              url: urlBase + "/Departments/:id/Teams/count",
              method: "GET",
            },

            // INTERNAL. Use Users.Teams() instead.
            "::get::Users::Teams": {
              url: urlBase + "/Users/:id/Teams",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Teams#patchOrCreate
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Teams#updateOrCreate
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Teams#patchOrCreateWithWhere
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Teams#update
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Teams#destroyById
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Teams#removeById
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Teams#patchAttributes
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Teams#modelName
        * @propertyOf lbServices.Teams
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Teams`.
        */
        R.modelName = "Teams";

    /**
     * @ngdoc object
     * @name lbServices.Teams.Contents
     * @header lbServices.Teams.Contents
     * @object
     * @description
     *
     * The object `Teams.Contents` groups methods
     * manipulating `Contents` instances related to `Teams`.
     *
     * Call {@link lbServices.Teams#Contents Teams.Contents()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Teams#Contents
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Queries Contents of Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R.Contents = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::get::Teams::Contents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Contents#count
             * @methodOf lbServices.Teams.Contents
             *
             * @description
             *
             * Counts Contents of Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Contents.count = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::count::Teams::Contents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Contents#create
             * @methodOf lbServices.Teams.Contents
             *
             * @description
             *
             * Creates a new instance in Contents of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R.Contents.create = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::create::Teams::Contents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Contents#createMany
             * @methodOf lbServices.Teams.Contents
             *
             * @description
             *
             * Creates a new instance in Contents of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R.Contents.createMany = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::createMany::Teams::Contents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Contents#destroyAll
             * @methodOf lbServices.Teams.Contents
             *
             * @description
             *
             * Deletes all Contents of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Contents.destroyAll = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::delete::Teams::Contents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Contents#destroyById
             * @methodOf lbServices.Teams.Contents
             *
             * @description
             *
             * Delete a related item by id for Contents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `fk` – `{*}` - Foreign key for Contents
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Contents.destroyById = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::destroyById::Teams::Contents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Contents#findById
             * @methodOf lbServices.Teams.Contents
             *
             * @description
             *
             * Find a related item by id for Contents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `fk` – `{*}` - Foreign key for Contents
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R.Contents.findById = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::findById::Teams::Contents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Contents#updateById
             * @methodOf lbServices.Teams.Contents
             *
             * @description
             *
             * Update a related item by id for Contents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `fk` – `{*}` - Foreign key for Contents
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R.Contents.updateById = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::updateById::Teams::Contents"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams#Departments
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Fetches belongsTo relation Departments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
        R.Departments = function() {
          var TargetResource = $injector.get("Departments");
          var action = TargetResource["::get::Teams::Departments"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Teams.Users
     * @header lbServices.Teams.Users
     * @object
     * @description
     *
     * The object `Teams.Users` groups methods
     * manipulating `Users` instances related to `Teams`.
     *
     * Call {@link lbServices.Teams#Users Teams.Users()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Teams#Users
             * @methodOf lbServices.Teams
             *
             * @description
             *
             * Queries Users of Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::get::Teams::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Users#count
             * @methodOf lbServices.Teams.Users
             *
             * @description
             *
             * Counts Users of Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Users.count = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::count::Teams::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Users#create
             * @methodOf lbServices.Teams.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.create = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::create::Teams::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Users#createMany
             * @methodOf lbServices.Teams.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.createMany = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::createMany::Teams::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Users#destroyAll
             * @methodOf lbServices.Teams.Users
             *
             * @description
             *
             * Deletes all Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyAll = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::delete::Teams::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Users#destroyById
             * @methodOf lbServices.Teams.Users
             *
             * @description
             *
             * Delete a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::destroyById::Teams::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Users#findById
             * @methodOf lbServices.Teams.Users
             *
             * @description
             *
             * Find a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.findById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::findById::Teams::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Teams.Users#updateById
             * @methodOf lbServices.Teams.Users
             *
             * @description
             *
             * Update a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Teams id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.updateById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::updateById::Teams::Users"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Tooltips
 * @header lbServices.Tooltips
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Tooltips` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Tooltips",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Tooltips/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#__get__notDeleted
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Queries notDeleted of Tooltips.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "__get__notDeleted": {
              isArray: true,
              url: urlBase + "/Tooltips/notDeleted",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#__create__notDeleted
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Creates a new instance in notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "__create__notDeleted": {
              url: urlBase + "/Tooltips/notDeleted",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#__delete__notDeleted
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Deletes all notDeleted of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "__delete__notDeleted": {
              url: urlBase + "/Tooltips/notDeleted",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#__count__notDeleted
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Counts notDeleted of Tooltips.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "__count__notDeleted": {
              url: urlBase + "/Tooltips/notDeleted/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#create
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Tooltips",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#createMany
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Tooltips",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#upsert
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Tooltips",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#replaceOrCreate
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Tooltips/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#upsertWithWhere
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Tooltips/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#exists
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Tooltips/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#findById
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Tooltips/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#replaceById
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Tooltips/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#find
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Tooltips",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#findOne
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Tooltips/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#updateAll
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Tooltips/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#deleteById
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Tooltips/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#count
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Tooltips/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#prototype$updateAttributes
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Tooltips id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Tooltips/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#createChangeStream
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Tooltips/change-stream",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Tooltips#patchOrCreate
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#updateOrCreate
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#patchOrCreateWithWhere
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#update
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#destroyById
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#removeById
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Tooltips#patchAttributes
             * @methodOf lbServices.Tooltips
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Tooltips id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Tooltips` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Tooltips#modelName
        * @propertyOf lbServices.Tooltips
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Tooltips`.
        */
        R.modelName = "Tooltips";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Users
 * @header lbServices.Users
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Users` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Users",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Users/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Users#prototype$__findById__accessTokens
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Find a related item by id for accessTokens.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `fk` – `{*}` - Foreign key for accessTokens
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "prototype$__findById__accessTokens": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/accessTokens/:fk",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#prototype$__destroyById__accessTokens
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Delete a related item by id for accessTokens.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `fk` – `{*}` - Foreign key for accessTokens
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__destroyById__accessTokens": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/accessTokens/:fk",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#prototype$__updateById__accessTokens
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Update a related item by id for accessTokens.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `fk` – `{*}` - Foreign key for accessTokens
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "prototype$__updateById__accessTokens": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/accessTokens/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Users.Departments() instead.
            "prototype$__get__Departments": {
              url: urlBase + "/Users/:id/Departments",
              method: "GET",
            },

            // INTERNAL. Use Users.Teams() instead.
            "prototype$__get__Teams": {
              url: urlBase + "/Users/:id/Teams",
              method: "GET",
            },

            // INTERNAL. Use Users.Locations() instead.
            "prototype$__get__Locations": {
              url: urlBase + "/Users/:id/Locations",
              method: "GET",
            },

            // INTERNAL. Use Users.Events.findById() instead.
            "prototype$__findById__Events": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Events/:fk",
              method: "GET",
            },

            // INTERNAL. Use Users.Events.destroyById() instead.
            "prototype$__destroyById__Events": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Events/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Users.Events.updateById() instead.
            "prototype$__updateById__Events": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Events/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Users.Books.findById() instead.
            "prototype$__findById__Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Books/:fk",
              method: "GET",
            },

            // INTERNAL. Use Users.Books.destroyById() instead.
            "prototype$__destroyById__Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Books/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Users.Books.updateById() instead.
            "prototype$__updateById__Books": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Users/:id/Books/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Users.Roles() instead.
            "prototype$__get__Roles": {
              url: urlBase + "/Users/:id/Roles",
              method: "GET",
            },

            // INTERNAL. Use Users.PermissionGroups() instead.
            "prototype$__get__PermissionGroups": {
              url: urlBase + "/Users/:id/PermissionGroups",
              method: "GET",
            },

            // INTERNAL. Use Users.Divisions() instead.
            "prototype$__get__Divisions": {
              url: urlBase + "/Users/:id/Divisions",
              method: "GET",
            },

            // INTERNAL. Use Users.UsersStatus() instead.
            "prototype$__get__UsersStatus": {
              url: urlBase + "/Users/:id/UsersStatus",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#prototype$__get__accessTokens
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Queries accessTokens of Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "prototype$__get__accessTokens": {
              isArray: true,
              url: urlBase + "/Users/:id/accessTokens",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#prototype$__create__accessTokens
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Creates a new instance in accessTokens of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "prototype$__create__accessTokens": {
              url: urlBase + "/Users/:id/accessTokens",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#prototype$__delete__accessTokens
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Deletes all accessTokens of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$__delete__accessTokens": {
              url: urlBase + "/Users/:id/accessTokens",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#prototype$__count__accessTokens
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Counts accessTokens of Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "prototype$__count__accessTokens": {
              url: urlBase + "/Users/:id/accessTokens/count",
              method: "GET",
            },

            // INTERNAL. Use Users.Events() instead.
            "prototype$__get__Events": {
              isArray: true,
              url: urlBase + "/Users/:id/Events",
              method: "GET",
            },

            // INTERNAL. Use Users.Events.create() instead.
            "prototype$__create__Events": {
              url: urlBase + "/Users/:id/Events",
              method: "POST",
            },

            // INTERNAL. Use Users.Events.destroyAll() instead.
            "prototype$__delete__Events": {
              url: urlBase + "/Users/:id/Events",
              method: "DELETE",
            },

            // INTERNAL. Use Users.Events.count() instead.
            "prototype$__count__Events": {
              url: urlBase + "/Users/:id/Events/count",
              method: "GET",
            },

            // INTERNAL. Use Users.Books() instead.
            "prototype$__get__Books": {
              isArray: true,
              url: urlBase + "/Users/:id/Books",
              method: "GET",
            },

            // INTERNAL. Use Users.Books.create() instead.
            "prototype$__create__Books": {
              url: urlBase + "/Users/:id/Books",
              method: "POST",
            },

            // INTERNAL. Use Users.Books.destroyAll() instead.
            "prototype$__delete__Books": {
              url: urlBase + "/Users/:id/Books",
              method: "DELETE",
            },

            // INTERNAL. Use Users.Books.count() instead.
            "prototype$__count__Books": {
              url: urlBase + "/Users/:id/Books/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#create
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Users",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#createMany
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Users",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#upsert
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Users",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#replaceOrCreate
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Users/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#upsertWithWhere
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Users/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#exists
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Users/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#findById
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Users/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#replaceById
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Users/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#find
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Users",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#findOne
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Users/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#updateAll
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Users/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#deleteById
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Users/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#count
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Users/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#prototype$updateAttributes
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Users/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#createChangeStream
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Users/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#login
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Login a user with username/email and password.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `include` – `{string=}` - Related objects to include in the response. See the description of return value for more details.
             *   Default value: `user`.
             *
             *  - `rememberMe` - `boolean` - Whether the authentication credentials
             *     should be remembered in localStorage across app/browser restarts.
             *     Default: `true`.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The response body contains properties of the AccessToken created on login.
             * Depending on the value of `include` parameter, the body may contain additional properties:
             *   - `user` - `U+007BUserU+007D` - Data of the currently logged in user. (`include=user`)
             *
             */
            "login": {
              params: {
                include: 'user',
              },
              interceptor: {
                response: function(response) {
                  var accessToken = response.data;
                  LoopBackAuth.setUser(
                    accessToken.id, accessToken.userId, accessToken.user);
                  LoopBackAuth.rememberMe =
                    response.config.params.rememberMe !== false;
                  LoopBackAuth.save();
                  return response.resource;
                },
              },
              url: urlBase + "/Users/login",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#logout
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Logout a user with access token.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `access_token` – `{string=}` - Do not supply this argument, it is automatically extracted from request headers.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "logout": {
              interceptor: {
                response: function(response) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return response.resource;
                },
                responseError: function(responseError) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return responseError.resource;
                },
              },
              url: urlBase + "/Users/logout",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#confirm
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Confirm a user registration with email verification token.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `uid` – `{string}` -
             *
             *  - `token` – `{string}` -
             *
             *  - `redirect` – `{string=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "confirm": {
              url: urlBase + "/Users/confirm",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#resetPassword
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Reset password for a user with email.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "resetPassword": {
              url: urlBase + "/Users/reset",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#setNewPassword
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Set new password for authenticated user
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "setNewPassword": {
              isArray: true,
              url: urlBase + "/Users/setNewPassword",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#getUserStats
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Get list of all changes to a book
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "getUserStats": {
              isArray: true,
              url: urlBase + "/Users/getUserStats",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#approvalListUsers
             * @methodOf lbServices.Users
             *
             * @description
             *
             * getApprovalListOfUsersFromBook
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "approvalListUsers": {
              isArray: true,
              url: urlBase + "/Users/approvalListUsers",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#switchUser
             * @methodOf lbServices.Users
             *
             * @description
             *
             * switch user
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
            "switchUser": {
              isArray: true,
              url: urlBase + "/Users/switchUser",
              method: "POST",
            },

            // INTERNAL. Use Books.Users() instead.
            "::get::Books::Users": {
              url: urlBase + "/Books/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Departments.Users.findById() instead.
            "::findById::Departments::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Departments.Users.destroyById() instead.
            "::destroyById::Departments::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Departments.Users.updateById() instead.
            "::updateById::Departments::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Departments/:id/Users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Departments.Users() instead.
            "::get::Departments::Users": {
              isArray: true,
              url: urlBase + "/Departments/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Departments.Users.create() instead.
            "::create::Departments::Users": {
              url: urlBase + "/Departments/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Departments.Users.createMany() instead.
            "::createMany::Departments::Users": {
              isArray: true,
              url: urlBase + "/Departments/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Departments.Users.destroyAll() instead.
            "::delete::Departments::Users": {
              url: urlBase + "/Departments/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use Departments.Users.count() instead.
            "::count::Departments::Users": {
              url: urlBase + "/Departments/:id/Users/count",
              method: "GET",
            },

            // INTERNAL. Use Divisions.Users.findById() instead.
            "::findById::Divisions::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Divisions.Users.destroyById() instead.
            "::destroyById::Divisions::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Divisions.Users.updateById() instead.
            "::updateById::Divisions::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Divisions/:id/Users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Divisions.Users() instead.
            "::get::Divisions::Users": {
              isArray: true,
              url: urlBase + "/Divisions/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Divisions.Users.create() instead.
            "::create::Divisions::Users": {
              url: urlBase + "/Divisions/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Divisions.Users.createMany() instead.
            "::createMany::Divisions::Users": {
              isArray: true,
              url: urlBase + "/Divisions/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Divisions.Users.destroyAll() instead.
            "::delete::Divisions::Users": {
              url: urlBase + "/Divisions/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use Divisions.Users.count() instead.
            "::count::Divisions::Users": {
              url: urlBase + "/Divisions/:id/Users/count",
              method: "GET",
            },

            // INTERNAL. Use Events.Users() instead.
            "::get::Events::Users": {
              url: urlBase + "/Events/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use InsurerDetails.Users.findById() instead.
            "::findById::InsurerDetails::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InsurerDetails/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use InsurerDetails.Users.destroyById() instead.
            "::destroyById::InsurerDetails::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InsurerDetails/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use InsurerDetails.Users.updateById() instead.
            "::updateById::InsurerDetails::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/InsurerDetails/:id/Users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use InsurerDetails.Users() instead.
            "::get::InsurerDetails::Users": {
              isArray: true,
              url: urlBase + "/InsurerDetails/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use InsurerDetails.Users.create() instead.
            "::create::InsurerDetails::Users": {
              url: urlBase + "/InsurerDetails/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use InsurerDetails.Users.createMany() instead.
            "::createMany::InsurerDetails::Users": {
              isArray: true,
              url: urlBase + "/InsurerDetails/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use InsurerDetails.Users.destroyAll() instead.
            "::delete::InsurerDetails::Users": {
              url: urlBase + "/InsurerDetails/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use InsurerDetails.Users.count() instead.
            "::count::InsurerDetails::Users": {
              url: urlBase + "/InsurerDetails/:id/Users/count",
              method: "GET",
            },

            // INTERNAL. Use Locations.Users.findById() instead.
            "::findById::Locations::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Locations/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Locations.Users.destroyById() instead.
            "::destroyById::Locations::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Locations/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Locations.Users.updateById() instead.
            "::updateById::Locations::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Locations/:id/Users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Locations.Users() instead.
            "::get::Locations::Users": {
              isArray: true,
              url: urlBase + "/Locations/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Locations.Users.create() instead.
            "::create::Locations::Users": {
              url: urlBase + "/Locations/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Locations.Users.createMany() instead.
            "::createMany::Locations::Users": {
              isArray: true,
              url: urlBase + "/Locations/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Locations.Users.destroyAll() instead.
            "::delete::Locations::Users": {
              url: urlBase + "/Locations/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use Locations.Users.count() instead.
            "::count::Locations::Users": {
              url: urlBase + "/Locations/:id/Users/count",
              method: "GET",
            },

            // INTERNAL. Use Roles.Users.findById() instead.
            "::findById::Roles::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Roles.Users.destroyById() instead.
            "::destroyById::Roles::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Roles.Users.updateById() instead.
            "::updateById::Roles::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Roles/:id/Users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Roles.Users() instead.
            "::get::Roles::Users": {
              isArray: true,
              url: urlBase + "/Roles/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Roles.Users.create() instead.
            "::create::Roles::Users": {
              url: urlBase + "/Roles/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Roles.Users.createMany() instead.
            "::createMany::Roles::Users": {
              isArray: true,
              url: urlBase + "/Roles/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Roles.Users.destroyAll() instead.
            "::delete::Roles::Users": {
              url: urlBase + "/Roles/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use Roles.Users.count() instead.
            "::count::Roles::Users": {
              url: urlBase + "/Roles/:id/Users/count",
              method: "GET",
            },

            // INTERNAL. Use Teams.Users.findById() instead.
            "::findById::Teams::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use Teams.Users.destroyById() instead.
            "::destroyById::Teams::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Teams.Users.updateById() instead.
            "::updateById::Teams::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Teams/:id/Users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Teams.Users() instead.
            "::get::Teams::Users": {
              isArray: true,
              url: urlBase + "/Teams/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use Teams.Users.create() instead.
            "::create::Teams::Users": {
              url: urlBase + "/Teams/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Teams.Users.createMany() instead.
            "::createMany::Teams::Users": {
              isArray: true,
              url: urlBase + "/Teams/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use Teams.Users.destroyAll() instead.
            "::delete::Teams::Users": {
              url: urlBase + "/Teams/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use Teams.Users.count() instead.
            "::count::Teams::Users": {
              url: urlBase + "/Teams/:id/Users/count",
              method: "GET",
            },

            // INTERNAL. Use UsersStatus.Users.findById() instead.
            "::findById::UsersStatus::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/UsersStatuses/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use UsersStatus.Users.destroyById() instead.
            "::destroyById::UsersStatus::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/UsersStatuses/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use UsersStatus.Users.updateById() instead.
            "::updateById::UsersStatus::Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/UsersStatuses/:id/Users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use UsersStatus.Users() instead.
            "::get::UsersStatus::Users": {
              isArray: true,
              url: urlBase + "/UsersStatuses/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use UsersStatus.Users.create() instead.
            "::create::UsersStatus::Users": {
              url: urlBase + "/UsersStatuses/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use UsersStatus.Users.createMany() instead.
            "::createMany::UsersStatus::Users": {
              isArray: true,
              url: urlBase + "/UsersStatuses/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use UsersStatus.Users.destroyAll() instead.
            "::delete::UsersStatus::Users": {
              url: urlBase + "/UsersStatuses/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use UsersStatus.Users.count() instead.
            "::count::UsersStatus::Users": {
              url: urlBase + "/UsersStatuses/:id/Users/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Users#getCurrent
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Get data of the currently logged user. Fail with HTTP result 401
             * when there is no user logged in.
             *
             * @param {function(Object,Object)=} successCb
             *    Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *    `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             */
            'getCurrent': {
              url: urlBase + "/Users" + '/:id',
              method: 'GET',
              params: {
                id: function() {
                  var id = LoopBackAuth.currentUserId;
                  if (id == null) id = '__anonymous__';
                  return id;
                },
              },
              interceptor: {
                response: function(response) {
                  LoopBackAuth.currentUserData = response.data;
                  return response.resource;
                },
                responseError: function(responseError) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return $q.reject(responseError);
                },
              },
              __isGetCurrentUser__: true,
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Users#patchOrCreate
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Users#updateOrCreate
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Users#patchOrCreateWithWhere
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Users#update
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Users#destroyById
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Users#removeById
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Users#patchAttributes
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];

        /**
         * @ngdoc method
         * @name lbServices.Users#getCachedCurrent
         * @methodOf lbServices.Users
         *
         * @description
         *
         * Get data of the currently logged user that was returned by the last
         * call to {@link lbServices.Users#login} or
         * {@link lbServices.Users#getCurrent}. Return null when there
         * is no user logged in or the data of the current user were not fetched
         * yet.
         *
         * @returns {Object} A Users instance.
         */
        R.getCachedCurrent = function() {
          var data = LoopBackAuth.currentUserData;
          return data ? new R(data) : null;
        };

        /**
         * @ngdoc method
         * @name lbServices.Users#isAuthenticated
         * @methodOf lbServices.Users
         *
         * @returns {boolean} True if the current user is authenticated (logged in).
         */
        R.isAuthenticated = function() {
          return this.getCurrentId() != null;
        };

        /**
         * @ngdoc method
         * @name lbServices.Users#getCurrentId
         * @methodOf lbServices.Users
         *
         * @returns {Object} Id of the currently logged-in user or null.
         */
        R.getCurrentId = function() {
          return LoopBackAuth.currentUserId;
        };

        /**
        * @ngdoc property
        * @name lbServices.Users#modelName
        * @propertyOf lbServices.Users
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Users`.
        */
        R.modelName = "Users";


            /**
             * @ngdoc method
             * @name lbServices.Users#Departments
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Fetches belongsTo relation Departments.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Departments` object.)
             * </em>
             */
        R.Departments = function() {
          var TargetResource = $injector.get("Departments");
          var action = TargetResource["::get::Users::Departments"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users#Teams
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Fetches belongsTo relation Teams.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Teams` object.)
             * </em>
             */
        R.Teams = function() {
          var TargetResource = $injector.get("Teams");
          var action = TargetResource["::get::Users::Teams"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users#Locations
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Fetches belongsTo relation Locations.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Locations` object.)
             * </em>
             */
        R.Locations = function() {
          var TargetResource = $injector.get("Locations");
          var action = TargetResource["::get::Users::Locations"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Users.Events
     * @header lbServices.Users.Events
     * @object
     * @description
     *
     * The object `Users.Events` groups methods
     * manipulating `Events` instances related to `Users`.
     *
     * Call {@link lbServices.Users#Events Users.Events()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Users#Events
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Queries Events of Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R.Events = function() {
          var TargetResource = $injector.get("Events");
          var action = TargetResource["::get::Users::Events"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Events#count
             * @methodOf lbServices.Users.Events
             *
             * @description
             *
             * Counts Events of Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Events.count = function() {
          var TargetResource = $injector.get("Events");
          var action = TargetResource["::count::Users::Events"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Events#create
             * @methodOf lbServices.Users.Events
             *
             * @description
             *
             * Creates a new instance in Events of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R.Events.create = function() {
          var TargetResource = $injector.get("Events");
          var action = TargetResource["::create::Users::Events"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Events#createMany
             * @methodOf lbServices.Users.Events
             *
             * @description
             *
             * Creates a new instance in Events of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R.Events.createMany = function() {
          var TargetResource = $injector.get("Events");
          var action = TargetResource["::createMany::Users::Events"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Events#destroyAll
             * @methodOf lbServices.Users.Events
             *
             * @description
             *
             * Deletes all Events of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Events.destroyAll = function() {
          var TargetResource = $injector.get("Events");
          var action = TargetResource["::delete::Users::Events"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Events#destroyById
             * @methodOf lbServices.Users.Events
             *
             * @description
             *
             * Delete a related item by id for Events.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `fk` – `{*}` - Foreign key for Events
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Events.destroyById = function() {
          var TargetResource = $injector.get("Events");
          var action = TargetResource["::destroyById::Users::Events"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Events#findById
             * @methodOf lbServices.Users.Events
             *
             * @description
             *
             * Find a related item by id for Events.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `fk` – `{*}` - Foreign key for Events
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R.Events.findById = function() {
          var TargetResource = $injector.get("Events");
          var action = TargetResource["::findById::Users::Events"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Events#updateById
             * @methodOf lbServices.Users.Events
             *
             * @description
             *
             * Update a related item by id for Events.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `fk` – `{*}` - Foreign key for Events
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Events` object.)
             * </em>
             */
        R.Events.updateById = function() {
          var TargetResource = $injector.get("Events");
          var action = TargetResource["::updateById::Users::Events"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Users.Books
     * @header lbServices.Users.Books
     * @object
     * @description
     *
     * The object `Users.Books` groups methods
     * manipulating `Books` instances related to `Users`.
     *
     * Call {@link lbServices.Users#Books Users.Books()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Users#Books
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Queries Books of Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::get::Users::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Books#count
             * @methodOf lbServices.Users.Books
             *
             * @description
             *
             * Counts Books of Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Books.count = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::count::Users::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Books#create
             * @methodOf lbServices.Users.Books
             *
             * @description
             *
             * Creates a new instance in Books of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books.create = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::create::Users::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Books#createMany
             * @methodOf lbServices.Users.Books
             *
             * @description
             *
             * Creates a new instance in Books of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books.createMany = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::createMany::Users::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Books#destroyAll
             * @methodOf lbServices.Users.Books
             *
             * @description
             *
             * Deletes all Books of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Books.destroyAll = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::delete::Users::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Books#destroyById
             * @methodOf lbServices.Users.Books
             *
             * @description
             *
             * Delete a related item by id for Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `fk` – `{*}` - Foreign key for Books
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Books.destroyById = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::destroyById::Users::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Books#findById
             * @methodOf lbServices.Users.Books
             *
             * @description
             *
             * Find a related item by id for Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `fk` – `{*}` - Foreign key for Books
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books.findById = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::findById::Users::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users.Books#updateById
             * @methodOf lbServices.Users.Books
             *
             * @description
             *
             * Update a related item by id for Books.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `fk` – `{*}` - Foreign key for Books
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Books` object.)
             * </em>
             */
        R.Books.updateById = function() {
          var TargetResource = $injector.get("Books");
          var action = TargetResource["::updateById::Users::Books"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users#Roles
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Fetches belongsTo relation Roles.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Roles` object.)
             * </em>
             */
        R.Roles = function() {
          var TargetResource = $injector.get("Roles");
          var action = TargetResource["::get::Users::Roles"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users#PermissionGroups
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Fetches belongsTo relation PermissionGroups.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `PermissionGroups` object.)
             * </em>
             */
        R.PermissionGroups = function() {
          var TargetResource = $injector.get("PermissionGroups");
          var action = TargetResource["::get::Users::PermissionGroups"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users#Divisions
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Fetches belongsTo relation Divisions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Divisions` object.)
             * </em>
             */
        R.Divisions = function() {
          var TargetResource = $injector.get("Divisions");
          var action = TargetResource["::get::Users::Divisions"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Users#UsersStatus
             * @methodOf lbServices.Users
             *
             * @description
             *
             * Fetches belongsTo relation UsersStatus.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Users id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
        R.UsersStatus = function() {
          var TargetResource = $injector.get("UsersStatus");
          var action = TargetResource["::get::Users::UsersStatus"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.UsersStatus
 * @header lbServices.UsersStatus
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `UsersStatus` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "UsersStatus",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/UsersStatuses/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use UsersStatus.Users.findById() instead.
            "prototype$__findById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/UsersStatuses/:id/Users/:fk",
              method: "GET",
            },

            // INTERNAL. Use UsersStatus.Users.destroyById() instead.
            "prototype$__destroyById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/UsersStatuses/:id/Users/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use UsersStatus.Users.updateById() instead.
            "prototype$__updateById__Users": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/UsersStatuses/:id/Users/:fk",
              method: "PUT",
            },

            // INTERNAL. Use UsersStatus.Users() instead.
            "prototype$__get__Users": {
              isArray: true,
              url: urlBase + "/UsersStatuses/:id/Users",
              method: "GET",
            },

            // INTERNAL. Use UsersStatus.Users.create() instead.
            "prototype$__create__Users": {
              url: urlBase + "/UsersStatuses/:id/Users",
              method: "POST",
            },

            // INTERNAL. Use UsersStatus.Users.destroyAll() instead.
            "prototype$__delete__Users": {
              url: urlBase + "/UsersStatuses/:id/Users",
              method: "DELETE",
            },

            // INTERNAL. Use UsersStatus.Users.count() instead.
            "prototype$__count__Users": {
              url: urlBase + "/UsersStatuses/:id/Users/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#create
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/UsersStatuses",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#createMany
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/UsersStatuses",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#upsert
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/UsersStatuses",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#replaceOrCreate
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/UsersStatuses/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#upsertWithWhere
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/UsersStatuses/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#exists
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/UsersStatuses/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#findById
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/UsersStatuses/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#replaceById
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/UsersStatuses/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#find
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/UsersStatuses",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#findOne
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/UsersStatuses/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#updateAll
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/UsersStatuses/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#deleteById
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/UsersStatuses/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#count
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/UsersStatuses/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#prototype$updateAttributes
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UsersStatus id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/UsersStatuses/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#createChangeStream
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/UsersStatuses/change-stream",
              method: "POST",
            },

            // INTERNAL. Use Users.UsersStatus() instead.
            "::get::Users::UsersStatus": {
              url: urlBase + "/Users/:id/UsersStatus",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#patchOrCreate
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#updateOrCreate
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#patchOrCreateWithWhere
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#update
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#destroyById
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#removeById
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#patchAttributes
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UsersStatus id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `UsersStatus` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.UsersStatus#modelName
        * @propertyOf lbServices.UsersStatus
        * @description
        * The name of the model represented by this $resource,
        * i.e. `UsersStatus`.
        */
        R.modelName = "UsersStatus";

    /**
     * @ngdoc object
     * @name lbServices.UsersStatus.Users
     * @header lbServices.UsersStatus.Users
     * @object
     * @description
     *
     * The object `UsersStatus.Users` groups methods
     * manipulating `Users` instances related to `UsersStatus`.
     *
     * Call {@link lbServices.UsersStatus#Users UsersStatus.Users()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.UsersStatus#Users
             * @methodOf lbServices.UsersStatus
             *
             * @description
             *
             * Queries Users of UsersStatus.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UsersStatus id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::get::UsersStatus::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus.Users#count
             * @methodOf lbServices.UsersStatus.Users
             *
             * @description
             *
             * Counts Users of UsersStatus.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UsersStatus id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Users.count = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::count::UsersStatus::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus.Users#create
             * @methodOf lbServices.UsersStatus.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UsersStatus id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.create = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::create::UsersStatus::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus.Users#createMany
             * @methodOf lbServices.UsersStatus.Users
             *
             * @description
             *
             * Creates a new instance in Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UsersStatus id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.createMany = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::createMany::UsersStatus::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus.Users#destroyAll
             * @methodOf lbServices.UsersStatus.Users
             *
             * @description
             *
             * Deletes all Users of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UsersStatus id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyAll = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::delete::UsersStatus::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus.Users#destroyById
             * @methodOf lbServices.UsersStatus.Users
             *
             * @description
             *
             * Delete a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UsersStatus id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Users.destroyById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::destroyById::UsersStatus::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus.Users#findById
             * @methodOf lbServices.UsersStatus.Users
             *
             * @description
             *
             * Find a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UsersStatus id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.findById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::findById::UsersStatus::Users"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.UsersStatus.Users#updateById
             * @methodOf lbServices.UsersStatus.Users
             *
             * @description
             *
             * Update a related item by id for Users.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - UsersStatus id
             *
             *  - `fk` – `{*}` - Foreign key for Users
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Users` object.)
             * </em>
             */
        R.Users.updateById = function() {
          var TargetResource = $injector.get("Users");
          var action = TargetResource["::updateById::UsersStatus::Users"];
          return action.apply(R, arguments);
        };


        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Versions
 * @header lbServices.Versions
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Versions` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Versions",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Versions/:id",
          { 'id': '@id' },
          {

            // INTERNAL. Use Versions.Contents() instead.
            "prototype$__get__Contents": {
              url: urlBase + "/Versions/:id/Contents",
              method: "GET",
            },

            // INTERNAL. Use Versions.Structures.findById() instead.
            "prototype$__findById__Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/Structures/:fk",
              method: "GET",
            },

            // INTERNAL. Use Versions.Structures.destroyById() instead.
            "prototype$__destroyById__Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/Structures/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Versions.Structures.updateById() instead.
            "prototype$__updateById__Structures": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/Structures/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Versions.History.findById() instead.
            "prototype$__findById__History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/History/:fk",
              method: "GET",
            },

            // INTERNAL. Use Versions.History.destroyById() instead.
            "prototype$__destroyById__History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/History/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Versions.History.updateById() instead.
            "prototype$__updateById__History": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Versions/:id/History/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Versions.Structures() instead.
            "prototype$__get__Structures": {
              isArray: true,
              url: urlBase + "/Versions/:id/Structures",
              method: "GET",
            },

            // INTERNAL. Use Versions.Structures.create() instead.
            "prototype$__create__Structures": {
              url: urlBase + "/Versions/:id/Structures",
              method: "POST",
            },

            // INTERNAL. Use Versions.Structures.destroyAll() instead.
            "prototype$__delete__Structures": {
              url: urlBase + "/Versions/:id/Structures",
              method: "DELETE",
            },

            // INTERNAL. Use Versions.Structures.count() instead.
            "prototype$__count__Structures": {
              url: urlBase + "/Versions/:id/Structures/count",
              method: "GET",
            },

            // INTERNAL. Use Versions.History() instead.
            "prototype$__get__History": {
              isArray: true,
              url: urlBase + "/Versions/:id/History",
              method: "GET",
            },

            // INTERNAL. Use Versions.History.create() instead.
            "prototype$__create__History": {
              url: urlBase + "/Versions/:id/History",
              method: "POST",
            },

            // INTERNAL. Use Versions.History.destroyAll() instead.
            "prototype$__delete__History": {
              url: urlBase + "/Versions/:id/History",
              method: "DELETE",
            },

            // INTERNAL. Use Versions.History.count() instead.
            "prototype$__count__History": {
              url: urlBase + "/Versions/:id/History/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#create
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/Versions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#createMany
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/Versions",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#upsert
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/Versions",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#replaceOrCreate
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Replace an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "replaceOrCreate": {
              url: urlBase + "/Versions/replaceOrCreate",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#upsertWithWhere
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "upsertWithWhere": {
              url: urlBase + "/Versions/upsertWithWhere",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#exists
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Check whether a model instance exists in the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `exists` – `{boolean=}` -
             */
            "exists": {
              url: urlBase + "/Versions/:id/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#findById
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Find a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Versions/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#replaceById
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Replace attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "replaceById": {
              url: urlBase + "/Versions/:id/replace",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#find
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Versions",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#findOne
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Versions/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#updateAll
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
            "updateAll": {
              url: urlBase + "/Versions/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#deleteById
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "deleteById": {
              url: urlBase + "/Versions/:id",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#count
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Versions/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#prototype$updateAttributes
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/Versions/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#createChangeStream
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Create a change stream.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `changes` – `{ReadableStream=}` -
             */
            "createChangeStream": {
              url: urlBase + "/Versions/change-stream",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#checkVersion
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Get id of version if matched
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "checkVersion": {
              url: urlBase + "/Versions/checkVersion",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#approveVersionsByBook
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Set version status to approved (2) by bookId if live
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "approveVersionsByBook": {
              url: urlBase + "/Versions/approveVersionsByBook",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Versions#getFilePaths
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Get id of version if matched
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
            "getFilePaths": {
              url: urlBase + "/Versions/getFilePaths",
              method: "POST",
            },

            // INTERNAL. Use Contents.Versions.findById() instead.
            "::findById::Contents::Versions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Contents/:id/Versions/:fk",
              method: "GET",
            },

            // INTERNAL. Use Contents.Versions.destroyById() instead.
            "::destroyById::Contents::Versions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Contents/:id/Versions/:fk",
              method: "DELETE",
            },

            // INTERNAL. Use Contents.Versions.updateById() instead.
            "::updateById::Contents::Versions": {
              params: {
                'fk': '@fk',
              },
              url: urlBase + "/Contents/:id/Versions/:fk",
              method: "PUT",
            },

            // INTERNAL. Use Contents.Versions() instead.
            "::get::Contents::Versions": {
              isArray: true,
              url: urlBase + "/Contents/:id/Versions",
              method: "GET",
            },

            // INTERNAL. Use Contents.Versions.create() instead.
            "::create::Contents::Versions": {
              url: urlBase + "/Contents/:id/Versions",
              method: "POST",
            },

            // INTERNAL. Use Contents.Versions.createMany() instead.
            "::createMany::Contents::Versions": {
              isArray: true,
              url: urlBase + "/Contents/:id/Versions",
              method: "POST",
            },

            // INTERNAL. Use Contents.Versions.destroyAll() instead.
            "::delete::Contents::Versions": {
              url: urlBase + "/Contents/:id/Versions",
              method: "DELETE",
            },

            // INTERNAL. Use Contents.Versions.count() instead.
            "::count::Contents::Versions": {
              url: urlBase + "/Contents/:id/Versions/count",
              method: "GET",
            },

            // INTERNAL. Use History.versions() instead.
            "::get::History::versions": {
              url: urlBase + "/Histories/:id/versions",
              method: "GET",
            },

            // INTERNAL. Use History.versionsNew() instead.
            "::get::History::versionsNew": {
              url: urlBase + "/Histories/:id/versionsNew",
              method: "GET",
            },

            // INTERNAL. Use OrderHistory.versions() instead.
            "::get::OrderHistory::versions": {
              url: urlBase + "/OrderHistories/:id/versions",
              method: "GET",
            },

            // INTERNAL. Use OrderHistory.versionsNew() instead.
            "::get::OrderHistory::versionsNew": {
              url: urlBase + "/OrderHistories/:id/versionsNew",
              method: "GET",
            },

            // INTERNAL. Use Structures.versions() instead.
            "::get::Structures::versions": {
              url: urlBase + "/Structures/:id/versions",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Versions#patchOrCreate
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R["patchOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Versions#updateOrCreate
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Patch an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Versions#patchOrCreateWithWhere
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source based on the where criteria.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R["patchOrCreateWithWhere"] = R["upsertWithWhere"];

            /**
             * @ngdoc method
             * @name lbServices.Versions#update
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Update instances of the model matched by {{where}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Information related to the outcome of the operation
             */
        R["update"] = R["updateAll"];

            /**
             * @ngdoc method
             * @name lbServices.Versions#destroyById
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R["destroyById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Versions#removeById
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Delete a model instance by {{id}} from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R["removeById"] = R["deleteById"];

            /**
             * @ngdoc method
             * @name lbServices.Versions#patchAttributes
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Patch attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Versions` object.)
             * </em>
             */
        R["patchAttributes"] = R["prototype$updateAttributes"];


        /**
        * @ngdoc property
        * @name lbServices.Versions#modelName
        * @propertyOf lbServices.Versions
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Versions`.
        */
        R.modelName = "Versions";


            /**
             * @ngdoc method
             * @name lbServices.Versions#Contents
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Fetches belongsTo relation Contents.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `refresh` – `{boolean=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Contents` object.)
             * </em>
             */
        R.Contents = function() {
          var TargetResource = $injector.get("Contents");
          var action = TargetResource["::get::Versions::Contents"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Versions.Structures
     * @header lbServices.Versions.Structures
     * @object
     * @description
     *
     * The object `Versions.Structures` groups methods
     * manipulating `Structures` instances related to `Versions`.
     *
     * Call {@link lbServices.Versions#Structures Versions.Structures()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Versions#Structures
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Queries Structures of Versions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::get::Versions::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.Structures#count
             * @methodOf lbServices.Versions.Structures
             *
             * @description
             *
             * Counts Structures of Versions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.Structures.count = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::count::Versions::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.Structures#create
             * @methodOf lbServices.Versions.Structures
             *
             * @description
             *
             * Creates a new instance in Structures of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures.create = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::create::Versions::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.Structures#createMany
             * @methodOf lbServices.Versions.Structures
             *
             * @description
             *
             * Creates a new instance in Structures of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures.createMany = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::createMany::Versions::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.Structures#destroyAll
             * @methodOf lbServices.Versions.Structures
             *
             * @description
             *
             * Deletes all Structures of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Structures.destroyAll = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::delete::Versions::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.Structures#destroyById
             * @methodOf lbServices.Versions.Structures
             *
             * @description
             *
             * Delete a related item by id for Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `fk` – `{*}` - Foreign key for Structures
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.Structures.destroyById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::destroyById::Versions::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.Structures#findById
             * @methodOf lbServices.Versions.Structures
             *
             * @description
             *
             * Find a related item by id for Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `fk` – `{*}` - Foreign key for Structures
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures.findById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::findById::Versions::Structures"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.Structures#updateById
             * @methodOf lbServices.Versions.Structures
             *
             * @description
             *
             * Update a related item by id for Structures.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `fk` – `{*}` - Foreign key for Structures
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Structures` object.)
             * </em>
             */
        R.Structures.updateById = function() {
          var TargetResource = $injector.get("Structures");
          var action = TargetResource["::updateById::Versions::Structures"];
          return action.apply(R, arguments);
        };
    /**
     * @ngdoc object
     * @name lbServices.Versions.History
     * @header lbServices.Versions.History
     * @object
     * @description
     *
     * The object `Versions.History` groups methods
     * manipulating `History` instances related to `Versions`.
     *
     * Call {@link lbServices.Versions#History Versions.History()}
     * to query all related instances.
     */


            /**
             * @ngdoc method
             * @name lbServices.Versions#History
             * @methodOf lbServices.Versions
             *
             * @description
             *
             * Queries History of Versions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `filter` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::get::Versions::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.History#count
             * @methodOf lbServices.Versions.History
             *
             * @description
             *
             * Counts History of Versions.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
        R.History.count = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::count::Versions::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.History#create
             * @methodOf lbServices.Versions.History
             *
             * @description
             *
             * Creates a new instance in History of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History.create = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::create::Versions::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.History#createMany
             * @methodOf lbServices.Versions.History
             *
             * @description
             *
             * Creates a new instance in History of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History.createMany = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::createMany::Versions::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.History#destroyAll
             * @methodOf lbServices.Versions.History
             *
             * @description
             *
             * Deletes all History of this model.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `where` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.History.destroyAll = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::delete::Versions::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.History#destroyById
             * @methodOf lbServices.Versions.History
             *
             * @description
             *
             * Delete a related item by id for History.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `fk` – `{*}` - Foreign key for History
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
        R.History.destroyById = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::destroyById::Versions::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.History#findById
             * @methodOf lbServices.Versions.History
             *
             * @description
             *
             * Find a related item by id for History.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `fk` – `{*}` - Foreign key for History
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History.findById = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::findById::Versions::History"];
          return action.apply(R, arguments);
        };

            /**
             * @ngdoc method
             * @name lbServices.Versions.History#updateById
             * @methodOf lbServices.Versions.History
             *
             * @description
             *
             * Update a related item by id for History.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Versions id
             *
             *  - `fk` – `{*}` - Foreign key for History
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `History` object.)
             * </em>
             */
        R.History.updateById = function() {
          var TargetResource = $injector.get("History");
          var action = TargetResource["::updateById::Versions::History"];
          return action.apply(R, arguments);
        };


        return R;
      }]);


  module
  .factory('LoopBackAuth', function() {
    var props = ['accessTokenId', 'currentUserId', 'rememberMe'];
    var propsPrefix = '$LoopBack$';

    function LoopBackAuth() {
      var self = this;
      props.forEach(function(name) {
        self[name] = load(name);
      });
      this.currentUserData = null;
    }

    LoopBackAuth.prototype.save = function() {
      var self = this;
      var storage = this.rememberMe ? localStorage : sessionStorage;
      props.forEach(function(name) {
        save(storage, name, self[name]);
      });
    };

    LoopBackAuth.prototype.setUser = function(accessTokenId, userId, userData) {
      this.accessTokenId = accessTokenId;
      this.currentUserId = userId;
      this.currentUserData = userData;
    };

    LoopBackAuth.prototype.clearUser = function() {
      this.accessTokenId = null;
      this.currentUserId = null;
      this.currentUserData = null;
    };

    LoopBackAuth.prototype.clearStorage = function() {
      props.forEach(function(name) {
        save(sessionStorage, name, null);
        save(localStorage, name, null);
      });
    };

    return new LoopBackAuth();

    // Note: LocalStorage converts the value to string
    // We are using empty string as a marker for null/undefined values.
    function save(storage, name, value) {
      try {
        var key = propsPrefix + name;
        if (value == null) value = '';
        storage[key] = value;
      } catch (err) {
        console.log('Cannot access local/session storage:', err);
      }
    }

    function load(name) {
      var key = propsPrefix + name;
      return localStorage[key] || sessionStorage[key] || null;
    }
  })
  .config(['$httpProvider', function($httpProvider) {
    $httpProvider.interceptors.push('LoopBackAuthRequestInterceptor');
  }])
  .factory('LoopBackAuthRequestInterceptor', ['$q', 'LoopBackAuth',
    function($q, LoopBackAuth) {
      return {
        'request': function(config) {
          // filter out external requests
          var host = getHost(config.url);
          if (host && host !== urlBaseHost) {
            return config;
          }

          if (LoopBackAuth.accessTokenId) {
            config.headers[authHeader] = LoopBackAuth.accessTokenId;
          } else if (config.__isGetCurrentUser__) {
            // Return a stub 401 error for User.getCurrent() when
            // there is no user logged in
            var res = {
              body: { error: { status: 401 }},
              status: 401,
              config: config,
              headers: function() { return undefined; },
            };
            return $q.reject(res);
          }
          return config || $q.when(config);
        },
      };
    }])

  /**
   * @ngdoc object
   * @name lbServices.LoopBackResourceProvider
   * @header lbServices.LoopBackResourceProvider
   * @description
   * Use `LoopBackResourceProvider` to change the global configuration
   * settings used by all models. Note that the provider is available
   * to Configuration Blocks only, see
   * {@link https://docs.angularjs.org/guide/module#module-loading-dependencies Module Loading & Dependencies}
   * for more details.
   *
   * ## Example
   *
   * ```js
   * angular.module('app')
   *  .config(function(LoopBackResourceProvider) {
   *     LoopBackResourceProvider.setAuthHeader('X-Access-Token');
   *  });
   * ```
   */
  .provider('LoopBackResource', function LoopBackResourceProvider() {
    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#setAuthHeader
     * @methodOf lbServices.LoopBackResourceProvider
     * @param {string} header The header name to use, e.g. `X-Access-Token`
     * @description
     * Configure the REST transport to use a different header for sending
     * the authentication token. It is sent in the `Authorization` header
     * by default.
     */
    this.setAuthHeader = function(header) {
      authHeader = header;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#getAuthHeader
     * @methodOf lbServices.LoopBackResourceProvider
     * @description
     * Get the header name that is used for sending the authentication token.
     */
    this.getAuthHeader = function() {
      return authHeader;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#setUrlBase
     * @methodOf lbServices.LoopBackResourceProvider
     * @param {string} url The URL to use, e.g. `/api` or `//example.com/api`.
     * @description
     * Change the URL of the REST API server. By default, the URL provided
     * to the code generator (`lb-ng` or `grunt-loopback-sdk-angular`) is used.
     */
    this.setUrlBase = function(url) {
      urlBase = url;
      urlBaseHost = getHost(urlBase) || location.host;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#getUrlBase
     * @methodOf lbServices.LoopBackResourceProvider
     * @description
     * Get the URL of the REST API server. The URL provided
     * to the code generator (`lb-ng` or `grunt-loopback-sdk-angular`) is used.
     */
    this.getUrlBase = function() {
      return urlBase;
    };

    this.$get = ['$resource', function($resource) {
      var LoopBackResource = function(url, params, actions) {
        var resource = $resource(url, params, actions);

        // Angular always calls POST on $save()
        // This hack is based on
        // http://kirkbushell.me/angular-js-using-ng-resource-in-a-more-restful-manner/
        resource.prototype.$save = function(success, error) {
          // Fortunately, LoopBack provides a convenient `upsert` method
          // that exactly fits our needs.
          var result = resource.upsert.call(this, {}, this, success, error);
          return result.$promise || result;
        };
        return resource;
      };

      LoopBackResource.getUrlBase = function() {
        return urlBase;
      };

      LoopBackResource.getAuthHeader = function() {
        return authHeader;
      };

      return LoopBackResource;
    }];
  });
})(window, window.angular);

(function () {
  'use strict';
  angular
    .module('wrapsv1')
    .config(config);
  function config($urlRouterProvider) {
    $urlRouterProvider.rule(function ($injector, $location) {
      var path = $location.path();
      var newPath;
      var hasTrailingSlash = path[path.length - 1] === '/';
      if (hasTrailingSlash) {
        // if last character is a slash, return the same url without the slash
        newPath = path.substr(0, path.length - 1);
        return newPath;
      }
    });
    $urlRouterProvider.otherwise('/login');
  }
  config.$inject = ["$urlRouterProvider"];
}());
