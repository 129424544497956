(function () {
  'use strict';

  angular
    .module('library.addartwork')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('addartwork', {
        url: '/addartwork',
        templateUrl: 'library/addartwork/addartwork.tpl.html',
        controller: 'AddartworkCtrl',
        controllerAs: 'addartwork'
      });
  }
}());
