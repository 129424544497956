angular
    .module('library')
    .component('inputWfStep', {
        templateUrl: 'library/library-components/right-menu/input-wf-step/input-wf-step.tpl.html',
        controller: inputWfStepCtrl,
        controllerAs:'inputWfStep',
        bindings:{
            array:'=',
            disabled: '=',
            isvalid: '=',
            ownerid: '='
        }

    });

inputWfStepCtrl.$inject = ['$scope', 'Wraps'];
function inputWfStepCtrl($scope, Wraps){
    var vm = this;
    vm.removeWorkflow = $scope.$parent.tabWorkflow.removeWorkflow;
   // vm.arrayMCO = _.find(array, function(o) { return o.role === 2 && o.user > 0; });
//console.log("arrayMCO",vm.arrayMCO );



    Wraps.initVars.then(function () {
        vm.users = angular.copy(Wraps.users);
        vm.teams = angular.copy(Wraps.teams);
        _.forEach(vm.users, function(user, key) {
            if(user.status == 2){
                user.disabled = true;
            }
        });
        _.forEach(vm.teams, function(team, key) {
            if(team.teamStatus == 0){
                team.disabled = true;
            }
        });
    });



    vm.getRoleById = Wraps.getRoleById;
    vm.getUserById = Wraps.getUserById;
    vm.getTeamById = Wraps.getTeamById;


    vm.checkValidWorkflow = function(){

    };

    $scope.$watch(function () {
        return vm.array;
        }, function watchCallback(newValue, oldValue) {
            const noTeam = _.findIndex(newValue, function(item) { return (!item.team || item.team === '') && item.value === true; }) >= 0;
            vm.arrayMCO = _.find(newValue, function(o) { return o.role === 2 && o.user > 0; });
            if( typeof vm.arrayMCO  != 'undefined' ){ vm.arrayMCOUser = vm.arrayMCO.user; }
            else{ vm.arrayMCOUser = false; }

            //if team change - blank user
            if(typeof oldValue != 'undefined'){
              //console.log(newValue, oldValue);
              _.forEach(newValue, function(i, k) {
                  //this check if the user is in the correct team, if not it clears it
                  if(vm.getUserById(newValue[k].user) && vm.getUserById(newValue[k].user).teamId != newValue[k].team){
                    newValue[k].user= '';
                  }

              });
            }

            vm.isvalid = !noTeam;

    },true);


}
