<md-content  layout="row" layout-wrap  layout-align="center start" class="login-box">


        <div class="md-whiteframe-z1 " >
            <md-content layout-padding >
                <h2 ng-if="!login.resend" class="">Login</h2>
                <h2  ng-if="login.resend" class="">Resend Password</h2>
                <form name="loginForm" novalidate   ng-submit="login.submitLogin(login.resend)" autocomplete="off">
                    <!--{{ loginForm.email.$error }}-->
                    <div layout="row" class="relative">
                        <md-input-container flex>
                            <label>Email</label>
                            <input ng-model="login.credentials.email"  required type="email" name="email"  minlength="10" >
                            <div   ng-show="loginForm.email.$touched"  ng-messages="loginForm.email.$error">
                                <div ng-message="required, email">Please enter a valid email</div>
                                <div ng-message="minlength">Your email must at least 5 characters</div>
                            </div>
                        </md-input-container>
                        <p ng-if="login.resendMsg" class="animate-if error">{{login.resendMsg}}</p>
                    </div>
                    <!--{{ loginForm.password.$error }}-->
                    <div ng-if="!login.resend"  class="animate-if relative" layout="row">
                        <md-input-container flex  >
                            <label>Password</label>
                            <input ng-model="login.credentials.password" type="password"  required name="password"  minlength="5" >
                            <div ng-show="loginForm.password.$touched" ng-messages="loginForm.password.$error">
                                <div ng-message="required">Please enter a valid password</div>
                                <div ng-message="minlength, maxlength">Your password must at least 5 characters</div>
                            </div>
                        </md-input-container>
                        <p ng-if="login.error" class="animate-if error">{{login.error}}</p>

                    </div>

                    <section layout="row" layout-sm="column" layout-align="end center" class="relative">
                        <md-progress-circular style="position: absolute; top: 10px; left: 0px; "  md-diameter="100" md-mode="indeterminate" ng-if="login.isLoading" class="animate-if"></md-progress-circular>
                        <div ng-hide="login.resend"><md-button type="button" class="md-primary button-skinny"  ng-click="login.resend = true">Resend password</md-button>&nbsp;&nbsp;<md-button type="submit" name="loginSubmit" class="md-accent md-raised button-padding" >Login</md-button></div>
                        <div ng-show="login.resend"><md-button type="button" class="md-primary button-skinny" ng-click="login.resend = false">Login</md-button>&nbsp;&nbsp;<md-button type="submit" name="resendSubmit" class="md-accent md-raised button-padding">Resend</md-button></div>
                    </section>
                </form>

            </md-content>



        </div>


</md-content>



