(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableAudience
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableAudience', readableAudience);

  function readableAudience(Wraps) {
    return function (id) {
      var audience;
      if ((audience = _.find(Wraps.audiences, {audienceId: parseInt(id)})) && audience.audienceName) {
        return audience.audienceName;
      }
      return null;
    };
  }
}());
