<md-content layout-padding>
    <div layout="row"  layout-wrap>
        <md-input-container flex="100" wraps-tooltip="docman-main-input_name">
            <label>Name *</label>
            <input ng-model="tabMain.book.bookName" ng-keypress="tabMain.validateCharactors($event)" ng-disabled="tabMain.disabled" name="bookName" flex="66"> <span flex="33" class="charter-length-text" ng-class="{error: (tabMain.book.bookName + '').length > 50}"> {{(tabMain.book.bookName + '').length}}</span>
            <div class="clear-left charter-length-text"> (the following characters are not allowed: <>:"/\|?*)</div>
            <span ng-if="tabMain.checkBlank(tabMain.book.bookName) && tabMain.documentInfoForm.bookName.$touched" class="error animate-error-if" style="clear:left;">Mandatory field, please complete</span>
            <span ng-if="(tabMain.book.bookName + '').length > 50" class="error animate-error-if" style="clear:left;">Some systems have a 50 character limit</span>

        </md-input-container>
    </div>

    <div layout="row"  layout-wrap>
        <md-input-container flex="100" wraps-tooltip="docman-main-input_code">
            <label>Code *</label>
            <input ng-model="tabMain.book.bookCode" ng-disabled="tabMain.book.bookCodeOld !== 'disableAutoCode'" name="bookCode" flex="66">
        </md-input-container>

        <!-- <md-input-container flex="33" wraps-tooltip="docman-main-input_psi-code">
            <md-checkbox class="" ng-model="tabMain.hasPsi" aria-label="PSI" style="margin: 0px;" ng-disabled="tabMain.disabled" flex="33" ng-change="tabMain.setPsi()">
                PSI
            </md-checkbox>
        </md-input-container>
        <md-input-container flex="66" wraps-tooltip="docman-main-input_psi-code" ng-show="tabMain.hasPsi" >
            <label>PSI Code</label>
            <input ng-model="tabMain.book.psiCode" ng-disabled="tabMain.disabled" name="bookCode">
        </md-input-container> -->
    </div>

    <div layout="row" layout-wrap>
        <md-input-container flex="33">
            <label>Division *</label>
            <md-select ng-model="tabMain.book.divisionId" ng-change="tabMain.clearDivisionDataDialog('{{tabMain.book.divisionId}}')" placeholder="Division *"  ng-disabled="tabMain.allowedDocTypeEdit() || !tabMain.hasPermissions('editDivisionOnDocs')"  name="DocTypeCat">
                <md-option   ng-value="item.divisionId" ng-repeat="item in tabMain.divisions | orderBy:['division']" >{{item.division}}</md-option>
            </md-select>
            <span ng-if="tabMain.temp.division == false && tabMain.documentInfoForm.DocTypeCat.$touched" class="error animate-error-if">Mandatory field, please complete</span>
        </md-input-container>
        <md-input-container flex="66" wraps-tooltip="docman-main-input_owner">
            <label>Owner *</label>
            <md-select ng-model="tabMain.book.bookOwner" placeholder="Owner *"  ng-disabled="tabMain.disabled" name="bookOwner">
                <md-option value="{{user.id}}" ng-if="user.status != 2" ng-repeat="user in tabMain.users | filter: {divisionId:tabMain.hasPermissions('editDivisionOnDocs')? undefined : tabMain.book.divisionId} | orderBy:[ 'firstName', 'lastName', 'Teams.teamName']" > {{user.firstName}} {{user.lastName}} - <b>({{user.Divisions.division}}/{{user.Teams.teamName}})</b></md-option>
            </md-select>
            <span ng-if="tabMain.checkBlank(tabMain.book.bookOwner) && tabMain.documentInfoForm.bookOwner.$touched" class="error animate-error-if">Mandatory field, please complete</span>
        </md-input-container>

    </div>


    <div layout="row" ng-if="tabMain.newlanguage === true" layout-wrap>




        <md-input-container flex="33">
            <label>JSON translation file</label>
        </md-input-container>

        <!--<div layout-margin layout-padding>
            <input ng-model="tabMain.book.jsonUpload" onchange="angular.element(this).tabMain.uploadFile(this.files)" class="ng-hide" id="input-file-id" type="file" />
            <label for="input-file-id" class="md-button md-raised md-primary">Choose Files</label>

        </div>-->

        <div layout="row">
            <div flex="66" layout="row" layout-wrap>

                <ul class="file-ui">
                    <li ng-repeat="f in tabMain.JSONfilesList" style="font:smaller" class="file-li animate-if">{{f.fileName}}
                        <div class="file-prog" ng-mouseenter="f.hovered = true" ng-mouseleave="f.hovered = false">
                            <md-progress-circular class="animate-if" ng-if="f.determinateValue < 100" md-mode="determinate" value="{{f.determinateValue}}" md-diameter="30"></md-progress-circular>
                            <span class="animate-ifx" ng-if="f.determinateValue < 100" >{{f.determinateValue}}%</span>
                            <ng-md-icon class="animate-if file-complete" ng-if="f.determinateValue === 100"  icon="check_circle" size="24"  aria-label="Upload complete"></ng-md-icon>
                            <ng-md-icon class="file-remove" ng-if="f.hovered"  icon="cancel" size="24"  aria-label="Remove file" ng-click="tabMain.removeFile(tabMain.JSONfilesList, $index)"></ng-md-icon>
                        </div>
                    </li>
                </ul>
            </div>
            <div flex="5"></div>
            <div flex="33">
                <div ngf-drop ngf-select ng-model="tabMain.JSONFile" class="drop-box"
                     ngf-drag-over-class="'dragover'" ngf-multiple="false" ngf-allow-dir="true"
                     accept="*"
                     ngf-pattern="'*'"><ng-md-icon class="drop-box-icon" icon="cloud_upload" style="fill: #fff; " size="160"></ng-md-icon>Drop files here or<br /><strong style="text-decoration: underline;">Click to upload</strong></div>
                <div ngf-no-file-drop>File Drag/Drop is not supported for this browser</div>



            </div>
        </div>



    </div>


    <div layout="row"  layout-wrap>

        <md-input-container flex="33">
            <label>Master Doc Type *</label>
            <md-select ng-model="tabMain.temp.DocTypeCat" placeholder="Master Doc Type *" ng-disabled="tabMain.allowedDocTypeEdit()" name="DocTypeCat">
                <md-option ng-if="docTypeCat.divisionId == tabMain.book.divisionId"  ng-value="docTypeCat.docTypeCatId" ng-repeat="docTypeCat in tabMain.docTypeCats | filter: {divisionId:tabMain.book.divisionId} | orderBy: 'docTypeCatName'" >{{docTypeCat.docTypeCatName}}</md-option>
            </md-select>
            <span ng-if="tabMain.temp.DocTypeCat == false && tabMain.documentInfoForm.DocTypeCat.$touched" class="error animate-error-if">Mandatory field, please complete</span>
        </md-input-container>

        <md-input-container flex="33" ng-show="tabMain.temp.DocTypeCat" wraps-tooltip="docman-main-input_subdoctype" ng-if="tabMain.book.divisionId">
            <label>Doc Type *</label>
            <md-select ng-model="tabMain.book.DocTypes" placeholder="Doc Type *"  ng-disabled="tabMain.allowedDocTypeEdit()"  ng-model-options="{trackBy: '$value.docTypeId'}" ng-change="tabMain.setWorkflow(); tabMain.generateDocCode();"  name="DocTypes">
                <md-option   ng-value="docType" ng-repeat="docType in tabMain.docTypes | filter: {docTypeCatId: tabMain.temp.DocTypeCat, docTypeStatus: 1, divisionId: tabMain.book.divisionId} | orderBy: 'docTypeName'" >{{docType.docTypeName}}</md-option>
            </md-select>
            <span ng-if="tabMain.checkBlank(tabMain.book.DocTypes) && tabMain.documentInfoForm.DocTypes.$touched" class="error animate-error-if">Mandatory field, please complete</span>
        </md-input-container>

        <md-input-container flex="33" ng-if="tabMain.book.DocTypes.docTypeTemplate === 1" wraps-tooltip="docman-main-input_glossemph">
            <label>Glossary Emphasis *</label>
            <md-select ng-model="tabMain.book.emphasis" placeholder="Glossary Emphasis *"  ng-disabled="tabMain.disabled" name="emphasis">
                <md-option value="{{emphasis.id}}"  ng-repeat="emphasis in tabMain.emphasis | orderBy: 'name'" >{{emphasis.name}}</md-option>
            </md-select>
            <span ng-if="tabMain.checkBlank(tabMain.book.emphasis) && tabMain.documentInfoForm.emphasis.$touched" class="error animate-error-if">Mandatory field, please complete</span>
        </md-input-container>

        <md-input-container flex="33" ng-if="tabMain.book.DocTypes.docTypeId == 93">
            <label>BGHP Colour *</label>
            <md-select ng-model="tabMain.book.extras.BGHPColour" placeholder="BGHP Colour *"  ng-disabled="tabMain.disabled" name="BGHPColour">
                <md-option value="{{colour.id}}"  ng-repeat="colour in tabMain.BGHPColour | orderBy:'name'" >{{colour.name}}</md-option>
            </md-select>
            <span ng-if="tabMain.checkBlank(tabMain.book.extras.BGHPColour) && tabMain.documentInfoForm.emphasis.$touched" class="error animate-error-if">Mandatory field, please complete</span>
        </md-input-container>

        <md-input-container flex="33" ng-if="tabMain.book.DocTypes.docTypeId == 182"> <!-- TODO change 182 to Social Asset DocType Id -->
            <label>Social Channels *</label>
            <md-select ng-model="tabMain.book.socialChannelIds" placeholder="Social Channels *"  ng-disabled="tabMain.disabled" name="socialChannels" multiple>
                <md-option value="{{channel.socialChannelId}}"  ng-repeat="channel in tabMain.socialChannels | unique: 'socialChannelId' | orderBy:'socialChannelName'" >{{channel.socialChannelName}}</md-option>
            </md-select>
            <span ng-if="tabMain.checkBlank(tabMain.book.socialChannelIds) && tabMain.documentInfoForm.socialChannels.$touched" class="error animate-error-if">Mandatory field, please complete</span>
        </md-input-container>

    </div>
    <div layout="row"  layout-wrap>


        <md-input-container flex="33" wraps-tooltip="docman-main-input_insuredby" ng-if="tabMain.book.divisionId">
            <label>Insured By *</label>
            <md-select ng-model="tabMain.book.insurerId"
                    ng-change="tabMain.insurerDetailsDialog(); tabMain.generateDocCode(); tabMain.filteredInsurers = tabMain.currentlySelectedOrSelectableFilter(tabMain.insurers, 'insurerId', 'insurer');" placeholder="Insured By *"  ng-disabled="tabMain.disabled"  name="insurerId">
                <md-option value="{{insurer.insurerId}}"  ng-repeat="insurer in tabMain.filteredInsurers | unique: 'insurerId' | orderBy: 'insurerName'" >{{insurer.insurerName}}<i ng-if="insurer.insurerStatus === 0 "> - disabled </i></md-option>
            </md-select>
            <span ng-if="tabMain.checkBlank(tabMain.book.insurerId) && tabMain.documentInfoForm.insurerId.$touched" class="error animate-error-if">Mandatory field, please complete</span>
        </md-input-container>

        <md-input-container flex="33" ng-if="tabMain.book.divisionId">
            <label>Product *</label>
            <md-select ng-model="tabMain.book.productId" placeholder="Product *" ng-change="tabMain.generateDocCode()"  ng-disabled="tabMain.disabled"  name="productId" wraps-tooltip="docman-main-input_product">
                <md-option value="{{product.productId}}"  ng-repeat="product in tabMain.products | filter: {divisionId:tabMain.book.divisionId} | orderBy:'productName'" >{{product.productName}}</md-option>
            </md-select>
            <span ng-if="tabMain.checkBlank(tabMain.book.productId) && tabMain.documentInfoForm.productId.$touched" class="error animate-error-if">Mandatory field, please complete</span>
        </md-input-container>

        <md-input-container flex="33" wraps-tooltip="docman-main-input_region" ng-if="tabMain.book.divisionId">
            <label>Region *</label>
            <md-select ng-model="tabMain.book.regionId" placeholder="Region *" ng-disabled="tabMain.disabled" name="regionId" >
                <md-option value="{{region.regionId}}"  ng-repeat="region in tabMain.regions | filter: {divisionId:tabMain.book.divisionId} | orderBy: 'regionName'" >{{region.regionName}}</md-option>
            </md-select>
            <span ng-if="tabMain.checkBlank(tabMain.book.regionId) && tabMain.documentInfoForm.regionId.$touched" class="error animate-error-if">Mandatory field, please complete</span>
        </md-input-container>

        <md-input-container flex="33" wraps-tooltip="docman-main-input_insurerDetails" ng-if="tabMain.book.divisionId" ng-hide="tabMain.book.insurerId != '38' || tabMain.showInsurerDetails !== true"><!-- non specific insurer id -->
            <label>Insurer Details *</label>
            <md-select multiple ng-model="tabMain.book.insurerDetailsSelectedArray" placeholder="Insurer Details *"  ng-disabled="tabMain.disabled" name="insurerId"
                       ng-change="tabMain.filteredMultiInsurers = tabMain.currentlySelectedOrSelectableFilter(tabMain.insurers, 'insurerDetailsSelectedArray', 'insurer')">
                <md-option ng-if="insurer.insurerId !== 38"
                        value="{{insurer.insurerId}}"  ng-repeat="insurer in tabMain.filteredMultiInsurers | unique: 'insurerId' | orderBy: 'insurerName'">{{insurer.insurerName}}<i ng-if="insurer.insurerStatus === 0 "> - disabled </i></md-option>
            </md-select>
            <span ng-if="tabMain.checkBlank(tabMain.book.insurerId) && tabMain.documentInfoForm.insurerId.$touched" class="error animate-error-if">Mandatory field, please complete</span>
        </md-input-container>


    </div>
    <div layout="row"  layout-wrap>

        <md-input-container flex="33" ng-if="tabMain.book.divisionId">
            <label>Customer Type *</label>
            <md-select ng-model="tabMain.book.productTypeId" placeholder="Customer Type *"  ng-disabled="tabMain.disabled"  name="productTypeId">
                <md-option value="{{productType.productTypeId}}"  ng-repeat="productType in tabMain.productTypes | filter: {divisionId:tabMain.book.divisionId} | orderBy: 'productTypeName'" >{{productType.productTypeName}}</md-option>
            </md-select>
            <span ng-if="tabMain.checkBlank(tabMain.book.productTypeId) && tabMain.documentInfoForm.productTypeId.$touched" class="error animate-error-if">Mandatory field, please complete</span>
        </md-input-container>

        <md-input-container flex="33" ng-if="tabMain.book.productTypeId == 4 && tabMain.book.divisionId">
            <label>Client *</label>
            <md-select ng-model="tabMain.book.clientId" placeholder="Client *" ng-change="tabMain.generateDocCode()" ng-disabled="tabMain.disabled" name="clientId">
                <md-option value="{{client.clientId}}"  ng-repeat="client in tabMain.clients | filter: {divisionId:tabMain.book.divisionId} | orderBy: 'clientName'" >{{client.clientName}}</md-option>
            </md-select>
            <span ng-if="tabMain.checkBlank(tabMain.book.clientId) && tabMain.book.productTypeId == 4 && tabMain.documentInfoForm.clientId.$touched" class="error animate-error-if">Mandatory field, please complete</span>
        </md-input-container>

        <md-input-container flex="33" wraps-tooltip="docman-main-input_audience" ng-if="tabMain.book.divisionId">
            <label>Audience *</label>
            <md-select ng-model="tabMain.book.audienceId" placeholder="Audience *"  ng-disabled="tabMain.disabled"   name="audienceId">
                <md-option value="{{audience.audienceId}}"  ng-repeat="audience in tabMain.audiences | filter: {divisionId:tabMain.book.divisionId} | orderBy: 'audienceName'" >{{audience.audienceName}}</md-option>
            </md-select>
            <span ng-if="tabMain.checkBlank(tabMain.book.audienceId) && tabMain.documentInfoForm.audienceId.$touched" class="error animate-error-if">Mandatory field, please complete</span>
        </md-input-container>


    </div>

    <div layout="row"  layout-wrap>

        <md-input-container flex="33" wraps-tooltip="docman-main-input_language" ng-if="tabMain.book.divisionId">
            <label>Language *</label>
            <md-select ng-model="tabMain.book.language" placeholder="Language *"  ng-disabled="tabMain.disabled" ng-change="tabMain.generateDocCode()" name="language">
                <md-option value="{{language.id}}"  ng-repeat="language in tabMain.languages | filter: {divisionId:tabMain.book.divisionId} | orderBy: 'name'" >{{language.name}}</md-option>
            </md-select>
            <span ng-if="tabMain.checkBlank(tabMain.book.language) && tabMain.documentInfoForm.language.$touched" class="error animate-error-if">Mandatory field, please complete</span>
        </md-input-container>

        <md-input-container flex="33" ng-if="tabMain.book.divisionId" ng-hide="divisionSubInsurers == 0">
            <label>Sub Insured By</label>
            <md-select ng-model="tabMain.book.subInsurerId" placeholder="Sub Insured By"  ng-disabled="tabMain.disabled">
                <md-option value="">None</md-option>
                <md-option value="{{subInsurer.subInsurerId}}"  ng-repeat="subInsurer in divisionSubInsurers = (tabMain.subInsurers | filter: {divisionId:tabMain.book.divisionId} | orderBy: 'SubInsurerName')" >{{subInsurer.subInsurerName}}</md-option>
            </md-select>
        </md-input-container>


        <md-input-container flex="33" ng-if="tabMain.book.divisionId" ng-hide="divisionDistributors == 0">
            <label>Distributor </label>
            <md-select ng-model="tabMain.book.distributorID" placeholder="Distributor"  ng-disabled="tabMain.disabled">
                <md-option value="">None</md-option>
                <md-option value="{{distributor.distributorId}}"  ng-repeat="distributor in divisionDistributors = (tabMain.distributors | filter: {divisionId:tabMain.book.divisionId} | orderBy: 'DistributorName')" >{{distributor.distributorName}}</md-option>
            </md-select>
        </md-input-container>

    </div>


    <div layout="row"  layout-wrap>

        <md-input-container flex="33" ng-if="tabMain.book.divisionId">
            <label>Project *</label>
            <md-select ng-model="tabMain.book.projectId" placeholder="Project *"  ng-disabled="tabMain.disabled" name="Project" ng-change="tabMain.filteredProjects = tabMain.currentlySelectedOrSelectableFilter(tabMain.projects, 'projectId', 'project');">
                <md-option value="{{project.projectId}}"  ng-repeat="project in tabMain.filteredProjects | unique: 'projectId' | orderBy: 'projectName'" >{{project.projectName}}</md-option>
            </md-select>
            <span ng-if="tabMain.checkBlank(tabMain.book.projectId) && tabMain.documentInfoForm.Project.$touched" class="error animate-error-if">Mandatory field, please complete</span>
        </md-input-container>

    </div>

    <div layout="row"  layout-wrap>
        <md-input-container flex="33" wraps-tooltip="docman-main-input_finprom">
            <span>Financial Promotion *
                <ng-md-icon icon="help">
                    <md-tooltip md-direction="top">
                        <p>A financial promotion is a communication that promotes brand, health and travel insurance plans in an attempt to persuade customers to purchase them, through any medium, print or digital.</p>
                        <p>If you require further information or support, contact Conduct Risk Marketing team.</p>
                    </md-tooltip>
                </ng-md-icon></span>
            <md-radio-group ng-model="tabMain.book.finProm">
                <md-radio-button value="true">Yes</md-radio-button>
                <md-radio-button value="false">No</md-radio-button>
            </md-radio-group>
        </md-input-container>

        <md-input-container flex="33">
            <md-checkbox class=""  ng-model="tabMain.book.limitDoc" ng-disabled="tabMain.disabled" aria-label="Private Document" style="margin: 0px;">
                Private Document
            </md-checkbox>
        </md-input-container>


    </div>



</md-content>
