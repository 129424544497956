(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.historydialog.controller:HistorydialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.historydialog')
    .controller('HistorydialogCtrl', HistorydialogCtrl);
//TODO add tab for history from other books
  function HistorydialogCtrl($mdDialog, $scope, apiUrl, clickSource, History, structureId, Users, Wraps) {
    var vm = this;
    vm.ctrlName = 'HistorydialogCtrl';
    if(!Users.isAuthenticated()){$location.path('/');}

     vm.clickSource = clickSource;
     History.find({ filter: { where: { structureId: structureId }, "include": [{"versionsNew": {"Contents":"ContentTypes"} }] }}).$promise.then(function (results) {


       _.forEach(results, function (item) {
         item.versions = item.versionsNew;
         if (item.versions.Contents.ContentTypes.isJson === true) {
           Wraps.json2Obj(item);
         }
         if(item.versions.Contents.contentTypeId == 1 && item.versions.versiondata.charAt(0) === "{"){
           var json = angular.fromJson(item.versions.versiondata);
           item.versions.versiondata = json.chapterCopy;
         }
       });
       vm.histories = results;

    });


    vm.dialogHide = function() {
      $mdDialog.hide();
    };
    vm.dialogCancel = function() {
      $mdDialog.cancel();
    };




    vm.getFile = function(container, file){
      return apiUrl+"/containers/wraps-files%2f"+container.slice(0, -1).replace(/\//g, "%2f")+"/download/"+file;
      //return "http://wraps-files.s3-website-us-east-1.amazonaws.com/"+container+file;
    }

    vm.swapHistory= function(data) {
	   
			$mdDialog.hide(data.versiondata);
	    
	    
    };
  }
}());
