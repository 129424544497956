(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name library.service:Wraps
   *
   * @description
   *
   */
  angular
    .module('library')
    .service('Notifications', Notifications);

  function Notifications($interval, $localStorage, $rootScope, $q, Books) {
    var self = this;

    self.get = function () {
      return 'Notifications';
    };

    self.list = {loading:true};
    self.count = {};

    self.getNotifications = function(){
		var deferred = $q.defer();
		Books.getNotifications({"selector":"rows"}).$promise.then(function(res){
            self.list = res;
            deferred.resolve();
		});
		return deferred.promise;
    };

    self.getCounts = function(){
        //console.log("getting counts");
      var deferred = $q.defer();
      Books.getNotifications({"selector":"count"}).$promise.then(function(res){
        if(!angular.equals(res, self.count)){
          self.count = res;
          $rootScope.$broadcast('NOTIFICATION_COUNT_UPDATE');
        }
        deferred.resolve();
      });
      return deferred.promise;
    };

      $rootScope.$watch(function(){return $localStorage.user}, function(current, old) {
          if(current && current.id){
              //start interval
              if (!angular.isDefined($rootScope.notifCountInterval)) {
                  $rootScope.notifCountInterval = $interval(function () {
                      self.getCounts();
                  }, 60000);
              }
          }else{
              if (angular.isDefined($rootScope.notifCountInterval)){
                  $interval.cancel($rootScope.notifCountInterval);
              }
          }


      });

  }

}());

