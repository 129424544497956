(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableClient', readableClient);

  function readableClient(Wraps) {
    return function (id) {
      var client;
      if ((client = _.find(Wraps.clients, {clientId: parseInt(id)})) && client.clientName) {
        return client.clientName;
      }
      return null;
    };
  }
}());
