(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableDocType', readableDocType);

  function readableDocType(Wraps) {
    return function (id) {
      var docType;
      if ((docType = _.find(Wraps.docTypes, {docTypeId: parseInt(id)})) && docType.docTypeName) {
        return docType.docTypeName;
      }
      return null;
    };
  }
}());
