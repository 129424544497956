<div>
<md-toolbar class="md-theme-light">
    <div class="md-toolbar-tools">
       	
		<h1 flex class="md-title" wraps-tooltip="permission-edit-title"><span ng-if="$storage.selectedRow == 0">Add Row</span><span ng-if="$storage.selectedRow != 0">Edit Row {{$storage.selectedRow}}</span> in {{permissionmanagement.tableName | titlecase}}</h1>
		<md-button aria-label="Close Pane" ng-click="permissionmanagement.closeEdit()" class="md-icon-button" wraps-tooltip="permission-edit-close">
            <ng-md-icon icon="close"></ng-md-icon>
        </md-button>
        
    </div>
</md-toolbar>
<md-content  layout-padding style="padding-left: 20px;">

    <div style="padding:0px 20px 80px;"  >
			<div class="details-row">
				<!---EDIT FORM--->
				<div ng-repeat="col in permissionmanagement.cols" wraps-tooltip="permission-edit-field">
					<span ng-if="permissionmanagement.pk != col.name && permissionmanagement.tables[permissionmanagement.tableName].dropDown.childColName != col.name">
						<md-input-container ng-class="{'md-input-invalid': permissionmanagement.errors[col.name]}">
						<label>{{col.name | titlecase}}</label>
						<input ng-model="$storage[permissionmanagement.tableName][col.name]">
						<p class="error" ng-show="permissionmanagement.errors[col.name]">{{permissionmanagement.errors[col.name]}}</p>
						</md-input-container>
					</span>
					<span ng-if="permissionmanagement.tables[permissionmanagement.tableName].dropDown.childColName == col.name">
						<md-input-container ng-class="{'md-input-invalid': permissionmanagement.errors[col.name]}">
							<label>{{col.name | titlecase}}</label>
							<md-select ng-model="$storage[permissionmanagement.tableName][permissionmanagement.tables[permissionmanagement.tableName].dropDown.childPk]">
								<md-option value="{{opt[permissionmanagement.tables[permissionmanagement.tableName].dropDown.childPk]}}" ng-repeat="opt in permissionmanagement[permissionmanagement.tables[permissionmanagement.tableName].dropDown.tableName]">{{opt[permissionmanagement.tables[permissionmanagement.tableName].dropDown.childColName]}}</md-option>
							</md-select>
						<p class="error" ng-show="permissionmanagement.errors[col.name]">{{permissionmanagement.errors[col.name]}}</p>
						</md-input-container>
					</span>
				</div>

				<div>
					<md-button  type="button" class="md-raised"  ng-click="$storage.selectedRow = 0; $storage[permissionmanagement.tableName] = {}; permissionmanagement.closeEdit();" wraps-tooltip="permission-edit-close">Cancel</md-button>
					<md-button  type="button" class="md-primary md-raised" ng-if="$storage.selectedRow != 0" ng-disabled="permissionmanagement.compareJson($storage[permissionmanagement.tableName],row)"  ng-click="permissionmanagement.saveRow($storage[permissionmanagement.tableName], 'edit');" wraps-tooltip="permission-edit-save">Save Row</md-button>
					<md-button  type="button" class="md-raised md-primary" ng-if="$storage.selectedRow == 0" ng-disabled="permissionmanagement.checkObj($storage[permissionmanagement.tableName])"  ng-click="permissionmanagement.saveRow($storage[permissionmanagement.tableName], 'new');" wraps-tooltip="permission-edit-save">Save New</md-button>
				</div>
			</div>
	</div>

</md-content>
</div>
