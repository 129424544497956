(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.workflowdialog.controller:WorkflowdialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.workflowdialog')
    .controller('WorkflowdialogCtrl', WorkflowdialogCtrl);

  function WorkflowdialogCtrl($filter, $localStorage, $mdDialog, $q, $scope, apiUrl, book, Books, child, Containers, Events, Messages, Structures, type, Upload, Users, Wraps, WrapsBooks) {
    var vm = this;
    vm.ctrlName = 'WorkflowdialogCtrl';

    vm.apiUrl = apiUrl;
    vm.child = child;


    $scope.$storage = $localStorage;
    if(!Users.isAuthenticated()){$location.path('/');}


    vm.safeFileList = Wraps.safeFileList;

    vm.rfcReasons = Wraps.rfcReasons;
    vm.rfcCategories = Wraps.rfcCategories;
    vm.rejectionReasons = Wraps.rejectionReasons;

    vm.hasPermissions = Wraps.hasPermissions;

    vm.type = type;

    var loadEvents = function () {
      vm.isLoading = true;
      Events.find({"filter":{
          "where":{"structureId":child.structureId},
          "include": ["Messages","Users"],
          "order": "eventId DESC" }}).$promise.then(function(events){

        _.forEach(events, function (event) {
          if(event.eventData.charAt(0)==='{') {
            event.isJson=true;
            event.rfcReasons = [];
            event.categories = [];
            event.significant = false;
            event.Comment = angular.fromJson(event.eventData);
            //link id's with name from datalists

            if(!angular.isArray(event.Comment.reason && event.Comment.reason)){event.Comment.reason = [event.Comment.reason];}
            _.forEach(event.Comment.reason, function(val,key){
              event.rfcReasons.push(_.result(_.find(vm.rfcReasons, {'id': _.parseInt(val)}), 'name'));
            });
            event.rfcReasons= _.uniq(event.rfcReasons);


            if(!angular.isArray(event.Comment.category) && event.Comment.category){event.Comment.category = [event.Comment.category];}
            _.forEach(event.Comment.category, function(val,key){
              event.categories.push(_.result(_.find(vm.rfcCategories, {'id': _.parseInt(val)}), 'name'));
            });
            event.categories= _.uniq(event.categories);
            if(event.Comment.significant){event.significant = event.Comment.significant;}

          }else{
            event.Comment = {};
            event.Comment.message = event.eventData;
          }
        });
        vm.events = events;
        vm.isLoading = false;

        vm.selected = [];
        vm.toggle = function (item, list) {
          var idx = list.indexOf(item);
          if (idx > -1) {
            list.splice(idx, 1);
          }
          else {
            list.push(item);
          }
        };

        vm.exists = function (item, list) {
          return list.indexOf(item) > -1;
        };

        vm.isIndeterminate = function() {
          return (vm.selected.length !== 0 &&
            vm.selected.length !== vm.allContacts.length);
        };

        vm.isChecked = function() {
          return vm.allContacts && vm.selected.length === vm.allContacts.length;
        };

        vm.toggleAll = function() {
          if (vm.selected.length === vm.allContacts.length) {
            vm.selected = [];
          } else if (vm.selected.length === 0 || vm.selected.length > 0) {
            vm.selected = vm.allContacts.slice(0);
          }
        };


        vm.book = Wraps.book;
        vm.contacts = '';

        vm.asyncContacts = [];
        vm.filterSelected = false;

      });
    }

    loadEvents();

    $scope.$on('newCommentCreated', function(event){
        // console.log('comment event received');
        loadEvents();
    });

    /*console.log(_.findLastIndex(book.approvalList.slice(0,book.approvalStatus), function(chr) {
      return chr.type != 'team' && chr.value != false;
    }));*/

    vm.nl2br = function(string){
      try {
        return string.replace(/\n/g, '<br>');
      }catch(err){
        //return false;
      }
    }

    vm.json2Obj = function(obj){

      try{ obj = angular.extend(obj,angular.fromJson(obj.versions.versiondata));}
      catch (err){    console.log(err.name + ': "' + err.message + '" occurred when assigning string.'); return false;}
      console.log(obj.benefit);
      return obj;

    }



    vm.removeFile = function(array, index){
      array.splice(index, 1);
    }

    var timestamp = new Date().getTime();
    var S3_BUCKET_URL = apiUrl+'/Containers/wraps-files/upload';
    var itemType = "reject";
    $scope.$watch('workflowdialog.files', function (files) {
      //console.log('upload watch',test);
      vm.upload(files).then(function(statuses){
        vm.isLoading = false;
      });
    });

    vm.log = '';

    vm.upload = function (files) {
      if(!vm.reject){vm.reject = {};}
      if(vm.reject && !vm.reject.filesList){vm.reject.filesList = [];}
      var deferred = $q.defer();

      if (files && files.length) {
        _.forEach(files, function (file) {
          if (!file.$error) {
            vm.isLoading = true;

            file.container = 'wraps-files';
            file.fileLocation = itemType + '/' + timestamp;
            file.fileName = file.name;
            file.fileSize = file.size;
            vm.reject.filesList.push(file);


            //TODO add timeout to animate files

            var newName = file.fileLocation + '/' + file.name;
            Upload.rename(file, newName );
            Upload.upload({
              url: S3_BUCKET_URL,
              file: file
            }).then(function (resp) {
              file.loaded = true;

              deferred.resolve();
            },
            null,
            function (evt) {
              file.determinateValue = parseInt(100.0 *   evt.loaded / evt.total);
              //TODO get this working
              //console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total) + '% file :'+ evt);
            });


          }
        });
      }
      return deferred.promise;
    };


    var previousEditor = _.findLastIndex(book.approvalList.slice(0,child.approvalStatus+child.status), function(chr) {
      return ((chr.role == 2 && chr.content === "doc") || (chr.role == 2 && chr.content === "brief") || (chr.role == 2 && chr.content === "artwork") || (chr.role == 6 && chr.content === "final") || (chr.role == 6 && chr.content === "artwork")) && chr.value != false;
    });

    //console.log("previousEditor",previousEditor);


    //set to CO unless user is co or book.co is false
    //update structure, book changed and add event
    vm.saveReject = function(reject){
      vm.isLoading = true;
      child.lastUpdated = moment.utc().format(Wraps.databaseDateFormat);

      var co = _.result(_.find(book.approvalList, { 'type': "co" }),'value'); // is co true

      var previousEditor = _.findLastIndex(book.approvalList.slice(0,child.approvalStatus+child.status), function(chr) {
        return ((chr.role == 2 && chr.content === "doc") || (chr.role == 2 && chr.content === "brief") || (chr.role == 2 && chr.content === "artwork") || (chr.role == 6 && chr.content === "final") || (chr.role == 6 && chr.content === "artwork")) && chr.value != false;
      });




      reject.status = child.status;
      reject.approvalStatus = previousEditor;


      child.status = 0;
      child.approvalStatus = previousEditor;

      var json = Wraps.toJson(reject);

      Structures.upsert(child, function () {
        Events.create({
          "eventId": 0,
          "bookId": child.bookId,
          "structureId": child.structureId,
          "contentId": child.versions.contentId,
          "historyId": child.History[child.History.length - 1].historyId,
          "userId": $scope.$storage.user.id,
          "eventType": "Rejected",
          "eventData": json
        }).$promise.then(function (event) {

          Books.upsert({"bookId": child.bookId, "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)}).$promise.then(function (results) {
            WrapsBooks.setBookStatuses(book, vm.contacts).then(function (emailAction) {
              //book.update(true);

              //console.log("send rejection email");
              Wraps.sendRejectEmail(book, reject, previousEditor,event.eventId);

              vm.dialogHide(true);
              vm.isLoading = false;
            });

          });
        });


      });
    }

    vm.openEmailDetailsDialog = function (change) {
      $mdDialog.show({
        controller: 'EmaildetailsdialogCtrl',
        controllerAs: 'emaildetailsdialog',
        templateUrl: 'library/emaildetailsdialog/emaildetailsdialog.tpl.html',
        parent: angular.element(document.body),
        locals: {change: change},
        bindToController: true,
        clickOutsideToClose: true
      })
        .then(function (data) {

        }, function () {
          vm.status = 'You cancelled the dialog.';
        });
    };

    vm.dialogHide = function(data) {
      $mdDialog.hide(data);
    };

    vm.dialogCancel = function(data) {
      $mdDialog.cancel(data);
    };

    vm.isObjectEmpty = function(card){
      return Object.keys(card).length === 0;
    };

    vm.createFilterFor = function(query) {
      var lowercaseQuery = query.toLowerCase();

      return function filterFn(contact) {
        return (contact._lowername.indexOf(lowercaseQuery) != -1);
      };

    };

    vm.querySearch = function(criteria) {
      return criteria ? vm.allContacts.filter(vm.createFilterFor(criteria)) : [];
    };

    vm.asyncContacts = [];
    vm.filterSelected = true;
  };
}());

