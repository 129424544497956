(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name library.service:WrapsBooks
   *
   * @description
   *
   */
  angular
    .module('library')
    .service('WrapsBooks', WrapsBooks);

  function WrapsBooks(
      $filter,
      $location,
      $localStorage,
      $mdDialog,
      $q,
      $rootScope,
      $sessionStorage,
      $state,
      clientConfig,
      system,
      Books,
      Containers,
      Contents,
      EnclosureTypes,
      Versions,
      PdfVersions,
      Notifications,
      History,
      Events,
      Structures,
      Wraps) {
    var self = this;

    self.get = function () {
      return 'WrapsBooks';
    };


      if(!$localStorage.pagination){
          $localStorage.pagination = {};
      }
      if(!$localStorage.pagination.startRow){
          $localStorage.pagination.startRow = 0;
      }
      if(!$localStorage.pagination.noResults){
          $localStorage.pagination.noResults = 100;
      }
      if(!$localStorage.pagination.filterLibrary){
          $localStorage.pagination.filterLibrary = {};
      }
      self.pagination = $localStorage.pagination;
      self.pagination.totalRows = 0;

    self.clientConfig = clientConfig;

    self.getBooks = function (filterLibrary, startRow) {
      var isTab = false;
      //console.log('startRow', startRow);
      if(typeof startRow == "undefined"){
        isTab = true;
        startRow = 0;
      }

      var filterObject = {"startRow":startRow, "noResults":self.pagination.noResults, "searchArray":filterLibrary};

      var deferred = $q.defer();

      // self.statuses = Structures.getStatuses();
      //sql = "SELECT bookId,  approvalStatus as id, status , count(approvalStatus) as count FROM Structures S  WHERE S.deleted = 0  GROUP BY approvalStatus, bookId , status"

      Books.getLibrarySearch(filterObject).$promise.then(function (results) {
        var books = results.data;
        if(!isTab) {
            self.pagination.totalRows = results.totalRows[0].result_count;
        }

        _.forEach(books, function (book) {

          /*self.statuses.$promise.then(function (statuses) {
            checkBookActions(book, statuses, $localStorage.user);
          });*/

          book.userInBook = userInBookApprovalList(book, $localStorage.user);

          book.updateBook = updateBook;
        });

        deferred.resolve(books);
      });
      return deferred.promise;
    };


    self.books = self.getBooks(self.pagination.filterLibrary, self.pagination.startRow); //initial call and assignment



    self.getLibraryList = function(){
        self.books = self.getBooks(self.pagination.filterLibrary, self.pagination.startRow);
      return self.books;
    }



    var updateBook = function(){
      var book = this;
      var deferred = $q.defer();
      book.isLoading = true;
      getBook(book.bookId).then(function (results) {

         _.forEach(book, function(element, i) { // removes all element before merge but keeps binding
             delete book[i];
         });
        _.merge(book, results);
        //book.isLoading = false;

        deferred.resolve(book);
      }).catch(function(error){
        console.log("book updateBook error:", error);
        deferred.reject(error);
      });
      return deferred.promise;
    }



    self.updateBookById = function(bookId){
      bookId = _.toInteger(bookId);
      var deferred = $q.defer();
      var book = _.find(self.openBooks, { 'bookId': bookId});

      //console.log(self.openBooks, bookId);

      book.updateBook().then(function () {
        deferred.resolve();
      }).catch(function(error){
        console.log("book updateBookById error:", error);
        deferred.reject(error);
      });
      return deferred.promise;
    }


    self.getBookById = function(bookId){
      bookId = _.toInteger(bookId);
      var book = _.find(self.openBooks, { 'bookId': bookId});
      return book;
    }


    self.updateAllTabs = function() {
      _.forEach(self.openBooks, function (book, ind) {
        book.updateBook();
      });
    }


    var getBook = function (bookId) {
      var book = {};
      var deferred = $q.defer();
      book.isLoading = true;
      var tabindex  = _.findIndex(self.openTabs, function(o) { return _.toInteger(o.bookId) == _.toInteger(bookId) });
      var tab       = self.openTabs[tabindex];
      if(tab){tab.updateRequired = false};

      if(bookId > 0) {
        self.getBooks({bookId: bookId}).then(function (results) {

          if (results[0]) {
            //console.log(results[0]);
            book = results[0];
            book.isLoading = true;

            // this is so the newly called book does not try to update,
            // the other opentabs might need to wait for the next cycle though - this might need to be chacked
            self.lastChecked = "3000-01-01";

            Structures.find({
              filter: {
                "where": {"and": [{"bookId": book.bookId}, {"parent": null}]},
                "order": "order ASC",
                "include": [{"versions": {"Contents": ["ContentTypes", "Teams"]}}, "History",
                  {
                    "Children": [{"versions": {"Contents": ["ContentTypes", "Teams"]}}, "History",
                      {
                        "Children": [{"versions": {"Contents": ["ContentTypes", "Teams"]}}, "History",
                          {"Children": [{"versions": {"Contents": ["ContentTypes", "Teams"]}}, "History"]}
                        ]
                      }
                    ]
                  }]
              }
            }).$promise.then(function (bookResults) {

              book.Children = bookResults;




              _.forEach(book.Children, function (chapter) {
                if (Wraps.json2Obj(chapter)) {
                  chapter.versions.versiondata = chapter.chapterCopy;
                  chapter.versions.chapterTypeId = chapter.chapterTypeId;

                  if (chapter.chapterTypeId === 3) {

                    const qrefFilterObject = {"startRow":0, "noResults":1, "searchArray":{bookId: chapter.chapterLinkId}};
                    Books.getLibrarySearch(qrefFilterObject).$promise.then(function (qrefResults) {

                      qrefResults = qrefResults.data[0];

                      chapter.versions.versiondata = chapter.versions.versiondata + "" +
                          "<b>ID:</b> " + qrefResults.bookIdLong + "<br /><b>Name:</b> " + qrefResults.bookName + "<br /><b>Code:</b> " + qrefResults.bookCode + "<br /><b>Workflow Status:</b> " + qrefResults.workflowStatus + "<br /><b>Document Status:</b> " + qrefResults.documentStatus;
                    });
                  } else if (chapter.chapterTypeId === 2) {
                    if (chapter.PDFList && chapter.PDFList[0]) {
                      var br = "<br />";
                      chapter.versions.versiondata = chapter.versions.versiondata + "" + "<b>Cover Filename:</b> " + chapter.PDFList[0].fileName;
                    } else {
                      var br = "";
                    }
                    if (chapter.filesList && chapter.filesList[0]) {
                      chapter.versions.versiondata = chapter.versions.versiondata + br + "<b>Logo Filename:</b> " + chapter.filesList[0].fileName;
                    }
                  }
                } else {
                  chapter.chapterTypeId = 1;
                }

                if (chapter.versions.Contents.contentTypeId === 9) {
                  book.hasBrief = true;
                  chapter.hasBrief = true;
                  //console.log("hasBrief", book);

                } else if (chapter.versions.Contents.contentTypeId === 10) {
                  book.hasArtwork = true;
                  chapter.hasArtwork = true;
                  //console.log("hasArtwork", chapter);

                } else if (chapter.versions.Contents.contentTypeId === 13) {
                  book.hasFinal = true;
                  chapter.hasFinal = true;
                  //console.log("hasFinal", chapter);
                }

                if (chapter.versions.Contents.ContentTypes.isJson === true) {
                  Wraps.json2Obj(chapter);
                }

                chapter.Children = _.orderBy(chapter.Children, 'order', 'asc');
                _.forEach(chapter.Children, function (level1) {
                  if (level1.versions.Contents.ContentTypes.isJson === true) {
                    Wraps.json2Obj(level1);
                  }
                  level1.Children = _.orderBy(level1.Children, 'order', 'asc');
                  _.forEach(level1.Children, function (level2) {
                    if (level2.versions.Contents.ContentTypes.isJson === true) {
                      Wraps.json2Obj(level2);
                    }
                    level2.Children = _.orderBy(level2.Children, 'order', 'asc');
                    _.forEach(level2.Children, function (level3) {
                      if (level3.versions.Contents.ContentTypes.isJson === true) {
                        Wraps.json2Obj(level3);
                      }
                    });
                  });
                });

                if (self.hasContentType(chapter, 6)) {
                  //Needs to be fixed at a later point - the words do not update live
                    $rootScope.$watch(function () {
                       return chapter;
                    }, function (newVal, oldVal) {
                       self.setBoldWords(chapter, book);
                       //console.log("setBoldWords watch",book.boldWords.length);
                    }, true);
                }

              });

              //TODO put this in a function
              Structures.getStatuses({'bookId': book.bookId}).$promise.then(function (thisStatuses) {

                if (angular.isArray(book.approvalList)) {
                  //ole.log('test1');
                  book.docApprovalList = _.filter(book.approvalList, {'content': "doc"});
                  book.briefApprovalList = _.filter(book.approvalList, {'content': "brief"});
                  book.artworkApprovalList = _.filter(book.approvalList, {'content': "artwork"});
                  book.finalApprovalList = _.filter(book.approvalList, {'content': "final"});
                }

                //checkBookActions(book, thisStatuses, $localStorage.user);

                // book.statusBar = setStatusBar(book, self.statuses, book.approvalList);

                if (book.docApprovalList.length > 0) {
                  book.docStatusBar = setStatusBar(book, thisStatuses, book.docApprovalList);
                }
                if (book.briefApprovalList.length > 0) {
                  book.briefStatusBar = setStatusBar(book, thisStatuses, book.briefApprovalList);
                }
                if (book.artworkApprovalList.length > 0) {
                  book.artworkStatusBar = setStatusBar(book, thisStatuses, book.artworkApprovalList);
                }
                if (book.finalApprovalList.length > 0) {
                  book.finalStatusBar = setStatusBar(book, thisStatuses, book.finalApprovalList);
                }

                book.enclosures = {};
                //console.log("vm.enclosureData",vm.enclosureData);
                _.forEach(book.Enclosures, function (enclosure) {
                  book.enclosures['enclosure_' + enclosure.enclosureType] = enclosure.enclosureBookCode;
                });

                book.productIdsSelectedArray = [];
                _.forEach(book.ProductIdsBookLink, function (prodId) {
                  book.productIdsSelectedArray[prodId.productId] = true;
                });


                book.insurerDetailsSelectedArray = [];
                _.forEach(book.InsurerDetails, function (insurerDetail) {
                  book.insurerDetailsSelectedArray.push(parseInt(insurerDetail.insurerId));
                });
                if(book.insurerDetailsSelectedArray.length<=0 && book.insurerId != 38){
                  book.insurerDetailsSelectedArray.push(parseInt(book.insurerId));
                }


                //book.insurerDetailsSelectedArray = [8,37];
                //console.log(book.insurerDetailsSelectedArray);



                book.userInBook = userInBookApprovalList(book, $localStorage.user); //array of indexes on approval list of current user

                if (book.currentWorkflowStatus) {
                  //show brief
                  //console.log('book.approvalStatus',book.approvalStatus);
                  //console.log('book.currentWorkflowStatus',book.currentWorkflowStatus);
                  //console.log('book.userInBook',book.userInBook);

                  if (book.hasBrief || (book.currentWorkflowStatus.content === 'brief' && (book.currentWorkflowStatus.role === 1 || book.currentWorkflowStatus.role === 2))) {
                    book.showBrief = true;
                  }

                  //show add brief button
                  if (!book.hasBrief && book.currentWorkflowStatus.content === 'brief' && _.indexOf(book.userInBook, book.approvalStatus) >= 0 && (book.currentWorkflowStatus.role === 1 || book.currentWorkflowStatus.role === 2)) {
                    book.showBriefButton = true;
                  } else {
                    book.showBriefButton = false;
                  }

                  //show artwork
                  if (book.hasArtwork || (book.currentWorkflowStatus.content === 'artwork' && book.currentWorkflowStatus.role === 6)) {
                    book.showArtwork = true;
                  }

                  //show artwork button
                  if (!book.hasArtwork && book.currentWorkflowStatus.content === 'artwork' && _.indexOf(book.userInBook, book.approvalStatus) >= 0 && book.currentWorkflowStatus.role === 6) {
                    book.showArtworkButton = true;
                  } else {
                    book.showArtworkButton = false;
                  }

                  //show final
                  if (book.hasFinal || (book.currentWorkflowStatus.content === 'final' && book.currentWorkflowStatus.role === 6)) {
                    book.showFinal = true;
                  }

                  //show add final artwork button
                  if (!book.hasFinal && book.currentWorkflowStatus.content === 'final' && _.indexOf(book.userInBook, book.approvalStatus) >= 0 && book.currentWorkflowStatus.role === 6) {
                    book.showFinalButton = true;
                  } else {
                    book.showFinalButton = false;
                  }
                }

                if (book.pdfVersion) {
                  book.pdfVersionUrl = '/' + book.pdfVersion;
                } else {
                  if (book.docTypeId == 93) {
                    book.pdfVersionUrl = '/bghp';
                  }
                }

                if (_.size(_.filter(book.levels, {'active': true})) === 5) {
                  book.expWidth = "24%";
                } else {
                  book.expWidth = "38%";
                }

                book.isLoading = false;
                if($state.params.bookId == book.bookId){
                  Wraps.book = book;
                }

                if (book.DocTypes && book.DocTypes.docTypeTemplate === 1) { //only get WCR if dynamic doc

                  Structures.getWCR({"bookId": book.bookId}).$promise.then(function (results) {

                    book.changes = addChanges(results, null);

                    book.getChangesByStructureId = function (structureId) {
                      //console.log("getChangesByStructureId test: ", _.find(book.changes, {"structureId": structureId}));
                      return _.find(book.changes, {"structureId": structureId});
                    }

                    book.wcrChanges = angular.copy(book.changes);
                    _.remove(book.wcrChanges, {'versionIdNew': null});
                    _.remove(book.wcrChanges, {'versionIdNew': 0, 'versionIdOld': 0});


                    //console.log("book.changes",book.changes);
                    deferred.resolve(book);
                  });
                }else{
                  deferred.resolve(book);
                }
              });
            });

          } else {
            book = {'bookId':bookId, 'isLoading':false, 'permissionDenied': true};
            book.updateBook = updateBook;
            deferred.resolve(book);
            //deferred.reject("no results returned from getbook");
          }
        });
      } else {
        deferred.reject("invalid bookId");
      }
      return deferred.promise;
    };

    //openBooks / tabs
    if(typeof $localStorage.openTabs == "undefined"){
        $localStorage.openTabs = [];
    }

     // this is so nothing gets updated in the fist cycle,
     // it shouldn't need to anyways as it's just loaded,
     // after the fist cycle this time is set from the server api return
     self.lastChecked = "3000-01-01";

    $localStorage.openTabs = _.uniqBy($localStorage.openTabs, 'bookId'); //removes duplicates
    self.openTabs = $localStorage.openTabs;
    self.openBooks = [];



    //
    self.loadTab = function(bookId){
      var deferred = $q.defer();

      var tabindex  = _.findIndex(self.openTabs, function(o) { return _.toInteger(o.bookId) == _.toInteger(bookId) });
      var tab       = self.openTabs[tabindex];
      var book      = self.openBooks[tabindex];

      //console.log("test",tabindex,tab,book,self.openTabs);
      if(tab && !book){
          tab.isLoading = true;
          getBook(bookId).then(function(results) {
              if (results) {
                  self.openBooks[tabindex] = results;
                  tab.isLoading = false;
                  tab.updateRequired == false;
                  deferred.resolve();
              }
          }).catch(function(error) {
            // delete from tabs if not returned
            self.openTabs.splice(tabindex, 1);
            deferred.reject();
          });
      }else{
        if(bookId == 0){
          self.openTabs.splice(tabindex, 1);
        }
          deferred.resolve();
      }
      return deferred.promise;
    }



      _.forEach(self.openTabs, function (openTab, ind) {
        self.loadTab(openTab.bookId);
      });




    //end openBooks / tabs


    // gets array of indexes current user is in book
    var userInBookApprovalList = function (book, user) {
      var indexes = [];
      _.forEach(book.approvalList, function (item, index) {
        item.role = parseInt(item.role);
        if (!((item.role === 3 || item.role === 8) && book.bookOwner === $localStorage.user.id)) { //not approver or proofreader and owner (owner cannot approver their own documents)

          if (item.user === user.id) {
            indexes.push(index);
          } else if ((angular.isUndefined(item.user) || item.user === '') && item.team === user.teamId) {
            indexes.push(index);
          }
        }
      });
      //console.log(indexes);
      return indexes;
    }


    // user for WCR
    var addChanges = function (list, parentId, location) {
      var changes = [];

      var levels = _.remove(list, {'parent': parentId});
      _.forEach(levels, function (item) {

        if (item.contentTypeId === 1 && item.versionData && item.versionData.charAt(0) === "{") {
          var json = angular.fromJson(item.versionData);
          item.versionData = json.chapterCopy;
        }


        if (item.contentTypeId === 5 || item.contentTypeId === 6 || item.contentTypeId === 7 || item.contentTypeId === 8 || item.contentTypeId === 11 || item.contentTypeId === 12 || item.contentTypeId === 17 || item.contentTypeId === 20) {
          Wraps.json2ObjWCR(item);
        }

        if (parentId === null) {
          item.location = "<strong>" + item.contentTypeName + "</strong>";
        } else {
          item.location = location + " • " + "<strong>" + item.contentTypeName + "</strong>";
        }
        ;
        changes = changes.concat(item);

        if (item.contentTypeId === 11) {
          if (item.json2 !== null) {
            var location2 = item.location + ": " + item.json2.tableHeader[0].name;
          }
        } else {
          if (item.versionData) {
            var location2 = item.location + ": " + item.versionData.replace(/<\/?[^>]+>/gi, '');
          }
        }

        if (item.contentTypeId === 1) {

          if (item.versionData1 && item.versionData1.charAt(0) === "{") {
            var json1 = angular.fromJson(item.versionData1);
            item.versionData1 = json1.chapterCopy;
            if (json1['chapterTypeId'] == 3) {
              item.versionData1 += "<span>QREF DOC ID: " + json1.chapterLinkId + "</span>";
            } else if (json1['chapterTypeId'] == 2) {
              if (json1.PDFList && json1.PDFList[0]) {
                item.versionData1 += "<span>Cover Filename: " + json1.PDFList[0].fileName + "</span>";
              }
              if (json1.filesList && json1.filesList[0]) {
                item.versionData1 += "<span>Logo Filename: " + json1.filesList[0].fileName + "</span>";
              }
            }
          }
          if (item.versionData2 && item.versionData2.charAt(0) === "{") {
            var json2 = angular.fromJson(item.versionData2);
            item.versionData2 = json2.chapterCopy;
            if (json2['chapterTypeId'] == 3) {
              item.versionData2 += "<span>QREF DOC ID: " + json2.chapterLinkId + "</span>";
            } else if (json2['chapterTypeId'] == 2) {
              if (json2.PDFList && json2.PDFList[0]) {
                item.versionData2 += "<span>Cover Filename: " + json2.PDFList[0].fileName + "</span>";
              }
              if (json2.filesList && json2.filesList[0]) {
                item.versionData2 += "<span>Logo Filename: " + json2.filesList[0].fileName + "</span>";
              }
            }
          }

        }

        changes = changes.concat(addChanges(list, item.structureId, location2));
      });
      //console.log("changes",changes);
      return changes;
    }

    // gets array of active approval statuses for a book
    var findApproversInBook = function (book, statuses) {
      return _.map(_.filter(statuses, {'status': 0, 'bookId': book.bookId}), 'id');
    }

    /*var checkBookActions = function (book, statuses, user) {
      book.action = false;
      book.actionColour = '';

      if (!book.approvalList[book.approvalStatus - 1]) {
        //console.log('xxx',book.bookId,book.approvalList);
      }

      var now = moment().format('YYYY-MM-DD');
      if (book.status === 0 && ((book.earlyExpiryDate == null || moment(book.earlyExpiryDate).format('YYYY-MM-DD') > now) && moment(book.expiryDate).format('YYYY-MM-DD') > now)) {
        var count = 0;
        var lastRole = false;
        // if membguides / BGHP
        if (book.DocTypes && book.DocTypes.docTypeTemplate == 1) {
          _.forEach(findApproversInBook(book, statuses), function (item, index) {//findApproversInBook returns array of appstatus
            count++;
            if (count === 2 && lastRole === 6) {
              book.action = false;
            }
            if (book.approvalList[item]) {
              lastRole = book.approvalList[item].role;
            }

            if (_.indexOf(book.userInBook, item) >= 0 && book.approvalStatus >= item) {
              //console.log(book.bookId,book.approvalList[item]);
              book.action = true;
            }
          });
        }

        if (_.indexOf(book.userInBook, book.approvalStatus) >= 0) { //current user approvelist indexes in  book status
          book.action = true;
        }
      } else if (

        ((book.earlyExpiryDate != null && now >= moment(book.earlyExpiryDate).format('YYYY-MM-DD')) || now >= moment(book.expiryDate).format('YYYY-MM-DD'))
        &&
        (book.status === 1 || (book.approvalStatus > 0 && book.approvalList[book.approvalStatus] && book.approvalList[book.approvalStatus].content == 'final'))
        &&
        book.bookOwner === $localStorage.user.id
        &&
        (book.withdrawDate == null || now < moment(book.withdrawDate).format('YYYY-MM-DD'))
      ) {
        book.action = true;
        book.actionColour = 'redAction';
      } else {
        book.action = false;
        book.actionColour = '';
      }

    }*/


    var setStatusBar = function (book, statuses, approvalList) {
      var statusBar = [];
      var ord = 0;
      //console.log("statuses",statuses);
      var newIndex = 0;
      _.forEach(approvalList, function (item, index) {
        if (approvalList[0].content == "artwork") {
          newIndex = index + _.findIndex(book.approvalList, ['content', 'artwork']);
        } else if (approvalList[0].content == "final") {
          newIndex = index + _.findIndex(book.approvalList, ['content', 'final']);
        } else {
          newIndex = index;
        }
        if (item.value) {

          var stat = {};
          stat.ord = ord++;
          if (item.role) {
            if (_.isInteger(parseInt(item.role)) && item.role != 3) {
              stat.name = $filter('uppercase')(Wraps.getRoleById(item.role).description);
            } else if (parseInt(item.team) > 0) {
              stat.name = $filter('uppercase')(Wraps.getTeamById(item.team).shortName);
            }
            if (Wraps.getTeamById(item.team)) {
              stat.tooltip = Wraps.getTeamById(item.team).teamName;
            } else {
              stat.tooltip = "All";
            }
            if (Wraps.getUserById(item.user)) {
              stat.tooltip += " - " + Wraps.getUserById(item.user).firstName + " " + Wraps.getUserById(item.user).lastName;
            } else {
              stat.tooltip += " - All";
            }
          } else {
            stat.name = $filter('uppercase')(item.type);
          }


          //console.log("type",type);

          stat.count = _.result(_.find(statuses, {
            'status': 0,
            'id': newIndex,
            'bookId': book.bookId
          }), 'count');
          if (angular.isUndefined(stat.count)) {
            stat.count = '';
          }

          if (stat.count !== '' && book.approvalStatus >= newIndex) {
            stat.colour = 'green';
          } else if (book.approvalStatus >= newIndex || book.status === 1) {
            stat.colour = 'grey'
          } else {
            stat.colour = 'white'
          }

          statusBar.push(stat);
        }
      });

      if (statusBar.length > 0) {
        var stat = {};
        stat.ord = ord++;
        stat.name = "READY";
        stat.tooltip = "Ready";
        stat.count = _.result(_.find(statuses, {'status': 1, 'id': newIndex, 'bookId': book.bookId}), 'count');
        if (angular.isUndefined(stat.count)) {
          stat.count = '';
        }
        if (book.status === 1) {
          stat.colour = 'green';
        } else {
          stat.colour = 'white';
        }
        statusBar.push(stat);
      }


      return statusBar;

    }

    self.hasContentType = function (parent, type) {
      if (parent.Children) {
        for (var i = 0; i < parent.Children.length; i++) {
          if (parent.Children[i].versions.Contents.contentTypeId === type) {
            return true;
            break;
          }
        }
      }

    }


    self.setBoldWords = function (chapter, book) {

      book.boldWords = [];
      angular.forEach(chapter.Children, function (child) {

        //console.log(angular.copy(child), child.glossary);
        if (child.glossary) {

          if (angular.isArray(book.boldWords)) {
            if (child.glossary.words) {
              book.boldWords = book.boldWords.concat(child.glossary.words.trim()
                .replace("&nbsp;", " ")
                .replace(/\s*,[,\sp]*\b/g, ",")
                .replace(/,*\s*$/, "")
                .replace("’", "'") //this is new for apostophe
                .split(","));
            }
          } else {
            book.boldWords = child.glossary.words.trim()
              .replace(/&nbsp;/g, " ")
              .replace(/\s*,[,\sp]*\b/g, "")
              .replace(/,*\s*$/, ",")
              .replace("’", "'") //this is new for apostophe
              .split(",");
          }
        }
      });
    }

    self.stringifySearch = function (searchObj) {
      searchObj = angular.copy(searchObj);
      var searchString = [];
      var count = 0;
      if(searchObj && searchObj['sortOrder']) {
        delete searchObj['sortOrder'];
      }
      if(searchObj && searchObj['sortDirection']) {
        delete searchObj['sortDirection'];
      }
      _.forEach(searchObj, function (search, field) {
        if(search == '') {
          delete searchObj[search];
        }else {
          count++;
          searchString.push(_.startCase(_.camelCase(field)));
        }
      })

      if(count===0) {
        return '';
      }else {
        return count + ' (' +searchString.join(', ') + ')';
      }
    }





    self.uploadPDF = function (book){

      if(system === "local") {
        var linkExtras = "";
      }else if(system === "demo") {
        var linkExtras = "&demo=true";
      }
      var deferred = $q.defer();
      var timestamp = new Date().getTime();
      var itemType = "final";
      if(book.docTypeId == 93) {
        var pdfaddress = self.clientConfig.pdfUrl + "/bghp/?book=" + book.bookId + linkExtras;
      }else{

        var pdfaddress = self.clientConfig.pdfUrl + "/?book=" + book.bookId + linkExtras;
      }


      var description = "<p>Final PDF</p>\n";
      var container = 'wraps-files';
      var fileLocation = itemType+'/'+timestamp;
      var fileName = book.bookCode+".pdf";


      Containers.uploadFinalPDF({"url":pdfaddress,"container":container, "filename":fileLocation + "/" + fileName}).$promise.then(function (response) {
        //console.log(response);

        var artwork = {"artwork":{
          "description": description,
          "filesList":[
            {
              "container": container,
              "fileLocation":fileLocation,
              "fileName": fileName,
              "fileSize": response[0].filesize,
              "ngfName": fileLocation + "/" + fileName
            }
          ]
        }};

        var owner = 20; //design -  was artworkData.artworkTeam.teamId

        Contents.create({contentId: 0, contentTypeId: 13, contentName: "Final PDF", contentOwner: owner}, function (data1) {

          Versions.create({versionId: 0, contentId: data1.contentId, versiondata: Wraps.toJson(artwork)}, function (data2) {

            Structures.create({structureId: 0, versionId: data2.versionId, contentId: data1.contentId, bookId: book.bookId, "status": 0, "approvalStatus": book.approvalStatus , parent: null, order: data2.versionId}, function (data3) {
              Books.upsert({ "bookId": book.bookId,  "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)});


              PdfVersions.find({filter: {"where": {"docTypeId": book.docTypeId }, "order": "pdfVersionId DESC", "limit": 1}}, function (pdfData) {
                Books.upsert({ "bookId": book.bookId,  "lastUpdated": Date.now(), pdfVersion: pdfData.pdfVersionId });
              });
              History.create({
                "structureId": data3.structureId,
                "versionIdOld": 0,
                "versionIdNew": data3.versionId,
                "historyId": 0
              },function(historyResponse){
                Events.create({
                  "eventId": 0,
                  "bookId": book.bookId,
                  "structureId": data3.structureId,
                  "contentId": data1.contentId,
                  "historyId": historyResponse.historyId,
                  "userId": Wraps.$storage.user.id,
                  "eventType": "Added",
                  "eventData": "Final PDF added"
                });
              });
              //self.updateBookById(book.bookId);
              //book.getBook(true);
              deferred.resolve();


            });
          });
        });

      });
      return deferred.promise;
    }

    self.setBookStatuses = function(thisBook){
      //console.log('setBookStatuses:',thisBook.bookId);


      var deferred = $q.defer();

      // waiting for api logic move
      Books.setBookStatuses({bookId: thisBook.bookId, userId:Wraps.$storage.user.id }).$promise.then(function (results) {
        //console.log(results);

        self.updateBookById(thisBook.bookId);
        Notifications.getCounts();
        deferred.resolve(results);
      });
      return deferred.promise;


    }

    self.filenameInCodeCheck = function (filename, bookCode) {
      //console.log(filename,bookCode,filename.search(bookCode));
      if(filename && filename.search(bookCode) >= 0){
          return true;
      }else{
          return false;
      }
    }

      self.downloadCSV = function (searchArray) {

          var deferred = $q.defer();
          var csv;
          EnclosureTypes.find({
              "filter": {
                  "where": {
                      enclosureTypeStatus: 1
                  },
                  "order": "enclosureTypeOrder ASC"
              }
          }).$promise.then(function (enclosureTypes) {
              self.enclosureTypes = enclosureTypes;
              //console.log("enclosureTypes", enclosureTypes);
              self.buildCSV(searchArray).then(function(csv){

                  deferred.resolve(csv);

              });
          });
          return deferred.promise;
      }


      self.buildCSV = function (searchArray) {
          var booksCSV = [];
          var deferred = $q.defer();

          var getWorkflowUsers = function (approvalList) {
              var workflowUsers = [];
              _.forEach(approvalList, function (value, name) {
                  // if(Wraps.getUserById(value.user)) {
                  //     workflowUsers.push(Wraps.getUserById(value.user).firstName + ' ' + Wraps.getUserById(value.user).lastName);
                  // }
                  var thisUser = '';
                  if (Wraps.getTeamById(value.team)) {
                      thisUser = Wraps.getRoleById(value.role).description + "-" + Wraps.getTeamById(value.team).teamName;
                  } else {
                      thisUser = "All";
                  }
                  if (Wraps.getUserById(value.user)) {
                      thisUser = Wraps.getRoleById(value.role).description + "-" + Wraps.getUserById(value.user).firstName + " " + Wraps.getUserById(value.user).lastName;
                  } else {
                      thisUser += "-All";
                  }
                  if (thisUser.length > 0) {
                      workflowUsers.push(thisUser);
                  }
              });
              return workflowUsers;
          }

          var getPublishing = function (array) {
              if(array[0] == '{') {
                  var newarray = Object.values(array);
                  newarray = newarray.join('');
                  try{
                      array = angular.fromJson(newarray);
                  }catch(err){
                      array = angular.fromJson(array);
                  }
              }else{
                  array = angular.fromJson(array);
              }

              var publishTypes = [];
              _.forEach(array, function (value, name) {
                  if (value == true) {
                      publishTypes.push(_.get(_.find(Wraps.publishTypesNotFiltered, {'id': _.toInteger(name)}), 'name'));
                  }
              });
              return publishTypes;
          }

          if (!searchArray) {
            searchArray = self.pagination.filterLibrary;
          }

          var filterObject = {"startRow":0, "noResults":0, "searchArray":searchArray};


          Books.getLibrarySearch(filterObject).$promise.then(function (results) {

              var books = results.data;


              _.forEach(books, function (book) {
                  var loopDefered = $q.defer();
                  //console.log(book);
                  var ContentList = [];
                  if (book.bookContent) {
                      book.bookContent = angular.fromJson(book.bookContent);
                      _.forEach(book.bookContent, function (value, key) {
                          if (value) {
                              ContentList.push(_.get(_.find(Wraps.docContents, {'id': _.toInteger(key)}), 'name'));
                          }
                      });
                      ContentList = ContentList.toString();
                  } else {
                      ContentList = "";
                  }

                  var otherLanguageVersions = [];
                  if (book.languageVersions) {
                      book.languageVersions = angular.fromJson(book.languageVersions);
                      _.forEach(book.languageVersions, function (value, key) {
                          if (value) {
                              if (book.bookId === 1308) {
                                  //console.log("val:", value);
                              }
                              otherLanguageVersions.push(_.get(_.find(Wraps.languages, {'id': _.toInteger(key)}), 'name'));
                          }
                      });
                      otherLanguageVersions = otherLanguageVersions.toString();
                  } else {
                      otherLanguageVersions = "";
                  }


                  var workflowUsersDoc = getWorkflowUsers(_.filter(book.approvalList, {content: 'doc'}));
                  var workflowUsersBrief = getWorkflowUsers(_.filter(book.approvalList, {content: 'brief'}));
                  var workflowUsersArtwork = getWorkflowUsers(_.filter(book.approvalList, {content: 'artwork'}));
                  var workflowUsersFinal = getWorkflowUsers(_.filter(book.approvalList, {content: 'final'}));
                  var enclosures;


                  var newBook = {};

                  var owner = "";
                  if (Wraps.getUserById(book.bookOwner)) {
                      owner = Wraps.getUserById(book.bookOwner).firstName + " " + Wraps.getUserById(book.bookOwner).lastName;
                  }

                  newBook.bookId = book.bookId;
                  newBook.bookName = book.bookName;
                  newBook.bookCode = book.bookCode;
                  //newBook.psiCode = book.psiCode;

                  newBook.DocTypeCatName = book.DocTypes ? book.DocTypes.DocTypeCats.docTypeCatName : "";
                  newBook.DocType = book.DocTypes ? book.DocTypes.docTypeName : "";
                  newBook.Owner = owner;
                  newBook.division = book.Divisions ? book.Divisions.division : "";
                  newBook.workflowStatus = book.workflowStatus;
                  newBook.documentStatus = book.documentStatus;

                  newBook.Insurer = book.Insurers ? book.Insurers.insurerName : "";
                  newBook.InsurerDetails = book.InsurerDetailsText ? book.InsurerDetailsText : "";
                  newBook.Audience = book.Audiences ? book.Audiences.audienceName : "";
                  newBook.Region = book.Regions ? book.Regions.regionName : "";
                  newBook.Product = book.Products ? book.Products.productName : "";
                  newBook.CustomerType = book.ProductTypes ? book.ProductTypes.productTypeName : "";
                  newBook.SubInsurer = book.SubInsurer ? book.SubInsurer.subInsurerName : "";
                  newBook.Distributor = book.Distributor ? book.Distributor.distributorName : "";

                  if(book.finProm == null){
                    newBook.finProm = "Not set";
                  }else if(book.finProm  == "true" ){
                    newBook.finProm = "Yes";
                  }else{
                    newBook.finProm = "No";
                  }

                  newBook.limitDoc = book.limitDoc ? "Yes" : "No";
                 // newBook.BGHPColour = book.extras && book.extras.BGHPColour ? $filter('readableBGHPColour')(book.extras.BGHPColour) : "";

                  // if (book.socialChannelIds && book.socialChannelIds.length > 0) {
                  //   var count = 0;
                  //   newBook.socialChannels = "";
                  //   _.forEach(book.socialChannelIds, function(id){
                  //     if (count === 0) {
                  //       count++;
                  //     } else {
                  //         newBook.socialChannels += ", ";
                  //     }
                  //     newBook.socialChannels += $filter('readableSocialChannel')(id);
                  //   });
                  // } else {
                  //     newBook.socialChannels = "";
                  // }

                  newBook.workflowUsersDoc = workflowUsersDoc.join(", ");
                  newBook.workflowUsersBrief = workflowUsersBrief.join(", ");
                  newBook.workflowUsersArtwork = workflowUsersArtwork.join(", ");
                  newBook.workflowUsersPublishing = workflowUsersFinal.join(", ");
                  newBook.language = book.language ? book.Languages.languageName : "";

                  // newBook.production = book.production ? getPublishing(book.production).join(", ") : "";
                  // newBook.fulfilment = book.fulfilment ? getPublishing(book.fulfilment).join(", ") : "";
                  // newBook.digital = book.digital ? getPublishing(book.digital).join(", ") : "";
                  // newBook.support = book.support ? getPublishing(book.support).join(", ") : "";
                  // newBook.inSitu = book.inSitu ? getPublishing(book.inSitu).join(", ") : "";


                  newBook.client = book.clientId ? book.Clients.clientName : "";
                  newBook.sourceId = book.sourceId;
                  newBook.projectName = book.projectId ? book.Projects.projectName : "";

                  newBook.createdDate = $filter('date')(book.created, "dd/MM/yyyy");
                  newBook.activeDate = $filter('date')(book.activeDate, "dd/MM/yyyy");
                  newBook.liveDate = $filter('date')(book.liveDate, "dd/MM/yyyy");
                  if ($filter('date')(book.reviewDate, "yyyy") !== '3000') {
                      newBook.reviewDate = $filter('date')(book.reviewDate, "dd/MM/yyyy");
                  } else {
                      newBook.reviewDate = '';
                  }
                  if ($filter('date')(book.expiryDate, "yyyy") !== '3000') {
                      newBook.expiryDate = $filter('date')(book.expiryDate, "dd/MM/yyyy");
                  } else {
                      newBook.expiryDate = '';
                  }
                  newBook.earlyExpiryDate = $filter('date')(book.earlyExpiryDate, "dd/MM/yyyy");
                  newBook.withdrawDate = $filter('date')(book.withdrawDate, "dd/MM/yyyy");
                  //newBook.lastUpdated = $filter('date')(book.lastUpdated, "dd/MM/yyyy");

                  newBook.notes = book.notes;
                  newBook.ContentList = ContentList;
                  newBook.otherLanguageVersions = otherLanguageVersions;


                  var now = moment().format('YYYY-MM-DD');
                  var expiryDate = $filter('date')(book.expiryDate, "yyyy-MM-dd");
                  var earlyExpiryDate = $filter('date')(book.earlyExpiryDate, "yyyy-MM-dd");

                  var isLive = (book.status || (_.findIndex(book.approvalList, ['content', 'final']) > 0 && book.approvalStatus >= _.findIndex(book.approvalList, ['content', 'final'])));

                  //newBook.isLive  = isLive?"Yes":"No";

                  if (!book.withdrawDate && (expiryDate < now || earlyExpiryDate < now) && isLive) {
                      newBook.blackAsset = "Yes";
                      var tempExpiry = (earlyExpiryDate == null || expiryDate < earlyExpiryDate) ? expiryDate : earlyExpiryDate;


                      newBook.daysAsBlackAsset = moment(now).subtract(1, 'day').diff(moment(tempExpiry), 'days');
                  } else {
                      newBook.blackAsset = "No";
                      newBook.daysAsBlackAsset = "";
                  }


                  //newBook.MCOMPinWorkflow = _.findIndex(book.approvalList, ['team', 10]) > 0 ? "Yes" : "No";

                  //newBook.COMPinWorkflow = _.findIndex(book.approvalList, ['team', 6]) > 0 ? "Yes" : "No";

                  //now in SP from endpoint
                  // this was excluding the mco but the SP is including it, we need to check with Sara to see what the right thing to do is?
                  newBook.MCOMPinWorkflow = book.MCOMPinWorkflow;
                  newBook.COMPinWorkflow = book.COMPinWorkflow;;

                  // newBook.productIds = _.map(book.ProductIdsBookLink, 'productId').join(', ');

                  // _.forEach(self.enclosureTypes, function (item) {
                  //     var encType = "";
                  //     var colName = "enclosure" + item.enclosureTypeName.replace(/ /g, "");
                  //     newBook[colName] = "";
                  //     if (encType = _.filter(book.Enclosures, {'enclosureType': "" + item.enclosureTypeId})[0]) {
                  //         //console.log("encType", encType);
                  //         newBook[colName] = encType.enclosureBookCode;
                  //     }
                  // });

                  booksCSV.push(newBook);

              });
              deferred.resolve(booksCSV);
          });
          return deferred.promise;

      }


  }
}());
