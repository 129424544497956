(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.swapteamuserdialog.controller:SwapteamuserdialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.swapteamuserdialog')
    .controller('SwapteamuserdialogCtrl', SwapteamuserdialogCtrl);

  function SwapteamuserdialogCtrl(
    $filter,
    $localStorage,
    $mdDialog,
    $scope,
    $sessionStorage,
    book,
    Books,
    Events,
    Teams,
    Users,
    Wraps,
    WrapsBooks) {
    var vm = this;
    vm.ctrlName = 'SwapteamuserdialogCtrl';

    if(!Users.isAuthenticated()){$location.path('/');}

    $scope.$storage = $localStorage;
    $scope.$session = $sessionStorage;

    vm.booId = book.bookId;
    vm.bookName = book.bookName;
    var approvalList = angular.copy(book.approvalList);


    // add team to list
    vm.teamList = [$scope.$storage.user.teamId];

    // if team = design add unigraph team to list
    if($scope.$storage.user.teamId == 20){
      vm.teamList.push(21);
    }

    Users.find({"filter": {"where": {"and":[{"teamId":{"inq": vm.teamList}},{"status":null}]}}}).$promise.then(function(results){
      vm.users = results;
    });


    Teams.find({"filter": {"where": {"and":[{"teamId":{"inq": vm.teamList}},{"teamStatus":1}]}}}).$promise.then(function(results){
      vm.teams = results;
    });

    _.forEach(approvalList, function (item, index) {

      item.labels = {}

      switch (item.content){
        case 'doc':
          item.labels.type = 'Document';
          break;
        case 'brief':
          item.labels.type = 'Document or Brief';
          break;
        case 'artwork':
          item.labels.type = 'Artwork Draft';
          break;
        case 'final':
          item.labels.type = 'Publishing';
          break;
        default:
      }

      if (item.team) {
        item.labels.team = Wraps.getTeamById(item.team).teamName;
      }
      if (item.user) {
        item.labels.user = Wraps.getUserById(item.user).firstName + ' ' + Wraps.getUserById(item.user).lastName;
      }else{
        item.labels.user = 'All';
      }

      if (item.role) {
        if (item.role != 3) {
          if (item.role == 6 && item.content == 'final') {
            item.labels.title = 'FINAL FILES';
          }else{
            item.labels.title = $filter('uppercase')(Wraps.getRoleById(item.role).description);
          }
        }
        else if (parseInt(item.team) > 0) {
          item.labels.title = $filter('uppercase')(Wraps.getTeamById(item.team).shortName);
        }
        else{
          //console.log("error 1",item);
        }
      }else{
       //console.log("error 2",item);
      }
    });

    vm.approvalList = angular.copy(approvalList);
    vm.approvalListCopy = angular.copy(approvalList); // for validation checks

    vm.canChangeTeam = function (item) {
      // if user team in teamList and length > 1
      if (vm.teamList.length >= 1 && vm.teamList.includes(item.team)) {

        if (item.role === 3 || item.role === 6 || item.role === 11) {
          return true;
        }
      }
      return false;
    }

    vm.mco = _.find(vm.approvalList, function (o) { return o.role === 2 && o.user > 0; });

    vm.isMcoAsApprover = function (user, item, mco) {
      if (
        angular.isObject(item) &&
        item.role &&
        (item.role === 3 || item.role === 8) &&
        user.id === mco.user
        ) {
        return true;
      }
      return false;
    }

    vm.checkForChanges = function(){
      return Wraps.toJson(vm.approvalList) == Wraps.toJson(vm.approvalListCopy);
    }

    vm.saveWorkflow = function(){
      vm.isLoading = true;
      var thisList = angular.copy(vm.approvalList);

      var newList = [];
      _.forEach(thisList, function (item, index) { //remove labels and sort keys to make sure the order matches with existing format
        delete item.labels;
        newList[index] = {};
        _(item).keys().sort().each(function (key) {
          newList[index][key] = item[key];
        });
      });
      Books.upsert({
        "bookId": book.bookId,
        "approvalList": Wraps.toJson(newList)
      }).$promise.then(function (results) {
        Events.create({
          "eventId": 0,
          "bookId": book.bookId,
          "userId": $scope.$storage.user.id,
          "eventType": "Edited Workflow",
          "eventData": "{\"before\":" + Wraps.toJson(thisList) + ",\"after\":" + Wraps.toJson(newList) + "}"
        }).$promise.then(function (results) {
          WrapsBooks.updateBookById(book.bookId);
          //book.update(true);
          vm.dialogHide();
        });
      });




    }

    vm.dialogHide = function() {
      $mdDialog.hide();
    };

    vm.dialogCancel = function() {
      $mdDialog.cancel();
    };
  }
}());
