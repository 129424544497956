<md-dialog aria-label="Swpa User in Team" style="min-width:600px; overflow:hidden;"  ng-cloak>

    <md-toolbar>
        <div class="md-toolbar-tools">
            <h2>Swap User in Team</h2>
            <span flex></span>

            <md-button ng-click="swapteamuserdialog.dialogCancel(false)" class="md-icon-button">
                <ng-md-icon  icon="close" style="fill: white;"  aria-label="Close dialog"></ng-md-icon>
            </md-button>
        </div>
    </md-toolbar>
    <md-dialog-content class="dialog-content" style="">
        <div style="padding: 20px 20px;">



            <div ng-repeat="item in swapteamuserdialog.approvalList">
                <h3 style="margin: 20px 0 10px;padding: 0;" ng-if="swapteamuserdialog.approvalList[$index].content != swapteamuserdialog.approvalList[$index-1].content">{{item.labels.type}}</h3>

                <div layout="row" style="font-size: 14px; color:#666; ">
                    <div flex="15" class="statbar-mini">
                        <span class="outer">
                            <span class="inner  grey"></span>
                            <span class="label ">{{item.labels.title}}</span>
                        </span>
                    </div>
                    <!--<div style="padding: 6px 0;" flex="30">{{item.labels.team}}</div>-->


                    <div ng-if="!swapteamuserdialog.canChangeTeam(item)"  style="padding: 6px 0;" flex="50">{{item.labels.team}}</div>
                    <div ng-if="swapteamuserdialog.canChangeTeam(item)" style="padding: 0px 0;" flex="50">

                        <md-select ng-model="item.team" placeholder="Select team" style="font-size: 14px; margin: -2px 0 0 0;"  >
                            <md-option ng-value="team.teamId" ng-repeat="team in swapteamuserdialog.teams  | orderBy: ['team'] " >{{team.teamName}}</md-option>
                        </md-select>

                    </div>


                    <div ng-if="!swapteamuserdialog.canChangeTeam(item)"  style="padding: 6px 0;" flex="50">{{item.labels.user}}</div>
                    <div ng-if="swapteamuserdialog.canChangeTeam(item)" style="padding: 0px 0;" flex="50">

                        <md-select ng-model="item.user" placeholder="Select user" style="font-size: 14px; margin: -2px 0 0 0;"  >
                            <md-option ng-value="undefined">All</md-option>
                            <md-option ng-disabled="swapteamuserdialog.isMcoAsApprover(user,item,swapteamuserdialog.mco);" ng-value="user.id" ng-repeat="user in swapteamuserdialog.users | filter:{ teamId: item.team } | orderBy: ['firstName', 'lastName'] " >{{user.firstName}} {{user.lastName}}</md-option>
                        </md-select>

                    </div>
                </div>
            </div>

        </div>
    </md-dialog-content>
    <md-dialog-actions layout="row">
        <span flex></span>

        <md-button ng-disabled="swapteamuserdialog.checkForChanges() || swapteamuserdialog.isLoading" class="md-primary md-raised"  ng-click="swapteamuserdialog.saveWorkflow()">Save</md-button>



    </md-dialog-actions>
</md-dialog>
