(function () {
  'use strict';

  angular
    .module('library.emaildetailsdialog')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('emaildetailsdialog', {
        url: '/emaildetailsdialog',
        templateUrl: 'library/emaildetailsdialog/emaildetailsdialog.tpl.html',
        controller: 'EmaildetailsdialogCtrl',
        controllerAs: 'emaildetailsdialog'
      });
  }
}());
