(function () {
  'use strict';

  angular
    .module('library.pushdialog')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('pushdialog', {
        url: '/pushdialog',
        templateUrl: 'library/pushdialog/pushdialog.tpl.html',
        controller: 'PushdialogCtrl',
        controllerAs: 'pushdialog'
      });
  }
}());
