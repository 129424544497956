(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableMaterDocType
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableMasterDocType', readableMasterDocType);

  function readableMasterDocType(Wraps) {
    return function (id) {
      var docType, docTypeCat;
      if ((docType = _.find(Wraps.docTypes, {docTypeId: parseInt(id)})) && docType.docTypeCatId) {
        if ((docTypeCat = _.find(Wraps.docTypeCats, {docTypeCatId: parseInt(docType.docTypeCatId)})) && docTypeCat.docTypeCatName) {
          return docTypeCat.docTypeCatName;
        }
      }
      return null;
    };
  }
}());
