angular
    .module('library')
    .component('tabList', {
        templateUrl: 'library/library-components/tab-list/tab-list.tpl.html',
        controller: tabListCtrl,
        controllerAs:'tabList',
        bindings:{
            library:'<'
        }

    });

tabListCtrl.$inject = ['$scope','$localStorage', '$q', '$sce', 'WrapsBooks'];
function tabListCtrl($scope, $localStorage, $q, $sce,  WrapsBooks){
    var vm = this;
    $scope.$storage = $localStorage;
    vm.pagination = WrapsBooks.pagination;
    vm.temp = {};
    vm.temp.filterLibrary = angular.copy(vm.pagination.filterLibrary);
    //vm.filteredLibrary = WrapsBooks.filteredLibrary;
    //filtered Library is currently tabList.library.books;
    vm.isLoading = WrapsBooks.isLoading;


    vm.getActionText = function(){
        if(vm.temp.filterLibrary.actionStatus && vm.temp.filterLibrary.actionStatus.length > 0){
            var text = '';
            _.forEach(vm.temp.filterLibrary.actionStatus, function(item){
                if(item == 1) {
                    text = text + '<ng-md-icon style="fill: rgb(244, 67, 54);" class="animate-if ng-scope ng-isolate-scope" wraps-tooltip="action-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path></svg></ng-md-icon>';
                }else if(item == 2){
                    text = text + '<ng-md-icon style="fill: rgb(3, 169, 244);" class="animate-if ng-scope ng-isolate-scope" wraps-tooltip="action-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path></svg></ng-md-icon>';
                }else if(item ==3){
                    text = text + '<ng-md-icon style="fill: rgb(3, 169, 244);" class="animate-if ng-scope ng-isolate-scope" wraps-tooltip="action-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16"><path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path></svg></ng-md-icon>';
                }
            });
            return $sce.trustAsHtml(text);
        }else{
            return "Action";
        }
    }

    $scope.$watch('tabList.pagination.filterLibrary', function (before,after) {
        if(!angular.equals(before,after)){
            vm.temp.filterLibrary = angular.copy(vm.pagination.filterLibrary);
            vm.first();
        }
    },true);

    vm.updateFilter = function(){
        vm.pagination.filterLibrary = angular.copy(vm.temp.filterLibrary);
        $scope.searchForm.$setPristine();
    }

    vm.lastOnPage = function(){
        var lastOnPage = vm.pagination.startRow + vm.pagination.noResults;
        if(lastOnPage > vm.pagination.totalRows) {lastOnPage = vm.pagination.totalRows;}
        return lastOnPage;
    }

    vm.first = function(){
        vm.pagination.startRow = 0;
        vm.updateBooks();
    }

    vm.previous = function(){
        vm.pagination.startRow -= vm.pagination.noResults;
        vm.updateBooks();
    }

    vm.next = function(){
        vm.pagination.startRow += vm.pagination.noResults;
        vm.updateBooks();
    }

    vm.last = function(){
        var itemsOnLastPage = vm.pagination.totalRows % vm.pagination.noResults;
        if(itemsOnLastPage == 0){itemsOnLastPage = vm.pagination.noResults}
        vm.pagination.startRow = vm.pagination.totalRows - itemsOnLastPage;
        vm.updateBooks();
    }


    vm.hasPrevious = function () {

        if(vm.pagination.startRow == 0) {
            return false;
        }else {
            return true;
        }

    };


    vm.hasNext = function () {
        var itemsOnLastPage = vm.pagination.totalRows % vm.pagination.noResults;
        if(vm.pagination.startRow == vm.pagination.totalRows - itemsOnLastPage){
            return false;
        }else{
            return true;
        }

    };

    vm.sortBy = function(propertyName){

        if(vm.pagination.filterLibrary.sortOrder === propertyName && vm.pagination.filterLibrary.sortDirection == 'asc') {
            vm.pagination.filterLibrary.sortDirection = 'desc';
        }else{
            vm.pagination.filterLibrary.sortDirection = 'asc';
        }
        vm.pagination.filterLibrary.sortOrder = propertyName;
    }

    vm.updateBooks = WrapsBooks.getLibraryList;


    vm.stringifySearch = WrapsBooks.stringifySearch;




}