
<md-content  layout="row" layout-wrap  layout-align="center start"  class="default-content">

    <div class="md-whiteframe-z1 default-box" style="" >

        <md-content >
            <h2  class="">Workflow</h2>


            <md-list>






            </md-list>



        </md-content>



    </div>

</md-content>
<md-sidenav class="md-sidenav-right md-whiteframe-z3 users-sidenav" md-component-id="right">
    <md-toolbar class="md-theme-light">
        <h3 class="md-toolbar-tools">Edit Item - {{}}</h3>
    </md-toolbar>
    <md-content  layout-padding>
        <form>





        </form>
        <md-button ng-click="library.closeRightMenu()" class="md-fab">
            <ng-md-icon icon="close" style="fill: white" size="30"></ng-md-icon>
        </md-button>
    </md-content>
</md-sidenav>