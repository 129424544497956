(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableProject', readableProject);

  function readableProject(Wraps) {
    return function (id) {
      var project;
      if ((project = _.find(Wraps.projects, {projectId: parseInt(id)})) && project.projectName) {
        return project.projectName;
      }
      return null;
    };
  }
}());
