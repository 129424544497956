(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name library.controller:LibraryCtrl
     *
     * @description
     *
     */
    angular
        .module('library')
        .controller('LibraryCtrl', LibraryCtrl)
        .filter('unique', function () {
            return function (arr, field) {
                return _.uniqBy(arr, field);
            };
        });

    function LibraryCtrl(
        $document,
        $element,
        $filter,
        $interval,
        $localStorage,
        $location,
        $mdBottomSheet,
        $mdDialog,
        $mdSidenav,
        $mdToast,
        $q,
        $rootScope,
        $scope,
        $sessionStorage,
        $state,
        $timeout,
        $window,
        apiUrl,
        Audiences,
        Books,
        BooksVersions,
        Containers,
        Distributors,
        DocTypeCats,
        DocTypes,
        dragularService,
        Enclosures,
        EnclosureTypes,
        Events,
        History,
        InsurerDetails,
        Insurers,
        Messages,
        Notifications,
        OrderHistory,
        pdfUrl,
        Products,
        ProductIds,
        ProductIdsBookLink,
        ProductTypes,
        Regions,
        SaveSearch,
        Structures,
        SubInsurers,
        system,
        Upload,
        Users,
        Versions,
        Wraps,
        WrapsBooks
    ) {

        var vm = this;
        var bookMetaOriginalState = {};
        vm.ctrlName = 'LibraryCtrl';
        vm.apiUrl = apiUrl;

        if(system=='switch'){
          vm.enclosuresAllowedSubDocTypes = [];
          vm.productIdsAllowedSubDocTypes = angular.copy(vm.enclosuresAllowedSubDocTypes);
        }else{
          vm.enclosuresAllowedSubDocTypes = [55, 56, 57, 76, 77, 78, 80, 81, 82, 83, 84, 85, 117];
          vm.productIdsAllowedSubDocTypes = angular.copy(vm.enclosuresAllowedSubDocTypes);
          vm.productIdsAllowedSubDocTypes.push(1, 93, 32, 29, 97);
        }



        // [10, 29, 32, 55, 56, 57, 58, 61, 76, 77, 78, 80, 81, 82, 83, 84, 85, 86, 93, 97];
        // changed to match enclosure type re: Laura's email 31st Dec 2018, point 5
        // also includes, member guide, member guide (BGHP), member guide (GHP, HSAP & Bespoke only), Changes (List & Significant), IPID;

        $scope.$storage = $localStorage;
        vm.localStorage = $localStorage;
        $scope.$session = $sessionStorage;

        if (!Users.isAuthenticated()) {
            $location.path('/');
        }
        vm.hasAccess = null;
        Wraps.fetchedUser.then(function () {
            if (!vm.hasPermissions('viewLibrary')) {
                $state.go('login', {nextAfterLogin: 'Library'});
            } else {
                vm.hasAccess = true;
            }
        });


        vm.showScrollFabs = 'hideScrollFab';
        vm.pixelsScrolledToTop = 0;

        $document.on('scroll', function () {

            vm.pixelsScrolledToTop = $window.scrollY;
            if (vm.pixelsScrolledToTop > 250 && vm.showScrollFabs == 'hideScrollFab') {
                $scope.$applyAsync(function () {
                    vm.showScrollFabs = 'showScrollFab';
                });
            } else if (vm.pixelsScrolledToTop < 250 && vm.showScrollFabs == 'showScrollFab') {
                $scope.$applyAsync(function () {
                    vm.showScrollFabs = 'hideScrollFab';
                });
            }

        });

        vm.toTheTop = function () {
            $document.scrollTop(0, 1000);
        }


        //console.log(apiUrl);
        //console.log("system",system);

        vm.pdfUrl = pdfUrl;
        vm.system = system;

        if (system === "local") {
            vm.linkExtras = "&local=true";
        } else if (system === "demo") {
            vm.linkExtras = "&demo=true";
        } else if (system === "staging") {
            vm.linkExtras = "&staging=true";
        }

        vm.selected = [];
        vm.filterLibrary = {};
        vm.filterLibrary = angular.copy($scope.$storage.filterLibrary);
        vm.searchLibrary = angular.copy($scope.$storage.searchLibrary);
        vm.queryLibrary = angular.copy($scope.$storage.queryLibrary);

        if (angular.isUndefined($scope.reorderToggle)) {
            $scope.reorderToggle = false;
        }



        //loads from wraps service
        vm.states = Wraps.states;
        vm.levels = Wraps.levels;
        vm.emphasis = Wraps.emphasis;
        vm.setBookStatuses = WrapsBooks.setBookStatuses;
        vm.BGHPColour = Wraps.BGHPColour;

        vm.hasPermissions = Wraps.hasPermissions;

        vm.getTableWidths = Wraps.getTableWidths;

        vm.updateBookData = Wraps.updateBookData;

        vm.test = Wraps.test;

        vm.thisUser = angular.copy($scope.$storage.user);

        $scope.$storage.pdfVersion = 'v1.3'; //TODO connect to PDF API getLatestVersion


        vm.uploadPDF = function (book) {
            WrapsBooks.uploadPDF(book);
        }


        vm.isMemUploadable = function (book) {
            if (book.DocTypes.docTypeTemplate == 1 && $scope.$storage.user.id == 42) { //doctype
                return true;
            } else {
                return false;
            }
        }

        vm.isRepairWorkflow = function (book) {
          if (vm.hasPermissions('viewHealthcheck')) {
              return true;
          }
          return false;
        }

        vm.repairWorkflow = function (book) {
            WrapsBooks.setBookStatuses(book).then(function (emailAction) {
              vm.book.updateBook().then(function(){ });
          });
        }

        //TODO - use a model to generate this and control the view with ng-repeats on the filter list and columns
        if (!$scope.$storage.showFilters) {
            $scope.$storage.showFilters = {}

            //$scope.$storage.showFilters.id = true;
            $scope.$storage.showFilters.code = true;
            $scope.$storage.showFilters.name = false;
            $scope.$storage.showFilters.insurer = false;
            $scope.$storage.showFilters.product = true;

            $scope.$storage.showFilters.audience = false;
            $scope.$storage.showFilters.region = false;
            $scope.$storage.showFilters.productType = false;

            $scope.$storage.showFilters.doc = true;
            $scope.$storage.showFilters.docTypeCatName = false;
            $scope.$storage.showFilters.finProm = false;
            $scope.$storage.showFilters.clients = false;
            $scope.$storage.showFilters.projectName = false;

            $scope.$storage.showFilters.Owner = {};
            $scope.$storage.showFilters.Owner.fullName = true;
            $scope.$storage.showFilters.Owner.teamName = false;
            $scope.$storage.showFilters.workflowStatus = true;
            $scope.$storage.showFilters.documentStatus = true;
            $scope.$storage.showFilters.date = false;
            $scope.$storage.showFilters.lastUpdated = false;
            $scope.$storage.showFilters.activeDate = false;
            $scope.$storage.showFilters.liveDate = true;
            $scope.$storage.showFilters.reviewDate = false;
            $scope.$storage.showFilters.expiryDate = true;
            //$scope.$storage.showFilters.action = true;
        }


        Wraps.initVars = Wraps.initData();
        Wraps.initVars.then(function (data) {
            console.log("Init Data finished");
            //console.log("init: ",data);
            // vm.teams = data.teams;
            vm.users = data.users;
            // vm.roles = data.roles;
            //
            // vm.docContents = data.docContents;
            // vm.languages = data.languages;
             vm.publishTypes = data.publishTypes;
             vm.publishTypesNotFiltered = data.publishTypesNotFiltered;
            //
            // vm.rfcReasons = data.rfcReasons;
            // vm.rfcCategories = data.rfcCategories;
            // vm.rfcJoin = data.rfcJoin;
            //
            vm.clients = Wraps.clients;
            vm.projects = Wraps.projects;
            ////vm.updateBooks();
        });


        if (vm.filterLibrary && Object.keys(vm.filterLibrary).length > 0) {
            vm.searchLibrary = true;
        }



        SaveSearch.find({
            "filter": {
                "where": {
                    userId: $scope.$storage.loggedInUser.id,
                    deleted: 0
                }
            }
        }).$promise.then(function (saveSearch) {
            vm.savedSearches = saveSearch;
        });

        vm.saveSearch = function () {
            if (vm.searchName !== '') {
                SaveSearch.upsert({
                    userId: $scope.$storage.loggedInUser.id,
                    searchName: vm.searchName,
                    saveBlob: JSON.stringify({
                        filterLibrary: $scope.$storage.pagination.filterLibrary
                    }),
                    dateCreated: Date.now()
                }, function () {
                    SaveSearch.find({
                        "filter": {
                            "where": {
                                userId: $scope.$storage.loggedInUser.id,
                                deleted: 0
                            }
                        }
                    }).$promise.then(function (saveSearch) {
                        vm.savedSearches = saveSearch;
                    });
                })
            }
        }


        vm.checkBlankArray = function(arrayToTest) {
            return _.isEmpty(arrayToTest);
        }


        vm.openBook = function (book) {
            //$scope.$storage.selectedBook = book.bookId;
            WrapsBooks.updateBookById(book.bookId);
            //book.updateBook();
            vm.goToAnchor('anchorBook' + book.bookId);
        }


        $scope.$watch( function() { return WrapsBooks.books; }, function(returnedBooks) {
            vm.isLoading = true;
            if(returnedBooks) {
              returnedBooks.then(function (books) {
                vm.isLoading = false;
                vm.books = books;
              });
            }
        });

        const defaultCols = {
            code: true,
            name: true,
            insurer: true,
            Owner: {
                fullName: true
            },
            doc:true,
            documentStatus: true,
            workflowStatus: true,
            liveDate: true,
            expiryDate: true
        }

        vm.resetColVisibility = function(){
            $scope.$storage.showFilters = angular.copy(defaultCols);
        }



        vm.updateBooks = WrapsBooks.getLibraryList;


        vm.goToAnchor = function (anchor) {
            var anchorFunction = function () {
                var section2 = angular.element(document.getElementById(anchor));
                $document.scrollTo(section2, 84, 1000);
            }
            $timeout(anchorFunction, 100);
        }

        vm.showRandom = function (percentage) {

            _.forEach(vm.books, function (book) {
                book.complianceSelect = false;
            });

            var booksNeeded = _.ceil(vm.filteredLibrary.length * percentage / 100);
            var bookIds = _.take(_.shuffle(_.map(vm.filteredLibrary, 'bookId')), booksNeeded);

            _.forEach(bookIds, function (bookId) {
                _.find(vm.filteredLibrary, {'bookId': bookId}).complianceSelect = true;
            });


        }




        $scope.$watch(function () {
            return Wraps.updateBookData;
        }, function watchCallback(newValue, oldValue) {
            if (newValue) {
                var book = vm.bookById(Wraps.updateBookData);
                WrapsBooks.updateBookById(book.bookId);
                //book.updateBook();
                Wraps.updateBookData = false;
            }
        });

        //
        // var updateBook = function (force) {
        //     var thisBook = this;
        //     console.log("updateBook:", thisBook);
        //     if (angular.isUndefined(thisBook.Children)) {
        //         vm.getBook(thisBook);
        //         thisBook.isNew = true;
        //     } else {
        //         Books.findOne({
        //             "filter": {
        //                 "where": {"bookId": thisBook.bookId}
        //             }
        //         }).$promise.then(function (results) {
        //             if ((results.lastUpdated > thisBook.lastUpdated) || force) {
        //                 vm.getBook(thisBook);
        //             } else {
        //                 Wraps.book = thisBook;
        //             }
        //         });
        //     }
        //
        // }


        vm.bookById = function (id) {
            return _.find(vm.openBooks, {'bookId': id});
        }

        vm.childByStructureId = function (parent, structureId) {
            var child;
            if (parent.structureId === structureId) {
                child = parent;
            } else {
                angular.forEach(parent.Children, function (val1) {
                    if (!child) {
                        child = vm.childByStructureId(val1, structureId);
                    }
                });
            }
            return child;
        }

        //for testing the above function
        vm.rectest = function (parent, structureId) {
            console.log("test", vm.childByStructureId(parent, structureId));
        }

        vm.checkHistory = function (obj) {
            if (!angular.isUndefined(obj.History)) {
                if (obj.History[0].versionIdOld != obj.versionId) {
                    return 'changed';
                }
            }
            return false;
        }

        vm.checkHistoryChildren = function (obj) {
            var test = '';
            if (!angular.isUndefined(obj.Children)) {
                angular.forEach(obj.Children, function (child) {
                    if (vm.checkHistory(child)) {
                        test = 'chapterChanged';
                    } else {
                        if (vm.checkHistoryChildren(child)) {
                            test = 'chapterChanged';
                        }
                    }

                });
            }
            return test;
        }

        vm.checkVersion = function (obj) {
            if (obj.versions.status <= 1) {
                return 'changed';
            }
            return false;
        }

        vm.checkVersionChildren = function (obj) {
            var test = '';
            if (!angular.isUndefined(obj.Children)) {
                angular.forEach(obj.Children, function (child) {
                    if (vm.checkVersion(child)) {
                        test = 'chapterChanged';
                    } else {
                        if (vm.checkVersionChildren(child)) {
                            test = 'chapterChanged';
                        }
                    }

                });
            }
            return test;
        }


        vm.toggleSelected = function (id) {
            if (_.indexOf($scope.$session.selectedList, id) >= 0) {
                _.pull($scope.$session.selectedList, id);
            } else {
                //ADDED TO SHOW ONE ONE CHAPTER
                //$scope.$session.selectedList = [];
                //$scope.$storage.selectedCopy = 0;

                $scope.$session.selectedList.push(id);
            }
        }


        vm.consolelog = function (text) {
            console.log(text);
        }




        // vm.setBoldWords = function (chapter, book) {
        //
        //     book.boldWords = [];
        //     angular.forEach(chapter.Children, function (child) {
        //         if (child.glossary) {
        //
        //             if (angular.isArray(book.boldWords)) {
        //                 if (child.glossary.words) {
        //                     book.boldWords = book.boldWords.concat(child.glossary.words.trim()
        //                         .replace("&nbsp;", " ")
        //                         .replace(/\s*,[,\sp]*\b/g, ",")
        //                         .replace(/,*\s*$/, "")
        //                         .replace("’", "'") //this is new for apostophe
        //                         .split(","));
        //                 }
        //             } else {
        //                 book.boldWords = child.glossary.words.trim()
        //                     .replace(/&nbsp;/g, " ")
        //                     .replace(/\s*,[,\sp]*\b/g, "")
        //                     .replace(/,*\s*$/, ",")
        //                     .replace("’", "'") //this is new for apostophe
        //                     .split(",");
        //             }
        //         }
        //     });
        // }




        vm.nl2br = function (string) {
            try {
                return string.replace(/\n/g, '<br>');
            } catch (err) {
            }
        }

        vm.sideNavOpens = 0;


        vm.openRightMenu = function (book) {
            vm.sideNavOpens++;
            $scope.documentInfoForm.$setUntouched();
            $scope.documentInfoForm.$setPristine();

            vm.tabIndex = 0;
            $mdSidenav('right').toggle();
            if (!book) {
                vm.selected = [];
                vm.selected.book = {};
                vm.selected.book.co = true;

                vm.selected.book.divisionId = angular.copy($scope.$storage.user.divisionId);


                //vm.selected.book.earlyExpiryDate = moment(new Date());
                vm.selected.book.bookOwner = $scope.$storage.user.id;
                initWorkflowList();
                vm.selected.book.levels = vm.levels;
                //---dates---//


                vm.disableBookEdit = false;

                vm.temp = {DocTypeCatName: false};
                vm.switch = {};
                vm.switch.production = {flag: true};
                vm.switch.fulfilment = {flag: true};
                vm.switch.digital = {flag: true};
                vm.switch.support = {flag: true};
                vm.switch.inSitu = {flag: true};


                //console.log(vm.selected.book);
            } else {

                if (book.eventData && book.eventData.reason && book.eventData.reason == 48) {
                    vm.newLanguage = true;
                } else {
                    vm.newLanguage = false;
                }


                // if (book.activeDate) {
                //   book.activeDate = new Date(book.activeDate);
                // }
                // if (book.liveDate) {
                //   book.liveDate = new Date(book.liveDate);
                // }


                if(book.DocTypes){
                  vm.temp = {DocTypeCat : book.DocTypes.docTypeCatId};
                }else{
                  vm.temp = {};
                }

                vm.switch = {};
                if (_.isEmpty(book.production)) {
                    vm.switch.production = {flag: true};
                } else {
                    vm.switch.production = {flag: false};
                }

                if (_.isEmpty(book.fulfilment)) {
                    vm.switch.fulfilment = {flag: true};
                } else {
                    vm.switch.fulfilment = {flag: false};
                }

                if (_.isEmpty(book.digital)) {
                    vm.switch.digital = {flag: true};
                } else {
                    vm.switch.digital = {flag: false};
                }

                if (_.isEmpty(book.support)) {
                    vm.switch.support = {flag: true};
                } else {
                    vm.switch.support = {flag: false};
                }

                if (_.isEmpty(book.inSitu)) {
                    vm.switch.inSitu = {flag: true};
                } else {
                    vm.switch.inSitu = {flag: false};
                }



                vm.selected.book = angular.copy(book);


                if (vm.hasPermissions('masterDocumentEdit')) { // has permission to always edit.
                    vm.disableBookEdit = false;
                    // console.log('admin');
                } else if (book.duplicateId) {
                    if(vm.hasPermissions('duplicateDocuments')){
                        vm.disableBookEdit = false;
                        //console.log('duplicateId perms true');
                    }else if($scope.$storage.user.roleId === 2){ //need to remove this once transitioned to perms
                        vm.disableBookEdit = false;
                        //console.log('duplicateId mco true');
                    }else{
                        vm.disableBookEdit = true;
                        //console.log('duplicateId perms false');
                    }
                } else if (book.documentStatus.substring(0, 4) === 'EXPD'){ // expired book
                    vm.disableBookEdit = true;
                    // console.log('expired');
                } else if (_.indexOf(book.userInBook, book.approvalStatus) >= 0) {
                    if ($scope.$storage.user.roleId === 6) {
                        vm.disableBookEdit = true;
                        //console.log('design');
                    } else if (book.status >= 1) {
                        vm.disableBookEdit = true;
                        //console.log('live');
                    } else if (book.approvalList[book.approvalStatus] && book.approvalList[book.approvalStatus].role === 1 && book.approvalStatus == 0) {
                        vm.disableBookEdit = false;
                        //console.log('dlo');
                    } else if (book.approvalList[book.approvalStatus] && book.approvalList[book.approvalStatus].role === 2 && book.approvalStatus <= 1) {
                        vm.disableBookEdit = false;
                        //console.log('mco');
                    } else if (book.duplicateId) {
                        vm.disableBookEdit = false;
                        //console.log('duplicateId');
                    } else {
                        vm.disableBookEdit = true;
                        //console.log('else 1');
                    }
                } else {
                    vm.disableBookEdit = true;
                    //console.log('user not in book');
                }
                delete vm.selected.book.Children;

                //console.log("openRightMenu editing:",vm.selected.book);
                initWorkflowList();

                bookMetaOriginalState = angular.copy(vm.selected.book);
                delete bookMetaOriginalState.lastUpdated;
                delete bookMetaOriginalState.created;
                delete bookMetaOriginalState.Teams;
                delete bookMetaOriginalState.Insurers;
                delete bookMetaOriginalState.SubInsurers;
                delete bookMetaOriginalState.Distributors;
                delete bookMetaOriginalState.Audiences;
                delete bookMetaOriginalState.Regions;
                delete bookMetaOriginalState.Products;
                delete bookMetaOriginalState.ProductTypes;
                delete bookMetaOriginalState.changes;
                delete bookMetaOriginalState.boldWords;
                delete bookMetaOriginalState.Children;

                //if (bookMetaOriginalState.levels) { bookMetaOriginalState.levels = Wraps.toJson(bookMetaOriginalState.levels); }
                //if (bookMetaOriginalState.bookContent) {  bookMetaOriginalState.bookContent = Wraps.toJson(bookMetaOriginalState.bookContent); }
                //if (bookMetaOriginalState.languageVersions) { bookMetaOriginalState.languageVersions = Wraps.toJson(bookMetaOriginalState.languageVersions); }

                //---dates--//
                // vm.setBeforeLive(vm.selected.book);
                //vm.setBeforeExpiry(vm.selected.book);
                //vm.changeActiveDate(vm.selected.book);

            }

            vm.bookCodeList = _.map(vm.books, 'bookCode');
            _.pull(vm.bookCodeList, vm.selected.book.bookCode);

        };

        vm.closeRightMenu = function () {
            vm.tabIndex = 0;
            $mdSidenav('right').close()
                .then(function () {
                    //console.log(vm);
                });
        };

        vm.removeFile = function (array, index) {
            //console.log(index);
            array.splice(index, 1);
        }


        var timestamp = new Date().getTime();
        $scope.$watch('library.JSONFile', function () {
            if (vm.JSONFile && vm.JSONFile.name.search(/\.json/i) > -1) {
                vm.upload(vm.JSONFile).then(function (statuses) {
                    vm.isLoading = false;
                });
            }
        });

        vm.log = '';
        vm.upload = function (file) {

            var S3_BUCKET_URL = apiUrl + '/containers/wraps-files/upload';
            var deferred = $q.defer();

            if (file) {
                if (!file.$error) {
                    vm.isLoading = true;

                    file.container = 'wraps-files';
                    file.fileLocation = 'translationFiles/' + timestamp;
                    file.fileName = file.name;
                    file.fileSize = file.size;
                    vm.JSONFile.filesList = [];
                    vm.JSONFile.filesList.push(file);
                    //console.log(vm.JSONFile);

                    //TODO add timeout to animate files
                    var newName = file.fileLocation + '/' + file.name;
                    Upload.rename(file, newName);
                    Upload.upload({
                        url: S3_BUCKET_URL,
                        file: file

                    }).then(function (resp) {
                        //console.log(resp);
                        vm.selected.book.languageFile = newName;
                        deferred.resolve();
                    }, null, function (evt) {
                        file.determinateValue = parseInt(100.0 * evt.loaded / evt.total);

                    });
                }
            }

            return deferred.promise;
        };


        vm.setNow = function (check) {
            if (check) {
                vm.selected.book.earlyExpiryDate = new Date();
            } else {
                vm.selected.book.earlyExpiryDate = null;
            }

        }


        vm.openLeftMenu = function (selectTab) {
            vm.leftContent = "menu";
            $mdSidenav('left').toggle();
            if (!isNaN(selectTab)) {
                vm.searchName = '';
                vm.leftMenuTabIndex = selectTab;
            }
        };

        vm.loadSearchBlob = function (searchId) {
            SaveSearch.findOne({"filter": {"where": {searchId: searchId}}}, function (searchDetails) {
                searchDetails.saveBlob = JSON.parse(searchDetails.saveBlob);
                $localStorage.pagination.filterLibrary = searchDetails.saveBlob.filterLibrary;
                if(searchDetails.saveBlob.searchBooksText){
                    $localStorage.pagination.filterLibrary.textSearch = searchDetails.saveBlob.searchBooksText;
                }
                vm.searchLibrary = true;
                vm.closeLeftMenu();
            });
        }

        vm.deleteSearchSave = function (saveSearchId, saveSearchName, ev) {
            var confirm = $mdDialog.confirm()
                .title('Delete this search?')
                .textContent('This deletion is permanent. Are you sure you want to delete "' + saveSearchName + '"?')
                .ariaLabel('Delete search ID:' + saveSearchId)
                .targetEvent(ev)
                .ok('Delete')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function () {
                SaveSearch.upsert({searchId: saveSearchId, deleted: 1, dateDeleted: Date.now()}, function () {
                    SaveSearch.find({
                        "filter": {
                            "where": {
                                userId: $scope.$storage.loggedInUser.id,
                                deleted: 0
                            }
                        }
                    }).$promise.then(function (saveSearch) {
                        vm.savedSearches = saveSearch;
                    });
                });
            }, function () {

            });

        }

        vm.closeLeftMenu = function () {
            $mdSidenav('left').close()
                .then(function () {
                    vm.leftContent = "";
                });
        };


        vm.openLeftComments = function (thisBook) {
            vm.leftContent = "comments";
            Wraps.commentsBook = thisBook;
            $mdSidenav('left').toggle();

            $mdSidenav('left').onClose(function () {
                vm.leftContent = "";
                //console.log('closing');
                $interval.cancel(Wraps.intervalLoadComments);
            });
        }

        vm.closeLeftComments = function () {
            $mdSidenav('left').close();
        };


        vm.reorderClose = function (parent) {
            vm.orderingId = false;
            //console.log("close");
        }


        vm.reorderOpen = function (item, parent, contentType) {
            //console.log(parent);
            vm.orderingId = item.structureId;
            var siblings = parent.Children;
            initParaDrag(siblings, parent.structureId, contentType);
            $scope.$storage.selectedCopy = 0;
            if (contentType == 1) {
                //TODO move this whole function into tabBook component and toggleStructure
                //vm.toggleSelected(false);
                var tabindex  = _.findIndex(vm.openTabs, function(o) { return _.toInteger(o.bookId) == _.toInteger(item.bookId) });
                vm.openTabs[tabindex].selected.level1 = [];
            }
        }


        vm.isReorderingChildren = function (parent) {
            //if(_.find(parent.Children, { 'structureId': vm.orderingId })){console.log(parent);}
            return _.find(parent.Children, {'structureId': vm.orderingId});
        }


        vm.reorderSave = function (thisBook, itemId, parent) {

            var bookId = thisBook.bookId
            var confirm = $mdDialog.confirm()
                .title('Re-Order Siblings')
                .htmlContent('Are you sure you want to save the order of the siblings?<br /><b>This action cannot be undone.</b><br />')
                .ariaLabel('Confirm Order')
                .ok('Save')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function () {
                var newOrder = [];
                var oldOrder = [];

                angular.forEach(parent.Children, function (value, key) {
                    oldOrder.push({structureId: value.structureId, order: value.order});
                    newOrder.push({structureId: value.structureId, order: parseInt(key) + 1});
                });

                oldOrder = _.sortBy(oldOrder, 'order');

                Structures.updateOrder({
                    "bookId": bookId,
                    "parentStructureId": itemId,
                    "orderOld": Wraps.toJson(oldOrder),
                    "orderNew": Wraps.toJson(newOrder),
                    "userId": $scope.$storage.user.id
                }).$promise.then(function (response) {
                    //console.log(response);
                });
                vm.reorderClose(parent);
            }, function () {
                WrapsBooks.updateBookById(bookId);
                //thisBook.updateBook();

            });


        }

        vm.ordAbcBookDialog = function (item, siblings) {
            var confirm = $mdDialog.confirm()
                .title('Sort Siblings Alphabetically')
                .htmlContent('Are you sure you want to sort these siblings alphabetically?<br /> <b>This action cannot be undone.</b><br />')
                .ariaLabel('Confirm Order')
                .ok('Sort')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    vm.ordAbc(item, siblings);
                }, function () {

                });
        }

        vm.ordAbc = function (item, siblings) {

            var contentTypeId = item.versions.Contents.contentTypeId;
            var newOrder = [];
            var oldOrder = [];

            if ((contentTypeId >= 5 && contentTypeId <= 8) || contentTypeId == 17 || contentTypeId == 19 || contentTypeId == 20) {

                if (contentTypeId === 5 || contentTypeId == 17) {
                    siblings.sort(function (a, b) {
                        if (a.versions.Contents.contentTypeId === contentTypeId && b.versions.Contents.contentTypeId === contentTypeId) {
                            return a.benefit.name.localeCompare(b.benefit.name);
                        }
                    });

                } else if (contentTypeId === 6) {
                    siblings.sort(function (a, b) {
                        if (a.versions.Contents.contentTypeId === contentTypeId && b.versions.Contents.contentTypeId === contentTypeId) {
                            return a.glossary.term.localeCompare(b.glossary.term);
                        }
                    });

                } else if (contentTypeId === 7 || contentTypeId === 20) {
                    siblings.sort(function (a, b) {
                        if (a.versions.Contents.contentTypeId == contentTypeId && b.versions.Contents.contentTypeId == contentTypeId) {
                            return a.exclusion.name.localeCompare(b.exclusion.name);
                        }
                    });

                } else if (contentTypeId === 8) {
                    siblings.sort(function (a, b) {
                        if (a.versions.Contents.contentTypeId == contentTypeId && b.versions.Contents.contentTypeId == contentTypeId) {
                            return a.medical.term.localeCompare(b.medical.term);
                        }
                    });
                } else if (contentTypeId === 19) {
                    siblings.sort(function (a, b) {
                        if (a.versions.Contents.contentTypeId == contentTypeId && b.versions.Contents.contentTypeId == contentTypeId) {
                            return a.versions.versiondata.localeCompare(b.versions.versiondata);
                        }
                    });
                }

                _.forEach(siblings, function (val, key) {
                    siblings[key].order = key;
                });

                _.forEach(siblings, function (val, key) {
                    oldOrder.push({structureId: val.structureId, order: val.order});
                    newOrder.push({structureId: val.structureId, order: key});

                });

                oldOrder = _.sortBy(oldOrder, 'order');

                Structures.updateOrder({
                        "bookId": item.bookId,
                        "parentStructureId": item.parent,
                        "orderOld": Wraps.toJson(oldOrder),
                        "orderNew": Wraps.toJson(newOrder),
                        "userId": $scope.$storage.user.id
                    },
                    function () {
                    },
                    function () {
                    }
                );
            }

        }

        vm.getIndex = function (b, c) {
            return b.Children.indexOf(c);

        }

        vm.moveDown = function (indexNo, parent) {

            var siblings = parent.Children;
            var temp = siblings[indexNo];
            var newOrder = [];
            var oldOrder = [];

            siblings[indexNo] = siblings[indexNo + 1];
            siblings[indexNo + 1] = temp;

            _.forEach(siblings, function (val, key) {
                oldOrder.push({structureId: val.structureId, order: val.order});
                newOrder.push({structureId: val.structureId, order: key});
            });

            oldOrder = _.sortBy(oldOrder, 'order');

            Structures.updateOrder({
                    "bookId": parent.bookId,
                    "parentStructureId": parent.structureId,
                    "orderOld": Wraps.toJson(oldOrder),
                    "orderNew": Wraps.toJson(newOrder),
                    "userId": $scope.$storage.user.id
                },
                function () {
                },
                function () {
                }
            );
        }

        vm.moveUp = function (indexNo, parent) {

            var siblings = parent.Children;
            var temp = siblings[indexNo];
            var newOrder = [];
            var oldOrder = [];

            siblings[indexNo] = siblings[indexNo - 1];
            siblings[indexNo - 1] = temp;

            _.forEach(siblings, function (val, key) {
                oldOrder.push({structureId: val.structureId, order: val.order});
                newOrder.push({structureId: val.structureId, order: key});
            });

            oldOrder = _.sortBy(oldOrder, 'order');

            Structures.updateOrder({
                    "bookId": parent.bookId,
                    "parentStructureId": parent.structureId,
                    "orderOld": Wraps.toJson(oldOrder),
                    "orderNew": Wraps.toJson(newOrder),
                    "userId": $scope.$storage.user.id
                },
                function () {
                },
                function () {
                }
            );

        }

        vm.textDiff = function (left, right, switchDiff) {
            if (switchDiff) {
                right
                    = String(right).replace(/\n/g, '').replace(/<p>/g, '').replace(/<\/li>/g, '\n').replace(/<\/p>/g, '\n').replace(/<li>/g, '• ').replace(/<[^>]+>/gm, '');
                if (!left) {
                    left = angular.copy(right);
                } else {
                    left = String(left).replace(/\n/g, '').replace(/<p>/g, '').replace(/<\/li>/g, '\n').replace(/<\/p>/g, '\n').replace(/<li>/g, '• ').replace(/<[^>]+>/gm, '');
                }

                var dmp = new diff_match_patch();
                var diffs = dmp.diff_main(left, right);
                dmp.diff_cleanupSemantic(diffs);
                var html = dmp.diff_prettyHtml(diffs).replace(/\n/g, '<br>').replace(/&amp;nbsp;/g, ' ');
                //console.log(html);
                return html;
            } else {
                return right;
            }
        }


        // vm.hasContentType = function (parent, type) {
        //     if (parent.Children) {
        //         for (var i = 0; i < parent.Children.length; i++) {
        //             if (parent.Children[i].versions.Contents.contentTypeId === type) {
        //                 return true;
        //                 break;
        //             }
        //         }
        //     }
        //
        // }


        // temp so library-doc.tpl.html can access
        vm.hasContentType = WrapsBooks.hasContentType;


        vm.openEditCopy = function (item, book) {

            vm.tempData = angular.copy(item);

            $mdBottomSheet.show({
                templateUrl: 'library/bottomsheet/bottomsheet.tpl.html',
                controller: 'BottomsheetCtrl',
                controllerAs: 'bottomsheet',
                disableParentScroll: false,
                clickOutsideToClose: false,
                parent: angular.element(document.body),
                locals: {item: item, book: book}
            }).then(function (clickedItem) {
                //vm.updateBook(book);
                WrapsBooks.updateBookById(book.bookId);
                //book.updateBook();
            }, function (reason) {
                //alert('cancel');
                item.versions.versiondata = vm.tempData.versions.versiondata;
                if (item.glossary) {
                    item.glossary = vm.tempData.glossary;
                }
                if (item.benefit) {
                    item.benefit = vm.tempData.benefit;
                }
                if (item.brief) {
                    item.brief = vm.tempData.brief;
                }
                if (item.artwork) {
                    item.artwork = vm.tempData.artwork;
                }
            });
        };


        vm.openChapterDialog = function (book) {
            $mdDialog.show({
                controller: 'ChaptersdialogCtrl',
                controllerAs: 'chaptersdialog',
                templateUrl: 'library/chaptersdialog/chaptersdialog.tpl.html',
                parent: angular.element(document.body),
                locals: {book: book},
                bindToController: true,
                clickOutsideToClose: true,

                skipHide: true,
            })
                .then(function (data) {
                    //console.log("chapt close:",data);
                    if (data) {
                        //vm.updateBook(book);
                        WrapsBooks.updateBookById(book.bookId);
                        //book.updateBook();
                    }
                }, function () {
                    //vm.status = 'You cancelled the dialog.';

                });
        };


        vm.addbriefDialog = function (book) {
            $mdDialog.show({
                controller: 'AddbriefCtrl',
                controllerAs: 'addbrief',
                templateUrl: 'library/addbrief/addbrief.tpl.html',
                parent: angular.element(document.body),
                locals: {book: book},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {
                    if (data) {
                        //vm.updateBook(book);
                        console.log("updated from add brief dialog");
                        $scope.$session.selectedBrief = [book.bookId]; //makes sure section is selected/open
                        WrapsBooks.updateBookById(book.bookId);
                        //book.updateBook();
                    }
                }, function () {
                    //vm.status = 'You cancelled the dialog.';
                });
        };

        vm.addartworkDialog = function (book) {
            $mdDialog.show({
                controller: 'AddartworkCtrl',
                controllerAs: 'addartwork',
                templateUrl: 'library/addartwork/addartwork.tpl.html',
                parent: angular.element(document.body),
                locals: {book: book, type: "artwork"},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {
                    if (data) {
                        //vm.updateBook(book);
                        $scope.$session.selectedArtwork = [book.bookId]; //makes sure section is selected/open
                        WrapsBooks.updateBookById(book.bookId);
                        //book.updateBook();
                    }
                }, function () {
                    //vm.status = 'You cancelled the dialog.';
                });
        };

        vm.addFinalDialog = function (book) {
            $mdDialog.show({
                controller: 'AddartworkCtrl',
                controllerAs: 'addartwork',
                templateUrl: 'library/addartwork/addartwork.tpl.html',
                parent: angular.element(document.body),
                locals: {book: book, type: "final"},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {
                    if (data) {
                        //vm.updateBook(book);
                        $scope.$session.selectedFinal = [book.bookId]; //makes sure section is selected/open
                        WrapsBooks.updateBookById(book.bookId);
                        //book.updateBook();
                    }
                }, function () {
                    //vm.status = 'You cancelled the dialog.';
                });
        };


        vm.openDocDiffDialog = function (change, child) {
            $mdDialog.show({
                controller: 'DocdiffdialogCtrl',
                controllerAs: 'docdiffdialog',
                templateUrl: 'library/docdiffdialog/docdiffdialog.tpl.html',
                parent: angular.element(document.body),
                locals: {change: change, child: child},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {

                }, function () {
                    vm.status = 'You cancelled the dialog.';
                });
        };

        vm.openMetaChangeDialog = function (change) {
            $mdDialog.show({
                controller: 'MetachangedialogCtrl',
                controllerAs: 'metachangedialog',
                templateUrl: 'library/metachangedialog/metachangedialog.tpl.html',
                parent: angular.element(document.body),
                locals: {change: change},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {

                }, function () {
                    vm.status = 'You cancelled the dialog.';
                });
        };

        vm.openEmailDetailsDialog = function (change) {
            $mdDialog.show({
                controller: 'EmaildetailsdialogCtrl',
                controllerAs: 'emaildetailsdialog',
                templateUrl: 'library/emaildetailsdialog/emaildetailsdialog.tpl.html',
                parent: angular.element(document.body),
                locals: {change: change},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {

                }, function () {
                    vm.status = 'You cancelled the dialog.';
                });
        };


        vm.openWorkflowDialog = function (child, book, type) {
            $mdDialog.show({
                controller: 'WorkflowdialogCtrl',
                controllerAs: 'workflowdialog',
                templateUrl: 'library/workflowdialog/workflowdialog.tpl.html',
                parent: angular.element(document.body),
                locals: {child: child, book: book, type: type},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {
                    //vm.updateBook(book);
                    WrapsBooks.updateBookById(book.bookId);
                    //book.updateBook();
                }, function () {
                    //vm.status = 'You cancelled the dialog.';
                });
        };


//filter for the content repeat
        vm.lessThan6 = function (item) {
            return item.versions.Contents.contentTypeId < 6 ? true : false;
        };


        function deleteRecursive(Structs, eventData) {
            Structures.trash({"structureId": Structs.structureId}, function () {
            });
            History.create({
                "structureId": Structs.structureId,
                "versionIdOld": Structs.versionId,
                "versionIdNew": 0,
                "historyId": 0
            }, function (historyResponse) {
                Events.create({
                    "eventId": 0,
                    "bookId": Structs.bookId,
                    "structureId": Structs.structureId,
                    "contentId": Structs.versions.contentId,
                    "historyId": historyResponse.historyId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Delete",
                    "eventData": Wraps.toJson(eventData)
                });
            });
            angular.forEach(Structs.Children, function (Struct) {
                deleteRecursive(Struct);
            });

        }

        vm.deleteContentDialog = function (child, parent) {
            var confirm = $mdDialog.confirm()
                .title('Delete content')
                .htmlContent('Are you sure you want to delete this content and all it\'s children?<br /> <b>This action cannot be undone.</b>')
                .ariaLabel('Confirm delete')
                .ok('Delete')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {

                    vm.openChangeDialog().then(function (eventData) {
                        if (eventData) {


                            //console.log(eventData); //TODO send data to evetns and show different list

                            _.remove(parent, function (n) {
                                return child === n
                            });
                            deleteRecursive(child, eventData);
                            Books.upsert({"bookId": child.bookId}).$promise.then(function (results) {
                                vm.setBookStatuses(vm.bookById(child.bookId));
                                //book.updateBook();
                                WrapsBooks.updateBookById(child.bookId);

                            });
                        }
                    });
                }, function () {

                });
        }


        vm.getListChildren = function (book, field) {
            //console.log(book);
            var list = [];
            _.forEach(book.Children, function (val) {
                list.push(val[field]);
                _.forEach(val.Children, function (val2) {
                    list.push(val2[field]);
                    _.forEach(val2.Children, function (val3) {
                        list.push(val3[field]);
                        _.forEach(val3.Children, function (val4) {
                            list.push(val4[field]);
                        });
                    });
                });
            });

            //console.log(list);
            //console.log(_.uniq(list));
            return _.uniq(list);

        }

        vm.approveContentDialog = function (child, book, needFile) {
            $mdDialog.show({
                controller: 'ApprovedialogCtrl',
                controllerAs: 'approvedialog',
                templateUrl: 'library/approvedialog/approvedialog.tpl.html',
                locals: {child: child, book: book, needFile: needFile},
                bindToController: true,
                clickOutsideToClose: false
            })
                .then(function (data) {
                    // Books.upsert({"bookId": book.bookId, "lastUpdated":  moment(new Date())}).$promise.then(function (results) {
                    //   console.log("results",results);
                    //   book.update();
                    // });
                }, function () {

                });
        };


        vm.showApproveAll = function (thisBook) {
            if (thisBook.documentStatus.substring(0, 4) != 'EXPD' && thisBook.approvalList[thisBook.approvalStatus] && thisBook.approvalList[thisBook.approvalStatus].role != 2 && thisBook.DocTypes && thisBook.DocTypes.docTypeTemplate == 1) {
                var bookStatuses = Wraps.getListChildrenNew(thisBook, ['approvalStatus', 'status']);
                var userIndex = _.indexOf(thisBook.userInBook, thisBook.approvalStatus);
                var userInChildrenIndex = _.findIndex(bookStatuses, function (o) {
                    return o.approvalStatus == thisBook.userInBook[userIndex] && o.status == 0;
                })

                if (userIndex >= 0 && thisBook.status === 0 && userInChildrenIndex >= 0) {
                    return true;
                } else {
                    return false;
                }
            }
        }


        vm.showWithdraw = function (thisBook) {
            var now = new Date();
            if (
                thisBook.withdrawDate === null &&
                (thisBook.bookOwner === $scope.$storage.user.id || vm.isMCO(thisBook)) &&
                ((thisBook.approvalList[thisBook.approvalStatus] && thisBook.approvalList[thisBook.approvalStatus].content == 'final') || thisBook.status == 1) &&
                (thisBook.documentStatus == "EXPD" || thisBook.documentStatus == "EXPD(F)" )
            ) {
                return true;
            } else {
                return false;
            }
        }

        vm.withdraw = function (thisBook) {
            $mdDialog.show({
                clickOutsideToClose: true,
                //scope: $scope,        // use parent scope in template
                preserveScope: true,  // do not forget this if use parent scope
                parent: angular.element(document.body),
                locals: {dialogBook: thisBook},
                template: '<md-dialog>' +
                    '<md-toolbar>' +
                '<div class="md-toolbar-tools">' +
                        '  <h2>Withdraw</h2>' +
                    '   <span flex></span>' +
                    '   <md-button ng-click="closeDialog(false)" class="md-icon-button">' +
                    '     <ng-md-icon  icon="close" aria-label="Close dialog"></ng-md-icon>' +
                    '   </md-button>' +
                    '  </div>' +
                    '  </md-toolbar>' +
                    '  <md-dialog-content style="max-width: 800px;">' +
                    '   <div class="md-padding" >' +
                    '     <p>This document has now expired, and the expiration is recorded and reportable.  ' +
                    'Non-compliant customer-facing documentation can lead to serious consequences for ' +
                    'our business and as such can be very high profile.  It is imperative that every ' +
                    'effort is made to ensure that all available customer-facing documentation is compliant.</p>' +


                    '     <p><strong>Items:</strong> If this document is found to still be in use after the recorded expiration ' +
                    'date, a risk event may need to be raised and reported.  By ticking the ‘withdrawn’' +
                    ' box, I am confirming that the document is no longer in use and I have removed it ' +
                    'from wherever it is published (on and offline), remaining stocks destroyed, ' +
                    'advised any agencies to destroy/delete and removed from the P/G Drive etc.</p>' +

                    '     <p><strong>Membership Guides:</strong> This Membership Guide has expired and must not be used for ' +
                    'new sales.  Customers will continue to use this Membership Guide to access the ' +
                    'cover from their health plan until their next renewal.  If this Membership Guide ' +
                    'is found to still be in use for news sales, a risk event may need to be raised ' +
                    'and reported.  By ticking the ‘withdrawn’ box, I am confirming that this ' +
                    'Membership Guide is no longer available for new sales.  It has been replaced ' +
                    'wherever it is published, on and off line.</p>' +


                    '     <md-input-container flex="33">' +
                    '       <md-checkbox class="" ng-model="withdrawAgree" aria-label="Withdraw" style="margin: 0px;">' +
                    '         Withdrawn' +
                    '       </md-checkbox>' +
                    '     </md-input-container> ' +

                    '   </div>' +
                    '  </md-dialog-content>' +

                    '<md-dialog-actions layout="row">' +
                    ' <span flex></span>' +

                    '   <md-button class="md-grey" ng-click="closeDialog(false)">Cancel<ng-md-icon icon="close" style="fill: white; padding-left: 4px; " size="20"></ng-md-icon></md-button>' +
                    '   <md-button ng-disabled="!withdrawAgree" class="md-green"  ng-click="saveDialog()">Approve<ng-md-icon icon="done" style="fill: white; padding-left: 4px; " size="20"></ng-md-icon></md-button>' +

                    '  </md-dialog-actions>' +
                    '</md-dialog>',
                controller: function withdrawController($scope, $localStorage, $mdDialog, dialogBook) {

                    $scope.$storage = $localStorage;

                    $scope.saveDialog = function () {
                        Books.upsert({
                            "bookId": dialogBook.bookId,
                            "withdrawDate": moment.utc().format(Wraps.databaseDateFormat)
                        }).$promise.then(function (results) {
                            Events.create({
                                "eventId": 0,
                                "bookId": dialogBook.bookId,
                                "userId": $scope.$storage.user.id,
                                "eventType": "Withdraw",
                                "eventData": "Book Withdrawn"
                            });

                            Notifications.getCounts();
                            WrapsBooks.updateBookById(dialogBook.bookId);
                            //dialogBook.updateBook();
                        });
                        $mdDialog.hide();
                    }
                    $scope.closeDialog = function () {
                        $mdDialog.hide();
                    }
                }
            });

        }

        vm.showDLORequestDelete = function (thisBook) {

            if ($scope.$storage.user.id === thisBook.bookOwner && thisBook.requestDelete != 1) {
                return true;
            } else {
                return false;
            }
        }

        vm.requestDelete = function (bookOrContent, bookId, contentId, versionId) {
            $mdDialog.show({
                clickOutsideToClose: true,
                //scope: $scope,        // use parent scope in template
                preserveScope: true,  // do not forget this if use parent scope
                parent: angular.element(document.body),
                locals: {dialogBook: bookId},
                template: '<md-dialog>' +
                    '  <md-dialog-content style="overflow: hidden;">' +

                    '    <md-toolbar>' +
                    '<div class="md-toolbar-tools">' +
                    '  <h4>Request deletion</h4>' +
                    '   <span flex></span>' +
                    '   <md-button ng-click="closeDialog(false)" class="md-icon-button" style="position: relative;  left:20px;">' +
                    '     <ng-md-icon  icon="close" style="fill: white; position: relative; top: 4px; right:10px;" size="20"  aria-label="Close dialog"></ng-md-icon>' +
                    '   </md-button>' +
                    '  </div>' +
                    '  </md-toolbar>' +
                    '   <div style="padding: 20px;"  >' +
                    '     <p>If you feel this document should be deleted. Please complete the form below and the DLO will be notified.</p>' +

                    '     <md-input-container flex="66">' +
                    '       <label>Reason for deletion</label>' +
                    '       <textarea class="" ng-model="requestDeletion" aria-label="Request Deletion" style="margin: 0px;"></textarea>' +
                    '     </md-input-container> ' +
                    '   </md-dialog-content>' +

                    '<md-dialog-actions layout="row">' +
                    ' <span flex></span>' +

                    '   <md-button class="md-grey"  ng-click="closeDialog(false)">Cancel<ng-md-icon icon="close" style="fill: white; position: relative; top:4px; padding-left: 4px; " size="20"></ng-md-icon></md-button>' +
                    '   <md-button ng-disabled="!requestDeletion" class="md-green"  ng-click="saveDialog()">Request Deletion<ng-md-icon icon="delete_forever" style="fill: white; position: relative; top:4px; padding-left: 4px; " size="20"></ng-md-icon></md-button>' +

                    '  </md-dialog-actions>' +
                    '</md-dialog>',
                controller: function requestDeletionController($scope, $localStorage, $mdDialog, dialogBook) {
                    $scope.$storage = $localStorage;
                    $scope.saveDialog = function () {

                        var args = {
                            userId: $scope.$storage.user.id,
                            bookId: bookId,
                            reason: $scope.requestDeletion
                        };

                        Books.requestDeletion(args).$promise.then(
                            function (results) {
                                //console.log(results);
                                if (results) {

                                    Wraps.toastMessages = {message: "Email Sent to: " + results.to};
                                }
                                vm.updateBooks();
                                $mdDialog.hide();


                            }, function (error) {
                                // error
                                console.log("Error:", error);

                                if (error.data) {
                                    error.data.error.timestamp = new Date();
                                    angular.extend(Wraps.httpErrors, error.data.error);
                                }


                                $mdDialog.hide();
                            });

                    };
                    $scope.closeDialog = function () {
                        $mdDialog.hide();
                    }
                }
            });

        }


        vm.showDLODeleteBook = function (thisBook) {
            if (thisBook.requestDelete === 1 && $scope.$storage.user.roleId === 1) {
                return true;
            }
            return false;
        };

        vm.DLODelete = function (thisBook) {
            var confirm = $mdDialog.confirm()
                .title('Delete document')
                .htmlContent('Are you sure you want to delete this document?<br /> <b>This action cannot be undone.</b>')
                .ariaLabel('Confirm delete')
                .ok('Delete')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    Books.trash({
                        bookId: thisBook.bookId,
                        clientId: thisBook.clientId,
                        reason: 'DLODelete',
                        userId: $localStorage.loggedInUser.id
                    }, function (results) {
                        if (results) {
                            Wraps.toastMessages = {message: "Email Sent to: " + results.to};
                        }
                        //$scope.$storage.selectedBook = 0;
                        vm.updateBooks();
                    });
                });
        };


        vm.contentRequestDeleteShow = function (requestDelete) {
            if (requestDelete !== 1) {
                return true;
            } else {
                return false;
            }
        }

        vm.contentDLODeleteShow = function (DLODelete, book) {
            if (DLODelete === 1 && book.requestDeleteDLO === $localStorage.loggedInUser.id) {
                return true;
            } else {
                return false;
            }
        };


        vm.DLOContentDelete = function (bookOrContent, bookId, contentId, versionId) {
            var confirm = $mdDialog.confirm()
                .title('Delete document')
                .htmlContent('Are you sure you want to delete this?<br /> <b>This action cannot be undone.</b>')
                .ariaLabel('Confirm delete')
                .ok('Delete')
                .cancel('Cancel');


            $mdDialog.show(confirm)
                .then(function () {
                    Structures.trash({
                        versionId: versionId,
                        contentId: contentId,
                        bookId: bookId,
                        userId: $localStorage.loggedInUser.id
                    });

                    //vm.updateBooks();
                    WrapsBooks.updateBookById(bookId);
                    //book.updateBook();
                });
        };


        vm.pushContentDialog = function (activeContent) {
            $mdDialog.show({
                controller: 'PushdialogCtrl',
                controllerAs: 'pushdialog',
                templateUrl: 'library/pushdialog/pushdialog.tpl.html',
                parent: angular.element(document.body),
                locals: {activeContent: activeContent},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {
                    if (data) {
                        var book = vm.bookById(activeContent.bookId);
                        WrapsBooks.updateBookById(book.bookId);
                        //book.updateBook();
                    }
                }, function () {
                    //vm.status = 'You cancelled the dialog.';
                });
        };


        vm.insertContentDialog = function (child, book) {
            $mdDialog.show({
                controller: 'ContentdialogCtrl',
                controllerAs: 'contentdialog',
                templateUrl: 'library/contentdialog/contentdialog.tpl.html',
                parent: angular.element(document.body),
                locals: {child: child, book: book},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {
                    console.log('update', data);
                    if (data) {
                        //vm.updateBook(vm.bookById(child.bookId));
                        WrapsBooks.updateBookById(book.bookId);
                        //book.updateBook();

                    }
                }, function () {
                    //vm.status = 'You cancelled the dialog.';
                });
        };






        vm.editAccess = function (copy, book) {


            //TODO brief/artwork
            if (book.status === 0) {
                var now = new Date();
                if ((book.earlyExpiryDate && now >= new Date(book.earlyExpiryDate)) || now >= new Date(book.expiryDate)) {
                    return false;
                }

                if (_.indexOf(book.userInBook, copy.approvalStatus) >= 0 && book.approvalList[copy.approvalStatus]) {
                    if (book.approvalList[copy.approvalStatus].role === 1 || book.approvalList[copy.approvalStatus].role === 2 && (!copy.hasArtwork) && copy.status == 0) {
                        return true;
                    } else if (book.approvalList[copy.approvalStatus].role === 6 && (copy.hasArtwork || copy.hasFinal) && (book.approvalStatus >= copy.approvalStatus) && copy.status == 0) {
                        return true;
                    } else if ($scope.$storage.loggedInUser.roleId == 6 && vm.isMCO(book) && copy.chapterTypeId === 1) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }

        }

        vm.diffAccess = function (book) {
            //TODO needs some work still
            return false;
        }

        vm.insertAccess = function (copy, book) {

            if (book.status === 0 && book.documentStatus.substring(0, 4) !== 'EXPD') {
                if (_.indexOf(book.userInBook, copy.approvalStatus) >= 0) {
                    if (book.approvalList[copy.approvalStatus] && book.approvalList[copy.approvalStatus].role === 1 || book.approvalList[copy.approvalStatus].role === 2) {

                        if (book.bookId === 89) {
                            console.log('t1');
                        }
                        return true;
                    } else {
                        if (book.bookId === 89) {
                            console.log('f1');
                        }
                        return false;
                    }
                } else {
                    if (book.bookId === 89) {
                        console.log('book.userInBook', book.userInBook);
                    }
                    return false;
                }
            }
        }

        vm.insertAccessChildren = function (book) {
            var rtrn = [];
            rtrn.push(vm.insertAccess(book, book));
            _.forEach(book.Children, function (val) {
                rtrn.push(vm.insertAccess(val, book));
                _.forEach(val.Children, function (val2) {
                    rtrn.push(vm.insertAccess(val2, book));
                    _.forEach(val2.Children, function (val3) {
                        rtrn.push(vm.insertAccess(val3, book));
                        _.forEach(val3.Children, function (val4) {
                            rtrn.push(vm.insertAccess(val4, book));
                        });
                    });
                });
            });
            if (rtrn.indexOf(true) >= 0) {
                return true;
            }
        }



        vm.deleteAccess = function (copy, book) {


            if (book.status === 0 && book.DocTypes && book.DocTypes.docTypeTemplate === 1) {
                if (_.indexOf(book.userInBook, copy.approvalStatus) >= 0 && book.approvalList[copy.approvalStatus]) {

                    if (book.approvalList[copy.approvalStatus].role === 1 || book.approvalList[copy.approvalStatus].role === 2 || (book.approvalList[copy.approvalStatus].role === 6 && book.approvalStatus >= copy.approvalStatus)) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }


        }


        vm.moveAccess = function (copy, book) {

            //TODO might need to set it so that an MCO can move other content while they are editing single one
            if (book.status === 0) {
                if (book.approvalList[copy.approvalStatus] && _.indexOf(book.userInBook, copy.approvalStatus) >= 0) {
                    if (book.approvalList[copy.approvalStatus].role === 1 || book.approvalList[copy.approvalStatus].role === 2) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
        }

        vm.menuAccess = function (copy, book) {
            return true;
        }


        vm.isOwner = function (thisBook) {
            if (thisBook.bookOwner === $scope.$storage.user.id) {
                return true;
            } else {
                return false;
            }
        }


        vm.isMCO = function (thisBook) {
            //console.log("xx", _.indexOf(thisBook.userInBook, thisBook.approvalStatus),thisBook.userInBook);


            if (($scope.$storage.user.roleId === 2 && _.indexOf(thisBook.userInBook, thisBook.approvalStatus) >= 0) || $scope.$storage.user.roleId === 5) {
                return true;
            } else {
                return false;
            }
        }

        vm.isDLO = function (book) {
            var isDLOinBook = (_.findIndex(book.approvalList, function(o) { return o.role === 1 && (o.user == $scope.$storage.user.id || (o.team === $scope.$storage.user.team && !o.user)); }) >=0);
            //console.log('isDLOinBook', isDLOinBook , $scope.$storage.user);
            if ( isDLOinBook || $scope.$storage.user.roleId === 5 ) {
                return true;
            } else {
                return false;
            }
        }


        vm.viewAction = function (copy, book) {
            var now = moment().format('YYYY-MM-DD');
            if (copy.status === 0 && ((book.earlyExpiryDate == null || book.earlyExpiryDate) > now || book.expiryDate > now)) {

                //console.log(book.bookId, book.earlyExpiryDate  == null);
                if (_.indexOf(book.userInBook, copy.approvalStatus) >= 0) {
                    //if(book.approvalList[copy.approvalStatus].role >= 3 || (book.approvalList[copy.approvalStatus].role === 2 && book.approvalList[book.approvalStatus].role > 2)){
                    if (book.approvalList[copy.approvalStatus] && (
                        ((book.approvalList[copy.approvalStatus].role == 3 || book.approvalList[copy.approvalStatus].role == 8) && book.bookOwner != $scope.$storage.user.id)
                        || book.approvalList[copy.approvalStatus].role == 9
                        || book.approvalList[copy.approvalStatus].role == 10
                        || (book.approvalList[copy.approvalStatus].role === 2 && book.approvalList[book.approvalStatus].role > 2)
                    )) {

                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
            return false;


        }

        vm.viewActionChildren = function (copy, book) {
            var rtrn = [];
            if (copy.parent === null || !angular.isUndefined(copy.bookName) || copy.Children) {
                _.forEach(copy.Children, function (val) {
                    rtrn.push(vm.viewAction(val, book));
                    _.forEach(val.Children, function (val2) {
                        rtrn.push(vm.viewAction(val2, book));
                        _.forEach(val2.Children, function (val3) {
                            rtrn.push(vm.viewAction(val3, book));
                            _.forEach(val3.Children, function (val4) {
                                rtrn.push(vm.viewAction(val4, book));
                            });
                        });
                    });
                });
                if (rtrn.indexOf(true) >= 0) {
                    return true;
                }
            }

        }


        vm.viewActionBook = function (bookId) {

        }


        //--- ROLES
        // 1	DLO
        // 2	MCO
        // 3	Approver
        // 4	CO
        // 5	Admin
        // 6	Designer
        // 7	Digital
        // 8	proofreader
        // 9	Partner
        // 10	Client


        vm.viewReject = function (copy, book) {
            if (book.status === 0 &&
                copy &&
                book.documentStatus.substring(0, 4) != 'EXPD'
            ) {
                //TODO setup CO logic
                switch (copy.versions.Contents.ContentTypes.contentTypeGroup) {
                    case 'brief':
                        var arrayMCO = _.find(book.approvalList, function(o) { return o.role === 2 && o.user > 0 && o.content === 'brief';}) || {user: 0 };
                        // artwork is on design and brief is status 1
                        var artworkStats = Wraps.getListChildren(book, 'approvalStatus', 'artwork');
                        if (
                            _.indexOf(book.userInBook, copy.approvalStatus + 1) >= 0 &&
                            book.approvalList[book.approvalStatus] &&
                            book.approvalList[book.approvalStatus].content === 'artwork' &&
                            book.approvalList[book.approvalStatus].role == 6 &&
                            copy.status === 1 // Design
                        ) {
                            return true;
                        }
                        else if (
                            _.indexOf(book.userInBook, copy.approvalStatus) >= 0 &&
                            book.approvalList[copy.approvalStatus] &&
                            (
                                book.approvalList[copy.approvalStatus].role == 9 ||
                                book.approvalList[copy.approvalStatus].role == 10 ||
                                (
                                    (book.approvalList[copy.approvalStatus].role == 3 || book.approvalList[copy.approvalStatus].role == 8) &&
                                    book.bookOwner != $scope.$storage.user.id &&
                                    arrayMCO.user != $scope.$storage.user.id
                                )
                            ) &&
                            book.approvalStatus >= copy.approvalStatus &&
                            copy.status === 0 // Approver
                        ) {
                            return true;
                        }
                        else {
                            return false;
                        }
                        break;
                    case 'artwork':
                        var arrayMCO = _.find(book.approvalList, function(o) { return o.role === 2 && o.user > 0 && o.content === 'artwork';}) || {user: 0 };
                        if (
                            _.indexOf(book.userInBook, copy.approvalStatus) >= 0 &&
                            book.approvalList[copy.approvalStatus] &&
                            book.approvalList[copy.approvalStatus].role == 2 &&
                            book.approvalStatus >= copy.approvalStatus &&
                            copy.status === 0 // MCO
                        ) {
                            return true;
                        }
                        else if (
                            _.indexOf(book.userInBook, copy.approvalStatus) >= 0 &&
                            book.approvalList[copy.approvalStatus] &&
                            (
                                book.approvalList[copy.approvalStatus].role == 9 ||
                                book.approvalList[copy.approvalStatus].role == 10 ||
                                (
                                    (book.approvalList[copy.approvalStatus].role == 3 || book.approvalList[copy.approvalStatus].role == 8) &&
                                    book.bookOwner != $scope.$storage.user.id &&
                                    arrayMCO.user != $scope.$storage.user.id
                                ) // Approver
                            ) &&
                            book.approvalStatus >= copy.approvalStatus &&
                            copy.status === 0
                        ) {
                            return true;
                        }
                        else {
                            return false;
                        }
                        break;
                    case 'doc':
                        var arrayMCO = _.find(book.approvalList, function(o) { return o.role === 2 && o.user > 0 && o.content === 'doc';}) || {user: 0 };
                        if (
                            _.indexOf(book.userInBook, copy.approvalStatus) >= 0 &&
                            book.approvalList[copy.approvalStatus] &&
                            (
                                book.approvalList[copy.approvalStatus].role == 9 ||
                                book.approvalList[copy.approvalStatus].role == 10 ||
                                (
                                    (book.approvalList[copy.approvalStatus].role == 3 || book.approvalList[copy.approvalStatus].role == 8) &&
                                    book.bookOwner != $scope.$storage.user.id &&
                                    arrayMCO.user != $scope.$storage.user.id
                                )
                            ) &&
                            book.approvalStatus >= copy.approvalStatus &&
                            copy.status === 0 // Approver
                        ) {
                            return true;
                        }
                        else {
                            return false;
                        }
                        break;
                    case 'final':
                        if (
                            _.indexOf(book.userInBook, copy.approvalStatus) >= 0 &&
                            book.approvalList[copy.approvalStatus] &&
                            book.approvalList[copy.approvalStatus].role == 11 &&
                            book.DocTypes &&
                            book.DocTypes.docTypeTemplate != 1 // Approver
                        ) {
                            return true;
                        }
                        else {
                            return false;
                        }
                        break;
                    default:
                        return false;
                }
            } else {
                return false;
            }
        };


        vm.viewResetToBrief = function (book) { // to set brief back to MCO1 and artwork back to designer
            if (
                book.documentStatus &&
                book.status === 0 &&
                book.approvalList[book.approvalStatus] &&
                book.approvalList[book.approvalStatus].content != 'final' && // book is not in finals
                book.documentStatus.substring(0, 4) != 'EXPD' && // book is not expired
                book.hasBrief &&
                (book.approvalList[book.approvalStatus].role != 2 || book.approvalList[book.approvalStatus].content != 'brief') && // not already at first brief
                book.userInBook &&
                book.approvalList[book.userInBook[0]] &&
                book.approvalList[book.userInBook[0]].role === 2 &&
                book.approvalList[book.userInBook[0]].content === 'brief' // user is mco of brief in book
            ) {
                return true;
            }
        };

        vm.viewResetToMCO = function (book) { // to set doc back to MCO
            if (
                book.documentStatus &&
                book.status === 0 &&
                book.approvalList[book.approvalStatus] &&
                book.approvalList[book.approvalStatus].content != 'final' && // not in finals
                book.documentStatus.substring(0, 4) != 'EXPD' && // not expired
                typeof book.approvalList[book.approvalStatus] != 'undefined' &&
                book.approvalList[book.approvalStatus].role > 2 &&
                book.approvalList[book.approvalStatus].content === 'doc' && // not already at first brief
                book.userInBook && book.approvalList[book.userInBook[0]] &&
                book.approvalList[book.userInBook[0]].role === 2
            ) {
                return true;
            }
        };

        vm.viewEarlyExpire = function (book) { // Force / Early Expire Doc
            if (
                book.bookOwner == $scope.$storage.user.id && // is owner
                book.earlyExpiryDate == null &&
                book.documentStatus.substring(0, 4) != 'EXPD' // not already expired
            ) {
                return true;
            }

        };

        vm.viewMakeBookLive = function (book) { // to make book live
            var bookStatus = Wraps.getListChildren(book, 'status');
            if (
                book.status === 0 &&
                bookStatus.length === 1 &&
                bookStatus[0] === 1 &&
                book.documentStatus.substring(0, 4) != 'EXPD' && // not expired
                book.approvalList.length === (book.approvalStatus + 1) // last status before live
            ) {
                if (
                    _.find(book.userInBook, function (val) {
                        return book.approvalList[val].role == 2
                    })
                ) {
                    return true;
                } else if (_.indexOf(book.userInBook, book.approvalStatus) >= 0) { // in workflow and has action
                    return true;
                }
            }
        };

        /*vm.allowedDocTypeEdit = function (thisBook) {
            //for ng-disable so true = disabled;
            if ($scope.$storage.user.id == 49 || !thisBook.DocTypes) {
                return false;
            } else if (!thisBook.bookId) {
                return false;
            } else {
                return true;
            }


        }*/


        vm.viewApprove = function (copy, book) {
            if (
                copy &&
                copy.status === 0 &&
                book.status === 0 &&
                _.indexOf(book.userInBook, copy.approvalStatus) >= 0 &&
                book.approvalStatus >= copy.approvalStatus &&
                book.documentStatus.substring(0, 4) != 'EXPD'
            ) {
                //TODO setup CO logic
                switch(copy.versions.Contents.ContentTypes.contentTypeGroup) {
                    case 'brief':
                        var arrayMCO = _.find(book.approvalList, function(o) { return o.role === 2 && o.user > 0 && o.content === 'brief';}) || {user: 0 };
                        if (
                            book.approvalList[copy.approvalStatus] &&
                            (
                                book.approvalList[copy.approvalStatus].role == 2 ||
                                book.approvalList[copy.approvalStatus].role == 9 ||
                                book.approvalList[copy.approvalStatus].role == 10 ||
                                (
                                    (book.approvalList[copy.approvalStatus].role == 3 || book.approvalList[copy.approvalStatus].role == 8) &&
                                    book.bookOwner != $scope.$storage.user.id &&
                                    arrayMCO.user != $scope.$storage.user.id
                                )
                            )
                        ) {
                            return true;
                        }
                        else {
                            return false;
                        }
                        break;
                    case 'artwork':
                        var arrayMCO = _.find(book.approvalList, function(o) { return o.role === 2 && o.user > 0 && o.content === 'artwork';}) || {user: 0 };
                        var briefStats = Wraps.getListChildren(book, 'status', 'brief');
                        if (
                            book.approvalList[copy.approvalStatus] &&
                            book.approvalList[copy.approvalStatus].role == 6 &&
                            briefStats.length === 1 &&
                            briefStats[0] === 1 // Design, all the same status and stat = 1
                        ) {
                            return true;
                        }
                        else if (
                            book.approvalList[copy.approvalStatus] &&
                            (
                                book.approvalList[copy.approvalStatus].role == 2  ||  // MCO
                                book.approvalList[copy.approvalStatus].role == 9 ||
                                book.approvalList[copy.approvalStatus].role == 10 ||
                                (
                                    (book.approvalList[copy.approvalStatus].role == 3 || book.approvalList[copy.approvalStatus].role == 8) &&
                                    book.bookOwner != $scope.$storage.user.id &&
                                    arrayMCO.user != $scope.$storage.user.id
                                ) // Approver

                            )
                        ) {
                            return true;
                        }
                        else {
                            return false;
                        }
                        break;
                    case 'doc':
                        var arrayMCO = _.find(book.approvalList, function(o) { return o.role === 2 && o.user > 0 && o.content === 'doc';}) || {user: 0 };
                        if (
                            book.approvalList[copy.approvalStatus] &&
                            book.approvalList[copy.approvalStatus].role == 2 &&
                            book.approvalStatus > copy.approvalStatus // MCO and has been rejected
                        ) {
                            return true;
                        }
                        else if (
                            book.approvalList[copy.approvalStatus] &&
                            (
                                book.approvalList[copy.approvalStatus].role == 9 ||
                                book.approvalList[copy.approvalStatus].role == 10 ||
                                (
                                    (book.approvalList[copy.approvalStatus].role == 3 || book.approvalList[copy.approvalStatus].role == 8) &&
                                    book.bookOwner != $scope.$storage.user.id &&
                                    arrayMCO.user != $scope.$storage.user.id
                                ) // Approver
                            )
                        ) {
                            return true;
                        }
                        else {
                            return false;
                        }
                        break;
                    case 'final':
                        if (
                            book.approvalList[copy.approvalStatus] &&
                            (
                                book.approvalList[copy.approvalStatus].role == 6 ||
                                book.approvalList[copy.approvalStatus].role == 11 ||
                                book.approvalList[copy.approvalStatus].role == 12 ||
                                book.approvalList[copy.approvalStatus].role == 13 ||
                                book.approvalList[copy.approvalStatus].role == 14 ||
                                book.approvalList[copy.approvalStatus].role == 15
                            ) // Approver
                        ) {
                            return true;
                        }
                        else {
                            return false;
                        }
                        break;
                    default:
                        return false;
                }

            } else {
                return false;
            }
        };


        vm.audiences = Audiences.__get__notDeleted();
        vm.insurers = Insurers.__get__notDeleted();
        vm.subInsurers = SubInsurers.__get__notDeleted();
        vm.distributors = Distributors.__get__notDeleted();
        vm.regions = Regions.__get__notDeleted();
        vm.products = Products.__get__notDeleted();
        vm.productTypes = ProductTypes.__get__notDeleted();
        vm.docTypes = DocTypes.find({"filter": {"include": "DocTypeCats", "where": {"docTypeStatus": 1}}});



        vm.submitForApprovalVisibility = function (book) {
            return  book.approvalList[book.approvalStatus] &&
                    vm.isMCO(book) && book.approvalList[book.approvalStatus].role === 2 &&
                    book.approvalStatus < 2 && book.status === 0 &&
                    book.approvalList[book.approvalStatus].content === 'doc' &&
                    book.documentStatus.substring(0, 4) !== 'EXPD';
        }


        vm.changeStatusNextDialog = function (book) {
            var confirm = $mdDialog.confirm()
                .title('Change Book Status')
                .htmlContent('Are you sure you want to change the status of this book?<br /> <b>This action cannot be undone.</b>')
                .ariaLabel('Confirm status change')
                .ok('Change status')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    vm.changeStatusNext(book);
                }, function () {

                });
        }

        vm.changeStatusNext = function (thisBook) {

            var approvalStatus = thisBook.approvalStatus;
            var status = 0;

            var type = 'doc';
            if (thisBook.approvalStatus === _.findLastIndex(thisBook.approvalList, {'content': type})) {  //child approval is end of TYPE workflow
                status = 1; //set child to ready
            } else {
                approvalStatus++; //move child up
            }

            if (thisBook.approvalStatus === thisBook.approvalList.length - 1) {  //child approval is end of TYPE workflow
                thisBook.status = 1; //set book to ready
            } else {
                thisBook.approvalStatus++; //move child up
            }

                Events.create({
                    "eventId": 0,
                    "bookId": thisBook.bookId,
                    "userId": $scope.$storage.user.id,
                    "eventType": "Approved",
                    "eventData": "Book Approved - approvalStatus:" + approvalStatus + " - Status:" + status
                }).$promise.then(function (event) {

                    if (thisBook.status === 0 && thisBook.DocTypes && thisBook.DocTypes.docTypeTemplate === 1 && thisBook.approvalStatus === _.findIndex(thisBook.approvalList, ['content', 'final'])) {
                        thisBook.isLoading = true;
                        WrapsBooks.uploadPDF(thisBook).then(function () {
                            thisBook.isLoading = false;
                            WrapsBooks.updateBookById(thisBook.bookId);
                            //thisBook.updateBook();
                            console.log('pdf saved');
                        });

                    }

                    //thisBook.status = results.approvalStatus;
                    //thisBook.approvalStatus = results.approvalStatus;

                    Structures.updateAll({
                        "where": {
                            "bookId": thisBook.bookId,
                            "approvalStatus": {"lte": approvalStatus}
                        }
                    }, {
                        "status": status,
                        "approvalStatus": approvalStatus
                    }).$promise.then(function (results) {

                        if (status === 1) {
                            Versions.approveVersionsByBook({"bookId": thisBook.bookId}).$promise.then(function (response) {
                                //console.log(response);
                            });
                            Wraps.sendApprovedEmail(thisBook);
                        } else {
                            Wraps.sendReviewEmail(thisBook,event.eventId);
                        }


                        var liveDate = $filter('date')(thisBook.liveDate, "dd/MM/yyyy");

                        //this is to update the lastupdated date so the trigger get set after the structure changes


                        Books.upsert({
                            "bookId": thisBook.bookId,
                            "status": thisBook.status,
                            "approvalStatus": thisBook.approvalStatus
                        }).$promise.then(function (results) {
                            WrapsBooks.updateBookById(thisBook.bookId);
                            //thisBook.updateBook();
                        });
                    });
                });


        }


        vm.resetToBriefDialog = function (book) {
            var confirm = $mdDialog.confirm()
                .title('Reset to Brief')
                .htmlContent('Are you sure you want to reset the status of this book?<br />• Brief will return to MCO<br />• Artwork will return to Design<br /><br /> <b>This action cannot be undone.</b>')
                .ariaLabel('Confirm Reset to Brief')
                .ok('Reset to Brief')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    vm.openChangeDialog('resetToBrief').then(function (eventData) {
                        if (eventData) {
                            vm.resetToBrief(book, eventData);
                        }
                    });
                }, function () {

                });
        }


        vm.resetToMCODialog = function (book) {
            var confirm = $mdDialog.confirm()
                .title('Reset to MCO')
                .htmlContent('Are you sure you want to reset the status of this book?<br />• All content will return to MCO<br /><br /> <b>This action cannot be undone.</b>')
                .ariaLabel('Confirm Reset to MCO')
                .ok('Reset to MCO')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    vm.openChangeDialog('resetToMCO').then(function (eventData) {
                        if (eventData) {
                            vm.resetToMCO(book, eventData);
                        }
                    });
                }, function () {

                });
        }


        vm.resetToMCO = function (book, eventData) {

            var statusofMCO1 = _.findIndex(book.approvalList, function (item) {
                return item.role == 2 && item.content === 'doc';
            });
            Structures.updateAll({"where": {"bookId": book.bookId}}, {
                "approvalStatus": statusofMCO1,
                "status": 0
            }).$promise.then(function (results) {
                Books.upsert({
                    "bookId": book.bookId,
                    "approvalStatus": statusofMCO1
                }).$promise.then(function (results) {
                    Events.create({
                        "eventId": 0,
                        "bookId": book.bookId,
                        "userId": $scope.$storage.user.id,
                        "eventType": "Reset to MCO",
                        "eventData": Wraps.toJson(eventData)
                    }).$promise.then(function (results) {
                        //vm.updateBooks();
                        WrapsBooks.updateBookById(book.bookId);
                        //book.updateBook();
                    });
                });
            });
        }


        vm.earlyExpireDialog2 = function (book) {
            var confirm = $mdDialog.confirm()
                .title('Early Expiry Date')
                .htmlContent("<md-datepicker ng-model=\"library.earlyExpiryDate\" md-placeholder=\"Early Expiry Date\"   flex></md-datepicker>")
                .ariaLabel('Early Expire Document')
                .ok('Expire Document')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    vm.openChangeDialog().then(function (eventData) {
                        if (eventData) {
                            //vm.resetToMCO(book, eventData);
                        }
                    });
                }, function () {

                });
        }

        vm.makeBookLiveDialog = function (book) {
            var confirm = $mdDialog.confirm()
                .title('Confirm Make Live')
                .htmlContent('Are you sure you want to make this book live?<br /><br /> <b>This action cannot be undone.</b>')
                .ariaLabel('Confirm Make Live')
                .ok('Confirm Make Live')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    vm.makeBookLive(book);
                }, function () {

                });
        }

        vm.earlyExpireDialog = function (book) {

            $mdDialog.show({
                clickOutsideToClose: false,

                scope: $scope,        // use parent scope in template
                preserveScope: true,  // do not forget this if use parent scope
                locals: {book: book},
                template:
                    '<md-dialog style="width:400px;">' +
                    '<md-dialog-content class="md-padding" flex="100">' +
                    '<h2 class="md-title">Early Expire Document</h2>' +
                    '<h4>Expiry Date</h4>' +
                    '<md-datepicker ng-model="earlyExpiryDate" md-placeholder="Early Expiry Date"   flex></md-datepicker>' +
                    '</md-dialog-content>' +
                    '<md-dialog-actions>' +
                    '<md-button ng-click="closeDialog()" class="md-primary">Cancel</md-button>' +
                    '<md-button ng-click="saveDialog()" class="md-green"  >Expire</md-button>' +
                    '</md-dialog-actions>' +
                    '</md-dialog>',

                controller: function DialogController($scope, $mdDialog, book) {
                    $scope.book = book;
                    $scope.earlyExpiryDate = new Date();
                    $scope.closeDialog = function () {
                        $mdDialog.hide();
                    }
                    $scope.saveDialog = function () {
                        $mdDialog.hide();
                        vm.openChangeDialog('rfe').then(function (eventData) {
                            if (eventData) {
                                //console.log($scope.earlyExpiryDate);

                                vm.earlyExpire(book, $scope.earlyExpiryDate, eventData);
                            }
                        });
                    }
                }
            });
        }

        vm.earlyExpire = function (book, earlyExpiryDate, eventData) {

            Books.upsert({
                "bookId": book.bookId,
                "lastUpdated": moment.utc().format(Wraps.databaseDateFormat),
                "earlyExpiryDate": earlyExpiryDate
            }).$promise.then(function (result) {




                Wraps.sendEarlyExpiryEmail(book, earlyExpiryDate).then(function(res){
                    var msgid = null;
                    if(res && res.messageId){
                        msgid = res.messageId;
                    }
                    Events.create({
                        "eventId": 0,
                        "bookId": book.bookId,
                        "userId": $scope.$storage.user.id,
                        "messageId": msgid,
                        "eventType": "Early Expired",
                        "eventData": Wraps.toJson(eventData)

                    });
                    Notifications.getCounts();
                    WrapsBooks.updateBookById(book.bookId);
                });

                //book.updateBook();

            });

        }

        vm.makeBookLive = function (book) {

            var bookStatus = Wraps.getListChildren(book, 'status');
            if (book.status === 0 && bookStatus.length === 1 && bookStatus[0] === 1) {
                if (book.approvalList.length === (book.approvalStatus + 1)) { //last status before live
                    var liveDate = $filter('date')(book.liveDate, "dd/MM/yyyy");
                    book.status = 1;

                    Books.upsert({
                        "bookId": book.bookId,
                        "status": book.status,
                        "pdfVersion": book.pdfVersion,
                        "approvalStatus": book.approvalStatus,
                        "lastUpdated": moment.utc().format(Wraps.databaseDateFormat)
                    }).$promise.then(function (results) {

                        Wraps.sendApprovedEmail(book);

                        Versions.approveVersionsByBook({"bookId": book.bookId});

                        Events.create({
                            "eventId": 0,
                            "bookId": book.bookId,
                            "userId": $scope.$storage.user.id,
                            "eventType": "Approved",
                            "eventData": "Book Approved - Status:" + book.status + "\napprovalStatus:" + book.approvalStatus

                        });
                        WrapsBooks.updateBookById(book.bookId);
                        //book.updateBook();
                    });
                }
            }
        }


        vm.resetToBrief = function (book, eventData) {
            var statusofMCO1 = _.findIndex(book.approvalList, function (item) {
                return item.role == 2 && item.content === 'brief';
            });
            var statusofDesign = _.findIndex(book.approvalList, function (item) {
                return item.role == 6 && item.content === 'artwork';
            });
            var statusofDesignFinal = _.findIndex(book.approvalList, function (item) {
                return item.role == 6 && item.content === 'final';
            });


            Events.create({
                "eventId": 0,
                "bookId": book.bookId,
                "userId": $scope.$storage.user.id,
                "eventType": "Reset to Brief",
                "eventData": Wraps.toJson(eventData)
            });

            var prom = [];
            _.forEach(book.Children, function (item, index) {
                var thisStatus = false;
                if (item.hasBrief) {
                    thisStatus = statusofMCO1;
                } else if (item.hasArtwork) {
                    thisStatus = statusofDesign;
                } else if (item.hasFinal) {
                    thisStatus = statusofDesignFinal;
                }

                //console.log("thisStatus", thisStatus);
                if (thisStatus || thisStatus === 0) {
                    prom.push(
                        Structures.upsert({
                            "structureId": item.structureId,
                            "approvalStatus": thisStatus,
                            "status": 0
                        }).$promise.then(function (results) {
                            //events update
                            Events.create({
                                "eventId": 0,
                                "bookId": book.bookId,
                                "structureId": item.structureId,
                                "contentId": item.versions.contentId,
                                "historyId": item.History[item.History.length - 1].historyId,
                                "userId": $scope.$storage.user.id,
                                "eventType": "Reset to Brief",
                                "eventData": "Reset to Brief - Status:" + item.status + "\napprovalStatus:" + item.approvalStatus
                            });
                        })
                    );
                }
            });
            $q.all(prom).then(function () {
                //book approval status to MCO1
                Books.upsert({
                    "bookId": book.bookId,
                    "approvalStatus": statusofMCO1
                }).$promise.then(function (results) {

                    Events.create({
                        "eventId": 0,
                        "bookId": book.bookId,
                        "userId": $scope.$storage.user.id,
                        "eventType": "Reset to Brief",
                        "eventData": Wraps.toJson(eventData)
                    }).$promise.then(function (results) {
                        //when done
                        //vm.updateBook(book);
                        WrapsBooks.updateBookById(book.bookId);
                        //book.updateBook();
                    });

                });
            });

        }


        vm.saveBookInfoDialog = function (book) {
            if (vm.selected.book.DocTypes && vm.selected.book.DocTypes.docTypeId === 1) {

                var levels = false;
                if (book.levels) {
                    angular.forEach(book.levels, function (value, name) {
                        if (value.active === true) {
                            levels = true;
                        }
                    });
                }

                if (levels === false) {
                    var confirm = $mdDialog.confirm()
                        .title('Save Book')
                        .htmlContent('Are you sure you want to save this book without any Levels set?<br />')
                        .ariaLabel('Confirm Save')
                        .ok('Save')
                        .cancel('Cancel');

                    $mdDialog.show(confirm)
                        .then(function () {
                            vm.saveBookInfo(book);
                        }, function () {
                        });
                } else {
                    vm.saveBookInfo(book);
                }

            } else {
                vm.saveBookInfo(book);
            }
        }


        vm.saveEnclosures = function (bookId, enclosures) {

            var deferred = $q.defer();
            var enclosureIdResults;
            var enclosureId;
            var whereData, upsertData;
            var promises = [];

            _.forEach(enclosures, function (enclosure, key) {

                var pattern = new RegExp(/_([0-9]+)$/g);

                enclosureIdResults = pattern.exec(key);
                if (_.isArray(enclosureIdResults)) {
                    enclosureId = enclosureIdResults[1]
                } else {
                    enclosureId = '';
                }

                if (enclosureId != '' && enclosure != '' && !_.isNaN(bookId)) {

                    whereData = {
                        'enclosureBookId': bookId,
                        'enclosureBookCode': enclosure,
                        'enclosureType': enclosureId
                    };

                    upsertData = {
                        'enclosureBookId': bookId,
                        'enclosureBookCode': enclosure,
                        'enclosureType': enclosureId,
                        'status': 1
                    };

                    var loopDefered = $q.defer();
                    Enclosures.upsertWithWhere({"where": whereData}, upsertData).$promise.then(function (results) {
                        //console.log("Enclosures.upsertWithWhere", results);
                        loopDefered.resolve(results);
                    });
                    promises.push(loopDefered.promise);
                }

            });
            $q.all(promises).then(function (results) {
                //console.log("Enclosures all");

                deferred.resolve(results);
            });

            return deferred.promise;
        };

        vm.saveProductIds = function (bookId, productIdsSelectedArray) {

            var deferred = $q.defer();
            var whereData, insertData;


            //ProductIdsBookLink.prototype$updateAttributes({bookId: bookId}, {status: 0});
            //ProductIdsBookLink.setStatusByBookId({bookId: bookId});

            var promises = [];
            _.forEach(productIdsSelectedArray, function (state, productId) {

                if (state === true) {

                    whereData = {
                        'bookId': bookId,
                        'productId': productId
                    };

                    insertData = {
                        'bookId': bookId,
                        'productId': productId,
                        'status': 1
                    };
                    var loopDefered = $q.defer();
                    ProductIdsBookLink.upsertWithWhere({"where": whereData}, insertData).$promise.then(function (results) {
                        loopDefered.resolve();
                    });
                    promises.push(loopDefered.promise);
                }


            });
            $q.all(promises).then(function () {
                deferred.resolve();
            });
            return deferred.promise;
        };

        vm.saveInsurerDetails = function (bookId, insurerDetails) {

            //console.log("insurerDetails", insurerDetails);
            var deferred = $q.defer();
            var whereData, upsertData;
            var promises = [];

            _.forEach(insurerDetails, function (insurer) {

                if (!_.isNaN(bookId)) {

                    whereData = {
                        'bookId': bookId,
                        'insurerId': insurer,
                    };

                    upsertData = {
                        'bookId': bookId,
                        'insurerId': insurer,
                        'status': 1
                    };

                    var loopDefered = $q.defer();
                    InsurerDetails.upsertWithWhere({"where": whereData}, upsertData).$promise.then(function (results) {
                        //console.log("InsurerDetails.upsertWithWhere", results);
                        loopDefered.resolve(results);
                    });
                    promises.push(loopDefered.promise);
                }

            });
            $q.all(promises).then(function (results) {
                //console.log("Insurer details all");

                deferred.resolve(results);
            });

            return deferred.promise;
        };


        vm.saveBookInfo = function (book) {

            var languageFile = '';
            if (vm.newLanguage) {
                languageFile = vm.selected.book.languageFile;
                book.languageFile = languageFile;
                //console.log(book.languageFile);
            }

            vm.isLoading = true;

            if (book.liveOnApproval) {
                book.activeDate = null;
                book.liveDate = null;
                if(book.dates && book.dates.lifecyclePeriod && book.dates.lifecyclePeriod != '0,months' && book.dates.lifecyclePeriod != ''){
                    book.expiryDate = "3000-01-01";
                    book.reviewDate = "3000-01-01";
                }
            }

            // If not corporate then clear client id
            if (book.productTypeId != 4) {
                book.clientId = null;
            }

            book.approvalList = [];
            if (book.workflowDoc) {
                book.approvalList = book.approvalList.concat(book.workflowDoc);
            }
            if (book.workflowBrief) {
                book.approvalList = book.approvalList.concat(book.workflowBrief);
            }
            if (book.workflowArtwork) {
                book.approvalList = book.approvalList.concat(book.workflowArtwork);
            }
            if (book.workflowFinal) {
                book.approvalList = book.approvalList.concat(book.workflowFinal);
            }


            if (book.approvalList) {

                var deleted = _.remove(book.approvalList, function (item) {
                    return item.value === false;
                });


                if (!book.bookId) {

                    book.approvalStatus = 0; //workflow can now always start at the begining!! (14-11-19 after call with Laura)

                }
                book.approvalList = Wraps.toJson(book.approvalList);
            }

            if (book.production) {
                book.production = Wraps.toJson(_.pickBy(book.production, _.identity));
            }
            if (book.fulfilment) {
                book.fulfilment = Wraps.toJson(_.pickBy(book.fulfilment, _.identity));
            }
            if (book.digital) {
                book.digital = Wraps.toJson(_.pickBy(book.digital, _.identity));
            }
            if (book.support) {

                book.support = Wraps.toJson(_.pickBy(book.support, _.identity));
            }
            if (book.inSitu) {
                book.inSitu = Wraps.toJson(_.pickBy(book.inSitu, _.identity));
            }
            if (book.extras) {
                book.extras = Wraps.toJson(book.extras);
            }
            if (book.dates && typeof book.dates == "object") {
                book.dates = Wraps.toJson(book.dates);
            }

            if (book.levels) {
                book.levels = Wraps.toJson(book.levels);
            }
            if (book.bookContent) {
                book.bookContent = Wraps.toJson(book.bookContent);
            }
            if (book.languageVersions) {
                book.languageVersions = Wraps.toJson(book.languageVersions);
            }
            if (book.docTypeId !== 182) { //TODO replace 182 with social asset doc type id
                delete book.socialChannelIds;
            }
            if (book.socialChannelIds && typeof book.socialChannelIds == 'object') {
                book.socialChannelIds = Wraps.toJson(book.socialChannelIds);
            }

            if(book.insurerDetailsSelectedArray && book.insurerDetailsSelectedArray.length == 1) {
                if(book.insurerId == 38){
                    book.insurerId = book.insurerDetailsSelectedArray[0];
                }else{
                    book.insurerDetailsSelectedArray = [];
                    book.insurerDetailsSelectedArray[0] = book.insurerId;
                }
            }else if(book.insurerId != 38){
                book.insurerDetailsSelectedArray = [];
                book.insurerDetailsSelectedArray[0] = book.insurerId;
            }

            if (!book.bookId) { //new
                book.bookId = 0;
                book.status = 0;


                delete book.lastUpdated;
                delete book.created;
                delete book.Teams; //TODO change to work with track by same as doctype
                delete book.Clients; //TODO change to work with track by same as doctype
                delete book.Insurers; //TODO change to work with track by same as doctype
                delete book.SubInsurers; //TODO change to work with track by same as doctype
                delete book.Distributors; //TODO change to work with track by same as doctype
                delete book.Audiences; //TODO change to work with track by same as doctype
                delete book.Regions; //TODO change to work with track by same as doctype
                delete book.Products; //TODO change to work with track by same as doctype
                delete book.ProductTypes; //TODO change to work with track by same as doctype
                delete book.bookCodeOld;
                delete book.changes;
                delete book.wcrChanges;
                delete book.boldWords;
                delete book.Children;
                delete book.withdrawDate;
                delete book.Projects;

                Books.upsert(book).$promise.then(function (result) {

                    var codeComponents = {
                        "bookId": result.bookId,
                        "update": "true"
                    };

                    Books.generateDocumentCode(codeComponents);

                    if (book.duplicateId) {
                        //console.log("duplicate");

                        Structures.find({
                            filter: {
                                where: {
                                    bookId: book.duplicateId,
                                    "parent": null
                                }
                            }
                        }).$promise.then(function (chapters) {

                            //this was stopping the duplication removing the data if no products/enclosures were selected
                            //Enclosures.duplicateByBookId({newBookId: result.bookId, oldBookId: book.duplicateId});
                            //ProductIdsBookLink.duplicateByBookId({newBookId: result.bookId, oldBookId: book.duplicateId});

                            delete book.duplicateId;
                            if (book.DocTypes && book.DocTypes.docTypeTemplate === 1) {
                                angular.forEach(chapters, function (chapter) {
                                    //console.log("vm.newLanguage", vm.newLanguage);

                                    Structures.duplicateDeep({
                                        "bookId": result.bookId,
                                        "userId": $scope.$storage.user.id,
                                        "structureId": chapter.structureId,
                                        "status": result.status,
                                        "approvalStatus": result.approvalStatus,
                                        "parent": null,
                                        "eventData": book.eventData,
                                        "newLanguage": vm.newLanguage,
                                        "languageFile": languageFile,
                                        "languageId": vm.selected.book.language
                                    }).$promise.then(function (response) {

                                    });
                                });

                            }

                            var insurerDetailsDeferred = $q.defer();
                            InsurerDetails.setStatusByBookId({bookId: result.bookId}, function () {
                                //console.log('insurerDetails.setStatusByBookId');
                                vm.saveInsurerDetails(result.bookId, book.insurerDetailsSelectedArray).then(function () {
                                    insurerDetailsDeferred.resolve();
                                });
                            });

                            Notifications.getCounts();
                            var enclosureDeferred = $q.defer();
                            Enclosures.setStatusByBookId({'bookId': result.bookId}, function () {
                                if (result.fulfilment && vm.enclosuresAllowedSubDocTypes.indexOf(result.docTypeId) !== -1 && angular.fromJson(result.fulfilment)[7] == true) {
                                    vm.saveEnclosures(result.bookId, book.enclosures).then(function (enclosuresResult) {
                                        enclosureDeferred.resolve(enclosuresResult);
                                    });
                                }else{
                                    enclosureDeferred.resolve();
                                }
                            });

                            var productIdsDeferred = $q.defer();
                            ProductIdsBookLink.setStatusByBookId({bookId: result.bookId}, function () {
                                if (result.fulfilment && vm.productIdsAllowedSubDocTypes.indexOf(result.docTypeId) !== -1 && angular.fromJson(result.fulfilment)[7] == true) {
                                    vm.saveProductIds(result.bookId, book.productIdsSelectedArray).then(function () {

                                        productIdsDeferred.resolve();
                                    });
                                }else{
                                    productIdsDeferred.resolve();
                                }
                            });

                            $q.all([enclosureDeferred.promise, productIdsDeferred.promise, insurerDetailsDeferred.promise]).then(function () {
                                 vm.closeRightMenu();
                                 //open new book in new tab;
                                 vm.addBookTab(result.bookId).then(function () {
                                    checkTabUpdates().then(function(){
                                        WrapsBooks.updateBookById(result.bookId);
                                    });
                                 });

                                 vm.isLoading = false;
                             });



                        });
                    } else {
                        var insurerDetailsDeferred = $q.defer();
                        InsurerDetails.setStatusByBookId({bookId: result.bookId}, function () {

                            vm.saveInsurerDetails(result.bookId, book.insurerDetailsSelectedArray).then(function () {
                                insurerDetailsDeferred.resolve();
                            });
                        });

                        Notifications.getCounts();
                        var enclosureDeferred = $q.defer();
                        Enclosures.setStatusByBookId({'bookId': result.bookId}, function () {
                            if (result.fulfilment && vm.enclosuresAllowedSubDocTypes.indexOf(result.docTypeId) !== -1 && angular.fromJson(result.fulfilment)[7] == true) {
                                vm.saveEnclosures(result.bookId, book.enclosures).then(function (enclosuresResult) {
                                    enclosureDeferred.resolve(enclosuresResult);
                                });
                            }else{
                                enclosureDeferred.resolve();
                            }
                        });

                        var productIdsDeferred = $q.defer();
                        ProductIdsBookLink.setStatusByBookId({bookId: result.bookId}, function () {
                            if (result.fulfilment && vm.productIdsAllowedSubDocTypes.indexOf(result.docTypeId) !== -1 && angular.fromJson(result.fulfilment)[7] == true) {
                                vm.saveProductIds(result.bookId, book.productIdsSelectedArray).then(function () {
                                    productIdsDeferred.resolve();
                                });
                            }else{
                                productIdsDeferred.resolve();
                            }
                        });

                        $q.all([enclosureDeferred.promise, productIdsDeferred.promise, insurerDetailsDeferred.promise]).then(function () {
                            vm.closeRightMenu();
                            //open new book in new tab;
                            vm.addBookTab(result.bookId).then(function () {
                                checkTabUpdates().then(function(){
                                    WrapsBooks.updateBookById(result.bookId);
                                });
                            });

                            vm.isLoading = false;
                        });
                    }
                    if(book.eventData && book.eventData.duplication && book.eventData.duplication === true) {
                        Events.create({
                            "eventId": 0,
                            "bookId":result.bookId,
                            "messageId":book.eventData.reason,
                            "eventType":"Book Duplication",
                            "eventData":book.eventData.message,
                            "userId":$scope.$storage.user.id
                        });
                    }else{
                        Events.create({
                            "eventId": 0,
                            "bookId":result.bookId,
                            "eventType":"Document Created",
                            "eventData":'{"message":"This document was created manually"}',
                            "userId":$scope.$storage.user.id
                        });
                    }
                });
            } else {

                Enclosures.setStatusByBookId({'bookId': book.bookId}, function () {
                    if (book.fulfilment && vm.enclosuresAllowedSubDocTypes.indexOf(book.docTypeId) !== -1 && angular.fromJson(book.fulfilment)[7] == true) {
                        vm.saveEnclosures(book.bookId, book.enclosures);
                    }
                });

                ProductIdsBookLink.setStatusByBookId({bookId: book.bookId}, function () {
                    if (book.fulfilment && vm.productIdsAllowedSubDocTypes.indexOf(book.docTypeId) !== -1 && angular.fromJson(book.fulfilment)[7] == true) {
                        vm.saveProductIds(book.bookId, book.productIdsSelectedArray);
                    }
                });

                InsurerDetails.setStatusByBookId({bookId: book.bookId}, function () {

                    vm.saveInsurerDetails(book.bookId, book.insurerDetailsSelectedArray)

                });



                delete book.lastUpdated;
                delete book.created;
                delete book.Teams; //TODO change to work with track by same as doctype
                delete book.Clients; //TODO change to work with track by same as doctype
                delete book.Projects; //TODO change to work with track by same as doctype
                delete book.Insurers; //TODO change to work with track by same as doctype
                delete book.SubInsurers; //TODO change to work with track by same as doctype
                delete book.Distributors; //TODO change to work with track by same as doctype
                delete book.Audiences; //TODO change to work with track by same as doctype
                delete book.Regions; //TODO change to work with track by same as doctype
                delete book.Products; //TODO change to work with track by same as doctype
                delete book.ProductTypes; //TODO change to work with track by same as doctype
                delete book.changes;
                delete book.boldWords;
                delete book.Children;
                delete book.ownerName;
                delete book.ownerEmail;
                delete book.bookIdLong;
                delete book.sourceIdLong;

                //console.log(bookMetaOriginalState);

                //BooksVersions.upsert({bookId: book.bookId, userId: $scope.$storage.user.id, fromBlob: JSON.stringify(bookMetaOriginalState), toBlob: JSON.stringify(book)});


                //console.log("pre save test",book);
                Books.upsert(book).$promise.then(function (result) {

                    if(result.bookCodeOld !== 'disableAutoCode'){
                        var codeComponents = {
                            "bookId": result.bookId,
                            "update": "true"
                        };
                        Books.generateDocumentCode(codeComponents);
                    }
                    Notifications.getCounts();
                    //console.log("update result",result);
                    //book.updateBook();
                    checkTabUpdates().then(function(){
                        WrapsBooks.updateBookById(book.bookId);
                    });
                    vm.closeRightMenu();
                    vm.isLoading = false;
                });
            }
        }


        vm.openChangeDialog = function (filter) {
            var deferred = $q.defer();
            return $mdDialog.show({
                controller: 'ChangedialogCtrl',
                controllerAs: 'changedialog',
                templateUrl: 'library/changedialog/changedialog.tpl.html',
                parent: angular.element(document.body),
                locals: {filter: filter},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {
                    return data;
                }, function () {
                    return false; //cancel
                });
        };


        vm.duplicateBookDialog = function (book) {
            var confirm = $mdDialog.confirm()
                .title('Duplicate Book')
                .htmlContent('Are you sure you want to duplicate this book?<br />')
                .ariaLabel('Confirm Duplication')
                .ok('Duplicate')
                .cancel('Cancel');

            $mdDialog.show(confirm)
                .then(function () {
                    vm.duplicateBook(book);
                }, function () {

                });
        }


        vm.duplicateBook = function (thisBook) {
            //console.log(thisBook.approvalList);
            //console.log(_.findIndex(thisBook.approvalList,{role: 2} ));

            vm.openChangeDialog('rfd-' + thisBook.DocTypes.docTypeTemplate).then(function (eventData) {
                if (eventData) {
                    eventData.duplication = true;

                    var newBook = angular.copy(thisBook);
                    //console.log(eventData); //TODO send data to evetns and show different list

                    vm.isLoading = true;

                    newBook.created = moment.utc().format(Wraps.databaseDateFormat);
                    newBook.sourceId = newBook.bookId;

                    newBook.bookId = 0;
                    newBook.status = 0;
                    newBook.earlyExpiryDate = null;
                    newBook.pdfVersion = null;
                    newBook.projectId = null;
                    newBook.approvalStatus = 0;
                    newBook.bookCodeOld = null;
                    newBook.finProm = null;
                    newBook.socialChannelIds = null;

                    //workflow user edits
                    _.forEach(newBook.approvalList, function(workflowStep){
                        //make Design or Unigraph default to "Design/All" for Artwork Design or Final
                        // 20 = Design, 21 = Unigraph
                        if( (workflowStep.team == 20 || workflowStep.team == 21) && (workflowStep.content == "final" || (workflowStep.content == "artwork" && workflowStep.role == 6))){
                            workflowStep.user = undefined;
                            workflowStep.team = 20;
                        }
                        if(workflowStep.user > 0){
                            //if user is disabled the user id and team id gets cleared
                            if(_.findIndex(vm.users, { 'id': workflowStep.user, 'status': 2 })>0 ){
                                workflowStep.team = undefined;
                                workflowStep.user = undefined;
                            }
                        }
                    });

                    var firstMCOIndex = _.findIndex(newBook.approvalList, {role: 2});
                    if (!firstMCOIndex || firstMCOIndex < 0) {
                        firstMCOIndex = 0;
                    }


                    if ($scope.$storage.user.roleId != 1 || $scope.$storage.user.roleId != 5) { //if not DLO or admin make current user owner and first MCO
                        //console.log("firstMCOIndex", firstMCOIndex);
                        newBook.bookOwner = $scope.$storage.user.id;
                        newBook.approvalList[firstMCOIndex].team = $scope.$storage.user.teamId;
                        newBook.approvalList[firstMCOIndex].user = $scope.$storage.user.id;
                    }


                    // if (angular.isObject(newBook.approvalList)) {
                    //   newBook.approvalList = Wraps.toJson(newBook.approvalList);
                    // }
                    //
                    // if (angular.isObject(newBook.levels)) {
                    //   newBook.levels = Wraps.toJson(newBook.levels);
                    // }
                    // if (angular.isObject(newBook.bookContent)) {
                    //   newBook.bookContent = Wraps.toJson(newBook.bookContent);
                    // }
                    // if (angular.isObject(newBook.languageVersions)) {
                    //   newBook.languageVersions = Wraps.toJson(newBook.languageVersions);
                    // }


                    var codeComponents = {
                        "bookId": newBook.bookId,
                        "insurerId": thisBook.insurerId,
                        "productId": thisBook.productId,
                        "docType": thisBook.DocTypes ? thisBook.DocTypes.docTypeCode : '',
                        "languageId": thisBook.language,
                        "clientId": thisBook.clientId,
                        "createdDate": moment().format('MMYY')
                    };

                    Books.generateDocumentCode(codeComponents).$promise.then(function(result) {
                        //vm.book.bookCode = result[0][0].bookCode;
                        newBook.bookCode = result[0][0].bookCode;
                    });


                    newBook.bookName = newBook.bookName;
                    //newBook.bookCode = '';//newBook.bookCode;
                    newBook.duplicateId = thisBook.bookId;
                    newBook.eventData = eventData;

                    delete newBook.Children;
                    delete newBook.boldWords;
                    delete newBook.changes;

                    vm.openRightMenu(newBook);
                    vm.isLoading = false;


                }
            });
        }


        vm.removeFalseFromList = function (obj) {
            obj = _.pick(obj, _.isNumber);
            //console.log(obj);

        }

        vm.getFile2 = function (cont, loc, file) {
            var fileLoc = loc.concat(file);
            Container.download({container: cont, file: fileLoc}, function (res) {
                return res;
            }, function (cb) {
                //console.log(cb);
            });

        }


        var initParaDrag = function (siblings, parentId, contentType) {

          var bookId = siblings[0].bookId;
          dragularService.cleanEnviroment();

          if(contentType == 1) {
            var container = document.getElementById('chapterSort-'+bookId);
            dragularService(container, {

              containersModel: siblings,
              boundingBox: container,
              revertOnSpill: true
            });
          }
          else {
            var container = document.getElementById('sort-' + parentId);
            dragularService(container, {
              moves: function (el, container, handle) {
                return angular.element(handle).hasClass('drag-handle');
              },
              containersModel: siblings,
              boundingBox: container,
              lockY: true
            });
          }
        }


        var initWorkflowList = function () {

            //console.log(angular.copy(vm.selected.book.approvalList));

            var tempApprovalList = angular.copy(vm.selected.book.approvalList);


            // vm.selected.book.workflowTest = [
            //     {content: 'doc', role: 1, team: 20, user: 1, value: true},
            //     {content: 'doc', role: 2, team: $scope.$storage.user.teamId, user: $scope.$storage.user.id, value: true}
            // ];

            vm.selected.book.workflowDoc = [
                {content: 'doc', role: 1, team: 20, user: 1, value: false},
                {content: 'doc', role: 2, team: $scope.$storage.user.teamId, user: $scope.$storage.user.id, value: true}

                //{content: 'doc', role: 4, team: '', user: '', value: true} //CO
            ];
            vm.selected.book.workflowBrief = [
                {content: 'brief', role: 1, team: 20, user: 1, value: false},
                {content: 'brief', role: 2, team: $scope.$storage.user.teamId, user: $scope.$storage.user.id, value: true}
            ];


            vm.selected.book.workflowArtwork = [
                {content: 'artwork', role: 6, team: 20, user: '', value: true},
                {
                    content: 'artwork',
                    role: 2,
                    team: $scope.$storage.user.teamId,
                    user: $scope.$storage.user.id,
                    value: true
                }
            ];

            if (!vm.selected.book.workflowFinal) {
                vm.selected.book.workflowFinal = [
                    {content: 'final', role: 6, team: 20, user: '', value: true},
                    {content: 'final', role: 11, team: 20, user: '', value: false},
                    {content: 'final', role: 12, team: 27, user: '', value: false},
                    {content: 'final', role: 14, team: 44, user: '', value: false},
                    {content: 'final', role: 15, team: 43, user: '', value: false},
                    {content: 'final', role: 13, team: $scope.$storage.user.teamId,  user: $scope.$storage.user.id, value: false }
                ];
            } else if (vm.selected.book.workflowFinal.length === 3) {
                vm.selected.book.workflowFinal = _.concat({content: 'final', role: 6, team: 20, user: '',  value: true}, vm.selected.book.workflowFinal);
            }
            //console.log(angular.copy(vm.selected.book.approvalList));

            if (vm.selected.book.approvalList && vm.selected.book.approvalList.length > 0) {

                //console.log(angular.copy(vm.selected.book.approvalList));

                if (vm.selected.book.DocTypes && vm.selected.book.DocTypes.docTypeTemplate === 1) {
                    //vm.selected.book.workflowFinal.splice(0, 1); // removes design for membership guides

                    vm.selected.book.workflowDocTemp = _.remove(vm.selected.book.approvalList, {content: 'doc'});
                    //console.log("vm.selected.book.workflowDocTemp",vm.selected.book.workflowDocTemp);
                    //console.log("vm.selected.book.workflowDoc1",vm.selected.book.workflowDoc);
                    _.forEach(vm.selected.book.workflowDoc, function (item, index) {
                        if (!_.find(vm.selected.book.workflowDocTemp, {'role': item.role})) {
                            item.value = false;
                            vm.selected.book.workflowDocTemp.splice(index, 0, item);
                        }
                    });
                    vm.selected.book.workflowDoc = vm.selected.book.workflowDocTemp;

                    //console.log("vm.selected.book.workflowDoc2",vm.selected.book.workflowDoc);

                    //TODO vm.selected.book.workflowFinal remove first one
                    vm.selected.book.workflowFinalTemp = _.remove(vm.selected.book.approvalList, {content: 'final'});
                    _.forEach(vm.selected.book.workflowFinal, function (item, index) {
                        if (!_.find(vm.selected.book.workflowFinalTemp, {'role': item.role})) {
                            item.value = false;
                            vm.selected.book.workflowFinalTemp.splice(index, 0, item);
                        }
                    });
                    vm.selected.book.workflowFinal = vm.selected.book.workflowFinalTemp;

                    delete vm.selected.book.workflowBrief;
                    delete vm.selected.book.workflowArtwork;

                } else if (vm.selected.book.DocTypes && vm.selected.book.DocTypes.docTypeTemplate != 1) {

                    vm.selected.book.workflowBriefTemp = _.remove(vm.selected.book.approvalList, {content: 'brief'});
                    _.forEach(vm.selected.book.workflowBrief, function (item, index) {
                        if (!_.find(vm.selected.book.workflowBriefTemp, {'role': item.role})) {
                            item.value = false;
                            vm.selected.book.workflowBriefTemp.splice(index, 0, item);
                        }
                    });
                    vm.selected.book.workflowBrief = vm.selected.book.workflowBriefTemp;

                    vm.selected.book.workflowArtworkTemp = _.remove(vm.selected.book.approvalList, {content: 'artwork'});
                    _.forEach(vm.selected.book.workflowArtwork, function (item, index) {
                        if (!_.find(vm.selected.book.workflowArtworkTemp, {'role': item.role})) {
                            item.value = false;
                            vm.selected.book.workflowArtworkTemp.splice(index, 0, item);
                        }
                    });
                    vm.selected.book.workflowArtwork = vm.selected.book.workflowArtworkTemp;


                    vm.selected.book.workflowFinalTemp = _.remove(vm.selected.book.approvalList, {content: 'final'});
                    _.forEach(vm.selected.book.workflowFinal, function (item, index) {
                        if (!_.find(vm.selected.book.workflowFinalTemp, {'role': item.role})) {
                            item.value = false;
                            vm.selected.book.workflowFinalTemp.splice(index, 0, item);
                        }
                    });
                    vm.selected.book.workflowFinal = vm.selected.book.workflowFinalTemp;

                    delete vm.selected.book.workflowDoc;
                }
            } else {
                if (vm.selected.book.DocTypes && vm.selected.book.DocTypes.docTypeTemplate === 1) {

                    delete vm.selected.book.workflowBrief;
                    delete vm.selected.book.workflowArtwork;
                    vm.selected.book.workflowFinal.splice(0, 1); // removes design for membership guides
                    vm.selected.book.workflowDoc.push({content: 'doc', role: 3, team: 10, user: "", value: true});
                } else if (vm.selected.book.DocTypes && vm.selected.book.DocTypes.docTypeTemplate != 1) {
                    delete vm.selected.book.workflowDoc;
                    vm.selected.book.workflowBrief.push({content: 'brief', role: 3, team: 10, user: "", value: true});
                    vm.selected.book.workflowArtwork.push({content: 'artwork', role: 3,  team: 10, user: "", value: true });
                }

                //console.log(angular.copy(vm.selected.book));
            }
            vm.selected.book.approvalList = tempApprovalList;
            //console.log(angular.copy(vm.selected.book.approvalList));


            vm.selected.book.initWorkflowList = initWorkflowList;
        }




        vm.showHistory = function (id, clickSource) {
            $mdDialog.show({
                controller: 'HistorydialogCtrl',
                controllerAs: 'historydialog',
                templateUrl: 'library/historydialog/historydialog.tpl.html',
                parent: angular.element(document.html),
                locals: {structureId: id, clickSource: clickSource},
                clickOutsideToClose: true
            })
                .then(function (data) {

                    if (data) {
                        vm.bottomSheetData.versions.versiondata = data;
                        vm.json2Obj(vm.bottomSheetData);


                        //console.log(vm.bottomSheetData.versions);
                        //console.log(data);


                    }

                }, function () {
                    vm.status = 'You cancelled the dialog.';
                });
        };

        //TODO move approval of book stages here
        vm.showApproveToast = function (book, elId) {
            //var el = angular.element(document.getElementById(elId));
            //
            //$mdToast.show(
            //    $mdToast.simple()
            //        .textContent('Approve book')
            //        .position('top right')
            //        .parent(el)
            //        .hideDelay(0)
            //        .action('OK')
            //        .capsule(true)
            //).then(function(){
            //  console.log('toast closed');
            //});
        };



        vm.getFile = function (container, file) {
            return apiUrl + "/containers/getfile/" + container + "/" + file;
            //return apiUrl + "/containers/wraps-files/download/" + file;
            //return "http://wraps-test.s3-website-us-east-1.amazonaws.com/"+container+file;
        }


        vm.getRoleById = Wraps.getRoleById;


        // 3 FUNCTIONS FOR AUTOCOMPLETE
        /*vm.queryBooks = function (query) {
            var results = query ? vm.bookCodeList.filter(createFilterFor(query)) : [];
            return results;
        }

        function createFilterFor(query) {
            return function filterFn(item) {
                return (item.toLowerCase().indexOf(query.toLowerCase()) >= 0);
            };
        }
        */
        //needed for the validation
        //TODO move template-based right-menu tab validation logic to the tab-main component & pass the value up to rightmenu
        vm.checkCodes = function (text) {
            //console.log(_.includes(vm.bookCodeList, text));
            return _.includes(vm.bookCodeList, text);
        }
        vm.checkBlank = function (text) {
            //console.log(text);
            return text === '' || typeof text === 'undefined' || text === null;
        }

        vm.checkBlankArray = function(arrayVariable) {
            return _.isEmpty(arrayVariable) || typeof arrayVariable === 'undefined';
        }

        vm.checkProductIdsSelectedArray = function (productIdsSelectedArray) {
            var hitme = false;
            _.forEach(productIdsSelectedArray, function (val) {
                if (val === true) {
                    hitme = true;
                }
            });
            if (hitme === true) {
                return true;
            } else {
                return false;
            }
        }


        // // FUNCTIONS FOR CLIENT AUTOCOMPLETE
        // vm.queryClients = function (query) {
        //   var results = query ? vm.bookClientList.filter( createFilterFor(query) ) : [];
        //   return results;
        // }


        vm.toggleSwitchGroup = function (thisNa, thisGroup) {
            if (thisNa.flag) {
                _.forEach(thisGroup, function (value, key) {
                    thisGroup[key] = false;
                });
            } else {
                thisNa.flag = true;
            }
        }

        /*vm.toggleSwitchNa = function (thisGroup, thisNa) {
            if (_.indexOf(_.values(thisGroup), true) >= 0) {
                thisNa.flag = false;
            } else {
                thisNa.flag = true;
            }

        }*/


        vm.lockSwitch = function (item) {
            if (item.role == 6) {
                item.value = true;
            } else {

                item.value = !item.value;
            }
        }

        vm.replaceSpaceUni = function (txt) {
            if (txt) {
                txt = txt.replace("NO DATE", "NO\u00A0DATE");
                txt = txt.replace("IN WORK", "IN\u00A0WORK");
                return txt;
            }
        }


        vm.isSwapTeamUser = function (thisBook) {

            var now = moment().format('YYYY-MM-DD');

            if (
                $scope.$storage.user.limitDocs === null &&
                thisBook.limitDoc === 0 &&
                thisBook.status == 0 &&
                (thisBook.documentStatus.substring(0, 4) != 'EXPD') &&
                (
                    ($scope.$storage.user.teamId == 20 && typeof _.find(thisBook.approvalList, function (i) { return (i.team == 20 || i.team == 21) && i.role == 6; }) !== 'undefined') ||
                    //($scope.$storage.user.teamId == 10 && typeof _.find(thisBook.approvalList, function (i) { return (i.team == 10) && i.role == 3; }) !== 'undefined') ||
                    (typeof _.find(thisBook.approvalList, function (i) { return (i.team == $scope.$storage.user.teamId ) && i.role == 3; }) !== 'undefined')
                )
            ) {
                return true;
            } else {
                return false;
            }
        };

        vm.swapTeamUserDialog = function (thisBook) {
            return $mdDialog.show({
                controller: 'SwapteamuserdialogCtrl',
                controllerAs: 'swapteamuserdialog',
                templateUrl: 'library/swapteamuserdialog/swapteamuserdialog.tpl.html',
                parent: angular.element(document.body),
                locals: {book: thisBook},
                bindToController: true,
                clickOutsideToClose: true
            })
                .then(function (data) {
                    return data;
                }, function () {
                    return false; //cancel
                });
        };

        //---Routes + doc tabs ---//
        vm.params = $state.params;
        vm.openTabs = WrapsBooks.openTabs;
        vm.openBooks = WrapsBooks.openBooks;
        if(!$scope.$storage.tabHistory){$scope.$storage.tabHistory = []}
        vm.tabHistory = $scope.$storage.tabHistory;

        vm.newTab = function(bookId){

            var deferred = $q.defer();
            //console.log("bookId",bookId);

            bookId = _.toInteger(bookId);
            if(bookId >= 0){
                //console.log("bookId2",bookId);
                bookId = parseInt(bookId);

                $state.go('.', {'bookId':bookId});
                WrapsBooks.loadTab(bookId).then(function(){

                    deferred.resolve();
                }).catch(function(){

                    $state.go('.', {'bookId':null});
                    vm.selectedTabIndex = 0;

                    if(bookId == 'NaN'){bookId = 'Not a number';}

                    var prompt = $mdDialog.alert().title('Unable to return job ' + bookId + '' ).textContent('This may be because this ID doesn\'t exist or you do not have access to this job.').ok('Close');
                    $mdDialog.show(prompt).then(function(res){

                    });
                    deferred.resolve();
                });
            }

            return deferred.promise;
        };

        vm.addBookTab = function(bookId){

            var deferred = $q.defer();
            //console.log('addBookTab');
            bookId = _.toInteger(bookId);
            var tabindex  = _.findIndex(vm.openTabs, function(o) { return _.toInteger(o.bookId) == _.toInteger(bookId) });
            if(tabindex >= 0){
                $state.go('.', {'bookId':bookId});
                vm.selectedTabIndex = tabindex + 1;
            }else{
                vm.openTabs.push({bookId: bookId, isLoading: true});
                vm.newTab(bookId).then(function(){
                    deferred.resolve();
                });
            }

            return deferred.promise;
        }

        if ($state.params.bookId ){
            var bookId = _.toInteger($state.params.bookId);
            if(!_.includes(_.map(vm.openTabs, 'bookId'), bookId)){
                // not in openTabs
                vm.addBookTab(bookId);
            }else{
                // already in openTabs
            }
            for( var i = 0; i < vm.tabHistory.length-1; i++){
                if ( vm.tabHistory[i] === bookId) {
                    vm.tabHistory.splice(i, 1); //remove any previous instances of that book in the history
                }
            }
            vm.tabHistory.push(bookId); //add bookid to end of history array.
        }else{
            vm.tabHistory = $scope.$storage.tabHistory = []; //reset history array
        }


        vm.selectedTabIndex = _.indexOf(_.map(vm.openTabs, 'bookId'),_.toInteger($state.params.bookId)) + 1;



        vm.openLibraryList = function(){
            $state.go('.', {'bookId':null});
        };

        vm.uiOnParamsChanged = function(newParams) {
            //console.log('opc', newParams);
            var bookId = _.toInteger($state.params.bookId);

            if(bookId && bookId == 0){
                $state.go('.', {'bookId':null});
                vm.selectedTabIndex = 0;
            }else if (bookId && !_.includes(_.map(vm.openTabs, 'bookId'),bookId)){
                vm.addBookTab($state.params.bookId);
            }else if($state.params.bookId){
                vm.selectedTabIndex = _.indexOf(_.map(vm.openTabs, 'bookId'),bookId) + 1;
            }

            if(bookId){
                for( var i = 0; i < vm.tabHistory.length-1; i++){
                    if ( vm.tabHistory[i] === bookId) {
                        vm.tabHistory.splice(i, 1); //remove any previous instances of that book in the history
                    }
                }
                vm.tabHistory.push(bookId); //add bookid to end of history array.
            }else{
                vm.tabHistory = $scope.$storage.tabHistory = []; //reset history array
            }
        }

        $scope.$watch('library.selectedTabIndex', function(current, old) {

            //console.log("sti", current);
            if(parseInt(current) == 0 || current == undefined){
                vm.openLibraryList();
                //TODO add a check?
                if(!angular.equals(current, old)) {
                    vm.updateBooks(); //refresh book list.
                }
                //start interval
                $scope.listInterval = $interval(function () {
                    vm.updateBooks();
                }, 300000);
            }else{
                var currentIndex = parseInt(current)-1;

                if (angular.isDefined($scope.listInterval)){
                    $interval.cancel($scope.listInterval);
                }

                var openTab = vm.openTabs[currentIndex];
                var openBook = vm.openBooks[currentIndex];
                Wraps.book = openBook;
                //console.log("openBook",openBook);
                if(openBook && openTab && openTab.updateRequired == true){ //if update flag is set
                    WrapsBooks.updateBookById(openBook.bookId);
                    //openBook.updateBook();
                    openTab.updateRequired = false; //clear flag
                }
                if(openTab){

                    $state.go('.', {'bookId':openTab.bookId});
                }
            }


        });


        $rootScope.$watch(function(){return $localStorage.user}, function(current, old) {
            if(current && current.id){
                //start interval
                if (!angular.isDefined(vm.checkInterval)) {
                    vm.checkInterval = $interval(function(){
                        //every 1 minute
                        checkTabUpdates();
                    },60000);
                }
            }else{
                if (angular.isDefined(vm.checkInterval)){
                    $interval.cancel(vm.checkInterval);
                }
            }


        });


        var checkTabUpdates = function(){

            var deferred = $q.defer();
            var checkableTabs = [];

            //also check all open books that don't have an update flag already set.
            _.forEach(vm.openTabs,function(openTab, ind) {
                if(!openTab.updateRequired || openTab.updateRequired == false){
                    checkableTabs.push(openTab.bookId);
                }
            });
            if(checkableTabs.length > 0) {
                Books.getUpdatedBookIds({
                    "ids": checkableTabs,
                    "lastClientUpdated": WrapsBooks.lastChecked
                }).$promise.then(function (res) {
                    //if there's an update waiting, set an update flag on that book.
                    _.forEach(vm.openTabs, function (tab) {
                        if (res.updatedBookIds.indexOf(parseInt(tab.bookId)) >= 0) {
                            tab.updateRequired = true;
                        }
                    });
                    WrapsBooks.lastChecked = res.updateTime;
                    deferred.resolve();
                });
            }
            return deferred.promise;
        }

        vm.closeTab = function(bookId){
            var tabindex  = _.findIndex(vm.openTabs, function(o) { return _.toInteger(o.bookId) == _.toInteger(bookId) });
            //console.log(tabindex);
            vm.openTabs.splice(tabindex, 1);
            vm.openBooks.splice(tabindex, 1);
            if (vm.tabHistory.length > 1){
                var newTabId = vm.tabHistory[vm.tabHistory.length-2];
                var newTabIndex = _.findIndex(vm.openTabs, function(o) { return _.toInteger(o.bookId) == _.toInteger(newTabId) });
                vm.tabHistory.length = vm.tabHistory.length-2;
                vm.selectedTabIndex = newTabIndex + 1;
                $state.go('.', {'bookId':newTabId});
            }else{
                vm.selectedTabIndex = 0;
                $state.go('.', {'bookId':null});
            }

        };


        vm.docOpenDialog = function(ev){
            var prompt = $mdDialog.prompt().title('Open a document').textContent('Enter the ID of the document you wish to open').placeholder('0000000').targetEvent(ev).required(true).ok('Open').cancel('Cancel');
            $mdDialog.show(prompt).then(function(bookId){

                var bookId = _.toInteger(bookId);
                vm.addBookTab(bookId);

            },function(){
                vm.selectedTabIndex = 0;
                $state.go('.', {'bookId':null});
            });
        };

        //--- end doc-tabs ---//

        vm.stringifySearch = WrapsBooks.stringifySearch;

        //--action icons --//
        vm.icons = ['warning','error', 'error_outline'];
        vm.colors = ['red', 'light-blue', 'light-blue'];
        //-- end action icons --//

        $scope.$on('$destroy', function() {
            if (angular.isDefined(vm.checkInterval)){
                $interval.cancel(vm.checkInterval);
            }
        });

        vm.downloadCSV = function () {
            var deferred = $q.defer();
            vm.isLoading = true;
            WrapsBooks.downloadCSV().then(function(csv){
                vm.isLoading = false;
                deferred.resolve(csv);
            });
            return deferred.promise;
        }


    }



}());
