(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name versions.controller:VersionsCtrl
   *
   * @description
   *
   */
  angular
    .module('versions')
    .controller('VersionsCtrl', VersionsCtrl);

  function VersionsCtrl(
    $window,
    $scope,
    $q,
    $filter,
    $location,
    $localStorage,
    AppVersions,
    Wraps) {
    var vm = this;
    vm.ctrlName = 'VersionsCtrl';

    AppVersions.find().$promise.then(function (versions) {
      vm.versions = versions;
    });

    vm.reload = function(){
      Wraps.reload();
    }
    
  }
}());
