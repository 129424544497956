<div>
<md-toolbar class="md-theme-light">
    <div class="md-toolbar-tools">
		
		<h1 flex class="md-title" wraps-tooltip="maintenance-form-title"><span ng-if="$storage.selectedRow == 0">Add Row</span><span ng-if="$storage.selectedRow != 0">Edit Row {{$storage.selectedRow}}</span> in {{maintenance.tableName}}</h1>
		<md-button ng-click="maintenance.closeEdit()" class="md-icon-button" aria-label="Close pane" wraps-tooltip="maintenance-form-close">
            <ng-md-icon icon="close" size="24"></ng-md-icon>
        </md-button>
        
    </div>
</md-toolbar>
<md-content  layout-padding>
	<form name="metaMaint">
		<div style="padding:20px 20px 80px;"  >
				<div class="details-row">
					<!---EDIT FORM--->
					<div ng-repeat="col in maintenance.cols" wraps-tooltip="maintenance-form-input">
						<md-input-container ng-if="maintenance.pk != col.name && maintenance.tables[maintenance.tableName].dropDown[col.name].childColName != col.name && col.fieldType != 'textarea'" ng-class="{'md-input-invalid': maintenance.errors[col.name]}" style="width:100%">
						<label>{{col.name | titlecase}}</label>
						<input name="{{$storage[maintenance.tableName][col.name]}}" ng-model="$storage[maintenance.tableName][col.name]" ng-keypress="maintenance.validateCharactors($event, col.pattern)" ng-keyup="col.uppercase?$storage[maintenance.tableName][col.name]=$storage[maintenance.tableName][col.name].toUpperCase():''" ng-maxlength="{{col.maxLength}}" md-maxlength="{{col.maxLength}}" maxlength="{{col.maxLength}}" ng-minlength="{{col.minLength}}" type="{{col.type?col.type:'text'}}">
						<p class="error" ng-show="maintenance.errors[col.name]">{{maintenance.errors[col.name]}}</p>
						<p class="error" ng-if="col.minLength && col.minLength != '' && !metaMaint[$storage[maintenance.tableName][col.name]].$valid">This field must be a minimum of {{col.minLength}}</p>
						</md-input-container>
						<md-input-container ng-if="maintenance.pk != col.name && maintenance.tables[maintenance.tableName].dropDown[col.name].childColName == col.name && col.fieldType != 'textarea'" ng-class="{'md-input-invalid': maintenance.errors[col.name]}" style="width:100%">
							<label>{{col.name | titlecase}}</label>
						<md-select ng-model="$storage[maintenance.tableName][maintenance.tables[maintenance.tableName].dropDown[col.name].parentColName]">
							<md-option value="" ng-if="maintenance.tables[maintenance.tableName].dropDown[col.name].addNullValue == true">N/A</md-option>
							<md-option ng-if="!maintenance.tables[maintenance.tableName].dropDown[col.name].filter" value="{{opt[maintenance.tables[maintenance.tableName].dropDown[col.name].childPk]}}" ng-repeat="opt in maintenance.joinTableData[maintenance.tables[maintenance.tableName].dropDown[col.name].tableName]">{{opt[maintenance.tables[maintenance.tableName].dropDown[col.name].childColName]}}</md-option>
							<md-option ng-if="maintenance.tables[maintenance.tableName].dropDown[col.name].filter" value="{{opt[maintenance.tables[maintenance.tableName].dropDown[col.name].childPk]}}" ng-repeat="opt in maintenance.joinTableData[maintenance.tables[maintenance.tableName].dropDown[col.name].tableName] | filter: maintenance.tables[maintenance.tableName].dropDown[col.name].filter">{{opt[maintenance.tables[maintenance.tableName].dropDown[col.name].childColName]}}</md-option>
						</md-select>
						<p class="error" ng-show="maintenance.errors[col.name]">{{maintenance.errors[col.name]}}</p>
						</md-input-container>

						<md-input-container ng-if="maintenance.pk != col.name && maintenance.tables[maintenance.tableName].dropDown[col.name].childColName != col.name && col.fieldType == 'textarea'" ng-class="{'md-input-invalid': maintenance.errors[col.name]}" style="width:100%">
							<label>{{col.name | titlecase}}</label>
							<textarea>{{$storage[maintenance.tableName][col.name]}}</textarea>
							<p class="error" ng-show="maintenance.errors[col.name]">{{maintenance.errors[col.name]}}</p>
						</md-input-container>
					</div>

					<span ng-if="maintenance.tableAdmin === true && maintenance.tableBehaviour == 1" ng-show="true"><!--hiding divisions-->
						<md-input-container>
							<md-checkbox ng-repeat="division in maintenance.divisions" ng-model="division.selected">{{division.division}}</md-checkbox><br />
						</md-input-container>
					</span>

					<span ng-if="maintenance.tableAdmin === true && maintenance.tableBehaviour == 2" ng-show="true"><!--hiding divisions-->
						<md-radio-group ng-model="maintenance.selectedDivision" ng-change="maintenance.updateSelection(maintenance.selectedDivision, maintenance.divisions)">
							<md-radio-button ng-repeat="division in maintenance.divisions" ng-value="division.divisionId">{{division.division}}</md-radio-button><br />
						</md-radio-group>
					</span>

					<div>
						<md-button  type="button" class="md-raised"  ng-click="$storage.selectedRow = 0; $storage[maintenance.tableName] = {}; maintenance.closeEdit();" wraps-tooltip="maintenance-form-close">Cancel</md-button>
						<md-button  type="button" class="md-primary md-raised" ng-disabled="maintenance.compareJson($storage[maintenance.tableName],row) || !metaMaint.$valid" ng-if="$storage.selectedRow != 0"  ng-click="maintenance.saveRow($storage[maintenance.tableName], 'edit');" wraps-tooltip="maintenance-form-save">Save Row</md-button>
						<md-button  type="button" class="md-primary md-raised" ng-disabled="maintenance.compareJson($storage[maintenance.tableName],row) || !metaMaint.$valid" ng-if="$storage.selectedRow == 0"  ng-click="maintenance.saveRow($storage[maintenance.tableName], 'new');" wraps-tooltip="maintenance-form-save">Save Row</md-button>
					</div>
				</div>


		</div>
	</form>






</md-content>
</div>
