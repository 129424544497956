(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableSubInsurer', readableSubInsurer);

  function readableSubInsurer(Wraps) {
    return function (id) {
      var subInsurer;
      if ((subInsurer = _.find(Wraps.subInsurers, {subInsurerId: parseInt(id)})) && subInsurer.subInsurerName) {
        return subInsurer.subInsurerName;
      }
      return null;
    };
  }
}());
