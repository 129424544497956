(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name workflow.controller:WorkflowCtrl
   *
   * @description
   *
   */
  angular
    .module('workflow')
    .controller('WorkflowCtrl', WorkflowCtrl);

  function WorkflowCtrl() {
    var vm = this;
    vm.ctrlName = 'WorkflowCtrl';
  }
}());
