(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name library.emaildetailsdialog.controller:EmaildetailsdialogCtrl
   *
   * @description
   *
   */
  angular
    .module('library.emaildetailsdialog')
    .controller('EmaildetailsdialogCtrl', EmaildetailsdialogCtrl);

  function EmaildetailsdialogCtrl($mdDialog, $q, $sce, $scope, change, Contents, Events, History, Roles, Structures, Users, Versions, Wraps) {
    var vm = this;
    vm.ctrlName = 'EmaildetailsdialogCtrl';
    if(!Users.isAuthenticated()){$location.path('/');}

    vm.change = change;
    vm.isObj = angular.isObject

    vm.dialogHide = function(data) {
      $mdDialog.hide(data);
    };
    vm.dialogCancel = function(data) {
      $mdDialog.cancel(data);
    };



    vm.trustAsHtml = function(string) {
      return $sce.trustAsHtml(string);
    };




    var loadEvents = function () {

      var filter = {"filter":{"where":{"eventId":change},"include": [{"relation":"Messages","Users": { "order": "eventId DESC"}}]}};

      Events.find(filter).$promise.then(function(events){


        events[0].Messages.html = events[0].Messages.html.replace(/undefined/g, "");



        events[0].Messages.response = JSON.parse(events[0].Messages.response);

        vm.events = events;
      });


    }

    loadEvents();

  }
}());
