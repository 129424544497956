(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name maintenance.filter:titlecase
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('drive')
    .filter('seven', seven);

  function seven() {
    return function (id) {
      return ("0000000" + id).slice(-7);
    };
  }
}());
