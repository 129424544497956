(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name maintenance.controller:MaintenanceCtrl
     *
     * @description
     * Table definitions-
     * Seporate: 2
     * Conditional: 1
     * Shared: 3
     *
     */
    angular
        .module('maintenance')
        .controller('MaintenanceCtrl', MaintenanceCtrl);

    function MaintenanceCtrl(
        $filter,
        $localStorage,
        $location,
        $mdDialog,
        $mdSidenav,
        $q,
        $scope,
        $state,
        $timeout,
        Audiences,
        Clients,
        Departments,
        Distributors,
        DivisionMetaLink,
        Divisions,
        DocContents,
        DocTypes,
        DocTypeCats,
        EnclosureTypes,
        Insurers,
        Languages,
        Locations,
        MetaTables,
        NotificationTypes,
        Products,
        ProductTypes,
        ProductIds,
        Projects,
        Regions,
        RfcCategories,
        RfcReasons,
        Roles,
        SocialChannels,
        SubInsurers,
        Teams,
        Tooltips,
        Users,
        Wraps) {
        var vm = this;
        vm.ctrlName = 'MaintenanceCtrl';


        $scope.$storage = $localStorage;
        vm.hasAccess = null;
        vm.hasPermissions = Wraps.hasPermissions;
        vm.hasAnyOfPermissionCat = Wraps.hasAnyOfPermissionCat;
        vm.tables = {};
        vm.divisionId = $scope.$storage.loggedInUser.Divisions.divisionId;
        vm.divisionName = $scope.$storage.loggedInUser.Divisions.division;
        //vm.searchMaintenance = false;

        if (!Users.isAuthenticated()) {
            $location.path('/');
        }

        MetaTables.find({ filter: { order: "tableName" } }, function (results) {
            //vm.tables = results;
            _.forEach(results, function (tables) {
                vm.tables[tables.tableName] = tables;
                vm.tables[tables.tableName].fields = angular.fromJson(vm.tables[tables.tableName].fields);
                vm.tables[tables.tableName].dropDown = angular.fromJson(vm.tables[tables.tableName].dropDown);
                vm.tables[tables.tableName].hasMany = angular.fromJson(vm.tables[tables.tableName].hasMany);
                vm.tables[tables.tableName].disabled = true;
            });

            Wraps.fetchedUser.then(function () {
                _.forEach(vm.tables, function (val, tableName) {
                    vm.tables[tableName].disabled = !(vm.hasPermissions('admin' + tableName) || vm.hasPermissions('adminDivision' + tableName));
                });

                vm.hasAccess = vm.hasAnyOfPermissionCat(3) || vm.hasAnyOfPermissionCat(6);
                if (vm.hasAccess == false) {
                    $state.go('accessDenied', { src: 'Meta List Maintenance' });
                }

                for (var tableName in vm.tables) {
                    if (!vm.tables[tableName].disabled) {
                        vm.changeTable(tableName);
                        break;
                    }
                }
            });

        });

        $scope.$watch('maintenance.filteredRows.length', function () {
            vm.maintQuery.page = 1;
        }, true);


        vm.changeTable = function (tableName) {
            vm.cols = vm.tables[tableName].fields;
            vm.pk = vm.tables[tableName].pk;
            vm.tableId = vm.tables[tableName].metaTableId;
            vm.tableName = tableName;
            vm.deletedCol = vm.tables[tableName].deletedCol;
            vm.orderBy = vm.tables[tableName].orderby;
            vm.tableBehaviour = vm.tables[tableName].tableBehaviour;
            vm.reverse = false;
            vm.maintQuery = {
                limit: 15,
                page: 1
            };

            if (vm.hasPermissions('admin' + tableName)) {
                vm.tableAdmin = true;
            } else if (vm.hasPermissions('adminDivision' + tableName)) {
                vm.tableAdmin = false;
            }

            //$scope.$storage.selectedRow = '';
            $scope.$storage[tableName] = {};
            vm.grabRows(tableName);
        };

        vm.toggleDivisionMeta = function (row) {
            var status;
            if (row.selected === true) {
                status = 1;
            } else {
                status = 0;
            }

            DivisionMetaLink.upsertWithWhere(
                {
                    "where": {
                        "metaTableId": vm.tables[vm.tableName].metaTableId,
                        "metaRowId": row[vm.pk],
                        "divisionId": vm.divisionId
                    }
                },
                {
                    "metaTableId": vm.tables[vm.tableName].metaTableId,
                    "metaRowId": row[vm.pk],
                    "divisionId": vm.divisionId,
                    "status": status
                }
            );

        };

        $scope.sortBy = function (propertyName) {
            //console.log(vm.tables[vm.tableName].dropDown);
            if (vm.tables[vm.tableName].dropDown && vm.tables[vm.tableName].dropDown[propertyName] && vm.tables[vm.tableName].dropDown[propertyName].childColName == propertyName) {
                //for dropdown fields
                vm.reverse = (vm.orderBy === vm.tables[vm.tableName].dropDown[propertyName].tableName + "." + propertyName) ? !vm.reverse : false;
                vm.orderBy = vm.tables[vm.tableName].dropDown[propertyName].tableName + "." + propertyName;
            } else {
                vm.reverse = (vm.orderBy === propertyName) ? !vm.reverse : false;
                vm.orderBy = propertyName;
            }
        };

        //table filter info
        if (!vm.maintQuery) {
            vm.maintQuery = {
                order: 'id',
                limit: 15,
                page: 1
            };
        }


        $scope.$watch('maintenance.selectedTabIndex', function (current, old) {
            var i = 0;
            _.forEach(vm.tables, function (tableinfo, tableName) {
                if (i == current) {
                    vm.changeTable(tableName);
                }
                i++;
            });
        });

        vm.grabRows = function (tableName) {

            vm.rows = [];
            vm.isLoading = true;
            vm.deleteArray = {};
            vm.joinTableData = {};


            if (vm.tableAdmin === true) {
                vm.getRowsForAdminUser();
            } else {
                vm.getRowsForDivisionUser();
            }
        };

        vm.getRowsForDivisionUser = function () {
            var args = {};
            var divisionMetaLink;
            var rowData = [];

            DivisionMetaLink.find({
                "filter": {
                    "where": {
                        "metaTableId": vm.tables[vm.tableName].metaTableId,
                        "divisionId": vm.divisionId,
                        "status": 1
                    }
                }

            }, function (divisionMetaLinks) {

                if (vm.tables[vm.tableName].dropDown) {
                    var joinTables = [];
                    _.forEach(vm.tables[vm.tableName].dropDown, function (dropDown) {
                        joinTables.push(dropDown.tableName);
                        args = { "filter": { "where": {}, "order": dropDown.childColName + ' ASC' } };
                        args.filter.where[dropDown.parentStatusCol] = 1;
                        vm.joinTableData[dropDown.tableName] = eval(dropDown.tableName).find(args);
                    });
                    args = { "filter": { "include": joinTables, "where": {} } };
                    args.filter.where[vm.deletedCol] = 1;
                } else {
                    args = { "filter": { "where": {} } };
                    args.filter.where[vm.deletedCol] = 1;
                }


                eval(vm.tableName).find(args).$promise.then(function (results) {
                    _.forEach(results, function (row, key) {
                        divisionMetaLink = divisionMetaLinks.filter(function (item) {
                            return item.metaTableId == vm.tables[vm.tableName].metaTableId && item.metaRowId == row[vm.pk] && item.divisionId == vm.divisionId;
                        });

                        if (typeof divisionMetaLink[0] !== 'undefined') {

                            if (vm.tableBehaviour == 1) {
                                results[key].divisionLinkId = divisionMetaLink[0].divisionMetaLinkId;
                                results[key].selected = true;
                            } else if (vm.tableBehaviour == 2 && divisionMetaLink[0].divisionId == vm.divisionId) {
                                rowData.push(row);
                            }
                        }
                    });

                    if (vm.tableBehaviour == 1 || vm.tableBehaviour == 3) {
                        vm.rows = results;
                    } else if (vm.tableBehaviour == 2) {
                        vm.rows = rowData;
                    }
                    vm.isLoading = false;
                });

            });
        }

        vm.getRowsForAdminUser = function () {
            var args = {};

            DivisionMetaLink.find({
                "filter": {
                    "include": "Divisions",
                    "where": {
                        "metaTableId": vm.tables[vm.tableName].metaTableId,
                        "status": 1
                    }
                }

            }, function (divisionMetaLinks) {
                if (vm.tables[vm.tableName].dropDown) {
                    var joinTables = [];
                    _.forEach(vm.tables[vm.tableName].dropDown, function (dropDown) {
                        joinTables.push(dropDown.tableName);
                        args = { "filter": { "where": {}, "order": dropDown.childColName + ' ASC' } };
                        args.filter.where[dropDown.parentStatusCol] = 1;
                        vm.joinTableData[dropDown.tableName] = eval(dropDown.tableName).find(args);
                    });
                    args = { "filter": { "include": joinTables, "where": {} } };
                    args.filter.where[vm.deletedCol] = 1;
                } else {
                    args = { "filter": { "where": {} } };
                    args.filter.where[vm.deletedCol] = 1;
                }


                var divisionMetaLink;
                var divisions = [];

                eval(vm.tableName).find(args).$promise.then(function (results) {
                    _.forEach(results, function (row, key) {
                        divisionMetaLink = divisionMetaLinks.filter(function (item) {
                            return item.metaTableId == vm.tables[vm.tableName].metaTableId && item.metaRowId == row[vm.pk];
                        });
                        if (typeof divisionMetaLink !== 'undefined' && !_.isEmpty(divisionMetaLink)) {
                            _.forEach(divisionMetaLink, function (link) {
                                divisions.push(link.Divisions);
                            });
                            results[key].divisions = divisions;
                            divisions = [];
                        }
                    });
                    vm.rows = results;
                    vm.isLoading = false;
                });
            });
        }

        vm.compareJson = function (objA, objB) {
            return (angular.toJson(objA) == angular.toJson(objB));
        };

        vm.saveRow = function (row, action) {

            vm.errors = {};
            vm.isLoading = true;
            var pkObj = {};
            pkObj[vm.pk] = row[vm.pk];

            _.forEach(vm.cols, function (col) { //loop through columns/fields
                var colName = col.name;

                if (typeof vm.tables[vm.tableName].dropDown != 'undefined' && vm.tables[vm.tableName].dropDown !== null && typeof vm.tables[vm.tableName].dropDown[colName] != 'undefined') {
                    delete row[vm.tables[vm.tableName].dropDown[colName].tableName];
                    colName = vm.tables[vm.tableName].dropDown[colName].parentColName;
                }

                if (col.required && !row[colName] && colName != vm.pk) {
                    vm.errors[colName] = 'This field is required';
                } else if (col.unique && colName != vm.pk) { //check they are meant to be unique and that they're not the primary key.
                    var found = _.find(vm.rows, function (item) { //find matching data in existing rows
                        return (item[colName] + '').toLowerCase().trim() == (row[colName] + '').toLowerCase().trim();
                    });
                    if (found && found[vm.pk] != row[vm.pk]) {  //check for found row unless it's the current row.
                        vm.errors[colName] = 'An entry with this value already exists';
                        vm.isLoading = false;
                    }
                }
            });
            if (_.isEmpty(vm.errors)) {
                if (action == 'edit') {
                    eval(vm.tableName).prototype$updateAttributes(pkObj, row, function (row) {
                        if (vm.tableBehaviour == 1 || vm.tableBehaviour == 2) {
                            var promises = {};


                            _.forEach(vm.divisions, function (division) {

                                var divisionDefer = $q.defer();
                                promises[division.divisionId] = divisionDefer.promise;

                                if (typeof division.selected === 'undefined') {
                                    division.selected = false;
                                }

                                if (vm.tableAdmin === false && division.divisionId === vm.divisionId) {
                                    division.selected = true;
                                }

                                DivisionMetaLink.upsertWithWhere(
                                    {
                                        "where": {
                                            "metaTableId": vm.tableId,
                                            "metaRowId": row[vm.pk],
                                            "divisionId": division.divisionId
                                        }
                                    },
                                    {
                                        "metaTableId": vm.tableId,
                                        "metaRowId": row[vm.pk],
                                        "divisionId": division.divisionId,
                                        "status": division.selected //division.divisionId == 1 ? true : false //division.selected //---hiding divisions---//
                                    }, function () {
                                        divisionDefer.resolve();
                                    });
                            });
                            $q.all(promises).then(function () {
                                //all division meta link upserts are complete.
                                vm.closeEditAndGrabRows();
                            });
                        } else {
                            vm.closeEditAndGrabRows();
                        }

                    },
                        function () {
                            //error
                        });
                } else if (action == 'new') {
                    row[vm.pk] = 0;
                    eval(vm.tableName).create(row, function (result) {

                        if (vm.tableBehaviour == 1 || vm.tableBehaviour == 2) {
                            vm.insertIntoMetaLinkTable(result[vm.pk]);
                        } else {
                            vm.closeEditAndGrabRows();
                        }
                    });
                }
            } else {
                vm.isLoading = false;
            }

        };

        vm.insertIntoMetaLinkTable = function (metaRowId) {

            if (vm.tableAdmin === true) {
                _.forEach(vm.divisions, function (division) {

                    if (typeof division.selected === 'undefined') {
                        division.selected = false;
                    }

                    DivisionMetaLink.upsertWithWhere(
                        {
                            "where": {
                                "metaTableId": vm.tableId,
                                "metaRowId": metaRowId,
                                "divisionId": division.divisionId
                            }
                        },
                        {
                            "metaTableId": vm.tableId,
                            "metaRowId": metaRowId,
                            "divisionId": division.divisionId,
                            "status": division.divisionId == 1 ? true : false //division.selected //---hiding divisions---//
                        }, function () {
                            vm.closeEditAndGrabRows();
                        });
                });
            } else {
                DivisionMetaLink.create({
                    "metaTableId": vm.tableId,
                    "metaRowId": metaRowId,
                    "divisionId": vm.divisionId
                }, function () {
                    vm.closeEditAndGrabRows();
                });
            }


        }


        vm.closeEditAndGrabRows = function () {
            $scope.$storage[vm.tableName] = {};
            vm.closeEdit();
            vm.grabRows(vm.tableName);
        }


        vm.deleteRows = function (selectedRows) {
            var confirm = $mdDialog.confirm()
                .title('Delete rows')
                .htmlContent('Are you sure you want to delete these rows?<br />')
                .ariaLabel('Confirm Delete')
                .ok('Delete')
                .cancel('Cancel');

            $mdDialog.show(confirm).then(function () {
                var finishedDeletes = {};
                var relationshipErrors = [];
                _.forEach(selectedRows, function (tbd, key) { //tbd : to be deleted (bool)
                    var rowDefer = $q.defer();
                    finishedDeletes[key] = rowDefer.promise;
                    if (tbd == true) {
                        //if we actually want to delete this row.

                        var relation;
                        if (relation = vm.tables[vm.tableName].hasMany) {
                            var args = { "filter": { "where": {} } };
                            args.filter.where[relation.fk] = parseInt(key);
                            args.filter.where[relation.fdel] = 1;
                            //if table has a hasMany relationship with another table.
                            eval(relation.table).find(args).$promise.then(function (res) { // make call to child table to check for children
                                if (res.length > 0) {
                                    //has children - push row info to error array for prevent alert.
                                    relationshipErrors.push({
                                        rowId: key,
                                        count: res.length,
                                        childTable: relation.table
                                    });
                                    rowDefer.resolve();


                                } else {
                                    //no children associated - okay to delete
                                    vm.doDelete(key).then(function () {
                                        rowDefer.resolve();
                                    });
                                }
                            });
                        } else {
                            //no relationship - okay to delete.
                            vm.doDelete(key).then(function () {
                                rowDefer.resolve();
                            });
                        }
                    } else {
                        rowDefer.resolve();
                    }
                });

                $q.all(finishedDeletes).then(function () {
                    //when all promises are resolved;
                    if (relationshipErrors.length > 0) {
                        //check for relational errors + build error modal.
                        var errorString = '';
                        _.forEach(relationshipErrors, function (row) {
                            errorString += '<li>Row ' + row.rowId + ' has ' + row.count + ' ' + row.childTable + ' assigned to it.</li>'
                        });
                        var preventAlert = $mdDialog.alert().title('Unable to delete all rows').htmlContent('Unable to delete the following rows due to dependencies in other tables. <ul>' + errorString + '</ul>Please delete or reassign these dependants before attempting to delete this row.').ok('Accept');
                        $mdDialog.show(preventAlert);
                    }
                    vm.grabRows(vm.tableName);	//refresh
                });
            });
        };

        vm.doDelete = function (key) {
            var doDelDefer = $q.defer();
            _.forEach(vm.rows, function (row) {
                if (row[vm.pk] == key) {
                    row[vm.deletedCol] = 0;
                    eval(vm.tableName).upsert(row, function () {
                        doDelDefer.resolve();
                    });
                }
            });
            return doDelDefer.promise;
        }

        vm.checkObj = function (obj) {
            var conclusion = true;
            _.forEach(obj, function (element) {

                if (element != '') {
                    conclusion = false;
                }
            });
            return conclusion;
        };


        

        vm.openEdit = function (row) {

            vm.errors = {};
            if (typeof row !== 'undefined') {
                Divisions.find({}, function (divisions) {
                    vm.selectedDivision = vm.divisionId;
                    _.forEach(row.divisions, function (rowDivision) {
                        vm.selectedDivision = rowDivision.divisionId;
                        _.forEach(divisions, function (division, key) {
                            if (rowDivision.divisionId === division.divisionId) {
                                divisions[key].selected = true;
                            }
                        });
                    });
                    vm.divisions = divisions;
                });
            } else {
                Divisions.find({}, function (divisions) {
                    vm.divisions = divisions;
                    vm.selectedDivision = vm.divisionId;
                });
            }

            if (!row) {
                $scope.$storage[vm.tableName] = {};
            } else {
                $scope.$storage[vm.tableName] = angular.copy(row);
            }
            $mdSidenav('right').toggle();
        }

        vm.updateSelection = function (id, entities) {
            angular.forEach(entities, function (division, index) {
                if (division.divisionId == id) {
                    division.selected = true;
                } else {
                    division.selected = false;
                }

            });
        }

        vm.closeEdit = function () {
            vm.isLoading = false;
            $mdSidenav('right').toggle();
        }

        vm.joinDivisions = function (divisions) {
            return _.map(divisions, 'division').join(', ');
        }

        vm.downloadCsv = function () {
            var rows = {};
            var content = [];
            var currentRow;
            var tmp = {};

            _.forEach(vm.filteredRows, function (row) {
                _.forEach(vm.cols, function (col) {

                    if (vm.tables[vm.tableName].dropDown &&
                        vm.tables[vm.tableName].dropDown[col.name] &&
                        row[vm.tables[vm.tableName].dropDown[col.name].tableName]) {
                        rows[col.name] = row[vm.tables[vm.tableName].dropDown[col.name].tableName][col.name];
                    } else {
                        rows[col.name] = row[col.name];
                    }

                });

                if (vm.tableBehaviour != 3) {
                    if (vm.tableAdmin) {
                        tmp[vm.pk] = row[vm.pk];
                        currentRow = _.filter(vm.rows, tmp);
                        rows['divisions'] = vm.joinDivisions(currentRow[0].divisions);
                    } else {
                        rows['divisions'] = vm.divisionName;
                    }
                }

                content.push(rows);
                rows = {};
            });

            return content;
        }

        vm.validateCharactors = function($event, pattern) {
            console.log(pattern);
            var regex = new RegExp(pattern);
            var key = String.fromCharCode(!$event.charCode ? $event.which : $event.charCode);

            if (!regex.test(key)) {
                $event.preventDefault();
                return false;
            }else {
                return true;
            }


        }

        vm.toUppercase = function(val, doit) {
            console.log(val);
            //var key = String.fromCharCode(!$event.charCode ? $event.which : $event.charCode);
            if(val && doit) {
                val = val.toUpperCase();
                //key = key.toUpperCase();
                //console.log(key);
                //$event = key.toUpperCase();
            }
        }


        document.touchstart = {
            setup: function (_, ns, handle) {
                if (ns.includes("noPreventDefault")) {
                    this.addEventListener("touchstart", handle, { passive: false });
                } else {
                    this.addEventListener("touchstart", handle, { passive: true });
                }
            }
        };


    }
}());
