(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name newLibrary.controller:NewLibraryCtrl
   *
   * @description
   *
   */
  angular
    .module('newLibrary')
    .controller('NewLibraryCtrl', NewLibraryCtrl);

  function NewLibraryCtrl(WrapsBooks) {
    var vm = this;
    vm.ctrlName = 'NewLibraryCtrl';

    vm.isLoading = true;
    WrapsBooks.books.then(function (books) {
      vm.isLoading = false;
      vm.books = books;
    });

    // setInterval(function(){
    //   self.books = getBooks();
    // },10000);
    
  }
}());
