(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name library.filter:readableUser
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('library')
    .filter('readableDistributor', readableDistributor);

  function readableDistributor(Wraps) {
    return function (id) {
      var dist;
      if ((dist = _.find(Wraps.distributors, {distributorId: parseInt(id)})) && dist.distributorName) {
        return dist.distributorName;
      }
      return null;
    };
  }
}());
