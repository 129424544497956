<md-fab-speed-dial 
        md-direction="right" 
        class="md-scale " 
        md-open="false" 
        style="padding-left: 10px; padding-top:5px" 
        >
    <md-fab-trigger>
        <md-button   target="_blank"  class="md-fab md-mini" aria-label="add workflow step">
            <ng-md-icon  icon="add" wraps-tooltip="docman-wf-input_addnew"></ng-md-icon>
        </md-button>
    </md-fab-trigger>

    <md-fab-actions >
        <md-button ng-click="inputWfSpeedial.addWorkflow(3, inputWfSpeedial.content)" target="_blank" type="button" class="speeddial-button green-button" wraps-tooltip="docman-wf-input_addnew_approver">APPROVER</md-button>
        <md-button ng-click="inputWfSpeedial.addWorkflow(8, inputWfSpeedial.content)" target="_blank" type="button" class="speeddial-button blue-button" wraps-tooltip="docman-wf-input_addnew_proof">PROOFREADER</md-button>
        <md-button ng-click="inputWfSpeedial.addWorkflow(9, inputWfSpeedial.content)" target="_blank" type="button" class="speeddial-button orange-button" wraps-tooltip="docman-wf-input_addnew_partner">PARTNER</md-button>
        <md-button ng-click="inputWfSpeedial.addWorkflow(10, inputWfSpeedial.content)" target="_blank" type="button" class="speeddial-button purple-button" wraps-tooltip="docman-wf-input_addnew_client">CLIENT</md-button>
    </md-fab-actions>
</md-fab-speed-dial>