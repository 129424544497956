(function () {
  'use strict';

  angular
    .module('profile')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('profile', {
        url: '/profile',

          views: {
			  tooltip: {
				  templateUrl: 'tooltip/tooltip.tpl.html',
				  controller: 'TooltipCtrl',
				  controllerAs: 'tooltip'
			  },
              navigation: {
                  templateUrl: 'navigation/navigation.tpl.html',
                  controller: 'NavigationCtrl',
                  controllerAs: 'navigation'
              },
              main: {
                  templateUrl: 'profile/profile.tpl.html',
                  controller: 'ProfileCtrl',
                  controllerAs: 'profile'
              },
              footer: {
                templateUrl: 'footer/footer.tpl.html',
                controller: 'FooterCtrl',
                controllerAs: 'footer'
              }
          }
      });
  }
}());
