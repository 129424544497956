<div>
    <md-toolbar class="md-theme-light">
        <div class="md-toolbar-tools">
            <h1 flex class="md-title" wraps-tooltip="comments-title">Comments - {{leftComments.book.bookIdLong}}</h1>
            <md-button ng-click="library.closeLeftComments()" class="md-icon-button" aria-label="Close left pane">
                <ng-md-icon icon="close" size="24"></ng-md-icon>
            </md-button>
        </div>
    </md-toolbar>
    <md-content layout="column" flex class="comments-pane">

        <add-comments book="leftComments.book" ng-if="library.hasPermissions('addComments')"></add-comments>
        <div class="divider"></div>
        <md-progress-linear class="animate-if" md-mode="indeterminate" ng-if="leftComments.isLoading"></md-progress-linear>
        <div class="comments-tab" flex>

            <div ng-repeat="event in leftComments.events | orderBy:'-created'" layout="row" layout-padding class="comment-container" ng-class="{'self-comment':$storage.user.id==event.Users.id}">
                <div aria-label="{{event.Users.firstName}} {{event.Users.lastName}}" class="avatar-container" wraps-tooltip="comment-user-avatar">
                    <img ng-if="event.Users && event.Users.profilePic != '' && event.Users.profilePic != null" ng-src="{{library.apiUrl}}/containers/getthumbnail/{{event.Users.profilePic}}" class="user-avatar" alt="User Avatar"/>
                    <div ng-if="event.Users && (event.Users.profilePic == '' || event.Users.profilePic == null)" class="user-avatar initials {{event.Users.lastName|lowercase|limitTo:1}}">{{event.Users.firstName|uppercase|limitTo:1}}{{event.Users.lastName|uppercase|limitTo:1}}</div>
                    <img ng-if="!event.Users" ng-src="/images/wraps-logo.png" md-colors="{background: 'primary'}" class="user-avatar sys-notif" alt="WRAPS"/>

                </div>
                <div flex layout="row" layout-wrap class="comment-text">
                    <span class="md-body-2" flex="100">{{event.eventName}}</span><br/>
                    <span class="md-caption" flex="50">{{event.Users.firstName}} {{event.Users.lastName}}</span><span class="md-caption timestamp" flex="50">{{event.created | date:'dd MMM yyyy HH:mm'}}</span>
                    <div flex="100" class="comment-bubble">

                        <span ng-bind-html="event.Comment.message"></span>
                        <!--<span style="white-space: pre-wrap;" ng-if="event.eventType==='Comment'">{{event.Comment.message}}</span>-->
                        <span style="white-space: pre-wrap;" ng-if="!event.Comment">{{event.eventData}}</span>
                        <div style="" ng-if="event.categories.length > 0">
                            - Category: <span data-ng-repeat="category in event.categories">{{category}} </span>
                        </div>
                        <div style="" ng-if="event.rfcReasons.length > 0">
                            - Reason: <span data-ng-repeat="reason in event.rfcReasons">{{reason}} </span>
                        </div>
                        <div style="" ng-if="event.Comment.filesList.length > 0">
                            <ul class="files-ul" style="white-space: normal; margin-top:-10px; font-size: 12px;">
                                <li ng-repeat="file in event.Comment.filesList" >
                                    <a   ng-href="{{library.getFile(file.fileLocation,file.fileName)}}" target="_blank"  ng-switch="file.fileName.split('.').pop()">
                                        <ng-md-icon ng-switch-when="pdf|doc|docx" ng-switch-when-separator="|" class="file-download" icon="insert_drive_file" size="24" aria-label="Upload complete"></ng-md-icon>
                                        <ng-md-icon ng-switch-when="jpg|png|jpeg|gif|svg" ng-switch-when-separator="|" class="file-download" icon="image" size="24" aria-label="Upload complete"></ng-md-icon>
                                        <ng-md-icon ng-switch-when="csv|xls|xlsx" ng-switch-when-separator="|" class="file-download" icon="border_all" size="24" aria-label="Upload complete"></ng-md-icon>
                                        <ng-md-icon ng-switch-when="htm|html|js|php" ng-switch-when-separator="|" class="file-download" icon="code" size="24" aria-label="Upload complete"></ng-md-icon>
                                        <ng-md-icon ng-switch-default class="file-download" icon="attach_file" size="24" aria-label="Upload complete"></ng-md-icon>{{file.fileName}}
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>

                </div>
                <div style="width:48px; text-align:center">
                    <md-button class="md-icon-button" ng-if="event.Messages" ng-click="library.openEmailDetailsDialog(event.eventId)"  aria-label="alert">
                        <ng-md-icon  icon="email" style="fill: #ccc;" size="24"></ng-md-icon>
                    </md-button>

                    <md-button ng-if="(event.structureId && leftComments.book.DocTypes.docTypeTemplate === 1) && !(event.eventName === 'Final Added')" wraps-tooltip="action-icon"  class="md-icon-button" ng-click="library.openDocDiffDialog(leftComments.book.getChangesByStructureId(event.structureId))"  aria-label="alert">
                        <ng-md-icon  icon="info" style="fill: #ccc;" size="24"></ng-md-icon>
                    </md-button>

                    <md-button ng-if="event.Comment.diff" wraps-tooltip="action-icon"  class="md-icon-button" ng-click="library.openMetaChangeDialog(event.Comment.diff)"  aria-label="alert">
                        <ng-md-icon  icon="info" style="fill: #ccc;" size="24"></ng-md-icon>
                    </md-button>
                </div>
            </div>


        </div>

    </md-content>
</div>
